<div [ngClass]="{'l-container': true, 'l-container-mobile-pad': isMobile}">
	<div class="l-form">
		<div class="l-content">
			<form class="collect-info-form" *ngIf="form" [formGroup]="form">
				<ng-container *ngIf="!isEditing && !isCustom">
					<ng-container
						*ngIf="((profileCollectInfoOptions && profileCollectInfoOptions.length) && !allPreSetOptionsTaken); else emptyProfileQuestions"
					>
						<app-mat-select-control-field
							label="Select a question"
							placeholder="Question"
							[control]="form.get('profileQuestionId')"
							[disabled]="isEditing"
							[options]="questionOptionsNoDuplicates()"
							[tooltip]="'This is the question that will be displayed to the attendee when filling out his/her information.'"
							customId="predefined-question-selector"
						>
						</app-mat-select-control-field>
						<div *ngIf="(profileCollectInfoOptions && profileCollectInfoOptions.length) && !form.get('profileQuestionId').value && !isRegistration" 
							class="l-two-buttons-container"
						>
							<button class="g-secondary-button border-0 l-add-choice-button" id="create-custom-question" (click)="onCustomQuestion()">
								CREATE CUSTOM QUESTION
							</button>
						</div>
					</ng-container>
					<ng-template #emptyProfileQuestions>
						<p>
							{{ profileQuestionsCount !== 0
								? "You've already selected all pre-defined questions. Click below to create your own custom question."
								: "All pre-defined questions have been selected. Click below to create your own custom question."}}
						</p>
						<div *ngIf="!isRegistration" class="l-two-buttons-container">
							<button class="g-secondary-button border-0 l-add-choice-button" (click)="onCustomQuestion()">
								CREATE CUSTOM QUESTION
							</button>
						</div>
					</ng-template>
				</ng-container>
				<ng-container *ngIf="isEditing && !isCustom">
					<app-mat-text-area-control-field
						[control]="form.get('question')"
						[inputClass]="'l-form-field form-control l-text-area'"
						label="Question"
						customId="question-name"
						isDisabled="true"
					></app-mat-text-area-control-field>
				</ng-container>
				<ng-container *ngIf="isCustom || (isEditing && !form.get('profileQuestionId').value)">
					<app-mat-text-area-control-field
						[control]="form.get('question')"
						[inputClass]="'l-form-field form-control l-text-area'"
						[label]="'Question *'"
						[placeholder]="'e.g. Surname'"
						customId="question-name"
					></app-mat-text-area-control-field>
					<div class="l-form-item">
						<label class="g-form-label l-form-label">
							Type of answer *
						</label>
						<mat-radio-group 
							formControlName="type" 
							[ngClass]="{'l-radio-two-columns': !isMobile, 'l-radio-single-column': isMobile}"
							id="question-type"
						>
							<mat-radio-button 
								[value]="eventCollectInfoTypeEnum.SmallTextField" name="questionType" id="type-small-text-field">
								<span class="l-radio-label">{{ eventQuestionTypeMap.get(eventCollectInfoTypeEnum.SmallTextField) }}</span>
							</mat-radio-button>
							<mat-radio-button [value]="eventCollectInfoTypeEnum.LargeTextField" name="questionType" id="type-large-text-field">
								<span class="l-radio-label">{{ eventQuestionTypeMap.get(eventCollectInfoTypeEnum.LargeTextField) }}</span>
							</mat-radio-button>
							<mat-radio-button [value]="eventCollectInfoTypeEnum.DateField" name="questionType" id="type-date-field">
								<span class="l-radio-label">{{ eventQuestionTypeMap.get(eventCollectInfoTypeEnum.DateField) }}</span>
							</mat-radio-button>
							<mat-radio-button [value]="eventCollectInfoTypeEnum.Address" name="questionType" id="type-address">
								<span class="l-radio-label">{{ eventQuestionTypeMap.get(eventCollectInfoTypeEnum.Address) }}</span>
							</mat-radio-button>
							<mat-radio-button [value]="eventCollectInfoTypeEnum.SectionHeading" name="questionType" id="type-section-heading">
								<span class="l-radio-label">{{ eventQuestionTypeMap.get(eventCollectInfoTypeEnum.SectionHeading) }}</span>
							</mat-radio-button>
							<mat-radio-button [value]="eventCollectInfoTypeEnum.Waiver" name="questionType" id="type-waiver">
								<span class="l-radio-label">{{ eventQuestionTypeMap.get(eventCollectInfoTypeEnum.Waiver) }}</span>
							</mat-radio-button>
							<mat-radio-button 
								*ngIf="!isRegistration" 
								[value]="eventCollectInfoTypeEnum.FileUpload" 
								name="questionType" 
								id="type-file-upload"
							>
								<span class="l-radio-label">{{ eventQuestionTypeMap.get(eventCollectInfoTypeEnum.FileUpload) }}</span>
							</mat-radio-button>
							<mat-radio-button
								[value]="eventCollectInfoTypeEnum.CheckBoxList"
								name="questionType"
								id="type-multiple-choice"
								[checked]="isMultipleChoiceSelected()"
							>
								<span class="l-radio-label">Multiple choice</span>
							</mat-radio-button>
						</mat-radio-group>
					</div>
					<ng-container [ngSwitch]="form.get('type').value">
						<ng-container *ngSwitchCase="eventCollectInfoTypeEnum.SmallTextField">
							<app-mat-select-control-field
								[disabled]="isEditing"
								[control]="form.get('textboxWidth')"
								[options]="collectInfoTextboxWidthOptions"
								[placeholder]="'Default'"
								[label]="'Textbox width'"
								customId="textbox-width"
							>
							</app-mat-select-control-field>
							<app-mat-select-control-field
								[disabled]="isEditing"
								[control]="form.get('textboxValidation')"
								[options]="collectInfoTexboxValidationOptions"
								[placeholder]="'None'"
								[label]="'Textbox validation'"
								customId="option-textbox-validation"
							>
							</app-mat-select-control-field>
							<div *ngIf="form.get('textboxValidation').value === eventCollectInfoTextboxValidationMap.RegExp">
								<ng-container [ngTemplateOutlet]="regexValidation">
								</ng-container>
							</div>
						</ng-container>
						<ng-container *ngSwitchCase="eventCollectInfoTypeEnum.DateField">
							<div class="field__composed">
								<app-mat-number-control-field
									[label]="'Minimum age'"
									[control]="form.get('minAge')"
									[allowNegative]="false"
									[tooltip]="'What is the minimum age (in years) that the ticket holder is allowed to be.'"
									customId="minimum-age"
								></app-mat-number-control-field>
								<app-mat-number-control-field
									[label]="'Maximum age'"
									[control]="form.get('maxAge')"
									[allowNegative]="false"
									[tooltip]="'What is the maximum age (in years) that the ticket holder is allowed to be.'"
									customId="maximum-age"
									></app-mat-number-control-field>
							</div>
						</ng-container>
						<ng-container *ngSwitchCase="eventCollectInfoTypeEnum.Waiver">
							<app-mat-text-area-control-field
								[control]="form.get('termsAndCond')"
								placeholder="Enter your terms and conditions here."
								label="Terms & Conditions"
								[inputClass]="'l-form-field form-control l-text-area form-item'"
								[tooltip]="'Attendees will need to check a box confirming they accept the terms and conditions.'"
								customId="terms-and-conditions"
							></app-mat-text-area-control-field>
						</ng-container>
						<ng-container *ngSwitchCase="eventCollectInfoTypeEnum.FileUpload">
							<app-mat-select-control-field
								[disabled]="isEditing"
								[control]="form.get('fileValidation')"
								[options]="collectInfoFileValidationOptions"
								[placeholder]="'e.g. Document'"
								[label]="'File upload validation'"
								customId="file-upload-validation"
							>
							</app-mat-select-control-field>
						</ng-container>
					</ng-container>
					<ng-container
						*ngIf="form.get('type').value === eventCollectInfoTypeEnum.CheckBoxList || form.get('type').value === eventCollectInfoTypeEnum.DropDownList || form.get('type').value === eventCollectInfoTypeEnum.RadioButtonList || form.get('type').value === eventCollectInfoTypeEnum.Country"
					>
						<app-mat-select-control-field
							[disabled]="isEditing"
							[control]="form.get('type')"
							[options]="collectInfoChoiceTypeOptions"
							[label]="'Configure multiple choice'"
							customId="configure-multiple-choice"
						>
						</app-mat-select-control-field>
					</ng-container>
					<div
						*ngIf="(form.get('type').value === eventCollectInfoTypeEnum.CheckBoxList || form.get('type').value === eventCollectInfoTypeEnum.DropDownList || form.get('type').value === eventCollectInfoTypeEnum.RadioButtonList) && choiceOptions"
						class="collect-info-choices"
					>
						<label class="g-form-label l-form-label">Choices</label>
						<div *ngFor="let op of choiceOptions.controls; let i = index" class="collect-info-choice-option">
							<div class="l-choice-label">
								<span class="g-form-label">{{ i + 1 }}.</span>
							</div>
							<div class="l-choice-container">
								<app-mat-text-control-field
									[placeholder]="'e.g. Parking'"
									[control]="op"
									style="flex-grow: 1;"
									customId="multiple-choice-option-{{ i + 1 }}"
								>
								</app-mat-text-control-field>
								<div class="l-choice-delete-container">
									<mat-icon 
										id="delete-multiple-choice-option-{{ i + 1 }}"
										class="l-delete" 
										(click)="$event.stopPropagation(); removeChoice(choiceOptions, i)"
									>
										delete_outline
									</mat-icon>
								</div>
							</div>
						</div>
						<div class="l-two-buttons-container">
							<button
								id="add-multiple-choice-option"
								class="g-secondary-button  l-add-multi-option-button" 
								(click)="addChoice(choiceOptions)"
							>
								ADD CHOICE
							</button>
						</div>
					</div>
					<div *ngIf="form.get('type').value === eventCollectInfoTypeEnum.CheckBoxList || form.get('type').value === eventCollectInfoTypeEnum.RadioButtonList" class="l-toggle">
						<app-mat-slide-control-field
							formControlName="isOtherOption"
							label="Add 'Other' option"
							tooltip="If a ticket buyer selects the 'Other' option a textbox will be revealed allowing them to fill in their own answer."
							[isMobile]="isMobile"
							customId="add-multiple-choice-other-option"
						>
						</app-mat-slide-control-field>
					</div>
					<div *ngIf="form.get('type').value === eventCollectInfoTypeEnum.DropDownList || form.get('type').value === eventCollectInfoTypeEnum.RadioButtonList" class="l-toggle">
						<app-mat-slide-control-field
							(change)="handleChoiceValidationChange($event)"
							[selected]="form.get('textboxValidation').value"
							label="Multiple choice validation"
							tooltip="If you are expecting a 'correct' answer from the buyer, toggle this option ON"
							[isMobile]="isMobile"
							customId="multiple-choice-validation-toggle"
						>
						</app-mat-slide-control-field>
						
						<div class="pb-2" *ngIf="form.get('textboxValidation').value">
							<div [ngClass]="{ 'input-group-mobile': isMobile, 'w-100': !isMobile }">
								<ng-container [ngTemplateOutlet]="regexValidation">
								</ng-container>
							</div>
						</div>
					</div>
				</ng-container>
				<ng-template #regexValidation>
					<app-mat-text-control-field
						[control]="form.get('textboxRegEx')"
						[placeholder]="'e.g. /\d{3,}/gi'"
						[label]="'Regular expression'"
						[tooltip]="form.get('type').value === eventCollectInfoTypeEnum.SmallTextField ? textboxRegexTooltip : multipleChoiceRegexTooltip"
						customId="validation-regular-expression"
						[paddingBottom]="true"
					></app-mat-text-control-field>
					<app-mat-text-control-field
						[control]="form.get('textboxRegExMessage')"
						[placeholder]="'e.g. Should 3 or more digits'"
						[label]="'Error message'"
						[tooltip]="form.get('type').value === eventCollectInfoTypeEnum.SmallTextField ? textboxErrorTooltip : multipleChoiceErrorTooltip"
						customId="validation-regex-message"
						[paddingBottom]="true"
					></app-mat-text-control-field>
				</ng-template>
				<ng-container *ngIf="!isRegistration && (isCustom || isEditing || ((profileCollectInfoOptions && profileCollectInfoOptions.length) && form.get('profileQuestionId').value))">
					<div *ngIf="form.get('profileQuestionId').value === defaultQuestionTypeEnum.DateOfBirth">
						<div class="field__composed">
							<app-mat-number-control-field
								label="Minimum (in years)"
								[control]="form.get('minAge')"
								[allowNegative]="false"
								tooltip="What is the minimum (in years). e.g. if 5 is entered, then only dates greater than 5 years ago are valid"
								customId="minimum-age"
							></app-mat-number-control-field>
							<app-mat-number-control-field
								label="Maximum (in years)"
								[control]="form.get('maxAge')"
								[allowNegative]="false"
								tooltip="What is the maximum (in years). e.g. if 10 is entered, then only dates less than 10 years ago are valid"
								customId="maximum-age"
								></app-mat-number-control-field>
						</div>
					</div>
					<div class="l-extra-options">
						<app-mat-slide-control-field
							formControlName="applyToBuyer"
							label="Apply only to ticket buyer"
							tooltip="If ON this will apply only to the buyer, and not to individual tickets in the order"
							[isMobile]="isMobile"
							customId="apply-to-buyer"
						>
						</app-mat-slide-control-field>
						<ng-container
							*ngIf="form.get('type').value !== eventCollectInfoTypeEnum.SectionHeading && form.get('type').value !== eventCollectInfoTypeEnum.FileUpload"
						>
							<app-mat-slide-control-field
								formControlName="showOnInvoice"
								label="Show this information on buyer's invoice"
								[isMobile]="isMobile"
								customId="show-on-invoice"
							>
							</app-mat-slide-control-field>
							<app-mat-slide-control-field
								formControlName="showOnTicket"
								label="Show on ticket"
								[isMobile]="isMobile"
								customId="show-on-ticket"
							>
							</app-mat-slide-control-field>
							<app-mat-slide-control-field
								*ngIf="form.get('type').value !== eventCollectInfoTypeEnum.Waiver"
								formControlName="showOnCheckin"
								label="Show on check-in"
								tooltip="If ON, this will show when the ticket is checked in with the Quicket GO mobile app. It will not appear in the laptop browser app."
								[isMobile]="isMobile"
								customId="show-on-checkin"
							>
							</app-mat-slide-control-field>
						</ng-container>
						<app-mat-slide-control-field
							*ngIf="form.get('type').value !== eventCollectInfoTypeEnum.SectionHeading"
							formControlName="includeOnTransferredTickets"
							label="Include on transferred tickets"
							tooltip="If ON, users who receive a transferred ticket will need to answer this question."
							[isMobile]="isMobile"
							customId="include-on-trans-tickets"
						>
						</app-mat-slide-control-field>
						<app-mat-slide-control-field
							*ngIf="form.get('type').value === eventCollectInfoTypeEnum.SmallTextField"
							formControlName="isUnique"
							label="Unique field"
							tooltip="If YES, this answer will be unique, i.e. no two answers can be the same."
							[isMobile]="isMobile"
							customId="unique-field"
						>
						</app-mat-slide-control-field>
					</div>
					<div class="collect-info-tickets">
						<ng-container
							*ngIf="!form.get('applyToBuyer').value"
						>
							<label class="l-label">
								Select tickets
								<mat-icon 
									class="l-tooltip" 
									matTooltip="Select which ticket types this question should apply to."
									matTooltipPosition="right"
									#selectTooltip="matTooltip"
									(click)="selectTooltip.toggle()"
								>
									info
								</mat-icon>
							</label>
							<div class="l-ticket-selector" id="ticket-selector">
								<ng-container *ngFor="let t of collectInfoTickets">
									<div class="l-label">
										<mat-checkbox 
											(change)="updateSelectedTickets(t.id, $event.checked)"
											[value]="t.id" 
											[checked]="form.get('selectedTickets').value.includes(t.id)"
											id="select-ticket-{{t.id}}"
										>
											{{t.name}}
										</mat-checkbox>
									</div>
								</ng-container>
							</div>
						</ng-container>
					</div>
				</ng-container>
				<div *ngIf="isRegistration && !isEditing && !isCustom" class="l-two-buttons-container">
					<button class="g-secondary-button border-0 l-add-choice-button" id="create-custom-question" (click)="onCustomQuestion()">
						CREATE CUSTOM QUESTION
					</button>
				</div>
			</form>
		</div>
	</div>
	<app-footer-button 
		class="w-100 mt-auto l-save-cancel-bar" 
		[primaryText]="isEditing ? 'UPDATE' : 'ADD'"
		[secondaryText]="'CANCEL'"
		[isDisabled]="!isSubmitAllowed(form)" 
		[isMobile]="isMobile"
		($primaryButton)="onSave(form.getRawValue())"
		($secondaryButton)="onCancel()"
		primaryButtonId="add-or-update-question"
		secondaryButtonId="cancel"
	>
	</app-footer-button>
</div>
