<div [ngClass]="cssClassList">
	<input
		[textMask]="{mask: createMask(), placeholderChar: ' '}"
		[value]="value"
		autocomplete="off"
		#inputRef
		pInputText
		(input)="onInput($event)"
		(focus)="onFocus()"
		(blur)="onBlur()"
		(keydown)="onKeyDown($event)"
		[placeholder]="placeholder"
		[readonly]="readonly"
		[disabled]="disabled"
		class="form-control w-100"
		[ngClass]="inputClass"
	>
	<div class="ui-input-prefix l-prefix" *ngIf="prefix">
		{{prefix}}
	</div>
	<div *ngIf="allowSpinButtons" class="spin-buttons-wrap">
		<i class="fa fa-caret-up" (click)="onIncrement()"></i>
		<i class="fa fa-caret-down" *ngIf="!(!allowNegative && parse((value || ' ').toString()) === 0)" (click)="onDecrement()"></i>
	</div>
</div>
