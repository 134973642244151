import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { GuestListReportModalComponent } from '../guest-list-report-modal/guest-list-report-modal.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SchemeID } from '@app/models/dataSchema.model';
import { StoreService } from '@app/services/store/store.service';
import * as eventSelectors from '@app/store/selectors/event.selector';
import { GetSelectedGuestInfo, ResendTickets } from '@app/store/actions/event/event.actions';
import { takeUntil } from 'rxjs';
import { SelectedGuestInfo } from '@app/models/event.model';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';

@Component({
	selector: 'app-guest-details-modal',
	templateUrl: './guest-details-modal.component.html',
	styleUrls: ['./guest-details-modal.component.sass'],
})
export class GuestDetailsModalComponent implements OnInit {

	isLoading = false;
	isMobile = false;
	destroyed$: EventEmitter<void> = new EventEmitter<void>();
	selectedGuestInfo: SelectedGuestInfo;
	displayedColumns: string[] = ['question', 'answer'];
	primaryButtonText = 'RESEND TICKETS';
	secondaryButtonText = 'CANCEL';
	constructor(
		private store: StoreService,
		private breakPointService: BreakpointService,

		public dialogRef: MatDialogRef<GuestListReportModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data: {
			eventId: SchemeID;
			purchaseId: number;
			purchaseItemId: number;
			isSeated: boolean;
		}
	) { }

	ngOnInit() {
		this.breakPointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});
		this.initialiseSelectedGuestInfo();
	}

	initialiseSelectedGuestInfo() {
		this.isLoading = true;

		this.store.dispatch(new GetSelectedGuestInfo(
			{ id: this.data.eventId, purchaseId: this.data.purchaseId, purchaseItemId: this.data.purchaseItemId }
		));

		this.store.select(eventSelectors.SelectedGuestInfo(this.data.eventId)).pipe(takeUntil(this.destroyed$))
			.subscribe((selectedGuestInfo) => {
				if (selectedGuestInfo) {
					this.selectedGuestInfo = selectedGuestInfo;
					this.isLoading = false;
				}
			});
	}

	formatTicketReference(ticketReference: string): string {
		const formattedReference = ticketReference.replace(/(QTK)0+(?=[1-9])/, '$1');
		return formattedReference;
	}

	handleCancel() {
		this.dialogRef.close();
	}

	handleResendTickets() {
		this.store.dispatch(new ResendTickets({ eventId: this.data.eventId, purchaseId: this.data.purchaseId }));
		this.dialogRef.close();
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
