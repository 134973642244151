<div class="l-container" *ngIf="!isProductsLoading; else pageLoading">
	<div class="l-content l-header-content" [ngClass]="{ 'mobile': isMobile }">
		<app-web-content-header *ngIf='!isMobile; else mobileHeaderContent' class="l-header"
		[headerContent]="headerContent" [fullWidth]="true" [showBreadCrumb]='false'></app-web-content-header>
		<ng-template #mobileHeaderContent>
			<div>
				<p class="l-header" id="read-more">{{ customDescription }}
					<span (click)="handleReadMore()" class="g-pointer g-highlight g-green">
						{{readMoreText}}
					</span>
				</p>
			</div>
		</ng-template>
	</div>

	<div class="l-download-cards-container" [ngClass]="{ 'mobile': isMobile }">
		<div class="l-card-container" id="download-android" (click)="handleDownloadAndroid()">
			<div class="l-card-header">
				<img class="l-plus-icon" src="./assets/icons/phone_android.svg" alt="Plus Icon">
				<h4 class="l-card-title"> Download on Google Play </h4>
			</div>
		</div>
		<div class="l-card-container" id="download-ios" (click)="handleDownloadIos()">
			<div class="l-card-header">
				<img class="l-plus-icon" src="./assets/icons/phone_iphone.svg" alt="Plus Icon">
				<h4 class="l-card-title"> Download on the App Store </h4>
			</div>
		</div>
	</div>

	<div *ngIf="isOngoing" class="l-selector-container" [ngClass]="{ 'mobile': isMobile }">
		<app-schedule-selector [scheduleItems]="scheduleItems"
		(SelectedScheduleOutput)="handleScheduleFilterOutput($event)" [isMobile]="isMobile" ></app-schedule-selector>
	</div>

	<div class="l-scanners-content-container" [ngClass]="{ 'mobile': isMobile }">
		<app-web-filter [isMobile]="isMobile" [buttonText]="'ADD SCANNER'" [searchPlaceholder]="'Search'"
		(filterOutput)="handleFilter($event)" (buttonClick)="openCreateScannerModal()"></app-web-filter>
		<div class="l-table-container">
			<div *ngIf='isProductsFiltering' class='l-filtering'>
				<mat-spinner></mat-spinner>
			</div>
			<table mat-table [dataSource]="scanners" class='l-table web' matSort>
				<ng-container matColumnDef="scannerName">
					<th mat-header-cell *matHeaderCellDef mat-sort-header id="name-sortable"> Name </th>
					<td mat-cell *matCellDef="let scanner">{{ scanner.scannerName }}</td>
				</ng-container>
				<ng-container matColumnDef="pin">
					<th mat-header-cell *matHeaderCellDef> Pin </th>
					<td mat-cell *matCellDef="let scanner">{{ scanner.scannerPin }}</td>
				</ng-container>
				<ng-container matColumnDef="login link">
					<th mat-header-cell *matHeaderCellDef> Login Link </th>
					<td mat-cell *matCellDef="let scanner">
						<span (click)="handleLogin(scanner.scannerShortLink.shortLink)" class="l-link" id="scanner-name">
							https://qkt.io/{{ scanner.scannerShortLink.shortLink }}
						</span>
					</td>
				</ng-container>
				<ng-container matColumnDef="actions">
					<th mat-header-cell *matHeaderCellDef> Actions </th>
					<td mat-cell *matCellDef="let scanner">
						<button mat-icon-button [matMenuTriggerFor]="menu" id="menu-button">
							<mat-icon>more_vert</mat-icon>
						</button>
						<mat-menu #menu="matMenu">
							<button mat-menu-item id="edit-button" (click)="handleEdit(scanner)">Edit</button>
							<button mat-menu-item id="new-pin-button" (click)="handleGenerateNewPin(scanner)">Generate new pin</button>
							<button mat-menu-item id="login-button" (click)="handleLogin(scanner.scannerShortLink.shortLink)">Login</button>
						</mat-menu>
					</td>
				</ng-container>
				<tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
				<tr *matNoDataRow>
					<td [colSpan]="displayColumns.length">
						<div class="l-empty-state">
							<h3 class="mb-3">Nothing to see here...</h3>
							<div class="mb-3">Nothing matches your search or filters</div>
						</div>
					</td>
				</tr>
			</table>
		</div>
	</div>
</div>
<ng-template #pageLoading>
	<div class="l-page-loader">
		<mat-spinner></mat-spinner>
	</div>
</ng-template>
