import { Injectable } from '@angular/core';
import config from '@app/config';

enum GoogleConnectionMetadataSource {
	PROFILE = 'PROFILE',
	CONTACT = 'CONTACT',
}

interface GoogleConnectionMetadata {
	primary?: boolean;
	source: {
		type: GoogleConnectionMetadataSource;
		id: string;
	};
}

interface GoogleConnection {
	names: {
		metadata: GoogleConnectionMetadata;
		displayName: string;
	}[];
	emailAddresses?: {
		metadata: GoogleConnectionMetadata;
		value: string;
	}[];
}

interface GoogleConnectionAltDto {
	feed: {
		entry: {
			gd$name?: {
				gd$fullName: {
					$t: string;
				};
				gd$givenName: {
					$t: string;
				};
				gd$familyName: {
					$t: string;
				};
			};
			gd$email?: {
				address: string;
			}[];
		}[];
	};
}

interface GoogleConnectionDto {
	connections?: GoogleConnection[];
}

interface GoogleAccountUser {
	name: string;
	image: string;
	email: string;
}

@Injectable()
export class GoogleAccountService {
	static readonly apiKey = config.googleAPIKey;
	static readonly clientId = config.googleClientId;
	static readonly discoveryDocs = ['https://www.googleapis.com/discovery/v1/apis/people/v1/rest'];
	static readonly scope = 'https://www.googleapis.com/auth/contacts.readonly';
	static readonly defaultLibraries = 'client:auth2';
	static readonly contactsURL = 'https://people.googleapis.com/v1/people/me/connections?personFields=names,emailAddresses';

	loadLibraries(): Promise<void> {
		return new Promise((resolve) => {
			gapi.load(GoogleAccountService.defaultLibraries, () => {
				resolve();
			});
		});
	}

	initClient(): Promise<void> {
		const client = this.getClient();

		if (client) {
			const { apiKey, clientId, discoveryDocs, scope } = GoogleAccountService;
			return client.init({
				apiKey,
				clientId,
				discoveryDocs,
				scope,
			});
		}
	}

	getClient() {
		const isClientReady = !!gapi.client;

		return isClientReady
			? gapi.client
			: null;
	}

	getAuthInstance(): gapi.auth2.GoogleAuth {
		return (gapi.auth2 ? gapi.auth2.getAuthInstance() : null);
	}

	async fetchContacts(): Promise<{ name: string; email: string }[]> {
		return gapi.client.request({
			path: GoogleAccountService.contactsURL,
		}).then(res => {
			const connectionsDto: GoogleConnectionDto = JSON.parse(res.body);

			if (!connectionsDto.connections) {
				return [];
			}

			return connectionsDto.connections
				.filter(contact => !!contact.emailAddresses)
				.reduce((acc, v) => {
					const contact = v.names.find(
						el => el.metadata.source.type === GoogleConnectionMetadataSource.CONTACT
					);

					if (contact) {
						acc.push(
							...v.emailAddresses.map(el => ({
								name: contact.displayName,
								email: el.value,
							}))
						);
					}
					return acc;
				}, []);
		});
	}

	async fetchContactsAlt(token: string): Promise<{ name: string; email: string }[]> {
		return fetch(
			`https://www.google.com/m8/feeds/contacts/default/full?v=3.0&alt=json&max-results=2000&access_token=${token}`
		).then(res => res.json())
			.then((connectionsDto: GoogleConnectionAltDto) => {
				if (!connectionsDto.feed.entry) {
					return [];
				}

				return connectionsDto.feed.entry
					.filter(contact => !!contact.gd$email)
					.reduce((acc, v) => {
						acc.push(
							...v.gd$email.map(el => ({
								name: v.gd$name ? v.gd$name.gd$fullName.$t : null,
								email: el.address,
							}))
						);
						return acc;
					}, []);
			});
	}

	getCurrentUser(): GoogleAccountUser {
		const auth = this.getAuthInstance();
		if (!auth) {
			return null;
		}

		const currentUserInfo = auth.currentUser
			.get()
			.getBasicProfile();

		return {
			name: currentUserInfo.getName(),
			email: currentUserInfo.getEmail(),
			image: currentUserInfo.getImageUrl(),
		};
	}

}
