import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NewPromoterNetworkInfo, PromoterNetworkInfo } from '@app/models/event.model';
import { ConfirmationModalComponent } from '@app/shared/modals/confirmation-modal/confirmation-modal.component';

@Component({
	selector: 'app-create-promoter-network-modal',
	templateUrl: './create-promoter-network-modal.component.html',
	styleUrls: ['./create-promoter-network-modal.component.sass'],
})
export class CreatePromoterNetworkModalComponent {
	label?: string = 'Create Promoter Network';
	name: string;
	referralAmountPercentage: number;
	additionalNotes: string;
	openToPublic = false;
	initialFormValues: any;

	constructor (
		public dialogRef: MatDialogRef<CreatePromoterNetworkModalComponent>,
		public dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data: {
			isMobile: boolean;
			canEditReferralAmount: boolean;
			network?: PromoterNetworkInfo;
			actionType: string;
			modalTitle: string;
			modalDescription: string;
		}
	) {
		if (data.actionType === 'EDIT') {
			this.name = this.data.network.name;
			this.referralAmountPercentage = this.data.network.referralAmount;
			this.additionalNotes = this.data.network.notes;
			this.openToPublic = this.data.network.isPublic;

			this.initialFormValues = {
				networkName: this.name,
				referralAmountPercentage: this.referralAmountPercentage,
				additionalNotes: this.additionalNotes,
				openToPublic: this.openToPublic,
			};
		}
	}

	isFormDirty() {
		return JSON.stringify(this.initialFormValues) !== JSON.stringify({
			networkName: this.name,
			referralAmountPercentage: this.referralAmountPercentage,
			additionalNotes: this.additionalNotes,
			openToPublic: this.openToPublic,
		});
	}

	handleClose() {
		this.dialogRef.close();
	}

	handleSave() {

		let newPromoterNetworkInfo: NewPromoterNetworkInfo;

		if (this.data.actionType === 'CREATE') {
			newPromoterNetworkInfo = {
				name: this.name,
				referralAmount: this.referralAmountPercentage,
				additionalNotes: this.additionalNotes,
				openToPublic: this.openToPublic,
				deleted: false,
			};
		} else {
			const editedValues = {
				...(this.initialFormValues.networkName !== this.name ? { networkName: this.name } : {}),

				...(this.initialFormValues.referralAmountPercentage !== this.referralAmountPercentage
					? { referralAmount: this.referralAmountPercentage }
					: {}),

				...(this.initialFormValues.additionalNotes !== this.additionalNotes ? { additionalNotes: this.additionalNotes } : {}),

				...(this.initialFormValues.openToPublic !== this.openToPublic ? { openToPublic: this.openToPublic } : {}),
			};

			newPromoterNetworkInfo = {
				name: editedValues.networkName || this.initialFormValues.networkName,
				referralAmount: editedValues.referralAmount || this.initialFormValues.referralAmountPercentage,
				additionalNotes: editedValues.additionalNotes || this.initialFormValues.additionalNotes,
				openToPublic: editedValues.openToPublic !== undefined ? editedValues.openToPublic : this.initialFormValues.openToPublic,
				id: this.data.network.id,
				deleted: false,
			};
		}
		this.dialogRef.close(newPromoterNetworkInfo);
	}

	handleBack() {
		this.dialog.open(ConfirmationModalComponent, {data:
			{
				title: 'Are You Sure You Want To Exit?',
				text: 'Your changes will not be saved.',
				buttonText: 'CONFIRM',
				centerText: true,
			}})
			.afterClosed().subscribe((result) => {
				if (result) {
					this.handleClose();
				}
			});
	}
}
