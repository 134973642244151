<div class="l-container">
	<div class="l-content" [ngClass]="{'mt-3': isMobile}">
		<div class="d-flex flex-column" [ngClass]="{'my-2': isMobile, 'mt-4 mx-3 ps-2': !isMobile}">
			<h3 class="mb-3">Edit Profile Details</h3>
			<p class="l-description mb-3">
				Any products also linked to the profile selected will use the same
				profile details.
			</p>
		</div>
		<div *ngIf='!isProfileLoading; else profileLoader'>
			<app-event-organiser-profile-form
				[form]="form"
				[includeHeaderWithName]="true"
				[profile]="organiserProfile"
				[isEventCreation]="false"
				[isMobile]="isMobile"
				[isOrganiserHub]="true"
				(imageChange)="handleImageChange()"
			></app-event-organiser-profile-form>
			<div class="l-sticky-bar">
				<app-footer-button
					[isMobile]="isMobile"
					[primaryText]="'SAVE CHANGES'"
					id="save-organiser-profile"
					($primaryButton)="handleOrganiserSave()"
					[isLoading]="isUpdatingDetails"
					[isDisabled]="form && ((!form.valid || form.pristine))"
					[fullWidth]="true"
				>
				</app-footer-button>
			</div>
		</div>
		<ng-template #profileLoader>
			<div class="l-page-loader" [class.mobile]="isMobile">
				<mat-spinner></mat-spinner>
			</div>
		</ng-template>
	</div>
</div>
