import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { routes } from './root-routing.config';

const options = { useHash: true };

@NgModule({
	exports: [ RouterModule ],
	imports: [ RouterModule.forRoot(routes, options) ],
})

export class RootRoutingModule { }
