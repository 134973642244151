<div class="l-container">
	<div *ngIf="!isLoading && dashboard else pageLoading;">
		<div
			*ngIf="dashboard.bannerUrl else noBanner;"
			class="l-banner-container"
			[style.background-image]="backgroundBannerUrl"
		>
			<img
				class="l-banner"
				[src]="backgroundBanner"
			/>
			<i
				class="fa-solid fa-maximize l-maximise"
				#bannerTooltip="matTooltip"
				matTooltip="Click to view your saved banner. To maintain aspect ratio your banner may look smaller here. 
				To preview your actual banner please go to the design page."
				matTooltipPosition="right"
				(click)="handleOpenBanner()"
			></i>
		</div>
		<ng-template #noBanner>
			<a
				[href]="designLink"
				class="l-banner-upload-container"
				id="upload-banner"
			>
				<div class="l-banner-upload">
					<img
						class="l-banner-upload-image"
						src=".\assets\placeholders\event-banner.png"
					>
					<div class="l-banner-upload-elements">
						<mat-icon>image</mat-icon>
						<div class="l-banner-upload-elements-top-text">Upload Cover Photo</div>
						<div class="l-banner-upload-elements-bottom-text">Dimensions 1500 x 500</div>
					</div>

				</div>
			</a>
		</ng-template>
		<div class="l-info-and-share-container">
			<div class="l-info">
				<app-dashboard-info-buttons
					[isMobile]="isMobile"
					[dashboard]="dashboard"
					[eventId]="eventId"
					(toggleSheduleToGoPublic)="toggleSheduleToGoPublicOutput($event)"
					(toggleEventVisibility)="toggleEventVisibilityOutput()"
					(redirectEvent)="redirectFinishedEvent($event)"
				>
				</app-dashboard-info-buttons>
				<div class="l-info-main">
					<div
						class="l-event-name"
						id="event-name"
					>{{ dashboard.name }}</div>
					<div
						class="l-event-date"
						id="dashboard-header-event-date"
					>
						{{ eventDateText }}
						{{ (eventDate | date:'EEE, MMM d, yyyy, HH:mm a') }}
					</div>
					<div
						class="l-event-link"
						id="dashboard-header-event-link"
					>
						<mat-icon class="l-event-link-icon">link</mat-icon>
						<a
							[href]="isEventLive ? dashboard.eventLink : dashboard.eventLink + '?preview=t'"
							target="_blank"
							class="l-event-link-link"
						>{{ dashboard.eventLink }}</a>
					</div>
					<div
						*ngIf="dashboard.productStatus === productStatus.Active && dashboard.hasPaidTickets && dashboard.bankName && isPayoutCurrency(dashboard.currency)"
						class="l-event-payout-details"
					>
						<div
							*ngIf="dashboard.hasPaidTickets && dashboard.bankName && isPayoutCurrency(dashboard.currency)"
							class="l-event-payout-details-details"
							id="dashboard-header-event-payout-details"
						>
							<mat-icon class="l-event-payout-details-icon">payments</mat-icon>
							You will be paid out to your {{ dashboard.bankName }} account {{ dashboard.payoutDate }}
							<a
								[href]="payoutDetailsLink"
								class="l-event-payout-details-link"
								id="change-payout-account-link"
							>(change account)</a>
						</div>
					</div>
				</div>
			</div>
			<app-share-my-event-buttons
				*ngIf="isEventLive"
				class="l-share-buttons"
				[eventLink]="dashboard.eventLink"
				[eventName]="dashboard.name"
			>
			</app-share-my-event-buttons>
		</div>
	</div>
	<ng-template #pageLoading>
		<div class="l-page-loader">
			<mat-spinner></mat-spinner>
		</div>
	</ng-template>
</div>