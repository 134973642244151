<div class="l-container" [ngClass]="{ 'mobile': data.isMobile }">
	<h3 class="l-title" [ngClass]="{ 'mobile': data.isMobile }">{{ data.modalTitle }}</h3>
	<form #scannerForm="ngForm">
		<div>
			<div class="l-form-field-container">
				<label class="g-form-label"> Scanner Name <span class='g-orange'>*</span> </label>
				<input #scannerNameInput="ngModel" id='scanner-name-input' type='text' class="form-control"
					[(ngModel)]="scannerName" name="scannerName" [maxlength]='30' required />
				<div *ngIf="scannerNameInput.invalid && (scannerNameInput.dirty || scannerNameInput.touched)">
					<div class="g-error-input" *ngIf="scannerNameInput.errors.required">Name is required.</div>
				</div>
			</div>

			<div class="l-form-field-container">
				<label class="g-form-label"> Scanner Password <span class='g-orange'>*</span> </label>
				<input #scannerPasswordInput="ngModel" id='scanner-password-input' type='text' class="form-control"
					[(ngModel)]="scannerPin" name="scannerPassword" [maxlength]='30' required />
				<div
					*ngIf="scannerPasswordInput.invalid && (scannerPasswordInput.dirty || scannerPasswordInput.touched)">
					<div class="g-error-input" *ngIf="scannerPasswordInput.errors.required">Password is required.</div>
				</div>
			</div>
		</div>
	</form>
	<div class="l-two-buttons-container">
		<button class="g-primary-button" id="save-button" (click)="handleSave()"
			[class.disabled]='scannerForm.invalid' [disabled]="scannerForm.invalid">SAVE</button>
			<button class="g-secondary-button" id="cancel-button" (click)="handleClose()">CANCEL</button>
	</div>
</div>
