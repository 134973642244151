<div
	class="l-container"
	*ngIf="(!isEventTicketsLoading && !isUserChartsLoading) else pageLoading"
>
	<div class='l-header-content' [ngClass]="{ 'mobile': isMobile }">
		<app-web-content-header
			[headerContent]="headerContent"
			[mediumWidth]='true'
			[showBreadCrumb]="!isMobile"
			[showTitle]="!isMobile"
		></app-web-content-header>
		<button
			*ngIf="form && form.get('tickets').value.length"
			class='l-stop-button g-warning-button'
			(click)="handleStopSales()"
			id='stop-sales-modal'
		><mat-icon class='l-stop-icon'>not_interested</mat-icon>STOP SALES</button>
	</div>
	<div>
		<div
			class="l-content"
			[ngClass]="{ 'mobile': isMobile }"
		>
			<app-event-ticket-types-form
				*ngIf="form && details"
				[isMobile]='isMobile'
				[form]="form"
				[eventId]="eventId"
				[details]="details"
				[currencies]="currencies"
				[questions]="questions"
				[hasSoldTickets]="hasSoldTickets"
				[seatingCategories]="seatingCategories"
				[designerKey]="userDesignerKey"
				[userCharts]="userCharts"
				[eventEndDateTime]="details.endDateTime"
			>
			</app-event-ticket-types-form>
		</div>
	</div>
</div>
<ng-template #pageLoading>
	<div class="l-page-loader">
		<mat-spinner></mat-spinner>
	</div>
</ng-template>
