import { Component, EventEmitter, Input, OnInit} from '@angular/core';
import { SchemeID } from '@app/models/dataSchema.model';
import { EventOrder, EventOrderFilter, PaymentStatus } from '@app/models/event.model';
import { StoreService } from '@app/services/store/store.service';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs';
import * as eventSelectors from '@app/store/selectors/event.selector';
import { EventActionsConstants } from '@app/store/actions/event/event.actions.constants';
import { PageEvent } from '@angular/material/paginator';
import { GetEventOrders } from '@app/store/actions/event/event.actions';
import { Sort } from '@angular/material/sort';
import { FilterOptions } from '@app/models/shared';
import { ScheduleItem } from '@app/models/schedule.model';
import { Go } from '@app/store/actions/router/router.actions';
import { InternalURLCreator } from '@app/services/url/url.dictionary';

@Component({
	selector: 'app-orders-list',
	templateUrl: './orders-list.component.html',
	styleUrls: ['./orders-list.component.sass'],
})
export class OrdersListComponent implements OnInit {
	@Input() isMobile = false;
	@Input() eventId: SchemeID;

	orders: EventOrder[] = [];
	pages = 1;
	totalRecords: number;

	displayColumns: string[] = ['DateAdded', 'CartId', 'OrderId', 'Email', 'Amount', 'Source', 'PaymentStatus', 'More'];

	paymentStatuses =  PaymentStatus;
	pageSizeOptions = [10, 20, 50, 100];
	searchPlaceholder = 'Search orders';

	ordersLength = 0;

	isOrdersLoading = true;
	isOrdersFiltering = false;
	firstDispatch = false;

	filters: EventOrderFilter = new EventOrderFilter();

	paginatorInitialized = false;

	pageIndex = 0;

	scheduleItems: ScheduleItem[] = [];
	isEventOngoing: boolean;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(private store: StoreService, private actions$: Actions) {}

	ngOnInit(): void {
		this.initialiseFilteredOrders();
	}

	initialiseFilteredOrders() {
		this.dispatchFilteredOrders();
		this.firstDispatch = true;

		this.store.select(eventSelectors.eventMetadata(this.eventId))
			.pipe(takeUntil(this.destroyed$))
			.subscribe((event) => {
				if (event) {
					this.scheduleItems = event.scheduleItems;
					this.isEventOngoing = event.isOngoing;

				}
			});

		this.store
			.select(eventSelectors.eventOrdersList(this.eventId))
			.pipe(takeUntil(this.destroyed$))
			.subscribe((orders) => {
				if (orders) {
					this.orders = orders.results;
					this.pages = orders.pages;
					this.ordersLength = orders.totalRecords;
				}
			});

		this.actions$
			.pipe(
				ofType(EventActionsConstants.GET_EVENT_ORDERS_SUCCESS, EventActionsConstants.GET_EVENT_ORDERS_FAILED),
				takeUntil(this.destroyed$)
			)
			.subscribe(({ type }: { type: string }) => {
				if (type === EventActionsConstants.GET_EVENT_ORDERS_FAILED) {
					this.orders = [];
					this.pages = 0;
					this.totalRecords = 0;
				}
				this.isOrdersFiltering = false;
				this.isOrdersLoading = false;
			});
	}

	dispatchFilteredOrders() {
		if (this.firstDispatch) {
			this.firstDispatch = false;
		} else {
			this.isOrdersFiltering = true;
		}

		const filters = {
			...this.filters,
		};

		this.store.dispatch(new GetEventOrders({ eventId: this.eventId, filters: filters }));
	}

	handlePagination(event: PageEvent) {
		this.filters.page = event.pageIndex + 1;
		this.filters.pageSize = event.pageSize;

		this.pageIndex = event.pageIndex;

		this.dispatchFilteredOrders();
	}

	handleSorting(event: Sort) {
		this.filters.sortCol = event.active;

		if (event.direction !== '') {
			this.filters.sortDir = event.direction.toUpperCase();
		}

		this.resetPage();

		this.dispatchFilteredOrders();
	}

	handleStatusClass(statusValue: string) {
		return statusValue.split(' ').join('').toLowerCase();
	}

	handleFilterOutput(filters: FilterOptions) {
		this.filters.search = filters.searchTerm || '';

		this.filters.paidStatus = filters.paymentStatus ? filters.paymentStatus.toUpperCase() : 'ANY';

		this.filters.dateFrom = filters.dateFrom;
		this.filters.dateTo = filters.dateTo;

		this.resetPage();
		this.dispatchFilteredOrders();
	}

	handleSelectedScheduleItem(scheduleItem: ScheduleItem) {
		this.filters.scheduleItemId = scheduleItem.id;
		this.resetPage();
		this.dispatchFilteredOrders();
	}

	handleOrderDetailsRedirect(order: EventOrder) {
		this.store.dispatch(new Go({ path: [InternalURLCreator.orderDetails(this.eventId)], query: { orderId: order.orderId}}));
	}

	resetPage() {
		this.filters.page = 1;
		this.pageIndex = 0;
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
