<div 
    class='l-content'
	[ngClass]="{'mt-3': isMobile}">
	<div 
		class="d-flex flex-column"
		[ngClass]="{ 'l-filter-mobile': isMobile, 'l-filter-web': !isMobile }">		
		<app-web-filter 
            class='mt-3' 
            [isMobile]="isMobile" 
            searchPlaceholder="Search"
            buttonText="ADD QUESTION"
            (buttonClick)="onCollectInfoFormRequest(null)"
			(filterOutput)='handleSearch($event.searchTerm)'
            buttonId="add-question-button"
        >
        </app-web-filter>
		<div class="l-table-container" *ngIf='!isLoading; else showLoading' cdkDropList (cdkDropListDropped)="onReorder($event)" [cdkDropListDisabled]="dragDisabled">
            <div *ngIf='!noQuestions; else hasNoQuestions'>
                <table mat-table id="question-table" [dataSource]="filteredQuestions" class='l-table web'>
                    <ng-container matColumnDef="sortOrder">
                        <th mat-header-cell *matHeaderCellDef class="l-sorting-column">#</th>
                        <td mat-cell *matCellDef="let i = index" class="l-sorting-column">
							<img
								*ngIf="!hasSearchTerm"
								src="./assets/icons/dots-nine.svg"
								alt="Drag Icon"
								class="l-drag-icon g-move"
								(mousedown)="dragDisabled = false;"
								(touchstart)="dragDisabled = false;"
							>						
						</td>
                    </ng-container>
                    <ng-container matColumnDef="question">
                        <th mat-header-cell *matHeaderCellDef>Question</th>
                        <td mat-cell *matCellDef="let question; let i = index" id="question-{{i}}" [innerHTML]="getSafeHtml(question.question)"></td>
                    </ng-container>
                    <ng-container matColumnDef="enabled">
                        <th mat-header-cell *matHeaderCellDef>Include</th>
                        <td mat-cell *matCellDef="let question; let i = index" id="include-{{i}}">
                            <mat-checkbox 
                                [checked]="question.enabled" 
                                (change)="onEventQuestionPropertyToggle('enabled', question.id, $event.checked, question.enabled);
                                onEventQuestionPropertyToggle('required', question.id, false, question.required)"
                                [disabled]="!question.allowEditing"
                                id="enabled-checkbox"
                            >
                            </mat-checkbox>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="required">
                        <th mat-header-cell *matHeaderCellDef>Required</th>
                        <td mat-cell *matCellDef="let question; let i = index" id="required-{{i}}">
                            <mat-checkbox *ngIf="question.type != questionType.SectionHeading"
                                [checked]="question.required"
                                (change)="onEventQuestionPropertyToggle('required', question.id, $event.checked, question.required);
                                onEventQuestionPropertyToggle('enabled', question.id, true, question.enabled)"
                                [disabled]="!question.allowEditing"
                                id="required-checkbox-{{i}}"
                            >
                            </mat-checkbox>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef>Actions</th>
                        <td mat-cell *matCellDef="let question; let i = index" id="actions-{{i}}">
                            <button mat-icon-button [matMenuTriggerFor]="menu">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item
                                    [disabled]="!question.allowEditing"
                                    (click)="onCollectInfoFormRequest(question)"
                                    id="edit-button-{{i}}"
                                >
                                    Edit
                                </button>
                                <button mat-menu-item 
                                    (click)="onEventQuestionDeletion(question.id)"
                                    [disabled]="!question.allowEditing"
                                    id="delete-button-{{i}}"
                                >
                                    Delete
                                </button>
                            </mat-menu>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayColumns; let i = index" id="question-row-{{i}}" cdkDrag></tr>
                </table>
            </div>
            <ng-template #hasNoQuestions>
                <div class='l-empty-card'>
                    <div class="l-empty-state">
                        <h3 class="mb-3 l-empty-card-text">You don't have any questions to collect information from your guests. Click ADD QUESTION to get started.</h3>
                    </div>
                </div>
            </ng-template>
		</div>
        <ng-template #showLoading>
            <div class="l-loading">
                <mat-spinner></mat-spinner>
            </div>
        </ng-template>
	</div>
    <app-footer-button
        *ngIf="displayFooter"
		class="w-100 mt-auto l-save-cancel-bar" 
		primaryText="BACK"
		[isMobile]="isMobile"
        [isDisabled]="false"
		($primaryButton)="goBack()"
		primaryButtonId="back-button"
	>
	</app-footer-button>
</div>
