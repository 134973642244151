import { Input, Directive } from '@angular/core';

@Directive()
export class NumberField {
	@Input() prefix = '';
	@Input() suffix = '';
	@Input() includeThousandsSeparator = true;
	@Input() thousandsSeparatorSymbol = ',';
	@Input() allowDecimal = true;
	@Input() decimalSymbol = '.';
	@Input() decimalLimit = 10;
	@Input() integerLimit = 10;
	@Input() allowNegative = true;
	@Input() allowLeadingZeroes = false;
}
