export class Decimal {
	private _value: string;
	get value() {
		return this._value;
	}

	private _grade: number;
	get grade() {
		return this._grade;
	}

	constructor(value: string | number = 0) {
		let valueToProceed = value;
		if (typeof value === 'number') {
			valueToProceed = value.toString();
		}
		const decimal = this._convertToDecimal(valueToProceed as string);
		this._value = decimal.value;
		this._grade = decimal.grade;
	}

	static toDecimal(value: string, grade: number): Decimal {
		const dec = new Decimal();
		dec._grade = grade;
		dec._value = value;
		return dec;
	}

	private _convertToDecimal(value: string) {
		const valueParts = value.split('.');
		const decimalPart = valueParts[1] ? valueParts[1] : '';

		const decimalStr = valueParts[0] + decimalPart;

		return {
			value: decimalStr,
			grade: decimalPart.length,
		};
	}

	private _upgradeDecimal(grade: number): void {
		if (grade > this._grade) {
			this._value = this._value + new Array(grade - this._grade).fill('0').join('');
			this._grade = grade;
		}
	}

	add(secondDec: Decimal): Decimal {
		if (!(secondDec instanceof Decimal)) {
			return this;
		} else {
			if (this._grade >= secondDec._grade) {
				secondDec._upgradeDecimal(this._grade);
			} else {
				this._upgradeDecimal(secondDec._grade);
			}

			const sum = Number(this._value) + Number(secondDec._value);

			return Decimal.toDecimal(sum.toString(), this._grade);
		}
	}

	subtract(secondDec: Decimal): Decimal {
		if (!(secondDec instanceof Decimal)) {
			return this;
		} else {
			if (this._grade >= secondDec._grade) {
				secondDec._upgradeDecimal(this._grade);
			} else {
				this._upgradeDecimal(secondDec._grade);
			}

			const diff = Number(this._value) - Number(secondDec._value);

			return Decimal.toDecimal(diff.toString(), this._grade);
		}
	}

	toNumber(): number {
		const isNegative = this._value.includes('-');
		if (isNegative) {
			this._value = this.value.replace('-', '');
		}

		const valueStr = (new Array(this._grade).fill('0').join('') + this._value).split('');
		valueStr.splice(valueStr.length - this._grade, 0, '.');

		const valueNmb = Number((isNegative ? '-' : '') + valueStr.join(''));

		return valueNmb;
	}
}
