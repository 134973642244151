import { Component, Input } from '@angular/core';
import { SectionRouteCard } from '@app/models/shared';

@Component({
	selector: 'app-integration-card',
	templateUrl: './integration-card.component.html',
	styleUrls: ['./integration-card.component.sass'],
})
export class IntegrationCardComponent {
	@Input() data: SectionRouteCard;
}
