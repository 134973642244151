<div class="l-nav-menu">
	<a
		[id]="formatId(item.value.title, 'side-nav')"
		*ngFor='let item of navItems | keyvalue: handleDefaultOrder'
		[routerLink]="[item.value.routeTo()]"
		class='l-nav-item g-flat-link'
	>
		<div
			class="l-icon-container"
			[class.l-active-route]="currentNavItemTitle === item.value.title"
		>
			<img
				*ngIf="currentNavItemTitle === item.value.title; else inactive"
				class="l-icon"
				[src]="item.value.iconPath + '-active.svg'"
			/>
			<ng-template #inactive>
				<img
					class="l-icon"
					[src]="item.value.iconPath + '.svg'"
				/>
			</ng-template>
		</div>
		<div class="l-nav-title">{{item.value.title}}</div>
	</a>
</div>