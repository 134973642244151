import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountDetails, Bank, BankAccount, BankBranch, GroupedBank } from '@app/models/user.model';

@Component({
	selector: 'app-add-bank-modal',
	templateUrl: './add-bank-modal.component.html',
	styleUrls: ['./add-bank-modal.component.sass'],
})
export class AddBankModalComponent implements OnInit {

	bankDetails: AccountDetails = {
		bank: null,
		accountHolder: null,
		accountNumber: null,
		isDefault: false,
		branch: null,
	};
	confirmAccountNumber: string;

	bankGroups: GroupedBank[] = this.data.groupedBanks;
	filteredBankGroups: GroupedBank[];

	branches: BankBranch[];
	filteredBranches: BankBranch[];
	@ViewChild('accountDetailsForm') accountDetailsForm!: NgForm;

	constructor(
		public dialogRef: MatDialogRef<AddBankModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data: {
			banks: Bank[];
			isNew: boolean;
			groupedBanks: GroupedBank[];
			account?: BankAccount;
		}
	) {
		const { banks, account, isNew } = this.data;

		const defaultBranch: BankBranch = {
			id: 0,
			branchName: '',
			branchCode: '',
		};

		if (!isNew) {
			const bank = banks.find((b) => b.name === account.bankName) || null;

			if (bank?.branches.length) {
				this.branches = bank.branches;
			}

			defaultBranch.branchCode = this.data.account?.branchCode;

			this.bankDetails = {
				bank: bank,
				accountHolder: account.holder,
				accountNumber: account.number,
				isDefault: account.isDefault,
				branch: bank?.branches?.find(branch => branch.branchName === account?.branch['branchName']) ?? defaultBranch,
			};

		} else {
			this.bankDetails.branch = defaultBranch;
		}
	}

	ngOnInit() {
		this.filteredBankGroups = this.copyBankGroups(this.bankGroups);
		this.filteredBranches = this.branches;
	}

	handleBanksFilter(input: string) {
		if (!this.bankGroups) {
			return;
		}
		let search = input;
		const bankGroupsCopy = this.copyBankGroups(this.bankGroups);
		if (!search) {
			this.filteredBankGroups = bankGroupsCopy;
			return;
		} else {
			search = search.toLowerCase();
		}
		this.filteredBankGroups = bankGroupsCopy.filter(bankGroup => {
			const showBankGroup = bankGroup.iso.toLowerCase().indexOf(search) > -1;
			if (!showBankGroup) {
				bankGroup.banks = bankGroup.banks.filter(bank => bank.name.toLowerCase().indexOf(search) > -1);
			}
			return bankGroup.banks.length > 0;
		});
	}

	handleBranchesFilter(input: string) {
		if (!this.branches) {
			return;
		}
		let search = input;
		if (!search) {
			this.filteredBranches = this.branches;
			return;
		} else {
			search = search.toLowerCase();
		}
		this.filteredBranches = this.branches.filter(branch => branch.branchName.toLowerCase().indexOf(search) > -1);

	}

	handleBankSelection() {
		if (this.bankDetails.bank.branches.length) {
			this.branches = this.bankDetails.bank.branches;
		} else {
			this.branches = null;
		}
		this.filteredBranches = this.branches;
	}

	copyBankGroups(bankGroups: GroupedBank[]) {
		return bankGroups.map(bankGroup => ({
			iso: bankGroup.iso,
			banks: bankGroup.banks.slice(),
		}));
	}

	handleCancel() {
		this.dialogRef.close();
	}

	validateAccountNumbers(accountDetailsForm: NgForm){
		if (this.data.isNew) {
			accountDetailsForm.form.get('accountNumber').updateValueAndValidity();
			accountDetailsForm.form.get('confirmAccount').updateValueAndValidity();
		};
	}

	isSubmitAllowed(form: NgForm){
		return (!form.form.invalid && !form.form.pristine);
	}

	handleSave() {
		this.dialogRef.close(this.bankDetails);
	}
}
