import { Injectable } from '@angular/core';
import { EventOrganiserProfile } from '@app/models/profile.model';
import { UntypedFormGroup } from '@angular/forms';
import {
	requiredValidator,
	emailValidator,
	websiteValidatorWithProtocol,
	spotifyUrl,
	facebookUrl
} from '@app/shared/form-field/form-validators';
import { FormService, ValidatorsConfig } from '../form/form.service';

@Injectable()
export class OrganiserProfileservice {
	constructor(private formService: FormService) {}

	createEventProfileForm(initialValues?: Partial<EventOrganiserProfile>) {
		const formValues = new EventOrganiserProfile();

		const validatorsConfig: ValidatorsConfig<EventOrganiserProfile> = {
			name: [requiredValidator()],
			email: [requiredValidator(), emailValidator()],
			facebookUrl: [facebookUrl(), websiteValidatorWithProtocol()],
			websiteUrl: [websiteValidatorWithProtocol()],
			spotifyLink: [spotifyUrl()],
		};

		const formConfig = this.formService.createFormControls({ formValues, initialValues, validatorsConfig });

		return this.formService.formBuilder.group(formConfig);
	}

	profileFormInitialsValues() {
		return new EventOrganiserProfile();
	}

	checkAndUpdateOrganiserId(savedProfile: EventOrganiserProfile, form: UntypedFormGroup, pathToId = 'id') {
		if (savedProfile && savedProfile.id !== form.get(pathToId).value) {
			form.get(pathToId).setValue(savedProfile.id);
		}
	}
}
