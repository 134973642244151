<div [ngClass]="outerClass">
	<label *ngIf="label" [ngClass]="labelClass" class="g-form-label">
		{{label}}
		<span *ngIf="required" class="g-orange"> * </span>
		<mat-icon 
            *ngIf="tooltip" 
            class="l-tooltip" 
            [matTooltip]="tooltip"
            matTooltipPosition="right"
			#textAreaTooltip="matTooltip"
			(click)="textAreaTooltip.toggle()"
        >
            info
        </mat-icon>
	</label>
	<textarea
		[class]="inputClass"
		[formControl]="control"
		[placeholder]="placeholder"
		[id]="customId"
		[readonly]="readonly"
		[class.l-pointer]="readonly"
		(click)="handleTextAreaClick()"
		[disabled]="isDisabled"
	>
	</textarea>
	<small  class="g-error-input" *ngIf="isControlInvalid() && showError">
		*{{getError(control.errors)}}
	</small>
</div>
