export enum ErrorCodes {
	Undefined = 'An error has occurred, please contact support@quicket.co.za',
	EventNotFound = 'This event does not exist on Quicket',
	UserNotFound = 'Please log into your Quicket account to view this page',
	UserAlreadyHasDraft = 'You already have draft event',
	ValidationError = 'Validation error',
	ProductAccessDenied = 'The account you’re logged into does not have access to this event',
	ImageNotFound = 'The image you requested does not exist',
	ChangeCurrencyDenied = 'Change currency denied',
	CurrencyNotFound = 'This currency is not currently available on Quicket',
	ProductValidation_LongName = 'Event name is too long',
	ProductValidation_EmptyName = 'Please provide a short distinct event name',
	ProductValidation_EmptyShortLink = 'Short link can\'t be empty',
	ProductValidation_ShortLinkExists = 'The link you have chosen already exists, please choose another link',
	ProductValidation_EmptyDescription = 'Please provide a description for your event',
	ProductValidation_LongXeroMessage = '',
	ProductValidation_EmptyStartDate = 'Please provide the start date and time of your event',
	ProductValidation_EmptyEndDate = 'Please provide the end date and time of your event',
	ProductValidation_StartDateGreaterThanEndDate = 'Please make sure the start date of your event comes before the end date',
	ProductValidation_NoSchedules = 'Please provide the dates for your event series',
	OrganiserValidation_EmptyWebsiteUrl = 'Organiser website url is empty',
	OrganiserValidation_EmptyName = 'Please provide the name of the host for your event',
	OrganiserValidation_EmptyEmail = 'Please provide the event host’s email address',
	/* eslint-disable-next-line max-len */
	OrganiserValidation_NotUniqueName = ' A profile with this name already exists in your account. Please select that profile to use, or create a new profile with a different name',
	OrganiserValidation_EmptyFacebookUrl = 'Organiser Facebook URL is empty',
	OrganiserValidation_InvalidEmail = 'Please make sure email address is in the correct format, e.g. example@email.com',
	PictureValidation_EmptyDescription = '',
	PictureValidation_IncorrectItemTypeId = '',
	PictureValidation_IncorrectContentType = '',
	PictureValidation_IncorrectImageSize = '',
	/* eslint-disable-next-line max-len */
	PictureValidation_InvalidMimeType = 'The image being uploaded is not in the correct format or is corrupted. Please remove the image and upload a new one.',
	PictureValidation_EmptyName = '',
	ProductDealValidation_LongDescription = 'Ticket description is too long',
	ProductDealValidation_ScanninValidStartDateGreaterThanEndDate = 'Ticket scanning valid start date greater than end date',
	ProductDealValidation_LongName = 'Ticket name is too long',
	ProductDealValidation_EmptyName = 'Ticket name is empty',
	ProductDealValidation_InvalidQuantity = 'Invalid quantity has been set to the ticket. Should be greater or equal to 0',
	ProductDealValidation_StartDateGreaterThanEndDate = 'Ticket start date greater than end date',
	ProductDealValidation_NeedAtLeastOneCategory = 'At least one category should be checked per each ticket',
	ProductValidation_HasSoldTickets = 'You can\'t change schedule when tickets are already sold',
	ProductValidation_CancellationIsNotAllowed = 'Cancellation is not allowed',
	ProductValidation_SubmitForApprovalNotAllowed = 'Submit for approval is not allowed for this event',

	CsvValidationException = 'Oops! This file is in wrong format',
	InvalidFileExtension = 'Oops! This file must have .csv extension',
	InvalidUploadedCsvFileSize = 'Oops! This file size must be less than 5 Mb',
	UnknownCsvParseError = 'Oops! Something went wrong with file parsing',
	LinkCampaignUrlAlreadyExist = 'The URL you\'ve chosen already exists. Please choose another URL',

	FacebookOfficialEventValidation_CoverPhoto = 'A cover image is required in order to upload your event to Facebook.',
	FacebookOfficialEventValidation_Name = 'A name is required for your event',
	FacebookOfficialEventValidation_Description = 'A description is required for your event',

	ScheduleItemSeatsBeingBooked = 'Seats are currently being booked for this schedule. Please try again in a little while',
	AddScheduleTicketsAlreadyBooked = 'Cannot add schedule as one or more tickets have been booked',
	ScheduleItemAlreadyHasPurchases = 'Cannot delete schedule item as tickets have been booked on it',

	ManageOrderAccessDeneied = 'You are not the owner of this order.',
	ScheduleProductMismatch = 'Schedule items not found.',
	UpdateManageBookingError = 'One or more tickets has already been checked in.',
	ScheduleFullCannotTransfer = 'There are not enough tickets available on the new selected date.',

	MandrillApiError = 'There was an error sending Quicket mail templates to Mandrill. Please check your API key is correct and try again',
	PurchaseNotOwnedByUser = 'You do not have access to these tickets.',
	RegistrationNotOwnedByUser = 'You do not have access to this registration.',

	ProductQuestionDuplicate = 'A question already exists with that name.',

	PurchaseCapacityError = 'One or more of the ticket types in the purchase are sold out',

	ProductCreationOrder = 'You need to save the previous step before proceeding.'
}
