import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LinkCampaignsService } from '@app/services/link-campaigns/link-campaigns.service';
import { StoreService } from '@app/services/store/store.service';
import { AddEventLinkCampaign } from '@app/store/actions/event/event.actions';
import { EventActionsConstants } from '@app/store/actions/event/event.actions.constants';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs';

@Component({
	selector: 'app-add-link-campaign-modal',
	templateUrl: './add-link-campaign-modal.component.html',
	styleUrls: ['./add-link-campaign-modal.component.sass'],
})
export class AddLinkCampaignModalComponent implements OnInit {

	isLoading = false;
	linkCampaignsForm: UntypedFormGroup;
	urlTooltip: string;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		public dialogRef: MatDialogRef<AddLinkCampaignModalComponent>,
		private linkCampaignsService: LinkCampaignsService,
		private store: StoreService,
		private actions$: Actions,
		@Inject(MAT_DIALOG_DATA) public data: {
			isMobile: boolean;
			eventId: number;
			isEvent: boolean;
		}
	) { }

	ngOnInit(): void {
		this.linkCampaignsForm = this.linkCampaignsService.createLinkCampaignForm();

		this.urlTooltip = this.data.isEvent ?
			'This will appear at the end of your event link. A random URL will be generated if no URL is provided.' :
			'This will appear at the end of your fundraiser link. A random URL will be generated if no URL is provided.';


		this.actions$
			.pipe(
				ofType(
					EventActionsConstants.ADD_EVENT_LINK_CAMPAIGN_SUCCESS,
					EventActionsConstants.ADD_EVENT_LINK_CAMPAIGN_FAILED
				),
				takeUntil(this.destroyed$)
			)
			.subscribe(({ type }: { type: string }) => {
				if (type === EventActionsConstants.ADD_EVENT_LINK_CAMPAIGN_SUCCESS) {
					this.dialogRef.close();
				}
				this.isLoading = false;
			});
	}


	handleCancel() {
		this.dialogRef.close();
	}

	handleAddCampaign() {
		this.isLoading = true;
		this.store.dispatch(
			new AddEventLinkCampaign({
				eventId: this.data.eventId,
				campaign: this.linkCampaignsForm.getRawValue(),
			}));
	}

	fromForm(controlKey: string): AbstractControl{
		if (this.linkCampaignsForm) {
			return this.linkCampaignsForm.get(controlKey);
		}
		return null;
	}

	getError(errors: { [key: string]: string }) {
		return  errors && errors[Object.keys(errors)[0]];
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
