import { FormControl } from '@angular/forms';

export class Event {
	id: number = null;
	name: string = null;
	description: string = null;
	url: string = null;
	imageUrl: string = null;
	dateCreated: string = null;
	lastModified: string = null;
	startDate: string = null;
	endDate: string = null;
	venue: Venue = new Venue();
	locality: Locality = new Locality();
	organiser: Organiser = new Organiser();
	categories: any[] = [];
	tickets: Ticket[] = [];
	schedules: any[] = [];
	refundFeePayableBy: number = null;
}

export class Venue {
	id: number = null;
	name: string = null;
	addressLine1: string = null;
	addressLine2: string = null;
	latitude: number = null;
	longitude: number = null;
}

export class Locality {
	levelOne: string = null;
	levelTwo: string = null;
	levelThree: string = null;
}

export class Organiser {
	id: number = null;
	name: string = null;
	phone: string = null;
	mobile: string = null;
	facebookUrl: string = null;
	twitterHandle: string = null;
	hashTag: string = null;
	organiserPageUrl: string = null;
}

export class Ticket {
	id: number = null;
	name: string = null;
	soldOut: boolean = null;
	provisionallySoldOut: boolean = null;
	price: number = null;
	salesStart: string = null;
	salesEnd: string = null;
	description: string = null;
	donation: boolean = null;
	vendorTicket: boolean = null;
}


export class Guest {
	orderId: number = null;
	ticketId: number = null;
	barcode: number = null;
	dateAdded: string = null;
	ticketType: string = null;
	ticketTypeId: number = null;
	seatNumber: string = null;
	discountCode: string = null;
	discountAmount: number = null;
	checkedIn: string = null;
	checkinDate: string = null;
	checkedInBy: string = null;
	complimentary: string = null;
	price: number = null;
	amountPaid: number = null;
	valid: boolean = null;
	transferredToOrderId: string = null;
	frozen: boolean = null;
	ticketInformation: TicketInformation = new TicketInformation();
}

export class TicketInformation {
	firstName: string = null;
	lastName: string = null;
	email: string = null;
	pricePaid: string = null;
}

export class OrderAction {
	editAttendeeInfo: string = null;
	printTickets: string = null;
}

export class QuestionAnswer {
	questionId: number = null;
	question: string = null;
	answer: string = null;
	controlTypeId: string = null;
}

export class Order {
	eventId: number = null;
	eventName: string = null;
	orderId: number = null;
	reference: string = null;
	userId: number = null;
	email: string = null;
	dateAdded: string = null;
	amount: number = null;
	isComplimentary: boolean = null;
	paymentState: string = null;
	paymentType: string = null;
	paymentDate: string = null;
	isVended: boolean = null;
	paymentCollectionBy: string = null;
	canRefund: boolean = null;
	encrypt: string = null;
	totalGuests: number = null;
	guests: Guest[] = [];
	actions: OrderAction = new OrderAction();
	isTransferred: boolean = null;
	questionAnswers: QuestionAnswer[] = [];
	teamName: string = null;
	hasRefundProtect: boolean = null;
	refundRequiresBankAccount: boolean = null;
	refundFee: number = null;
	currencySymbol: string = null;
	paid: boolean = null;
}

export class ViewOrder extends Order {
	id: number = null;
	cartId: number = null;
	cartTotal: number = null;
	amountProcessed: number = null;
	isMultiOrderCart: boolean = null;
	gateways: ViewOrderGateway[] = [];
	productPaymentGatewayDetailsLinkId: number = null;
}

export class ViewOrderGateway {
	name: string = null;
	productPaymentGatewayDetailsLinkId: number = null;
	active: boolean = null;
}

export interface ChangedTicketTypes {
	newTicketTypeIds: string;
	ticketIds: string;
}

export interface CancelTicketsRequest {
	ticketIds: number[];
	bankAccount: RefundBankAccount;
	refundFeesPayableBy: RefundPayee;
	makePayment: boolean;
}

export class RefundBankAccount {
	branchCode = '';
	bankName = '';
	accountHolder = '';
	accountNumber = '';
	accountType = 'CHEQUE';
}

export interface TicketTypeForm {
	ticketId: FormControl<number>;
	barcode: FormControl<number>;
	ticketType: FormControl<string>;
	ticketTypeId: FormControl<number>;
	oldTicketType: FormControl<string>;
	newTicketTypeId: FormControl<number>;
	ticketHolder: FormControl<string>;
	amount: FormControl<number>;
	refundAmount: FormControl<number>;
	isValid: FormControl<boolean>;
	frozen: FormControl<boolean>;
}

export interface BankAccountFormControl {
	bankName: FormControl<string>;
	branchCode: FormControl<string>;
	accountHolder: FormControl<string>;
	accountNumber: FormControl<string>;
	accountType: FormControl<string>;
}

export interface BankAccountForm {
	bankName: string;
	branchCode: string;
	accountHolder: string;
	accountNumber: string;
	accountType: string;
}

export enum RefundPayee {
	BUYER = 1,
	ORGANISER = 2,
	US = 3
}

export enum PaymentCollectionBy {
	ORGANISER = 'Organiser',
	QUICKET = 'Quicket',
	NONE = 'None'
}

export enum OrderModalStep {
	DETAILS = 1,
	CONFIRMATION = 2,
	BANK_DETAILS = 3
}

export enum PaymentState {
	PAID = 'Paid',
	TRANSFER = 'Transfer',
	COMPLIMENTARY = 'Complimentary',
	RSVP_ATTENDING = 'RSVP Attending'
}
