import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import * as ticketsActions from '../actions/tickets/tickets.actions';
import { AppState } from '@app/models/store.model';
import { cloneDeep } from 'lodash';
import { mapEventFrom, chartCategoryToSeatingCategory } from '@app/utils/mappers/event';
import { Go } from '../actions/router/router.actions';
import { TicketsActionsConstant as constants } from '../actions/tickets/tickets.constants';
import { getResponseErrors } from '@app/utils/Response';
import { eventTickets } from '../selectors/event.selector';
import { withLatestFrom, map, catchError, mergeMap, switchMap } from 'rxjs/operators';
import { TicketsService } from '@app/services/tickets/tickets.service';
import { setTimezoneFrom } from '@app/utils/Date';
import { GetUserDesignerKey } from '@app/store/actions/user/user.actions';
import { concat, throwError } from 'rxjs';
import { normalize } from 'normalizr';
import { eventScheme } from '@app/schemes/event.schema';
import { defaultDashboardRoute } from '@app/services/url/url.service';
import { EventDomainAPI } from '@app/api/domains/event';
import { ofTypeExt, handleSuccessRequest, handleFailedRequest } from '@app/utils/Store';

@Injectable()
export class TicketEffects {
	constructor(
		private action$: Actions,
		private store$: Store<AppState>,
		private ticketService: TicketsService,
		private api?: EventDomainAPI
	) {}

	getTicketCategories$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<ticketsActions.GetSeatingCategories>(constants.GET_SEATING_CATEGORIES),
			switchMap(({ action, payload: { eventId, chartId } }) =>
				this.api.getTicketCategories(eventId, chartId).pipe(
					handleSuccessRequest(
						({
							payload: {
								categories: { list },
							},
						}) => {
							const categories = list.map(({ label, key: seatsIOKey, id }) => ({
								label,
								seatsIOKey,
								capacity: 0,
								id,
							}));
							return new ticketsActions.GetSeatingCategoriesSuccess({
								eventId,
								chartId,
								entity: {
									seatingCategories: chartCategoryToSeatingCategory(categories, chartId),
								},
							});
						}
					),
					catchError(
						handleFailedRequest(
							new ticketsActions.GetSeatingCategoriesFailed({
								msg: 'can\'t get seating categories',
								action,
							})
						)
					)
				)
			)
		)
	);

	getCurrencies$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<ticketsActions.GetCurrencies>(constants.GET_CURRENCIES),
			switchMap(({ action, payload: { productType } }) =>
				this.api.getCurrencies(productType).pipe(
					handleSuccessRequest(
						({ payload: currencies }) =>
							new ticketsActions.GetCurrenciesSuccess({
								currencies,
								productType,
							})
					),
					catchError(
						handleFailedRequest(
							new ticketsActions.GetCurrenciesFailed({
								msg: 'can\'t get currencies',
								action,
							})
						)
					)
				)
			)
		)
	);

	getEventTickets$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<ticketsActions.GetEventTickets>(constants.GET_EVENT_TICKETS),
			switchMap(({ action, payload: { id } }) =>
				this.api.getEventTickets(id).pipe(
					mergeMap((value) => {
						if (value.isSuccess) {
							const product = mapEventFrom(value.payload);
							const {
								productDetails: { seatsIOChartKey },
								seatingCategories,
								questions,
							} = value.payload;
							const {
								ticketsDetails: { seatingChartId },
							} = product;
							const productType = product.details.productType;
							const { entities: normalizedEntity } = normalize(product, eventScheme);

							return concat([
								new ticketsActions.GetEventTicketsSuccess({
									id,
									normalizedEntity,
									entity: {
										original: {
											seatingCategories: chartCategoryToSeatingCategory(seatingCategories, seatingChartId),
											seatsIOChartKey,
										},
										questions,
										seatingCategories: chartCategoryToSeatingCategory(seatingCategories, seatingChartId),
									},
								}),
								new ticketsActions.GetCurrencies({ productType }),
								new GetUserDesignerKey({ eventId: id }),
							]);
						} else {
							return throwError(getResponseErrors(value));
						}
					}),
					catchError(
						handleFailedRequest(
							new ticketsActions.GetEventTicketsFailed({
								msg: 'can\'t get event tickets',
								action,
								critical: true,
								meta: {
									extraAction: new Go({ path: [defaultDashboardRoute] }),
									extraActionTitle: 'Go to dashboard',
									allowClose: false,
								},
							})
						)
					)
				)
			)
		)
	);

	stopTicketSales$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<ticketsActions.StopTicketSales>(constants.STOP_TICKET_SALES),
			switchMap(({ action, payload: { eventId } }) =>
				this.api.endTicketSales(eventId).pipe(
					withLatestFrom(this.store$.pipe(select(eventTickets()))),
					map(([value, eTickets]) => {
						if (value.isSuccess) {
							const ticketsDetails = this.ticketService.proceedEndTicketSales(
								cloneDeep(eTickets.ticketsDetails),
								value.payload.length ? setTimezoneFrom(value.payload[0].salesEnd, eTickets.details.timezone) : null
							);

							const { entities: normalizedEntity } = normalize({ ticketsDetails, details: eTickets.details }, eventScheme);

							return new ticketsActions.StopTicketSalesSuccess({
								eventId,
								normalizedEntity,
							});
						} else {
							throw getResponseErrors(value);
						}
					}),
					catchError(
						handleFailedRequest(
							new ticketsActions.StopTicketSalesFailed({
								msg: 'can\'t stop ticket sales',
								action,
							})
						)
					)
				)
			)
		)
	);

	markTicketsAsSoldOut$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<ticketsActions.MarkAsSoldOut>(constants.MARK_TICKETS_AS_SOLD_OUT),
			switchMap(({ action, payload: { eventId } }) =>
				this.api.markTicketsAsSoldOut(eventId).pipe(
					withLatestFrom(this.store$.pipe(select(eventTickets()))),
					map(([value, eTickets]) => {
						if (value.isSuccess) {
							const ticketsDetails = this.ticketService.proceedMarkAsSoldOut(cloneDeep(eTickets.ticketsDetails));

							const { entities: normalizedEntity } = normalize({ ticketsDetails, details: eTickets.details }, eventScheme);

							return new ticketsActions.MarkAsSoldOutSuccess({
								eventId,
								normalizedEntity,
							});
						} else {
							throw getResponseErrors(value);
						}
					}),
					catchError(
						handleFailedRequest(
							new ticketsActions.MarkAsSoldOutFailed({
								msg: 'can\'t mark tickets as sold out',
								action,
							})
						)
					)
				)
			)
		)
	);

	// #region getTicketSettings
	GetTicketSettings = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<ticketsActions.GetTicketSettings>(constants.GET_TICKET_SETTINGS),
			mergeMap(({ action, payload }) =>
				this.api.getTicketSettings(payload.id).pipe(
					mergeMap(({ payload: settings }) => [
						new ticketsActions.GetTicketSettingsSuccess({
							id: payload.id,
							settings: settings,
						}),
					]),
					catchError(
						handleFailedRequest(
							new ticketsActions.GetTicketSettingsFailed({
								msg: 'can\'t get ticket settings',
								action,
								critical: true,
								meta: {
									extraAction: new Go({ path: [defaultDashboardRoute] }),
									extraActionTitle: 'Go to dashboard',
									allowClose: false,
								},
							})
						)
					)
				)
			)
		)
	);
	// #endregion

	// #region updateTicketSettings
	UpdateTicketSettings = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<ticketsActions.UpdateTicketSettings>(constants.UPDATE_TICKET_SETTINGS),
			mergeMap(({ action, payload }) =>
				this.api.updateTicketSettings(payload.id, payload.settings).pipe(
					mergeMap(({ payload: settings }) => [
						new ticketsActions.UpdateTicketSettingsSuccess({
							id: payload.id,
							settings: settings,
						}),
					]),
					catchError(
						handleFailedRequest(
							new ticketsActions.UpdateTicketSettingsFailed({
								msg: 'can\t update ticket settings',
								action,
								critical: true,
							})
						)
					)
				)
			)
		)
	);
	// #endregion
}
