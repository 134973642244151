import { EventOrganiserProfile } from './profile.model';
import { Venue,
	EventImage,
	ProductCreationStep,
	ProductStatus,
	EventFeatureOptions,
	EventFeatureAnswers,
	FundraiserCertificate } from './event.model';
import { Currency, ChartCategory, SubscriptionRecurringPeriod, ShowTicketStockType } from './ticket.model';
import { SchemeID } from './dataSchema.model';
import { EventQuestion } from '@app/models/event.model';
import { ImageString } from '@app/services/image/image.service';
import { Schedule } from './schedule.model';

export class ProductDetails {
	name: string;
	description: string;
	startDate: string;
	endDate: string;
	isOneDayEvent: boolean;
	isOnline: boolean;
	id: number;
	seatsIOChartKey: string;
	maxCapacity: number;
	maxTicketsPerOrder: number;
	donationTarget: number;
	fbEventId: string;
	fbPicture: string;
	useSeatNumbers: boolean;
	allowRefunds: boolean;
	serviceFeeForHost: boolean;
	commissionForHost: boolean;
	public: boolean;
	formatId: number;
	categoryId: number;
	subCategoryId: number;
	ianaTimezone: string;
	showMap: boolean;
	hasVendors: boolean;
	ticketStubDescription: string;
	serviceFee: number;
	commission: number;
	taxRateId: number;
	hasSoldTickets: boolean;
	isEventFinished: boolean;
	allowNoCategories: boolean;
	bankAccountId: SchemeID;
	donationIncludeTickets: boolean;
	productType: ProductType;
	indefinite: boolean;
	originalImage: string;
	imageString: ImageString | string;
	thumbnail: string;
	hasStreaming: boolean;
	croppedImage: ImageString | string;
	hasOnlineContent: boolean;
	eventEndDateChanged: boolean;
	fundraiserCertificate: FundraiserCertificate;
	pdfLogoUrl: string;
	allowRefundProtect: boolean;
	displayBannerInEmails: boolean;
	lockedOnPrivate: boolean;
	creationStep: ProductCreationStep;
}

export interface ProductMetadata {
	name: string;
	aclItems: any[];
	id: number;
	accountingVersion: number;
	encrypt: string;
	isOwner: boolean;
	isSeated: boolean;
	creationStep: ProductCreationStep;
	productStatus: ProductStatus;
	isNewPageVersion: boolean;
	productType: ProductType;
	email: string;
	fullName: string;
	phone: string;
	userId: SchemeID;
}

export class Product {
	productDetails: ProductDetails = null;
	organiser: EventOrganiserProfile = null;
	schedules: Schedule[] = [];
	venue: Venue = null;
	questions: EventQuestion[] = [];
	currency: Currency;
	tickets: ProductDeal[] = [];
	seatingCategories: ChartCategory[] = [];
	settings: {
		alertMessage: string;
		allowCopyFromAbove: boolean;
		allowTicketEditing: boolean;
		lockTransferredTickets: boolean;
		cutOffTicketTransfer: boolean;
		expressCheckoutEnabled: boolean;
	};
	image: EventImage;
	action: ProductCreationAction;
	eventFeatureOptions: EventFeatureOptions[] = [];
	eventFeatureAnswers: EventFeatureAnswers[] = [];
}

export interface ProductDeal {
	id: SchemeID;
	name: string;
	description: string;
	ticketStubInformation: string;
	price: string;
	sortOrder: number;
	isTypeHidden: boolean;
	minItemsCanSell: number;
	maxItemsCanSell: number;
	maxTicketsPerUser: number;
	maxTicketsPerPurchase: number;
	salesStart: string;
	salesEnd: string;
	scanningValidFrom: string;
	scanningValidTo: string;
	incrementOf: number;
	donation: boolean;
	hasSales: boolean;
	atTheDoor: boolean;
	autoCheckin: boolean;
	canVend: boolean;
	scannerColour: string;
	categories: ChartCategory[];
	imageString: string;
	image: EventImage;
	questions: ProductDealQuestion[];
	linkedToStream: boolean;
	hideUntil: string;
	hideAfter: string;
	benefits: string[];
	recurringPeriod: SubscriptionRecurringPeriod;
	subscriptionPerkTypes: number[];
	lowerLimit: number;
	showTicketStockType: ShowTicketStockType;
}

export interface ProductDealQuestion {
	id: SchemeID;
	productQuestionId: number;
	required: boolean;
}

export class ProductSchedule {
	adding = true;
	date_days: string[] = [];
	date_repeatfrom: string = null;
	date_repeatto: string = null;
	date_string: string = null;
	date_timeday: string = null;
	date_timefrom: string = null;
	date_timeto: string = null;
	date_type: number = null;
	deleted = false;
	editing = false;
	endOccurrences: string[] = [];
	hasSoldTickets = false;
	hidden = false;
	hiddenValues: boolean[] = [];
	key: string = null;
	month_repeating_type = 1;
	occurrence_count = 0;
	occurrences: string[] = [];
	scheduleItemNames: string[] = [];
	top_date_string: string = null;
	monthly_repeat_day?: string; // month 3rd 1st repeating type
	monthly_repeat_day_of_month?: string; // week
}

export enum ProductCreationAction {
	CREATE,
	UPDATE,
}

export enum ProductType {
	Event = 1,
	Fundraiser = 2,
	OrganiserSubscriptions = 3,
}

export enum ProductVenueOption {
	Add = 1,
	Previous = 2,
	None = 3,
}

export enum invitationMethodEnum {
	usingPreviousContactList = 'Using a previous contact List',
	uploadingCSVFile = 'Uploading a CSV File',
	addingContactsManually = 'Adding contacts manually',
}
