import {
	NgModule,
	Component,
	ElementRef,
	AfterViewInit,
	Input,
	Output,
	EventEmitter,
	ContentChild,
	forwardRef,
	Provider
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule, Header } from 'primeng/api';
import { DomHandler } from 'primeng/dom';
import ImageResize from 'quill-image-resize-module';
import { ImageUpload } from 'quill-image-upload';
import { ImageDrop } from 'quill-image-drop-module';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { EventImage } from '@app/models/event.model';
import { ImageUploadError, ResponseScheme } from '@app/models/http.model';

declare const Quill;

export interface EditorImageUploadOptions {
	url: string;
	method: 'POST' | 'PUT';
	headers: { [key: string]: string };
	callbackOK?: (serverResponse: ResponseScheme<EventImage>, next: ((url: string) => void)) => void;
	callbackKO?: (serverError: ImageUploadError) => void;
}

export const EDITOR_VALUE_ACCESSOR: Provider = {
	provide: NG_VALUE_ACCESSOR,
	// tslint:disable-next-line:no-use-before-declare
	useExisting: forwardRef(() => EditorComponent),
	multi: true,
};

@Component({
	selector: 'p-editor',
	template: `
		<div [ngClass]="'ui-widget ui-editor-container ui-corner-all'" [class]="styleClass">
			<div class="ui-editor-toolbar ui-widget-header ui-corner-top" *ngIf="toolbar">
				<ng-content select="p-header"></ng-content>
			</div>
			<div class="ui-editor-toolbar ui-widget-header ui-corner-top" *ngIf="!toolbar">
				<span class="ql-formats">
					<select class="ql-header">
					  <option value="1">Heading</option>
					  <option value="2">Subheading</option>
					  <option selected>Normal</option>
					</select>
					<select class="ql-font">
					  <option selected>Sans Serif</option>
					  <option value="serif">Serif</option>
					  <option value="monospace">Monospace</option>
					</select>
				</span>
				<span class="ql-formats">
					<button class="ql-bold" aria-label="Bold"></button>
					<button class="ql-italic" aria-label="Italic"></button>
					<button class="ql-underline" aria-label="Underline"></button>
				</span>
				<span class="ql-formats">
					<select class="ql-color"></select>
					<select class="ql-background"></select>
				</span>
				<span class="ql-formats">
					<button class="ql-list" value="ordered" aria-label="Ordered List"></button>
					<button class="ql-list" value="bullet" aria-label="Unordered List"></button>
					<select class="ql-align">
						<option selected></option>
						<option value="center"></option>
						<option value="right"></option>
						<option value="justify"></option>
					</select>
				</span>
				<span class="ql-formats">
					<button class="ql-link" aria-label="Insert Link"></button>
					<button class="ql-image" aria-label="Insert Image"></button>
					<button class="ql-code-block" aria-label="Insert Code Block"></button>
				</span>
				<span class="ql-formats">
					<button class="ql-clean" aria-label="Remove Styles"></button>
				</span>
			</div>
			<div class="ui-editor-content" [ngStyle]="style"></div>
		</div>
	`,
	providers: [DomHandler, EDITOR_VALUE_ACCESSOR],
})
export class EditorComponent implements AfterViewInit, ControlValueAccessor {
	/* eslint-disable @angular-eslint/no-output-on-prefix */
	@Output() onTextChange: EventEmitter<any> = new EventEmitter();

	@Output() onSelectionChange: EventEmitter<{
		range;
		oldRange;
		source;
	}> = new EventEmitter();

	@ContentChild(Header) toolbar;

	@Input() style: any;

	@Input() styleClass: string;

	@Input() placeholder: string;

	@Input() imageUploadHandler: EditorImageUploadOptions;

	@Input() formats: string[];

	@Output() onInit: EventEmitter<{ editor: any }> = new EventEmitter();

	value: string;

	_readonly: boolean;

	quill: any;

	onModelChange: Function = () => {};

	onModelTouched: Function = () => {};
	/* eslint-enable @angular-eslint/no-output-on-prefix */
	constructor(
		public el: ElementRef
	) { }

	ngAfterViewInit() {
		const editorElement = DomHandler.findSingle(this.el.nativeElement , 'div.ui-editor-content');
		const toolbarElement = DomHandler.findSingle(this.el.nativeElement , 'div.ui-editor-toolbar');
		const Image = Quill.import('formats/image');
		const Link = Quill.import('formats/link');
		Image.className = 'img-responsive';
		Quill.register(Image, true);

		Quill.register('modules/imageResize', ImageResize);
		Quill.register('modules/imageDrop', ImageDrop);

		const modules = {
			toolbar: toolbarElement,
			imageResize: {
				modules: [ 'Resize', 'DisplaySize' ],
			},
			imageDrop: false,
		};

		if (this.imageUploadHandler) {
			Quill.register('modules/imageUpload', ImageUpload);
			modules['imageUpload'] = this.imageUploadHandler;
		}

		this.quill = new Quill(editorElement, {
			modules,
			placeholder: this.placeholder,
			readOnly: this.readonly,
			theme: 'snow',
			formats: this.formats,
		});


		Link.sanitize = function(url) {
			// quill by default creates relative links if scheme is missing.
			if (!url.startsWith('http://') && !url.startsWith('https://')) {
				return `http://${url}`;
			}
			return url;
		};

		if (this.value) {
			if (!this.readonly) {
				this.quill.disable();
			}

			this.quill.pasteHTML(this.value);

			if (!this.readonly) {
				this.quill.enable();
			}
		}

		this.quill.on('text-change', (delta, oldContents, source) => {
			if (source === 'user') {
				const html = editorElement.children[0].innerHTML;
				const text = this.quill.getText().trim();

				/* eslint-disable-next-line max-len */
				const pattern = /<iframe|<img src|<img class="img-responsive" src="http|<img class="img-responsive" src="\/content|<img class="img-responsive" src="\/\/images/gm;

				const hasEmbededContent = pattern.test(html);

				this.onTextChange.emit({
					htmlValue: html,
					textValue: text,
					delta,
					source,
				});

				const value = text || hasEmbededContent
					? html
					: null;

				this.onModelChange(value);
				this.onModelTouched();
			}
		});

		this.quill.clipboard.addMatcher('IMG', (node, delta) => {
			const Delta = Quill.import('delta');
			return new Delta().insert('');
		});
		this.quill.clipboard.addMatcher('PICTURE', (node, delta) => {
			const Delta = Quill.import('delta');
			return new Delta().insert('');
		});

		this.quill.on('selection-change', (range, oldRange, source) => {
			this.onSelectionChange.emit({
				range: range,
				oldRange: oldRange,
				source,
			});
		});

		this.onInit.emit({
			editor: this.quill,
		});
	}

	writeValue(value: any): void {
		this.value = value;

		if (this.quill) {
			if (value) {
				this.quill.pasteHTML(value);
			} else {
				this.quill.setText('');
			}
		}
	}

	registerOnChange(fn: Function): void {
		this.onModelChange = fn;
	}

	registerOnTouched(fn: Function): void {
		this.onModelTouched = fn;
	}

	getQuill() {
		return this.quill;
	}

	@Input() get readonly(): boolean {
		return this._readonly;
	}

	set readonly(val: boolean) {
		this._readonly = val;

		if (this.quill) {
			if (this._readonly) {
				this.quill.disable();
			} else {
				this.quill.enable();
			}
		}
	}
}

@NgModule({
	imports: [CommonModule],
	exports: [EditorComponent, SharedModule],
	declarations: [EditorComponent],
})
export class EditorModule { }
