<div class="l-container">
	<h3 *ngIf="isMobile">{{ title }}</h3>
	<app-web-content-header
		class="w-100"
		[headerContent]="headerContent"
		[isMobile]="isMobile"
		[reference]="resaleTickets.purchaseReference"
		[id]="purchaseId"
	></app-web-content-header>
	<div
		class="l-content"
	>
	<div class="g-info-block py-4">
		<mat-icon class="g-info-block-icon">info</mat-icon>
		<p class="g-info-block-msg mb-0">
			Please note only South African bank accounts are supported.
		</p>
	</div>
		<div class="g-info-block py-4" *ngIf="!(resaleTickets.allowPublic && resaleTickets.allowPrivate)">
			<mat-icon class="g-info-block-icon">info</mat-icon>
			<p class="g-info-block-msg mb-0">
				The event organiser has only allowed {{resaleTickets.allowPublic ? 'public' : 'private'}} resale for this event.
			</p>
		</div>
		<div
			class="l-table-container"
			*ngIf="validTicketsForResale.length; else showEmptyTicket"
		>
			<mat-progress-bar
				mode="indeterminate"
				*ngIf="isListingUpdating"
			></mat-progress-bar>
			<table
				mat-table
				[dataSource]="validTicketsForResale"
				class="l-table mb-3"
				[ngClass]="{ 'web': !isMobile, 'mobile': isMobile}"
			>
				<ng-container matColumnDef="select">
					<th
						mat-header-cell
						*matHeaderCellDef
						class="l-first-child"
					>
						<div [ngClass]="{ 'd-flex justify-content-start flex-wrap': isMobile}">
							<mat-checkbox
								id="select-all-checkbox"
								(change)="handleSelectAll()"
								[checked]="isSelectAllChecked"
							></mat-checkbox>
							<span *ngIf="!isMobile">Select All</span>
						</div>
					</th>
					<td
						id="select-resale-ticket"
						mat-cell
						*matCellDef="let ticket; let i = index"
						class="l-first-child"
					> <mat-checkbox
							id="select-ticket-{{i + 1 }}"
							(change)="handleCheckboxChange(ticket)"
							[checked]="isSelected(ticket)"
						></mat-checkbox> </td>
				</ng-container>
				<ng-container matColumnDef="ticketType">
					<th
						mat-header-cell
						*matHeaderCellDef
						class="l-medium"
					> {{isMobile ? 'Type' : 'Ticket Type'}} </th>
					<td
						mat-cell
						*matCellDef="let ticket; let i = index"
						id="ticket-type-{{i + 1}}"
					> {{ticket.ticketTypeName}} </td>
				</ng-container>
				<ng-container matColumnDef="price">
					<th
						mat-header-cell
						*matHeaderCellDef
						class="l-small"
					> Price </th>
					<td
						mat-cell
						*matCellDef="let ticket; let i = index"
						id="price-{{i + 1}}"
					>{{currencySymbol}} {{ticket.price | number :
						'.2-2'}} </td>
				</ng-container>
				<ng-container matColumnDef="ticketHolder">
					<th
						mat-header-cell
						*matHeaderCellDef
						class="l-small"
					> {{isMobile ? 'Holder' : 'Ticket Holder'}} </th>
					<td
						mat-cell
						*matCellDef="let ticket; let i = index"
						id="ticketholder-{{i + 1}}"
					> {{ticket.ticketHolder}} </td>
				</ng-container>
				<ng-container matColumnDef="public">
					<th
						mat-header-cell
						*matHeaderCellDef
					> <span class="d-flex align-items-center">
							Public
							<mat-icon
								#publicTooltip="matTooltip"
								class="l-tooltip-icon"
								(click)="publicTooltip.toggle()"
								matTooltip="If ON, your ticket will be available to the public, if OFF, please provide the email address of the person you wish to receive your ticket"
								matTooltipPosition="right"
							>info
							</mat-icon>
						</span>
					</th>
					<td
						mat-cell
						*matCellDef="let ticket; let i = index"
						id="public-{{i + 1}}"
					> <mat-slide-toggle
							class="standard-toggle"
							[(ngModel)]="ticket.isPublic"
						></mat-slide-toggle> </td>
				</ng-container>
				<ng-container matColumnDef="email">
					<th
						mat-header-cell
						*matHeaderCellDef
						class="l-last-child"
					> Email </th>
					<td
						mat-cell
						*matCellDef="let ticket; let i = index"
						class="l-last-child"
					><input
							id="resale-email-input-{{i + 1}}"
							type="email"
							class="form-control l-input"
							[ngClass]="{'l-input-error': !isEmailValid(ticket.email) && !ticket.isPublic && emailInput.touched}"
							placeholder="jane@doe.com"
							[(ngModel)]="ticket.email"
							[disabled]="ticket.isPublic"
							appConditionalEmailValidator
							[isPublic]="!ticket.isPublic"
							#emailInput="ngModel"
						/>
					</td>
				</ng-container>
				<tr
					mat-header-row
					*matHeaderRowDef="validDisplayedColumns"
				></tr>
				<tr
					mat-row
					*matRowDef="let row; columns: validDisplayedColumns;"
				></tr>
			</table>
		</div>
		<ng-template #showEmptyTicket>
			<div class="l-empty-state">
				<h3 class="mb-3">Nothing Available For Resale</h3>
				<div class="mb-3">None of your tickets are eligible for resale</div>
			</div>
		</ng-template>

		<div *ngIf="invalidTicketsForResale.length">
			<mat-accordion class='mb-4'>
				<mat-expansion-panel expanded="false">
					<mat-expansion-panel-header>
						<span>
							<strong>{{invalidTicketsForResale.length}} x invalid
								{{invalidTicketsForResale.length === 1 ? 'ticket' :
								'tickets'}}</strong>
						</span>
					</mat-expansion-panel-header>
					<div class="l-table-container">
						<table
							mat-table
							[dataSource]="invalidTicketsForResale"
							class="l-table"
							[ngClass]="{ 'web': !isMobile, 'mobile': isMobile}"
						>
							<ng-container matColumnDef="ticketType">
								<th
									mat-header-cell
									*matHeaderCellDef
									class="l-first-child l-medium"
								> {{isMobile ? 'Type' : 'Ticket Type'}} </th>
								<td
									mat-cell
									*matCellDef="let ticket; let i = index"
									class="l-first-child"
									id="invalid-ticket-type-{{ i+ 1}}"
								> {{ticket.ticketTypeName}} </td>
							</ng-container>
							<ng-container matColumnDef="price">
								<th
									mat-header-cell
									*matHeaderCellDef
									class="l-small"
								> Price </th>
								<td
									mat-cell
									*matCellDef="let ticket; let i = index"
									id="invalid-price-{{ i+ 1}}"
								>{{currencySymbol}} {{ticket.price |
									number : '.2-2'}} </td>
							</ng-container>
							<ng-container matColumnDef="ticketHolder">
								<th
									mat-header-cell
									*matHeaderCellDef
								> {{isMobile ? 'Holder' : 'Ticket Holder'}} </th>
								<td
									mat-cell
									*matCellDef="let ticket; let i = index"
									id="invalid-ticket-holder-{{ i+ 1}}"
								> {{ticket.ticketHolder}} </td>
							</ng-container>
							<ng-container matColumnDef="reason">
								<th
									mat-header-cell
									*matHeaderCellDef
									class="l-last-child l-large"
								> Reason </th>
								<td
									mat-cell
									*matCellDef="let ticket; let i = index"
									id="invalid-reason-{{ i+ 1}}"
									class="l-last-child"
								> {{ticket.reasonCantResale}} </td>
							</ng-container>

							<tr
								mat-header-row
								*matHeaderRowDef="invalidDisplayedColumns"
							></tr>
							<tr
								mat-row
								*matRowDef="let row; columns: invalidDisplayedColumns;"
							></tr>
						</table>
					</div>
				</mat-expansion-panel>
			</mat-accordion>
		</div>
	</div>
</div>
<app-footer-button
	#footerButton
	class="g-fixed-footer l-footer"
	primaryText="LIST SELECTED"
	[isDisabled]="isFooterButtonDisabled"
	($primaryButton)="handleListTickets()"
	($secondaryButton)="handleNavigationBack()"
	[secondaryText]="'BACK'"
	[isLoading]="isBanksDataFetching || isListingUpdating"
	[isMobile]="isMobile"
	[primaryButtonId]="'list-selected-button'"
	[secondaryButtonId]="'back-button'"
></app-footer-button>