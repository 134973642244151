import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HeaderContent } from '@app/models/shared';
import { StoreService } from '@app/services/store/store.service';
import { Go } from '@app/store/actions/router/router.actions';

@Component({
	selector: 'app-web-content-header',
	templateUrl: './web-content-header.component.html',
	styleUrls: ['./web-content-header.component.sass'],
})
export class WebContentHeaderComponent {
	@Input() headerContent: HeaderContent;
	@Input() smallWidth = false;
	@Input() mediumWidth = false;
	@Input() longWidth = false;
	@Input() fullWidth = false;
	@Input() handleRoutingExternally = false;
	@Input() includeReadMore = false;
	@Input() readMoreText: string;
	@Input() showBreadCrumb = true;
	@Input() showTitle = true;
	@Input() customDescription: string;
	@Input() isMobile = false;

	@Input() id: string;
	@Input() reference: string;
	@Input() additionalText: string;


	@Output() handleExternalNavigation: EventEmitter<CallableFunction> = new EventEmitter();
	@Output() readMore: EventEmitter<void> = new EventEmitter();

	constructor(private store: StoreService) { }

	handleNavigation(routeTo: CallableFunction) {
		if (this.handleRoutingExternally) {
			this.handleExternalNavigation.emit(routeTo);
		} else if (this.id) {
			this.store.dispatch(new Go({ path: [routeTo(this.id)] }));
		} else {
			this.store.dispatch(new Go({ path: [routeTo()] }));
		}
	}

	handleReadMore() {
		this.readMore.emit();
	}
}
