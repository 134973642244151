import * as Money from 'js-money/lib/money';
import { currencies } from './Currencies';

interface Currency {
	symbol: string;
	name: string;
	symbol_native: string;
	decimal_digits: number;
	rouding: number;
	code: string;
	name_plural: string;
}

export default class MoneyPresenter {
	money: Money;
	currency: Currency;
	/**
	 * Creates an instance of MoneyPresenter.
	 * @param {MoneyPresenter} { money: Money || {amount: String, currency: String} }
	 *
	 * @memberof MoneyPresenter
	 */
	constructor(money) {
		this.money = new Money(Number(money.amount), money.currency);
		this.currency = currencies[money.currency];
	}

	/**
	 * @static
	 * @param {Money} firstMoney
	 * @param {Money} secondMoney
	 * @returns
	 *
	 * @memberof MoneyPresenter
	 */
	static subtract(firstMoney, secondMoney) {
		return new MoneyPresenter(new MoneyPresenter(firstMoney).money.subtract(new MoneyPresenter(secondMoney).money)
		).toString();
	}

	/**
	 * @static
	 * @param {Money} firstMoney
	 * @param {Money} secondMoney
	 * @returns
	 *
	 * @memberof MoneyPresenter
	 */
	static add(firstMoney, secondMoney) {
		return new MoneyPresenter(
			new MoneyPresenter(firstMoney).money.add(new MoneyPresenter(secondMoney).money)
		).toString();
	}

	/**
	 * @static
	 * @param {string} currency
	 * @returns
	 *
	 * @memberof MoneyPresenter
	 */
	static addAll(currency, exchangeRates, ...amounts) {
		let total = new Money(0, currency);
		for (let i = 0; i < amounts.length; i++) {
			let nextValue = new MoneyPresenter(amounts[i]).money;
			if (amounts[i].currency !== currency) {
				const diffCurrency = exchangeRates.find(value => value.currency === amounts[i].currency);
				const rate = diffCurrency ? diffCurrency.rate : 1;
				nextValue = new MoneyPresenter({
					...nextValue.divide(Number(rate), Math.round),
					currency,
				}).money;
			}
			total = new MoneyPresenter(total).money.add(nextValue);
		}
		return total;
	}

	/**
	 * @static
	 * @param {string} currency
	 * @returns
	 *
	 * @memberof MoneyPresenter
	 */
	static subtractAll(currency, exchangeRates, ...amounts) {
		let total = new MoneyPresenter(amounts[0]).money;
		for (let i = 1; i < amounts.length; i++) {
			let nextValue = new MoneyPresenter(amounts[i]).money;
			if (amounts[i].currency !== currency) {
				const diffCurrency = exchangeRates.find(value => value.currency === amounts[i].currency);
				const rate = diffCurrency ? diffCurrency.rate : 1;
				nextValue = new MoneyPresenter({
					...nextValue.divide(Number(rate), Math.round),
					currency,
				}).money;
			}
			total = new MoneyPresenter(total).money.subtract(nextValue);
		}
		return total;
	}

	/**
	 * @static
	 * @param {string} code
	 * @returns
	 *
	 * @memberof MoneyPresenter
	 */
	static getSymbol(code) {
		return currencies[code] && currencies[code].symbol_native;
	}

	/**
	 * @static
	 * @param {string} code
	 * @returns
	 *
	 * @memberof MoneyPresenter
	 */
	static getBasicSymbol(code) {
		return currencies[code] && currencies[code].symbol;
	}

	/**
	 * @static
	 * @param {string} code
	 * @returns
	 *
	 * @memberof MoneyPresenter
	 */
	static getDecimalDigits(code) {
		return currencies[code] && currencies[code].decimal_digits;
	}

	/**
	 * @static
	 * @param {string} symbol
	 * @returns
	 *
	 * @memberof MoneyPresenter
	 */
	static getCode(symbol) {
		return Object.keys(currencies)
			.map(key => ({
				...currencies[key],
				currency: key,
			}))
			.find(currency => currency.symbol_native === symbol).currency;
	}

	/**
	 * @static
	 * @param {string[]} exclude
	 * @returns
	 *
	 * @memberof MoneyPresenter
	 */
	static getAllCodes(exclude: string[] = []) {
		return Object.keys(currencies).filter(key => exclude.indexOf(key) === -1).map(key => ({
			code: key,
			symbol: currencies[key].symbol_native,
			name: currencies[key].name,
		}));
	}

	/**
	 * @static
	 * @param {string} code
	 * @returns
	 *
	 * @memberof MoneyPresenter
	 */
	static getCurrencyName(code) {
		return `${currencies[code].name} (${currencies[code].symbol_native})`;
	}

	/**
	 * @param {Bool} isSymbolBased
	 * @returns
	 *
	 * @memberof MoneyPresenter
	 */
	toString(formatter?: Function, isCodeBased?: boolean) {
		if (isCodeBased) {
			return `${this.money.toString()} ${this.money.getCurrency()}`;
		} else {
			return `${this.currency.symbol_native}${formatter ? formatter(this.money.toString(), { useGrouping: true }) : this.money.toString()}`; // eslint-disable-line max-len
		}
	}
}
