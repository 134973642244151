<div
	class="l-container"
	[ngClass]="{'l-mobile-container': isMobile}"
>
	<img
		[src]="eventImage"
		*ngIf="!isMobile"
		class="l-image"
	/>
	<div
		class="l-content"
		[ngClass]="{'flex-column w-100': isMobile, 'web': !isMobile}"
	>
		<div
			*ngIf="isMobile"
			class="mb-3"
		>
			<div class="l-mobile-info-container">
				<div
					id="status"
					class="l-badge"
					[ngClass]="statusClass"
				>
					{{ status }}
				</div>
				<div
					class="l-reference"
					*ngIf="isTicket"
				><strong>{{ reference }}</strong></div>
			</div>
		</div>
		<div class="l-details">
			<div
				id="event-name"
				class='l-name'
			>
				<span
					class="l-title"
					[ngClass]="{ 'l-registration': isRegistration, 'l-tickets': !isRegistration }"
				>{{ productName }}</span>
			</div>
			<div
				*ngIf='isTicket'
				class='l-ticket-content'
			>
				<div
					id="start-date"
					class="l-font"
				>
					<strong>STARTS: </strong>{{ lineOne | formattedDatePipe }}
				</div>
				<div
					id="end-date"
					class="l-font"
				>
					<strong>ENDS: </strong>{{ lineTwo | formattedDatePipe }}
				</div>
				<div
					id="venue"
					*ngIf='!isRegistration'
					class="l-font"
				>
					<span
						class="l-venue"
						[ngClass]="{ 'l-registration': isRegistration, 'l-tickets': !isRegistration }"
					><strong>VENUE: </strong>{{ venue }}</span>
				</div>
				<div
					id="mobile-paid-amount"
					*ngIf='isTicket && !isRegistration && isMobile'
					class="l-font"
				>
					<strong>PAID: </strong><span
						id="price"
						*ngIf="bookingAmount > 0 else freeTickets"
					>{{ currencySymbol }}{{ bookingAmount }}</span>
					<ng-template #freeTickets>
						<span id="price">Free</span>
					</ng-template>
				</div>
			</div>
			<div
				*ngIf='!isTicket'
				class='l-ticket-content'
			>
				<div class="l-font">
					{{ lineOne }}
				</div>
				<div class="l-font">
					<strong (click)='$event.stopPropagation(); handleLinkClick()'>{{ lineTwo }}</strong>
				</div>
			</div>
		</div>
		<div
			class="l-info"
			*ngIf="!isMobile"
		>
			<div class="l-ticket-info-container">
				<div
					id="status"
					class="l-badge"
					[ngClass]="statusClass"
				>
					{{ status }}
				</div>
				<div
					id="reference"
					*ngIf="isTicket"
					class="l-reference"
				>
					<strong>{{ reference }}</strong>
				</div>
				<div *ngIf="isTicket && !isRegistration">
					<strong>PAID: </strong><span
						id="price"
						*ngIf="bookingAmount > 0 else freeTickets"
					>{{ currencySymbol }}{{ bookingAmount }}</span>
					<ng-template #freeTickets>
						<span id="price">Free</span>
					</ng-template>
				</div>
			</div>

			<div
				id="manage-button"
				class="l-link"
			>
				<span class="l-text">{{ manageText }}</span>
				<mat-icon class="l-icon">arrow_forward</mat-icon>
			</div>
		</div>
		<div
			id="manage-button"
			class="l-link mt-3"
			*ngIf="isMobile"
		>
			<span class="l-text">{{ manageText }}</span>
			<mat-icon class="l-icon">arrow_forward</mat-icon>
		</div>
	</div>
</div>