<div [ngClass]="outerClass" class="l-container">
	<label 
		*ngIf="label" 
		[ngClass]="labelClass" 
		class="g-form-label"
	>
		{{label}} 
		<span class="g-orange" *ngIf="required">*</span>
		<mat-icon
			*ngIf="tooltip"
			class="l-tooltip"
			[matTooltip]="tooltip"
			matTooltipPosition="right"
			#phoneFieldTooltip="matTooltip"
			(click)="phoneFieldTooltip.toggle()"
		>
			info
		</mat-icon>
	</label>
	<div class="l-input-container">
		<ngx-intl-tel-input
			class="l-tel-input"
			[preferredCountries]="preferredCountries"
			enableAutoCountrySelect="false"
			searchCountryFlag="true"
			selectFirstCountry="false"
			maxLength="15"
			phoneValidation="true"
			inputId="phone-input-field"
			name="phoneNumber"
			[formControl]="displayFormControl"
		></ngx-intl-tel-input>
	</div>
	<div 
		class="g-error-input"
	>
		<div *ngIf="displayFormControl.errors?.validatePhoneNumber">Invalid phone number.</div>
		<div *ngIf="displayFormControl.errors?.required && !displayFormControl.errors?.validatePhoneNumber">This field is required.</div>
		<div *ngIf="control.errors?.notEventUnique">The organiser requires this answer to be unique across the event.</div>
		<div *ngIf="control.errors?.notUnique">This field needs to be unique. Another of your tickets has this value.</div>
	</div>
</div>
