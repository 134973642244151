<app-my-account-header
	[isMobile]='isMobile'
	pageName="Manage Booking"
	(navigateBack)="handleNavigationBack()"
>
</app-my-account-header>
<div class="g-account-container">
	<div
		*ngIf="isMobile && booking?.eventImage"
		class="l-banner-mobile"
	>
		<img
			[src]="booking.eventImage"
			class="l-banner-image"
		/>
	</div>
	<div
		class="g-account-content"
		[ngClass]="{ 'l-mobile-content': isMobile, 'l-web-content': !isMobile }"
	>
		<div
			*ngIf="!isLoading; else detailsLoading"
			class="w-100"
		>
			<app-web-content-header
				class="w-100"
				[headerContent]="headerContent"
				[isMobile]="isMobile"
				[reference]="booking.reference"
			></app-web-content-header>
			<div class="l-ticket-details">
				<div
					id="event-name"
					class="g-h3 l-event-name-container"
					(click)="handleEventNavigate()"
				>
					<span class="l-event-name">{{booking.eventName}}</span>
					<mat-icon class="l-link-icon">open_in_new</mat-icon>
				</div>
				<ng-container *ngIf="booking?.isLocked">
					<div class="g-orange l-status-message mb-3"> These tickets are
						currently locked. Please edit your ticket details below and you will be able to access your
						tickets.</div>
				</ng-container>
				<div
					class="l-show-more"
					[@detailExpand]="detailExpandState"
				>
					<div
						id="status"
						class="l-badge"
						[ngClass]="statusClass"
					>
						{{ status }}
					</div>
					<div><span class="fw-bold">Date: </span><span id="event-date">{{booking.dateFrom |
							formattedDatePipe}} -
							{{booking.dateTo |
							formattedDatePipe}}</span></div>
					<div><span class="fw-bold">Reference Number: </span> <span
							id="ticket-reference">{{booking.reference}}</span></div>
					<div class="d-flex gap-1 flex-wrap"><span class="fw-bold">Venue: </span><span
							id="event-venue-name">{{booking.venueName}}</span> <span
							*ngIf="booking.googleMapsUrl"
							id='maps-link'
							class="l-maps-link"
							(click)="handleMapNavigate()"
						> - View map <mat-icon class="l-location-icon"> location_on</mat-icon></span></div>
					<div><span class="fw-bold">Number of Tickets: </span><span
							id="ticket-quantity">{{booking.tickets.length}}</span></div>
					<div><span class="fw-bold">Amount Paid: </span><span id="amount-paid">{{booking.amountPaid}}</span>
					</div>
				</div>
				<div
					(click)="showDetails = !showDetails"
					class="g-green g-pointer"
				>
					{{ showDetails ? '- Hide Booking Details' : '+ Show Booking Details' }}
				</div>
			</div>
			<mat-divider
				*ngIf="!isMobile"
				class="w-100 my-4"
			></mat-divider>
			<div [ngClass]="{ 'my-5': isMobile}">
				<div class="g-h3 text-start">Actions</div>
				<div class="l-actions-container mt-3">
					<div
						*ngFor="let cardData of getFilteredCardData() | keyvalue: handleDefaultOrder"
						[appMiddleClickNavigate]="handleMiddleClick(cardData.value.externalRouteTo, cardData.key)"
						(click)="handleNavigateTo(cardData.value.routeTo, cardData.key)"
						class="l-card"
					>
						<ng-container *ngIf="!isMobile; else showMobile">
							<app-details-card
								[id]="formatId(cardData.value.title)"
								[icon]="cardData.value.iconPath"
								[text]="cardData.value.title"
								[isDisabled]="isCardDisabled(cardData.key)"
								[tooltip]="getTooltipMessage(cardData.value.title)"
								class="l-card-content"
							></app-details-card>
						</ng-container>
						<ng-template #showMobile>
							<app-mobile-section-route-card
								[icon]="cardData.value.iconPath"
								[text]="cardData.value.title"
								[isDisabled]="isCardDisabled(cardData.key)"
								[tooltip]="getTooltipMessage(cardData.value.title)"
							></app-mobile-section-route-card>
						</ng-template>
					</div>
				</div>
			</div>
			<mat-divider
				*ngIf="!isMobile"
				class="w-100 my-5"
			></mat-divider>
			<div>
				<div class="g-h3 text-start">Have Questions?</div>
				<div class="my-2">Contact the organiser about the event:</div>
				<div class="l-actions-container mt-3">
					<div
						*ngFor="let cardData of helperCardData | keyvalue: handleDefaultOrder"
						class="l-card"
						(click)="!(cardData.value.title === 'Send Message' && !detailParameters.contactHost) && handleNavigateHelp(cardData.value.routeTo())"
					>
						<ng-container *ngIf="!isMobile; else showHelperMobile">
							<app-details-card
								[id]="formatId(cardData.value.title)"
								[icon]="cardData.value.iconPath"
								[text]="cardData.value.title"
								[isDisabled]="cardData.value.title === 'Send Message' && !detailParameters.contactHost"
								[tooltip]="getTooltipMessage(cardData.value.title)"
								class="l-card-content"
							></app-details-card>
						</ng-container>
						<ng-template #showHelperMobile>
							<app-mobile-section-route-card
								[id]="formatId(cardData.value.title)"
								[icon]="cardData.value.iconPath"
								[text]="cardData.value.title"
								[isDisabled]="cardData.value.title === 'Send Message' && !detailParameters.contactHost"
								[tooltip]="getTooltipMessage(cardData.value.title)"
							></app-mobile-section-route-card>
						</ng-template>
					</div>
				</div>
			</div>

		</div>
	</div>
	<app-footer-button
		#footerButton
		class="g-fixed-footer"
		[primaryText]="'DOWNLOAD TICKETS'"
		[isDisabled]="false"
		($primaryButton)="handlePrimaryButton()"
		($secondaryButton)="handleNavigationBack()"
		[secondaryText]="'BACK'"
		[isMobile]="isMobile"
		[primaryButtonId]="'download-tickets'"
		[secondaryButtonId]="'back-button'"
	>
	</app-footer-button>
	<ng-template #detailsLoading>
		<div class="l-loading">
			<mat-spinner></mat-spinner>
		</div>
	</ng-template>
</div>