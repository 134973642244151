import { Country } from '@app/models/user.model';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { getCountryCode } from '@app/utils/Countries';

@Component({
	selector: 'app-country-select',
	templateUrl: './country-select.component.html',
	styleUrls: ['./country-select.component.sass'],
})
export class CountrySelectComponent implements OnInit, OnChanges {
	@Input() isMobile: boolean;
	@Input() inputFormControl: FormControl;
	@Input() label: string;

	country: Country;
	isRequired = false;
	isDisabled = false;

	ngOnInit(): void {
		this. isDisabled = this.inputFormControl.disabled;
		this.country = {
			name: this.inputFormControl.value,
			alpha2Code: getCountryCode(this.inputFormControl.value),
			alpha3Code: null,
			numericCode: null,
		};
		this.isRequired = this.inputFormControl.hasValidator(Validators.required);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.inputFormControl) {
			const countryChanges = changes.inputFormControl.currentValue;
			if (!changes.inputFormControl.isFirstChange() && countryChanges.value !== this.country.name) {
				this.country = {
					name: countryChanges.value,
					alpha2Code: getCountryCode(countryChanges.value),
					alpha3Code: null,
					numericCode: null,
				};
			}
		}
	}

	onCountrySelected($event: Country) {
		this.country = $event;
		this.inputFormControl.setValue($event.name);
	}
}
