import { initialState } from './initialState';
import * as eventActions from '@app/store/actions/event/event.actions';
import { EventActionsConstants } from '@app/store/actions/event/event.actions.constants';
import { EventsState } from '@app/models/store.model';

export const eventTypes = (
	state = initialState.eventTypes,
	action: eventActions.Actions
): EventsState['eventTypes'] => {
	switch (action.type) {
		case EventActionsConstants.GGET_EVENT_TYPES_SUCCESS:
			return action.payload;
		default:
			return state;
	}
};
