import { UserActionsConstants } from '@app/store/actions/user/user.actions.constants';
import { Actions } from '@app/store/actions/user/user.actions';
import { UserContactsState } from '@app/models/store.model';
import { combineReducers, ActionReducer } from '@ngrx/store';
import { FileActionsConstants } from '@app/store/actions/file/file.actions.constants';
import * as fileActions from '@app/store/actions/file/file.actions';
import { EventActionsConstants } from '@app/store/actions/event/event.actions.constants';
import * as eventActions from '@app/store/actions/event/event.actions';

export const initialState: UserContactsState = {
	previousLists: {
		all: [],
		isFetching: false,
	},
	parsedContacts: {
		all: [],
		isFetching: false,
	},
	previousEvents: {
		all: [],
		attendees: [],
		isFetching: false,
	},
	isSendInvitesFetching: false,
	activeContactsSource: -1,
};

const userPreviousContactListsReducer = (
	state = initialState.previousLists,
	action: Actions
): UserContactsState['previousLists'] => {
	switch (action.type) {
		case UserActionsConstants.GET_PREVIOUS_CONTACT_LISTS:
			return {
				isFetching: true,
				all: [],
			};
		case UserActionsConstants.GET_PREVIOUS_CONTACT_LISTS_FAILED:
			return {
				...state,
				isFetching: false,
			};
		case UserActionsConstants.GET_PREVIOUS_CONTACT_LISTS_SUCCESS:
			return {
				all: action.payload.previousContactLists,
				isFetching: false,
			};
		default:
			return state;
	}
};

const userParsedContactsReducer = (
	state = initialState.parsedContacts,
	action: fileActions.Actions
): UserContactsState['parsedContacts'] => {
	switch (action.type) {
		case FileActionsConstants.UPLOAD_CONTACTS_CSV:
			return {
				...initialState.parsedContacts,
				isFetching: true,
			};
		case FileActionsConstants.UPLOAD_CONTACTS_CSV_FAILED:
			return {
				...state,
				isFetching: false,
			};
		case FileActionsConstants.UPLOAD_CONTACTS_CSV_SUCCESS:
			return {
				all: action.payload.contacts,
				isFetching: false,
			};
		default:
			return state;
	}
};

const userPreviousEventsReducer = (
	state = initialState.previousEvents,
	action: Actions
): UserContactsState['previousEvents'] => {
	switch (action.type) {
		case UserActionsConstants.GET_PREVIOUS_EVENTS:
			return {
				...initialState.previousEvents,
				isFetching: true,
			};
		case UserActionsConstants.GET_PREVIOUS_EVENTS_SUCCESS:
			return {
				...state,
				all: action.payload.events,
				isFetching: false,
			};
		case UserActionsConstants.GET_PREVIOUS_EVENTS_FAILED:
		case UserActionsConstants.GET_EVENTS_ATTENDEES_FAILED:
			return {
				...state,
				isFetching: false,
			};
		case UserActionsConstants.GET_EVENTS_ATTENDEES:
			return {
				...state,
				isFetching: true,
			};
		case UserActionsConstants.GET_EVENTS_ATTENDEES_SUCCESS:
			return {
				...state,
				attendees: action.payload.contacts,
				isFetching: false,
			};
		default:
			return state;
	}
};

const isSendInvitesFetchingReducer = (
	state = initialState.isSendInvitesFetching,
	action: Actions | eventActions.Actions
): UserContactsState['isSendInvitesFetching'] => {
	switch (action.type) {
		case UserActionsConstants.CREATE_CONTACT_LIST:
		case EventActionsConstants.SEND_INVITES:
			return true;
		case EventActionsConstants.SEND_INVITES_SUCCESS:
		case EventActionsConstants.SEND_INVITES_FAILED:
		case UserActionsConstants.CREATE_CONTACT_LIST_FAILED:
		case UserActionsConstants.CREATE_CONTACT_LIST_SUCCESS:
			return false;
		default:
			return state;
	}
};

const activeContactsSourceReducer = (
	state = initialState.activeContactsSource,
	action: Actions
): UserContactsState['activeContactsSource'] => {
	switch (action.type) {
		case UserActionsConstants.SET_ACTIVE_CONTACTS_SOURCE:
			return action.payload.source;
		default:
			return state;
	}
};

export const userContactsReducer: ActionReducer<
UserContactsState,
Actions | fileActions.Actions | eventActions.Actions
> = combineReducers({
	previousLists: userPreviousContactListsReducer,
	parsedContacts: userParsedContactsReducer,
	previousEvents: userPreviousEventsReducer,
	isSendInvitesFetching: isSendInvitesFetchingReducer,
	activeContactsSource: activeContactsSourceReducer,
});
