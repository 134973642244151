<form
	*ngIf="form"
	class="event-tickets l-form-container"
	[ngClass]="{'mobile' : isMobile}"
	[formGroup]="form"
>
	<div class="l-reserved-seating-container">
		<div
			class="l-toggle-container"
			[ngClass]="{'mobile' : isMobile}"
			#toggleContainer
		>
			<label class="g-form-label l-label">Reserved seating<mat-icon
					#reservedSeatingTooltip="matTooltip"
					class="l-tooltip-icon"
					matTooltip="If attendees need to reserve specific seats enable this option."
					matTooltipPosition="above"
					(click)="reservedSeatingTooltip.toggle()"
				>info</mat-icon></label>
			<mat-slide-toggle
				class="standard-toggle"
				[formControl]="fromForm('isReservedSeating')"
				id="reserved-seating-toggle"
				(change)='handleSetReservedSeating($event)'
			>
			</mat-slide-toggle>
		</div>
		<div>
			<div
				class="l-page-loader"
				*ngIf="isSeatsLoading"
			>
				<mat-spinner></mat-spinner>
			</div>
		</div>
		<div
			*ngIf="(form.get('isReservedSeating').value && !isSeatsLoading)"
			class='l-seating-chart-container'
		>
			<h3 class='my-3'>Seating Chart</h3>
			<p class='l-description'>Clicking 'Add Seating Chart' below will allow you to design your
				seating layout. Switch the 'Reserved Seating' toggle off if you do not require guests to reserve seats.
			</p>
			<ng-container *ngIf="userCharts.length; else seatingChartButton">
				<div
					class='l-seating-charts-container'
					[ngClass]="{'mobile': isMobile}"
				>
					<div
						class='l-add-chart-card'
						(click)='handleAddChart()'
						id='add-chart-card'
					>
						<div class='l-add-chart-content'>
							<div class='l-add-chart-text'>ADD CHART</div>
							<mat-icon class='l-add-chart-icon'>add</mat-icon>
						</div>
					</div>
					<div
						*ngFor="let chartItem of displayedCharts; let i = index"
						class="l-flip-card"
						[class.l-selected]="chartItem.key === form.get('seatingChartId').value"
					>
						<div
							class="l-flip-card-inner"
							[class.l-flipped]="flippedIndex === i"
							(click)="handleFlip(i, chartItem, false)"
							id="chart-card-{{i}}"
						>
							<div class="l-chart-card">
								<div class="l-actions-container">
									<mat-radio-button
										class='l-chart-radio'
										[checked]="form.get('seatingChartId').value == chartItem.key"
										(click)="$event.preventDefault()"
									></mat-radio-button>
									<mat-icon
										*ngIf="chartItem.status === userChartStatusEnum.PUBLISHED_WITH_DRAFT || chartItem.status === userChartStatusEnum.PUBLISHED"
										#chartTooltip="matTooltip"
										class="l-action-icon l-chart-tooltip-icon"
										[matTooltip]="getChartTooltipText(chartItem.status)"
										matTooltipPosition="above"
										(click)="chartTooltip.toggle()"
									>info</mat-icon>
									<mat-icon
										class="l-action-icon l-chart-setting-icon"
										(click)="$event.stopPropagation(); handleFlip(i, chartItem, true)"
									>
										settings
									</mat-icon>
								</div>
								<img
									class="l-chart-image"
									[src]="chartItem.publishedVersionThumbnailUrl"
									alt=""
								>
								<div class='l-chart-name'>{{chartItem.name}}</div>
							</div>
							<div class="l-card-back">
								<img
									class="l-chart-confirmation-image"
									[src]="chartItem.publishedVersionThumbnailUrl"
									alt=""
								>
								<div
									class="l-setting-container"
									*ngIf="showSetting"
								>
									<div class='l-confirmation-text'>
										Copy {{form.get('seatingChartId').value == chartItem.key ? "or edit" : ""}} your
										chart
									</div>
									<div class='l-button-container'>
										<button
											class='g-primary-button l-confirm-button'
											(click)="handleCopyChart(chartItem.key)"
										>
											<mat-icon>content_copy</mat-icon>
										</button>
										<button
											*ngIf="form.get('seatingChartId').value == chartItem.key"
											class="g-secondary-button l-confirm-button"
											(click)="handleEditChart()"
										>
											<mat-icon>edit</mat-icon>
										</button>
									</div>
								</div>
								<div
									class="l-link-container"
									*ngIf="!showSetting"
								>
									<div class='l-confirmation-text'>Confirm linking this chart to your event?</div>
									<div class='l-button-container'>
										<button
											class='g-primary-button l-confirm-button'
											(click)='handleSetChart(chartItem)'
											id="confirm-chart-selection-{{i}}"
										><mat-icon>done</mat-icon></button>
										<button
											class='g-secondary-button l-confirm-button'
											id="decline-chart-selection-{{i}}"
										><mat-icon>close</mat-icon></button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<button
					
					class="l-show-more g-secondary-button"
					[ngClass]="{'mobile' : isMobile}"
					(click)="toggleShowMore()"
				>
					SHOW {{ showMoreCharts ? 'LESS' : 'MORE' }} CHARTS
				</button>
			</ng-container>
			<ng-template #seatingChartButton>
				<button
					class='g-secondary-button mb-4'
					id="add-chart-button"
					(click)='handleAddChart()'
				>ADD SEATING CHART</button>
			</ng-template>
		</div>
	</div>

	<div
		*ngIf="!isOneDayEvent"
		class="l-status-message-container"
	>
		<mat-icon class="l-info-icon">info</mat-icon>
		<div>Please note that all ticket types and event capacity are applied to
			<span class='g-green fw-bold'>each event in the series</span>.
		</div>
	</div>

	<app-event-ticket-types-table
		[eventId]="eventId"
		[isMobile]="isMobile"
		[timezoneTitle]='timezoneTitle'
		[hasVendors]='hasVendors'
		[currencySymbol]='currencySymbol'
		[isReservedSeating]="form.get('isReservedSeating').value"
		[seatingChartId]="form.get('seatingChartId').value"
		[isEventCreation]="isEventCreation"
		[tickets]="form.get('tickets').controls"
		(addTicket)='handleAddTicket($event)'
		(deleteTicket)='handleDeleteTicket($event)'
		(handleDrag)='handleDrag($event)'
		class='l-ticket-table'
	></app-event-ticket-types-table>

	<div class='d-flex flex-column align-items-center'>
		<div
			*ngIf="isMaxTicketAmount()"
			class="l-status-message-container"
			[ngClass]="{'mobile': isMobile}"
		>
			<mat-icon class="l-info-icon">info</mat-icon>
			<div>You have reached the limit of <span class='g-green fw-bold'>200 ticket types</span> for this
				event. You will not be able to add more than 200 tickets.
			</div>
		</div>

	</div>

	<hr class="w-100 g-light-line" />

	<div *ngIf="areTicketsWithPrice()">
		<div class="l-form-item-container">
			<label class="g-form-label l-label">Currency<mat-icon
					*ngIf="hasSoldTickets"
					#currencyTooltip="matTooltip"
					class="l-tooltip-icon g-orange"
					matTooltip="You cannot change the currency because at least one order has already been made."
					matTooltipPosition="above"
					(click)="currencyTooltip.toggle()"
				>warning</mat-icon></label>
			<div
				class='l-form-item-content'
				#currencyContainerTooltip="matTooltip"
				matTooltip="You cannot change the currency because at least one order has already been made."
				[matTooltipDisabled]="!hasSoldTickets"
				matTooltipPosition="above"
				(click)="currencyContainerTooltip.toggle()"
			>
				<input
					class="form-control l-currency-input"
					disabled
					[value]="form.get('currencyCode').value"
					id="currency-code-input-disabled"
				/>
				<button
					class='g-primary-button l-currency-button'
					[disabled]='!currencies.length || hasSoldTickets'
					[class.disabled]="!currencies.length || hasSoldTickets"
					(click)="handleCurrencyDialog()"
					id="change-currency-button"
				>CHANGE CURRENCY</button>
			</div>
		</div>
		<hr class="w-100 g-light-line" />
	</div>
	<div *ngIf="isTicketsCapacity() || isDonationAdded()">
		<div
			class="l-toggle-container"
			[ngClass]="{'mobile' : isMobile}"
		>
			<label class="g-form-label l-label p-0">Limit Event Capacity<mat-icon
					#reservedSeatingTooltip="matTooltip"
					class="l-tooltip-icon"
					[matTooltip]="form.get('capacity').value !== null ? 'Ticket sales will stop when the number of tickets issued is equal to the number entered here.' : 'No total capacity. Ticket sales will be capped at the amount that is set for sale for each ticket type.'"
					matTooltipPosition="above"
					(click)="reservedSeatingTooltip.toggle()"
				>info</mat-icon></label>
			<mat-slide-toggle
				class="standard-toggle"
				[checked]="hasEventCapacity"
				id="limit-event-capacity"
				(change)='handleEventCapacityToggle()'
			>
			</mat-slide-toggle>
		</div>

		<div
			class="l-form-item-container mt-3"
			*ngIf='hasEventCapacity'
		>
			<label class="g-form-label l-label">Limit Amount<mat-icon
					#limitAmountTooltip="matTooltip"
					class="l-tooltip-icon"
					matTooltip="Ticket sales will stop when the number of tickets issued is equal to the number entered here."
					matTooltipPosition="above"
					(click)="limitAmountTooltip.toggle()"
				>info</mat-icon></label>
			<div class='l-form-item-content'>
				<input
					class="form-control"
					[formControl]="form.get('capacity')"
					placeholder='200'
					[allowNegativeNumbers]="false"
					mask="separator"
					thousandSeparator=","
					id="event-capacity-input"
				/>
			</div>
			<div class="g-error-input"
				*ngIf="fromForm('capacity').invalid && ((fromForm('capacity').dirty || fromForm('capacity').touched))">
				*{{getError(fromForm('capacity').errors)}}
			</div>
		</div>
		<hr class="w-100 g-light-line" />

		<div
			*ngIf="isDonationAdded()"
			class='mt-4'
		>
			<div class="l-form-item-container">
				<label class="g-form-label l-label">Donation Target<mat-icon
						#fundraisingGoalTooltip="matTooltip"
						class="l-tooltip-icon"
						matTooltip="If set, a progress bar will be displayed for your page visitors to see how close donations are to your goal."
						matTooltipPosition="above"
						(click)="fundraisingGoalTooltip.toggle()"
					>info</mat-icon></label>
				<div class='l-form-item-content'>
					<app-price-field
						[offset]="'zero'"
						[currency]="form.get('currencyCode').value"
						[control]="form.get('donationTarget')"
						placeholder="1,000.00"
						class='w-100'
						id="donation-target-input"
					>
					</app-price-field>
				</div>
			</div>
			<div
				class="l-toggle-container"
				[ngClass]="{'mobile' : isMobile}"
			>
				<label class="g-form-label l-label p-0">Include tickets in donation target<mat-icon
						#reservedSeatingTooltip="matTooltip"
						class="l-tooltip-icon"
						matTooltip="If ON, the funds collected from donations and all of your ticket types will be included in the donation target on your event page."
						matTooltipPosition="above"
						(click)="reservedSeatingTooltip.toggle()"
					>info</mat-icon></label>
				<mat-slide-toggle
					class="standard-toggle"
					[formControlName]="'donationIncludeTickets'"
					id="include-donation-toggle"
				>
				</mat-slide-toggle>
			</div>
			<hr class="w-100 g-light-line" />
		</div>
	</div>

	<div *ngIf="form.get('isReservedSeating').value">
		<div
			class="l-toggle-container"
			[ngClass]="{'mobile' : isMobile}"
		>
			<label class="g-form-label l-label p-0">Max tickets per order<mat-icon
					#maxTicketsTooltip="matTooltip"
					class="l-tooltip-icon"
					[matTooltip]="form.get('maxTicketsPerOrder').value !== null ? 'Ticket sales allowed per order.' : 'No limit to ticket purchase. Ticket sales will be capped at the amount that is set for sale for each ticket type.'"
					matTooltipPosition="above"
					(click)="maxTicketsTooltip.toggle()"
				>info</mat-icon></label>
			<mat-slide-toggle
				class="standard-toggle"
				[checked]="hasMaxTicketCapacity"
				id="max-tickets-toggle"
				(change)='toggleMaxTicketsPerOrder()'
			>
			</mat-slide-toggle>
		</div>

		<div
			class="l-form-item-container mt-3"
			*ngIf='hasMaxTicketCapacity'
		>
			<label class="g-form-label l-label">Max Amount<mat-icon
					#maxTicketsTooltip="matTooltip"
					class="l-tooltip-icon"
					matTooltip="Ticket sales allowed per order"
					matTooltipPosition="above"
					(click)="maxTicketsTooltip.toggle()"
				>info</mat-icon></label>
			<div class='l-form-item-content'>
				<input
					class="form-control"
					[formControl]="form.get('maxTicketsPerOrder')"
					id="max-tickets-input"
				/>
			</div>

		</div>
		<hr class="w-100 g-light-line" />
	</div>

	<div
		class="l-toggle-container"
		[ngClass]="{'mobile' : isMobile}"
	>
		<label class="g-form-label l-label p-0">
			Do you want to allow {{areTicketsWithPrice() ? 'refunds' : 'ticket cancellations'}}?
			<mat-icon
				#reservedSeatingTooltip="matTooltip"
				class="l-tooltip-icon"
				matTooltip="Ticket sales will be capped at the amount that is set for sale for each ticket type."
				matTooltipPosition="above"
				(click)="reservedSeatingTooltip.toggle()"
			>info</mat-icon></label>
		<mat-slide-toggle
			class="standard-toggle"
			[formControlName]="'allowRefunds'"
			id="allow-refunds-toggle"
		>
		</mat-slide-toggle>
	</div>
	<div *ngIf="form.get('allowRefunds').value === false
					&& form.get('allowRefundProtect').value === true
					&& form.get('currencyCode').value === 'ZAR'">
		<div
			class="l-status-message-container mt-3"
			[ngClass]="{'mobile': isMobile}"
		>
			<div>Ticket buyers will be offered refund protection via a third party service at
				checkout. Please <span
					class='g-green fw-bold g-pointer'
					(click)="handleBuyerProtectionRoute()"
				>click
					here</span>
				for more information.
			</div>
		</div>
	</div>
	<hr class="w-100 g-light-line" />

	<div *ngIf="form.get('tickets').value.length && areTicketsWithPrice()">
		<div
			class="l-toggle-container"
			[ngClass]="{'mobile' : isMobile}"
		>
			<label class="g-form-label l-label p-0">
				Do you want to absorb the {{commission}}% commission fee?
				<mat-icon
					#absorbCommissionTooltip="matTooltip"
					class="l-tooltip-icon"
					[class.l-commission-tooltip]="isMobile"
					matTooltip="If ON, Quicket's {{commission}}% commission fee will be charged to you instead of your ticket buyers"
					matTooltipPosition="above"
					(click)="absorbCommissionTooltip.toggle()"
				>info</mat-icon></label>
			<mat-slide-toggle
				class="standard-toggle"
				[formControlName]="'commissionForHost'"
				id="commission-toggle"
			>
			</mat-slide-toggle>
		</div>
		<div
			class="l-toggle-container mt-4"
			[ngClass]="{'mobile' : isMobile}"
		>
			<label class="g-form-label l-label p-0">
				Do you want to absorb the {{currencySymbol}}{{serviceFee}} ticket booking fee?
				<mat-icon
					#absorbBookingFeeTooltip="matTooltip"
					class="l-tooltip-icon"
					[class.l-booking-fee-tooltip]="isMobile"
					matTooltip="If ON, Quicket's {{currencySymbol}}{{serviceFee}} booking fee will be charged to you instead of your ticket buyers."
					matTooltipPosition="above"
					(click)="absorbBookingFeeTooltip.toggle()"
				>info</mat-icon></label>
			<mat-slide-toggle
				class="standard-toggle"
				[formControlName]="'serviceFeeForHost'"
				id="serviceFee-toggle"
			>
			</mat-slide-toggle>
		</div>
		<div
			class="l-status-message-container mt-3"
			[ngClass]="{'mobile': isMobile}"
		>
			<div>Fees are charged on each ticket sold. Any fees you choose to absorb will be deducted from your event
				payout, within 3 working days of your event end date.<span *ngIf='hasSoldTickets'> Updating either fee
					setting will only be applied to sales going forward.</span><span *ngIf="!isEventCreation"> You can
					go to <span
						class='g-link'
						(click)='handlePayoutsNavigation()'
					>Payouts</span> to
					add or select a bank account.</span>
			</div>
		</div>
		<hr class="w-100 g-light-line" />
	</div>
	<app-bank-account-selection
		*ngIf="isEventCreation"
		class="l-bank-container"
		[currencyId]="form.get('currencyId').value"
		(activeBankAccountChange)="bankAccountChange($event)"
		[isMobile]="isMobile"
	></app-bank-account-selection>
</form>
<div *ngIf="showFooterButton" class="l-sticky-bar">
	<app-footer-button
		[isMobile]='isMobile'
		[isDisabled]="isDisabled"
		[isLoading]='isSubmitLoading'
		[primaryText]="'SAVE TICKETS'"
		primaryButtonId="save-ticket-types"
		($primaryButton)="proceedFormSubmit()"
	>
	</app-footer-button>
</div>