import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { UserOrganiserProfilesComponent } from './organiser-profiles.component';
import { EventSharedModule } from '@app/features/event-shared/event-shared.module';
import { OrganiserProfileFormComponent } from './organiser-profile-form/organiser-profile-form.component';



@NgModule({
	imports: [
		SharedModule,
		EventSharedModule,
	],
	declarations: [
		UserOrganiserProfilesComponent,
		OrganiserProfileFormComponent,
	],
	exports: [
		UserOrganiserProfilesComponent,
	],
})
export class UserOrganiserProfilesModule {}
