<div class="l-bank-container">
	<h4>Bank Account</h4>
	<div class="l-bank-text">
		In order to receive payment:
	</div>
	<div
		class="l-add-bank-card"
		(click)="handleBankAccountModal()"
		id="link-bank-account"
	>
		<img
			src=".\assets\payments\add-bank-card.svg"
			class="l-add-bank-image"
		/>
		<div class="l-add-bank-text">LINK BANK ACCOUNT</div>
		<i class="fa fa-solid fa-plus l-add-bank-icon"></i>
	</div>
	<div *ngIf="bankAccountOptions.length">
		<label class="g-form-label mt-4">
			{{ isDefaultBankSelected ?
			'We have automatically selected your default bank account for payment:'
			: 'Select a Bank Account:' }}
		</label>
		<mat-select
			class="form-control w-100"
			[(ngModel)]="selectedAccountId"
			name="account"
			#account="ngModel"
			placeholder="Please Select"
			#singleSelect
			(selectionChange)="handleSelectionChange()"
			id="select-bank-account"
		>
			<mat-option
				*ngFor="let account of bankAccountOptions"
				[value]="account.value"
				[id]="'account-option-' + account.value"
			>
				{{ account.label }}
			</mat-option>
		</mat-select>
	</div>
</div>