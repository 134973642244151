import { ConfigModel } from '@app/models/common.model';

const config: ConfigModel = {
	facebookAppId: '176333702418869',
	googleClientId: '632760137799-9ff10dltbe1vmnvhrpumfka24hognvb3.apps.googleusercontent.com',
	googleAPIKey: 'AIzaSyAj91x4ec0agjywjplIO7HBNRQYHI1S9P0',
	baseURL: '',
	baseAPI_URL: 'https://api.quicket.co.za/api',
	splitIOKey: 'ft0ec75pqe3s3r2sjrdgbv7fqe3bsm1qfqid',
	isSplitIOLocal: false,
	algoliaKey: 'ec5eb43c5ca4fd2aa3883ae2c131f731',
	algoliaAppId: 'QAUIPDMSEL',
	sentryDSN: 'https://c68de2ba027e46398d88cdeb418367a6@o1026512.ingest.us.sentry.io/6729277',
	sentryEnv: 'production',
	APIKey: '3171510c9eb84a7b70700652bcbd5cae',
	GA4MeasurementId: 'G-F8RXX30R81',
};

export default config;
