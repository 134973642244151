<div class="l-container">
	<div class="l-content" [ngClass]="{ 'mobile': isMobile }">
		<div class="l-title" [ngClass]="{ 'mobile': isMobile, 'web': !isMobile }">
			<h3 *ngIf='!isMobile'>Guest Management</h3>
			<p>
				Efficiently manage all aspects of guest interactions and ticketing for your event. This section provides tools to track orders, organise your guest list, and communicate effectively with attendees.
			</p>
		</div>
		<div class="l-cards-container" [ngClass]="{ 'mobile': isMobile, 'web': !isMobile }">
			<a *ngFor="let card of cardsData | keyvalue: handleDefaultOrder" [routerLink]="[card.value.routeTo(eventId)]"
				[ngClass]="{'l-card': !isMobile }" class="g-flat-link g-pointer">
				<ng-container *ngIf="!isMobile; else showMobile">
					<app-section-route-card class="g-pointer l-card-content"
						[card]="card.value"></app-section-route-card>
				</ng-container>
				<ng-template #showMobile>
					<app-mobile-section-route-card [icon]="card.value.iconPath" [text]="card.value.title"
						[description]='card.value.description' [asCard]='true'></app-mobile-section-route-card>
				</ng-template>
			</a>
		</div>
	</div>
</div>