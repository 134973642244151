import { Component, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HeaderContent } from '@app/models/shared';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { StoreService } from '@app/services/store/store.service';
import { InternalURLCreator } from '@app/services/url/url.dictionary';
import { EVENT_ID_PARAM_KEY, FileTypes } from '@app/utils/consts';
import { Actions, ofType } from '@ngrx/effects';
import { Observable, take, takeUntil } from 'rxjs';
import * as eventSelectors from '@app/store/selectors/event.selector';
import { EventImage } from '@app/models/event.model';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { TicketsService } from '@app/services/tickets/tickets.service';
import { omit } from 'lodash';
import { ImageService, ImageServiceType } from '@app/services/image/image.service';
import { EventTicketsSettings } from '@app/models/ticket.model';
import { GetTicketSettings, UpdateTicketSettings } from '@app/store/actions/tickets/tickets.actions';
import { TicketsActionsConstant } from '@app/store/actions/tickets/tickets.constants';
import { Go } from '@app/store/actions/router/router.actions';
import { FormCanDeactivate } from '@app/services/guards/event.guard';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '@app/shared/modals/confirmation-modal/confirmation-modal.component';

@Component({
	selector: 'app-event-settings-ticketing',
	templateUrl: './event-settings-ticketing.component.html',
	styleUrls: ['./event-settings-ticketing.component.sass'],
})
export class EventSettingsTicketingComponent extends FormCanDeactivate {
	isMobile: boolean;
	eventId: number;
	destroyed$: EventEmitter<void> = new EventEmitter<void>();
	isPageLoading = true;
	isUpdating = false;
	ticketSettings: EventTicketsSettings;
	image: EventImage;
	form: UntypedFormGroup;
	allowedFileTypes = FileTypes.ticketImage;
	useCustom: boolean;
	includeMessage: boolean;
	ImageServiceTypeEnum = ImageServiceType;

	headerContent: HeaderContent = {
		breadCrumbs: [
			{
				routeName: 'Settings',
				routeTo: () => InternalURLCreator.settings(this.eventId),
			},
			{
				routeName: 'Ticket Settings',
			},
		],
		title: 'Ticket Settings',
		description: '',
	};

	ticketStubInfoTooltip = `Add any additional information that may be helpful for guests
		to see on their tickets.`;
	logoOrImageTooltip = 'This will appear at the bottom of each ticket.';

	constructor(
		private breakpointService: BreakpointService,
		private activatedRoute: ActivatedRoute,
		private actions$: Actions,
		private store: StoreService,
		private ticketsService: TicketsService,
		private imageService: ImageService,
		private dialog: MatDialog
	) {
		super();
		this.imageService.init(ImageServiceType.TICKET);
	}

	ngOnInit() {
		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});

		this.activatedRoute.parent.paramMap.pipe(take(1)).subscribe((params) => {
			this.eventId = +params.get(EVENT_ID_PARAM_KEY);
			this.store.dispatch(new GetTicketSettings({ id: this.eventId }));
		});

		this.actions$
			.pipe(
				ofType(
					TicketsActionsConstant.GET_TICKET_SETTINGS_SUCCESS,
					TicketsActionsConstant.GET_TICKET_SETTINGS_FAILED
				),
				takeUntil(this.destroyed$)
			)
			.subscribe(() => {
				this.isPageLoading = false;
			});

		this.actions$
			.pipe(
				ofType(
					TicketsActionsConstant.UPDATE_TICKET_SETTINGS_FAILED,
					TicketsActionsConstant.UPDATE_TICKET_SETTINGS_SUCCESS
				),
				takeUntil(this.destroyed$)
			)
			.subscribe(() => {
				this.isUpdating = false;
				this.form.markAsPristine();
			});

		this.store
			.select(eventSelectors.eventTicketSettings(this.eventId))
			.pipe(takeUntil(this.destroyed$))
			.subscribe((settings) => {
				if (settings) {
					this.ticketSettings = settings;
					this.useCustom = this.ticketSettings.useCustom;
					this.includeMessage = this.ticketSettings.includeMessage;
					this.image = settings.image;
					if (!this.form) {
						this.form = this.ticketsService.createEventTicketsSettingsForm(omit(settings, ['image']));
					}
				}
			});
	}

	fromForm(controlKey: string): AbstractControl {
		if (this.form) {
			return this.form.get(controlKey);
		}
		return null;
	}

	handleBack() {
		this.store.dispatch(new Go({ path: [InternalURLCreator.settings(this.eventId)] }));
	}

	handleSave() {
		this.isUpdating = true;
		this.store.dispatch(
			new UpdateTicketSettings({
				id: this.eventId,
				settings: {
					...this.form.getRawValue(),
					image: this.image,
				},
			})
		);
	}

	handleDelete(event: EventImage) {
		this.imageService.onImageRemove(event, this.eventId);
	}

	handleImageUpload(image: EventImage) {
		this.image = image;
	}

	openTicketPreview() {
		window.open(this.ticketSettings.ticketPreviewLink);
	}

	isFormDeactivatable(): boolean | Observable<boolean> {
		return !this.form || this.form.pristine;
	}

	deactivationFallback(): Observable<boolean> {
		this.dialog
			.open(ConfirmationModalComponent, {
				data: {
					title: 'Unsaved Changes',
					text: 'Are you sure you want to leave this page? Any unsaved changes will be discarded.',
					buttonText: 'LEAVE',
					centerText: true,
					isMobile: this.isMobile,
				},
				panelClass: 'g-standard-dialog',
			})
			.afterClosed()
			.subscribe((result) => {
				this.closeDeactivationModal(result);
			});
		return this.modalCallback.asObservable();
	}

	closeDeactivationModal(isConfirmed: boolean): void {
		this.modalCallback.next(isConfirmed);
	}

	markFormAsDirty() {
		this.form.markAsDirty();
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
