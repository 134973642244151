import { Component, OnChanges, SimpleChanges } from '@angular/core';
import { takeUntil } from 'rxjs';
import { MultiChoiceBaseControlComponent } from './../multi-choice-base-control/multi-choice-base-control.component';
@Component({
	selector: 'app-radio-button',
	templateUrl: './radio-button.component.html',
	styleUrls: ['./radio-button.component.sass'],
})

export class RadioButtonComponent extends MultiChoiceBaseControlComponent implements OnChanges {


	ngOnInit(): void {
		super.ngOnInit();

		this.otherOptionControl.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
			if (!this.options.includes(value)) {
				this.inputFormControl.setValue(value);
				this.inputFormControl.markAsDirty();
			} else {
				this.otherOptionControl.setValue('');
			}
		});

		if (this.options.includes(this.inputFormControl.value)) {
			this.otherEnabled = false;
		} else {
			this.otherEnabled = true;
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (!changes.inputFormControl || changes.inputFormControl.firstChange) {
			return;
		}

		const { currentValue, previousValue } = changes.inputFormControl;

		if (currentValue.value === previousValue.value) {
			return;
		}

		if (this.options.includes(currentValue.value)) {
			this.otherEnabled = false;
			this.updateValidators();
			return;
		}

		if (this.control.addOtherOption && !this.options.includes(currentValue.value)) {
			this.otherEnabled = true;
			this.updateValidators();
			this.otherOptionControl.setValue(currentValue.value);
		}
	}

	onSelectionChange(event: any) {
		if (this.options.includes(event.value)) {
			this.otherEnabled = false;
		} else {
			this.otherEnabled = true;
		}
		this.inputFormControl.markAsDirty();
	}
}

