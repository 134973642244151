<div class="l-container">
	<div
		*ngIf="showPrimaryHeader; else subMenu"
		class='w-100'
	>
		<div class='l-main-header'>
			<a
				href="/"
				class="header-logo-link"
			>
				<img
					class="header-logo"
					[src]="QUICKET_LOGO"
					alt="logo"
				>
			</a>
			<button
				class='g-primary-button l-nav-button'
				(click)="handleNavMenu()"
			>
				<img
					[src]="currentParentNavItem.iconPath + '-active.svg'"
					class='l-icon'
				/>{{currentParentNavItem.title}}
			</button>
		</div>
		<div
			class='l-event-menu'
			(click)="handleEventNavMenu()"
		>
			<mat-icon
				id="mobile-navigate-sub-menu"
				class='ms-2 g-pointer'
			>menu</mat-icon>
			<div>{{currentNavItem}}</div>
		</div>
	</div>
	<ng-template #subMenu>
		<div class="l-sub-page">
			<mat-icon
				id="mobile-navigate-back"
				class="l-mobile-navigate-back"
				(click)='handleNavigateBack()'
			>
				arrow_back
			</mat-icon>
			<h3 class="mb-3 m-auto mt-3">{{currentPageHeading}}</h3>
		</div>
	</ng-template>
</div>