import { EventsState } from '@app/models/store.model';
import { initialState } from './initialState';
import * as userActions from '@app/store/actions/user/user.actions';
import { UserActionsConstants } from '@app/store/actions/user/user.actions.constants';

export const banks = (
	state = initialState.banks,
	action: userActions.Actions
): EventsState['banks'] => {
	switch (action.type) {
		case UserActionsConstants.GET_BANKS:
			return initialState.banks;
		case UserActionsConstants.GET_BANKS_SUCCESS:
		case UserActionsConstants.GET_ALL_BANKS_SUCCESS:
			return action.payload.banks;
		default:
			return state;
	}
};
