import { trigger, state, style, transition, animate } from '@angular/animations';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges
} from '@angular/core';
import { AbstractControl, FormGroup, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { SchemeID } from '@app/models/dataSchema.model';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '../modals/confirmation-modal/confirmation-modal.component';
import { TicketStockTypeMap, TicketType } from '@app/models/ticket.model';
import { capitalize } from 'lodash';
import { SelectFieldOption } from '../form-field/select-field/select-field.model';
import { ImageService, ImageServiceType } from '@app/services/image/image.service';
import { DropdownOption, FilterOptions } from '@app/models/shared';
import { Dictionary } from '@ngrx/entity';
import { TICKET_OPTIONS } from '@app/utils/consts';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
	selector: 'app-event-ticket-types-table',
	templateUrl: './event-ticket-types-table.component.html',
	styleUrls: ['./event-ticket-types-table.component.sass'],
	animations: [
		trigger('detailExpand', [
			state('collapsed', style({ height: '*', minHeight: '*', opacity: '0' })),
			state('expanded', style({ height: '*', opacity: '1' })),
			transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
		]),
		trigger('rotateIcon', [
			state(
				'default',
				style({
					transform: 'rotate(360deg)',
				})
			),
			state(
				'rotated',
				style({
					transform: 'rotate(270deg)',
				})
			),
			transition('default <=> rotated', animate('300ms ease-out')),
		]),
	],
})
export class EventTicketTypesTableComponent implements OnInit, OnChanges {
	@Input() eventId: SchemeID;
	@Input() isMobile: boolean;
	@Input() timezoneTitle: string;
	@Input() hasVendors: boolean;
	@Input() isEventCreation: boolean;

	@Input() tickets: AbstractControl[];
	@Input() isReservedSeating: boolean;
	@Input() seatingChartId: string;
	@Input() currencySymbol: string;

	@Output() addTicket: EventEmitter<TicketType> = new EventEmitter<TicketType>();
	@Output() deleteTicket: EventEmitter<number> = new EventEmitter<number>();
	@Output() handleDrag: EventEmitter<CdkDragDrop<string[]>> = new EventEmitter<CdkDragDrop<string[]>>();

	dataSource = new MatTableDataSource<AbstractControl>();
	displayColumns: string[] = ['sorting', 'isExpanded', 'name', 'price', 'quantity', 'actions', 'error'];
	expandedDetailElement: number;
	hasSearchTerm = false;
	dragDisabled = true;

	ImageServiceTypeEnum = ImageServiceType;
	isImageUploading: boolean;

	ticketStockOptions: SelectFieldOption[] = Array.from(TicketStockTypeMap).map(([key, label]) => ({
		value: key,
		label,
	}));

	ticketOptions: Dictionary<DropdownOption> = TICKET_OPTIONS;

	get currentDateTime(): string {
		return new Date().toISOString();
	}

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(private dialog: MatDialog, public imageService: ImageService, private changeDetectorRef: ChangeDetectorRef) {
		this.imageService.init(ImageServiceType.TICKET);

		this.dataSource.filterPredicate = (data: AbstractControl, filter: string) => {
			const textToSearch = data.value.name ? data.value.name.toLowerCase() : '';
			return textToSearch.includes(filter);
		};
	}

	ngOnInit(): void {
		this.dataSource.data = this.tickets;
		this.markInvalidControlsAsTouched();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.tickets) {
			this.dataSource.data = changes.tickets.currentValue;
			this.handleChange();
		}
		if (changes.isReservedSeating) {
			this.ticketOptions[TicketType.donation].disabled = changes.isReservedSeating.currentValue ? true : false;
		}
	}

	handleChange() {
		this.changeDetectorRef.detectChanges();
	}

	handleFilter(filterOptions: FilterOptions) {
		const { searchTerm } = filterOptions;
		this.dataSource.filter = searchTerm.trim().toLowerCase();
		this.hasSearchTerm = searchTerm.length > 0;
	}

	handleAddTicket(ticketOption: DropdownOption) {
		this.addTicket.emit(TicketType[ticketOption.value]);
	}

	handleTicketDrag(event: CdkDragDrop<string[]>): void {
		this.dragDisabled = true;
		this.handleDrag.emit(event);
	}

	handleExpandContent(ticket: UntypedFormGroup) {
		this.expandedDetailElement = this.expandedDetailElement === ticket.value.id ? null : ticket.value.id;
	}

	toggleControlValue(control: UntypedFormControl) {
		if (control) {
			control.setValue(!control.value);
			control.markAsDirty();
		}
	}

	handleDeleteTicket(selectedTicket: UntypedFormGroup) {
		const hasSales = selectedTicket.get('hasSales').value;
		this.dialog
			.open(ConfirmationModalComponent, {
				data: {
					title: hasSales ? 'Cannot Delete ' : 'Are you sure?',
					text: hasSales ? 'Tickets of this type have already been purchased' : 'Deleting this ticket type cannot be undone.',
					buttonText: 'DELETE',
					centerText: true,
					isDisabled: hasSales,
					isMobile: this.isMobile,
				},
				panelClass: 'g-standard-dialog',
			})
			.afterClosed()
			.subscribe((result) => {
				if (result) {
					const index = this.tickets.findIndex((ticket) => ticket.get('id').value === selectedTicket.get('id').value);
					this.deleteTicket.emit(index);
				}
			});
	}

	handleSellAtTheDoorToggle(change: MatSlideToggleChange, ticket: UntypedFormGroup) {
		if (!change.checked) {
			ticket.get('autoCheckin').setValue(false);
		}
	}

	getTicketQuestions(ticket: UntypedFormGroup): AbstractControl[] {
		const questions = (ticket.get('questions') as UntypedFormArray).controls;
		questions.forEach((question) => {
			if (question.get('question').value === 'Email' && ticket.get('linkedToStream').value) {
				question.get('required').disable();
			} else {
				question.get('required').enable();
			}
		});

		return questions.filter((question) => question.get('enabled').value);
	}

	getTicketCategoriesKeys(ticket: UntypedFormGroup): AbstractControl[] {
		return (ticket.get('categories') as UntypedFormArray).controls;
	}

	getCategoriesError(errors: { [key: string]: string }) {
		return errors.ticketCategories;
	}

	isSalesCopyDisabled(type: 'start' | 'end', ticket: UntypedFormGroup) {
		return !ticket.get(`sales${capitalize(type)}DateTime`).value;
	}

	copySalesDates(type: 'start' | 'end', ticket: UntypedFormGroup) {
		if (!this.isSalesCopyDisabled(type, ticket)) {
			const salesDateTimeKey = `sales${capitalize(type)}DateTime`,
				salesDateTime = ticket.get(salesDateTimeKey).value;

			this.tickets.forEach((el) => {
				el.get(salesDateTimeKey).setValue(salesDateTime);
			});
		}
	}

	onImageUpload(image: File, ticket: UntypedFormGroup) {
		this.isImageUploading = true;
		const file: File = image;

		this.imageService.encodeImage(file, (base) => {
			ticket.get('imageString').setValue({
				base64: this.imageService.buildBase64String(base, file),
				...this.imageService.buildImagePreview(file),
			});
			ticket.get('image').setValue(null);
		});
		ticket.get('imageString').markAsDirty();
		this.isImageUploading = false;
	}

	getImagePath(ticket: UntypedFormGroup) {
		if (ticket.get('imageString').value) {
			return ticket.get('imageString').value.url;
		} else if (ticket.get('image').value) {
			return ticket.get('image').value.url;
		}
		return null;
	}

	onImageRemove(ticket: UntypedFormGroup) {
		ticket.get('image').setValue(null);
		ticket.get('imageString').setValue(null);
		this.isImageUploading = false;
	}

	getError(errors: { [key: string]: string }) {
		return errors && errors[Object.keys(errors)[0]];
	}

	isInvalidTicket(ticket: AbstractControl): boolean {
		return ticket.invalid;
	}

	isHiddenTicket(ticket: AbstractControl): boolean {
		return !this.isInvalidTicket(ticket) && ticket.get('isTypeHidden')?.value;
	}

	isSaleEnded(ticket: AbstractControl): boolean {
		return (
			!this.isInvalidTicket(ticket) &&
			!this.isHiddenTicket(ticket) &&
			new Date(ticket.get('salesEndDateTime')?.value) <= new Date(this.currentDateTime)
		);
	}

	markInvalidControlsAsTouched() {
		for (const ticket of this.tickets) {
			if (ticket.invalid){
				const ticketAsFormGroup = ticket as FormGroup;
				for (const control in ticketAsFormGroup.controls) {
					if (ticketAsFormGroup.controls[control].invalid) {
						ticketAsFormGroup.controls[control].markAsTouched();
					}
				}
			}
		}
	};

	fromForm(controlKey: string, form: UntypedFormControl): AbstractControl {
		if (form) {
			return form.get(controlKey);
		}
		return null;
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
