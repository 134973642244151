import { Component, EventEmitter } from '@angular/core';
import { HeaderContent, SectionRouteCard, SideNavItems } from '@app/models/shared';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { URLCreator } from '@app/services/url/url.dictionary';
import { handleOrder } from '@app/utils/common-helpers';
import { EARN_COMMISSION_HEADER, MY_COMMISSION_CARDS, SIDENAV_ITEMS } from '@app/utils/consts';
import { Dictionary } from '@ngrx/entity';
import { takeUntil } from 'rxjs';

@Component({
	selector: 'app-earn-commission',
	templateUrl: './earn-commission.component.html',
	styleUrls: ['./earn-commission.component.sass'],
})
export class EarnCommissionComponent {
	cardsData: Dictionary<SectionRouteCard> = MY_COMMISSION_CARDS;
	isMobile = false;
	currentRoute: SideNavItems = SIDENAV_ITEMS.earnCommission;
	handleDefaultOrder = handleOrder;
	destroyed$: EventEmitter<void> = new EventEmitter<void>();
	headerContent: HeaderContent = EARN_COMMISSION_HEADER['earnCommission'];

	constructor(
		private breakpointService: BreakpointService
	) { }

	ngOnInit(): void {
		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});
	}

	handleTermsNavigation() {
		window.open(URLCreator.terms());
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}

}
