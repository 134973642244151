import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SendInvitesMethod } from '@app/models/event.model';
import { HeaderContent } from '@app/models/shared';
import { UserContactListDraftItem, UserContactsInviteBatch } from '@app/models/user.model';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { StoreService } from '@app/services/store/store.service';
import { InternalURLCreator } from '@app/services/url/url.dictionary';
import { take, takeUntil } from 'rxjs';
import { EVENT_ID_PARAM_KEY } from '@app/utils/consts';
import { Go } from '@app/store/actions/router/router.actions';

@Component({
	selector: 'app-send-invites',
	templateUrl: './send-invites.component.html',
	styleUrls: ['./send-invites.component.sass'],
})
export class SendInvitesComponent implements OnInit {
	invitationMethod = Object.values(SendInvitesMethod);
	selectedOption: string = this.invitationMethod[0];
	sendInvitesMethod = SendInvitesMethod;
	eventId: number;
	selectedContactLists = [];
	isSendInvitesDisabled = true;
	isPageLoading = true;
	isMobile = false;
	isButtonLoading = false;

	contactList: UserContactListDraftItem[];
	previousEventsAttendees: UserContactsInviteBatch = {
		contactListName: '',
		contacts: [],
	};

	headerContent: HeaderContent = {
		breadCrumbs: [
			{
				routeName: 'Marketing',
				routeTo: () => InternalURLCreator.marketing(this.eventId),
			},
			{
				routeName: 'Send Invites',
			},
		],
		title: 'Send Invites',
		description: 'Invite your contacts and past attendees to book tickets to your event. Recipients will receive an email that includes the event start date and venue, as well as links to the event page to find more information and book tickets.', // eslint-disable-line max-len
	};

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private breakpointService: BreakpointService,
		private store: StoreService,
		private activatedRoute: ActivatedRoute
	) {	}

	ngOnInit() {
		this.activatedRoute.parent.paramMap.pipe(take(1)).subscribe((params) => {
			this.eventId = +params.get(EVENT_ID_PARAM_KEY);
			this.isPageLoading = false;
		});

		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});
	}

	handlePreviousEventContacts(previousEventContacts: UserContactsInviteBatch) {
		this.previousEventsAttendees = previousEventContacts;
		this.isSendInvitesDisabled = this.previousEventsAttendees.contacts.length === 0;
	}

	handleRouteToDashboard() {
		this.store.dispatch(new Go({ path: [InternalURLCreator.eventDashboard(this.eventId)] }));
	}

	onOptionSelected(option: string): void {
		this.selectedOption = option;
	}
}
