import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormGroup, AbstractControl, UntypedFormArray, FormArray, FormGroup } from '@angular/forms';
import { SchemeID } from '@app/models/dataSchema.model';
import {
	EventQuestion,
	EventCurrencyOption,
	EventDetails,
	EventPart,
	EventCurrencyValue,
	EventCopySeatingChartRequest
} from '@app/models/event.model';
import { SeatingCategory, UserChart, TicketType, UserChartStatus, Ticket, SeatingCategorySuccessResponse } from '@app/models/ticket.model';
import { DatesService } from '@app/services/dates/dates.service';
import { TicketsService } from '@app/services/tickets/tickets.service';
import { TimezoneService } from '@app/services/timezone/timezone.service';
import { getDraftID } from '@app/utils/IdGenerator';
import MoneyPresenter from '@app/utils/MoneyPresenter';
import { isEqual } from 'lodash';
import { Subscription, takeUntil } from 'rxjs';
import {
	requiredValidator,
	numberMoreThen,
	notEmptyArray,
	numberLessThen,
	isDateBefore,
	isDateAfter,
	maxLength
} from '../form-field/form-validators';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '../modals/confirmation-modal/confirmation-modal.component';
import { SeatingChartModalComponent } from '../seating-chart-modal/seating-chart-modal.component';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { CurrencySelectModalComponent } from '../currency-select-modal/currency-select-modal.component';
import { StoreService } from '@app/services/store/store.service';
import { Go } from '@app/store/actions/router/router.actions';
import { InternalURLCreator } from '@app/services/url/url.dictionary';
import { CopySeatingChart, UpdateEvent } from '@app/store/actions/event/event.actions';
import { QuantityWarningModalComponent } from '../quantity-warning-modal/quantity-warning-modal.component';
import { Actions, ofType } from '@ngrx/effects';
import { EventActionsConstants } from '@app/store/actions/event/event.actions.constants';
import { GetSeatingCategories } from '@app/store/actions/tickets/tickets.actions';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { InputModalComponent } from '../modals/input-modal/input-modal.component';
import { GetUserCharts } from '@app/store/actions/user/user.actions';
import { UserActionsConstants } from '@app/store/actions/user/user.actions.constants';
import { TicketsActionsConstant } from '@app/store/actions/tickets/tickets.constants';

@Component({
	selector: 'app-event-ticket-types-form',
	templateUrl: './event-ticket-types-form.component.html',
	styleUrls: ['./event-ticket-types-form.component.sass'],
})
export class EventTicketTypesFormComponent implements OnInit, OnChanges, OnDestroy {
	@Input() isMobile: boolean;
	@Input() form: UntypedFormGroup;
	@Input() currencies: { value: EventCurrencyValue; title: string }[];
	@Input() questions: EventQuestion[];
	@Input() seatingCategories: SeatingCategory[];
	@Input() designerKey: string;
	@Input() userCharts: UserChart[] = [];
	@Input() eventId: SchemeID;
	@Input() hasSoldTickets: boolean;
	@Input() details: EventDetails = null;
	@Input() showFooterButton = true;
	@Input() isEventCreation = false;
	@Input() eventEndDateTime: string;

	commission = '0';
	serviceFee = 0;
	isOneDayEvent = false;
	ticketStubDescription: string = null;
	hasVendors: boolean;

	seatingIdSub: Subscription;
	reservedSeatingSub: Subscription;
	userChartStatusEnum = UserChartStatus;

	hasEventCapacity: boolean;
	hasMaxTicketCapacity: boolean;
	timezoneTitle: string;
	currencySymbol: string;

	isSubmitLoading: boolean;
	flippedIndex: number | null = null;

	isSettingFlip = false;
	showSetting = false;
	showMoreCharts = false;

	isSeatsLoading = false;

	get isDisabled(): boolean {
		return (
			this.form.invalid ||
			this.form.pristine ||
			this.isSubmitLoading ||
			(this.form.get('isReservedSeating').value && !this.form.get('seatingChartId').value)
		);
	}

	get displayedCharts() {
		const selectedChartId = this.form.get('seatingChartId').value;
		const selectedChart = this.userCharts.find((chart) => chart.key === selectedChartId);
		const otherCharts = this.userCharts.filter((chart) => chart.key !== selectedChartId);

		if (this.showMoreCharts) {
			return selectedChart ? [selectedChart, ...otherCharts] : this.userCharts;
		} else {
			return selectedChart ? [selectedChart, ...otherCharts.slice(0, 2)] : this.userCharts.slice(0, 3);
		}
	}

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	@ViewChild('toggleContainer') toggleContainer: ElementRef;

	constructor(
		public ticketsService: TicketsService,
		private datesService: DatesService,
		public timezoneService: TimezoneService,
		private dialog: MatDialog,
		private store: StoreService,
		private actions$: Actions
	) {}

	ngOnInit() {
		this.commission = this.getCommission(this.details.commission);
		this.serviceFee = this.details.serviceFee;
		this.isOneDayEvent = this.details.isOneDayEvent;
		this.timezoneTitle = this.timezoneService.getTimezoneByName(this.details.timezone).title;
		this.ticketStubDescription = this.details.ticketStubDescription;
		this.hasVendors = this.details.hasVendors;
		this.currencySymbol = this.getCurrencySymbol();
		this.checkMaxTicketsPerOrder();
		this.form.get('tickets').setValidators(notEmptyArray());
		this.form.get('tickets').updateValueAndValidity();
		this.hasEventCapacity = this.form.get('capacity').value !== null;
		this.hasMaxTicketCapacity = this.form.get('capacity').value > 0 || this.form.get('capacity').value !== null;

		this.actions$
			.pipe(ofType(EventActionsConstants.UPDATE_EVENT_SUCCESS, EventActionsConstants.UPDATE_EVENT_FAILED), takeUntil(this.destroyed$))
			.subscribe(({ type }: { type: string }) => {
				if (type === EventActionsConstants.UPDATE_EVENT_SUCCESS) {
					if (this.form) {
						this.form.markAsPristine();
					}
				}
				this.isSubmitLoading = false;
			});

		this.actions$.pipe(ofType(EventActionsConstants.COPY_SEATING_CHART_SUCCESS), takeUntil(this.destroyed$)).subscribe(() => {
			this.store.dispatch(new GetUserCharts({ eventId: this.eventId }));
		});

		this.actions$
			.pipe(
				ofType(
					UserActionsConstants.GET_USER_CHARTS_SUCCESS,
					UserActionsConstants.GET_USER_CHARTS_FAILED,
					EventActionsConstants.COPY_SEATING_CHART_FAILED
				),
				takeUntil(this.destroyed$)
			)
			.subscribe(() => {
				this.isSeatsLoading = false;
			});

		this.actions$
			.pipe(
				ofType(
					TicketsActionsConstant.GET_SEATING_CATEGORIES_SUCCESS
				),
				takeUntil(this.destroyed$)
			)
			.subscribe((payload: SeatingCategorySuccessResponse) => {
				this.ticketsService.checkAndUpdateTicketCategories(payload.payload.entity.seatingCategories, this.form);
			});
	}

	getError(errors: { [key: string]: string }) {
		return errors && errors[Object.keys(errors)[0]];
	}

	getChartTooltipText(status: string): string {
		if (status === UserChartStatus.PUBLISHED) {
			return 'This chart already in use';
		} else if (status === UserChartStatus.PUBLISHED_WITH_DRAFT) {
			return 'There\'s an event linked to the chart, and a draft version exists';
		}
	}

	handleSetChart(chart: UserChart) {
		this.flippedIndex = null;
		if (chart.key !== this.form.get('seatingChartId').value) {
			this.setChart(chart.key, chart.status);
		}
	}

	handleAddChart() {
		this.form.get('seatingChartId').setValue(null);
		this.seatingChartModal();
	}

	handleEditChart() {
		this.dialog
			.open(ConfirmationModalComponent, {
				data: {
					title: 'Edit Chart?',
					text: 'Please note that editing this chart will affect all events that are linked to this chart',
					buttonText: 'Proceed',
					isMobile: this.isMobile,
				},
				panelClass: 'g-standard-dialog',
			})
			.afterClosed()
			.subscribe((result) => {
				if (result) {
					this.seatingChartModal();
				}
			});
	}

	handleCopyChart(chartKey: string) {
		this.dialog
			.open(InputModalComponent, {
				data: {
					title: 'Copy Chart?',
					buttonText: 'COPY',
					inputText: 'New seating plan name: ',
				},
			})
			.afterClosed()
			.subscribe((result) => {
				if (result) {
					const copySeatingChartRequest: EventCopySeatingChartRequest = {
						oldChartKey: chartKey,
						newChartName: result,
						productId: this.eventId,
					};

					this.isSeatsLoading = true;

					this.toggleContainer.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });

					this.store.dispatch(new CopySeatingChart({ copySeatingChartRequest: copySeatingChartRequest }));
				}
			});
	}

	seatingChartModal() {
		const chartStatus = this.getActiveChart() && this.getActiveChart().status;
		this.dialog
			.open(SeatingChartModalComponent, {
				data: {
					chartId: this.form.get('seatingChartId').value,
					designerKey: this.designerKey,
					isDonationAdded: this.isDonationAdded(),
					chartStatus: chartStatus,
				},
				panelClass: 'g-full-page-dialog',
			})
			.afterClosed()
			.subscribe((chartKey) => {
				if (chartKey) {
					this.setChart(chartKey, chartStatus);
				}
			});
	}

	setChart(chartKey: string, chartStatus: string) {
		this.form.get('seatingChartId').setValue(chartKey);
		this.store.dispatch(new GetSeatingCategories({ chartId: chartKey, eventId: this.eventId }));
		const ticketsArray = this.form.get('tickets') as UntypedFormArray;
		for (let i = ticketsArray.length - 1; i >= 0; i--) {
			if (ticketsArray.at(i).get('type').value === TicketType.donation) {
				this.handleDeleteTicket(i);
			}
		};
		ticketsArray.controls.forEach(control => {
			const categoryControl = control.get('categories');
			categoryControl.updateValueAndValidity();
		});
	}

	toggleShowMore() {
		this.showMoreCharts = !this.showMoreCharts;
	}

	getTicketsControls(): AbstractControl[] {
		return (this.form.get('tickets') as UntypedFormArray).controls;
	}

	checkMaxTicketsPerOrder() {
		if (this.form && this.form.get('isReservedSeating').value === true) {
			if (this.form.get('maxTicketsPerOrder').value === 9999) {
				this.form.get('maxTicketsPerOrder').clearValidators();
			} else {
				this.form
					.get('maxTicketsPerOrder')
					.setValidators([requiredValidator(), numberMoreThen(0, 'ticket limit must be more than 0.')]);
			}
			this.form.get('maxTicketsPerOrder').updateValueAndValidity();
		}
	}

	handleEventCapacityToggle() {
		this.hasEventCapacity = !this.hasEventCapacity;
		if (!this.hasEventCapacity) {
			this.fromForm('capacity').clearValidators();
			this.fromForm('capacity').setValue(null);
		} else {
			this.fromForm('capacity').addValidators(requiredValidator());
		}
		this.fromForm('capacity').markAsDirty();
		this.fromForm('capacity').updateValueAndValidity();
	}

	subscribeToSeatingChanges() {
		if (this.form && this.form.get('isReservedSeating')) {
			this.reservedSeatingSub = this.form.get('isReservedSeating').valueChanges.subscribe((value) => {
				(this.form.get('tickets') as UntypedFormArray).controls.forEach((el, i) => {
					el.get('categories').updateValueAndValidity();
				});
				if (!value) {
					(this.form.get('tickets') as UntypedFormArray).controls.forEach((el) => {
						const categories = el.get('categories') as UntypedFormArray;

						for (let j = categories.controls.length - 1; j >= 0; j--) {
							categories.removeAt(j);
						}
					});

					if (this.form.get('seatingChartId').value) {
						this.getTicketsControls().forEach((c) => {
							c.get('quantity').setValue(null);
						});
					}

					this.form.patchValue({
						seatingChartId: null,
					});
				}
				this.form.get('maxTicketsPerOrder').setValue(9999);
			});
		}
	}

	subscribeToChartIdChanges() {
		this.seatingIdSub = this.form.get('seatingChartId').valueChanges.subscribe((value) => {
			if (value) {
				this.getTicketsControls().forEach((c) => {
					c.get('quantity').setValue(value ? 99999 : null);
				});
			}
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		if (
			changes.ticketCategories &&
			!isEqual(changes.ticketCategories.previousValue, changes.ticketCategories.currentValue) &&
			this.getTicketsControls()
		) {
			this.getTicketsControls().forEach((el) => {
				el.get('categories').updateValueAndValidity();
			});
		}

		if (changes.form) {
			this.subscribeToSeatingChanges();
			this.subscribeToChartIdChanges();
			this.getTicketsControls().forEach((el) => {
				el.get('categories').updateValueAndValidity();
			});
		}

		this.getTicketFormValidators();
	}

	fromForm(controlKey: string): AbstractControl {
		if (this.form) {
			return this.form.get(controlKey);
		}
		return null;
	}

	toggleMaxTicketsPerOrder(): void {
		this.hasMaxTicketCapacity = !this.hasMaxTicketCapacity;
		if (this.form.get('maxTicketsPerOrder').value === 9999) {
			this.form
				.get('maxTicketsPerOrder')
				.setValidators([requiredValidator(), numberMoreThen(0, 'ticket limit must be more than 0.')]);
			this.form.get('maxTicketsPerOrder').updateValueAndValidity();
			this.form.get('maxTicketsPerOrder').setValue(0);
		} else {
			this.form.get('maxTicketsPerOrder').clearValidators();
			this.form.get('maxTicketsPerOrder').setValue(9999);
		}
	}

	handleAddTicket(type: TicketType): void {
		const ticketQuestions = this.questions.map((q) => ({
			id: null,
			productQuestionId: q.id,
			question: q.question,
			required: this.ticketsService.isQuestionChecked(q),
			enabled: q.enabled,
		}));

		const ticket = this.ticketsService.createEventTicketGroup(
			{
				id: getDraftID(),
				type,
				salesStartDateTime: this.datesService.getStartOfTheDay().toISOString(),
				salesEndDateTime: this.eventEndDateTime,
				questions: ticketQuestions,
				quantity: this.form.get('seatingChartId').value ? 99999 : null,
				stubInfo: this.ticketStubDescription,
			},
			this.form.get('seatingChartId'),
			this.seatingCategories,
			this.form.get('allowNoCategories').value
		);

		const ticketsArray = this.form.get('tickets') as UntypedFormArray;
		const newTickets = new UntypedFormArray([...ticketsArray.controls, ticket]);
		this.form.setControl('tickets', newTickets);
		this.form.get('tickets').updateValueAndValidity();
		this.getTicketFormValidators();
	}

	handleDeleteTicket(index: number) {
		if (index !== -1) {
			const ticketsArray = this.form.get('tickets') as UntypedFormArray;
			ticketsArray.removeAt(index);
			const newTickets = new UntypedFormArray([...ticketsArray.controls]);
			this.form.setControl('tickets', newTickets);
			this.form.get('tickets').setValidators(notEmptyArray());
			this.form.get('tickets').updateValueAndValidity();
			this.getTicketFormValidators();
			this.form.get('serviceFeeForHost').markAsDirty();
		}
	}

	getTicketFormValidators() {
		this.getTicketsControls().forEach((c) => {
			const quantity = c.get('quantity');
			const name = c.get('name');
			const description = c.get('description');
			const stubInfo = c.get('stubInfo');
			const maxPerOrder = c.get('maxPerOrder');
			const minPerOrder = c.get('minPerOrder');
			const incrementsOf = c.get('incrementsOf');
			const salesStartDateTime = c.get('salesStartDateTime');
			const salesEndDateTime = c.get('salesEndDateTime');
			const showTicketStockType = c.get('showTicketStockType');
			if (c.get('type').value !== 'donation') {
				quantity.setValidators([requiredValidator(), numberMoreThen(-1)]);
				quantity.updateValueAndValidity();
			}
			name.setValidators([requiredValidator(), maxLength(200, 'Ticket name is too long.')]);
			name.updateValueAndValidity();
			description.setValidators([maxLength(1000, 'Description is too long.')]);
			description.updateValueAndValidity();
			stubInfo.setValidators([maxLength(1000, 'Stub information is too long.')]);
			stubInfo.updateValueAndValidity();
			maxPerOrder.setValidators([
				requiredValidator(),
				numberMoreThen(minPerOrder.value - 1, 'Maximum tickets per order cannot be less than the minimum tickets per order'),
			]);
			maxPerOrder.updateValueAndValidity();
			minPerOrder.setValidators([
				requiredValidator(),
				numberLessThen(maxPerOrder.value + 1, 'Minimum tickets per order cannot be more than the maximum tickets per order.'),
			]);
			minPerOrder.updateValueAndValidity();
			incrementsOf.setValidators([requiredValidator(), numberMoreThen()]);
			salesStartDateTime.setValidators([requiredValidator(), isDateBefore(salesEndDateTime)]);
			salesEndDateTime.updateValueAndValidity();
			salesEndDateTime.setValidators([requiredValidator(), isDateAfter(salesStartDateTime)]);
			salesEndDateTime.updateValueAndValidity();
			showTicketStockType.setValidators([requiredValidator()]);
			showTicketStockType.updateValueAndValidity();
		});
	}

	handleFlip(index: number, chartItem: UserChart, isSettingFlip: boolean) {
		this.isSettingFlip = isSettingFlip;

		setTimeout(() => {
			this.showSetting = isSettingFlip;
		}, 150);

		if (this.isSettingFlip || this.form.get('seatingChartId').value !== chartItem.key) {
			this.flippedIndex = this.flippedIndex === index ? null : index;
		} else if (!this.isSettingFlip && this.form.get('seatingChartId').value === chartItem.key) {
			this.flippedIndex = null;
		}
	}

	handleDrag(event: CdkDragDrop<string[]>): void {
		moveItemInArray(this.getTicketsControls(), event.previousIndex, event.currentIndex);
		const ticketsArray = this.form.get('tickets') as UntypedFormArray;
		const newTickets = new UntypedFormArray([...ticketsArray.controls]);
		this.form.setControl('tickets', newTickets);
		this.form.get('serviceFeeForHost').markAsDirty();
	}

	isMaxTicketAmount(): boolean {
		const tickets = <UntypedFormArray>this.form.get('tickets');
		return tickets.length > 199;
	}

	getActiveChart(): UserChart {
		const activeChart = this.userCharts.find((el) => el.key === this.form.get('seatingChartId').value);
		return activeChart;
	}

	areTicketsWithPrice(): boolean {
		return this.form.get('tickets').value.some((el: Ticket) => el.type === TicketType.paid || el.type === TicketType.donation);
	}

	isTicketsCapacity(): boolean {
		return !this.form.get('tickets').value.every((el: Ticket) => el.type === TicketType.donation);
	}

	isDonationAdded(): boolean {
		return this.form.get('tickets').value.some((el: Ticket) => el.type === TicketType.donation);
	}

	getCommission(commission: number) {
		return (commission * 100).toFixed(1);
	}

	handleCurrencyDialog() {
		this.dialog
			.open(CurrencySelectModalComponent, {
				data: {
					currencies: this.currencies,
					currencyCode: this.form.get('currencyCode').value,
					title: 'What currency are you selling tickets in',
					text: 'This applies to all of your ticket types. You will not be able to change the currency ' +
						'after your first ticket has been sold. For currencies other than South African Rand you ' +
						'will need to use your own payment processor which can be done from your event dashboard. ' +
						'We will invoice you for our fees after your event ends.',
				},
				panelClass: 'g-standard-dialog',
			})
			.afterClosed()
			.subscribe((currency: EventCurrencyOption) => {
				if (currency) {
					this.form.markAsDirty();
					const id = currency.value.id;
					const code = currency.value.code;

					this.form.get('currencyCode').setValue(code);
					this.form.get('currencyId').setValue(id);

					this.currencies.filter((curr) => {
						if (curr.value.code === code) {
							this.commission = this.getCommission(curr.value.defaultCommission);
							this.serviceFee = curr.value.defaultServiceFee;
						}
					});

					this.currencySymbol = this.getCurrencySymbol();
				}
			});
	}

	getCurrencySymbol() {
		return this.form.get('currencyCode').value && MoneyPresenter.getBasicSymbol(this.form.get('currencyCode').value);
	}

	handleSetReservedSeating(toggle: MatSlideToggleChange) {
		if (!toggle.checked) {
			this.form.patchValue({
				isReservedSeating: false,
				seatingChartId: null,
			});

			const tickets = this.form.get('tickets') as FormArray;
			tickets.controls.forEach((control: FormGroup) => {
				if (control.contains('categories')) {
					control.patchValue({
						'categories': [],
					});
				}
			});
		}
		this.form.updateValueAndValidity();
	}

	handleBuyerProtectionRoute() {
		window.open('https://help.quicket.com/portal/en/kb/articles/refund-protection-for-ticket-buyers', '_blank');
	}

	handlePayoutsNavigation() {
		this.store.dispatch(new Go({ path: [InternalURLCreator.eventPayout(this.eventId)] }));
	}

	showStreamingWarning(tickets: Ticket[]): boolean {
		const draft = '__DRAFT';
		const newTicket = tickets.some((t) => t.id.toString().includes(draft));
		const hasStreaming = this.form.get('hasStreaming');
		if (this.form && hasStreaming.value && newTicket) {
			return true;
		}
		return false;
	}

	proceedFormSubmit() {
		const quantityWarningType = this.ticketsService.checkTicketsQuantity(this.form.getRawValue().tickets);
		const streamWarning = this.showStreamingWarning(this.form.getRawValue().tickets);

		if (quantityWarningType || streamWarning) {
			this.dialog
				.open(QuantityWarningModalComponent, {
					data: {
						warningType: quantityWarningType,
						streamWarning: streamWarning,
					},
					panelClass: 'g-standard-dialog',
				})
				.afterClosed()
				.subscribe((result: boolean) => {
					if (result) {
						this.handleSubmitForm();
					}
				});
		} else {
			this.handleSubmitForm();
		}
	}

	handleSubmitForm() {
		this.isSubmitLoading = true;
		this.store.dispatch(
			new UpdateEvent({
				id: this.eventId,
				event: {
					ticketsDetails: this.form.getRawValue(),
					details: this.details,
				},
				eventPart: EventPart.TICKETS,
				isUpdating: true,
			})
		);
	}

	bankAccountChange(bankAccountId: SchemeID) {
		this.form.patchValue({
			bankAccountId,
		});
	}

	ngOnDestroy() {
		if (this.seatingIdSub) {
			this.seatingIdSub.unsubscribe();
		}
		if (this.reservedSeatingSub) {
			this.reservedSeatingSub.unsubscribe();
		}
		this.destroyed$.next();
	}
}
