import { UserActionsConstants } from '@app/store/actions/user/user.actions.constants';
import { Actions } from '@app/store/actions/user/user.actions';
import { UserBankAccountsState } from '@app/models/store.model';
import { BankAccount } from '@app/models/user.model';

export const initialState: UserBankAccountsState = {
	all: [],
	isFetching: false,
	needsPasswordVerification: false,
	passwordVerificationRedirect: null,
};


export const userBankAccountsReducer = (
	state = initialState,
	action: Actions
): UserBankAccountsState => {
	switch (action.type) {
		case UserActionsConstants.GET_BANK_ACCOUNTS_SUCCESS:
			if (action.payload.bankAccounts) {
				return {
					isFetching: false,
					all: action.payload.bankAccounts
						.map(el => ({ ...el, isSelected: el.isDefault })),
					needsPasswordVerification: false,
					passwordVerificationRedirect: null,
				};
			} else {
				return {
					isFetching: false,
					all: [],
					needsPasswordVerification: action.payload.needsPasswordVerification,
					passwordVerificationRedirect: action.payload.passwordVerificationRedirect,
				};
			}

		case UserActionsConstants.GET_BANK_ACCOUNTS_FAILED:
			return {
				...state,
				isFetching: false,
			};
		case UserActionsConstants.GET_BANK_ACCOUNTS:
			return {
				isFetching: true,
				all: [],
			};
		case UserActionsConstants.ADD_BANK_ACCOUNT:
			return {
				...state,
				isFetching: true,
			};
		case UserActionsConstants.ADD_BANK_ACCOUNT_SUCCESS:
			const { account } = action.payload;
			const accounts =
				state.all
					.map(el => ({
						...el,
						isDefault: account.isDefault ? false : el.isDefault,
						isSelected: false,
					}));

			const allAccounts = [
				...accounts,
				{
					...account,
					isSelected: true,
				},
			].sort((a, b) => {
				const firstBankName = a.bankName.toLowerCase();
				const secondBankName = b.bankName.toLowerCase();
				if (firstBankName < secondBankName) {
					return -1;
				}
				if (firstBankName > secondBankName) {
					return 1;
				}
				return 0;
			});

			return {
				all: allAccounts,
				isFetching: false,
			};
		case UserActionsConstants.UPDATE_BANK_ACCOUNT_SUCCESS:
			const { updatedAccount } = action.payload;
			const newUpdatedAccount = {
				...updatedAccount,
				isSelected: true,
			};

			const currentAccounts: BankAccount[] =
				state.all
					.map(el => ({
						...el,
						isDefault: updatedAccount.isDefault ? false : el.isDefault,
						isSelected: false,
					}));

			const updateIndex = currentAccounts.findIndex(currentAccount => currentAccount.id === updatedAccount.id);
			if (updateIndex !== -1) {
				currentAccounts[updateIndex] = newUpdatedAccount;
			}

			currentAccounts.sort((a, b) => {
				const firstBankName = a.bankName.toLowerCase();
				const secondBankName = b.bankName.toLowerCase();
				if (firstBankName < secondBankName) {
					return -1;
				}
				if (firstBankName > secondBankName) {
					return 1;
				}
				return 0;
			});

			return {
				all: currentAccounts,
				isFetching: false,
			};

		case UserActionsConstants.DELETE_BANK_ACCOUNT_SUCCESS:
			const { accountId } = action.payload;

			const afterDeleteAccounts = state.all.filter((acc) => acc.id !== accountId);

			afterDeleteAccounts.sort((a, b) => {
				const firstBankName = a.bankName.toLowerCase();
				const secondBankName = b.bankName.toLowerCase();
				if (firstBankName < secondBankName) {
					return -1;
				}
				if (firstBankName > secondBankName) {
					return 1;
				}
				return 0;
			});

			return {
				all: afterDeleteAccounts,
				isFetching: false,
			};

		case UserActionsConstants.ADD_BANK_ACCOUNT_FAILED:
			return {
				...state,
				isFetching: false,
			};
		default:
			return state;
	}
};
