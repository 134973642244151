<div class="l-container" [class.l-container-mobile]='data.isMobile'>
	<h3 class="mb-3">Transfer Tickets Back</h3>
	<div class="mb-4">
		Below you can transfer tickets back to the original purchaser.
	</div>
	<ng-container *ngIf="data.eventIsCancelled">
		<div class="l-status-message mb-3"> As this event has been cancelled if you proceed the original
			purchaser will be able to claim the refund. If you believe the refund is due to you please <span
				id='contact-us-link' (click)="handleContactHost()" class="g-link ">click here</span> to
			contact the organiser
			directly.</div>
	</ng-container>

	<div class="l-checkbox-container">
		<mat-checkbox #confirm='ngModel' name='confirm' [(ngModel)]='confirmation' required></mat-checkbox>
		<p class="m-0">
			I understand that this action cannot be reversed.
		</p>
	</div>

	<div [class.l-buttons-container]="!data.isMobile">
		<button id='cancel-transfer-back-modal' class="g-secondary-button" (click)="handleCancel()" [disabled]="isLoading"
			[ngClass]="{ 'g-disabled': isLoading, 'w-100 mb-2': data.isMobile}">CANCEL</button>
		<button id='transfer-back' class="g-primary-button" [class.w-100]="data.isMobile" (click)="handleTransferBack()"
			[disabled]="!confirmation || isLoading" [ngClass]="{ disabled: !confirmation || isLoading}">
			<div *ngIf='!isLoading; else showLoading'>
				TRANSFER {{ data.isMobile ? '' : 'BACK' }}
			</div>
			<ng-template #showLoading>
				<mat-spinner class='g-button-spinner'></mat-spinner>
			</ng-template>
		</button>
	</div>
</div>
<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
