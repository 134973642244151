import { Component, EventEmitter, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ActivatedRoute } from '@angular/router';
import { MyTickets } from '@app/models/user.model';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { StoreService } from '@app/services/store/store.service';
import { GetUserMyTickets } from '@app/store/actions/user/user.actions';
import * as userSelectors from '@app/store/selectors/user.selector';
import { take, takeUntil } from 'rxjs';
import { MobileFilterBottomsheetComponent } from '../../../../shared/mobile-filter-bottomsheet/mobile-filter-bottomsheet.component';
import { MY_TICKETS_HEADER, MyTicketFilters } from '@app/utils/consts';
import { FilterGroup, FilterOption, SearchAndFilter, SortOption, TicketFilterGroups } from '@app/models/filter-and-sort.model';
import { HeaderContent } from '@app/models/shared';
import { InternalURLCreator, URLCreator } from '@app/services/url/url.dictionary';
import { Actions, ofType } from '@ngrx/effects';
import { UserActionsConstants } from '@app/store/actions/user/user.actions.constants';
import { cloneDeep } from 'lodash';
import { Go } from '@app/store/actions/router/router.actions';

export const TICKET_ID_PARAM_KEY = 'id';

@Component({
	selector: 'app-my-tickets',
	templateUrl: './my-tickets.component.html',
	styleUrls: ['./my-tickets.component.sass'],
})
export class MyTicketsComponent implements OnInit {
	headerContent: HeaderContent = MY_TICKETS_HEADER['myTickets'];
	myTickets: MyTickets[];
	filteredTickets: MyTickets[];

	isMobile = false;

	currentTicketFilter: FilterOption;
	numberAppliedFilters: number;
	filterOptions: FilterGroup[] = MyTicketFilters;
	currentSearchTerm = '';
	currentTicketSort: SortOption;

	cartId = 0;
	hasCartIdParam = false;

	isTicketsLoading = true;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private store: StoreService,
		private route: ActivatedRoute,
		private breakpointService: BreakpointService,
		private bottomSheet: MatBottomSheet,
		private actions$: Actions
	) {}

	ngOnInit() {
		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});

		this.route.queryParams.pipe(take(1)).subscribe((params) => {
			this.cartId = Number(params['cartId']) || 0;
			if (this.cartId !== 0) {
				this.hasCartIdParam = true;
			}
			this.store.dispatch(new GetUserMyTickets({ cartId: this.cartId }));
		});

		this.actions$
			.pipe(
				ofType(UserActionsConstants.GET_USER_MY_TICKETS_SUCCESS, UserActionsConstants.GET_USER_MY_TICKETS_FAILED),
				takeUntil(this.destroyed$)
			)
			.subscribe(() => {
				setTimeout(() => (this.isTicketsLoading = false), 500);
			});

		this.initializeTickets();
	}

	initializeTickets() {
		this.store
			.select(userSelectors.userMyTickets())
			.pipe(takeUntil(this.destroyed$))
			.subscribe((myTickets) => {
				this.myTickets = myTickets || [];
				this.filteredTickets = [...this.myTickets];
				this.handleSort(SortOption.MostRecent);
				this.currentTicketSort = undefined;
			});
	}

	handleBottomSheetFilter() {
		const bottomSheetRef = this.bottomSheet.open(MobileFilterBottomsheetComponent, {
			data: {
				filterOptions: this.filterOptions,
				currentTicketFilter: this.currentTicketFilter,
				currentTicketSort: this.currentTicketSort,
				showFilterOptions: true,
			},
		});
		bottomSheetRef
			.afterDismissed()
			.pipe(take(1))
			.subscribe((result: { sortOption: SortOption; filterOption: FilterOption }) => {
				if (result) {
					if (result.sortOption) {
						this.currentTicketSort = result.sortOption;
					}
					const searchAndFilter = { searchTerm: this.currentSearchTerm, filterOption: result.filterOption };
					this.handleTicketFilterAndSearch(searchAndFilter);
				}
			});
	}

	handleTicketFilterAndSearch(searchAndFilter: SearchAndFilter) {
		const { searchTerm, filterOption } = searchAndFilter;
		this.currentTicketFilter = filterOption;
		this.currentSearchTerm = searchTerm;
		this.filteredTickets = this.myTickets;

		if (this.currentTicketSort || this.currentTicketFilter) {
			this.numberAppliedFilters =
				this.currentTicketSort && this.currentTicketFilter ? 2 : this.currentTicketSort || this.currentTicketFilter ? 1 : 0;
		}
		if (filterOption?.filterType === TicketFilterGroups.status) {
			this.filteredTickets = this.myTickets.filter((ticket) => ticket.productStatus === filterOption?.selectedFilter.value);
		}

		if (searchTerm) {
			const lowerCaseSearchTerm = searchTerm.toLowerCase();
			this.filteredTickets = this.filteredTickets.filter(
				(ticket) =>
					ticket.productName.toLowerCase().includes(lowerCaseSearchTerm) ||
					ticket.reference.toLowerCase().includes(lowerCaseSearchTerm)
			);
		}

		this.handleSort(this.currentTicketSort);
	}

	handleMobileSearchTerm(searchTerm: string) {
		this.currentSearchTerm = searchTerm;
		const searchAndFilter = { searchTerm: searchTerm, filterOption: this.currentTicketFilter };
		this.handleTicketFilterAndSearch(searchAndFilter);
	}

	handleSort(sortOption: SortOption) {
		if (!sortOption) {
			return;
		}

		const sortFunctions = {
			[SortOption.StartDate]: (a, b) => new Date(a.dateFrom).getTime() - new Date(b.dateFrom).getTime(),
			[SortOption.NameAscending]: (a, b) => a.productName.localeCompare(b.productName),
			[SortOption.MostRecent]: (a, b) => (b.id ? b.id : b.productId) - (a.id ? a.id : a.productId),
			[SortOption.NameDescending]: (a, b) => b.productName.localeCompare(a.productName),
		};

		const sortAndAssign = (source, targetProp, sortFunc) => {
			if (source) {
				const sourceCopy = cloneDeep(source);
				this[targetProp] = sourceCopy.sort(sortFunc);
			}
		};

		const sortFunction = sortFunctions[sortOption];
		sortAndAssign(this.filteredTickets, 'filteredTickets', sortFunction);
		this.currentTicketSort = sortOption;
	}

	handleRemoveCartFilter() {
		this.isTicketsLoading = true;
		this.store.dispatch(new GetUserMyTickets({ cartId: 0 }));
		this.hasCartIdParam = false;
	}

	handleNavigationBack() {
		this.store.dispatch(new Go({ path: [InternalURLCreator.myAccount()] }));
	}

	handleRouterLink(id: string): string {
		return InternalURLCreator.manageBooking(id);
	}

	checkNoResults() {
		return (
			(this.currentTicketFilter && !Object.values(this.currentTicketFilter).every((value) => value === undefined)) ||
			this.currentSearchTerm !== '' ||
			this.myTickets.length > 0
		);
	}

	handleEventNavigation() {
		window.open(URLCreator.eventsPage());
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
