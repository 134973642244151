<div class="l-additional-info" [ngClass]="{'gap-0': isMobile && !(allowPublicResale && allowPrivateResale)}">
	<hr *ngIf="isBankAccountModal" />
	<div class='mb-2' *ngIf="allowPublicResale && allowPrivateResale">
		<div class="g-info-block p-3 gap-3">
			<mat-icon class="g-info-block-icon">info</mat-icon>
			<p class="g-info-block-msg mb-0">
				Please select if you would like your resale to be public or private. If you want your
					resale to be private, please input their email address.
			</p>
		</div>
	</div>
	<div *ngIf="allowPrivateResale && !allowPublicResale">
		<div class="g-info-block p-3 gap-3">
			<mat-icon class="g-info-block-icon">info</mat-icon>
			<p class="g-info-block-msg mb-0">
				Please enter the email address of the person you want to resell your ticket to.
			</p>
		</div>
	</div>
	<div class="l-input input-group w-100" *ngIf="allowPrivateResale">
		<label class="g-form-label">Email</label>
		<input #emailInput="ngModel" [(ngModel)]="email" type="email" name="email" class="form-control w-100"
			placeholder="jane@doe.com" [pattern]='emailRegex' [required]="!isPublic" [disabled]="isPublic"
			(ngModelChange)="emitAdditionalInfo(emailInput.valid)" />
		<div *ngIf="!isPublic && emailInput.invalid && emailInput.touched" class="g-error-input">
			Please enter a valid email address.
		</div>

	</div>
	<mat-slide-toggle class="standard-toggle" 
		*ngIf="allowPublicResale && allowPrivateResale" 
		[(ngModel)]="isPublic" 
		(ngModelChange)="emitAdditionalInfo(isPublic)"
	>
		<label class="ps-2 pt-2 g-form-label">Make resale public</label>
	</mat-slide-toggle>
</div>
