import { Component, EventEmitter, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { NormalizedScheme, SchemeID } from '@app/models/dataSchema.model';
import { CreateEdit, EventScanner } from '@app/models/event.model';
import { FilterOptions, HeaderContent } from '@app/models/shared';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { StoreService } from '@app/services/store/store.service';
import { InternalURLCreator, URLCreator } from '@app/services/url/url.dictionary';
import { EVENT_ID_PARAM_KEY } from '@app/utils/consts';
import { take, takeUntil } from 'rxjs';
import * as eventSelectors from '@app/store/selectors/event.selector';
import { ScheduleItem } from '@app/models/schedule.model';
import { Actions, ofType } from '@ngrx/effects';
import { EventActionsConstants } from '@app/store/actions/event/event.actions.constants';
import { CreateScannerModalComponent } from './modals/create-scanner-modal/create-scanner-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { AddEventScanner, EditEventScanner, GetEventScanners, NewPinEventScanner } from '@app/store/actions/event/event.actions';
import { ReadMoreModalComponent } from './modals/read-more-modal/read-more-modal.component';
import { MatSort } from '@angular/material/sort';

@Component({
	selector: 'app-event-scanners',
	templateUrl: './event-scanners.component.html',
	styleUrls: ['./event-scanners.component.sass'],
})
export class EventScannersComponent {

	isMobile = false;
	eventId: SchemeID;
	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	isProductsLoading = true;
	isProductsFiltering: boolean;

	customDescription = `This application will allow you to check-in guests using multiple computers. It also allows
	for offline scanning and will automatically update itself when there is an internet connection available...`;
	readMoreText = 'Read More';

	scanners: MatTableDataSource<EventScanner> = new MatTableDataSource();
	scannersNormalized: NormalizedScheme<EventScanner>;
	displayColumns: string[] = ['scannerName', 'pin', 'login link', 'actions'];
	sortInitialized = false;

	headerContent: HeaderContent = {
		breadCrumbs: [
			{
				routeName: 'On The Day',
				routeTo: () => InternalURLCreator.scanners(this.eventId),
			},
			{
				routeName: 'Scanners',
			},
		],
		title: 'Scanners',
		description: 'Welcome to the Quicket scanning application. This application will allow you to check-in '
			+ 'guests using multiple computers. It also allows for offline scanning and will automatically update '
			+ 'itself when there is an internet connection available.\n\n'
			+ 'To get started add a scanner below. When you are ready click the \'Login\' link next to the scanner you '
			+ 'want to use. Multiple scanners allow you to track where guests are entering your event. Check-in data '
			+ 'is available on you guest list.\n\n'
			+ 'Alternatively, download the Quicket barcode scanning app on your Android or Apple. The app allows '
			+ 'barcode to be scanned using your phone or tablet\'s camera. If you are using the App you do not need '
			+ 'to add scanners below.\n\n'
			+ 'To get the app, please click the badge below:',
	};
	scheduleItems: ScheduleItem[];
	isOngoing: boolean;
	selectedScheduleItemId: SchemeID = 0;

	@ViewChild(MatSort) set sort(_sort: MatSort) {
		if (_sort && !this.sortInitialized) {
			this.sortInitialized = true;
			this.scanners.sort = _sort;
		}
	};

	constructor(
		private breakpointService: BreakpointService,
		private activatedRoute: ActivatedRoute,
		private store: StoreService,
		private actions$: Actions,
		private dialog: MatDialog
	) { }

	ngOnInit(): void {
		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});

		this.activatedRoute.parent.paramMap.pipe(take(1)).subscribe((params) => {
			this.eventId = +params.get(EVENT_ID_PARAM_KEY);
			this.store.dispatch(new GetEventScanners({ id: this.eventId, scheduleItemId: this.selectedScheduleItemId }));
		});

		this.store.select(eventSelectors.eventMetadata(this.eventId)).pipe(takeUntil(this.destroyed$)).subscribe((eventMetaData) => {
			if (eventMetaData) {
				this.scheduleItems = eventMetaData.scheduleItems.filter(item => !item.deleted);
				this.isOngoing = eventMetaData.isOngoing;
			}
		});

		this.actions$
			.pipe(
				ofType(
					EventActionsConstants.GET_EVENT_SCANNERS_SUCCESS,
					EventActionsConstants.GET_EVENT_SCANNERS_FAILED,
					EventActionsConstants.EDIT_EVENT_SCANNERS_SUCCESS,
					EventActionsConstants.EDIT_EVENT_SCANNERS_FAILED,
					EventActionsConstants.ADD_EVENT_SCANNERS_SUCCESS,
					EventActionsConstants.ADD_EVENT_SCANNERS_FAILED,
					EventActionsConstants.NEW_PIN_EVENT_SCANNER_SUCCESS,
					EventActionsConstants.NEW_PIN_EVENT_SCANNER_FAILED),
				takeUntil(this.destroyed$)
			)
			.subscribe(() => {
				this.isProductsLoading = false;
				this.isProductsFiltering = false;
			});

		this.store.select(eventSelectors.EventScanners(this.eventId)).pipe(takeUntil(this.destroyed$)).subscribe((scanners) => {
			if (scanners) {
				this.scanners.data = scanners;
			}
		});
	}

	handleScheduleFilterOutput(scheduleFilterOutput: ScheduleItem) {
		this.selectedScheduleItemId = scheduleFilterOutput.id;
		this.store.dispatch(new GetEventScanners({ id: this.eventId, scheduleItemId: this.selectedScheduleItemId }));
	}

	openCreateScannerModal() {
		this.dialog.open(CreateScannerModalComponent,
			{
				data: {
					isMobile: this.isMobile,
					scanner: null,
					productId: this.eventId,
					actionType: CreateEdit.Create,
					modalTitle: 'Add Event Scanner',
				},
				panelClass: 'g-standard-dialog',
			}
		)
			.afterClosed()
			.subscribe((newScanner) => {
				if (newScanner) {
					this.store.dispatch(new AddEventScanner({
						id: this.eventId,
						scanner: newScanner,
						scheduleItemId: this.selectedScheduleItemId,
					}));
				}
			});
	}

	handleReadMore() {
		this.dialog.open(ReadMoreModalComponent, {
			data: {
				isMobile: this.isMobile,
				modalTitle: 'Read More...',
				modalDescription: this.headerContent.description,
			},
		});
	}

	handleFilter(filterOptions: FilterOptions) {
		const { searchTerm } = filterOptions;
		this.scanners.filter = searchTerm.trim().toLowerCase();
	}

	handleEdit(scanner: EventScanner) {
		this.dialog.open(CreateScannerModalComponent,
			{
				data: {
					isMobile: this.isMobile,
					scanner: scanner,
					productId: this.eventId,
					actionType: CreateEdit.Edit,
					modalTitle: 'Edit Event Scanner',
				},
				panelClass: 'g-standard-dialog',
			}
		)
			.afterClosed()
			.subscribe((newScanner) => {
				if (newScanner) {
					this.store.dispatch(new EditEventScanner({
						id: this.eventId,
						scanner: newScanner,
						scheduleItemId: this.selectedScheduleItemId,
					}));
				}
			});
	}

	handleGenerateNewPin(scanner: EventScanner) {
		this.store.dispatch(new NewPinEventScanner({ id: this.eventId, scanner: scanner, scheduleItemId: this.selectedScheduleItemId }));
	}

	handleLogin(shortlink: string) {
		window.open('https://qkt.io/' + shortlink, '_blank');
	}

	handleDownloadAndroid() {
		window.open(URLCreator.downloadScannerAppAndroid(), '_blank');
	}

	handleDownloadIos() {
		window.open(URLCreator.downloadScannerAppIos(), '_blank');
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
