import { NormalizedScheme, SchemeID } from './dataSchema.model';

export interface ReportItem {
	date: Date;
	value: number;
}

export interface ReportKeyedItem {
	key: SchemeID;
	value: number;
}

export enum ReportGroupingLevel {
	DAY = 'day',
	MONTH = 'month',
	YEAR = 'year',
}

export interface ReportSheetItem {
	subitems?: SchemeID[];
	total: number;
}

export interface ReportSheet {
	day: NormalizedScheme<ReportSheetItem>;
	month: NormalizedScheme<ReportSheetItem>;
	year: NormalizedScheme<ReportSheetItem>;
	total: number;
	from: Date;
	topLevelGrouping: ReportGroupingLevel;
	to: Date;
}
