import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'formattedAccountNumberPipe',
})
export class FormattedAccountNumberPipePipe implements PipeTransform {

	transform(accountNumber: string): string {
		return '**** ' + accountNumber.slice(-4);
	}

}
