import { Component, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SchemeID } from '@app/models/dataSchema.model';
import { HeaderContent } from '@app/models/shared';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { InternalURLCreator, URLCreator } from '@app/services/url/url.dictionary';
import { EVENT_ID_PARAM_KEY } from '@app/utils/consts';
import { take, takeUntil } from 'rxjs';

@Component({
	selector: 'app-promoter-network',
	templateUrl: './promoter-network.component.html',
	styleUrls: ['./promoter-network.component.sass'],
})
export class PromoterNetworkComponent {
	url: string;
	eventId: SchemeID;
	networkId: number;
	networkName: string;
	isMobile = false;

	headerContent: HeaderContent = {
		breadCrumbs: [
			{
				routeName: 'Promoter Networks',
				routeTo: () => InternalURLCreator.eventPromoterNetworks(this.eventId),
			},
		],
		title: 'Promoter Network',
	};

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(private activatedRoute: ActivatedRoute, private breakpointService: BreakpointService) { }


	ngOnInit(): void {
		this.activatedRoute.parent.paramMap.pipe(take(1)).subscribe((params) => {
			this.eventId = +params.get(EVENT_ID_PARAM_KEY);

			this.activatedRoute.params.pipe(take(1)).subscribe((promoterNetworkId) => {
				const promoterNetworkInfo = promoterNetworkId.networkId.split('-');
				this.networkId = +promoterNetworkInfo[0];
				this.networkName = promoterNetworkInfo.slice(1).join('-');
				this.url = URLCreator.promoterNetwork(this.eventId, this.networkId);
				this.headerContent.breadCrumbs.push({
					routeName: this.networkName,
				});
				this.headerContent.title = this.networkName;
			});
		});

		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
