import { NgModule } from '@angular/core';
import { AppGuard } from '@app/services/guards/app.guard';
import { CanDeactivateEventPage, CanActivateEventChild } from '@app/services/guards/event.guard';
import { CanActivateEvent } from './event.guard';
import { CanDeactivateUserPage } from './user.guard';

@NgModule({
	providers: [
		AppGuard,
		CanDeactivateEventPage,
		CanActivateEventChild,
		CanActivateEvent,
		CanDeactivateUserPage,
	],
})
export class GuardsModule { }
