<div
	*ngIf="!isProductsLoading else productsLoading"
	class="d-flex flex-column"
	[ngClass]="{'mt-1': isMobile}"
>
	<div class='mt-0 pb-4'>
		<h3
			class="mb-3"
			*ngIf="!isMobile"
		>My Events</h3>
		<div *ngIf="!hasNoEvents else noProducts">
			<p
				class="mb-3"
				*ngIf="!isMobile"
			>Below is a list of events that you own or have access to that are hosted on Quicket.</p>
			<app-web-filter
				class='mt-3'
				[organiserProfiles]='organiserNames'
				[eventStatuses]='eventStatuses'
				[isMobile]="isMobile"
				buttonText="CREATE EVENT"
				(filterOutput)='handleFilterOutput($event)'
				(buttonClick)='handleCreationNavigation()'
			></app-web-filter>
			<div class="l-table-container">
				<div
					*ngIf="isProductsFiltering"
					class='l-filtering'
				>
					<mat-spinner></mat-spinner>
				</div>
				<table
					mat-table
					[dataSource]="products"
					class='l-table web'
					matSort
					(matSortChange)="handleSorting($event)"
				>
					<ng-container matColumnDef="details">
						<th
							mat-header-cell
							*matHeaderCellDef
							mat-sort-header
						> Event Details </th>
						<td
							mat-cell
							*matCellDef="let product; let i = index"
							[attr.id]="'event-details-' + i"
							class="l-cell"
						>
							<a
								[routerLink]="[handleRouterLink(product.id)]"
								class="l-link"
							>
								<div class="l-product-details">
									<img
										[src]="product.image"
										class="l-image"
									/>
									<div class="l-details">
										<div
											class="l-text"
											[attr.id]="'org-name-' + i"
										>
											{{ product.organiserName }}
										</div>
										<div
											class="l-event-name"
											[attr.id]="'event-name-' + i"
										>
											<h3 *ngIf='product.name; else draftName'>{{ product.name}}</h3>
											<ng-template #draftName>
												<h3 class='g-orange'>Requires Name</h3>
											</ng-template>
										</div>
										<div
											class="l-text"
											[attr.id]="'event-start-date-' + i"
										>
											<div *ngIf='product.date; else emptyDate'>{{ product.date |
												formattedDatePipe }}
											</div>
											<ng-template #emptyDate>
												<div class='g-orange'>No Date Set</div>
											</ng-template>
										</div>
									</div>
								</div>
							</a>
						</td>
					</ng-container>
					<ng-container matColumnDef="date">
						<th
							mat-header-cell
							*matHeaderCellDef
							mat-sort-header
							class="l-start-date-header"
						> Start Date </th>
						<td
							mat-cell
							*matCellDef="let product; let i = index"
							[attr.id]="'date-' + i"
							class="l-cell l-start-date"
						></td>
					</ng-container>
					<ng-container matColumnDef="id">
						<th
							mat-header-cell
							*matHeaderCellDef
							mat-sort-header
						> ID </th>
						<td
							mat-cell
							*matCellDef="let product; let i = index"
							[attr.id]="'event-id-' + i"
							class="l-cell"
						><a
								[routerLink]="[handleRouterLink(product.id)]"
								class="l-link"
							>{{ product.id }}</a></td>
					</ng-container>
					<ng-container matColumnDef="quantity">
						<th
							mat-header-cell
							*matHeaderCellDef
							mat-sort-header
						> Tickets Sold </th>
						<td
							mat-cell
							*matCellDef="let product; let i = index"
							[attr.id]="'event-tickets-sold-' + i"
							class="l-cell"
						><a
								[routerLink]="[handleRouterLink(product.id)]"
								class="l-link"
							>{{ product.ticketCount }}</a></td>
					</ng-container>
					<ng-container matColumnDef="status">
						<th
							mat-header-cell
							*matHeaderCellDef
						> Status </th>
						<td
							mat-cell
							*matCellDef="let product; let i = index"
							[attr.id]="'event-status-' + i"
							class="l-cell"
						><a
								[routerLink]="[handleRouterLink(product.id)]"
								class="l-link"
							>
								<div class='l-info'>
									<div
										class="l-badge"
										[ngClass]="handleStatusClass(product.status)"
									>
										{{ handleStatusMapping(product.status) }}
									</div>
								</div>
							</a>
						</td>
					</ng-container>
					<ng-container matColumnDef="access">
						<th
							mat-header-cell
							*matHeaderCellDef
						> Access </th>
						<td
							mat-cell
							*matCellDef="let product; let i = index"
							[attr.id]="'event-access-' + i"
							class="l-cell"
						><a
								[routerLink]="[handleRouterLink(product.id)]"
								class="l-link"
							>
								<div class='l-info'>
									<div
										class="l-badge"
										[ngClass]="getAccessClass(product)"
									>
										{{ getTypeContent(product) }}
									</div>
								</div>
							</a>
						</td>
					</ng-container>
					<tr
						mat-header-row
						*matHeaderRowDef="displayColumns"
					></tr>
					<tr
						mat-row
						*matRowDef="let row; columns: displayColumns;"
					></tr>
				</table>
				<div
					*ngIf="!products.length"
					class="no-data-container"
				>
					<div class="l-empty-state">
						<h3 class="mb-3">Nothing to see here...</h3>
						<div class="mb-3">Nothing matches your search or filters</div>
					</div>
				</div>
			</div>
			<div
				*ngIf="products.length"
				class="l-paginator-container"
			>
				<mat-paginator
					class="w-100"
					#productsPaginator
					[length]='totalProducts'
					[pageSizeOptions]="pageSizeOptions"
					[pageIndex]="currentPageIndex"
					(page)="handlePagination($event)"
				></mat-paginator>
			</div>
			<button
				id='download-report-button-mobile'
				*ngIf='isMobile'
				class='g-greyed-button w-100 mt-3'
				(click)="handleEmailReport()"
			><mat-icon class='l-icon'>save_alt</mat-icon>EMAIL
				REPORT</button>
		</div>
	</div>
	<ng-template #noProducts>
		<div class='l-empty-card'>
			<app-no-products-card
				title="No Events"
				content="You currently have no events. Want to change that?"
				[buttonText]="'CREATE EVENT'"
				(buttonClick)='handleCreationNavigation()'
			></app-no-products-card>
		</div>
	</ng-template>
</div>
<ng-template #productsLoading>
	<div class="l-loading">
		<mat-spinner></mat-spinner>
	</div>
</ng-template>