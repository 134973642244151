import { Component, EventEmitter } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SchemeID } from '@app/models/dataSchema.model';
import { HeaderContent } from '@app/models/shared';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { StoreService } from '@app/services/store/store.service';
import { InternalURLCreator } from '@app/services/url/url.dictionary';
import { EVENT_ID_PARAM_KEY } from '@app/utils/consts';
import { Observable, take, takeUntil } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import * as eventSelectors from '@app/store/selectors/event.selector';
import { EventWebhooks } from '@app/models/event.model';
import { websiteValidator } from '@app/shared/form-field/form-validators';
import { FormSchemeControls, FormValidators } from '@app/models/common.model';
import { FormService } from '@app/services/form/form.service';
import { GetEventWebhooks, UpdateEventWebhooks } from '@app/store/actions/event/event.actions';
import { EventActionsConstants } from '@app/store/actions/event/event.actions.constants';
import { MatDialog } from '@angular/material/dialog';
import { TestWebhooksModalComponent } from '../modals/test-webhooks-modal/test-webhooks-modal.component';
import { FormCanDeactivate } from '@app/services/guards/event.guard';
import { ConfirmationModalComponent } from '@app/shared/modals/confirmation-modal/confirmation-modal.component';


@Component({
	selector: 'app-webhooks',
	templateUrl: './webhooks.component.html',
	styleUrls: ['./webhooks.component.sass'],
})
export class WebhooksComponent extends FormCanDeactivate{
	eventId: SchemeID;
	isMobile = false;
	isLoading = true;
	form: UntypedFormGroup;
	isSavingChanges = false;
	showPOSTExample = false;
	POSTExampleText = 'Show POST Example';


	checkoutStartedTooltip = 'Enter in the URL to which the HTTP POST will be made when checkout is started.';
	checkoutCompletedTooltip = `Enter in the URL to which the HTTP POST will be made when checkout is completed / paid. 
								If EFT payment option is used, checkout completed will fire only once payment confirmation is received`;
	checkoutCancelledTooltip = `Enter in the URL to which the HTTP POST will be made when checkout process is cancelled. 
								Checkout cancels if the booking time limit is reached without successful completion or 
								if EFT payment method was used but no payment confirmation was received.`;
	eftPendingTooltip = `Enter in the URL to which the HTTP POST will be made when checkout is put into
						 hold state while awaiting confirmation of EFT payment. 
						This only happens if client elects to pay by EFT.`;

	POSTExample = `{
		"reference":"REF00123456",
		"event_id":123,
		"event_name":"My Big Event",
		"amount":0.00,
		"email":"demo@example.com",
		"event_date": "2015-02-18 17:15", //Start date of the event, if schedule will be the date of the schedule
		"action":"checkout_started", 
				// options are "checkout_started","checkout_cancelled","eft_pending","checkout_completed"
		"tickets":[
						{
							"id":122,
							"attendee_name":"",
							"attendee_lastname":"",
							"attendee_email":"",
							"ticket_type":"Free Ticket",
							"price":0.00,
							"barcode": 12345678 //Barcode is 0 on checkout_started
						},
						{
							"id":122,
							"attendee_name":"",
							"attendee_lastname":"",
							"attendee_email":"",
							"ticket_type":"Free Ticket",
							"price":0.00,
							"barcode": 12345678 //Barcode is 0 on checkout_started
						}
					]
	}`;
	headerContent: HeaderContent = {
		breadCrumbs: [
			{
				routeName: 'Settings',
				routeTo: () => InternalURLCreator.settings(this.eventId),
			},
			{
				routeName: 'Integrations',
				routeTo: () => InternalURLCreator.integrations(this.eventId),
			},
			{
				routeName: 'Webhooks',
			},
		],
		title: 'Webhooks',
		description: `Our system can make an HTTP POST request to an endpoint
		of your choice the moment a purchase is created, paid
		for, booked by EFT or cancelled. The format is a JSON
		body containing all the purchase details.

		The HTTP POST will only be attempted once. If it fails
		for any reason it will not try again.`,
	};

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private breakpointService: BreakpointService,
		private store: StoreService,
		private activatedRoute: ActivatedRoute,
		private formService: FormService,
		private action$: Actions,
		private dialog: MatDialog
	) {
		super();
	}


	ngOnInit(): void {
		this.activatedRoute.parent.paramMap.pipe(take(1)).subscribe((params) => {
			this.eventId = +params.get(EVENT_ID_PARAM_KEY);
		});

		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});

		this.action$
			.pipe(
				ofType(EventActionsConstants.GET_EVENT_WEBHOOKS_SUCCESS, EventActionsConstants.GET_EVENT_WEBHOOKS_FAILED),
				takeUntil(this.destroyed$)
			)
			.subscribe(() => {
				this.isLoading = false;
			});

		this.action$
			.pipe(
				ofType(EventActionsConstants.UPDATE_EVENT_WEBHOOKS_SUCCESS, EventActionsConstants.UPDATE_EVENT_WEBHOOKS_FAILED),
				takeUntil(this.destroyed$)
			)
			.subscribe(() => {
				this.isSavingChanges = false;
			});

		this.store.dispatch(new GetEventWebhooks({ eventId: this.eventId }));

		this.store.select(eventSelectors.eventWebhooks(this.eventId)).pipe(takeUntil(this.destroyed$)).subscribe((webhooks) => {
			if (webhooks) {
				this.form = this.createWebhooksForm(webhooks);
			}
		});
	}

	createWebhooksForm(initialValues?: Partial<EventWebhooks>) {
		const formValues = new EventWebhooks();

		const validatorsConfig: FormValidators<Partial<EventWebhooks>> = {
			checkoutStarted: [websiteValidator()],
			checkoutCancelled: [websiteValidator()],
			checkoutCompleted: [websiteValidator()],
			eftPending: [websiteValidator()],
		};

		const formConfig: Partial<FormSchemeControls<EventWebhooks>> =
			this.formService.createFormControls({
				formValues,
				initialValues,
				validatorsConfig,
			});

		return this.formService.formBuilder.group(formConfig);
	}

	fromForm(controlKey: string): AbstractControl {
		if (this.form) {
			return this.form.get(controlKey);
		}
		return null;
	}

	isSubmitAllowed = (form: UntypedFormGroup): boolean => {
		if (form) {
			return this.form.valid && !this.isSavingChanges && !this.form.pristine;
		}
		return false;
	};

	proceedFormSubmit(form) {
		this.isSavingChanges = true;
		this.store.dispatch(new UpdateEventWebhooks({
			eventId: this.eventId,
			webhooks: {
				checkoutStarted: form.get('checkoutStarted').value,
				checkoutCancelled: form.get('checkoutCancelled').value,
				checkoutCompleted: form.get('checkoutCompleted').value,
				eftPending: form.get('eftPending').value,
			},
			isUpdating: true,
		}));
	}

	handleSendTest(webhook: string, type: string) {
		if (webhook) {
			this.dialog.open(TestWebhooksModalComponent, {
				data: {
					eventId: this.eventId,
					webhook: webhook,
					type: type,
					isMobile: this.isMobile,
				},
				panelClass: 'g-standard-dialog',
			});
		}
	}

	handleShowPostExample() {
		this.showPOSTExample = !this.showPOSTExample;
		this.POSTExampleText = this.showPOSTExample ? 'Hide POST Example' : 'Show POST Example';
	}

	isSendTestDisabled(control: string) {
		return !(this.fromForm(control).value && this.fromForm(control).valid);
	}

	isFormDeactivatable(): boolean | Observable<boolean> {
		return !this.form || this.form.pristine;
	}

	deactivationFallback(): Observable<boolean> {
		this.dialog
			.open(ConfirmationModalComponent, {
				data: {
					title: 'Unsaved Changes',
					text: 'Are you sure you want to leave this page? Any unsaved changes will be discarded.',
					buttonText: 'LEAVE',
					centerText: true,
					isMobile: this.isMobile,
				},
				panelClass: 'g-standard-dialog',
			})
			.afterClosed()
			.subscribe((result) => {
				this.closeDeactivationModal(result);
			});
		return this.modalCallback.asObservable();
	}

	closeDeactivationModal(isConfirmed: boolean): void {
		this.modalCallback.next(isConfirmed);
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
