import { Input, Directive } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FormFieldCore } from './form-field-core.model';

export enum FormFieldOffset {
	default = 'default',
	zero = 'zero',
	long = 'long',
}

export type FormFieldTooltipPosition = 'top' | 'bottom' | 'left' |'right';

@Directive()
export class FormField implements FormFieldCore {
	@Input() placeholder = '';
	@Input() readonly = false;
	@Input() required = false;
	@Input() control?: UntypedFormControl;
	@Input() tooltip?: string = null;
	@Input() tooltipPosition?: FormFieldTooltipPosition = 'top';
	@Input() label?: string;
	@Input() labelIcon?: string;
	@Input() offset: FormFieldOffset = FormFieldOffset.default;

	getError(errors: { [key: string]: string }) {
		return  errors && errors[Object.keys(errors)[0]];
	}

	getFormFieldClasses(offset: FormFieldOffset, ...classes: string[]) {
		const classList = {
			'form-field': true,
			'form-field__long-offset': offset === 'long',
			'form-field__offsetless': offset === 'zero',
		};

		if (classes && classes.length) {
			classes.forEach(key => {
				classList[key] = true;
			});
		}
		return classList;
	}
}
