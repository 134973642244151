import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SchemeID } from '@app/models/dataSchema.model';
import { EventTab } from '@app/models/event.model';
import { StoreService } from '@app/services/store/store.service';
import {
	DeleteTabContent, GetEventTabbedContent,
	ReorderTabbedContent, SetEventTabContentVisibility
} from '@app/store/actions/event/event.actions';
import { EventActionsConstants } from '@app/store/actions/event/event.actions.constants';
import * as eventSelectors from '@app/store/selectors/event.selector';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatTable } from '@angular/material/table';
import { tabContentTypeMap } from '@app/models/event.model';
import { FilterOptions } from '@app/models/shared';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '@app/shared/modals/confirmation-modal/confirmation-modal.component';



@Component({
	selector: 'app-tabbed-content-list',
	templateUrl: './tabbed-content-list.component.html',
	styleUrls: ['./tabbed-content-list.component.sass'],
})
export class TabbedContentListComponent {
	@Input() eventId: SchemeID;
	@Input() isMobile: boolean;
	@Output() addTab = new EventEmitter();
	@Output() editTab = new EventEmitter<EventTab>();

	displayColumns: string[] = ['sorting', 'tabName', 'tabType', 'visible', 'actions'];
	dragDisabled = true;
	hasSearchTerm = false;
	isTabsLoading = true;
	isEditingTabs = true;
	searchPlaceholder = 'Search';
	webFilterButtonText = 'ADD TAB';
	tabs: EventTab[];
	tabContentTypeMap = tabContentTypeMap;
	noResults = false;

	@ViewChild('tabsTable') table!: MatTable<any>;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private store: StoreService,
		private action$: Actions,
		private dialog: MatDialog
	) { }

	ngOnInit(): void {
		this.initializeTabbedContentList();
		this.action$
			.pipe(
				ofType(EventActionsConstants.GET_EVENT_TABBED_CONTENT_SUCCESS, EventActionsConstants.GET_EVENT_TABBED_CONTENT_FAILED),
				takeUntil(this.destroyed$)
			)
			.subscribe(() => {
				this.isTabsLoading = false;
			});

		this.store.select(eventSelectors.eventTabbedContent(this.eventId)).pipe(takeUntil(this.destroyed$)).subscribe((eventTabs) => {
			if (eventTabs) {
				this.tabs = eventTabs;
				this.isTabsLoading = false;
			};
		});
	}

	initializeTabbedContentList(): void {
		this.store.dispatch(new GetEventTabbedContent({ eventId: this.eventId }));
	}

	handleAddTab() {
		this.addTab.emit();
	}

	handleEditTab(tab: EventTab) {
		this.editTab.emit(tab);
	}

	handleWebFilterOutput(filterOutput: FilterOptions) {
		const filteredTabs = this.tabs.filter(tab =>
			tab.name.toLowerCase().includes(filterOutput.searchTerm.toLowerCase()) ||
			tabContentTypeMap.get(tab.type).toLowerCase().includes(filterOutput.searchTerm.toLowerCase())
		);
		if (this.table && this.table.dataSource){
			this.table.dataSource = filteredTabs;
		}
		this.hasSearchTerm = filterOutput.searchTerm?.length > 0;
		this.noResults = filterOutput.searchTerm && !filteredTabs.length;
	}

	handleTabVisibility(tab: EventTab) {
		this.store.dispatch(new SetEventTabContentVisibility({
			id: tab.id,
			eventId: this.eventId,
			isVisible: tab.isVisible ? false : true,
			prevValue: tab.isVisible,
		}));
	}

	handleTabDelete(tab: EventTab) {
		this.dialog.open(ConfirmationModalComponent, {
			data: {
				title: 'Delete Tab?',
				text: 'Are you sure you want to delete your tab',
				buttonText: 'YES',
				centerText: true,
				isMobile: this.isMobile,
			},
			panelClass: 'g-standard-dialog',
		})
			.afterClosed().subscribe((result) => {
				if (result) {
					this.store.dispatch(new DeleteTabContent({ eventId: this.eventId, id: tab.id }));
				}
			});
	}

	reOrderTabs(event: CdkDragDrop<string[]>) {
		this.dragDisabled = true;
		const prevOrder = this.tabs.map(tabIds => tabIds.id);
		moveItemInArray(this.tabs, event.previousIndex, event.currentIndex);
		const order = this.tabs.map(tabIds => tabIds.id);
		this.store.dispatch(new ReorderTabbedContent({ eventId: this.eventId, order: order, prevOrder: prevOrder }));
		this.table.renderRows();
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
