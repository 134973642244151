import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import { Observable, of, Subject, Subscription } from 'rxjs';
import { takeUntil, withLatestFrom } from 'rxjs/operators';

import { SchemeID } from '@app/models/dataSchema.model';
import { EventAtTheDoor } from '@app/models/event.model';

import { PermissionService, PermissionSnapshot } from '@app/services/permission/permission.service';
import { StoreService } from '@app/services/store/store.service';

import * as eventSelectors from '@app/store/selectors/event.selector';

import { GetEventAtTheDoor } from './at-the-door.actions';

import { ScheduleItem } from '@app/models/schedule.model';
import { getCookie, setCookie } from '@app/utils/Cookie';

@Component({
	selector: 'app-at-the-door',
	templateUrl: './at-the-door.component.html',
	styleUrls: ['./at-the-door.component.sass'],
})
export class EventAtTheDoorComponent implements OnInit, OnDestroy{

	paramsSub: Subscription;
	isAccessGranted$: Observable<boolean>;
	idWithPermission$: Observable<[Params, PermissionSnapshot]>;

	eventId: SchemeID;
	atTheDoor: EventAtTheDoor;

	isFetching$: Observable<boolean>;

	selectedScheduleItem: SchemeID;
	scheduleItems: ScheduleItem[];
	isScheduledEvent: boolean;

	cookieKey: string;

	destroy$: Subject<boolean> = new Subject<boolean>();

	constructor(
		private route: ActivatedRoute,
		private storeService: StoreService,
		public permissionService: PermissionService
	) {}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.complete();
	}

	ngOnInit(){
		this.isFetching$ = this.storeService.select(eventSelectors.isAtTheDoorFetching());

		this.paramsSub = this.route.parent.params
			.pipe(
				withLatestFrom(
					this.permissionService.createPermissionCheck()
				)
			)
			.subscribe(([{ id }, { isAccessGranted }]) => {
				this.eventId = id;
				this.cookieKey = `${this.eventId}.scheduleItemId`;
				this.selectedScheduleItem = this.getScheduleItem();

				this.isAccessGranted$ = of(isAccessGranted);
				if (isAccessGranted) {
					this.storeService.dispatch(
						new GetEventAtTheDoor({
							eventId: id,
							scheduleItemId: this.selectedScheduleItem,
						})
					);
				}
			});

		this.storeService.select(eventSelectors.eventAtTheDoor()).subscribe(atTheDoor => {
			this.atTheDoor = atTheDoor;
		});

		this.storeService.select(eventSelectors.eventMetadata(this.eventId))
			.pipe(
				takeUntil(this.destroy$)
			).subscribe(
				metadata => {
					if (metadata) {
						this.scheduleItems = metadata.scheduleItems;
						this.isScheduledEvent = metadata.isOngoing;
					};
				}
			);
	}

	redirectToDoorSale() {
		window.open(this.atTheDoor.url, '_blank');
	};

	onScheduleItemChange ($event: SchemeID): void {
		this.selectedScheduleItem = $event;

		this.setScheduleItemCookie();

		this.storeService.dispatch(
			new GetEventAtTheDoor({
				eventId: this.eventId,
				scheduleItemId: this.selectedScheduleItem,
			})
		);
	};

	addScheduleItemCookie(): void {
		setCookie(this.cookieKey, '0', 0.042);
	};

	getScheduleItem(): SchemeID {
		if (!getCookie(this.cookieKey)){
			this.addScheduleItemCookie();
			return 0;
		};
		return +getCookie(this.cookieKey);
	};

	setScheduleItemCookie(): void {
		setCookie(this.cookieKey, `${this.selectedScheduleItem}`, 0.042);
	};

	// Returns true for unscheduled event or selected schedule
	// Will show error content if no schedule is selected on scheduled event
	isValidSchedule(): boolean {

		if (this.isScheduledEvent && this.selectedScheduleItem === 0){
			return false;
		}

		return true;
	}

}
