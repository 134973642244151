import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { StoreService } from '@app/services/store/store.service';
import { Actions, ofType } from '@ngrx/effects';
import { switchMap, map, take } from 'rxjs/operators';
import { VerifyTokenSuccess, VerifyTokenFailed, VerifyToken } from '@app/store/actions/user/user.actions';
import { TokenService } from '@app/services/token/token.service';
import { UserActionsConstants } from '@app/store/actions/user/user.actions.constants';
import { environment } from 'environments/environment';
import { isUserVerified } from '@app/store/selectors/user.selector';
import { GuardPayload } from '@app/models/common.model';
import { URLService } from '@app/services/url/url.service';

@Injectable()
export class AppGuard implements CanActivate {
	constructor(
		private storeService: StoreService,
		private action$: Actions,
		private tokenService: TokenService,
		private router: Router,
		private urlService: URLService
	) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
		return this.storeService.select(isUserVerified())
			.pipe(
				take(1),
				switchMap(isReady => {
					const guardPayload: GuardPayload<VerifyTokenSuccess | VerifyTokenFailed> = {
						isReady,
						action: null,
					};

					const token = this.tokenService.getToken();

					if (isReady || !token) {
						return of(guardPayload);
					}


					if (token) {
						this.storeService.dispatch(new VerifyToken({ token }));

						return this.action$.pipe(
							ofType(
								UserActionsConstants.VERIFY_TOKEN_FAILED,
								UserActionsConstants.VERIFY_TOKEN_SUCCESS
							),
							map(action => ({
								...guardPayload,
								action,
							}))
						);
					}


				}),
				map(guardPayload => {
					/* eslint-disable-next-line max-len */
					if (guardPayload.isReady || (guardPayload.action && guardPayload.action.type === UserActionsConstants.VERIFY_TOKEN_SUCCESS)) {
						return true;
					}

					if (environment.production || environment.staging) {
						this.urlService.navigate('login', [encodeURIComponent(`/app/#${state.url}`)])();
					} else {
						this.router.navigate(['/login']);
					}

					return false;
				})
			);
	}
}
