import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { EventDomainAPI } from '@app/api/domains/event';
import { ofTypeExt, handleFailedRequest, handleSuccessRequest } from '@app/utils/Store';
import { EventActionsConstants } from '../actions/event/event.actions.constants';
import { withLatestFrom, switchMap, mergeMap, catchError } from 'rxjs/operators';
import { mapEventFrom, chartCategoryToSeatingCategory } from '@app/utils/mappers/event';
import { eventScheme } from '@app/schemes/event.schema';
import { throwError } from 'rxjs';
import { getResponseErrors } from '@app/utils/Response';
import { Go } from '../actions/router/router.actions';
import { defaultDashboardRoute } from '@app/services/url/url.service';
import { Store } from '@ngrx/store';
import { AppState } from '@app/models/store.model';
import { normalize } from 'normalizr';
import * as eventSelectors from '@app/store/selectors/event.selector';
import * as eventActions from '../actions/event/event.actions';
import { AppAction } from '@app/models/action.model';

@Injectable()
export class EventDashboardEffects {
	constructor(private action$: Actions, private store$: Store<AppState>, private api?: EventDomainAPI) {}

	getEventDetails$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.GetEventDetails>(EventActionsConstants.GET_EVENT_DETAILS),
			withLatestFrom(this.store$),
			switchMap(
				([
					{
						action,
						payload: { id },
					},
					store,
				]) =>
					this.api.getEventDetails(id).pipe(
						mergeMap((value) => {
							if (value.isSuccess && value.payload) {
								const product = mapEventFrom(value.payload);

								const { entities: normalizedEntity } = normalize(product, eventScheme);

								const actionsToProceed = [
									new eventActions.GetEventDetailsSuccess({
										id,
										normalizedEntity,
										entity: {
											original: {
												/* eslint-disable-next-line max-len */
												seatingCategories: chartCategoryToSeatingCategory(
													value.payload.seatingCategories,
													product.ticketsDetails.seatingChartId
												),
												seatsIOChartKey: value.payload.productDetails.seatsIOChartKey,
											},
										},
									}),
									new eventActions.GetPreviousVenues({ eventId: id }),
								];

								if (!eventSelectors.eventTypes()(store)) {
									actionsToProceed.push(new eventActions.GetEventTypes() as any);
								}

								return actionsToProceed as AppAction<any>[];
							} else {
								return throwError(getResponseErrors(value));
							}
						}),
						catchError(
							handleFailedRequest(
								new eventActions.GetEventDetailsFailed({
									msg: 'can\'t get event details',
									action,
									critical: true,
									meta: {
										extraAction: new Go({ path: [defaultDashboardRoute] }),
										extraActionTitle: 'Go to dashboard',
										allowClose: false,
									},
								})
							)
						)
					)
			)
		)
	);

	getHelpArticle = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.GetUserHelp>(EventActionsConstants.GET_USER_HELP),
			switchMap(({ action, payload: { type } }) =>
				this.api.getHelpArticle(type).pipe(
					handleSuccessRequest(
						({ payload: articleContent }) =>
							new eventActions.GetUserHelpSuccess({
								articleContent,
							})
					),
					catchError(
						handleFailedRequest(
							new eventActions.GetUserHelpFailed({
								msg: 'can\'t get help article',
								action,
							})
						)
					)
				)
			)
		)
	);

	setEventListing = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.SetEventListing>(EventActionsConstants.SET_EVENT_LISTING),
			switchMap(({ action, payload: { id, isPublic: valueToSet } }) =>
				this.api.setEventListing(id, valueToSet).pipe(
					handleSuccessRequest(
						({ payload: isPublic }) =>
							new eventActions.SetEventListingSuccess({
								id,
								isPublic,
							})
					),
					catchError(
						handleFailedRequest(
							new eventActions.SetEventListingFailed({
								msg: 'can\'t set event listing',
								action,
							})
						)
					)
				)
			)
		)
	);

	setEventServiceFee = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.SetEventServiceFee>(EventActionsConstants.SET_EVENT_SERVICE_FEE),
			switchMap(({ action, payload: { id, isServiceFeeForHost: valueToSet } }) =>
				this.api.setEventServiceFee(id, valueToSet).pipe(
					handleSuccessRequest(
						({ payload: isServiceFeeForHost }) =>
							new eventActions.SetEventServiceFeeSuccess({
								id,
								isServiceFeeForHost,
							})
					),
					catchError(
						handleFailedRequest(
							new eventActions.SetEventServiceFeeFailed({
								msg: 'can\'t set service fee to host',
								action,
							})
						)
					)
				)
			)
		)
	);

	submitEventForApproval = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.SubmitEventForApproval>(EventActionsConstants.SUBMIT_EVENT_FOR_APPROVAL),
			switchMap(({ action, payload: { id } }) =>
				this.api.submitEventForApproval(id).pipe(
					handleSuccessRequest(
						({ payload: status }) =>
							new eventActions.SubmitEventForApprovalSuccess({
								id,
								status,
							})
					),
					catchError(
						handleFailedRequest(
							new eventActions.SubmitEventForApprovalFailed({
								msg: 'can\'t submit event for approval',
								action,
							})
						)
					)
				)
			)
		)
	);

	getEventDashboard = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.GetEventDashboard>(EventActionsConstants.GET_EVENT_DASHBOARD),
			switchMap(({ action, payload: { id } }) =>
				this.api.getEventDashboard(id).pipe(
					handleSuccessRequest(
						({ payload: dashboard }) =>
							new eventActions.GetEventDashboardSuccess({
								id,
								dashboard,
							})
					),
					catchError(
						handleFailedRequest(
							new eventActions.GetEventDashboardFailed({
								critical: true,
								meta: {
									extraAction: new Go({ path: [defaultDashboardRoute] }),
									extraActionTitle: 'Go to My Events',
									allowClose: false,
								},
								msg: 'can\'t get event dashboard',
								action,
							})
						)
					)
				)
			)
		)
	);

	setEventShortLink = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.SetEventShortLink>(EventActionsConstants.SET_EVENT_SHORT_LINK),
			switchMap(({ action, payload: { id, link: valueToSet } }) =>
				this.api.setEventShortLink(id, valueToSet).pipe(
					handleSuccessRequest(
						({ payload: link }) =>
							new eventActions.SetEventShortLinkSuccess({
								id,
								link,
							})
					),
					catchError(
						handleFailedRequest(
							new eventActions.SetEventShortLinkFailed({
								msg: 'can\'t set event short link',
								action,
							})
						)
					)
				)
			)
		)
	);

	getEventDashboardReports = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.GetEventDashboardReports>(EventActionsConstants.GET_EVENT_DASHBOARD_REPORTS),
			switchMap(({ action, payload: { id, scheduleItemId } }) =>
				this.api.getEventDashboardReports(id, scheduleItemId).pipe(
					handleSuccessRequest(
						({ payload: data }) =>
							new eventActions.GetEventDashboardReportsSuccess({
								id,
								data,
							})
					),
					catchError(
						handleFailedRequest(
							new eventActions.GetEventDashboardReportsFailed({
								msg: 'can\'t get event dashboard reports',
								action,
							})
						)
					)
				)
			)
		)
	);

	changeEventDates = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.ChangeEventDates>(EventActionsConstants.CHANGE_EVENT_DATES),
			switchMap(({ action, payload: { id, startDateTime, endDateTime } }) =>
				this.api.changeEventDates(id, startDateTime, endDateTime).pipe(
					handleSuccessRequest(
						({ payload: { startDate, endDate } }) =>
							new eventActions.ChangeEventDatesSuccess({
								id,
								startDate,
								endDate,
							})
					),
					catchError(
						handleFailedRequest(
							new eventActions.ChangeEventDatesFailed({
								msg: 'can\'t change event dates',
								action,
							})
						)
					)
				)
			)
		)
	);

	cancelEvent = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.CancelEvent>(EventActionsConstants.CANCEL_EVENT),
			switchMap(({ action, payload: { id } }) =>
				this.api.cancelEvent(id).pipe(
					handleSuccessRequest(
						({ payload: { isAutoCancelled, productStatus } }) =>
							new eventActions.CancelEventSuccess({
								id,
								isAutoCancelled,
								productStatus,
							})
					),
					catchError(
						handleFailedRequest(
							new eventActions.CancelEventFailed({
								msg: 'can\'t cancel event',
								action,
							})
						)
					)
				)
			)
		)
	);

	sendInvites = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.SendInvites>(EventActionsConstants.SEND_INVITES),
			switchMap(({ action, payload: { eventId, contactsListId } }) =>
				this.api.sendInvites(eventId, contactsListId).pipe(
					handleSuccessRequest(() => new eventActions.SendInvitesSuccess()),
					catchError(
						handleFailedRequest(
							new eventActions.SendInvitesFailed({
								msg: 'can\'t send invites',
								action,
							})
						)
					)
				)
			)
		)
	);

	getFacebookIntegrationData = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.GetFacebookIntegrationData>(EventActionsConstants.GET_FACEBOOK_INTEGRATION_DATA),
			switchMap(({ action, payload: { eventId } }) =>
				this.api.getFacebookIntegrationData(eventId).pipe(
					handleSuccessRequest(
						({ payload: data }) =>
							new eventActions.GetFacebookIntegrationDataSuccess({
								eventId,
								data,
							})
					),
					catchError(
						handleFailedRequest(
							new eventActions.GetFacebookIntegrationDataFailed({
								msg: 'can\'t get event facebook data',
								action,
								critical: true,
								meta: {
									extraAction: new Go({ path: [defaultDashboardRoute] }),
									extraActionTitle: 'Go to dashboard',
									allowClose: false,
								},
							})
						)
					)
				)
			)
		)
	);

	updateFacebookEventData = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.UpdateEventFacebookData>(EventActionsConstants.UPDATE_EVENT_FACEBOOK_DATA),
			switchMap(({ action, payload: { eventId, data, token } }) =>
				this.api.updateEventFacebookData(eventId, data, token).pipe(
					handleSuccessRequest(
						({ payload }) =>
							new eventActions.UpdateEventFacebookDataSuccess({
								eventId,
								data: payload,
								isAdding: !data.facebookEventId,
							})
					),
					catchError(
						handleFailedRequest(
							new eventActions.UpdateEventFacebookDataFailed({
								msg: 'can\'t update event facebook data',
								action,
								critical: true,
							})
						)
					)
				)
			)
		)
	);

	scheduleEventPublic = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.ScheduleEventPublic>(EventActionsConstants.SCHEDULE_EVENT_PUBLIC),
			switchMap(({ action, payload: { id, scheduledToGoPublicOn } }) =>
				this.api.scheduleEventPublic(id, scheduledToGoPublicOn).pipe(
					handleSuccessRequest(
						({ payload }) =>
							new eventActions.ScheduleEventPublicSuccess({
								id,
								scheduledToGoPublicOn,
							})
					),
					catchError(
						handleFailedRequest(
							new eventActions.ScheduleEventPublicFailed({
								msg: 'can\'t set schedule to make Public',
								action,
							})
						)
					)
				)
			)
		)
	);

	deleteScheduleEventPublic = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.DeleteScheduleEventPublic>(EventActionsConstants.DELETE_SCHEDULE_EVENT_PUBLIC),
			switchMap(({ action, payload: { id } }) =>
				this.api.deleteScheduleEventPublic(id).pipe(
					handleSuccessRequest(
						({ payload }) =>
							new eventActions.DeleteScheduleEventPublicSuccess({
								id,
							})
					),
					catchError(
						handleFailedRequest(
							new eventActions.DeleteScheduleEventPublicFailed({
								msg: 'can\'t delete schedule to go public',
								action,
							})
						)
					)
				)
			)
		)
	);

	setEventCovidCompliant = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.SetEventCovidCompliant>(EventActionsConstants.SET_EVENT_COVID_COMPLIANT),
			switchMap(({ action, payload: { id, covidComplianceEnabled } }) =>
				this.api.setEventCovidCompliant(id, covidComplianceEnabled).pipe(
					handleSuccessRequest(
						({ payload }) =>
							new eventActions.SetEventCovidCompliantSuccess({
								id,
								covidComplianceEnabled,
							})
					),
					catchError(
						handleFailedRequest(
							new eventActions.SetEventCovidCompliantFailed({
								msg: 'can\'t set covid compliance',
								action,
							})
						)
					)
				)
			)
		)
	);

	SetEventRedirectProductId$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.SetEventRedirectProductId>(EventActionsConstants.SET_EVENT_REDIRECT_PRODUCT_ID),
			switchMap(({ action, payload: { id, redirectProductId } }) =>
				this.api.setEventRedirectProductId(id, redirectProductId).pipe(
					handleSuccessRequest(
						({ payload }) =>
							new eventActions.SetEventRedirectProductIdSuccess({
								id,
								redirectProductId: Number(payload.id),
							})
					),
					catchError(
						handleFailedRequest(
							new eventActions.SetEventCovidCompliantFailed({
								msg: 'can\'t set redirect product id',
								action,
							})
						)
					)
				)
			)
		)
	);
}
