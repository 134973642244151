<div class="l-container">
	<div *ngIf="logoUrl; else showBankInitials">
		<img [src]="logoUrl" class="l-icon" />
	</div>
	<ng-template #showBankInitials>
		<div class="l-bank-initials">
			{{bankInitials}}
		</div>
	</ng-template>
	<div class="l-card-details">
		<div class="l-card-number">
			{{ hiddenNumber }}
		</div>
		<div class="l-cardDetails">
			{{ data.holder }}
		</div>
	</div>
	<i *ngIf="data.isDefault" class="fa-solid fa-check l-default-account ps-3 pt-2"></i>
	<div class="l-icons-container">
		<mat-icon id='delete-bank-account' class="mt-3" (click)="$event.stopPropagation(); handleDelete()">delete_outline</mat-icon>
	</div>
</div>
