<div class='w-100 mb-3'>
	<label class="l-label g-form-label">
		{{label}}
		<mat-icon 
			*ngIf="tooltip" 
			class="l-tooltip" 
			[matTooltip]="tooltip"
			matTooltipPosition="above"
			#imageUploadTooltip="matTooltip"
			(click)="imageUploadTooltip.toggle()"
		>
			info
		</mat-icon>
	</label>
	<input id="image-upload-input" type="file" class="d-none" [accept]="allowedFileTypes"
		(change)="onFileSelected($event)" #fileUpload [disabled]="isUploadDisabled">
	<div *ngIf="!imagePath; else showImagePreview" [ngClass]="{'l-upload-container': !isMobile, 'l-upload-container-mobile': isMobile}"
		(click)="fileUpload.click()" id="image-upload-container">
		<div *ngIf='!isImageUploading else imageUploading'>
			<mat-icon class='l-upload-icon'>landscape</mat-icon>
		</div>
		<ng-template #imageUploading>
			<mat-spinner class="l-image-loader" id="image-loader"></mat-spinner>
		</ng-template>
		<div class='l-upload-text'>
			<p>{{ uploadText }}</p>
			<p>Please note your image must be {{ preferredRes }}.</p>
		</div>
	</div>
	<div class="g-error-input" *ngIf="imageUploadError" id="image-upload-error">
		<div>{{ imageUploadError }}</div>
	</div>
	<ng-template #showImagePreview>
		<img class='l-image-preview' [src]='imagePath' id="uploaded-image-preview"/>
		<div class='l-image-control-container'>
			<div 
				[ngClass]="{'l-image-control' : !isMobile, 'l-image-control-mobile' : isMobile}"
				(click)='handleCropImageVisibility()' *ngIf="!onlyUpload" id="crop-image-button"
			>
				<mat-icon
					class='l-image-icon'
				>crop</mat-icon>
				{{ !showCropper ? 'Crop Image' : 'Hide'}}
			</div>
			<div 
				[ngClass]="{'l-image-control' : !isMobile, 'l-image-control-mobile' : isMobile}" 
				(click)='handleImageRemove()'
				id="remove-image-button"
			>
				<mat-icon class='l-image-icon'>delete_outline</mat-icon>
				Remove Image
			</div>
		</div>
		<div class='mt-2' *ngIf='showCropper && !onlyUpload'>
			<div *ngIf='!imageCropperLoading; else cropperLoading'>
				<app-image-cropper [imageBase64]="base64" [type]="type"
					(imageCropped)="imageCropped($event)"
					(loadImageFailed)="imageLoadingFailed()"
					id="image-cropper"
				>
				</app-image-cropper>
			</div>
			<ng-template #cropperLoading>
				<div class='l-upload-container'>
					<mat-spinner></mat-spinner>
				</div>
			</ng-template>
		</div>
	</ng-template>
</div>
