export enum EventActionsConstants {
	GET_VENUES = 'GET_VENUES',
	GET_VENUES_SUCCESS = 'GET_VENUES_SUCCESS',
	GET_VENUES_FAILED = 'GET_VENUES_FAILED',

	GET_EVENT = 'GET_EVENT',
	GET_EVENT_SUCCESS = 'GET_EVENT_SUCCESS',
	GET_EVENT_FAILED = 'GET_EVENT_FAILED',

	CREATE_EVENT = 'CREATE_EVENT',
	CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS',
	CREATE_EVENT_FAILED = 'CREATE_EVENT_FAILED',

	UPDATE_EVENT = 'UPDATE_EVENT',
	UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS',
	UPDATE_EVENT_FAILED = 'UPDATE_EVENT_FAILED',

	SET_ACTIVE_STEP_INDEX = 'SET_ACTIVE_STEP_INDEX',
	DETACH_EVENT = 'DETACH_EVENT',

	GET_EVENT_DETAILS = 'GET_EVENT_DETAILS',
	GET_EVENT_DETAILS_SUCCESS = 'GET_EVENT_DETAILS_SUCCESS',
	GET_EVENT_DETAILS_FAILED = 'GET_EVENT_DETAILS_FAILED',

	GET_EVENT_METADATA = 'GET_EVENT_METADATA',
	GET_EVENT_METADATA_SUCCESS = 'GET_EVENT_METADATA_SUCCESS',
	GET_EVENT_METADATA_FAILED = 'GET_EVENT_METADATA_FAILED',

	GET_USER_HELP = 'GET_USER_HELP',
	GET_USER_HELP_FAILED = 'GET_USER_HELP_FAILED',
	GET_USER_HELP_SUCCESS = 'GET_USER_HELP_SUCCESS',

	SET_EVENT_LISTING = 'SET_EVENT_LISTING',
	SET_EVENT_LISTING_SUCCESS = 'SET_EVENT_LISTING_SUCCESS',
	SET_EVENT_LISTING_FAILED = 'SET_EVENT_LISTING_FAILED',

	SET_EVENT_SERVICE_FEE = 'SET_EVENT_SERVICE_FEE',
	SET_EVENT_SERVICE_FEE_SUCCESS = 'SET_EVENT_SERVICE_FEE_SUCCESS',
	SET_EVENT_SERVICE_FEE_FAILED = 'SET_EVENT_SERVICE_FEE_FAILED',

	SUBMIT_EVENT_FOR_APPROVAL = 'SUBMIT_EVENT_FOR_APPROVAL',
	SUBMIT_EVENT_FOR_APPROVAL_SUCCESS = 'SUBMIT_EVENT_FOR_APPROVAL_SUCCESS',
	SUBMIT_EVENT_FOR_APPROVAL_FAILED = 'SUBMIT_EVENT_FOR_APPROVAL_FAILED',

	GET_EVENT_DASHBOARD = 'GET_EVENT_DASHBOARD',
	GET_EVENT_DASHBOARD_SUCCESS = 'GET_EVENT_DASHBOARD_SUCCESS',
	GET_EVENT_DASHBOARD_FAILED = 'GET_EVENT_DASHBOARD_FAILED',

	SET_EVENT_SHORT_LINK = 'SET_EVENT_SHORT_LINK',
	SET_EVENT_SHORT_LINK_SUCCESS = 'SET_EVENT_SHORT_LINK_SUCCESS',
	SET_EVENT_SHORT_LINK_FAILED = 'SET_EVENT_SHORT_LINK_FAILED',

	GET_EVENT_DASHBOARD_REPORTS = 'GET_EVENT_DASHBOARD_REPORTS',
	GET_EVENT_DASHBOARD_REPORTS_SUCCESS = 'GET_EVENT_DASHBOARD_REPORTS_SUCCESS',
	GET_EVENT_DASHBOARD_REPORTS_FAILED = 'GET_EVENT_DASHBOARD_REPORTS_FAILED',

	CHANGE_EVENT_DATES = 'CHANGE_EVENT_DATES',
	CHANGE_EVENT_DATES_SUCCESS = 'CHANGE_EVENT_DATES_SUCCESS',
	CHANGE_EVENT_DATES_FAILED = 'CHANGE_EVENT_DATES_FAILED',

	CANCEL_EVENT = 'CANCEL_EVENT',
	CANCEL_EVENT_SUCCESS = 'CANCEL_EVENT_SUCCESS',
	CANCEL_EVENT_FAILED = 'CANCEL_EVENT_FAILED',

	GET_EVENT_TYPES = 'GET_EVENT_TYPES',
	GGET_EVENT_TYPES_SUCCESS = 'GET_EVENT_TYPES_SUCCESS',
	GET_EVENT_TYPES_FAILED = 'GET_EVENT_TYPES_FAILED',

	SET_EVENT_DASHBOARD_MODAL_FLAG = 'SET_EVENT_DASHBOARD_MODAL_FLAG',
	SET_EVENT_ENTITY_FETCHING_FLAG = 'SET_EVENT_ENTITY_FETCHING_FLAG',
	SET_EVENT_FETCHING_FLAG = 'SET_EVENT_FETCHING_FLAG',

	SEND_INVITES = 'SEND_INVITES',
	SEND_INVITES_SUCCESS = 'SEND_INVITES_SUCCESS',
	SEND_INVITES_FAILED = 'SEND_INVITES_FAILED',

	GET_FACEBOOK_INTEGRATION_DATA = 'GET_FACEBOOK_INTEGRATION_DATA',
	GET_FACEBOOK_INTEGRATION_DATA_SUCCESS = 'GET_FACEBOOK_INTEGRATION_DATA_SUCCESS',
	GET_FACEBOOK_INTEGRATION_DATA_FAILED = 'GET_FACEBOOK_INTEGRATION_DATA_FAILED',

	UPDATE_EVENT_FACEBOOK_DATA = 'UPDATE_EVENT_FACEBOOK_DATA',
	UPDATE_EVENT_FACEBOOK_DATA_SUCCESS = 'UPDATE_EVENT_FACEBOOK_DATA_SUCCESS',
	UPDATE_EVENT_FACEBOOK_DATA_FAILED = 'UPDATE_EVENT_FACEBOOK_DATA_FAILED',

	SCHEDULE_EVENT_PUBLIC = 'SCHEDULE_EVENT_PUBLIC',
	SCHEDULE_EVENT_PUBLIC_SUCCESS = 'SCHEDULE_EVENT_PUBLIC_SUCCESS',
	SCHEDULE_EVENT_PUBLIC_FAILED = 'SCHEDULE_EVENT_PUBLIC_FAILED',

	DELETE_SCHEDULE_EVENT_PUBLIC = 'DELETE_SCHEDULE_EVENT_PUBLIC',
	DELETE_SCHEDULE_EVENT_PUBLIC_SUCCESS = 'DELETE_SCHEDULE_EVENT_PUBLIC_SUCCESS',
	DELETE_SCHEDULE_EVENT_PUBLIC_FAILED = 'DELETE_SCHEDULE_EVENT_PUBLIC_FAILED',

	SET_EVENT_COVID_COMPLIANT = 'SET_EVENT_COVID_COMPLIANT',
	SET_EVENT_COVID_COMPLIANT_SUCCESS = 'SET_EVENT_COVID_COMPLIANT_SUCCESS',
	SET_EVENT_COVID_COMPLIANT_FAILED = 'SET_EVENT_COVID_COMPLIANT_FAILED',

	SET_EVENT_REDIRECT_MODAL_FLAG = 'SET_EVENT_REDIRECT_MODAL_FLAG',

	SET_EVENT_REDIRECT_PRODUCT_ID = 'SET_EVENT_REDIRECT_PRODUCT_ID',
	SET_EVENT_REDIRECT_PRODUCT_ID_SUCCESS = 'SET_EVENT_REDIRECT_PRODUCT_ID_SUCCESS',
	SET_EVENT_REDIRECT_PRODUCT_ID_FAILED = 'SET_EVENT_REDIRECT_PRODUCT_ID_FAILED',

	GET_SHORT_LINK = 'GET_SHORT_LINK',
	GET_SHORT_LINK_SUCCESS = 'GET_SHORT_LINK_SUCCESS',
	GET_SHORT_LINK_FAILED = 'GET_SHORT_LINK_FAILED',

	GET_EVENT_ORDERS = 'GET_EVENT_ORDERS',
	GET_EVENT_ORDERS_SUCCESS = 'GET_EVENT_ORDERS_SUCCESS',
	GET_EVENT_ORDERS_FAILED = 'GET_EVENT_ORDERS_FAILED',

	EVENT_IMAGE_UPLOAD = 'EVENT_IMAGE_UPLOAD',
	EVENT_IMAGE_UPLOAD_SUCCESS = 'EVENT_IMAGE_UPLOAD_SUCCESS',
	EVENT_IMAGE_UPLOAD_FAILED = 'EVENT_IMAGE_UPLOAD_FAILED',

	GET_EVENT_PROMOTERS = 'GET_EVENT_PROMOTERS',
	GET_EVENT_PROMOTERS_SUCCESS = 'GET_EVENT_PROMOTERS_SUCCESS',
	GET_EVENT_PROMOTERS_FAILED = 'GET_EVENT_PROMOTERS_FAILED',

	SET_PROMOTERS_INCENTIVE = 'SET_PROMOTERS_INCENTIVE',
	SET_PROMOTERS_INCENTIVE_SUCCESS = 'SET_PROMOTERS_INCENTIVE_SUCCESS',
	SET_PROMOTERS_INCENTIVE_FAILED = 'SET_PROMOTERS_INCENTIVE_FAILED',

	GET_EVENT_PROMOTER_NETWORKS = 'GET_EVENT_PROMOTER_NETWORKS',
	GET_EVENT_PROMOTER_NETWORKS_SUCCESS = 'GET_EVENT_PROMOTER_NETWORKS_SUCCESS',
	GET_EVENT_PROMOTER_NETWORKS_FAILED = 'GET_EVENT_PROMOTER_NETWORKS_FAILED',

	CREATE_PROMOTER_NETWORK = 'CREATE_PROMOTER_NETWORK',
	CREATE_PROMOTER_NETWORK_SUCCESS = 'CREATE_PROMOTER_NETWORK_SUCCESS',
	CREATE_PROMOTER_NETWORK_FAILED = 'CREATE_PROMOTER_NETWORK_FAILED',

	GET_EVENT_GUEST_LIST = 'GET_EVENT_GUEST_LIST',
	GET_EVENT_GUEST_LIST_SUCCESS = 'GET_EVENT_GUEST_LIST_SUCCESS',
	GET_EVENT_GUEST_LIST_FAILED = 'GET_EVENT_GUEST_LIST_FAILED',

	RESEND_TICKETS = 'RESEND_TICKETS',
	RESEND_TICKETS_SUCCESS = 'RESEND_TICKETS_SUCCESS',
	RESEND_TICKETS_FAILED = 'RESEND_TICKETS_FAILED',

	GET_SELECTED_GUEST_INFO = 'GET_SELECTED_GUEST_INFO',
	GET_SELECTED_GUEST_INFO_SUCCESS = 'GET_SELECTED_GUEST_INFO_SUCCESS',
	GET_SELECTED_GUEST_INFO_FAILED = 'GET_SELECTED_GUEST_INFO_FAILED',

	GET_REGISTRATION_SETTINGS = 'GET_REGISTRATION_SETTINGS',
	GET_REGISTRATION_SETTINGS_SUCCESS = 'GET_REGISTRATION_SETTINGS_SUCCESS',
	GET_REGISTRATION_SETTINGS_FAILED = 'GET_REGISTRATION_SETTINGS_FAILED',

	UPDATE_REGISTRATION_SETTINGS = 'UPDATE_REGISTRATION_SETTINGS',
	UPDATE_REGISTRATION_SETTINGS_SUCCESS = 'UPDATE_REGISTRATION_SETTINGS_SUCCESS',
	UPDATE_REGISTRATION_SETTINGS_FAILED = 'UPDATE_REGISTRATION_SETTINGS_FAILED',

	GET_REGISTRATIONS = 'GET_REGISTRATIONS',
	GET_REGISTRATIONS_SUCCESS = 'GET_REGISTRATIONS_SUCCESS',
	GET_REGISTRATIONS_FAILED = 'GET_REGISTRATIONS_FAILED',

	GET_EVENT_TABBED_CONTENT = 'GET_EVENT_TABBED_CONTENT',
	GET_EVENT_TABBED_CONTENT_SUCCESS = 'GET_EVENT_TABBED_CONTENT_SUCCESS',
	GET_EVENT_TABBED_CONTENT_FAILED = 'GET_EVENT_TABBED_CONTENT_FAILED',

	REORDER_TABBED_CONTENT = 'REORDER_TABBED_CONTENT',
	REORDER_TABBED_CONTENT_SUCCESS = 'REORDER_TABBED_CONTENT_SUCCESS',
	REORDER_TABBED_CONTENT_FAILED = 'REORDER_TABBED_CONTENT_FAILED',

	SET_TAB_CONTENT_VISIBILITY = 'SET_TAB_CONTENT_VISIBILITY',
	SET_TAB_CONTENT_VISIBILITY_SUCCESS = 'SET_TAB_CONTENT_VISIBILITY_SUCCESS',
	SET_TAB_CONTENT_VISIBILITY_FAILED = 'SET_TAB_CONTENT_VISIBILITY_FAILED',

	DELETE_TAB_CONTENT = 'DELETE_TAB_CONTENT',
	DELETE_TAB_CONTENT_SUCCESS = 'DELETE_TAB_CONTENT_SUCCESS',
	DELETE_TAB_CONTENT_FAILED = 'DELETE_TAB_CONTENT_FAILED',

	UPSERT_TAB_CONTENT = 'UPSERT_TAB_CONTENT',
	UPSERT_TAB_CONTENT_SUCCESS = 'UPSERT_TAB_CONTENT_SUCCESS',
	UPSERT_TAB_CONTENT_FAILED = 'UPSERT_TAB_CONTENT_FAILED',

	TABBED_CONTENT_UPLOAD_IMAGE = 'UPLOAD_TABBED_CONTENT_IMAGE',
	TABBED_CONTENT_UPLOAD_IMAGE_SUCCESS = 'UPLOAD_TABBED_CONTENT_IMAGE_SUCCESS',
	TABBED_CONTENT_UPLOAD_IMAGE_FAILED = 'UPLOAD_TABBED_CONTENT_IMAGE_FAILED',

	GET_EVENT_LINK_CAMPAIGNS = 'GET_EVENT_LINK_CAMPAIGNS',
	GET_EVENT_LINK_CAMPAIGNS_SUCCESS = 'GET_EVENT_LINK_CAMPAIGNS_SUCCESS',
	GET_EVENT_LINK_CAMPAIGNS_FAILED = 'GET_EVENT_LINK_CAMPAIGNS_FAILED',

	DELETE_EVENT_LINK_CAMPAIGN = 'DELETE_EVENT_LINK_CAMPAIGN',
	DELETE_EVENT_LINK_CAMPAIGN_SUCCESS = 'DELETE_EVENT_LINK_CAMPAIGN_SUCCESS',
	DELETE_EVENT_LINK_CAMPAIGN_FAILED = 'DELETE_EVENT_LINK_CAMPAIGN_FAILED',

	SET_LINK_CAMPAIGN_MODAL_FLAG = 'SET_LINK_CAMPAIGN_MODAL_FLAG',

	ADD_EVENT_LINK_CAMPAIGN = 'ADD_EVENT_LINK_CAMPAIGN',
	ADD_EVENT_LINK_CAMPAIGN_SUCCESS = 'ADD_EVENT_LINK_CAMPAIGN_SUCCESS',
	ADD_EVENT_LINK_CAMPAIGN_FAILED = 'ADD_EVENT_LINK_CAMPAIGN_FAILED',

	GET_EVENT_SCANNERS = 'GET_EVENT_SCANNERS',
	GET_EVENT_SCANNERS_SUCCESS = 'GET_EVENT_SCANNERS_SUCCESS',
	GET_EVENT_SCANNERS_FAILED = 'GET_EVENT_SCANNERS_FAILED',

	EDIT_EVENT_SCANNERS = 'EDIT_EVENT_SCANNERS',
	EDIT_EVENT_SCANNERS_SUCCESS = 'EDIT_EVENT_SCANNERS_SUCCESS',
	EDIT_EVENT_SCANNERS_FAILED = 'EDIT_EVENT_SCANNERS_FAILED',

	ADD_EVENT_SCANNERS = 'ADD_EVENT_SCANNERS',
	ADD_EVENT_SCANNERS_SUCCESS = 'ADD_EVENT_SCANNERS_SUCCESS',
	ADD_EVENT_SCANNERS_FAILED = 'ADD_EVENT_SCANNERS_FAILED',

	NEW_PIN_EVENT_SCANNER = 'NEW_PIN_EVENT_SCANNER',
	NEW_PIN_EVENT_SCANNER_SUCCESS = 'NEW_PIN_EVENT_SCANNER_SUCCESS',
	NEW_PIN_EVENT_SCANNER_FAILED = 'NEW_PIN_EVENT_SCANNER_FAILED',

	GET_EVENT_REQUEST_FIELD_SERVICES = 'GET_EVENT_REQUEST_FIELD_SERVICES',
	GET_EVENT_REQUEST_FIELD_SERVICES_SUCCESS = 'GET_EVENT_REQUEST_FIELD_SERVICES_SUCCESS',
	GET_EVENT_REQUEST_FIELD_SERVICES_FAILED = 'GET_EVENT_REQUEST_FIELD_SERVICES_FAILED',

	GET_EVENT_COLLECT_INFO = 'GET_EVENT_COLLECT_INFO',
	GET_EVENT_COLLECT_INFO_SUCCESS = 'GET_EVENT_COLLECT_INFO_SUCCESS',
	GET_EVENT_COLLECT_INFO_FAILED = 'GET_EVENT_COLLECT_INFO_FAILED',

	UPSERT_EVENT_COLLECT_INFO = 'UPSERT_EVENT_COLLECT_INFO',
	UPSERT_EVENT_COLLECT_INFO_SUCCESS = 'UPSERT_EVENT_COLLECT_INFO_SUCCESS',
	UPSERT_EVENT_COLLECT_INFO_FAILED = 'UPSERT_EVENT_COLLECT_INFO_FAILED',

	GET_PROFILE_COLLECT_INFO = 'GET_PROFILE_COLLECT_INFO',
	GET_PROFILE_COLLECT_INFO_SUCCESS = 'GET_PROFILE_COLLECT_INFO_SUCCESS',
	GET_PROFILE_COLLECT_INFO_FAILED = 'GET_PROFILE_COLLECT_INFO_FAILED',

	SET_COLLECT_INFO_PROPERTY = 'SET_COLLECT_INFO_PROPERTY',
	SET_COLLECT_INFO_PROPERTY_SUCCESS = 'SET_COLLECT_INFO_PROPERTY_SUCCESS',
	SET_COLLECT_INFO_PROPERTY_FAILED = 'SET_COLLECT_INFO_PROPERTY_FAILED',

	DELETE_COLLECT_INFO = 'DELETE_COLLECT_INFO',
	DELETE_COLLECT_INFO_SUCCESS = 'DELETE_COLLECT_INFO_SUCCESS',
	DELETE_COLLECT_INFO_FAILED = 'DELETE_COLLECT_INFO_FAILED',

	REORDER_COLLECT_INFO = 'REORDER_COLLECT_INFO',
	REORDER_COLLECT_INFO_SUCCESS = 'REORDER_COLLECT_INFO_SUCCESS',
	REORDER_COLLECT_INFO_FAILED = 'REORDER_COLLECT_INFO_FAILED',

	GET_EVENT_DEFINITIONS = 'GET_EVENT_DEFINITIONS',
	GET_EVENT_DEFINITIONS_SUCCESS = 'GET_EVENT_DEFINITIONS_SUCCESS',
	GET_EVENT_DEFINITIONS_FAILED = 'GET_EVENT_DEFINITIONS_FAILED',

	UPDATE_EVENT_DEFINITIONS = 'UPDATE_EVENT_DEFINITIONS',
	UPDATE_EVENT_DEFINITIONS_SUCCESS = 'UPDATE_EVENT_DEFINITIONS_SUCCESS',
	UPDATE_EVENT_DEFINITIONS_FAILED = 'UPDATE_EVENT_DEFINITIONS_FAILED',

	GET_EVENT_DESIGN = 'GET_EVENT_DESIGN',
	GET_EVENT_DESIGN_SUCCESS = 'GET_EVENT_DESIGN_SUCCESS',
	GET_EVENT_DESIGN_FAILED = 'GET_EVENT_DESIGN_FAILED',

	UPDATE_EVENT_DESIGN = 'UPDATE_EVENT_DESIGN',
	UPDATE_EVENT_DESIGN_SUCCESS = 'UPDATE_EVENT_DESIGN_SUCCESS',
	UPDATE_EVENT_DESIGN_FAILED = 'UPDATE_EVENT_DESIGN_FAILED',

	UPDATE_EVENT_SKIP_COMPLETION_ITEM = 'UPDATE_EVENT_SKIP_COMPLETION_ITEM',
	UPDATE_EVENT_SKIP_COMPLETION_ITEM_SUCCESS = 'UPDATE_EVENT_SKIP_COMPLETION_ITEM_SUCCESS',
	UPDATE_EVENT_SKIP_COMPLETION_ITEM_FAILED = 'UPDATE_EVENT_SKIP_COMPLETION_ITEM_FAILED',

	GET_COPY_EVENT_INFO = 'GET_COPY_EVENT_INFO',
	GET_COPY_EVENT_INFO_SUCCESS = 'GET_COPY_EVENT_INFO_SUCCESS',
	GET_COPY_EVENT_INFO_FAILED = 'GET_COPY_EVENT_INFO_FAILED',

	COPY_EVENT = 'COPY_EVENT',
	COPY_EVENT_SUCCESS = 'COPY_EVENT_SUCCESS',
	COPY_EVENT_FAILED = 'COPY_EVENT_FAILED',

	GET_EVENT_TICKET_GROUPS = 'GET_EVENT_TICKET_GROUPS',
	GET_EVENT_TICKET_GROUPS_SUCCESS = 'GET_EVENT_TICKET_GROUPS_SUCCESS',
	GET_EVENT_TICKET_GROUPS_FAILED = 'GET_EVENT_TICKET_GROUPS_FAILED',

	DELETE_TICKET_GROUP = 'DELETE_TICKET_GROUP',
	DELETE_TICKET_GROUP_SUCCESS = 'DELETE_TICKET_GROUP_SUCCESS',
	DELETE_TICKET_GROUP_FAILED = 'DELETE_TICKET_GROUP_FAILED',

	UPSERT_TICKET_GROUP = 'UPSERT_TICKET_GROUP',
	UPSERT_TICKET_GROUP_SUCCESS = 'UPSERT_TICKET_GROUP_SUCCESS',
	UPSERT_TICKET_GROUP_FAILED = 'UPSERT_TICKET_GROUP_FAILED',

	SET_TICKET_GROUPS_USAGE = 'SET_TICKET_GROUPS_USAGE',
	SET_TICKET_GROUPS_USAGE_SUCCESS = 'SET_TICKET_GROUPS_USAGE_SUCCESS',
	SET_TICKET_GROUPS_USAGE_FAILED = 'SET_TICKET_GROUPS_USAGE_FAILED',

	REORDER_TICKET_GROUP = 'REORDER_TICKET_GROUP',
	REORDER_TICKET_GROUP_SUCCESS = 'REORDER_TICKET_GROUP_SUCCESS',
	REORDER_TICKET_GROUP_FAILED = 'REORDER_TICKET_GROUP_FAILED',

	GET_EVENT_SETTINGS = 'GET_EVENT_SETTINGS',
	GET_EVENT_SETTINGS_SUCCESS = 'GET_EVENT_SETTINGS_SUCCESS',
	GET_EVENT_SETTINGS_FAILED = 'GET_EVENT_SETTINGS_FAILED',

	UPDATE_EVENT_SETTINGS = 'UPDATE_EVENT_SETTINGS',
	UPDATE_EVENT_SETTINGS_FAILED = 'UPDATE_EVENT_SETTINGS_FAILED',
	UPDATE_EVENT_SETTINGS_SUCCESS = 'UPDATE_EVENT_SETTINGS_SUCCESS',

	GET_EVENT_WEBHOOKS = 'GET_EVENT_WEBHOOKS',
	GET_EVENT_WEBHOOKS_SUCCESS = 'GET_EVENT_WEBHOOKS_SUCCESS',
	GET_EVENT_WEBHOOKS_FAILED = 'GET_EVENT_WEBHOOKS_FAILED',

	UPDATE_EVENT_WEBHOOKS = 'UPDATE_EVENT_WEBHOOKS',
	UPDATE_EVENT_WEBHOOKS_SUCCESS = 'UPDATE_EVENT_WEBHOOKS_SUCCESS',
	UPDATE_EVENT_WEBHOOKS_FAILED = 'UPDATE_EVENT_WEBHOOKS_FAILED',

	TEST_EVENT_WEBHOOKS = 'TEST_EVENT_WEBHOOKS',
	TEST_EVENT_WEBHOOKS_SUCCESS = 'TEST_EVENT_WEBHOOKS_SUCCESS',
	TEST_EVENT_WEBHOOKS_FAILED = 'TEST_EVENT_WEBHOOKS_FAILED',

	COPY_SEATING_CHART = 'COPY_SEATING_CHART',
	COPY_SEATING_CHART_SUCCESS = 'COPY_SEATING_CHART_SUCCESS',
	COPY_SEATING_CHART_FAILED = 'COPY_SEATING_CHART_FAILED',

	GET_EVENT_SCHEDULE_TRANSFER = 'GET_EVENT_SCHEDULE_TRANSFER',
	GET_EVENT_SCHEDULE_TRANSFER_SUCCESS = 'GET_EVENT_SCHEDULE_TRANSFER_SUCCESS',
	GET_EVENT_SCHEDULE_TRANSFER_FAILED = 'GET_EVENT_SCHEDULE_TRANSFER_FAILED',

	UPDATE_EVENT_SCHEDULE_TRANSFER = 'UPDATE_EVENT_SCHEDULE_TRANSFER',
	UPDATE_EVENT_SCHEDULE_TRANSFER_SUCCESS = 'UPDATE_EVENT_SCHEDULE_TRANSFER_SUCCESS',
	UPDATE_EVENT_SCHEDULE_TRANSFER_FAILED = 'UPDATE_EVENT_SCHEDULE_TRANSFER_FAILED',

	DELETE_EVENT = 'DELETE_EVENT',
	DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS',
	DELETE_EVENT_FAILED = 'DELETE_EVENT_FAILED',

	GET_ORDER_DETAILS = 'GET_ORDER_DETAILS',
	GET_ORDER_DETAILS_SUCCESS = 'GET_ORDER_DETAILS_SUCCESS',
	GET_ORDER_DETAILS_FAILED = 'GET_ORDER_DETAILS_FAILED',

	CHANGE_TICKET_TYPES = 'CHANGE_TICKET_TYPES',
	CHANGE_TICKET_TYPES_SUCCESS = 'CHANGE_TICKET_TYPES_SUCCESS',
	CHANGE_TICKET_TYPES_FAILED = 'CHANGE_TICKET_TYPES_FAILED',

	CANCEL_TICKETS = 'CANCEL_TICKETS',
	CANCEL_TICKETS_SUCCESS = 'CANCEL_TICKETS_SUCCESS',
	CANCEL_TICKETS_FAILED = 'CANCEL_TICKETS_FAILED',

	RESEND_ORDER_TICKETS = 'RESEND_ORDER_TICKETS',
	RESEND_ORDER_TICKETS_SUCCESS = 'RESEND_ORDER_TICKETS_SUCCESS',
	RESEND_ORDER_TICKETS_FAILED = 'RESEND_ORDER_TICKETS_FAILED',

	GET_EVENT_OLD_API = 'GET_EVENT_OLD_API',
	GET_EVENT_OLD_API_SUCCESS = 'GET_EVENT_OLD_API_SUCCESS',
	GET_EVENT_OLD_API_FAILED = 'GET_EVENT_OLD_API_FAILED',

	MARK_AS_PAID = 'MARK_AS_PAID',
	MARK_AS_PAID_SUCCESS = 'MARK_AS_PAID_SUCCESS',
	MARK_AS_PAID_FAILED = 'MARK_AS_PAID_FAILED',
}
