import { Component, Inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { minLength, onlyNumberAndLetters, requiredValidator } from '@app/shared/form-field/form-validators';

@Component({
	selector: 'app-edit-event-link-modal',
	templateUrl: './edit-event-link-modal.component.html',
	styleUrls: ['./edit-event-link-modal.component.sass'],
})
export class EditEventLinkModalComponent {
	shortLinkControl: UntypedFormControl;
	isShortLinkEditing: boolean;

	constructor(
		public dialogRef: MatDialogRef<EditEventLinkModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data: {isMobile: boolean; shortLink: string}
	) {
		this.shortLinkControl = new UntypedFormControl(data.shortLink, [requiredValidator(), onlyNumberAndLetters(), minLength(4)]);
		this.shortLinkControl.setValue(this.data.shortLink);
	}

	getError(errors: { [key: string]: string }) {
		return  errors && errors[Object.keys(errors)[0]];
	}

	handleClose() {
		this.dialogRef.close();
	}

	handleEditLink(): void {
		this.dialogRef.close(this.shortLinkControl.getRawValue());
	}
}
