import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { RequestType, AsyncResponse } from '@app/models/http.model';
import { IEventDomainAPI } from '@app/models/event.api.model';
import { EventOrganiserProfile } from '@app/models/profile.model';
import {
	Venue,
	EventDetailsSettings,
	EventDashboard,
	ProductStatus,
	EventFormat,
	EventCategory,
	EventSubcategory,
	EventFacebookData,
	EventFacebookDataForm,
	EventTab,
	EventLinkCampaign,
	EventCollectInfo,
	EventCollectInfoTicket,
	EventCollectInfoBase,
	EventDesign,
	EventTicketGroups,
	EventTicketGroup,
	EventCollections,
	EventStreamingTicketTypes,
	EventWidget,
	EventScheduleTransferOptions,
	EventScheduleTransferSettings,
	CovidComplianceEnabled,
	EventWebhooks,
	TestEventWebhook,
	EventScanner,
	EventAtTheDoor,
	EventOrdersList,
	EventOrderFilter,
	PromotersPageInfo,
	ListResultInfo,
	GuestListRequestInfo,
	SelectedGuestInfo,
	ResendTicketsResult,
	EventRegistrationSettings,
	EventRegistrations,
	RegistrationFilter,
	PromoterNetworksPageInfo,
	NewPromoterNetworkInfo,
	EventImage,
	DraftEventLinkCampaign,
	DraftEventTab,
	EventCopySeatingChartRequest,
	EventCopySeatingChartResult
} from '@app/models/event.model';
import { SchemeID } from '@app/models/dataSchema.model';
import { HelpArticle } from '@app/models/help.model';
import { Currency, EventTicketsSettings, SeatsIOCategories } from '@app/models/ticket.model';
import { Product, ProductDeal, ProductMetadata, ProductType } from '@app/models/product.model';
import { EventCopy, EventCopyInfo, EventDashboardReports } from '@app/models/event-metadata.model';
import { NormalizedSchemeField } from '@app/models/dataSchema.model';
import { EventScheduleTransfer } from '@app/models/event.model';
import { RedirectProductId } from '@app/models/event.model';
import { EventDefinitions,
	EventScheduledPublicDate,
	EventOnlineStreamingSettings,
	ProductOnlineStreamingRooms } from '@app/models/event.model';
import { stringifyObjectValues } from '@app/utils/common-helpers';
import { ImageServiceType } from '@app/services/image/image.service';
import config from '@app/config';
import { TokenService } from '@app/services/token/token.service';
import { ViewOrder } from '@app/models/order.model';

@Injectable()
export class EventDomainAPI implements IEventDomainAPI {
	constructor(
		private http: HttpService,
		private tokenService: TokenService
	) { }

	createEvent = (productType: ProductType): AsyncResponse<Product> =>
		this.http.makeRequest({
			type: RequestType.GET,
			url: `/api/product/${productType}/new`,
		});

	getEvent = (productType: ProductType): AsyncResponse<Product> =>
		this.http.makeRequest({
			url: `/api/product/${productType}`,
			type: RequestType.GET,
		});

	updateEventOrganiser = (event: Product): AsyncResponse<Product> =>
		this.http.makeRequest({
			url: '/api/product/step/1',
			type: RequestType.POST,
			payload: event,
		});

	updateEventDetails = (event: Product): AsyncResponse<Product> =>
		this.http.makeRequest({
			url: '/api/product/step/2',
			type: RequestType.POST,
			payload: event,
		});

	updateEventTickets = (event: Product): AsyncResponse<Product> =>
		this.http.makeRequest({
			url: '/api/product/step/3',
			type: RequestType.POST,
			payload: event,
		});

	getProfiles = (eventId: SchemeID): AsyncResponse<EventOrganiserProfile[]> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/organisers`,
			type: RequestType.GET,
		});

	getPreviousVenues = (eventId: SchemeID): AsyncResponse<Venue[]> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/previous_venues`,
			type: RequestType.GET,
		});

	createProfile = (eventId: SchemeID, profile: EventOrganiserProfile): AsyncResponse<EventOrganiserProfile> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/organisers`,
			type: RequestType.POST,
			payload: profile,
		});

	getTicketCategories = (eventId: SchemeID, chartId: string): AsyncResponse<SeatsIOCategories> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/seats/chart/${chartId}`,
			type: RequestType.GET,
		});

	getCurrencies = (productType: ProductType): AsyncResponse<Currency[]> =>
		this.http.makeRequest({
			url: `/api/currencies/${productType}`,
			type: RequestType.GET,
		});

	getEventDetails = (eventId: SchemeID): AsyncResponse<Product> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/details`,
			type: RequestType.GET,
		});

	getEventTickets = (eventId: SchemeID): AsyncResponse<Product> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/tickets`,
			type: RequestType.GET,
		});

	getEventSettings = (eventId: SchemeID): AsyncResponse<EventDetailsSettings> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/details/settings`,
			type: RequestType.GET,
		});

	updateEventSettings = (eventId: SchemeID, setting: EventDetailsSettings): AsyncResponse<EventDetailsSettings> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/details/settings`,
			type: RequestType.POST,
			payload: setting,
		});

	getTicketSettings = (eventId: SchemeID): AsyncResponse<EventTicketsSettings> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/tickets/settings`,
			type: RequestType.GET,
		});

	updateTicketSettings = (eventId: SchemeID, setting: EventTicketsSettings): AsyncResponse<EventTicketsSettings> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/tickets/settings`,
			type: RequestType.POST,
			payload: setting,
		});

	getEventMetadata = (eventId: SchemeID): AsyncResponse<ProductMetadata> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/metadata`,
			type: RequestType.GET,
		});

	getEventCopyInfo = (eventId: SchemeID): AsyncResponse<EventCopyInfo> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/copyinfo`,
			type: RequestType.GET,
		});

	copyEvent = (eventId: SchemeID, copyInfo): AsyncResponse<EventCopy> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/copy`,
			type: RequestType.POST,
			payload: copyInfo,
		});

	endTicketSales = (eventId: SchemeID): AsyncResponse<ProductDeal[]> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/endsales`,
			type: RequestType.PUT,
		});

	markTicketsAsSoldOut = (eventId: SchemeID): AsyncResponse<boolean> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/soldout`,
			type: RequestType.PUT,
		});

	getHelpArticle = (type: HelpArticle): AsyncResponse<string> =>
		this.http.makeRequest({
			url: `/api/help/article/${type}`,
			type: RequestType.GET,
		});

	getEventOrganiser = (eventId: SchemeID): AsyncResponse<Product> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/profile`,
			type: RequestType.GET,
		});

	saveEventOrganiser = (eventId: SchemeID, event: EventOrganiserProfile): AsyncResponse<Product> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/profile`,
			type: RequestType.PUT,
			payload: event,
		});

	setEventListing = (eventId: SchemeID, isPublic: boolean): AsyncResponse<boolean> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/setpublic/${isPublic}`,
			type: RequestType.PUT,
		});

	scheduleEventPublic = (eventId: SchemeID, scheduledToGoPublicOn: Date): AsyncResponse<EventScheduledPublicDate> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/setpublic/schedulepublic`,
			type: RequestType.POST,
			payload: {scheduledToGoPublicOn},
		});

	deleteScheduleEventPublic = (eventId: SchemeID): AsyncResponse<{id: SchemeID}> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/setpublic/schedulepublic`,
			type: RequestType.DELETE,
		});

	setEventServiceFee = (eventId: SchemeID, isServiceFeeForHost: boolean): AsyncResponse<boolean> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/setservicefeeforhost/${isServiceFeeForHost}`,
			type: RequestType.PUT,
		});

	submitEventForApproval = (eventId: SchemeID): AsyncResponse<number> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/submitforapproval`,
			type: RequestType.POST,
		});

	getEventDashboard = (eventId: SchemeID): AsyncResponse<EventDashboard> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/dashboard`,
			type: RequestType.GET,
		});

	setEventShortLink = (eventId: SchemeID, shortLink: string): AsyncResponse<string> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/shortlink`,
			type: RequestType.PUT,
			payload: {
				shortLink,
			},
		});

	getEventDashboardReports = (
		eventId: SchemeID,
		scheduleItemId: SchemeID
	): AsyncResponse<EventDashboardReports> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/dashboarddata?${this.http.stringifyQueryParams({
				scheduleItemId,
			})}`,
			type: RequestType.GET,
		});

	changeEventDates = (eventId: SchemeID, startDate: Date, endDate: Date): AsyncResponse<{ startDate: string; endDate: string }> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/postpone`,
			type: RequestType.POST,
			payload: {
				startDate,
				endDate,
			},
		});

	cancelEvent = (eventId: SchemeID): AsyncResponse<{ productStatus: ProductStatus; isAutoCancelled: boolean }> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/cancel`,
			type: RequestType.POST,
		});

	getEventTypes = (): AsyncResponse<{
		formats: EventFormat[];
		categories: EventCategory<SchemeID>[];
		subCategories: NormalizedSchemeField<EventSubcategory>;
	}> =>
		this.http.makeRequest({
			url: '/api/product/categories',
			type: RequestType.GET,
		});

	sendInvites = (eventId: SchemeID, contactListId: SchemeID[]): AsyncResponse<boolean> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/invites`,
			type: RequestType.POST,
			payload: contactListId,
		});

	getFacebookIntegrationData = (eventId: SchemeID): AsyncResponse<EventFacebookData> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/facebookdata`,
			type: RequestType.GET,
		});

	updateEventFacebookData = (eventId: SchemeID, data: EventFacebookDataForm, token: string): AsyncResponse<EventFacebookData> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/facebookdata`,
			type: RequestType.POST,
			payload: {
				data,
				token,
			},
		});

	getEventTabbedContent = (eventId: SchemeID): AsyncResponse<EventTab[]> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/tabbedContent`,
			type: RequestType.GET,
		});

	upsertEventTabbedContent = (eventId: SchemeID, tab: DraftEventTab): AsyncResponse<EventTab> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/tabbedContent`,
			type: RequestType.POST,
			payload: tab,
		});

	setTabContentVisibility = (eventId: SchemeID, id: SchemeID, isVisible: boolean): AsyncResponse<{
		id: SchemeID;
		eventId: SchemeID;
		isVisible: boolean;
	}> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/tabs/${id}/visibility`,
			type: RequestType.PUT,
			payload: {
				isVisible,
			},
		});

	deleteTabContent = (eventId: SchemeID, id: SchemeID): AsyncResponse<{
		id: SchemeID;
		eventId: SchemeID;
	}> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/tabs/${id}`,
			type: RequestType.DELETE,
		});

	getEventTicketGroups = (eventId: SchemeID): AsyncResponse<EventTicketGroups> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/ticket-groups`,
			type: RequestType.GET,
		});

	deleteEventTicketGroup = (id: SchemeID, eventId: SchemeID): AsyncResponse<{
		id: SchemeID;
		eventId: SchemeID;
		deleted: boolean;
	}> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/ticket-groups/${id}`,
			type: RequestType.DELETE,
		});

	getEventLinkCampaigns = (eventId: SchemeID): AsyncResponse<EventLinkCampaign[]> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/linkcampaigns`,
			type: RequestType.GET,
		});

	deleteEventLinkCampaign = ( eventId: SchemeID, id: SchemeID ): AsyncResponse<{
		eventId: SchemeID;
		id: SchemeID;
	}> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/linkcampaigns/${id}`,
			type: RequestType.DELETE,
		});

	addEventLinkCampaign = (eventId: SchemeID, campaign: DraftEventLinkCampaign): AsyncResponse<EventLinkCampaign> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/linkcampaigns`,
			type: RequestType.POST,
			payload: campaign,
		});

	getEventCollectInfo = (eventId: SchemeID, isRegistration: boolean): AsyncResponse<{
		id: SchemeID;
		collectInfo: EventCollectInfo[];
		tickets: EventCollectInfoTicket[];
	}> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/questions?isRegistration=${isRegistration}`,
			type: RequestType.GET,
		});

	upsertEventCollectInfo = (eventId: SchemeID, collectInfo: EventCollectInfo): AsyncResponse<{
		eventId: SchemeID;
		collectInfo: EventCollectInfo;
	}> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/questions`,
			type: RequestType.POST,
			payload: collectInfo,
		});

	getProfileCollectInfo = (eventId: SchemeID, isRegistration: boolean): AsyncResponse<{
		id: SchemeID;
		collectInfo: EventCollectInfoBase[];
	}> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/profilequestions?isRegistration=${isRegistration}`,
			type: RequestType.GET,
		});

	setCollectInfoRequired = (eventId: SchemeID, questionId: SchemeID, required: boolean): AsyncResponse<{
		eventId: SchemeID;
		questionId: SchemeID;
		required: boolean; }> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/questions/${questionId}/required`,
			type: RequestType.PUT,
			payload: {
				required,
			},
		});

	setCollectInfoEnabled = (eventId: SchemeID, questionId: SchemeID, enabled: boolean): AsyncResponse<{
		eventId: SchemeID;
		questionId: SchemeID;
		enabled: boolean; }> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/questions/${questionId}/enabled`,
			type: RequestType.PUT,
			payload: {
				enabled,
			},
		});

	deleteCollectInfo = (eventId: SchemeID, id: SchemeID): AsyncResponse<{
		eventId: SchemeID;
		id: SchemeID;
	}> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/questions/${id}`,
			type: RequestType.DELETE,
			payload: {
				eventId,
				id,
			},
		});

	getEventDesign = (eventId: SchemeID): AsyncResponse<EventDesign> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/design`,
			type: RequestType.GET,
		});

	updateEventDesign = (eventId: SchemeID, design: EventDesign): AsyncResponse<EventDesign> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/design`,
			type: RequestType.POST,
			payload: design,
		});

	setTicketGroupUsage = (eventId: SchemeID, useTicketGroups: boolean): AsyncResponse<{
		eventId: SchemeID;
		useTicketGroups: boolean;
	}> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/ticket-groups/usage`,
			type: RequestType.POST,
			payload: {
				useTicketGroups,
			},
		});

	upsertTicketGroup = (eventId: SchemeID, ticketGroup: EventTicketGroup): AsyncResponse<EventTicketGroup> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/ticket-groups`,
			type: RequestType.POST,
			payload: ticketGroup,
		});

	reorderCollectInfo = (
		eventId: SchemeID,
		order: SchemeID[],
		isRegistration: boolean
	): AsyncResponse<{ eventId: SchemeID; order: SchemeID[] }> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/questions/reorder?isRegistration=${isRegistration}`,
			type: RequestType.POST,
			payload: order,
		});

	reorderTicketGroup = (eventId: SchemeID, order: SchemeID[]): AsyncResponse<{ eventId: SchemeID; order: SchemeID[] }> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/ticket-groups/reorder`,
			type: RequestType.POST,
			payload: order,
		});

	reorderTabbedContent = (eventId: SchemeID, order: SchemeID[] ): AsyncResponse<{ eventId: SchemeID; order: SchemeID[] }> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/tabbedContent/reorder`,
			type: RequestType.POST,
			payload: order,
		});

	getEventDefinitions = (eventId: SchemeID): AsyncResponse<EventDefinitions> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/definitions`,
			type: RequestType.GET,
		});

	updateEventDefinitions = (eventId: SchemeID, definitions: EventDefinitions): AsyncResponse<EventDefinitions> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/definitions`,
			type: RequestType.POST,
			payload: definitions,
		});

	getEventStreaming = (eventId: SchemeID): AsyncResponse<{
		eventId: SchemeID;
		settings: EventOnlineStreamingSettings;
		streams: ProductOnlineStreamingRooms[];
		ticketTypes: EventStreamingTicketTypes[];
		eventHasBegun: boolean;
	}> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/streams`,
			type: RequestType.GET,
		});

	upsertEventStreamingRoom = (eventId: SchemeID, streams: ProductOnlineStreamingRooms): AsyncResponse<ProductOnlineStreamingRooms> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/streams/stream`,
			type: RequestType.POST,
			payload: streams,
		});

	deleteEventStreamingRoom = (eventId: SchemeID, id: SchemeID): AsyncResponse<{
		eventId: SchemeID;
		id: SchemeID;
	}> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/streams/${id}`,
			type: RequestType.DELETE,
		});

	setEventStreamingEnabled = (eventId: SchemeID, id: SchemeID, isEnabled: boolean): AsyncResponse<{
		eventId: SchemeID;
		id: SchemeID;
		isEnabled: boolean;
	}> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/streams/${id}/enabled`,
			type: RequestType.POST,
			payload: {
				isEnabled,
			},
		});

	setEventStreamingSettings = (eventId: SchemeID, settings: EventOnlineStreamingSettings): AsyncResponse<EventOnlineStreamingSettings> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/streams/settings`,
			type: RequestType.POST,
			payload: settings,
		});

	reorderStreams = (eventId: SchemeID, sortOrder: SchemeID[]): AsyncResponse<{ eventId: SchemeID; sortOrder: SchemeID[] }> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/streams/sortorder`,
			type: RequestType.POST,
			payload: {
				sortOrder,
			},
		});

	getEventCollections = (eventId: SchemeID): AsyncResponse<EventCollections> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/collections`,
			type: RequestType.GET,
		});

	updateEventCollections = (eventId: SchemeID, collections: EventCollections): AsyncResponse<EventCollections> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/collections`,
			type: RequestType.POST,
			payload: collections,
		});

	setOrganiserPreviewCode = (eventId: SchemeID): AsyncResponse<{
		eventId: SchemeID;
		settings: EventOnlineStreamingSettings;
		streams: ProductOnlineStreamingRooms[];
		ticketTypes: EventStreamingTicketTypes[];
	}> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/streams/universalaccesscode`,
			type: RequestType.POST,
		});

	deleteOrganiserPreviewCode = (eventId: SchemeID): AsyncResponse<{
		eventId: SchemeID;
		settings: EventOnlineStreamingSettings;
		streams: ProductOnlineStreamingRooms[];
		ticketTypes: EventStreamingTicketTypes[];
	}> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/streams/universalaccesscode`,
			type: RequestType.DELETE,
		});

	requestVodDownloadLink = (eventId: SchemeID, id: SchemeID): AsyncResponse<boolean> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/streams/${id}/downloadvod`,
			type: RequestType.POST,
		});

	setEventCovidCompliant = (eventId: SchemeID, covidComplianceEnabled: CovidComplianceEnabled): AsyncResponse<CovidComplianceEnabled> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/togglecovid`,
			type: RequestType.POST,
			payload: covidComplianceEnabled,
		});

	getWidget = (eventId: SchemeID): AsyncResponse<EventWidget> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/widget`,
			type: RequestType.GET,
		});

	getEventScheduleTransfers = (eventId: SchemeID): AsyncResponse<EventScheduleTransfer> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/transferpurchaseschedule`,
			type: RequestType.GET,
		});

	updateEventScheduleTransfers = (eventId: SchemeID,
		settings: EventScheduleTransferSettings): AsyncResponse<{
		scheduleItems: EventScheduleTransferOptions[];
		settings: EventScheduleTransferSettings;
	}> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/transferpurchaseschedule`,
			type: RequestType.POST,
			payload: {
				settings,
			},
		});

	getEventScanners = (eventId: SchemeID, scheduleItemId: SchemeID): AsyncResponse<EventScanner[]> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/scanners?scheduleItemId=${scheduleItemId}`,
			type: RequestType.GET,
		});

	generateNewPin = (eventId: SchemeID, scanner: EventScanner, scheduleItemId: SchemeID): AsyncResponse<EventScanner[]> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/newPin?scheduleItemId=${scheduleItemId}`,
			type: RequestType.PATCH,
			payload: {
				ScannerName: scanner.scannerName,
				ScannerPin: scanner.scannerPin,
				ProductId: scanner.productId,
				Id: scanner.id,
				ShortLink: scanner.shortLink,
			},
		});

	editEventScanner = (eventId: SchemeID, scanner: EventScanner, scheduleItemId: SchemeID): AsyncResponse<EventScanner[]> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/editScanner?scheduleItemId=${scheduleItemId}`,
			type: RequestType.PATCH,
			payload: {
				Id: scanner.id,
				ScannerName: scanner.scannerName,
				ScannerPin: scanner.scannerPin,
			},
		});

	addEventScanner = (eventId: SchemeID, scanner: EventScanner, scheduleItemId: SchemeID): AsyncResponse<EventScanner[]> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/addScanner?scheduleItemId=${scheduleItemId}`,
			type: RequestType.POST,
			payload: {
				ScannerName: scanner.scannerName,
				ScannerPin: scanner.scannerPin,
			},
		});

	getEventWebhooks(eventId: SchemeID): AsyncResponse<EventWebhooks> {
		return this.http.makeRequest({
			url: `/api/product/${eventId}/webhooks`,
			type: RequestType.GET,
		});
	}

	updateEventWebhooks(eventId: SchemeID, webhooks: EventWebhooks): AsyncResponse<EventWebhooks> {
		return this.http.makeRequest({
			url: `/api/product/${eventId}/webhooks`,
			type: RequestType.POST,
			payload: webhooks,
		});
	}

	testEventWebhooks(eventId: SchemeID, testWebhook: TestEventWebhook): AsyncResponse<TestEventWebhook> {
		return this.http.makeRequest({
			url: `/api/product/${eventId}/webhooks/test`,
			type: RequestType.POST,
			payload: testWebhook,
		});
	}

	setEventRedirectProductId = (eventId: SchemeID, redirectProductId: number): AsyncResponse<RedirectProductId> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/dashboard/updateredirectid`,
			type: RequestType.POST,
			payload: {
				id: redirectProductId,
			},
		});

	getEventRequestFieldServices(eventId: SchemeID): AsyncResponse<string> {
		return this.http.makeRequest({
			url: `/api/product/${eventId}/fieldservicerates`,
			type: RequestType.GET,
		});
	}

	getAtTheDoorUrls = (eventId: SchemeID, scheduleItemId: SchemeID): AsyncResponse<EventAtTheDoor> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/atthedoor?scheduleItemId=${scheduleItemId}`,
			type: RequestType.GET,
		});

	getEventOrders(eventId: SchemeID, filters: EventOrderFilter): AsyncResponse<EventOrdersList> {
		const stringifiedFilter = stringifyObjectValues(filters);
		const queryParams = new URLSearchParams(stringifiedFilter).toString();
		return this.http.makeRequest({
			url: `/api/product/${eventId}/orders?${queryParams}`,
			type: RequestType.GET,
		});
	}

	eventImageUpload = (eventId: SchemeID, imageType: ImageServiceType, imageData: FormData, replace = true) =>
		this.http.http.post(
			`${config.baseURL}/api/upload/image?itemId=${eventId}&itemTypeId=${imageType}&replace=${replace}`,
			imageData,
			{
				headers: {
					Authorization: `Bearer ${this.tokenService.getToken()}`,
				},
			}
		) as AsyncResponse<EventImage>;

	getEventPromoters = (eventId: SchemeID): AsyncResponse<PromotersPageInfo> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/promoters`,
			type: RequestType.GET,
		});

	setEventPromotersIncentive = (eventId: SchemeID, incentiveMessage: string): AsyncResponse<PromotersPageInfo> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/promoters/incentivemessage/update`,
			type: RequestType.POST,
			payload: {
				message: incentiveMessage,
			},
		});

	getEventPromoterNetworks = (eventId: SchemeID): AsyncResponse<PromoterNetworksPageInfo> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/promoter-networks`,
			type: RequestType.GET,
		});

	createPromoterNetwork = (eventId: SchemeID, newPromoterNetworkInfo: NewPromoterNetworkInfo): AsyncResponse<PromoterNetworksPageInfo> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/promoter-networks`,
			type: RequestType.POST,
			payload: newPromoterNetworkInfo,
		});

	getGuestList = (
		id: SchemeID,
		guestListRequestInfo: GuestListRequestInfo
	): AsyncResponse<ListResultInfo> => {
		const stringifiedFilter = stringifyObjectValues(guestListRequestInfo);
		const queryParams = new URLSearchParams(stringifiedFilter).toString();
		return this.http.makeRequest({
			url: `/api/product/${id}/guestlist?${queryParams}`,
			type: RequestType.GET,
		});
	};


	getGuestInfo = (
		eventId: SchemeID,
		purchaseId: number,
		purchaseItemId: number
	): AsyncResponse<SelectedGuestInfo> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/guestlist/guestinfo?${this.http.stringifyQueryParams({
				purchaseId: purchaseId,
				purchaseItemId: purchaseItemId,
			})}`,
			type: RequestType.GET,
		});

	reSendTickets = (
		eventId: SchemeID,
		purchaseId: number
	): AsyncResponse<ResendTicketsResult> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/guestlist/resendtickets?${this.http.stringifyQueryParams({
				purchaseId: purchaseId,
			})}`,
			type: RequestType.PUT,
		});

	getEventRegistrationSettings = (eventId: SchemeID): AsyncResponse<EventRegistrationSettings> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/registrations/settings`,
			type: RequestType.GET,
		});

	updateEventRegistrationSettings = (
		eventId: SchemeID, eventRegistrationsSettings: EventRegistrationSettings): AsyncResponse<EventRegistrationSettings> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/registrations/settings`,
			type: RequestType.POST,
			payload: eventRegistrationsSettings,
		});

	getEventRegistrationsResults = (eventId: SchemeID, filter: RegistrationFilter): AsyncResponse<EventRegistrations> => {
		const stringifiedFilter = stringifyObjectValues(filter);
		const queryParams = new URLSearchParams(stringifiedFilter).toString();
		return this.http.makeRequest({
			url: `/api/product/${eventId}/registrations?${queryParams}`,
			type: RequestType.GET,
		});
	};

	downloadRegistrationCSV = (eventId: SchemeID): AsyncResponse<string> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/registrations/download`,
			type: RequestType.GET,
		});

	skipEventProgressCompletionItem = (eventId: SchemeID, itemId: number): AsyncResponse<EventDashboard> => this.http.makeRequest({
		url: `/api/product/${eventId}/eventprogressitem/${itemId}/skip`,
		type: RequestType.POST,
	});

	copySeatingChart = (
		copySeatingChartRequest: EventCopySeatingChartRequest
	): AsyncResponse<EventCopySeatingChartResult> => this.http.makeRequest({
		url: '/api/users/seats/chart/copy',
		type: RequestType.POST,
		payload: copySeatingChartRequest,
	});

	deleteDraftEvent = (eventId: SchemeID): AsyncResponse<boolean> => this.http.makeRequest({
		url: `/api/product/${eventId}/delete`,
		type: RequestType.POST,
	});

	markAsPaid = (eventId: SchemeID, orderId: SchemeID, productPaymentGatewayDetailsLinkId: number): AsyncResponse<boolean> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/orders/${orderId}/mark-as-paid`,
			type: RequestType.POST,
			payload: {
				productPaymentGatewayDetailsLinkId : productPaymentGatewayDetailsLinkId,
			},
		});

	getOrderDetails = (eventId: SchemeID, orderId: SchemeID): AsyncResponse<ViewOrder> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/orders/${orderId}`,
			type: RequestType.GET,
		});
}
