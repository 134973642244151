import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
	selector: 'app-large-text-field',
	templateUrl: './large-text-field.component.html',
	styleUrls: ['./large-text-field.component.sass'],
})
export class LargeTextFieldComponent implements OnInit {
	@Input() isMobile: boolean;
	@Input() inputFormControl: FormControl;
	@Input() label: string;

	isRequired = false;

	ngOnInit(): void {
		this.isRequired = this.inputFormControl.hasValidator(Validators.required);
	}
}
