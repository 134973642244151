import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ApprovalType, EventRegistrationSettings } from '@app/models/event.model';
import { isEqual } from 'lodash';

@Component({
	selector: 'app-registration-settings',
	templateUrl: './registration-settings.component.html',
	styleUrls: ['./registration-settings.component.sass'],
})
export class RegistrationSettingsComponent {
	isApprovalAutomatic = false;

	@Input() isWeb: boolean;
	@Input() isMobile: boolean;
	@Input() eventId: number;
	@Input() isPageLoading = true;
	@Input() isTimeValid = true;
	@Input() isFormPristine = true;
	@Input() tempSettings: EventRegistrationSettings;
	@Input() registrationSettings = new EventRegistrationSettings();
	@Input() hasSettings = false;
	dateToEval: number;
	dateFromEval: number;

	@Output() saveSettings = new EventEmitter<EventRegistrationSettings>();
	@Output() openCollectRegInfo = new EventEmitter();
	@Output() pristineChange = new EventEmitter<boolean>();

	ngOnInit() {
		if (this.hasSettings) {
			this.setDateTime();
			this.setApprovalType();
		}
	}

	handleApprovalTypeChange() {
		if (this.isApprovalAutomatic) {
			this.tempSettings.approvalType = ApprovalType['Automatic'];
		} else {
			this.tempSettings.approvalType = ApprovalType['Manual'];
		}
		this.handleFormChange();
	}

	setApprovalType() {
		if (this.tempSettings.approvalType) {
			if (this.tempSettings.approvalType === ApprovalType['Automatic']) {
				this.isApprovalAutomatic = true;
			} else {
				this.isApprovalAutomatic = false;
			}
		}
	}

	handleSaveChanges() {
		this.saveSettings.emit(this.tempSettings);
	}

	handleTimeValidation() {
		this.setDateTime();
		if (this.dateFromEval >= this.dateToEval) {
			this.isTimeValid = false;
		} else {
			this.isTimeValid = true;
		}
	}

	setDateTime() {
		this.dateFromEval = new Date(this.tempSettings.dateFrom).getTime();
		this.dateToEval = new Date(this.tempSettings.dateTo).getTime();
	}

	handleDateTimeChange() {
		this.isFormPristine = false;
		this.pristineChange.emit(this.isFormPristine);
	}

	handleEnabledChange(value: boolean) {
		if (!value) {
			this.tempSettings.approvalType = ApprovalType.None;
		} else if (this.tempSettings.approvalType === ApprovalType.None) {
			this.tempSettings.approvalType = ApprovalType.Manual;
		}

		this.handleFormChange();
	}

	handleFormChange() {
		if (!isEqual(this.tempSettings, this.registrationSettings)) {
			this.isFormPristine = false;
			this.pristineChange.emit(this.isFormPristine);
		}
	}

	handleEditRegistrationForm() {
		this.openCollectRegInfo.emit();
	}
}
