<div
	class="l-container"
>
	<div
		class="l-content l-header-content"
		[ngClass]="{ 'mobile': isMobile }"
	>
		<app-web-content-header
			class="l-header"
			[headerContent]="headerContent"
			[mediumWidth]="true"
			[showBreadCrumb]="!isMobile"
			[showTitle]="!isMobile"
			[handleRoutingExternally]='true'
			(handleExternalNavigation)='handleBreadCrumbNavigation($event)'
		></app-web-content-header>
	</div>
	<div [ngSwitch]="tabPageView" class="l-tab-view">
		<div *ngSwitchCase="'VIEW'" class="l-tab-list" [class.mobile]="isMobile">
			<app-tabbed-content-list
				[eventId]="eventId"
				[isMobile]="isMobile"
				(addTab)="handleAddTab()"
				(editTab)="handleTabEdit($event)"
			></app-tabbed-content-list>
		</div>
		<div *ngSwitchCase="'ADD'" class="l-tab-view">
			<app-add-edit-tab
				[isMobile]="isMobile"
				[eventId]="eventId"
				[isEditing]="isEditing"
				(tabComplete)="handleTabComplete()"
				(doCancel)="handleTabComplete()"
			></app-add-edit-tab>
		</div>
		<div *ngSwitchCase="'EDIT'" class="l-tab-view">
			<app-add-edit-tab
				[isMobile]="isMobile"
				[eventId]="eventId"
				[tabForEditing]="tabForEditing"
				[isEditing]="isEditing"
				(tabComplete)="handleTabComplete()"
				(doCancel)="handleTabComplete()"
			></app-add-edit-tab>
		</div>
	</div>
</div>