import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormGroup, UntypedFormControl } from '@angular/forms';
import { BreakdownRangeFilterOptions, BreakdownRangeFilterPresets, BreakdownRangeFilterRange } from './overview-reports-filters.config';
import { DatesService } from '@app/services/dates/dates.service';
import { BreakdownRangeFilterOption } from './overview-reports-filters.config';
import { SchemeID } from '@app/models/dataSchema.model';
import { ScheduleItem } from '@app/models/schedule.model';
import { Observable, combineLatest, filter, takeUntil } from 'rxjs';

@Component({
	selector: 'app-overview-reports-filters',
	templateUrl: './overview-reports-filters.component.html',
	styleUrls: ['./overview-reports-filters.component.sass'],
})
export class OverviewReportsFiltersComponent {
	@Input() scheduleItems: ScheduleItem[];
	@Input() isMobile = false;
	@Input() salesFrom: Date;
	@Input() salesTo: Date;
	@Input() eventCreationDateString: string;
	@Input() eventEndDateString: string;
	@Output() breakdownRangeFilterChange = new EventEmitter<{ to: Date; from: Date; filterType: BreakdownRangeFilterRange }>();
	@Output() scheduleItemFilterChange = new EventEmitter<{ scheduleItemId: SchemeID }>();

	form: FormGroup;
	breakdownRangeFilter = BreakdownRangeFilterRange.ALL_TIME;
	breakdownRangeFilterPresets = BreakdownRangeFilterPresets;
	breakdownRangeFilterOptions = BreakdownRangeFilterOptions;
	eventCreationDate: Date;
	eventEndDate: Date;
	destroyed$: EventEmitter<void> = new EventEmitter<void>();
	customStartDate$: Observable<UntypedFormControl>;
	customEndDate$: Observable<UntypedFormControl>;

	constructor(public datesService: DatesService) {}

	ngOnInit() {
		this.form = new FormGroup({
			breakdownRangeSelector: new UntypedFormControl(this.breakdownRangeFilter),
			reportTypeSelector: new UntypedFormControl(''),
			scheduleSelector: new UntypedFormControl(''),
			startDate: new UntypedFormControl(null),
			endDate: new UntypedFormControl(null),
		});

		this.form.get('breakdownRangeSelector').valueChanges.subscribe(() => {
			if (this.form.get('breakdownRangeSelector').value !== 5) {
				this.handleBreakdownRangeFilterOutput();
			}
		});

		this.customStartDate$ = this.form.get('startDate').valueChanges;
		this.customEndDate$ = this.form.get('endDate').valueChanges;
		combineLatest([this.customEndDate$, this.customStartDate$])
			.pipe(
				takeUntil(this.destroyed$),
				filter(([endDate, startDate]) => startDate != null && endDate != null)
			)
			.subscribe(() => {
				this.handleBreakdownRangeFilterOutput();
			});

		this.eventCreationDate = new Date(this.eventCreationDateString);
		this.eventEndDate = new Date(this.eventEndDateString);
	}

	getMaxDate() {
		const endDateValue = this.form.get('endDate')?.value;
		if (endDateValue) {
			const endDate = new Date(endDateValue);
			if (endDate < this.eventEndDate) {
				return endDate;
			}
		}
		return this.eventEndDate;
	}

	getMinDate() {
		const startDateValue = this.form.get('startDate')?.value;
		if (startDateValue) {
			const startDate = new Date(startDateValue);
			if (startDate > this.eventCreationDate) {
				return startDate;
			}
		}
		return this.eventCreationDate;
	}

	fromForm(controlKey: string): AbstractControl {
		if (this.form) {
			return this.form.get(controlKey);
		}
		return null;
	}

	handleBreakdownRangeFilterOutput() {
		const breakdownRangeSelectorValue = this.form.get('breakdownRangeSelector').value;

		const filterOption: BreakdownRangeFilterOption = this.breakdownRangeFilterPresets[breakdownRangeSelectorValue];
		const filterType = filterOption.key;

		let to = new Date();
		let from = new Date();

		if (filterOption && Number.isInteger(filterOption.range)) {
			to.setDate(to.getDate() + 1);
			from.setDate(to.getDate() - filterOption.range - 1);
		}
		if (filterType === BreakdownRangeFilterRange.CUSTOM) {
			to = new Date(this.form.get('endDate').value);
			to.setDate(to.getDate() + 1);
			from = new Date(this.form.get('startDate').value);
		}

		if (filterType === BreakdownRangeFilterRange.ALL_TIME) {
			to = this.salesTo;
			from = this.salesFrom;
		}

		this.breakdownRangeFilterChange.emit({ from, to, filterType });
	}

	handleScheduleFilterOutput(scheduleItem: ScheduleItem) {
		this.scheduleItemFilterChange.emit({
			scheduleItemId: scheduleItem.id,
		});
	}
}
