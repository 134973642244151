import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'formattedDatePipe',
})
export class FormattedDatePipe implements PipeTransform {
	transform(dateString: string): string {
		const timestamp = Date.parse(dateString);
		const date = new Date(timestamp);

		const options: Intl.DateTimeFormatOptions = {
			weekday: 'short',
			month: 'long',
			day: 'numeric',
			year: 'numeric',
		};

		return date.toLocaleDateString('en-US', options);
	}
}

@Pipe({
	name: 'formattedShortDatePipe',
})
export class FormattedShortDatePipe implements PipeTransform {
	transform(dateString: string): string {
		const timestamp = Date.parse(dateString);
		const date = new Date(timestamp);

		const options: Intl.DateTimeFormatOptions = {
			month: '2-digit',
			day: '2-digit',
			year: 'numeric',
		};

		return date.toLocaleDateString('en-GB', options);
	}
}
