import { Component, Input, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, UntypedFormControl } from '@angular/forms';
import { AddressLines } from '@app/models/profile.model';

@Component({
	selector: 'app-address-lines',
	templateUrl: './address-lines.component.html',
	styleUrls: ['./address-lines.component.sass'],
})
export class AddressLinesComponent implements OnInit, OnChanges {
	@Input() label = '';
	@Input() outerClass = 'l-form-item';
	@Input() labelClass = 'g-form-label l-form-label';
	@Input() control: UntypedFormControl;
	@Input() tooltip = '';
	@Input() required = false;
	@Input() customIcon = '';
	@Input() isMobile = false;

	addressForm: FormGroup;

	constructor(private fb: FormBuilder) {}

	ngOnInit() {
		this.initializeForm();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.control && !changes.control.isFirstChange()) {
			this.initializeForm();
		}
	}

	initializeForm() {
		if (this.control) {
			this.addressForm = this.fb.group({
				type: 1,
				lineOne: [this.control.value?.lineOne || ''],
				lineTwo: [this.control.value?.lineTwo || ''],
				lineThree: [this.control.value?.lineThree || ''],
				lineFour: [this.control.value?.lineFour || ''],
				lineFive: [this.control.value?.lineFive || ''],
			});

			this.addressForm.valueChanges.subscribe((value) => {
				if (this.control) {
					this.control.markAsDirty();
					this.control.setValue(value, { emitEvent: false });
				}
			});

			this.control.valueChanges.subscribe((value) => {
				if (value) {
					this.addressForm.setValue(value, { emitEvent: false });
				} else {
					this.addressForm.reset(new AddressLines());
				}
			});
		}
	}
}
