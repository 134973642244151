import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { SchemeID } from '@app/models/dataSchema.model';
import {
	DraftEventTabForm,
	EventTab,
	EventTabContent,
	EventTabType,
	TabContentImage,
	TabbedContentImageUploadSuccess,
	tabContentTypeMap
} from '@app/models/event.model';
import { NotificationType } from '@app/models/notification.model';
import { TabbedContentService } from '@app/services/events/manage-event/tabbed-content.service';
import { ImageService, ImageServiceType, ImageString } from '@app/services/image/image.service';
import { StoreService } from '@app/services/store/store.service';
import { ConfirmationModalComponent } from '@app/shared/modals/confirmation-modal/confirmation-modal.component';
import { EditorImageUploadOptions } from '@app/shared/editor/editor.component';
import { SelectFieldOption } from '@app/shared/form-field/select-field/select-field.model';
import { UpsertTabContent } from '@app/store/actions/event/event.actions';
import { EventActionsConstants } from '@app/store/actions/event/event.actions.constants';
import { AddNotification } from '@app/store/actions/notification/notification.actions';
import { Actions, ofType } from '@ngrx/effects';
import { Subscription, takeUntil } from 'rxjs';

@Component({
	selector: 'app-add-edit-tab',
	templateUrl: './add-edit-tab.component.html',
	styleUrls: ['./add-edit-tab.component.sass'],
})
export class AddEditTabComponent {
	@Input() eventId: SchemeID;
	@Input() isEditing: boolean;
	@Input() tabForEditing: EventTab;
	@Input() isMobile: boolean;
	@Output() tabComplete = new EventEmitter();
	@Output() doCancel = new EventEmitter();

	selectedTabType: string;
	tabName: string;
	tabContentTypeMap = tabContentTypeMap;
	eventTabTypeEnum = EventTabType;
	form: UntypedFormGroup;
	tabPlaceholder = 'Please select the type of tab';
	newEventTab = new DraftEventTabForm();
	validatorTypeSub: Subscription;
	tabTypeSub: Subscription;
	tabTypeOptions: SelectFieldOption[] = Array.from(tabContentTypeMap).map(([key, label]) => ({
		value: key,
		label,
	}));
	expandedContentItem: boolean[] = [];
	editorImageUploadHandler: EditorImageUploadOptions;
	contentLabel = 'Add Info';
	isImageUploading: boolean;
	dataSource = new MatTableDataSource<any>();

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private store: StoreService,
		private tabbedContentService: TabbedContentService,
		private action$: Actions,
		public imageService: ImageService,
		private dialog: MatDialog
	) { }

	ngOnInit(): void {
		this.onTabContentFormRequest(this.tabForEditing);
		this.initEditorImageUploadHandler();
		this.action$
			.pipe(
				ofType(EventActionsConstants.UPSERT_TAB_CONTENT_SUCCESS),
				takeUntil(this.destroyed$)
			)
			.subscribe(() => {
				this.tabComplete.emit();
			});

		this.action$
			.pipe(
				ofType(EventActionsConstants.TABBED_CONTENT_UPLOAD_IMAGE_SUCCESS, EventActionsConstants.TABBED_CONTENT_UPLOAD_IMAGE_FAILED),
				takeUntil(this.destroyed$)
			)
			.subscribe(({ type, payload }:
			{ type: string; payload: TabbedContentImageUploadSuccess }) => {
				if (type === EventActionsConstants.TABBED_CONTENT_UPLOAD_IMAGE_SUCCESS) {
					if (payload.tabContentUid) {
						const selectedTabContent = this.dataSource.data
							.find((formGroup: UntypedFormGroup) => formGroup.value.contentUid === payload.tabContentUid);

						if (selectedTabContent) {
							selectedTabContent.patchValue({
								originalImage: payload.image.url,
								thumbnail: payload.image.thumbUrl,
							});
							selectedTabContent
								.get('imageString')
								.setValue({
									base64: payload.imageString,
								});
						} else {
							new AddNotification({
								id: 'image-upload-failed',
								title: 'Oops! Your tabbed content could not be found',
								actionType: null,
								action: null,
								type: NotificationType.ERROR,
							});
						};
					} else {
						const contents = this.tabbedContentService.getTabContentControls(this.form) as any;
						const name = 'imageGallery' + new Date().getTime();
						this.addContent(contents, name, payload);
						const selectedTabContent = this.dataSource.data
							.find((formGroup: UntypedFormGroup) =>
								formGroup.value.name === `${name}`);
						if (selectedTabContent) {
							selectedTabContent
								.get('imageString')
								.setValue({
									base64: payload.imageString,
								});
						}
					};
				} else {
					this.store.dispatch(
						new AddNotification({
							id: 'image-upload-failed',
							title: 'Oops! Something went wrong with the upload. Please try again in a moment or contact support.',
							actionType: null,
							action: null,
							type: NotificationType.ERROR,
						})
					);
				}
				this.isImageUploading = false;
			});
	}

	initEditorImageUploadHandler() {
		this.editorImageUploadHandler =
			this.imageService.initEditorImagerUploadHandler(this.eventId, ImageServiceType.CONTENT_TAB, false);
	}

	onTabContentFormRequest = (tab?: EventTab) => {
		this.form = this.tabbedContentService.createTabContentForm(tab);
		if (!tab) {
			this.tabTypeSub = this.form.get('type').valueChanges
				.pipe(takeUntil(this.destroyed$)).subscribe(v => {
					const contents = (this.form.get('content') as UntypedFormArray);
					for (let i = contents.controls.length - 1; i >= 0; i--) {
						contents.removeAt(i);
					}
					if (v && v !== EventTabType.ImageGallery) {
						(this.form.get('content') as UntypedFormArray).push(
							this.tabbedContentService.createTabContentItemForm({
								name: `${tabContentTypeMap.get(v)} 1`,
							})
						);
					}
				});
			this.validatorTypeSub = this.tabbedContentService.setValidatorsOnAdd(this.form);
		} else {
			this.tabbedContentService.getAddingContentValidation(this.form);
		}

		this.dataSource.data = this.tabbedContentService.getTabContentControls(this.form);
	};

	getTabContentType(): string {
		return tabContentTypeMap.get(this.form.get('type').value);
	}

	addContent(contents: UntypedFormArray, name: string, imageGalleryData?: TabbedContentImageUploadSuccess) {
		(this.form.get('content') as UntypedFormArray).push(
			this.tabbedContentService.createTabContentItemForm({
				name: imageGalleryData ? `${name}` : `${name} ${contents.length + 1}`,
				contentUid: new Date().getTime(),
				thumbnail: imageGalleryData?.image.thumbUrl,
				originalImage: imageGalleryData?.image.url,
			})
		);

		this.tabbedContentService.getAddingContentValidation(this.form);
		this.dataSource.data = this.tabbedContentService.getTabContentControls(this.form);
	}

	isSubmitAllowed = (form: UntypedFormGroup): boolean => {
		if (form) {
			const content = (form.get('content') as UntypedFormArray).controls;
			const isContentFormValid = content.every(c => c.valid);
			return form.valid && (content.length === 0 || isContentFormValid) && (form.dirty || form.touched);
		}

		return false;
	};

	onTabContentFormClose = (draftTab?: DraftEventTabForm) => {
		if (draftTab) {
			this.store.dispatch(new UpsertTabContent({
				eventId: this.eventId,
				tab: draftTab,
				isUpdating: !!draftTab.id,
			}));
		}
	};

	handleDeleteTabType(index: number) {
		this.dialog.open(ConfirmationModalComponent, {
			data: {
				title: 'Delete Tab Content?',
				text: 'Are you sure you want to delete your Tab Content?',
				buttonText: 'YES',
				centerText: true,
				isMobile: this.isMobile,
			},
			panelClass: 'g-standard-dialog',
		})
			.afterClosed().subscribe((result) => {
				if (result) {
					(this.form.get('content') as UntypedFormArray).removeAt(index);
					this.dataSource.data = this.tabbedContentService.getTabContentControls(this.form);
					this.form.markAsTouched();
				}
			});
	}

	onImageUpload(image: { files: File[] }, content: UntypedFormGroup) {
		const file: File = image.files[0];
		this.imageService.encodeImage(file, base => {
			content
				.get('imageString')
				.setValue({
					base64: this.imageService.buildBase64String(base, file),
					...this.imageService.buildImagePreview(file),
				});
			content.patchValue({
				originalImage: null,
				thumbnail: null,
			});
		});
	}

	getUploadedImage(content: AbstractControl) {
		const image: EventTabContent = content.value;
		const imageString: ImageString = content.get('imageString').value;
		return this.imageService.getUploadedFile({
			image,
			imageString,
			key: 'thumbnail',
		});
	}

	onImageRemove(content: UntypedFormGroup) {
		content.patchValue({
			originalImage: null,
			imageString: null,
			thumbnail: null,
		});
	}

	onImageGalleryRemove(contents: UntypedFormArray, image: { index: number }) {
		contents.removeAt(image.index);
	}

	getImageName(index: number) {
		return `Image ${index + 1}`;
	}

	getUploadedImagesForGallery(contents: AbstractControl[]) {
		const images = contents.reduce((acc, c) => {
			acc.push(...this.getUploadedImage(c));
			return acc;
		}, []);

		return images;
	}

	onImageGalleryUpload(theImage: TabContentImage, image: { files: File[] }, contents: UntypedFormArray) {
		image.files.filter(({ name }) => name).forEach(file => {
			const content = this.tabbedContentService.createTabContentItemForm();
			this.onImageUpload({ files: [file] }, content);
			contents.push(content);
		});
	}

	handleLoading(isImageUploading: boolean) {
		this.isImageUploading = isImageUploading;
	}

	getFormValue(value: string) {
		return this.form.get(value);
	}

	getFormTabType(tabType: string) {
		return tabContentTypeMap.get(this.getFormValue(tabType).value);
	}

	handleCancel() {
		this.dialog.open(ConfirmationModalComponent, {
			data: {
				title: 'Unsaved Changes',
				text: 'Are you sure you want to leave this page? Any unsaved changes will be discarded.',
				buttonText: 'LEAVE',
				centerText: true,
				isMobile: this.isMobile,
			},
			panelClass: 'g-standard-dialog',
		})
			.afterClosed().subscribe((result) => {
				if (result) {
					this.doCancel.emit();
				}
			});
	}

	fromForm(controlKey: string): AbstractControl{
		if (this.form) {
			return this.form.get(controlKey);
		}
		return null;
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}

}

