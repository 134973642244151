import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Title } from '@angular/platform-browser';


@Injectable()
export class UserControlPanelFeatureResolver implements Resolve<void> {
	constructor(
		private title: Title
	) {}

	resolve(route: ActivatedRouteSnapshot) {
		const { pageTitle } = route.data;

		this.title.setTitle(pageTitle);
	}
}
