import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SchemeID } from '@app/models/dataSchema.model';
import { GuestListReportFormatType, GuestListReportType } from '@app/models/event.model';
import { ScheduleItem } from '@app/models/schedule.model';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { FileSaverService } from '@app/services/file-saver/file-saver.service';
import { URLCreator } from '@app/services/url/url.dictionary';
import { takeUntil } from 'rxjs';

@Component({
	selector: 'app-guest-list-report-modal',
	templateUrl: './guest-list-report-modal.component.html',
	styleUrls: ['./guest-list-report-modal.component.sass'],
})
export class GuestListReportModalComponent implements OnInit {


	isLoading = false;
	isButtonLoading = false;
	isMobile = false;
	isModal = true;
	destroyed$: EventEmitter<void> = new EventEmitter<void>();
	reportTypes: string[];
	selectedScheduleId: number;
	selectedReportType: string;
	selectedFormat: string;
	schedules = this.data.scheduleItems;
	isAllScheduleSelected = false;
	formats = Object.values(GuestListReportFormatType);
	isSeated = this.data.isSeated;
	primaryButtonText = 'DOWNLOAD';
	secondaryButtonText = 'CANCEL';
	scheduleItems: ScheduleItem[];

	constructor(
		public dialogRef: MatDialogRef<GuestListReportModalComponent>,
		private fileSaverService: FileSaverService,
		private breakPointService: BreakpointService,
		@Inject(MAT_DIALOG_DATA) public data: {
			eventId: SchemeID;
			eventName: string;
			isOngoing: boolean;
			scheduleItems: ScheduleItem[];
			isSeated: boolean;
		}
	) { }

	ngOnInit() {
		this.isLoading = true;
		this.breakPointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});
		this.initializeReportTypes();
		if (this.data.scheduleItems) {
			this.sortScheduleItemsDateAscending();
		}
	}

	initializeReportTypes() {
		this.reportTypes = [GuestListReportType.guestList, GuestListReportType.rsvp, GuestListReportType.print];
		if (this.data.isSeated) {
			this.reportTypes.push(GuestListReportType.seating);
		}
		this.isLoading = false;
	}

	handleCancel() {
		this.dialogRef.close();
	}

	handleDownload() {
		if (this.selectedReportType === GuestListReportType.guestList) {
			this.selectedReportType = this.selectedFormat;
		}

		if (this.selectedReportType === GuestListReportType.print){
			window.open(URLCreator.printGuests(this.data.eventId, this.selectedScheduleId));
		} else {
			this.selectedScheduleId = this.selectedScheduleId == null ? 0 : this.selectedScheduleId;
			this.isButtonLoading = true;
			this.fileSaverService.save({
				// eslint-disable-next-line max-len
				url: `/api/product/${this.data.eventId}/guestlist/download?scheduleItemId=${this.selectedScheduleId}&reportType=${this.selectedReportType.toLowerCase()}`,
				// eslint-disable-next-line max-len
				fileName: this.selectedReportType === GuestListReportType.seating || this.selectedReportType === GuestListReportFormatType.excel ? `${this.data.eventName}-${this.selectedReportType}-Report.xlsx` : `${this.data.eventName}-${this.selectedReportType}-Report.csv`,
				onComplete: () => {
					this.isButtonLoading = false;
				},
				onError: () => {
					this.isButtonLoading = false;
				},
			});
		}

		this.selectedScheduleId = undefined;
		this.selectedReportType = undefined;
		this.selectedFormat = undefined;
	}

	canDownload(): boolean {
		const isScheduleSelected = this.data.isOngoing ? this.selectedScheduleId != null : true;
		const isReportTypeSelected = this.selectedReportType != null;
		const isFormatSelected = this.selectedReportType === GuestListReportType.guestList ? this.selectedFormat != null : true;

		return isScheduleSelected && isReportTypeSelected && isFormatSelected;
	}

	onScheduleSelectChange() {
		if (this.selectedScheduleId === 0) {
			this.reportTypes = this.reportTypes.filter(item => item !== GuestListReportType.seating);
		} else {
			if (this.isSeated && !this.reportTypes.includes(GuestListReportType.seating)) {
				this.reportTypes.push(GuestListReportType.seating);
			}
		}
	}

	isSameDaySchedule(scheduleItem: ScheduleItem): boolean {
		const start = new Date(scheduleItem.startDate);
		const end = new Date(scheduleItem.endDate);

		return start.getFullYear() === end.getFullYear() &&
			start.getMonth() === end.getMonth() &&
			start.getDate() === end.getDate();
	}

	sortScheduleItemsDateAscending() {
		const sortedScheduleItems = [...this.data.scheduleItems].sort((a, b) => {
			const dateA = new Date(a.startDate).getTime();
			const dateB = new Date(b.startDate).getTime();

			if (dateA !== dateB) {
				return dateA - dateB;
			} else {
				const endDateA = new Date(a.endDate).getTime();
				const endDateB = new Date(b.endDate).getTime();
				return endDateA - endDateB;
			}
		});
		this.scheduleItems = sortedScheduleItems;
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
