import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { QuantityWarningType } from '@app/models/ticket.model';

@Component({
	selector: 'app-quantity-warning-modal',
	templateUrl: './quantity-warning-modal.component.html',
	styleUrls: ['./quantity-warning-modal.component.sass'],
})
export class QuantityWarningModalComponent {
	quantityWarningTypeEnum = QuantityWarningType;

	constructor(
		public dialogRef: MatDialogRef<QuantityWarningModalComponent>,
		@Inject(MAT_DIALOG_DATA)
		public data: {
			warningType: QuantityWarningType;
			streamWarning: boolean;
		}) { }

	isWarningVisible(warningType: string): boolean {
		return ['zero', 'one', 'both'].includes(warningType);
	}

	getWarningMessage(warningType: string): string {
		const messages = {
			zero: 'This means that this ticket will not be available to order for the event.',
			one: 'This means that there will only be 1 available ticket of this ticket type to order for the event.',
		};
		return messages[warningType] || '';
	}

	getWarningShortMessage(warningType: string): string {
		const messages = {
			zero: 'set to 0. ',
			one: 'set to 1. ',
			both: 'set to 0 and 1. ',
		};
		return messages[warningType] || '';
	}

	getSecondaryWarningMessage(warningType: string): string {
		if (warningType === 'both') {
			return 'An amount of 0 means that this ticket will not be available to order for the event. ' +
				'An amount of 1 means that only 1 ticket will be available to order for the event.';
		}
		return '';
	}

	handleClose(close: boolean): void {
		this.dialogRef.close(close);
	}
}
