import { combineReducers } from '@ngrx/store';
import { Actions } from '@app/store/actions/organiser/organiser.actions';
import { OrganiserActionsConstants } from '@app/store/actions/organiser/organiser.actions.constants';
import { initialState } from './initialState';
import { OrganiserState } from '@app/models/store.model';

const topLinkCampaigns = (
	state = initialState.dashboard.topLinkCampaigns,
	action: Actions
): OrganiserState['dashboard']['topLinkCampaigns'] => {
	switch (action.type) {
		case OrganiserActionsConstants.GET_CURRENT_CAMPAIGNS_SUCCESS:
			return action.payload.topLinkCampaigns;
		default:
			return state;
	}
};

const dashboardEvents = (
	state = initialState.dashboard.dashboardEvents,
	action: Actions
): OrganiserState['dashboard']['dashboardEvents'] => {
	switch (action.type) {
		case OrganiserActionsConstants.GET_DASHBOARD_EVENTS_SUCCESS:
			return {
				...state,
				upcoming: action.payload.dashboardEvents.upcoming,
				past: action.payload.dashboardEvents.past,
			};
		default:
			return state;
	}
};

const salesOverview = (
	state = initialState.dashboard.salesOverview,
	action: Actions
): OrganiserState['dashboard']['salesOverview'] => {
	switch (action.type) {
		case OrganiserActionsConstants.GET_SALES_OVERVIEW_SUCCESS:
			return action.payload.salesOverview;
		default:
			return state;
	}
};


export const dashboard = combineReducers({
	dashboardEvents: dashboardEvents,
	topLinkCampaigns: topLinkCampaigns,
	salesOverview: salesOverview,
});
