<app-my-account-header
	[isMobile]='isMobile'
	pageName="Ticket Resale"
	(navigateBack)="handleNavigationBack()"
>
</app-my-account-header>

<div
	*ngIf="(!isPageLoading && bankVerificationChecked); else pageLoader"
	class="g-account-container"
>
	<div
		class="g-account-content h-100"
		[ngClass]="{ 'mobile': isMobile, 'web': !isMobile }"
	>
		<mat-tab-group
			[mat-stretch-tabs]="isMobile"
			mat-align-tabs="start"
			class="small-tab-header"
		>

			<mat-tab [label]="isMobile ? 'RESALE' : 'AVAILABLE FOR RESALE'">
				<div [ngClass]="{'ticket-resale-select-table': !isMobile}">
					<app-resale-available
						[isMobile]="isMobile"
						[headerContent]="headerContent"
						[purchaseId]="purchaseId"
						[resaleTickets]="resaleTickets"
						[validTicketsForResale]="validTicketsForResale"
						[invalidTicketsForResale]="invalidTicketsForResale"
						[isTicketsLoading]="isTicketsLoading"
						[isListingUpdating]="isListingUpdating"
						[currencySymbol]="currencySymbol"
						title="Put Your Tickets Up For Resale"
					>
					</app-resale-available>
				</div>
			</mat-tab>

			<mat-tab [label]="isMobile ? 'LISTED' : 'CURRENTLY LISTED'">
				<div [ngClass]="{'ticket-resale-select-table': !isMobile}">
					<app-resale-listed
						[isMobile]="isMobile"
						[headerContent]="headerContent"
						[purchaseId]="purchaseId"
						[resaleTickets]="resaleTickets"
						[ticketsInResale]="ticketsInResale"
						[productName]="productName"
						[currencySymbol]="currencySymbol"
						title="Your Currently Listed Tickets"
					>
					</app-resale-listed>
				</div>
			</mat-tab>

		</mat-tab-group>
	</div>
</div>
<ng-template #pageLoader>
	<div class="l-page-loader">
		<mat-spinner></mat-spinner>
	</div>
</ng-template>