import { UserActionsConstants } from '@app/store/actions/user/user.actions.constants';
import * as userActions from '@app/store/actions/user/user.actions';
import { UserState } from '@app/models/store.model';
import { combineReducers, ActionReducer } from '@ngrx/store';
import * as bookingActions from '@app/user-features/manage-booking/manage-booking.actions';
import { ManageBookingConstants } from '@app/user-features/manage-booking/manage-booking.actions';

export const initialState: UserState = {
	info: {
		userName: null,
		encrypt: null,
		email: null,
		id: null,
		seatsDesignerKey: null,
		isAdmin: null,
		contactInfo: {
			firstName: null,
			surname: null,
			phone: null,
			telephone: null,
			company: null,
			dateOfBirth: null,
			email: null,
			gender: null,
			idPassportNumber: null,
			userAddress: {
				city: null,
				isPoBox: false,
				number: null,
				postalCode: null,
				street: null,
				suburb: null,
			},
		},
		emailEncrypt: null,
		apiToken: null,
	},
	isFetching: null,
	manageBooking: {
		booking: null,
		isFetching: false,
		isInvoiceModalOpen: false,
		isChangeDatesModalOpen: false,
		isUpdateSuccess: false,
		isUpdateFailed: false,
	},
	myTickets: {
		bookings: {
			byId: {},
			all: [],
		},
		isFetching: false,
		isFundraiserModalOpen: false,
		registrations: [],
		currentRefundableTickets: {
			refundableTickets: {
				errorMessage: null,
				canRefund: null,
				currencyId: null,
				reference: null,
				productImage: null,
				productName: null,
				refundMethod: {
					canRefundViaQuicket: null,
					gatewaySupportsRefunding: null,
					quicketHasTheMoney: null,
					refundRequiresBankAccount: null,
				},
				tickets: [],
				bank: null,
			},
			purchaseId: null,
		},
		currentTransferableTickets: {
			transferableTickets: {
				statusMessage: null,
				tickets: [],
				email: null,
				onlyOneTransferAllowed: null,
				purchaseReference: null,
			},
			purchaseId: null,
		},
		currentResaleTickets: {
			resaleTickets: {
				purchaseReference: null,
				productName: null,
				refundRequiredBankAccount: null,
				allowPrivate: null,
				allowPublic: null,
				currencyId: null,
				ticketsForResale: [],
				ticketsInResale: [],
			},
			purchaseId: null,
		},
	},
	userSavedEvents: {
		userSavedEvents: [],
	},
	earnCommission: {
		referrals: {
			commissionPercentage: null,
			code: null,
			affiliateLinkExcludingCode: null,
			totalBalanceOwed: null,
			referralCommissionsEarned: [],
			selectedBankAccountSemiHashed: null,
		},
		promoterNetworks: [],
		currentPromoterDetails: {
			id: null,
			productName: null,
			productStatus: null,
			shortlinkCode: null,
			shortlinkUrl: null,
			commissionPercentage: null,
			totalEarned: null,
			selectedBankAccountSemiHashed: null,
			payments: null,
			eventImage: null,
			success: null,
			errorMessage: null,
		},
	},
	isAPIModalOpen: false,
};

type UserActions =
	userActions.Actions
	| bookingActions.Actions;

const isFetching = (
	state = initialState.isFetching,
	action: UserActions
): UserState['isFetching'] => {
	switch (action.type) {
		case UserActionsConstants.VERIFY_TOKEN:
			return true;
		case UserActionsConstants.VERIFY_TOKEN_SUCCESS:
		case UserActionsConstants.VERIFY_TOKEN_FAILED:
			return false;
		default:
			return state;
	}
};

const userSavedEvents = (
	state = initialState.userSavedEvents,
	action: UserActions
): UserState['userSavedEvents'] => {
	switch (action.type) {
		case UserActionsConstants.GET_USER_SAVED_EVENTS_SUCCESS:
			return {
				...state,
				userSavedEvents: action.payload.userSavedEvents,
			};
		default:
			return state;
	}
};

const isAPIModalOpen = (
	state = initialState.isAPIModalOpen,
	action: UserActions
): UserState['isAPIModalOpen'] => {
	switch (action.type) {
		case UserActionsConstants.SET_API_MODAL_FLAG:
			return action.payload.isModalOpen;
		default:
			return state;
	}
};

const info = (
	state = initialState.info,
	action: UserActions
): UserState['info'] => {
	switch (action.type) {
		case UserActionsConstants.VERIFY_TOKEN:
		case UserActionsConstants.LOGOUT_COMPLETE:
			return null;
		case UserActionsConstants.VERIFY_TOKEN_SUCCESS:
			return action.payload.user;
		case UserActionsConstants.GET_USER_DESIGNER_KEY:
			return {
				...state,
				seatsDesignerKey: null,
			};
		case UserActionsConstants.GET_USER_DESIGNER_KEY_SUCCESS:
			return {
				...state,
				seatsDesignerKey: action.payload.seatsDesignerKey,
			};
		case UserActionsConstants.SET_USER_INFO_SUCCESS:
			return {
				...state,
				...action.payload,
			};
		case UserActionsConstants.UPDATE_USER_API_TOKEN:
			return {
				...state,
			};
		case UserActionsConstants.UPDATE_USER_API_TOKEN_SUCCESS:
			return {
				...state,
				apiToken: action.payload,
			};
		case UserActionsConstants.UPDATE_USER_API_TOKEN_FAILED:
			return {
				...state,
			};
		case UserActionsConstants.GET_USER_INFO_SUCCESS:
			return {
				...state,
				contactInfo: action.payload,
			};
		case UserActionsConstants.UPDATE_USER_INFO_SUCCESS:
			return {
				...state,
				contactInfo: action.payload,
				userName: action.payload.email,
			};
		default:
			return state;
	}
};

const earnCommission = (
	state = initialState.earnCommission,
	action: userActions.Actions
): UserState['earnCommission'] => {
	switch (action.type) {
		case UserActionsConstants.GET_REFERRAL_DETAILS_SUCCESS:
			return {
				...state,
				referrals: action.payload.referrals,
			};
		case UserActionsConstants.GET_ALL_PROMOTER_NETWORK_SUCCESS:
			return {
				...state,
				promoterNetworks: action.payload.promoterNetworks,
			};
		case UserActionsConstants.GET_PROMOTER_NETWORK_SUCCESS:
		case UserActionsConstants.UPDATE_PROMOTER_BANK_ACCOUNT_SUCCESS:
		case UserActionsConstants.UPDATE_PROMOTER_NETWORK_LINK_SUCCESS:
			return {
				...state,
				currentPromoterDetails: action.payload.promoterNetwork,
			};
		case UserActionsConstants.UPDATE_REFERRAL_BANK_ACCOUNT_SUCCESS:
			if (action.payload.updateReferrals.success) {
				return {
					...state,
					referrals: {
						...state.referrals,
						selectedBankAccountSemiHashed: action.payload.updateReferrals.bankAccountSemiHashed,
					},
				};
			} else {
				return {
					...state,
				};
			}
		case UserActionsConstants.UPDATE_REFERRAL_CODE_SUCCESS:
			if (action.payload.updateReferrals.success) {
				return {
					...state,
					referrals: {
						...state.referrals,
						code: action.payload.updateReferrals.code,
					},
				};
			} else {
				return {
					...state,
				};
			}
		default:
			return state;
	}
};

const myTickets = (
	state = initialState.myTickets,
	action: userActions.Actions
): UserState['myTickets'] => {
	switch (action.type) {
		case UserActionsConstants.GET_USER_MY_TICKETS:
		case UserActionsConstants.GET_USER_MY_REGISTRATIONS:
			return {
				...state,
				isFetching: true,
			};
		case UserActionsConstants.GET_USER_MY_TICKETS_SUCCESS:
			return {
				...state,
				bookings: action.payload.bookings,
				isFetching: false,
			};
		case UserActionsConstants.GET_USER_MY_REGISTRATIONS_SUCCESS:
			return {
				...state,
				registrations: action.payload.registrations,
				isFetching: false,
			};
		case UserActionsConstants.GET_USER_MY_TICKETS_FAILED:
		case UserActionsConstants.GET_USER_MY_REGISTRATIONS_FAILED:
			return {
				...state,
				isFetching: false,
			};
		case UserActionsConstants.GET_REFUNDABLE_TICKETS_SUCCESS:
			return {
				...state,
				currentRefundableTickets: {
					refundableTickets: action.payload.refundableTickets,
					purchaseId: action.payload.purchaseId,
				},
			};
		case UserActionsConstants.POST_REFUNDABLE_TICKETS_SUCCESS:
			if (action.payload.refundableTickets.errorMessage?.includes('Success')
				|| action.payload.refundableTickets.errorMessage?.includes('successfully')) {
				return {
					...state,
					currentRefundableTickets: {
						refundableTickets: action.payload.refundableTickets,
						purchaseId: action.payload.purchaseId,
					},
				};
			} else {
				return state;
			}
		case UserActionsConstants.GET_TRANSFERABLE_TICKETS_SUCCESS:
			return {
				...state,
				currentTransferableTickets: {
					transferableTickets: action.payload.transferableTickets,
					purchaseId: action.payload.purchaseId,
				},
			};
		case UserActionsConstants.TRANSFER_TICKETS_SUCCESS:
			if (action.payload.transferableTickets.statusMessage.includes('Success')) {
				return {
					...state,
					currentTransferableTickets: {
						transferableTickets: action.payload.transferableTickets,
						purchaseId: action.payload.purchaseId,
					},
				};
			} else {
				return state;
			}
		case UserActionsConstants.GET_RESALE_TICKETS_SUCCESS:
		case UserActionsConstants.POST_RESALE_TICKETS_SUCCESS:
		case UserActionsConstants.CANCEL_RESALE_TICKETS_SUCCESS:
			return {
				...state,
				currentResaleTickets: {
					resaleTickets: action.payload.resaleTickets,
					purchaseId: action.payload.purchaseId,
				},
			};
		default:
			return state;
	}
};

const manageBooking = (
	state = initialState.manageBooking,
	action: bookingActions.Actions
): UserState['manageBooking'] => {
	switch (action.type) {
		case ManageBookingConstants.GET_USER_MANAGE_BOOKING:
		case ManageBookingConstants.UPDATE_BOOKING_INVOICE:
		case ManageBookingConstants.UPDATE_MANAGE_BOOKING:
			return {
				...state,
				isFetching: true,
				isUpdateSuccess: false,
				isUpdateFailed: false,
			};
		case ManageBookingConstants.GET_USER_MANAGE_BOOKING_SUCCESS:
			return {
				...state,
				booking: action.payload.booking,
				isFetching: false,
			};
		case ManageBookingConstants.UPDATE_MANAGE_BOOKING_SUCCESS:
			return {
				...state,
				isFetching: false,
				isUpdateSuccess: true,
				isUpdateFailed: false,
				booking: action.payload.booking,
			};
		case ManageBookingConstants.GET_USER_MANAGE_BOOKING_FAILED:
		case ManageBookingConstants.UPDATE_BOOKING_INVOICE_FAILED:
		case ManageBookingConstants.UPDATE_MANAGE_BOOKING_FAILED:
			return {
				...state,
				isFetching: false,
				isUpdateSuccess: false,
				isUpdateFailed: true,
			};
		case ManageBookingConstants.UPDATE_BOOKING_INVOICE_SUCCESS:
			return {
				...state,
				isFetching: false,
				isInvoiceModalOpen: false,
				booking: {
					...state.booking,
					invoice: action.payload.invoice,
				},
			};
		case ManageBookingConstants.SET_UPDATE_INVOICE_MODAL_FLAG:
			return {
				...state,
				isInvoiceModalOpen: action.payload.isModalOpen,

			};
		case ManageBookingConstants.SET_BOOKING_CHANGE_DATES_MODAL_FLAG:
			return {
				...state,
				isChangeDatesModalOpen: action.payload.isModalOpen,
			};
		case ManageBookingConstants.IS_UPDATE_BOOKING_SUCCESS:
			return {
				...state,
				isUpdateSuccess: action.payload.isUpdateSuccess,
			};
		case ManageBookingConstants.IS_UPDATE_BOOKING_FAILED:
			return {
				...state,
				isUpdateFailed: action.payload.isUpdateFailed,
			};
		case ManageBookingConstants.DOWNLOAD_TICKETS_SEPERATELY:
			return {
				...state,
			};
		case ManageBookingConstants.DOWNLOAD_TICKETS_SEPERATELY_SUCCESS:
			return {
				...state,
			};
		case ManageBookingConstants.DOWNLOAD_TICKETS_SEPERATELY_FAILED:
			return {
				...state,
			};
		default:
			return state;
	}
};

export const userReducer: ActionReducer<UserState, UserActions> = combineReducers({
	isAPIModalOpen,
	isFetching,
	info,
	manageBooking,
	earnCommission,
	myTickets,
	userSavedEvents,
});
