import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { CountryISO } from 'ngx-intl-tel-input-gg';
import { takeUntil } from 'rxjs';

@Component({
	selector: 'app-phone-input',
	templateUrl: './phone-input.component.html',
	styleUrls: ['./phone-input.component.sass'],
})
export class PhoneInputComponent implements OnInit, OnChanges, OnDestroy {
	@Input() disabled = false;
	@Input() placeholder = '';
	@Input() label = '';
	@Input() outerClass = 'l-form-item';
	@Input() labelClass = 'g-form-label l-form-label';
	@Input() inputClass = 'form-control';
	@Input() control: FormControl;
	@Input() tooltip = '';
	@Input() customId = '';
	@Input() required = false;
	@Input() isMobile = false;
	@Input() large = true;
	@Input() syncErrorsWithDisplayControl = true;

	preferredCountries = [CountryISO.SouthAfrica];
	displayFormControl = new FormControl();
	destroyed$: EventEmitter<void> = new EventEmitter<void>();
	firstSync = true;
	isSyncing = false;

	@Output() touched = new EventEmitter<void>();

	ngOnInit() {
		this.initFormControl();
		this.syncControlWithDisplayControl();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.control || changes.disabled) {
			this.initFormControl();
			this.setDisabledState();
		}
	}

	initFormControl() {
		if (this.control.hasValidator(Validators.required)) {
			this.displayFormControl.addValidators(Validators.required);
		}

		this.displayFormControl.setValue(this.control.value);
	}

	syncControlWithDisplayControl() {
		this.displayFormControl.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
			if (this.isSyncing) {
				return;
			}
			if (value?.number && value.e164Number !== this.control.value) {
				if (this.firstSync) {
					this.firstSync = false;
				}
				this.syncControl(this.control, value.e164Number, !this.firstSync);
			} else if (!value) {
				this.syncControl(this.control, null, true);
			}
		});

		this.control.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
			if (this.isSyncing) {
				return;
			}
			if (!value) {
				this.syncControl(this.displayFormControl, null, false);
				return;
			}

			if (value !== this.displayFormControl.value?.e164Number) {
				this.syncControl(this.displayFormControl, value, false);
			}
		});

		if (this.syncErrorsWithDisplayControl) {
			this.displayFormControl.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
				this.control.setErrors(this.displayFormControl.errors);
			});
		}
	}

	syncControl(formControl: FormControl<any>, value: any, markAsDirty: boolean): void {
		this.isSyncing = true;
		formControl.setValue(value);
		if (markAsDirty) {
			formControl.markAsDirty();
		}
		this.isSyncing = false;
	}

	setDisabledState() {
		if (this.disabled || this.control.disabled) {
			this.displayFormControl.disable({ emitEvent: false });
		} else {
			this.displayFormControl.enable({ emitEvent: false });
		}
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
