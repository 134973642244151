<app-my-account-header
	[isMobile]='isMobile'
	pageName="My Registrations"
	(navigateBack)="handleNavigationBack()"
>
</app-my-account-header>
<div class="g-account-container">
	<div
		class="g-account-content"
		[ngClass]="{ 'mobile': isMobile, 'web': !isMobile }"
	>
		<app-web-content-header
			class="w-100"
			[headerContent]="headerContent"
			[longWidth]="true"
			[isMobile]="isMobile"
		></app-web-content-header>

		<div class="d-flex flex-column">
			<app-search-bar
				id="registration-search-bar"
				(sortOption)="handleSort($event)"
				[isMobile]="isMobile"
				[showFilter]="false"
				(searchAndFilter)="handleRegistrationsSearch($event)"
				(openFilterBottomSheet)="handleBottomSheetFilter()"
				(mobileSearchTerm)="handleRegistrationMobileSearchTerm($event)"
			></app-search-bar>
			<div *ngIf="!isRegistrationsLoading else registrationsLoading">
				<div class="l-card-container mt-4">
					<ng-container *ngIf="filteredRegistrations.length > 0; else noRegistrations">
						<a
							*ngFor="let registration of filteredRegistrations"
							class="g-flat-link l-card"
							[routerLink]="[handleRouterLink(registration.preRegistrationId)]"
						>
							<app-ticket-card
								[data]="registration"
								[productStatus]="registration.status"
								[productName]="registration.productName"
								[lineOne]="registration.dateFrom"
								[lineTwo]="registration.dateTo"
								[eventImage]="registration.eventImage"
								[reference]="'REF' + registration.preRegistrationId"
								[isTicket]="true"
								[isRegistration]="true"
								type="registration"
								[isMobile]="isMobile"
								class="l-card-content"
							></app-ticket-card>
						</a>
					</ng-container>
				</div>
			</div>
			<ng-template #registrationsLoading>
				<div class="l-loading">
					<mat-spinner></mat-spinner>
				</div>
			</ng-template>
			<ng-template #noRegistrations>
				<div
					class="l-empty-state"
					*ngIf="currentRegistrationSearchTerm.length > 0; else noUserRegistrations"
				>
					<h3 class="mb-3">Nothing to see here...</h3>
					<div class="mb-3">Nothing matches your search or filters</div>
				</div>
				<ng-template #noUserRegistrations>
					<div class="l-empty-state">
						<h3 class="mb-3">No Registrations</h3>
						<div class="mb-3">You currently have no registrations. Want to change that?</div>
						<button
							class="g-primary-button"
							(click)="handleEventNavigation()"
						>Browse
							Events</button>
					</div>
				</ng-template>
			</ng-template>
		</div>
	</div>
</div>