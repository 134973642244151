import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AccountTicketResaleItem } from '@app/models/user.model';

@Component({
	selector: 'app-mobile-resale-card',
	templateUrl: './mobile-resale-card.component.html',
	styleUrls: ['./mobile-resale-card.component.sass'],
})
export class MobileResaleCardComponent {
	@Input() ticket: AccountTicketResaleItem;
	@Input() title: string;
	@Input() currencySymbol: string;
	@Input() cancelButtonText: string;

	@Output() cancelTicket: EventEmitter<void> = new EventEmitter<void>();

	handleTicketCancel() {
		this.cancelTicket.emit();
	}
}
