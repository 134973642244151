import { AfterViewInit, Component, Inject, NgZone } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserChartStatus } from '@app/models/ticket.model';

declare let seatsio: any;

@Component({
	selector: 'app-seating-chart-modal',
	templateUrl: './seating-chart-modal.component.html',
	styleUrls: ['./seating-chart-modal.component.sass'],
})
export class SeatingChartModalComponent implements AfterViewInit {
	seatingChart: any;
	isCancelConfirmation = false;

	constructor(
		private ngZone: NgZone,
		public dialogRef: MatDialogRef<SeatingChartModalComponent>,
		@Inject(MAT_DIALOG_DATA)
		public data: {
			chartId: string;
			designerKey: string;
			isDonationAdded: boolean;
			chartStatus: string;
		}) { }

	ngAfterViewInit() {
		this.addChart();
	}

	addChart() {
		if (this.data.designerKey) {
			this.seatingChart = new seatsio.SeatingChartDesigner({
				divId: 'seating-chart',
				v2: true,
				designerKey: this.data.designerKey,
				chartKey: this.data.chartId,
				openDraftDrawing: this.data.chartStatus === UserChartStatus.PUBLISHED_WITH_DRAFT,
				onChartCreated: (chartId: string) => {
					this.ngZone.run(() => {
						this.data.chartId = chartId;
					});
				},
				onExitRequested: () => {
					this.ngZone.run(() => {
						this.toggleCancellation();
					});
				},
			}).render();
		}
	}

	handleClose() {
		this.seatingChart.destroy();
		this.dialogRef.close(this.data.chartId);
	}

	toggleCancellation() {
		this.isCancelConfirmation = !this.isCancelConfirmation;
	}


}
