<app-autocomplete-field
		[offset]="'zero'"
		[options]="options"
		[control]="timezoneCtrl"
		[filter]="filterTimezones"
		[itemSelect]="itemSelect"
		(itemSelectChange)="onItemSelect($event)"
		placeholder="Search for timezone... e.g Europe/Kiev"
>
	<ng-template pTemplate="item" let-item>
		<span>{{ item.name.split('_').join(' ') }}</span> |
		<small>GMT {{ item.offset }}</small>
	</ng-template>
</app-autocomplete-field>

