import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { EventDomainAPI } from '@app/api/domains/event';
import { ofTypeExt, handleSuccessRequest } from '@app/utils/Store';
import { GetEventWidget, WidgetActionConstants, GetEventWidgetSuccess, GetEventWidgetFailed } from './widget.actions';
import { switchMap, catchError } from 'rxjs/operators';
import { handleFailedRequest } from '../../../utils/Store';
import { Go } from '@app/store/actions/router/router.actions';
import { defaultDashboardRoute } from '@app/services/url/url.service';

@Injectable()
export class WidgetEffects {
	constructor(private action$: Actions, private api: EventDomainAPI) {}

	getEventWidget$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<GetEventWidget>(WidgetActionConstants.GET_EVENT_WIDGET),
			switchMap(({ action, payload: { id } }) =>
				this.api.getWidget(id).pipe(
					handleSuccessRequest(
						({ payload: widget }) =>
							new GetEventWidgetSuccess({
								id,
								widget,
							})
					),
					catchError(
						handleFailedRequest(
							new GetEventWidgetFailed({
								msg: 'can\'t get widget details',
								action,
								critical: true,
								meta: {
									extraAction: new Go({
										path: [defaultDashboardRoute],
									}),
									extraActionTitle: 'Go to dashboard',
									allowClose: false,
								},
							})
						)
					)
				)
			)
		)
	);
}
