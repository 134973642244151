import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
	DraftEventCollectInfoForm,
	EventCollectInfo,
	EventCollectInfoBase,
	EventQuestionToggle,
	EventRegistrationSettings,
	RegistrationPageMode
} from '@app/models/event.model';
import * as eventSelector from '@app/store/selectors/event.selector';
import { HeaderContent } from '@app/models/shared';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { StoreService } from '@app/services/store/store.service';
import { InternalURLCreator } from '@app/services/url/url.dictionary';
import { Actions, ofType } from '@ngrx/effects';
import { Observable, Subscription, map, of, take, takeUntil, withLatestFrom } from 'rxjs';
import {
	DeleteCollectInfo,
	GetEventCollectInfo,
	GetEventRegistrationsSettings,
	GetProfileCollectInfo,
	ReorderCollectInfo,
	SetCollectInfoProperty,
	UpdateEventRegistrationsSettings,
	UpsertEventCollectInfo
} from '@app/store/actions/event/event.actions';
import { EventActionsConstants } from '@app/store/actions/event/event.actions.constants';
import { UntypedFormGroup } from '@angular/forms';
import { NormalizedScheme, SchemeID } from '@app/models/dataSchema.model';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CollectInfoService } from '@app/services/events/tickets/collect-info.service';
import { SelectFieldOption } from '@app/shared/form-field/select-field/select-field.model';
import * as eventSelectors from '@app/store/selectors/event.selector';
import { Go } from '@app/store/actions/router/router.actions';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '@app/shared/modals/confirmation-modal/confirmation-modal.component';
import { FormCanDeactivate } from '@app/services/guards/event.guard';

@Component({
	selector: 'app-registrations',
	templateUrl: './registrations.component.html',
	styleUrls: ['./registrations.component.sass'],
})
export class RegistrationsComponent extends FormCanDeactivate implements OnInit {
	headerContent: HeaderContent = {
		breadCrumbs: [
			{
				routeName: 'Manage Checkout',
				routeTo: () => InternalURLCreator.tickets(this.eventId),
			},
			{
				routeName: 'Registrations',
				routeTo: () => InternalURLCreator.registrations(this.eventId),
			},
		],
		title: 'Registrations',
		description: `Set up attendee registration requirements before ticket purchase. Configure and manage 
		registration details easily on this page and approve or deny registrations to control your event's attendance.`,
	};
	isWeb: boolean;
	isMobile: boolean;
	eventId: number;
	isPageLoading = true;
	isTimeValid = true;
	isFormPristine = true;
	tempSettings: EventRegistrationSettings;
	registrationSettings = new EventRegistrationSettings();
	hasSettings = false;
	mode: string = RegistrationPageMode.Settings;
	RegistrationPageMode = RegistrationPageMode;
	questions: EventCollectInfo[];
	isAddingCustom = false;
	collectInfoForm: UntypedFormGroup;
	collectInfoFormChangeSub: Subscription;
	isEditingQuestion: boolean;
	profileCollectInfo: NormalizedScheme<EventCollectInfoBase>;
	profileCollectInfoOptions: SelectFieldOption<number>[];
	selectedQuestion: EventCollectInfo = null;
	browserBackClicked = false;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private store: StoreService,
		private actions$: Actions,
		private breakpointService: BreakpointService,
		private activatedRoute: ActivatedRoute,
		private collectInfoService: CollectInfoService,
		private dialog: MatDialog
	) {
		super();
	}

	ngOnInit(): void {
		this.activatedRoute.parent.paramMap.pipe(take(1)).subscribe((params) => {
			this.eventId = +params.get('id');
		});
		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});

		this.breakpointService.isWeb$.pipe(takeUntil(this.destroyed$)).subscribe((isWeb) => {
			this.isWeb = isWeb;
		});

		this.actions$
			.pipe(
				ofType(EventActionsConstants.GET_REGISTRATION_SETTINGS_SUCCESS,
					EventActionsConstants.GET_REGISTRATION_SETTINGS_FAILED,
					EventActionsConstants.UPDATE_REGISTRATION_SETTINGS_SUCCESS,
					EventActionsConstants.UPDATE_REGISTRATION_SETTINGS_FAILED),
				takeUntil(this.destroyed$)
			)
			.subscribe(() => {
				this.isFormPristine = true;
				this.isPageLoading = false;
			});

		this.actions$
			.pipe(
				ofType(EventActionsConstants.DELETE_COLLECT_INFO_SUCCESS),
				takeUntil(this.destroyed$)
			)
			.subscribe(() => {
				this.store.dispatch(new GetProfileCollectInfo({eventId: this.eventId, isRegistration: true}));
			});

		this.getAllRegistrationInfo();

		this.store.select(eventSelector.profileCollectInfo()).pipe(takeUntil(this.destroyed$)).subscribe(profileCollectInfo => {
			this.profileCollectInfo = profileCollectInfo;
		});

		this.store.select(eventSelector.registrationSettings(this.eventId)).pipe(takeUntil(this.destroyed$))
			.subscribe((registrationSettings) => {
				if (registrationSettings) {
					this.hasSettings = true;
					this.registrationSettings = registrationSettings;
					this.tempSettings = {...this.registrationSettings};
				}
			});

		this.store.select(eventSelectors.eventCollectInfo()).pipe(takeUntil(this.destroyed$)).subscribe(questions => {
			this.questions = questions;
		});

		this.store.select(eventSelectors.profileCollectInfo())
			.pipe(
				withLatestFrom(of(this.questions)),
				map(
					([profile, event]) => (
						profile
							? profile.all
								.filter(
									id => !event.some(e => e.profileQuestionId === id)
								)
								.map(
									id => ({
										value: id as number,
										label: profile.byId[id].question,
									})
								)
							: []
					)
				),
				takeUntil(this.destroyed$)
			)
			.subscribe(result => {
				this.profileCollectInfoOptions = result;
			});

		window.addEventListener('popstate', () => {
			this.browserBackClicked = true;
		}, false);
	}

	getAllRegistrationInfo() {
		this.store.dispatch(new GetEventRegistrationsSettings({id: this.eventId}));
		this.store.dispatch(new GetEventCollectInfo({eventId: this.eventId, isRegistration: true}));
		this.store.dispatch(new GetProfileCollectInfo({eventId: this.eventId, isRegistration: true}));
	}

	handleRoutingBack(mode: string, deleteCrumbCount: number) {
		if (this.collectInfoForm && !this.collectInfoForm.pristine) {
			this.handleUnsavedChanges().subscribe((navigateBack) => {
				if (navigateBack) {
					this.performRoutingBack(mode, deleteCrumbCount);
					this.collectInfoForm.markAsPristine();
				}
			});
		} else {
			this.performRoutingBack(mode, deleteCrumbCount);
		}
	}

	performRoutingBack(mode: string, deleteCrumbCount: number) {
		this.headerContent.breadCrumbs = this.headerContent.breadCrumbs.slice(0, -deleteCrumbCount);
		this.mode = mode;
		this.setHeaderContent();
	}

	setHeaderContent() {
		switch (this.mode) {
			case RegistrationPageMode.Settings:
				this.headerContent.description = `Set up attendee registration requirements before ticket purchase. Configure and manage 
					registration details easily on this page and approve or deny registrations to control your event's attendance.`;
				this.headerContent.title = 'Registrations';
				break;
			case RegistrationPageMode.Questions:
				this.headerContent.description = 'Select what information people must fill in when registering for your event. ' +
					'You can also create your own custom fields.';
				this.headerContent.title = 'Registration Questions';
				break;
			case RegistrationPageMode.AddEdit:
				this.headerContent.description = `${this.isEditingQuestion ? 'Edit' : 'Add'} your question below`;
				this.headerContent.title = this.isEditingQuestion ? 'Edit Question' : 'Add Question';
				break;
		}
	}

	handleBreadCrumbNavigation(routeTo: CallableFunction) {
		if (routeTo(this.eventId) === InternalURLCreator.registrations(this.eventId)) {
			this.handleRoutingBack(RegistrationPageMode.Settings, this.mode === RegistrationPageMode.AddEdit ? 2 : 1);
		} else if (routeTo() === false) {
			this.handleRoutingBack(RegistrationPageMode.Questions, 1);
		} else {
			this.store.dispatch(new Go({ path: [routeTo(this.eventId)] }));
		}
	}

	handleUnsavedChanges(): Observable<boolean> {
		const dialogRef = this.dialog.open(ConfirmationModalComponent, {
			data: {
				title: 'Unsaved Changes',
				text: 'Are you sure you want to leave this page? Any unsaved changes will be discarded.',
				buttonText: 'LEAVE',
				centerText: true,
				isMobile: this.isMobile,
			},
			panelClass: 'g-standard-dialog',
		});

		return dialogRef.afterClosed();
	}

	handleEditRegistrationForm() {
		this.mode = RegistrationPageMode.Questions;
		this.setHeaderContent();
		this.headerContent.breadCrumbs = [
			...this.headerContent.breadCrumbs,
			{
				routeName: 'Questions',
				routeTo: () => false,
			},
		];
	}

	onCollectInfoFormRequest = (question?: EventCollectInfo) => {
		this.isEditingQuestion = !!question;
		this.mode = RegistrationPageMode.AddEdit;
		this.setHeaderContent();
		this.headerContent.breadCrumbs = [
			...this.headerContent.breadCrumbs,
			{
				routeName: this.headerContent.title,
			},
		];

		this.collectInfoForm = this.collectInfoService.createCollectInfoForm(question, true);

		if (this.collectInfoFormChangeSub) {
			this.collectInfoFormChangeSub.unsubscribe();
		}

		this.collectInfoFormChangeSub = this.collectInfoService
			.setFormValueChangeHandler({
				form: this.collectInfoForm,
				profileCollectInfo: this.profileCollectInfo,
				collectInfoTickets: null,
				isRegistration: true,
			});
	};

	onCollectInfoFormClose = (draftQuestion?: DraftEventCollectInfoForm) => {
		if (draftQuestion) {
			this.store.dispatch(
				new UpsertEventCollectInfo({
					eventId: this.eventId,
					collectInfo: draftQuestion,
					isUpdating: !!draftQuestion.id,
				})
			);
			this.collectInfoForm.markAsPristine();
		}

		this.handleRoutingBack(RegistrationPageMode.Questions, 1);
	};

	backToSettingsPage() {
		this.handleRoutingBack(RegistrationPageMode.Settings, 1);
	}

	onEventQuestionPropertyToggle = (data: EventQuestionToggle) => {
		this.store.dispatch(
			new SetCollectInfoProperty({
				id: data.id,
				eventId: this.eventId,
				name: data.name,
				value: data.value,
				prevValue: data.prevValue,
			})
		);
	};

	onEventQuestionDeletion = (id: SchemeID) => {
		this.store.dispatch(
			new DeleteCollectInfo({
				eventId: this.eventId,
				id,
			})
		);
	};

	onReorder(event: CdkDragDrop<string[]>) {
		const prevOrder = this.questions.map(q => q.id);
		moveItemInArray(this.questions, event.previousIndex, event.currentIndex);
		const newOrder = this.questions.map(q => q.id);

		this.store.dispatch(
			new ReorderCollectInfo({
				eventId: this.eventId,
				prevOrder: prevOrder,
				order: newOrder,
				isRegistration: true,
			})
		);
	}

	pristineChange(isPristine: boolean) {
		this.isFormPristine = isPristine;
	}

	handleSaveSettingsChanges(settings: EventRegistrationSettings) {
		this.store.dispatch(new UpdateEventRegistrationsSettings({id: this.eventId, registrationSettings: settings}));
		this.isPageLoading = true;
	}

	isFormDeactivatable(): boolean | Observable<boolean> {
		if (this.isMobile) {
			return this.mode === RegistrationPageMode.Settings;
		}

		return this.isFormPristine
			&& (!this.collectInfoForm || this.collectInfoForm.pristine);
	}

	deactivationFallback(): Observable<boolean> {
		this.handleUnsavedChanges().subscribe((navigateBack) => {
			this.closeDeactivationModal(navigateBack);
			if (navigateBack){
				this.isFormPristine = true;
				if (this.collectInfoForm) {
					this.collectInfoForm.markAsPristine();
				}
				if ((this.isMobile || this.browserBackClicked) && this.mode !== RegistrationPageMode.Settings) {
					this.browserBackClicked = false;
					this.store.dispatch(new Go({ path: [InternalURLCreator.registrations(this.eventId)] }));

					const routeBack = this.mode === RegistrationPageMode.AddEdit
						? RegistrationPageMode.Questions
						: RegistrationPageMode.Settings;

					this.handleRoutingBack(routeBack, 1);
					this.getAllRegistrationInfo();
				}
			}
		});
		return this.modalCallback.asObservable();
	}

	closeDeactivationModal(isConfirmed: boolean): void {
		this.modalCallback.next(isConfirmed);
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}
}
