<div class="l-container" *ngIf='!isLoading; else pageLoader'>
	<ng-container *ngIf="!isMobile; else mobileHeader">
		<app-header class='l-web-header'></app-header>
		<app-event-side-nav class='l-side-nav' [currentNavItem]='currentNavItem'
			[metadata]='metadata'></app-event-side-nav>
		<app-tab-nav-bar *ngIf='showTabs' class='l-web-tabs' [pages]='currentSubNavItem.primaryPages' [conditionalTabs]="currentSubNavItem.conditionalTabs" [isOngoing]="isOngoing"></app-tab-nav-bar>
	</ng-container>
	<ng-template #mobileHeader>
		<app-mobile-event-header class="l-mobile-header" [eventId]='eventId' [currentNavItem]='currentNavItem'
			[showPrimaryHeader]='showPrimaryHeader' [currentPageHeading]='currentPageHeading'
			[currentSubNavItem]='currentSubNavItem'>
		</app-mobile-event-header>
		<app-tab-nav-bar *ngIf='showTabs' class='l-mobile-tabs'
			[pages]='currentSubNavItem.primaryPages' [conditionalTabs]="currentSubNavItem.conditionalTabs" [isOngoing]="isOngoing"></app-tab-nav-bar>
	</ng-template>
	<div [ngClass]="contentClass">
		<router-outlet *ngIf='userAccess.isAccessGranted; else noAccess'></router-outlet>
		<ng-template #noAccess>
			<app-missing-permissions [permissions]='permissions' [currentPageHeading]='currentPageHeading'></app-missing-permissions>
		</ng-template>
	</div>
</div>
<ng-template #pageLoader>
	<div class="l-page-loader">
		<mat-spinner></mat-spinner>
	</div>
</ng-template>
