import { ErrorCodes } from '@app/models/errorCode.model';

export class AppAction<T = undefined> {
	readonly type: string;
	constructor(public payload?: T) {}
}

export class FailedActionPayload<A, EA> {
	msg: string;
	action: A;
	critical ? = false;
	meta?: {
		allowClose: boolean;
		extraAction: EA;
		extraActionTitle: string;
	};
	error?: Error;
	serverMessages?: ErrorCodes[];
	serverCustomErrorMessage?: string;
}

export enum ActionTypeEndings {
	SUCCESS = '_SUCCESS',
	FAILED = '_FAILED',
}

export class FailedAction<A = AppAction<any>, EA = AppAction<any>> extends AppAction<FailedActionPayload<A, EA>> { }
