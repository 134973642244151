<div [ngClass]="{ 'input-group-mobile-large': isMobile, 'input-group-web-large': !isMobile }">
	<label class="g-form-label">
		<span [innerHTML]="label"></span>
		<span *ngIf='isRequired' class="g-orange"> *</span>
	</label>
	<input id="date-field-input" type="text" [formControl]="displayControl" class="form-control" [matDatepicker]="picker" (click)="picker.open()" />
	<mat-datepicker id="date-picker" #picker startView="multi-year">
		<mat-date-range-picker-actions>
			<button
				id="close-datepicker"
				class="g-secondary-button g-datepicker-button"
				matDatepickerCancel
			>CANCEL</button>
			<button
				id="apply-datepicker"
				class="g-primary-button g-datepicker-button"
				matDatepickerApply
			>APPLY</button>
		</mat-date-range-picker-actions>
	</mat-datepicker>
	<div *ngIf="inputFormControl.invalid">
		<div class="g-error-input">
			<div *ngIf="inputFormControl.hasError('dateFieldRequired')">A date is required.</div>
			<div *ngIf="inputFormControl.hasError('minAge')">This event has a minimum age limit of <strong>{{minAge}}</strong></div>
			<div *ngIf="inputFormControl.hasError('maxAge')">This event has a maximum age limit of <strong>{{maxAge}}</strong></div>
		</div>
	</div>
</div>
