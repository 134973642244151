<div
	class="l-container"
>
	<form
		*ngIf="form"
		[formGroup]="form"
	>
		<app-mat-text-control-field
			[control]="fromForm('name')"
			[label]="'Name'"
			[customId]="'name'"
			[placeholder]="'e.g. Accommodation'"
			[required]="true"
			[outerClass]="''"
			#ticketGroupName
		>
		</app-mat-text-control-field>
		<div
			class="w-100 pt-3"
			[ngClass]="{'l-description-and-image-web': !isMobile, 'l-description-and-image-mobile': isMobile }"
		>
			<div [ngClass]="{'l-description-container-web': !isMobile, 'l-description-container-mobile': isMobile }">
				<app-mat-text-area-control-field
					[control]="fromForm('description')"
					[label]="'Group description'"
					[customId]="'group-description'"
					[placeholder]="'e.g. Choose general or deluxe camping tickets'"
					[inputClass]="'w-100 h-100 l-unset-max-height form-control'"
					class="w-100"
					[outerClass]="'l-form-item h-100'"
				>
				</app-mat-text-area-control-field>
			</div>
			<div
				[ngClass]="{'l-image-upload-container w-100 h-100' : !isMobile , 'l-image-upload-container-mobile w-100 h-100': isMobile}">
				<app-image-upload
					[eventId]="eventId"
					[label]="'Ticket Group Image'"
					[maxFileSize]="imageService.maxImageSize"
					[imageServiceType]="ImageServiceTypeEnum.TICKET"
					[uploadText]="'This will appear on your ticket Group'"
					[imagePath]="getTicketGroupImage()"
					[handleTicketGroups]="true"
					[isRound]="true"
					[maxHeight]="'270px'"
					(sendImageString)="ticketGroupsService.handleImageStringUpload($event, form)"
					(uploadHandler)="ticketGroupsService.handleImageUpload($event, form)"
					(removeHandler)="ticketGroupsService.onImageRemove(form)"
					(maxFileSizeAchieved)="imageService.onImageMaxSizeAchived()"
					class="l-listing-image"
					id="image Upload"
				>
				</app-image-upload>
			</div>
		</div>
		<div class="pt-5">
			<div *ngIf="fromForm('imageString').value || fromForm('thumbnail').value">
				<app-mat-slide-control-field
					formControlName="isImageShown"
					label="Show image in ticket selector"
					[isMobile]="isMobile"
					customId="showImageInTicketSelector"
				>
				</app-mat-slide-control-field>
			</div>
			<app-mat-slide-control-field
				formControlName="isExpanded"
				label="Expanded by default"
				tooltip="If ON, the tickets in this group will be displayed by default. If OFF, these tickets will only appear when the user expands the group on your event page."
				[isMobile]="isMobile"
				customId="expandedByDefault"
			>
			</app-mat-slide-control-field>
		</div>
		<div
			class="pb-5"
			*ngIf="linkedTicketControls.length"
		>
			<label class="g-form-label pt-3">Ticket Types<span class="g-orange"> *</span></label>
			<div [ngClass]="{'l-question-list-web' : !isMobile}">
				<div *ngFor="let ticket of linkedTicketControls; let i = index">
					<mat-checkbox
						(change)="updateSelectedTickets(ticket.value.id, $event.checked)"
						[checked]="ticket.value.checked"
						[formControl]="ticket.get('checked')"
						[id]="'select-ticket-' + ticket.value.id"
					>
						{{ticket.value.name}}
					</mat-checkbox>
				</div>
			</div>
			<div
				class="g-error-input"
				*ngIf="hasFormErrors('linkedTicketTypes')"
			>
				{{getError(fromForm('linkedTicketTypes').errors)}}
			</div>
		</div>
	</form>
</div>
<div [ngClass]="{'l-sticky-bar-mobile': isMobile, 'l-sticky-bar-web': !isMobile}">
	<app-footer-button
		class="w-100 mt-auto"
		[primaryText]="isEditing ? 'UPDATE' : 'ADD'"
		[secondaryText]="'CANCEL'"
		[isDisabled]="!isSubmitAllowed(form)"
		[isMobile]="isMobile"
		[isLoading]="isSavingChanges"
		($primaryButton)="handleSavingTicketGroup(form.getRawValue())"
		($secondaryButton)="onCancel()"
		primaryButtonId="add-or-update-question"
		secondaryButtonId="cancel"
	>
	</app-footer-button>
</div>