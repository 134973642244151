import { Component, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SectionRouteCard } from '@app/models/shared';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { StoreService } from '@app/services/store/store.service';
import { EVENT_ID_PARAM_KEY, MANAGE_EVENT_CARDS } from '@app/utils/consts';
import { Dictionary } from '@ngrx/entity';
import { combineLatest, take, takeUntil } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { DeleteEvent } from '@app/store/actions/event/event.actions';
import { Actions, ofType } from '@ngrx/effects';
import { EventActionsConstants } from '@app/store/actions/event/event.actions.constants';
import { ProductStatus } from '@app/models/event.model';
import * as eventSelectors from '@app/store/selectors/event.selector';
import * as userSelectors from '@app/store/selectors/user.selector';
import { InternalURLCreator } from '@app/services/url/url.dictionary';
import { Go } from '@app/store/actions/router/router.actions';
import { handleOrder } from '@app/utils/common-helpers';
import { ConfirmationModalComponent } from '@app/shared/modals/confirmation-modal/confirmation-modal.component';

@Component({
	selector: 'app-manage-event',
	templateUrl: './manage-event.component.html',
	styleUrls: ['./manage-event.component.sass'],
})
export class ManageEventComponent {
	handleDefaultOrder = handleOrder;
	cardsData: Dictionary<SectionRouteCard>;
	deleteDraftEventCard = {
		title: 'Delete Event',
		iconPath: './assets/icons/trash-solid-green',
		description: 'Delete your draft event.',
	};
	isMobile = false;
	eventId: number;
	destroyed$: EventEmitter<void> = new EventEmitter<void>();
	isLoading = true;
	productStatus: ProductStatus;
	isAdmin: boolean;
	isOwner: boolean;

	constructor(
		private breakpointService: BreakpointService,
		private activatedRoute: ActivatedRoute,
		private store: StoreService,
		private dialog: MatDialog,
		private actions$: Actions
	) { }

	ngOnInit(): void {
		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});

		this.activatedRoute.parent.paramMap.pipe(take(1)).subscribe((params) => {
			this.eventId = +params.get(EVENT_ID_PARAM_KEY);
		});

		combineLatest([
			this.store.select(eventSelectors.eventMetadata()),
			this.store.select(userSelectors.isAdmin()),
		]).pipe(takeUntil(this.destroyed$)).subscribe(([metaData, isAdmin]) => {
			if (metaData) {
				this.productStatus = metaData.productStatus;
				this.isOwner = metaData.isOwner;
				if (metaData.productStatus === ProductStatus.Draft) {
					delete MANAGE_EVENT_CARDS['postponeEvent'];
					delete MANAGE_EVENT_CARDS['cancelEvent'];
				}
			}
			this.isAdmin = isAdmin;
			this.cardsData = MANAGE_EVENT_CARDS;
			this.isLoading = false;
		});

		this.actions$.pipe(
			ofType(EventActionsConstants.DELETE_EVENT_SUCCESS, EventActionsConstants.DELETE_EVENT_FAILED),
			takeUntil(this.destroyed$))
			.subscribe(({ type }: { type: string }) => {
				this.isLoading = false;
				if (type === EventActionsConstants.DELETE_EVENT_SUCCESS) {
					this.store.dispatch(new Go({ path: [InternalURLCreator.orgDashboard()] }));
				}
			});
	}

	handleDeleteDraft() {
		this.dialog.open(ConfirmationModalComponent, {
			data: {
				title: 'Are you sure you want to continue?',
				text: `You are about to permanently delete this draft event.
					Only events in draft status can be deleted; once published, they cannot be removed.`,
				buttonText: 'DELETE',
				centerText: true,
				isMobile: this.isMobile,
				cancelText: 'CANCEL',
				validationPattern: 'DELETE',
			},
			panelClass: 'g-standard-dialog',
		}).afterClosed()
			.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
				if (value) {
					this.store.dispatch(new DeleteEvent({ eventId: this.eventId }));
					this.isLoading = true;
				}
			});
	}

	checkCanUserDeleteEvent() {
		return this.productStatus === ProductStatus.Draft && (this.isOwner || this.isAdmin);
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
