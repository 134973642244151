<app-my-account-header
	[isMobile]='isMobile'
	pageName="Promoter Details"
	(navigateBack)="handleNavigationBack()"
>
</app-my-account-header>
<div class="g-account-container">
	<div
		class="g-account-content"
		[ngClass]="{ 'mobile': isMobile, 'web': !isMobile }"
	>
		<app-web-content-header
			class="w-100"
			[headerContent]="headerContent"
			[isMobile]="isMobile"
			[mediumWidth]='true'
			[customDescription]="customDescription"
			[readMoreText]='readMoreText'
			includeReadMore='true'
			(readMore)='handleShowMore()'
		></app-web-content-header>
		<div
			class="l-status-message"
			[ngClass]="{ 'show': showMore }"
			[@expandCollapse]="showMore ? 'expanded' : 'collapsed'"
		>
			<p>Below is your unique link that you can send to people who may be interested in buying tickets to this
				event.</p>
			<ol>
				<li *ngFor='let info of LINK_COMMISSION_INFO'>
					{{info}}
				</li>
				<li>For more information, take a look at Quicket's <span
						(click)="handleTermsNavigation()"
						class="g-pointer fw-bold text-decoration-underline g-green"
					>terms and
						conditions.</span>
				</li>
			</ol>
		</div>
		<div
			class="d-flex flex-column pb-3"
			*ngIf="!isPageLoading; else pageLoader"
		>
			<div
				*ngIf="!isLoading; else promoterCodeLoading"
				class="l-link-container mt-2"
			>
				<app-link-card
					[cardText]="'Customize your promoter link below:'"
					[primaryButtonText]="'COPY LINK'"
					[isMobile]='isMobile'
					[preText]="promoterDetails?.shortlinkUrl"
					[link]="promoterDetails?.shortlinkCode"
					[icon]="'link'"
					[canEdit]="true"
					[isLoading]="isUpdating"
					(primaryButtonClick)="handleCopyLink(promoterDetails?.shortlinkUrl, $event)"
					(secondaryButtonClick)="handleSaveLink($event)"
				>
				</app-link-card>
				<div class="l-disclaimer">
					By using the Quicket's promoter network you agree to the Quicket <span
						(click)="handleTermsNavigation()"
						class="g-pointer fw-bold text-decoration-underline g-highlight"
					>terms and
						conditions.</span>
				</div>
			</div>
			<ng-template #promoterCodeLoading>
				<div class='l-loading'>
					<mat-spinner></mat-spinner>
				</div>
			</ng-template>
			<div
				class='l-payment-details'
				[ngClass]="{ 'mt-3': isMobile, 'mt-5': !isMobile }"
			>
				<h3>Payment Details</h3>
				<p>
					Payment will happen within three working days of the event finishing successfully.
				</p>
			</div>
			<app-link-bank-banner
				*ngIf='!linkedAccount'
				[isMobile]='isMobile'
				(handleClick)='handleChooseBankAccount()'
			></app-link-bank-banner>
			<div
				class="l-table-container"
				*ngIf="promoterPaymentDetails.data?.length; else showEmptyPaymentDetails"
			>
				<table
					mat-table
					[dataSource]="promoterPaymentDetails"
					[ngClass]="{ 'web': !isMobile, 'mobile': isMobile}"
					class="l-table-elements"
				>
					<ng-container matColumnDef="linkClicks">
						<th
							mat-header-cell
							*matHeaderCellDef
							class="first-child"
						> Link Clicks </th>
						<td
							mat-cell
							*matCellDef="let payment"
						> {{payment.linksClicked}} </td>
					</ng-container>
					<ng-container matColumnDef="ticketsSold">
						<th
							mat-header-cell
							*matHeaderCellDef
						> Tickets Sold </th>
						<td
							mat-cell
							*matCellDef="let payment"
						> {{payment.ticketsSold}} </td>
					</ng-container>
					<ng-container matColumnDef="sales">
						<th
							mat-header-cell
							*matHeaderCellDef
						> Sales </th>
						<td
							mat-cell
							*matCellDef="let payment"
						> {{payment.sales}}
						</td>
					</ng-container>
					<ng-container matColumnDef="yourShare">
						<th
							mat-header-cell
							*matHeaderCellDef
						> Your Share </th>
						<td
							mat-cell
							*matCellDef="let payment"
						> {{payment.yourShare}}
						</td>
					</ng-container>
					<ng-container matColumnDef="account">
						<th
							mat-header-cell
							*matHeaderCellDef
							class="last-child"
						> Account </th>
						<td
							mat-cell
							*matCellDef="let payment"
							class="l-account"
						> {{payment.account ?
							payment.account : 'N/A'}}
						</td>
					</ng-container>
					<tr
						mat-header-row
						*matHeaderRowDef="promoterPaymentColumns"
					></tr>
					<tr
						mat-row
						*matRowDef="let row; columns: promoterPaymentColumns;"
					></tr>
				</table>
			</div>
			<ng-template #showEmptyPaymentDetails>
				<div
					class="l-empty-state"
					[class.mx-3]='isMobile'
				>
					<h3 class="mb-3">No Payments</h3>
					<div class="mb-3">Share your Promoter Link to earn your commission!</div>
				</div>
			</ng-template>
			<div
				*ngIf='isMobile'
			>
				<button
					class='g-primary-button w-100'
					(click)='handleChooseBankAccount()'
				>EDIT BANK ACCOUNT</button>
			</div>

		</div>
		<ng-template #pageLoader>
			<div class="l-page-loader">
				<mat-spinner></mat-spinner>
			</div>
		</ng-template>
	</div>
</div>
<app-bank-info-bar
	*ngIf='!isPageLoading'
	class='g-fixed-footer'
	[isMobile]='isMobile'
	[infoTitleOne]="'Total Tickets Sold'"
	[infoValueOne]='totalTickets'
	[infoTitleTwo]='"Linked Account"'
	[infoValueTwo]='linkedAccount'
	[buttonDisabled]='isBanksDataFetching'
	(handleClick)='handleChooseBankAccount()'
></app-bank-info-bar>