<div class="l-container" *ngIf="!isPageLoading; else pageLoading">
	<div class="l-content l-header-content" [ngClass]="{ mobile: isMobile }">
		<app-web-content-header
			*ngIf="!isMobile; else mobileHeaderContent"
			class="l-header"
			[headerContent]="headerContent"
			[mediumWidth]="true"
		></app-web-content-header>
		<ng-template #mobileHeaderContent>
			<div class="l-mobile-description">
				<p *ngIf="!readMore" class="l-header">
					{{ customDescription }}
					<span
						*ngIf="includeReadMore"
						id="web-header-read-more"
						(click)="handleReadMore()"
						class="g-pointer g-highlight g-green"
					>
						{{ readMoreText }}
					</span>
				</p>
				<p *ngIf="readMore" class="l-header">
					{{ headerContent.description }}
					<span
						*ngIf="includeReadMore"
						id="web-header-read-more"
						(click)="handleReadMore()"
						class="g-pointer g-highlight g-green"
					>
						{{ readMoreText }}
					</span>
				</p>
			</div>
		</ng-template>
	</div>
	<form *ngIf="form" [formGroup]="form">
		<div [ngClass]="{ 'l-form-mobile': isMobile, 'l-form-web': !isMobile }">
			<h3 class="mb-3 l-header">Basic Information</h3>
			<div class="l-form-field-container">
				<app-mat-text-control-field
					[control]="fromForm('name')"
					[label]="'Event Name'"
					[customId]="'event-name-input'"
					[required]="true"
				></app-mat-text-control-field>
				<div class="g-error-input" *ngIf="fromForm('name').errors?.required">Required</div>
			</div>

			<div class="l-form-field-container" *ngIf="fromForm('hasSchedule').value">
				<div class="l-warning">
					<mat-icon class="g-orange l-warning-icon">warning</mat-icon>
					<span class="l-warning-text"
						>This event is repeating. Please note that the schedules will not be copied over and you will have to create them
						again.</span
					>
				</div>
			</div>

			<div class="l-form-field-container">
				<app-schedule-creator
					[singleEvent]="true"
					[startDate]="fromForm('startDate')"
					[endDate]="fromForm('endDate')"
					[isMobile]="isMobile"
					[required]="true"
					[isEventCreation]="true"
					(markFormDirty)="markFormDirty()"
				>
				</app-schedule-creator>
			</div>

			<div class="l-header-and-checkbox">
				<h3 class="mb-3 l-header">Other Information To Copy</h3>
				<mat-checkbox class="l-select-all" [control]="selectAll" id="input-select-all" (click)="handleSelectAll()"> Select All </mat-checkbox>
			</div>
			<div class="l-checkboxes-container">
				<div class="d-flex flex-column align-items-start l-separate-checkbox-container">
					<label class="g-form-label"> Tickets </label>
					<div *ngFor="let tId of getFormGroupKeys(form, 'tickets')">
						<mat-checkbox
							[formControl]="form.get('tickets.' + tId + '.checked')"
							[id]="'input-ticket-' + form.get('tickets.' + tId + '.id').value"
						>
							{{ form.get('tickets.' + tId + '.name').value }}
						</mat-checkbox>
					</div>
				</div>

				<div class="d-flex flex-column align-items-start l-separate-checkbox-container">
					<label class="g-form-label"> Custom Questions </label>
					<div *ngFor="let tId of getFormGroupKeys(form, 'questions')">
						<mat-checkbox
							[formControl]="form.get('questions.' + tId + '.checked')"
							[id]="'input-question-' + form.get('questions.' + tId + '.id').value"
						>
							{{ form.get('questions.' + tId + '.name').value }}
						</mat-checkbox>
					</div>
				</div>
			</div>

			<label class="g-form-label"> Additional Options </label>
			<div class="l-checkboxes-container">
				<div class="d-flex flex-column align-items-start l-combined-checkbox-container">
					<mat-checkbox [formControl]="fromForm('copyMerchendise')" id="input-copy-merchandise"
						> Copy Merchandise </mat-checkbox
					>
					<mat-checkbox [formControl]="fromForm('copyTabbedContent')" id="input-copy-tabbed-content"
						> Copy Tabbed Content </mat-checkbox
					>
					<mat-checkbox [formControl]="fromForm('copyTicketGroups')" id="input-copy-ticket-groups"
						> Copy Ticket Groups </mat-checkbox
					>
				</div>
				<div class="d-flex flex-column align-items-start l-combined-checkbox-container">
					<mat-checkbox [formControl]="fromForm('copyCustomCss')" id="input-copy-customisation"
						> Copy Customisation </mat-checkbox
					>
					<mat-checkbox [formControl]="fromForm('copyImage')" id="input-copy-image"> Copy Event Listing Image </mat-checkbox>
					<mat-checkbox [formControl]="fromForm('copyTheme')" id="input-copy-theme"> Copy Theme </mat-checkbox>
				</div>
			</div>
		</div>
	</form>
	<div class="l-sticky-bar">
		<app-footer-button
			[isMobile]="isMobile"
			[primaryText]="'COPY EVENT'"
			[secondaryText]="'BACK'"
			[isDisabled]="!form || form.invalid || isPageUpdating || form.pristine"
			[isLoading]="isPageUpdating"
			($primaryButton)="handleSave()"
			($secondaryButton)="handleBack()"
		>
		</app-footer-button>
	</div>
</div>
<ng-template #pageLoading>
	<div class="l-page-loader">
		<mat-spinner></mat-spinner>
	</div>
</ng-template>
