import { NgModule } from '@angular/core';
import { DashboardReportingComponent } from './dashboar-reporting.component';
import { ReportingDateSelectionComponent } from './reporting-date-selection/reporting-date-selection.component';
import { ReportingTypeSelectionComponent } from './reporting-type-selection/reporting-type-selection.component';
import { SharedModule } from '@app/shared/shared.module';
import { ReportingScheduleSelectionComponent } from './reporting-schedule-selection/reporting-schedule-selection.component';

@NgModule({
	declarations: [
		DashboardReportingComponent,
		ReportingDateSelectionComponent,
		ReportingTypeSelectionComponent,
		ReportingScheduleSelectionComponent,
	],
	imports: [
		SharedModule,
	],
	exports: [
		DashboardReportingComponent,
	],
}) export class DashboardReportingModule {}
