import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-apply-all-form',
	templateUrl: './apply-all-form.component.html',
	styleUrls: ['./apply-all-form.component.sass'],
})
export class ApplyAllFormComponent {
	@Input() message: string;
	@Input() isDisabled = false;
	@Input() icon = 'content_copy';
	@Input() successIcon = 'check_circle';

	@Output() selected: EventEmitter<void> = new EventEmitter();

	currentIcon = this.icon;

	handleEmit() {
		if (!this.isDisabled) {
			this.currentIcon = this.successIcon;
			this.selected.emit();
			setTimeout(() => {
				this.currentIcon = this.icon;
			}, 2000);
		}
	}
}
