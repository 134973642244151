import { Component, EventEmitter, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HeaderContent } from '@app/models/shared';
import {
	AccountDetails,
	Bank,
	BankAccount,
	BankAccountReturn,
	CurrentBankAccount,
	DraftBankAccount,
	GroupedBank,
	UserContactInfo
} from '@app/models/user.model';
import { StoreService } from '@app/services/store/store.service';
import { AddBankAccount, DeleteBankAccount, GetAllBanks, GetBankAccounts, UpdateBankAccount } from '@app/store/actions/user/user.actions';
import { MY_ACCOUNT_HEADER, REDIRECT_PARAM_KEY } from '@app/utils/consts';
import { take, takeUntil } from 'rxjs';
import { banks as banksSelector } from '@app/store/selectors/event.selector';
import { userContactInfo as userContactInfo } from '@app/store/selectors/user.selector';
import { Actions, ofType } from '@ngrx/effects';
import { UserActionsConstants } from '@app/store/actions/user/user.actions.constants';
import { ConfirmationModalComponent } from '@app/shared/modals/confirmation-modal/confirmation-modal.component';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { AddBankModalComponent } from '../../modals/add-bank-modal/add-bank-modal.component';
import { InternalURLCreator, URLCreator } from '@app/services/url/url.dictionary';
import { Router } from '@angular/router';
import { userBankAccounts as bankAccountsSelector } from '@app/store/selectors/userBankAccounts.selector';
import { Go } from '@app/store/actions/router/router.actions';
@Component({
	selector: 'app-payouts',
	templateUrl: './payouts.component.html',
	styleUrls: ['./payouts.component.sass'],
})
export class PayoutsComponent implements OnInit {
	headerContent: HeaderContent = MY_ACCOUNT_HEADER['payouts'];

	banks: Bank[];
	groupedBanks: GroupedBank[];
	bankAccounts: BankAccount[];
	user: UserContactInfo;

	isBanksLoading = true;
	isLoading = true;
	isPageLoading = true;
	isMobile = false;
	isWeb = false;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private breakpointService: BreakpointService,
		private store: StoreService,
		private dialog: MatDialog,
		private actions$: Actions,
		private router: Router
	) { }

	ngOnInit(): void {
		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});

		this.breakpointService.isWeb$.pipe(takeUntil(this.destroyed$)).subscribe((isWeb) => {
			this.isWeb = isWeb;
		});

		this.store.select(bankAccountsSelector()).pipe(takeUntil(this.destroyed$)).subscribe((accounts) => {
			if (accounts) {
				this.bankAccounts = accounts;
			}
		});

		this.store.select(banksSelector()).pipe(takeUntil(this.destroyed$)).subscribe((banks) => {
			if (banks.length) {
				this.banks = banks;

				this.groupedBanks = banks.reduce((acc: GroupedBank[], bank: Bank) => {
					const existingGroup = acc.find((group: GroupedBank) => group.iso === bank.currency.iso);
					if (existingGroup) {
						existingGroup.banks.push(bank);
					} else {
						acc.push({
							iso: bank.currency.iso,
							banks: [bank],
						});
					}
					return acc;
				}, [] as GroupedBank[]);
				this.isBanksLoading = false;

			} else {
				this.store.dispatch(new GetAllBanks());
			}
		});

		this.store.select(userContactInfo()).pipe(take(1)).subscribe((user) => {
			this.user = user;
		});

		this.actions$
			.pipe(
				ofType(UserActionsConstants.GET_BANK_ACCOUNTS_SUCCESS, UserActionsConstants.GET_BANK_ACCOUNTS_FAILED),
				takeUntil(this.destroyed$)
			)
			.subscribe(({ type, payload: { bankAccounts, needsPasswordVerification, passwordVerificationRedirect } }:
			{
				type: string; payload: BankAccountReturn;
			}) => {
				this.isLoading = false;
				if (type === UserActionsConstants.GET_BANK_ACCOUNTS_SUCCESS) {
					if (needsPasswordVerification) {
						const currentUrl = '/app/%23' + this.router.url;
						window.location.href = `${passwordVerificationRedirect}&${REDIRECT_PARAM_KEY}=${currentUrl}`;
					} else {
						this.bankAccounts = bankAccounts;
						this.isPageLoading = false;
					}
				}
			});

		this.store.dispatch(new GetBankAccounts());

		this.actions$
			.pipe(
				ofType(
					UserActionsConstants.UPDATE_BANK_ACCOUNT_SUCCESS,
					UserActionsConstants.UPDATE_BANK_ACCOUNT_FAILED,
					UserActionsConstants.ADD_BANK_ACCOUNT_SUCCESS,
					UserActionsConstants.ADD_BANK_ACCOUNT_FAILED,
					UserActionsConstants.DELETE_BANK_ACCOUNT_SUCCESS,
					UserActionsConstants.DELETE_BANK_ACCOUNT_FAILED),
				takeUntil(this.destroyed$)
			)
			.subscribe(() => {
				this.isBanksLoading = false;
			});
	}

	handleAccount(isNew: boolean, account?: BankAccount): void {
		if (!this.isBanksLoading) {
			const dialogRef = this.dialog.open(AddBankModalComponent, {
				data: {
					banks: this.banks,
					isNew: isNew,
					account: account,
					groupedBanks: this.groupedBanks,
				},
				panelClass: this.isMobile ? 'g-full-page-dialog' : 'g-standard-dialog',
			});


			dialogRef.afterClosed().subscribe((bankDetails: AccountDetails) => {
				if (bankDetails) {
					this.isBanksLoading = true;
					const { bank, accountHolder, accountNumber, isDefault, branch } = bankDetails;
					const commonParams = {
						holder: accountHolder,
						mobileNumber: this.user.phone,
						accountNumber,
						isDefault,
						branchCode: branch ? branch.branchCode : bank.universalCode,
						branchId: branch ? branch.id : 0,
						branchName: branch ? branch.branchName : '',
					};

					if (!isNew) {
						const updateBankAccount: CurrentBankAccount = {
							BankAccountId: account?.id as number,
							...commonParams,
						};
						this.store.dispatch(new UpdateBankAccount({ draftAccount: updateBankAccount }));
					} else {
						const newBankAccount: DraftBankAccount = {
							bankId: bank.id,
							...commonParams,
							branch,
							bankName: bank.name,
						};
						this.store.dispatch(new AddBankAccount({ draftAccount: newBankAccount }));
					}
				}
			});
		}

	}

	handleDelete(account: BankAccount): void {
		const dialogRef = this.dialog.open(ConfirmationModalComponent, {
			data: {
				title: 'Are You Sure?',
				text: 'Your bank account information will be removed.',
				buttonText: 'DELETE',
				isMobile: this.isMobile,
			},
			panelClass: 'g-standard-dialog',
		});

		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.isBanksLoading = true;
				this.store.dispatch(new DeleteBankAccount({ accountId: account.id as number }));
			}
		});
	}

	handleCommissionNavigation(){
		window.open(URLCreator.earnCommission());
	}

	handleNavigationBack() {
		this.store.dispatch(new Go({path: [InternalURLCreator.myAccount()]}));
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
