import configDev from './config.development';
import configProd from './config.production';
import configStaging from './config.staging';
import { environment } from 'environments/environment';

export default environment.production
	? configProd
	: environment.staging
		? configStaging
		: configDev;
