import { EventActionsConstants } from '@app/store/actions/event/event.actions.constants';
import { NotificationDesc, NotificationDelay } from '@app/models/notification.model';
import { OrganiserProfileActionConstants } from '@app/store/actions/organiserProfiles/organiserProfiles.constants';
import { TicketsActionsConstant } from '@app/store/actions/tickets/tickets.constants';
import { SYSTEM_EXECUTION_FAILED } from '@app/store/actions/system/system.actions';
import { FileActionsConstants } from '@app/store/actions/file/file.actions.constants';
import * as eventActions from '@app/store/actions/event/event.actions';
import { ErrorCodes } from '@app/models/errorCode.model';
import { ProductStatus } from '@app/models/event.model';
import { UserActionsConstants } from '@app/store/actions/user/user.actions.constants';
import * as userActions from '@app/store/actions/user/user.actions';
import * as fileActions from '@app/store/actions/file/file.actions';
import { CollectionsActionConstants } from '@app/features/control-panel/collections/event-collections.actions';
import { UpdateEventCollectionsFailed } from '@app/features/control-panel/collections/event-collections.actions';
import { GetEventSuccess, CreateEventSuccess,
	UpsertTabContentFailed, UpsertTabContentSuccess, UpsertEventCollectInfoFailed } from '@app/store/actions/event/event.actions';
import { ProductType } from '@app/models/product.model';
import { ManageBookingConstants } from '@app/user-features/manage-booking/manage-booking.actions';
import { OrganiserActionsConstants } from '@app/store/actions/organiser/organiser.actions.constants';
import { AddMailAccountFailed } from '@app/store/actions/organiser/organiser.actions';

export const matchErrorCodes = (codes: ErrorCodes[]): string[] => (
	(codes || [])
		.map(c => ErrorCodes[c])
		.filter(c => !!c)
);

export const getEventSuccessNotification = (productType: ProductType): string => {
	if (productType === ProductType.Event) {
		return 'We\'ve picked up where you left off. If you need to start a new event, first finish setting up this one.';
	} else if (productType === ProductType.Fundraiser) {
		return 'We\'ve picked up where you left off. If you need to start a new fundraiser, first finish setting up this one.';
	} else if (productType === ProductType.OrganiserSubscriptions) {
		return 'We\'ve picked up where you left off. If you need to start a new subscriptions page, first finish setting up this one.';
	}
};

export const createEventSuccessTitle = (productType: ProductType): string => {
	if (productType === ProductType.Event) {
		return 'Event successfully created';
	} else if (productType === ProductType.Fundraiser) {
		return 'Fundraiser successfully created';
	} else if (productType === ProductType.OrganiserSubscriptions) {
		return 'Subscriptions successfully created';
	}
};

export const createEventSuccessNotification = (productType: ProductType): string => {
	if (productType === ProductType.Event) {
		return 'You should finish all 3 steps to publish your event.';
	} else if (productType === ProductType.Fundraiser) {
		return 'You should finish all 3 steps to publish your fundraiser.';
	} else if (productType === ProductType.OrganiserSubscriptions) {
		return 'You should finish both steps to publish your subscriptions page.';
	}
};

export interface NotificationSource {
	[actionType: string]: (otions?: any) => NotificationDesc;
}

export abstract class NotificationDictionary {
	abstract source: NotificationSource;
}

export const preferableActions: NotificationSource = {
	[OrganiserProfileActionConstants.CREATE_PROFILE_SUCCESS]: () => ({
		title: 'Profile created successfully',
	}),
	[EventActionsConstants.GET_EVENT_SUCCESS]: (action: GetEventSuccess) => ({
		title: 'Editing resumed',
		desc: `${getEventSuccessNotification(action.payload.productType)}`,
	}),
	[EventActionsConstants.CREATE_EVENT_SUCCESS]: (action: CreateEventSuccess) => ({
		title: `${createEventSuccessTitle(action.payload.productType)}`,
		desc: `${createEventSuccessNotification(action.payload.productType)}`,
	}),
	[TicketsActionsConstant.GET_SEATING_CATEGORIES_SUCCESS]: () => ({
		title: 'Ticket categories successfully assigned',
		desc: 'You can manage them in ticket settings',
	}),
	[TicketsActionsConstant.GET_SEATING_CATEGORIES_FAILED]: () => ({
		title: 'Oops! We can\'t get ticket categories for now',
	}),
	[EventActionsConstants.UPDATE_EVENT_SUCCESS]: () => ({
		title: 'Successfully updated',
	}),
	[EventActionsConstants.UPDATE_EVENT_FAILED]: () => ({
		title: 'Oops! We can\'t update these details',
	}),
	[FileActionsConstants.REMOVE_EVENT_IMAGE_FAILED]: () => ({
		title: 'Oops! We can\'t remove event image for now',
	}),
	[FileActionsConstants.REMOVE_EVENT_IMAGE_SUCCESS]: () => ({
		title: 'Image successfully removed',
	}),
	[FileActionsConstants.UPLOAD_EVENT_IMAGE_SUCCESS]: () => ({
		title: 'Image successfully uploaded',
	}),
	[FileActionsConstants.UPLOAD_EVENT_IMAGE_FAILED]: () => ({
		title: 'Oops! We can\'t upload image for now',
	}),
	[FileActionsConstants.UPLOAD_TICKET_IMAGE_SUCCESS]: () => ({
		title: 'Image successfully uploaded',
	}),
	[FileActionsConstants.UPLOAD_TICKET_IMAGE_FAILED]: () => ({
		title: 'Oops! We can\'t upload image for now',
	}),
	[TicketsActionsConstant.UPDATE_TICKET_SETTINGS_SUCCESS]: () => ({
		title: 'Ticket settings successfully updated',
	}),
	[TicketsActionsConstant.UPDATE_TICKET_SETTINGS_FAILED]: () => ({
		title: 'Oops! We can\'t update ticket settings for now',
	}),
	[FileActionsConstants.REMOVE_TICKET_IMAGE_FAILED]: () => ({
		title: 'Oops! We can\'t remove event image for now',
	}),
	[FileActionsConstants.REMOVE_TICKET_IMAGE_SUCCESS]: () => ({
		title: 'Image successfully removed',
	}),
	[EventActionsConstants.GET_EVENT_FAILED]: () => ({
		title: 'Oops! We can\'t get your page for now',
	}),
	[EventActionsConstants.CREATE_EVENT_FAILED]: () => ({
		title: 'Oops! We can\'t create this for now',
	}),
	[EventActionsConstants.GET_VENUES_FAILED]: () => ({
		title: 'Oops! We can\'t get your previous venues',
	}),
	[SYSTEM_EXECUTION_FAILED]: () => ({
		title: 'System error has been encountered. Please contact supporting team.',
	}),
	[TicketsActionsConstant.MARK_TICKETS_AS_SOLD_OUT_SUCCESS]: () => ({
		title: 'Event successfully marked as sold out',
	}),
	[TicketsActionsConstant.MARK_TICKETS_AS_SOLD_OUT_FAILED]: () => ({
		title: 'Oops! We can\'t mark event as sold out',
	}),
	[TicketsActionsConstant.STOP_TICKET_SALES_SUCCESS]: () => ({
		title: 'All tickets sales ended',
	}),
	[TicketsActionsConstant.STOP_TICKET_SALES_FAILED]: () => ({
		title: 'Oops! We can\'t stop ticket sales',
	}),
	[OrganiserProfileActionConstants.SAVE_EVENT_ORGANISER_SUCCESS]: () => ({
		title: 'Successfully updated',
	}),
	[EventActionsConstants.SET_EVENT_LISTING_SUCCESS]: (action: eventActions.SetEventListingSuccess) => ({
		title: `Page listing has been successfully changed to ${action.payload.isPublic ? 'public' : 'private'}`,
	}),
	[EventActionsConstants.SET_EVENT_LISTING_FAILED]: () => ({
		title: 'Oops! We can\'t change the page listing',
	}),
	[EventActionsConstants.SCHEDULE_EVENT_PUBLIC_SUCCESS]: () => ({
		title: 'Schedule to go public successfully set',
	}),
	[EventActionsConstants.SCHEDULE_EVENT_PUBLIC_FAILED]: () => ({
		title: 'Oops! We can\'t set the schedule to go public',
	}),
	[EventActionsConstants.DELETE_SCHEDULE_EVENT_PUBLIC_SUCCESS]: () => ({
		title: 'Schedule to go public successfully deleted',
	}),
	[EventActionsConstants.DELETE_SCHEDULE_EVENT_PUBLIC_FAILED]: () => ({
		title: 'Oops! We can\'t delete the schedule to go public',
	}),
	[EventActionsConstants.SET_EVENT_SERVICE_FEE_SUCCESS]: (action: eventActions.SetEventServiceFeeSuccess) => ({
		title: `Ticket service fees are now payable by ${action.payload.isServiceFeeForHost ? 'you' : 'the ticket buyers'}`,
	}),
	[EventActionsConstants.SET_EVENT_SERVICE_FEE_FAILED]: () => ({
		title: 'Oops! We can\'t change event service for host',
	}),
	[EventActionsConstants.SET_EVENT_SHORT_LINK_SUCCESS]: () => ({
		title: 'Short link successfully updated',
	}),
	[EventActionsConstants.SET_EVENT_SHORT_LINK_FAILED]: (action: eventActions.SetEventShortLinkFailed) => ({
		title: 'Oops! We can\'t change your short link',
		desc: matchErrorCodes(action.payload.serverMessages).join('. '),
	}),
	[EventActionsConstants.CANCEL_EVENT_FAILED]: (action: eventActions.CancelEventFailed) => ({
		title: 'Oops! We can\'t proceed event cancellation',
		desc: matchErrorCodes(action.payload.serverMessages).join('. '),
	}),
	[EventActionsConstants.CHANGE_EVENT_DATES_FAILED]: (action: eventActions.ChangeEventDatesFailed) => ({
		title: 'Oops! We can\'t change event dates',
		desc: matchErrorCodes(action.payload.serverMessages).join('. '),
	}),
	[EventActionsConstants.CHANGE_EVENT_DATES_SUCCESS]: () => ({
		title: 'Your event dates have been updated',
		desc: 'You may wish to review your ticket types now to make sure the sales close on the right dates',
		delay: NotificationDelay.LONG,
	}),
	[EventActionsConstants.CANCEL_EVENT_SUCCESS]: (action: eventActions.CancelEventSuccess) => ({
		title: action.payload.isAutoCancelled ? 'Event successfully cancelled' : 'Event cancellation request has been received',
		desc: !action.payload.isAutoCancelled ? 'We will begin refunding your ticket holders' : null,
	}),
	[EventActionsConstants.SUBMIT_EVENT_FOR_APPROVAL_SUCCESS]: (action: eventActions.SubmitEventForApprovalSuccess) => ({
		title: `Status successfully changed to
			 "${action.payload.status === ProductStatus.AwaitingActivation ? 'Awaiting activation' : 'Live'}"`,
	}),
	[EventActionsConstants.SET_EVENT_COVID_COMPLIANT_SUCCESS]: (action: eventActions.SetEventCovidCompliantSuccess) => ({
		title: `Covid compliance successfully ${action.payload.covidComplianceEnabled ? 'enabled' : 'disabled'}`,
	}),
	[EventActionsConstants.SET_EVENT_COVID_COMPLIANT_FAILED]: () => ({
		title: 'Oops! We can\'t update covid compliance',
	}),
	[EventActionsConstants.SUBMIT_EVENT_FOR_APPROVAL_FAILED]: () => ({
		title: 'Oops! We can\'t change the status',
	}),
	[EventActionsConstants.GET_EVENT_TYPES_FAILED]: () => ({
		title: 'Oops! We can\'t get categories right now',
	}),
	[UserActionsConstants.UPDATE_EMAIL_PREFERENCES_SUCCESS]: () => ({
		title: 'Your preferences have successfully updated',
	}),
	[UserActionsConstants.SET_USER_INFO_SUCCESS]: () => ({
		title: 'Your contact information was successfully updated',
	}),
	[UserActionsConstants.SET_USER_INFO_FAILED]: () => ({
		title: 'Oops! We can\'t update your contact information',
	}),
	[UserActionsConstants.UPDATE_USER_INFO_SUCCESS]: () => ({
		title: 'Your details have successfully updated',
	}),
	[UserActionsConstants.UPDATE_USER_INFO_FAILED]: (action: userActions.UpdateUserInfoFailed) => ({
		title: action.payload.serverMessages[0] !== ErrorCodes.Undefined ?
			action.payload.serverCustomErrorMessage : 'Oops! We can\'t update your details',
	}),
	[UserActionsConstants.CREATE_CONTACT_LIST_FAILED]: () => ({
		title: 'Oops! We can\'t create contact list for you',
	}),
	[UserActionsConstants.CREATE_CONTACT_LIST_SUCCESS]: (action: userActions.CreateContactListSuccess) => ({
		title: `Contact list with ${action.payload.itemsImported} contacts was successfully created`,
	}),
	[UserActionsConstants.UPDATE_USER_API_TOKEN_SUCCESS]: (action: userActions.UpdateUserAPITokenSuccess) => ({
		title: 'New token generated',
	}),
	[UserActionsConstants.UPDATE_USER_API_TOKEN_FAILED]: (action: userActions.UpdateUserAPITokenFailed) => ({
		title: 'Oops! We can\'t generate a new token',
	}),
	[EventActionsConstants.SEND_INVITES_FAILED]: () => ({
		title: 'Oops! We can\'t send invites',
	}),
	[EventActionsConstants.SEND_INVITES_SUCCESS]: () => ({
		title: 'Invites have been successfully sent',
	}),
	[FileActionsConstants.UPLOAD_CONTACTS_CSV_FAILED]: (action: fileActions.UploadContactsCSVFailed) => {
		const errorMessages = matchErrorCodes(action.payload.serverMessages);
		return ({
			title: errorMessages.length
				? errorMessages[0]
				: 'Oops! We can\'t parse your file',
		});
	},
	[UserActionsConstants.GET_PREVIOUS_CONTACT_LISTS_FAILED]: () => ({
		title: 'Oops! We can\'t get your previous contact lists',
	}),
	[UserActionsConstants.GET_PREVIOUS_EVENTS_FAILED]: () => ({
		title: 'Oops! We can\'t get your previous events',
	}),
	[UserActionsConstants.GET_EVENTS_ATTENDEES_FAILED]: () => ({
		title: 'Oops! We can\'t get your previous events attendees',
	}),
	[UserActionsConstants.ADD_BANK_ACCOUNT_SUCCESS]: () => ({
		title: 'Your bank account was successfully added',
	}),
	[UserActionsConstants.ADD_BANK_ACCOUNT_FAILED]: () => ({
		title: 'Oops! We can\'t add your bank account',
	}),
	[UserActionsConstants.GET_BANK_ACCOUNTS_FAILED]: () => ({
		title: 'Oops! We can\'t get your bank accounts',
	}),
	[UserActionsConstants.DELETE_BANK_ACCOUNT_FAILED]: (action: userActions.DeleteBankAccountFailed) => ({
		title: `${action.payload.msg}`,
	}),
	[UserActionsConstants.DELETE_BANK_ACCOUNT_SUCCESS]: () => ({
		title: 'Successfully deleted your bank account',
	}),
	[UserActionsConstants.UPDATE_BANK_ACCOUNT_FAILED]: (action: userActions.UpdateBankAccountFailed) => ({
		title: `${action.payload.msg}`,
	}),
	[UserActionsConstants.UPDATE_BANK_ACCOUNT_SUCCESS]: () => ({
		title: 'Successfully updated your bank account',
	}),
	[EventActionsConstants.UPDATE_EVENT_FACEBOOK_DATA_SUCCESS]: ({
		payload: { isAdding },
	}: eventActions.UpdateEventFacebookDataSuccess) => ({
		title: `Event successfully ${ isAdding ? 'created' : 'updated' } on Facebook`,
	}),
	[EventActionsConstants.DELETE_EVENT_LINK_CAMPAIGN_SUCCESS]: () => ({
		title: 'Link campaign successfully deleted',
	}),
	[EventActionsConstants.DELETE_EVENT_LINK_CAMPAIGN_FAILED]: () => ({
		title: 'Oops! We can\'t delete this link campaign',
	}),
	[EventActionsConstants.UPDATE_EVENT_DEFINITIONS_SUCCESS]: () => ({
		title: 'Event definitions successfully updated',
	}),
	[EventActionsConstants.UPDATE_EVENT_DEFINITIONS_FAILED]: () => ({
		title: 'Oops! We can\'t update your event definitions',
	}),
	[CollectionsActionConstants.UPDATE_EVENT_COLLECTIONS_SUCCESS]: () => ({
		title: 'Collection details successfully updated',
	}),
	[CollectionsActionConstants.UPDATE_EVENT_COLLECTIONS_FAILED]: (action: UpdateEventCollectionsFailed) => ({
		title: 'Oops! We can\'t update your collection details',
		desc: matchErrorCodes(action.payload.serverMessages).join('. '),
	}),
	[EventActionsConstants.UPDATE_EVENT_SCHEDULE_TRANSFER_SUCCESS]: () => ({
		title: 'Attendees transferred successfully',
	}),
	[EventActionsConstants.UPDATE_EVENT_SCHEDULE_TRANSFER_FAILED]: (action: eventActions.UpdateEventScheduleTransferFailed) => ({
		title: 'Oops! We cannot transfer your attendees',
		desc: matchErrorCodes(action.payload.serverMessages).join('. '),
	}),
	[OrganiserProfileActionConstants.UPDATE_ORGANISER_PROFILE_SUCCESS]: () => ({
		title: 'Profile updated successfully',
	}),
	[OrganiserProfileActionConstants.DELETE_ORGANISER_PROFILE_SUCCESS]: () => ({
		title: 'Profile successfully deleted',
	}),
	[OrganiserProfileActionConstants.UPDATE_ORGANISER_PROFILE_FAILED]: () => ({
		title: 'Oops! We can\'t update these profile details',
	}),
	[OrganiserProfileActionConstants.DELETE_ORGANISER_PROFILE_FAILED]: () => ({
		title: 'Oops! We can\'t delete this profile',
	}),
	[EventActionsConstants.UPDATE_EVENT_WEBHOOKS_SUCCESS]: () => ({
		title: 'Webhook successfully updated',
	}),
	[EventActionsConstants.UPDATE_EVENT_WEBHOOKS_FAILED]: () => ({
		title: 'Webhook update failed',
	}),
	[EventActionsConstants.TEST_EVENT_WEBHOOKS_FAILED]: () => ({
		title: 'webhook test failed',
	}),
	[EventActionsConstants.SET_EVENT_REDIRECT_PRODUCT_ID_SUCCESS]: () => ({
		title: 'Saved finished/cancelled event redirect',
	}),
	[EventActionsConstants.SET_EVENT_REDIRECT_PRODUCT_ID_FAILED]: () => ({
		title: 'Save finished/cancelled event redirect failed',
	}),
	[EventActionsConstants.ADD_EVENT_SCANNERS_SUCCESS]: () => ({
		title: 'Scanner added successfully',
	}),
	[EventActionsConstants.ADD_EVENT_SCANNERS_FAILED]: () => ({
		title: 'Oops! adding scanner failed',
	}),
	[EventActionsConstants.EDIT_EVENT_SCANNERS_SUCCESS]: () => ({
		title: 'Scanner edited successfully',
	}),
	[EventActionsConstants.EDIT_EVENT_SCANNERS_FAILED]: () => ({
		title: 'Oops! Editing scanner failed',
	}),
	[EventActionsConstants.NEW_PIN_EVENT_SCANNER_SUCCESS]: () => ({
		title: 'New scanner password generated successfully',
	}),
	[EventActionsConstants.NEW_PIN_EVENT_SCANNER_FAILED]: () => ({
		title: 'Oops! Generating new scanner password failed',
	}),
	[ManageBookingConstants.DOWNLOAD_TICKETS_SEPERATELY_SUCCESS]: () => ({
		title: 'Due to the large number of tickets, they will be emailed to you shortly',
	}),
	[ManageBookingConstants.DOWNLOAD_TICKETS_SEPERATELY_FAILED]: () => ({
		title: 'There was an error downloading your tickets please contact support or try again later',
	}),
	[ManageBookingConstants.UPDATE_BOOKING_INVOICE_SUCCESS]: () => ({
		title: 'Invoice updated successfully',
	}),
	[ManageBookingConstants.UPDATE_BOOKING_INVOICE_FAILED]: () => ({
		title: 'Oops! We can\'t update invoice details. Please contact support or try again',
	}),
	[UserActionsConstants.UPDATE_PASSWORD_SUCCESS]: () => ({
		title: 'Password updated successfully',
	}),
	[UserActionsConstants.UPDATE_PASSWORD_FAILED]: (action: userActions.UpdatePasswordFailed) => ({
		title: `${action.payload.msg}`,
	}),
	[UserActionsConstants.POST_REFUNDABLE_TICKETS_FAILED]: () => ({
		title: 'Oops! your tickets were not refunded, please try again or contact support',
	}),
	[UserActionsConstants.TRANSFER_TICKETS_FAILED]: (action) => ({
		title: action.payload.serverMessages[0] !== ErrorCodes.Undefined ?
			action.payload.serverCustomErrorMessage : 'Oops! your tickets were not transferred, please try again or contact support',
	}),
	[UserActionsConstants.TRANSFER_TICKETS_BACK_SUCCESS]: () => ({
		title: 'Your tickets were transferred back successfully',
	}),
	[UserActionsConstants.TRANSFER_TICKETS_BACK_FAILED]: (action) => ({
		title: action.payload.serverMessages[0] !== ErrorCodes.Undefined ?
			action.payload.serverCustomErrorMessage : 'Oops! your tickets were not transferred back, please try again or contact support',
	}),
	[UserActionsConstants.UPDATE_EDIT_TICKET_DETAILS_FAILED]: (action) => ({
		title: action.payload.serverMessages[0] !== ErrorCodes.Undefined ?
			action.payload.serverCustomErrorMessage : 'Oops! your tickets were not updated, please try again or contact support',
	}),
	[UserActionsConstants.GET_EDIT_TICKET_DETAILS_FAILED]: (action: userActions.GetEditTicketDetailsFailed) => {
		const errorMessages = matchErrorCodes(action.payload.serverMessages);
		return ({
			title: errorMessages.length
				? errorMessages[0]
				: 'Oops! We can\'t get your ticket details, please try again or contact support',
		});
	},
	[UserActionsConstants.GET_REGISTRATION_DETAILS_FAILED]: (action: userActions.GetRegistrationDetailsFailed) => {
		const errorMessages = matchErrorCodes(action.payload.serverMessages);
		return ({
			title: errorMessages.length
				? errorMessages[0]
				: 'Oops! We can\'t get your registration details, please try again or contact support',
		});
	},
	[UserActionsConstants.UPDATE_REGISTRATION_DETAILS_SUCCESS]: () => ({
		title: 'Successfully updated your registration',
	}),
	[UserActionsConstants.UPDATE_REGISTRATION_DETAILS_FAILED]: () => ({
		title: 'Oops! your registration was not updated, please try again or contact support',
	}),
	[UserActionsConstants.POST_RESALE_TICKETS_SUCCESS]: () => ({
		title: 'Successfully listed your ticket for resale',
	}),
	[UserActionsConstants.POST_RESALE_TICKETS_FAILED]: () => ({
		title: 'Oops! your ticket was not listed for resale, please try again or contact support',
	}),
	[UserActionsConstants.CANCEL_RESALE_TICKETS_SUCCESS]: () => ({
		title: 'Successfully cancelled your ticket resale',
	}),
	[UserActionsConstants.CANCEL_RESALE_TICKETS_FAILED]: () => ({
		title: 'Oops! your ticket resale was not cancelled, please try again or contact support',
	}),
	[UserActionsConstants.GET_USER_SAVED_EVENTS_FAILED]: () => ({
		title: 'Oops! unable to get your saved events, please try again or contact support',
	}),
	[UserActionsConstants.UPDATE_PROMOTER_BANK_ACCOUNT_FAILED]: () => ({
		title: 'Oops! your promoter bank account was not updated, please try again or contact support',
	}),
	[UserActionsConstants.UPDATE_REFERRAL_BANK_ACCOUNT_FAILED]: () => ({
		title: 'Oops! your referral bank account was not updated, please try again or contact support',
	}),
	[OrganiserActionsConstants.POST_UNLINK_MAILCHIMP_SUCCESS]: () => ({
		title: 'Successfully unlinked your MailChimp Account',
	}),
	[OrganiserActionsConstants.POST_UNLINK_MAILCHIMP_FAILED]: () => ({
		title: 'Oops! unable to link your MailChimp Account, please try again or contact support',
	}),
	[OrganiserActionsConstants.GET_MAILCHIMP_CONNECTION_FAILED]: () => ({
		title: 'Oops! unable to get your MailChimp Status, please try again or contact support',
	}),
	[OrganiserActionsConstants.GET_USER_REPORTS_INFO_FAILED]: () => ({
		title: 'Oops! unable to get your report info, please try again or contact support',
	}),
	[OrganiserActionsConstants.DOWNLOAD_USER_REPORTS_FAILED]: () => ({
		title: 'Oops! unable to download your reports, please try again or contact support',
	}),
	[OrganiserActionsConstants.DOWNLOAD_USER_REPORTS_SUCCESS]: () => ({
		title: 'Success! Your report will be emailed to you. Please allow for a few minutes while it is generated',
	}),
	[EventActionsConstants.SET_PROMOTERS_INCENTIVE_SUCCESS]: () => ({
		title: 'Successfully updated your incentive message',
	}),
	[EventActionsConstants.SET_PROMOTERS_INCENTIVE_FAILED]: () => ({
		title: 'Oops! unable to update your incentive message, please try again or contact support',
	}),
	[EventActionsConstants.RESEND_TICKETS_SUCCESS]: (action: eventActions.ResendTicketsSuccess) => ({
		title: `Tickets successfully resent to ${action.payload.mailSendingResult.purchaserEmail}`,
	}),
	[EventActionsConstants.RESEND_TICKETS_FAILED]: () => ({
		title: 'Oops!, unable to resend tickets, please contact support or try again',
	}),
	[OrganiserActionsConstants.ADD_MAIL_ACCOUNT_FAILED]: (action: AddMailAccountFailed) => ({
		title: 'Oops! We can\'t add your mail account',
		desc: matchErrorCodes(action.payload.serverMessages).join('. '),
	}),
	[OrganiserActionsConstants.ADD_MAIL_ACCOUNT_SUCCESS]: () => ({
		title: 'Successfully added your mail account',
	}),
	[EventActionsConstants.CREATE_PROMOTER_NETWORK_SUCCESS]: () => ({
		title: 'Successfully updated promoter network list',
	}),
	[EventActionsConstants.CREATE_PROMOTER_NETWORK_FAILED]: () => ({
		title: 'Oops!, unable to complete that action, please contact support or try again',
	}),
	[EventActionsConstants.UPDATE_REGISTRATION_SETTINGS_SUCCESS]: () => ({
		title: 'Successfully updated registration settings',
	}),
	[EventActionsConstants.UPDATE_REGISTRATION_SETTINGS_FAILED]: () => ({
		title: 'Oops! We can\'t update the registration settings',
	}),
	[EventActionsConstants.GET_REGISTRATION_SETTINGS_FAILED]: () => ({
		title: 'Oops! We can\'t get the registration settings',
	}),
	[EventActionsConstants.DELETE_TAB_CONTENT_SUCCESS]: () => ({
		title: 'Tab was successfully deleted',
	}),
	[EventActionsConstants.DELETE_TAB_CONTENT_FAILED]: () => ({
		title: 'Oops! We can\'t delete tab',
	}),
	[EventActionsConstants.SET_TAB_CONTENT_VISIBILITY_SUCCESS]: () => ({
		title: 'Successfully updated tab\'s visibility',
	}),
	[EventActionsConstants.SET_TAB_CONTENT_VISIBILITY_FAILED]: () => ({
		title: 'Oops! We can\'t set tab content visibility',
	}),
	[EventActionsConstants.UPSERT_TAB_CONTENT_FAILED]: (action:  UpsertTabContentFailed) => ({
		title: `Oops! We can't ${action.payload.action.payload.isUpdating ? 'update' : 'add'} tab content`,
	}),
	[EventActionsConstants.UPSERT_TAB_CONTENT_SUCCESS]: (action:  UpsertTabContentSuccess) => ({
		title: `Tab ${action.payload.isUpdating ? 'updated' : 'added'} successfully`,
	}),
	[EventActionsConstants.REORDER_TABBED_CONTENT_SUCCESS]: () => ({
		title: 'Successfully reordered your tabs',
	}),
	[EventActionsConstants.REORDER_TABBED_CONTENT_FAILED]: () => ({
		title: 'Oops! We can\'t reorder the tabs, please contact support or try again',
	}),
	[EventActionsConstants.ADD_EVENT_LINK_CAMPAIGN_SUCCESS]: () => ({
		title: 'Successfully added new link campaign',
	}),
	[EventActionsConstants.ADD_EVENT_LINK_CAMPAIGN_FAILED]: () => ({
		title: 'Oops! We can\'t add the link campaign, please contact support or try again',
	}),
	[EventActionsConstants.UPSERT_EVENT_COLLECT_INFO_SUCCESS]: () => ({
		title: 'Successfully saved your question',
	}),
	[EventActionsConstants.UPSERT_EVENT_COLLECT_INFO_FAILED]: (action:  UpsertEventCollectInfoFailed) => ({
		title: 'Oops!, unable to save your question',
		desc: matchErrorCodes(action.payload.serverMessages).join(''),
	}),
	[EventActionsConstants.REORDER_COLLECT_INFO_SUCCESS]: () => ({
		title: 'Successfully reordered your questions',
	}),
	[EventActionsConstants.REORDER_COLLECT_INFO_FAILED]: () => ({
		title: 'Oops!, unable to reorder your questions, please contact support or try again',
	}),
	[EventActionsConstants.SET_COLLECT_INFO_PROPERTY_FAILED]: () => ({
		title: 'Oops!, unable to update your questions, please contact support or try again',
	}),
	[EventActionsConstants.DELETE_COLLECT_INFO_SUCCESS]: () => ({
		title: 'Successfully deleted your question',
	}),
	[EventActionsConstants.DELETE_COLLECT_INFO_FAILED]: () => ({
		title: 'Oops!, unable to delete your question, please contact support or try again',
	}),
	[EventActionsConstants.UPDATE_EVENT_SKIP_COMPLETION_ITEM_SUCCESS]: () => ({
		title: 'Successfully skipped item',
	}),
	[EventActionsConstants.UPDATE_EVENT_SKIP_COMPLETION_ITEM_FAILED]: () => ({
		title: 'Skipping item failed. Please try again',
	}),
	[EventActionsConstants.COPY_SEATING_CHART_SUCCESS]: () => ({
		title: 'Seating chart copied successfully.',
	}),
	[EventActionsConstants.COPY_SEATING_CHART_FAILED]: () => ({
		title: 'Failed to copy the seating chart, please contact support or try again.',
	}),
	[EventActionsConstants.UPDATE_EVENT_DESIGN_SUCCESS]: () => ({
		title: 'Event design successfully updated',
	}),
	[EventActionsConstants.UPDATE_EVENT_DESIGN_FAILED]: () => ({
		title: 'Oops! We can\'t update event design',
	}),
	[EventActionsConstants.COPY_EVENT_SUCCESS]: () => ({
		title: 'Event successfully copied',
	}),
	[EventActionsConstants.COPY_EVENT_FAILED]: () => ({
		title: 'Oops! We can\'t copy this event for now',
	}),
	[EventActionsConstants.REORDER_TICKET_GROUP_SUCCESS]: () => ({
		title: 'Ticket group successfully reordered',
	}),
	[EventActionsConstants.REORDER_TICKET_GROUP_FAILED]: () => ({
		title: 'Oops! There was a problem reordering the ticket group',
	}),
	[EventActionsConstants.DELETE_TICKET_GROUP_SUCCESS]: () => ({
		title: 'Ticket group successfully deleted',
	}),
	[EventActionsConstants.DELETE_TICKET_GROUP_FAILED]: () => ({
		title: 'Oops! There was a problem deleting the ticket group',
	}),
	[EventActionsConstants.UPSERT_TICKET_GROUP_FAILED]: (action:  eventActions.UpsertTicketGroupSuccess) => ({
		title: `Oops! We can\'t ${action.payload.isUpdating ? 'update' : 'add'} ticket group`,
	}),
	[EventActionsConstants.UPSERT_TICKET_GROUP_SUCCESS]: (action:  eventActions.UpsertTicketGroupSuccess) => ({
		title: `Ticket group ${action.payload.isUpdating ? 'updated' : 'added'} successfully`,
	}),
	[EventActionsConstants.UPDATE_EVENT_SETTINGS_SUCCESS]: () => ({
		title: 'Event successfully updated',
	}),
	[EventActionsConstants.UPDATE_EVENT_SETTINGS_FAILED]: () => ({
		title: 'Oops! We can\'t update event settings, please contact support or try again',
	}),
	[EventActionsConstants.DELETE_EVENT_SUCCESS]: () => ({
		title: 'Event successfully deleted',
	}),
	[EventActionsConstants.DELETE_EVENT_FAILED]: () => ({
		title: 'Oops! We can\'t delete event, please contact support or try again',
	}),
	[EventActionsConstants.CHANGE_TICKET_TYPES_SUCCESS]: () => ({
		title: 'Tickets successfully changed to new ticket types.',
	}),
	[EventActionsConstants.CHANGE_TICKET_TYPES_FAILED]: (action: eventActions.ChangeTicketTypesFailed) => ({
		title: 'We can\'t update this orders ticket types',
		desc: `${action.payload.msg}`,
	}),
	[EventActionsConstants.CANCEL_TICKETS_SUCCESS]: () => ({
		title: 'Tickets successfully cancelled.',
	}),
	[EventActionsConstants.CANCEL_TICKETS_FAILED]: (action: eventActions.CancelTicketsFailed) => ({
		title: 'We can\'t cancel this orders tickets',
		desc: `${action.payload.msg}`,
	}),
	[EventActionsConstants.RESEND_ORDER_TICKETS_SUCCESS]: () => ({
		title: 'Tickets successfully queued for resending',
	}),
	[EventActionsConstants.RESEND_ORDER_TICKETS_FAILED]: () => ({
		title: 'Oops! We can\'t resend tickets, please contact support or try again',
	}),
	[EventActionsConstants.MARK_AS_PAID_SUCCESS]: () => ({
		title: 'Order successfully marked as paid',
	}),
	[EventActionsConstants.MARK_AS_PAID_FAILED]: () => ({
		title: 'Oops! We can\'t mark this order as paid, please contact support or try again',
	}),
};

export class GeneralNotificationSource extends NotificationDictionary {
	source = preferableActions;
}
