<div
	class="l-container"
	[ngClass]="{'mobile': data.isMobile}"
>
	<h3 class="mb-3">Generate Report</h3>
	<form
		#reportForm="ngForm"
		class="g-form-container mt-5"
	>
		<div
			*ngIf="!isLoading && userReportInfo; else showLoading"
			class='w-100'
		>
			<div *ngIf="hasEvents; else hasNoEvents">
				<div class="input-group w-100 mb-3">
					<label class="g-form-label">Report Type<span class="g-orange">*</span></label>
					<mat-select
						id='report-type-select'
						class="form-control w-100"
						[(ngModel)]="selectedReportType"
						name="report"
						#report="ngModel"
						placeholder="Please Select Report Type"
						required
						#singleSelect
					>
						<mat-option
							*ngFor="let type of userReportInfo.reportTypes"
							[value]="type"
						>
							{{ type.name }}
						</mat-option>
					</mat-select>
				</div>
				<div *ngIf="selectedReportType">
					<div
						*ngIf="displayFormField(selectedReportType, 'products')"
						class="input-group w-100 mb-3 l-max-width"
					>
						<label class="g-form-label">Select Event<span class="g-orange">*</span></label>
						<mat-select
							id='event-select'
							class="form-control w-100"
							#eventSelect="ngModel"
							name="event"
							placeholder="Please Select Events"
							[(ngModel)]="selectedEvents"
							(selectionChange)="eventSelectChange()"
							required
							multiple
						>
							<mat-option>
								<ngx-mat-select-search
									ngModel
									(ngModelChange)="handleEventsFilter($event)"
									name="eventSelect"
									#eventSearch="ngModel"
									placeholderLabel="Select your events..."
									noEntriesFoundLabel="No matching events found"
								>
								</ngx-mat-select-search>
							</mat-option>
							<div class="l-select-all">
								<label class="l-select-all-label"> 
									<mat-checkbox
										id="select-all-checkbox"
										(change)="toggleSelectAll()"
										[checked]="selectAllSelected"
									></mat-checkbox>
									Select all
								</label>
							</div>
							<mat-option
								*ngFor="let event of filteredEvents"
								[value]="event.id"
							>
								{{ event.name }}
							</mat-option>
						</mat-select>
					</div>

					<div
						*ngIf="displayFormField(selectedReportType, 'organisers')"
						class="input-group w-100 mb-3"
					>
						<label class="g-form-label">Select Organiser<span class="g-orange">*</span></label>
						<mat-select
							id='event-type-select'
							class="form-control w-100"
							[(ngModel)]="selectedOrganiser"
							name="organiser"
							#eventType="ngModel"
							placeholder="Please Select an Organiser"
							required
							#singleSelect
						>
							<mat-option
								*ngFor="let organiser of userReportInfo.organisers"
								[value]="organiser.id"
							>
								{{ organiser.name }}
							</mat-option>
						</mat-select>
					</div>

					<div
						*ngIf="displayFormField(selectedReportType, 'dates')"
						class="g-double-input-container"
					>
						<div class="l-input input-group">
							<label class="g-form-label">Date From<span class="g-orange">*</span></label>
							<div class="l-date-container">
								<mat-icon class="l-icon">calendar_today</mat-icon>
								<input
									id="date-from-input"
									#from="ngModel"
									[(ngModel)]="dateFrom"
									type="text"
									name="from"
									class="form-control w-100 l-date-input"
									placeholder="DD-MM-YYYY"
									required
									readonly
									[matDatepicker]="dateFromPicker"
									[matDatepickerFilter]="dateFromFilter"
									(click)="dateFromPicker.open()"
								/>
							</div>
							<mat-datepicker
								id="date-from-date-picker"
								#dateFromPicker
							>
								<mat-date-range-picker-actions>
									<button
										id="close-datepicker"
										class="g-secondary-button g-datepicker-button"
										matDatepickerCancel
									>CANCEL</button>
									<button
										id="apply-datepicker"
										class="g-primary-button g-datepicker-button"
										matDatepickerApply
									>APPLY</button>
								</mat-date-range-picker-actions>
							</mat-datepicker>
						</div>
						<div class="l-input input-group">
							<label class="g-form-label">Date To<span class="g-orange">*</span></label>
							<div class="l-date-container">
								<mat-icon class="l-icon">calendar_today</mat-icon>
								<input
									id="date-to-input"
									#to="ngModel"
									[(ngModel)]="dateTo"
									type="text"
									name="to"
									class="form-control w-100 l-date-input"
									placeholder="DD-MM-YYYY"
									required
									readonly
									[matDatepicker]="dateToPicker"
									[matDatepickerFilter]="dateToFilter"
									(click)="dateToPicker.open()"
								/>
							</div>
							<mat-datepicker
								id="date-to-date-picker"
								#dateToPicker
							>
								<mat-date-range-picker-actions>
									<button
										id="close-datepicker"
										class="g-secondary-button g-datepicker-button"
										matDatepickerCancel
									>CANCEL</button>
									<button
										id="apply-datepicker"
										class="g-primary-button g-datepicker-button"
										matDatepickerApply
									>APPLY</button>
								</mat-date-range-picker-actions>
							</mat-datepicker>
						</div>
					</div>
				</div>
			</div>
			<ng-template #hasNoEvents>
				<div>
					<p class="l-text">You currently have no events to create reports on</p>
				</div>
			</ng-template>
		</div>
		<ng-template #showLoading>
			<div class="l-loading">
				<mat-spinner></mat-spinner>
			</div>
		</ng-template>
	</form>

	<div class="l-buttons-container">
		<button
			id='cancel-button'
			class="g-secondary-button border-0"
			(click)="handleCancel()"
		>CANCEL</button>
		<button
			id='download-report-button'
			class="g-primary-button"
			(click)="handleDownload()"
			[class.disabled]="reportForm.invalid || isLoading || !hasEvents"
			[disabled]="reportForm.invalid || isLoading"
		>SEND</button>
	</div>
	<div class="l-disclaimer pt-1">
		Your report will be emailed to your inbox once you have clicked send.
	</div>
</div>