import { Directive, HostListener, Input, NgModule } from '@angular/core';

@Directive({
	selector: '[appMiddleClickNavigate]',
})
export class MiddleClickNavigateDirective {
	@Input() appMiddleClickNavigate: string;
	@Input() queryParams: any;

	@HostListener('mousedown', ['$event'])
	onClick(event: MouseEvent) {
		if (event.button === 1) {
			event.preventDefault();
			if (this.appMiddleClickNavigate) {
				const url = this.createFullUrl(this.appMiddleClickNavigate, this.queryParams);
				window.open(url, '_blank');
			}
		}
	}

	createFullUrl(path: string, queryParams: any): string {
		if (!queryParams) {
			return path;
		}

		const queryString = Object.keys(queryParams)
			.map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
			.join('&');

		return `${path}?${queryString}`;
	}
}

@NgModule({
	declarations: [MiddleClickNavigateDirective],
	exports: [MiddleClickNavigateDirective],
	imports: [],
})
export class MiddleClickNavigateModule {}
