import { Notification } from '@app/models/notification.model';
import { AppAction } from '@app/models/action.model';
import { NotificationActionConstants as constants } from './notification.constants';

export class AddNotification extends AppAction<Notification> {
	readonly type = constants.NOTIFICATION_ADD;
}

export class RemoveNotification extends AppAction<{ id: string }> {
	readonly type = constants.NOTIFICATION_REMOVE;
}

export type Actions =
	AddNotification
	| RemoveNotification;
