<div class="l-container my-3" [ngClass]="{ 'mx-3 flex-column': isMobile, 'mx-5': !isMobile }">
	<div class="l-content">
		<div class="l-icon-container" *ngIf='!isMobile'>
			<img src='./assets/icons/warning.svg' class='l-icon' />
		</div>
		<div class="l-text">
			You have not linked your bank account yet. Link your account in order for your commission payments to go through.
		</div>
	</div>
	<div class="l-button">
		<button class="g-warning-button" (click)="handleButton()" [class.disabled]="false" [disabled]="false">LINK BANK
			ACCOUNT</button>
	</div>
</div>
