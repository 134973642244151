import { Component, EventEmitter, OnInit, Input} from '@angular/core';
import { GuestInfo, GuestListRequestInfo, ListResultInfo } from '@app/models/event.model';
import { StoreService } from '@app/services/store/store.service';
import { EventActionsConstants } from '@app/store/actions/event/event.actions.constants';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs';
import * as eventSelectors from '@app/store/selectors/event.selector';
import { GetEventGuestList, GetEventMetadata } from '@app/store/actions/event/event.actions';
import { SchemeID } from '@app/models/dataSchema.model';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { ScheduleItem } from '@app/models/schedule.model';
import { MatDialog } from '@angular/material/dialog';
import { GuestListReportModalComponent } from '../../modals/guest-list-report-modal/guest-list-report-modal.component';
import { GuestDetailsModalComponent } from '../../modals/guest-details-modal/guest-details-modal.component';
import { indexOf} from 'lodash';
import { FilterOptions } from '@app/models/shared';

@Component({
	selector: 'app-guest-list-table',
	templateUrl: './guest-list-table.component.html',
	styleUrls: ['./guest-list-table.component.sass'],
})
export class GuestListTableComponent implements OnInit {

	@Input() eventId: SchemeID;
	@Input() isMobile: boolean;

	displayColumns: string[] = ['name', 'ticketType', 'email', 'contact', 'moreInfo'];

	isGuestLoading = true;
	isGuestListFiltering = false;

	guestListInfo: ListResultInfo;
	guests: GuestInfo[];
	resultsLength: number;
	hasInitialGuests: boolean;

	eventName: string;
	scheduleItems: ScheduleItem[];
	isOngoing = false;
	isSeated = false;

	guestListFilter = new GuestListRequestInfo();
	selectedScheduleItemId: SchemeID = 0;
	pageSize = 10;
	pageSizeOptions: number[] = [10, 25, 50];
	currentPageIndex = 0;
	searchPlaceholder = 'Search guests';

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private store: StoreService,
		private action$: Actions,
		private dialog: MatDialog
	) {}

	ngOnInit(): void {
		this.initialiseGuestListInfo();
	}

	initialiseGuestListInfo() {
		this.guestListFilter.eventId = this.eventId;
		this.dispatchFilteredGuests();

		this.store.select(eventSelectors.eventMetadata(this.eventId)).pipe(takeUntil(this.destroyed$)).subscribe((eventMetaData) => {
			if (eventMetaData){
				this.isOngoing = eventMetaData.isOngoing;
				this.scheduleItems = eventMetaData.scheduleItems.filter(item => !item.deleted);
				this.eventName = eventMetaData.name;
				this.isSeated = eventMetaData.isSeated;
			} else {
				this.store.dispatch(new GetEventMetadata({ id: this.eventId }));
			};
		});

		this.action$
			.pipe(
				ofType(EventActionsConstants.GET_EVENT_GUEST_LIST_SUCCESS, EventActionsConstants.GET_EVENT_GUEST_LIST_FAILED),
				takeUntil(this.destroyed$)
			)
			.subscribe(() => {
				this.isGuestLoading = false;
				this.isGuestListFiltering = false;
			});

		this.store.select(eventSelectors.GuestListInfo(this.eventId)).pipe(takeUntil(this.destroyed$)).subscribe((guestListInfo) => {
			if (guestListInfo){
				if (guestListInfo.results.length > 0) {
					this.hasInitialGuests = true;
				}

				this.guests = guestListInfo.results;
				this.resultsLength = guestListInfo.records;
			};
		});
	}

	handlePagination(page: PageEvent) {
		if (this.guestListFilter.pageSize !== page.pageSize) {
			this.resetPagination();
		} else {
			this.guestListFilter.startingResultIndex = (page.pageIndex * page.pageSize);
			this.currentPageIndex = page.pageIndex;
		}
		this.guestListFilter.pageSize = page.pageSize;
		this.dispatchFilteredGuests();
	}

	handleSort(sort: Sort) {
		if (sort.direction === ''){
			sort.direction = 'asc';
		}
		this.guestListFilter.sortCol = indexOf(this.displayColumns, sort.active);
		this.guestListFilter.sortDirection = sort.direction.toUpperCase();
		this.resetPagination();
		this.dispatchFilteredGuests();
	}

	dispatchFilteredGuests() {
		this.isGuestListFiltering = true;
		const filters = {
			...this.guestListFilter,
		};
		if (this.selectedScheduleItemId !== filters.scheduleItemId){
			this.selectedScheduleItemId = filters.scheduleItemId;
		};
		this.store.dispatch(new GetEventGuestList({ guestListRequestInfo: filters }));
	}

	handleScheduleFilterOutput(scheduleFilterOutput: ScheduleItem){
		this.guestListFilter.scheduleItemId = scheduleFilterOutput.id;
		this.resetPagination();
		this.dispatchFilteredGuests();
	}

	handleWebFilterOutput(filterOutput: FilterOptions) {
		if (filterOutput.searchTerm === ''){
			delete this.guestListFilter.search;
		}else{
			this.guestListFilter.search = filterOutput.searchTerm;
		}
		this.currentPageIndex = 0;
		this.guestListFilter.startingResultIndex = 0;
		this.dispatchFilteredGuests();
	}

	handleMoreInfo(purchaseId: number, purchaseItemId: number){
		this.dialog.open(GuestDetailsModalComponent, {
			data: {
				eventId: this.eventId,
				purchaseId: purchaseId,
				purchaseItemId: purchaseItemId,
				isSeated: this.isSeated,
			},
			panelClass: this.isMobile ? 'g-full-page-dialog' : 'g-standard-dialog',
		});
	}

	handleDownloadReport() {
		this.dialog.open(GuestListReportModalComponent, {
			data: {
				eventId: this.eventId,
				scheduleItemId: this.selectedScheduleItemId,
				eventName: this.eventName,
				isOngoing: this.isOngoing,
				scheduleItems: this.scheduleItems,
				isSeated: this.isSeated,
			},
			panelClass: 'g-standard-dialog',
		});
	}

	resetPagination(){
		this.currentPageIndex = 0;
		this.guestListFilter.startingResultIndex = 0;
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
