<div class="l-container">
	<div
		class="l-content l-header-content"
		[ngClass]="{ 'mobile': isMobile }"
	>
		<app-web-content-header
			class="l-header"
			[ngClass]="{ 'mobile': isMobile }"
			[headerContent]="headerContent"
			[mediumWidth]="true"
			[showBreadCrumb]="!isMobile"
			[showTitle]="!isMobile"
			(handleExternalNavigation)='handleBreadCrumbNavigation($event)'
			[handleRoutingExternally]="true"
		>
		</app-web-content-header>
		<div *ngIf="!isLoading; else ticketGroupsLoading">
			<div *ngIf="mode == TicketGroupsPageMode.List; else addOrEdit">
				<app-ticket-groups-list
					[isMobile]="isMobile"
					[eventId]="eventId"
					[ticketGroups]="eventTicketGroups"
					[ticketTypes]="ticketTypes"
					[ticketGroupForm]="form"
					[isLoading]="isLoading"
					(reorder)="onReorder($event)"
					(deleteTicketGroup)="handleDeleteTicketGroup()"
					(addOrEditTicketGroup)="handleAddEditTicketGroup($event)"
				>
				</app-ticket-groups-list>
			</div>
			<ng-template #addOrEdit>
				<app-ticket-groups-add-edit
					[form]="form"
					[eventId]="eventId"
					[isMobile]="isMobile"
					[isEditing]="isEditing"
					(routeBackToListPage)="handleAddEditComplete()"
				></app-ticket-groups-add-edit>
			</ng-template>
		</div>
		<ng-template #ticketGroupsLoading>
			<div class="l-loading">
				<mat-spinner></mat-spinner>
			</div>
		</ng-template>
	</div>
</div>