import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SchemeID } from '@app/models/dataSchema.model';
import { ScheduleItem } from '@app/models/schedule.model';
import { getCookie, setCookie } from '@app/utils/Cookie';
import { EVENT_ID_PARAM_KEY } from '@app/utils/consts';
import { take } from 'rxjs';
import { ProductStatus } from '@app/models/event.model';

@Component({
	selector: 'app-schedule-selector',
	templateUrl: './schedule-selector.component.html',
	styleUrls: ['./schedule-selector.component.sass'],
})

export class ScheduleSelectorComponent implements OnInit {
	@Input() scheduleItems: ScheduleItem[];
	@Input() isMobile = false;
	@Output() SelectedScheduleOutput: EventEmitter<ScheduleItem> = new EventEmitter<ScheduleItem>;
	selectedScheduleItem: ScheduleItem;
	initialScheduleItemId: SchemeID;
	allSchedulesSelected: ScheduleItem;
	eventId: number;
	cookieScheduleKey: string;
	ProductStatus = ProductStatus;

	constructor(
		private activatedRoute: ActivatedRoute
	) { }

	ngOnInit(): void {
		this.allSchedulesSelected = new ScheduleItem();
		this.allSchedulesSelected.id = 0;
		this.activatedRoute.parent.paramMap.pipe(take(1)).subscribe((params) => {
			this.eventId = +params.get(EVENT_ID_PARAM_KEY);
		});
		this.initialiseScheduleCookies();
		this.sortScheduleItemsDateAscending();
	}

	initialiseScheduleCookies() {
		this.cookieScheduleKey = `${this.eventId}.scheduleItemId`;
		this.initialScheduleItemId = this.getScheduleItemCookie();
		if (this.initialScheduleItemId !== 0) {
			this.selectedScheduleItem = this.scheduleItems.find(item => item.id === this.initialScheduleItemId);
			this.SelectedScheduleOutput.emit(this.selectedScheduleItem);
		}
	}

	sortScheduleItemsDateAscending() {
		const sortedScheduleItems = [...this.scheduleItems].sort((a, b) => {
			const dateA = new Date(a.startDate).getTime();
			const dateB = new Date(b.startDate).getTime();

			if (dateA !== dateB) {
				return dateA - dateB;
			} else {
				const endDateA = new Date(a.endDate).getTime();
				const endDateB = new Date(b.endDate).getTime();
				return endDateA - endDateB;
			}
		});
		this.scheduleItems = sortedScheduleItems;
	}

	handleEmit() {
		this.setScheduleItemCookie();
		this.SelectedScheduleOutput.emit(this.selectedScheduleItem);
	}

	addScheduleItemCookie(): void {
		setCookie(this.cookieScheduleKey, '0', 0.042);
	}

	getScheduleItemCookie(): SchemeID {
		if (!getCookie(this.cookieScheduleKey)) {
			this.addScheduleItemCookie();
			return 0;
		}
		return +getCookie(this.cookieScheduleKey);
	}

	setScheduleItemCookie(): void {
		setCookie(this.cookieScheduleKey, `${this.selectedScheduleItem.id}`, 0.042);
	}

	isSameDaySchedule(scheduleItem: ScheduleItem): boolean {
		const start = new Date(scheduleItem.startDate);
		const end = new Date(scheduleItem.endDate);

		return start.getFullYear() === end.getFullYear() &&
			start.getMonth() === end.getMonth() &&
			start.getDate() === end.getDate();
	}
}
