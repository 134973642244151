import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DatesService } from '@app/services/dates/dates.service';
import { setTimezoneFrom } from '@app/utils/Date';

@Component({
	selector: 'app-schedule-to-go-public-modal',
	templateUrl: './schedule-to-go-public-modal.component.html',
	styleUrls: ['./schedule-to-go-public-modal.component.sass'],
})
export class ScheduleToGoPublicModalComponent {
	dateToGoLive: Date = null;

	constructor(
		public dialogRef: MatDialogRef<ScheduleToGoPublicModalComponent>,
		public datesService: DatesService,

		@Inject(MAT_DIALOG_DATA) public data: {
			isMobile: boolean;
			scheduledToGoPublicOn: Date;
			timeZone: string;
		}
	) { }

	getScheduledToGoPublicOn() {
		return setTimezoneFrom(this.data.scheduledToGoPublicOn, this.data.timeZone);
	}

	handleCancel() {
		this.dialogRef.close(false);
	}

	handleSave() {
		this.dialogRef.close(this.dateToGoLive);
	}
}
