import { Bank, BankAccount, RefundOptions, RefundableTicket, BankAccountReturn, AccountModalReturn } from './../../../../models/user.model';
import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderContent } from '@app/models/shared';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { BANK_ACCOUNT_MODAL, MY_TICKETS_HEADER, REDIRECT_PARAM_KEY } from '@app/utils/consts';
import { take, takeUntil } from 'rxjs/operators';
import { StoreService } from '@app/services/store/store.service';
import * as userSelectors from '@app/store/selectors/user.selector';
import { Actions, ofType } from '@ngrx/effects';
import { UserActionsConstants } from '@app/store/actions/user/user.actions.constants';
import { Go } from '@app/store/actions/router/router.actions';
import { GetBankAccounts, GetRefundableTickets } from '@app/store/actions/user/user.actions';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RefundModalComponent } from '../../modals/refund-modal/refund-modal.component';
import { FooterButtonComponent } from '@app/shared/footer-button/footer-button.component';
import { InternalURLCreator, URLCreator } from '@app/services/url/url.dictionary';
import { ConfirmationModalComponent } from '@app/shared/modals/confirmation-modal/confirmation-modal.component';
import { BankAccountModalComponent } from '@app/shared/bank-account-modal/bank-account-modal.component';
import { TICKET_ID_PARAM_KEY } from '../my-tickets/my-tickets.component';
@Component({
	selector: 'app-refund-request',
	templateUrl: './refund-request.component.html',
	styleUrls: ['./refund-request.component.sass'],
})
export class RefundRequestComponent implements OnInit {
	isMobile = false;
	headerContent: HeaderContent = MY_TICKETS_HEADER['refundRequest'];
	purchaseId: number;
	reference: string;
	confirmation = false;
	errorMessage: string;
	refundableTickets: RefundableTicket[] = [];
	selectedTickets: RefundableTicket[] = [];
	refundOptions: RefundOptions;
	currencyId: number;
	banks: Bank[];
	accounts: BankAccount[];
	customDescription: string;
	productImage: string;

	isTicketFetchLoading = true;
	isBanksDataFetching = false;
	isLoading = true;

	@ViewChild('footerButton') footerButton: FooterButtonComponent;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	get isFooterButtonDisabled(): boolean {
		return !this.selectedTickets.length || !this.confirmation || this.isBanksDataFetching || this.isLoading;
	}

	constructor(
		private breakpointService: BreakpointService,
		private activatedRoute: ActivatedRoute,
		private store: StoreService,
		private actions$: Actions,
		private dialog: MatDialog,
		private _snackBar: MatSnackBar,
		private router: Router
	) {}

	ngOnInit(): void {
		this.bankVerificationCheck();

		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});

		this.activatedRoute.params.pipe(take(1)).subscribe((params) => {
			this.purchaseId = parseInt(params[TICKET_ID_PARAM_KEY], 10);
			this.initializeCurrentRefundableTickets();
		});
	}

	initializeCurrentRefundableTickets() {
		this.actions$
			.pipe(ofType(UserActionsConstants.GET_REFUNDABLE_TICKETS_SUCCESS, UserActionsConstants.GET_REFUNDABLE_TICKETS_FAILED), take(1))
			.subscribe(() => {
				this.isTicketFetchLoading = false;
			});

		this.store
			.select(userSelectors.currentRefundableTickets())
			.pipe(takeUntil(this.destroyed$))
			.subscribe((refundableTickets) => {
				if (!refundableTickets.refundableTickets.errorMessage || +refundableTickets.purchaseId !== +this.purchaseId) {
					return;
				}
				if (refundableTickets.refundableTickets.canRefund) {
					this.refundableTickets = refundableTickets.refundableTickets.tickets;
					this.refundOptions = refundableTickets.refundableTickets.refundMethod;
					this.currencyId = refundableTickets.refundableTickets.currencyId;
				} else {
					this.errorMessage = refundableTickets.refundableTickets.errorMessage;
				}
				this.productImage = refundableTickets.refundableTickets.productImage;
				this.customDescription =
					`Please select the tickets below you wish to cancel and refund for ${refundableTickets.refundableTickets.productName}`;
				this.reference = refundableTickets.refundableTickets.reference;
			});

		this.store.dispatch(new GetRefundableTickets({ purchaseId: this.purchaseId }));

	}

	handleCheckboxChange(ticket: RefundableTicket) {
		if (this.selectedTickets.includes(ticket)) {
			this.selectedTickets = this.selectedTickets.filter((t) => t !== ticket);
		} else {
			this.selectedTickets = [...this.selectedTickets, ticket];
		}
		if (this.selectedTickets.length === 0) {
			this.confirmation = false;
		}
	}

	handleNavigationBack() {
		this.store.dispatch(new Go({ path: [InternalURLCreator.manageBooking(this.purchaseId)] }));
	}

	handlePrimaryButton() {
		if (!this.refundOptions.canRefundViaQuicket) {
			this._snackBar.open('These tickets are not refundable', 'Close', {
				duration: 4000,
				panelClass: 'g-error-snackbar',
				horizontalPosition: 'center',
				verticalPosition: 'top',
			});
		}

		const webText = 'Are you sure you want to cancel these tickets? Cancellation of tickets cannot be reversed.';
		const mobileText =
			'Are you sure you want to cancel these tickets? Cancellation of tickets cannot be reversed.';

		const confirmationDialogRef = this.dialog.open(ConfirmationModalComponent, {
			data: {
				title: 'Are You Sure?',
				text: this.isMobile ? mobileText : webText,
				buttonText: 'REFUND',
				isMobile: this.isMobile,
			},
			panelClass: 'g-standard-dialog',
		});

		confirmationDialogRef.afterClosed().subscribe((result) => {
			if (result) {
				if (this.refundOptions.gatewaySupportsRefunding) {
					this.handleRefundDialog();
					return;
				}
				if (this.refundOptions.refundRequiresBankAccount) {
					this.isBanksDataFetching = true;
					const dialogRef = this.dialog.open(BankAccountModalComponent, {
						data: {
							isMobile: this.isMobile,
							currencyId: this.currencyId,
							descriptions: BANK_ACCOUNT_MODAL['refund'],
						},
						panelClass: this.isMobile ? 'g-full-page-dialog' : 'g-standard-dialog',
					});

					dialogRef.afterClosed().subscribe((account: AccountModalReturn) => {
						if (account?.accountId) {
							this.handleRefundDialog(account.accountId);
						} else {
							this.isBanksDataFetching = false;
						}
					});
				}
			}
		});
	}

	handleTermsNavigation() {
		window.open(URLCreator.terms());
	}

	handleRefundDialog(bankAccountId: number = null) {
		const refundDialogRef = this.dialog.open(RefundModalComponent, {
			data: {
				purchaseId: this.purchaseId,
				tickets: this.selectedTickets,
				bankAccountId: bankAccountId,
			},
			panelClass: 'g-standard-dialog',
			disableClose: true,
		});

		refundDialogRef.afterClosed().subscribe(({ isSuccess, tickets }: { isSuccess: boolean; tickets: RefundableTicket[] }) => {
			this.isBanksDataFetching = false;
			if (isSuccess) {
				this.selectedTickets = [];
				if (tickets.length === 0) {
					this.store.dispatch(new Go({ path: [InternalURLCreator.myTickets()] }));
				}
			}
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}

	bankVerificationCheck() {
		this.actions$
			.pipe(ofType(UserActionsConstants.GET_BANK_ACCOUNTS_SUCCESS, UserActionsConstants.GET_BANK_ACCOUNTS_FAILED), take(1))
			.subscribe(
				({
					type,
					payload: { needsPasswordVerification, passwordVerificationRedirect },
				}: {
					type: string;
					payload: BankAccountReturn;
				}) => {
					if (type === UserActionsConstants.GET_BANK_ACCOUNTS_SUCCESS) {
						if (needsPasswordVerification) {
							const currentUrl = '/app/%23' + this.router.url;
							window.location.href = `${passwordVerificationRedirect}&${REDIRECT_PARAM_KEY}=${currentUrl}`;
						} else {
							this.isLoading = false;
						}
					}
				}
			);

		this.store.dispatch(new GetBankAccounts());
	}
}
