import { Injectable, Provider } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable, catchError, from, switchMap } from 'rxjs';
import { GoogleAnalyticsService } from '@app/services/google-analytics/google-analytics.service';

@Injectable()
export class AnalyticsInterceptor implements HttpInterceptor {
	constructor(private googleAnalyticsService: GoogleAnalyticsService) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return from(this.googleAnalyticsService.getClientID()).pipe(
			switchMap((clientID) => {
				const requestWithToken = request.clone({
					headers: request.headers
						.set('q-ga4ClientId', clientID || ''),
				});
				return next.handle(requestWithToken);
			}),
			catchError(() => {
				return next.handle(request);
			})
		);
	}
}

export const AnalyticsInterceptorProvider: Provider = {
	provide: HTTP_INTERCEPTORS,
	useClass: AnalyticsInterceptor,
	multi: true,
};
