import { AppAction, FailedAction } from '@app/models/action.model';
import { SchemeID } from '@app/models/dataSchema.model';
import { EventAtTheDoor } from '@app/models/event.model';

export enum EventAtTheDoorConstants {
	GET_EVENT_ATTHEDOOR = 'GET_EVENT_ATTHEDOOR',
	GET_EVENT_ATTHEDOOR_SUCCESS = 'GET_EVENT_ATTHEDOOR_SUCCESS',
	GET_EVENT_ATTHEDOOR_FAILED = 'GET_EVENT_ATTHEDOOR_FAILED',
}

export class GetEventAtTheDoor extends AppAction <{
	eventId: SchemeID;
	scheduleItemId: SchemeID;
}> {
	readonly type = EventAtTheDoorConstants.GET_EVENT_ATTHEDOOR;
}

export class GetEventAtTheDoorSuccess extends AppAction <{
	eventId: SchemeID;
	atTheDoor: EventAtTheDoor;
}> {
	readonly type = EventAtTheDoorConstants.GET_EVENT_ATTHEDOOR_SUCCESS;
}

export class GetEventAtTheDoorFailed extends FailedAction {
	readonly type = EventAtTheDoorConstants.GET_EVENT_ATTHEDOOR_FAILED;
}

export type Actions =
	| GetEventAtTheDoor
	| GetEventAtTheDoorSuccess
	| GetEventAtTheDoorFailed;
