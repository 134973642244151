<app-my-account-header
	[isMobile]='isMobile'
	pageName="My Tickets"
	(navigateBack)="handleNavigationBack()"
>
</app-my-account-header>
<div class="g-account-container">
	<div
		class="g-account-content"
		[ngClass]="{ 'mobile': isMobile, 'web': !isMobile }"
	>
		<app-web-content-header
			class="w-100"
			[headerContent]="headerContent"
			[longWidth]="true"
			[isMobile]="isMobile"
		></app-web-content-header>

		<div class="d-flex flex-column">
			<div class="l-description-container">
				<div
					*ngIf="hasCartIdParam"
					class="l-cancel-cart-filter l-description mb-3"
					(click)="handleRemoveCartFilter()"
				>
					<i class="fa-solid fa-circle-info"></i>
					You are currently viewing tickets from your most recent purchase, click to view all your
					tickets
				</div>
			</div>
			<app-search-bar
				id="ticket-search-bar"
				[filterOptions]="filterOptions"
				[isMobile]="isMobile"
				[numberAppliedFilters]="numberAppliedFilters"
				(searchAndFilter)="handleTicketFilterAndSearch($event)"
				(sortOption)="handleSort($event)"
				(openFilterBottomSheet)="handleBottomSheetFilter()"
				(mobileSearchTerm)="handleMobileSearchTerm($event)"
			></app-search-bar>
			<div *ngIf="!isTicketsLoading else ticketsLoading">
				<div class="l-card-container mt-4">
					<ng-container *ngIf="filteredTickets.length > 0; else noTickets">
						<a
							*ngFor="let ticket of filteredTickets"
							class="g-flat-link l-card"
							[routerLink]="[handleRouterLink(ticket.id)]"
						>
							<app-ticket-card
								[id]="ticket.reference"
								[productStatus]="ticket.productStatus"
								[productName]="ticket.productName"
								[lineOne]="ticket.dateFrom"
								[lineTwo]="ticket.dateTo"
								[venue]="ticket.venue"
								[bookingAmount]="ticket.bookingAmount"
								[eventImage]="ticket.eventImage"
								[reference]="ticket.reference"
								[currencySymbol]="ticket.currencySymbol"
								[isTicket]="true"
								type="ticket"
								[isMobile]="isMobile"
								class="l-card-content"
							></app-ticket-card>
						</a>
					</ng-container>
				</div>
			</div>
			<ng-template #ticketsLoading>
				<div class="l-loading">
					<mat-spinner></mat-spinner>
				</div>
			</ng-template>
			<ng-template #noTickets>
				<div
					class="l-empty-state"
					*ngIf="checkNoResults(); else noUserTickets"
				>
					<h3 class="mb-3">Nothing to see here...</h3>
					<div class="mb-3">Nothing matches your search or filters</div>
				</div>
				<ng-template #noUserTickets>
					<div class="l-empty-state">
						<h3 class="mb-3">No Tickets</h3>
						<div class="mb-3 text-center px-2">You currently have no tickets. Want to change
							that?</div>
						<button
							class="g-primary-button"
							(click)="handleEventNavigation()"
						>Browse
							Events</button>
					</div>
				</ng-template>
			</ng-template>
		</div>
	</div>
</div>