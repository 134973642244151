import * as eventActions from '@app/store/actions/event/event.actions';
import { EventActionsConstants } from '@app/store/actions/event/event.actions.constants';
import { initialState } from './initialState';
import { EventsState } from '@app/models/store.model';

export const venues = (
	state = initialState.venues,
	action: eventActions.Actions
): EventsState['venues'] => {
	switch (action.type) {
		case EventActionsConstants.GET_VENUES_SUCCESS:
			return action.payload.venues;
		default:
			return state;
	}
};
