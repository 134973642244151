<div class="l-container">
	<h3 class="mb-3"> {{data.isNew ? "Add" : "Update"}} Bank Account</h3>
	<div class="mb-4">
		<p>
			Please enter your bank details below.
		</p>
	</div>
	<form
		#accountDetailsForm="ngForm"
		class="g-form-container my-5"
	>
		<div class="input-group w-100 mb-3">
			<label class="g-form-label">Select Bank<span class="g-orange">*</span></label>
			<mat-select
				id='bank-select'
				class="form-control w-100"
				[(ngModel)]="bankDetails.bank"
				name="bank"
				#bank="ngModel"
				placeholder="Please Select"
				[disabled]="!data.isNew"
				(selectionChange)="handleBankSelection()"
				required
				#singleSelect
			>
				<mat-option>
					<ngx-mat-select-search
						ngModel
						(ngModelChange)="handleBanksFilter($event)"
						name="bankSearch"
						#bankSearch="ngModel"
						placeholderLabel="Search for your bank..."
						noEntriesFoundLabel="No matching bank found"
					>
					</ngx-mat-select-search>
				</mat-option>
				<mat-optgroup
					*ngFor="let group of filteredBankGroups"
					[label]="group.iso"
				>
					<mat-option
						*ngFor="let bank of group.banks"
						[value]="bank"
					>
						{{ bank.name }}
					</mat-option>
				</mat-optgroup>
			</mat-select>
		</div>

		<div
			class="input-group w-100 mb-3"
			*ngIf="branches?.length"
		>
			<label class="g-form-label">Select Branch<span class="g-orange">*</span></label>
			<mat-select
				id='branch-select'
				class="form-control w-100"
				#branch="ngModel"
				name="branch"
				placeholder="Please Select"
				[(ngModel)]="bankDetails.branch"
				required
			>
				<mat-option>
					<ngx-mat-select-search
						ngModel
						(ngModelChange)="handleBranchesFilter($event)"
						name="branchSearch"
						#branchSearch="ngModel"
						placeholderLabel="Search for your branch..."
						noEntriesFoundLabel="No matching branch found"
					>
					</ngx-mat-select-search>
				</mat-option>
				<mat-option
					*ngFor="let branch of filteredBranches"
					[value]="branch"
				>
					{{ branch.branchName }}
				</mat-option>
			</mat-select>
		</div>
		<div
			*ngIf="!data.isNew; else addAccount"
			class="w-100"
		>
			<div class="g-double-input-container w-100 mb-3 l-double-input">
				<div class="input-group l-input">
					<label class="g-form-label">Account Holder Name<span class="g-orange">*</span></label>
					<input
						id='account-name-input'
						#accountName="ngModel"
						[(ngModel)]="bankDetails.accountHolder"
						type="text"
						name="accountName"
						class="form-control w-100"
						placeholder="Name Surname"
						required
					/>
				</div>
				<div class="input-group l-input">
					<label class="g-form-label">Account Number<span class="g-orange">*</span></label>
					<input
						id='account-number-input'
						#accountNumber="ngModel"
						[(ngModel)]="bankDetails.accountNumber"
						type="number"
						name="accountNumber"
						class="form-control w-100"
						placeholder="123456789"
						maxlength="11"
						minlength="5"
						pattern="\d{5,11}"
						mask="00000000000"
						required
					/>
					<div
						class="g-error-input"
						*ngIf="accountNumber.invalid"
					>
						<div *ngIf="accountNumber.errors?.required">Account is required.</div>
						<div *ngIf="accountNumber.errors?.pattern">Only 5 to 11 digits is allowed.</div>
					</div>
				</div>
			</div>
			<div
				*ngIf="bankDetails.bank && !bankDetails.bank.universalCode"
				class="input-group l-input l-branch-code"
			>
				<label class="g-form-label">Branch Code<span class="g-orange">*</span></label>
				<input
					type="text"
					id="branch-code-input"
					[(ngModel)]="bankDetails.branch.branchCode"
					name="branchCode"
					class="form-control w-100"
					#branchCode="ngModel"
					required
				/>
				<div
					class="g-error-input"
					*ngIf="branchCode.invalid && branchCode.touched"
				>
					<div *ngIf="branchCode.errors?.required">Branch code is required.</div>
				</div>
			</div>
		</div>
		<ng-template
			#addAccount
			class="w-100"
		>
			<div class="input-group w-100 mb-3">
				<label class="g-form-label">Account Holder Name<span class="g-orange">*</span></label>
				<input
					id='new-account-name-input'
					#accountName="ngModel"
					[(ngModel)]="bankDetails.accountHolder"
					type="text"
					name="accountName"
					class="form-control w-100"
					placeholder="Name Surname"
					required
				/>
				<div
					class="g-error-input"
					*ngIf="accountName.invalid && accountName.touched"
				>
					<div *ngIf="accountValue.errors?.required">Account is required.</div>
				</div>
			</div>
			<div class="g-double-input-container w-100 mb-3 l-double-input">
				<div class="input-group l-input">
					<label class="g-form-label">Account Number<span class="g-orange">*</span></label>
					<input
						id='new-account-number-input'
						#accountValue="ngModel"
						[(ngModel)]="bankDetails.accountNumber"
						type="number"
						name="accountNumber"
						class="form-control w-100"
						placeholder="12345678912"
						maxlength="11"
						minlength="5"
						pattern="\d{5,11}"
						mask="00000000000"
						appValueMatch
						[confirmValue]="confirmAccountValue"
						required
						(ngModelChange)="validateAccountNumbers(accountDetailsForm)"
					/>
					<div
						class="g-error-input"
						*ngIf="accountValue.invalid && accountValue.touched"
					>
						<div *ngIf="accountValue.errors?.confirmValue">Account numbers do not match.</div>
						<div *ngIf="accountValue.errors?.required">Account is required.</div>
						<div *ngIf="accountValue.errors?.pattern">Only 5 to 11 digits is allowed.</div>
					</div>
				</div>
				<div class="input-group l-input">
					<label class="g-form-label">Confirm Account Number<span class="g-orange">*</span></label>
					<input
						id='new-account-confirm-input'
						[(ngModel)]="confirmAccountNumber"
						type="number"
						name="confirmAccount"
						class="form-control w-100"
						placeholder="12345678912"
						maxlength="11"
						minlength="5"
						pattern="\d{5,11}"
						mask="00000000000"
						required
						#confirmAccountValue="ngModel"
						appValueMatch
						[confirmValue]="accountValue"
						(ngModelChange)="validateAccountNumbers(accountDetailsForm)"
					/>
					<div
						class="g-error-input"
						*ngIf="confirmAccountValue.invalid && confirmAccountValue.touched"
					>
						<div *ngIf="confirmAccountValue.errors?.confirmValue">Account numbers do not match.</div>
						<div *ngIf="confirmAccountValue.errors?.required">Confirmation is required.</div>
						<div *ngIf="confirmAccountValue.errors?.pattern">Only 5 to 11 digits is allowed.</div>
					</div>
				</div>

				<div
					*ngIf="bankDetails.bank && !bankDetails.bank.universalCode"
					class="input-group l-input l-branch-code"
				>
					<label class="g-form-label">Branch Code<span class="g-orange">*</span></label>
					<input
						type="text"
						id="branch-code-input"
						[(ngModel)]="bankDetails.branch.branchCode"
						name="branchCode"
						placeholder="12345678912"
						class="form-control w-100"
						#branchCode="ngModel"
						required
					/>
					<div
						class="g-error-input"
						*ngIf="branchCode.invalid && branchCode.touched"
					>
						<div *ngIf="branchCode.errors?.required">Branch code is required.</div>
					</div>
				</div>
			</div>

		</ng-template>
		<mat-checkbox
			id='default-checkbox'
			class="example-margin me-auto"
			[checked]='bankDetails.isDefault'
			[(ngModel)]='bankDetails.isDefault'
			name="defaultCheckbox"
		>Default Account</mat-checkbox>
	</form>

	<div class="l-buttons-container">
		<button
			class="g-secondary-button border-0"
			(click)="handleCancel()"
		>CANCEL</button>
		<button
			class="g-primary-button"
			(click)="handleSave()"
			[class.disabled]="!isSubmitAllowed(accountDetailsForm)"
			[disabled]="!isSubmitAllowed(accountDetailsForm)"
		>SAVE</button>
	</div>
</div>