import { Component, Input } from '@angular/core';
import { SocialSharingService } from '@app/services/social-sharing/socail-sharing.service';

@Component({
	selector: 'app-twitter-sharing-btn',
	templateUrl: './twitter-sharing.component.html',
})

export class TwitterSharingButtonComponent {
	@Input() title: string;
	@Input() link: string;
	constructor(
		private socialSharing: SocialSharingService
	) { }

	getSharingLink() {
		return this.socialSharing.getTwitterSharingURL(this.title, this.link);
	}
}
