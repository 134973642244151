import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { formErrorMessage } from '@app/utils/consts';
@Component({
	selector: 'app-small-text-field',
	templateUrl: './small-text-field.component.html',
	styleUrls: ['./small-text-field.component.sass'],

})
export class SmallTextFieldComponent implements OnInit {
	@Input() isMobile: boolean;
	@Input() inputFormControl: FormControl;
	@Input() label: string;

	isRequired = false;

	errorMessages = formErrorMessage;
	get errorKeys(): string[] {
		return Object.keys(formErrorMessage);
	}

	ngOnInit(): void {
		this.isRequired = this.inputFormControl.hasValidator(Validators.required);
	}
}
