import { NgModule } from '@angular/core';
import { FacebookIntegrationComponent } from './facebook-integration.component';
import { SharedModule } from '@app/shared/shared.module';
import { FacebookIntegrationService } from './facebook-integration.service';
import { EventSharedModule } from '@app/features/event-shared/event-shared.module';

@NgModule({
	imports: [
		EventSharedModule,
		SharedModule,
	],
	declarations: [
		FacebookIntegrationComponent,
	],
	providers: [
		FacebookIntegrationService,
	],
	exports: [
		FacebookIntegrationComponent,
	],
})
export class FacebookIntegrationModule {}
