<div
	*ngIf="!isLoading else showLoader"
	class="d-flex flex-column"
	[ngClass]="{'mt-1': isMobile, 'ps-2': !isMobile}"
>
	<div *ngIf='isConnected; else unlinkedContent'>
		<div
			class="d-flex flex-column my-4"
			[ngClass]="{'mx-3': !isMobile}"
		>
			<div class='l-header-container'>
				<img
					*ngIf='!isMobile'
					class="l-icon"
					[src]="unlinkCard.iconPath"
				/>
				<h3 class='mb-0'>Mailchimp - <span class='g-green'>Connected <mat-icon
							class='l-link-icon ms-2'>done</mat-icon></span></h3>
			</div>
			<p class="l-description mb-3">
				Your Mailchimp account is linked to this organiser profile and applies to all your events.<br />
				For event specific Mailchimp control, please go to that particular event and navigate to
				<b>Integrations &#8594; Mailchimp</b> which can be found under the <b>Settings</b> menu.<br />
			</p>
			<p class="mt-2">To Unlink your Mailchimp account, please click the button below.</p>
		</div>
		<div
			class="l-mailchimp-content mb-5"
			[ngClass]="{'mx-3': !isMobile}"
		>
			<div
				class="l-card"
				(click)="handleUnlinkMailChimp()"
			>
				<div class='l-mail-card'>
					<img
						class="l-icon"
						[src]="unlinkCard.iconPath"
					/>
					<div>{{unlinkCard.title}}</div>
				</div>
			</div>
		</div>
	</div>
	<ng-template #unlinkedContent>
		<div
			class="d-flex flex-column my-4"
			[ngClass]="{'mx-3': !isMobile}"
		>
			<h3 class="mb-3">Mailchimp</h3>
			<p class="l-description mb-3">
				Connect your Mailchimp account and automatically synchronize all your guests to your Mailchimp
				lists. After clicking the button below you will be redirected to Mailchimp where you will be
				asked to log in with your Mailchimp credentials. You will then be sent back to Quicket and this
				event's organiser profile and your Mailchimp account will be linked.
			</p>
		</div>
		<div
			class="l-mailchimp-content"
			[ngClass]="{'mx-3': !isMobile}"
		>
			<div
				*ngFor="let card of mailChimpCards | keyvalue: handleDefaultOrder"
				class="l-card"
				(click)="handleMailNavigate(card.key)"
			>
				<div
					class='l-mail-card'
					[ngClass]="{'l-disabled-card': isCardDisabled(card.key)}"
				>
					<img
						class="l-icon"
						[src]="card.value.iconPath"
					/>
					<div>{{card.value.title}}</div>
				</div>

			</div>
		</div>
		<div
			class="d-flex flex-column mt-5"
			[ngClass]="{'mx-3': !isMobile}"
		>
			<h3 class="mb-3">Have Questions?</h3>
			<p class="l-description mb-3">
				What is Mailchimp and how could it help me?
			</p>
		</div>
		<div
			class="l-mailchimp-content mb-5"
			[ngClass]="{'mx-3': !isMobile}"
		>
			<a
				class="l-card g-flat-link"
				[href]="helpCard.routeTo()"
				target="_blank"
			>
				<div class='l-mail-card'>
					<img
						class="l-icon"
						[src]="helpCard.iconPath"
					/>
					<div>{{helpCard.title}}</div>
				</div>

			</a>
		</div>
	</ng-template>
</div>
<ng-template #showLoader>
	<div class="l-loading">
		<mat-spinner></mat-spinner>
	</div>
</ng-template>