import { ErrorCodes } from './errorCode.model';
import { Observable } from 'rxjs';

export interface ServiceRequest {
	url: string;
	type: RequestType;
	payload?: { [key: string]: any };
	headers?: { [key: string]: any };
}

export enum RequestType {
	GET = 'get',
	POST = 'post',
	PUT = 'put',
	PATCH = 'patch',
	DELETE = 'delete',
}

export enum HttpRequestMethod {
	GET = 'GET',
	POST = 'POST',
	DELETE = 'DELETE',
	PATCH = 'PATCH',
	PUT = 'PUT',
}

export interface ResponseScheme<T> {
	readonly isSuccess: boolean;
	readonly payload: T;
	readonly errors: {
		message: string;
		errorCode: ErrorCodes;
	}[];
}

export type AsyncResponse<T> = Observable<ResponseScheme<T>>;

export interface SingleItemResult<T> extends APIBaseResult {
	readonly result: T;
}

export interface ListResult<T> extends APIBaseResult {
	readonly results: T[];
	readonly pageSize: number;
	readonly pages: number;
	readonly records: number;
}

export interface APIBaseResult {
	readonly message: string;
	readonly statusCode: number;
	readonly extras?: { [key: string]: string };
}

export type AsyncListResultResponse<T> = Observable<ListResult<T>>;
export type AsyncSingleItemResultResponse<T> = Observable<SingleItemResult<T>>;


export interface ResponseSchemeError {
	errorCode: ErrorCodes;
	message: string;
}

export interface ImageUploadError {
	body: string;
	code: number;
}

export interface ImageUploadErrorBody {
	errors: ImageUploadErrorBodyError[];
}

export interface ImageUploadErrorBodyError {
	message: string;
	errorCode: string;
}
