<div [ngClass]="{ 'input-group-mobile-large': isMobile, 'input-group-web-large': !isMobile }">
	<label class="g-form-label">
	  <span [innerHTML]="label"></span>
	  <span *ngIf='isRequired' class="g-orange"> *</span>
	</label>
	<input
	  [id]="id"
	  *ngIf="!isDisabled"
	  [formControl]="displayControl"
	  class="form-control l-input"
	  type="text"
	  autocomplete="off"
	/>
	<input
	  *ngIf="isDisabled"
	  [(ngModel)]="addressString"
	  class="form-control"
	  type="text"
	  disabled
	  autocomplete="off"
	/>
	<div *ngIf="displayControl.invalid">
	  <div class="g-error-input">
		<div *ngIf="displayControl.hasError('required')">This field is required.</div>
	  </div>
	</div>
  </div>
  