import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { regexValidator, requiredValidator } from '@app/shared/form-field/form-validators';
import { convertToIdFormat } from '@app/utils/common-helpers';

@Component({
	selector: 'app-confirmation-modal',
	templateUrl: './confirmation-modal.component.html',
	styleUrls: ['./confirmation-modal.component.sass'],
})
export class ConfirmationModalComponent {
	confirmationTextControl: FormControl;

	constructor(
		public dialogRef: MatDialogRef<ConfirmationModalComponent>,
		public formBuilder: FormBuilder,
		@Inject(MAT_DIALOG_DATA)
		public data: {
			title: string;
			text: string;
			buttonText: string;
			centerText: boolean;
			isMobile: boolean;
			isDisabled: boolean;
			cancelText: string;
			validationPattern: string;
		}
	) {}

	ngOnInit() {
		if (this.data.validationPattern) {
			this.createConfirmationTextControl();
		}
	}

	createConfirmationTextControl() {
		this.confirmationTextControl = new FormControl(undefined, [
			requiredValidator(),
			regexValidator(this.data.validationPattern),
		]);
	}

	handleDelete() {
		this.dialogRef.close(true);
	}

	handleCancel() {
		this.dialogRef.close(false);
	}

	formatId(itemTitle: string, prefix: string): string {
		return convertToIdFormat(itemTitle, prefix);
	}
}
