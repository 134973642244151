import { Injectable } from '@angular/core';
import * as moment from 'moment';
import 'moment-timezone';
import { Timezone } from '@app/models/event.model';

@Injectable()
export class TimezoneService {
	constructor() {}

	createTimezones(): Timezone[] {
		const timezonesList = moment.tz.names();
		const formattedList = [];

		for (const key in timezonesList) {
			if (timezonesList.hasOwnProperty(key)) {
				const timezone = {
					name: timezonesList[key],
					offset: moment.tz(timezonesList[key]).format('Z'),
				};

				formattedList.push({
					...timezone,
					title: `GMT${timezone.offset} ${timezone.name.split('_').join(' ')}`,
				});
			}
		}

		return formattedList;
	}

	getTimezoneByName(name: string) {
		const timeZones = this.createTimezones();
		return timeZones.find(el => el.name === name);
	}
}
