<div class="l-container">
	<h3 class="mb-3 text-center" *ngIf="data.allowPublicResale && data.allowPrivateResale; else oneResaleType">
		Public or Private
	</h3>
	<ng-template #oneResaleType>
		<h3 class="mb-3 text-center">Confirm your {{data.allowPublicResale ? 'public' : 'private'}} resale</h3>
	</ng-template>
	<app-additional-info
		[email]="email"
		[isPublic]="data.allowPublicResale && !data.allowPrivateResale"
		[isBankAccountModal]="false"
		(additionalInfo)="handleAdditionalInfo($event)"
		[allowPrivateResale]="data.allowPrivateResale"
		[allowPublicResale]="data.allowPublicResale"
		[isMobile]="true"
	></app-additional-info>
	<div class="l-buttons-container">
		<button
			id="cancel-button"
			class="g-secondary-button border-0"
			(click)="handleClose()"
		>CANCEL</button>
		<button
			id="save-button"
			class="g-primary-button"
			(click)="handleSubmit()"
			[class.disabled]="!additionalInfoIsValid"
			[disabled]="!additionalInfoIsValid"
		>LIST</button>
	</div>
</div>