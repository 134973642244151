import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { SharedModule } from '@app/shared/shared.module';
import { AngularMaterialModule } from '@app/shared/angular-material.module';
import { EventsComponent } from './pages/events/events.component';
import { SalesComponent } from './pages/sales/sales.component';
import { MarketingComponent } from './pages/marketing/marketing.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { OrganiserProfileComponent } from './pages/organiser-profile/organiser-profile.component';
import { OrganiserHubComponent } from './pages/organiser-hub/organiser-hub.component';
import { EventCardComponent } from './components/event-card/event-card.component';
import { CampaignCardComponent } from './components/campaign-card/campaign-card.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { GenerateReportsModalComponent } from './modals/generate-reports-modal/generate-reports-modal.component';
import { ProductListComponent } from './components/product-list/product-list.component';
import { NoProductsCardComponent } from './components/no-products-card/no-products-card.component';
import { ProfileCardComponent } from './components/profile-card/profile-card.component';
import { IntegrationCardComponent } from './components/integration-card/integration-card.component';
import { MailAccountsComponent } from './pages/mail-accounts/mail-accounts.component';
import { OrganiserDetailsComponent } from './pages/organiser-details/organiser-details.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddMailAccountModalComponent } from './modals/add-mail-account-modal/add-mail-account-modal.component';
import { LinkedProductsModalComponent } from './modals/linked-products-modal/linked-products-modal.component';
import { OrganiserProfileParentComponent } from './components/organiser-profile-parent/organiser-profile-parent.component';
import { MailchimpIntegrationComponent } from './pages/mailchimp-integration/mailchimp-integration.component';
import { DashboardChartComponent } from './components/dashboard-chart/dashboard-chart.component';
import { DirectDistributionComponent } from './pages/direct-distribution/direct-distribution.component';
import { TrackingScriptsComponent } from './pages/tracking-scripts/tracking-scripts.component';

@NgModule({
	declarations: [
		DashboardComponent,
		EventsComponent,
		SalesComponent,
		MarketingComponent,
		ReportsComponent,
		OrganiserProfileComponent,
		OrganiserHubComponent,
		EventCardComponent,
		CampaignCardComponent,
		GenerateReportsModalComponent,
		ProductListComponent,
		NoProductsCardComponent,
		ProfileCardComponent,
		IntegrationCardComponent,
		MailAccountsComponent,
		OrganiserDetailsComponent,
		AddMailAccountModalComponent,
		LinkedProductsModalComponent,
		OrganiserProfileParentComponent,
		MailchimpIntegrationComponent,
		DashboardChartComponent,
		DirectDistributionComponent,
		TrackingScriptsComponent,
	],
	imports: [
		CommonModule,
		SharedModule,
		AngularMaterialModule,
		NgxChartsModule,
		ReactiveFormsModule,
		FormsModule,
	],
})
export class OrganiserHubModule { }
