import { Injectable } from '@angular/core';
import { FacebookService } from '../facebook/facebook.service';

@Injectable()
export class SocialSharingService {
	constructor(
		private facebookService: FacebookService
	) {  }

	shareToFacebook = (link: string) => {
		this.facebookService.share(link);
	};

	getTwitterSharingURL = (title: string, link: string) => (
		`https://twitter.com/intent/tweet?text=Come join me at ${title}&url=${link}`
	);

	getLinkedinSharingURL = (link: string) => (
		`https://www.linkedin.com/shareArticle?url=${link}`
	);

	getPinterestSharingURL = (link: string) => (
		`https://www.pinterest.com/pin/create/button/?url=${link}`
	);

	getMailSharingURL = (title: string, link: string) => (
		`mailto:?body=Come join me at ${title}, you can buy tickets here ${link}&subject=${title}`
	);

	getWhatsappSharingURL = (link: string, eventName: string) => (
		`https://api.whatsapp.com/send?text=Come join me at ${eventName}, you can buy tickets here ${link}`
	);
}
