import { TicketsActionsConstant } from '@app/store/actions/tickets/tickets.constants';
import { EventActionsConstants } from '@app/store/actions/event/event.actions.constants';
import * as eventActions from '@app/store/actions/event/event.actions';
import * as ticketActions from '@app/store/actions/tickets/tickets.actions';
import { initialState } from './initialState';
import { EventsState } from '@app/models/store.model';

export const tickets = (
	state = initialState.tickets,
	action: ticketActions.Actions | eventActions.Actions
): EventsState['tickets'] => {
	switch (action.type) {
		case TicketsActionsConstant.GET_EVENT_TICKETS_SUCCESS:
		case TicketsActionsConstant.STOP_TICKET_SALES_SUCCESS:
		case TicketsActionsConstant.MARK_TICKETS_AS_SOLD_OUT_SUCCESS:
		case EventActionsConstants.UPDATE_EVENT_SUCCESS:
		case EventActionsConstants.GET_EVENT_SUCCESS:
		case EventActionsConstants.CREATE_EVENT_SUCCESS:
			return action.payload.normalizedEntity.ticket || initialState.tickets;
		default:
			return state;
	}
};
