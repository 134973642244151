import { Injectable } from '@angular/core';

@Injectable()
export class HelpArticleService {
	htmlToElement(html) {
		const childNodes: Document = new DOMParser().parseFromString(html, 'text/html');
		return this.filterArticleNodes(childNodes);
	}

	private removeAllFromList(nodes: NodeListOf<Element>) {
		for (let i = 0; i < nodes.length; i++) {
			nodes[i].parentNode.removeChild(nodes[i]);
		}
	}

	filterArticleNodes(childNodes: Document): { title: string; html: string } {
		/* eslint-disable-next-line max-len */
		const title = childNodes.querySelectorAll('#solutionTitle').length ? childNodes.querySelectorAll('#solutionTitle')[0].innerHTML : null;


		this.removeAllFromList(childNodes.querySelectorAll('script'));
		this.removeAllFromList(childNodes.querySelectorAll('.Solusubinfo'));
		this.removeAllFromList(childNodes.querySelectorAll('.solsharediv'));
		this.removeAllFromList(childNodes.querySelectorAll('.cprelatedarticle'));

		return {
			title,
			/* eslint-disable-next-line max-len */
			html: childNodes.querySelectorAll('.cpsoludetaildiv').length ? childNodes.querySelectorAll('.cpsoludetaildiv')[0].innerHTML : null,
		};
	}
}
