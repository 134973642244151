import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventDashboardReports, EventMetadata } from '@app/models/event-metadata.model';
import { StoreService } from '@app/services/store/store.service';
import { InternalURLCreator } from '@app/services/url/url.dictionary';
import {
	DeleteScheduleEventPublic,
	GetEventDashboard,
	ScheduleEventPublic,
	SetEventListing,
	SkipEventCompletionItem,
	SubmitEventForApproval,
	SetEventShortLink,
	SetEventRedirectProductId
} from '@app/store/actions/event/event.actions';
import { Go } from '@app/store/actions/router/router.actions';
import { EVENT_ID_PARAM_KEY } from '@app/utils/consts';
import { take, takeUntil } from 'rxjs';
import * as eventSelectors from '@app/store/selectors/event.selector';
import { EventDashboard, ProductStatus } from '@app/models/event.model';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { SchemeID } from '@app/models/dataSchema.model';
import { MatDialog } from '@angular/material/dialog';
import { AddNotification } from '@app/store/actions/notification/notification.actions';
import { NotificationType } from '@app/models/notification.model';
import { Clipboard } from '@angular/cdk/clipboard';
import { EditEventLinkModalComponent } from '../../modals/edit-event-link-modal/edit-event-link-modal.component';
import { setTimezoneTo } from '@app/utils/Date';
import { GetEventDashboardReports } from '@app/store/actions/event/event.actions';
import { ScheduleItem } from '@app/models/schedule.model';
import { SendInvitesModalComponent } from '../../modals/send-invites-modal/send-invites-modal.component';
import { getCookie, setCookie } from '@app/utils/Cookie';
import { FileSaverService } from '@app/services/file-saver/file-saver.service';

@Component({
	selector: 'app-event-dashboard',
	templateUrl: './event-dashboard.component.html',
	styleUrls: ['./event-dashboard.component.sass'],
})
export class EventDashboardComponent implements OnInit {
	eventId: SchemeID;
	metadata: EventMetadata;
	dashboard: EventDashboard;
	isLoading = true;
	isReportsLoading = true;
	isUpdating = false;
	isMobile = false;
	isOwner: boolean;
	isOngoing: boolean;
	isSeated: boolean;
	scheduleItems: ScheduleItem[];
	ProductStatus = ProductStatus;
	reports: EventDashboardReports;
	currencyISO: string;
	cookieKey: string;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();
	constructor(
		private store: StoreService,
		private activatedRoute: ActivatedRoute,
		private breakpointService: BreakpointService,
		private dialog: MatDialog,
		private clipboard: Clipboard,
		private fileSaverService: FileSaverService
	) { }

	ngOnInit(): void {
		this.activatedRoute.parent.paramMap.pipe(take(1)).subscribe((params) => {
			this.eventId = +params.get(EVENT_ID_PARAM_KEY);
		});

		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});

		this.initialiseDashboard();
	}

	isFirstTimeLive() {
		this.cookieKey = `${this.eventId}.firstTimeOnLiveEvent`;
		const cookieValue = this.getFirstTimeLiveCookie();
		if (cookieValue === 1) {
			setCookie(this.cookieKey, '0', 730);
			return true;
		}
		return false;
	}

	getFirstTimeLiveCookie(): SchemeID {
		if (!getCookie(this.cookieKey)) {
			setCookie(this.cookieKey, '1', 730);
			return 0;
		};
		return +getCookie(this.cookieKey);
	};

	doSendInvitesModal() {
		if (this.dashboard.productStatus === ProductStatus.Active && this.isFirstTimeLive()) {
			this.dialog.open(SendInvitesModalComponent, {
				data: {
					isMobile: this.isMobile,
				},
				panelClass: 'g-standard-dialog',
			})
				.afterClosed().subscribe((value) => {
					if (value) {
						this.handleSendInvitesNavigation();
					}
				});
		}
	}

	toggleEventVisibility() {
		if (!this.dashboard.isPublic && this.dashboard.lockedOnPrivate) {
			this.store.dispatch(
				new AddNotification(
					{
						id: 'toggle-event-visibility-failed',
						title: 'Your event is locked on private.',
						actionType: null,
						action: null,
						type: NotificationType.INFO,
					}
				));
		} else {
			this.store.dispatch(new SetEventListing({
				id: this.eventId,
				isPublic: !this.dashboard.isPublic,
			}));
		}
	}

	initialiseDashboard() {
		this.store.select(eventSelectors.eventDetailsForDashboard()).pipe(takeUntil(this.destroyed$)).subscribe(data => {
			if (data) {
				this.metadata = data.metadata;
				this.isOngoing = this.metadata.isOngoing;
				this.isSeated = this.metadata.isSeated;
				this.scheduleItems = this.metadata.scheduleItems;
				this.dashboard = data.dashboard;
				this.isLoading = false;
				this.currencyISO = this.metadata.currencyISO;
				this.doSendInvitesModal();
			}
		});

		this.store.select(eventSelectors.eventDashboardReports()).pipe(takeUntil(this.destroyed$)).subscribe(reports => {
			if (reports) {
				this.reports = reports;
				this.isReportsLoading = false;
			}
		});

		this.store.dispatch(new GetEventDashboard({ id: this.eventId }));

		this.store.dispatch(new GetEventDashboardReports({
			id: this.eventId,
			scheduleItemId: 0,
		}));
	}

	handleSendInvitesNavigation() {
		this.store.dispatch(new Go({ path: [InternalURLCreator.sendInvites(this.eventId)]}));
	}

	skipCompletionItem(itemId: number) {
		this.store.dispatch(new SkipEventCompletionItem({ id: this.eventId, itemId }));
	}

	submitEventForApproval() {
		this.store.dispatch(new SubmitEventForApproval({
			id: this.eventId,
		}));
	}

	handleEventActionCopy() {
		this.store.dispatch(new Go({ path: [InternalURLCreator.copyEvent(this.eventId)] }));
	}

	handleEventActionPostpone() {
		this.store.dispatch(new Go({ path: [InternalURLCreator.postpone(this.eventId)] }));
	}

	handleEventActionCancel() {
		this.store.dispatch(new Go({ path: [InternalURLCreator.cancel(this.eventId)] }));
	}

	handleEventActionCopyLink() {
		this.clipboard.copy('https://qkt.io/' + this.dashboard.shortLink);
		this.store.dispatch(new AddNotification({
			id: 'copy-short-link-to-clipboard',
			actionType: null,
			action: null,
			type: NotificationType.SUCCESS,
			title: 'Copied to clipboard',
			delay: 1000,
		}));
	}

	handleEventActionEditLink() {
		this.dialog.open(EditEventLinkModalComponent, {
			data: {
				isMobile: this.isMobile,
				shortLink: this.dashboard.shortLink,
			},
			panelClass: 'g-standard-dialog',
		})
			.afterClosed().subscribe((link) => {
				if (link) {
					this.store.dispatch(new SetEventShortLink({ id: this.eventId, link: link }));
				}
			});
	}

	handleEventQrCodeDownload(){
		this.fileSaverService.save({
			url: `/api/product/${this.eventId}/dashboard/qrcode`,
			fileName: `${this.eventId}-qrcode.png`,
		});
	}

	toggleSheduleToGoPublic($event: Date) {
		if ($event) {
			this.store.dispatch(new ScheduleEventPublic({
				id: this.eventId,
				scheduledToGoPublicOn: new Date(setTimezoneTo($event, this.dashboard.ianaTimezone)),
			}));
		} else {
			this.store.dispatch(new DeleteScheduleEventPublic({
				id: this.eventId,
			}));
		}
	}

	redirectFinishedEvent($event: number) {
		this.store.dispatch(new SetEventRedirectProductId({
			id: this.eventId,
			redirectProductId: $event,
		}));
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
