<div class="l-container">
	<div class="l-info" [class.l-info-mobile]='isMobile'>
		<div class="l-info-content">
			<p class='mb-1'>{{infoTitleOne}}</p>
			<h3 class='l-value m-0' [ngClass]="{'mobile' : isMobile}">
				<span *ngIf="!showCurrencySymbol; else showCurrency">
					{{infoValueOne || "--"}}
				</span>
				<ng-template #showCurrency>
					R {{ infoValueOne ? (+infoValueOne | number : '.2-2') : '--' }}
				</ng-template>
			</h3>
		</div>
		<div class="l-info-content">
			<p class='mb-1'>{{infoTitleTwo}}</p>
			<p class='l-value m-0' [ngClass]="{'mobile' : isMobile}">{{infoValueTwo || "--"}}</p>
		</div>
	</div>
	<div class="l-button" *ngIf="!isMobile">
		<button class="g-primary-button" (click)="handleButton()" [class.disabled]="buttonDisabled"
			[disabled]="buttonDisabled">CHOOSE BANK ACCOUNT</button>
	</div>
</div>
