export enum OrganiserActionsConstants {
	GET_SALES_OVERVIEW = 'GET_SALES_OVERVIEW',
	GET_SALES_OVERVIEW_SUCCESS = 'GET_SALES_OVERVIEW_SUCCESS',
	GET_SALES_OVERVIEW_FAILED = 'GET_SALES_OVERVIEW_FAILED',

	GET_CURRENT_CAMPAIGNS = 'GET_CURRENT_CAMPAIGNS',
	GET_CURRENT_CAMPAIGNS_SUCCESS = 'GET_CURRENT_CAMPAIGNS_SUCCESS',
	GET_CURRENT_CAMPAIGNS_FAILED = 'GET_CURRENT_CAMPAIGNS_FAILED',

	GET_FILTERED_PRODUCTS = 'GET_FILTERED_PRODUCTS',
	GET_FILTERED_PRODUCTS_SUCCESS = 'GET_FILTERED_PRODUCTS_SUCCESS',
	GET_FILTERED_PRODUCTS_FAILED = 'GET_FILTERED_PRODUCTS_FAILED',

	GET_ORGANISER_NAMES = 'GET_ORGANISER_NAMES',
	GET_ORGANISER_NAMES_SUCCESS = 'GET_ORGANISER_NAMES_SUCCESS',
	GET_ORGANISER_NAMES_FAILED = 'GET_ORGANISER_NAMES_FAILED',

	GET_MAIL_ACCOUNT_BY_USER_ID = 'GET_MAIL_ACCOUNT_BY_USER_ID',
	GET_MAIL_ACCOUNT_BY_USER_ID_SUCCESS = 'GET_MAIL_ACCOUNT_BY_USER_ID_SUCCESS',
	GET_MAIL_ACCOUNT_BY_USER_ID_FAILED = 'GET_MAIL_ACCOUNT_BY_USER_ID_FAILED',

	ADD_MAIL_ACCOUNT = 'ADD_MAIL_ACCOUNT',
	ADD_MAIL_ACCOUNT_SUCCESS = 'ADD_MAIL_ACCOUNT_SUCCESS',
	ADD_MAIL_ACCOUNT_FAILED = 'ADD_MAIL_ACCOUNT_FAILED',

	DELETE_MAIL_ACCOUNT = 'DELETE_MAIL_ACCOUNT',
	DELETE_MAIL_ACCOUNT_SUCCESS = 'DELETE_MAIL_ACCOUNT_SUCCESS',
	DELETE_MAIL_ACCOUNT_FAILED = 'DELETE_MAIL_ACCOUNT_FAILED',

	GET_MAILCHIMP_CONNECTION = 'GET_MAILCHIMP_CONNECTION',
	GET_MAILCHIMP_CONNECTION_SUCCESS = 'GET_MAILCHIMP_CONNECTION_SUCCESS',
	GET_MAILCHIMP_CONNECTION_FAILED = 'GET_MAILCHIMP_CONNECTION_FAILED',

	POST_UNLINK_MAILCHIMP = 'POST_UNLINK_MAILCHIMP',
	POST_UNLINK_MAILCHIMP_SUCCESS = 'POST_UNLINK_MAILCHIMP_SUCCESS',
	POST_UNLINK_MAILCHIMP_FAILED = 'POST_UNLINK_MAILCHIMP_FAILED',

	GET_DASHBOARD_EVENTS = 'GET_DASHBOARD_EVENTS',
	GET_DASHBOARD_EVENTS_SUCCESS = 'GET_DASHBOARD_EVENTS_SUCCESS',
	GET_DASHBOARD_EVENTS_FAILED = 'GET_DASHBOARD_EVENTS_FAILED',

	GET_USER_REPORTS_INFO = 'GET_USER_REPORTS_INFO',
	GET_USER_REPORTS_INFO_SUCCESS = 'GET_USER_REPORTS_INFO_SUCCESS',
	GET_USER_REPORTS_INFO_FAILED = 'GET_USER_REPORTS_INFO_FAILED',

	DOWNLOAD_USER_REPORTS = 'DOWNLOAD_USER_REPORTS',
	DOWNLOAD_USER_REPORTS_SUCCESS = 'DOWNLOAD_USER_REPORTS_SUCCESS',
	DOWNLOAD_USER_REPORTS_FAILED = 'DOWNLOAD_USER_REPORTS_FAILED',
};
