import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { BankAccountFormControl, Guest, Order, TicketInformation, TicketTypeForm } from '@app/models/order.model';
import { maxLength, minLength, requiredValidator } from '@app/shared/form-field/form-validators';

@Injectable()
export class OrderService {
	constructor(private fb: FormBuilder) {}

	createGuestForm(guest: Guest): FormGroup<TicketTypeForm> {
		const ticketHolder = this.populateTicketHolder(guest.ticketInformation);

		return this.fb.group<TicketTypeForm>({
			ticketId: new FormControl(guest.ticketId),
			barcode: new FormControl(guest.barcode),
			ticketType: new FormControl(guest.ticketType),
			ticketTypeId: new FormControl(guest.ticketTypeId),
			oldTicketType: new FormControl(guest.ticketType),
			newTicketTypeId: new FormControl(guest.ticketTypeId),
			ticketHolder: new FormControl(ticketHolder),
			amount: new FormControl(this.convertToNumber(guest.ticketInformation.pricePaid)),
			refundAmount: new FormControl(guest.price),
			isValid: new FormControl(guest.valid),
			frozen: new FormControl(guest.frozen),
		});
	}

	populateTicketHolder(ticketInfo: TicketInformation): string {
		let ticketHolder = '-';
		if (ticketInfo.email) {
			return ticketInfo.email;
		}
		if (ticketInfo.firstName) {
			ticketHolder = ticketInfo.firstName;
			if (ticketInfo.lastName) {
				ticketHolder = ticketHolder.concat(` ${ticketInfo.lastName}`);
			}
			return ticketHolder;
		}
		return ticketHolder;
	}

	createGuestForms(order: Order): FormGroup<TicketTypeForm>[] {
		return order.guests.map((guest) => this.createGuestForm(guest));
	}

	createBankRefundForm(): FormGroup<BankAccountFormControl> {
		return this.fb.group({
			bankName: new FormControl(''),
			branchCode: new FormControl('', [requiredValidator()]),
			accountHolder: new FormControl('', [requiredValidator()]),
			accountNumber: new FormControl('', [requiredValidator(), maxLength(11), minLength(5)]),
			accountType: new FormControl('CHEQUE'),
		});
	}

	convertToNumber(value: string): number {
		const normalizedValue = value.replace(',', '.');
		return parseFloat(normalizedValue);
	}
}
