<div class="l-table-container">
	<table
		mat-table
		[dataSource]="dataSource"
		multiTemplateDataRows
		class='l-table expansion'
		cdkDropList
		(cdkDropListDropped)="handleDrag($event)"
		[cdkDropListDisabled]="dragDisabled"
		#tabContentTable
	>
		<ng-container matColumnDef="sorting">
			<th
				mat-header-cell
				*matHeaderCellDef
				class="l-sorting-column"
			></th>
			<td
				mat-cell
				*matCellDef="let mixedContentContent"
				class="l-sorting-column"
				[ngClass]='{
					"expansion-parent": expandedDetailElement !== mixedContentContent.value.contentUid,
					"current-expansion-parent": expandedDetailElement === mixedContentContent.value.contentUid
				}'
			>
				<img
					src="./assets/icons/dots-nine.svg"
					alt="Drag Icon"
					class="l-info-icon g-move"
					(mousedown)="dragDisabled = false;"
					(touchstart)="dragDisabled = false;"
				>
			</td>
		</ng-container>
		<ng-container matColumnDef="isExpanded">
			<th
				mat-header-cell
				*matHeaderCellDef
				class="l-expanded-column"
			></th>
			<td
				mat-cell
				*matCellDef="let mixedContentContent"
				class="l-expanded-column"
				[ngClass]='{
					"expansion-parent": expandedDetailElement !== mixedContentContent.value.contentUid,
					"current-expansion-parent": expandedDetailElement === mixedContentContent.value.contentUid
				}'
			>
				<mat-icon
					[@rotateIcon]="expandedDetailElement !== mixedContentContent.value.contentUid ? 'default' : 'rotated'"
				>chevron_right</mat-icon>
			</td>
		</ng-container>
		<ng-container matColumnDef="name">
			<th
				mat-header-cell
				*matHeaderCellDef
			> Name </th>
			<td
				mat-cell
				*matCellDef="let mixedContentContent"
				id="mixedContent-content-name"
				[ngClass]='{
					"expansion-parent": expandedDetailElement !== mixedContentContent.value.contentUid,
					"current-expansion-parent": expandedDetailElement === mixedContentContent.value.contentUid
				}'
			>{{mixedContentContent.value.name}}</td>
		</ng-container>
		<ng-container matColumnDef="visibility">
			<th
				mat-header-cell
				*matHeaderCellDef
			> Visibility </th>
			<td
				mat-cell
				*matCellDef="let mixedContentContent"
				[ngClass]='{
					"expansion-parent": expandedDetailElement !== mixedContentContent.value.contentUid,
					"current-expansion-parent": expandedDetailElement === mixedContentContent.value.contentUid
				}'
			><mat-slide-toggle
					class="standard-toggle"
					(click)="$event.stopPropagation()"
					[formControl]="mixedContentContent.get('isVisible')"
					id="mixedContent-content-visibility"
				></mat-slide-toggle>
			</td>
		</ng-container>
		<ng-container matColumnDef="delete">
			<th
				mat-header-cell
				*matHeaderCellDef
			></th>
			<td
				mat-cell
				*matCellDef="let mixedContentContent"
				[ngClass]='{
					"expansion-parent": expandedDetailElement !== mixedContentContent.value.contentUid,
					"current-expansion-parent": expandedDetailElement === mixedContentContent.value.contentUid
				}'
			>
				<mat-icon
					(click)="handleDeleteTabContentType(mixedContentContent)"
					id="mixedContent-content-delete"
				>delete_outline</mat-icon>
			</td>
		</ng-container>
		<ng-container matColumnDef="expandedDetail">
			<td
				mat-cell
				*matCellDef="let mixedContentContent"
				[attr.colspan]="displayColumns.length"
				class="l-expanded-container"
			>
				<div
					[@detailExpand]="mixedContentContent.value.contentUid == expandedDetailElement ? 'expanded' : 'collapsed'">
					<app-mat-text-control-field
						[label]="'Name'"
						[placeholder]="'Name'"
						[control]="mixedContentContent.get('name')"
						[outerClass]="'pb-3'"
						id="mixedContent-content-name"
						[required]="true"
						labelClass="g-form-label"
					>
					</app-mat-text-control-field>
					<app-text-editor
						label="Details"
						[control]="mixedContentContent.get('description')"
						[editorStyle]="{'height': '320px'}"
						[imageUploadHandler]="editorImageUploadHandler"
						id="mixedContent-content-wysiwyg"
					></app-text-editor>
					<div class="l-image-upload pb-3">
						<app-image-upload
							[eventId]="eventId"
							[label]="'Mixed Content Image'"
							[maxFileSize]="imageService.maxImageSize"
							[image]="mixedContentContent.value.originalImage"
							[imageServiceType]="ImageServiceTypeEnum.CONTENT_TAB"
							[showPreferredResText]="false"
							[uploadText]="'This will appear on your event description page in this tab. \n'"
							[isRound]="true"
							[isMobile]="isMobile"
							[tabContentUid]="mixedContentContent.value.contentUid"
							[imagePath]="tabbedContentService.getImagePreview(mixedContentContent)"
							[handleTabContent]="true"
							[(isUploading)]="isImageUploading"
							(removeHandler)="tabbedContentService.onImageRemove(mixedContentContent)"
							(handleTabUpload)="tabbedContentService.onSingleImageUpload($event, mixedContentContent)"
							(maxFileSizeAchieved)="imageService.onImageMaxSizeAchived()"
							(isLoadingChange)="handleLoading($event)"
							class="l-listing-image pb-3"
							id="mixedContent-content-imageUpload"
						>
						</app-image-upload>
					</div>
				</div>
			</td>
		</ng-container>
		<tr
			mat-header-row
			*matHeaderRowDef="displayColumns"
		></tr>
		<tr
			mat-row
			*matRowDef="let mixedContentContent; columns: displayColumns;"
			class='g-pointer'
			(click)="handleExpandContent(mixedContentContent)"
			cdkDrag
		>
		</tr>
		<tr
			mat-row
			*matRowDef="let mixedContentContent; columns: ['expandedDetail']"
			class="l-detail-row"
			[class.collapsed-row]="expandedDetailElement !== mixedContentContent.value.contentUid"
		></tr>
	</table>
</div>