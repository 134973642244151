import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-skeleton-loader',
	templateUrl: './skeleton-loader.component.html',
	styleUrls: ['./skeleton-loader.component.sass'],
})
export class SkeletonLoaderComponent {
	@Input() width = 'auto';
	@Input() height = '130px';
	@Input() repeat = 1;
}
