<div *ngIf="!isMobile" class="notifications-overlay">
	<div
		*ngFor="let m of msg"
		[ngClass]="getNotificationClass(m)"
		[@flyInOut]="'in'"
	>
		<div class="notification-icon-wrap">
			<i [ngClass]="getNotificationIcon(m)"></i>
		</div>
		<div class="notification-content">
			<span class="notification-title">{{m.title}}</span>
			<span class="notification-desc" *ngIf="m.desc">{{m.desc}}</span>
		</div>
		<i class="fa fa-remove notification-close" (click)="onClose(m)"></i>
		<i
			*ngIf="m.type === 'error'"
			class="fa fa-repeat notification-retry"
			(click)="onRetry(m)"
		></i>
	</div>
</div>