import { ProductStatus } from './event.model';

export interface SectionRouteCard {
	title: string;
	iconPath?: string;
	description?: string;
	routeTo?: CallableFunction;
	externalRouteTo?: CallableFunction;
	type?: string;
}
export interface HeaderContent {
	title?: string;
	description?: string;
	breadCrumbs?: BreadCrumb[];
}

export interface EventSideNavItems {
	routeTo: CallableFunction;
	children: string[];
	primaryPages: string[];
	iconPath: string;
	primaryPageTabs?: boolean;
	conditionalTabs?: ConditionalTab[];
}

export interface ConditionalTab {
	tab: string;
	condition: string;
}

export interface SideNavItems {
	title: string;
	routeTo: string;
	iconPath?: string;
}

export interface Socials {
	title: string;
	iconPath: string;
	routeTo: string;
}

export interface BankAccountDescriptions {
	addBankDescription: string;
	linkExistingDescription: string;
}

export interface TabbedNav {
	path: string;
	label: string;
}

export class FilterOptions {
	searchTerm = '';
	eventStatus: ProductStatus = null;
	organiserId: number = null;
	dateFrom: string = null;
	dateTo: string = null;
	eventIds: number[] = [];
	paymentStatus: string = null;
}

export class ImageFileSize {
	bit: number;
	mb: number;
}

export interface DropdownOption {
	name: string;
	routeTo?: CallableFunction;
	value?: string;
	disabled?: boolean;
}

export interface BreadCrumb {
	routeName: string;
	routeTo?: CallableFunction;
}

export interface ChartTooltip {
	name: string;
	value: number;
	label: string;
	series: string;
}
