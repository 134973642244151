import { Component, OnInit, ElementRef, Input, forwardRef, ViewChild } from '@angular/core';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NumberField } from './number-field.model';
import { FormFieldCore } from '../../form-field-core.model';
import { Decimal } from '@app/utils/Decimal';

@Component({
	selector: 'form-number-field-core',
	templateUrl: './number-field-core.component.html',
	styleUrls: ['./number-field-core.component.sass', '../../form-field.component.sass'],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => NumberFieldCoreComponent),
		multi: true,
	}],
})

export class NumberFieldCoreComponent extends NumberField implements OnInit, ControlValueAccessor, FormFieldCore {

	value;
	@Input() placeholder: string;
	@Input() required: boolean;
	@Input() disabled: boolean;
	@Input() readonly = false;
	@Input() formatOnFocus: Function;
	@Input() formatOnBlur: Function;
	@Input() allowSpinButtons = true;
	@Input() allowIncrementByArrows = false;
	@Input() cssClassList: string;
	@Input() inputClass = '';
	@ViewChild('inputRef', { static: true })
	public inputRef: ElementRef;

	onControlChange: Function;
	onControlFocus: Function;
	constructor(
		public hostRef: ElementRef
	) {
		super();
	}

	ngOnInit() {
		if (this.formatOnBlur) {
			this.value = this.formatOnBlur(this.value) || this.value;
		}
	}

	writeValue(obj: string | number): void {
		if (this.value === undefined && obj !== undefined) {
			if (this.formatOnBlur) {
				this.value = this.formatOnBlur(obj === null ? '' :  obj.toString());
			} else {
				this.value = obj === null ? '' :  obj.toString();
			}
		} else {
			this.value = String(obj);
		}
	}

	registerOnChange(fn: Function): void {
		this.onControlChange = fn;
	}

	registerOnTouched(fn: Function): void {
		this.onControlFocus = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	createMask() {
		return createNumberMask({
			prefix: '',
			suffix: this.suffix,
			includeThousandsSeparator: this.includeThousandsSeparator,
			thousandsSeparatorSymbol: this.thousandsSeparatorSymbol,
			allowDecimal: this.allowDecimal,
			decimalSymbol: this.decimalSymbol,
			decimalLimit: this.decimalLimit,
			integerLimit: this.integerLimit,
			allowNegative: this.allowNegative,
			allowLeadingZeroes: this.allowLeadingZeroes,
		});
	}

	parse(value: string): number {
		const amount = Number(value ? value.toString().replace(/[^\d.-]/g, '') : 0);
		return amount;
	}

	getChangeStep(): number {
		if (this.allowDecimal) {
			return 1 / Math.pow(10, this.decimalLimit);
		} else {
			return 1;
		}
	}

	onFocus(): void {
		if (this.formatOnFocus) {
			this.value = this.parse(this.value) ? this.formatOnFocus(this.value) : this.value;
		}

		if (!this.parse(this.value)) {
			this.inputRef.nativeElement.select();
		}
	}

	onInput(evt): void {
		this.value = evt.target.value;
		const value = this.parse(evt.target.value);

		this.onControlChange(value);

		if (evt.target.value === '') {
			this.onControlChange(null);
		}
	}

	onBlur(): void {
		this.onControlFocus();
		if (this.formatOnBlur) {
			this.value = this.formatOnBlur(this.value) || this.value;
		}
	}

	onIncrement() {
		const step = this.getChangeStep();
		this.applyChange(step);
	}

	onDecrement() {
		const step = this.getChangeStep() * -1;
		this.applyChange(step);
	}

	applyChange(step: number) {
		let value = this.parse((this.value || ' ').toString());

		if (this.allowDecimal) {
			value = new Decimal(value).add(new Decimal(step)).toNumber();
		} else {
			value += step;
		}

		if (value < 0 && !this.allowNegative) {
			return;
		} else {
			this.value = value;
			this.onControlChange(value);
		}
	}

	onKeyDown(evt: KeyboardEvent) {
		if (this.allowIncrementByArrows) {
			if (evt.code === 'ArrowUp') {
				this.onIncrement();
			}

			if (evt.code === 'ArrowDown') {
				this.onDecrement();
			}
		}
	}
}
