import { Component, Input } from '@angular/core';
import { SocialSharingService } from '@app/services/social-sharing/socail-sharing.service';

@Component({
	selector: 'app-pinterest-sharing-btn',
	templateUrl: './pinterest-sharing.component.html',
})

export class PinterestSharingButtonComponent {
	@Input() link: string;
	constructor(
		private sharingService: SocialSharingService
	) { }

	getSharingURL() {
		return this.sharingService.getPinterestSharingURL(this.link);
	}
}
