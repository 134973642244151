<app-my-account-header
	[isMobile]='isMobile'
	pageName="Email Preference"
	(navigateBack)="handleNavigationBack()"
>
</app-my-account-header>
<form
	#emailForm="ngForm"
	class="g-account-container"
>
	<div
		class="g-account-content h-100"
		[ngClass]="{ 'mobile': isMobile, 'web': !isMobile }"
	>
		<div
			*ngIf="!isMobile; else mobileDescription"
			class="w-100"
		>
			<app-web-content-header
				[headerContent]="headerContent"
				[longWidth]="true"
			></app-web-content-header>
		</div>
		<ng-template #mobileDescription>
			<p class="mt-3">Select which lists you would like to receive email communications from.</p>
		</ng-template>
		<div
			class="g-form-container my-4 mx-2 l-form-container"
			*ngIf="!isLoading; else mainLoader"
		>
			<div class="g-double-input-container">
				<div [ngClass]="{'w-100': isMobile, 'g-double-input': !isMobile}">
					<mat-checkbox
						id='email-newsletter'
						[(ngModel)]="subscribedToNewsletter"
						name="newsletter"
					>Quicket
						Newsletter</mat-checkbox>
				</div>
				<div [ngClass]="{'w-100': isMobile, 'g-double-input': !isMobile}">
					<mat-checkbox
						id='email-recommendations'
						[(ngModel)]="subscribedToRecommendations"
						name="recommendations"
					>Event
						Recommendations</mat-checkbox>
				</div>
			</div>
			<div class="g-double-input-container">
				<div [ngClass]="{'w-100': isMobile, 'g-double-input': !isMobile}">
					<mat-checkbox
						id='email-invitations'
						[(ngModel)]="subscribedToInvites"
						name="invites"
					>Invitations from event
						organisers</mat-checkbox>
				</div>
				<div [ngClass]="{'w-100': isMobile, 'g-double-input': !isMobile}">
					<mat-checkbox
						id='email-sales'
						[(ngModel)]="subscribedToDailySales"
						name="sales"
					>Daily sales updates on
						your events</mat-checkbox>
				</div>
			</div>

		</div>
		<ng-template #mainLoader>
			<div class="l-loading">
				<mat-spinner></mat-spinner>
			</div>
		</ng-template>
		<div
			class="l-disclaimer"
			[ngClass]="{ 'mobile': isMobile }"
		>
			By submitting this form, I am agreeing to Quicket's <strong><u
					id='navigate-privacy-policy'
					class="g-pointer g-highlight"
					(click)="handlePrivacyPolicy()"
				>privacy policy</u></strong>.
		</div>
	</div>
</form>
<app-footer-button
	class="g-fixed-footer"
	[primaryText]="'SAVE CHANGES'"
	($primaryButton)="handleUpdatePreferences()"
	[isDisabled]="emailForm.pristine"
	[isMobile]="isMobile"
></app-footer-button>