import { Component, Input } from '@angular/core';
import { IconType } from '@app/models/event.model';
import { convertToIdFormat } from '@app/utils/common-helpers';

@Component({
	selector: 'app-mobile-section-route-card',
	templateUrl: './mobile-section-route-card.component.html',
	styleUrls: ['./mobile-section-route-card.component.sass'],
})
export class MobileSectionRouteCardComponent {
	@Input() icon: string;
	@Input() text: string;
	@Input() isDisabled: boolean;
	@Input() iconType = IconType.SVG;
	@Input() description: string;
	@Input() asCard = false;
	@Input() tooltip: string;

	formatId(itemTitle: string, prefix: string ): string {
		return convertToIdFormat(itemTitle, prefix);
	}
}
