import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import * as moment from 'moment';

export interface ValidationMessage {
	[key: string]: string;
}

export const conditionalPipe = (condition: boolean | Function, ...validators: ValidatorFn[]): ValidatorFn => (
	(control: AbstractControl): ValidationMessage => {
		const allowExecution = typeof condition === 'function'
			? condition()
			: condition;

		if (!allowExecution || !validators || !validators.length) {
			return null;
		}

		const result = validators.reduce((acc, validator) => {
			const res = validator(control);
			let accErrors = acc;
			if (res) {
				accErrors = {
					...accErrors,
					...res,
				};
			}
			return accErrors;
		}, {});

		if (!Object.keys(result).length) {
			return null;
		}

		return result;
	}
);

export const requiredValidator = (msg?: string): ValidatorFn => (
	(control: AbstractControl): ValidationMessage => {
		if (Validators.required(control) && Validators.required(control).required) {
			return {
				required: msg || 'This field is required',
			};
		}
		return null;
	}
);

export const notEmptyArray = (msg?: string): ValidatorFn => (
	(control: AbstractControl): ValidationMessage => {
		if (control.value && !control.value.length) {
			return {
				notEmptyArray: msg || 'Can\'t be empty',
			};
		}
		return null;
	}
);

export const emailValidator = (msg?: string): ValidatorFn => (
	(control: AbstractControl): ValidationMessage => {
		/* eslint-disable-next-line max-len */
		if (!/^((([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i.test(control.value) && control.value) {
			return {
				email: msg || 'Invalid email address.',
			};
		}
		return null;
	}
);

export const phoneValidator = (msg?: string): ValidatorFn => (
	(control: AbstractControl): ValidationMessage => {
		if (control.value && !/\+?\d{5,11}$/gm.test(control.value)) {
			return {
				phone: msg || 'Invalid phone number.',
			};
		}
		return null;
	}
);

export const websiteValidator = (msg?: string): ValidatorFn => (
	(control: AbstractControl): ValidationMessage => {
		if (!/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/
			.test(control.value) && control.value) {
			return {
				website: msg || 'Invalid URL provided.',
			};
		}
		return null;
	}
);

export const websiteValidatorWithProtocol = (msg?: string): ValidatorFn => (
	(control: AbstractControl): ValidationMessage => {
		if (!/^((http|https):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&\/\/=]*$)/
			.test(control.value) && control.value) {
			return {
				website: msg || 'Invalid website URL provided. e.g. http(s)://example.com',
			};
		}
		return null;
	}
);

export const twitterHandle = (msg?: string): ValidatorFn => (
	(control: AbstractControl): ValidationMessage => {
		if (!/^[a-zA-Z0-9_]{1,15}/.test(control.value) && control.value) {
			return {
				twitterHandle: msg || 'Invalid Twitter/X handle (e.g. @username).',
			};
		}
		return null;
	}
);

export const spotifyUrl =
	(msg?: string): ValidatorFn =>
		(control: AbstractControl): ValidationMessage => {
			if (
				!/https:\/\/open\.spotify\.com\/[a-zA-Z]+\/[a-zA-Z0-9]+(\?[^\s]*)?/.test(control.value) &&
			control.value
			) {
				return {
					spotifyUrl: msg || 'Invalid Spotify URL provided.',
				};
			}
			return null;
		};

export const iframe = (msg?: string): ValidatorFn => (
	(control: AbstractControl): ValidationMessage => {
		if (!/<iframe(.+)<\/iframe>/g.test(control.value) && control.value)  {
			return {
				iframe: msg || 'Embed code is invalid.',
			};
		}
		return null;
	}
);

export const onlyNumberAndLetters = (msg?: string): ValidatorFn => (
	(control: AbstractControl): ValidationMessage => {
		if (!/^[a-zA-Z0-9_.-]*$/.test(control.value)) {
			return {
				onlyNumberAndLetters: msg || 'This field should only contain numbers and letters.',
			};
		}
		return null;
	}
);

export const onlyLettersAndSpaces = (msg?: string): ValidatorFn => (
	(control: AbstractControl): ValidationMessage => {
		if (!/^[a-zA-Z\s]*$/.test(control.value)) {
			return {
				onlyLettersAndSpaces: msg || 'This field should only contain letters and spaces.',
			};
		}
		return null;
	}
);

export const maxLength = (maxL = 0, msg?: string): ValidatorFn => (
	(control: AbstractControl): ValidationMessage => {
		if (String(control.value) && String(control.value).length > maxL) {
			return {
				maxLength: msg || `Maximum ${maxL} characters allowed. Please shorten your entry.`,
			};
		}
		return null;
	}
);

export const minLength = (minL = 0, msg?: string): ValidatorFn => (
	(control: AbstractControl): ValidationMessage => {
		if (String(control.value) && String(control.value).length < minL) {
			return {
				minLength: msg || `Minimum length required is ${minL}.`,
			};
		}
		return null;
	}
);

export const isDateAfter = (dateControl: AbstractControl, msg?: string, formatValue?: (date: Date) => Date): ValidatorFn => (
	(control: AbstractControl): ValidationMessage => {
		const value = formatValue
			? formatValue(control.value)
			: control.value;
		if (moment(value).seconds(0).isSameOrBefore(moment(dateControl.value).seconds(0))) {
			return {
				isDateAfter: msg || 'The date entered must be after the start date.',
			};
		}
		return null;
	}
);

export const isDateBefore = (dateControl: AbstractControl, msg?: string): ValidatorFn => (
	(control: AbstractControl): ValidationMessage => {
		if (moment(control.value).seconds(0).isSameOrAfter(moment(dateControl.value).seconds(0))) {
			return {
				isDateBefore: msg || 'The date entered must be before the end date.',
			};
		}
		return null;
	}
);

export const numberMoreThen = (baseNumber = 0, msg?: string, excluding?: boolean): ValidatorFn => (
	(control: AbstractControl): ValidationMessage => {
		const condition = excluding ? control.value < baseNumber : control.value <= baseNumber;

		if (condition) {
			return {
				numberMoreThan: msg || `Value should be greater than ${baseNumber}`,
			};
		}
		return null;
	}
);

export const numberLessThen = (baseNumber = 0, msg?: string, excluding?: boolean): ValidatorFn => (
	(control: AbstractControl): ValidationMessage => {
		const condition = excluding ? control.value > baseNumber : control.value >= baseNumber;

		if (condition) {
			return {
				numberLessThan: msg || `Value should be less than ${baseNumber}`,
			};
		}
		return null;
	}
);

export const ticketCategories = (): ValidatorFn => (
	(categories: AbstractControl): ValidationMessage => {
		const anyRequired = categories.value.some(el => el.required);
		return !anyRequired && categories.value.length ? {
			ticketCategories: 'At least one category should be checked',
		} : null;
	}
);

export const matchFieldValidator = (control: AbstractControl, msg?: string): ValidatorFn => (
	({ value }: AbstractControl): ValidationMessage => {
		const { value: valueToMatch } = control;
		const condition = value !== valueToMatch;

		if (condition) {
			return {
				matchField: msg || 'Field should match',
			};
		}

		return null;
	}
);

export const dissimilarFieldValidator = (control: AbstractControl, msg?: string): ValidatorFn => (
	({ value }: AbstractControl): ValidationMessage => {
		const { value: valueToCompare } = control;
		const condition = value === valueToCompare;
		if (condition) {
			return {
				matchField: msg || 'Selected fields Shouldn\'t be the same.',
			};
		}

		return null;
	}
);

export const cannotContainSpaces = (msg?: string): ValidatorFn => (
	(control: AbstractControl): ValidationMessage => {
		if ((control.value as string).indexOf(' ') >= 0) {
			return {
				cannotContainSpaces: msg || 'This field cannot contain spaces.',
			};
		}
		return null;
	}
);

export const facebookUrl = (msg?: string): ValidatorFn => (
	(control: AbstractControl): ValidationMessage => {
		if (!/(https?:\/\/)?(www\.)?facebook\.com\/[a-zA-Z0-9(\.\?)?]/.test(control.value) && control.value) {
			return {
				facebookUrl: msg || 'Invalid Facebook URL provided.',
			};
		}
		return null;
	}
);

export const scheduleHasValid = (msg?: string): ValidatorFn => (
	(control: AbstractControl): ValidationMessage => {
		const allDeleted = control.value.every(el => el.deleted);
		if (allDeleted) {
			return {
				scheduleHasValid: msg || 'Need a valid schedule.',
			};
		}
		return null;
	}
);

export const regexValidator = (pattern: string, msg?: string): ValidatorFn => (
	(control: AbstractControl): ValidationMessage => {
		if (!control.value) {
			return null;
		}

		const regex = new RegExp(pattern);
		if (!regex.test(control.value)) {
			return {
				regexPattern: msg || 'Invalid format provided.',
			};
		}
		return null;
	}
);

export function stringExistsValidator(existingStrings: string[], msg?: string): ValidatorFn {
	return (control: AbstractControl): ValidationMessage => {
		if (existingStrings.includes(control.value)) {
			return {
				stringExists: msg || 'Item exists in list',
			};
		}
		return null;
	};
}
