import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl  } from '@angular/forms';
import { SelectFieldOption } from '@app/shared/form-field/select-field/select-field.model';

@Component({
	selector: 'app-mat-select-control-field',
	templateUrl: './mat-select-control-field.component.html',
	styleUrls: ['./mat-select-control-field.component.sass'],
})
export class MatSelectControlFieldComponent implements OnInit {
	@Input() disabled = false;
	@Input() options: SelectFieldOption[];
	@Input() placeholder = '';
	@Input() label = '';
	@Input() outerClass = 'l-form-item';
	@Input() labelClass = 'g-form-label l-form-label';
	@Input() control: UntypedFormControl;
	@Input() tooltip = '';
	@Input() customId = '';
	@Input() required = false;
	@Input() handleErrors = false;
	@Output() selectedOption = new EventEmitter<SelectFieldOption>();

	ngOnInit(): void {
		if (this.disabled) {
			this.control.disable();
		}
	}

	optionSelected(e: SelectFieldOption) {
		this.selectedOption.emit(e);
	}

	isControlInvalid() {
		if (this.control) {
			return this.control.invalid && (this.control.dirty || this.control.touched);
		}
		return false;
	}

	getError(errors: { [key: string]: string }) {
		return  errors && errors[Object.keys(errors)[0]];
	}
}
