import { BreakpointService } from '../../../../services/breakpoint/breakpoint.service';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { HeaderContent } from '@app/models/shared';
import { StoreService } from '@app/services/store/store.service';
import { MY_ACCOUNT_HEADER } from '@app/utils/consts';
import { UserAPIToken } from '@app/store/selectors/user.selector';
import { takeUntil } from 'rxjs/operators';
import { UpdateUserAPIToken } from '@app/store/actions/user/user.actions';
import { Location } from '@angular/common';
import { URLCreator } from '@app/services/url/url.dictionary';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '@app/shared/modals/confirmation-modal/confirmation-modal.component';

@Component({
	selector: 'app-api-keys',
	templateUrl: './api-keys.component.html',
	styleUrls: ['./api-keys.component.sass'],
})
export class ApiKeysComponent implements OnInit {
	headerContent: HeaderContent = MY_ACCOUNT_HEADER['api'];
	apiKey: string;
	isMobile = false;
	contactUs: string = URLCreator.contactUs();
	destroyed$: EventEmitter<void> = new EventEmitter();

	constructor(
		private store: StoreService,
		private breakpointService: BreakpointService,
		private location: Location,
		private dialog: MatDialog
	) { }

	ngOnInit(): void {

		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});

		this.store.select(UserAPIToken()).pipe(takeUntil(this.destroyed$)).subscribe((key) => {
			this.apiKey = key;
		});
	}

	handleGenerateToken() {
		const dialogRef = this.dialog.open(ConfirmationModalComponent, {
			data: {
				title: 'Are You Sure?',
				text: 'By changing the API token, any program using the previous token will no longer work.',
				buttonText: 'NEW TOKEN',
				isMobile: this.isMobile,
			},
			panelClass: 'g-standard-dialog',
		});

		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.store.dispatch(new UpdateUserAPIToken());
			}
		});
	}

	handleDeveloperPortal() {
		window.open('https://developer.quicket.co.za/');
	}

	handleContactUs() {
		window.open(URLCreator.contactUs());
	}

	handleNavigationBack() {
		this.location.back();
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}
}
