<div class="l-container" [ngClass]="{'l-mobile' : isMobile}">
	<div class='l-header-container'>
		<h3 class='mb-0'>Linked Events</h3>
	</div>
	<div class="mb-3">This organiser profile is currently linked to the below products
		Please select a new profile for each product before deleting the profile.
	</div>
	<div class='l-linked-products mb-5' [ngClass]="{'l-mobile' : isMobile}">
		<ul *ngFor="let product of data.linkedProducts; let i = index">
			<li class="l-link">
				{{product.name}}
				<span class="g-pointer g-green" (click)='handleRouting(product)'>
					<mat-icon class="l-icon" (click)='handleRouting(product)'>open_in_new</mat-icon>
				</span>
			</li>
		</ul>
	</div>
	<div class="l-buttons-container">
		<button class="g-primary-button l-orange" (click)="handleClose()">CLOSE</button>
	</div>
</div>