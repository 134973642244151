<div class="l-item" [ngClass]="{ 'l-item-completed': completed }">
    <div class="l-item-content">
        <mat-icon 
            class="l-item-icon" 
        >
            {{ icon }}
        </mat-icon>
        {{ text }}
    </div>
    <div class="l-action-items">
        <button 
            *ngIf="displaySkip" 
            (click)="clickAction()" 
            class="l-item-button l-item-button-skip"
            [id]="'skip-' + id + '-button'"
        >
            Skip
        </button>
        <a [href]="link">
            <button class="l-item-button" [id]="id + '-button'">{{ buttonText }}</button>
        </a>
    </div>
</div>
