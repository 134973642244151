import { Component, OnInit } from '@angular/core';
import { MultiChoiceBaseControlComponent } from '../multi-choice-base-control/multi-choice-base-control.component';

@Component({
	selector: 'app-drop-down-field',
	templateUrl: './drop-down-field.component.html',
	styleUrls: ['./drop-down-field.component.sass'],
})
export class DropDownFieldComponent extends MultiChoiceBaseControlComponent implements OnInit {
	ngOnInit(): void {
		super.ngOnInit();
	}
}
