import { Component, OnInit, OnDestroy } from '@angular/core';
import { CloseErrorModal, RetryErrorAction } from '@app/store/actions/errorHandler/errorHandler.actions';
import { ErrorHandlerState } from '@app/models/store.model';
import { ActivatedRoute } from '@angular/router';
import { StoreService, SCTypes } from '@app/services/store/store.service';
import { errorState } from '@app/store/selectors/errorHandler.selector';
import { ErrorBusService } from '@app/services/error-handling/error-bus.service';
import { SystemExecutionFailed } from '../../store/actions/system/system.actions';
import { Reload } from '../../store/actions/router/router.actions';

@Component({
	selector: 'app-error-modal',
	templateUrl: './error-modal.component.html',
	styleUrls: ['./error-modal.component.sass'],
})

export class ErrorModalComponent implements OnInit, OnDestroy {
	disconnectStore: SCTypes.UnsubscribeFn;
	errorState: ErrorHandlerState;
	isExtraMessage: boolean;
	isOpened: boolean;
	constructor(
		private store: StoreService,
		private router: ActivatedRoute,
		private errorBus: ErrorBusService
	) {
		this.disconnectStore = this.store.connect(
			new Map([
				[errorState(), this.onErrorStateUpdate],
			])
		);

		this.router.url.subscribe(value => {
			if (value && this.errorState.isOpened) {
				this.store.dispatch(new CloseErrorModal());
			}
		});

		this.errorBus.errorStream$
			.subscribe(value => {
				this.isExtraMessage = true;
				this.isOpened = true;
				this.store.dispatch(new SystemExecutionFailed({
					action: null,
					msg: 'can\'t resume application execution.',
					error: value,
					critical: true,
					meta: {
						allowClose: false,
						extraAction: new Reload(),
						extraActionTitle: 'Reload',
					},
				}));
			});
	}

	closeModal() {
		this.store.dispatch(new CloseErrorModal());
	}

	onErrorStateUpdate = (value) => {
		this.errorState = value;
	};

	proceedAction(action, retry?: boolean) {
		if (retry) {
			this.store.dispatch(new RetryErrorAction({ action }));
		} else {
			this.closeModal();
		}
		this.store.dispatch(action);
	}

	ngOnInit() { }

	decodeServerErrorCodes(codes) {
		return this.errorBus.getServerMessages(codes);
	}

	ngOnDestroy() {
		this.disconnectStore();
	}
}
