import { EventEntityState } from '@app/models/store.model';
import { Actions, StreamingActionConstants } from './event-streaming.actions';
import { omit } from 'lodash';

export const entityStreamingReducer = (initialState: EventEntityState['streaming']) => (
	state = initialState,
	action: Actions
): EventEntityState['streaming'] => {
	switch (action.type) {
		case StreamingActionConstants.GET_EVENT_STREAMING_SUCCESS:
			return {
				...state,
				settings: action.payload.settings,
				streams: action.payload.streams,
				ticketTypes: action.payload.ticketTypes,
				eventHasBegun: action.payload.eventHasBegun,
			};
		case StreamingActionConstants.UPDATE_EVENT_STREAMING:
		case StreamingActionConstants.REQUEST_VOD_DOWNLOAD_LINK:
			return {
				...state,
				isFetching: true,
			};
		case StreamingActionConstants.UPDATE_EVENT_STREAMING_FAILED:
		case StreamingActionConstants.REQUEST_VOD_DOWNLOAD_LINK_SUCCESS:
		case StreamingActionConstants.REQUEST_VOD_DOWNLOAD_LINK_FAILED:
			return {
				...state,
				isFetching: false,
			};
		case StreamingActionConstants.UPDATE_EVENT_STREAMING_SUCCESS:
			const idAlreadyExists = state.streams.all.indexOf(action.payload.streams.id) > -1;
			return {
				...state,
				isFetching: false,
				streams: {
					...state.streams,
					all: [
						...state.streams.all,
						...(idAlreadyExists
							? []
							: [action.payload.streams.id]),
					],
					byId: {
						...state.streams.byId,
						[action.payload.streams.id]: action.payload.streams,
					},
				},
			};
		case StreamingActionConstants.DELETE_EVENT_STREAMING_ROOM:
			return {
				...state,
				isFetching: true,
			};
		case StreamingActionConstants.DELETE_EVENT_STREAMING_ROOM_SUCCESS:
			return {
				...state,
				isFetching: false,
				streams: {
					...state.streams,
					all: state.streams.all.filter(id => id !== action.payload.id),
					byId: omit(state.streams.byId, action.payload.id),
				},
			};
		case StreamingActionConstants.DELETE_EVENT_STREAMING_ROOM_FAILED:
			return {
				...state,
				isFetching: false,
			};
		case StreamingActionConstants.SET_EVENT_STREAMING_ENABLED:
			return {
				...state,
				streams: {
					...state.streams,
					byId: {
						...state.streams.byId,
						[action.payload.id]: {
							...state.streams.byId[action.payload.id],
							enabled: action.payload.isEnabled,
						},
					},
				},
			};
		case StreamingActionConstants.SET_EVENT_STREAMING_ENABLED_FAILED: {
			const { prevValue, id } = action.payload.action.payload;
			return {
				...state,
				streams: {
					...state.streams,
					byId: {
						...state.streams.byId,
						[id]: {
							...state.streams.byId[id],
							enabled: prevValue,
						},
					},
				},
			};
		}
		case StreamingActionConstants.SET_EVENT_STREAMING_SETTINGS:
			return {
				...state,
				isFetching: true,
			};
		case StreamingActionConstants.SET_EVENT_STREAMING_SETTINGS_SUCCESS:
			return {
				...state,
				isFetching: false,
				settings: action.payload.settings,
			};
		case StreamingActionConstants.SET_EVENT_STREAMING_SETTINGS_FAILED:
			return {
				...state,
				isFetching: false,
			};
		case StreamingActionConstants.SET_ORGANISER_PREVIEW_CODE:
			return {
				...state,
				isFetching: true,
			};
		case StreamingActionConstants.SET_ORGANISER_PREVIEW_CODE_SUCCESS:
			return {
				...state,
				isFetching: false,
				settings: action.payload.settings,
				streams: action.payload.streams,
				ticketTypes: action.payload.ticketTypes,
			};
		case StreamingActionConstants.SET_ORGANISER_PREVIEW_CODE_FAILED:
			return {
				...state,
				isFetching: false,
			};
		case StreamingActionConstants.DELETE_ORGANISER_PREVIEW_CODE:
			return {
				...state,
				isFetching: true,
			};
		case StreamingActionConstants.DELETE_ORGANISER_PREVIEW_CODE_SUCCESS:
			return {
				...state,
				isFetching: false,
				settings: action.payload.settings,
				streams: action.payload.streams,
				ticketTypes: action.payload.ticketTypes,
			};
		case StreamingActionConstants.DELETE_ORGANISER_PREVIEW_CODE_FAILED:
			return {
				...state,
				isFetching: false,
			};
		case StreamingActionConstants.SET_STREAMING_FORM_MODAL_FLAG:
			return {
				...state,
				isModalOpen: action.payload.isModalOpen,
			};
		case StreamingActionConstants.SET_EVENT_STREAMING_FETCHING:
			return {
				...state,
				isFetching: action.payload.isFetching,
			};
		case StreamingActionConstants.REORDER_STREAMS:
			return {
				...state,
				streams: {
					...state.streams,
					all: [...action.payload.sortOrder],
				},
			};
		case StreamingActionConstants.REORDER_STREAMS_FAILED:
			return {
				...state,
				streams: {
					...state.streams,
					all: [...action.payload.action.payload.prevOrder],
				},
			};
		default:
			return state;
	}
};
