import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Timezone } from '@app/models/event.model';

@Component({
	selector: 'app-timezone-selector',
	templateUrl: './timezone-selector.component.html',
	styleUrls: ['./timezone-selector.component.sass'],
})
export class TimezoneSelectorComponent{
	@Input() options: Timezone[];
	@Input() value: string;
	@Input() disabled = false;
	@Input() control?: UntypedFormControl;
	@Output() timezoneSelect: EventEmitter<string> = new EventEmitter<string>();
	timezoneCtrl: UntypedFormControl;

	ngOnInit() {
		if (!this.control){
			this.timezoneCtrl = new UntypedFormControl(this.getTitleByTimezone(this.value));
			if (this.disabled){
				this.timezoneCtrl.disable();
			}
		}
	}

	filterTimezones(query: string): Timezone[] {
		return this.options.filter(timezone => timezone.title.toLowerCase().indexOf(query.toLowerCase()) !== -1);
	}

	getTimezoneByTitle(title: string): Timezone {
		const timeZone = this.options.find(el => el.title === title);
		return timeZone;
	}

	getTitleByTimezone(name: string): string {
		const timeZone = this.options.find(el => el.name === name);
		return timeZone && timeZone.title;
	}

	itemSelect(item: Timezone): string {
		return item.title;
	}

	onItemSelect(item: Timezone): void {
		this.timezoneSelect.emit(item.name);
	}
}
