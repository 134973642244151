<p-dialog
	[visible]="visible"
	[style]="style"
	[styleClass]="styleClass"
	blockScroll="true"
	[closeOnEscape]="false"
	[closable]="false"
	[modal]="true"
	[draggable]="false"
	[resizable]="false"
>
	<p-header *ngIf="dialogHeader || header">
		<ng-container
			*ngIf="header; else headerTemplate"
		>
			{{ header }}
		</ng-container>
		<ng-template #headerTemplate>
			<ng-content
				select="app-dialog-header"
			></ng-content>
		</ng-template>
		<i
			*ngIf="useClose"
			class="fa fa-times ui-dialog-close-icon"
			(click)="close.emit()"
		></i>
	</p-header>
	
	<ng-content></ng-content>
	<div *ngIf="loading" class="ui-dialog-loading-wrap">
		<div
			class="ui-dialog-loading"
			
			[loadingSp]="loading"
			[loadingText]="null"
			[loadingBackground]="'rgb(243, 243, 244, .5)'"
			[spinnerBg]="'black'"
		></div>
	</div>
	<p-footer>
		<ng-content
			select="app-dialog-footer"
		></ng-content>
	</p-footer>
</p-dialog>