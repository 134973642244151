<div class="l-container" *ngIf="!isPageLoading; else pageLoading">
	<div class="l-content l-header-content" [ngClass]="{ 'mobile': isMobile }" >
		<app-web-content-header
			*ngIf="!isMobile; else mobileHeaderContent"
			class="l-header"
			[headerContent]="headerContent"
			[mediumWidth]="true"
		></app-web-content-header>
		<ng-template #mobileHeaderContent>
			<div class="l-mobile-description">
				<p *ngIf="!readMore" class="l-header">
					{{ headerContent.description }}
					<span (click)="handleReadMore()" id="read-less-button" class="g-pointer g-highlight g-green">
						{{ readMoreText }}
					</span>
				</p>
				<p *ngIf="readMore" class="l-header" [ngClass]="{ mobile: isMobile }">
					{{ customDescription }}
					<span (click)="handleReadMore()" id="read-less-button" class="g-pointer g-highlight g-green">
						{{ readMoreText }}
					</span>
				</p>
			</div>
		</ng-template>
	</div>
	<form [formGroup]="form" *ngIf="form">
		<div class="l-form" [ngClass]="{ mobile: isMobile }">
			<h3 class="mb-3 l-header"> General </h3>

			<div class="l-form-toggles-container" [ngClass]="{ mobile: isMobile }">
				<div class="l-five-toggles-container">
					<div class="l-toggle-container">
						<app-mat-slide-control-field
							customId="sms-tickets-toggle"
							[isMobile]="isMobile"
							label="SMS Tickets"
							tooltip="Allows guests to optionally receive tickets via. SMS. Tickets will be sent
							as a text and include the barcode number and event details. Guests are charged R3
							for every SMS sent to them."
							formControlName="allowSms"
						></app-mat-slide-control-field>
					</div>
					<div class="l-toggle-container">
						<app-mat-slide-control-field
							customId="allow-copy-toggle"
							[isMobile]="isMobile"
							label="Allow Copy From Above"
							tooltip="'Copy from above' allows people buying tickets to checkout quicker. If
							enabled, multiple ticket holder details can be copied to each ticket holder during
							the checkout process. If disabled, each ticket holder's details must be filled in manually."
							formControlName="allowCopyFromAbove"
						></app-mat-slide-control-field>
					</div>
					<div class="l-toggle-container" *ngIf="fromForm('allowTicketTransfer').value">
						<app-mat-slide-control-field
							customId="lock-transferred-tickets-toggle"
							[isMobile]="isMobile"
							label="Lock Transferred Tickets"
							tooltip="This setting only applies if transfers are allowed. If enabled, it will prevent
							transferred tickets from being downloaded until the recipient has filled in the required
							ticket details."
							formControlName="lockTransferredTickets"
						></app-mat-slide-control-field>
					</div>
					<div class="l-toggle-container">
						<app-mat-slide-control-field
							customId="third-party-sharing-toggle"
							[isMobile]="isMobile"
							label="Third Party Sharing"
							tooltip="Enable social media sharing capabilities on your event page. This will allow users
							to share your event through social networks."
							formControlName="allowSharing"
						></app-mat-slide-control-field>
					</div>
					<div class="l-toggle-container">
						<app-mat-slide-control-field
							customId="allow-public-resale-toggle"
							[isMobile]="isMobile"
							label="Allow Public Resale Of Tickets"
							tooltip="If ON, ticket buyers who can no longer attend and do not have a buyer in mind can
							put their tickets up for resale. These will appear in the ticket selector only if tickets are
							sold out. If someone purchases a resold ticket, the original owner will be refunded the ticket
							price. Your event payout will not be affected."
							formControlName="allowPublicResale"
						></app-mat-slide-control-field>
					</div>
				</div>

				<div class="l-five-toggles-container">
					<div class="l-toggle-container">
						<app-mat-slide-control-field
							customId="allow-ticket-editing-toggle"
							[isMobile]="isMobile"
							label="Allow Ticket Editing"
							[tooltip]="getAllowTicketEditTooltip(covidComplianceEnabled)"
							formControlName="allowTicketEditing"
						></app-mat-slide-control-field>
					</div>
					<div class="l-toggle-container">
						<app-mat-slide-control-field
							customId="allow-ticket-transfer-toggle"
							[isMobile]="isMobile"
							label="Allow Ticket Transfer"
							tooltip="In order to further prevent ticket scalping Quicket has developed a ticket transfer
							facility. If enabled ticket buyers will be able to transfer tickets to other users, this will
							change the barcode on the ticket and will not be accessible to the original buyer."
							formControlName="allowTicketTransfer"
						></app-mat-slide-control-field>
					</div>
					<div class="l-toggle-container" *ngIf="fromForm('allowTicketTransfer').value">
						<app-mat-slide-control-field
							customId="cut-off-ticket-transfers-toggle"
							[isMobile]="isMobile"
							label="Cut Off Ticket Transfers"
							tooltip="This setting only applies if transfers are allowed. If enabled transfers will shut 2
							days prior to your event starting."
							formControlName="cutOffTransfers"
						></app-mat-slide-control-field>
					</div>
					<div class="l-toggle-container">
						<app-mat-slide-control-field
							customId="incentivise-guests-toggle"
							[isMobile]="isMobile"
							label="Incentivise Guests"
							tooltip="Incentivise your guests to share your event through their social networks. Clicks and
							sales are tracked for each of your guest and available as a download to you, you can then give out
							rewards for whoever shares the most. This is a great way to make your event viral."
							formControlName="showIncentive"
						></app-mat-slide-control-field>
					</div>
					<div class="l-toggle-container">
						<app-mat-slide-control-field
							customId="allow-private-resale-toggle"
							[isMobile]="isMobile"
							label="Allow Private Resale Of Tickets"
							tooltip="If ON, ticket buyers who can no longer attend and do not have a buyer in mind can put their
							tickets up for resale. These will appear in the ticket selector only if tickets are sold out. If
							someone purchases a resold ticket, the original owner will be refunded the ticket price. Your event
							payout will not be affected."
							formControlName="allowPrivateResale"
						></app-mat-slide-control-field>
					</div>
				</div>
			</div>

			<hr class="l-horizontal-rule g-light-line" />
			<h3 class="mb-3 l-header"> Messages </h3>

			<div class="l-form-field-container" *ngIf="fromForm('showIncentive').value">
				<app-mat-text-area-control-field
					inputClass = "form-control mh-100 l-text-area"
					customId="incentive-message-input"
					label="Incentive Message"
					[control]="fromForm('incentiveMessage')"
					tooltip="Add a message that will be displayed to guests once they have bought their tickets. This should be
					where you tell them what you are offering for their help in spreading the word. For example, 'Share this
					link and you could win tickets to the next event!'">
				</app-mat-text-area-control-field>
			</div>

			<div class="l-form-field-container">
				<app-mat-text-area-control-field
					inputClass = "form-control mh-100 l-text-area"
					customId="purchase-success-message-input"
					label="Purchase Success Message"
					[control]="fromForm('purchaseSuccessMessage')"
					tooltip="Optionally add a message that will be displayed to guests on the purchase success page. The purchase
					success page is not shown to people reserving tickets to be paid by EFT.">
				</app-mat-text-area-control-field>
			</div>

			<div class="l-form-field-container">
				<app-mat-text-area-control-field
					inputClass = "form-control mh-100 l-text-area"
					customId="alert-message-input"
					label="Alert Message"
					[control]="fromForm('alertMessage')"
					tooltip="Add a message that will display at the top of your event page. This is useful for informing
					your attendees about any changes to your event.">
				</app-mat-text-area-control-field>
			</div>

			<hr class="l-horizontal-rule g-light-line" />
			<h3 class="mb-3 l-header"> Purchase Timeouts </h3>

			<div class="l-number-field-container">
				<app-mat-number-control-field
					customId="purchase-time-limit-input"
					label="Purchase Time Limit (minutes)"
					[allowNegative]="false"
					[min]="5"
					tooltip="Choose how long users have to fill out the payment form. Increase this number if you have a long
					form for buyers to fill out."
					[control]="fromForm('purchaseTimeLimit')"
					required="true">
				</app-mat-number-control-field>
			</div>
		</div>
	</form>
	<div class="l-sticky-bar" *ngIf="form">
		<app-footer-button
			id="save-settings-button"
			primaryText="SAVE"
			($primaryButton)="handleSave()"
			[isMobile]="isMobile"
			[isDisabled]="!form || form.invalid || isUpdating || form.pristine"
			[isLoading]="isUpdating"
		>
		</app-footer-button>
	</div>
</div>
<ng-template #pageLoading>
	<div class="l-page-loader">
		<mat-spinner></mat-spinner>
	</div>
</ng-template>