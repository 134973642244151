import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import config from '@app/config';

@Component({
	selector: 'app-iframe',
	templateUrl: './iframe.component.html',
	styleUrls: ['./iframe.component.sass'],
})
export class IframeComponent implements OnInit, OnDestroy {
	@Input() url: string;
	@Input() customId = '';
	safeUrl: SafeResourceUrl;
	isLoading = true;

	@Output() loaded: EventEmitter<void> = new EventEmitter<void>();

	constructor(private sanitizer: DomSanitizer) { }

	ngOnInit() {
		this.handleQueryParams();
		window.addEventListener('message', this.handleMessage);
	}

	handleMessage = (event: MessageEvent) => {
		// WIP - implement origin matching to prevent the message from being sent to or received by unintended origins.

		if (event.origin === config.baseURL) {
			// TODO: Find out of this baseURL check would work in production?
		}
		if (event.data.type === 'LOADED') {
			this.isLoading = false;
			this.loaded.emit();
		}
	};

	handleQueryParams() {
		let separator = this.url.includes('?') ? '&' : '?';

		if (this.url.includes('events/manage/orders/default')) {
			separator = '?';
		}

		const urlWithQueryString = `${this.url}${separator}iframe=true`;
		this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(urlWithQueryString);
	}

	ngOnDestroy() {
		window.removeEventListener('message', this.handleMessage);
	}

}
