import { AppAction, FailedAction } from '@app/models/action.model';
import { MailTicketsPdf, ManageBooking, ManageBookingInvoice } from '@app/models/user.model';
import { SchemeID } from '@app/models/dataSchema.model';

export enum ManageBookingConstants {
	GET_USER_MANAGE_BOOKING = 'GET_USER_MANAGE_BOOKING',
	GET_USER_MANAGE_BOOKING_SUCCESS = 'GET_USER_MANAGE_BOOKING_SUCCESS',
	GET_USER_MANAGE_BOOKING_FAILED = 'GET_USER_MANAGE_BOOKING_FAILED',

	UPDATE_BOOKING_INVOICE = 'UPDATE_BOOKING_INVOICE',
	UPDATE_BOOKING_INVOICE_SUCCESS = 'UPDATE_BOOKING_INVOICE_SUCCESS',
	UPDATE_BOOKING_INVOICE_FAILED = 'UPDATE_BOOKING_INVOICE_FAILED',

	SET_UPDATE_INVOICE_MODAL_FLAG = 'SET_UPDATE_INVOICE_MODAL_FLAG',

	SET_BOOKING_CHANGE_DATES_MODAL_FLAG = 'SET_BOOKING_CHANGE_DATES_MODAL_FLAG',

	IS_UPDATE_BOOKING_SUCCESS = 'IS_UPDATE_BOOKING_SUCCESS',

	IS_UPDATE_BOOKING_FAILED = 'IS_UPDATE_BOOKING_FAILED ',

	UPDATE_MANAGE_BOOKING = 'UPDATE_MANAGE_BOOKING',
	UPDATE_MANAGE_BOOKING_SUCCESS = 'UPDATE_MANAGE_BOOKING_SUCCESS',
	UPDATE_MANAGE_BOOKING_FAILED = 'UPDATE_MANAGE_BOOKING_FAILED',

	DOWNLOAD_TICKETS_SEPERATELY = 'DOWNLOAD_TICKETS_SEPERATELY',
	DOWNLOAD_TICKETS_SEPERATELY_SUCCESS = 'DOWNLOAD_TICKETS_SEPERATELY_SUCCESS',
	DOWNLOAD_TICKETS_SEPERATELY_FAILED = 'DOWNLOAD_TICKETS_SEPERATELY',
}

export class GetUserManageBooking extends AppAction <{
	id: SchemeID;
}> {
	readonly type = ManageBookingConstants.GET_USER_MANAGE_BOOKING;
}

export class GetUserManageBookingSuccess extends AppAction <{
	id: SchemeID;
	booking: ManageBooking;
}> {
	readonly type = ManageBookingConstants.GET_USER_MANAGE_BOOKING_SUCCESS;
}

export class GetUserManageBookingFailed extends FailedAction {
	readonly type = ManageBookingConstants.GET_USER_MANAGE_BOOKING_FAILED;
}

export class UpdateBookingInvoice extends AppAction <{
	id: SchemeID;
	invoice: ManageBookingInvoice;
}> {
	readonly type = ManageBookingConstants.UPDATE_BOOKING_INVOICE;
}

export class UpdateBookingInvoiceSuccess extends AppAction <{
	id: SchemeID;
	invoice: ManageBookingInvoice;
}> {
	readonly type = ManageBookingConstants.UPDATE_BOOKING_INVOICE_SUCCESS;
}

export class UpdateBookingInvoiceFailed extends FailedAction {
	readonly type = ManageBookingConstants.UPDATE_BOOKING_INVOICE_FAILED;
}

export class SetUpdateInvoiceModalFlag extends AppAction <{
	id: SchemeID;
	isModalOpen: boolean;
}> {
	readonly type = ManageBookingConstants.SET_UPDATE_INVOICE_MODAL_FLAG;
}

export class SetBookingChangeDatesModalFlag extends AppAction <{
	id: SchemeID;
	isModalOpen: boolean;
}> {
	readonly type = ManageBookingConstants.SET_BOOKING_CHANGE_DATES_MODAL_FLAG;
}

export class IsUpdateBookingSuccess extends AppAction <{
	id: SchemeID;
	isUpdateSuccess: boolean;
}> {
	readonly type = ManageBookingConstants.IS_UPDATE_BOOKING_SUCCESS;
}

export class IsUpdateBookingFailed extends AppAction <{
	id: SchemeID;
	isUpdateFailed: boolean;
}> {
	readonly type = ManageBookingConstants.IS_UPDATE_BOOKING_FAILED;
}

export class UpdateManageBooking extends AppAction <{
	id: SchemeID;
	newScheduleItemId: SchemeID;
}> {
	readonly type = ManageBookingConstants.UPDATE_MANAGE_BOOKING;
}

export class UpdateManageBookingSuccess extends AppAction <{
	id: SchemeID;
	booking: ManageBooking;
}> {
	readonly type = ManageBookingConstants.UPDATE_MANAGE_BOOKING_SUCCESS;
}

export class UpdateManageBookingFailed extends FailedAction {
	readonly type = ManageBookingConstants.UPDATE_MANAGE_BOOKING_FAILED;
}

export class DownloadTicketsSeperately extends AppAction <{
	ticketsInfo: MailTicketsPdf;
}> {
	readonly type = ManageBookingConstants.DOWNLOAD_TICKETS_SEPERATELY;
}

export class DownloadTicketsSeperatelySuccess extends AppAction <{
}> {
	readonly type = ManageBookingConstants.DOWNLOAD_TICKETS_SEPERATELY_SUCCESS;
}

export class DownloadTicketsSeperatelyFailed extends FailedAction {
	readonly type = ManageBookingConstants.DOWNLOAD_TICKETS_SEPERATELY_FAILED;
}


export type Actions =
	GetUserManageBooking
	| GetUserManageBookingSuccess
	| GetUserManageBookingFailed
	| UpdateBookingInvoice
	| UpdateBookingInvoiceSuccess
	| UpdateBookingInvoiceFailed
	| SetUpdateInvoiceModalFlag
	| UpdateManageBooking
	| UpdateManageBookingSuccess
	| UpdateManageBookingFailed
	| SetBookingChangeDatesModalFlag
	| IsUpdateBookingSuccess
	| IsUpdateBookingFailed
	| DownloadTicketsSeperately
	| DownloadTicketsSeperatelySuccess
	| DownloadTicketsSeperatelyFailed;
