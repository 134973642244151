import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { DatesService } from '@app/services/dates/dates.service';
import { ReportItem, ReportSheet, ReportGroupingLevel } from '@app/models/report.model';
import { SchemeID } from '@app/models/dataSchema.model';

@Injectable()
export class ReportService {
	private static maxDaysPerGroup = 31;
	private static maxMonthsPerGroup = 1;
	static yearKeyFormat = 'YYYY';
	static monthKeyFormat = 'YYYY-MM';
	static dayKeyFormat = 'YYYY-MM-DD';

	static groupingLevelValue = {
		[ReportGroupingLevel.DAY]: 0,
		[ReportGroupingLevel.MONTH]: 1,
		[ReportGroupingLevel.YEAR]: 2,
	};

	constructor(
		private datesService: DatesService
	) { }

	getReports(collection: ReportItem[], startDate: Date, endDate: Date) {
		const reportSheet: ReportSheet = {
			day: {
				byId: {},
				all: [],
			},
			month: {
				byId: {},
				all: [],
			},
			year: {
				byId: {},
				all: [],
			},
			from: null,
			to: null,
			total: 0,
			topLevelGrouping: ReportGroupingLevel.DAY,
		};

		if (collection && collection.length) {
			const { from, to } = this.getEdgeDatesForCollection(collection, startDate, endDate);

			reportSheet.from = from;
			reportSheet.to = to;

			const dates = this.datesService.getDates(from, to);

			dates.forEach(date => {
				const momentDate = moment(date);
				const yearKey = momentDate.format(ReportService.yearKeyFormat);
				const monthKey = momentDate.format(ReportService.monthKeyFormat);
				const dayKey = momentDate.format(ReportService.dayKeyFormat);

				if (!reportSheet.year.byId[yearKey]) {
					reportSheet.year.byId[yearKey] = {
						subitems: [],
						total: 0,
					};

					reportSheet.year.all.push(yearKey);
				}


				if (!reportSheet.month.byId[monthKey]) {
					reportSheet.month.byId[monthKey] = {
						subitems: [],
						total: 0,
					};

					reportSheet.month.all.push(monthKey);
					reportSheet.year.byId[yearKey].subitems.push(monthKey);
				}


				if (!reportSheet.day.byId[dayKey]) {
					reportSheet.day.byId[dayKey] = {
						total: 0,
					};

					reportSheet.day.all.push(dayKey);
					reportSheet.month.byId[monthKey].subitems.push(dayKey);
				}

			});

			collection.forEach(item => {
				const itemDate = moment(item.date);
				const yearKey = itemDate.format(ReportService.yearKeyFormat);
				const monthKey = itemDate.format(ReportService.monthKeyFormat);
				const dayKey = itemDate.format(ReportService.dayKeyFormat);

				if (!reportSheet.year.byId[yearKey]) {
					reportSheet.year.byId[yearKey] = {
						total: 0,
					};
				}
				reportSheet.year.byId[yearKey].total += item.value;
				if (!reportSheet.month.byId[monthKey]) {
					reportSheet.month.byId[monthKey] = {
						total: 0,
					};
				}
				reportSheet.month.byId[monthKey].total += item.value;
				if (!reportSheet.day.byId[dayKey]) {
					reportSheet.day.byId[dayKey] = {
						total: 0,
					};
				}
				reportSheet.day.byId[dayKey].total = item.value;
				reportSheet.total += item.value;
			});

			if (this.isReportGroupedByMonth(reportSheet)) {
				reportSheet.topLevelGrouping = ReportGroupingLevel.MONTH;
			}

			// if (this.isReportGroupedByYear(reportSheet)) {
			// 	reportSheet.topLevelGrouping = ReportGroupingLevel.YEAR;
			// }

		}

		return reportSheet;
	}

	getEdgeDatesForCollection(collection: { date: Date; value: number }[], start, end): { from: Date; to: Date } {
		const from = start || (collection && collection[0] && collection[0].date);
		const to = end || (collection && collection[collection.length - 1] && collection[collection.length - 1].date);
		return {
			from: moment(from).startOf('day').toDate(),
			to: moment(to).startOf('day').toDate(),
		};
	}

	getYearsReport(reportSheet: ReportSheet) {
		return reportSheet.year.all.map(key => ({
			key,
			value: reportSheet.year.byId[key].total,
		}));
	}

	getMonthsReport(reportSheet: ReportSheet, yearKey?: SchemeID) {
		const months = yearKey
			? reportSheet.year.byId[yearKey]
				? reportSheet.year.byId[yearKey].subitems
				: []
			: reportSheet.month.all;

		return months.map(key => ({
			key,
			value: reportSheet.month.byId[key].total,
		}));
	}

	getDaysReport(reportSheet: ReportSheet, monthKey?: SchemeID) {
		const days = monthKey
			? reportSheet.month.byId[monthKey]
				? reportSheet.month.byId[monthKey].subitems
				: []
			: reportSheet.day.all;

		return days.map(key => ({
			key,
			value: reportSheet.day.byId[key].total,
		}));
	}

	getDaysReportTotal(reportSheet: ReportSheet, monthKey?: SchemeID) {
		return monthKey
			? reportSheet.month.byId[monthKey]
				? reportSheet.month.byId[monthKey].total
				: 0
			: reportSheet.total;
	}

	getTopLevelGroupingReport(reportSheet: ReportSheet) {
		switch (reportSheet.topLevelGrouping) {
			case ReportGroupingLevel.DAY:
				return this.getDaysReport(reportSheet);
			case ReportGroupingLevel.MONTH:
				return this.getMonthsReport(reportSheet);
			case ReportGroupingLevel.YEAR:
				return this.getYearsReport(reportSheet);
			default:
				return this.getDaysReport(reportSheet);
		}
	}

	getGroupingReport(reportSheet: ReportSheet, groupingLevel: ReportGroupingLevel, key: SchemeID) {
		switch (groupingLevel) {
			case ReportGroupingLevel.DAY:
				return this.getDaysReport(reportSheet, key);
			case ReportGroupingLevel.MONTH:
				return this.getMonthsReport(reportSheet, key);
			case ReportGroupingLevel.YEAR:
				return this.getYearsReport(reportSheet);
			default:
				return this.getDaysReport(reportSheet, key);
		}
	}

	isReportGroupedByYear(reportSheet: ReportSheet) {
		return reportSheet.year.all.length > ReportService.maxMonthsPerGroup;
	}

	isReportGroupedByMonth(reportSheet: ReportSheet) {
		return reportSheet.day.all.length > ReportService.maxDaysPerGroup;
	}

	getNextGroupingLevel(groupingLevel: ReportGroupingLevel) {
		switch (groupingLevel) {
			case ReportGroupingLevel.DAY:
				return null;
			case ReportGroupingLevel.MONTH:
				return ReportGroupingLevel.DAY;
			case ReportGroupingLevel.YEAR:
				return ReportGroupingLevel.MONTH;
		}
	}

	getPreviousGroupingLevel(groupingLevel: ReportGroupingLevel) {
		switch (groupingLevel) {
			case ReportGroupingLevel.DAY:
				return ReportGroupingLevel.MONTH;
			default:
				return null;
		}
	}

	getPreviousSelectedKey(currentKey: SchemeID, prevGroupingLevel: ReportGroupingLevel): SchemeID {
		return null;
		// switch (prevGroupingLevel) {
		// 	case ReportGroupingLevel.MONTH:
		// 		return moment(currentKey, ReportService.monthKeyFormat).format(ReportService.yearKeyFormat);
		// 	case ReportGroupingLevel.YEAR:
		// 	case ReportGroupingLevel.DAY:
		// 		return null;
		// }
	}

	isGroupScalingPossible(topLevelGrouping: ReportGroupingLevel, desiredLevel: ReportGroupingLevel) {
		return ReportService.groupingLevelValue[topLevelGrouping] >= ReportService.groupingLevelValue[desiredLevel];
	}
}
