/* eslint-disable @typescript-eslint/no-shadow */
import { Component, EventEmitter, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SchemeID } from '@app/models/dataSchema.model';
import { HeaderContent, DropdownOption } from '@app/models/shared';
import { InternalURLCreator } from '@app/services/url/url.dictionary';
import * as moment from 'moment';
import { Guest, PaymentCollectionBy, PaymentState, ViewOrder } from '@app/models/order.model';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { StoreService } from '@app/services/store/store.service';
import { GetOrderDetails, ResendOrderTickets } from '@app/store/actions/event/event.actions';
import { EventActionsConstants } from '@app/store/actions/event/event.actions.constants';
import { EVENT_ID_PARAM_KEY, ORDER_OPTIONS, OrderActionType } from '@app/utils/consts';
import { Actions, ofType } from '@ngrx/effects';
import { filter, take, takeUntil } from 'rxjs';
import * as eventSelectors from '@app/store/selectors/event.selector';
import { MatDialog } from '@angular/material/dialog';
import { OrderTicketsModalComponent } from '../../modals/order-tickets-modal/order-tickets-modal.component';
import { OrderModalType } from '@app/models/event.model';
import { ActivatedRoute } from '@angular/router';
import { ResendTicketsModalComponent } from '../../modals/resend-tickets-modal/resend-tickets-modal.component';
import { Dictionary } from 'lodash';
import { MarkAsPaidModalComponent } from '../../modals/mark-as-paid-modal/mark-as-paid-modal.component';

@Component({
	selector: 'app-order-details',
	templateUrl: './order-details.component.html',
	styleUrls: ['./order-details.component.sass'],
})
export class OrderDetailsComponent {
	url: string;
	eventId: SchemeID;
	orderId: SchemeID;
	isMobile = false;
	isLoading = true;
	isTableLoading = false;
	order: ViewOrder;
	headerContent: HeaderContent;
	defaultOptions: Dictionary<DropdownOption> = ORDER_OPTIONS;
	options: Dictionary<DropdownOption>;
	downloadLink: string;
	paymentState = PaymentState;
	isPaid: boolean;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	guests: MatTableDataSource<Guest> = new MatTableDataSource();

	displayColumns: string[] = ['ticketId', 'barcode', 'ticketHolder', 'type', 'amount', 'status'];
	pageSizeOptions = [10, 15, 20];
	paginatorInitialized = false;
	sortInitialized = false;

	@ViewChild('productsPaginator') set paginator(_pagination: MatPaginator) {
		if (_pagination && !this.paginatorInitialized) {
			this.guests.paginator = _pagination;
		}
	}

	@ViewChild(MatSort) set sort(_sort: MatSort) {
		if (_sort && !this.sortInitialized) {
			this.sortInitialized = true;
			this.guests.sort = _sort;
			this.guests.sortingDataAccessor = (item, property) => item[property];
		}
	}
	constructor(
		private activatedRoute: ActivatedRoute,
		private breakpointService: BreakpointService,
		private store: StoreService,
		private actions$: Actions,
		private dialog: MatDialog
	) {}
	ngOnInit(): void {
		this.options = { ...this.defaultOptions };
		this.activatedRoute.parent.paramMap.pipe(take(1)).subscribe((params) => {
			this.eventId = +params.get(EVENT_ID_PARAM_KEY);
			this.activatedRoute.queryParams.pipe(takeUntil(this.destroyed$)).subscribe((queryParams) => {
				if (queryParams['orderId']) {
					this.orderId = queryParams['orderId'];
					this.headerContent = {
						breadCrumbs: [
							{
								routeName: 'Guest Management',
								routeTo: () => InternalURLCreator.guestManagement(this.eventId),
							},
							{
								routeName: 'Orders',
								routeTo: () => InternalURLCreator.orders(this.eventId),
							},
							{
								routeName: 'Order Details',
							},
						],
						title: `Order #${this.orderId}`,
						description: 'View and edit your selected order below.',
					};
					this.store.dispatch(new GetOrderDetails({ orderId: this.orderId, eventId: this.eventId }));
				}
			});

			this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
				this.isMobile = isMobile;
			});
		});

		this.initialiseOrderDetails();
	}

	initialiseOrderDetails() {
		this.store
			.select(eventSelectors.eventOrderDetails(this.eventId))
			.pipe(
				takeUntil(this.destroyed$),
				filter((order) => order?.orderId === +this.orderId)
			)
			.subscribe((order) => {
				if (order) {
					this.order = order;
					this.guests.data = this.order.guests;
					this.isPaid = !!(this.order.paymentState === PaymentState.PAID || this.order.paymentState === PaymentState.TRANSFER);
					this.downloadLink = this.order.actions?.printTickets;
					this.options = { ...this.defaultOptions };

					if (this.downloadLink && !this.displayColumns.includes('download')) {
						this.displayColumns.push('download');
					}
					if (!this.isPaid) {
						delete this.options[OrderActionType.Resend];
						delete this.options[OrderActionType.Download];
						delete this.options[OrderActionType.ChangeType];
					}
					if (
						!(
							this.order.canRefund &&
							(this.order.paymentState === PaymentState.PAID ||
								this.order.paymentState === PaymentState.TRANSFER ||
								this.order.paymentState === PaymentState.COMPLIMENTARY ||
								this.order.paymentState === PaymentState.RSVP_ATTENDING)
						)
					) {
						delete this.options[OrderActionType.Cancel];
					}
					if (this.order.paid || this.order.paymentCollectionBy !== PaymentCollectionBy.ORGANISER) {
						delete this.options[OrderActionType.MarkAsPaid];
					}
				}
			});

		this.actions$
			.pipe(
				ofType(
					EventActionsConstants.GET_ORDER_DETAILS_SUCCESS,
					EventActionsConstants.GET_ORDER_DETAILS_FAILED,
					EventActionsConstants.RESEND_ORDER_TICKETS_SUCCESS,
					EventActionsConstants.RESEND_ORDER_TICKETS_FAILED
				),
				takeUntil(this.destroyed$)
			)
			.subscribe(() => {
				this.isLoading = false;
				this.isTableLoading = false;
			});
	}

	handleTicketsModal(modalType: OrderModalType) {
		this.dialog
			.open(OrderTicketsModalComponent, {
				data: {
					isMobile: this.isMobile,
					type: modalType,
					order: this.order,
					eventId: this.eventId,
					orderId: this.orderId,
				},
				panelClass: this.isMobile ? 'g-full-page-dialog' : 'g-wide-dialog',
			})
			.afterClosed()
			.subscribe((result: boolean) => {
				if (result) {
					this.isTableLoading = true;
					this.store.dispatch(new GetOrderDetails({ orderId: this.orderId, eventId: this.eventId }));
				}
			});
	}

	formatDate(inputDateString: string) {
		return moment(inputDateString).format('MMM D, YYYY h:mm:ss A');
	}

	openResendTicketsModal() {
		this.dialog
			.open(ResendTicketsModalComponent, {
				data: {
					isMobile: this.isMobile,
					email: this.order.email,
				},
				panelClass: 'g-standard-dialog',
			})
			.afterClosed()
			.subscribe((result) => {
				if (result) {
					this.store.dispatch(new ResendOrderTickets({ purchaseId: this.order.orderId, email: result }));
					this.isLoading = true;
				}
			});
	}

	handleAction(option: DropdownOption) {
		switch (option.value) {
			case OrderActionType.Resend:
				this.openResendTicketsModal();
				break;
			case OrderActionType.Edit:
				const editLink = this.order.actions?.editAttendeeInfo;
				if (editLink) {
					window.open(editLink, '_blank');
				}
				break;
			case OrderActionType.Download:
				if (this.downloadLink) {
					window.open(this.downloadLink, '_blank');
				}
				break;
			case OrderActionType.ChangeType:
				this.handleTicketsModal(OrderModalType.ChangeTicketTypes);
				break;
			case OrderActionType.Cancel:
				this.handleTicketsModal(OrderModalType.CancelTickets);
				break;
			case OrderActionType.MarkAsPaid:
				this.dialog
					.open(MarkAsPaidModalComponent, {
						data: {
							isMobile: this.isMobile,
							eventId: this.eventId,
							orderId: this.orderId,
							gateways: this.order.gateways,
							cartAmount: this.order.currencySymbol + this.order.cartTotal,
							productPaymentGatewayDetailsLinkId: this.order.productPaymentGatewayDetailsLinkId,
						},
						panelClass: 'g-standard-dialog',
					})
					.afterClosed()
					.subscribe((result) => {
						if (result) {
							this.isTableLoading = true;
							this.store.dispatch(new GetOrderDetails({ orderId: this.orderId, eventId: this.eventId }));
						}
					});
		}
	}

	handleTicketDownload(ticketId: string) {
		if (this.downloadLink) {
			const singleLink = this.downloadLink + '&pits=' + ticketId;
			window.open(singleLink, '_blank');
		}
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
