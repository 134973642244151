import { NgModule } from '@angular/core';
import { TextFieldComponent } from './text-field/text-field.component';
import { SelectFieldComponent } from './select-field/select-field.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import { TextEditorComponent } from './text-editor/text-editor.component';
import { FileUploadFieldComponent, FileUploadImageComponent } from './file-upload-field/file-upload-field.component';
import { TextareaFieldComponent } from './textarea-field/textarea-field.component';
import { NumberFieldComponent } from './number-field/number-field.component';
import { NumberFieldCoreComponent } from './number-field/number-field-core/number-field-core.component';
import { PriceFieldComponent } from './price-field/price-field.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS } from '@danielmoncada/angular-datetime-picker';
import { DateTimeFieldComponent } from './datetime-field/datetime-field.component';
import { UIKitModule } from '@app/shared/uikit.module';
import { SlideFieldComponent } from './slide-field/slide-field.component';
import { AutoCompleteComponent } from './autocomplete-field/autocomplete-field.component';
import { ColorPickerFieldComponent } from './color-picker/color-picker.component';
import { DateRangeFieldComponent } from './date-range-field/date-range-field.component';
import { RadioFieldComponent } from './radio-field/radio-field.component';
import { CheckboxFieldComponent } from './checkbox/checkbox.component';
import { ImageCroppperFieldComponent } from './image-cropper/image-cropper.component';

const declaredComponents = [
	TextFieldComponent,
	SelectFieldComponent,
	TextEditorComponent,
	TextareaFieldComponent,
	NumberFieldComponent,
	NumberFieldCoreComponent,
	PriceFieldComponent,
	DateTimeFieldComponent,
	DateRangeFieldComponent,
	FileUploadFieldComponent,
	FileUploadImageComponent,
	SlideFieldComponent,
	AutoCompleteComponent,
	ColorPickerFieldComponent,
	RadioFieldComponent,
	CheckboxFieldComponent,
	ImageCroppperFieldComponent,
];

@NgModule({
	imports: [
		UIKitModule,
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		TextMaskModule,
		OwlDateTimeModule,
		OwlNativeDateTimeModule,
	],
	declarations: declaredComponents,
	exports: [
		ReactiveFormsModule,
		...declaredComponents,
	],
	providers: [
		{
			provide: OWL_DATE_TIME_FORMATS,
			useValue: {
				fullPickerInput: {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: 'numeric', hour12: false},
				datePickerInput: {year: 'numeric', month: 'numeric', day: 'numeric', hour12: false},
				timePickerInput: {hour: '2-digit', minute: '2-digit', hour12: false},
				monthYearLabel: {year: 'numeric', month: 'short', hour12: false},
				dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric', hour12: false},
				monthYearA11yLabel: {year: 'numeric', month: 'long', hour12: false},
			},
		},
	],
}) export class FormFieldModule {}
