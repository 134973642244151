import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class DatesService {
	constructor() { }

	static defaultDateTimeFormat = 'YYYY-MM-DD HH:mm';

	convertToUTCAndDefaultFormat(date: Date) {
		return moment.utc(date).format(DatesService.defaultDateTimeFormat);
	}

	convertToDateAndDefaultFormat(date: Date) {
		return moment(date).format(DatesService.defaultDateTimeFormat);
	}

	getDates(startDate: Date, endDate: Date): Date[] {
		const now = moment(startDate);
		const end = moment(endDate);
		const dates = [];

		while (now.isSameOrBefore(end)) {
			dates.push(now.clone().toDate());
			now.add(1, 'days');
		}

		return dates;
	}

	getMonthsStartDates(from: Date, to: Date) {
		const dates = this.getDates(from, to);
		const monthsStartDays: Date[] =
			dates.reduce((acc, d) => {
				const isMonthAdded = acc
					.some(el => el.getTime() === moment(d).startOf('month').toDate().getTime());

				if (!isMonthAdded) {
					acc.push(moment(d).startOf('month').toDate());
				}

				return acc;
			}, []);

		return monthsStartDays;
	}

	populateMonthDaysFromStarts(monthsStartDays: Date[]) {
		return monthsStartDays
			.map(el => this.getDates(el, moment(el).endOf('month').toDate()));
	}

	getMonthDates(from: Date, to: Date) {
		return this.populateMonthDaysFromStarts(
			this.getMonthsStartDates(from, to)
		);
	}

	getUniqueUnitOfTimeFromCollection(collection: Date[], unitOfTime: moment.unitOfTime.StartOf = 'month'): { from: Date; to: Date }[] {
		const uniqueMonths: { from: Date; to: Date }[] = collection.reduce((acc, d) => {
			const isMonthAdded = acc.some(el => el.from.getTime() === moment(d).startOf(unitOfTime).toDate().getTime());

			if (!isMonthAdded) {
				acc.push({
					from: moment(d).startOf(unitOfTime).toDate(),
					to: moment(d).endOf(unitOfTime).toDate(),
				});
			}

			return acc;
		}, []);

		return uniqueMonths;
	}

	isDatesMatchDiff(
		startTime: Date,
		endTime: Date,
		diff: number,
		timeEndingDay: number
	): boolean {
		return moment(endTime)
			.add(timeEndingDay, 'days')
			.diff(moment(startTime), 'minutes') >= diff;
	}

	getStartOfTheDay(date?: Date): Date {
		return moment().hours(0).minutes(0).seconds(0).milliseconds(0).toDate();
	}

	getEndOfTheDay(date?: Date): Date {
		return moment().hours(23).minutes(59).seconds(59).milliseconds(999).toDate();
	}

	getRangeUpToDate(rangeLength: number, baseDate?: Date): Date[] {
		if (rangeLength === undefined || rangeLength === null) {
			return [
				null,
				null,
			];
		}

		return [
			moment(this.getStartOfTheDay(baseDate))
				.subtract(rangeLength, 'days')
				.toDate(),
			this.getEndOfTheDay(baseDate),
		];
	}
}
