<div
	*ngIf='!isLoading; else showLoading'
	[ngClass]="{'l-container': true, 'l-container-mobile-pad': isMobile}"
>
	<div class="l-use-groups pt-3">
		<label class="g-form-label px-3 l-label">Use Ticket Groups
			<mat-icon
				class="l-icon"
				[matTooltip]="useTicketGroupsTooltip"
				matTooltipPosition="right"
				#ticketGroupTooltip="matTooltip"
				(click)="ticketGroupTooltip.toggle()"
			>info</mat-icon>
		</label>
		<mat-slide-toggle
			class="standard-toggle px-4"
			[checked]="useTicketGroups"
			(change)="toggleUseTicketGroups($event)"
			name="useTicketGroups"
			id="use-ticket-groups"
		></mat-slide-toggle>
	</div>
	<app-web-filter
		class='mt-3'
		[isMobile]="isMobile"
		searchPlaceholder="Search"
		buttonText="ADD TICKET GROUP"
		(buttonClick)="onTicketGroupFormRequest()"
		(filterOutput)='handleSearch($event.searchTerm)'
		buttonId="add-ticket-group-button"
		[isButtonDisabled]="isAddButtonDisabled"
		[disabledTooltipText]="disabledAddTicketGroupsButtonText"
	>
	</app-web-filter>
	<div
		class="l-table-container pb-5"
		*ngIf='!isLoading; else showLoading'
		cdkDropList
		(cdkDropListDropped)="onReorder($event)"
		[cdkDropListDisabled]="dragDisabled"
	>
		<div *ngIf='(ticketGroups && ticketGroups.length); else hasNoTicketGroups'>
			<div
				*ngIf='isFiltering && (filteredTicketGroups && filteredTicketGroups.length == 0); else ticketGroupTable'>
				<div class='l-empty-card'>
					<div class="l-empty-state">
						<h3 class="mb-3 l-empty-card-text">Your search does not match any current ticket groups.</h3>
					</div>
				</div>
			</div>
			<ng-template #ticketGroupTable>
				<table
					mat-table
					id="ticket-group-table"
					[dataSource]="getDataSource()"
					class='l-table web'
				>
					<ng-container matColumnDef="sorting">
						<th
							mat-header-cell
							*matHeaderCellDef
							class="l-sorting-column"
						></th>
						<td
							mat-cell
							*matCellDef="let ticketGroup"
							class="l-sorting-column"
						>
							<img
								*ngIf="!isFiltering"
								src="./assets/icons/dots-nine.svg"
								alt="Drag Icon"
								class="l-drag-icon g-move"
								(mousedown)="dragDisabled = false;"
								(touchstart)="dragDisabled = false;"
							>
						</td>
					</ng-container>
					<ng-container matColumnDef="groupName">
						<th
							mat-header-cell
							*matHeaderCellDef
						> Name </th>
						<td
							mat-cell
							*matCellDef="let ticketGroup; let i = index"
							[attr.id]="'group-name-' + (i + 1)"
						>
							<span class="g-f-14">{{ ticketGroup.name.length > 100 ? (ticketGroup.name | slice:0:25) + '...' : ticketGroup.name }}</span>
						</td>
					</ng-container>
					<ng-container matColumnDef="image">
						<th
							mat-header-cell
							*matHeaderCellDef
						>Image</th>
						<td
							mat-cell
							*matCellDef="let ticketGroup; let i = index"
							[attr.id]="'group-image-' + (i + 1)"
						>
							<img
								[src]="getImagePreview(ticketGroup.thumbnail)"
								*ngIf="ticketGroup.thumbnail"
								class="l-image"
							>
						</td>
					</ng-container>
					<ng-container matColumnDef="limitedTickets">
						<th
							mat-header-cell
							*matHeaderCellDef
						>Ticket Types In Group</th>
						<td
							mat-cell
							*matCellDef="let ticketGroup; let i = index"
							[attr.id]="'ticket-types-in-group-' + (i + 1)"
						>
							{{ticketGroup.linkedTicketTypes.length}} tickets
						</td>
					</ng-container>
					<ng-container matColumnDef="actions">
						<th
							mat-header-cell
							*matHeaderCellDef
						>Actions</th>
						<td
							mat-cell
							*matCellDef="let ticketGroup; let i = index"
						>
							<button
								mat-icon-button
								[matMenuTriggerFor]="menu"
								[attr.id]="'more-button-' + (i + 1)"
							>
								<mat-icon>more_vert</mat-icon>
							</button>
							<mat-menu #menu="matMenu">
								<button
									mat-menu-item
									(click)="onTicketGroupFormRequest(ticketGroup)"
									[attr.id]="'edit-button-' + (i + 1)"
								>
									Edit
								</button>
								<button
									mat-menu-item
									(click)="handleDeleteTicketGroup(ticketGroup.id)"
									[attr.id]="'delete-button-' + (i + 1)"
								>
									Delete
								</button>
							</mat-menu>
						</td>
					</ng-container>
					<tr
						mat-header-row
						*matHeaderRowDef="displayColumns"
					></tr>
					<tr
						mat-row
						*matRowDef="let row; columns: displayColumns;"
						id="ticket-group-row"
						cdkDrag
					></tr>
				</table>
			</ng-template>
		</div>
		<ng-template #hasNoTicketGroups>
			<div class='l-empty-card'>
				<div class="l-empty-state">
					<h3 class="mb-3 l-empty-card-text">You don't have any ticket groups. Click ADD TICKET GROUP to get
						started.</h3>
				</div>
			</div>
		</ng-template>
	</div>
</div>
<ng-template #showLoading>
	<div class="l-loading">
		<mat-spinner></mat-spinner>
	</div>
</ng-template>