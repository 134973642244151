import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SchemeID } from '@app/models/dataSchema.model';
import { HeaderContent } from '@app/models/shared';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { InternalURLCreator, URLCreator } from '@app/services/url/url.dictionary';
import { EVENT_ID_PARAM_KEY } from '@app/utils/consts';
import { take, takeUntil } from 'rxjs';

@Component({
	selector: 'app-reviews-report',
	templateUrl: './reviews-report.component.html',
	styleUrls: ['./reviews-report.component.sass'],
})
export class ReviewsReportComponent implements OnInit {
	url: string;
	eventId: SchemeID;
	isMobile = false;

	headerContent: HeaderContent = {
		breadCrumbs: [
			{
				routeName: 'Reports',
				routeTo: () => InternalURLCreator.reports(this.eventId),
			},
			{
				routeName: 'Reviews Report',
			},
		],
		title: 'Reviews Report',
		description: `Reviews are a great way to better understand what your audience is looking for and to 
		constructively improve for your next event. 
		These reviews are for your records only and are not publicly available.
		Quicket users who have a valid ticket to the event are invited to leave a review automatically when the event completes. 
		They have seven days in which to leave a review, after which no more reviews may be submitted. `,
	};

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(private activatedRoute: ActivatedRoute, private breakpointService: BreakpointService) {}

	ngOnInit(): void {
		this.activatedRoute.parent.paramMap.pipe(take(1)).subscribe((params) => {
			this.eventId = +params.get(EVENT_ID_PARAM_KEY);
			this.url = URLCreator.reviewsReport(this.eventId);
		});

		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
