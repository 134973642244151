<div class="l-container">
	<img [src]="data.imageUrl" class="l-image" />
	<div class="l-content" [ngClass]="{'flex-column': isMobile}">
		<div *ngIf="isMobile">
		</div>
		<div class="l-details">
			<div class="l-name">
				<strong>{{ data.name }}</strong>
			</div>
			<div class="l-venue">
				{{ data.venueName }}
			</div>
			<div class="l-date-time">
				<span class="l-date">{{ data.dateFrom | formattedDatePipe }}</span>
				<span class="l-time">{{ data.dateFrom | formattedTimePipe }}</span>
			</div>
		</div>
	</div>
</div>
