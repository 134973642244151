<div class="schedule-select mt-2">
	<label class="g-form-label" [ngClass]="{'l-mobile-label': isMobile}">Schedule</label>
	<mat-form-field>
		<mat-select class="form-control w-100" [(ngModel)]="selectedScheduleItem" (selectionChange)="handleEmit()"
			name="schedules" placeholder="Schedules" #singleSelect>
			<mat-option [value]="allSchedulesSelected">
				<span>All Schedules</span>
			</mat-option>
			<mat-option *ngFor="let item of scheduleItems" [value]="item">
				<ng-container *ngIf="isSameDaySchedule(item); else differentDay">
					<span>{{ item.startDate | date: 'd MMM yyyy, h:mma' }} - {{ item.endDate | date: 'h:mma' }}</span>
					<span *ngIf="item.status === ProductStatus.Finished"> (finished)</span>
				</ng-container>
				<ng-template #differentDay>
					<span>{{ item.startDate | date: 'd MMM yyyy, h:mma' }} - {{ item.endDate | date: 'd MMM yyyy, h:mma' }}</span>
					<span *ngIf="item.status === ProductStatus.Finished"> (finished)</span>
				</ng-template>
			</mat-option>
		</mat-select>
		<span matPrefix><img src='./assets/icons/schedule-selector.svg' /></span>
	</mat-form-field>
</div>
