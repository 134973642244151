<app-my-account-header
	[isMobile]='isMobile'
	pageName="Vending"
	(navigateBack)="handleNavigateBack()"
>
</app-my-account-header>
<div class="g-account-container">
	<div
		class="g-account-content h-100"
		[ngClass]="{ 'mobile': isMobile, 'web': !isMobile }"
	>
		<app-web-content-header
			*ngIf="!isMobile"
			class="w-100"
			[headerContent]="headerContent"
		></app-web-content-header>
		<app-iframe
			class="g-iframe"
			[url]='url'
		></app-iframe>
	</div>
</div>