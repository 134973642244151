import { Component, EventEmitter } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { HeaderContent } from '@app/models/shared';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { StoreService } from '@app/services/store/store.service';
import { InternalURLCreator } from '@app/services/url/url.dictionary';
import { GetEventDefinitions, UpdateEventDefinitions } from '@app/store/actions/event/event.actions';
import { EventActionsConstants } from '@app/store/actions/event/event.actions.constants';
import { EVENT_ID_PARAM_KEY } from '@app/utils/consts';
import * as eventSelectors from '@app/store/selectors/event.selector';
import { Actions, ofType } from '@ngrx/effects';
import { Observable, take, takeUntil } from 'rxjs';
import { maxLength, minLength, onlyLettersAndSpaces, requiredValidator } from '@app/shared/form-field/form-validators';
import { EventDefinitions } from '@app/models/event.model';
import { FormSchemeControls, FormValidators } from '@app/models/common.model';
import { FormService } from '@app/services/form/form.service';
import { FormCanDeactivate } from '@app/services/guards/event.guard';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '@app/shared/modals/confirmation-modal/confirmation-modal.component';

@Component({
	selector: 'app-definitions',
	templateUrl: './definitions.component.html',
	styleUrls: ['./definitions.component.sass'],
})
export class DefinitionsComponent extends FormCanDeactivate{

	eventId: number;
	isMobile = false;
	form: UntypedFormGroup;
	isLoading = false;
	isSavingChanges = false;
	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	headerContent: HeaderContent = {
		breadCrumbs: [
			{
				routeName: 'Settings',
				routeTo: () => InternalURLCreator.settings(this.eventId),
			},
			{
				routeName: 'Definitions',
			},
		],
		title: 'Definitions',
		description: 'Customize your event page by changing the default wording below.',
	};

	// eslint-disable-next-line
	desktopToolTip = 'If you have a seating plan, you can change the default instructions we have for booking seats on desktop in the ticket selector.';
	// eslint-disable-next-line
	mobileToolTip = 'If you have a seating plan, you can change the default instructions we have for booking seats on a mobile device in the ticket selector.';

	constructor(
		private breakpointService: BreakpointService,
		private activatedRoute: ActivatedRoute,
		private store: StoreService,
		private action$: Actions,
		private formService: FormService,
		private dialog: MatDialog
	) {
		super();
	}

	ngOnInit(): void {
		this.isLoading = true;

		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});

		this.activatedRoute.parent.paramMap.pipe(take(1)).subscribe((params) => {
			this.eventId = +params.get(EVENT_ID_PARAM_KEY);
		});

		this.initializeEventDefinitions();
		this.action$
			.pipe(
				ofType(EventActionsConstants.GET_EVENT_DEFINITIONS_SUCCESS, EventActionsConstants.GET_EVENT_DEFINITIONS_FAILED),
				takeUntil(this.destroyed$)
			)
			.subscribe(() => {
				this.isLoading = false;
			});

		this.action$
			.pipe(
				ofType(EventActionsConstants.UPDATE_EVENT_DEFINITIONS_SUCCESS, EventActionsConstants.UPDATE_EVENT_DEFINITIONS_FAILED),
				takeUntil(this.destroyed$)
			)
			.subscribe(() => {
				this.isSavingChanges = false;
			});

		this.store.select(eventSelectors.eventDefinitions(this.eventId)).pipe(takeUntil(this.destroyed$)).subscribe((definitions) => {
			if (definitions){
				this.form = this.createEventDefinitionsForm(definitions);
			}
		});
	}

	createEventDefinitionsForm = (initialValues?: Partial<EventDefinitions>) => {
		const formValues = new EventDefinitions();

		const validatorsConfig: FormValidators<Partial<EventDefinitions>> = {
			event: [
				requiredValidator(),
				maxLength(20, 'Maximum length is 20 characters'),
				onlyLettersAndSpaces()],
			events: [
				requiredValidator(),
				maxLength(23, 'Maximum length is 23 characters'),
				onlyLettersAndSpaces()],
			ticket: [
				requiredValidator(),
				maxLength(20, 'Maximum length is 20 characters'),
				onlyLettersAndSpaces()],
			tickets: [
				requiredValidator(),
				maxLength(23, 'Maximum length is 23 characters'),
				onlyLettersAndSpaces()],
			buyer: [
				requiredValidator(),
				maxLength(20, 'Maximum length is 20 characters'),
				onlyLettersAndSpaces()],
			buttonText: [
				requiredValidator(),
				maxLength(16, 'Maximum length is 16 characters'),
				minLength(2, 'Minimum length is 2 characters'),
				onlyLettersAndSpaces(),
			],
			/* tslint:disable */
			// register: [
			// 	requiredValidator(),
			// 	maxLength(70, 'Maximum length is 70 characters'),
			// 	onlyLettersAndSpaces()],
			// usePromoCode: [
			// 	requiredValidator(),
			// 	maxLength(50, 'Maximum length is 50 characters'),
			// 	onlyLettersAndSpaces()
			// ],
			// enterPromoCode: [
			// 	requiredValidator(),
			// 	maxLength(70, 'Maximum length is 70 characters'),
			// 	onlyLettersAndSpaces()
			// ],
			// chooseYourSeats : [
			// 	requiredValidator(),
			// 	maxLength(40, 'Maximum length is 40 characters'),
			// 	minLength(2, 'Minimum length is 2 characters'),
			// 	onlyLettersAndSpaces(),
			// ],
			// selectYourMerchandise : [
			// 	requiredValidator(),
			// 	maxLength(40, 'Maximum length is 40 characters'),
			// 	minLength(2, 'Minimum length is 2 characters'),
			// 	onlyLettersAndSpaces(),
			// ],
			/* tslint:enable */
			howToBookDesktop : [
				maxLength(5000, 'Maximum length is 5000 characters'),
			],
			howToBookMobile : [
				maxLength(5000, 'Maximum length is 5000 characters'),
			],
		};
		const formConfig: Partial<FormSchemeControls<EventDefinitions>> = this.formService.createFormControls({
			formValues,
			initialValues,
			validatorsConfig,
		});

		return this.formService.formBuilder.group(formConfig);
	};

	fromForm(controlKey: string): AbstractControl{
		if (this.form) {
			return this.form.get(controlKey);
		}
		return null;
	}

	isSubmitAllowed = (form: UntypedFormGroup): boolean => {
		if (form) {
			return this.form.valid && !this.isSavingChanges && !this.form.pristine;
		}
		return false;
	};

	initializeEventDefinitions(){
		this.store.dispatch(new GetEventDefinitions({id: this.eventId}));
	}

	saveDefinitions = (definitions: EventDefinitions) => {
		if (definitions) {
			this.isSavingChanges = true;
			this.store.dispatch(new UpdateEventDefinitions({
				id: this.eventId,
				definitions: definitions,
			}));
		}
	};

	getError(errors: { [key: string]: string }) {
		return  errors && errors[Object.keys(errors)[0]];
	}

	isFormDeactivatable(): boolean | Observable<boolean> {
		return !this.form || this.form.pristine;
	}

	deactivationFallback(): Observable<boolean> {
		this.dialog
			.open(ConfirmationModalComponent, {
				data: {
					title: 'Unsaved Changes',
					text: 'Are you sure you want to leave this page? Any unsaved changes will be discarded.',
					buttonText: 'LEAVE',
					centerText: true,
					isMobile: this.isMobile,
				},
				panelClass: 'g-standard-dialog',
			})
			.afterClosed()
			.subscribe((result) => {
				this.closeDeactivationModal(result);
			});
		return this.modalCallback.asObservable();
	}

	closeDeactivationModal(isConfirmed: boolean): void {
		this.modalCallback.next(isConfirmed);
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
