import { Injectable } from '@angular/core';
import { omit, isEqual } from 'lodash';
import 'moment-timezone';
import { OrganiserProfileservice } from '../organiser-profile/organiser-profile.service';
import { EventEntity, UserContactInfoForm } from '@app/models/event.model';
import { EventDetailsService } from '../event-details/event-details.service';
import { TicketsService } from '../tickets/tickets.service';
import { SeatingCategory } from '@app/models/ticket.model';
import { FormService, ValidatorsConfig } from '@app/services/form/form.service';
import { ValidatorFn, UntypedFormGroup } from '@angular/forms';
import { phoneValidator, ValidationMessage } from '@app/shared/form-field/form-validators';

@Injectable()
export class EventCreationService {
	constructor(
		private organiserProfileService: OrganiserProfileservice,
		private eventDetailsService: EventDetailsService,
		private ticketsService: TicketsService,
		private formService: FormService
	) { }

	getInitialEventStep(event: EventEntity, seatingCategories: SeatingCategory[]): number {
		const emptyEvent = new EventEntity();
		let step = null;

		if (!isEqual(event.profile, emptyEvent.profile) && this.organiserProfileService.createEventProfileForm(event.profile).valid) {
			step = 1; // 0
			if (!isEqual(omit(event.details, ['timezone', 'name']), omit(emptyEvent.details, ['timezone', 'name']))
			&& this.eventDetailsService.createEventDetailsForm(event.details).form.valid) {
				step = 2; // 1
				if (!isEqual(event.ticketsDetails, emptyEvent.ticketsDetails)
					&& this.ticketsService.createEventTicketsForm(event.ticketsDetails, seatingCategories).valid) {
					step = 2;
				}
			}
		}

		return step;
	}

	createEventUserInfoForm(
		initialValues?: Partial<UserContactInfoForm>
	) {
		const formValues = new UserContactInfoForm();

		const formValidator = (msg: string): ValidatorFn =>
			(group: UntypedFormGroup): ValidationMessage => {
				const { firstName, surname, phone }: UserContactInfoForm = group.getRawValue();
				if (!(firstName || '').trim() && !(surname || '').trim() && !(phone || '').trim()) {
					return {
						'form': msg,
					};
				}
				return null;
			};

		const validatorsConfig: ValidatorsConfig<UserContactInfoForm> = {
			phone: [phoneValidator('Incorrect phone number')],
		};

		const formConfig = this.formService.createFormControls({
			formValues,
			initialValues,
			validatorsConfig,
		});

		const formGroup = this.formService.formBuilder.group(
			formConfig,
			{ validator: [formValidator('At least one field should be filled in')] }
		);

		return formGroup;
	}
}
