<div
	class="l-container"
	[class.mobile]="data.isMobile"
>
	<h3 class="mb-3 text-center">{{data.title}}</h3>
	<p
		class="mb-4"
		[class.text-center]='data.centerText'
	>{{data.text}}</p>
	<div *ngIf="confirmationTextControl" class="l-input">
		<app-mat-text-control-field
			[label]="'Please type ' + data.validationPattern + ' into the box below to continue.'"
			[control]="confirmationTextControl"
			placeholder="DELETE"
			customId="delete-confirmation-input"
			required="true"
		></app-mat-text-control-field>
	</div>
	<div class="l-buttons-container">
		<button
			[id]="formatId(data.buttonText)"
			*ngIf="data.buttonText"
			class="g-secondary-button"
			(click)="handleCancel()"
		>{{ data.cancelText || 'CANCEL' }}</button>
		<button
			id="cancel"
			[disabled]="confirmationTextControl ? confirmationTextControl.invalid : data.isDisabled"
			class="g-primary-button"
			[ngClass]="{'disabled': confirmationTextControl ? confirmationTextControl.invalid : data.isDisabled}"
			(click)="handleDelete()"
		>{{data.buttonText}}</button>
	</div>
</div>