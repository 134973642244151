import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AdditionalInfo } from '@app/models/user.model';
import { emailRegex } from '@app/utils/regex';

@Component({
	selector: 'app-additional-info',
	templateUrl: './additional-info.component.html',
	styleUrls: ['./additional-info.component.sass'],
})
export class AdditionalInfoComponent implements OnInit {

	@Input() email: string;
	@Input() isPublic = false;
	@Input() isBankAccountModal = true;
	@Input() allowPublicResale = true;
	@Input() allowPrivateResale = true;
	@Input() isMobile: boolean;

	emailRegex: RegExp = emailRegex;

	@Output() additionalInfo = new EventEmitter<AdditionalInfo>();

	ngOnInit(): void {
		this.emitAdditionalInfo(this.allowPublicResale && !this.allowPrivateResale);
	}

	emitAdditionalInfo(valid?: boolean) {
		let isValid = valid;

		if (isValid === undefined) {
			isValid = true;
			if (!this.isPublic && this.email) {
				isValid = this.email.match(this.emailRegex) !== null;
			}
		}

		if (this.isPublic) {
			this.email = '';
		}

		this.additionalInfo.emit({
			email: this.email,
			isPublic: this.isPublic,
			isValid,
		});
	}
}
