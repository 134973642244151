<div class="l-container">
	<a href="/" class="header-logo-link">
		<img class="header-logo" [src]="QUICKET_LOGO" alt="logo">
	</a>
	<button *ngIf="currentNavItem" class='g-primary-button l-nav-button'>
		<img [src]="currentNavItem.iconPath + '-active.svg'" class='l-header-icon' />{{currentNavItem.title}}
	</button>
</div>
<div class="l-nav-menu">
	<div *ngFor='let item of navItems | keyvalue: handleDefaultOrder' (click)="handleNavigation(item.value)"
		class='l-nav-item'>
		<div class="l-icon-container" [class.l-active-route]="currentNavItem.title === item.value.title">
			<img class="l-icon"
				[src]="currentNavItem.title === item.value.title ? item.value.iconPath + '-active.svg' : item.value.iconPath + '.svg'" />
		</div>
		<p class='l-title'>{{item.value.title}}</p>
	</div>
	<div class="l-switch" (click)="handleCustomerNavigation()">
		<mat-icon>arrow_back</mat-icon>
		<div>SWITCH TO CUSTOMER</div>
	</div>
</div>
