import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml  } from '@angular/platform-browser';
import { SchemeID } from '@app/models/dataSchema.model';
import { EventCollectInfo, EventQuestionToggle } from '@app/models/event.model';
import { QuestionType } from '@app/models/user.model';
import { ConfirmationModalComponent } from '@app/shared/modals/confirmation-modal/confirmation-modal.component';

@Component({
	selector: 'app-question-list',
	templateUrl: './question-list.component.html',
	styleUrls: ['./question-list.component.sass'],
})
export class QuestionListComponent {
	@Input() isMobile = false;
	@Input() eventId: SchemeID;
	@Input() questions: EventCollectInfo[];
	@Input() isLoading = true;
	@Input() noQuestions = false;
	@Input() displayFooter = false;

	@Output() questionPropertyToggle = new EventEmitter<EventQuestionToggle>();
	@Output() eventQuestionDeletetion = new EventEmitter<SchemeID>();
	@Output() reorder = new EventEmitter<CdkDragDrop<string[]>>();
	@Output() collectInfoFormRequest = new EventEmitter<EventCollectInfo>();
	@Output() backPress = new EventEmitter();

	displayColumns = ['sortOrder', 'question', 'enabled', 'required', 'actions'];
	filteredQuestions: EventCollectInfo[];
	dragDisabled = true;
	hasSearchTerm = false;
	questionType = QuestionType;

	constructor(
		private sanitizer: DomSanitizer,
		private dialog: MatDialog
	) {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.questions) {
			this.filteredQuestions = this.questions;
		}
	}

	handleSearch(searchTerm: string) {
		if (!searchTerm) {
			this.filteredQuestions = this.questions;
			this.hasSearchTerm = false;
		} else {
			this.filteredQuestions = this.questions.filter(question =>
				question.question.toLowerCase().includes(searchTerm.toLowerCase())
			);
			this.hasSearchTerm = true;
		}
	}

	getSafeHtml(html: string): SafeHtml {
		return this.sanitizer.bypassSecurityTrustHtml(html);
	}

	onEventQuestionPropertyToggle(name: keyof EventCollectInfo, id: SchemeID, value: boolean, prevValue: boolean) {
		this.questionPropertyToggle.emit({
			name: name,
			id: id,
			value: value,
			prevValue: prevValue,
		});
	}

	onEventQuestionDeletion(id: SchemeID) {
		const confirmationDialogRef = this.dialog.open(ConfirmationModalComponent, {
			data: {
				title: 'Are You Sure?',
				text: 'Deleting this question cannot be undone.',
				buttonText: 'DELETE',
				isMobile: this.isMobile,
			},
			panelClass: 'g-standard-dialog',
		});

		confirmationDialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.eventQuestionDeletetion.emit(id);
			}
		});
	}

	onReorder(event: CdkDragDrop<string[]>) {
		this.dragDisabled = true;
		this.reorder.emit(event);
	}

	onCollectInfoFormRequest(event: EventCollectInfo) {
		this.collectInfoFormRequest.emit(event);
	}

	goBack() {
		this.backPress.emit();
	}
}
