import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
	selector: 'app-mat-text-control-field',
	templateUrl: './mat-text-control-field.component.html',
	styleUrls: ['./mat-text-control-field.component.sass'],
})
export class MatTextControlFieldComponent {
	@Input() placeholder = '';
	@Input() label = '';
	@Input() outerClass = 'l-form-item';
	@Input() labelClass = 'g-form-label l-form-label';
	@Input() control: UntypedFormControl;
	@Input() tooltip = '';
	@Input() customId = '';
	@Input() required = false;
	@Input() customIcon = '';
	@Input() errorOutline = false;
	@Input() paddingBottom = false;
	@ViewChild('inputRef', { static: true })
	public inputRef: ElementRef;

	isControlInvalid(){
		if (this.control){
			return this.control.invalid && (this.control.dirty || this.control.touched);
		}
		return false;
	}

	getError(errors: { [key: string]: string }) {
		return  errors && errors[Object.keys(errors)[0]];
	}
}
