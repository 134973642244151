import { Component, EventEmitter, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HeaderContent, SectionRouteCard, SideNavItems } from '@app/models/shared';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { MY_ACCOUNT_CARDS, SIDENAV_ITEMS } from '@app/utils/consts';
import { Dictionary } from '@ngrx/entity';
import { takeUntil } from 'rxjs/operators';
import { DeleteAccountModalComponent } from '../../modals/delete-account-modal/delete-account-modal.component';
import { handleOrder } from '@app/utils/common-helpers';

@Component({
	selector: 'app-profile-dashboard',
	templateUrl: './profile-dashboard.component.html',
	styleUrls: ['./profile-dashboard.component.sass'],
})
export class ProfileDashboardComponent implements OnInit {
	cardsData: Dictionary<SectionRouteCard> = MY_ACCOUNT_CARDS;
	isMobile = false;
	currentRoute: SideNavItems = SIDENAV_ITEMS.myAccount;
	handleDefaultOrder = handleOrder;
	headerContent: HeaderContent = {
		title: 'My Account',
		description: 'Edit your profile details below.',
	};
	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private breakpointService: BreakpointService,
		private dialog: MatDialog
	) { }

	ngOnInit(): void {
		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}

	handleDeleteAccount(): void {
		this.dialog.open(DeleteAccountModalComponent, {
			data: { isMobile: this.isMobile },
			panelClass: this.isMobile ? 'g-full-page-dialog' : 'g-standard-dialog',
			disableClose: true,
		});
	}
}
