import { Injectable } from '@angular/core';
import { EventFacebookDataForm } from '@app/models/event.model';
import { FormService, ValidatorsConfig, FormConfig } from '@app/services/form/form.service';
import { requiredValidator } from '@app/shared/form-field/form-validators';

@Injectable()
export class FacebookIntegrationService {
	constructor(
		private formService: FormService
	) {}
	createFacebookIntegrationForm(initialValues?: Partial<EventFacebookDataForm>) {
		const formValues = new EventFacebookDataForm();

		const validatorsConfig: ValidatorsConfig<EventFacebookDataForm> = {
			name: [requiredValidator()],
			description: [requiredValidator()],
			facebookPage: [requiredValidator()],
			imageString: [requiredValidator()],
		};

		const formConfig: FormConfig<EventFacebookDataForm> =
			this.formService.createFormControls({
				formValues,
				initialValues,
				validatorsConfig,
			});

		return this.formService.formBuilder.group(formConfig);
	}
}
