import { Component, Input, Output, EventEmitter, NgZone, OnChanges, SimpleChanges } from '@angular/core';
import { FormField, FormFieldOffset } from '../form-field.model';
import { HttpService } from '@app/api/http/http.service';
import { ResponseScheme } from '@app/models/http.model';
import * as deepEqual from 'fast-deep-equal';

@Component({
	selector: 'app-file-upload-field',
	templateUrl: './file-upload-field.component.html',
	styleUrls: ['./file-upload-field.component.sass'],
})
export class FileUploadFieldComponent extends FormField implements OnChanges {
	@Input() accept = 'image/*';
	@Input() ignoreImageTemplate = false;
	@Input() uploadLabel = 'Upload image';
	@Input() offset: FormFieldOffset = FormFieldOffset.long;
	@Input() max = 1;
	@Input() multiple = false;
	@Input() maxFileSize: number;
	@Input() url: string;
	@Input() headers: { [name: string]: string };
	@Input() dropBoxMessage: string;
	@Input() uploadedFiles = [];
	@Input() customSuccessMessage: string;
	files = [];
	@Output() uploadFinished = new EventEmitter();
	@Input() customUpload: boolean;
	@Output() uploadHandler = new EventEmitter();
	@Output() remove = new EventEmitter();
	@Output() maxFileSizeAchived = new EventEmitter();

	imageFiles: { fileName: string; url: string } = { fileName: '', url: '' };
	constructor(
		private ngZone: NgZone,
		private httpService: HttpService
	) {
		super();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.uploadedFiles && changes.uploadedFiles.currentValue && changes.uploadedFiles.currentValue.length === 0) {
			this.imageFiles.url = null;
		}
		if (changes.uploadedFiles &&
			changes.uploadedFiles.currentValue &&
			!deepEqual(changes.uploadedFiles.currentValue, changes.uploadedFiles.previousValue)) {
			changes.uploadedFiles.currentValue.forEach(element => {
				this.imageFiles.fileName = element.fileName;
				this.imageFiles.url = element.url;
			});
		}
	}

	getRawClasses() {
		const classList = this.getFormFieldClasses(this.offset, 'file-ulpoad');
		return Object.keys(classList).filter(el => classList[el]).join(' ');
	}

	onRemoved(file, index: number) {
		this.imageFiles.url = null;
		this.ngZone.runOutsideAngular(() => {
			const image = { id: this.imageFiles.fileName, index };
			this.remove.emit(image);
		});
	}

	onBeforeSend(event: { xhr: XMLHttpRequest }) {
		for (const key in this.headers) {
			if (this.headers.hasOwnProperty(key)) {
				event.xhr.setRequestHeader(key, this.headers[key]);
			}
		}
	}

	onUploadFinished(event: { files: File[]; xhr: XMLHttpRequest }) {
		const response = JSON.parse(event.xhr.response);
		this.uploadFinished.emit(response.payload);
	}

	onError() {
		this.uploadFinished.emit(null);
	}

	onCustomUpload({ files }: { files: File[] }) {
		if (this.maxFileSize) {
			const isSomeFileBigger = files.some(el => el.size > this.maxFileSize);
			if (isSomeFileBigger) {
				this.maxFileSizeAchived.emit();
				this.files = [];
				return;
			}
		}

		if (this.customUpload) {
			this.uploadHandler.emit({ files });
			return;
		}

		const formData = new FormData();

		files.forEach(f => formData.append(null, f, f.name));

		this.httpService.http.post<ResponseScheme<any>>(
			this.url,
			formData,
			{
				headers: this.headers,
			}
		).subscribe(
			res => {
				this.uploadFinished.emit(res.payload);
			},
			() => {
				this.uploadFinished.emit(null);
			}
		);
	}

}

@Component({
	selector: 'app-file-upload-image',
	template: `
		<div class="ui-fileupload-img-wrap">
			<img *ngIf="url" [src]="url" alt="" class="ui-fileupload-img">
			<button class="ui-fileupload-img-remove" (click)="remove.emit()">
				<i class="fa fa-times "></i>
			</button>
		</div>
	`,
})
export class FileUploadImageComponent {
	@Input() url: string;
	@Output() remove = new EventEmitter();
}
