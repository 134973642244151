import { EventEntityState } from '@app/models/store.model';
import { EventAtTheDoorConstants, Actions } from './at-the-door.actions';

export const entityEventAtTheDoorReducer = (initialState: EventEntityState['atTheDoor']) =>
	(state = initialState, action: Actions): EventEntityState['atTheDoor'] => {
		switch (action.type){
			case EventAtTheDoorConstants.GET_EVENT_ATTHEDOOR:
				return {
					...state,
					isFetching: true,
				};
			case EventAtTheDoorConstants.GET_EVENT_ATTHEDOOR_SUCCESS:
				return {
					...state,
					isFetching: false,
					atTheDoor: action.payload.atTheDoor,
				};
			case EventAtTheDoorConstants.GET_EVENT_ATTHEDOOR_FAILED:
				return {
					...state,
					isFetching: false,
				};
		}
	};
