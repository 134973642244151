<mat-accordion *ngIf="currentStep === orderModalStep.DETAILS" class='mb-4 l-status-message'>
	<mat-expansion-panel expanded="true">
		<mat-expansion-panel-header class="l-expansion-header">
			<div class="g-subtitle pe-2">
				Select the tickets that you want to cancel below.</div>
		</mat-expansion-panel-header>
		<div *ngIf="!order.isTransferred && order.paymentCollectionBy != paymentCollectionByEnum.ORGANISER" class="l-order-info">
			<span class="l-payment-type">
				<mat-icon
					class="l-info-icon"
					[ngClass]="{'mobile': isMobile}"
				>
					info
				</mat-icon>
				This order was paid by <b>{{order.paymentType}}.</b>
			</span>
			Where possible, refunds are paid back to the original purchaser via the same payment method that was used to
			make the purchase.
		</div>
		<div *ngIf="order.isTransferred && order.paymentCollectionBy == paymentCollectionByEnum.ORGANISER" class="l-order-info">
			<br />
			This order is transferred. If you cancel this order you are responsible for refunding the ticket holder.
			<b>Please note that your are responsible for collecting the ticket buyers bank details and refunding them.
				The original buyer will no longer be eligible for a refund.</b>
		</div>
		<div class="g-subtitle mt-2 l-order-info">
			By proceeding you confirm that you have read and agree with the Quicket <a
				[href]="termsLink"
				target="_blank"
				class="fw-bold g-green"
			>terms and conditions</a>.</div>
	</mat-expansion-panel>
</mat-accordion>
<div *ngIf="currentStep !== orderModalStep.BANK_DETAILS">
	<div
		class="l-table-container"
		*ngIf="!isLoading; else showLoading"
	>
		<table
			mat-table
			[dataSource]="ticketData"
			class='l-table web'
		>
			<ng-container matColumnDef="selectTicket">
				<th
					id="select-all"
					mat-header-cell
					*matHeaderCellDef
				>
					<mat-checkbox
						(change)="$event ? toggleAllRows() : null"
						[checked]="selection.hasValue() && isAllSelected()"
						[indeterminate]="selection.hasValue() && !isAllSelected()"
					>
					</mat-checkbox>
				</th>
				<td
					mat-cell
					*matCellDef="let ticket"
				>
					<mat-checkbox
						(click)="$event.stopPropagation()"
						(change)="$event ? selection.toggle(ticket) : null"
						[checked]="selection.isSelected(ticket)"
						[disabled]="!fromForm('isValid', ticket).value || fromForm('frozen', ticket).value"
					>
					</mat-checkbox>
				</td>
				<td
					mat-footer-cell
					*matFooterCellDef
				></td>
			</ng-container>
			<ng-container matColumnDef="ticketNumber">
				<th
					id="ticket-number-table-header"
					class="l-medium-width"
					mat-header-cell
					*matHeaderCellDef
				>
					Ticket # </th>
				<td
					mat-cell
					*matCellDef="let ticket"
					[ngClass]="{ 'l-disabled': !fromForm('isValid', ticket).value }"
				>{{ fromForm('ticketId', ticket).value }}</td>
				<td
					mat-footer-cell
					*matFooterCellDef
				></td>
			</ng-container>
			<ng-container matColumnDef="barcode">
				<th
					id="barcode-table-header"
					class="l-medium-width"
					mat-header-cell
					*matHeaderCellDef
				>
					Barcode </th>
				<td
					mat-cell
					*matCellDef="let ticket"
					[ngClass]="{ 'l-disabled': !fromForm('isValid', ticket).value }"
				>{{ fromForm('barcode', ticket).value }}</td>
				<td
					mat-footer-cell
					*matFooterCellDef
				></td>
			</ng-container>
			<ng-container matColumnDef="ticketHolder">
				<th
					id="ticket-holder-table-header"
					class="l-large-width"
					mat-header-cell
					*matHeaderCellDef
				>
					Ticket Holder </th>
				<td
					mat-cell
					*matCellDef="let ticket"
					[ngClass]="{ 'l-disabled': !fromForm('isValid', ticket).value }"
				>{{ fromForm('ticketHolder', ticket).value }}</td>
				<td
					mat-footer-cell
					*matFooterCellDef
				></td>
			</ng-container>
			<ng-container matColumnDef="type">
				<th
					id="old-type-table-header"
					class="l-large-width"
					mat-header-cell
					*matHeaderCellDef
				>
					Type </th>
				<td
					mat-cell
					*matCellDef="let ticket"
					[ngClass]="{ 'l-disabled': !fromForm('isValid', ticket).value }"
				>{{ fromForm('oldTicketType', ticket).value }}</td>
				<td
					mat-footer-cell
					*matFooterCellDef
				></td>
			</ng-container>
			<ng-container matColumnDef="amount">
				<th
					id="ticket-value-table-header"
					class="l-medium-width"
					mat-header-cell
					*matHeaderCellDef
				>
					{{ currentStep === orderModalStep.DETAILS ? 'Amount' : 'Ticket Value' }} </th>
				<td
					mat-cell
					*matCellDef="let ticket"
					[ngClass]="{ 'l-disabled': !fromForm('isValid', ticket).value }"
				>{{ fromForm('amount', ticket).value | currency: order.currencySymbol }}</td>
				<td
					mat-footer-cell
					*matFooterCellDef
				><b>{{getTotalAmount() | currency: order.currencySymbol}} </b></td>
			</ng-container>
			<ng-container matColumnDef="refundable">
				<th
					id="can-refund-table-header"
					mat-header-cell
					*matHeaderCellDef
				>
					Refundable </th>
				<td
					mat-cell
					*matCellDef="let ticket"
					[ngClass]="{ 'l-disabled': !fromForm('isValid', ticket).value }"
				>{{ (order.canRefund && fromForm('amount', ticket).value > 0) ? 'Yes' : 'No' }}</td>
				<td
					mat-footer-cell
					*matFooterCellDef
				></td>
			</ng-container>
			<ng-container matColumnDef="refundFee">
				<th
					id="refund-fee-table-header"
					class="l-medium-width"
					mat-header-cell
					*matHeaderCellDef
				>
					Refund Fee </th>
				<td
					mat-cell
					*matCellDef="let ticket"
					[ngClass]="{ 'l-disabled': !fromForm('isValid', ticket).value }"
				>{{ (order.refundFee * fromForm('amount', ticket).value) | currency: order.currencySymbol }} ex VAT</td>
				<td
					mat-footer-cell
					*matFooterCellDef
				><b>{{getTotalRefundFee() | currency: order.currencySymbol}} </b></td>
			</ng-container>
			<ng-container matColumnDef="totalRefund">
				<th
					id="total-refund-table-header"
					class="l-medium-width"
					mat-header-cell
					*matHeaderCellDef
				>
					Total refund to Buyer </th>
				<td
					mat-cell
					*matCellDef="let ticket"
					[ngClass]="{ 'l-disabled': !fromForm('isValid', ticket).value }"
				>{{ getRefund(fromForm('refundAmount', ticket).value) | currency: order.currencySymbol }}</td>
				<td
					mat-footer-cell
					*matFooterCellDef
				><b>{{getTotalRefund() | currency: order.currencySymbol}} </b></td>
			</ng-container>
			<ng-container matColumnDef="paymentMethod">
				<th
					id="payment-method-table-header"
					class="l-medium-width"
					mat-header-cell
					*matHeaderCellDef
				>
					Payment Method </th>
				<td
					mat-cell
					*matCellDef="let ticket"
					[ngClass]="{ 'l-disabled': !fromForm('isValid', ticket).value }"
				>{{order.paymentType}}</td>
				<td
					mat-footer-cell
					*matFooterCellDef
				></td>
			</ng-container>
			<tr
				mat-header-row
				*matHeaderRowDef="displayColumns"
			></tr>
			<tr
				mat-row
				*matRowDef="let row; columns: displayColumns;"
			></tr>
			<tr
				mat-footer-row
				*matFooterRowDef="displayColumns"
			></tr>
		</table>
	</div>
	<div
		class="g-input-container mt-3"
		*ngIf="shouldDisplayRefundFeeOptions"
	>
		<div [ngClass]="{ 'input-group-mobile': isMobile, 'w-100': !isMobile }">
			<label class="g-form-label">Who will pay the refund fee?</label>
			<div class="d-flex flex-column align-items-start">
				<mat-radio-group
					[(ngModel)]="refundFeePayableBy"
					class="d-flex flex-column"
					[ngClass]="{'gap-2': isMobile}"
					(change)="handleRefundFeePayableBy($event)"
				>
					<mat-radio-button
						[value]="refundPayeeEnum.ORGANISER"
						id="organiser-radio"
					>
						Me (organiser)
					</mat-radio-button>
					<mat-radio-button
						[value]="refundPayeeEnum.BUYER"
						id="buyer-radio"
					>
						Ticket purchaser
					</mat-radio-button>
				</mat-radio-group>
			</div>
		</div>
	</div>
</div>
<div
	*ngIf="currentStep === orderModalStep.BANK_DETAILS"
	class="l-bank-details-container"
>
	<app-cancel-tickets-bank-details [bankAccountForm]="bankAccountForm"></app-cancel-tickets-bank-details>
</div>
<ng-template #showLoading>
	<div class="l-loading">
		<mat-spinner></mat-spinner>
	</div>
</ng-template>