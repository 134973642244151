<div class="l-preview-container">
    <button id="close-iframe" class="l-cancel-button" (click)="handleCancel()"><mat-icon>close</mat-icon></button>
    <iframe
    (load)="handleLoading($event)"
    id="page-url-iframe"
    *ngIf="pageURL"
    class="l-preview"
    [src]="pageURL"
    frameborder="0"
    width="100%"
    height="100%"
    [class.l-frame-hidden]="iframeLoading"
    ></iframe>
    <div class="l-loading" *ngIf="iframeLoading">
        <mat-spinner></mat-spinner>
    </div>
</div>
