import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'dynamicCurrency',
})
export class DynamicCurrencyPipe implements PipeTransform {
	transform(value: number, currencySymbol: string | null): string {
		if (!value) {
			return '-';
		}

		let min = 2;
		if (value % 1 === 0) {
			min = 0;
		}

		const formattedNumber = new Intl.NumberFormat('en-US', {
			minimumFractionDigits: min,
			maximumFractionDigits: 2,
		}).format(value);

		return currencySymbol ? `${currencySymbol}${formattedNumber}` : formattedNumber;
	}
}
