import { Component, EventEmitter, OnInit } from '@angular/core';
import { ErrorBusService } from '@app/services/error-handling/error-bus.service';
import { Observable } from 'rxjs';
import { map, mapTo, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { pushDataLayerTracking } from '@app/utils/DataLayer';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';

@Component({
	selector: 'app-app',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.sass'],
})
export class AppComponent implements OnInit {
	isError$: Observable<boolean>;
	destroyed$: EventEmitter<void> = new EventEmitter<void>();
	isMobile = false;

	constructor(
		private errorBus: ErrorBusService,
		private router: Router,
		private breakpoint: BreakpointObserver,
		private breakpointService: BreakpointService
	) {
		this.isError$ = this.errorBus.errorStream$.pipe(mapTo(true));
		pushDataLayerTracking(this.router);
	}

	ngOnInit(): void {
		this.observeBreakpoint(Breakpoints.HandsetPortrait, isMobile => this.breakpointService.isMobile(isMobile));
		this.observeBreakpoint(Breakpoints.Web, isWeb => this.breakpointService.isWeb(isWeb));

		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});
	}

	private observeBreakpoint(breakpoint: string, callback: (matches: boolean) => void): void {
		this.breakpoint.observe(breakpoint)
			.pipe(
				takeUntil(this.destroyed$),
				map(result => result.matches)
			)
			.subscribe(callback);
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
