<app-dialog class="error-modal" *ngIf="errorState.isOpened || isOpened"
	[style]="{ width: '150px', borderRadius: '8px' }" [visible]="errorState.isOpened || isOpened">
	<div class="error-content">
		<i class="far fa-sad-tear fa-4x error-icon"></i>
		<h1 class="error-title"> – Oops!</h1>
		<span class="error-desc"> Something went wrong
			<span *ngIf="isOpened && !errorState.isOpened">.</span>
			<span *ngIf="errorState.msg"> and we
				<b class="error-msg">{{errorState.msg}}</b>
			</span>
			<span class="error-desc-extra" *ngIf="isExtraMessage"> Please try reloading the page and if this error still
				occurs please contact our support team at <a class="g-link"
					href="mailto:support@quicket.co.za">support&#64;quicket.co.za</a></span>
		</span>
		<ul *ngIf="errorState && errorState.serverMessages && errorState.serverMessages.length" class='l-error-list'>
			<li *ngFor="let msg of decodeServerErrorCodes(errorState.serverMessages)">{{msg}}</li>
		</ul>
	</div>
	<app-dialog-footer>
		<div class="l-buttons-container">
			<button *ngIf="errorState && errorState.meta.extraAction && errorState.meta.extraActionTitle"
				class="g-secondary-button l-border" (click)="proceedAction(errorState.meta.extraAction)">{{
				errorState.meta.extraActionTitle }}</button>
			<button *ngIf="errorState.action" class="g-primary-button"
				(click)="proceedAction(errorState.action, true)">RETRY</button>
			<button *ngIf="errorState && errorState.meta.allowClose && !isExtraMessage" class="g-primary-button"
				(click)="closeModal()">CLOSE</button>
		</div>
	</app-dialog-footer>
</app-dialog>
