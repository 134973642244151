import { Injectable } from '@angular/core';
import { OrganiserDomainAPI } from '@app/api/domains/organiser';
import { ofTypeExt, handleFailedRequest } from '@app/utils/Store';
import { Actions, createEffect } from '@ngrx/effects';
import { mergeMap, catchError } from 'rxjs';
import { OrganiserActionsConstants } from '../actions/organiser/organiser.actions.constants';
import * as organiserActions from '../actions/organiser/organiser.actions';

@Injectable()
export class OrganiserEffects {
	constructor(
		private action$: Actions,
		private api?: OrganiserDomainAPI
	) { }

	// #region GetSalesOverview
	GetSalesOverview$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<organiserActions.GetSalesOverview>(OrganiserActionsConstants.GET_SALES_OVERVIEW),
			mergeMap(({ action, payload }) =>
				this.api.getSalesOverview(payload.id, payload.dateRange, payload.currencyId).pipe(
					mergeMap(({ payload: salesOverview }) =>
						[new organiserActions.GetSalesOverviewSuccess({
							salesOverview: salesOverview,
						})]
					),
					catchError(
						handleFailedRequest(
							new organiserActions.GetSalesOverviewFailed({
								msg: 'can\'t get your sales overview',
								action,
							})
						)
					)
				)
			)
		)
	);
	// #endregion

	// #region GetCurrentCampaigns
	GetCurrentCampaigns$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<organiserActions.GetCurrentCampaigns>(OrganiserActionsConstants.GET_CURRENT_CAMPAIGNS),
			mergeMap(({ action, payload }) =>
				this.api.getCurrentCampaigns(payload.id).pipe(
					mergeMap(({ payload: topLinkCampaigns }) =>
						[new organiserActions.GetCurrentCampaignsSuccess({
							topLinkCampaigns: topLinkCampaigns,
						})]
					),
					catchError(
						handleFailedRequest(
							new organiserActions.GetCurrentCampaignsFailed({
								msg: 'can\'t get your current campaigns',
								action,
							})
						)
					)
				)
			)
		)
	);
	// #endregion

	// #region GetFilteredProducts
	GetFilteredProducts$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<organiserActions.GetFilteredProducts>(OrganiserActionsConstants.GET_FILTERED_PRODUCTS),
			mergeMap(({ action, payload }) =>
				this.api.getFilteredProducts(payload.userId, payload.productsFilter).pipe(
					mergeMap(({ payload: { userProducts, totalProducts } }) =>
						[new organiserActions.GetFilteredProductsSuccess({
							userProducts: userProducts,
							totalProducts: totalProducts,
						})]
					),
					catchError(
						handleFailedRequest(
							new organiserActions.GetFilteredProductsFailed({
								msg: 'can\'t get your products',
								action,
							})
						)
					)
				)
			)
		)
	);
	// #endregion

	// #region GetOrganiserNames
	GetOrganiserNames$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<organiserActions.GetOrganiserNames>(OrganiserActionsConstants.GET_ORGANISER_NAMES),
			mergeMap(({ action, payload }) =>
				this.api.getOrganiserNames(payload.id).pipe(
					mergeMap(({ payload: organiserNames }) =>
						[new organiserActions.GetOrganiserNamesSuccess({
							organiserNames: organiserNames,
						})]
					),
					catchError(
						handleFailedRequest(
							new organiserActions.GetOrganiserNamesFailed({
								msg: 'can\'t get organiser names',
								action,
							})
						)
					)
				)
			)
		)
	);
	// #endregion

	// #region GetMailAccountsByUserId
	GetMailAccountsByUserId$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<organiserActions.GetMailAccountsByUserId>(OrganiserActionsConstants.GET_MAIL_ACCOUNT_BY_USER_ID),
			mergeMap(({ action, payload }) =>
				this.api.getMailAccountsByUserId(payload.id).pipe(
					mergeMap(({ payload: mailAccounts }) =>
						[new organiserActions.GetMailAccountsByUserIdSuccess({
							mailAccounts: mailAccounts,
						})]
					),
					catchError(
						handleFailedRequest(
							new organiserActions.GetMailAccountsByUserIdFailed({
								msg: 'can\'t get your mail accounts',
								action,
							})
						)
					)
				)
			)
		)
	);
	// #endregion

	// #region AddMailAccount
	AddMailAccount$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<organiserActions.AddMailAccount>(OrganiserActionsConstants.ADD_MAIL_ACCOUNT),
			mergeMap(({ action, payload }) =>
				this.api.addMailAccount(payload.id, payload.mailAccount).pipe(
					mergeMap(({ payload: mailAccounts }) =>
						[new organiserActions.AddMailAccountSuccess({
							mailAccounts: mailAccounts,
						})]
					),
					catchError(
						handleFailedRequest(
							new organiserActions.AddMailAccountFailed({
								msg: 'can\'t add your mail account',
								action,
							})
						)
					)
				)
			)
		)
	);
	// #endregion

	// #region DeleteMailAccount
	DeleteMailAccount$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<organiserActions.DeleteMailAccount>(OrganiserActionsConstants.DELETE_MAIL_ACCOUNT),
			mergeMap(({ action, payload }) =>
				this.api.deleteMailAccount(payload.id, payload.mailAccountId).pipe(
					mergeMap(({ payload: mailAccounts }) =>
						[new organiserActions.DeleteMailAccountSuccess({
							mailAccounts: mailAccounts,
						})]
					),
					catchError(
						handleFailedRequest(
							new organiserActions.DeleteMailAccountFailed({
								msg: 'can\'t delete your mail account',
								action,
							})
						)
					)
				)
			)
		)
	);
	// #endregion

	// #region GetDashboardEvents
	GetDashboardEvents$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<organiserActions.GetDashboardEvents>(OrganiserActionsConstants.GET_DASHBOARD_EVENTS),
			mergeMap(({ action, payload }) =>
				this.api.getDashboardEvents(payload.id).pipe(
					mergeMap(({ payload: dashboardEvents }) =>
						[new organiserActions.GetDashboardEventsSuccess({
							dashboardEvents: dashboardEvents,
						})]
					),
					catchError(
						handleFailedRequest(
							new organiserActions.GetDashboardEventsFailed({
								msg: 'can\'t get your dashboard events',
								action,
							})
						)
					)
				)
			)
		)
	);
	// #endregion

	// #region GetMailChimpConnection
	GetMailChimpConnection$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<organiserActions.GetMailChimpConnection>(OrganiserActionsConstants.GET_MAILCHIMP_CONNECTION),
			mergeMap(({ action, payload }) =>
				this.api.getMailChimpConnection(payload.id, payload.code).pipe(
					mergeMap(({ payload: MailChimpConnection }) =>
						[new organiserActions.GetMailChimpConnectionSuccess({
							mailChimpConnection: MailChimpConnection,
						})]
					),
					catchError(
						handleFailedRequest(
							new organiserActions.GetMailChimpConnectionFailed({
								msg: 'can\'t get your mailchimp Connection',
								action,
							})
						)
					)
				)
			)
		)
	);
	// #endregion

	// #region PostUnlinkMailChimp
	PostUnlinkMailChimp$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<organiserActions.PostUnlinkMailChimp>(OrganiserActionsConstants.POST_UNLINK_MAILCHIMP),
			mergeMap(({ action, payload }) =>
				this.api.postUnlinkMailChimp(payload.id).pipe(
					mergeMap(({ payload: MailChimpConnection }) =>
						[new organiserActions.PostUnlinkMailChimpSuccess({
							mailChimpConnection: MailChimpConnection,
						})]
					),
					catchError(
						handleFailedRequest(
							new organiserActions.PostUnlinkMailChimpFailed({
								msg: 'can\'t unlink your mailchimp account',
								action,
							})
						)
					)
				)
			)
		)
	);
	// #endregion

	// #region GetUserReportsInfo
	GetUserReportsInfo$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<organiserActions.GetUserReportsInfo>(OrganiserActionsConstants.GET_USER_REPORTS_INFO),
			mergeMap(({ action, payload}) =>
				this.api.getUserReportsInfo(payload.id).pipe(
					mergeMap(({ payload: reportsInfo}) =>
						[new organiserActions.GetUserReportsInfoSuccess({
							userReportInfo : reportsInfo,
						})]
					),
					catchError(
						handleFailedRequest(
							new organiserActions.GetUserReportsInfoFailed({
								msg: 'could not get your reports info',
								action,
							})
						)
					)
				))
		));
	// #endregion


	// #region DownloadUserReports
	DownloadUserReports$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<organiserActions.DownloadUserReports>(OrganiserActionsConstants.DOWNLOAD_USER_REPORTS),
			mergeMap(({ action, payload}) =>
				this.api.downloadUserReports(payload.id, payload.downloadUserReportsDto).pipe(
					mergeMap(({ payload: successResponse}) =>
						[new organiserActions.DownloadUserReportsSuccess({
							successResponse: successResponse,
						})]
					),
					catchError(
						handleFailedRequest(
							new organiserActions.DownloadUserReportsFailed({
								msg: 'could not download your reports',
								action,
							})
						)
					)
				))
		));
	// #endregion
}
