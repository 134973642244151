import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { HeaderContent } from '@app/models/shared';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { MY_TICKETS_HEADER } from '@app/utils/consts';
import { take, takeUntil } from 'rxjs/operators';
import { FooterButtonComponent } from '@app/shared/footer-button/footer-button.component';
import { FormCreationService } from '@app/services/form-creation/form-creation.service';
import { FormGroup } from '@angular/forms';
import { MyRegistrations, QuestionType, RegistrationDetails } from '@app/models/user.model';
import { StoreService } from '@app/services/store/store.service';
import { Go } from '@app/store/actions/router/router.actions';
import { ActivatedRoute } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { UserActionsConstants } from '@app/store/actions/user/user.actions.constants';
import {
	GetRegistrationDetails,
	GetRegistrationDetailsFailed,
	GetRegistrationDetailsSuccess,
	GetUserMyRegistrations,
	UpdateRegistrationDetails
} from '@app/store/actions/user/user.actions';
import { InternalURLCreator } from '@app/services/url/url.dictionary';
import * as userSelectors from '@app/store/selectors/user.selector';
import { MapsService } from '@app/services/maps/maps.service';
import { MatDialog } from '@angular/material/dialog';
import { FormCanDeactivate } from '@app/services/guards/user.guard';
import { Observable } from 'rxjs';
import { ConfirmationModalComponent } from '@app/shared/modals/confirmation-modal/confirmation-modal.component';

const NO_REGISTRATION_FOUND_MESSAGE = 'No Registration Found - Please contact support.';

@Component({
	selector: 'app-registration-details',
	templateUrl: './registration-details.component.html',
	styleUrls: ['./registration-details.component.sass'],
})
export class RegistrationDetailsComponent extends FormCanDeactivate implements OnInit {
	isMobile = false;
	headerContent: HeaderContent = MY_TICKETS_HEADER['registrationDetails'];
	currentRegistration: MyRegistrations;

	registrationForm: FormGroup;
	registrationFormData: RegistrationDetails;

	registrationId: number;

	errorMessage: string;
	statusMessage: string;
	displayUpdateTicketWarning = false;
	registrationLoading = true;

	NO_ACCESS_MESSAGE = 'You do not have access to this registration.';

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	@ViewChild('footerButton') footerButton: FooterButtonComponent;

	get isFooterButtonDisabled(): boolean {
		return this.registrationLoading || this.registrationForm.invalid || this.registrationForm.pristine;
	}

	constructor(
		private breakpointService: BreakpointService,
		private formCreation: FormCreationService,
		private store: StoreService,
		private activatedRoute: ActivatedRoute,
		private actions$: Actions,
		private mapsService: MapsService,
		private dialog: MatDialog
	) {
		super();
		this.registrationForm = this.formCreation.createFormGroup();
	}

	ngOnInit(): void {
		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});

		this.actions$
			.pipe(
				ofType<GetRegistrationDetailsSuccess | GetRegistrationDetailsFailed>(
					UserActionsConstants.GET_REGISTRATION_DETAILS_SUCCESS,
					UserActionsConstants.GET_REGISTRATION_DETAILS_FAILED
				),
				takeUntil(this.destroyed$)
			)
			.subscribe(action => {
				if (action.type === UserActionsConstants.GET_REGISTRATION_DETAILS_SUCCESS) {
					const successAction = action as GetRegistrationDetailsSuccess;
					const registrationDetails = successAction.payload.registrationDetails;

					this.initializeRegistrationFormData(registrationDetails);
					this.trackFormValueChanges();

					this.displayUpdateTicketWarning = this.registrationFormData.questions.some(
						(question) => question.showUpdateRegWarning
					);

					const hasAddressQuestion = this.registrationFormData.questions.some(
						(question) => question.type === QuestionType.Address
					);

					if (!hasAddressQuestion) {
						this.registrationLoading = false;
					} else {
						this.mapsService.initializeMaps().then(() => {
							this.registrationLoading = false;
						});
					}
				} else if (action.type === UserActionsConstants.GET_REGISTRATION_DETAILS_FAILED) {
					const failedAction = action as GetRegistrationDetailsFailed;
					if (failedAction.payload && failedAction.payload.msg === this.NO_ACCESS_MESSAGE) {
						this.errorMessage = failedAction.payload.msg;
					} else {
						this.errorMessage = 'Oops! We can\'t get your registration details, please try again or contact support';
					}
					this.registrationLoading = false;
				}
			});

		this.activatedRoute.params.pipe(take(1)).subscribe((params) => {
			this.registrationId = params['id'];
			if (this.registrationId) {
				this.store.dispatch(new GetRegistrationDetails({ id: this.registrationId }));
			}
		});

		this.store.dispatch(new GetUserMyRegistrations());

		this.store.select(userSelectors.userMyRegistrations()).pipe(takeUntil(this.destroyed$)).subscribe((registrations) => {
			if (registrations.length) {
				this.currentRegistration = registrations.find((registration) => registration.preRegistrationId === +this.registrationId);
			}
		});
	}

	initializeRegistrationFormData(registrationDetails: RegistrationDetails): void {
		this.registrationFormData = registrationDetails;
		this.errorMessage = this.registrationFormData.errorMessage;
		this.statusMessage = this.registrationFormData.statusMessage;

		if (this.registrationFormData.questions.length) {
			this.formCreation.populateFormFromData(this.registrationForm, this.registrationFormData.questions);
		} else {
			this.statusMessage = NO_REGISTRATION_FOUND_MESSAGE;
		}
	}

	handleSubmit() {
		this.registrationLoading = true;

		this.actions$
			.pipe(
				ofType(UserActionsConstants.UPDATE_REGISTRATION_DETAILS_SUCCESS, UserActionsConstants.UPDATE_REGISTRATION_DETAILS_FAILED),
				takeUntil(this.destroyed$)
			)
			.subscribe(({ type, payload: { registrationDetails } }) => {
				this.registrationLoading = false;
				if (type === UserActionsConstants.UPDATE_REGISTRATION_DETAILS_SUCCESS) {
					this.initializeRegistrationFormData(registrationDetails);
					this.registrationForm.markAsPristine();
				}
			});

		const updatedQuestions = this.registrationFormData.questions.map(question => {
			const questionId = question.id;
			const newAnswer = this.registrationForm.value[questionId];
			return newAnswer ? { ...question, answer: newAnswer } : question;
		});

		const updatedFormData = { ...this.registrationFormData, questions: updatedQuestions };

		this.store.dispatch(new UpdateRegistrationDetails({ id: this.registrationId, registrationDetails: updatedFormData }));
	}

	handleNavigationBack() {
		this.store.dispatch(new Go({ path: [InternalURLCreator.myRegistrations()] }));
	}

	trackFormValueChanges(): void {
		Object.keys(this.registrationForm.controls).forEach(controlName => {
			const control = this.registrationForm.get(controlName);
			control.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
				this.registrationForm.markAsDirty();
			});
		});
	}

	isFormDeactivatable(): boolean | Observable<boolean> {
		return !this.registrationForm || this.registrationForm.pristine;
	}

	deactivationFallback(): Observable<boolean> {
		this.dialog
			.open(ConfirmationModalComponent, {
				data: {
					title: 'Unsaved Changes',
					text: 'Are you sure you want to leave this page? Any unsaved changes will be discarded.',
					buttonText: 'LEAVE',
					centerText: true,
					isMobile: this.isMobile,
				},
				panelClass: 'g-standard-dialog',
			})
			.afterClosed()
			.subscribe((result) => {
				this.closeDeactivationModal(result);
			});
		return this.modalCallback.asObservable();
	}

	closeDeactivationModal(isConfirmed: boolean): void {
		this.modalCallback.next(isConfirmed);
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
