<div [ngClass]="{ 'input-group-mobile-large': isMobile, 'input-group-web-large': !isMobile }">
	<label class="g-form-label">
		<span [innerHTML]="label"></span>
	</label>

	<div class="l-upload-container" (click)="fileUpload.click()">
		<input id="file-upload-input" type="file" class="d-none" [accept]="requiredFileTypes" (change)="onFileSelected($event)" #fileUpload [disabled]="isDisabled">
		<div class="l-upload">
			<button mat-mini-fab class="l-icon" [ngClass]="{ 'l-disabled': isDisabled }">
				<ng-container *ngIf="!isLoading; else uploading">
					<mat-icon>attach_file</mat-icon>
				</ng-container>
				<ng-template #uploading>
					<mat-icon class="l-upload-icon">cloud_upload</mat-icon>
				</ng-template>
			</button>
			<div *ngIf="!isLoading; else uploadingText" class="g-form-label">{{fileName || "No file uploaded yet."}}</div>
			<ng-template #uploadingText><div class="g-form-label">Uploading file...</div></ng-template>
			<div [ngClass]="{ 'l-upload-type-mobile': isMobile, 'l-upload-type': !isMobile, 'l-disabled': isDisabled }">{{uploadType}}</div>
			<div *ngIf="fileName && !isDisabled" class="l-delete-container">
				<mat-icon id="delete-file-upload" class="l-delete" (click)="$event.stopPropagation(); handleDelete()">delete_outline</mat-icon>
			</div>
		</div>
	</div>
	<div id="download-file-upload" class="l-download" *ngIf="downloadUrl" (click)="handleFileDownload()">Click here to download</div>
	<div *ngIf="inputFormControl.invalid">
		<div class="g-error-input">
			<div *ngFor="let errorKey of errorKeys">
				<div *ngIf="inputFormControl.hasError(errorKey)">{{ errorMessages[errorKey] }}</div>
			</div>
		</div>
	</div>
	<div *ngIf="uploadErrorMessage">
		<div class="g-error-input">
			{{uploadErrorMessage}}
		</div>
	</div>

</div>
