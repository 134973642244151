import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BankAccount } from '@app/models/user.model';
import { getInitials } from '@app/utils/common-helpers';

@Component({
	selector: 'app-payment-card',
	templateUrl: './payment-card.component.html',
	styleUrls: ['./payment-card.component.sass'],
})
export class PaymentCardComponent implements OnInit {
	@Input() data: BankAccount;
	@Output() delete = new EventEmitter<BankAccount>();
	hiddenNumber: string;
	logoUrl: string;
	bankInitials: string;

	constructor() { }

	ngOnInit(): void {
		if (!this.data?.logoUrl?.includes('placeholder')) {
			this.logoUrl = this.data.logoUrl;
		} else {
			this.bankInitials = getInitials(this.data.bankName);
		}
		this.hiddenNumber = '**** ' + this.data.number.slice(-4);
	}

	handleDelete() {
		this.delete.emit(this.data);
	}

}
