import { Injectable } from '@angular/core';
import { EventDomainAPI } from '@app/api/domains/event';
import { defaultDashboardRoute } from '@app/services/url/url.service';
import { Go } from '@app/store/actions/router/router.actions';
import { handleFailedRequest, handleSuccessRequest, ofTypeExt } from '@app/utils/Store';
import { Actions, createEffect } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { EventAtTheDoorConstants, GetEventAtTheDoor, GetEventAtTheDoorFailed, GetEventAtTheDoorSuccess } from './at-the-door.actions';

@Injectable()
export class AtTheDoorEffects {
	constructor(private api: EventDomainAPI, private action$: Actions) {}
	getEventAtTheDoorUrls$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<GetEventAtTheDoor>(EventAtTheDoorConstants.GET_EVENT_ATTHEDOOR),
			switchMap(({ action, payload: { eventId, scheduleItemId } }) =>
				this.api.getAtTheDoorUrls(eventId, scheduleItemId).pipe(
					handleSuccessRequest(
						({ payload: atTheDoor }) =>
							new GetEventAtTheDoorSuccess({
								eventId,
								atTheDoor,
							})
					),
					catchError(
						handleFailedRequest(
							new GetEventAtTheDoorFailed({
								msg: 'can\'t get at-the-door urls',
								action,
								critical: true,
								meta: {
									extraAction: new Go({ path: [defaultDashboardRoute] }),
									extraActionTitle: 'Go to dashboard',
									allowClose: false,
								},
							})
						)
					)
				)
			)
		)
	);
}
