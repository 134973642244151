import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { HeaderContent } from '@app/models/shared';
import { AccountTicketResaleItem, AccountTicketResales } from '@app/models/user.model';
import { StoreService } from '@app/services/store/store.service';
import { ConfirmationModalComponent } from '@app/shared/modals/confirmation-modal/confirmation-modal.component';
import { CancelResaleTickets } from '@app/store/actions/user/user.actions';
import { UserActionsConstants } from '@app/store/actions/user/user.actions.constants';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs';

@Component({
	selector: 'app-resale-listed',
	templateUrl: './resale-listed.component.html',
	styleUrls: ['./resale-listed.component.sass'],
})
export class ResaleListedComponent implements OnInit {

	@Input() isMobile: boolean;
	@Input() headerContent: HeaderContent;
	@Input() purchaseId: number;
	@Input() productName: string;
	@Input() resaleTickets: AccountTicketResales;
	@Input() ticketsInResale: MatTableDataSource<AccountTicketResaleItem>;
	@Input() currencySymbol: string;
	@Input() title: string;

	inResaleDisplayColumns: string[] = ['ticketType', 'price', 'status', 'type', 'dateCreated', 'email', 'bankDetails', 'action'];
	isCanceling = false;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	@ViewChild('inResalePaginator') set paginator(_pagination: MatPaginator) {
		if (_pagination) {
			this.ticketsInResale.paginator = _pagination;
		};
	}

	constructor(
		private dialog: MatDialog,
		private store: StoreService,
		private actions$: Actions
	) { }

	ngOnInit(): void {
		this.headerContent.title = this.title;
		this.actions$
			.pipe(
				ofType(UserActionsConstants.CANCEL_RESALE_TICKETS_SUCCESS, UserActionsConstants.CANCEL_RESALE_TICKETS_FAILED),
				takeUntil(this.destroyed$)
			)
			.subscribe(() => {
				this.isCanceling = false;
			});
	}

	isFirstTicketOfGroup(tickets: any[], index: number) {
		if (index === 0) {
			return true;
		}
		return tickets[index].ticketResaleId !== tickets[index - 1].ticketResaleId;
	}

	getCancelButtonText(ticket: any, index: number) {
		if (this.isFirstTicketOfGroup(this.resaleTickets.ticketsInResale, index) || this.isMobile) {
			const ticketsInSameResale = this.resaleTickets.ticketsInResale.filter(t => t.ticketResaleId === ticket.ticketResaleId);
			return ticketsInSameResale.length > 1 ? 'Cancel Group' : 'Cancel';
		}
		return '';
	}

	handleResaleCancel(ticket: AccountTicketResaleItem) {
		const confirmationDialogRef = this.dialog.open(ConfirmationModalComponent, {
			data: {
				title: 'Are You Sure?',
				text: 'Are you sure you want to cancel the resale of this ticket?',
				buttonText: 'PROCEED',
				isMobile: this.isMobile,
			},
			panelClass: 'g-standard-dialog',
		});

		confirmationDialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.isCanceling = true;
				this.store.dispatch(new CancelResaleTickets({
					ticketResaleId: ticket.ticketResaleId,
					purchaseId: this.purchaseId,
				}));
			}
		});
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}

}
