import { Component, EventEmitter, Inject } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AddingScheduleItem, AddingScheduleRawConfig, EventRepeats, Schedule } from '@app/models/schedule.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { DatesService } from '@app/services/dates/dates.service';
import { ScheduleService } from '@app/services/schedule/schedule.service';
import * as moment from 'moment';
import { takeUntil } from 'rxjs';
import { ScheduleStartEndTimes } from '@app/models/event.model';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MY_FORMATS } from '@app/utils/consts';

@Component({
	selector: 'app-multi-schedule-select-modal',
	templateUrl: './multi-schedule-select-modal.component.html',
	styleUrls: ['./multi-schedule-select-modal.component.sass'],
	providers: [
		{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
		{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
		{ provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
	],
})
export class MultiScheduleSelectModalComponent {
	schedule: AddingScheduleItem = null;
	isMobile = false;
	form: UntypedFormGroup;
	repeatsOptions = this.scheduleService.getEventRepeatsOptions();
	daysOfWeekOptions = this.scheduleService.getDaysOfWeekOptions();
	monthlyDayOfMonthOptions = this.scheduleService.getDaySequence();
	monthlyDayOfWeekOptions = this.scheduleService.getByDayOfWeekOptions();
	timeEndingDayOptions = this.scheduleService.getTimeEndingDate();
	dynamicValidatorsKeys = [];
	shortEventWarning = false;
	isEmptySchedule = false;
	tooManyScheduleItems = false;
	startAndEndTimeFormGroup: FormGroup;
	eventRepeats = EventRepeats;
	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		public dialogRef: MatDialogRef<MultiScheduleSelectModalComponent>,
		public scheduleService: ScheduleService,
		public datesService: DatesService,
		private breakpointService: BreakpointService,
		@Inject(MAT_DIALOG_DATA) public data: {
			currentScheduleItemCount: number;
		}
	) { }

	ngOnInit() {
		let initialValues = null;
		if (this.schedule) {
			initialValues = {
				...this.schedule,
			};
			if (initialValues.id && initialValues.weeklyRepeat) {
				initialValues.weeklyRepeat = initialValues.weeklyRepeat[0];
			}
		}

		this.startAndEndTimeFormGroup = new FormGroup({
			startTimeControl: new FormControl('', Validators.required),
			endTimeControl: new FormControl('', Validators.required),
		}, { validators: this.timeRangeValidator });

		const formData = this.scheduleService.createScheduleForm(initialValues);
		this.form = formData.form;
		this.dynamicValidatorsKeys = formData.dynamicValidatorsKeys;
		if (initialValues && initialValues.id) {
			this.form.get('repeats').disable();
		}

		this.form.get('repeats').valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
			this.form.patchValue({
				weeklyRepeat: null,
				monthlyDayOfWeek: null,
				monthlyDayOfMonth: null,
			});
			this.dynamicValidatorsKeys.forEach(key => {
				this.form.get(key).updateValueAndValidity();
				this.form.get(key).markAsUntouched();
			});
			this.fromForm('startTime').updateValueAndValidity();
			this.markTimeAsTouched('startTime');
			this.fromForm('endTime').updateValueAndValidity();
			this.markTimeAsTouched('startTime');
		});

		this.startAndEndTimeFormGroup.get('startTimeControl').valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
			if (value.length === 4) {
				this.setStartAndEndTimes(value, ScheduleStartEndTimes.start);
				this.checkEventDuration();
			}
		});

		this.startAndEndTimeFormGroup.get('endTimeControl').valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
			if (value.length === 4) {
				this.setStartAndEndTimes(value, ScheduleStartEndTimes.end);
				this.checkEventDuration();
			}
		});

		this.form.get('startDate').valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
			if (value) {
				if (this.fromForm('repeats').value === EventRepeats.SINGLE_DATE) {
					if (this.fromForm('endTime').value) {
						this.fromForm('endDate').setValue(this.fromForm('endTime').value);
						this.fromForm('endDate').updateValueAndValidity();
					} else {
						const time: Date = value;
						time.setMinutes(time.getMinutes() + 1);
						this.fromForm('endDate').setValue(time);
						this.fromForm('endDate').updateValueAndValidity();
					}
				}
			}
		});

		this.form.get('timeEndingDay').valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
			if (this.form.get('endTime').value) {
				const dateObject = new Date(this.form.get('endTime').value);
				const time = new Date();
				time.setHours(dateObject.getHours(), dateObject.getMinutes(), 0, 0);
				time.setDate(time.getDate() + value);
				this.form.get(ScheduleStartEndTimes.end).setValue(time);
				this.form.get(ScheduleStartEndTimes.end).markAsTouched();
				this.checkEventDuration();
			}
			this.fromForm('startTime').updateValueAndValidity();
			this.fromForm('endTime').updateValueAndValidity();
		});


		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});
	}

	checkEventDuration() {
		const startTime = this.form.get('startTime').value;
		const endTime = this.form.get('endTime').value;
		const timeEndingDay = this.form.get('timeEndingDay').value;
		if (startTime && endTime && !this.datesService.isDatesMatchDiff(startTime, endTime, 30, timeEndingDay)) {
			if (endTime > startTime) {
				this.shortEventWarning = true;
			}
		} else {
			this.shortEventWarning = false;
		}
	}

	getFormControl(controlName: string): FormControl {
		return this.form.get(controlName) as FormControl;
	}

	toggleMonthDaySelection() {
		this.form.patchValue({
			isMonthlyByDayOfMonth: !this.form.get('isMonthlyByDayOfMonth').value,
			monthlyDayOfWeek: null,
			monthlyDayOfMonth: null,
			weeklyRepeat: null,
		});
	}

	isControlInvalid(control: UntypedFormControl) {
		if (control) {
			return control.invalid && (control.dirty || control.touched);
		}
		return false;
	}

	setStartAndEndTimes(value: string, control: string) {
		const hours = parseInt(value.substring(0, 2), 10);
		const minutes = parseInt(value.substring(2, 4), 10);
		const time = new Date();
		time.setHours(hours, minutes, 0, 0);

		if (control === ScheduleStartEndTimes.end) {
			const daysAdded = this.form.get('timeEndingDay').value;
			time.setDate(time.getDate() + daysAdded);
		}
		if (control === ScheduleStartEndTimes.start && this.form.get('startDate').value){
			const startTime: moment.Moment = this.form.get('startDate').value;
			startTime.set('hour', hours);
			startTime.set('minute', minutes);
			this.form.get('startDate').setValue(startTime);
		}
		this.form.get(control).setValue(time);
		this.form.get(control).markAsTouched();
		this.form.get('startTime').updateValueAndValidity();
		this.form.get('endTime').updateValueAndValidity();
		this.form.get('startDate').updateValueAndValidity();
		this.form.get('endDate').updateValueAndValidity();
	}

	submitMultiSchedule() {
		const schedule: AddingScheduleItem = this.form.getRawValue();
		const config: AddingScheduleRawConfig = {
			isSplitted: false,
			schedule,
			scheduledDates: [],
		};

		if (schedule.repeats === EventRepeats.SINGLE_DATE) {
			config.schedule.endDate = config.schedule.startDate;
		}

		if (schedule.weeklyRepeat && !Array.isArray(schedule.weeklyRepeat)) {
			schedule.weeklyRepeat = [schedule.weeklyRepeat];
		}

		if (schedule.repeats !== EventRepeats.SINGLE_DATE) {
			const dates = this.datesService.getDates(schedule.startDate, schedule.endDate);
			switch (schedule.repeats) {
				case EventRepeats.DAILY:
					config.scheduledDates = this.datesService.getDates(schedule.startDate, schedule.endDate);
					break;
				case EventRepeats.WEEKLY:
					config.scheduledDates = this.scheduleService.getDatesByWeeklyRepeats(dates, schedule.weeklyRepeat, true);
					config.isSplitted = true;
					config.schedule = config.scheduledDates.map(dArr => ({
						...schedule,
						weeklyRepeat: [moment(dArr[0]).format('dddd').toUpperCase()],
					}));
					break;
				case EventRepeats.MONTHLY:
					config.scheduledDates = this.scheduleService
						.getDatesByMonthlyRepeats(
							dates,
							schedule.weeklyRepeat,
							schedule.isMonthlyByDayOfMonth,
							schedule.monthlyDayOfMonth,
							schedule.monthlyDayOfWeek
						);

					config.isSplitted = !schedule.isMonthlyByDayOfMonth;

					if (!schedule.isMonthlyByDayOfMonth) {
						config.schedule = config.scheduledDates.map(dArr => ({
							...schedule,
							weeklyRepeat: [moment(dArr[0]).format('dddd').toUpperCase()],
						}));
					}
					break;
				default:
					break;
			}
		} else {
			config.scheduledDates.push(schedule.startDate);
		}

		const newSchedule = new Schedule();

		let endDate = new Date();
		let configSchedule;

		// TIMES
		let startTime = moment(config.schedule.startTime).format('HH:mm:ss Z');
		let endTime = moment(config.schedule.endTime).format('HH:mm:ss Z');

		// START DATE
		let startDate;
		let startDateTime;
		let startDateMoment;

		// END DATE
		let schedEndDate = moment(endDate).format('MMM-DD-YYYY');
		let endDateTime = new Date(schedEndDate + ' ' + endTime);

		// Check if the formatted time is exactly "00:00:00", which represents 12 AM

		let scheduledDates = [];

		if (schedule.weeklyRepeat === null && schedule.monthlyDayOfWeek === null) {
			config.scheduledDates.map(schedDate => {
				startDate = moment(schedDate).format('MMM-DD-YYYY');
				startDateTime = new Date(startDate + ' ' + startTime);
				startDateMoment = moment(startDateTime);

				// NO DAY OF WEEK SELECTED
				configSchedule = schedule;
				switch (configSchedule.timeEndingDay) {
					case 0:
						endDate = startDateTime;
						break;
					case 1:
						endDate = startDateMoment.add(1, 'days').toDate();
						break;
					case 2:
						endDate = startDateMoment.add(2, 'days').toDate();
						break;
					case 3:
						endDate = startDateMoment.add(3, 'days').toDate();
						break;
					case 4:
						endDate = startDateMoment.add(4, 'days').toDate();
						break;
					case 5:
						endDate = startDateMoment.add(5, 'days').toDate();
						break;
					case 6:
						endDate = startDateMoment.add(6, 'days').toDate();
						break;
				}
				schedEndDate = moment(endDate).format('MMM-DD-YYYY');
				endDateTime = new Date(schedEndDate + ' ' + endTime);

				newSchedule.scheduleItems.push({
					startDate: startDateTime,
					endDate: endDateTime,
					id: schedule.id,
					name: '',
					hidden: false,
					hasInUseTickets: false,
					deleted: false,
				});
			});

		}

		// BY DAY OF WEEK
		if (schedule.weeklyRepeat && schedule.weeklyRepeat.length || schedule.monthlyDayOfWeek) {
			configSchedule = config.schedule[0];
			scheduledDates = config.scheduledDates;
			scheduledDates.map(dates => {
				dates.map(date => {
					startTime = moment(configSchedule.startTime).format('HH:mm:ss Z');
					startDate = moment(date).format('MMM-DD-YYYY');
					startDateTime = new Date(startDate + ' ' + startTime);
					startDateMoment = moment(startDateTime);
					switch (configSchedule.timeEndingDay) {
						case 0:
							endDate = startDateTime;
							break;
						case 1:
							endDate = startDateMoment.add(1, 'days').toDate();
							break;
						case 2:
							endDate = startDateMoment.add(2, 'days').toDate();
							break;
						case 3:
							endDate = startDateMoment.add(3, 'days').toDate();
							break;
						case 4:
							endDate = startDateMoment.add(4, 'days').toDate();
							break;
						case 5:
							endDate = startDateMoment.add(5, 'days').toDate();
							break;
						case 6:
							endDate = startDateMoment.add(6, 'days').toDate();
							break;
					}
					schedEndDate = moment(endDate).format('MMM-DD-YYYY');
					endTime = moment(configSchedule.endTime).format('HH:mm:ss Z');
					endDateTime = new Date(schedEndDate + ' ' + endTime);

					newSchedule.scheduleItems.push({
						startDate: startDateTime,
						endDate: endDateTime,
						id: schedule.id,
						name: '',
						hidden: false,
						hasInUseTickets: false,
						deleted: false,
					});
				});
			});
		}

		newSchedule.scheduleItems.sort((a, b) => {
			if (a.startDate < b.startDate) {
				return -1;
			}
			if (a.startDate > b.startDate) {
				return 1;
			}
			return 0;
		});

		if (newSchedule.scheduleItems.length + this.data.currentScheduleItemCount > 1000) {
			this.tooManyScheduleItems = true;
			this.form.markAsPristine();
		} else if (!newSchedule.scheduleItems.length) {
			this.isEmptySchedule = true;
		} else {
			this.dialogRef.close(newSchedule);
		}
	}

	getError(errors: { [key: string]: string }) {
		return errors && errors[Object.keys(errors)[0]];
	}

	fromForm(controlKey: string): AbstractControl {
		if (this.form) {
			return this.form.get(controlKey);
		}
		return null;
	}

	checkMonthlyRepeatByWeekly(startDateTime: Date, dates: Date[]) {
		dates.filter(date => date >= startDateTime);
	}

	isSubmitAllowed() {
		return this.form && this.form.valid && !this.form.pristine;
	}

	timeRangeValidator(group: FormGroup): { [key: string]: any } | null {
		const start = group.get('startTimeControl').value;
		const end = group.get('endTimeControl').value;
		return start !== null && end !== null && start < end ? null : { 'timeRangeError': true };
	}

	handleStartDateChange(event): void {
		if (this.fromForm('repeats').value === EventRepeats.SINGLE_DATE) {
			if (this.fromForm('startTime').value) {
				this.setFormDateTime('startTime', 'startDate', event.value);
			}
		} else {
			if (this.form.get('startTime').value){
				this.setFormDateTime('startTime', 'startDate');
			} else {
				const date: moment.Moment = event.value;
				date.hour(23);
				date.minute(58);
				date.second(0);
				this.form.get('startDate').setValue(date, { emitEvent: false });
				this.form.get('startDate').updateValueAndValidity();
			}
		}
	}

	handleEndDateChange(event): void {
		if (this.fromForm('repeats').value === EventRepeats.SINGLE_DATE) {
			if (this.fromForm('endTime').value) {
				this.setFormDateTime('endTime', 'endDate', event.value);
			}
		} else {
			const date: moment.Moment = event.value;
			date.hour(23);
			date.minute(59);
			date.second(0);
			this.form.get('endDate').setValue(date, { emitEvent: false });
			this.form.get('endDate').updateValueAndValidity();
			this.form.get('startDate').updateValueAndValidity();

		}
	}

	setFormDateTime(timeFormControl: string, dateFormControl: string, newDate?: Date){
		const startTime: Date = this.fromForm(timeFormControl).value;
		const hours = startTime.getHours();
		const minutes = startTime.getMinutes();
		const startDate: moment.Moment = newDate ? newDate : this.fromForm(dateFormControl).value;
		startDate.set('hour', hours);
		startDate.set('minute', minutes);
		this.fromForm(dateFormControl).setValue(startDate, { emitEvent: false });
		this.form.get(dateFormControl).updateValueAndValidity();
	}

	handleCancel() {
		this.dialogRef.close();
	}

	markTimeAsTouched(control: string) {
		this.fromForm(control).markAsTouched();
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
