<div class="l-container">
	<div class="l-header">
		<h3 class="l-title" [ngClass]="{ 'mobile': data.isMobile }">Share Promoter Network</h3>
		<p class="l-content" [ngClass]="{ 'mobile': data.isMobile }">
			Use this link to invite people to join your promoter network. Joined members then
			get a unique link to send to their contacts and get rewarded for sales that come
			through them.</p>
	</div>
	<div class="l-link-container">
		<div class="l-input-container">
			<input #shareLink class="l-link form-control" [(ngModel)]="data.shortLink" readonly>
			<mat-icon class='l-icon' (click)="handleCopyLink(data.shortLink)">
				file_copy
			</mat-icon>
		</div>
		<div class="l-button-container" *ngIf="!data.isMobile">
			<button class="g-primary-button w-100" (click)="handleClose()">CLOSE</button>
		</div>
	</div>
