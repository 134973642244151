<div [ngClass]="{ 'l-disabled-container': isDisabled, 'l-container': !isDisabled }">
	<img
		class="l-icon"
		[src]="!isDisabled ? icon + '.svg' : icon + '-disabled.svg'"
	/>
	<div class="l-text">{{text}}</div>
	<mat-icon
		*ngIf="isDisabled && tooltip"
		class="l-tooltip"
		[matTooltip]="tooltip"
		matTooltipPosition="right"
	>info</mat-icon>
</div>
