import { SchemeID } from './dataSchema.model';
import { ProductStatus } from '@app/models/event.model';

export interface URLCreatorList {
	/* ASPX */
	joinPromoterNetworkAspx: (id: SchemeID, networkId: SchemeID, encrypt: string) => string;
	trackingScriptsAspx: () => string;
	directDistributionAspx: () => string;
	requestBankDetailsAspx: (id: SchemeID, encrypt: string) => string;
	paymentOptionsAspx: (id: SchemeID) => string;

	/* Event */
	createEvent: () => string;
	dashboard: (id: SchemeID, accountingVersion: number) => string;
	eventDetails: (id: SchemeID) => string;
	eventOrganiser: (id: SchemeID) => string;
	eventDetailsSettings: (id: SchemeID) => string;
	tabbedContent: (id: SchemeID) => string;
	design: (id: SchemeID) => string;
	ticketTypes: (id: SchemeID) => string;
	ticketSettings: (id: SchemeID) => string;
	ticketGroups: (id: SchemeID) => string;
	checkoutQuestions: (id: SchemeID) => string;
	merchendise: (id: SchemeID) => string;
	eventSettings: (id: SchemeID) => string;
	userAccess: (id: SchemeID) => string;
	payoutDetails: (id: SchemeID) => string;
	eventPayoutDetails: (id: SchemeID) => string;
	discountCodes: (id: SchemeID) => string;
	linkCampaigns: (id: SchemeID) => string;
	complimentaries: (id: SchemeID) => string;
	invitations: (id: SchemeID) => string;
	contactInvitations: (id: SchemeID) => string;
	printedTicketsAspx: (id: SchemeID) => string;
	SMSyourGuests: (id: SchemeID) => string;
	zapier: (id: SchemeID) => string;
	mailChimp: (id: SchemeID) => string;
	createMailChimpAccount: () => string;
	widget: (id: SchemeID) => string;
	webhooks: (id: SchemeID) => string;
	exportData: ( id: SchemeID) => string;
	visitorsReport: (id: SchemeID) => string;
	salesReport: (id: SchemeID) => string;
	checkinReport: (id: SchemeID) => string;
	reviewsReport: (id: SchemeID) => string;
	vendingReport: (id: SchemeID) => string;
	eventSummary: (id: SchemeID, accountingVersion: number, encrypt: string, scheduleItem: number | string) => string;
	taxInvoice: (id: SchemeID,  accountingVersion: number, encrypt: string, scheduleItem: number | string) => string;
	sellAtTheDoor: (id: SchemeID) => string;
	requestFieldServices: (id: SchemeID) => string;
	preview: (id: SchemeID, name: string, productStatus: ProductStatus) => string;
	facebookIntegration: (id: SchemeID) => string;
	eventCopy: (id: SchemeID) => string;
	promoterNetwork: (id: SchemeID, networkId: number) => string;
	cancelPostpone: (id: SchemeID, type: string) => string;
	eventScheduleTransfer: (id: SchemeID) => string;
	userRegistrationDetails: (id: SchemeID, regId: SchemeID) => string;
	printGuests: (id: SchemeID, scheduleItemId: SchemeID) => string;

	eventDashboard: (id: SchemeID) => string;
	sendInvites: (id: SchemeID) => string;
	manageEvent: (id: SchemeID) => string;
	tickets: (id: SchemeID) => string;
	paymentOptions: (id: SchemeID) => string;
	eventPayout: (id: SchemeID) => string;
	marketing: (id: SchemeID) => string;
	eventPromoters: (id: SchemeID) => string;
	eventPromoterNetworks: (id: SchemeID) => string;
	eventPromoterNetwork: (id: SchemeID, networkId: string) => string;
	orders: (id: SchemeID) => string;
	scanners: (id: SchemeID) => string;
	fieldServices: (id: SchemeID) => string;
	atTheDoor: (id: SchemeID) => string;
	reports: (id: SchemeID) => string;
	settings: (id: SchemeID) => string;
	eventSettingsTicketing: (id: SchemeID) => string;
	guestList: (id: SchemeID) => string;
	integrations: (id: SchemeID) => string;
	ordersList: (id: SchemeID) => string;
	registrations: (id: SchemeID) => string;
	sendComplimentaryTickets: (id: SchemeID) => string;
	sendRSVPs: (id: SchemeID) => string;
	sendSMSs: (id: SchemeID) => string;
	eventMerchandise: (id: SchemeID) => string;
	promotionalCodes: (id: SchemeID) => string;
	eventUserAccess: (id: SchemeID) => string;
	orderDetails: (id: SchemeID) => string;
	eventVisitorsReport: (id: SchemeID) => string;
	eventSalesReport: (id: SchemeID) => string;
	eventCheckInReport: (id: SchemeID) => string;
	eventReviewsReport: (id: SchemeID) => string;
	eventExportData: (id: SchemeID) => string;
	eventMailChimp: (id: SchemeID) => string;
	eventWidget: (id: SchemeID) => string;
	campaigns: (id: SchemeID) => string;
	definitions: (id: SchemeID) => string;
	cancel: (id: SchemeID) => string;
	postpone: (id: SchemeID) => string;
	copyEvent: (id: SchemeID) => string;
	eventUserRegistrationDetails: (id: SchemeID, regId: SchemeID) => string;
	settingsEvent: (id: SchemeID) => string;
	organiserProfile: (id: SchemeID) => string;
	eventWebhooks: (id: SchemeID) => string;
	guestManagement: (id: SchemeID) => string;
	printedTickets: (id: SchemeID) => string;

	/* Angular Elements */
	eventsPage: () => string;
	eventFeaturesPage: () => string;

	/* User */
	savedEvents: () => string;
	userProfile: () => string;
	viewOrganiserProfile: () => string;
	manageTicketDashboard: () => string;
	viewTickets: (id: SchemeID, encrypt: string) => string;
	downloadAllTickets: (id: SchemeID, encrypt: string) => string;
	viewInvoice: (id: SchemeID, encrypt: string) => string;
	vending: () => string;

	logout: () => string;
	login: (redirectUrl?: string) => string;


	/* My Account */
	myAccount: () => string;
	personalInfo: () => string;
	contactInfo: () => string;
	emailPreference: () => string;
	changePassword: () => string;
	payouts: () => string;
	apiKey: () => string;
	requestBankDetails: (id: SchemeID, encrypt: string) => string;
	myVending: () => string;

	/* Tickets & Registrations */
	myTickets: (cartId?: SchemeID) => string;
	myRegistrations: (cartId?: SchemeID) => string;
	manageBooking: (id: SchemeID) => string;
	transferTickets: (id: SchemeID) => string;
	refundTickets: (id: SchemeID) => string;
	resaleTickets: (id: SchemeID) => string;
	editTickets: (id: SchemeID) => string;
	registrationDetails: (id: SchemeID) => string;

	/* Earn Commission */
	earnCommission: () => string;
	promoterNetworksList: () => string;
	referrals: () => string;
	promoterDetails: (id: SchemeID) => string;
	joinPromoterNetwork: (id: SchemeID, networkId: SchemeID, encrypt: string) => string;

	/* Organiser Hub */
	orgDashboard: () => string;
	orgEvents: () => string;
	orgProfile: () => string;
	orgProfileDetails: (id: SchemeID) => string;
	orgProfileMailChimp: (id: SchemeID) => string;
	orgMailAccounts: () => string;
	orgDirectDistribution: () => string;
	orgTrackingScripts: () => string;

	/* Other */
	helpCentre: () => string;
	contactUs: () => string;
	blog: () => string;
	careers: () => string;
	privacyPolicy: () => string;
	community: () => string;
	security: () => string;
	fees: () => string;
	terms: () => string;
	home: () => string;
	contactHost: (id: SchemeID) => string;
	referralArticle: () => string;
	downloadScannerAppAndroid: () => string;
	downloadScannerAppIos: () => string;
	streamingHelp: () => string;
	mailChimpHelp: () => string;
}

export type URLList = {
	[K in keyof URLCreatorList]: string;
};

export enum ACLItemAccess {
	READ = 'read',
	WRITE = 'write',
	NOACCESS = 'noaccess',
}

export enum ACLItem {
	dashboard = 'dashboard',
	contactInvitations = 'invites',
	fundraiserDashboard = 'dashboard',
	tabbedContent = 'tabbed_content',
	tabbedContentEdit = 'tabbed_content_edit',
	eventDetails = 'information',
	fundraiserDetails = 'information',
	themes = 'themes',
	createEvent = '___',
	eventDetailsSettings = 'information',
	design = 'design',
	fundraiserDesign = 'design',
	socialMedia = 'social_media',
	ticketTypes = 'tickets',
	fundraisingGoal = 'tickets',
	ticketSettings = 'tickets',
	ticketGroups = 'ticket_groups',
	ticketGroupsAddEdit = 'ticket_groups_add_edit',
	checkoutQuestions = 'collect_info',
	merchendise = 'merchandise',
	addMerchandise = 'add_merchandise',
	merchandiseEdit = 'merchandise_edit',
	addEditCustomQuestion = 'add_edit_custom_question',
	registration = 'Registrations',
	collectInformationOnPreregistrationTeams = 'collect_information_on_preregistration',
	getRegistrations = 'get_registrations',
	registrationView = 'registration_view',
	paymentOptions = 'payment_options',
	eventSettings = 'settings',
	userAccess = '___',
	payoutDetails = 'payout_details',
	discountCodes = 'discount_codes',
	discountCodesEdit = 'add_edit_discount_code',
	getDiscountCodesList = 'GetDiscountCodeList',
	shareAndSave = 'share_and_save',
	linkCampaigns = 'link_campaigns',
	fundraisingLinkCampaigns = 'link_campaigns',
	promoters = 'promoters',
	allocateTickets = 'allocate_tickets',
	guestsList = 'guest_list',
	getGuestsList = 'get_guest_list',
	oldGusetList = 'guestlist',
	getGuestDetails = 'get_guest_details',
	resendTicketDetails = 'post_resend_ticket',
	ordersList = 'orders_list',
	getOrders = 'getorders',
	getOrders2 = 'getorders2',
	cancelTickets = 'systemName',
	resendTickets = 'resendtickets',
	changeTicketTypes = 'changetickettypes',
	editTickets = 'edit_tickets',
	complimentaries = 'complimentaries',
	deliveryReportForComplimentaries = 'delivery_report_for_complimentaries',
	invitations = 'invitations',
	printedTickets = 'pre_printed_tickets',
	printBarcodes = 'print_barcodes',
	SMSyourGuests = 'sms_your_guests',
	eventListingSummary = 'event_listing_summary',
	getAnalyticsKeyMetrics = 'GetAnalyticsKeyMetrics',
	getAnalyticsReferringSources = 'GetAnalyticsReferringSources',
	getAnalyticsVisitorFlow = 'GetAnalyticsVisitorFlow',
	getAnalyticsWorldMap = 'GetAnalyticsWorldMap',
	getAnalyticsCountryMap = 'GetAnalyticsCountryMap',
	getAnalyticsSocialData = 'GetAnalyticsSocialData',
	getAnalyticsMobileData = 'GetAnalyticsMobileData',
	getAnalyticsAgeData = 'GetAnalyticsAgeData',
	getAnalyticsGenderData = 'GetAnalyticsGenderData',
	getAnalyticsSocialSalesData = 'GetAnalyticsSocialSalesData',
	getAnalyticsSalesTicketTypesData = 'GetAnalyticsSalesTicketTypesData',
	getAnalyticsSalesReferringSources = 'GetAnalyticsSalesReferringSources',
	getAnalyticsSalesKeyMetrics = 'GetAnalyticsSalesKeyMetrics',
	getAnalyticsSalesFlow = 'GetAnalyticsSalesFlow',
	getAnalyticsMobileSalesData = 'GetAnalyticsMobileSalesData',
	getAnalyticsKeyCheckinMetrics = 'GetAnalyticsKeyCheckinMetrics',
	getAnalyticsCheckinScannerStationsData = 'GetAnalyticsCheckinScannerStationsData',
	getAnalyticsCheckinScannerFlowData = 'GetAnalyticsCheckinScannerFlowData',
	sellOnFacebook = '___',
	zapier = '___',
	mailChimp = '___',
	widget = 'widget',
	webhooks = 'webhooks',
	API = '___',
	exportData = 'export_data',
	visitorsReport = 'visitors_report',
	salesReport = 'sales_report',
	checkinReport = 'checkin_report',
	eventStatement = 'event_summary',
	eventSummary = 'event_summary', // diff
	taxInvoice = 'tax_invoice',
	financialReports = 'financial_reports', // NOURLNEEDED
	scanners = 'scanners',
	addScannerToYourEvent = 'add_scanner_to_your_event',
	sellAtTheDoor = 'sell_at_the_door', // 1
	getAtTheDoorTickets = 'get_at_the_door_tickets',
	pushAtTheDoorTickets = 'push_at_the_door_tickets',
	requestFieldServices = 'request_field_services',
	preview = '___',
	helpCentre = '___',
	blog = '___',
	manageEvents = '___',
	manageTickets = '___',
	userProfile = '___',
	manageTeams = '___',
	team = 'teams',
	scanningAccess = 'scanning_access', // 1
	vending = 'vending',
	logout = '___',
	eventPagePreview = 'event_page_preview',
	facebookIntegration = 'facebook_integration',
	eventCopy = 'event_copy',
	eventStreaming = 'streaming',
	fundraiserCollectionsWidget = 'collections',
	transferSchedule = 'transfer_schedule',
	promoterNetworks = 'promoter_networks',
}

export enum ProductRoute {
	EVENT = 'event',
	FUNDRAISER = 'fundraiser',
	SUBSCRIPTION = 'subscriptions',
	USER = 'user',
}

export enum ProductPath {
	SUBSCRIPTIONS_PROFILE = 'subscriptions-profile',
}
