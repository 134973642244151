import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule, RouterStateSerializer } from '@ngrx/router-store';
import { initialState, reducersToken, reducersProvider } from './';
import { CustomSerializer } from './index';
import { environment } from 'environments/environment';

@NgModule({
	imports: environment.production ? [
		StoreModule.forRoot(reducersToken, { initialState }),
		StoreRouterConnectingModule.forRoot({
			stateKey: 'router',
		}),
	] : [
		StoreModule.forRoot(reducersToken, { initialState }),
		StoreDevtoolsModule.instrument(),
		StoreRouterConnectingModule.forRoot({
			stateKey: 'router',
		}),
	],
	providers: [reducersProvider, {
		provide: RouterStateSerializer,
		useClass: CustomSerializer,
	}],
}) export class ReducerModule { }
