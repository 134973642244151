import { Component, Input } from '@angular/core';
import { FormField } from '../form-field.model';
import { EditorImageUploadOptions } from './../../editor/editor.component';

@Component({
	selector: 'app-text-editor',
	templateUrl: './text-editor.component.html',
	styleUrls: ['./text-editor.component.sass', '../form-field.component.sass'],
})
export class TextEditorComponent extends FormField {
	@Input() editorStyle = {};
	@Input() imageUploadHandler: EditorImageUploadOptions;
	@Input() label: string;
	@Input() required: boolean;
}
