import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { VerifyToken } from '@app/store/actions/user/user.actions';
import { environment } from 'environments/environment';
import { StoreService } from '@app/services/store/store.service';
import { TokenService } from '@app/services/token/token.service';
import { URLService } from '@app/services/url/url.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.sass'],
})

export class LoginComponent implements OnInit {
	constructor(
		private storeService: StoreService,
		private urlService: URLService,
		private router: Router,
		private tokenService: TokenService
	) { }

	ngOnInit() {
		const token = this.tokenService.getToken();

		if (token) {
			this.storeService.dispatch(new VerifyToken({ token }));
		} else {
			if (environment.production || environment.staging) {
				let redirectUrl = `/app/#/account/${this.router.url}`;
				if (this.router.url === '/login') {
					redirectUrl = '/app/#/account';
				}
				this.urlService.navigate('login', [encodeURIComponent(redirectUrl)])();
			} else {
				this.router.navigate(['/login']);
			}
		}
	}
}
