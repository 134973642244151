import { Component, Input } from '@angular/core';
import { SocialSharingService } from '@app/services/social-sharing/socail-sharing.service';

@Component({
	selector: 'app-facebook-sharing-btn',
	templateUrl: './facebook-sharing.component.html',
})

export class FacebookSharingButtonComponent {
	@Input() link: string;
	constructor(
		private socialSharing: SocialSharingService
	) { }

	share() {
		this.socialSharing.shareToFacebook(this.link);
	}
}
