import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { SchemeID } from '@app/models/dataSchema.model';
import { EventTicketGroup, EventTicketGroupTicketType } from '@app/models/event.model';
import { StoreService } from '@app/services/store/store.service';
import { DeleteEventTicketGroup, GetEventTicketGroups, SetTicketGroupsUsage } from '@app/store/actions/event/event.actions';
import * as eventSelectors from '@app/store/selectors/event.selector';
import { takeUntil } from 'rxjs';
import config from '@app/config';
import { Actions, ofType } from '@ngrx/effects';
import { EventActionsConstants } from '@app/store/actions/event/event.actions.constants';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '@app/shared/modals/confirmation-modal/confirmation-modal.component';



@Component({
	selector: 'app-ticket-groups-list',
	templateUrl: './ticket-groups-list.component.html',
	styleUrls: ['./ticket-groups-list.component.sass'],
})
export class TicketGroupsListComponent {
	@Input() isMobile = false;
	@Input() eventId: SchemeID;
	@Input() ticketGroupForm: UntypedFormGroup;
	@Input() isLoading: boolean;
	@Input() noTicketGroups: false;
	@Input() ticketGroups: EventTicketGroup[];
	@Input() ticketTypes: EventTicketGroupTicketType[];
	@Output() addOrEditTicketGroup = new EventEmitter<EventTicketGroup>();
	@Output() reorder = new EventEmitter<CdkDragDrop<string[]>>();
	@Output() deleteTicketGroup = new EventEmitter();

	isFiltering = false;
	dragDisabled = true;
	searchTerm: string;
	useTicketGroups: boolean;
	displayColumns = ['sorting', 'groupName', 'image', 'limitedTickets', 'actions'];
	filteredTicketGroups: EventTicketGroup[];
	isAddButtonDisabled = false;
	disabledAddTicketGroupsButtonText = `All ticket types have been used please remove a ticket type from an existing group
										 or add more ticket types`;
	useTicketGroupsTooltip = `If this is set to OFF, your ticket types will display normally with no groupings.
							  If set to ON, any ticket types not linked to a group will be displayed in a group called OTHER.`;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private store: StoreService,
		private action$: Actions,
		private dialog: MatDialog
	) { }

	ngOnInit(): void {
		this.store.select(eventSelectors.useTicketGroups()).pipe(takeUntil(this.destroyed$))
			.subscribe(useTicketGroups => {
				this.useTicketGroups = useTicketGroups;
			});
		this.action$
			.pipe(
				ofType(EventActionsConstants.DELETE_TICKET_GROUP_SUCCESS),
				takeUntil(this.destroyed$)
			)
			.subscribe(({ payload }:
			{ payload: { eventId: SchemeID; id: number } }) => {
				if (this.filteredTicketGroups) {
					this.filteredTicketGroups = this.filteredTicketGroups.filter(x => x.id !== payload.id);
				}
				this.store.dispatch(new GetEventTicketGroups({ eventId: this.eventId }));
				this.deleteTicketGroup.emit();
				if (this.areAllTicketTypesLinked()){
					this.isAddButtonDisabled = true;
				}
			});

		if (this.areAllTicketTypesLinked()){
			this.isAddButtonDisabled = true;
		}
	}

	handleSearch(searchTerm: string) {
		this.searchTerm = searchTerm;
		if (!this.searchTerm) {
			this.isFiltering = false;
			this.filteredTicketGroups = null;
		} else {
			this.isFiltering = true;
			this.filteredTicketGroups = this.ticketGroups.filter(ticketGroup =>
				ticketGroup.name.toLowerCase().includes(searchTerm.toLowerCase())
			);
		}
	}

	getDataSource() {
		if (this.filteredTicketGroups) {
			return this.filteredTicketGroups;
		} if (this.ticketGroups) {
			return this.ticketGroups;
		}
	}

	onTicketGroupFormRequest(eventTicketGroup: EventTicketGroup) {
		if (eventTicketGroup) {
			this.addOrEditTicketGroup.emit(eventTicketGroup);
		} else {
			this.addOrEditTicketGroup.emit();
		}
	}

	handleDeleteTicketGroup(ticketGroupId: SchemeID) {
		this.dialog.open(ConfirmationModalComponent, {
			data: {
				title: 'Delete Ticket Group?',
				text: 'Are you sure you want to delete your ticket group?',
				buttonText: 'YES',
				isMobile: this.isMobile,
			},
			panelClass: 'g-standard-dialog',
		}).afterClosed().subscribe((result) => {
			if (result) {
				this.store.dispatch(new DeleteEventTicketGroup({
					eventId: this.eventId,
					id: ticketGroupId,
				}));
			}
		});
	}

	toggleUseTicketGroups(event: MatSlideToggleChange) {
		this.store.dispatch(new SetTicketGroupsUsage({
			eventId: this.eventId,
			useTicketGroups: event.checked,
			prevValue: !event.checked,
		}));
	}

	onReorder(event: CdkDragDrop<string[]>) {
		this.dragDisabled = true;
		this.reorder.emit(event);
	}

	getImagePreview(thumbnail: string) {
		return `${config.baseURL}${thumbnail}`;
	}

	areAllTicketTypesLinked(): boolean {
		const allLinkedTicketTypes = this.ticketGroups.reduce((acc, group) => acc.concat(group.linkedTicketTypes), []);

		return this.ticketTypes.every(ticketType => allLinkedTicketTypes.includes(ticketType.id));
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
