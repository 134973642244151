import { EventCurrencyOption, ProductStatus } from './event.model';
import { ProductType } from './product.model';

export interface SalesOverview {
	totalTickets: number;
	tickets: ChartInput[];
	totalSales: number;
	sales: ChartInput[];
	currencies: EventCurrencyOption[];
	currencyId: number;
	currencyName: string;
	currencySymbol: string;
}

export interface SalesOverviewCurrency {
	id: number;
	name: string;
	symbol: string;
}

export interface ReportByTicketType {
	name: string;
	series: TicketTypeInputValue[];
}

export interface TicketTypeInputValue {
	id: string;
	income: string;
	name: string;
	ticketsSold: string;
}

export interface ChartInput {
	name: string;
	series: ChartInputValue[];
}

export interface ChartInputValue {
	value: number;
	name: string;
	id?: string;
}

export interface TicketTypeColour {
	name: string;
	value: string;
	id?: string;
}

export interface TotalOrders {
	total: string;
	orders: ChartInput[];
}

export interface EventList {
	userProducts: BasicEventCard[];
	totalProducts: number;
}

export interface BasicEventCard {
	id: number;
	name: string | null;
	date: string | null;
	isPublic: boolean;
	image: string;
	ticketCount: number;
	isOngoing: boolean;
	isOwner: boolean;
	organiserId: number;
	organiserName: string;
	status: number;
}

export class ProductFilter {
	type: ProductType = ProductType.Event;
	pageSize = 10;
	pageNumber = 1;
	search = '';
	sortOrder: SortOrder = 0;
	sortBy: ProductListSortBy = 1;
	statusFilter: ProductStatus = null;
	organiserFilter: number = null;
}

export interface NameAndID {
	id: number;
	name: string;
}

export enum PaymentStatus {
	Approved = 1,
	Busy = 2,
	Failed = 3
}

export enum ProductListSortBy {
	eventDetails = 1,
	quantity = 2,
	id = 3,
	startDate = 4,
}

export enum SortOrder {
	asc = 0,
	desc = 1,
}

export class MailAccount {
	id: number = null;
	name: string = null;
	fromName: string = null;
	fromEmail: string = null;
	type = 1;
	typeName = 'Mandrill';
	apiKey: string = null;
}

export interface OrganiserDashboardEvents {
	upcoming: OrganiserDashboardEvent[];
	past: OrganiserDashboardEvent[];
}

export interface OrganiserDashboardEvent {
	id: number;
	name: string;
	image: string;
	startDate: string;
	isPublic: boolean;
	organiserName: string;
}

export interface MailChimpConnection {
	connected: boolean;
	connectLink: string;
}

export interface DateRange {
	dateFrom: string;
	dateTo: string;
}

export class DownloadUserReportsInfo {
	type: number;
	productIds: number[];
	dateFrom?: Date;
	dateTo?: Date;
	organiserId?: number;
}

export class UserReportInfo {
	reportTypes: UserReportType[];
	products: UserReportNameId[];
	organisers: UserReportNameId[];
}

export class UserReportType {
	id: number;
	name: string;
	fields: string[];
}

export class UserReportNameId {
	id: number;
	name: string;
}

export enum UserReportTypes {
	FinancialReport = 'Financial Report',
	TicketBreakdown = 'Ticket Breakdown',
	OrganisersReport = 'Organisers Report',
	OrganisersPOSReport = 'Organisers POS Report',
	OrganiserCashupReport = 'Organisers Cashup Report',
}

export enum UserReportOptions {
	Products = 'products',
	Dates = 'dates',
	Organisers = 'organisers',
}
