import { Component, EventEmitter, OnInit } from '@angular/core';
import { NormalizedScheme, SchemeID } from '@app/models/dataSchema.model';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { StoreService } from '@app/services/store/store.service';
import { Observable, Subscription, map, of, take, takeUntil, withLatestFrom } from 'rxjs';
import * as eventSelectors from '@app/store/selectors/event.selector';
import {
	CollectInfoPageMode,
	DraftEventCollectInfoForm,
	EventCollectInfo,
	EventCollectInfoBase,
	EventCollectInfoTicket,
	EventQuestionToggle
} from '@app/models/event.model';
import {
	DeleteCollectInfo,
	GetEventCollectInfo,
	GetProfileCollectInfo,
	ReorderCollectInfo,
	SetCollectInfoProperty,
	UpsertEventCollectInfo
} from '@app/store/actions/event/event.actions';
import { ActivatedRoute } from '@angular/router';
import { EVENT_ID_PARAM_KEY } from '@app/utils/consts';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Actions, ofType } from '@ngrx/effects';
import { EventActionsConstants } from '@app/store/actions/event/event.actions.constants';
import { HeaderContent } from '@app/models/shared';
import { InternalURLCreator } from '@app/services/url/url.dictionary';
import { SelectFieldOption } from '@app/shared/form-field/select-field/select-field.model';
import { UntypedFormGroup } from '@angular/forms';
import { CollectInfoService } from '@app/services/events/tickets/collect-info.service';
import { Go } from '@app/store/actions/router/router.actions';
import { ConfirmationModalComponent } from '@app/shared/modals/confirmation-modal/confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { FormCanDeactivate } from '@app/services/guards/event.guard';

@Component({
	selector: 'app-checkout-questions',
	templateUrl: './checkout-questions.component.html',
	styleUrls: ['./checkout-questions.component.sass'],
})
export class CheckoutQuestionsComponent extends FormCanDeactivate implements OnInit {
	isMobile = false;
	eventId: SchemeID;
	isLoading = true;
	noQuestions = false;
	questions: EventCollectInfo[];
	profileCollectInfo: NormalizedScheme<EventCollectInfoBase>;
	profileCollectInfoOptions: SelectFieldOption<number>[];
	selectedQuestion: EventCollectInfo = null;
	mode: string = CollectInfoPageMode.List;
	CollectInfoPageMode = CollectInfoPageMode;
	collectInfoTickets: EventCollectInfoTicket[];
	isAddingCustom = false;
	collectInfoForm: UntypedFormGroup;
	collectInfoFormChangeSub: Subscription;
	browserBackClicked = false;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	headerContent: HeaderContent = {
		breadCrumbs: [
			{
				routeName: 'Manage Checkout',
				routeTo: () => InternalURLCreator.tickets(this.eventId),
			},
			{
				routeName: 'Checkout Questions',
				routeTo: () => InternalURLCreator.checkoutQuestions(this.eventId),
			},
		],
		title: 'Checkout Questions',
		description: 'Below is a list of questions tickets buyers will be asked to complete when checking out.',
	};

	constructor(
		private store: StoreService,
		private breakpointService: BreakpointService,
		private activatedRoute: ActivatedRoute,
		private action$: Actions,
		private collectInfoService: CollectInfoService,
		private dialog: MatDialog
	) {
		super();
	}

	ngOnInit(): void {
		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});

		this.activatedRoute.parent.paramMap.pipe(take(1)).subscribe((params) => {
			this.eventId = +params.get(EVENT_ID_PARAM_KEY);
		});

		this.initialiseCollectInfo();

		window.addEventListener('popstate', () => {
			this.browserBackClicked = true;
		}, false);
	}

	initialiseCollectInfo() {
		this.store.select(eventSelectors.eventCollectInfo()).pipe(takeUntil(this.destroyed$)).subscribe(questions => {
			this.questions = questions;
		});

		this.store.select(eventSelectors.eventCollectInfoTickets()).pipe(takeUntil(this.destroyed$)).subscribe(collectInfoTickets => {
			this.collectInfoTickets = collectInfoTickets;
		});

		this.store.select(eventSelectors.profileCollectInfo()).pipe(takeUntil(this.destroyed$)).subscribe(profileCollectInfo => {
			this.profileCollectInfo = profileCollectInfo;
		});

		this.action$
			.pipe(
				ofType(EventActionsConstants.GET_EVENT_COLLECT_INFO_SUCCESS, EventActionsConstants.GET_EVENT_COLLECT_INFO_FAILED),
				takeUntil(this.destroyed$)
			)
			.subscribe(() => {
				this.isLoading = false;
				if (this.questions.length < 1) {
					this.noQuestions = true;
				}
			});

		this.action$
			.pipe(
				ofType(EventActionsConstants.DELETE_COLLECT_INFO_SUCCESS),
				takeUntil(this.destroyed$)
			)
			.subscribe(() => {
				this.store.dispatch(new GetProfileCollectInfo({eventId: this.eventId, isRegistration: false}));
			});

		this.store.select(eventSelectors.profileCollectInfo())
			.pipe(
				withLatestFrom(of(this.questions)),
				map(
					([profile, event]) => (
						profile
							? profile.all
								.filter(
									id => !event.some(e => e.profileQuestionId === id)
								)
								.map(
									id => ({
										value: id as number,
										label: profile.byId[id].question,
									})
								)
							: []
					)
				),
				takeUntil(this.destroyed$)
			)
			.subscribe(result => {
				this.profileCollectInfoOptions = result;
			});

		this.getCollectInfo();
	}

	getCollectInfo() {
		this.store.dispatch(new GetEventCollectInfo({eventId: this.eventId, isRegistration: false}));
		this.store.dispatch(new GetProfileCollectInfo({eventId: this.eventId, isRegistration: false}));
	}

	handleBreadCrumbNavigation(routeTo: CallableFunction) {
		if (
			routeTo(this.eventId) === InternalURLCreator.checkoutQuestions(this.eventId)
			&& this.mode !== CollectInfoPageMode.List
		) {
			this.handleRoutingBackToListPage();
		} else {
			this.store.dispatch(new Go({ path: [routeTo(this.eventId)] }));
		}
	}

	handleUnsavedChanges(): Observable<boolean> {
		const dialogRef = this.dialog.open(ConfirmationModalComponent, {
			data: {
				title: 'Unsaved Changes',
				text: 'Are you sure you want to leave this page? Any unsaved changes will be discarded.',
				buttonText: 'LEAVE',
				centerText: true,
				isMobile: this.isMobile,
			},
			panelClass: 'g-standard-dialog',
		});

		return dialogRef.afterClosed();
	}

	setHeaderDescription() {
		let description = '';

		switch (this.mode) {
			case CollectInfoPageMode.List:
				description = 'Below is a list of questions tickets buyers will be asked to complete when checking out.';
				break;
			case CollectInfoPageMode.Create:
				description = 'Create a question for your event. You can select which tickets this question will apply to.';
				break;
			case CollectInfoPageMode.Edit:
				description = 'Edit your question below';
				break;
		}

		this.headerContent.description = description;
	}

	onEventQuestionPropertyToggle = (data: EventQuestionToggle) => {
		this.store.dispatch(
			new SetCollectInfoProperty({
				id: data.id,
				eventId: this.eventId,
				name: data.name,
				value: data.value,
				prevValue: data.prevValue,
			})
		);
	};

	onEventQuestionDeletion = (id: SchemeID) => {
		this.store.dispatch(
			new DeleteCollectInfo({
				eventId: this.eventId,
				id,
			})
		);
	};

	onReorder(event: CdkDragDrop<string[]>) {
		const prevOrder = this.questions.map(q => q.id);
		moveItemInArray(this.questions, event.previousIndex, event.currentIndex);
		const newOrder = this.questions.map(q => q.id);

		this.store.dispatch(
			new ReorderCollectInfo({
				eventId: this.eventId,
				prevOrder: prevOrder,
				order: newOrder,
				isRegistration: false,
			})
		);
	}

	onCollectInfoFormRequest = (question?: EventCollectInfo) => {
		const title = question ? 'Edit Question' : 'Add Question';
		this.headerContent.title = title;
		this.headerContent.breadCrumbs = [
			...this.headerContent.breadCrumbs,
			{
				routeName: title,
			},
		];

		if (this.collectInfoForm) {
			this.collectInfoForm.get('applyToBuyer').enable();
		}

		this.collectInfoForm = this.collectInfoService.createCollectInfoForm(question);

		if (this.collectInfoFormChangeSub) {
			this.collectInfoFormChangeSub.unsubscribe();
		}

		this.collectInfoFormChangeSub = this.collectInfoService
			.setFormValueChangeHandler({
				form: this.collectInfoForm,
				profileCollectInfo: this.profileCollectInfo,
				collectInfoTickets: this.collectInfoTickets,
				isRegistration: false,
			});

		if (question) {
			this.mode = CollectInfoPageMode.Edit;
		} else {
			this.mode = CollectInfoPageMode.Create;
		}

		this.setHeaderDescription();
	};

	onCollectInfoFormClose = (draftQuestion?: DraftEventCollectInfoForm) => {
		if (draftQuestion) {
			this.store.dispatch(
				new UpsertEventCollectInfo({
					eventId: this.eventId,
					collectInfo: draftQuestion,
					isUpdating: !!draftQuestion.id,
				})
			);

			this.collectInfoForm.markAsPristine();
		}
		this.handleRoutingBackToListPage();
	};

	handleRoutingBackToListPage() {
		if (this.collectInfoForm && !this.collectInfoForm.pristine) {
			this.handleUnsavedChanges().subscribe((navigateBack) => {
				if (navigateBack) {
					this.performRoutingBack();
					this.collectInfoForm.markAsPristine();
				}
			});
		} else {
			this.performRoutingBack();
		}
	}

	performRoutingBack() {
		this.headerContent.breadCrumbs = this.headerContent.breadCrumbs.slice(0, -1);
		this.headerContent.title = 'Checkout Questions';
		this.mode = CollectInfoPageMode.List;
		this.setHeaderDescription();
	}

	isFormDeactivatable(): boolean | Observable<boolean> {
		return (this.isMobile || !this.collectInfoForm || this.collectInfoForm.pristine) && this.mode === CollectInfoPageMode.List;
	}

	deactivationFallback(): Observable<boolean> {
		this.handleUnsavedChanges()
			.subscribe((result) => {
				this.closeDeactivationModal(result);
				if (result) {
					if (this.collectInfoForm) {
						this.collectInfoForm.markAsPristine();
					}

					if (this.browserBackClicked) {
						this.browserBackClicked = false;

						this.handleRoutingBackToListPage();
						this.store.dispatch(new Go({ path: [InternalURLCreator.checkoutQuestions(this.eventId)] }));

						this.getCollectInfo();
					}
				}
			});
		return this.modalCallback.asObservable();
	}

	closeDeactivationModal(isConfirmed: boolean): void {
		this.modalCallback.next(isConfirmed);
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
