import { Injectable } from '@angular/core';
import { FormService } from '@app/services/form/form.service';
import { DraftDetailsSettingsForm, EventDetailsSettings } from '@app/models/event.model';
import { FormValidators, FormSchemeControls } from '@app/models/common.model';
import { requiredValidator, numberMoreThen, maxLength } from '@app/shared/form-field/form-validators';

@Injectable()
export class EventSettingsService {
	constructor(
		private formService: FormService
	) {}

	createEventDetailsSettingsForm(initialValues?: Partial<EventDetailsSettings>) {
		const formValues = new DraftDetailsSettingsForm();

		const validatorsConfig: FormValidators<Partial<DraftDetailsSettingsForm>> = {
			eftDaysToPay: [requiredValidator(), numberMoreThen(0, 'This needs to be 1 or greater')],
			purchaseTimeLimit: [requiredValidator(), numberMoreThen(4, 'This needs to be 5 minutes or more')],
			incentiveMessage: [maxLength(2500, 'Cannot be more than 2500 characters')],
			purchaseSuccessMessage: [maxLength(2500, 'Cannot be more than 2500 characters')],
		};

		const formConfig: Partial<FormSchemeControls<DraftDetailsSettingsForm>> =
			this.formService
				.createFormControls({
					formValues,
					initialValues,
					validatorsConfig,
				});

		return this.formService.formBuilder.group(formConfig);
	}
}
