<div [ngClass]="outerClass">
    <label *ngIf="label" [ngClass]="labelClass" class="g-form-label">
        {{label}} <span *ngIf="required" class="g-orange">*</span>
        <mat-icon
            *ngIf="tooltip"
            class="l-tooltip"
            [matTooltip]="tooltip"
            matTooltipPosition="right"
			#selectFieldTooltip="matTooltip"
			(click)="selectFieldTooltip.toggle()"
        >
            info
        </mat-icon>
    </label>
    <mat-select
        class="form-control w-100"
        [disabled]="disabled"
        [formControl]="control"
        [placeholder]="placeholder"
        [id]="customId"
        (selectionChange)="optionSelected($event)"
    >
        <mat-option *ngFor="let option of options" [value]="option.value">
            {{ option.label }}
        </mat-option>
    </mat-select>
    <small class="g-error-input" *ngIf="isControlInvalid() && handleErrors">
		*{{getError(control.errors)}}
	</small>
</div>
