import { OrganiserState } from '@app/models/store.model';

export const initialState: OrganiserState = {
	dashboard: {
		dashboardEvents: null,
		topLinkCampaigns: [],
		salesOverview: null,
	},
	events: {
		organiserNames: null,
		filteredProducts: null,
	},
	mailAccounts: null,
	mailChimpConnection: {
		connected: null,
		connectLink: null,
	},
};
