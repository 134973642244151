<div class="l-table-container">
	<table mat-table [dataSource]="dataSource" multiTemplateDataRows class='l-table expansion' cdkDropList
		(cdkDropListDropped)="handleDrag($event)" [cdkDropListDisabled]="dragDisabled" #tabContentTable>
		<ng-container matColumnDef="sorting">
			<th mat-header-cell *matHeaderCellDef class="l-sorting-column"></th>
			<td 
				mat-cell 
				*matCellDef="let attributeContent" 
				class="l-sorting-column"
				[ngClass]='{"expansion-parent": expandedDetailElement !== attributeContent.value.contentUid, "current-expansion-parent": expandedDetailElement === attributeContent.value.contentUid}'
			>
				<img src="./assets/icons/dots-nine.svg" alt="Drag Icon" class="l-info-icon g-move"
					(mousedown)="dragDisabled = false;"
					(touchstart)="dragDisabled = false;">
			</td>
		</ng-container>
		<ng-container matColumnDef="isExpanded">
			<th mat-header-cell *matHeaderCellDef class="l-expanded-column"></th>
			<td 
				mat-cell 
				*matCellDef="let attributeContent"
				class="l-expanded-column"
				[ngClass]='{
					"expansion-parent": expandedDetailElement !== attributeContent.value.contentUid,
					"current-expansion-parent": expandedDetailElement === attributeContent.value.contentUid
				}'
			>
				<mat-icon [@rotateIcon]="expandedDetailElement !== attributeContent.value.contentUid ? 'default' : 'rotated'">chevron_right</mat-icon>
			</td>
		</ng-container>
		<ng-container matColumnDef="name">
			<th mat-header-cell *matHeaderCellDef> Name </th>
			<td 
				mat-cell 
				*matCellDef="let attributeContent" 
				id="attribute-content-name"
				[ngClass]='{
					"expansion-parent": expandedDetailElement !== attributeContent.value.contentUid,
					"current-expansion-parent": expandedDetailElement === attributeContent.value.contentUid
				}'
			>
				{{attributeContent.value.name}}
			</td>
		</ng-container>
		<ng-container matColumnDef="visibility">
			<th mat-header-cell *matHeaderCellDef> Visibility </th>
			<td 
				mat-cell 
				*matCellDef="let attributeContent"
				[ngClass]='{
					"expansion-parent": expandedDetailElement !== attributeContent.value.contentUid,
					"current-expansion-parent": expandedDetailElement === attributeContent.value.contentUid
				}'
			>
				<mat-slide-toggle class="standard-toggle"
					(click)="$event.stopPropagation()" [formControl]="attributeContent.get('isVisible')"
					name="displayReviews" id="attribute-content-visibility">
				</mat-slide-toggle>
			</td>
		</ng-container>
		<ng-container matColumnDef="delete">
			<th mat-header-cell *matHeaderCellDef></th>
			<td 
				mat-cell 
				*matCellDef="let attributeContent"
				[ngClass]='{
					"expansion-parent": expandedDetailElement !== attributeContent.value.contentUid,
					"current-expansion-parent": expandedDetailElement === attributeContent.value.contentUid
				}'
			>
				<mat-icon id="attribute-content-delete" (click)="handleDeleteTabContentType(attributeContent)">delete_outline</mat-icon>
			</td>
		</ng-container>
		<ng-container matColumnDef="expandedDetail">
			<td mat-cell *matCellDef="let attributeContent" [attr.colspan]="displayColumns.length" class="l-expanded-container">
				<div
					[@detailExpand]="attributeContent.value.contentUid != expandedDetailElement ? 'expansion-parent' : 'current-expansion-parent'">
					<app-mat-text-control-field
						[label]="'Name'"
						[placeholder]="'Tab Name'"
						[control]="attributeContent.get('name')"
						[outerClass]="'pb-3'"
						id="attribute-content-Name"
						[required]="true"
						labelClass="g-form-label"
					>
					</app-mat-text-control-field>
					<div class="pb-3">
						<app-text-editor label="Details" [control]="attributeContent.get('description')"
							[editorStyle]="{'height': '320px'}" [imageUploadHandler]="editorImageUploadHandler"
							id="attribute-content-wysiwyg"></app-text-editor>
					</div>
				</div>
			</td>
		</ng-container>
		<tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
		<tr mat-row *matRowDef="let attributeContent; columns: displayColumns;" class='g-pointer'
			(click)="handleExpandContent(attributeContent)" cdkDrag>
		</tr>
		<tr mat-row *matRowDef="let attributeContent; columns: ['expandedDetail']" class="l-detail-row"
			[class.collapsed-row]="expandedDetailElement !== attributeContent.value.contentUid"></tr>
	</table>
</div>
