import { NgModule } from '@angular/core';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { CheckboxModule } from 'primeng/checkbox';
import { TooltipModule } from '@app/shared/tooltip/tooltip';
import { EditorModule } from '@app/shared/editor/editor.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TabViewModule } from 'primeng/tabview';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DialogModule } from 'primeng/dialog';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { ButtonModule } from 'primeng/button';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { AutoCompleteModule } from '@app/shared/autocomplete/autocomplete.component';
import { FileUploadModule } from 'primeng/fileupload';
import { ChartModule } from 'primeng/chart';
import { NgChartsModule } from 'ng2-charts';
import { ColorPickerModule } from 'ngx-color-picker';
import { PanelMenuModule } from './panelMenu/panel-menu.component';
import { TabMenuModule } from 'primeng/tabmenu';
import { AccordionModule } from 'primeng/accordion';
import { TableModule } from 'primeng/table';
import { ImageCropperModule } from 'ngx-image-cropper';
import { PaginatorModule } from 'primeng/paginator';
import { DragDropModule } from '@angular/cdk/drag-drop';


const uiKitModules = [
	InputTextModule,
	DropdownModule,
	MultiSelectModule,
	CheckboxModule,
	TooltipModule,
	EditorModule,
	RadioButtonModule,
	InputSwitchModule,
	TabViewModule,
	InputTextareaModule,
	AutoCompleteModule,
	DialogModule,
	MenuModule,
	MenubarModule,
	ButtonModule,
	PanelMenuModule,
	BreadcrumbModule,
	FileUploadModule,
	ColorPickerModule,
	ChartModule,
	NgChartsModule,
	TabMenuModule,
	AccordionModule,
	TableModule,
	ImageCropperModule,
	PaginatorModule,
	DragDropModule,
];

@NgModule({
	exports: uiKitModules,
	imports: uiKitModules,
})
export class UIKitModule {}
