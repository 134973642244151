import { Component, EventEmitter, Input } from '@angular/core';
import { EventSideNavItems, SectionRouteCard } from '@app/models/shared';
import { OverlayService } from '@app/services/overlay/overlay.service';
import { StoreService } from '@app/services/store/store.service';
import { MobileNavMenuComponent } from '@app/shared/mobile-nav-menu/mobile-nav-menu.component';
import { Go } from '@app/store/actions/router/router.actions';
import { EVENT_NAV, ORGANISER_NAV, QUICKET_LOGO } from '@app/utils/consts';
import { Dictionary } from '@ngrx/entity';
import { MobileEventNavComponent } from '../mobile-event-nav/mobile-event-nav.component';

@Component({
	selector: 'app-mobile-event-header',
	templateUrl: './mobile-event-header.component.html',
	styleUrls: ['./mobile-event-header.component.sass'],
})
export class MobileEventHeaderComponent {
	@Input() eventId: number;
	@Input() currentNavItem: string;
	@Input() showPrimaryHeader = true;
	@Input() currentPageHeading: string;
	@Input() currentSubNavItem: EventSideNavItems;

	QUICKET_LOGO = QUICKET_LOGO;
	navItems: Dictionary<EventSideNavItems> = EVENT_NAV;
	currentParentNavItem: SectionRouteCard = ORGANISER_NAV.events;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private overlayService: OverlayService,
		private store: StoreService
	) { }

	handleNavMenu() {
		this.overlayService.open(MobileNavMenuComponent, { currentNavItem: this.currentParentNavItem });
	}

	handleEventNavMenu() {
		this.overlayService.open(
			MobileEventNavComponent,
			{ currentNavItem: this.currentParentNavItem, currentEventNav: this.currentNavItem, eventId: this.eventId }
		);
	}

	handleNavigateBack() {
		this.store.dispatch(new Go({ path: [this.currentSubNavItem.routeTo(this.eventId)] }));
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}

}
