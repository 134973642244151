import { Component, Input, AfterContentInit, ViewChild, ContentChildren, EventEmitter, Output } from '@angular/core';
import { FormField } from '../form-field.model';
import { SelectField, SelectFieldOption } from '../select-field/select-field.model';
import { AutoComplete } from '../../autocomplete/autocomplete.component';
import { PrimeTemplate } from 'primeng/api';

@Component({
	selector: 'app-autocomplete-field',
	templateUrl: './autocomplete-field.component.html',
	styleUrls: [
		'../form-field.component.sass',
		'./autocomplete-field.component.sass',
	],
})

export class AutoCompleteComponent extends FormField implements SelectField, AfterContentInit {
	allowReset = false;
	@Input() options: SelectFieldOption[];
	@Input() multiple: boolean;
	@Input() filter: Function;
	@Input() itemSelect: Function;
	@Output() itemSelectChange: EventEmitter<SelectFieldOption> = new EventEmitter<SelectFieldOption>();
	filteredOptions: SelectFieldOption[] = [];
	@ContentChildren(PrimeTemplate) itemTemplates: PrimeTemplate[];
	@ViewChild(AutoComplete, { static: true }) autoComplete: AutoComplete;

	ngAfterContentInit(): void {
		const itemTemplate = this.itemTemplates.find(el => el.name === 'item');
		const selectedItemTemplate = this.itemTemplates.find(el => el.name === 'selectedItem');

		if (itemTemplate) {
			this.autoComplete.itemTemplate = itemTemplate.template;
		}

		if (selectedItemTemplate) {
			this.autoComplete.selectedItemTemplate = selectedItemTemplate.template;
		}
	}

	completeMethod(evt: { originalEvent: Event; query: string }) {
		this.filteredOptions = this.filter(evt.query, this.options);
	}

	onFocus(evt: FocusEvent) {
		this.autoComplete.handleDropdownClick(evt);
	}

	onSelect(evt: SelectFieldOption) {
		if (this.itemSelect) {
			this.control.setValue(this.itemSelect(evt));
		} else {
			this.control.setValue(evt.value);
		}
		this.itemSelectChange.emit(evt);
	}
}
