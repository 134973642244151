import { Injectable } from '@angular/core';
import { ValidatorFn, UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { ValidationMessage, requiredValidator, emailValidator, notEmptyArray } from '@app/shared/form-field/form-validators';
import { UserPreviousContactList } from '@app/models/event.model';
import { UserContactListDraftItem, PreviousEvent } from '@app/models/user.model';

@Injectable()
export class EventInvitationsService {
	checkedListValidator = (msg?: string): ValidatorFn => (control: UntypedFormArray): ValidationMessage => {
		const isAnyChecked = control.value.some(el => el.checked);
		if (!isAnyChecked) {
			return {
				elementChecked: msg || 'At least one contact should be selected',
			};
		}
		return null;
	};

	createGoogleContactsForm(initialValues: { name: string; email: string }[]) {
		const formBuilder = new UntypedFormBuilder();
		return formBuilder.array(
			initialValues.map(({ name, email }) => formBuilder.group({
				checked: false,
				label: `${name || email} (${email})`,
				id: email,
				value: {
					name,
					email,
				},
			})),
			[this.checkedListValidator()]
		);
	}

	createPreviousContactListsForm(initialValues: UserPreviousContactList[]) {
		const formBuilder = new UntypedFormBuilder();
		return formBuilder.array(
			initialValues.map(({ name, id, contactsCount }) => formBuilder.group({
				checked: false,
				label: `${name} (${contactsCount} contact${contactsCount === 1 ? '' : 's'})`,
				id,
				value: {
					name,
					id,
					contactsCount,
				},
			})),
			[this.checkedListValidator('At least one contact list should be selected')]
		);
	}

	createCSVContactsForm(initialValues: UserContactListDraftItem[]) {
		const formBuilder = new UntypedFormBuilder();
		return formBuilder.array(
			initialValues.map(({ name, surname, email }) => formBuilder.group({
				checked: false,
				label: `${name || surname ? name + ' ' + surname : email} (${email})`,
				id: email,
				value: {
					name,
					surname,
					email,
				},
			})),
			[this.checkedListValidator()]
		);
	}

	createManualContactFormItem() {
		const formBuilder = new UntypedFormBuilder();
		const contactItem = {
			email: [null, [requiredValidator(), emailValidator()]],
			name: null,
			surname: null,
		};

		return formBuilder.group(contactItem);
	}

	createManualContactsForm() {
		const formBuilder = new UntypedFormBuilder();
		return formBuilder.array([
			this.createManualContactFormItem(),
		], [notEmptyArray()]);
	}

	createPreviousEventsForm(initialValues: PreviousEvent[]) {
		const formBuilder = new UntypedFormBuilder();
		return formBuilder.array(
			initialValues.map(ev => formBuilder.group({
				checked: false,
				label: `${ev.eventName} (${ev.numberOfAttendees} attendee${ ev.numberOfAttendees === 1 ? '' : 's' })`,
				id: ev.id,
				value: ev,
			})),
			[this.checkedListValidator(' At least one event should be selected')]
		);
	}

	createPreviousEventsAttendeesForm(initialValues: UserContactListDraftItem[]) {
		return this.createCSVContactsForm(initialValues);
	}
}
