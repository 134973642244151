<div
	class="l-container"
	[ngClass]="{ 'mobile': isMobile }"
>
	<app-web-content-header
		class="mb-2 pe-3"
		[headerContent]="headerContent"
		[longWidth]='true'
		[showBreadCrumb]="!isMobile"
		[showTitle]="!isMobile"
	>
	</app-web-content-header>
	<app-iframe
		class="g-iframe"
		[url]='url'
		customId="export-data-iframe"
	></app-iframe>
</div>