import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
	AccountModalReturn,
	AccountTicketResalePurchaseItem,
	AccountTicketResales,
	CreateTicketResale,
	CreateTicketResaleItem
} from '@app/models/user.model';
import { BANK_ACCOUNT_MODAL } from '@app/utils/consts';
import { MatDialog } from '@angular/material/dialog';
import { HeaderContent } from '@app/models/shared';
import { emailRegex } from '@app/utils/regex';
import { StoreService } from '@app/services/store/store.service';
import { PostResaleTickets } from '@app/store/actions/user/user.actions';
import { Go } from '@app/store/actions/router/router.actions';
import { InternalURLCreator } from '@app/services/url/url.dictionary';
import { take, takeUntil } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { UserActionsConstants } from '@app/store/actions/user/user.actions.constants';
import { BankAccountModalComponent } from '@app/shared/bank-account-modal/bank-account-modal.component';
import { MobileResaleInfoModalComponent } from '../../modals/mobile-resale-info-modal/mobile-resale-info-modal.component';

@Component({
	selector: 'app-resale-available',
	templateUrl: './resale-available.component.html',
	styleUrls: ['./resale-available.component.sass'],
})
export class ResaleAvailableComponent implements OnInit {
	@Input() isMobile: boolean;
	@Input() headerContent: HeaderContent;
	@Input() purchaseId: number;
	@Input() resaleTickets: AccountTicketResales;
	@Input() validTicketsForResale: AccountTicketResalePurchaseItem[];
	@Input() invalidTicketsForResale: AccountTicketResalePurchaseItem[];
	@Input() currencySymbol: string;
	@Input() isTicketsLoading: boolean;
	@Input() title: string;

	@Output() listingUpdateRequested = new EventEmitter<void>();

	emailRegex: RegExp = emailRegex;

	selectedTickets: AccountTicketResalePurchaseItem[] = [];
	isSelectAllChecked = false;

	invalidDisplayedColumns: string[] = ['ticketType', 'price', 'ticketHolder', 'reason'];

	isBanksDataFetching = false;
	isListingUpdating = false;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	get isFooterButtonDisabled(): boolean {
		return (
			!this.selectedTickets.length ||
			this.isTicketsLoading ||
			this.isBanksDataFetching ||
			this.isListingUpdating ||
			(this.isTicketsInvalid() && !this.isMobile)
		);
	}

	get validDisplayedColumns(): string[] {
		const displayColumns = ['select', 'ticketType', 'price', 'ticketHolder'];

		if (!this.isMobile) {
			if (this.resaleTickets.allowPrivate && this.resaleTickets.allowPublic) {
				displayColumns.push('public', 'email');
			} else if (this.resaleTickets.allowPrivate) {
				displayColumns.push('email');
			}
		}

		return displayColumns;
	}

	constructor(private dialog: MatDialog, private store: StoreService, private actions$: Actions) {}

	ngOnInit(): void {
		this.headerContent.title = this.title;
		this.actions$
			.pipe(
				ofType(UserActionsConstants.POST_RESALE_TICKETS_SUCCESS, UserActionsConstants.POST_RESALE_TICKETS_FAILED),
				takeUntil(this.destroyed$)
			)
			.subscribe(() => {
				this.selectedTickets = [];
				this.isListingUpdating = false;
				this.isSelectAllChecked = false;
			});
		this.setPublicOnValidTickets();
	}

	setPublicOnValidTickets() {
		this.validTicketsForResale.forEach((ticket) =>
			(ticket.isPublic = this.resaleTickets.allowPublic && !this.resaleTickets.allowPrivate)
		);
	}

	handleListTickets() {
		if (this.resaleTickets.refundRequiredBankAccount) {
			this.isBanksDataFetching = true;
			this.dialog
				.open(BankAccountModalComponent, {
					data: {
						isMobile: this.isMobile,
						currencyId: this.resaleTickets.currencyId,
						descriptions: BANK_ACCOUNT_MODAL['resale'],
						collectAdditionalInfo: this.isMobile ? true : false,
						allowPublicResale: this.resaleTickets.allowPublic,
						allowPrivateResale: this.resaleTickets.allowPrivate,
					},
					panelClass: this.isMobile ? 'g-full-page-dialog' : 'g-standard-dialog',
				})
				.afterClosed()
				.pipe(take(1))
				.subscribe((account: AccountModalReturn) => {
					if (account && account.accountId) {
						this.dispatchTicketsForListing(account);
					}
					this.isBanksDataFetching = false;
				});
		} else if (!this.resaleTickets.refundRequiredBankAccount && this.isMobile) {
			this.dialog
				.open(MobileResaleInfoModalComponent, {
					panelClass: 'g-standard-dialog',
					data: {
						allowPrivateResale: this.resaleTickets.allowPrivate,
						allowPublicResale: this.resaleTickets.allowPublic,
					},
				})
				.afterClosed()
				.pipe(take(1))
				.subscribe((account: AccountModalReturn) => {
					if (account) {
						this.dispatchTicketsForListing(account);
					}
				});
		} else {
			this.dispatchTicketsForListing();
		}
	}

	handleSelectAll() {
		if (this.selectedTickets.length === this.validTicketsForResale.length) {
			this.selectedTickets = [];
			this.isSelectAllChecked = false;
		} else {
			this.selectedTickets = [...this.validTicketsForResale];
			this.isSelectAllChecked = true;
		}
	}

	handleCheckboxChange(ticket: AccountTicketResalePurchaseItem) {
		const index = this.selectedTickets.indexOf(ticket);
		if (index === -1) {
			this.selectedTickets.push(ticket);
		} else {
			this.selectedTickets.splice(index, 1);
		}

		this.isSelectAllChecked = this.selectedTickets.length === this.validTicketsForResale.length;
	}

	handleNavigationBack() {
		this.store.dispatch(new Go({ path: [InternalURLCreator.manageBooking(this.purchaseId)] }));
	}

	dispatchTicketsForListing(account: AccountModalReturn = null) {
		this.isListingUpdating = true;

		let tickets: CreateTicketResaleItem[];

		if (this.isMobile) {
			const ticketType = account?.isPublic ? 1 : 2;
			const ticketEmail = account?.email || null;

			tickets = this.selectedTickets.map((ticket) => ({
				purchaseItemId: ticket.purchaseItemId,
				type: ticketType,
				email: ticketEmail,
			}));
		} else {
			tickets = this.selectedTickets.map((ticket) => ({
				purchaseItemId: ticket.purchaseItemId,
				type: ticket.isPublic ? 1 : 2,
				email: ticket.email,
			}));
		}

		const createResaleTickets: CreateTicketResale = {
			bankAccountId: account?.accountId || null,
			tickets: tickets,
		};
		this.store.dispatch(new PostResaleTickets({ createTicketResale: createResaleTickets, purchaseId: this.purchaseId }));
	}

	isTicketsInvalid() {
		return !this.selectedTickets.every(({ isPublic, email }) => isPublic || this.isEmailValid(email));
	}

	isEmailValid(email: string): boolean {
		return this.emailRegex.test(email);
	}

	isSelected(ticket: AccountTicketResalePurchaseItem): boolean {
		return this.selectedTickets.includes(ticket);
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
