import { Injectable } from '@angular/core';
import { StoreService } from '@app/services/store/store.service';
import {
	EventImageUploadSuccess,
	EventImageUploadFailed,
	RemoveEventImage,
	TicketImageUploadSuccess,
	TicketImageUploadFailed,
	RemoveTicketImage
} from '@app/store/actions/file/file.actions';
import config from '@app/config';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { SchemeID } from '@app/models/dataSchema.model';
import { EventImage } from '@app/models/event.model';
import { TokenService } from '@app/services/token/token.service';
import { AddNotification } from '@app/store/actions/notification/notification.actions';
import { NotificationType } from '@app/models/notification.model';
import { EditorImageUploadOptions } from '@app/shared/editor/editor.component';
import { HttpService } from '@app/api/http/http.service';
import { catchError, switchMap } from 'rxjs/operators';
import { Observable, from, of } from 'rxjs';
import { ImageFileSize } from '@app/models/shared';
import { ImageUploadError, ImageUploadErrorBody } from '@app/models/http.model';
import { ImageUploadErrorCode } from '@app/utils/consts';

export enum ImageServiceType {
	EVENT = 1,
	TICKET = 7,
	PRODUCT_DESCRIPTION = 101,
	CONTENT_TAB = 100,
}

export interface ImageString {
	fileName: SchemeID;
	base64?: string;
	url?: string | SafeUrl;
}

interface IServiceActions {
	imageUploadSuccess;
	imageUploadFailed;
	imageRemove;
}

const ServiceActions = {
	[ImageServiceType.EVENT]: {
		imageUploadSuccess: EventImageUploadSuccess,
		imageUploadFailed: EventImageUploadFailed,
		imageRemove: RemoveEventImage,
	} as IServiceActions,
	[ImageServiceType.TICKET]: {
		imageUploadSuccess: TicketImageUploadSuccess,
		imageUploadFailed: TicketImageUploadFailed,
		imageRemove: RemoveTicketImage,
	} as IServiceActions,
};

@Injectable()
export class ImageService {
	serviceActions;
	imageType: ImageServiceType;
	readonly maxImageSize: ImageFileSize = {
		bit: 1048576,
		mb: 1,
	};
	readonly maxBigImageSize: ImageFileSize = {
		bit: 5242880,
		mb: 5,
	};
	constructor(
		private store: StoreService,
		private tokenService: TokenService,
		private httpService: HttpService,
		private sanitize: DomSanitizer
	) { }

	init(imageType: ImageServiceType) {
		if (imageType){
			this.imageType = imageType;
			this.serviceActions = ServiceActions[imageType];
		}
	}

	onImageUploadFinish(image: EventImage, eventId: SchemeID) {
		if (image) {
			this.store.dispatch(new this.serviceActions.imageUploadSuccess({ image, eventId }));
		} else {
			this.store.dispatch(new this.serviceActions.imageUploadFailed({
				msg: 'can\'t upload image',
				critical: false,
				action: null,
			}));
		}
	}

	onImageMaxSizeAchived = (size?: string) => {
		this.store.dispatch(
			new AddNotification({
				id: 'image-max-size',
				title: `Image size can't be more than ${size || '1Mb'}`,
				actionType: null,
				action: null,
				type: NotificationType.ERROR,
			})
		);
	};

	onImageRemove(image: EventImage, eventId: SchemeID) {
		this.store.dispatch(new this.serviceActions.imageRemove({ image, eventId }));
	}

	getImageUploadURL(eventId: SchemeID, imageType?: ImageServiceType, replace = true) {
		return {
			url: `${config.baseURL}/api/upload/image?itemId=${eventId}&itemTypeId=${imageType || this.imageType}&replace=${replace}`,
			headers: {
				Authorization: `Bearer ${this.tokenService.getToken()}`,
			},
		};
	}

	getUploadedFile<T extends { id: SchemeID }>({
		image,
		imageString,
		key,
		skipBase,
	}: {
		image: T;
		key: keyof T;
		imageString?: ImageString;
		skipBase?: boolean;
	}): ImageString[] {
		return image && image[key]
			? [{
				fileName: image.id,
				url: `${skipBase ? '' : config.baseURL}${image[key]}`,
			}]
			: imageString
				? [imageString]
				: [];
	}

	encodeImage(file: File, cb: (base: string) => void) {
		const reader = new FileReader();
		reader.onload = (function (theFile) {
			return function (e) {
				const binaryData = e.target.result;
				const base64String = btoa(binaryData as string);
				cb(base64String);
			};
		})(file);
		reader.readAsBinaryString(file);
	}

	downloadCurrentImage(url: string): Observable<string> {
		return this.httpService.http.get(url, { responseType: 'blob' }).pipe(
			switchMap(blob => this.blobToBase64(blob))
		);
	}

	private blobToBase64(blob: Blob): Observable<string> {
		return new Observable<string>((observer) => {
			const reader = new FileReader();
			reader.onloadend = () => {
				observer.next(reader.result as string);
				observer.complete();
			};
			reader.onerror = (error) => {
				observer.error(error);
			};
			reader.readAsDataURL(blob);
		});
	  }

	downloadImage(url: string) {
		const fileUpload = (file: Blob) => from(
			new Promise((res, rej) => {
				const reader = new FileReader();
				reader.onload = () => {
					res(reader.result as string);
				};
				reader.onerror = err => {
					rej(err);
				};
				reader.readAsDataURL(file);
			})
		);

		return this.httpService.http.get(url, {
			responseType: 'blob',
		})
			.pipe(
				switchMap(fileUpload),
				catchError(
					() => of(null)
				)
			);
	}

	buildBase64String(base: string, file: File) {
		return `data:${file.type};base64,${base}`;
	}

	buildImagePreview(file: File) {
		return ({
			fileName: file.name,
			url: this.sanitize.bypassSecurityTrustUrl(URL.createObjectURL(file)),
		});
	}

	getImageString(base: string, file: File): ImageString {
		return {
			base64: this.buildBase64String(base, file),
			...this.buildImagePreview(file),
		};
	}

	initEditorImagerUploadHandler(eventId: SchemeID, serviceType: ImageServiceType, replace?: boolean) {
		const request = this.getImageUploadURL(eventId, serviceType, replace);
		const editorImageUploadHandler: EditorImageUploadOptions = {
			url: request.url,
			method: 'POST',
			headers: request.headers,
			callbackOK: (response, next) => {
				const uploadedFiles = this.getUploadedFile({
					image: response.payload,
					key: 'url',
				});
				const url = uploadedFiles.length && uploadedFiles[0].url as string;
				next(url);
			},
			callbackKO: (serverError: ImageUploadError) => {
				const bodyError: ImageUploadErrorBody = JSON.parse(serverError.body);

				if (bodyError.errors[0].errorCode === ImageUploadErrorCode.Size) {
					alert('Error. Please upload an image smaller than 1MB.');
				} else {
					alert(bodyError.errors[0].message);
				}
			},
		};

		return editorImageUploadHandler;
	}
}
