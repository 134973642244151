<button
	id="drop-down-button-menu-toggle"
	class="g-primary-button l-button"
	[disabled]="isLoading"
	[ngClass]="{'disabled': isLoading}"
	[class.l-small-button]='smallButton'
	[matMenuTriggerFor]="menu"
	(menuOpened)="handleMenuToggle()"
	(menuClosed)="handleMenuToggle()"
>
	<div class='l-button-content'>
		<div class='l-text'>{{ buttonText }}</div>
		<mat-icon
			class='l-icon'
			[@rotateIcon]="isOpen ? 'rotated' : 'default'"
		>expand_more</mat-icon>
	</div>
</button>
<mat-menu #menu="matMenu">
	<button
		*ngFor="let option of options | keyvalue: handleDefaultOrder"
		id="drop-down-option-{{option.value.name}}"
		class="l-menu-button"
		[class.l-small-button]='smallButton'
		[disabled]="option.value.disabled"
		mat-menu-item
		(click)='handleClick(option.value)'
	>{{option.value.name}}</button>
</mat-menu>