import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import config from '@app/config';
import { SchemeID } from '@app/models/dataSchema.model';

@Component({
	selector: 'app-preview-modal',
	templateUrl: './preview-modal.component.html',
	styleUrls: ['./preview-modal.component.sass'],
})
export class PreviewComponent implements OnInit {
	pageURL: SafeResourceUrl;
	iframeLoading = true;
	loadCount = 0;
	destroyed$: EventEmitter<void> = new EventEmitter<void>();
	constructor(
		private sanitizer: DomSanitizer,
		public dialogRef: MatDialogRef<PreviewComponent>,
		@Inject(MAT_DIALOG_DATA)
		public data: { eventId: SchemeID }
	) { }

	ngOnInit() {
		this.pageURL = this.getIframeURL(this.data.eventId);
	}

	handleCancel() {
		this.dialogRef.close();
	}

	handleLoading() {
		this.loadCount += 1;
		if (this.loadCount === 2) {
			this.iframeLoading = false;
		}
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}

	getIframeURL = (id: SchemeID) =>
		this.sanitizer.bypassSecurityTrustResourceUrl(`${config.baseURL}/events/${id}-prev?previewmodal=true&preview=t`);
}
