import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-footer-button',
	templateUrl: './footer-button.component.html',
	styleUrls: ['./footer-button.component.sass'],
})
export class FooterButtonComponent implements OnInit {
	@Input() isMobile = false;
	@Input() isModal = false;
	@Input() primaryText: string;
	@Input() secondaryText: string;
	@Input() isDisabled = true;
	@Input() isLoading = false;
	@Input() fullWidth = false;
	@Input() primaryButtonId = 'primary-button';
	@Input() secondaryButtonId = 'secondary-button';
	@Input() longPrimaryText = false;
	@Input() longSecondaryText = false;
	@Output() $primaryButton: EventEmitter<boolean> = new EventEmitter();
	@Output() $secondaryButton: EventEmitter<boolean> = new EventEmitter();

	constructor() { }

	ngOnInit(): void { }

	handlePrimaryButtonClick() {
		this.$primaryButton.emit(true);
	}

	handleSecondaryButtonClick() {
		this.$secondaryButton.emit(true);
	}
}
