<div
	class="l-container"
	[ngClass]="{ 'mobile': isMobile }"
>
	<app-web-content-header
		*ngIf="!isMobile"
		class="mb-2"
		[headerContent]="headerContent"
		[longWidth]='true'
	>
	</app-web-content-header>
	<app-iframe
		class="g-iframe"
		[url]='url'
		customId="send-sms-iframe"
	></app-iframe>
</div>