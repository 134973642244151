import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SchemeID } from '@app/models/dataSchema.model';
import { HeaderContent } from '@app/models/shared';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { URLCreator } from '@app/services/url/url.dictionary';
import { EVENT_ID_PARAM_KEY } from '@app/utils/consts';
import { take, takeUntil } from 'rxjs';

@Component({
	selector: 'app-payment',
	templateUrl: './payment.component.html',
	styleUrls: ['./payment.component.sass'],
})
export class PaymentComponent implements OnInit {
	url: string;
	eventId: SchemeID;
	isMobile = false;

	headerContent: HeaderContent = {
		description: 'Choose which payment options are available for your ticket buyers.',
	};

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(private activatedRoute: ActivatedRoute, private breakpointService: BreakpointService) {}

	ngOnInit(): void {
		this.activatedRoute.parent.paramMap.pipe(take(1)).subscribe((params) => {
			this.eventId = +params.get(EVENT_ID_PARAM_KEY);
			this.url = URLCreator.paymentOptionsAspx(this.eventId);
		});

		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
