import { Component, Input } from '@angular/core';
import { SocialSharingService } from '@app/services/social-sharing/socail-sharing.service';

@Component({
	selector: 'app-linkedin-sharing-btn',
	templateUrl: './linkedin-sharing.component.html',
})

export class LinkedinSharingButtonComponent {
	@Input() link: string;
	constructor(
		private sharingService: SocialSharingService
	) { }

	getSharingURL() {
		return this.sharingService.getLinkedinSharingURL(this.link);
	}
}
