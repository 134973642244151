import { Routes } from '@angular/router';
import { UserControlPanelFeatureResolver } from '@app/services/resolvers/user-dashboard-feature/user-dashboard-feature.resolver';
import { ProductRoute } from '@app/models/url.model';
import { CanDeactivateUserPage } from '@app/services/guards/user.guard';
import { ApiKeysComponent } from '@app/features/my-account/pages/api-keys/api-keys.component';
import { ChangePasswordComponent } from '@app/features/my-account/pages/change-password/change-password.component';
import { ContactInfoComponent } from '@app/features/my-account/pages/contact-info/contact-info.component';
import { EmailPreferenceComponent } from '@app/features/my-account/pages/email-preference/email-preference.component';
import { PayoutsComponent } from '@app/features/my-account/pages/payouts/payouts.component';
import { PersonalInfoComponent } from '@app/features/my-account/pages/personal-info/personal-info.component';
import { ProfileDashboardComponent } from '@app/features/my-account/pages/profile-dashboard/profile-dashboard.component';
import {
	RegistrationDetailsComponent
} from '@app/features/my-tickets-and-registrations/pages/registration-details/registration-details.component';
import { EditTicketComponent } from '@app/features/my-tickets-and-registrations/pages/edit-ticket/edit-ticket.component';
import { TransferTicketComponent } from '@app/features/my-tickets-and-registrations/pages/transfer-ticket/transfer-ticket.component';
import { TicketResaleComponent } from '@app/features/my-tickets-and-registrations/pages/ticket-resale/ticket-resale.component';
import { RefundRequestComponent } from '@app/features/my-tickets-and-registrations/pages/refund-request/refund-request.component';
import { ManageBookingComponent } from '@app/features/my-tickets-and-registrations/pages/manage-booking/manage-booking.component';
import { EarnCommissionComponent } from '@app/features/earn-commission/pages/earn-commission/earn-commission.component';
import { PromoterNetworksComponent } from '@app/features/earn-commission/pages/promoter-networks/promoter-networks.component';
import { ReferralsComponent } from '@app/features/earn-commission/pages/referrals/referrals.component';
import {
	PromoterNetworkDetailsComponent
} from '@app/features/earn-commission/pages/promoter-network-details/promoter-network-details.component';
import { JoinPromoterNetworkComponent } from '@app/features/my-account/pages/join-promoter-network/join-promoter-network.component';
import { RequestBankDetailsComponent } from '@app/features/my-account/pages/request-bank-details/request-bank-details.component';
import { MyTicketsComponent } from '@app/features/my-tickets-and-registrations/pages/my-tickets/my-tickets.component';
import { MyRegistrationsComponent } from '@app/features/my-tickets-and-registrations/pages/my-registrations/my-registrations.component';
import { SavedEventsDashboardComponent } from '@app/features/my-saved-events/pages/saved-events-dashboard/saved-events-dashboard.component';
import { VendingComponent } from '@app/features/my-account/pages/vending/vending.component';

export const orderRoutes: Routes = [
	{
		path: '',
		component: MyTicketsComponent,
		data: {
			pageTitle: 'My Tickets | Quicket',
		},
		resolve: [UserControlPanelFeatureResolver],
	},
	{
		path: ':id/manage',
		component: ManageBookingComponent,
		data: {
			pageTitle: 'Booking Details | Quicket',
		},
		resolve: [UserControlPanelFeatureResolver],
	},
	{
		path: ':id/registration-details',
		component: RegistrationDetailsComponent,
		data: {
			pageTitle: 'Registration Details | Quicket',
		},
		resolve: [UserControlPanelFeatureResolver],
		canDeactivate: [CanDeactivateUserPage],
	},
	{
		path: ':id/edit-ticket',
		component: EditTicketComponent,
		data: {
			pageTitle: 'Edit Ticket | Quicket',
		},
		resolve: [UserControlPanelFeatureResolver],
		canDeactivate: [CanDeactivateUserPage],
	},
	{
		path: ':id/transfer',
		component: TransferTicketComponent,
		data: {
			pageTitle: 'Transfer Ticket | Quicket',
		},
		resolve: [UserControlPanelFeatureResolver],
	},
	{
		path: ':id/resale',
		component: TicketResaleComponent,
		data: {
			pageTitle: 'Ticket Resale | Quicket',
		},
		resolve: [UserControlPanelFeatureResolver],
	},
	{
		path: ':id/refund',
		component: RefundRequestComponent,
		data: {
			pageTitle: 'Refund Request | Quicket',
		},
		resolve: [UserControlPanelFeatureResolver],
	},
	{
		path: '**',
		redirectTo: '/orders',
	},
];

export const earnCommissionRoutes: Routes = [
	{
		path: '',
		component: EarnCommissionComponent,
		data: {
			pageTitle: 'Earn Commission | Quicket',
			ProductType: ProductRoute.USER,
		},
		resolve: [UserControlPanelFeatureResolver],
	},
	{
		path: 'promoter-networks',
		component: PromoterNetworksComponent,
		data: {
			pageTitle: 'Promoter Network | Quicket',
			ProductType: ProductRoute.USER,
		},
		resolve: [UserControlPanelFeatureResolver],
	},
	{
		path: 'referrals',
		component: ReferralsComponent,
		data: {
			pageTitle: 'Referral Page | Quicket',
			ProductType: ProductRoute.USER,
		},
		resolve: [UserControlPanelFeatureResolver],
	},
	{
		path: ':id/promoter-details',
		component: PromoterNetworkDetailsComponent,
		data: {
			pageTitle: 'Promoter Details | Quicket',
			ProductType: ProductRoute.USER,
		},
		resolve: [UserControlPanelFeatureResolver],
	},
	{
		path: ':id/promoter-networks/:networkId/join',
		component: JoinPromoterNetworkComponent,
		data: {
			pageTitle: 'Join Promoter Network | Quicket',
			ProductType: ProductRoute.USER,
		},
		resolve: [UserControlPanelFeatureResolver],
	},
];

export const userRoutes: Routes = [
	{
		path: '',
		component: ProfileDashboardComponent,
		data: {
			pageTitle: 'My Account | Quicket',
		},
		resolve: [UserControlPanelFeatureResolver],
	},
	{
		path: 'api',
		component: ApiKeysComponent,
		data: {
			pageTitle: 'API Token | Quicket',
			productType: ProductRoute.USER,
		},
		resolve: [UserControlPanelFeatureResolver],
	},
	{
		path: 'personal-info',
		component: PersonalInfoComponent,
		data: {
			pageTitle: 'Personal Info | Quicket',
			productType: ProductRoute.USER,
		},
		resolve: [UserControlPanelFeatureResolver],
		canDeactivate: [CanDeactivateUserPage],
	},
	{
		path: 'contact-info',
		component: ContactInfoComponent,
		data: {
			pageTitle: 'Contact Info | Quicket',
			productType: ProductRoute.USER,
		},
		resolve: [UserControlPanelFeatureResolver],
		canDeactivate: [CanDeactivateUserPage],
	},
	{
		path: 'email-preference',
		component: EmailPreferenceComponent,
		data: {
			pageTitle: 'Email Preference | Quicket',
			productType: ProductRoute.USER,
		},
		resolve: [UserControlPanelFeatureResolver],
		canDeactivate: [CanDeactivateUserPage],
	},
	{
		path: 'change-password',
		component: ChangePasswordComponent,
		data: {
			pageTitle: 'Change Password | Quicket',
			productType: ProductRoute.USER,
		},
		resolve: [UserControlPanelFeatureResolver],
		canDeactivate: [CanDeactivateUserPage],
	},
	{
		path: 'payouts',
		component: PayoutsComponent,
		data: {
			pageTitle: 'Payouts | Quicket',
			productType: ProductRoute.USER,
		},
		resolve: [UserControlPanelFeatureResolver],
	},
	{
		path: 'request-bank-details/:manualRefundId',
		component: RequestBankDetailsComponent,
		data: {
			pageTitle: 'Incorrect Bank Details | Quicket',
			productType: ProductRoute.USER,
		},
		resolve: [UserControlPanelFeatureResolver],
	},
	{
		path: 'my-saved-events',
		component: SavedEventsDashboardComponent,
		data: {
			pageTitle: 'My Saved Events | Quicket',
			productType: ProductRoute.USER,
		},
	},
	{
		path: 'my-registrations',
		component: MyRegistrationsComponent,
		data: {
			pageTitle: 'My Registrations | Quicket',
			productType: ProductRoute.USER,
		},
		resolve: [UserControlPanelFeatureResolver],
	},
	{
		path: 'vending',
		component: VendingComponent,
		data: {
			pageTitle: 'Vending | Quicket',
			productType: ProductRoute.USER,
		},
	},
	{
		path: 'orders',
		children: orderRoutes,
		data: {
			productType: ProductRoute.USER,
		},
	},
	{
		path: 'earn-commission',
		children: earnCommissionRoutes,
		data: {
			productType: ProductRoute.USER,
		},
	},
];
