<div
	*ngIf="!isMobile; else mobileHeader"
	class="w-100"
>
	<app-header></app-header>
</div>
<ng-template #mobileHeader>
	<app-header-mobile
		id='navigate-back'
		class="w-100"
		($navigateBack)="handleNavigationBack()"
		[pageName]="pageName"
		[isParent]="isParent"
	></app-header-mobile>
</ng-template>