import { Injectable, Provider } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable, combineLatest, of, switchMap, take } from 'rxjs';
import { TokenService } from '@app/services/token/token.service';
import config from '@app/config';
import { StoreService } from '@app/services/store/store.service';
import { UserAPIToken } from '@app/store/selectors/user.selector';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(private tokenService: TokenService, private store: StoreService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (request.url.startsWith(config.baseAPI_URL)) {
			const userToken$ = this.store.select(UserAPIToken());
			const publicAPIKey$ = of(config.APIKey);

			return combineLatest([userToken$, publicAPIKey$]).pipe(
				take(1),
				switchMap(([userToken, publicAPIKey]) => {
					let modifiedRequest = request;
					if (userToken) {
						modifiedRequest = request.clone({
							headers: request.headers.set('usertoken', userToken),
						});
					}

					const separator = modifiedRequest.url.includes('?') ? '&' : '?';
					const urlWithAPIKey = `${modifiedRequest.url}${separator}api_key=${publicAPIKey}`;
					modifiedRequest = modifiedRequest.clone({ url: urlWithAPIKey });

					return next.handle(modifiedRequest);
				})
			);
		} else {
			const token = this.tokenService.getToken();

			if (token) {
				const requestWithToken = request.clone({
					headers: request.headers.set('Authorization', `Bearer ${token}`),
				});
				return next.handle(requestWithToken);
			}

			return next.handle(request);
		}
	}
}

export const AuthInterceptorProvider: Provider = {
	provide: HTTP_INTERCEPTORS,
	useClass: AuthInterceptor,
	multi: true,
};
