import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProductType } from '@app/models/product.model';
import { ProfileLinkedProducts } from '@app/models/profile.model';
import { URLList } from '@app/models/url.model';
import { URLCreator } from '@app/services/url/url.dictionary';

@Component({
	selector: 'app-linked-products-modal',
	templateUrl: './linked-products-modal.component.html',
	styleUrls: ['./linked-products-modal.component.sass'],
})
export class LinkedProductsModalComponent implements OnInit {

	isMobile = false;
	links: Partial<Record<keyof URLList, string>>;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		public dialogRef: MatDialogRef<LinkedProductsModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data: {
			isMobile: boolean;
			linkedProducts: ProfileLinkedProducts[];
		}
	) { }

	ngOnInit(): void {

	}

	getManageProductType = (product: ProfileLinkedProducts) => {
		switch (product.productType) {
			case ProductType.Event:
				return 'event';
			case ProductType.Fundraiser:
				return 'fundraiser';
			case ProductType.OrganiserSubscriptions:
				return 'subscriptions';
		}
	};

	getManageProductLink = (product: ProfileLinkedProducts) => {
		this.links = {
			dashboard: URLCreator.dashboard(product.id, 2),
		};
		switch (product.productType) {
			case ProductType.Event:
				return this.links.dashboard;
		}
	};

	handleRouting(product: ProfileLinkedProducts) {
		window.open(this.getManageProductLink(product));
	}

	handleClose() {
		this.dialogRef.close();
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}

}
