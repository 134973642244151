<div
	[id]="formatId(text, 'mobile')"
	class="l-container"
	[ngClass]="{ 'l-list': !asCard, 'l-card': asCard, 'l-disabled-container': isDisabled }"
>
	<img
		[ngClass]="{ 'l-list-icon': !asCard, 'l-card-icon': asCard }"
		[src]="!isDisabled ? icon + iconType : icon + '-disabled' + iconType"
	/>
	<div class='d-flex flex-column'>
		<h4 [ngClass]="{ 'l-list-title': !asCard, 'l-card-title': asCard }">{{ text }}</h4>
		<div
			*ngIf="description"
			class='l-description'
		>{{description}}</div>
	</div>
	<mat-icon
		*ngIf="isDisabled && tooltip"
		class="l-tooltip"
		[matTooltip]="tooltip"
		matTooltipPosition="right"
		#disabledFieldTooltip="matTooltip"
		(click)="disabledFieldTooltip.toggle()"
	>info</mat-icon>
	<mat-icon class="l-chevron">chevron_right</mat-icon>
</div>