<div class='d-flex w-100 h-100'>
	<div class="l-parent-nav-menu">
		<a [id]="formatId(parentItem.value.title, 'side-nav')" *ngFor='let parentItem of parentNavItems | keyvalue: handleDefaultOrder'
			[routerLink]="[parentItem.value.routeTo()]"
			class='g-flat-link l-nav-item'>
			<div class="l-icon-container"
				[class.l-active-parent]="currentParentNavItem.title === parentItem.value.title">
				<img class="l-icon" [src]="precomputedImageSources[parentItem.value.title]" />
			</div>
			<div class="l-parent-nav-title">{{parentItem.value.title}}</div>
		</a>
	</div>
	<div class='l-event-side-nav'>
		<a class='g-flat-link l-my-events' [routerLink]="[handleMyEventsNavigation()]">
			<mat-icon>chevron_left</mat-icon>
			<div class="fw-semibold">My Events</div>
		</a>
		<div class="l-event-info" *ngIf='eventMetadata.name && eventMetadata.dateFrom'>
			<div 
				id="event-side-nav-event-name"
				class="l-event-name"
				#eventNameTooltip="matTooltip"
				[matTooltip]="eventMetadata.name"
				matTooltipPosition="right"
			>
				{{ eventMetadata.name }}
			</div>
			<div class="l-date">{{ eventMetadata.dateFrom | formattedDatePipe }}</div>
		</div>		
		<div class="l-event-nav-item-container">
			<a *ngFor='let navItem of navItems | keyvalue: handleDefaultOrder' [routerLink]="[navItem.value.routeTo(eventMetadata.id)]"
				[id]="formatId(navItem.key, 'event-nav')" class='g-flat-link l-event-nav-item' [class.l-active-route]="currentNavItem === navItem.key">
				{{navItem.key}}
			</a>
			<a
				class='g-flat-link l-event-nav-item'
				[href]="getPreviewLink()"
				target="_blank"
			>
				Preview
			</a>
		</div>
	</div>
</div>
