import { initialState } from './initialState';
import * as eventActions from '@app/store/actions/event/event.actions';
import { EventActionsConstants } from '@app/store/actions/event/event.actions.constants';
import { EventDashboardModal } from '@app/models/event.model';
import { EventsState } from '@app/models/store.model';

export const dashboardModals = (
	state = initialState.dashboardModals,
	action: eventActions.Actions
): EventsState['dashboardModals'] => {
	switch (action.type) {
		case EventActionsConstants.SET_EVENT_DASHBOARD_MODAL_FLAG:
			return {
				...state,
				[action.payload.type]: action.payload.isOpen,
			};
		case EventActionsConstants.SET_EVENT_LISTING_SUCCESS:
			return {
				...state,
				[EventDashboardModal.EventListing]: false,
			};
		case EventActionsConstants.SUBMIT_EVENT_FOR_APPROVAL_SUCCESS:
			return {
				...state,
				[EventDashboardModal.Approval]: false,
			};
		case EventActionsConstants.CANCEL_EVENT_SUCCESS:
			return {
				...state,
				[EventDashboardModal.Cancellation]: false,
			};
		case EventActionsConstants.CHANGE_EVENT_DATES_SUCCESS:
			return {
				...state,
				[EventDashboardModal.DatesChanging]: false,
			};
		case EventActionsConstants.SET_EVENT_SERVICE_FEE_SUCCESS:
			return {
				...state,
				[EventDashboardModal.ServiceFee]: false,
			};
		case EventActionsConstants.SCHEDULE_EVENT_PUBLIC_SUCCESS:
			return {
				...state,
				[EventDashboardModal.SchedulePublic]: false,
			};
		case EventActionsConstants.DELETE_SCHEDULE_EVENT_PUBLIC_SUCCESS:
			return {
				...state,
				[EventDashboardModal.DetelePublicSchedule]: false,
			};
		case EventActionsConstants.SET_EVENT_COVID_COMPLIANT_SUCCESS:
			return {
				...state,
				[EventDashboardModal.CovidCompliant]: false,
			};
		case EventActionsConstants.GET_EVENT_DASHBOARD:
			return initialState.dashboardModals;
		default:
			return state;
	}
};
