<div class="l-container">
    <div class="l-content" *ngIf="!isLoading">
        <div class="l-content-text">
            Your event page will redirect to the selected event. This can be removed or edited at any time.
        </div>
        <div>
            <app-mat-select-control-field
                [control]="form.get('productId')"
                [options]="options"
                placeholder="Select Event"
                label=""
                [required]="true"
            >
            </app-mat-select-control-field>
        </div>
    </div>
    <div class="l-buttons-container" [ngClass]="{ mobile: data.isMobile }">
        <button 
            class="g-secondary-button border-0 l-cancel-button" 
            [ngClass]="{ mobile: data.isMobile }"
            (click)="handleCancel()"
        >
            CANCEL
        </button>
        <button 
            class="g-primary-button" 
            (click)="handleSave()"
            [class.disabled]="!form.valid"
            [disabled]="!form.valid"
            id="schedule-to-go-public-button"
        >
            SAVE
        </button>
    </div>
</div>
