import { Component, OnChanges, Input, Output, EventEmitter, SimpleChanges, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SchemeID } from '@app/models/dataSchema.model';
import { SelectFieldOption } from '@app/shared/form-field/select-field/select-field.model';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { ScheduleItem } from '../../../../models/schedule.model';

@Component({
	selector: 'app-schedule-selection',
	templateUrl: './schedule-selection.component.html',
	styleUrls: [
		'./schedule-selection.component.sass',
	],
})

export class ScheduleSelectionComponent implements OnChanges, OnDestroy {
	@Input() selectedScheduleItem: SchemeID = 0;
	@Input() scheduleItems: ScheduleItem[];
	@Output() scheduleItemChange = new EventEmitter<SchemeID>();
	@Input() scheduleItemAmount: number;
	scheduleItemsOptions: SelectFieldOption<SchemeID>[] = [];
	scheduleItemControl: FormControl;
	scheduleItemSub: Subscription;

	ngOnChanges(changes: SimpleChanges) {
		if (changes.scheduleItems && changes.scheduleItems.currentValue) {
			const options = (changes.scheduleItems.currentValue as ScheduleItem[]).map(
				({ startDate, endDate, id, status }: ScheduleItem) => ({
					label: this.getScheduleDateDesc(startDate, endDate) + (status === 50 ? ' (CANCELLED)' : ''),
					value: id,
				})
			);

			options.unshift({
				label: 'All schedules',
				value: 0,
			});

			this.scheduleItemsOptions = options;

			this.scheduleItemControl = new FormControl(this.selectedScheduleItem);
			this.scheduleItemSub = this.scheduleItemControl.valueChanges
				.subscribe(this.onScheduleItemChange);
		}
	}

	ngOnDestroy() {
		if (this.scheduleItemSub) {
			this.scheduleItemSub.unsubscribe();
		}
	}

	getScheduleDateDesc = (from: Date, to: Date) => {
		const fromMoment = moment(from);
		const toMoment = moment(to);
		const defaultFormat = 'dddd, Do MMMM YYYY HH:mm';

		if (fromMoment.days() === toMoment.days()) {
			return `${fromMoment.format(defaultFormat)} - ${toMoment.format('HH:mm')}`;
		}

		return `${fromMoment.format(defaultFormat)} - ${toMoment.format(defaultFormat)}`;
	};

	onScheduleItemChange = (scheduleItemId: SchemeID) => (
		this.scheduleItemChange.emit(scheduleItemId)
	);
}
