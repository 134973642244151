import { ActivatedRoute } from '@angular/router';
import { Component, EventEmitter } from '@angular/core';
import { SectionRouteCard } from '@app/models/shared';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { handleOrder } from '@app/utils/common-helpers';
import { EVENT_ID_PARAM_KEY, EVENT_MARKETING_CARDS } from '@app/utils/consts';
import { Dictionary } from '@ngrx/entity';
import { take, takeUntil } from 'rxjs';

@Component({
	selector: 'app-marketing',
	templateUrl: './marketing.component.html',
	styleUrls: ['./marketing.component.sass'],
})
export class MarketingComponent {
	cardsData: Dictionary<SectionRouteCard> = EVENT_MARKETING_CARDS;
	isMobile = false;
	eventId: number;
	handleDefaultOrder = handleOrder;
	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private breakpointService: BreakpointService,
		private activatedRoute: ActivatedRoute
	) { }

	ngOnInit(): void {
		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});

		this.activatedRoute.parent.paramMap.pipe(take(1)).subscribe((params) => {
			this.eventId = +params.get(EVENT_ID_PARAM_KEY);
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}

}
