import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { EventActionsConstants } from '../actions/event/event.actions.constants';
import * as organiserActions from '../actions/organiserProfiles/organiserProfiles.actions';
import * as eventActions from '../actions/event/event.actions';
import { Store } from '@ngrx/store';
import {
	mapEventFrom,
	mapEventTo,
	chartCategoryToSeatingCategory,
	mapEventCopyInfoTo,
	mapOrderReturnToOrder,
	creationStepToActiveStep
} from '@app/utils/mappers/event';
import { Go } from '../actions/router/router.actions';
import { AppState } from '@app/models/store.model';
import * as eventSelectors from '../selectors/event.selector';
import { mapMetadataFrom } from '@app/utils/mappers/permissions';
import { EventPart, EventEntityPayload } from '@app/models/event.model';
import { GetCurrencies } from '@app/store/actions/tickets/tickets.actions';
import { getResponseErrors } from '@app/utils/Response';
import { getResponseErrorCodes } from '@app/utils/Response';
import { GetUserDesignerKey, GetBankAccounts } from '@app/store/actions/user/user.actions';
import { switchMap, catchError, mergeMap, withLatestFrom, concatMap, map} from 'rxjs/operators';
import { throwError, of, concat, Observable, forkJoin } from 'rxjs';
import { normalize } from 'normalizr';
import { eventScheme } from '@app/schemes/event.schema';
import { ProductCreationAction, Product } from '@app/models/product.model';
import { defaultDashboardRoute } from '@app/services/url/url.service';
import { EventDomainAPI } from '@app/api/domains/event';
import { AppAction } from '@app/models/action.model';
import { AsyncResponse } from '@app/models/http.model';
import { ofTypeExt, handleSuccessRequest, handleFailedRequest } from '@app/utils/Store';
import { NormalizeFields, SchemeID } from '@app/models/dataSchema.model';
import { pushDataLayer } from '@app/utils/DataLayer';
import { arrayOfCommonScheme } from '@app/schemes/common.scheme';
import { InternalURLCreator } from '@app/services/url/url.dictionary';
import { capitalize } from 'lodash';
import { OldAPIDomainAPI } from '@app/api/domains/oldApi';
import { ViewOrder } from '@app/models/order.model';

@Injectable()
export class EventEffects {
	constructor(
		private action$: Actions,
		private store$: Store<AppState>,
		private api?: EventDomainAPI,
		private oldApi?: OldAPIDomainAPI
	) {}

	getEvent$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.GetEvent>(EventActionsConstants.GET_EVENT),
			switchMap(({ action, payload: { productType } }) =>
				this.api.getEvent(productType).pipe(
					withLatestFrom(this.store$),
					mergeMap(([value, store]): Observable<AppAction<any>> => {
						if (value.isSuccess) {
							if (value.payload) {
								const product = mapEventFrom(value.payload);
								const {
									details: { eventId },
									ticketsDetails: { seatingChartId },
								} = product;
								const {
									seatingCategories,
									productDetails: { seatsIOChartKey },
									questions,
								} = value.payload;

								const { entities: normalizedEntity } = normalize(product, eventScheme);

								const actionsToProceed: AppAction<any>[] = [
									new eventActions.GetEventSuccess({
										id: product.details.eventId,
										normalizedEntity,
										productType: product.details.productType,
										entity: {
											original: {
												/* eslint-disable-next-line max-len */
												seatingCategories: chartCategoryToSeatingCategory(seatingCategories, seatingChartId),
												seatsIOChartKey,
											},
											questions,
											seatingCategories: chartCategoryToSeatingCategory(seatingCategories, seatingChartId),
										},
									}),
									new organiserActions.GetProfiles({ eventId }),
									new eventActions.GetPreviousVenues({ eventId }),
									new GetUserDesignerKey({ eventId }),
									new GetBankAccounts(),
								];

								if (product.details?.creationStep) {
									const index = creationStepToActiveStep(product.details?.creationStep);
									actionsToProceed.push(new eventActions.SetActiveStepIndex({ index }));
								}

								if (!eventSelectors.currencies()(store).length) {
									actionsToProceed.push(new GetCurrencies({ productType }));
								}

								if (!eventSelectors.eventTypes()(store)) {
									actionsToProceed.push(new eventActions.GetEventTypes());
								}

								return concat(actionsToProceed);
							} else {
								return concat([new eventActions.CreateEvent({ productType })]);
							}
						} else {
							return throwError(getResponseErrors(value));
						}
					}),
					catchError((error) => {
						if (error.error && error.error.errors && error.error.errors.some((e) => e.errorCode === 'EventNotFound')) {
							return of(new eventActions.CreateEvent({ productType }));
						}

						return of(
							new eventActions.GetEventFailed({
								msg: 'can\'t get event',
								action,
								critical: true,
								meta: {
									extraAction: new Go({ path: [defaultDashboardRoute] }),
									extraActionTitle: 'Go to dashboard',
									allowClose: false,
								},
								error,
								serverMessages: getResponseErrorCodes(error),
							})
						);
					})
				)
			)
		)
	);

	createEvent$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.CreateEvent>(EventActionsConstants.CREATE_EVENT),
			switchMap(({ action, payload: { productType } }) =>
				this.api.createEvent(productType).pipe(
					withLatestFrom(this.store$),
					mergeMap(([value, store]): Observable<AppAction<any>> => {
						if (value.isSuccess) {
							const product = mapEventFrom(value.payload);
							const {
								details: { eventId },
								ticketsDetails: { seatingChartId },
							} = product;
							const {
								seatingCategories,
								questions,
								productDetails: { seatsIOChartKey },
							} = value.payload;

							const { entities: normalizedEntity } = normalize(product, eventScheme);

							const actionsToProceed: AppAction<any>[] = [
								new eventActions.CreateEventSuccess({
									id: eventId,
									normalizedEntity,
									productType: product.details.productType,
									entity: {
										original: {
											seatingCategories: chartCategoryToSeatingCategory(seatingCategories, seatingChartId),
											seatsIOChartKey,
										},
										questions,
										seatingCategories: chartCategoryToSeatingCategory(seatingCategories, seatingChartId),
									},
								}),
								new organiserActions.GetProfiles({ eventId }),
								new eventActions.GetPreviousVenues({ eventId }),
								new GetUserDesignerKey({ eventId }),
								new GetBankAccounts(),
							];

							if (product.details?.creationStep) {
								const index = creationStepToActiveStep(product.details?.creationStep);
								actionsToProceed.push(new eventActions.SetActiveStepIndex({ index }));
							}

							if (!eventSelectors.currencies()(store).length) {
								actionsToProceed.push(new GetCurrencies({ productType }));
							}

							if (!eventSelectors.eventTypes()(store)) {
								actionsToProceed.push(new eventActions.GetEventTypes());
							}

							return concat(actionsToProceed);
						} else {
							return throwError(getResponseErrors(value));
						}
					}),
					catchError(
						handleFailedRequest(
							new eventActions.CreateEventFailed({
								msg: 'can\'t create event',
								action,
								critical: true,
								meta: {
									extraAction: new Go({ path: [defaultDashboardRoute] }),
									extraActionTitle: 'Go to dashboard',
									allowClose: false,
								},
							})
						)
					)
				)
			)
		)
	);

	updateEvent$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.UpdateEvent>(EventActionsConstants.UPDATE_EVENT),
			withLatestFrom(this.store$),
			switchMap(
				([
					{
						action,
						payload: { id, eventPart, isUpdating, event },
					},
					store,
				]) => {
					const entity = eventSelectors.event(id)(store) || null;

					const productToSet = mapEventTo(
						event,
						entity
							? {
								...entity.original,
								questions: entity.questions,
							  }
							: null,
						eventSelectors.currencies()(store)
					);

					if ((eventPart === EventPart.TICKETS || eventPart === EventPart.DETAILS) && isUpdating) {
						productToSet.action = ProductCreationAction.UPDATE;
					}

					let updateAPImethod$: AsyncResponse<Product>;

					switch (eventPart) {
						case EventPart.PROFILE:
							updateAPImethod$ = this.api.updateEventOrganiser(productToSet);
							break;
						case EventPart.DETAILS:
							updateAPImethod$ = this.api.updateEventDetails(productToSet);
							break;
						case EventPart.TICKETS:
							updateAPImethod$ = this.api.updateEventTickets(productToSet);
							break;
						default:
							updateAPImethod$ = this.api.updateEventOrganiser(productToSet);
					}
					return updateAPImethod$.pipe(
						mergeMap((value) => {
							if (value.isSuccess) {
								const product = mapEventFrom(value.payload);
								const {
									entities: normalizedEntity,
								}: {
									entities: NormalizeFields<EventEntityPayload>;
								} = normalize(product, eventScheme);

								if (eventPart === EventPart.TICKETS) {
									const actionsToProceed: AppAction<any>[] = [
										new eventActions.UpdateEventSuccess({
											id,
											normalizedEntity,
											entity: {
												original: {
													/* eslint-disable-next-line max-len */
													seatingCategories: chartCategoryToSeatingCategory(
														value.payload.seatingCategories,
														product.ticketsDetails.seatingChartId
													),
													seatsIOChartKey: value.payload.productDetails.seatsIOChartKey,
												},
												/* eslint-disable-next-line max-len */
												seatingCategories: chartCategoryToSeatingCategory(
													value.payload.seatingCategories,
													product.ticketsDetails.seatingChartId
												),
											},
											eventPart,
										}),
									];

									if (!isUpdating) {
										actionsToProceed.push(
											new eventActions.SetActiveStepIndex({
												index: eventPart + 1,
											})
										);
									}

									return actionsToProceed;
								} else {
									const actionsToProceed: AppAction<any>[] = [
										new eventActions.UpdateEventSuccess({
											id,
											normalizedEntity,
											entity: {
												original: {
													/* eslint-disable-next-line max-len */
													seatingCategories: chartCategoryToSeatingCategory(
														value.payload.seatingCategories,
														product.ticketsDetails.seatingChartId
													),
													seatsIOChartKey: value.payload.productDetails.seatsIOChartKey,
												},
											},
											eventPart,
										}),
									];
									/* eslint-disable-next-line max-len */
									if (!isUpdating && eventPart === EventPart.PROFILE) {
										actionsToProceed.push(
											new organiserActions.SyncOrganiserProfile({
												id,
												normalizedEntity,
												entity,
												organiserId : normalizedEntity.event[action.payload.id].profile.id,
											})
										);
									}
									if (!isUpdating) {
										actionsToProceed.push(
											new eventActions.SetActiveStepIndex({
												index: eventPart + 1,
											})
										);
									}
									return actionsToProceed;
								}
							} else {
								return throwError(() => getResponseErrors(value));
							}
						}),
						catchError(
							handleFailedRequest(
								new eventActions.UpdateEventFailed({
									msg: 'can\'t update event',
									action,
									critical: true,
								})
							)
						)
					);
				}
			)
		)
	);

	getEventMetadata$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.GetEventMetadata>(EventActionsConstants.GET_EVENT_METADATA),
			switchMap(({ action, payload: { id } }) =>
				this.api.getEventMetadata(id).pipe(
					handleSuccessRequest(({ payload }) => {
						const metadata = mapMetadataFrom(payload);
						pushDataLayer(metadata);
						return new eventActions.GetEventMetadataSuccess({
							id,
							metadata,
						});
					}),
					catchError(
						handleFailedRequest(
							new eventActions.GetEventMetadataFailed({
								msg: 'can\'t get event',
								action,
								critical: true,
								meta: {
									extraAction: new Go({ path: [defaultDashboardRoute] }),
									extraActionTitle: 'Go to dashboard',
									allowClose: false,
								},
							})
						)
					)
				)
			)
		)
	);

	getEventsCategories = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.GetEventTypes>(EventActionsConstants.GET_EVENT_TYPES),
			switchMap(({ action }) =>
				this.api.getEventTypes().pipe(
					handleSuccessRequest(({ payload: { categories, subCategories, formats } }) => {
						const mappedCategories =
							categories && categories.length
								? categories.map((c) => ({
									...c,
									subcategories: (c.subcategories || []).map((sC) => subCategories[sC]).filter((v) => !!v),
								  }))
								: [];

						return new eventActions.GetEventTypesSuccess({
							formats,
							categories: mappedCategories,
						});
					}),
					catchError(
						handleFailedRequest(
							new eventActions.GetEventTypesFailed({
								msg: 'can\'t get events categories',
								action,
							})
						)
					)
				)
			)
		)
	);

	GetEventOrders$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.GetEventOrders>(EventActionsConstants.GET_EVENT_ORDERS),
			mergeMap(({ action, payload }) =>
				this.api.getEventOrders(payload.eventId, payload.filters).pipe(
					mergeMap(({ payload: orders }) => [
						new eventActions.GetEventOrdersSuccess({
							eventId: payload.eventId,
							orders: orders,
						}),
					]),
					catchError(
						handleFailedRequest(
							new eventActions.GetEventOrdersFailed({
								msg: 'can\'t get your orders',
								action,
								critical: true,
								meta: {
									extraAction: new Go({ path: [InternalURLCreator.manageEvent(payload.eventId)] }),
									extraActionTitle: 'Go to Manage Event',
									allowClose: false,
								},
							})
						)
					)
				)
			)
		)
	);

	EventImageUpload$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.EventImageUpload>(EventActionsConstants.EVENT_IMAGE_UPLOAD),
			mergeMap(({ action, payload }) =>
				this.api.eventImageUpload(payload.eventId, payload.imageType, payload.imageData, payload.replace).pipe(
					mergeMap(({ payload: image }) => [
						new eventActions.EventImageUploadSuccess({
							eventId: payload.eventId,
							image: image,
						}),
					]),
					catchError(
						handleFailedRequest(
							new eventActions.EventImageUploadFailed({
								msg: 'can\'t upload image',
								action,
							})
						)
					)
				)
			)
		)
	);

	TabbedContentImageUpload$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.TabbedContentImageUpload>(EventActionsConstants.TABBED_CONTENT_UPLOAD_IMAGE),
			mergeMap(({ action, payload }) =>
				this.api.eventImageUpload(payload.eventId, payload.imageType, payload.imageData, payload.replace).pipe(
					mergeMap(({ payload: image }) => [
						new eventActions.TabbedContentImageUploadSuccess({
							eventId: payload.eventId,
							image: image,
							tabContentUid: payload?.tabContentUid,
							imageString: payload.imageString,
						}),
					]),
					catchError(
						handleFailedRequest(
							new eventActions.TabbedContentImageUploadFailed({
								msg: 'can\'t upload image',
								action,
							})
						)
					)
				)
			)
		)
	);
	// #region GetEventPromoters
	getEventPromoters = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.GetEventPromoters>(EventActionsConstants.GET_EVENT_PROMOTERS),
			mergeMap(({ action, payload }) =>
				this.api.getEventPromoters(payload.id).pipe(
					mergeMap(({ payload: promoters }) => [
						new eventActions.GetEventPromotersSuccess({
							id: payload.id,
							promotersPageInfo: promoters,
						}),
					]),
					catchError(
						handleFailedRequest(
							new eventActions.GetEventPromotersFailed({
								msg: 'can\'t get event promoters',
								action,
							})
						)
					)
				)
			)
		)
	);
	// #endregion

	// #region SetEventPromoters
	setEventPromotersIncentive = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.SetPromotersIncentive>(EventActionsConstants.SET_PROMOTERS_INCENTIVE),
			mergeMap(({ action, payload }) =>
				this.api.setEventPromotersIncentive(payload.id, payload.incentive).pipe(
					mergeMap(({ payload: promoters }) => [
						new eventActions.SetPromotersIncentiveSuccess({
							id: payload.id,
							promotersPageInfo: promoters,
						}),
					]),
					catchError(
						handleFailedRequest(
							new eventActions.SetPromotersIncentiveFailed({
								msg: 'can\'t set promoters incentive',
								action,
							})
						)
					)
				)
			)
		)
	);
	// #endregion

	// #region GetEventPromoterNetworks
	GetEventPromoterNetworks = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.GetEventPromoterNetworks>(EventActionsConstants.GET_EVENT_PROMOTER_NETWORKS),
			switchMap(({ action, payload }) =>
				this.api.getEventPromoterNetworks(payload.id).pipe(
					mergeMap(({ payload: networks }) => [
						new eventActions.GetEventPromoterNetworksSuccess({
							id: payload.id,
							promoterNetworksPageInfo: networks,
						}),
					]),
					catchError(
						handleFailedRequest(
							new eventActions.GetEventPromoterNetworksFailed({
								msg: 'can\'t get event promoter networks',
								action,
								critical: true,
								meta: {
									extraAction: new Go({ path: [InternalURLCreator.marketing(payload.id)] }),
									extraActionTitle: 'Go to Marketing',
									allowClose: false,
								},
							})
						)
					)
				)
			)
		)
	);
	// #endregion

	// #region CreatePromoterNetwork
	CreatePromoterNetwork = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.CreatePromoterNetwork>(EventActionsConstants.CREATE_PROMOTER_NETWORK),
			mergeMap(({ action, payload }) =>
				this.api.createPromoterNetwork(payload.id, payload.newPromoterNetworkInfo).pipe(
					mergeMap(({ payload: networks }) => [
						new eventActions.CreatePromoterNetworkSuccess({
							id: payload.id,
							promoterNetworksPageInfo: networks,
						}),
					]),
					catchError(
						handleFailedRequest(
							new eventActions.CreatePromoterNetworkFailed({
								msg: 'can\'t create promoter network',
								action,
							})
						)
					)
				)
			)
		)
	);
	// #endregion

	getEventGuestList$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.GetEventGuestList>(EventActionsConstants.GET_EVENT_GUEST_LIST),
			mergeMap(({ action, payload }) =>
				this.api.getGuestList(payload.guestListRequestInfo.eventId, payload.guestListRequestInfo).pipe(
					mergeMap(({ payload: guestList }) => [
						new eventActions.GetEventGuestListSuccess({
							id: payload.guestListRequestInfo.eventId,
							guestListResultInfo: guestList,
						}),
					]),
					catchError(
						handleFailedRequest(
							new eventActions.GetEventGuestListFailed({
								msg: 'Failed to retrieve guest list.',
								action,
								critical: true,
								meta: {
									extraAction: new Go({ path: [InternalURLCreator.orders(payload.guestListRequestInfo.eventId)] }),
									extraActionTitle: 'Go to Orders',
									allowClose: false,
								},
							})
						)
					)
				)
			)
		)
	);

	getSelectedGuestInfo$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.GetSelectedGuestInfo>(EventActionsConstants.GET_SELECTED_GUEST_INFO),
			mergeMap(({ action, payload }) =>
				this.api.getGuestInfo(payload.id, payload.purchaseId, payload.purchaseItemId).pipe(
					mergeMap(({ payload: selectedGuestInfo }) => [
						new eventActions.GetSelectedGuestInfoSuccess({
							id: payload.id,
							selectedGuestInfo: selectedGuestInfo,
						}),
					]),
					catchError(
						handleFailedRequest(
							new eventActions.GetSelectedGuestInfoFailed({
								msg: 'Failed to retrieve selected guest list info',
								action,
							})
						)
					)
				)
			)
		)
	);

	resendTickets$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.ResendTickets>(EventActionsConstants.RESEND_TICKETS),
			mergeMap(({ action, payload }) =>
				this.api.reSendTickets(payload.eventId, payload.purchaseId).pipe(
					mergeMap(({ payload: resendTicketsResult }) => [
						new eventActions.ResendTicketsSuccess({
							eventId: payload.eventId,
							mailSendingResult: resendTicketsResult,
						}),
					]),
					catchError(
						handleFailedRequest(
							new eventActions.ResendTicketsFailed({
								msg: 'Failed to resend tickets for this purchase',
								action,
								critical: true,
								meta: {
									extraAction: new Go({ path: [InternalURLCreator.guestList(payload.eventId)] }),
									extraActionTitle: 'Go to Guest List',
									allowClose: false,
								},
							})
						)
					)
				)
			)
		)
	);

	getTabbedContent$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.GetEventTabbedContent>(EventActionsConstants.GET_EVENT_TABBED_CONTENT),
			mergeMap(({ action, payload }) =>
				this.api.getEventTabbedContent(payload.eventId).pipe(
					mergeMap(({ payload: tabs }) => {
						const {
							entities: { byId },
							result: all,
						} = normalize(tabs, arrayOfCommonScheme);
						return [
							new eventActions.GetEventTabbedContentSuccess({
								eventId: payload.eventId,
								tabs: { byId, all },
							}),
						];
					}),
					catchError(
						handleFailedRequest(
							new eventActions.GetEventTabbedContentFailed({
								msg: 'Failed to get tabbed content for this event',
								action,
							})
						)
					)
				)
			)
		)
	);

	reOrderTabs$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.ReorderTabbedContent>(EventActionsConstants.REORDER_TABBED_CONTENT),
			mergeMap(({ action, payload }) =>
				this.api.reorderTabbedContent(payload.eventId, payload.order).pipe(
					mergeMap(({ payload: tabOrder }) => [
						new eventActions.ReorderTabbedContentSuccess({
							eventId: payload.eventId,
							order: tabOrder.order,
						}),
					]),
					catchError(
						handleFailedRequest(
							new eventActions.ReorderTabbedContentFailed({
								msg: 'Failed to reorder tabs for this event',
								action,
							})
						)
					)
				)
			)
		)
	);

	setTabContentVisibility = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.SetEventTabContentVisibility>(EventActionsConstants.SET_TAB_CONTENT_VISIBILITY),
			mergeMap(({ action, payload }) =>
				this.api.setTabContentVisibility(payload.eventId, payload.id, payload.isVisible).pipe(
					mergeMap(({ payload: tabVisibility }) => [
						new eventActions.SetEventTabContentVisibilitySuccess({
							id: payload.id,
							eventId: payload.eventId,
							isVisible: tabVisibility.isVisible,
						}),
					]),
					catchError(
						handleFailedRequest(
							new eventActions.SetEventTabContentVisibilityFailed({
								msg: 'Failed to set tab visibility',
								action,
							})
						)
					)
				)
			)
		)
	);

	deleteTabContent = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.DeleteTabContent>(EventActionsConstants.DELETE_TAB_CONTENT),
			mergeMap(({ action, payload }) =>
				this.api.deleteTabContent(payload.eventId, payload.id).pipe(
					mergeMap(({ payload: deleteTabContent }) => [
						new eventActions.DeleteTabContentSuccess({
							eventId: payload.eventId,
							id: deleteTabContent.id,
						}),
					]),
					catchError(
						handleFailedRequest(
							new eventActions.DeleteTabContentFailed({
								msg: 'Failed to delete selected tab',
								action,
							})
						)
					)
				)
			)
		)
	);

	upsertTabbedContent = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.UpsertTabContent>(EventActionsConstants.UPSERT_TAB_CONTENT),
			mergeMap(({ action, payload }) =>
				this.api.upsertEventTabbedContent(payload.eventId, payload.tab).pipe(
					mergeMap(({ payload: upsertedTab }) => [
						new eventActions.UpsertTabContentSuccess({
							eventId: payload.eventId,
							tab: upsertedTab,
							isUpdating: payload.isUpdating,
						}),
					]),
					catchError(
						handleFailedRequest(
							new eventActions.UpsertTabContentFailed({
								msg: 'Failed to add/edit tabbed content',
								action,
							})
						)
					)
				)
			)
		)
	);

	GetEventRegistrationSettings$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.GetEventRegistrationsSettings>(EventActionsConstants.GET_REGISTRATION_SETTINGS),
			mergeMap(({ action, payload }) =>
				this.api.getEventRegistrationSettings(payload.id).pipe(
					mergeMap(({ payload: RegistrationSettings }) => [
						new eventActions.GetEventRegistrationsSettingsSuccess({
							id: payload.id,
							registrationSettings: RegistrationSettings,
						}),
					]),
					catchError(
						handleFailedRequest(
							new eventActions.GetEventRegistrationsSettingsFailed({
								msg: 'can\'t get your registrations settings',
								action,
								critical: true,
								meta: {
									extraAction: new Go({ path: [InternalURLCreator.tickets(payload.id)] }),
									extraActionTitle: 'Go to Manage Checkout',
									allowClose: false,
								},
							})
						)
					)
				)
			)
		)
	);

	UpdateEventRegistrationSettings$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.UpdateEventRegistrationsSettings>(EventActionsConstants.UPDATE_REGISTRATION_SETTINGS),
			mergeMap(({ action, payload }) =>
				this.api.updateEventRegistrationSettings(payload.id, payload.registrationSettings).pipe(
					mergeMap(({ payload: RegistrationSettings }) => [
						new eventActions.UpdateEventRegistrationSettingsSuccess({
							id: payload.id,
							registrationSettings: RegistrationSettings,
						}),
					]),
					catchError(
						handleFailedRequest(
							new eventActions.UpdateEventRegistrationsSettingsFailed({
								msg: 'can\'t update your registration settings',
								action,
							})
						)
					)
				)
			)
		)
	);

	GetEventRegisteredUsers$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.GetRegisteredUsers>(EventActionsConstants.GET_REGISTRATIONS),
			mergeMap(({ action, payload }) =>
				this.api.getEventRegistrationsResults(payload.id, payload.filter).pipe(
					mergeMap(({ payload: EventRegistrations }) => [
						new eventActions.GetRegisteredUsersSuccess({
							id: payload.id,
							eventRegistrations: EventRegistrations,
						}),
					]),
					catchError(
						handleFailedRequest(
							new eventActions.GetRegisteredUsersFailed({
								msg: 'can\'t get your list of registered users',
								action,
								critical: true,
								meta: {
									extraAction: new Go({ path: [InternalURLCreator.tickets(payload.id)] }),
									extraActionTitle: 'Go to Manage Checkout',
									allowClose: false,
								},
							})
						)
					)
				)
			)
		)
	);

	getEventLinkCampaigns$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.GetEventLinkCampaigns>(EventActionsConstants.GET_EVENT_LINK_CAMPAIGNS),
			switchMap(({ action, payload: { eventId } }) =>
				this.api.getEventLinkCampaigns(eventId).pipe(
					mergeMap(({ payload: campaigns }) => {
						const {
							entities: { byId },
							result: all,
						} = normalize(campaigns, arrayOfCommonScheme);
						return of(
							new eventActions.GetEventLinkCampaignsSuccess({
								eventId,
								campaigns: {
									byId,
									all,
								},
							})
						);
					}),
					catchError(
						handleFailedRequest(
							new eventActions.GetEventLinkCampaignsFailed({
								msg: 'can\'t get event link campaigns',
								action,
								critical: true,
								meta: {
									extraAction: new Go({ path: [InternalURLCreator.marketing(eventId)] }),
									extraActionTitle: 'Go to Marketing',
									allowClose: false,
								},
							})
						)
					)
				)
			)
		)
	);

	deleteEventLinkCampaign$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.DeleteEventLinkCampaign>(EventActionsConstants.DELETE_EVENT_LINK_CAMPAIGN),
			switchMap(({ action, payload: { eventId, id } }) =>
				this.api.deleteEventLinkCampaign(eventId, id).pipe(
					mergeMap(({ payload }) => of(new eventActions.DeleteEventLinkCampaignSuccess(payload))),
					catchError(
						handleFailedRequest(
							new eventActions.DeleteEventLinkCampaignFailed({
								msg: 'can\'t delete link campaign',
								action,
							})
						)
					)
				)
			)
		)
	);

	addEventLinkCampaign$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.AddEventLinkCampaign>(EventActionsConstants.ADD_EVENT_LINK_CAMPAIGN),
			switchMap(({ action, payload: { eventId, campaign: campaignToAdd } }) =>
				this.api.addEventLinkCampaign(eventId, campaignToAdd).pipe(
					mergeMap((value) => {
						if (value.isSuccess && value.payload) {
							return of(
								new eventActions.AddEventLinkCampaignSuccess({
									eventId,
									campaign: value.payload,
								})
							);
						} else {
							return of(
								new eventActions.AddEventLinkCampaignFailed({
									msg: 'can\'t add link campaign',
									action,
								})
							);
						}
					}),
					catchError(
						handleFailedRequest(
							new eventActions.AddEventLinkCampaignFailed({
								msg: 'can\'t add link campaign',
								action,
							})
						)
					)
				)
			)
		)
	);

	getEventScanners = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.GetEventScanners>(EventActionsConstants.GET_EVENT_SCANNERS),
			mergeMap(({ action, payload }) =>
				this.api.getEventScanners(payload.id, payload.scheduleItemId).pipe(
					mergeMap(({ payload: scanners }) => {
						const {
							entities: { byId },
							result: all,
						} = normalize(scanners, arrayOfCommonScheme);
						return of(
							new eventActions.GetEventScannersSuccess({
								id: payload.id,
								scheduleItemId: payload.scheduleItemId,
								scanners: {
									byId,
									all,
								},
							})
						);
					}),
					catchError(
						handleFailedRequest(
							new eventActions.GetEventScannersFailed({
								msg: 'can\'t get scanner data',
								action,
							})
						)
					)
				)
			)
		)
	);

	editScanner = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.EditEventScanner>(EventActionsConstants.EDIT_EVENT_SCANNERS),
			mergeMap(({ action, payload }) =>
				this.api.editEventScanner(payload.id, payload.scanner, payload.scheduleItemId).pipe(
					mergeMap(({ payload: scanners }) => {
						const {
							entities: { byId },
							result: all,
						} = normalize(scanners, arrayOfCommonScheme);
						return of(
							new eventActions.EditEventScannerSuccess({
								id: payload.id,
								scheduleItemId: payload.scheduleItemId,
								scanners: {
									byId,
									all,
								},
							})
						);
					}),
					catchError(
						handleFailedRequest(
							new eventActions.EditEventScannerFailed({
								msg: 'can\'t edit event scanner',
								action,
							})
						)
					)
				)
			)
		)
	);

	addScanner = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.AddEventScanner>(EventActionsConstants.ADD_EVENT_SCANNERS),
			mergeMap(({ action, payload }) =>
				this.api.addEventScanner(payload.id, payload.scanner, payload.scheduleItemId).pipe(
					mergeMap(({ payload: scanners }) => {
						const {
							entities: { byId },
							result: all,
						} = normalize(scanners, arrayOfCommonScheme);
						return of(
							new eventActions.AddEventScannerSuccess({
								id: payload.id,
								scheduleItemId: payload.scheduleItemId,
								scanners: {
									byId,
									all,
								},
							})
						);
					}),
					catchError(
						handleFailedRequest(
							new eventActions.AddEventScannerFailed({
								msg: 'can\'t add event scanner',
								action,
							})
						)
					)
				)
			)
		)
	);

	newPinScanner = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.NewPinEventScanner>(EventActionsConstants.NEW_PIN_EVENT_SCANNER),
			mergeMap(({ action, payload }) =>
				this.api.generateNewPin(payload.id, payload.scanner, payload.scheduleItemId).pipe(
					mergeMap(({ payload: scanners }) => {
						const {
							entities: { byId },
							result: all,
						} = normalize(scanners, arrayOfCommonScheme);
						return of(
							new eventActions.NewPinEventScannerSuccess({
								id: payload.id,
								scheduleItemId: payload.scheduleItemId,
								scanners: {
									byId,
									all,
								},
							})
						);
					}),
					catchError(
						handleFailedRequest(
							new eventActions.NewPinEventScannerFailed({
								msg: 'can\'t get new password for scanner',
								action,
							})
						)
					)
				)
			)
		)
	);

	// #region getEventRequestFieldServices
	getEventRequestFieldServices = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.GetEventRequestFieldServices>(EventActionsConstants.GET_EVENT_REQUEST_FIELD_SERVICES),
			mergeMap(({ action, payload }) =>
				this.api.getEventRequestFieldServices(payload.id).pipe(
					mergeMap(({ payload: url }) => [
						new eventActions.GetEventRequestFieldServicesSuccess({
							id: payload.id,
							ratesUrl: url,
						}),
					]),
					catchError(
						handleFailedRequest(
							new eventActions.GetEventRequestFieldServicesFailed({
								msg: 'can\'t get field service rates url',
								action,
								critical: true,
								meta: {
									extraAction: new Go({ path: [InternalURLCreator.scanners(payload.id)] }),
									extraActionTitle: 'Go to On The Day',
									allowClose: false,
								},
							})
						)
					)
				)
			)
		)
	);

	getEventDesign = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.GetEventDesign>(EventActionsConstants.GET_EVENT_DESIGN),
			mergeMap(({ action, payload }) =>
				this.api.getEventDesign(payload.id).pipe(
					mergeMap(({ payload: design }) => [
						new eventActions.GetEventDesignSuccess({
							id: payload.id,
							design: design,
						}),
					]),
					catchError(
						handleFailedRequest(
							new eventActions.GetEventDesignFailed({
								msg: 'can\'t get event page design',
								action,
								critical: true,
								meta: {
									extraAction: new Go({ path: [InternalURLCreator.manageEvent(payload.id)] }),
									extraActionTitle: 'Go to manage event',
									allowClose: false,
								},
							})
						)
					)
				)
			)
		)
	);

	updateEventDesign = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.UpdateEventDesign>(EventActionsConstants.UPDATE_EVENT_DESIGN),
			mergeMap(({ action, payload }) =>
				this.api.updateEventDesign(payload.id, payload.design).pipe(
					mergeMap(({ payload: design }) => [
						new eventActions.UpdateEventDesignSuccess({
							id: payload.id,
							design: design,
						}),
					]),
					catchError(
						handleFailedRequest(
							new eventActions.UpdateEventDesignFailed({
								msg: 'can\'t update event page design',
								action,
							})
						)
					)
				)
			)
		)
	);

	// #endregion

	getEventCollectInfo$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.GetEventCollectInfo>(EventActionsConstants.GET_EVENT_COLLECT_INFO),
			mergeMap(({ action, payload: { eventId, isRegistration } }) =>
				this.api.getEventCollectInfo(eventId, isRegistration).pipe(
					mergeMap(({ payload: { collectInfo, tickets } }) => {
						const {
							entities: { byId },
							result: all,
						} = normalize(collectInfo, arrayOfCommonScheme);
						return [
							new eventActions.GetEventCollectInfoSuccess({
								eventId,
								collectInfo: {
									byId,
									all,
								},
								tickets,
							}),
						];
					}),
					catchError(
						handleFailedRequest(
							new eventActions.GetEventCollectInfoFailed({
								msg: 'can\'t get event questions',
								action,
								critical: true,
								meta: {
									extraAction: new Go({ path: [InternalURLCreator.tickets(eventId)] }),
									extraActionTitle: 'Go to Manage Checkout',
									allowClose: false,
								},
							})
						)
					)
				)
			)
		)
	);

	setCollectInfoProperty$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.SetCollectInfoProperty>(EventActionsConstants.SET_COLLECT_INFO_PROPERTY),
			concatMap(({ action, payload: { eventId, id, value, name } }) =>
				(
					this.api[`setCollectInfo${capitalize(name)}`](eventId, id, value) as AsyncResponse<{
						eventId: SchemeID;
						questionId: SchemeID;
						enabled: boolean;
					}>
				).pipe(
					handleSuccessRequest(
						({ payload }) =>
							new eventActions.SetCollectInfoPropertySuccess({
								id,
								eventId,
								name,
								value: payload[name],
							})
					),
					catchError(
						handleFailedRequest(
							new eventActions.SetCollectInfoPropertyFailed({
								msg: 'can\'t update question',
								action,
							})
						)
					)
				)
			)
		)
	);

	deleteCollectInfo$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.DeleteCollectInfo>(EventActionsConstants.DELETE_COLLECT_INFO),
			switchMap(({ action, payload: { eventId, id } }) =>
				this.api.deleteCollectInfo(eventId, id).pipe(
					handleSuccessRequest(({ payload }) => new eventActions.DeleteCollectInfoSuccess(payload)),
					catchError(
						handleFailedRequest(
							new eventActions.DeleteCollectInfoFailed({
								msg: 'can\'t delete question',
								action,
							})
						)
					)
				)
			)
		)
	);

	getProfileCollectInfo$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.GetProfileCollectInfo>(EventActionsConstants.GET_PROFILE_COLLECT_INFO),
			concatMap((action) => of(action).pipe(withLatestFrom(this.store$.select(eventSelectors.profileCollectInfo())))),
			switchMap(
				([
					{
						action,
						payload: { eventId, isRegistration },
					},
				]) =>
					this.api.getProfileCollectInfo(eventId, isRegistration).pipe(
						mergeMap((value) => {
							if (value.isSuccess) {
								const {
									entities: { byId },
									result: all,
								} = normalize(value.payload.collectInfo, arrayOfCommonScheme);

								return concat([
									new eventActions.GetProfileCollectInfoSuccess({
										eventId,
										collectInfo: {
											byId,
											all,
										},
									}),
								]);
							}

							return throwError(getResponseErrors(value));
						}),
						catchError((error) =>
							of(
								new eventActions.GetProfileCollectInfoFailed({
									msg: 'can\'t get profile questions',
									action,
									error,
									serverMessages: getResponseErrorCodes(error),
								})
							)
						)
					)
			)
		)
	);

	upsertEventCollectInfo$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.UpsertEventCollectInfo>(EventActionsConstants.UPSERT_EVENT_COLLECT_INFO),
			switchMap(({ action, payload: { eventId, collectInfo, isUpdating } }) =>
				this.api.upsertEventCollectInfo(eventId, collectInfo).pipe(
					mergeMap((value) => {
						if (value.isSuccess) {
							return concat([
								new eventActions.UpsertEventCollectInfoSuccess({
									eventId,
									collectInfo: value.payload.collectInfo,
									isUpdating,
								}),
							]);
						}
						return throwError(getResponseErrors(value));
					}),
					catchError(
						((error) =>
							of(
								new eventActions.UpsertEventCollectInfoFailed({
									msg: 'can\'t add question',
									action,
									error,
									serverMessages: getResponseErrorCodes(error),
								})
							))
					)
				)
			)
		)
	);

	reorder$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.ReorderCollectInfo>(EventActionsConstants.REORDER_COLLECT_INFO),
			concatMap(({ action, payload: { eventId, prevOrder, order, isRegistration } }) =>
				this.api.reorderCollectInfo(eventId, order, isRegistration).pipe(
					handleSuccessRequest(
						({ payload }) =>
							new eventActions.ReorderCollectInfoSuccess({
								eventId,
								order: payload.order,
								prevOrder,
							})
					),
					catchError(
						handleFailedRequest(
							new eventActions.ReorderCollectInfoFailed({
								msg: '',
								action,
							})
						)
					)
				)
			)
		)
	);

	getEventDefinitions$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.GetEventDefinitions>(EventActionsConstants.GET_EVENT_DEFINITIONS),
			mergeMap(({ action, payload }) =>
				this.api.getEventDefinitions(payload.id).pipe(
					mergeMap(({ payload: definitions }) => [
						new eventActions.GetEventDefinitionsSuccess({
							id: payload.id,
							definitions: definitions,
						}),
					]),
					catchError(
						handleFailedRequest(
							new eventActions.GetEventDefinitionsFailed({
								msg: 'oops, cant get event definitions',
								action,
								critical: true,
								meta: {
									extraAction: new Go({ path: [InternalURLCreator.settings(payload.id)] }),
									extraActionTitle: 'Go to Settings',
									allowClose: false,
								},
							})
						)
					)
				)
			)
		)
	);

	updateEventDefinitions$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.UpdateEventDefinitions>(EventActionsConstants.UPDATE_EVENT_DEFINITIONS),
			mergeMap(({ action, payload }) =>
				this.api.updateEventDefinitions(payload.id, payload.definitions).pipe(
					mergeMap(({ payload: definitions }) => [
						new eventActions.UpdateEventDefinitionsSuccess({
							id: payload.id,
							definitions: definitions,
						}),
					]),
					catchError(
						handleFailedRequest(
							new eventActions.UpdateEventDefinitionsFailed({
								msg: 'oops, cant update event definitions',
								action,
							})
						)
					)
				)
			)
		)
	);

	// #region getEventCopyInfo
	getEventCopyInfo = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.GetEventCopyInfo>(EventActionsConstants.GET_COPY_EVENT_INFO),
			mergeMap(({ action, payload }) =>
				this.api.getEventCopyInfo(payload.eventId)
					.pipe(
						mergeMap(({ payload: copyInfo }) =>
							[new eventActions.GetEventCopyInfoSuccess({
								eventId: payload.eventId,
								copyInfo: copyInfo,
							})]
						),
						catchError(
							handleFailedRequest(
								new eventActions.GetEventCopyInfoFailed({
									msg: 'oops, can\'t get event copy info',
									action,
									critical: true,
									meta: {
										extraAction: new Go({ path: [InternalURLCreator.manageEvent(payload.eventId)] }),
										extraActionTitle: 'Go to Manage Event',
										allowClose: false,
									},
								})
							)
						)
					)
			)
		)
	);
	// # endregion

	// #region copyEvent
	copyEvent = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.CopyEvent>(EventActionsConstants.COPY_EVENT),
			mergeMap(({ action, payload }) =>
				this.api.copyEvent(payload.eventId, mapEventCopyInfoTo(payload.copyInfo, payload.copyInfo.ianaTimezone))
					.pipe(
						mergeMap(({ payload: copyInfo }) =>
							[new eventActions.CopyEventSuccess({
								eventId: payload.eventId,
								copyInfo: copyInfo,
							})]
						),
						catchError(
							handleFailedRequest(
								new eventActions.CopyEventFailed({
									msg: 'oops, can\'t copy event',
									action,
								})
							)
						)
					)
			)
		)
	);
	// # endregion

	// #region webhooks
	updateSkipEventProgressItem$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.SkipEventCompletionItem>(EventActionsConstants.UPDATE_EVENT_SKIP_COMPLETION_ITEM),
			mergeMap(({ action, payload }) =>
				this.api.skipEventProgressCompletionItem(payload.id, payload.itemId).pipe(
					mergeMap(({ payload: eventDashboard }) => [
						new eventActions.SkipEventCompletionItemSuccess({
							eventId: payload.id,
							dashboard: eventDashboard,
						}),
					]),
					catchError(
						handleFailedRequest(
							new eventActions.SkipEventCompletionItemFailed({
								msg: 'oops, skipping this item failed',
								action,
							})
						)
					)
				)
			)
		));

	// # region getEventSettings
	getEventSettings$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.GetEventSettings>(EventActionsConstants.GET_EVENT_SETTINGS),
			mergeMap(({ action, payload}) =>
				this.api.getEventSettings(payload.id)
					.pipe(
						mergeMap(({ payload: settings }) =>
							[new eventActions.GetEventSettingsSuccess({
								settings,
								id: payload.id,
							})]
						),
						catchError(
							handleFailedRequest(
								new eventActions.GetEventSettingsFailed({
									msg: 'oops, couldn\'t get event settings',
									action,
								})
							)
						)
					)
			)
		));
	// # endregion

	// # region updateEventSettings
	updateEventSettings$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.UpdateEventSettings>(EventActionsConstants.UPDATE_EVENT_SETTINGS),
			mergeMap(({ action, payload}) =>
				this.api.updateEventSettings(payload.id, payload.settings)
					.pipe(
						mergeMap(({ payload: settings }) =>
							[new eventActions.UpdateEventSettingsSuccess({
								settings,
								id: payload.id,
							})]
						),
						catchError(
							handleFailedRequest(
								new eventActions.UpdateEventSettingsFailed({
									msg: 'oops, couldn\'t update event settings',
									action,
								})
							)
						)
					)
			)
		));
	// # endregion

	getEventTicketGroups$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.GetEventTicketGroups>(EventActionsConstants.GET_EVENT_TICKET_GROUPS),
			mergeMap(({ action, payload: { eventId } }) =>
				this.api.getEventTicketGroups(eventId).pipe(
					mergeMap(({ payload: { ticketGroups, ticketTypes, useTicketGroups } }) => {
						const ticketGroupsScheme = normalize(ticketGroups, arrayOfCommonScheme);
						const ticketTypesScheme = normalize(ticketTypes, arrayOfCommonScheme);
						return [new eventActions.GetEventTicketGroupsSuccess({
							eventId: eventId,
							ticketGroups: {
								byId: ticketGroupsScheme.entities.byId,
								all: ticketGroupsScheme.result,
							},
							ticketTypes: {
								byId: ticketTypesScheme.entities.byId,
								all: ticketTypesScheme.result,
							},
							useTicketGroups: useTicketGroups,
						})];
					}),
					catchError(
						handleFailedRequest(
							new eventActions.GetEventTicketGroupsFailed({
								msg: 'can\'t get event ticket groups',
								action,
								critical: true,
								meta: {
									extraAction: new Go({ path: [InternalURLCreator.tickets(eventId)] }),
									extraActionTitle: 'Go to Manage Checkout',
									allowClose: false,
								},
							})
						)
					)
				))
		));

	setTicketGroupsUsage$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.SetTicketGroupsUsage>(EventActionsConstants.SET_TICKET_GROUPS_USAGE),
			mergeMap(({ action, payload }) =>
				this.api.setTicketGroupUsage(payload.eventId, payload.useTicketGroups)
					.pipe(
						mergeMap(({ payload: ticketGroupsUsage }) =>
							[new eventActions.SetTicketGroupsUsageSuccess({
								eventId: ticketGroupsUsage.eventId,
								useTicketGroups: ticketGroupsUsage.useTicketGroups,
							})]
						),
						catchError(
							handleFailedRequest(
								new eventActions.SetTicketGroupsUsageFailed({
									msg: 'oops, can\'t set ticket groups usage',
									action,
								})
							)
						)
					))
		));

	deleteEventTicketGroup$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.DeleteEventTicketGroup>(EventActionsConstants.DELETE_TICKET_GROUP),
			mergeMap(({ action, payload }) =>
				this.api.deleteEventTicketGroup(payload.id, payload.eventId)
					.pipe(
						mergeMap(({ payload: deleteTicketGroup }) =>
							[new eventActions.DeleteEventTicketGroupSuccess({
								id: deleteTicketGroup.id,
								eventId: deleteTicketGroup.eventId,
							})]
						),
						catchError(
							handleFailedRequest(
								new eventActions.DeleteEventTicketGroupFailed({
									msg: 'oops, unable to delete ticket group',
									action,
								})
							)
						)
					))
		));

	upsertEventTicketGroup$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.UpsertTicketGroup>(EventActionsConstants.UPSERT_TICKET_GROUP),
			mergeMap(({ action, payload }) =>
				this.api.upsertTicketGroup(payload.eventId, payload.ticketGroup)
					.pipe(
						mergeMap(({ payload: ticketGroup }) =>
							[new eventActions.UpsertTicketGroupSuccess({
								eventId: payload.eventId,
								ticketGroup: ticketGroup,
								isUpdating: payload.isUpdating,
							})]
						),
						catchError(
							handleFailedRequest(
								new eventActions.UpsertTicketGroupFailed({
									msg: 'oops, can\'t add ticket group',
									action,
								})
							)
						)
					))
		));

	reorderTicketGroups$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.ReorderTicketGroup>(EventActionsConstants.REORDER_TICKET_GROUP),
			mergeMap(({ action, payload }) =>
				this.api.reorderTicketGroup(payload.eventId, payload.order)
					.pipe(
						mergeMap(({ payload: ticketGroupOrder }) =>
							[new eventActions.ReorderTicketGroupSuccess({
								eventId: ticketGroupOrder.eventId,
								order: ticketGroupOrder.order,
							})]
						),
						catchError(
							handleFailedRequest(
								new eventActions.ReorderTicketGroupFailed({
									msg: 'oops, can\'t reorder ticket groups',
									action,
								})
							)
						)
					))
		));

	getEventWebhooks = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.GetEventWebhooks>(EventActionsConstants.GET_EVENT_WEBHOOKS),
			switchMap(({ action, payload: { eventId } }) =>
				this.api.getEventWebhooks(eventId).pipe(
					handleSuccessRequest(
						({ payload: webhooks }) =>
							new eventActions.GetEventWebhooksSuccess({
								eventId,
								webhooks,
							})
					),
					catchError(
						handleFailedRequest(
							new eventActions.GetEventWebhooksFailed({
								msg: 'can\'t get event webhooks',
								action,
								critical: true,
								meta: {
									extraAction: new Go({ path: [InternalURLCreator.integrations(eventId)] }),
									extraActionTitle: 'Go to dashboard',
									allowClose: false,
								},
							})
						)
					)
				)
			)
		)
	);

	updateEventWebhooks$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.UpdateEventWebhooks>(EventActionsConstants.UPDATE_EVENT_WEBHOOKS),
			switchMap(({ action, payload: { eventId, webhooks: webhookToAdd, isUpdating } }) =>
				this.api.updateEventWebhooks(eventId, webhookToAdd).pipe(
					handleSuccessRequest(
						({ payload: webhooks }) =>
							new eventActions.UpdateEventWebhooksSuccess({
								eventId,
								webhooks,
								isUpdating,
							})
					),
					catchError(
						handleFailedRequest(
							new eventActions.UpdateEventWebhooksFailed({
								msg: 'can\'t update event webhooks',
								action,
							})
						)
					)
				)
			)
		)
	);

	testEventWebhooks$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.TestEventWebhooks>(EventActionsConstants.TEST_EVENT_WEBHOOKS),
			switchMap(({ action, payload: { eventId, testWebhook: webhookToTest } }) =>
				this.api.testEventWebhooks(eventId, webhookToTest).pipe(
					handleSuccessRequest(
						({ payload: testWebhook }) =>
							new eventActions.TestEventWebhooksSuccess({
								eventId,
								testWebhook,
							})
					),
					catchError(
						handleFailedRequest(
							new eventActions.TestEventWebhooksFailed({
								msg: 'Webhook test failed',
								action,
							})
						)
					)
				)
			)
		)
	);
	// # endregion

	copySeatingChart$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.CopySeatingChart>(EventActionsConstants.COPY_SEATING_CHART),
			mergeMap(({action, payload}) =>
				this.api.copySeatingChart(payload.copySeatingChartRequest).pipe(
					mergeMap(({ payload: copySeatingChartResult}) => {
						if (copySeatingChartResult.success){
							return [
								new eventActions.CopySeatingChartSuccess({
									copySeatingChartResult: copySeatingChartResult,
								}),
							];
						} else {
							throwError(copySeatingChartResult.message);
						}
					}),
					catchError(
						handleFailedRequest(
							new eventActions.CopySeatingChartFailed({
								msg: 'Oops, copying this seating chart failed!',
								action,
							})
						)
					)
				)
			)
		)
	);

	getEventScheduleTransfer$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.GetEventScheduleTransfer>(EventActionsConstants.GET_EVENT_SCHEDULE_TRANSFER),
			switchMap(({ action, payload: { eventId } }) =>
				this.api.getEventScheduleTransfers(eventId).pipe(
					handleSuccessRequest(({ payload: { scheduleItems, settings } }) => {
						const {
							entities: { byId },
							result: all,
						} = normalize(scheduleItems, arrayOfCommonScheme);
						return new eventActions.GetEventScheduleTransferSuccess({
							eventId,
							scheduleItems: {
								byId,
								all,
							},
							settings,
						});
					}),
					catchError(
						handleFailedRequest(
							new eventActions.GetEventScheduleTransferFailed({
								msg: 'can\'t get event schedules',
								action,
								critical: true,
								meta: {
									extraAction: new Go({
										path: [InternalURLCreator.eventDashboard(eventId)],
									}),
									extraActionTitle: 'Go to dashboard',
									allowClose: false,
								},
							})
						)
					)
				)
			)
		)
	);

	updateEventScheduleTransfer$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.UpdateEventScheduleTransfer>(EventActionsConstants.UPDATE_EVENT_SCHEDULE_TRANSFER),
			switchMap(({ action, payload }) =>
				this.api.updateEventScheduleTransfers(payload.eventId, payload.settings).pipe(
					handleSuccessRequest(
						({ payload: scheduleTransfer }) => {
							const { entities: { byId }, result: all } = normalize(scheduleTransfer.scheduleItems, arrayOfCommonScheme);
							return new eventActions.UpdateEventScheduleTransferSuccess({
								eventId: payload.eventId,
								settings: scheduleTransfer.settings,
								scheduleItems: {
									all: all,
									byId: byId,
								},
							});
						}),
					catchError(
						handleFailedRequest(
							new eventActions.UpdateEventScheduleTransferFailed({
								msg: 'can\'t update schedules',
								action,
							})
						)
					)
				)
			)
		));

	// #region deleteEvent
	deleteEvent$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.DeleteEvent>(EventActionsConstants.DELETE_EVENT),
			switchMap(({ action, payload }) =>
				this.api.deleteDraftEvent(payload.eventId).pipe(
					handleSuccessRequest(
						({ payload: success }) => new eventActions.DeleteEventSuccess({
							success,
						})),
					catchError(
						handleFailedRequest(
							new eventActions.DeleteEventFailed({
								msg: 'can\'t delete event',
								action,
							})
						)
					)
				)
			)
		));
	// #endregion

	// #region getOrderDetails
	getOrderDetails$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.GetOrderDetails>(EventActionsConstants.GET_ORDER_DETAILS),
			switchMap(({ action, payload }) =>
				forkJoin([
					this.api.getOrderDetails(payload.eventId, payload.orderId),
					this.oldApi.getOrderDetails(payload.orderId).pipe(
						map((order) => mapOrderReturnToOrder(order.result))
					),
				]).pipe(
					mergeMap(([order, orderOldApi]) => {
						const mergedOrder: ViewOrder = { ...order.payload, ...orderOldApi };
						return [
							new eventActions.GetOrderDetailsSuccess({
								order: mergedOrder,
								eventId: payload.eventId,
							}),
						];
					}),
					catchError(
						handleFailedRequest(
							new eventActions.GetOrderDetailsFailed({
								msg: 'oops, couldn\'t get your order details',
								action,
							})
						)
					)
				)
			)
		)
	);
	// #endregion

	// #region resendOrderTickets
	resendOrderTickets$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.ResendOrderTickets>(EventActionsConstants.RESEND_ORDER_TICKETS),
			switchMap(({ action, payload }) =>
				this.oldApi.resendOrderTickets(payload.purchaseId, payload.email)
					.pipe(
						mergeMap(({ result: response }) =>
							[new eventActions.ResendOrderTicketsSuccess({
								response,
							})]
						),
						catchError(
							handleFailedRequest(new eventActions.ResendOrderTicketsFailed({
								msg: 'oops, couldn\'t resend tickets',
								action,
							})
							)
						)
					)
			)
		)
	);
	// #endregion

	// #region changeTicketTypes
	changeTicketTypes$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.ChangeTicketTypes>(EventActionsConstants.CHANGE_TICKET_TYPES),
			switchMap(({ action, payload }) =>
				this.oldApi.changeTicketTypes(payload.orderId, payload.changedTickets).pipe(
					mergeMap(() => [
						new eventActions.ChangeTicketTypesSuccess({
							eventId: payload.eventId,
						}),
					]),
					catchError((error) =>
						of(
							new eventActions.ChangeTicketTypesFailed({
								msg: error.error,
								action,
							})
						)
					)
				)
			)
		)
	);
	// #endregion

	// #region cancelTickets
	cancelTickets$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.CancelTickets>(EventActionsConstants.CANCEL_TICKETS),
			switchMap(({ action, payload }) =>
				this.oldApi.cancelTickets(payload.orderId, payload.cancelTickets).pipe(
					mergeMap(() => [
						new eventActions.CancelTicketsSuccess({
							eventId: payload.eventId,
						}),
					]),
					catchError((error) =>
						of(
							new eventActions.CancelTicketsFailed({
								msg: error.error,
								action,
							})
						)
					)
				)
			)
		)
	);
	// #endregion

	// #region getEventOldAPI
	getEventOldAPI$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.GetEventOldAPI>(EventActionsConstants.GET_EVENT_OLD_API),
			switchMap(({ action, payload }) =>
				this.oldApi.getEvent(payload.eventId).pipe(
					mergeMap((event) => [new eventActions.GetEventOldAPISuccess({
						event: event,
						eventId: payload.eventId,
					})]),
					catchError((error) =>
						of(
							new eventActions.GetEventOldAPIFailed({
								msg: error.error,
								action,
							})
						)
					)
				)
			)
		)
	);
	// #endregion

	// #region markAsPaid
	markAsPaid$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<eventActions.MarkAsPaid>(EventActionsConstants.MARK_AS_PAID),
			switchMap(({ action, payload }) =>
				this.api.markAsPaid(payload.eventId, payload.orderId, payload.productPaymentGatewayDetailsLinkId).pipe(
					handleSuccessRequest(
						({ payload: success }) =>
							new eventActions.MarkAsPaidSuccess({
								success,
							})
					),
					catchError(
						handleFailedRequest(
							new eventActions.MarkAsPaidFailed({
								msg: 'oops, couldn\'t mark as paid',
								action,
							})
						)
					)
				)
			)
		)
	);
	// #endregion
}
