<div [ngClass]="{ 'input-group-mobile': isMobile, 'input-group-web-fill flex-grow-1': !isMobile }">
	<label class="l-label g-form-label">
		{{ label }}
		<span class="g-orange">*</span>
		<mat-icon 
			*ngIf="tooltip" 
			class="l-tooltip"
			#addressTooltip="matTooltip"
			[matTooltip]="tooltip"
			matTooltipPosition="above"
			(click)="addressTooltip.toggle()"
		>
				info
		</mat-icon>
	</label>
	<input id="address-filled-input" class="form-control l-input" type="address" #addressField/>
</div>
