import { schema } from 'normalizr';

export const profiles = new schema.Entity(
	'byId',
	{},
	{
		idAttribute: 'id',
	}
);

export const arrayOfProfiles = new schema.Array(profiles);
