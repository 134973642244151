import { Input, Output, EventEmitter, Directive } from '@angular/core';

export type ButtonSize = 'tiny' | 'small' | 'medium' | 'large';
export type ButtonType = 'primary' | 'secondary' | 'warning';
export type ButtonIconPos = 'left' | 'right';

@Directive()
export abstract class Button {
	/* eslint-disable  @angular-eslint/no-output-rename */
	/* eslint-disable  @angular-eslint/no-output-on-prefix */
	@Input() type: ButtonType = 'primary';
	@Input() size: ButtonSize = 'medium';
	@Input() simple = false;
	@Input() label: string = null;
	@Input() iconPos: ButtonIconPos = 'left';
	@Input() icon: string = null;
	@Input() disabled = false;
	@Input() loading = false;
	@Input() linkRef: string = null;
	@Input() linkTarget: string = null;
	@Output('onClick') click = new EventEmitter<MouseEvent>();
	@Output('onFocus') focus = new EventEmitter<FocusEvent>();
	@Output('onBlur') blur = new EventEmitter<FocusEvent>();

	abstract onClick(event?: MouseEvent): void;
	abstract onFocus(event?: FocusEvent): void;
	abstract onBlur(event?: FocusEvent): void;
	/* eslint-enable  @angular-eslint/no-output-rename */
	/* eslint-enable  @angular-eslint/no-output-on-prefix */
}
