import { combineReducers } from '@ngrx/store';
import { EventEntitySchema, EventsState } from '@app/models/store.model';
import { SchemeID } from '@app/models/dataSchema.model';
import { tickets } from './tickets.reducer';
import { venues } from './venues.reducer';
import { helpArticle } from './helpArticle.reducer';
import { currencies } from './currencies.reducer';
import { Actions } from '@app/store/actions/event/event.actions';
import * as ticketActions from '@app/store/actions/tickets/tickets.actions';
import * as profileActions from '@app/store/actions/organiserProfiles/organiserProfiles.actions';
import { EventActionsConstants } from '@app/store/actions/event/event.actions.constants';
import { entitiesById } from './eventEntity.reducer';
import { initialState } from './initialState';
import { eventTypes } from './eventTypes.reducer';
import { dashboardModals } from './dashboardModal.reducer';
import { banks } from './bank.reducer';

const activeStepIndex = (
	state = initialState.activeStepIndex,
	action: Actions
): EventsState['activeStepIndex'] => {
	switch (action.type) {
		case EventActionsConstants.SET_ACTIVE_STEP_INDEX:
			return action.payload.index;
		default:
			return state;
	}
};

const allEnteties = (
	state = initialState.entities.all,
	action: Actions
): EventsState['entities']['all'] => {
	switch (action.type) {
		case EventActionsConstants.GET_EVENT_SUCCESS:
			return [
				...state,
				action.payload.id,
			];
		default:
			return state;
	}
};

const allEntitiesFetching = (
	state = initialState.entities.isFetching,
	action: Actions | ticketActions.Actions | profileActions.Actions
): EventsState['entities']['isFetching'] => {
	switch (action.type) {
		case EventActionsConstants.GET_EVENT:
		case EventActionsConstants.GET_EVENT_METADATA:
			return true;
		case EventActionsConstants.GET_EVENT_FAILED:
		case EventActionsConstants.GET_EVENT_SUCCESS:
		case EventActionsConstants.CREATE_EVENT_FAILED:
		case EventActionsConstants.CREATE_EVENT_SUCCESS:
		case EventActionsConstants.GET_EVENT_METADATA_SUCCESS:
		case EventActionsConstants.GET_EVENT_METADATA_FAILED:
			return false;
		case EventActionsConstants.SET_EVENT_FETCHING_FLAG:
			return action.payload.isFetching;
		default:
			return state;
	}
};

const activeEntity = (
	state = initialState.entities.active,
	action: Actions
): EventsState['entities']['active'] => {
	switch (action.type) {
		case EventActionsConstants.GET_EVENT_SUCCESS:
		case EventActionsConstants.CREATE_EVENT_SUCCESS:
		case EventActionsConstants.GET_EVENT_METADATA_SUCCESS:
			return action.payload.id;
		case EventActionsConstants.DETACH_EVENT:
		case EventActionsConstants.GET_EVENT_METADATA:
			return null;
		default:
			return state;
	}
};

const entityWithMetadataReducer: EventEntitySchema<SchemeID, Actions> = {
	all: allEnteties,
	byId: entitiesById,
	isFetching: allEntitiesFetching,
	active: activeEntity,
};

export const eventReducer = {
	venues,
	currencies,
	banks,
	eventTypes,
	tickets,
	entities: combineReducers(entityWithMetadataReducer),
	helpArticle,
	activeStepIndex,
	dashboardModals,
};
