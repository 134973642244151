import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
	DefaultQuestionType,
	DraftEventCollectInfoForm,
	EventCollectInfo,
	EventCollectInfoTextboxValidation,
	EventCollectInfoTicket, EventCollectInfoType,
	collectInfoChoiceTypeOptions,
	collectInfoFileValidationOptions,
	collectInfoTexboxValidationOptions,
	collectInfoTextboxWidthOptions,
	eventQuestionTypeMap
} from '@app/models/event.model';
import { SelectFieldOption } from '@app/shared/form-field/select-field/select-field.model';
import { takeUntil } from 'rxjs';

@Component({
	selector: 'app-add-edit-question',
	templateUrl: './add-edit-question.component.html',
	styleUrls: ['./add-edit-question.component.sass'],
})
export class AddEditQuestionComponent {
	@Input() question: EventCollectInfo;
	@Input() profileCollectInfoOptions: SelectFieldOption<number>[];
	@Input() collectInfoTickets: EventCollectInfoTicket[];
	@Input() isEditing: boolean;
	@Input() form: UntypedFormGroup;
	@Input() isMobile = false;
	@Input() questions: EventCollectInfo[];
	@Input() isRegistration: boolean;

	@Output() save = new EventEmitter<DraftEventCollectInfoForm>();
	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	selectFieldOption: SelectFieldOption<number> = null;
	eventQuestionTypeMap = eventQuestionTypeMap;
	eventCollectInfoTypeEnum = EventCollectInfoType;
	defaultQuestionTypeEnum = DefaultQuestionType;
	collectInfoChoiceTypeOptions = collectInfoChoiceTypeOptions;
	collectInfoTextboxWidthOptions = collectInfoTextboxWidthOptions;
	collectInfoTexboxValidationOptions = collectInfoTexboxValidationOptions;
	eventCollectInfoTextboxValidationMap = EventCollectInfoTextboxValidation;
	collectInfoFileValidationOptions = collectInfoFileValidationOptions;
	isCustom = false;
	allPreSetOptionsTaken = false;

	multipleChoiceRegexTooltip =
		`If there is a specific choice that you would like the ticket buyer to select, enter it here. 
	Otherwise, if there is a set of correct options, you can use valid regular expression to define the correct options.`;
	textboxRegexTooltip =
		`Use a regular expression to validate the textbox input, 
	if you are expecting a specific answer from the ticket buyer.`;

	multipleChoiceErrorTooltip =
		`This message will be displayed if the 
	multiple choice option selected is not valid based on the regular expression validation.`;
	textboxErrorTooltip = 'This message will be displayed if the textbox input is not valid based on the regular expression validation.';

	get choiceOptions() {
		return this.form ? this.form.get('choiceOptions') as UntypedFormArray : null;
	}

	constructor(
	) {}

	ngOnInit(): void {
		if (this.profileCollectInfoOptions && this.questions){
			this.allPreSetOptionsTaken = this.profileCollectInfoOptions.every(x => this.questions.some(y => y.question === x.label));
		}
		if (this.form && this.form.get('applyToBuyer')) {
			this.form.get('applyToBuyer')?.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
			  if (value === false) {
					this.collectInfoTickets.forEach(ticket => {
						this.updateSelectedTickets(Number(ticket.id), true);
					});
			  }
			});
		}
	}

	ngOnChanges() {
		const { id, profileQuestionId }: DraftEventCollectInfoForm = this.form.getRawValue();
		if (this.isCustom !== true) {
			this.isCustom = id && !profileQuestionId;
		}
	}

	onCustomQuestion = () => {
		this.isCustom = true;
		this.form.patchValue({
			...new DraftEventCollectInfoForm(),
			isRegistrationQuestion: this.isRegistration,
			selectedTickets: !this.isRegistration ? this.collectInfoTickets.map(t => t.id) : [],
		}, {
			emitEvent: false,
		});
	};

	addChoice = (choiceOptions: UntypedFormArray) => choiceOptions.push(new UntypedFormControl(''));

	removeChoice = (choiceOptions: UntypedFormArray, index: number) => choiceOptions.removeAt(index);

	isSubmitAllowed = (form: UntypedFormGroup) => !(form && form.invalid);

	onSave = (draftQuestion?: DraftEventCollectInfoForm) => {
		if (draftQuestion && !this.isSubmitAllowed(this.form)) {
			return;
		}

		if (this.form.get('applyToBuyer').value) {
			draftQuestion.selectedTickets = [];
		}

		this.save.emit(draftQuestion);
	};

	onCancel() {
		this.save.emit(null);
	}

	updateSelectedTickets(id: number, isChecked: boolean): void {
		const ticketsFromForm = this.form.get('selectedTickets').value;
		let selectedTickets: number[] = [];

		if (isChecked) {
			selectedTickets.push(...ticketsFromForm, id);
		} else {
			selectedTickets = ticketsFromForm.filter(ticketId => ticketId !== id);
		}

		this.form.controls['selectedTickets'].patchValue(selectedTickets);
	}

	questionOptionsNoDuplicates(){
		if (this.profileCollectInfoOptions && this.questions){
			const filteredOptions = this.profileCollectInfoOptions.filter(x => !this.questions.some(y => y.question === x.label));
			return filteredOptions;
		}
	}

	handleChoiceValidationChange(change: boolean) {
		if (change) {
			this.form.get('textboxValidation').setValue(EventCollectInfoTextboxValidation.RegExp);
		} else {
			this.form.get('textboxValidation').setValue(EventCollectInfoTextboxValidation.None);
		}
	}

	isMultipleChoiceSelected() {
		return this.form.get('type').value === EventCollectInfoType.Country ||
			this.form.get('type').value === EventCollectInfoType.CheckBoxList ||
			this.form.get('type').value === EventCollectInfoType.RadioButtonList ||
			this.form.get('type').value === EventCollectInfoType.DropDownList;
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
