import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { IOldAPIDomainAPI } from '@app/models/oldApi.api.model';
import { SchemeID } from '@app/models/dataSchema.model';
import { AsyncSingleItemResultResponse, RequestType, AsyncListResultResponse } from '@app/models/http.model';
import { EventOrderFilter } from '@app/models/event.model';
import { CancelTicketsRequest, ChangedTicketTypes, Order, Event } from '@app/models/order.model';
import { Observable } from 'rxjs';

@Injectable()
export class OldAPIDomainAPI implements IOldAPIDomainAPI {
	constructor(private http: HttpService) {}

	getOrderDetails = (orderId: SchemeID): AsyncSingleItemResultResponse<Order> =>
		this.http.makeAPIRequest({
			type: RequestType.GET,
			url: `/orders/${orderId}`,
		});

	getEvent = (eventId: SchemeID): Observable<Event> =>
		this.http.makeAPIRequest({
			type: RequestType.GET,
			url: `/events/${eventId}`,
		});

	changeTicketTypes = (orderId: SchemeID, changedTickets: ChangedTicketTypes): AsyncSingleItemResultResponse<string> =>
		this.http.makeAPIRequest({
			type: RequestType.POST,
			url: `/orders/${orderId}/changeTicketTypes`,
			payload: {
				newTicketTypeIds: changedTickets.newTicketTypeIds,
				ticketIds: changedTickets.ticketIds,
			},
		});

	cancelTickets = (orderId: SchemeID, cancelTickets: CancelTicketsRequest): AsyncSingleItemResultResponse<string> =>
		this.http.makeAPIRequest({
			type: RequestType.POST,
			url: `/orders/${orderId}/cancel`,
			payload: {
				ticketIds: cancelTickets.ticketIds,
				bankAccount: cancelTickets.bankAccount,
				refundFeesPayableBy: cancelTickets.refundFeesPayableBy,
				makePayment: cancelTickets.makePayment,
			},
		});

	getOrders = (eventId: SchemeID, filters: EventOrderFilter): AsyncListResultResponse<any> =>
		this.http.makeAPIRequest({
			type: RequestType.GET,
			url: `/events/${eventId}/orders`,
		});

	resendOrderTickets = (purchaseId: SchemeID, email: string): AsyncSingleItemResultResponse<string> =>
		this.http.makeAPIRequest({
			type: RequestType.POST,
			url: `/orders/${purchaseId}/resend`,
			payload: { email },
		});
}
