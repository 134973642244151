import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { SchemeID } from '@app/models/dataSchema.model';
import { AccountModalReturn, BankAccount } from '@app/models/user.model';
import { StoreService } from '@app/services/store/store.service';
import { takeUntil } from 'rxjs';
import { BankAccountModalComponent } from '../bank-account-modal/bank-account-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { BANK_ACCOUNT_MODAL } from '@app/utils/consts';
import * as userBankAccountsSelector from '@app/store/selectors/userBankAccounts.selector';
import { GetBankAccounts, GetBanks } from '@app/store/actions/user/user.actions';

@Component({
	selector: 'app-bank-account-selection',
	templateUrl: './bank-account-selection.component.html',
	styleUrls: ['./bank-account-selection.component.sass'],
})
export class BankAccountSelectionComponent implements OnInit, OnChanges, OnDestroy {
	@Input() currencyId: SchemeID;
	@Input() isMobile: boolean;
	@Output() activeBankAccountChange = new EventEmitter<SchemeID>();

	bankAccounts: BankAccount[] = [];
	bankAccountOptions: { label: string; value: SchemeID }[] = [];
	selectedAccountId: SchemeID;
	isDefaultBankSelected: boolean;
	hasDispatchedAccounts = false;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(private store: StoreService, private dialog: MatDialog) {}

	ngOnInit() {
		this.store
			.select(userBankAccountsSelector.userBankAccounts())
			.pipe(takeUntil(this.destroyed$))
			.subscribe((accounts) => {
				this.bankAccounts = accounts;
				if (accounts && accounts.length) {
					const accountsForCurrency = accounts.filter(({ currency }) => currency.id === this.currencyId);
					const defaultAccount = accountsForCurrency.find(({ isDefault }) => isDefault);
					const selectedAccount = accountsForCurrency.find(({ isSelected }) => isSelected);
					const activeAccountId = selectedAccount || defaultAccount ? (selectedAccount || defaultAccount).id : null;

					this.isDefaultBankSelected = defaultAccount && activeAccountId === defaultAccount.id;
					this.selectedAccountId = activeAccountId;
					this.activeBankAccountChange.emit(activeAccountId);
					this.bankAccountOptions = this.getBankAccountOptions(accounts);
				} else if (!accounts.length && !this.hasDispatchedAccounts) {
					this.store.dispatch(new GetBankAccounts());
					this.hasDispatchedAccounts = true;
				}
			});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.currencyId && changes.currencyId.currentValue !== this.currencyId) {
			const { currentValue: currencyId } = changes.currencyId;
			if (currencyId) {
				this.store.dispatch(new GetBanks({ currencyId }));
				if (this.bankAccounts) {
					this.bankAccountOptions = this.getBankAccountOptions(this.bankAccounts);
				}
			}
		}
	}

	handleBankAccountModal() {
		const dialogRef = this.dialog.open(BankAccountModalComponent, {
			data: {
				isMobile: this.isMobile,
				currencyId: 1,
				descriptions: BANK_ACCOUNT_MODAL['standard'],
			},
			panelClass: this.isMobile ? 'g-full-page-dialog' : 'g-standard-dialog',
		});

		dialogRef.afterClosed().subscribe((account: AccountModalReturn) => {
			if (account && account.accountId) {
				this.selectedAccountId = account.accountId;
				this.isDefaultBankSelected = false;
				this.activeBankAccountChange.emit(this.selectedAccountId);
			}
		});
	}

	handleSelectionChange() {
		this.isDefaultBankSelected = false;
		this.activeBankAccountChange.emit(this.selectedAccountId);
	}

	getBankAccountOptions = (bankAccounts: BankAccount[]) =>
		(bankAccounts || [])
			.filter(({ currency: { id } }) => id === this.currencyId)
			.map(({ id, bankName, number }) => ({
				label: `${bankName}: **** ${number.slice(-4)}`,
				value: id,
			}));

	ngOnDestroy() {
		this.destroyed$.next();
	}
}
