import { OrganiserProfileActionConstants } from './organiserProfiles.constants';
import { AppAction, FailedAction } from '@app/models/action.model';
import { EventOrganiserProfile } from '@app/models/profile.model';
import { NormalizedScheme } from '@app/models/dataSchema.model';
import { SchemeID } from '@app/models/dataSchema.model';
import { EventEntity, NormalizedEventEntityPayload } from '@app/models/event.model';
import { EventEntityState } from '@app/models/store.model';

export class GetProfiles extends AppAction<{ eventId: SchemeID }> {
	readonly type = OrganiserProfileActionConstants.GET_PROFILES;
}

export class GetProfilesSuccess extends AppAction<{ profiles: NormalizedScheme<EventOrganiserProfile> }> {
	readonly type = OrganiserProfileActionConstants.GET_PROFILES_SUCCESS;
}

export class GetProfilesFailed extends FailedAction {
	readonly type = OrganiserProfileActionConstants.GET_PROFILES_FAILED;
}

export class CreateProfile extends AppAction<{
	eventId: SchemeID;
	profile: EventOrganiserProfile;
}> {
	readonly type = OrganiserProfileActionConstants.CREATE_PROFILE;
}

export class CreateProfileSuccess extends AppAction<{ profile: EventOrganiserProfile }> {
	readonly type = OrganiserProfileActionConstants.CREATE_PROFILE_SUCCESS;
}

export class CreateProfileFailed extends FailedAction {
	readonly type = OrganiserProfileActionConstants.CREATE_PROFILE_FAILED;
}

export class GetEventOrganiser extends AppAction<{ id: SchemeID }> {
	readonly type = OrganiserProfileActionConstants.GET_EVENT_ORGANISER;
}

export class GetEventOrganiserSuccess extends AppAction<{
	id: SchemeID;
	normalizedEntity: NormalizedEventEntityPayload;
	entity: Partial<EventEntityState>;
}> {
	readonly type = OrganiserProfileActionConstants.GET_EVENT_ORGANISER_SUCCESS;
}

export class GetEventOrganiserFailed extends FailedAction {
	readonly type = OrganiserProfileActionConstants.GET_EVENT_ORGANISER_FAILED;
}

export class SaveEventOrganiser extends AppAction<{
	id: SchemeID;
	event: EventEntity;
}> {
	readonly type = OrganiserProfileActionConstants.SAVE_EVENT_ORGANISER;
}

export class SaveEventOrganiserSuccess extends AppAction<{
	id: SchemeID;
	normalizedEntity: NormalizedEventEntityPayload;
	entity: Partial<EventEntityState>;
	organiserId: SchemeID;
}> {
	readonly type = OrganiserProfileActionConstants.SAVE_EVENT_ORGANISER_SUCCESS;
}

export class SaveEventOrganiserFailed extends FailedAction {
	readonly type = OrganiserProfileActionConstants.SAVE_EVENT_ORGANISER_FAILED;
}

export class GetUserOrganiserProfiles extends AppAction {
	readonly type = OrganiserProfileActionConstants.GET_ORGANISER_PROFILES;
}

export class GetUserOrganiserProfilesSuccess extends AppAction <{
	profiles: NormalizedScheme<EventOrganiserProfile>;
}> {
	readonly type = OrganiserProfileActionConstants.GET_ORGANISER_PROFILES_SUCCESS;
}

export class GetUserOrganiserProfilesFailed extends FailedAction {
	readonly type = OrganiserProfileActionConstants.GET_ORGANISER_PROFILES_FAILED;
}

export class UpdateUserOrganiserProfile extends AppAction <{
	profile: EventOrganiserProfile;
	isUpdating: boolean;
}> {
	readonly type = OrganiserProfileActionConstants.UPDATE_ORGANISER_PROFILE;
}

export class UpdateUserOrganiserProfileSuccess extends AppAction <{
	profile: EventOrganiserProfile;
	isUpdating: boolean;
}> {
	readonly type = OrganiserProfileActionConstants.UPDATE_ORGANISER_PROFILE_SUCCESS;
}

export class UpdateUserOrganiserProfileFailed extends FailedAction {
	readonly type = OrganiserProfileActionConstants.UPDATE_ORGANISER_PROFILE_FAILED;
}

export class SetOrganiserProfileModalFlag extends AppAction<{
	isFormModalOpen: boolean;
}> {
	readonly type = OrganiserProfileActionConstants.SET_ORGANISER_PROFILE_MODAL_FLAG;
}

export class DeleteUserOrganiserProfile extends AppAction <{
	id: SchemeID;
}> {
	readonly type = OrganiserProfileActionConstants.DELETE_ORGANISER_PROFILE;
}

export class DeleteUserOrganiserProfileSuccess extends AppAction <{
	id: SchemeID;
}> {
	readonly type = OrganiserProfileActionConstants.DELETE_ORGANISER_PROFILE_SUCCESS;
}

export class DeleteUserOrganiserProfileFailed extends FailedAction {
	readonly type = OrganiserProfileActionConstants.DELETE_ORGANISER_PROFILE_FAILED;
}

export class SetProfileLinkedProductModalFlag extends AppAction<{
	isLinkedProductModalOpen: boolean;
}> {
	readonly type = OrganiserProfileActionConstants.SET_PROFILE_LINKED_PRODUCT_MODAL_FLAG;
}

export class SyncOrganiserProfile extends AppAction<{
	id: SchemeID;
	normalizedEntity: NormalizedEventEntityPayload;
	entity: Partial<EventEntityState>;
	organiserId: SchemeID;
}> {
	readonly type = OrganiserProfileActionConstants.SYNC_ORGANISER_PROFILE;
}

export type Actions =
	GetProfiles
	| GetProfilesSuccess
	| GetProfilesFailed
	| CreateProfile
	| CreateProfileFailed
	| CreateProfileSuccess
	| GetEventOrganiser
	| GetEventOrganiserFailed
	| GetEventOrganiserSuccess
	| SaveEventOrganiser
	| SaveEventOrganiserFailed
	| SaveEventOrganiserSuccess
	| GetUserOrganiserProfiles
	| GetUserOrganiserProfilesSuccess
	| GetUserOrganiserProfilesFailed
	| UpdateUserOrganiserProfile
	| UpdateUserOrganiserProfileSuccess
	| UpdateUserOrganiserProfileFailed
	| SetOrganiserProfileModalFlag
	| DeleteUserOrganiserProfile
	| DeleteUserOrganiserProfileSuccess
	| DeleteUserOrganiserProfileFailed
	| SetProfileLinkedProductModalFlag
	| SyncOrganiserProfile;
