import { NgModule } from '@angular/core';
import { EventCreationModule } from './event-creation/event-creation.module';
import { Title } from '@angular/platform-browser';
import { EventControlPanelModule } from './control-panel/event-control-panel.module';
import { MyTicketsAndRegistrationsModule } from './my-tickets-and-registrations/my-tickets-and-registrations.module';
import { MyAccountModule } from './my-account/my-account.module';
import { MySavedEventsModule } from './my-saved-events/my-saved-events.module';
import { EarnCommissionModule } from './earn-commission/earn-commission.module';
import { OrganiserHubModule } from './organiser-hub/organiser-hub.module';
import { EventsModule } from './events/events.module';

@NgModule({
	imports: [
		EventCreationModule,
		EventControlPanelModule,
		MyAccountModule,
		MySavedEventsModule,
		MyTicketsAndRegistrationsModule,
		EarnCommissionModule,
		OrganiserHubModule,
		EventsModule,
	],
	exports: [
		EventCreationModule,
		EventControlPanelModule,
		MyAccountModule,
		MySavedEventsModule,
		MyTicketsAndRegistrationsModule,
		EarnCommissionModule,
		OrganiserHubModule,
		EventsModule,
	],
	providers: [Title],
})
export class FeaturesModule { }
