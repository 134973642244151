import { HttpService } from '@app/api/http/http.service';
import { StoreService } from '@app/services/store/store.service';
import { saveAs } from 'file-saver';
import { AddNotification } from '@app/store/actions/notification/notification.actions';
import { NotificationType } from '@app/models/notification.model';
import { Injectable } from '@angular/core';

@Injectable()
export class FileSaverService {
	constructor(
		private httpService: HttpService,
		private storeService: StoreService
	) {}

	static defaultErrorMessage = 'Oops! We can\'t download this file';

	get isFileSaverSupported(): boolean {
		try {
			return !!new Blob();
		} catch (e) {
			return false;
		}
	}

	private genType(fileName?: string): string {
		if (!fileName || fileName.lastIndexOf('.') === -1) {
			return 'text/plain';
		}
		const type = fileName.substr(fileName.lastIndexOf('.') + 1);
		switch (type) {
			case 'txt':
				return 'text/plain';
			case 'xml':
			case 'html':
				return `text/${type}`;
			case 'json':
				return 'octet/stream';
			default:
				return `application/${type}`;
		}
	}

	private _save(blob: Blob, fileName?: string, filtType?: string): void {
		if (!blob) {
			throw new Error('Data argument should be a blob instance');
		}

		saveAs(new Blob([blob], { type: filtType || blob.type || this.genType(fileName) }), decodeURI(fileName || 'download'));
	}

	saveText(txt: string, fileName?: string): void {
		const blob = new Blob([txt]);
		this._save(blob, fileName);
	}

	// TODO: After controller implementation in new WebAPI - get fileName from Content-Disposition header
	save = ({
		url,
		fileName,
		headers,
		onError = this.onError,
		onComplete,
	}: {
		url: string;
		fileName: string;
		headers?: { [header: string]: string };
		onError?: (msg: string) => void;
		onComplete?: () => void;
	}) => {
		this.httpService.http.get(
			url,
			{
				observe: 'response',
				responseType: 'blob',
				headers,
			}
		)
			.subscribe(
				res => {
				  this._save(res.body, fileName);
				  if (onComplete) {
						onComplete();
				  }
				},
				() => {
				  this.onError(FileSaverService.defaultErrorMessage);
				  if (onComplete) {
						onComplete();
				  }
				}
			  );
	};

	onError = (msg: string = FileSaverService.defaultErrorMessage) => {
		this.storeService.dispatch(
			new AddNotification({
				id: 'file-downloader',
				actionType: null,
				action: null,
				type: NotificationType.ERROR,
				title: msg,
			})
		);
	};
}
