import { Injectable } from '@angular/core';
import * as deepEqual from 'fast-deep-equal';
import { UntypedFormBuilder, UntypedFormArray, AbstractControl, UntypedFormGroup } from '@angular/forms';
import { omit, sortBy } from 'lodash';
import {
	requiredValidator,
	isDateAfter,
	isDateBefore,
	conditionalPipe,
	ticketCategories,
	maxLength
} from '@app/shared/form-field/form-validators';
import { FormService, ValidatorsConfig, FormConfig } from '../form/form.service';
import {
	EventTicketsDetails,
	Ticket,
	SeatingCategory,
	QuantityWarningType,
	EventTicketsSettings
} from '@app/models/ticket.model';
import { SchemeID } from '@app/models/dataSchema.model';
import { mapTicketCategoriesFrom } from '@app/utils/mappers/event';
import { isDraftID } from '@app/utils/IdGenerator';
import * as moment from 'moment';
import { TicketCategory } from '@app/models/ticket.model';
import { EventQuestion } from '@app/models/event.model';
import { FormValidators } from '@app/models/common.model';

@Injectable()
export class TicketsService {

	constructor(
		private formService: FormService
	) { }

	createEventTicketsForm(
		initialValues: Partial<EventTicketsDetails>,
		seatingCategories: SeatingCategory[],
		dirtyFields?: {id: SchemeID; key: string}[]
	) {
		const formValues = new EventTicketsDetails();

		const validatorsConfig: ValidatorsConfig<EventTicketsDetails> = {};

		const formConfig: FormConfig<EventTicketsDetails> = {};

		for (const key in formValues) {
			if (formValues.hasOwnProperty(key)) {
				const fieldValue = formValues[key];
				if (key === 'tickets') {
					formConfig[key] = this.formService.formBuilder.array([], validatorsConfig[key]);
					if (initialValues && initialValues.tickets.length) {
						initialValues.tickets.forEach(ticket => {
							(formConfig[key] as UntypedFormArray).push(
								this.createEventTicketGroup(
									ticket,
									formConfig.seatingChartId,
									seatingCategories,
									initialValues.allowNoCategories
								)
							);
						});
					}
				} else {
					formConfig[key] = this.formService.formBuilder.control(initialValues && initialValues[key] !== null
						&& initialValues[key] !== undefined ? initialValues[key] : fieldValue, validatorsConfig[key]);
				}
			}
		}

		const form = this.formService.formBuilder.group(formConfig);

		if (dirtyFields && dirtyFields.length) {
			dirtyFields.forEach(t => form.get(`tickets.${form.value.tickets.findIndex(e => t.id)}.${t.key}`).markAsDirty());
		}

		return form;
	}

	createEventTicketGroup(
		initialValues: Partial<Ticket>,
		chartIdController: AbstractControl,
		seatingCategories: SeatingCategory[],
		allowNoCategories: boolean
	) {

		const formValues = new Ticket();

		const validatorsConfig: ValidatorsConfig<Ticket> = {};

		const formConfig: FormConfig<Ticket>
			= this.formService.createFormControls({ formValues, initialValues, validatorsConfig });

		const questions = this.formService.formBuilder.array(
			((initialValues && initialValues.questions) || [])
				.map(el => this.formService.formBuilder.group(el))
		);

		const mergedCategories = mapTicketCategoriesFrom(initialValues && initialValues.categories, seatingCategories);

		const categories = this.formService.formBuilder.array(
			mergedCategories
				.map(el => this.formService.formBuilder.group(el))
		);

		const dynamicValidators = {
			name: [requiredValidator()],
			validFrom: [
				isDateBefore(formConfig.validTo),
			],
			hideUntil: [
				isDateBefore(formConfig.hideAfter, '\'Hide until\' date should come before \'Hide after\' date'),
			],
			hideAfter: [
				isDateAfter(formConfig.hideUntil, '\'Hide after\' date should come after \'Hide until\' date'),
			],
			validTo: [
				isDateAfter(formConfig.validFrom),
			],
			price: [
				conditionalPipe(((fC) => (
					fC.type.value && fC.type.value === 'paid'
				)).bind(null, formConfig), requiredValidator()),
			],
			categories: [
				conditionalPipe(((fC: AbstractControl) => (
					fC.value && !allowNoCategories
				)).bind(null, chartIdController), ticketCategories()),
			],
		};

		for (const key in dynamicValidators) {
			if (dynamicValidators.hasOwnProperty(key)) {
				(formConfig[key]).setValidators(dynamicValidators[key]);
			}
		}

		const benefits = this.formService.formBuilder.array(formConfig.benefits.value);

		const ticketForm = this.formService.formBuilder.group({ ...formConfig, questions, categories, benefits });
		ticketForm.get('categories').setValidators(dynamicValidators.categories);
		ticketForm.get('categories').updateValueAndValidity();
		if (ticketForm.get('hasSales').value) {
			ticketForm.get('price').disable();
		}
		return ticketForm;
	}

	createEventTicketsSettingsForm(
		initialValues?: Partial<EventTicketsSettings>
	) {
		const formValues = new EventTicketsSettings();

		const validatorsConfig:
		FormValidators<Partial<EventTicketsSettings>> = {
			ticketStubDescription: [maxLength(2500)],
		};

		const formConfig: FormConfig<EventTicketsSettings>
			= this.formService.createFormControls({ formValues, initialValues, validatorsConfig });

		return this.formService.formBuilder.group(formConfig);
	}

	proceedEndTicketSales(
		ticketsDetails: EventTicketsDetails,
		salesEndDateTime: string
	) {
		ticketsDetails.tickets.forEach(el => {
			el.salesEndDateTime = salesEndDateTime;
		});
		return ticketsDetails;
	}

	proceedMarkAsSoldOut(
		ticketsDetails: EventTicketsDetails
	): EventTicketsDetails {
		ticketsDetails.capacity = 0;
		return ticketsDetails;
	}

	isQuestionChecked(question: EventQuestion) {
		return question.enabled;
	}

	mergeFormAndStateTickets(
		ticketsFormArray: UntypedFormArray,
		stateTickets: Ticket[]
	): {
			dirtyFields: {id: SchemeID; key: string}[];
			tickets: Ticket[];
		} {
		interface SortedTicket {
			order: number;
			ticket: Ticket;
		}

		const formTickets: SortedTicket[] = ticketsFormArray.value
			.map((el, i) => ({
				order: i,
				ticket: el,
			} as SortedTicket));

		const dirtyTicketFields: {id: SchemeID; key: string}[] = [];

		const sortedStateTickets: SortedTicket[] =
			stateTickets
				.map((el, i) => {
					const dirtyFields = Object.keys(el)
						.filter(key => {
							const correspondingTicketIndex = formTickets.findIndex(e => e.ticket.id === el.id);
							return correspondingTicketIndex > -1
							&& key !== 'categories'
							&& key !== 'questions'
							&& key !== 'salesEndDateTime'
							&& !deepEqual(
								ticketsFormArray.controls[correspondingTicketIndex].get(key).value,
								el[key]
							);
						})
						.reduce((acc, key) => {
							const correspondingTicketIndex = formTickets.findIndex(e => e.ticket.id === el.id);
							if (correspondingTicketIndex > -1) {
								acc[key] = ticketsFormArray.controls[correspondingTicketIndex].get(key).value;
								dirtyTicketFields.push({ id: el.id, key });
							}
							return acc;
						}, {});

					return ({
						order: i,
						ticket: {
							...el,
							...dirtyFields,
						},
					} as SortedTicket);
				});

		const draftTickets: SortedTicket[] = formTickets
			.filter(el => {
				const similarSavedTicket = stateTickets.find(e => deepEqual(
					omit(el.ticket, ['categories', 'id', 'questions']),
					omit(e, ['categories', 'id', 'questions'])
				) && el.ticket.id !== e.id);
				return isDraftID(el.ticket.id) &&
				!similarSavedTicket;
			}
			)
			.map((el: SortedTicket) => ({
				...el,
				ticket: {
					...el.ticket,
					categories: Object.values(el.ticket.categories),
					questions: Object.values(el.ticket.questions),
				},
			} as SortedTicket));

		const resultedTickets: Ticket[] = sortBy(
			sortedStateTickets
				.concat(draftTickets)
		).map((el: SortedTicket) => el.ticket);

		return {
			dirtyFields: dirtyTicketFields,
			tickets: resultedTickets,
		};
	}

	checkAndUpdateTicketsIds(
		form: UntypedFormGroup,
		tickets: Ticket[],
		pathToTickets = 'tickets'
	) {
		const formTickets: Ticket[] = form.getRawValue()[pathToTickets];
		const isNewInTickets = formTickets.find(el => isDraftID(el.id));

		if (formTickets.length === tickets.length && isNewInTickets) {
			(form.get(pathToTickets) as UntypedFormArray).controls
				.forEach((t, i) => {
					if (isDraftID(t.get('id').value)) {
						t.get('id').setValue(tickets[i].id);
					}
				});
		}
	}

	checkAndUpdateTicketSalesEnds(
		form: UntypedFormGroup,
		tickets: Ticket[] = [],
		pathToTickets = 'tickets',
		pathToSalesEndDate = 'salesEndDateTime'
	) {
		const formTickets: Ticket[] = form.getRawValue()[pathToTickets];

		const isDatesUpdated = tickets.length &&
			/* eslint-disable-next-line max-len */
			!formTickets.every((el, i) => tickets[i] ? moment(tickets[i][pathToSalesEndDate]).isSame(moment(el[pathToSalesEndDate])) : true);

		if (isDatesUpdated) {
			tickets.forEach((t, i) => {
				form.get(`${pathToTickets}.${i}.${pathToSalesEndDate}`).setValue(t[pathToSalesEndDate]);
			});
		}
	}

	checkAndUpdateTicketsCapacity(
		form: UntypedFormGroup,
		tickets: EventTicketsDetails,
		pathToCapacity = 'capacity'
	) {
		if (form.get(pathToCapacity).value !== tickets.capacity) {
			form.get(pathToCapacity).setValue(0);
		}
	}

	checkAndUpdateTicketQuestions(
		form: UntypedFormGroup,
		tickets: Ticket[] = [],
		pathToTickets = 'tickets',
		pathToQuestions = 'questions'
	) {
		const formTickets: Ticket[] = form.getRawValue()[pathToTickets];

		formTickets.forEach((ticket, i) => {
			ticket[pathToQuestions].forEach((el, index) => {
				form.get(`${pathToTickets}.${i}.${pathToQuestions}.${index}`)
					.patchValue({
						id: tickets[i] ? tickets[i][pathToQuestions][index].id : null,
					});
			});
		});
	}

	checkAndUpdateTicketCategories(
		nextTicketCategories: SeatingCategory[],
		form: UntypedFormGroup,
		ticketGroupPath = 'tickets'
	) {
		this.setCategoriesToTickets(form, nextTicketCategories, ticketGroupPath);
	}

	setCategoriesToTickets(form: UntypedFormGroup, categories: SeatingCategory[], pathToTickets = 'tickets') {
		const formBuilder = new UntypedFormBuilder();
		const tickets = form.get(pathToTickets) as UntypedFormArray;
		const allowNoCategories = form.get('allowNoCategories') && form.get('allowNoCategories').value;

		tickets.controls.forEach(control => {
			const oldCatValues = control.get('categories').value;
			const mergedCategories =
				(categories || [])
					.map(el => ({
						...el,
						required: (oldCatValues || [])
							.some((c: TicketCategory) =>
								el.chartId === c.chartId &&
								el.seatsIOKey === c.seatsIOKey &&
								c.required
							),
					}));
			(control as UntypedFormGroup).removeControl('categories');

			const newCategories = formBuilder.array(
				mergedCategories
					.map(el => formBuilder.group(el))
			);
			(control as UntypedFormGroup).registerControl('categories', newCategories);


			const dynamicValidators = {
				categories: [
					/* eslint-disable-next-line max-len */
					conditionalPipe(((fC: AbstractControl) => fC.value && !allowNoCategories).bind(null, form.get('seatingChartId')), ticketCategories()),
				],
			};
			control.get('categories').setValidators(dynamicValidators.categories);
			control.get('categories').updateValueAndValidity();
		});
	}

	checkTicketsQuantity(tickets: Ticket[]): QuantityWarningType {
		const areTicketsWithZero = tickets.some(({ quantity }) => quantity === 0);
		const areTicketsWithOne = tickets.some(({ quantity }) => quantity === 1);

		if (areTicketsWithOne && areTicketsWithZero) {
			return QuantityWarningType.both;
		}

		if (areTicketsWithOne) {
			return QuantityWarningType.one;
		}

		if (areTicketsWithZero) {
			return QuantityWarningType.zero;
		}

		return null;
	}
}
