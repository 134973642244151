<div class="l-container" *ngIf="!isPageLoading; else pageLoading">
	<div class="l-content l-header-content" [ngClass]="{ 'mobile': isMobile }">
		<app-web-content-header
			*ngIf="!isMobile; else mobileHeaderContent"
			class="l-header"
			[headerContent]="headerContent"
			[mediumWidth]="true"
		></app-web-content-header>
		<div *ngIf="!isMobile" class="l-custom-description">
			<app-event-settings-ticketing-description></app-event-settings-ticketing-description>
		</div>
		<ng-template #mobileHeaderContent>
			<div class="l-mobile-description">
				<app-event-settings-ticketing-description></app-event-settings-ticketing-description>
			</div>
		</ng-template>
	</div>
	<form [formGroup]="form" *ngIf="form">
		<div class="l-form" [ngClass]="{ 'mobile': isMobile }">
			<div class="l-form-field-container">
				<div class="l-textarea-label-container g-form-label">
					<label> Ticket Stub Information </label>
					<mat-icon class="l-tooltip-icon" #tooltip="matTooltip" [matTooltip]="ticketStubInfoTooltip"
						matTooltipPosition="above" (click)="tooltip.toggle()">
						info
					</mat-icon>
				</div>
				<textarea
					id="ticket-stub-info-input"
					class="form-control mh-100 l-textarea"
					[formControl]="fromForm('ticketStubDescription')"
				></textarea>
				<div class="g-error-input" *ngIf="fromForm('ticketStubDescription').invalid">Max length is 2500 characters.</div>
			</div>
			<div class="l-form-field-container">
				<app-image-upload
					[eventId]="eventId"
					label="Logo Or Image"
					[maxFileSize]="imageService.maxImageSize"
					[image]="image"
					[imageServiceType]="ImageServiceTypeEnum.TICKET"
					uploadText="This image will appear on all ticket types. File types accepted are JPG, PNG, or BMP."
					[isMobile]="isMobile"
					preferredRes="1500x500"
					aspectRatio="3/1"
					maxHeight="500px"
					[allowedFileTypes]="allowedFileTypes"
					[customPreferredResText]="true"
					[tooltip]="logoOrImageTooltip"
					(uploadHandler)="handleImageUpload($event)"
					(removeHandler)="handleDelete($event)"
					(maxFileSizeAchieved)="imageService.onImageMaxSizeAchived()"
					(formDirty)="markFormAsDirty()"
				>
				</app-image-upload>
				<div class="l-ticket-preview-button-container" [ngClass]="{'mobile' : isMobile}">
					<button class="g-primary-button l-ticket-preview-button" 
						[ngClass]="{ 'l-ticket-preview-button-mobile': isMobile }" 
						id="ticket-preview-button" (click)="openTicketPreview()">
						PREVIEW TICKET
					</button>
				</div>
			</div>
			<hr class="l-horizontal-rule g-light-line" />
			<h3 class="mb-3 l-header">Email Settings</h3>
			<div class="l-form-toggles-container">
				<div class="l-toggle-container">
					<app-mat-slide-control-field
						customId="include-pdf-tickets-toggle"
						[isMobile]="isMobile"
						label="Include PDF Tickets"
						tooltip="If ON, a PDF of the tickets will be attached to the email sent to the buyer."
						formControlName="includePDF"
					></app-mat-slide-control-field>
				</div>
				<div class="l-toggle-container" *ngIf="!fromForm('includeMessage').value">
					<app-mat-slide-control-field
						customId="send-custom-mail-toggle"
						[isMobile]="isMobile"
						label="Send Custom Mail"
						tooltip="
							If ON, the standard Quicket confirmation email (with details such as venue & date) will be replaced with the custom message provided.
							The ticket PDF will still be attached unless switched off above.
						"
						formControlName="useCustom"
					></app-mat-slide-control-field>
				</div>
				<div class="l-toggle-container" *ngIf="!fromForm('useCustom').value">
					<app-mat-slide-control-field
						customId="include-message-toggle"
						[isMobile]="isMobile"
						label="Include A Message"
						tooltip="If ON, a custom message will appear in the standard Quicket confirmation email."
						formControlName="includeMessage"
					></app-mat-slide-control-field>
				</div>
				<div class="l-form-field-container" *ngIf="fromForm('includeMessage').value || fromForm('useCustom').value">
					<label class="g-form-label"> Custom Message </label>
					<textarea
						id="custom-message-input"
						class="form-control mh-100 l-textarea"
						[formControl]="fromForm('customMessage')"
					></textarea>
					<div class="g-error-input" *ngIf="fromForm('customMessage').invalid">Max length is 2500 characters.</div>
				</div>
			</div>
		</div>
	</form>
	<div [ngClass]="{'l-mobile-footer': isMobile}" class="l-sticky-bar">
		<app-footer-button
			[isMobile]="isMobile"
			[primaryText]="'SAVE'"
			[secondaryText]="'BACK'"
			[isDisabled]="!form || form.invalid || isUpdating || form.pristine"
			[isLoading]="isUpdating"
			($primaryButton)="handleSave()"
			($secondaryButton)="handleBack()"
		>
		</app-footer-button>
	</div>
</div>
<ng-template #pageLoading>
	<div class="l-page-loader">
		<mat-spinner></mat-spinner>
	</div>
</ng-template>
