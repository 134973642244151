<span *ngIf="label" class="ui-label">{{ label }}</span>
<image-cropper
	class="ui-image-cropper"
	[imageBase64]="imageBase64"
	[maintainAspectRatio]="maintainAspectRatio"
	[aspectRatio]="RatioParams.ratio"
	[cropperMinWidth]="RatioParams.minWidth"
	[resizeToWidth]="RatioParams.resizeToWidth"
	[onlyScaleDown]="onlyScaleDown"
	[roundCropper]="roundCropper"
	[format]="format"
	[output]="outputType"
	(imageCropped)="imageCropped.emit($event)"
	(imageLoaded)="imageLoaded.emit()"
	(loadImageFailed)="loadImageFailed.emit()"
></image-cropper>
