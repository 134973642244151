import { Action } from '@ngrx/store';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { HeaderContent } from '@app/models/shared';
import { StoreService } from '@app/services/store/store.service';
import { UpdatePassword } from '@app/store/actions/user/user.actions';
import { UserActionsConstants } from '@app/store/actions/user/user.actions.constants';
import { MY_ACCOUNT_HEADER } from '@app/utils/consts';
import { Actions, ofType } from '@ngrx/effects';
import { Observable, take, takeUntil } from 'rxjs';
import { NgForm } from '@angular/forms';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { FormCanDeactivate } from '@app/services/guards/user.guard';
import { MatDialog } from '@angular/material/dialog';
import { Go } from '@app/store/actions/router/router.actions';
import { InternalURLCreator } from '@app/services/url/url.dictionary';
import { ConfirmationModalComponent } from '@app/shared/modals/confirmation-modal/confirmation-modal.component';

@Component({
	selector: 'app-change-password',
	templateUrl: './change-password.component.html',
	styleUrls: ['./change-password.component.sass'],
})
export class ChangePasswordComponent extends FormCanDeactivate implements OnInit {
	headerContent: HeaderContent = MY_ACCOUNT_HEADER['changePassword'];
	showOldPassword = false;
	showConfirmPassword = false;
	showNewPassword = false;
	isLoading = false;
	isDifferent = true;

	oldPassword: string;
	confirmPassword: string;
	newPassword: string;

	isMobile = false;
	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private breakpointService: BreakpointService,
		private store: StoreService,
		private actions$: Actions,
		private dialog: MatDialog
	) {
		super();
	}

	ngOnInit(): void {
		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});
	}

	handlePrimaryButton(form: NgForm) {
		if (this.oldPassword === this.newPassword) {
			this.isDifferent = false;
			return;
		}

		this.isLoading = true;
		this.actions$
			.pipe(
				ofType(UserActionsConstants.UPDATE_PASSWORD_SUCCESS, UserActionsConstants.UPDATE_PASSWORD_FAILED),
				take(1)
			)
			.subscribe((payload: Action) => {
				this.isLoading = false;
				if (payload.type === UserActionsConstants.UPDATE_PASSWORD_SUCCESS) {
					form.resetForm();
				}
			});

		this.store.dispatch(new UpdatePassword({
			passwords: {
				oldPassword: this.oldPassword,
				newPassword: this.newPassword,
			},
		}));
	}

	handleNavigationBack() {
		this.store.dispatch(new Go({ path: [InternalURLCreator.myAccount()] }));
	}

	isFormDeactivatable(): boolean | Observable<boolean> {
		return !this.oldPassword && !this.confirmPassword && !this.newPassword;
	}

	deactivationFallback(): Observable<boolean> {
		this.dialog
			.open(ConfirmationModalComponent, {
				data: {
					title: 'Unsaved Changes',
					text: 'Are you sure you want to leave this page? Any unsaved changes will be discarded.',
					buttonText: 'LEAVE',
					centerText: true,
					isMobile: this.isMobile,
				},
				panelClass: 'g-standard-dialog',
			})
			.afterClosed()
			.subscribe((result) => {
				this.closeDeactivationModal(result);
			});
		return this.modalCallback.asObservable();
	}

	closeDeactivationModal(isConfirmed: boolean): void {
		this.modalCallback.next(isConfirmed);
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}
}
