import { ErrorHandlerActionConstants as constants } from './errorHandler.constants';
import { AppAction } from '@app/models/action.model';

export class CloseErrorModal extends AppAction {
	readonly type = constants.CLOSE_ERROR_MODAL;
}

export class RetryErrorAction extends AppAction<{ action: any }> {
	readonly type = constants.RETRY_ERROR_ACTION;
}

export type Actions =
	CloseErrorModal
	| RetryErrorAction;
