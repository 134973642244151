import { ErrorHandlerActionConstants as constants } from '../actions/errorHandler/errorHandler.constants';
import { ErrorHandlerState } from '@app/models/store.model';
import { FailedAction } from '@app/models/action.model';

export const initialState: ErrorHandlerState = {
	isOpened: false,
	action: null,
	msg: null,
	meta: {
		allowClose: true,
		extraAction: null,
		extraActionTitle: null,
	},
	serverMessages: null,
	serverCustomErrorMessage: null,
};

export const errorHandlerReducer = (
	state = initialState,
	action: FailedAction
): ErrorHandlerState => {
	if (action) {
		if (action.type === constants.CLOSE_ERROR_MODAL || action.type === constants.RETRY_ERROR_ACTION) {
			return {
				...state,
				isOpened: false,
			};
		}
		if (endsWith(action.type, '_FAILED') && action.payload.critical) {
			return {
				...state,
				isOpened: true,
				msg: action.payload.msg,
				action: action.payload.action,
				meta: action.payload.meta || state.meta,
				serverMessages: action.payload.serverMessages,
				serverCustomErrorMessage: action.payload.serverCustomErrorMessage,
			};
		}
	}
	return state;
};

function endsWith(str: string, temp: string) {
	const index = str.indexOf(temp);
	return index !== -1 && index + temp.length === str.length && true;
}
