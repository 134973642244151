import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SchemeID } from '@app/models/dataSchema.model';
import { EventOrganiserProfile } from '@app/models/profile.model';
import { HeaderContent } from '@app/models/shared';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { StoreService } from '@app/services/store/store.service';
import { InternalURLCreator } from '@app/services/url/url.dictionary';
import { EVENT_ID_PARAM_KEY } from '@app/utils/consts';
import { Actions } from '@ngrx/effects';
import { Observable, take, takeUntil } from 'rxjs';
import * as organiserSelectors from '@app/store/selectors/organiserProfile.selector';
import * as eventSelectors from '@app/store/selectors/event.selector';
import {
	CreateProfile,
	GetEventOrganiser,
	GetProfiles,
	SaveEventOrganiser
} from '@app/store/actions/organiserProfiles/organiserProfiles.actions';
import { ofType } from '@ngrx/effects';
import { OrganiserProfileActionConstants } from '@app/store/actions/organiserProfiles/organiserProfiles.constants';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { OrganiserProfileservice } from '@app/services/organiser-profile/organiser-profile.service';
import { omit } from 'lodash';
import { FormCanDeactivate } from '@app/services/guards/event.guard';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '@app/shared/modals/confirmation-modal/confirmation-modal.component';

@Component({
	selector: 'app-event-organiser',
	templateUrl: './event-organiser.component.html',
	styleUrls: ['./event-organiser.component.sass'],
})
export class EventOrganiserComponent extends FormCanDeactivate implements OnInit {
	isMobile = false;
	eventId: SchemeID;

	isProfilesLoading = true;
	isLoading = true;
	isUpdating = false;

	isButtonDisabled = true;

	selectedProfile = new EventOrganiserProfile();

	form: UntypedFormGroup;
	organiserProfiles: EventOrganiserProfile[] = [];
	organiserProfileOptions: { label: string; value: number }[] = [];

	headerContent: HeaderContent = {
		breadCrumbs: [
			{
				routeName: 'Manage Event',
				routeTo: () => InternalURLCreator.manageEvent(this.eventId),
			},
			{
				routeName: 'Event Organiser',
			},
		],
		title: 'Event Organiser',
		description: `Choose an organiser profile to link to your event. Any events also linked to the profile 
		selected will use the same details. There is also a seperate organiser profile page which displays these 
		details and links to your active events.`,
	};

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private breakpointService: BreakpointService,
		private store: StoreService,
		private actions$: Actions,
		private activatedRoute: ActivatedRoute,
		private organiserProfileService: OrganiserProfileservice,
		private dialog: MatDialog
	) {
		super();
	}

	ngOnInit() {
		this.activatedRoute.parent.paramMap.pipe(take(1)).subscribe((params) => {
			this.eventId = +params.get(EVENT_ID_PARAM_KEY);
		});
		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});

		this.initializePageData();

		this.store
			.select(organiserSelectors.organisers())
			.pipe(takeUntil(this.destroyed$))
			.subscribe((profiles) => {
				if (profiles) {
					this.organiserProfiles = profiles;
					this.organiserProfileOptions = profiles.map(({ name, id }) => ({
						label: name,
						value: id,
					}));
					this.isProfilesLoading = false;
				}
			});

		this.store
			.select(eventSelectors.eventOrganiser())
			.pipe(takeUntil(this.destroyed$))
			.subscribe((profile) => {
				if (profile) {
					this.selectedProfile = profile;
					this.createProfileForm();
					this.isLoading = false;
				}
			});
	}

	initializePageData() {
		this.getOrganiserProfiles();
		this.getSelectedProfile();
	}

	getOrganiserProfiles() {
		this.actions$
			.pipe(
				ofType(
					OrganiserProfileActionConstants.GET_ORGANISER_PROFILES_SUCCESS,
					OrganiserProfileActionConstants.GET_ORGANISER_PROFILES_FAILED
				),
				takeUntil(this.destroyed$)
			)
			.subscribe(() => {
				this.isProfilesLoading = false;
			});

		this.store.dispatch(new GetProfiles({ eventId: this.eventId }));
	}

	getSelectedProfile() {
		this.actions$
			.pipe(
				ofType(
					OrganiserProfileActionConstants.GET_EVENT_ORGANISER_SUCCESS,
					OrganiserProfileActionConstants.GET_EVENT_ORGANISER_FAILED
				),
				takeUntil(this.destroyed$)
			)
			.subscribe(() => {
				this.isLoading = false;
			});

		this.store.dispatch(new GetEventOrganiser({ id: this.eventId }));
	}

	createProfileForm() {
		this.form = this.organiserProfileService.createEventProfileForm(this.selectedProfile);
		const organiserProfileControl = this.form.get('id') as UntypedFormControl;
		organiserProfileControl.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(this.onOrganiserProfileChange);
	}

	onOrganiserProfileChange = (value: string): void => {
		if (value) {
			const profile = this.organiserProfiles.find(el => el.id === Number(value));
			this.form.patchValue(omit(profile, 'id', 'previousVenues'));
		} else {
			if (!this.form.pristine) {
				this.form.patchValue(
					omit(this.organiserProfileService.profileFormInitialsValues(), 'id', 'previousVenues')
				);
				this.form.markAsPristine();
				this.form.markAsUntouched();
			}
		}
	};

	createProfile() {
		if (this.form.get('id').value) {
			this.form.setValue(this.organiserProfileService.profileFormInitialsValues());
		}
		this.form.markAsPristine();
		this.form.markAsUntouched();
	}

	SaveSelectedOrganiser() {
		this.isUpdating = true;
		this.isButtonDisabled = true;

		this.actions$
			.pipe(
				ofType(
					OrganiserProfileActionConstants.SAVE_EVENT_ORGANISER_SUCCESS,
					OrganiserProfileActionConstants.SAVE_EVENT_ORGANISER_FAILED
				),
				takeUntil(this.destroyed$)
			)
			.subscribe(() => {
				this.isUpdating = false;
			});

		const profile = this.form.getRawValue();
		const { profilePageEmbed, ...profileToSave } = profile;
		this.store.dispatch(
			new SaveEventOrganiser({
				id: this.eventId,
				event: {
					profile: profileToSave,
				},
			})
		);
	}

	saveNewProfile() {
		this.isLoading = true;
		this.isButtonDisabled = true;

		this.actions$
			.pipe(
				ofType(OrganiserProfileActionConstants.CREATE_PROFILE_SUCCESS, OrganiserProfileActionConstants.CREATE_PROFILE_FAILED),
				take(1)
			)
			.subscribe(({type, payload: { profile }}: {type: string; payload: {profile: EventOrganiserProfile}}) => {
				if (type === OrganiserProfileActionConstants.CREATE_PROFILE_SUCCESS) {
					this.store.dispatch(
						new SaveEventOrganiser({
							id: this.eventId,
							event: {
								profile: profile,
							},
						})
					);
				}
				this.isLoading = false;
			});

		const newProfile = this.form.getRawValue();
		this.store.dispatch(new CreateProfile({ profile: newProfile, eventId: this.eventId }));
	}

	proceedFormSubmit(): void {
		if (!this.form.get('id').value) {
			this.saveNewProfile();
		} else {
			this.SaveSelectedOrganiser();
		}
		this.form.markAsPristine();
	}

	isFormDeactivatable(): boolean | Observable<boolean> {
		return !this.form || this.form.pristine;
	}

	deactivationFallback(): Observable<boolean> {
		this.dialog
			.open(ConfirmationModalComponent, {
				data: {
					title: 'Unsaved Changes',
					text: 'Are you sure you want to leave this page? Any unsaved changes will be discarded.',
					buttonText: 'LEAVE',
					centerText: true,
					isMobile: this.isMobile,
				},
				panelClass: 'g-standard-dialog',
			})
			.afterClosed()
			.subscribe((result) => {
				this.closeDeactivationModal(result);
			});
		return this.modalCallback.asObservable();
	}

	closeDeactivationModal(isConfirmed: boolean): void {
		this.modalCallback.next(isConfirmed);
	}
}
