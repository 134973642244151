<div
	class="l-container"
	[ngClass]="{ 'mobile': isMobile && !isLoading }"
>
	<div 
		class="l-content" 
		[ngClass]="{ 'mobile': isMobile }"
	>
		<app-web-content-header 
			*ngIf="!isMobile"
			[headerContent]="headerContent"
		>
		</app-web-content-header>
		<app-iframe
			[class.l-loading]="isLoading && !isMobile"
			class="g-iframe"
			[url]='url'
			(loaded)="handleLoadedIFrame()"
		></app-iframe>
	</div>
</div>