import { Injectable } from '@angular/core';
import { FormService } from '@app/services/form/form.service';
import { EventCopy, EventCopyInfo } from '@app/models/event-metadata.model';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { FormValidators } from '@app/models/common.model';
import { requiredValidator, isDateBefore, isDateAfter } from '@app/shared/form-field/form-validators';
import { DatesService } from '@app/services/dates/dates.service';

@Injectable()
export class EventCopyService {
	constructor (
		private formService: FormService,
		private datesService: DatesService
	) {}

	createEventCopyForm(initialValues?: Partial<EventCopyInfo>) {
		const formValues = new EventCopy();
		const formBuilder = new UntypedFormBuilder();

		const validatorsConfig: FormValidators<Partial<EventCopyInfo>> = {
			name: [requiredValidator()],
		};

		const formConfig =
			this.formService
				.createFormControls<any>({
				formValues,
				initialValues,
				validatorsConfig,
			});

		const ticketsMap = {};
		const questionsMap = {};

		if (initialValues) {
			if (initialValues.tickets && initialValues.tickets.length) {
				initialValues.tickets.forEach(t => {
					ticketsMap[t.id] = formBuilder.group({ ...t, checked: true });
				});
			}

			if (initialValues.questions && initialValues.questions.length) {
				initialValues.questions.forEach(q => {
					questionsMap[q.id] = formBuilder.group({ ...q, checked: true });
				});
			}
		}

		const dynamicValidators = {
			startDate: [
				requiredValidator(),
				isDateBefore(formConfig.endDate),
				isDateAfter(new UntypedFormControl(this.datesService.getStartOfTheDay()), 'Start date can\'t be in the past'),
			],
			endDate: [
				requiredValidator(),
				isDateAfter(formConfig.startDate),
			],
		};

		for (const key in dynamicValidators) {
			if (dynamicValidators.hasOwnProperty(key)) {
				formConfig[key].setValidators(dynamicValidators[key]);
			}
		}

		const tickets = formBuilder.group(ticketsMap);
		const questions = formBuilder.group(questionsMap);

		return formBuilder.group({ ...formConfig, tickets, questions });
	}
}
