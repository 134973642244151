import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { SchemeID } from '@app/models/dataSchema.model';
import { ChangedTicketTypes, Order, Event, OrderModalStep, TicketTypeForm } from '@app/models/order.model';
import { OrderService } from '@app/services/orders/orders.service';
import { StoreService } from '@app/services/store/store.service';
import { SelectFieldOption } from '@app/shared/form-field/select-field/select-field.model';
import { GetEventOldAPI } from '@app/store/actions/event/event.actions';
import { EventActionsConstants } from '@app/store/actions/event/event.actions.constants';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs';

@Component({
	selector: 'app-change-ticket-table',
	templateUrl: './change-ticket-table.component.html',
	styleUrls: ['./change-ticket-table.component.sass'],
})
export class ChangeTicketTableComponent implements OnInit, OnChanges {
	@Input() order: Order;
	@Input() eventId: number;
	@Input() currentStep: OrderModalStep;

	@Output() tickets = new EventEmitter<ChangedTicketTypes>();

	isLoading = true;

	ticketOptions: SelectFieldOption<SchemeID>[] = [];
	changedTickets: ChangedTicketTypes;
	changedTicketsMap = new Map<string, string>();

	selectDisplayColumns: string[] = ['ticketNumber', 'barcode', 'ticketHolder', 'typeSelect'];
	confirmDisplayColumns: string[] = ['ticketNumber', 'ticketHolder', 'oldType', 'newType'];

	ticketData = new MatTableDataSource<FormGroup>();
	guestForms: FormGroup<TicketTypeForm>[];
	orderModalStep = OrderModalStep;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	get displayColumns(): string[] {
		return this.currentStep === OrderModalStep.CONFIRMATION ? this.confirmDisplayColumns : this.selectDisplayColumns;
	}

	constructor(private store: StoreService, private actions$: Actions, private orderService: OrderService) {}

	ngOnInit(): void {
		this.initialiseTicketTypes();
		this.guestForms = this.orderService.createGuestForms(this.order);
		this.ticketData.data = this.guestForms;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.currentStep && !changes.currentStep.isFirstChange()) {
			this.updateTableDataSource();
		}
	}

	initialiseTicketTypes() {
		this.actions$
			.pipe(
				ofType(EventActionsConstants.GET_EVENT_OLD_API_SUCCESS, EventActionsConstants.GET_EVENT_OLD_API_FAILED),
				takeUntil(this.destroyed$)
			)
			.subscribe(({ type, payload: { event } }: { type: EventActionsConstants; payload: { event: Event } }) => {
				if (type === EventActionsConstants.GET_EVENT_OLD_API_SUCCESS) {
					const eventTickets = event.tickets;

					this.ticketOptions = eventTickets.map((ticket) => ({
						value: ticket.id,
						label: ticket.name,
					}));
				}
				this.isLoading = false;
			});

		this.store.dispatch(new GetEventOldAPI({ eventId: this.eventId }));
	}

	updateTableDataSource() {
		this.ticketData.data = this.currentStep === OrderModalStep.CONFIRMATION ? this.getConfirmedTicketData() : this.guestForms;
	}

	getConfirmedTicketData(): FormGroup<TicketTypeForm>[] {
		const changedTicketIds = this.changedTickets?.ticketIds?.split(',') || [];
		return this.guestForms.filter((form) => changedTicketIds.includes(form.get('ticketId').value.toString()));
	}

	getTicketTypeName(value: number): string | null {
		const found = this.ticketOptions.find((ticket) => ticket.value === value);
		return found ? found.label : '-';
	}

	handleTicketTypeChange(typeId: MatSelectChange, ticket: FormGroup<TicketTypeForm>) {
		const currentTicketId = ticket.get('ticketId').value.toString();
		const newTicketTypeId = typeId.value.toString();
		const oldTicketTypeId = ticket.get('ticketTypeId').value.toString();

		if (newTicketTypeId !== oldTicketTypeId) {
			this.changedTicketsMap.set(currentTicketId, newTicketTypeId);
		} else {
			this.changedTicketsMap.delete(currentTicketId);
		}

		this.updateChangedTickets();
	}

	updateChangedTickets() {
		this.changedTickets = {
			ticketIds: Array.from(this.changedTicketsMap.keys()).join(','),
			newTicketTypeIds: Array.from(this.changedTicketsMap.values()).join(','),
		};

		this.tickets.emit(this.changedTickets);
	}

	fromForm(controlKey: string, form: FormGroup<TicketTypeForm>): AbstractControl {
		if (form) {
			return form.get(controlKey);
		}
		return null;
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
