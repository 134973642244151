import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MailAccount } from '@app/models/organiser.model';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { StoreService } from '@app/services/store/store.service';
import { DeleteMailAccount, GetMailAccountsByUserId } from '@app/store/actions/organiser/organiser.actions';
import { OrganiserActionsConstants } from '@app/store/actions/organiser/organiser.actions.constants';
import { Actions, ofType } from '@ngrx/effects';
import { filter, takeUntil } from 'rxjs';
import * as organiserSelectors from '@app/store/selectors/organiser.selector';
import * as userSelectors from '@app/store/selectors/user.selector';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '@app/shared/modals/confirmation-modal/confirmation-modal.component';
import { AddMailAccountModalComponent } from '../../modals/add-mail-account-modal/add-mail-account-modal.component';
import { FilterOptions, HeaderContent } from '@app/models/shared';
import { InternalURLCreator } from '@app/services/url/url.dictionary';

@Component({
	selector: 'app-mail-accounts',
	templateUrl: './mail-accounts.component.html',
	styleUrls: ['./mail-accounts.component.sass'],
})
export class MailAccountsComponent implements OnInit {
	isMobile = false;
	userId: number;
	searchTerm: string;

	isMailAccountsLoading = true;

	headerContent: HeaderContent = {
		breadCrumbs: [
			{
				routeName: 'Organiser Profiles',
				routeTo: () => InternalURLCreator.orgProfile(),
			},
			{
				routeName: 'Mail Accounts',
			},
		],
		title: 'Mail Accounts',
		description: `Connect your own mail sending account for sending complimentary and invitation mails for your event. Once
		you have connected your own mail sending account, you will be able to send more mails than the default quota
		allowed for your events.`,
	};

	mailAccounts: MatTableDataSource<MailAccount> = new MatTableDataSource();

	displayColumns: string[] = ['name', 'fromName', 'fromEmail', 'typeName', 'more'];


	@ViewChild('paginator') set paginator(_pagination: MatPaginator) {
		if (_pagination) {
			this.mailAccounts.paginator = _pagination;
		};
	}

	@ViewChild(MatSort) set sort(_sort: MatSort) {
		if (_sort) {
			this.mailAccounts.sort = _sort;
		}
	};

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private breakpointService: BreakpointService,
		private store: StoreService,
		private actions$: Actions,
		private dialog: MatDialog

	) { }

	ngOnInit(): void {
		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});

		this.store.select(userSelectors.userId()).pipe(takeUntil(this.destroyed$), filter((user) => user != null)).subscribe((userId) => {
			this.userId = userId;
			this.initialiseMailAccounts();
		});
	}

	initialiseMailAccounts() {
		this.mailAccounts.data = [];

		this.actions$
			.pipe(
				ofType(
					OrganiserActionsConstants.GET_MAIL_ACCOUNT_BY_USER_ID_SUCCESS,
					OrganiserActionsConstants.GET_MAIL_ACCOUNT_BY_USER_ID_FAILED
				),
				takeUntil(this.destroyed$)
			)
			.subscribe(() => {
				this.isMailAccountsLoading = false;
			});

		this.store.select(organiserSelectors.getMailAccounts()).pipe(takeUntil(this.destroyed$)).subscribe((mailAccounts) => {
			if (mailAccounts) {
				this.isMailAccountsLoading = false;
				this.mailAccounts.data = mailAccounts;
			}
		});

		this.store.dispatch(new GetMailAccountsByUserId({id: this.userId}));
	}

	handleFilterOutput(filterOutput: FilterOptions) {
		const { searchTerm } = filterOutput;
		this.mailAccounts.filter = searchTerm.trim().toLowerCase();
		this.searchTerm = searchTerm;
	}

	handleAddMailAccount() {
		this.dialog.open(AddMailAccountModalComponent, {
			panelClass: this.isMobile ? 'g-full-page-dialog' : 'g-standard-dialog',
			data: {
				isMobile: this.isMobile,
				userId: this.userId,
			},
		});

	}

	handleDeleteMailAccount(mailAccount: MailAccount) {
		const dialogRef = this.dialog.open(ConfirmationModalComponent, {
			data: {
				title: 'Are You Sure?',
				text: `Delete ${mailAccount.name} from your mail accounts? This action cannot be undone.`,
				buttonText: 'DELETE',
				isMobile: this.isMobile,
			},
			panelClass: 'g-standard-dialog',
		});

		dialogRef.afterClosed().subscribe((value) => {
			if (value) {
				this.isMailAccountsLoading = true;
				this.store.dispatch(new DeleteMailAccount({ id: this.userId, mailAccountId: mailAccount.id }));
			}
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}

}
