import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EventDashboard, ProductStatus } from '@app/models/event.model';
import { URLCreator } from '@app/services/url/url.dictionary';
import config from '@app/config';
import { setTimezoneFrom } from '@app/utils/Date';
import { Currency } from '@app/models/ticket.model';
import { defaultCurrenciesForPayout } from '@app/features/control-panel/dashboard/event-dashboard.config';

@Component({
	selector: 'app-dashboard-header',
	templateUrl: './dashboard-header.component.html',
	styleUrls: ['./dashboard-header.component.sass'],
})
export class DashboardHeaderComponent {
	@Input() dashboard: EventDashboard;
	@Input() eventId: number;
	@Input() isLoading = true;
	@Input() isMobile = false;

	@Output() toggleEventVisibility = new EventEmitter();
	@Output() toggleSheduleToGoPublic = new EventEmitter<Date>();
	@Output() redirectEvent = new EventEmitter<number>();

	eventDateText = '';
	eventDate = '';
	designLink = '';
	backgroundBanner = '';
	backgroundBannerUrl = '';
	isEventLive = false;
	productStatus = ProductStatus;
	payoutDetailsLink: string;

	ngOnInit() {
		this.eventDateText = this.getEventDateText();
		this.eventDate = this.getEventDate();
		this.designLink = URLCreator.design(this.eventId);
		this.backgroundBanner = this.getBackgroundBanner();
		this.backgroundBannerUrl = this.getBackgroundBannerUrl();
		this.isEventLive = this.checkIfEventIsLive();
		this.payoutDetailsLink = URLCreator.eventPayout(this.eventId);
	}

	getBackgroundBanner() {
		return this.dashboard.bannerUrl ? (config.baseURL + this.dashboard.bannerUrl) : '';
	}

	getBackgroundBannerUrl() {
		return this.dashboard.bannerUrl ? `url(${config.baseURL}${this.dashboard.bannerUrl})` : '';
	}

	toggleEventVisibilityOutput() {
		this.toggleEventVisibility.emit();
	}

	toggleSheduleToGoPublicOutput($event?: Date) {
		this.toggleSheduleToGoPublic.emit($event);
	}

	getEventDateText() {
		if (this.dashboard.productStatus === ProductStatus.Finished) {
			return 'Event finished: ';
		} else if (this.dashboard.isScheduledEvent) {
			return 'Upcoming Event: ';
		} else {
			return '';
		}
	}

	getEventDate() {
		if (!this.dashboard.isScheduledEvent) {
			return setTimezoneFrom(this.dashboard.startDate, this.dashboard.ianaTimezone);
		} else {
			return setTimezoneFrom(this.getNextScheduledDate(), this.dashboard.ianaTimezone);
		}
	}

	getNextScheduledDate() {
		const now = new Date();
		const nextClosestDate = this.dashboard.scheduleItems.find(item => item.to > now);

		if (nextClosestDate) {
			return nextClosestDate.from;
		} else {
			return this.dashboard.scheduleItems[this.dashboard.scheduleItems.length - 1].from;
		}
	}

	checkIfEventIsLive(){
		return this.dashboard.productStatus !== ProductStatus.Draft && this.dashboard.productStatus !== ProductStatus.AwaitingActivation;
	}

	isPayoutCurrency = (currency: Currency) => (
		currency && defaultCurrenciesForPayout.includes(currency.iso)
	);

	redirectFinishedEvent($event: number) {
		this.redirectEvent.emit($event);
	}

	handleOpenBanner() {
		window.open(this.dashboard.bannerUrl, '_blank');
	}
}
