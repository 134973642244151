<app-my-account-header
	[isMobile]='isMobile'
	pageName="Password"
	(navigateBack)="handleNavigationBack()"
>
</app-my-account-header>
<div class="g-account-container">
	<div
		class="g-account-content"
		[ngClass]="{ 'mobile': isMobile, 'web': !isMobile }"
	>
		<app-web-content-header
			class="w-100"
			*ngIf="!isMobile"
			[headerContent]="headerContent"
		></app-web-content-header>
		<form
			#passwordForm="ngForm"
			class="g-form-container my-4 w-100"
		>
			<div class="mb-2 w-100 d-flex">
				<div
					class="position-relative mb-3"
					[ngClass]="{ 'g-full-width': isMobile, 'g-half-width': !isMobile }"
				>
					<label class="g-form-label">Old Password<span class="g-orange">*</span></label>
					<input
						#oldPasswordField="ngModel"
						[(ngModel)]="oldPassword"
						[type]="showOldPassword ? 'text' : 'password'"
						name="oldPassword"
						class="form-control g-append-input"
						placeholder="****"
						(input)="isDifferent = true"
						required
					/>
					<span
						class="l-eye-icon g-pointer"
						id='show-hide-old-password'
						(click)="showOldPassword = !showOldPassword"
					>
						<mat-icon *ngIf="showOldPassword">visibility</mat-icon>
						<mat-icon *ngIf="!showOldPassword">visibility_off</mat-icon>
					</span>
					<div
						class="g-error-input"
						*ngIf="!isDifferent"
					>
						New and old passwords are the same
					</div>
				</div>
			</div>
			<div class="g-double-input-container">
				<div
					class="position-relative"
					[ngClass]="{ 'g-full-width': isMobile, 'g-double-input': !isMobile }"
				>
					<label class="g-form-label">New Password<span class="g-orange">*</span></label>
					<input
						#newPasswordField="ngModel"
						[(ngModel)]="newPassword"
						[type]="showNewPassword ? 'text' : 'password'"
						name="newPassword"
						class="form-control g-append-input"
						placeholder="****"
						(input)="isDifferent = true"
						minlength="6"
						required
						appPasswordMatch
						[confirmPassword]="confirmPasswordField"
					/>
					<span
						class="l-eye-icon g-pointer"
						id='show-hide-new-password'
						(click)="showNewPassword = !showNewPassword"
					>
						<mat-icon *ngIf="showNewPassword">visibility</mat-icon>
						<mat-icon *ngIf="!showNewPassword">visibility_off</mat-icon>
					</span>
					<div
						class="g-error-input"
						*ngIf="passwordForm.invalid && (passwordForm.dirty || passwordForm.touched)"
					>
						<div *ngIf="newPasswordField.errors?.minlength">Minimum 6 characters</div>
						<div *ngIf="newPasswordField.errors?.confirmPassword">Passwords do not match</div>
					</div>
				</div>
				<div
					class="position-relative"
					[ngClass]="{ 'g-full-width': isMobile, 'g-double-input': !isMobile }"
				>
					<label class="g-form-label">Confirm Password<span class="g-orange">*</span></label>
					<input
						#confirmPasswordField="ngModel"
						[(ngModel)]="confirmPassword"
						[type]="showConfirmPassword ? 'text' : 'password'"
						name="confirmPassword"
						class="form-control g-append-input"
						placeholder="****"
						required
						appPasswordMatch
						[confirmPassword]="newPasswordField"
					/>
					<span
						class="l-eye-icon g-pointer"
						id='show-hide-confirm-password'
						(click)="showConfirmPassword = !showConfirmPassword"
					>
						<mat-icon *ngIf="showConfirmPassword">visibility</mat-icon>
						<mat-icon *ngIf="!showConfirmPassword">visibility_off</mat-icon>
					</span>
					<div
						class="g-error-input"
						*ngIf="passwordForm.invalid && (passwordForm.dirty || passwordForm.touched)"
					>
						<div *ngIf="confirmPasswordField.errors?.confirmPassword">Passwords do not match</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>
<app-footer-button
	class="g-fixed-footer"
	[primaryText]="'SAVE CHANGES'"
	[isLoading]="isLoading"
	[isDisabled]="passwordForm.invalid"
	($primaryButton)="handlePrimaryButton(passwordForm)"
	[isMobile]="isMobile"
>
</app-footer-button>