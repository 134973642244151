import { Component, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SectionRouteCard } from '@app/models/shared';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { StoreService } from '@app/services/store/store.service';
import { URLCreator } from '@app/services/url/url.dictionary';
import { Go } from '@app/store/actions/router/router.actions';
import { handleOrder } from '@app/utils/common-helpers';
import { EVENT_ID_PARAM_KEY, EVENT_REPORT_CARDS, ReportType } from '@app/utils/consts';
import { Dictionary } from '@ngrx/entity';
import { take, takeUntil } from 'rxjs';
import * as eventSelectors from '@app/store/selectors/event.selector';
import { EventMetadata } from '@app/models/event-metadata.model';

@Component({
	selector: 'app-reports',
	templateUrl: './reports.component.html',
	styleUrls: ['./reports.component.sass'],
})
export class ReportsComponent {
	cardsData: Dictionary<SectionRouteCard> = EVENT_REPORT_CARDS;
	isMobile = false;
	eventId: number;
	metadata: EventMetadata;

	handleDefaultOrder = handleOrder;
	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private breakpointService: BreakpointService,
		private store: StoreService,
		private activatedRoute: ActivatedRoute
	) { }

	ngOnInit(): void {
		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});

		this.activatedRoute.parent.paramMap.pipe(take(1)).subscribe((params) => {
			this.eventId = +params.get(EVENT_ID_PARAM_KEY);
		});

		this.store.select(eventSelectors.eventMetadata()).pipe(takeUntil(this.destroyed$)).subscribe(metadata => {
			if (metadata) {
				this.metadata = metadata;
			}
		});
	}

	handleNavigate(card: SectionRouteCard): void {
		if (card.type === ReportType.TaxInvoice) {
			window.open(URLCreator.taxInvoice(
				this.eventId,
				this.metadata.accountingVersion,
				this.metadata.encrypt,
				0
			), '_blank');
		} else if (card.type === ReportType.EventSummary) {
			window.open(URLCreator.eventSummary(
				this.eventId,
				this.metadata.accountingVersion,
				this.metadata.encrypt,
				0
			), '_blank');
		} else {
			this.store.dispatch(new Go({ path: [card.routeTo(this.eventId)] }));
		}
	}

	handleMiddleClick(card: SectionRouteCard): string {
		switch (card.type) {
			case ReportType.TaxInvoice:
			case ReportType.EventSummary:
				return null;
			default:
				return card.externalRouteTo(this.eventId);
		}
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}

}
