<div class="l-container" [ngClass]="{'l-mobile-container': isMobile}">
	<img [src]="data.image" *ngIf="!isMobile" class="l-image" />
	<div class="l-content" [ngClass]="{'l-mobile-content': isMobile}">
		<div *ngIf="isMobile" class="mb-3">
			<div class="l-badge" [ngClass]="data.isPublic ? 'public' : 'private'">
				{{ data.isPublic ? "Public" : "Private" }}
			</div>
		</div>
		<div class="l-details">
			<div class="l-text">
				{{ data.organiserName }}
			</div>
			<div>
				<h3 class="l-name">{{ data.name }}</h3>
			</div>
			<div class="l-text">
				{{ data.startDate | formattedDatePipe }}
			</div>
		</div>
		<div class="l-info" *ngIf="!isMobile">
			<div>
				<div class="l-badge" [ngClass]="data.isPublic ? 'public' : 'private'">
					{{ data.isPublic ? "Public" : "Private" }}
				</div>
			</div>
		</div>
	</div>
</div>
