<div class="l-container" [ngClass]="{'l-mobile-container': isMobile}">
	<div class="l-content" [ngClass]="{'flex-column': isMobile}">
		<div class="l-details">
			<div>
				<h3 class='l-title'>{{ data?.campaignName }}</h3>
			</div>
			<div class="l-text">
				View Link: <a id='link-champaign-link' class='l-link g-link' [href]="data?.link" target="_blank">{{ data?.eventName }}</a>
			</div>

			<div class="l-date">
				{{ data?.dateAdded | formattedDatePipe }}
			</div>
		</div>
	</div>
</div>
