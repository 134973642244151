import { SchemeID } from './dataSchema.model';


export enum EventRepeats {
	DAILY = 'DAILY',
	WEEKLY = 'WEEKLY',
	MONTHLY = 'MONTHLY',
	SINGLE_DATE = 'SINGLE_DATE',
}

export enum DaysOfWeek {
	SUNDAY = 'SUNDAY',
	MONDAY = 'MONDAY',
	TUESDAY = 'TUESDAY',
	WEDNESDAY = 'WEDNESDAY',
	THURSDAY = 'THURSDAY',
	FRIDAY = 'FRIDAY',
	SATURDAY = 'SATURDAY',
}

export enum ByDayOfWeek {
	FIRST = 'FIRST',
	SECOND = 'SECOND',
	THIRD = 'THIRD',
	FOURTH = 'FOURTH',
	LAST = 'LAST',
}

export class ScheduleMapper {
	dateType = {
		[EventRepeats.DAILY]: 1,
		[EventRepeats.WEEKLY]: 2,
		[EventRepeats.MONTHLY]: 3,
		[EventRepeats.SINGLE_DATE]: 4,
	};

	daysOfWeek = {
		[DaysOfWeek.SUNDAY]: '1',
		[DaysOfWeek.MONDAY]: '2',
		[DaysOfWeek.TUESDAY]: '3',
		[DaysOfWeek.WEDNESDAY]: '4',
		[DaysOfWeek.THURSDAY]: '5',
		[DaysOfWeek.FRIDAY]: '6',
		[DaysOfWeek.SATURDAY]: '7',
	};

	byDayOfWeek = {
		[ByDayOfWeek.FIRST]: '1',
		[ByDayOfWeek.SECOND]: '2',
		[ByDayOfWeek.THIRD]: '3',
		[ByDayOfWeek.FOURTH]: '4',
		[ByDayOfWeek.LAST]: '5',
	};

}

export class AddingScheduleItem {
	id: string = null;
	repeats: EventRepeats = null;
	weeklyRepeat: DaysOfWeek[] = null;
	isMonthlyByDayOfMonth = true;
	monthlyDayOfWeek: number = null;
	monthlyDayOfMonth: number = null;
	startTime: Date = null;
	endTime: Date = null;
	timeEndingDay = 0;
	startDate: Date = null;
	endDate: Date = null;
	hasSoldTickets: boolean = null;
}

export class AddingSchedule {
	id: string = null;
	config: AddingScheduleItem = null;
	dates: AddingScheduleItemDate[] = [];
}

export class AddingScheduleItemDate {
	from: Date = null;
	to: Date = null;
	hidden = false;
	name: string = null;
}

export interface AddingScheduleRawConfig {
	isSplitted: boolean;
	schedule: any;
	scheduledDates: Date[];
}

export class Schedule {
	id: SchemeID = null;
	hidden = false;
	hasInUseTickets = false;
	scheduleItems: ScheduleItem[] = [];
	deleted = false;
}

export class ScheduleItem {
	id: SchemeID = null;
	name: string = null;
	startDate: Date = null;
	endDate: Date = null;
	hidden = false;
	hasInUseTickets = false;
	deleted = false;
	status?: number;
}
