<div
	class="l-container"
	[class.l-container-mobile]='data.isMobile'
>
	<h3 class="mb-3">Delete My Account</h3>
	<div class="mb-4">
		<p>
			Please note your account and all its associated user data will be
			<strong>permanently deleted</strong> and <strong>rendered unrecoverable</strong>. This includes:
		</p>
		<ul>
			<li>User account</li>
			<li>Purchases</li>
			<li>Ticket holder information</li>
			<li>Event reminders</li>
			<li>
				Access to tickets and invoices from previous and upcoming events
			</li>
		</ul>
	</div>
	<form #deleteAccountForm="ngForm">
		<div class="input-group-web mb-3 w-100">
			<label class="g-form-label">Password</label>
			<input
				id='password-input'
				#userPassword="ngModel"
				[(ngModel)]="password"
				type="password"
				name="Password"
				class="form-control"
				placeholder="******"
				required
			/>
			<div
				*ngIf="errorMessage"
				class="g-error-input ms-1"
			>{{errorMessage}}</div>
		</div>
		<div class="l-checkbox-container">
			<mat-checkbox
				id='terms-checkbox'
				#confirm='ngModel'
				name='confirm'
				[(ngModel)]='confirmation'
				required
			></mat-checkbox>
			<p class="m-0">
				I understand my user account and all associated data will be deleted and
				that this can not be reversed.
			</p>
		</div>
	</form>
	<mat-divider class="w-100 my-4"></mat-divider>
	<p class="g-f-14 mb-3">
		If you would rather just unsubscribe from Quicket emails, then please
		<strong><u
				id='navigate-email'
				class="g-pointer g-green"
				(click)="handleEmailNavigation()"
			>click here</u></strong>.
	</p>

	<div [ngClass]="{ 'l-buttons-container-web':!data.isMobile, 'l-buttons-container-mobile': data.isMobile}">
		<button
			id='navigate-back'
			class="g-secondary-button"
			(click)="handleCancel()"
			[disabled]="isLoading"
			[ngClass]="{ 'g-disabled': isLoading, 'w-100 mb-2': data.isMobile}"
		>CANCEL</button>
		<button
			id='delete-account'
			class="g-primary-button"
			[class.w-100]="data.isMobile"
			(click)="handleDelete()"
			[disabled]="deleteAccountForm.invalid || isLoading"
			[ngClass]="{ disabled: deleteAccountForm.invalid || isLoading}"
		>
			<div *ngIf='!isLoading; else showLoading'>DELETE ACCOUNT</div>
			<ng-template #showLoading><mat-spinner class='g-button-spinner'></mat-spinner></ng-template>
		</button>
	</div>
</div>