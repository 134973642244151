import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { EventSideNavItems } from '@app/models/shared';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { StoreService } from '@app/services/store/store.service';
import { getCurrentPage, urlSegmentToHeading } from '@app/utils/common-helpers';
import {  EVENT_NAV } from '@app/utils/consts';
import { Dictionary } from '@ngrx/entity';
import { filter, take, takeUntil } from 'rxjs';
import * as eventSelectors from '@app/store/selectors/event.selector';
import { PermissionService } from '@app/services/permission/permission.service';
import { ProductCreationStep, UserEventAccess } from '@app/models/event.model';
import { EventMetadata } from '@app/models/event-metadata.model';
import { Go } from '@app/store/actions/router/router.actions';
import { InternalURLCreator } from '@app/services/url/url.dictionary';

const EVENT_ID_PARAM_KEY = 'id';

@Component({
	selector: 'app-event-navigation',
	templateUrl: './event-navigation.component.html',
	styleUrls: ['./event-navigation.component.sass'],
})
export class EventNavigationComponent implements OnInit {
	isMobile = false;
	isLoading = true;
	isPrimaryMobileHeader = true;
	eventId: number;
	currentNavItem: string;
	navItems: Dictionary<EventSideNavItems> = EVENT_NAV;
	showPrimaryHeader = true;
	currentPageHeading: string;
	currentSubNavItem: EventSideNavItems;
	showTabs = false;
	contentClass: string;
	isOngoing = false;
	metadata: EventMetadata;

	userAccess: UserEventAccess;
	permissions: { title: string; access: string }[] = [];

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private breakpointService: BreakpointService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private store: StoreService,
		private permissionService: PermissionService
	) { }

	ngOnInit(): void {
		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
			this.updateContentClass();
		});

		this.activatedRoute.paramMap.pipe(takeUntil(this.destroyed$)).subscribe((params) => {
			this.eventId = +params.get(EVENT_ID_PARAM_KEY);
			this.getEventInfo();
		});

		this.setCurrentNavItem(this.router.url);
		this.router.events.pipe(
			filter(event => event instanceof NavigationEnd),
			takeUntil(this.destroyed$)
		).subscribe((event: NavigationEnd) => {
			this.setCurrentNavItem(event.urlAfterRedirects);
		});
	}

	private setCurrentNavItem(url: string): void {
		this.isLoading = true;
		this.checkUserPermissions();
		const currentPage = getCurrentPage(url);
		this.currentPageHeading = urlSegmentToHeading(currentPage);
		for (const key in this.navItems) {
			if (this.navItems.hasOwnProperty(key)) {
				const navItem = this.navItems[key];
				if (navItem.children.includes(currentPage)) {
					this.currentNavItem = key;
					this.currentSubNavItem = navItem;
					this.showTabs = navItem.primaryPageTabs && navItem.primaryPages.includes(currentPage);
					if (this.isMobile) {
						this.showPrimaryHeader = navItem.primaryPages.includes(currentPage);
					}
					this.updateContentClass();
					break;
				}
			}
		}
	}

	getEventInfo(): void {
		this.store.select(eventSelectors.eventMetadata(this.eventId)).pipe(takeUntil(this.destroyed$))
			.subscribe((eventMetaData) => {
				if (eventMetaData) {
					if (eventMetaData.creationStep === ProductCreationStep.SuccessfullyCreated) {
						this.metadata = eventMetaData;
						this.permissions = this.permissionService.getAllUserPermissions(eventMetaData.permissions);
						this.isOngoing = eventMetaData.isOngoing;
					} else {
						this.store.dispatch(new Go({ path: [InternalURLCreator.createEvent()] }));
					}
				}
			});
	}

	checkUserPermissions() {
		this.permissionService.getUserAccess().pipe(take(1)).subscribe((access) => {
			this.userAccess = access;
			this.isLoading = false;
		});
	}

	updateContentClass() {
		this.contentClass = this.isMobile
			? (this.showTabs
				? 'l-mobile-tabbed-content'
				: (this.showPrimaryHeader ? 'l-mobile-primary-content' : 'l-mobile-secondary-content'))
			: (this.showTabs ? 'l-web-tabbed-content' : 'l-web-content');
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}

}
