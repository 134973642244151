<app-my-account-header
	[isMobile]='isMobile'
	pageName="API Key"
	(navigateBack)="handleNavigationBack()"
>
</app-my-account-header>
<div class="g-account-container">
	<div
		class="g-account-content h-100"
		[ngClass]="{ 'mobile': isMobile, 'web': !isMobile }"
	>
		<app-web-content-header
			*ngIf="!isMobile"
			class="w-100"
			[headerContent]="headerContent"
		></app-web-content-header>
		<div
			class="l-description"
			[ngClass]="{ 'mt-3': isMobile, 'mb-3': !isMobile }"
		>
			<p>
				In order to access the Quicket API you will need an API subscriber key. To get a subscriber key, you
				must first
				register on the Quicket Developer Portal at
				<strong><u
						class="g-pointer g-highlight"
						id='navigate-developer-portal'
						(click)="handleDeveloperPortal()"
					>https://developer.quicket.co.za/</u></strong>.
			</p>
		</div>

		<app-link-card
			[cardText]="'For any authenticated calls you will need to add a `usertoken` header below.'"
			[primaryButtonText]="'GENERATE NEW TOKEN'"
			[link]="apiKey"
			(primaryButtonClick)="handleGenerateToken()"
			[isMobile]="isMobile"
			[icon]="'vpn_key'"
			[boxShadow]="!isMobile"
		>
		</app-link-card>

		<div
			class="l-disclaimer"
		>
			Please note that the Quicket API is in Beta and is subject to change. If you have any queries or suggestions
			about
			the API, please <strong><u
					class="g-pointer g-highlight"
					id='navigate-contact-us'
					(click)="handleContactUs()"
				>contact us</u></strong>.
		</div>
	</div>
</div>