import { ActionReducerMap, combineReducers } from '@ngrx/store';
import { routerReducer, RouterStateSerializer } from '@ngrx/router-store';
import { InjectionToken, Provider, Injectable } from '@angular/core';
import { initialState as userInitials, userReducer } from './user/user.reducer';
import { eventReducer } from './event/event.reducer';
import { organiserHubReducer } from './organiser/organiser.reducer';
import { initialState as eventInitials } from './event/initialState';
import { initialState as organiserInitials } from './organiser/initialState';
import { RouterStateSnapshot } from '@angular/router';
import { errorHandlerReducer, initialState as errorHandlerInitials } from './errorHandler.reducer';
import { notificationReducer, initialState as notificationInitials } from './notificatio.reducer';
import { organiserReducer, initialState as organiserInitialState } from './organiserProfile.reducer';
import { userChartsReducer, initialState as userChartsInitials } from './user/userCharts.reducer';
import { AppState, RouterStateUrl } from '@app/models/store.model';
import { userContactsReducer, initialState as userContactsState } from './user/userContacts.reducer';
import { userBankAccountsReducer, initialState as userBankAccountsInitials } from './user/userBankAccounts.reducer';

export const initialState: AppState = {
	events: eventInitials,
	organiserProfiles: organiserInitialState,
	user: userInitials,
	errorHandler: errorHandlerInitials,
	notification: notificationInitials,
	userCharts: userChartsInitials,
	userContacts: userContactsState,
	userBankAccounts: userBankAccountsInitials,
	organiser: organiserInitials,
};

export const getReducers = () => ({
	user: userReducer,
	events: combineReducers(eventReducer),
	organiserProfiles: organiserReducer,
	router: routerReducer,
	errorHandler: errorHandlerReducer,
	notification: notificationReducer,
	userCharts: userChartsReducer,
	userContacts: userContactsReducer,
	userBankAccounts: userBankAccountsReducer,
	organiser: combineReducers(organiserHubReducer),
});

export const reducersToken = new InjectionToken<ActionReducerMap<any>>('Reducers');

export const reducersProvider: Provider[] = [{
	provide: reducersToken,
	useFactory: getReducers,
}];

@Injectable()
export class CustomSerializer implements RouterStateSerializer<RouterStateUrl> {
	serialize(routerState: RouterStateSnapshot): RouterStateUrl {
		let route = routerState.root;

		while (route.firstChild) {
			route = route.firstChild;
		}

		const { url, root: { queryParams } } = routerState;
		const { params } = route;

		return { url, params, queryParams };
	}
}
