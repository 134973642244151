import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { EventSharedModule } from '@app/features/event-shared/event-shared.module';
import { EventWidgetComponent } from './widget.component';
import { EffectsModule } from '@ngrx/effects';
import { WidgetEffects } from './widget.effects';
import { WidgetService } from './widget.service';


@NgModule({
	imports: [
		SharedModule,
		EventSharedModule,
		EffectsModule.forFeature([WidgetEffects]),
	],
	declarations: [
		EventWidgetComponent,
	],
	exports: [
		EventWidgetComponent,
	],
	providers: [
		WidgetService,
	],
}) export class EventWidgetModule {}
