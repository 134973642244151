<div [ngClass]="{'l-mobile-container': isMobile, 'l-web-container': !isMobile}">
    <form *ngIf='hasSettings' class="g-form-container" #registrationForm="ngForm">
        <div class="w-100 l-form-container"
            [ngClass]="{'d-flex justify-content-center': isMobile, 'me-auto': !isMobile}">
            <div [ngClass]="{ 'w-100 l-mobile-width': isMobile }" class="l-toggle-container my-4">
                <div class="l-row-item">
                    <div>Registrations Enabled</div>
                    <mat-slide-toggle id='registrations-enabled-toggle' 
                        [(ngModel)]="tempSettings.enabled" 
                        (ngModelChange)="handleEnabledChange($event)"
                        name="registrationEnabled" 
                        class="standard-toggle px-4"
                        [checked]="tempSettings.enabled"
                    ></mat-slide-toggle>
                </div>
                <div *ngIf="tempSettings.enabled" class="l-row-item">
                    <div> Automatic Approval </div>
                    <mat-slide-toggle 
                        id='automatic-approval-enabled-toggle' 
                        (change)="handleApprovalTypeChange()"
                        [(ngModel)]="isApprovalAutomatic" 
                        name="registrationApproval"
                        class="standard-toggle px-4"></mat-slide-toggle>
                </div>
                <div *ngIf="tempSettings.enabled">
                    <div [ngClass]="{'l-calendar-row' : !isMobile, 'l-calendar-mobile': isMobile}">
                        <div [ngClass]="{'l-calendar-container': !isMobile, 'l-calendar-mobile-container': isMobile}">
                            <label class="g-form-label l-date-label">Date From</label>
                            <div div class='l-date-container'>
                                <mat-icon class='l-icon'>calendar_today</mat-icon>
                                <input [ngxMatDatetimePicker]="dateFromPicker" #from="ngModel" (ngModelChange)="handleDateTimeChange()" [(ngModel)]="tempSettings.dateFrom" [ngModelOptions]="{standalone: true}"
                                [disabled]="false" (click)="dateFromPicker.open()" class="form-control w-100 l-date-input" required readonly [max]="tempSettings.dateTo"
                                (ngModelChange)="handleTimeValidation()" id='date-from-input'>
                                <ngx-mat-datetime-picker id='date-from-datetime-picker' #dateFromPicker [showSeconds]="false"
                                    [touchUi]="isMobile" [enableMeridian]="false"
                                    [disableMinute]="false" [hideTime]="false" [matDatepickerPosition]="'below'" [showSpinners]="false">
									<ngx-mat-datepicker-actions>
										<button
											id="close-datepicker"
											class="g-secondary-button g-datepicker-button"
											ngxMatDatepickerCancel
										>CANCEL</button>
										<button
											id="apply-datepicker"
											class="g-primary-button g-datepicker-button"
											ngxMatDatepickerApply
										>APPLY</button>
									</ngx-mat-datepicker-actions>
                                </ngx-mat-datetime-picker>
                            </div>
                        </div>
                        <div [ngClass]="{'l-calendar-container': !isMobile, 'l-calendar-mobile-container': isMobile}">
                            <label class="g-form-label l-date-label">Date To</label>
                            <div div class='l-date-container'>
                                <mat-icon class='l-icon'>calendar_today</mat-icon>
                                <input [ngxMatDatetimePicker]="dateToPicker" #to="ngModel" (ngModelChange)="handleDateTimeChange()" [(ngModel)]="tempSettings.dateTo" [ngModelOptions]="{standalone: true}"
                                [disabled]="false" (click)="dateToPicker.open()" class="form-control w-100 l-date-input" required readonly [min]="tempSettings.dateFrom"
                                (ngModelChange)="handleTimeValidation()" id='date-to-input'>
                                <ngx-mat-datetime-picker id='date-to-datetime-picker' #dateToPicker [showSeconds]="false"
                                    [touchUi]="isMobile" [enableMeridian]="false"
                                    [disableMinute]="false" [hideTime]="false" [matDatepickerPosition]="'below'" [showSpinners]="false">
									<ngx-mat-datepicker-actions>
										<button
											id="close-datepicker"
											class="g-secondary-button g-datepicker-button"
											ngxMatDatepickerCancel
										>CANCEL</button>
										<button
											id="apply-datepicker"
											class="g-primary-button g-datepicker-button"
											ngxMatDatepickerApply
										>APPLY</button>
									</ngx-mat-datepicker-actions>
                                </ngx-mat-datetime-picker>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="l-row-item">
                    <div>Check For Registration On Transfer</div>
                    <mat-slide-toggle id='registration-on-transfer-enabled-toggle' [(ngModel)]="tempSettings.checkOnTransfer" (ngModelChange)="handleFormChange()"
                        name="checkOnTransfer" class="standard-toggle px-4"
                        [checked]="tempSettings.checkOnTransfer"></mat-slide-toggle>
                </div>
                <div class="l-row-item">
                    <div>Force Registration For All Ticket Holders</div>
                    <mat-slide-toggle id='force-registration-enabled-toggle' [(ngModel)]="tempSettings.forceRegistration" (ngModelChange)="handleFormChange()"
                        name="forceRegistration" class="standard-toggle px-4"
                        [checked]="tempSettings.forceRegistration"></mat-slide-toggle>
                </div>
                <div class="l-row-item">
                    <div>Send Email On Approval</div>
                    <mat-slide-toggle id='send-email-enabled-toggle' [(ngModel)]="tempSettings.sendEmailOnApproval" (ngModelChange)="handleFormChange()"
                        name="sendEmailOnApproval" class="standard-toggle px-4"
                        [checked]="tempSettings.sendEmailOnApproval"></mat-slide-toggle>
                </div>
            </div>
            <div class="l-form-text-container">
                <label class="l-form-label">Description</label>
                <textarea id="description-text-area-input" [(ngModel)]="tempSettings.description" name="description" (ngModelChange)="handleFormChange()"
                    class="form-control mh-100 l-textarea" maxlength="500"></textarea>
                <label class="l-form-label">Registration Pending Approval Message</label>
                <textarea id="pending-text-area-input" [(ngModel)]="tempSettings.pendingMessage" (ngModelChange)="handleFormChange()"
                    name=pendingApproval class="form-control mh-100 l-textarea" maxlength="500"></textarea>
                <label class="l-form-label">Registration Approved Message</label>
                <textarea id="approval-text-area-input" [(ngModel)]="tempSettings.approvalMessage" name="approval" (ngModelChange)="handleFormChange()"
                    class="form-control mh-100 l-textarea" maxlength="500"></textarea>
                <label class="l-form-label">Registration Denied Message</label>
                <textarea id="denied-text-area-input" [(ngModel)]="tempSettings.deniedMessage" name="denied" (ngModelChange)="handleFormChange()"
                    class="form-control mh-100 l-textarea" maxlength="500"></textarea>
            </div>
        </div>
    </form>
	<div class="l-buttons-container">
		<button
			class="g-primary-button l-button"
			[ngClass]="{ disabled: (isPageLoading || !isTimeValid || isFormPristine) }"
			[disabled]="(isPageLoading || !isTimeValid || isFormPristine)"
			(click)="handleSaveChanges()"
			> SAVE CHANGES
		</button>
		<button
			class="g-secondary-button l-button"
			(click)="handleEditRegistrationForm()"
			> SETUP REGISTRATION FORM
		</button>
	</div>
</div>
<div *ngIf="!isPageLoading">
	<app-registrations-table [isMobile]="isMobile" [eventId]="eventId"></app-registrations-table>
</div>
