import { Component, OnChanges, SimpleChanges } from '@angular/core';
import { MultiChoiceBaseControlComponent } from './../multi-choice-base-control/multi-choice-base-control.component';
import { FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs';

@Component({
	selector: 'app-check-box-field',
	templateUrl: './check-box-field.component.html',
	styleUrls: ['./check-box-field.component.sass'],
})
export class CheckBoxFieldComponent extends MultiChoiceBaseControlComponent implements OnChanges {
	displayGroup = new FormGroup({});

	ngOnInit(): void {
		super.ngOnInit();
		this.otherOptionControl.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
			this.updateInputFormControlErrors();
		});

		const currentValue = this.inputFormControl.value;
		const currentValueArray = currentValue ? currentValue.split(',').map(answer => answer.trim()) : [];

		for (const option of this.options) {
			this.displayGroup.addControl(option, this.fb.control({
				value: currentValueArray.includes(option),
				disabled: this.isDisabled,
			}));
		}
		this.handleOtherEnabled();
		if (this.otherEnabled) {
			this.updateValidators();
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (!changes.inputFormControl || changes.inputFormControl.firstChange) {
			return;
		}

		const { currentValue, previousValue } = changes.inputFormControl;

		if (currentValue.value === previousValue.value) {
			return;
		}

		const currentValueArray = currentValue.value
			? currentValue.value
				.split(',')
				.map((answer: string) => answer.trim())
				.filter((answer: string) => answer !== '')
			: [];

		this.options.forEach((option: string) => {
			this.displayGroup.controls[option].setValue(currentValueArray.includes(option));
		});

		if (this.control.addOtherOption) {
			const otherValues = currentValueArray.filter((value: string) => !this.options.includes(value));
			this.otherEnabled = otherValues.length > 0;
			this.updateValidators();
			this.otherOptionControl.setValue(this.otherEnabled ? otherValues[0] : null);
		}

		if (!currentValueArray.length) {
			this.options.forEach((option: string) => {
				this.displayGroup.controls[option].setValue(false);
			});
			this.otherEnabled = false;
			this.updateValidators();
			this.otherOptionControl.setValue(null);
		}

		this.inputFormControl.updateValueAndValidity();
	}


	onCheckboxChange() {
		if (!this.otherEnabled && this.otherAnswer.length > 0) {
			this.removeOtherAnswer();
		} else {
			this.updateValidators();
		}
		this.inputFormControl.markAsDirty();
	}

	handleOtherOptionChange(value: string) {
		const newValue = value.replace(',', '_');
		if (!this.options.includes(value)) {
			this.inputFormControl.updateValueAndValidity();
			this.updateInput(newValue);
			this.inputFormControl.markAsDirty();
		}
	}

	handleOptionChange(option: string) {
		const currentValue = this.inputFormControl.value || '';
		const currentValueArray = currentValue ? currentValue.split(',').map(answer => answer.trim()) : [];

		if (currentValueArray.includes(option)) {
			this.inputFormControl.setValue(currentValueArray.filter(value => value !== option).join(', '));
		} else {
			currentValueArray.push(option);
			this.inputFormControl.setValue(currentValueArray.join(', '));
		}
		this.inputFormControl.updateValueAndValidity();
		this.inputFormControl.markAsDirty();
	}

	updateInput(value: string) {
		const answers = this.inputFormControl.value.split(',').map(item => item.trim());
		const otherOptionIndex = this.getOtherOptionIndex();

		if (otherOptionIndex === -1 && value !== '') {
			answers.push(value);
		} else if (otherOptionIndex >= 0) {
			answers[otherOptionIndex] = value;
		}

		const newControl = {
			...this.control,
			answer: answers.toString(),
		};

		this.control = newControl;
		this.otherAnswer = value;
		this.otherOptionControl.setValue(value);
		this.inputFormControl.setValue(answers.toString());

		this.updateValidators();
		this.updateInputFormControlErrors();
	}

	getOtherOptionIndex() {
		const otherAnswerIndex = this.control.answer.split(',').map(item => item.trim()).indexOf(this.otherAnswer.trim());
		return otherAnswerIndex;
	}

	removeOtherAnswer() {
		const answers = this.inputFormControl.value.split(',').map(
			item => item.trim()
		).filter(
			item => this.options.includes(item)
		).toString();

		this.inputFormControl.setValue(answers);
	}
}
