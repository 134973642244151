import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { ContactsSource } from '@app/models/user.model';
import { StoreService } from '@app/services/store/store.service';
import { Actions, ofType } from '@ngrx/effects';
import { EventActionsConstants } from '@app/store/actions/event/event.actions.constants';
import { takeUntil } from 'rxjs';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { emailValidator, notEmptyArray, requiredValidator } from '@app/shared/form-field/form-validators';
import { CreateContactList } from '@app/store/actions/user/user.actions';
import { UserActionsConstants } from '@app/store/actions/user/user.actions.constants';

@Component({
	selector: 'app-add-contacts-manually',
	templateUrl: './add-contacts-manually.component.html',
	styleUrls: ['./add-contacts-manually.component.sass'],
})
export class AddContactsManuallyComponent implements OnInit {
	contactListName: string;
	manualContactsForm: UntypedFormArray;
	isButtonLoading = false;
	currentDate: Date = new Date();
	consentChecked = false;
	displayColumns: string[] = ['name', 'surname', 'email', 'delete'];

	@Input() eventId: number;
	@Input() isMobile = false;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	@ViewChild('manualContacts') table: MatTable<UntypedFormArray>;

	constructor(
		private datePipe: DatePipe,
		private actions$: Actions,
		private store: StoreService
	) {}

	ngOnInit() {
		this.contactListName = `Manual - ${this.datePipe.transform(this.currentDate, 'dd-MMM-YY')}`;
		this.manualContactsForm = this.createManualContactsForm();
	}

	addContactRow() {
		this.manualContactsForm.push(this.createManualContactFormItem());
		this.table.renderRows();
	}

	deleteContactRow(index) {
		if (this.manualContactsForm.length > 1) {
			this.manualContactsForm.removeAt(index);
			this.table.renderRows();
		}
	}

	isNullOrEmpty(str: string): boolean {
		return !str || str.trim() === '';
	}

	fromForm(formControl: UntypedFormControl, formControlName: string) {
		if (this.manualContactsForm) {
			return formControl.get(formControlName);
		}
		return null;
	}

	handleSendInvites() {
		this.isButtonLoading = true;
		this.actions$
			.pipe(
				ofType(EventActionsConstants.SEND_INVITES_SUCCESS,
					EventActionsConstants.SEND_INVITES_FAILED,
					UserActionsConstants.CREATE_CONTACT_LIST_FAILED
				),
				takeUntil(this.destroyed$)
			).subscribe(() => {
				this.isButtonLoading = false;
				this.manualContactsForm = null;
				this.manualContactsForm = this.createManualContactsForm();
			});

		this.store.dispatch(
			new CreateContactList({
				name: this.contactListName,
				eventId: this.eventId,
				autoSendInvites: true,
				source: ContactsSource.Manual,
				contacts: this.manualContactsForm.getRawValue(),
			})
		);
	}

	createManualContactFormItem() {
		const formBuilder = new UntypedFormBuilder();
		const contactItem = {
			email: [null, [requiredValidator(), emailValidator()]],
			name: null,
			surname: null,
		};

		return formBuilder.group(contactItem);
	}

	createManualContactsForm() {
		const formBuilder = new UntypedFormBuilder();
		return formBuilder.array([
			this.createManualContactFormItem(),
		], [notEmptyArray()]);
	}
}
