import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
	selector: 'app-link-card',
	templateUrl: './link-card.component.html',
	styleUrls: ['./link-card.component.sass'],
})
export class LinkCardComponent implements OnInit, OnChanges {
	@Input() cardText: string;
	@Input() primaryButtonText: string;
	@Input() link: string;
	@Input() isMobile = false;
	@Input() icon: string;
	@Input() canEdit = false;
	@Input() preText: string;
	@Input() isLoading: boolean;
	@Input() boxShadow = true;
	@Input() maxLengthLink: number;

	@Output() primaryButtonClick: EventEmitter<string> = new EventEmitter();
	@Output() secondaryButtonClick: EventEmitter<string> = new EventEmitter();

	isEditing = false;
	newLink: string;
	currentlySavedLink: string;

	get isButtonDisabled(): boolean {
		return (!this.newLink || this.isLoading || (this.newLink === this.link && this.isEditing));
	}

	constructor() { }

	ngOnInit(): void {
		this.newLink = this.link;
		this.currentlySavedLink = this.link;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.link) {
			this.newLink = changes.link.currentValue;
		}
	}

	handleAttemptedSave(success: boolean) {
		if (success) {
			this.currentlySavedLink = this.newLink;
		} else {
			this.newLink = this.currentlySavedLink;
		}
	}

	handlePrimaryClick() {
		if (!this.canEdit) {
			this.primaryButtonClick.emit();
		} else {
			this.primaryButtonClick.emit(this.newLink);
		}
	}

	handleSecondaryClick() {
		this.secondaryButtonClick.emit(this.newLink);
		this.isEditing = !this.isEditing;
	}

	handleToggleEdit() {
		if (!this.isLoading) {
			if (this.isEditing) {
				this.newLink = this.link;
			}
			this.isEditing = !this.isEditing;
		}
	}
}
