<div
	class="l-container"
>
	<div *ngIf="!isMobile; else mobileHeader" class="w-100">
		<app-header></app-header>
	</div>
	<ng-template #mobileHeader>
	  <app-header-mobile id='navigate-back-join-network' class="w-100" ($navigateBack)="handleNavigateBack()"
		[pageName]="'Join Promoter Network'"></app-header-mobile>
	</ng-template>
	<div class="l-content">
		<app-web-content-header 
			*ngIf="!isMobile"
			class="l-breadcrumbs mt-4"
			[headerContent]="headerContent"
		>
		</app-web-content-header>
		<app-iframe
			class="g-iframe"
			[url]='url'
		></app-iframe>
	</div>
</div>