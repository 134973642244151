import { Injectable } from '@angular/core';
import { EventDetails, Venue, EventDatesChange, CovidComplianceEnabled } from '@app/models/event.model';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { FormService } from '@app/services/form/form.service';
import {
	requiredValidator,
	isDateBefore,
	isDateAfter,
	conditionalPipe,
	maxLength,
	scheduleHasValid
} from '@app/shared/form-field/form-validators';
import { DatesService } from '../dates/dates.service';
import { ScheduleService } from '../schedule/schedule.service';
import { numberMoreThen, numberLessThen } from '@app/shared/form-field/form-validators';
import { FormValidators, FormSchemeControls } from '@app/models/common.model';
import { ProductType } from '@app/models/product.model';

@Injectable()
export class EventDetailsService {
	constructor(
		private formService: FormService,
		private datesService: DatesService,
		private scheduleService: ScheduleService
	) { }

	createEventDetailsForm(initialValues: Partial<EventDetails>) {
		const formValues = new EventDetails();
		const formBuilder = new UntypedFormBuilder();

		const validatorsConfig: FormValidators<Partial<EventDetails>> = {
			isPublic: [requiredValidator()],
		};

		const formConfig: Partial<FormSchemeControls<EventDetails>> =
			this.formService
				.createFormControls({
					formValues,
					initialValues,
					validatorsConfig,
				}, ['venue']);

		(formConfig.schedules) = formBuilder.array([]);

		if (initialValues && initialValues.schedules && initialValues.schedules.length) {
			initialValues.schedules.forEach(sc => {
				(formConfig.schedules as UntypedFormArray).push(this.scheduleService.listScheduleForm(sc.scheduleItems, sc));
			});
		}

		(formConfig.eventFeatureAnswers) = formBuilder.array([]);
		/* eslint-disable max-len */
		if (initialValues && initialValues.eventFeatureOptions && initialValues.eventFeatureOptions.length) {
			initialValues.eventFeatureOptions.forEach(op => {
				(formConfig.eventFeatureAnswers as UntypedFormArray).push(this.formService.formBuilder.group({id: op.id, answer: op.answer}));
			});
		}

		const dynamicValidators = {
			endDateTime: [
				conditionalPipe(((fC) => fC.isOneDayEvent.value && !fC.isEventFinished.value && fC.indefinite.value === false).bind(null, formConfig),
					requiredValidator('Please select a valid end date and time'),
					isDateAfter(formConfig.startDateTime),
					isDateAfter(new UntypedFormControl(this.datesService.getStartOfTheDay()), 'End date can\'t be in the past')
				),
			],
			name: [
				conditionalPipe(((fC) => fC.productType.value === ProductType.Fundraiser).bind(null, formConfig), requiredValidator(), maxLength(50)
				),
				conditionalPipe(((fC) => fC.productType.value === ProductType.Event).bind(null, formConfig), requiredValidator(), maxLength(150)
				),
			],
			startDateTime: [
				conditionalPipe(((fC) => fC.isOneDayEvent.value && fC.productType.value === ProductType.Event).bind(null, formConfig),
					requiredValidator('Please select a valid start date and time'),
					isDateBefore(formConfig.endDateTime)
				),
				conditionalPipe((fC => fC.isOneDayEvent.value && !fC.isEventFinished.value).bind(null, formConfig)
				),
			],
			schedules: [
				conditionalPipe(((fC) => !fC.isOneDayEvent.value && !fC.schedules.value.length).bind(null, formConfig),
					requiredValidator()
				),
				conditionalPipe(((fC) => !fC.isOneDayEvent.value && fC.schedules.value.length).bind(null, formConfig),
					scheduleHasValid()
				),
			],
			venue: {
				name: [
					conditionalPipe((() => !formConfig.isOnline.value && formConfig.productType.value === ProductType.Event).bind(null, formConfig), requiredValidator()),
				],
				longitude: [
					numberMoreThen(-180, 'Should be greater or equal to -180', true),
					numberLessThen(180, 'Should be less or equal to 180', true),
				],
				latitude: [
					numberMoreThen(-90, 'Should be greater or equal to -90', true),
					numberLessThen(90, 'Should be less or equal to 90', true),
				],
			},
			hasOnlineContent: [
				conditionalPipe(((fC) => fC.productType.value === ProductType.Event).bind(null, formConfig), requiredValidator()),
			],
			description: [
				conditionalPipe(((fC) => fC.productType.value === ProductType.Event || fC.productType.value === ProductType.Fundraiser).bind(null, formConfig), requiredValidator()),
			],
			/* eslint-enable max-len */

		};

		this.formService.setDynamicValidators(formConfig, dynamicValidators);

		const startControl = formConfig['startDateTime'];
		if ((startControl as UntypedFormControl).value !== formValues.startDateTime) {
			(startControl as UntypedFormControl).markAsDirty();
		}

		const endControl = formConfig['endDateTime'];
		if ((endControl as UntypedFormControl).value !== formValues.endDateTime) {
			(endControl as UntypedFormControl).markAsDirty();
		}

		return {
			form: formBuilder.group(formConfig),
			dynamicValidatorsKeys: Object.keys(dynamicValidators).map(key => Array.isArray(dynamicValidators[key]) ? key : {
				[key]: Object.keys(dynamicValidators[key]).map(k => k),
			}),
		};
	}

	checkAndUpdateVenueId(savedVenue: Venue, form: UntypedFormGroup, pathToVenueId = 'venue.id') {
		if (savedVenue && savedVenue.id !== form.get(pathToVenueId).value) {
			form.get(pathToVenueId).setValue(savedVenue.id);
		}
	}

	createEventDatesChangeForm(initialValues?: Partial<EventDatesChange>) {
		const formValues = new EventDatesChange();
		const formBuilder = new UntypedFormBuilder();

		const formConfig: Partial<FormSchemeControls<EventDatesChange>> =
			this.formService
				.createFormControls({
					formValues,
					initialValues,
					validatorsConfig: {},
				});

		const dynamicValidators = {
			endDateTime: [
				requiredValidator('Please select end date and time'),
				isDateAfter(formConfig.startDateTime),
				isDateAfter(new UntypedFormControl(this.datesService.getStartOfTheDay()), 'End date can\'t be in the past'),
			],
			startDateTime: [
				requiredValidator('Please select start date and time'),
				isDateBefore(formConfig.endDateTime),
			],
		};

		for (const key in dynamicValidators) {
			if (dynamicValidators.hasOwnProperty(key)) {
				formConfig[key].setValidators(dynamicValidators[key]);
			}
		}

		return formBuilder.group(formConfig);
	}

	createCovidComplianceForm(initialValues?: Partial<CovidComplianceEnabled>) {
		const formValues = new CovidComplianceEnabled();
		const formBuilder = new UntypedFormBuilder();

		const validatorsConfig: FormValidators<Partial<CovidComplianceEnabled>> = {
			hasEntryRequirements: [requiredValidator()],
		};

		const formConfig: Partial<FormSchemeControls<CovidComplianceEnabled>> = this.formService.createFormControls({
			formValues,
			initialValues,
			validatorsConfig,
		});

		return formBuilder.group(formConfig);
	}
}
