import { Component, Input } from '@angular/core';
import { UserSavedEvent } from '@app/models/user.model';

@Component({
	selector: 'app-event-card',
	templateUrl: './event-card.component.html',
	styleUrls: ['./event-card.component.sass'],
})
export class EventCardComponent {
	@Input() isMobile = false;
	@Input() data: UserSavedEvent;
}
