import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { EventDomainAPI } from '@app/api/domains/event';
import { ofTypeExt, handleSuccessRequest, handleFailedRequest } from '@app/utils/Store';
import {
	GetEventCollections,
	CollectionsActionConstants,
	GetEventCollectionsSuccess,
	GetEventCollectionsFailed
} from './event-collections.actions';
import { catchError, switchMap } from 'rxjs/operators';
import { Go } from '@app/store/actions/router/router.actions';
import { defaultDashboardRoute } from '@app/services/url/url.service';

@Injectable()
export class CollectionsEffects {
	constructor(private action$: Actions, private api: EventDomainAPI) {}

	getEventCollections$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<GetEventCollections>(CollectionsActionConstants.GET_EVENT_COLLECTIONS),
			switchMap(({ action, payload: { id } }) =>
				this.api.getEventCollections(id).pipe(
					handleSuccessRequest(
						({ payload: collections }) =>
							new GetEventCollectionsSuccess({
								id,
								collections,
							})
					),
					catchError(
						handleFailedRequest(
							new GetEventCollectionsFailed({
								msg: 'can\'t get event collection details',
								action,
								critical: true,
								meta: {
									extraAction: new Go({
										path: [defaultDashboardRoute],
									}),
									extraActionTitle: 'Go to dashboard',
									allowClose: false,
								},
							})
						)
					)
				)
			)
		)
	);
}
