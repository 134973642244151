import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EventDashboard, ProductStatus } from '@app/models/event.model';
import { ScheduleToGoPublicModalComponent } from '../../modals/schedule-to-go-public-modal/schedule-to-go-public-modal.component';
import { URLCreator } from '@app/services/url/url.dictionary';
import { RedirectFinishedEventModalComponent } from '../../modals/redirect-finished-event-modal/redirect-finished-event-modal.component';

@Component({
	selector: 'app-dashboard-info-buttons',
	templateUrl: './dashboard-info-buttons.component.html',
	styleUrls: ['./dashboard-info-buttons.component.sass'],
})
export class DashboardInfoButtonsComponent {
	@Input() dashboard: EventDashboard;
	@Input() eventId: number;
	@Input() isLoading: true;
	@Input() isMobile: false;

	@Output() toggleEventVisibility = new EventEmitter();
	@Output() toggleSheduleToGoPublic = new EventEmitter<Date>();
	@Output() redirectEvent = new EventEmitter<number>();

	displayRedirectButton = false;

	get statusButton() {
		return this.getStatusButton();
	}

	constructor(
		private dialog: MatDialog
	) {}

	ngOnInit() {
		this.displayRedirectButton =
			this.dashboard.productStatus === ProductStatus.Finished || this.dashboard.productStatus === ProductStatus.Cancelled;
	}

	switchEventVisibility() {
		this.toggleEventVisibility.emit();
	}

	scheduleToPublic() {
		const dialogRef = this.dialog.open(ScheduleToGoPublicModalComponent, {
			data: {
				isMobile: this.isMobile,
				scheduledToGoPublicOn: this.dashboard.scheduledToGoPublicOn,
				timeZone: this.dashboard.ianaTimezone,
			},
			panelClass: 'g-standard-dialog',
		});

		dialogRef.afterClosed().subscribe((result) => {
			if (result !== false) {
				this.toggleSheduleToGoPublic.emit(result);
			}
		});
	}

	getPreviewLink() {
		return URLCreator.preview(this.eventId, this.dashboard.name, this.dashboard.productStatus);
	}

	handleRedirectModal() {
		this.dialog.open(RedirectFinishedEventModalComponent, {
			data: {
				events: this.dashboard.userActiveEvents,
				currentlySelected: this.dashboard.redirectProductId,
				isMobile: this.isMobile,
			},
			panelClass: this.isMobile ? 'g-full-page-dialog' : 'g-standard-dialog-height-8',
		})
			.afterClosed().subscribe((productId) => {
				if (productId || productId === 0) {
					this.redirectEvent.emit(productId);
				}
			});
	}

	getStatusButton() {
		switch (this.dashboard.productStatus) {
			case ProductStatus.Draft:
				return {
					text: 'Draft',
					icon: 'edit_square',
					class: 'l-info-button-lightened',
				};
			case ProductStatus.Active:
				return {
					text: 'Published',
					icon: 'done',
					class: 'l-info-button-gray',
				};
			case ProductStatus.Hidden:
				return {
					text: 'Hidden',
					icon: 'hide_image',
					class: 'l-info-button-xxxxx',
				};
			case ProductStatus.Finished:
				return {
					text: 'Finished',
					icon: 'done',
					class: 'l-info-button-gray',
				};
			case ProductStatus.AwaitingActivation:
				return {
					text: 'Activation pending',
					icon: 'update',
					class: 'l-info-button-lightened',
				};
			case ProductStatus.Cancelled:
				return {
					text: 'Cancelled',
					icon: 'cancel',
					class: 'l-info-button-gray',
				};
			default:
				return {
					text: '',
					icon: '',
					class: '',
				};
		}
	}
}
