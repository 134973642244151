export enum UserActionsConstants {
	VERIFY_TOKEN = 'VERIFY_TOKEN',
	VERIFY_TOKEN_SUCCESS = 'VERIFY_TOKEN_SUCCESS',
	VERIFY_TOKEN_FAILED = 'VERIFY_TOKEN_FAILED',

	LOGOUT = 'LOGOUT',
	LOGOUT_FAILED = 'LOGOUT_FAILED',
	LOGOUT_COMPLETE = 'LOGOUT_COMPLETE',

	GET_USER_DESIGNER_KEY = 'GET_USER_DESIGNER_KEY',
	GET_USER_DESIGNER_KEY_SUCCESS = 'GET_USER_DESIGNER_KEY_SUCCESS',
	GET_USER_DESIGNER_KEY_FAILED = 'GET_USER_DESIGNER_KEY_FAILED',

	GET_USER_CHARTS = 'GET_USER_CHARTS',
	GET_USER_CHARTS_SUCCESS = 'GET_USER_CHARTS_SUCCESS',
	GET_USER_CHARTS_FAILED = 'GET_USER_CHARTS_FAILED',

	SET_USER_INFO = 'SET_USER_INFO',
	SET_USER_INFO_SUCCESS = 'SET_USER_INFO_SUCCESS',
	SET_USER_INFO_FAILED = 'SET_USER_INFO_FAILED',

	CREATE_CONTACT_LIST = 'CREATE_CONTACT_LIST',
	CREATE_CONTACT_LIST_SUCCESS = 'CREATE_CONTACT_LIST_SUCCESS',
	CREATE_CONTACT_LIST_FAILED = 'CREATE_CONTACT_LIST_FAILED',

	SET_ACTIVE_CONTACTS_SOURCE = 'SET_ACTIVE_CONTACTS_SOURCE',

	GET_PREVIOUS_CONTACT_LISTS = 'GET_PREVIOUS_CONTACT_LISTS',
	GET_PREVIOUS_CONTACT_LISTS_SUCCESS = 'GET_PREVIOUS_CONTACT_LISTS_SUCCESS',
	GET_PREVIOUS_CONTACT_LISTS_FAILED = 'GET_PREVIOUS_CONTACT_LISTS_FAILED',

	GET_PREVIOUS_EVENTS = 'GET_PREVIOUS_EVENTS',
	GET_PREVIOUS_EVENTS_SUCCESS = 'GET_PREVIOUS_EVENTS_SUCCESS',
	GET_PREVIOUS_EVENTS_FAILED = 'GET_PREVIOUS_EVENTS_FAILED',

	GET_EVENTS_ATTENDEES = 'GET_EVENTS_ATTENDEES',
	GET_EVENTS_ATTENDEES_SUCCESS = 'GET_EVENTS_ATTENDEES_SUCCESS',
	GET_EVENTS_ATTENDEES_FAILED = 'GET_EVENTS_ATTENDEES_FAILED',

	GET_BANK_ACCOUNTS = 'GET_BANK_ACCOUNTS',
	GET_BANK_ACCOUNTS_SUCCESS = 'GET_BANK_ACCOUNTS_SUCCESS',
	GET_BANK_ACCOUNTS_FAILED = 'GET_BANK_ACCOUNTS_FAILED',

	DELETE_BANK_ACCOUNT = 'DELETE_BANK_ACCOUNT',
	DELETE_BANK_ACCOUNT_SUCCESS = 'DELETE_BANK_ACCOUNT_SUCCESS',
	DELETE_BANK_ACCOUNT_FAILED = 'DELETE_BANK_ACCOUNT_FAILED',

	GET_BANKS = 'GET_BANKS',
	GET_BANKS_SUCCESS = 'GET_BANKS_SUCCESS',
	GET_BANKS_FAILED = 'GET_BANKS_FAILED',

	GET_ALL_BANKS = 'GET_ALL_BANKS',
	GET_ALL_BANKS_SUCCESS = 'GET_ALL_BANKS_SUCCESS',
	GET_ALL_BANKS_FAILED = 'GET_ALL_BANKS_FAILED',

	ADD_BANK_ACCOUNT = 'ADD_BANK_ACCOUNT',
	ADD_BANK_ACCOUNT_SUCCESS = 'ADD_BANK_ACCOUNT_SUCCESS',
	ADD_BANK_ACCOUNT_FAILED = 'ADD_BANK_ACCOUNT_FAILED',

	UPDATE_BANK_ACCOUNT = 'UPDATE_BANK_ACCOUNT',
	UPDATE_BANK_ACCOUNT_SUCCESS = 'UPDATE_BANK_ACCOUNT_SUCCESS',
	UPDATE_BANK_ACCOUNT_FAILED = 'UPDATE_BANK_ACCOUNT_FAILED',

	GET_USER_METADATA = 'GET_USER_METADATA',
	GET_USER_METADATA_SUCCESS = 'GET_USER_METADATA_SUCCESS',
	GET_USER_METADATA_FAILED = 'GET_USER_METADATA_FAILED',

	UPDATE_USER_API_TOKEN = 'UPDATE_USER_API_TOKEN',
	UPDATE_USER_API_TOKEN_SUCCESS = 'UPDATE_USER_API_TOKEN_SUCCESS',
	UPDATE_USER_API_TOKEN_FAILED = 'UPDATE_USER_API_TOKEN_FAILED',
	SET_API_MODAL_FLAG = 'SET_API_MODAL_FLAG',

	UPDATE_PASSWORD = 'UPDATE_PASSWORD',
	UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS',
	UPDATE_PASSWORD_FAILED = 'UPDATE_PASSWORD_FAILED',

	GET_USER_INFO = 'GET_USER_INFO',
	GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS',
	GET_USER_INFO_FAILED = 'GET_USER_INFO_FAILED',

	UPDATE_USER_INFO = 'UPDATE_USER_INFO',
	UPDATE_USER_INFO_SUCCESS = 'UPDATE_USER_INFO_SUCCESS',
	UPDATE_USER_INFO_FAILED = 'UPDATE_USER_INFO_FAILED',

	DELETE_USER = 'DELETE_USER',
	DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS',
	DELETE_USER_FAILED = 'DELETE_USER_FAILED',

	GET_EMAIL_PREFERENCES = 'GET_EMAIL_PREFERENCES',
	GET_EMAIL_PREFERENCES_SUCCESS = 'GET_EMAIL_PREFERENCES_SUCCESS',
	GET_EMAIL_PREFERENCES_FAILED = 'GET_EMAIL_PREFERENCES_FAILED',

	UPDATE_EMAIL_PREFERENCES = 'UPDATE_EMAIL_PREFERENCES',
	UPDATE_EMAIL_PREFERENCES_SUCCESS = 'UPDATE_EMAIL_PREFERENCES_SUCCESS',
	UPDATE_EMAIL_PREFERENCES_FAILED = 'UPDATE_EMAIL_PREFERENCES_FAILED',

	GET_USER_MY_TICKETS = 'GET_USER_MY_TICKETS',
	GET_USER_MY_TICKETS_SUCCESS = 'GET_USER_MY_TICKETS_SUCCESS',
	GET_USER_MY_TICKETS_FAILED = 'GET_USER_MY_TICKETS_FAILED',

	GET_USER_MY_REGISTRATIONS = 'GET_USER_MY_REGISTRATIONS',
	GET_USER_MY_REGISTRATIONS_SUCCESS = 'GET_USER_MY_REGISTRATIONS_SUCCESS',
	GET_USER_MY_REGISTRATIONS_FAILED = 'GET_USER_MY_REGISTRATIONS_FAILED',

	GET_REFUNDABLE_TICKETS = 'GET_REFUNDABLE_TICKETS',
	GET_REFUNDABLE_TICKETS_SUCCESS = 'GET_REFUNDABLE_TICKETS_SUCCESS',
	GET_REFUNDABLE_TICKETS_FAILED = 'GET_REFUNDABLE_TICKETS_FAILED',

	POST_REFUNDABLE_TICKETS = 'POST_REFUNDABLE_TICKETS',
	POST_REFUNDABLE_TICKETS_SUCCESS = 'POST_REFUNDABLE_TICKETS_SUCCESS',
	POST_REFUNDABLE_TICKETS_FAILED = 'POST_REFUNDABLE_TICKETS_FAILED',

	GET_TRANSFERABLE_TICKETS = 'GET_TRANSFERABLE_TICKETS',
	GET_TRANSFERABLE_TICKETS_SUCCESS = 'GET_TRANSFERABLE_TICKETS_SUCCESS',
	GET_TRANSFERABLE_TICKETS_FAILED = 'GET_TRANSFERABLE_TICKETS_FAILED',

	TRANSFER_TICKETS = 'TRANSFER_TICKETS',
	TRANSFER_TICKETS_SUCCESS = 'TRANSFER_TICKETS_SUCCESS',
	TRANSFER_TICKETS_FAILED = 'TRANSFER_TICKETS_FAILED',

	TRANSFER_TICKETS_BACK = 'TRANSFER_TICKETS_BACK',
	TRANSFER_TICKETS_BACK_SUCCESS = 'TRANSFER_TICKETS_BACK_SUCCESS',
	TRANSFER_TICKETS_BACK_FAILED = 'TRANSFER_TICKETS_BACK_FAILED',

	GET_EDIT_TICKET_DETAILS = 'GET_EDIT_TICKET_DETAILS',
	GET_EDIT_TICKET_DETAILS_SUCCESS = 'GET_EDIT_TICKET_DETAILS_SUCCESS',
	GET_EDIT_TICKET_DETAILS_FAILED = 'GET_EDIT_TICKET_DETAILS_FAILED',

	UPDATE_EDIT_TICKET_DETAILS = 'UPDATE_EDIT_TICKET_DETAILS',
	UPDATE_EDIT_TICKET_DETAILS_SUCCESS = 'UPDATE_EDIT_TICKET_DETAILS_SUCCESS',
	UPDATE_EDIT_TICKET_DETAILS_FAILED = 'UPDATE_EDIT_TICKET_DETAILS_FAILED',

	GET_REGISTRATION_DETAILS = 'GET_REGISTRATION_DETAILS',
	GET_REGISTRATION_DETAILS_SUCCESS = 'GET_REGISTRATION_DETAILS_SUCCESS',
	GET_REGISTRATION_DETAILS_FAILED = 'GET_REGISTRATION_DETAILS_FAILED',

	UPDATE_REGISTRATION_DETAILS = 'UPDATE_REGISTRATION_DETAILS',
	UPDATE_REGISTRATION_DETAILS_SUCCESS = 'UPDATE_REGISTRATION_DETAILS_SUCCESS',
	UPDATE_REGISTRATION_DETAILS_FAILED = 'UPDATE_REGISTRATION_DETAILS_FAILED',

	FILE_UPLOAD = 'FILE_UPLOAD',
	FILE_UPLOAD_SUCCESS = 'FILE_UPLOAD_SUCCESS',
	FILE_UPLOAD_FAILED = 'FILE_UPLOAD_FAILED',

	GET_RESALE_TICKETS = 'GET_RESALE_TICKETS',
	GET_RESALE_TICKETS_SUCCESS = 'GET_RESALE_TICKETS_SUCCESS',
	GET_RESALE_TICKETS_FAILED = 'GET_RESALE_TICKETS_FAILED',

	POST_RESALE_TICKETS = 'POST_RESALE_TICKETS',
	POST_RESALE_TICKETS_SUCCESS = 'POST_RESALE_TICKETS_SUCCESS',
	POST_RESALE_TICKETS_FAILED = 'POST_RESALE_TICKETS_FAILED',

	CANCEL_RESALE_TICKETS = 'CANCEL_RESALE_TICKETS',
	CANCEL_RESALE_TICKETS_SUCCESS = 'CANCEL_RESALE_TICKETS_SUCCESS',
	CANCEL_RESALE_TICKETS_FAILED = 'CANCEL_RESALE_TICKETS_FAILED',

	GET_USER_SAVED_EVENTS = 'GET_USER_SAVED_EVENTS',
	GET_USER_SAVED_EVENTS_SUCCESS = 'GET_USER_SAVED_EVENTS_SUCCESS',
	GET_USER_SAVED_EVENTS_FAILED = 'GET_USER_SAVED_EVENTS_FAILED',

	GET_ALL_PROMOTER_NETWORK = 'GET_ALL_PROMOTER_NETWORK',
	GET_ALL_PROMOTER_NETWORK_SUCCESS = 'GET_ALL_PROMOTER_NETWORK_SUCCESS',
	GET_ALL_PROMOTER_NETWORK_FAILED = 'GET_ALL_PROMOTER_NETWORK_FAILED',

	GET_PROMOTER_NETWORK = 'GET_PROMOTER_NETWORK',
	GET_PROMOTER_NETWORK_SUCCESS = 'GET_PROMOTER_NETWORK_SUCCESS',
	GET_PROMOTER_NETWORK_FAILED = 'GET_PROMOTER_NETWORK_FAILED',

	UPDATE_PROMOTER_NETWORK_LINK = 'UPDATE_PROMOTER_NETWORK_LINK',
	UPDATE_PROMOTER_NETWORK_LINK_SUCCESS = 'UPDATE_PROMOTER_NETWORK_LINK_SUCCESS',
	UPDATE_PROMOTER_NETWORK_LINK_FAILED = 'UPDATE_PROMOTER_NETWORK_LINK_FAILED',

	UPDATE_PROMOTER_BANK_ACCOUNT = 'UPDATE_PROMOTER_BANK_ACCOUNT',
	UPDATE_PROMOTER_BANK_ACCOUNT_SUCCESS = 'UPDATE_PROMOTER_BANK_ACCOUNT_SUCCESS',
	UPDATE_PROMOTER_BANK_ACCOUNT_FAILED = 'UPDATE_PROMOTER_BANK_ACCOUNT_FAILED',

	GET_REFERRAL_DETAILS = 'GET_REFERRAL_DETAILS',
	GET_REFERRAL_DETAILS_SUCCESS = 'GET_REFERRAL_DETAILS_SUCCESS',
	GET_REFERRAL_DETAILS_FAILED = 'GET_REFERRAL_DETAILS_FAILED',

	UPDATE_REFERRAL_CODE = 'UPDATE_REFERRAL_CODE',
	UPDATE_REFERRAL_CODE_SUCCESS = 'UPDATE_REFERRAL_CODE_SUCCESS',
	UPDATE_REFERRAL_CODE_FAILED = 'UPDATE_REFERRAL_CODE_FAILED',

	UPDATE_REFERRAL_BANK_ACCOUNT = 'UPDATE_REFERRAL_BANK_ACCOUNT',
	UPDATE_REFERRAL_BANK_ACCOUNT_SUCCESS = 'UPDATE_REFERRAL_BANK_ACCOUNT_SUCCESS',
	UPDATE_REFERRAL_BANK_ACCOUNT_FAILED = 'UPDATE_REFERRAL_BANK_ACCOUNT_FAILED',
}
