<div
	class="l-container"
	[ngClass]="{ 'mobile': isMobile }"
>
    <div [ngClass]="{ mobile: isMobile }">
        <app-web-content-header
            *ngIf="!isMobile; else mobileHeaderContent"
            [headerContent]="headerContent"
            [mediumWidth]="true"
            [showBreadCrumb]="true"
        ></app-web-content-header>
        <div *ngIf="!isMobile" class="l-custom-description">
            Transfer attendees from one schedule in your event to another by selecting the old and new dates below.
                    Please note that all attendees in the old schedule will be moved to the new schedule regardless of the ticket quantity available.
                    <a class="g-link" [routerLink]="eventDetailsLink">Click here</a> if you need to add a new schedule before transferring attendees or to hide old schedules from your event page.
        </div>
        <ng-template #mobileHeaderContent>
            <div>
                <p *ngIf="!readMore">
                    {{ customDescription }}
                    <span (click)="handleReadMore()" id="read-less-button" class="g-pointer g-highlight g-green">
                        {{ readMoreText }}
                    </span>
                </p>
                <p *ngIf="readMore" [ngClass]="{ mobile: isMobile }">
                    Transfer attendees from one schedule in your event to another by selecting the old and new dates below.
                    Please note that all attendees in the old schedule will be moved to the new schedule regardless of the ticket quantity available.
                    <a class="g-link" [routerLink]="eventDetailsLink">Click here</a> if you need to add a new schedule before transferring attendees or to hide old schedules from your event page.
                    <span (click)="handleReadMore()" id="read-more-button" class="g-pointer g-highlight g-green">
                        {{ readMoreText }}
                    </span>
                </p>
            </div>
        </ng-template>

        <div *ngIf="!isSchedulesLoading; else loading" class="l-form-container">
            <form *ngIf="form" [formGroup]="form">
                <ng-container *ngIf="(hasSales && !isSchedulesLoading); else noSales">
                    <div class="l-input w-100" [ngClass]="{ 'mobile': isMobile }">
                        <app-mat-select-control-field
                            label="Move attendees from:"
                            placeholder="Select an old schedule"
                            [control]="fromForm('moveFromId')"
                            [options]="moveFromOptions"
                            class='mb-2 l-max-width'
                            customId="'move-from-id-'+{{moveFromOption}}"
                            (selectedOption)="updateValidators()"
                            [required]="true"
                            [handleErrors]="true"
                        >
                        </app-mat-select-control-field>
                    </div>
                    <div class="l-input w-100" [ngClass]="{ 'mobile': isMobile }">
                        <app-mat-select-control-field
                            label="Move attendees to:"
                            placeholder="Select an new schedule"
                            [control]="fromForm('moveToId')"
                            [options]="moveToOptions"
                            class='mb-2 l-max-width'
                            customId="'move-from-id-'+{{moveToOption}}"
                            (selectedOption)="updateValidators()"
                            [required]="true"
                            [handleErrors]="true"
                        >
                        </app-mat-select-control-field>
                    </div>
                    <app-mat-text-area-control-field
                        inputClass = "form-control mh-100 l-text-area"
                        customId="reason-message-input"
                        label="Let attendees know why they are being transferred: "
                        [required]="true"
                        [control]="fromForm('reason')"
                    >
                    </app-mat-text-area-control-field>
                </ng-container>
                <mat-checkbox *ngIf="hasSales"
                    [formControl]="fromForm('confirmTransfer')"
                    id="enabled-checkbox"
                    [ngClass]="{ 'l-checkbox': !isMobile}"
                    [required]="true"
                    class="l-checkbox"
                >
                I understand that by confirming this transfer, all attendees from the old date will be moved to the new date regardless of the amount of tickets available and that this cannot be undone.
                </mat-checkbox>
            </form>
        </div>
        <ng-template #noSales>
                <div class='l-empty-card'>
                    <div class="l-empty-state">
                        <h3 class="mb-3">You currently have no schedules.</h3>
                        <p>Your schedules do not have any attendees to transfer. <a [routerLink]="eventDetailsLink" class="g-link">Click here</a> to simply hide the schedules from your event page.</p>
                    </div>
                </div>
		</ng-template>
        <ng-template #loading>
            <div class="l-page-loader">
                <mat-spinner></mat-spinner>
            </div>
        </ng-template>
    </div>
</div>
<div class="l-sticky-bar" [ngClass]="{'mobile' : isMobile}" *ngIf="form">
    <app-footer-button
        id="transfer-attendees"
        primaryText="TRANSFER ATTENDEES"
        ($primaryButton)="submitForm()"
        [isDisabled]="!isSubmitAllowed()"
        [isLoading]="isSchedulesLoading"
        [isMobile]="isMobile"
        [fullWidth]="true"
    ></app-footer-button>
</div>