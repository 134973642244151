import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
	Resolve,
	ActivatedRouteSnapshot
} from '@angular/router';
import { PermissionService } from '@app/services/permission/permission.service';

@Injectable()
export class EventFeatureResolver implements Resolve<void> {
	constructor(
		private title: Title,
		private permissionService: PermissionService
	) { }
	resolve(route: ActivatedRouteSnapshot) {
		const { pageTitle, featureKey, helpArticle } = route.data;
		this.title.setTitle(pageTitle);
		this.permissionService.setKey(featureKey);
		this.permissionService.setHelpArticle(helpArticle);
	}
}
