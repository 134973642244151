import { Component, Input, OnInit, AfterViewInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Address, Question } from '@app/models/user.model';

@Component({
	selector: 'app-address-field',
	templateUrl: './address-field.component.html',
	styleUrls: ['./address-field.component.sass'],
})
export class AddressFieldComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
	@Input() isMobile: boolean;
	@Input() inputFormControl: FormControl;
	@Input() label: string;
	@Input() control: Question;
	@Input() index?: number;
	@Input() isBuyerQuestion = false;

	displayControl = new FormControl('');
	isRequired = false;
	isDisabled = false;
	addressString: string = null;
	addressInput: HTMLInputElement;
	id: string;

	ngOnInit(): void {
		if (this.isBuyerQuestion) {
			this.id = 'address-field-input-buyer-' + this.index.toString();
		} else {
			this.id = 'address-field-input-' + this.index.toString();
		}
		this.initializeFormControl();
		this.populateDisplayControl();

		this.displayControl.valueChanges.subscribe(value => {
			if (!value) {
				this.inputFormControl.setValue(null);
			}
		});
	}

	ngAfterViewInit(): void {
		this.addressInput = document.getElementById(this.id) as HTMLInputElement;
		this.initializeAutocomplete();
		window.addEventListener('scroll', this.onWindowScroll, true);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.inputFormControl && !changes.inputFormControl.isFirstChange()) {
			this.populateDisplayControl();
		}
	}

	initializeFormControl(): void {
		this.isDisabled = this.inputFormControl.disabled;
		if (this.control.required) {
			this.displayControl.addValidators(Validators.required);
		}
		this.isRequired = this.inputFormControl.hasValidator(Validators.required);
	}

	populateDisplayControl(): void {
		if (this.inputFormControl.value) {
			const controlValue: Address = JSON.parse(this.inputFormControl.value);
			if (controlValue?.lineOne.length > 0) {
				if (!this.isDisabled) {
					this.displayControl.setValue(this.convertAddressObjectToString(controlValue));
				} else {
					this.addressString = this.convertAddressObjectToString(controlValue);
				}
			} else {
				this.displayControl.setValue(null);
				this.inputFormControl.setValue(null);
			}
		} else {
			this.displayControl.setValue(null);
			this.inputFormControl.setValue(null);
		}
	}

	initializeAutocomplete(): void {
		if (!this.isDisabled) {
			const autocomplete = new google.maps.places.Autocomplete(this.addressInput, {
				fields: ['address_components', 'formatted_address'],
			});

			autocomplete.addListener('place_changed', () => {
				const place: google.maps.places.PlaceResult = autocomplete.getPlace();
				if (!place.address_components) {
					return;
				}
				this.handleAutocompleteSelected(place);
				this.populateDisplayControl();
			});
		}
	}

	handleAutocompleteSelected(address: google.maps.places.PlaceResult) {
		const addressComponents = address.address_components;
		const addressReturn = {
			type: '1',
			lineOne: addressComponents[0]?.long_name ?? '',
			lineTwo: addressComponents[1]?.long_name ?? '',
			lineThree: addressComponents[2]?.long_name ?? '',
			lineFour: addressComponents[5]?.long_name ?? '',
			lineFive: addressComponents[6]?.long_name ?? addressComponents[8]?.long_name ?? '',
			formattedAddress: address.formatted_address,
		};
		this.inputFormControl.setValue(JSON.stringify(addressReturn));
		this.inputFormControl.markAsDirty();
	}

	convertAddressObjectToString(data: Address): string {
		const keys = ['lineOne', 'lineTwo', 'lineThree', 'lineFour', 'lineFive'];
		const values = keys.map((key) => data[key] || '');
		return data.formattedAddress ? data.formattedAddress : values.join(', ');
	}

	onWindowScroll = (): void => {
		if (document.activeElement === this.addressInput) {
		  this.addressInput.blur();
		}
	};

	ngOnDestroy() {
		window.removeEventListener('scroll', this.onWindowScroll, true);
		const pacContainers = document.querySelectorAll('.pac-container');
		pacContainers.forEach((pacContainer) => {
			pacContainer.remove();
		});
	}
}
