import { Injectable } from '@angular/core';
import { FormService } from '@app/services/form/form.service';
import { FormValidators, FormSchemeControls } from '@app/models/common.model';
import { requiredValidator, onlyNumberAndLetters } from '@app/shared/form-field/form-validators';
import { DraftEventLinkCampaign, DraftEventLinkCampaignForm } from '@app/models/event.model';


@Injectable()
export class LinkCampaignsService {
	constructor (
		private formService: FormService
	) {}
	createLinkCampaignForm(initialValues?: Partial<DraftEventLinkCampaign>) {
		const formValues = new DraftEventLinkCampaignForm();

		const validatorsConfig: FormValidators<Partial<DraftEventLinkCampaignForm>> = {
			name: [requiredValidator()],
			campaignUrl: [onlyNumberAndLetters()],
		};

		const formConfig: Partial<FormSchemeControls<DraftEventLinkCampaignForm>> = this.formService.createFormControls({
			formValues,
			initialValues,
			validatorsConfig,
		});

		return this.formService.formBuilder.group(formConfig);
	}
}
