import { Component, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SectionRouteCard } from '@app/models/shared';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { StoreService } from '@app/services/store/store.service';
import { handleOrder } from '@app/utils/common-helpers';
import { EVENT_GUEST_MANAGEMENT_CARDS, EVENT_ID_PARAM_KEY } from '@app/utils/consts';
import { Dictionary } from '@ngrx/entity';
import * as eventSelectors from '@app/store/selectors/event.selector';
import { take, takeUntil } from 'rxjs';

@Component({
	selector: 'app-guest-management',
	templateUrl: './guest-management.component.html',
	styleUrls: ['./guest-management.component.sass'],
})
export class GuestManagementComponent {
	cardsData: Dictionary<SectionRouteCard> = EVENT_GUEST_MANAGEMENT_CARDS;
	isMobile = false;
	eventId: number;
	handleDefaultOrder = handleOrder;
	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private breakpointService: BreakpointService,
		private activatedRoute: ActivatedRoute,
		private store: StoreService
	) { }

	ngOnInit(): void {
		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});

		this.activatedRoute.parent.paramMap.pipe(take(1)).subscribe((params) => {
			this.eventId = +params.get(EVENT_ID_PARAM_KEY);
		});

		this.store.select(eventSelectors.eventMetadata()).pipe(takeUntil(this.destroyed$)).subscribe(metadata => {
			if (metadata) {
				if (!metadata.isOngoing){
					delete this.cardsData['transferSchedule'];
				}
			}
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
