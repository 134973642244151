import { Component, EventEmitter, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SectionRouteCard } from '@app/models/shared';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { getCurrentPage } from '@app/utils/common-helpers';
import { ORGANISER_NAV, ORGANISER_SUB_PAGE_NAV, OrganiserHubRoutes } from '@app/utils/consts';
import { Dictionary } from '@ngrx/entity';
import { filter, takeUntil } from 'rxjs';

enum ProfileSubRoutes {
	MAIL = 'mail-accounts',
	DETAILS = 'details',
	EVENTS = 'events',
	FUNDRAISERS = 'fundraisers',
	MAILCHIMP = 'mailchimp',
	DIRECT_DISTRIBUTION = 'direct-distribution',
	TRACKING_SCRIPTS = 'tracking-scripts',
}

@Component({
	selector: 'app-organiser-hub',
	templateUrl: './organiser-hub.component.html',
	styleUrls: ['./organiser-hub.component.sass'],
})
export class OrganiserHubComponent implements OnInit {
	isMobile = false;
	navItems: Dictionary<SectionRouteCard> = ORGANISER_NAV;
	navSubItems: Dictionary<SectionRouteCard> = ORGANISER_SUB_PAGE_NAV;
	currentNavItem: SectionRouteCard;
	currentSubNavItem: SectionRouteCard;
	isSecondaryPage = false;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private breakpointService: BreakpointService,
		private router: Router
	) { }

	ngOnInit(): void {
		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});
		this.setCurrentNavItem(this.router.url);

		this.router.events.pipe(
			filter(event => event instanceof NavigationEnd),
			takeUntil(this.destroyed$)
		).subscribe((event: NavigationEnd) => {
			this.setCurrentNavItem(event.urlAfterRedirects);
		});
	}

	private setCurrentNavItem(url: string): void {
		let currentPage = getCurrentPage(url);
		this.isSecondaryPage = false;
		if (
			currentPage === ProfileSubRoutes.DETAILS ||
			currentPage === ProfileSubRoutes.MAIL ||
			currentPage === ProfileSubRoutes.MAILCHIMP ||
			currentPage === ProfileSubRoutes.DIRECT_DISTRIBUTION ||
			currentPage === ProfileSubRoutes.TRACKING_SCRIPTS
		) {
			this.currentSubNavItem = this.navSubItems[currentPage];
			currentPage = OrganiserHubRoutes.PROFILE;
			this.isSecondaryPage = true;
		} else if (currentPage === ProfileSubRoutes.EVENTS) {
			currentPage = OrganiserHubRoutes.EVENTS;
		}

		this.currentNavItem = this.navItems[currentPage];
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
