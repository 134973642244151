<div class="l-container" *ngIf="!isLoading; else pageLoading">
	<div class="l-content l-header-content" [ngClass]="{ 'mobile': isMobile }" >
		<app-web-content-header
			class="l-header"
			[headerContent]="headerContent"
			[mediumWidth]="true"
			[showBreadCrumb]="!isMobile"
			[showTitle]="!isMobile"
		></app-web-content-header>
	</div>
		
	<form [formGroup]="form" *ngIf="form">
		<div class="l-form" [ngClass]="{ 'mobile': isMobile }">
			<div class="l-image-upload-container">
				<app-image-upload-and-cropper
					[label]="'Event Banner Image'"
					[uploadText]="'This will appear across the top of your event page.\n'"
					[eventId]="eventId"
					[originalImage]="fromForm('originalImage').value"
					[imageServiceType]="ImageServiceTypeEnum.EVENT"
					[isMobile]="isMobile"
					(croppedBase64Change)="handleImageCropChange($event)"
					(imageStringChange)="handleImageStringChange($event)"
					(originalImageChange)="handleOriginalImageChange($event)"
					(imageRemoved)="handleImageRemove()"
					(thumbnailChange)="handleThumbnailChange($event)"
					class="l-banner-image-upload"
					id="event-banner-image-upload"
				></app-image-upload-and-cropper>
			</div>
			<br>
			<div id="toggle" class="l-toggle-switch" [ngClass]="{ 'mobile': isMobile }">
				<label class="g-form-label">Display banner in emails: </label>
				<mat-slide-toggle id='banner-email-toggle' formControlName="displayBannerInEmails"
					name="display-banner-in-emails" class="standard-toggle px-4"
					></mat-slide-toggle>
			</div>
			<div class="l-colour-picker" [ngClass]="{ 'mobile': isMobile }">
				<div class="l-theme-colour">
					<div class="flex-1 flex-col justify-content-center w-100 m-1.5">
						<div class="g-info-block py-4">
							<i class="fa-solid fa-info g-info-block-icon"></i>
							<p class="g-info-block-msg mb-0">
								Please do not select a white or grey theme
								colour as this will prevent important links and buttons from displaying
								correctly and may hinder sales.
							</p>
						</div>
					</div>
					<app-color-picker
					[offset]="'zero'"
					label="Theme colour"
					placeholder="Click here to select a colour or enter a Hex value"
					[control]="form.get('color')"
					[previewOnTop]="true"
					id="design-color-picker"
					></app-color-picker>
				</div>
			</div>
		</div>
	</form>
	<div *ngIf="form" class="l-sticky-bar">
		<app-footer-button
			id="save-design-button"
			primaryText="SAVE CHANGES"
			secondaryText="PREVIEW"
			($primaryButton)="handleOnSubmit()"
			($secondaryButton)="handlePreviewOpen()"
			[isMobile]="isMobile"
			[isDisabled]="isButtonLoading || !form.dirty"
			[isLoading]="isButtonLoading"
		>
		</app-footer-button>
	</div>
</div>
<ng-template #pageLoading>
	<div class="l-page-loader">
		<mat-spinner></mat-spinner>
	</div>
</ng-template>