import { NgModule } from '@angular/core';
import { AppComponent } from './app/app.component';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '../shared/shared.module';
import { LoginComponent } from './login/login.component';
import { APIModule } from '../api/api.module';
import { EventComponent } from './event/event.component';
import { UserComponent } from './user/user.component';

@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		EventComponent,
		UserComponent,
	],
	imports: [
		HttpClientModule,
		SharedModule,
		APIModule,
	],
	exports: [
		HttpClientModule,
	],
})
export class CoreModule {}
