import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HeaderContent } from '@app/models/shared';
import { EditTicketDetails, PurchaseItem, Question, QuestionType } from '@app/models/user.model';
import { FormCreationService } from '@app/services/form-creation/form-creation.service';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { StoreService } from '@app/services/store/store.service';
import { MY_TICKETS_HEADER } from '@app/utils/consts';
import { take, takeUntil } from 'rxjs/operators';
import { Location } from '@angular/common';
import { FormControl, FormGroup } from '@angular/forms';
import {
	GetEditTicketDetails,
	GetEditTicketDetailsFailed,
	GetEditTicketDetailsSuccess,
	UpdateEditTicketDetails
} from '@app/store/actions/user/user.actions';
import { Actions, ofType } from '@ngrx/effects';
import { UserActionsConstants } from '@app/store/actions/user/user.actions.constants';
import { FooterButtonComponent } from '@app/shared/footer-button/footer-button.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MapsService } from '@app/services/maps/maps.service';
import { FormCanDeactivate } from '@app/services/guards/user.guard';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ConfirmationModalComponent } from '@app/shared/modals/confirmation-modal/confirmation-modal.component';
import { Go } from '@app/store/actions/router/router.actions';
import { InternalURLCreator } from '@app/services/url/url.dictionary';
import { AddNotification } from '@app/store/actions/notification/notification.actions';
import { NotificationType } from '@app/models/notification.model';

const NO_TICKETS_FOUND_MESSAGE = 'No Tickets Found - Please contact support.';

@Component({
	selector: 'app-edit-ticket',
	templateUrl: './edit-ticket.component.html',
	styleUrls: ['./edit-ticket.component.sass'],
})
export class EditTicketComponent extends FormCanDeactivate implements OnInit {
	editTicketLoading = true;
	editTicketForms: FormGroup;

	editTicketFormData: EditTicketDetails;
	purchaseItems: PurchaseItem[];
	buyerOnlyQuestions: Question[];

	questionTypes = QuestionType;
	expandPanels = true;

	isFromOrderDetails = false;
	hasUniqueError = false;
	isPurchaseLocked = false;
	updatedBuyerOnlyQuestionIds: number[] = [];

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	@ViewChild('footerButton') footerButton: FooterButtonComponent;

	get isFooterButtonDisabled(): boolean {
		return this.editTicketLoading || this.editTicketForms.invalid;
	}

	isMobile = false;
	headerContent: HeaderContent = MY_TICKETS_HEADER['editTicket'];
	ticketId: number;

	hasFileUploadQuestions = false;

	errorMessage: string;

	NO_ACCESS_MESSAGE = 'You do not have access to these tickets.';

	constructor(
		private breakpointService: BreakpointService,
		private location: Location,
		private activatedRoute: ActivatedRoute,
		private formCreation: FormCreationService,
		private store: StoreService,
		private actions$: Actions,
		private _snackBar: MatSnackBar,
		private mapsService: MapsService,
		private dialog: MatDialog
	) {
		super();
		this.editTicketForms = this.formCreation.createFormGroup();
	}

	ngOnInit(): void {
		this.initializeMobileSubscription();
		this.initializeEditTicketDetailsSubscription();
		this.initializeRouteParamsSubscription();
		this.initializeHandleIsFromOrderDetails();
	}

	initializeMobileSubscription(): void {
		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});
	}

	initializeEditTicketDetailsSubscription(): void {
		this.actions$
			.pipe(
				ofType<GetEditTicketDetailsSuccess | GetEditTicketDetailsFailed>(
					UserActionsConstants.GET_EDIT_TICKET_DETAILS_SUCCESS,
					UserActionsConstants.GET_EDIT_TICKET_DETAILS_FAILED
				),
				takeUntil(this.destroyed$)
			)
			.subscribe((action) => {
				if (action.type === UserActionsConstants.GET_EDIT_TICKET_DETAILS_SUCCESS) {
					const successAction = action as GetEditTicketDetailsSuccess;
					this.initializeEditTicketFormData(successAction.payload.editTicketDetails);
				} else if (action.type === UserActionsConstants.GET_EDIT_TICKET_DETAILS_FAILED) {
					const failedAction = action as GetEditTicketDetailsFailed;
					if (failedAction.payload && failedAction.payload.msg === this.NO_ACCESS_MESSAGE) {
						this.errorMessage = failedAction.payload.msg;
					} else {
						this.errorMessage = 'Oops! We can\'t get your ticket details,please try again or contact support'; // eslint-disable-line max-len
					}
					this.editTicketLoading = false;
				}
			});
	}

	initializeRouteParamsSubscription(): void {
		this.activatedRoute.params.pipe(take(1)).subscribe((params) => {
			this.ticketId = params['id'];
			if (this.ticketId) {
				this.store.dispatch(new GetEditTicketDetails({ id: this.ticketId }));
			}
		});
	}

	initializeEditTicketFormData(editTicketDetails: EditTicketDetails): void {
		this.editTicketFormData = editTicketDetails;
		this.buyerOnlyQuestions = editTicketDetails.buyerOnlyQuestions;
		this.isPurchaseLocked = this.editTicketFormData.isLocked;

		this.purchaseItems = this.editTicketFormData.purchaseItems.filter((ticket) => !ticket.checkedIn);
		if (this.purchaseItems.every(item => item.questions.length === 0)) {
			this.editTicketForms.markAsDirty();
		}
		let hasAddressQuestions = false;

		if (this.buyerOnlyQuestions) {
			hasAddressQuestions =  this.buyerOnlyQuestions.some((question) =>
				question.type === QuestionType.Address);
			this.hasFileUploadQuestions = this.buyerOnlyQuestions.some((question) =>
				question.type === QuestionType.FileUpload);
		}

		if (!hasAddressQuestions){
			hasAddressQuestions = this.purchaseItems.some((ticket) =>
				ticket.questions.some((question) => question.type === QuestionType.Address)
			);
		}

		if (!this.hasFileUploadQuestions) {
			this.hasFileUploadQuestions = this.purchaseItems.some((ticket) =>
				ticket.questions.some((question) => question.type === QuestionType.FileUpload)
			);
		}

		if (hasAddressQuestions) {
			this.mapsService.initializeMaps().then(() => {
				this.editTicketLoading = false;
			});
		} else {
			this.editTicketLoading = false;
		}

		if (this.editTicketFormData.errorMessage && !this.hasUniqueError) {
			this.errorMessage = this.editTicketFormData.errorMessage;
		} else if (this.purchaseItems.length) {
			this.initializeEditTicketForms(this.purchaseItems, this.buyerOnlyQuestions);
		} else {
			this.errorMessage = NO_TICKETS_FOUND_MESSAGE;
		}
	}

	initializeEditTicketForms(purchaseItems: PurchaseItem[], buyerOnlyQuestions: Question[]): string {
		this.editTicketForms = this.formCreation.createFormGroup();
		const uniqueQuestions = this.getUniqueQuestionsDictionary(purchaseItems);
		this.expandPanels = this.purchaseItems.length <= 3;
		for (const purchaseItem of purchaseItems) {
			const questionForm = this.formCreation.createFormGroup();
			this.editTicketForms.addControl(purchaseItem.id.toString(), questionForm);
			this.formCreation.populateFormFromData(
				questionForm,
				purchaseItem.questions,
				purchaseItem.isEditable,
				uniqueQuestions,
				this.editTicketForms
			);
		}
		const buyerQuestionForm = this.formCreation.createFormGroup();
		this.formCreation.populateFormFromData(buyerQuestionForm, buyerOnlyQuestions, true);
		this.editTicketForms.addControl('buyerOnly', buyerQuestionForm);
		return '';
	}

	initializeHandleIsFromOrderDetails() {
		this.activatedRoute.queryParams.pipe(takeUntil(this.destroyed$)).subscribe((queryParams) => {
			if (queryParams['isFromOrderDetails'] && queryParams['isFromOrderDetails'] === 'true') {
				this.isFromOrderDetails = true;
				this.headerContent.breadCrumbs = [
					{
						routeName: 'Order Details',
						routeTo: () => this.location.back(),
					},
				];
			}
		});
	}

	getSecondaryText() {
		return this.isFromOrderDetails ? 'BACK' : 'CANCEL';
	}

	handleNavigationBack(): void {
		if (this.isFromOrderDetails) {
			this.store.dispatch(new Go({ path: [InternalURLCreator.orderDetails(
				this.editTicketFormData.productId)], query: {orderId: this.ticketId},
			}));
		} else {
			this.store.dispatch(new Go({ path: [InternalURLCreator.manageBooking(this.ticketId)] }));
		}
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}

	handleApplyAll(ticketId: number): void {
		const currentTicketForm = this.editTicketForms.get(ticketId.toString());
		if (currentTicketForm) {
			const currentTicketValues = currentTicketForm.getRawValue();
			this.applyToAllTickets(ticketId, currentTicketValues);
		}
	}

	handleSubmit(): void {
		this.editTicketLoading = true;

		this.actions$
			.pipe(
				ofType(UserActionsConstants.UPDATE_EDIT_TICKET_DETAILS_SUCCESS, UserActionsConstants.UPDATE_EDIT_TICKET_DETAILS_FAILED),
				takeUntil(this.destroyed$)
			)
			.subscribe(({ type, payload: { editTicketDetails } }: { type: string; payload: { editTicketDetails: EditTicketDetails } }) => {
				if (type === UserActionsConstants.UPDATE_EDIT_TICKET_DETAILS_SUCCESS) {
					const { hasUniqueError } = editTicketDetails;
					const notificationMessage = hasUniqueError
						? 'One or more of your answers are required to be unique among all ticket buyers'
						: 'Successfully updated your ticket details.';
					const notificationType = hasUniqueError ? NotificationType.ERROR : NotificationType.SUCCESS;

					this.handleNotification(notificationMessage, notificationType);

					this.hasUniqueError = hasUniqueError;
					this.initializeEditTicketFormData(editTicketDetails);
					this.editTicketForms.markAsPristine();
					this.isPurchaseLocked = false;
					this.updatedBuyerOnlyQuestionIds = [];
				}
				this.editTicketLoading = false;
			});

		const updatedPurchaseItems = this.editTicketFormData.purchaseItems.map((purchaseItem) => {
			const purchaseItemId = purchaseItem.id;
			const updatedQuestions = purchaseItem.questions.map((question) => {
				const questionId = question.id;
				const newAnswer = this.editTicketForms.value[purchaseItemId]?.[questionId];
				return { ...question, answer: newAnswer, uniqueAnswerError: null, errorMessage: null };
			});
			return { ...purchaseItem, questions: updatedQuestions, nonUniqueError: false };
		});

		const updatedBuyerOnlyQuestions = this.editTicketFormData.buyerOnlyQuestions.map((buyerOnlyQuestion) => {
			const questionId = buyerOnlyQuestion.id;
			const newAnswer = this.editTicketForms.value['buyerOnly']?.[questionId];

			if (this.updatedBuyerOnlyQuestionIds.includes(questionId)){
				return { ... buyerOnlyQuestion, answer: newAnswer, isUpdated: true};
			}

			return { ... buyerOnlyQuestion, answer: newAnswer };
		});

		if (this.editTicketFormData.numEditsLimited) {
			this.editTicketFormData = {
				...this.editTicketFormData,
				numEditsRemaining: this.editTicketFormData.numEditsRemaining - 1,
				numEditsUsed: this.editTicketFormData.numEditsUsed + 1,
			};
		}

		const updatedFormData = {
			...this.editTicketFormData,
			purchaseItems: updatedPurchaseItems,
			buyerOnlyQuestions: updatedBuyerOnlyQuestions,
			hasUniqueError: false,
			errorMessage: null,
		};

		this.store.dispatch(new UpdateEditTicketDetails({ id: this.ticketId, editTicketDetails: updatedFormData }));
	}

	applyToAllTickets(excludedTicketId: number, values: any): void {
		const invalidIds = this.getInvalidIds();
		invalidIds.push(excludedTicketId);
		Object.keys(this.editTicketForms.controls).forEach((ticketId) => {
			if (!invalidIds.includes(+ticketId) && ticketId !== 'buyerOnly') {
				const oldForm = this.editTicketForms.get(ticketId) as FormGroup;
				if (oldForm) {
					const newForm = new FormGroup({});
					Object.keys(values).forEach((key) => {
						const oldControl = oldForm.get(key);
						const newControl = new FormControl(values[key], oldControl?.validator);

						newForm.addControl(key, newControl);
					});
					this.editTicketForms.setControl(ticketId, newForm);
				}
			}
		});
		this._snackBar.open(
			`Details applied to all tickets ${this.hasFileUploadQuestions ? '(file upload answers not included)' : ''}`,
			 'Close',
			{
				duration: 2000,
				panelClass: 'g-success-snackbar',
				horizontalPosition: 'center',
				verticalPosition: 'top',
			}
		);
		this.editTicketForms.markAsDirty();
	}

	getUniqueQuestionsDictionary(purchaseItems: PurchaseItem[]): { [key: number]: string[] } {
		const uniqueQuestionDictionary: { [key: number]: string[] } = {};
		purchaseItems.forEach((purchaseItem) => {
			purchaseItem.questions.forEach((question) => {
				if (question.isUnique) {
					if (!uniqueQuestionDictionary[question.id]) {
						uniqueQuestionDictionary[question.id] = [];
					}
					uniqueQuestionDictionary[question.id].push(question.answer);
				}
			});
		});
		return uniqueQuestionDictionary;
	}

	getInvalidIds(): number[] {
		return this.purchaseItems.reduce((acc, ticket) => {
			if (!ticket.isEditable) {
				acc.push(ticket.id);
			}
			return acc;
		}, []);
	}

	handleNotification(message: string, type: NotificationType) {
		this.store.dispatch(
			new AddNotification({
				id: 'edit-ticket-details',
				actionType: null,
				action: null,
				type: type,
				title: message,
				delay: 4000,
			})
		);
	}

	handleUpdateQuestion(id: number){
		this.updatedBuyerOnlyQuestionIds.push(id);
	}

	isFormDeactivatable(): boolean | Observable<boolean> {
		return !this.editTicketForms || this.editTicketForms.pristine;
	}

	deactivationFallback(): Observable<boolean> {
		this.dialog
			.open(ConfirmationModalComponent, {
				data: {
					title: 'Unsaved Changes',
					text: 'Are you sure you want to leave this page? Any unsaved changes will be discarded.',
					buttonText: 'LEAVE',
					centerText: true,
					isMobile: this.isMobile,
				},
				panelClass: 'g-standard-dialog',
			})
			.afterClosed()
			.subscribe((result) => {
				this.closeDeactivationModal(result);
			});
		return this.modalCallback.asObservable();
	}

	closeDeactivationModal(isConfirmed: boolean): void {
		this.modalCallback.next(isConfirmed);
	}
}
