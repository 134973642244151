import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SchemeID } from '@app/models/dataSchema.model';
import { HeaderContent } from '@app/models/shared';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { InternalURLCreator, URLCreator } from '@app/services/url/url.dictionary';
import { EVENT_ID_PARAM_KEY } from '@app/utils/consts';
import { take, takeUntil } from 'rxjs';

@Component({
	selector: 'app-send-rsvps',
	templateUrl: './send-rsvps.component.html',
	styleUrls: ['./send-rsvps.component.sass'],
})
export class SendRSVPsComponent implements OnInit {
	url: string;
	eventId: SchemeID;
	isMobile = false;

	headerContent: HeaderContent = {
		breadCrumbs: [
			{
				routeName: 'Guest Management',
				routeTo: () => InternalURLCreator.guestManagement(this.eventId),
			},
			{
				routeName: 'Send RSVPs',
			},
		],
		title: 'Send RSVPs',
		description: `Send out RSVPs to your event. Guests who accept your RSVP will be automatically 
		given a complimentary ticket. You can either invite a single guest or upload a list of guests to invite.`,
	};

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(private activatedRoute: ActivatedRoute, private breakpointService: BreakpointService) {}

	ngOnInit(): void {
		this.activatedRoute.parent.paramMap.pipe(take(1)).subscribe((params) => {
			this.eventId = +params.get(EVENT_ID_PARAM_KEY);
			this.url = URLCreator.invitations(this.eventId);
		});

		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
