import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ConditionalTab, TabbedNav } from '@app/models/shared';
import { urlSegmentToHeading } from '@app/utils/common-helpers';
import { DisplayTabCondition } from '@app/utils/consts';

@Component({
	selector: 'app-tab-nav-bar',
	templateUrl: './tab-nav-bar.component.html',
	styleUrls: ['./tab-nav-bar.component.sass'],
})
export class TabNavBarComponent implements OnChanges {
	@Input() pages: string[];
	@Input() conditionalTabs?: ConditionalTab[];
	@Input() isOngoing?: boolean;
	tabLinks: TabbedNav[];

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.pages) {
			this.checkTabs();
			this.tabLinks = this.pages.reduce((acc: TabbedNav[], item: string) => {
				acc.push({
					path: item,
					label: urlSegmentToHeading(item).toUpperCase(),
				});
				return acc;
			}, []);
		}
	}

	checkTabs() {
		if (this.conditionalTabs) {
			this.conditionalTabs.forEach(conditional => {
				switch (conditional.condition) {
					case DisplayTabCondition.ONGOING:
						if (!this.isOngoing) {
							const index = this.pages.indexOf(conditional.tab);
							this.pages.splice(index, 1);
						}
						break;
					default:
						break;
				}

			});
		}
	}
}
