import { Actions } from '../actions/notification/notification.actions';
import { NotificationActionConstants as constants } from '../actions/notification/notification.constants';
import { NotificationState } from '@app/models/store.model';

export const initialState: NotificationState = {
	notifications: [],
};

export const notificationReducer = (
	state = initialState,
	action: Actions
): NotificationState => {
	switch (action.type) {
		case constants.NOTIFICATION_ADD:
			return {
				...state,
				notifications: [
					...state.notifications,
					action.payload,
				],
			};
		case constants.NOTIFICATION_REMOVE:
			return {
				...state,
				notifications: [
					...state.notifications.filter(el => el.id !== action.payload.id),
				],
			};
		default:
			return state;
	}
};
