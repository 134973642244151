<div
	class="l-container"
	[ngClass]="{'mobile': data.isMobile}"
>
	<h3 class='mb-4 m-auto'>Mark Order As Paid</h3>
	<div class="l-description">You are about to mark this order as paid. By confirming, you acknowledge that the
		payment has been received.<br />
		<div class="mt-3 fw-bold">Please note: This action cannot be undone.</div>
	</div>
	<div *ngIf="data.gateways.length; else noGateways" class="l-payment-form" [formGroup]="form">
		<app-mat-select-control-field			
			label="Choose the payment method that was used"
			[control]="form.get('paymentMethod')"
			[options]="paymentOptions"
			class='w-100'
			customId="payment-method"
		>
		</app-mat-select-control-field>
		<div class="d-flex align-items-center">
			<mat-checkbox
				id="tc-checkbox-field"
				[formControl]="form.get('terms')"
			></mat-checkbox>
			<span>I confirm that I have received <b>{{data.cartAmount}}</b> which includes any fees paid by the buyer.</span>
		</div>
	</div>
	<ng-template #noGateways>
		<div class="l-no-gateways">There are no available gateways</div>
	</ng-template>
	<div class="l-buttons-container">
		<button
			class="g-secondary-button l-button"
			(click)="handleCancel()"
			id="back-button"
		>CANCEL</button>
		<button
			class="g-primary-button l-button"
			[disabled]='isDisabled'
			[class.disabled]='isDisabled'
			(click)="handleMarkAsPaid()"
			id="submit-button"
		>
			<div *ngIf='!isSubmitLoading; else showLoading'>CONFIRM</div>
			<ng-template #showLoading><mat-spinner class='g-button-spinner'></mat-spinner></ng-template>
		</button>
	</div>
</div>
<mat-progress-bar
	*ngIf="isSubmitLoading"
	mode="indeterminate"
></mat-progress-bar>