import { Injectable } from '@angular/core';
import { FormService } from '@app/services/form/form.service';
import { EventOnlineStreamingSettings, EventOnlineStreamingRooms } from '@app/models/event.model';
import { FormSchemeControls, FormValidators } from '@app/models/common.model';
import { DraftEventOnlineStreamingSettingsForm,
	DraftEventOnlineStreamingRoomsForm } from './event-streaming.model';
import { requiredValidator, maxLength, numberMoreThen } from '@app/shared/form-field/form-validators';


@Injectable()
export class StreamingService {
	constructor(
		private formService: FormService
	) {}
	createEventStreamingSettingsForm = (initialValues?: Partial<EventOnlineStreamingSettings>) => {
		const formValues = new DraftEventOnlineStreamingSettingsForm();

		const validatorsConfig:
		FormValidators<Partial<DraftEventOnlineStreamingSettingsForm>> = {
			maxCodeUses: [numberMoreThen(0)],
		};

		const formConfig: Partial<FormSchemeControls<DraftEventOnlineStreamingSettingsForm>> = this.formService.createFormControls({
			formValues,
			initialValues,
			validatorsConfig,
		});

		return this.formService.formBuilder.group(formConfig);
	};

	prepareSettingsFormData(
		formValues: DraftEventOnlineStreamingSettingsForm,
		bannerCroppedImage: string,
		logoCroppedImage: string ): DraftEventOnlineStreamingSettingsForm {
		return {
			...formValues,
			...(bannerCroppedImage && formValues.bannerImageString
				? {
					bannerImageString: bannerCroppedImage,
				}
				: {}
			),
			...(logoCroppedImage && formValues.logoImageString
				? {
					logoImageString: logoCroppedImage,
				}
				: {}
			),
		};
	}

	createEventStreamingRoomForm = (initialValues?: Partial<EventOnlineStreamingRooms>) => {

		const formValues = new DraftEventOnlineStreamingRoomsForm();

		const validatorsConfig:
		FormValidators<Partial<DraftEventOnlineStreamingRoomsForm>> = {
			name: [requiredValidator(), maxLength(50)],
			instructions: [maxLength(300)],
		};

		const formConfig: Partial<FormSchemeControls<DraftEventOnlineStreamingRoomsForm>> = this.formService.createFormControls({
			formValues,
			initialValues,
			validatorsConfig,
		});

		return this.formService.formBuilder.group(formConfig);
	};
}
