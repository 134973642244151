import { AppState, EventEntityState } from '@app/models/store.model';
import { SchemeID } from '@app/models/dataSchema.model';
import { Ticket, EventTicketsDetails } from '@app/models/ticket.model';
import { EventFormat, EventCategory } from '@app/models/event.model';
import { EventCopyInfo } from '@app/models/event-metadata.model';


export const venues = () =>
	(state: AppState) => state.events.venues;

export const currencies = () =>
	(state: AppState) => state.events.currencies;

export const banks = () =>
	(state: AppState) => state.events.banks;

export const ticket = (id?: SchemeID) =>
	(state: AppState): Ticket =>
		state.events.tickets[id];

export const activeStepIndex = () =>
	(state: AppState) => state.events.activeStepIndex;

export const allEvents = () =>
	(state: AppState) => state.events.entities.all.map(id => state.events.entities.byId[id]);

export const isFetching = () =>
	(state: AppState) => state.events.entities.isFetching;

export const event = (id?: SchemeID) =>
	(state: AppState): EventEntityState => {
		const selected = state.events.entities.byId[id || state.events.entities.active];

		return selected
			? {
				...selected,
				ticketsDetails: selected.ticketsDetails ? ({
					...selected.ticketsDetails,
					tickets: selected.ticketsDetails.tickets.map(el => ticket(el)(state)),
				}) : null,
			}
			: null;
	};

export const hasSoldTickets = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);
		return selected && selected.details
			? selected.details.hasSoldTickets
			: null;
	};

export const activeEventId = () =>
	(state: AppState): SchemeID =>
		state.events.entities.active;

export const entityisFetching = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);
		return selected
			? selected.isFetching
			: null;
	};

export const questions = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);
		return selected
			? selected.questions
			: null;
	};

export const seatingCategories = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);
		return selected
			? selected.seatingCategories
			: null;
	};

export const ticketsDetails = (id?: SchemeID) =>
	(state: AppState): EventTicketsDetails => {
		const selected = event(id)(state);

		return selected ? selected.ticketsDetails : null;
	};

export const eventDetails = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected && selected.details
			? {
				image: selected.details.image,
				details: selected.details,
			}
			: null;
	};

export const productType = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected && selected
			? selected.details.productType
			: null;
	};

export const eventName = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);
		return selected && selected.details
			? selected.details.name
			: null;
	};

export const eventSchedules = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);
		return selected && selected.details
			? selected.details.schedules
			: null;
	};

export const eventFeatureOptions = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected && selected.details
			? selected.details.eventFeatureOptions
			: null;
	};

export const fundraiserCertificateFile = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected && selected.details && selected.details.fundraiserCertificate
			? selected.details.fundraiserCertificate.fileName
			: null;
	};

export const eventDetailsForDashboard = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected && selected.dashboard
			? {
				dashboard: selected.dashboard,
				metadata: selected.metadata,
			} : null;
	};

export const eventDashboardReportsIsFetching = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected && selected.dashboardReports
			? selected.dashboardReports.isFetching
			: null;
	};

export const eventDashboardReports = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected && selected.dashboardReports
			? selected.dashboardReports.value
			: null;
	};

export const eventDashboardCovidCompliance = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected && selected.dashboard
			? selected.dashboard.covidComplianceEnabled
			: null;
	};

export const eventTicketTypes = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected && selected.ticketsDetails
			? selected.ticketsDetails.tickets
			: null;
	};


export const eventTickets = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected && selected.ticketsDetails
			? {
				seatingCategories: seatingCategories(id)(state),
				ticketsDetails: ticketsDetails(id)(state),
				details: selected.details,
			} : null;
	};

export const eventOrganiser = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected
			? selected.profile
			: null;
	};

export const eventSettings = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected
			? selected.settings
			: null;
	};

export const eventTicketSettings = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected
			? selected.ticketSettings
			: null;
	};

export const eventMetadata = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected
			? selected.metadata
			: null;
	};

export const isEventOngoing = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected && selected.metadata
			? selected.metadata.isOngoing
			: null;
	};

export const isEventDDTEnabled = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected && selected.metadata
			? selected.metadata.directDistributionEnabled
			: null;
	};

export const eventStatus = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected
			? selected.metadata.productStatus
			: null;
	};

export const allEventAccessPermissions = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);
		return selected && selected.metadata
			? selected.metadata.permissions
			: null;
	};

export const eventAccessPermission = (feature: string, id?: SchemeID) =>
	(state: AppState) => {
		const selected = allEventAccessPermissions(id)(state);
		return selected && selected[feature]
			? selected[feature].access
			: null;
	};

export const isEventOwner = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);
		return selected && selected.metadata && selected.metadata.isOwner;
	};

export const eventFeatureAccess = (feature: string, id?: SchemeID) =>
	(state: AppState) => ({
		isOwner: isEventOwner(id)(state),
		permission: eventAccessPermission(feature, id)(state),
		allPermissions: allEventAccessPermissions(id)(state),
	});

export const eventCopyInfo = (id?: SchemeID) =>
	(state: AppState): EventCopyInfo | null => {
		const selected = event(id)(state);
		return selected && selected.copyInfo
			? selected.copyInfo
			: null;
	};

export const helpArticle = () =>
	(state: AppState) =>  state.events.helpArticle;

export const eventSeatingChartId = (id?: SchemeID) =>
	(state: AppState): string => {
		const e = event(id)(state);
		if (e && e.ticketsDetails) {
			return e.ticketsDetails.seatingChartId;
		}
		return null;
	};

export const eventTypes = () =>
	(state: AppState): { formats: EventFormat[]; categories: EventCategory[] } => {
		const { eventTypes: selected } = state.events;

		if (selected) {
			if (
				selected.categories
				&& selected.categories.length
				&& selected.formats
				&& selected.formats.length
			) {
				return selected;
			}

			return null;
		}
		return null;
	};

export const eventDashboardModals = () =>
	(state: AppState) => state.events.dashboardModals;

export const eventFacebookIntegration = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected
			? selected.facebookIntegration
			: null;
	};

export const eventTabbedContent = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected && selected.tabbedContent
			? selected.tabbedContent.tabs.all.map(tabId => selected.tabbedContent.tabs.byId[tabId])
			: null;
	};

export const isEventTabbedContentFetching = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected && selected.tabbedContent
			? selected.tabbedContent.isFetching
			: null;
	};

export const isTabContentModalOpen = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected && selected.tabbedContent
			? selected.tabbedContent.isModalOpen
			: null;
	};

export const eventTicketGroups = (id?: SchemeID) => (state: AppState) => {
	const selected = event(id)(state);

	return selected && selected.ticketGroups
		? selected.ticketGroups.groups.all.map(ticketGroupId =>
			selected.ticketGroups.groups.byId[ticketGroupId])
		: null;
};

export const eventTicketGroupTicketTypes = (id?: SchemeID) => (state: AppState) => {
	const selected = event(id)(state);

	return selected && selected.ticketGroups && selected.ticketGroups.ticketTypes
		? selected.ticketGroups.ticketTypes.all.map(ticketTypeId =>
			selected.ticketGroups.ticketTypes.byId[ticketTypeId])
		: null;
};

export const isTicketGroupsFetching = (id?: SchemeID) => (state: AppState) => {
	const selected = event(id)(state);

	return selected && selected.ticketGroups
		? selected.ticketGroups.isFetching
		: null;
};

export const isTicketGroupsModalOpen = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected && selected.ticketGroups
			? selected.ticketGroups.isModalOpen
			: null;
	};

export const useTicketGroups = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected && selected.ticketGroups
			? selected.ticketGroups.useTicketGroups
			: null;
	};

export const eventLinkCampaigns = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected && selected.linkCampaigns
			? selected.linkCampaigns.campaigns.all.map(campaignId => selected.linkCampaigns.campaigns.byId[campaignId])
			: null;
	};

export const isEventLinkCampaignsFetching = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected && selected.linkCampaigns
			? selected.linkCampaigns.isFetching
			: null;
	};

export const isLinkCampaignModalOpen = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected && selected.linkCampaigns
			? selected.linkCampaigns.isModalOpen
			: null;
	};

export const eventCollectInfo = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected && selected.collectInfo
			? selected.collectInfo.event.all.map(questionId =>
				selected.collectInfo.event.byId[questionId])
			: null;
	};

export const eventCollectInfoTickets = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected && selected.collectInfo
			? selected.collectInfo.tickets
			: null;
	};

export const isCollectInfoModalOpen = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected && selected.collectInfo
			? selected.collectInfo.isModalOpen
			: null;
	};

export const profileCollectInfo = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected && selected.collectInfo
			? selected.collectInfo.profile
			: null;
	};

export const eventDesign = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected
			? selected.design
			: null;
	};

export const eventDefinitions = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected
			? selected.definitions
			: null;
	};

export const eventStreaming = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected
			? selected.streaming
			: null;
	};

export const streamingEventHasBegun = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected && selected.streaming
			? selected.streaming.eventHasBegun
			: null;
	};

export const eventStreamingSettings = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected && selected.streaming
			? selected.streaming.settings
			: null;
	};

export const eventStreamingRooms = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected && selected.streaming
			? selected.streaming.streams.all.map(streamId =>
				selected.streaming.streams.byId[streamId])
			: null;
	};

export const isEventStreamingFetching = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected && selected.streaming
			? selected.streaming.isFetching
			: null;
	};

export const isEventStreamingModalOpen = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected && selected.streaming
			? selected.streaming.isModalOpen
			: null;
	};

export const eventStreamingTickets = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected && selected.streaming
			? selected.streaming.ticketTypes
			: null;
	};

export const eventCollections = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected
			? selected.collections
			: null;
	};

export const eventWidget = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected
			? selected.widget
			: null;
	};

export const eventScheduleTransferOptions = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected
			&& selected.scheduleTransfer
			&& selected.scheduleTransfer.scheduleItems
			? selected.scheduleTransfer.scheduleItems.all.map(schedId =>
				selected.scheduleTransfer.scheduleItems.byId[schedId])
			: null;
	};

export const eventScheduleTransferSettings = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected && selected.scheduleTransfer
			? selected.scheduleTransfer.settings
			: null;
	};

export const isScheduleTransferFetching = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected && selected.scheduleTransfer
			? selected.scheduleTransfer.isFetching
			: null;
	};

export const EventScanners = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected && selected.scanners
			? selected.scanners.scanners.all.map(ScannerId =>
				selected.scanners.scanners.byId[ScannerId])
			: null;
	};

export const eventWebhooks = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected && selected.webhooks
			? selected.webhooks.webhooks
			: null;
	};

export const isWebhooksFetching = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected && selected.webhooks
			? selected.webhooks.isFetching
			: null;
	};

export const testWebhooks = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected && selected.webhooks
			? selected.webhooks.testResponse
			: null;
	};

export const isWebhooksModalOpen = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected && selected.webhooks
			? selected.webhooks.isModalOpen
			: null;
	};

export const isWebhooksTestModalOpen = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected && selected.webhooks
			? selected.webhooks.isTestModalOpen
			: null;
	};

export const eventRequestFieldServices = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected && selected.requestFieldServices
			? selected.requestFieldServices.ratesUrl
			: null;
	};

export const isEventRequestFieldServicesFetching = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected && selected.requestFieldServices
			? selected.requestFieldServices.isFetching
			: null;
	};

export const eventAtTheDoor = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);
		return selected && selected.atTheDoor
			? selected.atTheDoor.atTheDoor
			: null;
	};

export const isAtTheDoorFetching = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected && selected.atTheDoor
			? selected.atTheDoor.isFetching
			: null;
	};

export const eventOrdersList = (id: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected && selected.orders
			? selected.orders.orders
			: null;
	};

export const eventOrderDetails = (id: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);

		return selected && selected.orders
			? selected.orders.activeOrder
			: null;
	};

export const eventPromoters = (id?: SchemeID) => (state: AppState) => {
	const selected = event(id)(state);

	return selected && selected.promotersPageInfo
		? selected.promotersPageInfo
		: null;
};

export const eventPromoterNetworks = (id?: SchemeID) => (state: AppState) => {
	const selected = event(id)(state);
	return selected && selected.promoterNetworksPageInfo
		? selected.promoterNetworksPageInfo
		: null;
};

export const GuestListInfo = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);
		return selected && selected.guestList.guestListInfo
			? selected.guestList.guestListInfo
			: null;
	};

export const SelectedGuestInfo = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);
		return selected && selected.guestList.selectedGuestInfo
			? selected.guestList.selectedGuestInfo
			: null;
	};

export const registrationSettings = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);
		return selected && selected.eventRegistrations.registrationSettings
			? selected.eventRegistrations.registrationSettings
			: null;
	};

export const eventRegisteredUsers = (id?: SchemeID) =>
	(state: AppState) => {
		const selected = event(id)(state);
		return selected && selected.eventRegistrations.registrations
			? {
				registrations: selected.eventRegistrations.registrations,
				totalRegistrations: selected.eventRegistrations.totalRegistrations,
			}
			: null;
	};


