import { NgModule } from '@angular/core';

import { EventTicketsComponent } from './event-tickets.component';
import { EventSharedModule } from '@app/features/event-shared/event-shared.module';
import { SharedModule } from '@app/shared/shared.module';
import { DatePipe } from '@angular/common';

@NgModule({
	imports: [
		EventSharedModule,
		SharedModule,
	],
	exports: [EventTicketsComponent],
	declarations: [EventTicketsComponent],
	providers: [DatePipe],
})
export class EventTicketsModule { }
