import { Component, Inject, Optional } from '@angular/core';
import { EventSideNavItems, SectionRouteCard } from '@app/models/shared';
import { OVERLAY_DATA, OverlayService } from '@app/services/overlay/overlay.service';
import { StoreService } from '@app/services/store/store.service';
import { Go } from '@app/store/actions/router/router.actions';
import { handleOrder } from '@app/utils/common-helpers';
import { EVENT_NAV } from '@app/utils/consts';
import { Dictionary } from '@ngrx/entity';
import { convertToIdFormat } from '@app/utils/common-helpers';


@Component({
	selector: 'app-mobile-event-nav',
	templateUrl: './mobile-event-nav.component.html',
	styleUrls: ['./mobile-event-nav.component.sass'],
})
export class MobileEventNavComponent {
	navItems: Dictionary<EventSideNavItems> = EVENT_NAV;
	currentNavItem: SectionRouteCard;
	currentEventNav: string;
	handleDefaultOrder = handleOrder;
	constructor(
		private store: StoreService,
		private overlayService: OverlayService,
		@Optional() @Inject(OVERLAY_DATA) public data: {
			currentNavItem: SectionRouteCard;
			currentEventNav: string;
			eventId: number;
		}
	) { }

	ngOnInit(): void {
		this.currentNavItem = this.data.currentNavItem;
		this.currentEventNav = this.data.currentEventNav;
	}

	handleNavigation(navItem: SectionRouteCard) {
		this.store.dispatch(new Go({ path: [navItem.routeTo(this.data.eventId)] }));
		this.overlayService.close();
	}

	formatId(itemTitle: string, prefix: string ): string {
		return convertToIdFormat(itemTitle, prefix);
	}
}
