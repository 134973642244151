<form
	*ngIf="form"
	class="l-form-container"
	[ngClass]="{ 'mt-2': isMobile, 'm-3 ps-2': !isMobile && isOrganiserHub }"
>
	<div>
		<button
			*ngIf="!isEventCreation && form.get('id').value"
			id="view-profile-page"
			class="g-flat-button l-links"
			(click)="navigateToOrganiserProfile()"
		>
			<span class="l-icon-text">
				<span>View Organiser Profile</span>
				<mat-icon class="l-icon">open_in_new</mat-icon>
			</span>
		</button>
	</div>
	<div *ngIf="form.get('id').value && linkedProducts.length && linkedProducts.length">
		<button
			id="profile-active-products"
			class="g-flat-button l-links"
			(click)="viewLinkedProducts()"
		>
			<span class="l-icon-text">
				<span>View Linked Products</span>
				<mat-icon class="l-icon">open_in_new</mat-icon>
			</span>
		</button>
	</div>
	<div
		class="l-organiser-dropdown"
		*ngIf="organiserProfileOptions && (organiserProfileOptions.length > 0)"
	>
		<label class="g-form-label">Profile<span class="g-orange">*</span></label>
		<mat-select
			id='organiser-select'
			class="form-control w-100"
			name="organiserSelect"
			placeholder="Select a previous Organiser Profile"
			[formControl]="form.get('id')"
			(selectionChange)="toggleOrganiserSelection()"
		>
			<mat-option
				[value]="null"
				id="create-new-profile"
			>
				<span class="l-icon-text"><mat-icon>add</mat-icon>Create new organiser profile</span>
			</mat-option>
			<mat-option
				*ngFor="let option of organiserProfileOptions; let i = index"
				[value]="option.value"
				[id]="'organiser-profile-option-' + (i+1)"
			>
				{{option.label}}
			</mat-option>
		</mat-select>
	</div>

	<hr class="my-4" />
	<div class="g-double-input-container w-100">
		<div [ngClass]="{ 'input-group-mobile': isMobile, 'input-group-web flex-grow-1': !isMobile }">
			<app-mat-text-control-field
				label="Profile Name"
				placeholder="My Organisation"
				[control]="form.get('name')"
				id="profile-name-input"
				[required]="true"
				labelClass="g-form-label"
				outerClass="''"
			>
			</app-mat-text-control-field>
		</div>
		<div [ngClass]="{ 'input-group-mobile': isMobile, 'input-group-web flex-grow-1': !isMobile }">
			<app-mat-text-control-field
				label="Profile Email"
				placeholder="jane@doe.com"
				[control]="form.get('email')"
				id="profile-email-input"
				[required]="true"
				labelClass="g-form-label"
				outerClass="''"
			>
			</app-mat-text-control-field>
		</div>
	</div>
	<div class="w-100 mb-3">
		<label class="g-form-label">
			Profile description
			<mat-icon
				class="l-tooltip-icon"
				#descriptionTooltip="matTooltip"
				matTooltip="Provide some details about you, your brand or your organisation for your profile page."
				matTooltipPosition="above"
				(click)="descriptionTooltip.toggle()"
			>
				info
			</mat-icon>
		</label>
		<app-text-editor
			[control]="form.get('description')"
			[editorStyle]="{'height': '220px', 'width': '100%'}"
			[imageUploadHandler]="editorImageUploadHandler"
			id="profile-description"
		>
		</app-text-editor>
	</div>
	<app-image-upload-and-cropper
		label="Profile Banner Image"
		uploadText="This will appear across the top of your organiser profile page."
		[eventId]="form.get('description').value"
		[originalImage]="form.get('originalImage').value"
		[base64]="base64String"
		[imageServiceType]="ImageServiceTypeEnum.PRODUCT_DESCRIPTION"
		[isMobile]="isMobile"
		[removeImage]="removeImage"
		(croppedBase64Change)="handleImageCrop($event)"
		(imageStringChange)="handleImageStringChange($event)"
		(originalImageChange)="handleOriginalImageChange($event)"
		(imageRemoved)="handleImageRemove()"
		(thumbnailChange)="handleThumbnailChange($event)"
		tooltip="File types accepted are JPG, PNG, GIF or BMP. You can add or change the image at any time from your dashboard."
		id="profile-banner-image"
	>
	</app-image-upload-and-cropper>

	<div class="w-100 d-flex justify-content-center">
		<mat-divider class="w-100 my-4"></mat-divider>
	</div>
	<div class="g-double-input-container w-100">
		<div [ngClass]="{ 'input-group-mobile': isMobile, 'input-group-web flex-grow-1': !isMobile }">
			<div
				class="d-flex flex-row w-100"
				[ngClass]="{'l-toggle-group-mobile' : isMobile,'l-toggle-container': !isMobile}"
			>
				<label class="pt-2 g-form-label">
					Allow Event Reviews
					<mat-icon
						class="l-tooltip-icon"
						#showReviewsTooltip="matTooltip"
						matTooltip="If ON, guests will receive an email to rate and review your events which you can view from your dashboard."
						matTooltipPosition="above"
						(click)="showReviewsTooltip.toggle()"
					>
						info
					</mat-icon>
				</label>
				<mat-slide-toggle
					class="standard-toggle l-toggle"
					[formControl]="form.get('allowReviews')"
					id="allow-reviews-toggle"
				>
				</mat-slide-toggle>
			</div>
		</div>
		<div [ngClass]="{ 'input-group-mobile': isMobile, 'input-group-web flex-grow-1': !isMobile }">
			<div
				class="d-flex flex-row w-100"
				[ngClass]="{'l-toggle-group-mobile' : isMobile,'l-toggle-container': !isMobile}"
			>
				<label class="pt-2 g-form-label">
					Display Top Event Reviews
					<mat-icon
						class="l-tooltip-icon"
						#showDisplayReviewsTooltip="matTooltip"
						matTooltip="If ON, your top reviews from previous events will be displayed on your organiser profile."
						matTooltipPosition="above"
						(click)="showDisplayReviewsTooltip.toggle()"
					>
						info
					</mat-icon>
				</label>
				<mat-slide-toggle
					class="standard-toggle l-toggle"
					[formControl]="form.get('displayReviews')"
					id="display-reviews-toggle"
				>
				</mat-slide-toggle>
			</div>
		</div>
	</div>

	<div class="g-double-input-container w-100">
		<div [ngClass]="{ 'input-group-mobile': isMobile, 'input-group-web flex-grow-1': !isMobile }">
			<div
				class="d-flex flex-row w-100"
				[ngClass]="{'l-toggle-group-mobile' : isMobile,'l-toggle-container': !isMobile}"
			>
				<label class="pt-2 g-form-label">
					Display Private Events
					<mat-icon
						class="l-tooltip-icon"
						#showPrivateTooltip="matTooltip"
						matTooltip="If ON, your upcoming events listed as PRIVATE will be displayed on your organiser profile page but will not be searchable on Quicket or other search engines."
						matTooltipPosition="above"
						(click)="showPrivateTooltip.toggle()"
					>
						info
					</mat-icon>
				</label>
				<mat-slide-toggle
					class="standard-toggle l-toggle"
					[formControl]="form.get('displayPrivateEvents')"
					id="display-private-toggle"
				>
				</mat-slide-toggle>
			</div>
		</div>
	</div>
	<div class="w-100 d-flex justify-content-center">
		<mat-divider class="w-100 my-4"></mat-divider>
	</div>
	<div class="g-double-input-container w-100 mb-3">
		<div [ngClass]="{ 'input-group-mobile': isMobile, 'input-group-web flex-grow-1': !isMobile }" class="l-overflow">
			<app-phone-input
				label="Telephone"
				outerClass="''"
				[control]="form.get('telephone')"
				id="profile-telephone-input"
			>
			</app-phone-input>
		</div>
		<div [ngClass]="{ 'input-group-mobile': isMobile, 'input-group-web flex-grow-1': !isMobile }" class="l-overflow">
			<app-phone-input
				label="Mobile"
				[control]="form.get('mobile')"
				id="profile-mobile-input"
			>
			</app-phone-input>
		</div>
	</div>
	<div class="g-double-input-container w-100 mb-0">
		<div [ngClass]="{ 'input-group-mobile': isMobile, 'input-group-web flex-grow-1 ': !isMobile }">
			<mat-slide-toggle
				class="standard-toggle"
				[formControl]="form.get('showTelephone')"
				id="show-telephone-toggle"
			>
				<label class="ps-2 pt-2 g-form-label">Display Telephone On Page</label>
			</mat-slide-toggle>
		</div>
		<div [ngClass]="{ 'input-group-mobile': isMobile, 'input-group-web flex-grow-1': !isMobile }">
			<mat-slide-toggle
				class="standard-toggle"
				[formControl]="form.get('showMobile')"
				id="show-mobile-toggle"
			>
				<label class="ps-2 pt-2 g-form-label">Display Mobile On Page</label>
			</mat-slide-toggle>
		</div>
	</div>

	<div class="w-100 d-flex justify-content-center">
		<mat-divider class="w-100 my-4"></mat-divider>
	</div>
	<div class="g-double-input-container w-100">
		<div [ngClass]="{ 'input-group-mobile': isMobile, 'input-group-web flex-grow-1': !isMobile }">
			<app-mat-text-control-field
				label="VAT Number"
				placeholder="Vat Number"
				tooltip="This will be displayed on your event invoice"
				[control]="form.get('vatNumber')"
				id="profile-vat-input"
				labelClass="g-form-label"
				outerClass="''"
			>
			</app-mat-text-control-field>
		</div>
		<div [ngClass]="{ 'input-group-mobile': isMobile, 'input-group-web flex-grow-1': !isMobile }">
			<app-mat-text-control-field
				label="Company"
				placeholder="Company"
				tooltip="This will be displayed on your event invoice"
				[control]="form.get('company')"
				id="profile-company-input"
				labelClass="g-form-label"
				outerClass="''"
			>
			</app-mat-text-control-field>
		</div>
	</div>
	<div class="g-double-input-container w-100">
		<app-address-lines
			label="Address"
			tooltip="This will be displayed on your event invoice"
			[control]="form.get('address')"
			class="w-100"
		>
		</app-address-lines>
	</div>
	<div class="g-double-input-container w-100">
		<div [ngClass]="{ 'input-group-mobile': isMobile, 'input-group-web flex-grow-1': !isMobile }">
			<app-mat-text-control-field
				label="Facebook Page URL"
				placeholder="e.g. https://www.facebook.com/yourpagename"
				[control]="form.get('facebookUrl')"
				id="profile-facebook-input"
				labelClass="g-form-label"
			>
			</app-mat-text-control-field>
		</div>
	</div>
	<div class="g-double-input-container w-100">
		<div [ngClass]="{ 'input-group-mobile': isMobile, 'input-group-web flex-grow-1': !isMobile }">
			<app-mat-text-control-field
				label="Website URL"
				placeholder="e.g. https://www.example.com"
				[control]="form.get('websiteUrl')"
				id="profile-website-input"
				labelClass="g-form-label"
			>
			</app-mat-text-control-field>
		</div>
		<div [ngClass]="{ 'input-group-mobile': isMobile, 'input-group-web flex-grow-1': !isMobile }">
			<app-mat-text-control-field
				label="Spotify Embedded Link"
				placeholder='e.g. https://open.spotify.com/album/0mpGOZlTKJmtUh4Fu82I4K?si=8kGH..."'
				[control]="form.get('spotifyLink')"
				id="profile-spotify-input"
				labelClass="g-form-label"
				tooltip="Click the '...' next to a song, playlist or album on Spotify. Then click 'Share', then 'Copy Link'. Then paste the link in the field here to embed it on your event page."
			>
			</app-mat-text-control-field>
		</div>
	</div>
	<div class="g-double-input-container w-100 align-items-center">
		<div [ngClass]="{ 'input-group-mobile': isMobile, 'input-group-web flex-grow-1': !isMobile }">
			<app-mat-text-control-field
				label="X Handle (Twitter)"
				placeholder="jane_doe"
				[control]="form.get('twitterHandle')"
				customIcon="alternate_email"
				id="profile-twitter-input"
				labelClass="g-form-label"
			>
			</app-mat-text-control-field>
		</div>
		<div [ngClass]="{ 'input-group-mobile': isMobile, 'input-group-web flex-grow-1  l-twitter-toggle': !isMobile }">
			<mat-slide-toggle
				class="standard-toggle"
				[formControl]="form.get('showTwitterFeed')"
				id="show-twitter-feed-toggle"
			>
				<label class="ps-2 pt-2 g-form-label">Display Twitter feed on page</label>
			</mat-slide-toggle>
		</div>
	</div>
	<div class="d-flex flex-column" *ngIf="!isEventCreation">
		<app-mat-text-area-control-field
			[control]="form.get('profilePageEmbed')"
			[inputClass]="'l-form-field form-control l-text-area'"
			[readonly]="true"
			(textAreaClick)="handleCopy()"
			label="Profile Page Embed Code"
			placeholder=""
			customId="profile-profilePageEmbed"
			tooltip="If you have your own website, simply copy the code below to embed your organiser profile on your site."
		>
		</app-mat-text-area-control-field>
		<div
			class='d-flex gap-1 l-copy'
			id="copy-embedded"
			(click)='handleCopy()'
		>
			<mat-icon class='g-green l-icon'>file_copy</mat-icon>
			<span class="g-green"> Copy embedded code</span>
		</div>
	</div>
</form>