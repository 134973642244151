<div
	class="l-container"
	[ngClass]="{'l-container-mobile': isMobile, 'l-container-web': !isMobile}"
>
	<div class="l-header-container">
		<div>
			<h3 class='mb-3'>Organiser Profiles</h3>
			<p class='l-text'>You can have multiple profiles on Quicket to organise your events. Each
				profile has it owns public Quicket page that displays your public events.</p>
		</div>
	</div>
	<div class="l-content mb-4">
		<div
			class="l-card-container"
			*ngIf='!isProfilesLoading; else profilesLoading'
		>
			<ng-container *ngIf="profiles.length > 0; else noProfiles">
				<ng-container *ngFor="let profile of displayList">
					<a
						class="g-flat-link l-contents"
						[routerLink]="[handleRouterLink(profile.id)]"
						(click)="onLinkClick($event)"
					>
						<app-profile-card
							[thumbnail]="profile.thumbnail"
							[name]='profile.name'
							(delete)='handleDeleteProfile(profile)'
							class='l-profile-card'
						>
						</app-profile-card>
					</a>
				</ng-container>
			</ng-container>
			<ng-container *ngIf="moreProfilesToShow">
				<button class="g-flat-button g-green g-pointer" (click)="handleShowMore()"> Show more</button>
			</ng-container>
			<ng-template #noProfiles>
				<div class="l-empty-state">
					<app-no-products-card
						[title]="'You don\'t have any Organiser Profiles'"
						[content]="'Create a profile now! And start organising your event.'"
					></app-no-products-card>
				</div>
			</ng-template>
		</div>
		<ng-template #profilesLoading>
			<div
				class="l-empty-state"
				[ngClass]="{'my-3': isMobile, 'my-5': !isMobile}"
			>
				<div class="l-loading">
					<mat-spinner></mat-spinner>
				</div>
			</div>
		</ng-template>
	</div>
	<div class="l-header-container mt-5">
		<div>
			<h3 class='l-title'>Integrations</h3>
			<p class='l-text'>Manage integrations to use with and enhance your events.</p>
		</div>
	</div>
	<div class="l-integration-content">
		<a
			*ngFor="let integration of integrations | keyvalue: handleDefaultOrder"
			class="g-flat-link l-card"
			[routerLink]="[handleIntegrationsRouterLink(integration.key)]"
		>
			<app-integration-card [data]="integration.value"></app-integration-card>
		</a>
		<a
			class="g-flat-link l-card"
			[href]="zapier.routeTo()"
			target="_blank"
		>
			<app-integration-card [data]="zapier"></app-integration-card>
		</a>
	</div>
</div>