import { Component, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { FormField } from '../form-field.model';
import { SelectField, SelectFieldOption } from './select-field.model';
import { MultiSelect } from 'primeng/multiselect';
import { Dropdown } from 'primeng/dropdown';
@Component({
	selector: 'app-select-field',
	templateUrl: './select-field.component.html',
	styleUrls: ['./select-field.component.sass', '../form-field.component.sass'],
})
export class SelectFieldComponent extends FormField implements SelectField, AfterViewInit {
	private itemHeight = 39;
	@Input() allowReset = true;
	@Input() options: SelectFieldOption[] = [];
	@Input() itemsToDisplayInList = 5;
	@Input() multiple = false;
	@Input() disabled = false;
	@Input() emptyPreset: SelectFieldOption = {
		label: '- None -',
		value: null,
	};
	@Output() blur = new EventEmitter();
	@Output() change = new EventEmitter();
	@ViewChild('multiSelect') multiSelect: MultiSelect;
	@ViewChild('dropdown') dropdown: Dropdown;

	ngAfterViewInit() {
		if (this.multiSelect && this.multiSelect.onPanelShow) {
			this.multiSelect.onPanelShow.subscribe(() => {
			});
		}
		if (this.dropdown && this.dropdown.onShow) {
			this.dropdown.onShow.subscribe(() => {
			});
		}
	}

	getScrollHeight(): string {
		return `${this.itemHeight * this.itemsToDisplayInList}px`;
	}

	getOptions() {
		const options = [...this.options];

		if (options.length && this.control.value && this.emptyPreset) {
			options.unshift(this.emptyPreset);
		}

		return options;
	}

	onChange(event) {
		this.change.emit(event);
	}
}
