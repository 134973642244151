<app-info-card
	title="Total Guests"
	actionText="Export"
	actionIcon="fa-solid fa-arrow-up-from-bracket"
	[tooltipText]="tooltip"
	(actionClick)="handleGuestListExport()"
	[prefix]="totalGuestsString"
	[suffix]="maxGuestsString"
	[border]="false"
	[isLoading]="isLoading"
>
</app-info-card>