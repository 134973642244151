import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { FilterGroup, FilterOption, Filter, SortOption } from '@app/models/filter-and-sort.model';


@Component({
	selector: 'app-mobile-filter-bottomsheet',
	templateUrl: './mobile-filter-bottomsheet.component.html',
	styleUrls: ['./mobile-filter-bottomsheet.component.sass'],
})
export class MobileFilterBottomsheetComponent implements OnInit {
	sortOptions = SortOption;
	selectedSort: SortOption;
	selectedFilter: Filter;
	filterType: string;
	constructor(
		private bottomSheetRef: MatBottomSheetRef<MobileFilterBottomsheetComponent>,
		@Inject(MAT_BOTTOM_SHEET_DATA) public data: {
			filterOptions: FilterGroup[];
			currentTicketFilter: FilterOption;
			currentTicketSort: SortOption;
			showFilterOptions: boolean;
		}
	) { }

	ngOnInit(): void {
		this.selectedFilter = this.data.currentTicketFilter?.selectedFilter;
		this.selectedSort = this.data.currentTicketSort;
	}

	handleFilter() {
		if (this.selectedFilter) {
			this.filterType = this.data.filterOptions.find(
				(filterGroup) => filterGroup.filter.some(
					(filter) => filter.viewValue === this.selectedFilter?.viewValue)).name;
		}
		this.bottomSheetRef.dismiss({
			sortOption: this.selectedSort,
			filterOption: { filterType: this.filterType, selectedFilter: this.selectedFilter },
		});
	}

}
