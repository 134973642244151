import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { Injectable, InjectionToken, Injector } from '@angular/core';
import { take } from 'rxjs';

export const OVERLAY_DATA = new InjectionToken<any>('OVERLAY_DATA');

@Injectable({
	providedIn: 'root',
})
export class OverlayService {
	constructor(
		private overlay: Overlay,
		private injector: Injector
	) { }
	private overlayRef: OverlayRef | null = null;

	open(component: ComponentType<any>, data?: any) {
		const isFullHeight = data?.fullHeight;
		const overlayConfig = this.getOverlayConfig(isFullHeight);
		this.overlayRef = this.overlay.create(overlayConfig);

		const customInjector = Injector.create({
			parent: this.injector,
			providers: [{ provide: OVERLAY_DATA, useValue: data }],
		});

		const componentPortal = new ComponentPortal(component, null, customInjector);
		this.overlayRef.attach(componentPortal);
		this.overlayRef.backdropClick().pipe(take(1)).subscribe(() => this.close());
	}

	close() {
		if (this.overlayRef) {
			this.overlayRef.detach();
			this.overlayRef = null;
		}
	}

	private getOverlayConfig(isFullHeight: boolean): OverlayConfig {
		const positionStrategy = this.overlay.position().global().top('0').left('0');

		const overlayConfig = new OverlayConfig({
			hasBackdrop: true,
			backdropClass: 'cdk-overlay-dark-backdrop',
			panelClass: 'g-wide-dialog',
			positionStrategy,
			height: isFullHeight ? '100%' : 'auto',
		});
		return overlayConfig;
	}
}
