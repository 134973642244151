import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class BreakpointService {
	public isMobile$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public isWeb$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor() { }

	isMobile(isMobile: boolean) {
		this.isMobile$.next(isMobile);
	}

	isWeb(isWeb: boolean) {
		this.isWeb$.next(isWeb);
	}
}
