import { Component, OnInit, OnDestroy, EventEmitter, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { omit } from 'lodash';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { EventCreationService } from '@app/services/event-creation/event-creation.service';
import { GetEvent, UpdateEvent, SetActiveStepIndex } from '@app/store/actions/event/event.actions';
import MoneyPresenter from '@app/utils/MoneyPresenter';
import {
	EventEntity,
	Venue,
	EventQuestion,
	EventImage,
	EventPart,
	EventCategory,
	EventFormat,
	EventFeatureOptions,
	ProductCreationStep
} from '@app/models/event.model';
import { EventOrganiserProfile } from '@app/models/profile.model';
import * as organiserSelectors from '@app/store/selectors/organiserProfile.selector';
import * as eventSelectors from '@app/store/selectors/event.selector';
import { CreateProfile } from '@app/store/actions/organiserProfiles/organiserProfiles.actions';
import { DetachEvent } from '@app/store/actions/event/event.actions';
import { StoreService, SCTypes } from '@app/services/store/store.service';
import { TicketsService } from '@app/services/tickets/tickets.service';
import { EventDetailsService } from '@app/services/event-details/event-details.service';
import { OrganiserProfileservice } from '@app/services/organiser-profile/organiser-profile.service';
import { GetSeatingCategories } from '@app/store/actions/tickets/tickets.actions';
import { userDesignerKey, user } from '@app/store/selectors/user.selector';
import { FormCanDeactivate } from '@app/services/guards/event.guard';
import { Observable, Subscription } from 'rxjs';
import { userCharts } from '@app/store/selectors/userCharts.selector';
import { SeatingCategory, UserChart, QuantityWarningType } from '@app/models/ticket.model';
import { Title } from '@angular/platform-browser';
import { EventEntityState, UserState } from '@app/models/store.model';
import { ScheduleService } from '@app/services/schedule/schedule.service';
import { GetUserCharts } from '@app/store/actions/user/user.actions';
import { UserContactInfoForm } from '@app/models/event.model';
import { SetUserInfo } from '@app/store/actions/user/user.actions';
import { map, filter, startWith, takeUntil } from 'rxjs/operators';
import { SelectFieldOption } from '@app/shared/form-field/select-field/select-field.model';
import { SchemeID } from '@app/models/dataSchema.model';
import { StorageKey } from '@app/models/storage.model';
import { ActivatedRoute } from '@angular/router';
import { ProductRoute } from '@app/models/url.model';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { AnimationOptions } from 'ngx-lottie';
import { Go } from '@app/store/actions/router/router.actions';
import { InternalURLCreator } from '@app/services/url/url.dictionary';
import { AnimationItem } from 'lottie-web';
import { MatDialog } from '@angular/material/dialog';
import { QuantityWarningModalComponent } from '@app/shared/quantity-warning-modal/quantity-warning-modal.component';
import { MatStepper } from '@angular/material/stepper';
import { CdkStep } from '@angular/cdk/stepper';
import { LeavingConfirmationModalComponent } from './modals/leaving-confirmation-modal/leaving-confirmation-modal.component';
import { OrganiserProfileActionConstants } from '@app/store/actions/organiserProfiles/organiserProfiles.constants';
import { Actions, ofType } from '@ngrx/effects';
import { EventActionsConstants } from '@app/store/actions/event/event.actions.constants';

@Component({
	selector: 'app-event-creation',
	templateUrl: './event-creation.component.html',
	styleUrls: ['./event-creation.component.sass'],
})
export class EventCreationComponent extends FormCanDeactivate implements OnInit, OnDestroy {
	activeStepIndex: number;
	recoveredInitialStep = null;
	stepperFirstLoad = true;
	eventCreationForm: UntypedFormGroup;
	eventProfileForm: UntypedFormGroup;
	eventDetailsForm: UntypedFormGroup;
	eventDetailsFormValidators: any[];
	eventTicketsForm: UntypedFormGroup;
	organiserProfileOptions = [];

	previousVenues$: Observable<Venue[]>;
	questions$: Observable<EventQuestion[]>;
	userCharts$: Observable<UserChart[]>;
	currencies$: Observable<
	{ value: { id: SchemeID; code: string; defaultCommission: number; defaultServiceFee: number }; title: string }[]
	>;
	userDesignerKey$: Observable<string>;
	isFetching$: Observable<boolean>;
	isCreationFetching$: Observable<boolean>;
	eventTypes$: Observable<{
		formats: SelectFieldOption<SchemeID>[];
		categories: SelectFieldOption<SchemeID>[];
		subcategories: { [key: number]: SelectFieldOption<SchemeID>[] };
	}>;

	isUserIntro = !localStorage.getItem(StorageKey.EventCreationUserIntro);
	isEmptyUserContactInfo: boolean;
	isUserContactInfoForm = true;
	userContactInfoForm: UntypedFormGroup;

	ticketsQuantityWarningType: QuantityWarningType;

	organiserProfiles: EventOrganiserProfile[] = [];
	isProfileAdding = false;
	event: EventEntity;
	image: EventImage;
	seatingCategories: SeatingCategory[];
	isDeactivationModalOpen = false;
	stepWithUnsavedChanges = [];
	disconnectStore: SCTypes.UnsubscribeFn;
	organiserChangeSub: Subscription;
	ProductRoute = ProductRoute;
	eventFeatureOptions$: Observable<EventFeatureOptions[]>;
	selectedOrganiser$: Observable<EventOrganiserProfile>;
	dataLayer = window['dataLayer'];
	routeData = this.route.snapshot.data['productType'];
	isEventCreation = true;
	isMobile: boolean;
	isEventLoading = true;
	creationStep = 0;
	ticketsRequestSuccess = false;

	eventPartEnum = EventPart;

	options: AnimationOptions = {
		path: './assets/lotties/success.json',
		loop: false,
	};

	private animationItem: AnimationItem;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	@ViewChild('stepper') formStepper: MatStepper;
	@ViewChild('container') container: ElementRef;

	constructor(
		private breakpointService: BreakpointService,
		private formBuilder: UntypedFormBuilder,
		private ticketsService: TicketsService,
		private eventDetailsService: EventDetailsService,
		private organiserProfileService: OrganiserProfileservice,
		private eventCreationService: EventCreationService,
		private store: StoreService,
		private titleService: Title,
		private scheduleService: ScheduleService,
		private route: ActivatedRoute,
		private cdr: ChangeDetectorRef,
		private dialog: MatDialog,
		private actions$: Actions
	) {
		super();
		this.store.dispatch(new DetachEvent());
	}

	ngOnInit() {
		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});
		this.isCreationFetching$ = this.store.select(eventSelectors.isFetching());
		this.isFetching$ = this.store.select((state) => eventSelectors.entityisFetching()(state) || organiserSelectors.isFetching()(state));
		this.currencies$ = this.store.select(eventSelectors.currencies()).pipe(
			map((currencies) =>
				currencies.map(({ id, iso, defaultCommission, defaultServiceFee }) => ({
					value: {
						id,
						code: iso,
						defaultCommission,
						defaultServiceFee,
					},
					title: `${MoneyPresenter.getCurrencyName(iso)}`,
				}))
			)
		);
		this.previousVenues$ = this.store.select(eventSelectors.venues());
		this.questions$ = this.store.select(eventSelectors.questions());
		this.userCharts$ = this.store.select(userCharts());
		this.userDesignerKey$ = this.store.select(userDesignerKey());
		this.eventFeatureOptions$ = this.store.select(eventSelectors.eventFeatureOptions());
		this.selectedOrganiser$ = this.store.select(eventSelectors.eventOrganiser());
		this.eventTypes$ = this.store.select(eventSelectors.eventTypes()).pipe(
			startWith({ categories: [], formats: [], subcategories: [] }),
			filter((value: { formats: EventFormat[]; categories: EventCategory[] }) => !!value),
			map((value) => ({
				formats: value.formats.map(({ id, name }) => ({
					value: id,
					label: name,
				})),
				categories: value.categories.map(({ id, name }) => ({
					value: id,
					label: name,
				})),
				subcategories: value.categories.reduce((acc, { subcategories, id }) => {
					acc[id] =
						subcategories && subcategories.length
							? subcategories.map((el) => ({
								value: el.id,
								label: el.name,
							  }))
							: null;
					return acc;
				}, {}),
			}))
		);

		this.selectedOrganiser$.pipe(takeUntil(this.destroyed$)).subscribe((profile) => {
			if (profile) {
				this.pushDataLayer(profile);
			}
		});

		this.disconnectStore = this.store.connect(
			new Map<SCTypes.Selector, SCTypes.Subscriber>([
				[eventSelectors.activeStepIndex(), this.onActiveStepIndexUpdate],
				[organiserSelectors.organisers(), this.onOrganiserUpdate],
				[user(), this.onUserUpdate],
				[eventSelectors.event(), this.onEventUpdate],
			])
		);

		this.initializeProfileCreationListeners();
		this.initializeUpdateEventListeners();

		this.store.dispatch(
			new GetEvent({
				productType: 1,
			})
		);

	}

	ngOnDestroy() {
		if (this.organiserChangeSub) {
			this.organiserChangeSub.unsubscribe();
		}
		this.disconnectStore();
		this.destroyed$.next();
	}

	getTicketTypeFormEventDetails() {
		return {
			commission: this.eventDetailsForm.get('commission').value,
			serviceFee: this.eventDetailsForm.get('serviceFee').value,
			isOneDayEvent: this.eventDetailsForm.get('isOneDayEvent').value,
			timezone: this.eventDetailsForm.get('timezone').value,
			endDateTime: this.eventDetailsForm.get('endDateTime').value,
			ticketStubDescription: this.eventDetailsForm.get('ticketStubDescription').value,
			hasVendors: this.eventDetailsForm.get('hasVendors').value,
		};
	}

	pushDataLayer = (profile: EventOrganiserProfile) => {
		if (this.dataLayer && this.dataLayer !== null) {
			this.dataLayer.push({
				event: 'meta-data-loaded',
				UserId: profile.id,
				Email: profile.email,
				FullName: profile.name,
				Phone: profile.telephone,
			});
		}
	};

	onActiveStepIndexUpdate = (value: number) => {
		this.scrollToTop();
		switch (value) {
			case EventPart.PROFILE:
				this.titleService.setTitle('Select your profile | Quicket');
				break;
			case EventPart.DETAILS:
				this.titleService.setTitle('Add event information | Quicket');
				break;
			case EventPart.TICKETS:
				this.titleService.setTitle('Add ticket types | Quicket');
				break;
			default:
				this.titleService.setTitle('Event creation | Quicket');
		}
		this.activeStepIndex = value;
		this.cdr.detectChanges();

		if (this.activeStepIndex === EventPart.SUCCESS) {
			setTimeout(() => {
				this.animationItem?.play();
			}, 500);
		} else {
			this.animationItem?.stop();
		}
	};

	animationCreated(animationItem: AnimationItem) {
		this.animationItem = animationItem;
	}

	setStepsAsInteracted() {
		if (this.stepperFirstLoad && this.formStepper && this.activeStepIndex > EventPart.PROFILE) {
			this.stepperFirstLoad = false;
			this.formStepper.steps.forEach((matStep: CdkStep, index: number) => {
				if (index < this.activeStepIndex){
					matStep.interacted = true;
				}
			});
		}
	}

	onOrganiserUpdate = (value: EventOrganiserProfile[]) => {
		if (this.organiserProfileOptions.length < value.length && this.isProfileAdding) {
			this.isProfileAdding = false;
			this.eventProfileForm.get('id').setValue(value[value.length - 1].id);
			this.store.dispatch(
				new UpdateEvent({
					id: this.eventDetailsForm.get('eventId').value,
					event: {
						profile: this.eventProfileForm.getRawValue(),
						details: this.eventDetailsForm.getRawValue(),
						ticketsDetails: this.eventTicketsForm.getRawValue(),
					},
					eventPart: EventPart.PROFILE,
				})
			);
		}
		this.organiserProfiles = value;

		this.organiserProfileOptions = value.map(({ name, id }) => ({
			label: name,
			value: id,
		}));
		this.scrollToTop();
	};

	onUserUpdate = (value: UserState) => {
		if (value && value.info && value.info.contactInfo) {
			const { firstName, surname, phone } = value.info.contactInfo;
			if (!firstName || !surname || !phone) {
				this.userContactInfoForm = this.eventCreationService.createEventUserInfoForm({
					firstName,
					surname,
					phone,
				});
				this.isEmptyUserContactInfo = true;
			}

			if (firstName && surname && phone) {
				this.isEmptyUserContactInfo = false;
			}
		}
	};

	onEventUpdate = (value: EventEntityState) => {
		if (value && value.details) {
			if (!this.eventCreationForm) {
				const { details, ticketsDetails, profile } = value;
				this.createEventForm({ details, ticketsDetails, profile }, value.seatingCategories);
				this.store.dispatch(new GetUserCharts({ eventId: details.eventId }));

				if (details.creationStep > ProductCreationStep.StepOne) {
					this.isUserIntro = false;
					this.skipUserInfo();
				}
			} else {
				this.ticketsService.checkAndUpdateTicketsIds(this.eventTicketsForm, value.ticketsDetails.tickets, 'tickets');

				this.ticketsService.checkAndUpdateTicketCategories(value.seatingCategories, this.eventTicketsForm, 'tickets');

				this.ticketsService.checkAndUpdateTicketQuestions(
					this.eventTicketsForm,
					value.ticketsDetails.tickets,
					'tickets',
					'questions'
				); // eslint-disable-line max-len

				this.eventDetailsService.checkAndUpdateVenueId(value.details.venue, this.eventDetailsForm, 'venue.id');

				this.scheduleService.checkAndUpdateScheduleIds(value.details.schedules, this.eventDetailsForm, 'schedules');

				this.scheduleService.checkAndUpdateScheduleDates(value.details, this.eventDetailsForm);

				switch (this.activeStepIndex) {
					case EventPart.PROFILE:
						this.eventProfileForm.markAsPristine();
						break;
					case EventPart.DETAILS:
						this.eventDetailsForm.markAsPristine();
						break;
					case EventPart.TICKETS:
						this.eventTicketsForm.markAsPristine();
						break;
				}
			}
			this.image = value.details.image;
			this.eventDetailsForm.get('image').setValue(this.image);
			this.event = value;

			this.seatingCategories = value.seatingCategories;
			this.isEventLoading = false;
			this.creationStep = this.event.details.creationStep;
		}
	};

	submitForm(product: EventEntity) {
		this.store.dispatch(
			new UpdateEvent({
				id: product.details.eventId,
				event: product,
				eventPart: this.activeStepIndex,
			})
		);
	}

	proceedFormSubmit(): void {
		if (this.activeStepIndex === EventPart.PROFILE && !this.eventProfileForm.get('id').value) {
			const profile = this.eventProfileForm.getRawValue();
			const { eventId } = this.eventDetailsForm.getRawValue();
			this.isProfileAdding = true;
			this.store.dispatch(new CreateProfile({ profile, eventId }));
		} else {
			const product = {
				profile: this.eventProfileForm.getRawValue(),
				details: this.eventDetailsForm.getRawValue(),
				ticketsDetails: this.eventTicketsForm.getRawValue(),
			};

			if (this.activeStepIndex === EventPart.TICKETS) {
				const quantityWarningType = this.ticketsService.checkTicketsQuantity(this.eventTicketsForm.getRawValue().tickets);

				if (quantityWarningType) {
					this.dialog
						.open(QuantityWarningModalComponent, {
							data: {
								warningType: quantityWarningType,
							},
							panelClass: 'g-standard-dialog',
						})
						.afterClosed()
						.subscribe((result: boolean) => {
							if (result) {
								this.submitForm(product);
							}
						});
				} else {
					this.submitForm(product);
				}
			} else {
				this.submitForm(product);
			}
		}
	}

	setStepIndex(index: number): void {
		this.store.dispatch(new SetActiveStepIndex({ index }));
	}

	createEventForm({ details, profile, ticketsDetails }, seatingCategories: SeatingCategory[]): void {
		this.eventProfileForm = this.organiserProfileService.createEventProfileForm(profile);
		const eventDetailsFormData = this.eventDetailsService.createEventDetailsForm(details);
		this.eventDetailsForm = eventDetailsFormData.form;
		this.eventDetailsFormValidators = eventDetailsFormData.dynamicValidatorsKeys;
		this.eventTicketsForm = this.ticketsService.createEventTicketsForm(ticketsDetails, seatingCategories);

		this.eventCreationForm = this.formBuilder.group({
			eventProfileForm: this.eventProfileForm,
			eventDetailsForm: this.eventDetailsForm,
			eventTicketsForm: this.eventTicketsForm,
		});

		const organiserProfileControl = this.eventProfileForm.get('id') as UntypedFormControl;

		this.organiserChangeSub = organiserProfileControl.valueChanges.subscribe(this.onOrganiserProfileChange);
	}

	onOrganiserProfileChange = (value: string): void => {
		if (value) {
			const profile = this.organiserProfiles.find((el) => el.id === Number(value));
			this.eventProfileForm.patchValue(omit(profile, 'id', 'previousVenues'));
		} else {
			if (!this.eventProfileForm.pristine) {
				this.eventProfileForm.patchValue(omit(this.organiserProfileService.profileFormInitialsValues(), 'id', 'previousVenues'));
				this.eventProfileForm.markAsPristine();
				this.eventProfileForm.markAsUntouched();
			}
		}
	};

	createProfile() {
		if (!this.eventProfileForm.get('id').value) {
			this.eventProfileForm.setValue(this.organiserProfileService.profileFormInitialsValues());
		}
		this.eventProfileForm.markAsPristine();
		this.eventProfileForm.markAsUntouched();
	}

	initializeProfileCreationListeners() {
		this.actions$.pipe(ofType(OrganiserProfileActionConstants.CREATE_PROFILE_FAILED), takeUntil(this.destroyed$)).subscribe(() => {
			this.resetValue('eventProfileForm', 'name');
		});
	}

	initializeUpdateEventListeners() {
		this.actions$.pipe(ofType(EventActionsConstants.UPDATE_EVENT_FAILED), takeUntil(this.destroyed$)).subscribe(() => {
			switch (this.activeStepIndex) {
				case EventPart.PROFILE:
					this.resetValue('eventProfileForm', 'name');
					break;
				case EventPart.DETAILS:
					this.resetValue('eventDetailsForm', 'name');
					break;
			}
		});

		this.actions$.pipe(ofType(EventActionsConstants.UPDATE_EVENT_SUCCESS), takeUntil(this.destroyed$)).subscribe(() => {
			if (this.activeStepIndex === EventPart.TICKETS) {
				this.ticketsRequestSuccess = true;
				this.setStepIndex(EventPart.SUCCESS);
			}
			this.formStepper.next();
			this.cdr.detectChanges();
		});
	}

	resetValue(formName: string, controlName: string) {
		this.eventCreationForm.get(formName).get(controlName).setValue(null);
		this.eventCreationForm.get(formName).get(controlName).markAsDirty();
		this.eventCreationForm.get(formName).updateValueAndValidity();
	}

	isFormDeactivatable(): boolean {
		if (this.event) {
			this.stepWithUnsavedChanges = [];

			if (this.eventProfileForm.dirty) {
				this.stepWithUnsavedChanges.push(0);
			}

			if (this.eventDetailsForm.dirty) {
				this.stepWithUnsavedChanges.push(1);
			}

			if (this.eventTicketsForm.dirty) {
				this.stepWithUnsavedChanges.push(2);
			}
		}
		const isFormDeactivatable = this.stepWithUnsavedChanges.length === 0;
		if (isFormDeactivatable) {
			this.disconnectStore();
			this.store.dispatch(new DetachEvent());
		}
		return isFormDeactivatable;
	}

	deactivationFallback(): Observable<boolean> {
		this.dialog
			.open(LeavingConfirmationModalComponent, {
				data: {
					unsavedStep: this.stepWithUnsavedChanges,
					isMobile: this.isMobile,
				},
				panelClass: 'g-standard-dialog',
			})
			.afterClosed()
			.subscribe((result) => {
				this.closeDeactivationModal(result);
			});
		return this.modalCallback.asObservable();
	}

	closeDeactivationModal(isConfirmed: boolean) {
		this.stepWithUnsavedChanges = [];
		if (isConfirmed && this.disconnectStore) {
			this.disconnectStore();
		}
		this.modalCallback.next(isConfirmed);
	}

	getPricing() {
		return this.event
			? {
				taxRateId: this.event.details.taxRateId,
				commission: this.event.details.commission,
				serviceFee: this.event.details.serviceFee,
			  }
			: {};
	}

	isSubmitDisabled() {
		switch (this.activeStepIndex) {
			case EventPart.PROFILE:
				return this.eventProfileForm && this.eventProfileForm.invalid;
			case EventPart.DETAILS:
				return this.eventDetailsForm && this.eventDetailsForm.invalid;
			case EventPart.TICKETS:
				const reservedSeatingCheck = (this.eventTicketsForm.get('isReservedSeating').value
					&& !this.eventTicketsForm.get('seatingChartId').value);
				return (this.eventTicketsForm && this.eventTicketsForm.invalid) || reservedSeatingCheck;
			default:
				return false;
		}
	}

	getSubmitLabel() {
		let label: string;

		switch (this.activeStepIndex) {
			case EventPart.PROFILE:
				label = 'CONTINUE TO EVENT DETAILS';
				break;
			case EventPart.DETAILS:
				label = 'CONTINUE TO CREATE TICKETS';
				break;
			case EventPart.TICKETS:
				label = 'CREATE EVENT';
				break;
			default:
				break;
		}

		return label;
	}

	onTicketChartClose(eventId: number, chartId: string) {
		this.store.dispatch(new GetSeatingCategories({ chartId, eventId }));
	}

	isUserInfoFormShown() {
		return this.isUserContactInfoForm && this.isEmptyUserContactInfo;
	}

	onUserInfoSubmit(value: UserContactInfoForm) {
		this.store.dispatch(new SetUserInfo(value));
		this.isUserContactInfoForm = false;
	}

	skipUserInfo() {
		this.isUserContactInfoForm = false;
	}

	handleEventRoute() {
		this.store.dispatch(new Go({ path: [InternalURLCreator.eventDashboard(this.event.details.eventId)] }));
	}

	scrollToTop(): void {
		if (this.container) {
			this.container.nativeElement.scrollTo({ top: 0, behavior: 'smooth' });
		}
	}
}
