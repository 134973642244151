<app-my-account-header
	[isMobile]='isMobile'
	pageName="Referrals"
	(navigateBack)="handleNavigationBack()"
>
</app-my-account-header>
<div class="g-account-container">
	<div
		class="g-account-content"
		[ngClass]="{ 'mobile': isMobile, 'web': !isMobile }"
	>
		<app-web-content-header
			class="w-100"
			[headerContent]="headerContent"
			[isMobile]="isMobile"
			[mediumWidth]='true'
			[customDescription]="customDescription"
			[readMoreText]='readMoreText'
			includeReadMore='true'
			(readMore)='handleReadMore()'
		></app-web-content-header>

		<div
			class="d-flex flex-column pb-3"
			*ngIf="!isPageLoading; else pageLoader"
		>
			<div
				*ngIf="!isLoading; else referralCodeLoading"
				class="l-link-container mt-2"
			>
				<app-link-card
					[cardText]="'Customise your affiliate link below:'"
					[primaryButtonText]="'COPY LINK'"
					[isMobile]='isMobile'
					[preText]="referralInfo?.affiliateLinkExcludingCode"
					[link]="referralInfo?.code"
					[icon]="'link'"
					[canEdit]="true"
					[isLoading]="isUpdating"
					[maxLengthLink]="20"
					(primaryButtonClick)="handleCopyLink(referralInfo?.affiliateLinkExcludingCode, $event)"
					(secondaryButtonClick)="handleSaveLink($event)"
				>
				</app-link-card>
				<div class="l-disclaimer">
					By using the Quicket's referral program you agree to the Quicket <span
						(click)="handleTermsNavigation()"
						class="g-pointer fw-bold text-decoration-underline g-highlight"
					>terms and
						conditions.</span>
				</div>
			</div>
			<ng-template #referralCodeLoading>
				<div class='l-loading'>
					<mat-spinner></mat-spinner>
				</div>
			</ng-template>
			<div
				class='l-payment-details'
				[ngClass]="{ 'mt-3': isMobile, 'mt-5': !isMobile }"
			>
				<h3>Commission Earned</h3>
				<p>
					View payments below. Payment will happen every month only for revenue generated for events which
					have
					successfully finished.
				</p>
			</div>
			<app-link-bank-banner
				*ngIf='!linkedAccount'
				[isMobile]='isMobile'
				(handleClick)='handleChooseBankAccount()'
			></app-link-bank-banner>
			<div
				class="l-table-container"
				[ngClass]="{ 'mb-3': isMobile}"
				*ngIf="referralCommission.data?.length; else showEmptyCommissionDetails"
			>
				<table
					mat-table
					[dataSource]="referralCommission"
					[ngClass]="{ 'web': !isMobile, 'mobile': isMobile}"
				>
					<ng-container matColumnDef="info">
						<th
							mat-header-cell
							*matHeaderCellDef
						></th>
						<td
							mat-cell
							*matCellDef="let commission; let i = index"
							[ngClass]="{'l-owed-to-you-data-start' : i === 1, 'l-first-row-item-child' : i !== 1}"
						>
							{{commission.info}}
						</td>
					</ng-container>
					<ng-container matColumnDef="date">
						<th
							mat-header-cell
							*matHeaderCellDef
						> Date </th>
						<td
							mat-cell
							*matCellDef="let commission"
						> {{commission.date ? (commission.date | formattedDatePipe)
							: ''}} </td>
					</ng-container>
					<ng-container matColumnDef="account">
						<th
							mat-header-cell
							*matHeaderCellDef
						> Account </th>
						<td
							mat-cell
							*matCellDef="let commission"
						> {{commission.bankAccountSemiHash}} </td>
					</ng-container>
					<ng-container matColumnDef="balance">
						<th
							mat-header-cell
							*matHeaderCellDef
						> Balance </th>
						<td
							mat-cell
							*matCellDef="let commission; let i = index"
							[ngClass]="{'l-owed-to-you-data-end' : i === 1, 'l-last-row-item-child' : i !== 1}"
						>
							R {{ commission.balance ? (commission.balance | number : '.2-2') : 0 }}
						</td>
					</ng-container>
					<tr
						mat-header-row
						*matHeaderRowDef="referralCommissionColumns"
					></tr>
					<tr
						mat-row
						*matRowDef="let row; columns: referralCommissionColumns; let i = index"
						[ngClass]="{'l-owed-to-you-row' : i === 1}"
					></tr>
				</table>
				<mat-paginator
					#commissionPaginator
					[length]='referralCommission.data?.length'
					[hidePageSize]="true"
					[pageSize]="10"
				></mat-paginator>
			</div>
			<ng-template #showEmptyCommissionDetails>
				<div class="l-empty-state">
					<h3 class="mb-3">No Commissions Earned</h3>
					<div class="mb-3">Share your affiliate Link to earn your commission!</div>
				</div>
			</ng-template>
			<div *ngIf='isMobile'>
				<button
					class='g-primary-button w-100'
					(click)='handleChooseBankAccount()'
				>EDIT BANK ACCOUNT</button>
			</div>

		</div>
		<ng-template #pageLoader>
			<div class="l-page-loader">
				<mat-spinner></mat-spinner>
			</div>
		</ng-template>
	</div>
</div>
<app-bank-info-bar
	*ngIf='!isPageLoading || referralCommission.data?.length'
	class="g-fixed-footer"
	[isMobile]='isMobile'
	[infoTitleOne]="'Total Commission Earned'"
	[infoValueOne]='totalCommission'
	[infoTitleTwo]='"Linked Account"'
	[infoValueTwo]='linkedAccount'
	[buttonDisabled]='isBanksDataFetching'
	[showCurrencySymbol]='true'
	(handleClick)='handleChooseBankAccount()'
></app-bank-info-bar>