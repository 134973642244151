import { AppAction, FailedAction } from '@app/models/action.model';
import { MyTickets } from '@app/models/user.model';
import { NormalizedScheme } from '@app/models/dataSchema.model';
import { SchemeID } from '@app/models/dataSchema.model';


export enum MyTicketsConstants {
	GET_USER_MY_TICKETS = 'GET_USER_MY_TICKETS',
	GET_USER_MY_TICKETS_SUCCESS = 'GET_USER_MY_TICKETS_SUCCESS',
	GET_USER_MY_TICKETS_FAILED = 'GET_USER_MY_TICKETS_FAILED',

	SET_FUNDRAISER_MODAL_FLAG = 'SET_FUNDRAISER_MODAL_FLAG',

	UPDATE_FUNDRAISER_INVOICE = 'UPDATE_FUNDRAISER_INVOICE',
	UPDATE_FUNDRAISER_INVOICE_SUCCESS = 'UPDATE_FUNDRAISER_INVOICE_SUCCESS',
	UPDATE_FUNDRAISER_INVOICE_FAILED = 'UPDATE_FUNDRAISER_INVOICE_FAILED',
}

export class GetUserMyTickets extends AppAction <{
	cartId: SchemeID;
}>{
	readonly type = MyTicketsConstants.GET_USER_MY_TICKETS;
}

export class GetUserMyTicketsSuccess extends AppAction <{
	bookings: NormalizedScheme<MyTickets>;
}> {
	readonly type = MyTicketsConstants.GET_USER_MY_TICKETS_SUCCESS;
}

export class GetUserMyTicketFailed extends FailedAction {
	readonly type = MyTicketsConstants.GET_USER_MY_TICKETS_FAILED;
}

export class UpdateFundraiserInvoice extends AppAction <{
	id: SchemeID;
	booking: MyTickets;
}> {
	readonly type = MyTicketsConstants.UPDATE_FUNDRAISER_INVOICE;
}

export class UpdateFundraiserInvoiceSuccess extends AppAction <{
	id: SchemeID;
	booking: MyTickets;
}> {
	readonly type = MyTicketsConstants.UPDATE_FUNDRAISER_INVOICE_SUCCESS;
}

export class UpdateFundraiserInvoiceFailed extends FailedAction {
	readonly type = MyTicketsConstants.UPDATE_FUNDRAISER_INVOICE_FAILED;
}

export class SetFundraiserModalFlag extends AppAction <{
	isModalOpen: boolean;
}> {
	readonly type = MyTicketsConstants.SET_FUNDRAISER_MODAL_FLAG;
}



export type Actions =
	GetUserMyTickets
	| GetUserMyTicketsSuccess
	| GetUserMyTicketFailed
	| UpdateFundraiserInvoice
	| UpdateFundraiserInvoiceSuccess
	| UpdateFundraiserInvoiceFailed
	| SetFundraiserModalFlag;

