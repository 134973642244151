import { Component, OnInit, NgModule, Input, ContentChild, Output, EventEmitter } from '@angular/core';
import { DialogModule as UIKitDialogModule } from 'primeng/dialog';
import { CommonModule } from '@angular/common';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.component';

@Component({
	selector: 'app-dialog-header',
	template: `
		<ng-content></ng-content>
	`,
})
export class DialogHeaderComponent {}

@Component({
	selector: 'app-dialog-footer',
	template: `
		<ng-content></ng-content>
	`,
})
export class DialogFooterComponent {}

@Component({
	selector: 'app-dialog',
	templateUrl: 'dialog.component.html',
	styleUrls: [
		'./dialog.component.sass',
	],
})

export class DialogComponent implements OnInit {
	@Input() style;
	@Input() visible: boolean;
	@Input() loading: boolean;
	@Input() styleClass: string;
	@Input() header: string;
	@Input() asConfirmation: boolean;
	@Output() confirm = new EventEmitter<boolean>();
	@Input() confirmationMessage: string;
	@Input() confirmationIcon: string;
	@Input() useClose: boolean;
	@Output() close = new EventEmitter();
	@ContentChild(DialogHeaderComponent)
		dialogHeader: DialogHeaderComponent;

	constructor() { }

	ngOnInit() { }
}


@NgModule({
	imports: [
		UIKitDialogModule,
		CommonModule,
		LoadingSpinnerModule,
	],
	declarations: [
		DialogComponent,
		DialogHeaderComponent,
		DialogFooterComponent,
	],
	exports: [
		DialogComponent,
		DialogHeaderComponent,
		DialogFooterComponent,
	],
})
export class DialogModule {}
