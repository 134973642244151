<a
	pButton
	[ngClass]="getClasses()"
	[attr.target]="linkTarget || undefined"
	[icon]="icon"
	[href]="linkRef"
	[label]="label"
	[iconPos]="iconPos"
	[attr.disabled]="loading ? false : disabled"
	type="button"
	*ngIf="linkRef; else plainButton"
>
</a>
<ng-template #plainButton>
	<button
		pButton
		[ngClass]="getClasses()"
		[icon]="icon"
		[label]="loading && !simple ? '' : label"
		[iconPos]="iconPos"
		[disabled]="loading ? false : disabled"
		(click)="onClick($event)"
		(blur)="onBlur($event)"
		(focus)="onFocus($event)"
		[loadingSp]="loading && !simple"
		[loadingBackground]="'transparent'"
		[loadingText]="null"
		[spinnerBg]="type !== 'secondary' ? 'white' : 'black'"
		[loadingSize]="size === 'tiny' ? 'tiny' : 'small'"
		type="button"
		*ngIf="!linkRef"
	>
	</button>
</ng-template>