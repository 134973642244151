import { AppAction, FailedAction } from '@app/models/action.model';
import { SchemeID, NormalizedScheme } from '@app/models/dataSchema.model';
import { EventOnlineStreamingSettings, EventOnlineStreamingRooms, EventStreamingTicketTypes } from '@app/models/event.model';


export enum StreamingActionConstants {
	GET_EVENT_STREAMING = 'GET_EVENT_STREAMING',
	GET_EVENT_STREAMING_SUCCESS = 'GET_EVENT_STREAMING_SUCCESS',
	GET_EVENT_STREAMING_FAILED = 'GET_EVENT_STREAMING_FAILED',

	UPDATE_EVENT_STREAMING = 'UPDATE_EVENT_STREAMING',
	UPDATE_EVENT_STREAMING_SUCCESS = 'UPDATE_EVENT_STREAMING_SUCCESS',
	UPDATE_EVENT_STREAMING_FAILED = 'UPDATE_EVENT_STREAMING_FAILED',

	SET_STREAMING_FORM_MODAL_FLAG = 'SET_STREAMING_FORM_MODAL_FLAG',
	SET_EVENT_STREAMING_FETCHING = 'SET_EVENT_STREAMING_FETCHING',

	DELETE_EVENT_STREAMING_ROOM = 'DELETE_EVENT_STREAMING_ROOM',
	DELETE_EVENT_STREAMING_ROOM_SUCCESS = 'DELETE_EVENT_STREAMING_ROOM_SUCCESS',
	DELETE_EVENT_STREAMING_ROOM_FAILED = 'DELETE_EVENT_STREAMING_ROOM_FAILED',

	SET_EVENT_STREAMING_ENABLED = 'SET_EVENT_STREAMING_ENABLED',
	SET_EVENT_STREAMING_ENABLED_SUCCESS = 'SET_EVENT_STREAMING_ENABLED_SUCCESS',
	SET_EVENT_STREAMING_ENABLED_FAILED = 'SET_EVENT_STREAMING_ENABLED_FAILED',

	SET_EVENT_STREAMING_SETTINGS = 'SET_EVENT_STREAMING_SETTINGS',
	SET_EVENT_STREAMING_SETTINGS_SUCCESS = 'SET_EVENT_STREAMING_SETTINGS_SUCCESS',
	SET_EVENT_STREAMING_SETTINGS_FAILED = 'SET_EVENT_STREAMING_SETTINGS_FAILED',

	REORDER_STREAMS = 'REORDER_STREAMS',
	REORDER_STREAMS_SUCCESS = 'REORDER_STREAMS_SUCCESS',
	REORDER_STREAMS_FAILED = 'REORDER_STREAMS_FAILED',

	SET_ORGANISER_PREVIEW_CODE = 'SET_ORGANISER_PREVIEW_CODE',
	SET_ORGANISER_PREVIEW_CODE_SUCCESS = 'SET_ORGANISER_PREVIEW_CODE_SUCCESS',
	SET_ORGANISER_PREVIEW_CODE_FAILED = 'SET_ORGANISER_PREVIEW_CODE_FAILED',

	DELETE_ORGANISER_PREVIEW_CODE = 'DELETE_ORGANISER_PREVIEW_CODE',
	DELETE_ORGANISER_PREVIEW_CODE_SUCCESS = 'DELETE_ORGANISER_PREVIEW_CODE_SUCCESS',
	DELETE_ORGANISER_PREVIEW_CODE_FAILED = 'DELETE_ORGANISER_PREVIEW_CODE_FAILED',

	REQUEST_VOD_DOWNLOAD_LINK = 'REQUEST_VOD_DOWNLOAD_LINK',
	REQUEST_VOD_DOWNLOAD_LINK_SUCCESS = 'REQUEST_VOD_DOWNLOAD_LINK_SUCCESS',
	REQUEST_VOD_DOWNLOAD_LINK_FAILED = 'REQUEST_VOD_DOWNLOAD_LINK_FAILED',
}

const constants = StreamingActionConstants;

export class GetEventStreaming extends AppAction <{
	eventId: SchemeID;
}> {
	readonly type = constants.GET_EVENT_STREAMING;
}

export class GetEventStreamingSuccess extends AppAction <{
	eventId: SchemeID;
	settings: EventOnlineStreamingSettings;
	streams: NormalizedScheme<EventOnlineStreamingRooms>;
	ticketTypes: EventStreamingTicketTypes[];
	eventHasBegun: boolean;
}> {
	readonly type = constants.GET_EVENT_STREAMING_SUCCESS;
}

export class GetEventStreamingFailed extends FailedAction {
	readonly type = constants.GET_EVENT_STREAMING_FAILED;
}

export class UpdateEventStreaming extends AppAction <{
	eventId: SchemeID;
	streams: EventOnlineStreamingRooms;
	isUpdating: boolean;
}> {
	readonly type = constants.UPDATE_EVENT_STREAMING;
}

export class UpdateEventStreamingSuccess extends AppAction <{
	eventId: SchemeID;
	streams: EventOnlineStreamingRooms;
	isUpdating: boolean;
}> {
	readonly type = constants.UPDATE_EVENT_STREAMING_SUCCESS;
}

export class UpdateEventStreamingFailed extends FailedAction {
	readonly type = constants.UPDATE_EVENT_STREAMING_FAILED;
}

export class SetStreamingFormModalFlag extends AppAction <{
	eventId: SchemeID;
	isModalOpen: boolean;
}> {
	readonly type = constants.SET_STREAMING_FORM_MODAL_FLAG;
}

export class SetEventStreamingFetching extends AppAction <{
	eventId: SchemeID;
	isFetching: boolean;
}> {
	readonly type = StreamingActionConstants.SET_EVENT_STREAMING_FETCHING;
}

export class DeleteEventStreamingRoom extends AppAction <{
	eventId: SchemeID;
	id: SchemeID;
}> {
	readonly type = constants.DELETE_EVENT_STREAMING_ROOM;
}

export class DeleteEventStreamingRoomSuccess extends AppAction <{
	eventId: SchemeID;
	id: SchemeID;
}> {
	readonly type = constants.DELETE_EVENT_STREAMING_ROOM_SUCCESS;
}

export class DeleteEventStreamingRoomFailed extends FailedAction {
	readonly type = constants.DELETE_EVENT_STREAMING_ROOM_FAILED;
}

export class SetEventStreamingEnabled extends AppAction <{
	eventId: SchemeID;
	id: SchemeID;
	isEnabled: boolean;
	prevValue: boolean;
}> {
	readonly type = constants.SET_EVENT_STREAMING_ENABLED;
}

export class SetEventStreamingEnabledSuccess extends AppAction <{
	eventId: SchemeID;
	id: SchemeID;
	isEnabled: boolean;
}> {
	readonly type = constants.SET_EVENT_STREAMING_ENABLED_SUCCESS;
}

export class SetEventStreamingEnabledFailed extends FailedAction <SetEventStreamingEnabled> {
	readonly type = constants.SET_EVENT_STREAMING_ENABLED_FAILED;
}

export class SetStreamingSettingsProperty extends AppAction <{
	eventId: SchemeID;
	settings: EventOnlineStreamingSettings;
}> {
	readonly type = constants.SET_EVENT_STREAMING_SETTINGS;
}

export class SetStreamingSettingsPropertySuccess extends AppAction <{
	eventId: SchemeID;
	settings: EventOnlineStreamingSettings;
}> {
	readonly type = constants.SET_EVENT_STREAMING_SETTINGS_SUCCESS;
}

export class SetStreamingSettingsPropertyFailed extends FailedAction {
	readonly type = constants.SET_EVENT_STREAMING_SETTINGS_FAILED;
}

export class ReorderStreams extends AppAction <{
	eventId: SchemeID;
	sortOrder: SchemeID[];
	prevOrder: SchemeID[];
}> {
	readonly type = constants.REORDER_STREAMS;
}

export class ReorderStreamsSuccess extends AppAction <{
	eventId: SchemeID;
	sortOrder: SchemeID[];
}> {
	readonly type = constants.REORDER_STREAMS_SUCCESS;
}

export class ReorderStreamsFailed extends FailedAction<ReorderStreams> {
	readonly type = constants.REORDER_STREAMS_FAILED;
}

export class SetOrganiserPreviewCode extends AppAction <{
	eventId: SchemeID;
}> {
	readonly type = constants.SET_ORGANISER_PREVIEW_CODE;
}

export class SetOrganiserPreviewCodeSuccess extends AppAction <{
	eventId: SchemeID;
	settings: EventOnlineStreamingSettings;
	streams: NormalizedScheme<EventOnlineStreamingRooms>;
	ticketTypes: EventStreamingTicketTypes[];
}> {
	readonly type = constants.SET_ORGANISER_PREVIEW_CODE_SUCCESS;
}

export class SetOrganiserPreviewCodeFailed extends FailedAction {
	readonly type = constants.SET_ORGANISER_PREVIEW_CODE_FAILED;
}

export class DeleteOrganiserPreviewCode extends AppAction <{
	eventId: SchemeID;
}> {
	readonly type = constants.DELETE_ORGANISER_PREVIEW_CODE;
}

export class DeleteOrganiserPreviewCodeSuccess extends AppAction <{
	eventId: SchemeID;
	settings: EventOnlineStreamingSettings;
	streams: NormalizedScheme<EventOnlineStreamingRooms>;
	ticketTypes: EventStreamingTicketTypes[];
}> {
	readonly type = constants.DELETE_ORGANISER_PREVIEW_CODE_SUCCESS;
}

export class DeleteOrganiserPreviewFailed extends FailedAction {
	readonly type = constants.DELETE_ORGANISER_PREVIEW_CODE_FAILED;
}

export class RequestVodDownloadLink extends AppAction <{
	eventId: SchemeID;
	id: SchemeID;
}> {
	readonly type = constants.REQUEST_VOD_DOWNLOAD_LINK;
}

export class RequestVodDownloadLinkSuccess extends AppAction <{
	eventId: SchemeID;
	id: SchemeID;
}> {
	readonly type = constants.REQUEST_VOD_DOWNLOAD_LINK_SUCCESS;
}

export class RequestVodDownloadLinkFailed extends FailedAction {
	readonly type = constants.REQUEST_VOD_DOWNLOAD_LINK_FAILED;
}

export type Actions =
GetEventStreaming
| GetEventStreamingSuccess
| GetEventStreamingFailed
| UpdateEventStreaming
| UpdateEventStreamingSuccess
| UpdateEventStreamingFailed
| SetStreamingFormModalFlag
| DeleteEventStreamingRoom
| DeleteEventStreamingRoomSuccess
| DeleteEventStreamingRoomFailed
| SetEventStreamingEnabled
| SetEventStreamingEnabledSuccess
| SetEventStreamingEnabledFailed
| SetStreamingSettingsProperty
| SetStreamingSettingsPropertySuccess
| SetStreamingSettingsPropertyFailed
| SetEventStreamingFetching
| ReorderStreams
| ReorderStreamsSuccess
| ReorderStreamsFailed
| SetOrganiserPreviewCode
| SetOrganiserPreviewCodeSuccess
| SetOrganiserPreviewCodeFailed
| DeleteOrganiserPreviewCode
| DeleteOrganiserPreviewCodeSuccess
| DeleteOrganiserPreviewFailed
| RequestVodDownloadLink
| RequestVodDownloadLinkSuccess
| RequestVodDownloadLinkFailed;
