import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { HeaderContent } from '@app/models/shared';
import { StoreService } from '@app/services/store/store.service';
import { emailEncrypt } from '@app/store/selectors/user.selector';
import { Observable, take, takeUntil } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { UserActionsConstants } from '@app/store/actions/user/user.actions.constants';
import { GetEmailPreferences, UpdateEmailPreferences } from '@app/store/actions/user/user.actions';
import { EmailPreference, EmailSubscription, EmailSubscriptionType, Subscription } from '@app/models/user.model';
import { MY_ACCOUNT_HEADER } from '@app/utils/consts';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { InternalURLCreator } from '@app/services/url/url.dictionary';
import { Go } from '@app/store/actions/router/router.actions';
import { ConfirmationModalComponent } from '@app/shared/modals/confirmation-modal/confirmation-modal.component';
import { FormCanDeactivate } from '@app/services/guards/user.guard';


@Component({
	selector: 'app-email-preference',
	templateUrl: './email-preference.component.html',
	styleUrls: ['./email-preference.component.sass'],
})
export class EmailPreferenceComponent extends FormCanDeactivate implements OnInit {
	headerContent: HeaderContent = MY_ACCOUNT_HEADER['emailPreference'];
	emailEncrypt: string;
	isLoading = true;
	email: string;
	decryptFailed: boolean;
	subscribedToNewsletter: boolean;
	subscribedToDailySales: boolean;
	subscribedToInvites: boolean;
	subscribedToRecommendations: boolean;
	@ViewChild('emailForm') emailForm!: NgForm;

	isMobile = false;
	destroyed$: EventEmitter<void> = new EventEmitter<void>();


	constructor(
		private breakpointService: BreakpointService,
		private store: StoreService,
		private actions$: Actions,
		private dialog: MatDialog
	) {
		super();
	}

	ngOnInit(): void {

		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});

		this.store.select(emailEncrypt()).pipe(take(1)).subscribe((e) => {
			if (e) {
				this.emailEncrypt = e;
				this.store.dispatch(new GetEmailPreferences({ e: this.emailEncrypt }));
			}
		});

		this.actions$
			.pipe(
				ofType(UserActionsConstants.GET_EMAIL_PREFERENCES_SUCCESS, UserActionsConstants.GET_EMAIL_PREFERENCES_FAILED),
				take(1)
			)
			.subscribe(({ type, payload: { payload } }: { type: string; payload: { payload: EmailSubscription } }) => {
				if (type === UserActionsConstants.GET_EMAIL_PREFERENCES_SUCCESS) {
					this.email = payload.email;
					this.decryptFailed = payload.decryptFailed;
					const subscriptions = payload.subscriptions;
					this.setPreferencesHelper(subscriptions);
				}
				this.isLoading = false;
			});

		this.actions$
			.pipe(
				ofType(UserActionsConstants.UPDATE_EMAIL_PREFERENCES_SUCCESS),
				takeUntil(this.destroyed$)
			)
			.subscribe(() => {
				this.emailForm.form.markAsPristine();
			});
	}

	handleUpdatePreferences() {
		const preferenceUpdate: EmailPreference = {
			email: this.email,
			subscribedToNewsletter: this.subscribedToNewsletter,
			subscribedToDailySales: this.subscribedToDailySales,
			subscribedToInvites: this.subscribedToInvites,
			subscribedToRecommendations: this.subscribedToRecommendations,
			decryptFailed: this.decryptFailed,
		};
		this.store.dispatch(new UpdateEmailPreferences({ e: this.emailEncrypt, preferences: preferenceUpdate }));
	}

	handleNavigationBack() {
		this.store.dispatch(new Go({ path: [InternalURLCreator.myAccount()] }));
	}

	isFormDeactivatable(): boolean | Observable<boolean> {
		return !this.emailForm || !this.emailForm.form || this.emailForm.form.pristine;
	}

	deactivationFallback(): Observable<boolean> {
		this.dialog
			.open(ConfirmationModalComponent, {
				data: {
					title: 'Unsaved Changes',
					text: 'Are you sure you want to leave this page? Any unsaved changes will be discarded.',
					buttonText: 'LEAVE',
					centerText: true,
					isMobile: this.isMobile,
				},
				panelClass: 'g-standard-dialog',
			})
			.afterClosed()
			.subscribe((result) => {
				this.closeDeactivationModal(result);
			});
		return this.modalCallback.asObservable();
	}

	closeDeactivationModal(isConfirmed: boolean): void {
		this.modalCallback.next(isConfirmed);
	}

	handlePrivacyPolicy() {
		window.open('https://help.quicket.com/portal/en/kb/articles/privacy-policy');
	}

	setPreferencesHelper(subscriptions: Subscription[]) {
		subscriptions.forEach(subscription => {
			switch (subscription.subscriptionType) {
				case EmailSubscriptionType.subscribedToNewsletter:
					this.subscribedToNewsletter = subscription.subscriptionStatus === 'subscribed';
					break;
				case EmailSubscriptionType.subscribedToDailySales:
					this.subscribedToDailySales = subscription.subscriptionStatus === 'subscribed';
					break;
				case EmailSubscriptionType.subscribedToInvites:
					this.subscribedToInvites = subscription.subscriptionStatus === 'subscribed';
					break;
				case EmailSubscriptionType.subscribedToRecommendations:
					this.subscribedToRecommendations = subscription.subscriptionStatus === 'subscribed';
					break;
			}
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
