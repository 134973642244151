import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterGroup, Filter, SearchAndFilter, SortOption } from '@app/models/filter-and-sort.model';

@Component({
	selector: 'app-search-bar',
	templateUrl: './search-bar.component.html',
	styleUrls: ['./search-bar.component.sass'],
})
export class SearchBarComponent {
	@Input() isMobile = false;
	@Input() filterOptions: FilterGroup[];
	@Input() numberAppliedFilters: number;
	@Input() showFilter = true;

	searchTerm: string;
	selectedFilter: Filter;
	selectedSort: SortOption;
	sortOptions = SortOption;

	@Output() sortOption = new EventEmitter<SortOption>();
	@Output() searchAndFilter = new EventEmitter<SearchAndFilter>();
	@Output() mobileSearchTerm = new EventEmitter<string>();
	@Output() openFilterBottomSheet = new EventEmitter<boolean>();

	handleSortEmit() {
		this.sortOption.emit(this.selectedSort);
	}

	handleFilterEmit() {
		const filterType = this.filterOptions?.find((filterGroup) =>
			filterGroup.filter.some((filter) => filter.viewValue === this.selectedFilter?.viewValue)
		)?.name;
		this.searchAndFilter.emit({
			searchTerm: this.searchTerm,
			filterOption: { filterType, selectedFilter: this.selectedFilter },
		});
	}

	handleMobileSearchEmit() {
		this.mobileSearchTerm.emit(this.searchTerm);
	}

	handleMobileFilter() {
		this.openFilterBottomSheet.emit(true);
	}
}
