import { Injectable } from '@angular/core';
import { FormService } from '@app/services/form/form.service';
import { EventDomainAPI } from '@app/api/domains/event';
import { StoreService } from '@app/services/store/store.service';
import { EventCollections } from '@app/models/event.model';
import { FormSchemeControls } from '@app/models/common.model';
import { UntypedFormGroup } from '@angular/forms';
import { UpdateEventCollections, UpdateEventCollectionsSuccess, UpdateEventCollectionsFailed } from './event-collections.actions';
import { map, catchError } from 'rxjs/operators';
import { throwError, of } from 'rxjs';
import { getResponseErrors } from '@app/utils/Response';
import { getFaileadRequestAction } from '@app/utils/Store';


@Injectable()
export class CollectionsService {
	constructor(
		private formService: FormService,
		private api: EventDomainAPI,
		private storeService: StoreService
	) {}
	createEventCollectionsForm = (initialValues?: Partial<EventCollections>) => {
		const formValues = new EventCollections();

		const formConfig: Partial<FormSchemeControls<EventCollections>> = this.formService.createFormControls({
			formValues,
			initialValues,
			validatorsConfig: {},
		});

		return this.formService.formBuilder.group(formConfig);
	};

	submitCollectionsForm = ({
		formValues,
		form,
		action,
	}: {
		formValues: EventCollections;
		form: UntypedFormGroup;
		action: UpdateEventCollections;
	}) => (
		this.api.updateEventCollections(formValues.id, formValues)
			.pipe(
				map(value => {
					if (value.isSuccess) {
						this.storeService.dispatch(
							new UpdateEventCollectionsSuccess({
								id: value.payload.id,
								collections: value.payload,
							})
						);
						form.patchValue(value.payload);
						form.markAsPristine();
						return value;
					}
					return throwError(getResponseErrors(value));
				}),
				catchError(
					err => {
						this.storeService.dispatch(getFaileadRequestAction(
							new UpdateEventCollectionsFailed({
								msg: 'can\'t update collections',
								action,
							}),
							err
						));
						return of({
							isSuccess: false,
						});
					}
				)
			)
	);
}
