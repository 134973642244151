<div class="l-table-container">
	<table
		mat-table
		[dataSource]="dataSource"
		multiTemplateDataRows
		class='l-table web'
		cdkDropList
		(cdkDropListDropped)="handleDrag($event)"
		[cdkDropListDisabled]="dragDisabled"
		#tabContentTable
	>
		<ng-container matColumnDef="sorting">
			<th
				mat-header-cell
				*matHeaderCellDef
				class="l-sorting-column"
			></th>
			<td
				mat-cell
				*matCellDef="let galleryImages"
				class="l-sorting-column"
			>
				<img
					src="./assets/icons/dots-nine.svg"
					alt="Drag Icon"
					class="l-info-icon g-move"
					(mousedown)="dragDisabled = false;"
					(touchstart)="dragDisabled = false;"
				>
			</td>
		</ng-container>
		<ng-container matColumnDef="isExpanded">
			<th
				mat-header-cell
				*matHeaderCellDef
				class="l-expanded-column"
			></th>
			<td
				mat-cell
				*matCellDef="let galleryImages"
				class="l-expanded-column"
			>
				<mat-icon
					[@rotateIcon]="expandedDetailElement !== galleryImages.value.contentUid ? 'rotated' : 'default'"
				>expand_more</mat-icon>
			</td>
		</ng-container>
		<ng-container matColumnDef="thumbnail">
			<th
				mat-header-cell
				*matHeaderCellDef
			> Image </th>
			<td
				mat-cell
				*matCellDef="let galleryImages"
			>
				<img
					*ngIf="galleryImages.value"
					[src]="tabbedContentService.getImagePreview(galleryImages)"
					class="l-thumbnail-image"
					id="imageGallery-image"
				>
				<ng-template #noImage>No Image</ng-template>
		</ng-container>
		<ng-container matColumnDef="visibility">
			<th
				mat-header-cell
				*matHeaderCellDef
			> Visibility </th>
			<td
				mat-cell
				*matCellDef="let galleryImages"
			><mat-slide-toggle
					class="standard-toggle"
					(click)="$event.stopPropagation()"
					[formControl]="galleryImages.get('isVisible')"
					id="imageGallery-visibility"
				></mat-slide-toggle>
			</td>
		</ng-container>
		<ng-container matColumnDef="delete">
			<th
				mat-header-cell
				*matHeaderCellDef
			></th>
			<td
				mat-cell
				*matCellDef="let galleryImages"
			>
				<mat-icon
					(click)="handleDeleteTabContentType(galleryImages)"
					id="imageGallery-delete"
				>delete_outline</mat-icon>
			</td>
		</ng-container>
		<ng-container matColumnDef="expandedDetail">
			<td
				mat-cell
				*matCellDef="let galleryImages"
				[attr.colspan]="displayColumns.length"
			>
				<div
					[@detailExpand]="galleryImages.value.contentUid == expandedDetailElement ? 'expanded' : 'collapsed'">
					<div>
						<img
							*ngIf="galleryImages.value"
							class='l-image'
							[src]="tabbedContentService.getImagePreview(galleryImages)"
						>
						<ng-template #noImage>No Image</ng-template>
					</div>
				</div>
			</td>
		</ng-container>
		<tr
			mat-header-row
			*matHeaderRowDef="displayColumns"
		></tr>
		<tr
			mat-row
			*matRowDef="let galleryImages; columns: displayColumns;"
			class='g-pointer'
			(click)="handleExpandContent(galleryImages)"
			cdkDrag
		>
		</tr>
		<tr
			mat-row
			*matRowDef="let galleryImages; columns: ['expandedDetail']"
			class="l-detail-row"
			[class.collapsed-row]="expandedDetailElement !== galleryImages.value.contentUid"
		></tr>
	</table>
</div>
<app-image-upload
	[eventId]="eventId"
	[label]="'Image Gallery'"
	[maxFileSize]="imageService.maxImageSize"
	[imageServiceType]="ImageServiceTypeEnum.CONTENT_TAB"
	[showPreferredResText]="false"
	[uploadText]="'This will appear on your event description page in this tab. \n'"
	[isMobile]="isMobile"
	[showPreviewImage]="false"
	[handleTabContent]="true"
	[(isUploading)]="isImageUploading"
	(handleTabUpload)="tabbedContentService.onImageGalleryUpload($event, form)"
	(maxFileSizeAchieved)="imageService.onImageMaxSizeAchived()"
	(isLoadingChange)="handleLoading($event)"
	class="l-listing-image pt-3 pb-3"
>
</app-image-upload>