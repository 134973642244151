import { NgModule, ErrorHandler } from '@angular/core';
import { RootComponent } from './root.component';
import { CoreModule } from './core/core.module';
import { ServiceModule } from './services/service.module';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { FeaturesModule } from './features/features.module';
import { EffectModule } from './store/effects/effects.module';
import { ReducerModule } from './store/reducers/reducer.module';
import { ErrorHandlingService } from '@app/services/error-handling/error-handling.service';
import { AppRouterModule } from '@app/router/router.module';
import { UserFeaturesModule } from './user-features/user-features.module';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import * as Sentry from '@sentry/angular';

export function playerFactory() {
	return player;
}

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
	declarations: [
		RootComponent,
	],
	imports: [
		FeaturesModule,
		CoreModule,
		ServiceModule,
		SharedModule,
		ReducerModule,
		AppRouterModule,
		BrowserAnimationsModule,
		LayoutModule,
		EffectModule,
		UserFeaturesModule,
		LottieModule.forRoot({ player: playerFactory }),
		NgxMaskModule.forRoot(),
	],
	providers: [
		{
			provide: ErrorHandler,
			useClass: ErrorHandlingService,
			useValue: Sentry.createErrorHandler(),
		},
		{
			provide: Sentry.TraceService,
			deps: [AppRouterModule],
		},
	],
	bootstrap: [RootComponent],
})
export class RootModule {
}
