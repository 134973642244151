import { NgModule } from '@angular/core';
import { EventCreationService } from './event-creation/event-creation.service';
import { URLService } from './url/url.service';
import { DatesService } from './dates/dates.service';
import { ScheduleService } from './schedule/schedule.service';
import { FormService } from './form/form.service';
import { TicketsService } from './tickets/tickets.service';
import { OrganiserProfileservice } from './organiser-profile/organiser-profile.service';
import { TimezoneService } from './timezone/timezone.service';
import { ErrorBusModule } from './error-handling/error-bus.service';
import { StoreService } from '@app/services/store/store.service';
import { PermissionService } from '@app/services/permission/permission.service';
import { ResolverModule } from './resolvers/resolvers.module';
import { GuardsModule } from './guards/guards.module';
import { HelpArticleService } from './help-article/help-artcile.service';
import { TokenService } from './token/token.service';
import { SocialSharingService } from '@app/services/social-sharing/socail-sharing.service';
/* import { UserTourService } from '@app/services/user-tour/user-tour.service';
 */import { ChartService } from './chart/chart.service';
import { ReportService } from './report/report.service';
import { GoogleAccountService } from './google-account/google-account.service';
import { FacebookModule } from './facebook/facebook.service';
import { FileSaverService } from './file-saver/file-saver.service';
import { NotificationModule } from './notification/notification.module';
import { GeneralNotificationSource } from './notification/notification.dictionary';
import { FormCreationService } from './form-creation/form-creation.service';
import { TabbedContentService } from './events/manage-event/tabbed-content.service';
import { LinkCampaignsService } from './link-campaigns/link-campaigns.service';
import { EventCopyService } from './event-copy/event-copy.service';
import { TicketGroupsService } from './tickets/ticket-groups.service';
import { EventSettingsService } from './events/settings/event-settings.service';
import { EventDetailsService } from './event-details/event-details.service';
import { GuestManagementService } from './events/guest-management/guest-management.service';
import { OrderService } from './orders/orders.service';
import { GoogleAnalyticsService } from './google-analytics/google-analytics.service';

@NgModule({
	imports: [
		FacebookModule,
		ErrorBusModule,
		NotificationModule.forRoot([GeneralNotificationSource]),
	],
	exports: [
		ResolverModule,
		GuardsModule,
	],
	providers: [
		EventCreationService,
		EventDetailsService,
		OrganiserProfileservice,
		DatesService,
		ScheduleService,
		FormService,
		TicketsService,
		OrderService,
		TimezoneService,
		URLService,
		StoreService,
		PermissionService,
		HelpArticleService,
		TokenService,
		SocialSharingService,
		GuestManagementService,
		/* UserTourService, */
		ChartService,
		ReportService,
		GoogleAccountService,
		FileSaverService,
		FormCreationService,
		LinkCampaignsService,
		TabbedContentService,
		EventCopyService,
		TicketGroupsService,
		EventSettingsService,
		GoogleAnalyticsService,
	],
})
export class ServiceModule {}
