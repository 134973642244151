import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-section-heading',
	templateUrl: './section-heading.component.html',
	styleUrls: ['./section-heading.component.sass'],
})
export class SectionHeadingComponent {
	@Input() isMobile: boolean;
	@Input() label: string;
}
