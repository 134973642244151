import { Component, EventEmitter, OnInit } from '@angular/core';
import { HeaderContent } from '@app/models/shared';
import { Clipboard } from '@angular/cdk/clipboard';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { StoreService } from '@app/services/store/store.service';
import { BANK_ACCOUNT_MODAL, EARN_COMMISSION_HEADER, LINK_COMMISSION_INFO } from '@app/utils/consts';
import { combineLatest, distinctUntilChanged, filter, take, takeUntil, tap } from 'rxjs';
import * as userSelectors from '@app/store/selectors/user.selector';
import { AccountModalReturn, BankAccount, PromoterNetwork, PromoterNetworkPayout } from '@app/models/user.model';
import { Actions, ofType } from '@ngrx/effects';
import { UserActionsConstants } from '@app/store/actions/user/user.actions.constants';
import { GetPromoterNetwork, UpdatePromoterBankAccount, UpdatePromoterNetworkLink } from '@app/store/actions/user/user.actions';
import { InternalURLCreator } from '@app/services/url/url.dictionary';
import { MatTableDataSource } from '@angular/material/table';
import { Go } from '@app/store/actions/router/router.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { BankAccountModalComponent } from '@app/shared/bank-account-modal/bank-account-modal.component';
import { EarnCommissionBaseComponent } from '../../components/earn-commission-base-control/earn-commission-base-control.component';
import { animate, state, style, transition, trigger } from '@angular/animations';

const PROMOTER_ID_PARAM_KEY = 'id';

@Component({
	selector: 'app-promoter-network-details',
	templateUrl: './promoter-network-details.component.html',
	styleUrls: ['./promoter-network-details.component.sass'],
	animations: [
		trigger('expandCollapse', [
			state('collapsed', style({
				opacity: 0,
				maxHeight: '0px',
				padding: '0px 16px',
				marginBottom: '0px',
				overflow: 'hidden',
			})),
			state('expanded', style({
				opacity: 1,
				maxHeight: '500px',
				padding: '16px',
				marginBottom: '16px',
				overflow: 'hidden',
			})),
			transition('expanded <=> collapsed', [
				animate('0.3s ease-in-out'),
			]),
		]),
	],
})

export class PromoterNetworkDetailsComponent extends EarnCommissionBaseComponent implements OnInit {
	headerContent: HeaderContent = EARN_COMMISSION_HEADER['promoterDetails'];
	id: number;
	promoterDetails: PromoterNetwork;
	customDescription: string;
	totalTickets: number;
	showMore = false;
	readMoreText = 'Show More.';
	linkedAccount: string;
	bankAccounts: BankAccount[];
	currentAccount: BankAccount;
	LINK_COMMISSION_INFO = LINK_COMMISSION_INFO;

	promoterPaymentDetails: MatTableDataSource<PromoterNetworkPayout> = new MatTableDataSource();
	promoterPaymentColumns: string[] = ['linkClicks', 'ticketsSold', 'sales', 'yourShare', 'account'];

	destroyed$: EventEmitter<void> = new EventEmitter();

	constructor(
		breakpointService: BreakpointService,
		store: StoreService,
		actions$: Actions,
		clipboard: Clipboard,
		router: Router,
		private dialog: MatDialog,
		private activatedRoute: ActivatedRoute
	) {
		super(store, breakpointService, actions$, clipboard, router);
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.activatedRoute.params.pipe(take(1)).subscribe((params) => {
			this.id = params[PROMOTER_ID_PARAM_KEY];
			this.bankAccountsAndPromoterDetails();
		});
	}

	bankAccountsAndPromoterDetails(): void {
		const promoterDetails$ = this.store.select(userSelectors.currentPromoterDetails()).pipe(
			takeUntil(this.destroyed$),
			distinctUntilChanged((prev, curr) =>
				(prev.id === curr.id) && (prev.selectedBankAccountSemiHashed === curr.selectedBankAccountSemiHashed)),
			tap((promoterNetwork) => {
				if (!promoterNetwork.id || +promoterNetwork.id !== +this.id) {
					this.store.dispatch(new GetPromoterNetwork({ id: this.id }));
				}
			}),
			filter((promoterNetwork) => promoterNetwork.id && +promoterNetwork.id === +this.id)
		);

		combineLatest([promoterDetails$, this.bankAccounts$]).pipe(takeUntil(this.destroyed$))
			.subscribe(([promoterDetails, bankAccounts]) => {
				this.bankAccounts = bankAccounts;
				this.promoterDetails = promoterDetails;
				this.linkedAccount = this.promoterDetails.selectedBankAccountSemiHashed;
				this.promoterPaymentDetails.data = this.promoterDetails.payments;
				this.customDescription =
					`Share this link with everyone and earn ${this.promoterDetails.commissionPercentage}% of the tickets for yourself.`;
				this.totalTickets = this.promoterDetails.payments[0].ticketsSold;
				this.isLoading = false;
			});
	}

	handleShowMore() {
		this.showMore = !this.showMore;
		this.readMoreText = this.showMore ? 'Show Less' : 'Show More';
	}

	handleSaveLink(link: string) {
		this.actions$
			.pipe(
				ofType(UserActionsConstants.UPDATE_PROMOTER_NETWORK_LINK_SUCCESS, UserActionsConstants.UPDATE_PROMOTER_NETWORK_LINK_FAILED),
				takeUntil(this.destroyed$)
			)
			.subscribe(({ type, payload: { promoterNetwork } }:
			{ type: string; payload: { promoterNetwork: PromoterNetwork } }) => {
				this.isUpdating = false;
				if (type === UserActionsConstants.UPDATE_PROMOTER_NETWORK_LINK_SUCCESS) {
					this.handleNotification(promoterNetwork.success, false, promoterNetwork.errorMessage);
				}
			});

		this.store.dispatch(new UpdatePromoterNetworkLink({ id: this.id, code: link }));
		this.isUpdating = true;
	}

	handleChooseBankAccount() {
		this.actions$
			.pipe(
				ofType(UserActionsConstants.UPDATE_PROMOTER_BANK_ACCOUNT_SUCCESS, UserActionsConstants.UPDATE_PROMOTER_BANK_ACCOUNT_FAILED),
				takeUntil(this.destroyed$)
			)
			.subscribe(({ type, payload: { promoterNetwork } }:
			{ type: string; payload: { promoterNetwork: PromoterNetwork } }) => {
				this.isBanksDataFetching = false;
				if (type === UserActionsConstants.UPDATE_PROMOTER_BANK_ACCOUNT_SUCCESS) {
					this.handleNotification(promoterNetwork.success, true, promoterNetwork.errorMessage);
				}
			});

		this.isBanksDataFetching = true;
		const dialogRef = this.dialog.open(BankAccountModalComponent, {
			data: {
				isMobile: this.isMobile,
				currencyId: 1,
				descriptions: BANK_ACCOUNT_MODAL['referrals'],
			},
			panelClass: this.isMobile ? 'g-full-page-dialog' : 'g-standard-dialog',
		});

		dialogRef.afterClosed().subscribe((account: AccountModalReturn) => {
			if (account && account.accountId) {
				this.store.dispatch(new UpdatePromoterBankAccount({ id: this.id, accountId: account.accountId }));
			} else {
				this.isBanksDataFetching = false;
			}
		});
	}

	handleNavigationBack() {
		this.store.dispatch(new Go({ path: [InternalURLCreator.promoterNetworksList()] }));
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
