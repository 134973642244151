export enum OrganiserProfileActionConstants {
	GET_PROFILES = 'GET_PROFILES',
	GET_PROFILES_SUCCESS = 'GET_PROFILES_SUCCESS',
	GET_PROFILES_FAILED = 'GET_PROFILES_FAILED',

	CREATE_PROFILE = 'CREATE_PROFILE',
	CREATE_PROFILE_SUCCESS = 'CREATE_PROFILE_SUCCESS',
	CREATE_PROFILE_FAILED = 'CREATE_PROFILE_FAILED',

	GET_EVENT_ORGANISER = 'GET_EVENT_ORGANISER',
	GET_EVENT_ORGANISER_SUCCESS = 'GET_EVENT_ORGANISER_SUCCESS',
	GET_EVENT_ORGANISER_FAILED = 'GET_EVENT_ORGANISER_FAILED',

	SAVE_EVENT_ORGANISER = 'SAVE_EVENT_ORGANISER',
	SAVE_EVENT_ORGANISER_SUCCESS = 'SAVE_EVENT_ORGANISER_SUCCESS',
	SAVE_EVENT_ORGANISER_FAILED = 'SAVE_EVENT_ORGANISER_FAILED',

	SYNC_ORGANISER_PROFILE = 'SYNC_ORGANISER_PROFILE',

	GET_ORGANISER_PROFILES = 'GET_ORGANISER_PROFILES',
	GET_ORGANISER_PROFILES_SUCCESS= 'GET_ORGANISER_PROFILES_SUCCESS',
	GET_ORGANISER_PROFILES_FAILED = 'GET_ORGANISER_PROFILES_FAILED',

	SET_ORGANISER_PROFILE_MODAL_FLAG = 'SET_ORGANISER_PROFILE_MODAL_FLAG',

	SET_PROFILE_LINKED_PRODUCT_MODAL_FLAG = 'SET_PROFILE_LINKED_PRODUCT_MODAL_FLAG',

	UPDATE_ORGANISER_PROFILE = 'UPDATE_ORGANISER_PROFILE',
	UPDATE_ORGANISER_PROFILE_SUCCESS = 'UPDATE_ORGANISER_PROFILE_SUCCESS',
	UPDATE_ORGANISER_PROFILE_FAILED = 'UPDATE_ORGANISER_PROFILE_FAILED',

	DELETE_ORGANISER_PROFILE = 'DELETE_ORGANISER_PROFILE',
	DELETE_ORGANISER_PROFILE_SUCCESS = 'DELETE_ORGANISER_PROFILE_SUCCESS',
	DELETE_ORGANISER_PROFILE_FAILED = 'DELETE_ORGANISER_PROFILE_FAILED',
}
