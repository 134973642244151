import { ChartInput } from '@app/models/organiser.model';
import { Component, Input, SimpleChanges } from '@angular/core';
import { SchemeID } from '@app/models/dataSchema.model';
import { EventDashboardReports } from '@app/models/event-metadata.model';
import { ScheduleItem } from '@app/models/schedule.model';
import { StoreService } from '@app/services/store/store.service';
import { GetEventDashboardReports } from '@app/store/actions/event/event.actions';
import {
	BreakdownRangeFilterPresets,
	BreakdownRangeFilterRange,
	DateRangeFilter
} from '../overview-reports-filters/overview-reports-filters.config';
import { TicketType } from '@app/models/event.model';
import { formatDate } from '@angular/common';
import { ChartService } from '@app/services/chart/chart.service';
import MoneyPresenter from '@app/utils/MoneyPresenter';

@Component({
	selector: 'app-overview-reports',
	templateUrl: './overview-reports.component.html',
	styleUrls: ['./overview-reports.component.sass'],
})
export class OverviewReportsComponent {
	@Input() reportsData: EventDashboardReports;
	@Input() isReportsLoading: boolean;
	@Input() isOngoing: boolean;
	@Input() scheduleItems: ScheduleItem[];
	@Input() eventId: SchemeID;
	@Input() currencyISO: string;
	@Input() isMobile: boolean;

	eventIncomeGraphPoints: ChartInput[];
	ticketTypesSoldGraphPoints: ChartInput[];
	pageVisitsGraphPoints: ChartInput[];

	currentBreakdownRangeFilter = new DateRangeFilter();

	availableTicketTypes: TicketType[] = [];
	filteredTicketTypes: TicketType[] = [];
	selectedTicketTypes: TicketType[] = [];
	currentTicketTypeFilter: TicketType[] = [];

	appliedFilterText: string;

	salesDataMinDate: Date;
	salesDataMaxDate: Date;

	isEventFinished = false;
	currencySymbol: string;

	constructor(private store: StoreService, private chartService: ChartService) {}

	ngOnInit(): void {
		const range = this.initializeRange();
		this.setupTicketTypes();
		this.filterGraphDataByDateRange(range);
		this.currencySymbol = MoneyPresenter.getBasicSymbol(this.currencyISO) || this.currencyISO;
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.reportsData && !changes.reportsData.firstChange) {
			this.reportsData = changes.reportsData.currentValue;
			const range = this.initializeRange();
			this.setupTicketTypes();
			this.filterGraphDataByDateRange(range);
		}
	}

	initializeRange(): DateRangeFilter {
		this.isEventFinished = new Date() > new Date(this.reportsData.endDate);

		const { dataFrom, dataTo } = this.chartService.getDateRange(
			this.reportsData.reportByTicketType,
			new Date(this.reportsData.startDate),
			new Date(this.reportsData.endDate)
		);
		this.salesDataMinDate = dataFrom;
		this.salesDataMaxDate = dataTo;
		return {
			to: this.salesDataMaxDate,
			from: this.salesDataMinDate,
			filterType: BreakdownRangeFilterRange.ALL_TIME,
		};
	}

	updateGraphData() {
		const { ticketsSold, income } = this.chartService.createByTicketTypeGraphData(
			this.reportsData.reportByTicketType,
			this.currentBreakdownRangeFilter,
			this.currentTicketTypeFilter
		);

		this.ticketTypesSoldGraphPoints = ticketsSold;
		this.eventIncomeGraphPoints = income;

		this.pageVisitsGraphPoints = this.chartService.createPageVisitsGraphData(
			this.reportsData.pageVisits,
			this.currentBreakdownRangeFilter
		);
	}

	filterGraphDataByDateRange(range: DateRangeFilter) {
		this.currentBreakdownRangeFilter.to = range.to;
		this.currentBreakdownRangeFilter.from = range.from;
		this.currentBreakdownRangeFilter.filterType = range.filterType;
		this.appliedFilterText = this.getAppliedFilterText(range);
		this.updateGraphData();
	}

	onScheduleItemFilterChange(scheduleItemId: { scheduleItemId: number }) {
		this.store.dispatch(
			new GetEventDashboardReports({
				id: this.eventId,
				scheduleItemId: scheduleItemId.scheduleItemId,
			})
		);
	}

	setupTicketTypes() {
		const uniqueTypes = new Set<string>();
		this.availableTicketTypes = this.reportsData.reportByTicketType.reduce((acc: TicketType[], report) => {
			report.series.forEach((type) => {
				if (!uniqueTypes.has(type.id)) {
					uniqueTypes.add(type.id);
					acc.push({ id: +type.id, name: type.name });
				}
			});
			return acc;
		}, []);
		this.filteredTicketTypes = [...this.availableTicketTypes];
		this.selectedTicketTypes = [...this.availableTicketTypes];
		this.currentTicketTypeFilter = [...this.availableTicketTypes];
	}

	updateSelectedTicketTypes(types: TicketType[]) {
		this.currentTicketTypeFilter = types;
		this.updateGraphData();
	}

	filterTicketTypes(search: string) {
		const searchTerm = search.toLowerCase();
		this.filteredTicketTypes = search
			? this.availableTicketTypes.filter((type) => type.name.toLowerCase().includes(searchTerm))
			: [...this.availableTicketTypes];
	}

	toggleSelectAll(event) {
		this.selectedTicketTypes = event.checked ? [...this.filteredTicketTypes] : [];
		this.updateSelectedTicketTypes(this.selectedTicketTypes);
	}

	isAllSelected(): boolean {
		return this.selectedTicketTypes.length === this.filteredTicketTypes.length;
	}

	getAppliedFilterText(range: DateRangeFilter) {
		if (range.filterType === BreakdownRangeFilterRange.CUSTOM) {
			return `${formatDate(range.from, 'longDate', 'en-US')} to ${formatDate(range.to, 'longDate', 'en-US')}`;
		}
		return BreakdownRangeFilterPresets[range.filterType].title;
	}

	compareTicketTypes(t1: TicketType, t2: TicketType): boolean {
		return t1 && t2 ? t1.id === t2.id : t1 === t2;
	}

	formatNumberWithSeparators(value: number): string {
		return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	}
}
