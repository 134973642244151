import { NgModule, ModuleWithProviders, Inject, Type } from '@angular/core';
import { NotificationService } from '@app/services/notification/notification.service';
import { NotificationDictionary } from './notification.dictionary';
import { NOTIFICATION_DICTIONARY, NOTIFICATION_FEATURE_DICTIONARY } from './notification.tokens';
import { NotificationSourceService } from './notification-source.service';

@NgModule({})
export class NotificationRootModule {
	constructor(
		private sources: NotificationSourceService,
		@Inject(NOTIFICATION_DICTIONARY) rootNotifications: NotificationDictionary[]
	) {
		rootNotifications.forEach(n => this.sources.addNotifications(n));
	}

	addNotifications = (notifications: NotificationDictionary) =>
		this.sources.addNotifications(notifications);
}

@NgModule({})
export class NotificationFeatureModule {
	constructor(
		private root: NotificationRootModule,
		@Inject(NOTIFICATION_FEATURE_DICTIONARY) featureNotificationGroups: NotificationDictionary[][]
	) {
		featureNotificationGroups.forEach(ns => ns.forEach(n => this.root.addNotifications(n)));
	}
}

@NgModule({})
export class NotificationModule {
	static forRoot(rootDictionary: Type<NotificationDictionary>[]): ModuleWithProviders<NotificationRootModule> {
		return {
			ngModule: NotificationRootModule,
			providers: [
				rootDictionary,
				NotificationSourceService,
				NotificationService,
				{
					deps: rootDictionary,
					provide: NOTIFICATION_DICTIONARY,
					useFactory: createSourceInstances,
				},
			],
		};
	}

	static forFeature(notifications: Type<NotificationDictionary>[]): ModuleWithProviders<NotificationFeatureModule> {
		return {
			ngModule: NotificationFeatureModule,
			providers: [
				notifications,
				{
					provide: NOTIFICATION_FEATURE_DICTIONARY,
					deps: notifications,
					multi: true,
					useFactory: createSourceInstances,
				},
			],
		};
	}
}

export function createSourceInstances(...instances: NotificationDictionary[]) {
	return instances;
}
