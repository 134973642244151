<div class="l-container" [ngClass]="{ 'mobile': isMobile }">
	<div *ngIf="!isLoading else pageLoading;" class="l-content">
		<div class="l-header">
			<div>Welcome To Your Event Dashboard</div>
			<app-event-actions-menu-button
				[eventId]="eventId"
				[isDraft]="metadata.productStatus == ProductStatus.Draft"
				(copyEventButton)="handleEventActionCopy()"
				(postponeEventButton)="handleEventActionPostpone()"
				(cancelEventButton)="handleEventActionCancel()"
				(copyEventLinkButton)="handleEventActionCopyLink()"
				(editEventLinkButton)="handleEventActionEditLink()"
				(sendInvitesButton)="handleSendInvitesNavigation()"
				(qrCodeDownloadButton)="handleEventQrCodeDownload()"
			></app-event-actions-menu-button>
		</div>
		<app-event-completion-progress
			*ngIf="dashboard.eventSetupProgress && dashboard.productStatus !== ProductStatus.Active"
			[isLoading]="isLoading"
			[isMobile]="isMobile"
			[setupProgress]="dashboard.eventSetupProgress"
			[eventId]="eventId"
			[productStatus]="dashboard.productStatus"
			[commission]="dashboard.commission"
			[serviceFeeForHost]="dashboard.serviceFeeForHost"
			[commissionForHost]="dashboard.commissionForHost"
			[autoApproveEvents]="dashboard.autoApproveEvents"
			(skipItem)="skipCompletionItem($event)"
			(submitEventForApproval)="submitEventForApproval()"
		/>
		<div class="l-top-container">
			<app-dashboard-header
				class="l-event-header"
				[eventId]="eventId"
				[dashboard]="dashboard"
				[isLoading]="isLoading"
				(toggleEventVisibility)="toggleEventVisibility()"
				[isMobile]="isMobile"
				(toggleSheduleToGoPublic)="toggleSheduleToGoPublic($event)"
				(redirectEvent)="redirectFinishedEvent($event)"
			>
			</app-dashboard-header>
			<div class="l-guests-orders" *ngIf="dashboard.productStatus !== ProductStatus.Draft">
				<app-total-guests
					*ngIf="dashboard?.totalGuests"
					[event]="dashboard"
					[totalGuests]="dashboard.totalGuests.total"
					[maximumGuests]="dashboard.totalGuests.maxCapacity"
					[isSeated]="isSeated"
				>
				</app-total-guests>
				<app-recent-orders
					class="l-recent-orders"
					[isLoading]="isReportsLoading"
					[recentOrders]="reports?.recentOrders"
					[eventId]="eventId"
					[currencySymbol]="dashboard?.currency.symbol"
				></app-recent-orders>
			</div>
		</div>
	</div>
	<ng-template #pageLoading>
        <div class="l-page-loader">
            <mat-spinner></mat-spinner>
        </div>
    </ng-template>
	<app-overview-reports
		*ngIf="reports"
		[reportsData]="reports"
		[isReportsLoading]="isReportsLoading"
		[isOngoing]="isOngoing"
		[scheduleItems]="scheduleItems"
		[eventId]="eventId"
		[isMobile]="isMobile"a
		[currencyISO]="currencyISO"
	></app-overview-reports>
</div>