import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MailAccount } from '@app/models/organiser.model';
import { StoreService } from '@app/services/store/store.service';
import { AddMailAccount } from '@app/store/actions/organiser/organiser.actions';
import { OrganiserActionsConstants } from '@app/store/actions/organiser/organiser.actions.constants';
import { emailRegex } from '@app/utils/regex';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs';

@Component({
	selector: 'app-add-mail-account-modal',
	templateUrl: './add-mail-account-modal.component.html',
	styleUrls: ['./add-mail-account-modal.component.sass'],
})
export class AddMailAccountModalComponent implements OnInit {

	isLoading = false;
	emailRegex: RegExp = emailRegex;

	mailAccount = new MailAccount;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		public dialogRef: MatDialogRef<AddMailAccountModalComponent>,
		private store: StoreService,
		private actions$: Actions,
		@Inject(MAT_DIALOG_DATA) public data: {
			isMobile: boolean;
			userId: number;
		}
	) { }

	ngOnInit(): void {

		this.actions$
			.pipe(
				ofType(
					OrganiserActionsConstants.ADD_MAIL_ACCOUNT_SUCCESS,
					OrganiserActionsConstants.ADD_MAIL_ACCOUNT_FAILED
				),
				takeUntil(this.destroyed$)
			)
			.subscribe(({ type }: { type: string }) => {
				if (type === OrganiserActionsConstants.ADD_MAIL_ACCOUNT_SUCCESS) {
					this.dialogRef.close();
				}
				this.isLoading = false;
			});
	}


	handleCancel() {
		this.dialogRef.close();
	}

	handleAddAccount() {
		this.isLoading = true;
		const account = {...this.mailAccount};
		this.store.dispatch(new AddMailAccount({ id: this.data.userId, mailAccount: account }));
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}

}
