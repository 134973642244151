<div class="l-container">
	<div [ngClass]="{ 'input-group-mobile-large': isMobile, 'input-group-web-large': !isMobile }">
		<label class="g-form-label">
			<span [innerHTML]="label"></span>
			<span
				*ngIf='isRequired'
				class="g-orange"
			> *</span>
		</label>
		<textarea
			id="terms-and-conditions-field"
			class="form-control mh-100 l-textarea"
			[disabled]="true"
		>{{ control.options }}
		</textarea>
	</div>
	<div class="d-flex align-items-center">
		<mat-checkbox
			id="tc-checkbox-field"
			[formControl]="inputFormControl"
		></mat-checkbox>
		I agree to the above terms
	</div>
	<div *ngIf="inputFormControl.invalid">
		<div class="g-error-input">
			<div *ngIf="inputFormControl.hasError('selectRequired')">You need to accept the Terms and Conditions before
				submitting.</div>
		</div>
	</div>
</div>