import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DropdownOption, HeaderContent } from '@app/models/shared';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { InternalURLCreator } from '@app/services/url/url.dictionary';
import { EVENT_ID_PARAM_KEY, SEND_OPTIONS } from '@app/utils/consts';
import { Dictionary } from '@ngrx/entity';
import { takeUntil, take } from 'rxjs';


@Component({
	selector: 'app-guest-list',
	templateUrl: './guest-list.component.html',
	styleUrls: ['./guest-list.component.sass'],
})
export class GuestListComponent implements OnInit {
	isMobile = false;
	eventId: number;
	sendOptions: Dictionary<DropdownOption> = SEND_OPTIONS;

	headerContent: HeaderContent = {
		breadCrumbs: [
			{
				routeName: 'Guest Management',
				routeTo: () => InternalURLCreator.guestManagement(this.eventId),
			},
			{
				routeName: 'Guest List',
			},
		],
		title: 'Guest List',
		description: `Manage all aspects of your event guests efficiently. From tracking orders to 
		communicating with attendees, ensure a smooth and organized event experience.`,
	};

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private breakPointService: BreakpointService,
		private activatedRoute: ActivatedRoute
	) { }

	ngOnInit(): void {
		this.breakPointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});

		this.activatedRoute.parent.paramMap.pipe(take(1)).subscribe((params) => {
			this.eventId = +params.get(EVENT_ID_PARAM_KEY);
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}

}
