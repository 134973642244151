import { Component, EventEmitter, OnInit } from '@angular/core';
import { StoreService } from '@app/services/store/store.service';
import { Actions, ofType } from '@ngrx/effects';
import { filter, takeUntil } from 'rxjs';
import { OrganiserActionsConstants } from '@app/store/actions/organiser/organiser.actions.constants';
import * as organiserSelectors from '@app/store/selectors/organiser.selector';
import * as userSelectors from '@app/store/selectors/user.selector';
import { NameAndID } from '@app/models/organiser.model';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { MatDialog } from '@angular/material/dialog';
import { GenerateReportsModalComponent } from '../../modals/generate-reports-modal/generate-reports-modal.component';
import { GetOrganiserNames } from '@app/store/actions/organiser/organiser.actions';

@Component({
	selector: 'app-events',
	templateUrl: './events.component.html',
	styleUrls: ['./events.component.sass'],
})
export class EventsComponent implements OnInit {
	isMobile = false;
	isPageLoading = true;
	isEventsTab = true;
	userId: number;

	organiserNames: NameAndID[];

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private store: StoreService,
		private actions$: Actions,
		private breakpointService: BreakpointService,
		private dialog: MatDialog
	) { }

	ngOnInit(): void {
		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});
		this.initialiseEventsInfo();
	}

	initialiseEventsInfo() {
		this.actions$
			.pipe(
				ofType(
					OrganiserActionsConstants.GET_ORGANISER_NAMES_FAILED
				),
				takeUntil(this.destroyed$)
			)
			.subscribe(() => {
				this.isPageLoading = false;
			});

		this.store.select(userSelectors.userId()).pipe(takeUntil(this.destroyed$), filter((user) => user != null)).subscribe((userId) => {
			this.userId = userId;
			this.store.dispatch(new GetOrganiserNames({ id: this.userId }));
		});

		this.store.select(organiserSelectors.getOrganiserNames())
			.pipe(takeUntil(this.destroyed$), filter(organiser => organiser != null)).subscribe((organiserNames) => {
				this.organiserNames = organiserNames;
				this.isPageLoading = false;
			});
	}

	handleEmailReport() {
		this.dialog.open(GenerateReportsModalComponent, {
			data: {
				isMobile: this.isMobile,
				userId: this.userId,
			},
			panelClass: 'g-standard-dialog',
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}

}
