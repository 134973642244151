import { NgModule } from '@angular/core';
import { UserControlPanelComponent } from './user-control-panel.component';
import { SharedModule } from '@app/shared/shared.module';
import { UserControlPanelSharedModule } from './user-shared/user-control-panel-shared.module';
import { UserOrganiserProfilesModule } from './organiser-profiles/organiser-profiles.module';
import { UserManageBookingModule } from './manage-booking/manage-booking.module';
import { UserMyTicketsModule } from './my-tickets/my-tickets.module';

@NgModule({
	declarations: [
		UserControlPanelComponent,
	],
	imports: [
		SharedModule,
		UserControlPanelSharedModule,
		UserOrganiserProfilesModule,
		UserManageBookingModule,
		UserMyTicketsModule,
	],
})
export class UserControlPanelModule {}
