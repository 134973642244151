import { Component, EventEmitter, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { EventSideNavItems, SectionRouteCard } from '@app/models/shared';
import { InternalURLCreator, URLCreator } from '@app/services/url/url.dictionary';
import { handleOrder } from '@app/utils/common-helpers';
import { EVENT_NAV, ORGANISER_NAV, OrganiserHubRoutes } from '@app/utils/consts';
import { Dictionary } from '@ngrx/entity';
import { convertToIdFormat } from '@app/utils/common-helpers';
import { EventMetadata } from '@app/models/event-metadata.model';

@Component({
	selector: 'app-event-side-nav',
	templateUrl: './event-side-nav.component.html',
	styleUrls: ['./event-side-nav.component.sass'],
})
export class EventSideNavComponent implements OnInit, OnChanges {
	@Input() currentNavItem: string;
	@Input() metadata: EventMetadata;

	parentNavItems: Dictionary<SectionRouteCard> = ORGANISER_NAV;
	navItems: Dictionary<EventSideNavItems> = EVENT_NAV;
	currentParentNavItem: SectionRouteCard;
	precomputedImageSources: { [key: string]: string } = {};
	handleDefaultOrder = handleOrder;
	eventMetadata: EventMetadata;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	ngOnInit(): void {
		this.currentParentNavItem = this.parentNavItems[OrganiserHubRoutes.EVENTS];
		this.computeImageSources();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.metadata) {
			this.eventMetadata = changes.metadata.currentValue;
		}
	}

	computeImageSources(): void {
		this.precomputedImageSources = {};
		for (const key in this.parentNavItems) {
			if (this.parentNavItems.hasOwnProperty(key)) {
				const item = this.parentNavItems[key];
				const isActive = key === OrganiserHubRoutes.EVENTS;
				this.precomputedImageSources[item.title] = `${item.iconPath}${isActive ? '-active' : ''}.svg`;
			}
		}
	}

	handleMyEventsNavigation(): string {
		return InternalURLCreator.orgEvents();
	}

	formatId(itemTitle: string, prefix: string ): string {
		return convertToIdFormat(itemTitle, prefix);
	}

	getPreviewLink() {
		return URLCreator.preview(this.eventMetadata.id, this.eventMetadata.name, this.eventMetadata.productStatus);
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
