import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AdditionalInfo } from '@app/models/user.model';

@Component({
	selector: 'app-mobile-resale-info-modal',
	templateUrl: './mobile-resale-info-modal.component.html',
	styleUrls: ['./mobile-resale-info-modal.component.sass'],
})
export class MobileResaleInfoModalComponent {
	email: string;
	isPublic = false;
	additionalInfoIsValid = true;

	constructor(
		public dialogRef: MatDialogRef<MobileResaleInfoModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data: {
			allowPrivateResale: boolean;
			allowPublicResale: boolean;
		}
	) {}

	handleAdditionalInfo(info: AdditionalInfo): void {
		this.email = info.email;
		this.isPublic = info.isPublic;
		this.additionalInfoIsValid = info.isValid;
	}

	handleSubmit() {
		this.dialogRef.close({
			accountId: null,
			isPublic: this.isPublic,
			email: this.email,
		});
	}

	handleClose() {
		this.dialogRef.close();
	}
}
