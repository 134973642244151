<div
	class="l-container"
	[ngClass]="{ 'l-container-mobile': data.isMobile }"
>
	<div class="l-title-container">
		<h3 class="mb-0">{{data.title}}</h3>
		<span class="l-current-filter">
			<mat-icon class="l-icon">tune</mat-icon>
			{{data.appliedFilterText}}
		</span>
	</div>
	<mat-icon
		class="l-close-button"
		(click)="handleClose()"
	>close</mat-icon>

	<mat-dialog-content class="l-content">
		<table
			*ngIf="!isLoading; else showLoading"
			mat-table
			[dataSource]="tableData"
			class="l-table"
			[ngClass]="{ 'web': !data.isMobile, 'mobile': data.isMobile }"
		>
			<ng-container
				matColumnDef="name"
				[sticky]="true"
			>
				<th
					mat-header-cell
					*matHeaderCellDef
					class="l-name-header l-header"
				> Name </th>
				<td
					mat-cell
					*matCellDef="let element"
					class="l-name-cell"
				>
					<div
						class="l-name"
						[matTooltip]="element.name.length > 60 ? element.name : ''"
						matTooltipPosition="right"
						#nameTooltip="matTooltip"
						(click)="nameTooltip.toggle()"
					>
						<div
							class="l-legend-item"
							[style.background-color]="colorDictionary[element.name]"
						></div>
						{{ element.name | truncate: 60 }}
					</div>
				</td>
				<td
					mat-footer-cell
					*matFooterCellDef
					class="l-total-name-cell"
				> <b>Total</b> </td>
			</ng-container>
			<ng-container
				[matColumnDef]="column"
				*ngFor="let column of dateColumns; let i = index"
			>
				<th
					mat-header-cell
					*matHeaderCellDef
					class="l-header"
				> {{column}} </th>
				<td
					mat-cell
					*matCellDef="let element"
				>
					{{ element[column] | dynamicCurrency: data.currencySymbol }}
				</td>
				<td
					mat-footer-cell
					*matFooterCellDef
					class="l-column-total"
				>
					<b>
						{{ columnTotals[i] | dynamicCurrency: data.currencySymbol }}
					</b>
				</td>
			</ng-container>
			<ng-container
				matColumnDef="total"
				[stickyEnd]="true"
			>
				<th
					mat-header-cell
					*matHeaderCellDef
					class="l-total-header l-header"
				> <b>Total</b></th>
				<td
					mat-cell
					*matCellDef="let element"
					class="l-total"
				>
					<b>
						{{ element.total | dynamicCurrency: data.currencySymbol }}
					</b>
				</td>
				<td
					mat-footer-cell
					*matFooterCellDef
					class="l-total-cell"
				>
					<b>
						{{ total | dynamicCurrency: data.currencySymbol }}
					</b>
				</td>
			</ng-container>

			<tr
				mat-header-row
				*matHeaderRowDef="displayedColumns; sticky: true"
			></tr>
			<tr
				mat-row
				*matRowDef="let row; columns: displayedColumns"
			></tr>
			<tr
				mat-footer-row
				*matFooterRowDef="displayedColumns; sticky: true"
				class="l-total-row"
			></tr>

		</table>
	</mat-dialog-content>

	<ng-template #showLoading>
		<mat-spinner></mat-spinner>
	</ng-template>
</div>