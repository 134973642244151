import { omit } from 'lodash';
import { ProductMetadata } from '@app/models/product.model';
import { EventMetadata } from '@app/models/event-metadata.model';
export interface AclItem {
	name: string;
	systemName: string;
	url: string;
	aclActions: { actionName: string; systemName: string }[];
}

export function mapPermissionsFrom(aclItems: AclItem[]) {
	return aclItems.reduce((acc, v) => {
		acc[v.systemName] = {
			key: v.systemName,
			name: v.name,
			access: v.aclActions && v.aclActions.length ? v.aclActions.map(el => el.systemName) : null,
		};

		return acc;
	}, {});
}

export function mapMetadataFrom(metadata: ProductMetadata) {
	return omit({
		...metadata,
		featureKey: null,
		permissions: mapPermissionsFrom(metadata.aclItems),
	}, 'aclItems') as EventMetadata;
}
