<div [ngClass]="'l-container ' + inputClass">
    <label class="g-form-label">
        {{label}}
        <mat-icon
            *ngIf="tooltip"
            class="l-tooltip"
            [matTooltip]="tooltip"
            matTooltipPosition="right"
			#slideFieldTooltip="matTooltip"
			(click)="slideFieldTooltip.toggle()"
        >info</mat-icon>
    </label>
    <div [ngClass]="{'l-toggle': true, 'l-toggle-mobile': isMobile}">
        <mat-slide-toggle
            [disabled]="disabled"
            [(ngModel)]="value"
            (change)="onChange($event)"
            class="standard-toggle"
            [id]="customId"
        >
        </mat-slide-toggle>
    </div>
</div>
