import { Component, Input, OnInit } from '@angular/core';
import { IconType } from '@app/models/event.model';
import { SectionRouteCard } from '@app/models/shared';
import { convertToIdFormat } from '@app/utils/common-helpers';

@Component({
	selector: 'app-section-route-card',
	templateUrl: './section-route-card.component.html',
	styleUrls: ['./section-route-card.component.sass'],
})
export class SectionRouteCardComponent implements OnInit {
	@Input() card: SectionRouteCard;
	@Input() iconType = IconType.SVG;

	constructor() {}

	ngOnInit(): void {}

	formatId(itemTitle: string): string {
		return convertToIdFormat(itemTitle);
	}
}
