import { NgModule } from '@angular/core';
import { environment } from 'environments/environment';
import { MembershipDomainAPI } from './domains/membership';
import { EventDomainAPI } from './domains/event';
import { OldAPIDomainAPI } from './domains/oldApi';
import { HttpService } from './http/http.service';
import { AuthInterceptorProvider } from './http/auth.interceptor';
import { MockInterceptorProvider } from './http/mock.interceptor';
import { FileDomainAPI } from '@app/api/domains/file';
import { OrganiserDomainAPI } from './domains/organiser';
import { AnalyticsInterceptorProvider } from './http/analytics.interceptor';

const isMocksAllowed = environment.allowMocks || !environment.production;

const mainServices =  [
	MembershipDomainAPI,
	EventDomainAPI,
	OldAPIDomainAPI,
	FileDomainAPI,
	OrganiserDomainAPI,
	HttpService,
	AuthInterceptorProvider,
	AnalyticsInterceptorProvider,
];

const providers = isMocksAllowed
	? [
		...mainServices,
		MockInterceptorProvider,
	]
	: mainServices;

@NgModule({
	providers,
})
export class APIModule {}
