<div class="event-form-wrap"
	*ngIf="isAccessGranted$ | async"
	[loadingSp]="(isFetching$ | async)">
	<div class="event-form">
		<div *ngIf="isScheduledEvent" class="schedule-block">
			<div class="ui-block">
				<div class="schedule">
					<app-schedule-selection
						[scheduleItems]="scheduleItems"
						[selectedScheduleItem]="selectedScheduleItem"
						(scheduleItemChange)="onScheduleItemChange($event)"
					>
					</app-schedule-selection>
				</div>
			</div>
		</div>
		<div class="event-form-content">
			<div class="ui-block" 
				*ngIf="atTheDoor && isValidSchedule()">
				
				<div class="ui-message-block greyed" *ngIf="isScheduledEvent">
					<i class="ui-message-icon info fa fa-info-circle fa-2x"></i>
					<p class="ui-message-desc">
						Please note that the door sales links below are different depending on the event schedule date you have selected above.
					</p>
				</div>

				<div class="ui-message-block greyed">
					<i class="ui-message-icon info fa fa-info-circle fa-2x" *ngIf="!isScheduledEvent"></i>
					<p class="ui-message-desc">
						Click below to access the door sales application. This application runs in your browser and only requires an internet connection on first load. Thereafter it can be used online or offline by bookmarking the link. 
					</p>
				</div>
				
				<p class="ui-text ui-text-greyed">Copy link to access door sales directly: <a href={{atTheDoor.shortUrl}} class="ui-link">{{atTheDoor.shortUrl}}</a></p>
			</div>
			<div class="ui-block" 
				*ngIf="atTheDoor && !isValidSchedule()">
				<div class="ui-message-block greyed">
					<i class="ui-message-icon info fa fa-info-circle fa-2x"></i>
					<p class="ui-message-desc">
						You are required to select a specific event date in order to access the door sales application
					</p>
				</div>
			</div>
		</div>
		<div
			class='event-form-submit-button' *ngIf="isValidSchedule()">
				<app-button
					label="Access door sales"
					(onClick)="redirectToDoorSale()"
				>
				</app-button>
		</div>
	</div>
</div>
