<div class="l-container">
	<h3 class='text-center mb-3'>Before you submit</h3>
	<p
		class="mb-3"
		*ngIf="isWarningVisible(data.warningType)"
	>
		Please note that at least one of your tickets types has the amount for sale
		<span class='fw-bold'>{{ getWarningShortMessage(data.warningType) }}</span>
		<span class='g-green fw-bold'>{{ getWarningMessage(data.warningType) }}</span>
		<br>{{ getSecondaryWarningMessage(data.warningType) }}
	</p>
	<hr *ngIf='data.streamWarning && data.warningType' />
	<p
		class="mb-3"
		*ngIf="data.streamWarning"
	>
		If you need to <span class='g-green fw-bold'>link your new tickets to a stream</span>,
		<br>please go to your streaming management page after clicking Proceed.
	</p>
	<hr />
	<p class="mb-3 text-center">Would you like to proceed saving your tickets?</p>
	<div class="l-buttons-container">
		<button
			class="g-secondary-button l-button"
			(click)="handleClose(false)"
		>CANCEL</button>
		<button
			class="g-primary-button l-button"
			(click)="handleClose(true)"
		>PROCEED</button>
	</div>
</div>
