import {
	BankAccountDescriptions,
	EventSideNavItems,
	HeaderContent,
	SectionRouteCard,
	DropdownOption,
	SideNavItems,
	Socials
} from '@app/models/shared';
import { InternalURLCreator, URLCreator } from '@app/services/url/url.dictionary';
import { FilterGroup } from '@app/models/filter-and-sort.model';
import { TicketType } from '@app/models/ticket.model';

export const REDIRECT_PARAM_KEY = 'redirect';

export const QUICKET_LOGO = 'https://www.quicket.co.za/content/images/static/01_PrimaryLogo_4c.svg';

export const MY_ACCOUNT_CARDS: { [key: string]: SectionRouteCard } = {
	myTickets: {
		title: 'My Tickets',
		iconPath: './assets/icons/ticket',
		description: 'View and manage your tickets',
		routeTo: () => InternalURLCreator.myTickets(),
	},
	organiserHub: {
		title: 'Organiser Hub',
		iconPath: './assets/icons/organiser-hub',
		description: 'Manage and create your events',
		routeTo: () => InternalURLCreator.orgDashboard(),
	},
	personalInfo: {
		title: 'Personal Information',
		iconPath: './assets/icons/user',
		description: 'Update your personal information',
		routeTo: () => InternalURLCreator.personalInfo(),
	},
	contactInfo: {
		title: 'Contact Information',
		iconPath: './assets/icons/phone',
		description: 'Update your contact information',
		routeTo: () => InternalURLCreator.contactInfo(),
	},
	emailPreference: {
		title: 'Email Preference',
		iconPath: './assets/icons/email',
		description: 'Set your email preferences',
		routeTo: () => InternalURLCreator.emailPreference(),
	},
	password: {
		title: 'Password',
		iconPath: './assets/icons/lock',
		description: 'Change your password',
		routeTo: () => InternalURLCreator.changePassword(),
	},
	payout: {
		title: 'Payouts',
		iconPath: './assets/icons/bank-card',
		description: 'View your saved bank accounts',
		routeTo: () => InternalURLCreator.payouts(),
	},
	APIKey: {
		title: 'API Keys',
		iconPath: './assets/icons/fork',
		description: 'Generate user tokens',
		routeTo: () => InternalURLCreator.apiKey(),
	},
	vending: {
		title: 'Vending',
		iconPath: './assets/icons/vending',
		description: 'Activate vending for your account.',
		routeTo: () => InternalURLCreator.myVending(),
	},
	registrations: {
		title: 'Registrations',
		iconPath: './assets/icons/registrations',
		description: 'View and update your event registrations',
		routeTo: () => InternalURLCreator.myRegistrations(),
	},
};

export const MY_ACCOUNT_HEADER: { [key: string]: HeaderContent } = {
	personalInfo: {
		breadCrumbs: [
			{
				routeName: 'My Account',
				routeTo: () => InternalURLCreator.myAccount(),
			},
			{
				routeName: 'Personal Information',
			},
		],
		title: 'Personal Information',
	},
	contactInfo: {
		breadCrumbs: [
			{
				routeName: 'My Account',
				routeTo: () => InternalURLCreator.myAccount(),
			},
			{
				routeName: 'Contact Information',
			},
		],
		title: 'Contact Information',
		description: `Keep your contact details up to date. Please note that updating your email address 
		here will automatically update your Quicket account email.`,
	},
	emailPreference: {
		breadCrumbs: [
			{
				routeName: 'My Account',
				routeTo: () => InternalURLCreator.myAccount(),
			},
			{
				routeName: 'Email Preference',
			},
		],
		title: 'Email Preference',
		description: 'Select which lists you would like to receive email communications from.',
	},
	changePassword: {
		breadCrumbs: [
			{
				routeName: 'My Account',
				routeTo: () => InternalURLCreator.myAccount(),
			},
			{
				routeName: 'Password',
			},
		],
		title: 'Change Password',
		description: 'Update your password',
	},
	payouts: {
		breadCrumbs: [
			{
				routeName: 'My Account',
				routeTo: () => InternalURLCreator.myAccount(),
			},
			{
				routeName: 'Payouts',
			},
		],
		title: 'My Bank Accounts',
		description: 'Below are your saved bank accounts. Select a bank account to edit it.',
	},
	api: {
		breadCrumbs: [
			{
				routeName: 'My Account',
				routeTo: () => InternalURLCreator.myAccount(),
			},
			{
				routeName: 'API Keys',
			},
		],
		title: 'API keys',
	},
	mySavedEvents: {
		breadCrumbs: [
			{
				routeName: 'My Account',
				routeTo: () => InternalURLCreator.myAccount(),
			},
			{
				routeName: 'My Saved Events',
			},
		],
		title: 'My Saved Events',
		description: 'These are events you have previously favourited.',
	},
	vending: {
		breadCrumbs: [
			{
				routeName: 'My Account',
				routeTo: () => InternalURLCreator.myAccount(),
			},
			{
				routeName: 'Vending',
			},
		],
		title: 'Vending',
		description: 'Enable vending for your account',
	},

};

export const Genders = ['Male', 'Female', 'Non-binary', 'Prefer not to say'];

export const MY_TICKETS_HEADER: { [key: string]: HeaderContent } = {
	myTickets: {
		breadCrumbs: [
			{
				routeName: 'My Account',
				routeTo: () => InternalURLCreator.myAccount(),
			},
			{
				routeName: 'My Tickets',
				routeTo: () => InternalURLCreator.myTickets(),
			},
		],
		title: 'My Tickets',
		description: 'Manage everything ticket related below. Tickets for events are only obtained once payment has been finalized.',
	},
	myRegistrations: {
		breadCrumbs: [
			{
				routeName: 'My Account',
				routeTo: () => InternalURLCreator.myAccount(),
			},
			{
				routeName: 'My Registrations',
				routeTo: () => InternalURLCreator.myRegistrations(),
			},
		],
		title: 'My Registrations',
		description: 'Some events require registration before tickets are purchaseable. Below are events you have already registered for.',
	},
	registrationDetails: {
		breadCrumbs: [
			{
				routeName: 'My Registrations',
				routeTo: () => InternalURLCreator.myRegistrations(),
			},
		],
		title: 'Edit Registration Information',
		description: 'Below you can edit your answers to your registration for ',
	},
	editTicket: {
		breadCrumbs: [
			{
				routeName: 'My Tickets',
				routeTo: () => InternalURLCreator.myTickets(),
			},
			{
				routeName: 'Ticket Details',
				routeTo: (id: number) => InternalURLCreator.manageBooking(id),
			},
		],
		title: 'Edit Ticket Details',
	},
	refundRequest: {
		breadCrumbs: [
			{
				routeName: 'My Tickets',
				routeTo: () => InternalURLCreator.myTickets(),
			},
			{
				routeName: 'Ticket Details',
				routeTo: (id: number) => InternalURLCreator.manageBooking(id),
			},
		],
		title: 'Cancellation and refund request',
	},
	ticketTransfer: {
		breadCrumbs: [
			{
				routeName: 'My Tickets',
				routeTo: () => InternalURLCreator.myTickets(),
			},
			{
				routeName: 'Ticket Details',
				routeTo: (id: number) => InternalURLCreator.manageBooking(id),
			},
		],
		title: 'Transfer Tickets',
	},
	ticketResale: {
		breadCrumbs: [
			{
				routeName: 'My Tickets',
				routeTo: () => InternalURLCreator.myTickets(),
			},
			{
				routeName: 'Ticket Details',
				routeTo: (id: number) => InternalURLCreator.manageBooking(id),
			},
		],
		description: 'You will receive a refund once your ticket is sold.',
	},
	manageBooking: {
		breadCrumbs: [
			{
				routeName: 'My Account',
				routeTo: () => InternalURLCreator.myAccount(),
			},
			{
				routeName: 'My Tickets',
				routeTo: () => InternalURLCreator.myTickets(),
			},
		],
	},
};

export const SIDENAV_ITEMS: { [key: string]: SideNavItems } = {
	myTickets: {
		title: 'My Tickets',
		routeTo: URLCreator.myTickets(),
	},
	organiserHub: {
		title: 'Organiser Hub',
		routeTo: URLCreator.orgDashboard(),
	},
	myAccount: {
		title: 'My Account',
		routeTo: URLCreator.userProfile(),
	},
	savedEvents: {
		title: 'Saved Events',
		routeTo: URLCreator.savedEvents(),
	},
	earnCommission: {
		title: 'Earn Commission',
		routeTo: URLCreator.earnCommission(),
	},
	logout: {
		title: 'Logout',
		routeTo: URLCreator.logout(),
	},
};

export const SOCIALS: { [key: string]: Socials } = {
	linkedin: {
		title: 'LinkedIn',
		iconPath: './assets/icons/linkedin_green.svg',
		routeTo: 'http://www.linkedin.com/company/2350818',
	},
	twitter: {
		title: 'Twitter',
		iconPath: './assets/icons/x-twitter_green.svg',
		routeTo: 'https://twitter.com/QuicketSA',
	},
	instagram: {
		title: 'Instagram',
		iconPath: './assets/icons/instagram_green.svg',
		routeTo: 'https://www.instagram.com/quicket',
	},
	facebook: {
		title: 'Facebook',
		iconPath: './assets/icons/facebook_green.svg',
		routeTo: 'https://www.facebook.com/203195039742896',
	},
};

export const MyTicketFilters: FilterGroup[] = [
	{
		name: 'Status',
		filter: [
			{ value: 10, viewValue: 'Upcoming' },
			{ value: 30, viewValue: 'Past' },
			{ value: 40, viewValue: 'Provisional' },
			{ value: 50, viewValue: 'Cancelled' },
		],
	},
];

export const GENERIC_ERROR_MESSAGE = 'Something went wrong, please try again or contact support@quicket.com';

export enum ManageBookingCards {
	EDIT_TICKET = 'editTicket',
	VIEW_INVOICE = 'viewInvoice',
	EDIT_INVOICE = 'editInvoice',
	TRANSFER_TICKET = 'transferTicket',
	REQUEST_REFUND = 'requestRefund',
	TICKET_RESALE = 'ticketResale',
	TRANSFER_BACK = 'transferBack',
}

export const TICKET_DETAIL_CARDS: { [key in ManageBookingCards]: SectionRouteCard } = {
	[ManageBookingCards.EDIT_TICKET]: {
		title: 'Edit Ticket Details',
		iconPath: './assets/icons/edit',
		routeTo: (id: number) => InternalURLCreator.editTickets(id),
		externalRouteTo: (id: number) => URLCreator.editTickets(id),
	},
	[ManageBookingCards.VIEW_INVOICE]: {
		title: 'View Invoice',
		iconPath: './assets/icons/view-invoice',
		routeTo: (id: number, encrypt: string) => URLCreator.viewInvoice(id, encrypt),
	},
	[ManageBookingCards.EDIT_INVOICE]: {
		title: 'Edit Invoice',
		iconPath: './assets/icons/edit-invoice',
	},
	[ManageBookingCards.TRANSFER_TICKET]: {
		title: 'Transfer Tickets',
		iconPath: './assets/icons/transfer',
		routeTo: (id: number) => InternalURLCreator.transferTickets(id),
		externalRouteTo: (id: number) => URLCreator.transferTickets(id),
	},
	[ManageBookingCards.REQUEST_REFUND]: {
		title: 'Request A Refund',
		iconPath: './assets/icons/refund',
		routeTo: (id: number) => InternalURLCreator.refundTickets(id),
		externalRouteTo: (id: number) => URLCreator.refundTickets(id),
	},
	[ManageBookingCards.TICKET_RESALE]: {
		title: 'List Ticket For Resale',
		iconPath: './assets/icons/money',
		routeTo: (id: number) => InternalURLCreator.resaleTickets(id),
		externalRouteTo: (id: number) => URLCreator.resaleTickets(id),
	},
	[ManageBookingCards.TRANSFER_BACK]: {
		title: 'Transfer Ticket Back',
		iconPath: './assets/icons/transfer-back',
	},
};

export const formErrorMessage = {
	required: 'This field is required.',
	invalidURL: 'A field requires a valid URL.',
	invalidNumber: 'This field requires a valid number.',
	email: 'This field requires a valid email.',
	pattern: 'Incorrect pattern match.',
	invalidPhone: 'This field requires a valid phone number.',
	invalidDocument: 'Invalid document type. Allowed types: PDF, DOC, DOCX, .XLS,.XLSX',
	invalidImage: 'Invalid image type. Allowed types: JPG, JPEG, PNG',
	invalidFileType: 'Invalid file type. Only images and documents are allowed.',
	notUnique: 'This field needs to be unique. Another of your tickets has this value.',
	notEventUnique: 'The organiser requires this answer to be unique across the event.',
};

export const FileTypes = {
	document: [
		'application/pdf',
		'application/msword',
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	],
	image: ['image/jpeg', 'image/png', 'image/gif', 'jpg', 'jpeg', 'png', 'gif'],
	ticketImage: ['image/jpeg', 'image/png', 'jpg', 'jpeg', 'png'],
};

export const QuestionFileTypes = {
	document: [
		'application/pdf',
		'application/msword',
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		'application/vnd.ms-excel',
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	],
	image: ['image/jpeg', 'image/png', 'jpg', 'jpeg', 'png'],
};

export const BANK_ACCOUNT_MODAL: { [key: string]: BankAccountDescriptions } = {
	refund: {
		addBankDescription: 'This is the bank account the refund will be processed to.',
		linkExistingDescription: 'Please select your account below that you wish for the refund to be processed to.',
	},
	resale: {
		addBankDescription: 'This is the bank account your resale will be processed to.',
		linkExistingDescription: 'Please select your account below that you wish for the resale to be processed to.',
	},
	referrals: {
		addBankDescription: 'Please enter your bank details below.',
		linkExistingDescription: 'Please select your account below.',
	},
	standard: {
		addBankDescription: 'Please enter your bank details below.',
		linkExistingDescription: 'Please select your account below.',
	},
};

export const MY_COMMISSION_CARDS: { [key: string]: SectionRouteCard } = {
	PromoterNetworks: {
		title: 'Promoter Networks',
		iconPath: './assets/icons/promoter',
		description: 'Promote events for organisers.',
		routeTo: () => InternalURLCreator.promoterNetworksList(),
	},
	Referrals: {
		title: 'Referrals',
		iconPath: './assets/icons/referrals',
		description: 'Sign event organisers up to quicket',
		routeTo: () => InternalURLCreator.referrals(),
	},
};

export const EARN_COMMISSION_HEADER: { [key: string]: HeaderContent } = {
	earnCommission: {
		breadCrumbs: [
			{
				routeName: 'My Account',
				routeTo: () => InternalURLCreator.myAccount(),
			},
			{
				routeName: 'Earn Commission',
			},
		],
		title: 'Earn Commission',
		description: 'Explore the way you can earn a commission through Quicket.',
	},
	promoterNetworks: {
		breadCrumbs: [
			{
				routeName: 'My Account',
				routeTo: () => InternalURLCreator.myAccount(),
			},
			{
				routeName: 'Earn Commissions',
				routeTo: () => InternalURLCreator.earnCommission(),
			},
			{
				routeName: 'Promoter Networks',
			},
		],
		title: 'Promoter Networks',
		description:
			`Promoter networks are created by event organisers to help spread the word about their events.
			View the ones you are a part of below.`,
	},
	referrals: {
		breadCrumbs: [
			{
				routeName: 'My Account',
				routeTo: () => InternalURLCreator.myAccount(),
			},
			{
				routeName: 'Earn Commissions',
				routeTo: () => InternalURLCreator.earnCommission(),
			},
			{
				routeName: 'Referrals',
			},
		],
		title: 'Referral Program',
	},
	promoterDetails: {
		breadCrumbs: [
			{
				routeName: 'Earn Commissions',
				routeTo: () => InternalURLCreator.earnCommission(),
			},
			{
				routeName: 'Promoter Network',
				routeTo: () => InternalURLCreator.promoterNetworksList(),
			},
			{
				routeName: 'Promoter Network Details',
			},
		],
		title: 'Promoter Network Details',
	},
};

export const LINK_COMMISSION_INFO: string[] = [
	'Put the link out there - put it on your website, blog, social media, and email it to your contacts.',
	'If somebody clicks on the link and buys tickets for this event, you will earn a commission.',
	'You can see how much commission you have earned on this page, and set a bank account where you want to be paid.',
	'Once the event is finished successfully, you will receive your commission paid out to the nominated bank account.',
];

export enum OrganiserHubRoutes {
	DASHBOARD = 'organiser-hub',
	EVENTS = 'events',
	PROFILE = 'profiles',
}

export const ORGANISER_NAV: { [key in OrganiserHubRoutes]: SectionRouteCard } = {
	[OrganiserHubRoutes.DASHBOARD]: {
		title: 'Dashboard',
		routeTo: () => InternalURLCreator.orgDashboard(),
		iconPath: './assets/icons/dashboard',
	},
	[OrganiserHubRoutes.EVENTS]: {
		title: 'My Events',
		routeTo: () => InternalURLCreator.orgEvents(),
		iconPath: './assets/icons/my-events',
	},
	[OrganiserHubRoutes.PROFILE]: {
		title: 'Org Profile',
		routeTo: () => InternalURLCreator.orgProfile(),
		iconPath: './assets/icons/org-profile',
	},
};

export enum OrganiserHubSubRoutes {
	DETAILS = 'details',
	MAILCHIMP = 'mailchimp',
	MAIL = 'mail-accounts',
	DIRECT_DISTRIBUTION = 'direct-distribution',
	TRACKING_SCRIPTS = 'tracking-scripts'
}

export const ORGANISER_SUB_PAGE_NAV: { [key in OrganiserHubSubRoutes]: SectionRouteCard } = {
	[OrganiserHubSubRoutes.DETAILS]: {
		title: 'Details',
		routeTo: () => InternalURLCreator.orgProfile(),
	},
	[OrganiserHubSubRoutes.MAILCHIMP]: {
		title: 'MailChimp',
		routeTo: () => InternalURLCreator.orgProfile(),
	},
	[OrganiserHubSubRoutes.MAIL]: {
		title: 'Mail Accounts',
		routeTo: () => InternalURLCreator.orgProfile(),
	},
	[OrganiserHubSubRoutes.DIRECT_DISTRIBUTION]: {
		title: 'Direct Distribution',
		routeTo: () => InternalURLCreator.orgProfile(),
	},
	[OrganiserHubSubRoutes.TRACKING_SCRIPTS]: {
		title: 'Tracking Scripts',
		routeTo: () => InternalURLCreator.orgProfile(),
	},
};

export const EVENT_ID_PARAM_KEY = 'id';
export const REGISTRATION_ID_KEY = 'regId';
export const PROMOTER_NETWORK_ID_KEY = 'networkId';
export const MANUAL_REFUND_ID_KEY = 'manualRefundId';
export const ENCRYPT_KEY = 'e';

export enum EventSection {
	DASHBOARD = 'Dashboard',
	EVENT = 'Manage Event',
	TICKETS = 'Manage Checkout',
	PAYMENT = 'Payments',
	MARKETING = 'Marketing',
	SETTINGS = 'Settings',
	GUESTS = 'Guest Management',
	ONTHEDAY = 'On The Day',
	REPORTS = 'Reports',
}

export enum DisplayTabCondition {
	ONGOING = 'ongoing',
}

export const EVENT_NAV: { [key in EventSection]: EventSideNavItems } = {
	[EventSection.DASHBOARD]: {
		routeTo: (id: number) => InternalURLCreator.eventDashboard(id),
		children: [
			'dashboard',
			'send-invites',
		],
		primaryPages: ['dashboard'],
		iconPath: './assets/icons/dashboard',
	},
	[EventSection.EVENT]: {
		routeTo: (id: number) => InternalURLCreator.manageEvent(id),
		children: [
			'manage-event',
			'details',
			'provide-a-link',
			'quicket-hosted-streaming',
			'upload-video-file',
			'tabbed-content',
			'add-tab',
			'design',
			'user-access',
			'add-user',
			'profile',
			'copy',
			'cancel',
			'postpone',
		],
		primaryPages: ['manage-event'],
		iconPath: './assets/icons/my-events',
	},
	[EventSection.TICKETS]: {
		routeTo: (id: number) => InternalURLCreator.tickets(id),
		children: [
			'manage-checkout',
			'registrations',
			'tickets',
			'ticket-groups',
			'add-seating-chart',
			'merchandise',
			'add-merchandise',
			'checkout-questions',
			'add-question',
			'user-registration-details',
		],
		primaryPages: ['manage-checkout'],
		iconPath: './assets/icons/manage-tickets',
	},
	[EventSection.PAYMENT]: {
		routeTo: (id: number) => InternalURLCreator.paymentOptions(id),
		children: [
			'payment-options',
			'payouts',
		],
		primaryPages: [
			'payment-options',
			'payouts',
		],
		iconPath: './assets/icons/sales',
		primaryPageTabs: true,
	},
	[EventSection.MARKETING]: {
		routeTo: (id: number) => InternalURLCreator.marketing(id),
		children: [
			'marketing',
			'promoters',
			'promoter-networks',
			'promoter-network',
			'promotional-codes',
			'add-new-code',
			'link-campaigns',
			'send-invites',
		],
		primaryPages: ['marketing'],
		iconPath: './assets/icons/marketing',
	},
	[EventSection.SETTINGS]: {
		routeTo: (id: number) => InternalURLCreator.settings(id),
		children: [
			'settings',
			'details-settings',
			'ticket-settings',
			'checkout-settings',
			'email-settings',
			'integrations',
			'mailchimp',
			'definitions',
			'widget',
			'webhooks',
		],
		primaryPages: ['settings'],
		iconPath: './assets/icons/event-settings',
	},
	[EventSection.GUESTS]: {
		routeTo: (id: number) => InternalURLCreator.guestManagement(id),
		children: [
			'guest-management',
			'orders',
			'order-details',
			'guest-list',
			'send-complimentary',
			'send-RSVP',
			'printed-tickets',
			'add-printed-tickets',
			'send-SMS',
			'transfer-schedules',
		],
		primaryPages: ['guest-management'],
		iconPath: './assets/icons/guest-management',
	},
	[EventSection.ONTHEDAY]: {
		routeTo: (id: number) => InternalURLCreator.scanners(id),
		children: [
			'scanners',
			'request-field-services',
			'at-the-door',
		],
		primaryPages: [
			'scanners',
			'request-field-services',
			'at-the-door',
		],
		iconPath: './assets/icons/on-the-day',
		primaryPageTabs: true,
	},
	[EventSection.REPORTS]: {
		routeTo: (id: number) => InternalURLCreator.reports(id),
		children: [
			'reports',
			'visitors-report',
			'sales-report',
			'check-in-report',
			'reviews-report',
			'export-data',
		],
		primaryPages: ['reports'],
		iconPath: './assets/icons/reports',
	},

};

export const MANAGE_EVENT_CARDS: { [key: string]: SectionRouteCard } = {
	eventDetails: {
		title: 'Event Details',
		iconPath: './assets/icons/event-details',
		description: 'Provide and update key information for your event.',
		routeTo: (id: number) => InternalURLCreator.eventDetails(id),
	},
	tabbedContent: {
		title: 'Tabbed Content',
		iconPath: './assets/icons/tabbed-content',
		description: 'Organise event info into easy-to-navigate tabs.',
		routeTo: (id: number) => InternalURLCreator.tabbedContent(id),
	},
	design: {
		title: 'Design',
		iconPath: './assets/icons/design',
		description: 'Customise the visual design of your event page.',
		routeTo: (id: number) => InternalURLCreator.design(id),
	},
	userAccess: {
		title: 'User Access',
		iconPath: './assets/icons/user-access',
		description: 'Add and manage user permissions and access.',
		routeTo: (id: number) => InternalURLCreator.eventUserAccess(id),
	},
	postponeEvent: {
		title: 'Postpone Event',
		iconPath: './assets/icons/postpone-event',
		description: 'Easily reschedule your event to a later date.',
		routeTo: (id: number) => InternalURLCreator.postpone(id),
	},
	cancelEvent: {
		title: 'Cancel Event',
		iconPath: './assets/icons/cancel-event',
		description: 'Cancel your event and notify all ticket buyers.',
		routeTo: (id: number) => InternalURLCreator.cancel(id),
	},
	copyEvent: {
		title: 'Copy Event',
		iconPath: './assets/icons/event-summary',
		description: 'Duplicate details to quickly create a similar event.',
		routeTo: (id: number) => InternalURLCreator.copyEvent(id),
	},
	organiserProfile: {
		title: 'Organiser Profile',
		iconPath: './assets/icons/user',
		description: 'Manage and update your organiser profile.',
		routeTo: (id: number) => InternalURLCreator.organiserProfile(id),
	},
};

export const MANAGE_TICKETS_CARDS: { [key: string]: SectionRouteCard } = {
	ticketTypes: {
		title: 'Ticket Types',
		iconPath: './assets/icons/ticket',
		description: 'Create and customise different ticket categories.',
		routeTo: (id: number) => InternalURLCreator.ticketTypes(id),
	},
	ticketGroups: {
		title: 'Ticket Groups',
		iconPath: './assets/icons/user-access',
		description: 'Organise tickets into groups, used during checkout.',
		routeTo: (id: number) => InternalURLCreator.ticketGroups(id),
	},
	registrations: {
		title: 'Registrations',
		iconPath: './assets/icons/registrations',
		description: 'Set up and manage attendee registrations.',
		routeTo: (id: number) => InternalURLCreator.registrations(id),
	},
	collectInfo: {
		title: 'Checkout Questions',
		iconPath: './assets/icons/collect-info',
		description: 'Gather essential information from attendees.',
		routeTo: (id: number) => InternalURLCreator.checkoutQuestions(id),
	},
	merchandise: {
		title: 'Merchandise',
		iconPath: './assets/icons/clothes',
		description: 'Offer merchandise alongside ticket sales.',
		routeTo: (id: number) => InternalURLCreator.eventMerchandise(id),
	},
};

export enum ReportType {
	TaxInvoice = 'TaxInvoice',
	EventSummary = 'EventSummary',
}

export const EVENT_REPORT_CARDS: { [key: string]: SectionRouteCard } = {
	visitorsReport: {
		title: 'Visitors Report',
		iconPath: './assets/icons/visitor-report',
		description: 'Analyse visitor demographics and behavior.',
		routeTo: (id: number) => InternalURLCreator.eventVisitorsReport(id),
		externalRouteTo: (id: number) => URLCreator.eventVisitorsReport(id),
	},
	salesReport: {
		title: 'Sales Report',
		iconPath: './assets/icons/sales-report',
		description: 'Review detailed sales and revenue statistics.',
		routeTo: (id: number) => InternalURLCreator.eventSalesReport(id),
		externalRouteTo: (id: number) => URLCreator.eventSalesReport(id),
	},
	checkInReport: {
		title: 'Check-in Report',
		iconPath: './assets/icons/check-in-report',
		description: 'Monitor attendee check-ins and trends.',
		routeTo: (id: number) => InternalURLCreator.eventCheckInReport(id),
		externalRouteTo: (id: number) => URLCreator.eventCheckInReport(id),
	},
	reviewsReport: {
		title: 'Reviews Report',
		iconPath: './assets/icons/reviews-report',
		description: 'Gather and evaluate attendee feedback.',
		routeTo: (id: number) => InternalURLCreator.eventReviewsReport(id),
		externalRouteTo: (id: number) => URLCreator.eventReviewsReport(id),
	},
	viewEventSummary: {
		title: 'View Event Summary',
		iconPath: './assets/icons/event-summary',
		description: 'Get a concise overview with an event statement.',
		routeTo: (id: number) => InternalURLCreator.reports(id),
		type: ReportType.EventSummary,
	},
	viewTaxInvoice: {
		title: 'View Tax Invoice',
		iconPath: './assets/icons/tax-invoice',
		description: 'Access  your provisional tax invoice',
		routeTo: (id: number) => InternalURLCreator.reports(id),
		type: ReportType.TaxInvoice,
	},
	exportEventData: {
		title: 'Export Event Data',
		iconPath: './assets/icons/event-data',
		description: 'Export all event-related data for further analysis.',
		routeTo: (id: number) => InternalURLCreator.eventExportData(id),
		externalRouteTo: (id: number) => URLCreator.eventExportData(id),
	},
};

export const EVENT_MARKETING_CARDS: { [key: string]: SectionRouteCard } = {
	promoters: {
		title: 'Promoters',
		iconPath: './assets/icons/user-access',
		description: 'Add incentives for users to boost attendance.',
		routeTo: (id: number) => InternalURLCreator.eventPromoters(id),
	},
	promoterNetworks: {
		title: 'Promoter Networks',
		iconPath: './assets/icons/fork',
		description: 'Use networks to expand event reach.',
		routeTo: (id: number) => InternalURLCreator.eventPromoterNetworks(id),
	},
	promotionalCodes: {
		title: 'Promotional Codes',
		iconPath: './assets/icons/promotional-codes',
		description: 'Create and distribute promo codes.',
		routeTo: (id: number) => InternalURLCreator.promotionalCodes(id),
	},
	campaign: {
		title: 'Campaigns',
		iconPath: './assets/icons/promoter',
		description: 'Create and track event marketing campaigns.',
		routeTo: (id: number) => InternalURLCreator.campaigns(id),
	},
	sendInvites: {
		title: 'Send Invites',
		iconPath: './assets/icons/email',
		description: 'Invite your contacts and past attendees to your event.',
		routeTo: (id: number) => InternalURLCreator.sendInvites(id),
	},
};

export const EVENT_SETTINGS_CARDS: { [key: string]: SectionRouteCard } = {
	ticketing: {
		title: 'Ticketing',
		iconPath: './assets/icons/settings',
		description: 'Configure ticketing options and preferences.',
		routeTo: (id: number) => InternalURLCreator.eventSettingsTicketing(id),
	},
	event: {
		title: 'Event',
		iconPath: './assets/icons/event-summary',
		description: 'Set up and manage event-specific settings.',
		routeTo: (id: number) => InternalURLCreator.settingsEvent(id),
	},
	integrations: {
		title: 'Integrations',
		iconPath: './assets/icons/visitor-report',
		description: 'Connect and manage third-party integrations.',
		routeTo: (id: number) => InternalURLCreator.integrations(id),
	},
	definitions: {
		title: 'Definitions',
		iconPath: './assets/icons/edit',
		description: 'Define and customise key event terms.',
		routeTo: (id: number) => InternalURLCreator.definitions(id),
	},
};

export const EVENT_GUEST_MANAGEMENT_CARDS: { [key: string]: SectionRouteCard } = {
	orders: {
		title: 'Orders',
		iconPath: './assets/icons/orders',
		description: 'Track and manage guest ticket orders.',
		routeTo: (id: number) => InternalURLCreator.orders(id),
	},
	guestList: {
		title: 'Guest List',
		iconPath: './assets/icons/guests',
		description: 'View and organise your event guest list.',
		routeTo: (id: number) => InternalURLCreator.guestList(id),
	},
	printedTickets: {
		title: 'Printed Tickets',
		iconPath: './assets/icons/ticket',
		description: 'Manage and distribute pre printed tickets.',
		routeTo: (id: number) => InternalURLCreator.printedTickets(id),
	},
	transferSchedule: {
		title: 'Transfer Schedules',
		iconPath: './assets/icons/transfer-back',
		description: 'Arrange and manage ticket transfers.',
		routeTo: (id: number) => InternalURLCreator.eventScheduleTransfer(id),
	},
	sendComplimentary: {
		title: 'Send Complimentary',
		iconPath: './assets/icons/complimentary',
		description: 'Distribute complimentary tickets to guests.',
		routeTo: (id: number) => InternalURLCreator.sendComplimentaryTickets(id),
	},
	sendRSVP: {
		title: 'Send RSVP',
		iconPath: './assets/icons/rsvp',
		description: 'Send RSVP requests to potential attendees.',
		routeTo: (id: number) => InternalURLCreator.sendRSVPs(id),
	},
	sendSMS: {
		title: 'Send SMS',
		iconPath: './assets/icons/smart-phone',
		description: 'Communicate with guests via SMS.',
		routeTo: (id: number) => InternalURLCreator.sendSMSs(id),
	},
};

export enum IntegrationSection {
	ZAPIER = 'zapier',
	MAILCHIMP = 'mailchimp',
	WIDGET = 'widget',
	WEBHOOKS = 'webhooks',
}

export const EVENT_INTEGRATION_CARDS: { [key in IntegrationSection]: SectionRouteCard } = {
	[IntegrationSection.ZAPIER]: {
		title: 'Zapier',
		iconPath: './assets/icons/zapier',
		description: 'Automate workflows by connecting your event.',
		routeTo: (id: number) => URLCreator.zapier(id),
	},
	[IntegrationSection.MAILCHIMP]: {
		title: 'Mailchimp',
		iconPath: './assets/icons/mail-chimp',
		description: 'Manage your email marketing campaigns efficiently.',
		routeTo: (id: number) => InternalURLCreator.eventMailChimp(id),
		externalRouteTo: (id: number) => URLCreator.eventMailChimp(id),
	},
	[IntegrationSection.WIDGET]: {
		title: 'Widget',
		iconPath: './assets/icons/quicket-logo',
		description: 'Sell directly on your own website.',
		routeTo: (id: number) => InternalURLCreator.eventWidget(id),
		externalRouteTo: (id: number) => URLCreator.eventWidget(id),
	},
	[IntegrationSection.WEBHOOKS]: {
		title: 'Webhooks',
		iconPath: './assets/icons/webhooks',
		description: 'Send webhooks on purchase events',
		routeTo: (id: number) => InternalURLCreator.eventWebhooks(id),
		externalRouteTo: (id: number) => URLCreator.eventWebhooks(id),
	},
};

export enum SEND_MENU_OPTIONS {
	COMPLIMENTARY = 'Complimentary',
	RSVP = 'RSVPs',
	SMS = 'SMS',
}

export const SEND_OPTIONS: { [key in SEND_MENU_OPTIONS]: DropdownOption } = {
	[SEND_MENU_OPTIONS.COMPLIMENTARY]: {
		name: 'Complimentary',
		routeTo: (id: number) => InternalURLCreator.sendComplimentaryTickets(id),
	},
	[SEND_MENU_OPTIONS.RSVP]: {
		name: 'RSVPs',
		routeTo: (id: number) => InternalURLCreator.sendRSVPs(id),
	},
	[SEND_MENU_OPTIONS.SMS]: {
		name: 'SMS',
		routeTo: (id: number) => InternalURLCreator.sendSMSs(id),
	},
};

export const TICKET_OPTIONS: { [key in TicketType]: DropdownOption } = {
	[TicketType.paid]: {
		name: 'Paid Ticket',
		value: TicketType.paid,
	},
	[TicketType.free]: {
		name: 'Free Ticket',
		value: TicketType.free,
	},
	[TicketType.donation]: {
		name: 'Donation',
		value: TicketType.donation,
	},
};

export enum ImageUploadErrorCode {
	Size = 'PictureValidation_IncorrectImageSize',
}

export const MY_FORMATS = {
	parse: {
		dateInput: 'DD/MM/YYYY',
	},
	display: {
		dateInput: 'DD/MM/YYYY',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'DD/MM/YYYY',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};

export const CHART_COLOURS = [
	'#5eb700', '#FF7C00', '#9683E3', '#7CB2E8', '#D80073',
	'#FFD500', '#00B2F0', '#FF6347', '#8A2BE2', '#00A86B',
	'#4CAF50', '#FF9800', '#9C27B0', '#03A9F4', '#E91E63',
	'#FFC107', '#00BCD4', '#FF5722', '#673AB7', '#009688',
	'#8BC34A', '#FFEB3B', '#3F51B5', '#CDDC39', '#FF4081',
	'#795548', '#607D8B', '#F44336', '#2196F3', '#9E9D24',
	'#00E676', '#FF6F00', '#651FFF', '#2979FF', '#C51162',
	'#FFD600', '#00B8D4', '#FF5252', '#7C4DFF', '#0097A7',
	'#64DD17', '#FF8F00', '#AA00FF', '#304FFE', '#D50000',
	'#FFEA00', '#00E5FF', '#DD2C00', '#6200EA', '#00C853',
	'#FFAB00', '#D500F9', '#2962FF', '#C2185B', '#FFC400',
	'#0091EA', '#D32F2F', '#512DA8', '#004D40', '#3E2723',
	'#32CD32', '#00FA9A', '#00FF7F', '#40E0D0', '#008080',
	'#00FFFF', '#ADFF2F', '#7FFF00', '#7CFC00', '#00FF00',
	'#FF0000', '#FF4500', '#FFA500', '#FFD700', '#00CED1',
	'#1E90FF', '#4169E1', '#0000FF', '#C71585', '#DB7093',
	'#DC143C', '#B22222', '#A52A2A', '#BDB76B', '#9ACD32',
	'#556B2F', '#006400', '#9400D3', '#9932CC', '#FF00FF',
	'#FF1493', '#FF69B4', '#D2691E', '#8B0000', '#FF7F50',
	'#FF8C00', '#FFA07A', '#800080', '#DAA520', '#8B4513',
	'#6B8E23', '#4682B4', '#2E8B57', '#CD5C5C', '#6495ED',
	'#BA55D3', '#FFDAB9', '#98FB98', '#8FBC8F', '#FFB6C1',
	'#CF9186', '#66CDAA', '#20B2AA', '#87CEFA', '#778899',
];

export enum OrderActionType {
	Resend = 'Resend',
	Edit = 'Edit',
	Download = 'Download',
	ChangeType = 'ChangeType',
	Cancel = 'Cancel',
	MarkAsPaid = 'MarkAsPaid',
}

export const ORDER_OPTIONS: { [key in OrderActionType]: DropdownOption } = {
	[OrderActionType.Resend]: {
		name: 'Resend tickets',
		value: OrderActionType.Resend,
	},
	[OrderActionType.Edit]: {
		name: 'Edit attendee info',
		value: OrderActionType.Edit,
	},
	[OrderActionType.Download]: {
		name: 'Download tickets',
		value: OrderActionType.Download,
	},
	[OrderActionType.ChangeType]: {
		name: 'Change ticket type',
		value: OrderActionType.ChangeType,
	},
	[OrderActionType.Cancel]: {
		name: 'Cancel ticket',
		value: OrderActionType.Cancel,
	},
	[OrderActionType.MarkAsPaid]: {
		name: 'Mark order as paid',
		value: OrderActionType.MarkAsPaid,
	},
};

export const manageBookingCardTooltipTexts = {
	'Edit Ticket Details': 'Cannot edit tickets for this event.',
	'View Invoice': 'Viewing your invoice is not allowed',
	'Edit Invoice': 'Editing your invoice is not allowed',
	'Transfer Tickets': 'Ticket transfers are not allowed',
	'Request A Refund': 'Refunds are not allowed',
	'List Ticket For Resale': 'Ticket resales are not allowed',
	'Transfer Ticket Back' : 'Transferring your ticket back is not allowed',
	'Change Date': 'Changing dates is not allowed',
	'Send Message': 'Contacting the organiser is not allowed',
	'Visitor Help Center': 'This is currently disabled',
};
