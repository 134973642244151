import { SideNavItems, Socials } from '@app/models/shared';
import { QUICKET_LOGO, SIDENAV_ITEMS, SOCIALS } from './../../utils/consts';
import { Component, Input, OnInit } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { handleOrder } from '@app/utils/common-helpers';
import { InternalURLCreator } from '@app/services/url/url.dictionary';
import { StoreService } from '@app/services/store/store.service';
import { Go } from '@app/store/actions/router/router.actions';
import { Observable, filter, startWith } from 'rxjs';
import { user as userSelector } from '@app/store/selectors/user.selector';

@Component({
	selector: 'app-mobile-sidenav',
	templateUrl: './mobile-sidenav.component.html',
	styleUrls: ['./mobile-sidenav.component.sass'],
})
export class MobileSidenavComponent implements OnInit {
	constructor(private store: StoreService) { }

	user$: Observable<{ info: { userName: string } }>;
	navOptions: Dictionary<SideNavItems> = SIDENAV_ITEMS;
	socials: Dictionary<Socials> = SOCIALS;
	QUICKET_LOGO = QUICKET_LOGO;
	handleDefaultOrder = handleOrder;
	@Input() currentRoute: string;

	ngOnInit() {
		this.user$ = this.store.select(userSelector()).pipe(
			startWith({
				info: { userName: null },
			}),
			filter((user) => !!user.info)
		);
	}

	handleNavigation(navItem: SideNavItems) {
		window.location.href = navItem.routeTo;
	}

	handleCreateEventRoute(): void {
		this.store.dispatch(new Go({ path: [InternalURLCreator.createEvent()] }));
	}
}
