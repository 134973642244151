<nav
	mat-tab-nav-bar
	[tabPanel]="tabPanel"
	class='l-web-content'
>
	<a
		mat-tab-link
		*ngFor="let tabLink of tabLinks"
		[id]="'tabbed-route-'+tabLink.label"
		[routerLink]="tabLink.path"
		routerLinkActive
		#rla="routerLinkActive"
		[active]="rla.isActive"
	>
		{{ tabLink.label }}
	</a>
</nav>
<mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>