import { Component, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationType } from '@app/models/notification.model';
import { BankAccount, BankAccountReturn } from '@app/models/user.model';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { StoreService } from '@app/services/store/store.service';
import { URLCreator } from '@app/services/url/url.dictionary';
import { AddNotification } from '@app/store/actions/notification/notification.actions';
import { GetBankAccounts } from '@app/store/actions/user/user.actions';
import { UserActionsConstants } from '@app/store/actions/user/user.actions.constants';
import { Actions, ofType } from '@ngrx/effects';
import { Observable, map, takeUntil } from 'rxjs';
import { Clipboard } from '@angular/cdk/clipboard';
import { REDIRECT_PARAM_KEY } from '@app/utils/consts';

enum SuccessMessages {
	LINK_UPDATED = 'Successfully updated link!',
	COPIED = 'Link successfully copied to clipboard!',
	BANK_UPDATED = 'Successfully updated you bank account!',
}

@Component({ template: '' })
export class EarnCommissionBaseComponent implements OnInit {
	isMobile = false;
	isLoading = true;
	isUpdating = false;
	isPageLoading = false;
	isBanksDataFetching = false;
	bankAccounts$: Observable<BankAccount[]>;

	destroyed$: EventEmitter<void> = new EventEmitter();

	constructor(
		protected store: StoreService,
		protected breakpointService: BreakpointService,
		protected actions$: Actions,
		protected clipboard: Clipboard,
		protected router: Router
	) { }

	ngOnInit(): void {
		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});

		this.bankAccounts$ = this.actions$
			.pipe(
				ofType(UserActionsConstants.GET_BANK_ACCOUNTS_SUCCESS, UserActionsConstants.GET_BANK_ACCOUNTS_FAILED),
				takeUntil(this.destroyed$), map((
					{ type, payload: { bankAccounts, needsPasswordVerification, passwordVerificationRedirect } }: {
						type: string; payload: BankAccountReturn;
					}) => {
					if (type === UserActionsConstants.GET_BANK_ACCOUNTS_SUCCESS) {
						if (needsPasswordVerification) {
							const currentUrl = '/app/%23' + this.router.url;
							window.location.href = `${passwordVerificationRedirect}&${REDIRECT_PARAM_KEY}=${currentUrl}`;
							return [];
						} else {
							this.isPageLoading = false;
							return bankAccounts;
						}
					} else {
						this.isPageLoading = false;
						return [];
					}
				})
			);
		this.store.dispatch(new GetBankAccounts());
	}

	handleNotification(isSuccess: boolean, isBankAccount: boolean, errorMessage: string): void {
		const notificationType = isSuccess ? NotificationType.SUCCESS : NotificationType.ERROR;
		let message: string;
		if (isSuccess) {
			message = isBankAccount ? SuccessMessages.BANK_UPDATED : SuccessMessages.LINK_UPDATED;
		} else {
			message = errorMessage;
		}

		this.store.dispatch(new AddNotification({
			id: 'referral-Update',
			actionType: null,
			action: null,
			type: notificationType,
			title: message,
			delay: 4000,
		}));
	}

	handleCopyLink(url: string, link: string): void {
		const compiledText = url + link;
		this.clipboard.copy(compiledText);
		this.store.dispatch(new AddNotification({
			id: 'link copied',
			actionType: null,
			action: null,
			type: NotificationType.SUCCESS,
			title: SuccessMessages.COPIED,
			delay: 4000,
		}));
	}

	handleTermsNavigation(): void {
		window.open(URLCreator.terms());
	}

	formatAccountNumber(accountNumber: string): string {
		return '**** ' + accountNumber.slice(-4);
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
