<div [ngClass]="{'l-container-mobile': isMobile, 'l-container-web': !isMobile}">
	<div class="l-header-container">
		<div class="l-description">
			<h3 class='mb-3'>Welcome To Your Organiser Dashboard</h3>
			<p class='l-text'>
				Below is an overview summarising data across your events hosted on Quicket.
				<span *ngIf="currencies?.length > 1">
					Currently displaying events using the 
					<span class="l-currencyName">{{selectedCurrencyName}}</span>
					{{' (' + selectedCurrencySymbol + ')'}}.
					Change your currency 
					<button
						(click)="handleCurrencyDialog()"
						class="l-change-currency-button"
					>
						here.
					</button>
				</span>
			</p>
			<div>
			</div>
		</div>
		<button id='email-report-button-web' *ngIf='!isMobile' class='g-greyed-button l-report'
			(click)="handleEmailReport()"><mat-icon class='l-icon'>save_alt</mat-icon>EMAIL REPORT</button>
	</div>
	<div class="l-content">
		<app-dashboard-chart class="l-chart-container l-primary-content" [ngClass]="{'l-mobile-padding': isMobile}"
			*ngIf='!isSalesOverviewLoading; else totalSalesLoading' [chartData]='salesData' [totalValue]='totalSales'
			[showLegend]='true' [isMobile]='isMobile' [isSales]='true' [showTotal]="false" [currencySymbol]="selectedCurrencySymbol"></app-dashboard-chart>
		<ng-template #totalSalesLoading>
			<div class='l-chart-container l-primary-content'>
				<div class="l-loading">
					<mat-spinner></mat-spinner>
				</div>
			</div>
		</ng-template>
		<app-dashboard-chart class="l-chart-container l-secondary-content" [ngClass]="{'l-mobile-padding': isMobile}"
			*ngIf='!isSalesOverviewLoading; else totalTicketsLoading' [chartData]='ticketsData' [totalValue]='totalTickets'
			[isMobile]='isMobile' [isSales]='false'></app-dashboard-chart>
		<ng-template #totalTicketsLoading>
			<div class='l-chart-container l-secondary-content'>
				<div class="l-loading">
					<mat-spinner></mat-spinner>
				</div>
			</div>
		</ng-template>
	</div>
	<div class="l-content mt-4">
		<div class="l-primary-content l-events-content">
			<div>
				<h3 class='l-title'>Upcoming Events</h3>
				<div *ngIf='!isDashboardEventsLoading; else upcomingEventsLoading'>
					<div *ngIf='upcomingEvents.length; else noUpcomingEvents' class="l-events-container">
						<a *ngFor='let upcomingEvent of upcomingEvents' class="g-flat-link" 
							[routerLink]="[handleRouterLink(upcomingEvent.id)]">
							<app-event-card [isMobile]='isMobile' [data]='upcomingEvent'></app-event-card>
						</a>
					</div>
					<ng-template #noUpcomingEvents>
						<app-no-products-card [title]="'You don\'t have any upcoming events'"
							[content]="'Want to change that?'" [buttonText]="'CREATE EVENT'"
							(buttonClick)='handleCreateEventNavigation()'></app-no-products-card>
					</ng-template>
				</div>
				<ng-template #upcomingEventsLoading>
					<app-skeleton-loader [repeat]='3'></app-skeleton-loader>
				</ng-template>
			</div>
			<div>
				<h3 class='l-title'>Past Events</h3>
				<div *ngIf='!isDashboardEventsLoading; else pastEventsLoading'>
					<div *ngIf='pastEvents.length; else noPastEvents' class="l-events-container">
						<a *ngFor='let pastEvent of pastEvents' class="g-flat-link" 
							[routerLink]="[handleRouterLink(pastEvent.id)]">
							<app-event-card [isMobile]='isMobile' [data]='pastEvent'></app-event-card>
						</a>
					</div>
					<ng-template #noPastEvents>
						<app-no-products-card [title]="'You don\'t have any past events...yet'"
							[content]="''"></app-no-products-card>
					</ng-template>
				</div>
				<ng-template #pastEventsLoading>
					<app-skeleton-loader [repeat]='3'></app-skeleton-loader>
				</ng-template>
			</div>
		</div>
		<div class="l-secondary-content">
			<h3 class='l-title'>Current Link Campaigns</h3>
			<div *ngIf='!isCurrentCampaignsLoading; else currentCampaignsLoading'>
				<div *ngIf='currentCampaigns.length; else noCampaigns' class="l-campaign-container" [ngClass]="{'mobile': isMobile}">
					<div *ngFor='let campaign of currentCampaigns'>
						<app-campaign-card [isMobile]='isMobile' [data]='campaign'></app-campaign-card>
					</div>
				</div>
				<ng-template #noCampaigns>
					<div class="l-empty-card">
						<h3 class='l-title'>You don't have any active campaigns...</h3>
					</div>
				</ng-template>
			</div>
			<ng-template #currentCampaignsLoading>
				<app-skeleton-loader [repeat]='3' [height]="'120px'"></app-skeleton-loader>
			</ng-template>
		</div>
	</div>
	<div class="l-mobile-button-container" *ngIf='isMobile'>
		<button id='email-report-button-web' class='g-greyed-button l-mobile-report'
			(click)="handleEmailReport()"><mat-icon class='l-icon'>save_alt</mat-icon>EMAIL REPORT</button>
	</div>
</div>
