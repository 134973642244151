import { EventOnlineStreaming, EventOnlineStreamingRooms } from '@app/models/event.model';
import { EventOnlineStreamingSettings } from '@app/models/event.model';
import { NotificationDictionary } from '@app/services/notification/notification.dictionary';
import { StreamingActionConstants } from './event-streaming.actions';


export type DraftEventOnlineStreaming = EventOnlineStreaming;
export type DraftEventOnlineStreamingSettings = EventOnlineStreamingSettings;
export type DraftEventOnlineStreamingRooms = EventOnlineStreamingRooms;

export class DraftEventOnlineStreamingSettingsForm implements DraftEventOnlineStreamingSettings {
	timeBeforeType = 'Now';
	timeBefore = null;
	notifyAttendees = false;
	showCollectionsOnStream = false;
	currencyCode = null;
	includeChat = false;
	livestreamUrl = null;
	showLivestreamCount = false;
	videoStreamingCost = null;
	videoStorageCost = null;
	videoEncodingCost = null;
	streamPageMessage = null;
	organiserCode = null;
	bannerOriginalImage = null;
	bannerImageString = null;
	bannerThumbnail = null;
	logoOriginalImage = null;
	logoImageString = null;
	logoThumbnail = null;
	maxCodeUses = 2;
}

export class DraftEventOnlineStreamingRoomsForm implements DraftEventOnlineStreamingRooms {
	// General
	id = null;
	name = '';
	enabled = true;
	type = null;
	description = '';
	ticketLinks = [];
	ticketTypes = [];
	isGated = true;
	imageString = null;
	streamThumbnailUrl = null;
	streamUrl = null;
	hideFrom = null;
	hideTo = null;
	// Provide a link
	url = '';
	embed = false;
	instructions = '';
	displayDirectLink = true;
	linkUnknown = false;
	// Quicket Hosted Streaming
	publishLogin = null;
	publishName = null;
	publishPassword = null;
	publishUrl = null;
	convertStreamToVideo = false;
	// Upload video file
	uploadId = null;
	thumbnailUrl = null;
	pricing = null;
}

export class EventStreamigNotificationDictionary extends NotificationDictionary {
	source = {
		[StreamingActionConstants.DELETE_EVENT_STREAMING_ROOM_SUCCESS]: () => ({
			title: 'Content succesfully deleted',
		}),
		[StreamingActionConstants.DELETE_EVENT_STREAMING_ROOM_FAILED]: () => ({
			title: 'Oops! We can\'t delete this content',
		}),
		[StreamingActionConstants.SET_EVENT_STREAMING_ENABLED_FAILED]: () => ({
			title: 'Oops! We can\'t update this setting',
		}),
		[StreamingActionConstants.SET_EVENT_STREAMING_SETTINGS_FAILED]: () => ({
			title: 'Oops! We can\'t update this setting',
		}),
		[StreamingActionConstants.SET_EVENT_STREAMING_SETTINGS_SUCCESS]: () => ({
			title: 'Global settings successfully updated',
		}),
		[StreamingActionConstants.REORDER_STREAMS_FAILED]: () => ({
			title: 'Oops! We can\'t reorder the content items',
		}),
		[StreamingActionConstants.UPDATE_EVENT_STREAMING_SUCCESS]: () => ({
			title: 'Details saved successfully',
		}),
		[StreamingActionConstants.UPDATE_EVENT_STREAMING_FAILED]: () => ({
			title: 'Oops! We can\'t save these details',
		}),
		[StreamingActionConstants.REQUEST_VOD_DOWNLOAD_LINK_SUCCESS]: () => ({
			title: 'Request successful. Check your email for download link',
		}),
		[StreamingActionConstants.REQUEST_VOD_DOWNLOAD_LINK_FAILED]: () => ({
			title: 'Oops! We\'re not able to send your download link',
		}),
	};
}
