<div [ngClass]="getFormFieldClasses(this.offset)">
	<label class="ui-label" *ngIf="label">
		{{label}}
		<span *ngIf="required" class="ui-required" >*</span>
		<i *ngIf="labelIcon" [class]="'fa fa-' + labelIcon"  [pTooltip]="tooltip" [tooltipPosition]="tooltip ? tooltipPosition : null"></i>
	</label>
	<p-autoComplete
		[formControl]="control"
		[suggestions]="filteredOptions"
		(completeMethod)="completeMethod($event)"
		(onSelect)="onSelect($event)"
		(onFocus)="onFocus($event)"
		[placeholder]="placeholder"
	>
		<ng-content></ng-content>
	</p-autoComplete>
</div>