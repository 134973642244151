import { HeaderContent } from '@app/models/shared';
import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { UserContactInfo } from '@app/models/user.model';
import { Genders, MY_ACCOUNT_HEADER } from '@app/utils/consts';
import { StoreService } from '@app/services/store/store.service';
import { userContactInfo as userSelector } from '@app/store/selectors/user.selector';
import { take, takeUntil } from 'rxjs/operators';
import { GetUserInfo, UpdateUserInfo } from '@app/store/actions/user/user.actions';
import { Actions, ofType } from '@ngrx/effects';
import { UserActionsConstants } from '@app/store/actions/user/user.actions.constants';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { NgForm } from '@angular/forms';
import { Action } from '@ngrx/store';
import { FormCanDeactivate } from '@app/services/guards/user.guard';
import { MatDialog } from '@angular/material/dialog';
import { Go } from '@app/store/actions/router/router.actions';
import { InternalURLCreator } from '@app/services/url/url.dictionary';
import { ConfirmationModalComponent } from '@app/shared/modals/confirmation-modal/confirmation-modal.component';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-personal-info',
	templateUrl: './personal-info.component.html',
	styleUrls: ['./personal-info.component.sass'],
})
export class PersonalInfoComponent extends FormCanDeactivate implements OnInit {
	isMobile = false;
	isDisabled = true;
	personalInformation: UserContactInfo;
	headerContent: HeaderContent = MY_ACCOUNT_HEADER['personalInfo'];
	genders: string[] = Genders;
	today: Date = new Date();
	dateOfBirth: Date = null;
	isLoading = false;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	@ViewChild('personalInformationForm') infoForm: NgForm;

	constructor(
		private breakpointService: BreakpointService,
		private store: StoreService,
		private actions$: Actions,
		private dialog: MatDialog
	) {
		super();
	}

	ngOnInit(): void {

		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});

		this.store.dispatch(
			new GetUserInfo()
		);


		this.store.select(userSelector()).pipe(takeUntil(this.destroyed$)).subscribe((user) => {
			if (user) {
				this.personalInformation = { ...user };
				if (this.personalInformation.dateOfBirth) {
					this.dateOfBirth = this.convertToDate(this.personalInformation.dateOfBirth);
				}
			}
		});
	}

	handleNavigationBack() {
		this.store.dispatch(new Go({ path: [InternalURLCreator.myAccount()] }));
	}

	isFormDeactivatable(): boolean | Observable<boolean> {
		return !this.infoForm.form || this.infoForm.form.pristine;
	}

	deactivationFallback(): Observable<boolean> {
		this.dialog
			.open(ConfirmationModalComponent, {
				data: {
					title: 'Unsaved Changes',
					text: 'Are you sure you want to leave this page? Any unsaved changes will be discarded.',
					buttonText: 'LEAVE',
					centerText: true,
					isMobile: this.isMobile,
				},
				panelClass: 'g-standard-dialog',
			})
			.afterClosed()
			.subscribe((result) => {
				this.closeDeactivationModal(result);
			});
		return this.modalCallback.asObservable();
	}

	closeDeactivationModal(isConfirmed: boolean): void {
		this.modalCallback.next(isConfirmed);
	}

	handlePrimaryButton() {
		this.isLoading = true;

		this.actions$
			.pipe(
				ofType(UserActionsConstants.UPDATE_USER_INFO_SUCCESS, UserActionsConstants.UPDATE_USER_INFO_FAILED),
				take(1)
			)
			.subscribe((action: Action) => {
				this.isLoading = false;
				if (action.type === UserActionsConstants.UPDATE_USER_INFO_SUCCESS) {
					this.infoForm.form.markAsPristine();
				}
			});

		this.store.dispatch(
			new UpdateUserInfo({
				userDetails: {
					...this.personalInformation,
					dateOfBirth: this.dateOfBirth ? this.dateOfBirth.toDateString() : null,
				},
			})
		);

	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}

	convertToDate(dateString: string) {
		const d = dateString.split('/');
		const date = new Date(d[2] + '/' + d[1] + '/' + d[0]);
		return date;
	}
}
