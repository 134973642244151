<div class="l-container">
	<div class="l-filters-container" [ngClass]="{ 'mobile': isMobile }">
		<div class="selector-container" [ngClass]="{'full-width': !scheduleItems}">
			<app-mat-select-control-field
				[options]="breakdownRangeFilterOptions"
				[control]="fromForm('breakdownRangeSelector')"
				[label]="'Breakdown Range'"
				[customId]="'breakdown-range-dropdown'"
			></app-mat-select-control-field>
		</div>

		<div
			class="selector-container"
			*ngIf="scheduleItems && scheduleItems.length != 0"
			id="schedule-selector-dropdown">
			<app-schedule-selector
				[scheduleItems]="scheduleItems"
				[isMobile]="isMobile"
				(SelectedScheduleOutput)="handleScheduleFilterOutput($event)">
			</app-schedule-selector>
		</div>
	</div>
	<div *ngIf="fromForm('breakdownRangeSelector').value === 5" class="l-daterange-container">
		<div [ngClass]="{ 'l-calendar-row': !isMobile, 'l-calendar-mobile': isMobile }">
			<div [ngClass]="{ 'l-calendar-container': !isMobile, 'l-calendar-mobile-container': isMobile }">
				<label class="g-form-label"> Date <span class="g-orange"> * </span></label>
				<div [ngClass]="{ 'l-date-container': !isMobile, 'l-date-container-mobile': isMobile }">
					<div class="l-datetime-container">
						<mat-icon class="l-icon pr-3"> calendar_today </mat-icon>
						<input
							[ngxMatDatetimePicker]="dateFromPicker"
							[disabled]="false"
							placeholder="Date From"
							(click)="dateFromPicker.open()"
							class="form-control"
							[formControl]="form.get('startDate')"
							[min]="eventCreationDate"
							[max]="getMaxDate()"
							id="schedule-content-dateFrom"
						/>
						<ngx-mat-datetime-picker
							#dateFromPicker
							[touchUi]="isMobile"
							[enableMeridian]="false"
							[hideTime]="true"
						>
							<ngx-mat-datepicker-actions>
								<button
									id="close-datepicker"
									class="g-secondary-button g-datepicker-button"
									ngxMatDatepickerCancel
								>CANCEL</button>
								<button
									id="apply-datepicker"
									class="g-primary-button g-datepicker-button"
									ngxMatDatepickerApply
								>APPLY</button>
							</ngx-mat-datepicker-actions>
						</ngx-mat-datetime-picker>
					</div>
					<div [ngClass]="{ 'l-date-container': !isMobile, 'l-date-container-mobile pt-3': isMobile }">
						<div class="l-datetime-container">
							<mat-icon class="l-icon">calendar_today</mat-icon>
							<input
								[ngxMatDatetimePicker]="dateToPicker"
								[disabled]="false"
								placeholder="Date To"
								(click)="dateToPicker.open()"
								class="form-control"
								[formControl]="form.get('endDate')"
								[min]="getMinDate()"
								id="schedule-content-dateTo"
							/>
							<ngx-mat-datetime-picker
								#dateToPicker
								[touchUi]="isMobile"
								[enableMeridian]="false"
								[hideTime]="true"
							>
								<ngx-mat-datepicker-actions>
									<button
										id="close-datepicker"
										class="g-secondary-button g-datepicker-button"
										ngxMatDatepickerCancel
									>CANCEL</button>
									<button
										id="apply-datepicker"
										class="g-primary-button g-datepicker-button"
										ngxMatDatepickerApply
									>APPLY</button>
								</ngx-mat-datepicker-actions>
							</ngx-mat-datetime-picker>
						</div>
					</div>
					<div *ngIf="form.errors?.dates" class="g-error-input">
						{{ form.errors.dates }}
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
