import * as moment from 'moment';
import 'moment-timezone';

export const defaultDateTimeFormat = 'YYYY/MM/DD HH:mm:ss';

export function setTimezoneTo(date: string | Date, timezone: string): string {
	if (!date) {
		return null;
	}
	const dateString = moment(date).format(defaultDateTimeFormat);

	return moment.tz(dateString, defaultDateTimeFormat, timezone).toISOString();
}

export function setTimezoneFrom(date: string | Date, timezone: string): string {
	if (!date) {
		return null;
	}
	const dateString = moment(date).tz(timezone).format(defaultDateTimeFormat);

	return moment.tz(dateString, defaultDateTimeFormat, moment.tz.guess()).toISOString();
}
