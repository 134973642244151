<div class="l-container">
	<div class="l-content">
		<a href="/" class="l-mobile-header-icon-link">
			<img
			class="l-logo"
			[src]="QUICKET_LOGO"
			alt="logo"
		>
		</a>

		<div class="l-links">
			<div
				class="l-link"
				[class.l-active]="option.value.title === currentRoute"
				*ngFor="let option of navOptions | keyvalue: handleDefaultOrder"
			>
				<div
					(click)="handleNavigation(option.value)"
					class="g-subtitle l-href"
					[class.l-active-text]="option.value.title === currentRoute"
				>{{option.value.title}}</div>
			</div>
		</div>
	</div>
	<div class="l-footer-container">
		<hr />
		<div class="g-subtitle mb-3">Follow Us</div>
		<div class="l-footer">
			<div class="l-socials">
				<div *ngFor="let social of socials | keyvalue">
					<a
						[href]="social.value.routeTo"
						class="l-href"
					><img
							class="l-icon"
							[src]="social.value.iconPath"
							[alt]="social.value.title"
						/></a>
				</div>
			</div>
			<div class="l-create-event-container">
				<div class="l-current-user">
					<i class="fa fa-solid fa-user l-user-icon"></i>
					<div class="l-username">{{ (user$ | async).info.userName }}</div>
				</div>
				<button
					id='create-event'
					class="g-primary-button w-100 my-2"
					(click)="handleCreateEventRoute()"
				>CREATE EVENT</button>
			</div>
		</div>
	</div>
</div>