<div class="l-container">
	<div class="l-content" [ngClass]="{ 'mobile': isMobile }">
		<div class="l-title" [ngClass]="{ 'mobile': isMobile, 'web': !isMobile }">
			<h3 *ngIf='!isMobile'>Reports</h3>
			<p>Access various reports and data to analyze and evaluate event performance.</p>
		</div>
		<div class="l-cards-container" [ngClass]="{ 'mobile': isMobile, 'web': !isMobile }">
			<div *ngFor="let card of cardsData | keyvalue: handleDefaultOrder" [appMiddleClickNavigate]="handleMiddleClick(card.value)" 
				(click)="handleNavigate(card.value)"
				[ngClass]="{'l-card': !isMobile }" class="g-pointer">
				<ng-container *ngIf="!isMobile; else showMobile">
					<app-section-route-card class="g-pointer l-card-content"
						[card]="card.value"></app-section-route-card>
				</ng-container>
				<ng-template #showMobile>
					<app-mobile-section-route-card [icon]="card.value.iconPath"
						[text]="card.value.title" [description]='card.value.description' [asCard]='true'></app-mobile-section-route-card>
				</ng-template>
			</div>
		</div>
	</div>
</div>
