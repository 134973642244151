import { Injectable } from '@angular/core';
import { concat, of, throwError } from 'rxjs';
import { map, catchError, switchMap, mergeMap } from 'rxjs/operators';
import { Actions, createEffect } from '@ngrx/effects';
import { MembershipDomainAPI } from '@app/api/domains/membership';
import { UserActionsConstants } from '@app/store/actions/user/user.actions.constants';
import { VerifyToken, GetUserDesignerKey } from '@app/store/actions/user/user.actions';
import * as userActions from '@app/store/actions/user/user.actions';
import { Go, Redirect } from '@app/store/actions/router/router.actions';
import { getResponseErrors, getResponseErrorCodes } from '@app/utils/Response';
import { defaultUserDashboardRoute } from '@app/services/url/url.service';
import { TicketsActionsConstant } from '@app/store/actions/tickets/tickets.constants';
import { ofTypeExt, handleSuccessRequest, handleFailedRequest } from '@app/utils/Store';
import { TokenService } from '@app/services/token/token.service';
import { RouterActionsUnion } from '@app/store/actions/router/router.actions';
import * as eventActions from '@app/store/actions/event/event.actions';
import { ContactsSource } from '@app/models/user.model';
import { MyTicketsConstants } from '@app/user-features/my-tickets/my-tickets.actions';
import {
	UpdateManageBookingSuccess,
	UpdateManageBookingFailed,
	DownloadTicketsSeperately,
	DownloadTicketsSeperatelySuccess,
	DownloadTicketsSeperatelyFailed
} from '@app/user-features/manage-booking/manage-booking.actions';
import {
	GetUserManageBooking,
	ManageBookingConstants,
	GetUserManageBookingSuccess,
	GetUserManageBookingFailed,
	UpdateBookingInvoice,
	UpdateBookingInvoiceFailed,
	SetUpdateInvoiceModalFlag,
	UpdateBookingInvoiceSuccess,
	UpdateManageBooking
} from '@app/user-features/manage-booking/manage-booking.actions';
import { normalize } from 'normalizr';
import { arrayOfCommonScheme } from '@app/schemes/common.scheme';
import { IsUpdateBookingSuccess } from '@app/user-features/manage-booking/manage-booking.actions';
import {
	UpdateFundraiserInvoice,
	UpdateFundraiserInvoiceSuccess,
	UpdateFundraiserInvoiceFailed
} from '@app/user-features/my-tickets/my-tickets.actions';
import * as Sentry from '@sentry/angular';

@Injectable()
export class UserEffects {
	constructor(
		private action$: Actions,
		private tokenService: TokenService,
		private api?: MembershipDomainAPI
	) {}

	verifyToken$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<VerifyToken>(UserActionsConstants.VERIFY_TOKEN),
			switchMap(({ action, payload: { token } }) =>
				this.api.verifyToken(token).pipe(
					handleSuccessRequest(({ payload: user }) => {
						Sentry.getIsolationScope().setUser({ id: user.id, email: user.email });
						return new userActions.VerifyTokenSuccess({
							user,
						});
					}),
					catchError((err) => {
						this.tokenService.removeToken();
						Sentry.getIsolationScope().setUser(null);
						const routerAction: RouterActionsUnion = new Redirect({
							path: 'login',
							config: [encodeURIComponent(defaultUserDashboardRoute)],
						});

						return concat([
							routerAction,
							new userActions.VerifyTokenFailed({
								msg: err.message,
								action,
								critical: true,
								error: err,
								serverMessages: getResponseErrorCodes(err),
							}),
						]);
					})
				)
			)
		)
	);

	getUserDesignerKey$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<GetUserDesignerKey>(UserActionsConstants.GET_USER_DESIGNER_KEY),
			switchMap(({ action }) =>
				this.api.getSeatsIoAccount(action.payload.eventId).pipe(
					map((value) => {
						const { payload: seatsDesignerKey, isSuccess } = value;
						if (isSuccess && seatsDesignerKey) {
							return new userActions.GetUserDesignerKeySuccess({ seatsDesignerKey });
						} else {
							throw getResponseErrors(value);
						}
					}),
					catchError(
						handleFailedRequest(
							new userActions.GetUserDesignerKeyFailed({
								msg: 'can\'t get user design key',
								action,
							})
						)
					)
				)
			)
		)
	);

	getUserCharts$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<userActions.GetUserCharts>(UserActionsConstants.GET_USER_CHARTS, TicketsActionsConstant.GET_SEATING_CATEGORIES),
			switchMap(({ action, payload: { eventId: id } }) =>
				this.api.getUserCharts(id).pipe(
					handleSuccessRequest(
						({ payload: { items: charts } }) =>
							new userActions.GetUserChartsSuccess({
								id,
								charts,
							})
					),
					catchError(
						handleFailedRequest(
							new userActions.GetUserChartsFailed({
								msg: 'can\'t get user charts',
								action,
							})
						)
					)
				)
			)
		)
	);

	setUserInfo$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<userActions.SetUserInfo>(UserActionsConstants.SET_USER_INFO),
			switchMap(({ action }) =>
				this.api.setUserInfo(action.payload).pipe(
					handleSuccessRequest(({ payload }) => new userActions.SetUserInfoSuccess(payload)),
					catchError(
						handleFailedRequest(
							new userActions.SetUserInfoFailed({
								msg: 'can\'t update user info',
								action,
							})
						)
					)
				)
			)
		)
	);

	createContactList$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<userActions.CreateContactList>(UserActionsConstants.CREATE_CONTACT_LIST),
			switchMap(({ action, payload: { contacts, autoSendInvites, eventId, source, name } }) =>
				this.api.createContactList(contacts, source, name).pipe(
					mergeMap((value) => {
						if (value.isSuccess) {
							const actionsToProceed: (userActions.Actions | eventActions.Actions)[] = [
								new userActions.CreateContactListSuccess(value.payload),
								new userActions.SetActiveContactsSource({ source: ContactsSource.PreviousList }),
							];

							if (autoSendInvites) {
								actionsToProceed.push(
									new eventActions.SendInvites({
										contactsListId: [value.payload.contactListId],
										eventId,
									})
								);
							}

							return concat(actionsToProceed);
						}
						return throwError(getResponseErrors(value));
					}),
					catchError(
						handleFailedRequest(
							new userActions.CreateContactListFailed({
								msg: 'can\'t create contact list',
								action,
							})
						)
					)
				)
			)
		)
	);

	getPreviousContactLists$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<userActions.GetPreviousContactLists>(UserActionsConstants.GET_PREVIOUS_CONTACT_LISTS),
			switchMap(({ action, payload: { eventId } }) =>
				this.api.getPreviousContactLists(eventId).pipe(
					handleSuccessRequest(
						({ payload: previousContactLists }) => new userActions.GetPreviousContactListsSuccess({ previousContactLists }) // eslint-disable-line max-len
					),
					catchError(
						handleFailedRequest(
							new userActions.GetPreviousContactListsFailed({
								msg: 'can\'t get previous contact lists',
								action,
							})
						)
					)
				)
			)
		)
	);

	getPreviousEvents$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<userActions.GetPreviousEvents>(UserActionsConstants.GET_PREVIOUS_EVENTS),
			switchMap(({ action }) =>
				this.api.getPreviousEvents(action.payload.eventId).pipe(
					handleSuccessRequest(({ payload: events }) => new userActions.GetPreviousEventsSuccess({ events })),
					catchError(
						handleFailedRequest(
							new userActions.GetPreviousEventsFailed({
								msg: 'can\'t get previous events',
								action,
							})
						)
					)
				)
			)
		)
	);

	getPreviousEventsAttendees$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<userActions.GetEventsAttendees>(UserActionsConstants.GET_EVENTS_ATTENDEES),
			switchMap(({ action, payload: { eventIds } }) =>
				this.api.getPreviousEventsAttendees(eventIds).pipe(
					handleSuccessRequest(({ payload: contacts }) => new userActions.GetEventsAttendeesSuccess({ contacts })),
					catchError(
						handleFailedRequest(
							new userActions.GetEventsAttendeesFailed({
								msg: 'can\'t get previous events attendees',
								action,
							})
						)
					)
				)
			)
		)
	);

	getBanks$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<userActions.GetBanks>(UserActionsConstants.GET_BANKS),

			switchMap(({ action, payload: { currencyId } }) =>
				this.api.getBanks(currencyId).pipe(
					handleSuccessRequest(({ payload: banks }) => new userActions.GetBanksSuccess({ banks })),
					catchError(
						handleFailedRequest(
							new userActions.GetBanksFailed({
								msg: 'can\'t get banks',
								action,
							})
						)
					)
				)
			)
		)
	);

	// #region GetAllBanks
	GetAllBanks$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<userActions.GetAllBanks>(UserActionsConstants.GET_ALL_BANKS),
			switchMap(({ action }) =>
				this.api.getAllBanks().pipe(
					mergeMap((banks) => [new userActions.GetAllBanksSuccess({ banks: banks.payload })]),
					catchError((err) =>
						of(
							new userActions.GetAllBanksFailed({
								msg: err.error.errors ? err.error.errors[0].message : err.error.message,
								action,
							})
						)
					)
				)
			)
		)
	);
	// #endregion

	getBankAccounts$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<userActions.GetBankAccounts>(UserActionsConstants.GET_BANK_ACCOUNTS),
			switchMap(({ action }) =>
				this.api.getBankAccounts().pipe(
					handleSuccessRequest(
						({ payload: { bankAccounts, needsPasswordVerification, passwordVerificationRedirect } }) =>
							new userActions.GetBankAccountsSuccess({
								bankAccounts: bankAccounts,
								needsPasswordVerification: needsPasswordVerification,
								passwordVerificationRedirect: passwordVerificationRedirect,
							})
					),
					catchError(
						handleFailedRequest(
							new userActions.GetBankAccountsFailed({
								msg: 'can\'t get bank accounts',
								action,
							})
						)
					)
				)
			)
		)
	);

	addBankAccount$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<userActions.AddBankAccount>(UserActionsConstants.ADD_BANK_ACCOUNT),
			switchMap(({ action, payload: { draftAccount } }) =>
				this.api.addBankAccount(draftAccount).pipe(
					handleSuccessRequest(({ payload: account }) => new userActions.AddBankAccountSuccess({ account })),
					catchError(
						handleFailedRequest(
							new userActions.AddBankAccountFailed({
								msg: 'can\'t add bank account',
								action,
							})
						)
					)
				)
			)
		)
	);

	// #region UpdateBankAccount
	UpdateBankAccount$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<userActions.UpdateBankAccount>(UserActionsConstants.UPDATE_BANK_ACCOUNT),
			switchMap(({ action, payload: { draftAccount } }) =>
				this.api.updateBankAccount(draftAccount).pipe(
					mergeMap(({ payload }) => [new userActions.UpdateBankAccountSuccess({ updatedAccount: payload })]),
					catchError((err) =>
						of(
							new userActions.DeleteBankAccountFailed({
								msg: err.error.errors[0].message,
								action,
							})
						)
					)
				)
			)
		)
	);
	// #endregion

	// #region DeleteBankAccount
	DeleteBankAccount$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<userActions.DeleteBankAccount>(UserActionsConstants.DELETE_BANK_ACCOUNT),
			switchMap(({ action, payload: { accountId } }) =>
				this.api.deleteBankAccount(accountId).pipe(
					mergeMap((payload) => [new userActions.DeleteBankAccountSuccess({ accountId: payload.payload })]),
					catchError((err) =>
						of(
							new userActions.DeleteBankAccountFailed({
								msg: err.error.errors[0].message,
								action,
							})
						)
					)
				)
			)
		)
	);
	// #endregion

	getUserManageBooking$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<GetUserManageBooking>(ManageBookingConstants.GET_USER_MANAGE_BOOKING),
			switchMap(({ action, payload: { id } }) =>
				this.api.getUserManageBooking(id).pipe(
					handleSuccessRequest(
						({ payload: booking }) =>
							new GetUserManageBookingSuccess({
								id,
								booking,
							})
					),
					catchError(
						handleFailedRequest(
							new GetUserManageBookingFailed({
								msg: 'can\'t get this booking',
								action,
								critical: true,
								meta: {
									extraAction: new Go({ path: ['/login'] }),
									extraActionTitle: 'Go to login',
									allowClose: false,
								},
							})
						)
					)
				)
			)
		)
	);

	updateBookingInvoice$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<UpdateBookingInvoice>(ManageBookingConstants.UPDATE_BOOKING_INVOICE),
			switchMap(({ action, payload: { id, invoice } }) =>
				this.api.updateBookingInvoice(id, invoice).pipe(
					mergeMap((value) => {
						if (value.isSuccess && value.payload) {
							return concat([
								new UpdateBookingInvoiceSuccess({
									id,
									invoice,
								}),
								new SetUpdateInvoiceModalFlag({
									id,
									isModalOpen: false,
								}),
							]);
						} else {
							return throwError(getResponseErrors(value));
						}
					}),
					catchError(
						handleFailedRequest(
							new UpdateBookingInvoiceFailed({
								msg: 'can\'t update this invoice',
								action,
							})
						)
					)
				)
			)
		)
	);

	updateBookingDates$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<UpdateManageBooking>(ManageBookingConstants.UPDATE_MANAGE_BOOKING),
			switchMap(({ action, payload: { id, newScheduleItemId } }) =>
				this.api.updateManageBooking(id, newScheduleItemId).pipe(
					mergeMap((value) => {
						if (value && value.isSuccess) {
							return concat([
								new UpdateManageBookingSuccess({
									id,
									booking: value.payload,
								}),
								new IsUpdateBookingSuccess({
									id,
									isUpdateSuccess: true,
								}),
							]);
						} else {
							return throwError(getResponseErrors(value));
						}
					}),
					catchError(
						handleFailedRequest(
							new UpdateManageBookingFailed({
								msg: 'can\'t update this booking',
								action,
							})
						)
					)
				)
			)
		)
	);

	updateAPIToken$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<userActions.UpdateUserAPIToken>(UserActionsConstants.UPDATE_USER_API_TOKEN),
			switchMap(({ action }) =>
				this.api.updateAPIToken().pipe(
					handleSuccessRequest(({ payload }) => new userActions.UpdateUserAPITokenSuccess(payload.apiToken)),
					catchError(
						handleFailedRequest(
							new userActions.UpdateUserAPITokenFailed({
								msg: 'can\'t get your new API token',
								action,
							})
						)
					)
				)
			)
		)
	);

	updateFundraiserInvoice$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<UpdateFundraiserInvoice>(MyTicketsConstants.UPDATE_FUNDRAISER_INVOICE),
			switchMap(({ action, payload: { id, booking } }) =>
				this.api.updateFundraiserInvoice(id, booking).pipe(
					handleSuccessRequest(
						({ payload }) =>
							new UpdateFundraiserInvoiceSuccess({
								id,
								booking: payload,
							})
					),
					catchError(
						handleFailedRequest(
							new UpdateFundraiserInvoiceFailed({
								msg: 'can\'t update this invoice',
								action,
							})
						)
					)
				)
			)
		)
	);

	downloadTicketsSeperately$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<DownloadTicketsSeperately>(ManageBookingConstants.DOWNLOAD_TICKETS_SEPERATELY),
			switchMap(({ action, payload: { ticketsInfo } }) =>
				this.api.downloadTicketsSeperately(ticketsInfo).pipe(
					handleSuccessRequest(
						({ payload }) =>
							new DownloadTicketsSeperatelySuccess({
								downloadRequestSent: payload,
							})
					),
					catchError(
						handleFailedRequest(
							new DownloadTicketsSeperatelyFailed({
								msg: 'An error occurred while downloading your tickets please contact support or try again.',
								action,
							})
						)
					)
				)
			)
		)
	);

	// #region GetUserInfo
	GetUserInfo$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<userActions.GetUserInfo>(UserActionsConstants.GET_USER_INFO),
			switchMap(({ action }) =>
				this.api.getUserInfo().pipe(
					mergeMap(({ payload }) => [new userActions.GetUserInfoSuccess(payload)]),
					catchError(
						handleFailedRequest(
							new userActions.GetUserInfoFailed({
								msg: 'can\'t get your details',
								action,
							})
						)
					)
				)
			)
		)
	);
	// #endregion

	// #region UpdateUserInfo
	UpdateUserInfo$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<userActions.UpdateUserInfo>(UserActionsConstants.UPDATE_USER_INFO),
			switchMap(({ action, payload: { userDetails } }) =>
				this.api.updateUserInfo(userDetails).pipe(
					mergeMap(({ payload }) => [new userActions.UpdateUserInfoSuccess(payload)]),
					catchError(
						handleFailedRequest(
							new userActions.UpdateUserInfoFailed({
								msg: 'can\'t update your details',
								action,
							})
						)
					)
				)
			)
		)
	);
	// #endregion

	// #region UpdatePassword
	UpdatePassword$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<userActions.UpdatePassword>(UserActionsConstants.UPDATE_PASSWORD),
			switchMap(({ action, payload: { passwords } }) =>
				this.api.updatePassword(passwords).pipe(
					mergeMap((payload) => [new userActions.UpdatePasswordSuccess(payload)]),
					catchError((error) =>
						of(
							new userActions.UpdatePasswordFailed({
								msg: error.error.errors[0].message,
								action,
							})
						)
					)
				)
			)
		)
	);
	// #endregion

	// #region DeleteUser
	DeleteUser$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<userActions.DeleteUser>(UserActionsConstants.DELETE_USER),
			switchMap(({ action, payload: { password } }) =>
				this.api.deleteUserAccount(password).pipe(
					mergeMap((payload) => [new userActions.DeleteUserSuccess({ route: payload.payload })]),
					catchError((error) =>
						of(
							new userActions.DeleteUserFailed({
								msg: error.error.errors[0].message,
								action,
							})
						)
					)
				)
			)
		)
	);
	// #endregion

	// #region GetEmailPreferences
	GetEmailPreferences$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<userActions.GetEmailPreferences>(UserActionsConstants.GET_EMAIL_PREFERENCES),
			mergeMap(({ action, payload: { e } }) =>
				this.api.getEmailPreferences(e).pipe(
					mergeMap((payload) => [new userActions.GetEmailPreferencesSuccess(payload)]),
					catchError((error) =>
						of(
							new userActions.GetEmailPreferencesFailed({
								msg: error.error.errors[0].message,
								action,
							})
						)
					)
				)
			)
		)
	);
	// #endregion

	// #region UpdateEmailPreferences
	UpdateEmailPreferences$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<userActions.UpdateEmailPreferences>(UserActionsConstants.UPDATE_EMAIL_PREFERENCES),
			mergeMap(({ action, payload: { e, preferences } }) =>
				this.api.updateEmailPreferences(e, preferences).pipe(
					mergeMap((payload) => [new userActions.UpdateEmailPreferencesSuccess(payload)]),
					catchError((error) =>
						of(
							new userActions.UpdateEmailPreferencesFailed({
								msg: error.error.errors[0].message,
								action,
							})
						)
					)
				)
			)
		)
	);
	// #endregion

	// #region GetUserMyRegistrations
	GetUserMyRegistrations$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<userActions.GetUserMyRegistrations>(UserActionsConstants.GET_USER_MY_REGISTRATIONS),
			mergeMap(({ action }) =>
				this.api.getUserMyRegistrations().pipe(
					mergeMap(({ payload: registrations }) => [
						new userActions.GetUserMyRegistrationsSuccess({
							registrations: registrations,
						}),
					]),
					catchError((error) =>
						of(
							new userActions.GetUserMyRegistrationsFailed({
								msg: error.error.message,
								action,
							})
						)
					)
				)
			)
		)
	);
	// #endregion

	// #region GetUserMyTickets
	GetUserMyTickets$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<userActions.GetUserMyTickets>(UserActionsConstants.GET_USER_MY_TICKETS),
			mergeMap(({ action, payload }) =>
				this.api.getUserMyTickets(payload.cartId).pipe(
					mergeMap(({ payload: bookings }) => {
						const {
							entities: { byId },
							result: all,
						} = normalize(bookings, arrayOfCommonScheme);
						return [
							new userActions.GetUserMyTicketsSuccess({
								bookings: {
									byId,
									all,
								},
							}),
						];
					}),
					catchError((error) =>
						of(
							new userActions.GetUserMyTicketFailed({
								msg: error.error.message,
								action,
							})
						)
					)
				)
			)
		)
	);
	// #endregion

	// #region GetRefundableTickets
	GetRefundableTickets$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<userActions.GetRefundableTickets>(UserActionsConstants.GET_REFUNDABLE_TICKETS),
			mergeMap(({ action, payload }) =>
				this.api.getRefundableTickets(payload.purchaseId).pipe(
					mergeMap(({ payload: refundableTickets }) => [
						new userActions.GetRefundableTicketsSuccess({
							refundableTickets: refundableTickets,
							purchaseId: payload.purchaseId,
						}),
					]),
					catchError(
						handleFailedRequest(
							new userActions.GetRefundableTicketsFailed({
								msg: 'can\'t fetch your refundable tickets',
								action,
							})
						)
					)
				)
			)
		)
	);
	// #endregion

	// #region PostRefundableTickets
	PostRefundableTickets$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<userActions.PostRefundableTickets>(UserActionsConstants.POST_REFUNDABLE_TICKETS),
			mergeMap(({ action, payload }) =>
				this.api.postRefundableTickets(payload.purchaseId, payload.tickets, payload.bankAccountId).pipe(
					mergeMap(({ payload: refundableTickets }) => [
						new userActions.PostRefundableTicketsSuccess({
							refundableTickets: refundableTickets,
							purchaseId: payload.purchaseId,
						}),
					]),
					catchError(
						handleFailedRequest(
							new userActions.PostRefundableTicketsFailed({
								msg: 'Something went wrong, can\'t refund tickets',
								action,
							})
						)
					)
				)
			)
		)
	);
	// #endregion

	// #region GetTransferableTickets
	GetTransferableTickets$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<userActions.GetTransferableTickets>(UserActionsConstants.GET_TRANSFERABLE_TICKETS),
			mergeMap(({ action, payload }) =>
				this.api.getTransferableTickets(payload.purchaseId).pipe(
					mergeMap(({ payload: transferTickets }) => [
						new userActions.GetTransferableTicketsSuccess({
							transferableTickets: transferTickets,
							purchaseId: payload.purchaseId,
						}),
					]),
					catchError(
						handleFailedRequest(
							new userActions.GetTransferableTicketsFailed({
								msg: 'can\'t fetch your transferable tickets',
								action,
							})
						)
					)
				)
			)
		)
	);
	// #endregion

	// #region transferTickets
	transferTickets$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<userActions.TransferTickets>(UserActionsConstants.TRANSFER_TICKETS),
			mergeMap(({ action, payload }) =>
				this.api.transferTickets(payload.purchaseId, payload.transferableTickets).pipe(
					mergeMap(({ payload: transferTickets }) => [
						new userActions.TransferTicketsSuccess({
							transferableTickets: transferTickets,
							purchaseId: payload.purchaseId,
						}),
					]),
					catchError(
						handleFailedRequest(
							new userActions.TransferTicketsFailed({
								msg: 'can\'t transfer tickets',
								action,
							})
						)
					)
				)
			)
		)
	);
	// #endregion

	// #region transferTicketsBack
	transferTicketsBack$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<userActions.TransferTicketsBack>(UserActionsConstants.TRANSFER_TICKETS_BACK),
			mergeMap(({ action, payload }) =>
				this.api.transferTicketsBack(payload.purchaseId).pipe(
					mergeMap(() => [new userActions.TransferTicketsBackSuccess()]),
					catchError(
						handleFailedRequest(
							new userActions.TransferTicketsBackFailed({
								msg: 'can\'t transfer tickets back',
								action,
							})
						)
					)
				)
			)
		)
	);
	// #endregion

	// #region getEditTicketDetails
	getEditTicketDetails$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<userActions.GetEditTicketDetails>(UserActionsConstants.GET_EDIT_TICKET_DETAILS),
			mergeMap(({ action, payload }) =>
				this.api.getEditTicketDetails(payload.id).pipe(
					mergeMap(({ payload: editTicketDetails }) => [
						new userActions.GetEditTicketDetailsSuccess({
							editTicketDetails: editTicketDetails,
							id: payload.id,
						}),
					]),
					catchError((error) =>
						of(
							new userActions.GetEditTicketDetailsFailed({
								msg: error.error && error.error.errors.length
									? error.error.errors[0].message
									: 'can\'t get edit ticket questions',
								serverMessages: getResponseErrorCodes(error),
								action,
							})
						)
					)
				)
			)
		)
	);
	// #endregion

	// #region updateEditTicketDetails
	updateEditTicketDetails$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<userActions.UpdateEditTicketDetails>(UserActionsConstants.UPDATE_EDIT_TICKET_DETAILS),
			mergeMap(({ action, payload }) =>
				this.api.updateEditTicketDetails(payload.id, payload.editTicketDetails).pipe(
					mergeMap(({ payload: editTicketDetails }) => [
						new userActions.UpdateEditTicketDetailsSuccess({
							editTicketDetails: editTicketDetails,
						}),
					]),
					catchError(
						handleFailedRequest(
							new userActions.UpdateEditTicketDetailsFailed({
								msg: 'can\'t update edit ticket questions',
								action,
							})
						)
					)
				)
			)
		)
	);
	// #endregion

	// #region getRegistrationDetails
	getRegistrationDetails$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<userActions.GetRegistrationDetails>(UserActionsConstants.GET_REGISTRATION_DETAILS),
			mergeMap(({ action, payload }) =>
				this.api.getRegistrationDetails(payload.id).pipe(
					mergeMap(({ payload: registrationDetails }) => [
						new userActions.GetRegistrationDetailsSuccess({
							registrationDetails: registrationDetails,
							id: payload.id,
						}),
					]),
					catchError((error) =>
						of(new userActions.GetRegistrationDetailsFailed({
							msg: error.error && error.error.errors.length
								? error.error.errors[0].message
								: 'can\'t get registration questions',
							action,
							serverMessages: getResponseErrorCodes(error),
						}))
					)
				)
			)
		)
	);
	// #endregion

	// #region updateRegistrationDetails
	updateRegistrationDetails$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<userActions.UpdateRegistrationDetails>(UserActionsConstants.UPDATE_REGISTRATION_DETAILS),
			mergeMap(({ action, payload }) =>
				this.api.updateRegistrationDetails(payload.id, payload.registrationDetails).pipe(
					mergeMap(({ payload: registrationDetails }) => [
						new userActions.UpdateRegistrationDetailsSuccess({
							registrationDetails: registrationDetails,
						}),
					]),
					catchError(
						handleFailedRequest(
							new userActions.UpdateRegistrationDetailsFailed({
								msg: 'can\'t update registration questions',
								action,
							})
						)
					)
				)
			)
		)
	);

	// #region uploadFile
	fileUpload$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<userActions.FileUpload>(UserActionsConstants.FILE_UPLOAD),
			mergeMap(({ action, payload: { formData, name, purchaseId, encrypt, productId, purchaseItemId, questionId } }) =>
				this.api.uploadFile(formData, name, purchaseId, encrypt, productId).pipe(
					mergeMap(({ payload: { success, error, downloadUrl } }) => [
						new userActions.FileUploadSuccess({
							success: success,
							error: error,
							downloadUrl: downloadUrl,
							purchaseItemId: purchaseItemId,
							questionId: questionId,
						}),
					]),
					catchError(
						handleFailedRequest(
							new userActions.FileUploadFailed({
								msg: 'can\'t upload file',
								action,
							})
						)
					)
				)
			)
		)
	);

	// #region GetResaleTickets
	GetResaleTickets$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<userActions.GetResaleTickets>(UserActionsConstants.GET_RESALE_TICKETS),
			mergeMap(({ action, payload }) =>
				this.api.getResaleTickets(payload.purchaseId).pipe(
					mergeMap(({ payload: resaleTickets }) => [
						new userActions.GetResaleTicketsSuccess({
							resaleTickets: resaleTickets,
							purchaseId: payload.purchaseId,
						}),
					]),
					catchError(
						handleFailedRequest(
							new userActions.GetResaleTicketsFailed({
								msg: 'can\'t fetch your resale tickets',
								action,
							})
						)
					)
				)
			)
		)
	);
	// #endregion

	// #region PostResaleTickets
	PostResaleTickets$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<userActions.PostResaleTickets>(UserActionsConstants.POST_RESALE_TICKETS),
			mergeMap(({ action, payload }) =>
				this.api.postResaleTickets(payload.purchaseId, payload.createTicketResale).pipe(
					mergeMap(({ payload: resaleTickets }) => [
						new userActions.PostResaleTicketsSuccess({
							resaleTickets: resaleTickets,
							purchaseId: payload.purchaseId,
						}),
					]),
					catchError(
						handleFailedRequest(
							new userActions.PostResaleTicketsFailed({
								msg: 'can\'t create your resale tickets',
								action,
							})
						)
					)
				)
			)
		)
	);
	// #endregion

	// #region CancelResaleTickets
	CancelResaleTickets$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<userActions.CancelResaleTickets>(UserActionsConstants.CANCEL_RESALE_TICKETS),
			mergeMap(({ action, payload }) =>
				this.api.cancelResaleTickets(payload.purchaseId, payload.ticketResaleId).pipe(
					mergeMap(({ payload: resaleTickets }) => [
						new userActions.CancelResaleTicketsSuccess({
							resaleTickets: resaleTickets,
							purchaseId: payload.purchaseId,
						}),
					]),
					catchError(
						handleFailedRequest(
							new userActions.CancelResaleTicketsFailed({
								msg: 'can\'t cancel your resale tickets',
								action,
							})
						)
					)
				)
			)
		)
	);
	// #endregion

	// #region GetUserSavedEvents
	getUserSavedEvents$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<userActions.GetUserSavedEvents>(UserActionsConstants.GET_USER_SAVED_EVENTS),
			mergeMap(({ action }) =>
				this.api.getUserSavedEvents().pipe(
					mergeMap(({ payload: SavedEvents }) => [
						new userActions.GetUserSavedEventsSuccess({
							userSavedEvents: SavedEvents,
						}),
					]),
					catchError(
						handleFailedRequest(
							new userActions.GetUserSavedEventsFailed({
								msg: 'can\'t get saved events',
								action,
							})
						)
					)
				)
			)
		)
	);
	// #endregion

	// #region getAllPromoterNetworks
	getAllPromoterNetworks$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<userActions.GetAllPromoterNetworks>(UserActionsConstants.GET_ALL_PROMOTER_NETWORK),
			mergeMap(({ action }) =>
				this.api.getAllPromoterNetworks().pipe(
					mergeMap(({ payload: promoterNetworks }) => [
						new userActions.GetAllPromoterNetworksSuccess({
							promoterNetworks: promoterNetworks,
						}),
					]),
					catchError(
						handleFailedRequest(
							new userActions.GetAllPromoterNetworksFailed({
								msg: 'can\'t get promoter networks',
								action,
							})
						)
					)
				)
			)
		)
	);

	// #region getPromoterNetwork
	GetPromoterNetwork$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<userActions.GetPromoterNetwork>(UserActionsConstants.GET_PROMOTER_NETWORK),
			mergeMap(({ action, payload }) =>
				this.api.getPromoterNetwork(payload.id).pipe(
					mergeMap(({ payload: promoterNetwork }) => [
						new userActions.GetPromoterNetworkSuccess({
							promoterNetwork: promoterNetwork,
						}),
					]),
					catchError(
						handleFailedRequest(
							new userActions.GetPromoterNetworkFailed({
								msg: 'can\'t get promoter network',
								action,
							})
						)
					)
				)
			)
		)
	);

	// #region updatePromoterNetworkLink
	updatePromoterNetworkLink$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<userActions.UpdatePromoterNetworkLink>(UserActionsConstants.UPDATE_PROMOTER_NETWORK_LINK),
			mergeMap(({ action, payload: { id, code } }) =>
				this.api.updatePromoterNetworkLink(id, code).pipe(
					mergeMap(({ payload: promoterNetwork }) => [
						new userActions.UpdatePromoterNetworkLinkSuccess({
							promoterNetwork: promoterNetwork,
						}),
					]),
					catchError(
						handleFailedRequest(
							new userActions.UpdatePromoterNetworkLinkFailed({
								msg: 'can\'t update promoter network link',
								action,
							})
						)
					)
				)
			)
		)
	);

	updatePromoterBankAccount$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<userActions.UpdatePromoterBankAccount>(UserActionsConstants.UPDATE_PROMOTER_BANK_ACCOUNT),
			mergeMap(({ action, payload }) =>
				this.api.updatePromoterBankAccount(payload.id, payload.accountId).pipe(
					mergeMap(({ payload: promoterNetwork }) => [
						new userActions.UpdatePromoterBankAccountSuccess({
							promoterNetwork: promoterNetwork,
						}),
					]),
					catchError(
						handleFailedRequest(
							new userActions.UpdatePromoterBankAccountFailed({
								msg: 'can\'t update promoter bank account',
								action,
							})
						)
					)
				)
			)
		)
	);

	referralDetails$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<userActions.GetReferralDetails>(UserActionsConstants.GET_REFERRAL_DETAILS),
			mergeMap(({ action }) =>
				this.api.getReferralDetails().pipe(
					mergeMap(({ payload }) => [
						new userActions.GetReferralDetailsSuccess({
							referrals: payload,
						}),
					]),
					catchError(
						handleFailedRequest(
							new userActions.GetReferralDetailsFailed({
								msg: 'can\'t get referral data',
								action,
							})
						)
					)
				)
			)
		)
	);

	referralCodeUpdate$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<userActions.UpdateReferralCode>(UserActionsConstants.UPDATE_REFERRAL_CODE),
			mergeMap(({ action, payload }) =>
				this.api.updateReferralCode(payload.newCode).pipe(
					mergeMap(({ payload: updateReferrals }) => [
						new userActions.UpdateReferralCodeSuccess({
							updateReferrals: updateReferrals,
						}),
					]),
					catchError(
						handleFailedRequest(
							new userActions.UpdateReferralCodeFailed({
								msg: 'can\'t update referral code',
								action,
							})
						)
					)
				)
			)
		)
	);

	referralBankAccountUpdate$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<userActions.UpdateReferralBankAccount>(UserActionsConstants.UPDATE_REFERRAL_BANK_ACCOUNT),
			mergeMap(({ action, payload }) =>
				this.api.updateReferralBankAccount(payload.id).pipe(
					mergeMap(({ payload: updateReferrals }) => [
						new userActions.UpdateReferralBankAccountSuccess({
							updateReferrals: updateReferrals,
						}),
					]),
					catchError(
						handleFailedRequest(
							new userActions.UpdateReferralBankAccountFailed({
								msg: 'can\'t update referral bank account',
								action,
							})
						)
					)
				)
			)
		)
	);
}
