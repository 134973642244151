import { EventMetadata } from '@app/models/event-metadata.model';
import { Router, NavigationEnd } from '@angular/router';

export const pushDataLayer = (metaData: EventMetadata) => {
	const dataLayer = window['dataLayer'];
	if (dataLayer !== null) {
		dataLayer.push({
			event: 'meta-data-loaded',
			EventName: metaData.name,
			EventId: metaData.id,
			UserId: metaData.userId,
			Email: metaData.email,
			FullName: metaData.fullName,
			Phone: metaData.phone,
		});
	}
};

export const pushDataLayerTracking = (router: Router) => {
	router.events.subscribe(event => {
		if (event instanceof NavigationEnd) {
			const dataLayer = window['dataLayer'];
			let angularUrl = router.url;
			const ids = angularUrl.match(/(\d+)/);
			const productId = '/{id}/';
			if (dataLayer !== null) {
				if (ids) {
					for (let i = 0; i < ids.length; i++) {
						const id = '/' + ids[i] + '/';
						angularUrl = angularUrl.replace(id, productId);
					}
				}
				dataLayer.push({
					event: 'angularPageView',
					gaPagePathOverride: angularUrl,
				});
			}
		}
	});
};
