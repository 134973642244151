import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { URLCreator } from '@app/services/url/url.dictionary';

@Component({
	selector: 'app-submit-event-modal',
	templateUrl: './submit-event-modal.component.html',
	styleUrls: ['./submit-event-modal.component.sass'],
})
export class SubmitEventModalComponent {
	constructor(
		public dialogRef: MatDialogRef<SubmitEventModalComponent>,

		@Inject(MAT_DIALOG_DATA) public data: {
			isMobile: boolean;
			commission: number;
			serviceFeeForHost: boolean;
			commissionForHost: boolean;
		}
	) { }

	terms = URLCreator.terms();

	getCommission() {
		return (this.data.commission * 100).toFixed(1);
	}

	handleAction(submit: boolean) {
		this.dialogRef.close(submit);
	}
}
