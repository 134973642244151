export enum HelpArticle {
	EditInformation,
	EditTabs,
	EditTickets,
	EditCollectInfo,
	EditCustomize,
	PromoteDiscountCodes,
	PromoteLinkCampaigns,
	GuestsPromotes,
	GuestsOrders,
	IntegrateWidget,
	IntegrateWebHooks,
	OnTheDayScanners,
	EditMerchandise,
	UsersTrackingScripts,
	AccountUsersmailAccount,
	GuestsPreregistrations,
	IntegrateSellOnFacebook,
	GuestsGuestList,
	GuestsSmsYourGuests,
	PromoteShareAndSave,
	EditPaymentMethod,
	EditSettings,
	EditPayoutDetails,
	ReportsVisitorsReport,
	GuestsAcl,
	ReportsFinancial,
	GuestsInvitations,
	GuestsComplimentaries,
	EditDesign,
	TicketGroupAdded,
	GuestsTeams,
	IntegrateMailChimp,
	EditTicketGroups,
}
