import { schema } from 'normalizr';
import { EventTicketsDetails } from '@app/models/ticket.model';
import { EventEntity } from '@app/models/event.model';

export const ticketScheme = new schema.Entity(
	'ticket'
);

export const arrayOfTicketScheme = new schema.Array(ticketScheme);

export const ticketsDescScheme = new schema.Entity(
	'ticketsDetails',
	{
		tickets: arrayOfTicketScheme,
	},
	{
		idAttribute: (value: EventTicketsDetails, parent: EventEntity) => parent.details.eventId.toString(),
	}
);
