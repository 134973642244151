<div class="l-container" [class.l-no-shadow]="!boxShadow">
	<p class="l-text">
		{{ cardText }}
	</p>
	<div class="l-content">
		<div 
			class="l-body" 
			[ngClass]="{
				'l-body-disabled': !isEditing,
				'l-body-enabled': isEditing,
				'l-body-mobile': isMobile,
				'l-body-web': !isMobile 
			}"
		>
			<span class="l-icon">
				<mat-icon>{{ icon }}</mat-icon>
			</span>
			<span *ngIf="preText">{{ preText }}</span>
			<input type="text" class="l-input-link" [class.fw-bold]="isEditing" [(ngModel)]="newLink"
				[disabled]="!isEditing" [attr.maxLength]="maxLengthLink ? maxLengthLink : null">
		</div>

		<div class="l-button-container">
			<button class="g-primary-button w-100 flex-grow-1"
				[ngClass]="{ disabled: isButtonDisabled, 'w-100': isMobile }"
				[disabled]="isButtonDisabled"
				(click)="isEditing ? handleSecondaryClick() : handlePrimaryClick()">
				<div *ngIf="!isLoading else showLoading">
					{{ isEditing ? 'SAVE LINK' : primaryButtonText }}
				</div>
				<ng-template #showLoading><mat-spinner class='g-button-spinner'></mat-spinner></ng-template>
			</button>

			<div *ngIf="canEdit && link" class="l-edit-container" (click)="handleToggleEdit()"
				[ngClass]="{'l-end-editing': isEditing, 'l-start-editing': !isEditing}">
				<mat-icon [ngClass]="{'l-end-editing-icon': isEditing, 'l-start-editing-icon': !isEditing}">
					{{ isEditing ? 'close' : 'create' }}
				</mat-icon>
			</div>
		</div>
	</div>
</div>
