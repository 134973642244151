<mat-drawer-container
	class="l-page-container"
	autosize
>
	<mat-drawer
		#drawer
		[position]="'start'"
		class="g-mobile-sidenav"
		mode="over"
	>
		<app-mobile-sidenav [currentRoute]="currentRoute.title"></app-mobile-sidenav>
	</mat-drawer>
	<app-my-account-header
		[isMobile]='isMobile'
		isParent="true"
		pageName="My Account"
		(navigateBack)="drawer.toggle()"
		class="l-mobile-header"
	>
	</app-my-account-header>
	<div
		class="g-account-container"
		[ngClass]="{ 'l-mobile-container': isMobile, 'l-web-container': !isMobile }"
	>
		<div
			class="g-account-content h-100"
			[ngClass]="{ 'l-mobile-content': isMobile, 'l-web-content': !isMobile }"
		>
			<app-web-content-header
				*ngIf="!isMobile"
				[headerContent]="headerContent"
				[showBreadCrumb]="false"
				class="w-100"
			></app-web-content-header>
			<div
				class="l-cards-container"
				[ngClass]="{ 'mobile': isMobile, 'web': !isMobile }"
			>
				<a
					*ngFor="let card of cardsData | keyvalue: handleDefaultOrder"
					[routerLink]="[card.value.routeTo()]"
					[ngClass]="{'l-card': !isMobile }"
					class="g-pointer g-flat-link"
				>
					<ng-container *ngIf="!isMobile; else showMobile">
						<app-section-route-card
							class="l-card-content"
							[card]="card.value"
						></app-section-route-card>
					</ng-container>
					<ng-template #showMobile>
						<app-mobile-section-route-card
							[icon]="card.value.iconPath"
							[text]="card.value.title"
						></app-mobile-section-route-card>
					</ng-template>
				</a>
			</div>
			<div
				id='deactivate-modal-open'
				class="l-delete g-pointer"
				(click)="handleDeleteAccount()"
			>
				Need to deactivate your account?
			</div>
		</div>
	</div>
</mat-drawer-container>