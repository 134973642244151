
<div [ngClass]="{ 'input-group-mobile-large': isMobile, 'input-group-web-large': !isMobile }">
	<label class="g-form-label">
		<span [innerHTML]="label"></span>
		<span *ngIf='isRequired' class="g-orange"> *</span>
	</label>
	<textarea id="text-area-input" [formControl]="inputFormControl" class="form-control mh-100 l-textarea"></textarea>
	<div *ngIf="inputFormControl.invalid">
		<div class="g-error-input">
			<div *ngIf="inputFormControl.hasError('required')">This field is required.</div>
		</div>
	</div>
</div>
