import { HostListener, Injectable, Directive } from '@angular/core';
import { Subscription, Observable, Subject, of } from 'rxjs';
import { CanDeactivate } from '@angular/router';
import { switchMap } from 'rxjs/operators';


@Directive()
export abstract class ComponentCanDeactivate {
	intentionSub: Subscription;
	abstract canDeactivate(): Observable<boolean> | boolean;

	@HostListener('window:beforeunload', ['$event'])
	unloadNotification($event: BeforeUnloadEvent) {
		const intention = this.canDeactivate();
		if (intention instanceof Observable) {
			if (this.intentionSub) {
				this.intentionSub.unsubscribe();
			}
			this.intentionSub = intention.subscribe(v => {
				if (!v) {
					$event.returnValue = true;
				}
			});
		} else {
			if (!intention) {
				$event.returnValue = true;
			}
		}
	}
}

@Directive()
export abstract class FormCanDeactivate extends ComponentCanDeactivate {
	modalCallback = new Subject<boolean>();
	isDeactivationModalOpen = false;
	abstract isFormDeactivatable(): Observable<boolean> | boolean;
	abstract deactivationFallback(): Observable<boolean>;
	abstract closeDeactivationModal(isConfirmed: boolean): void;

	canDeactivate() {
		return this.isFormDeactivatable();
	}
}

@Injectable()
export class CanDeactivateUserPage implements CanDeactivate<FormCanDeactivate> {

	canDeactivate(component: FormCanDeactivate) {
		const intention = component.canDeactivate();

		if (intention instanceof Observable) {
			return intention
				.pipe(
					switchMap(v => v ? of(true) : component.deactivationFallback())
				);
		}

		if (!intention) {
			return component.deactivationFallback();
		}

		return true;
	}
}
