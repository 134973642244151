import { Component, Input } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
	selector: 'app-missing-permissions',
	templateUrl: './missing-permissions.component.html',
	styleUrls: ['./missing-permissions.component.sass'],
})
export class MissingPermissionsComponent {
	@Input() permissions: { title: string; access: string }[];
	@Input() currentPageHeading: string;

	options: AnimationOptions = {
		path: './assets/lotties/access-denied.json',
		loop: false,
	};
}
