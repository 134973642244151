import { Injectable } from '@angular/core';
import { cloneDeep, omit, capitalize } from 'lodash';
import * as moment from 'moment';
import * as deepEqual from 'fast-deep-equal';
import { EventDetails } from '@app/models/event.model';
import { DatesService } from '../dates/dates.service';
import { UntypedFormControl, UntypedFormGroup, UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { FormService, ValidatorsConfig, FormConfig } from '@app/services/form/form.service';
import { requiredValidator, conditionalPipe, isDateAfter, isDateBefore } from '@app/shared/form-field/form-validators';
import { AddingScheduleItem,
	EventRepeats,
	ByDayOfWeek,
	DaysOfWeek,
	ScheduleItem,
	Schedule} from '@app/models/schedule.model';
import { SelectFieldOption } from '@app/shared/form-field/select-field/select-field.model';

@Injectable()
export class ScheduleService {
	constructor(
		private datesService: DatesService,
		private formService: FormService
	) { }

	createScheduleForm(initialValues?: Partial<AddingScheduleItem>) {
		const formValues = new AddingScheduleItem();
		const validatorsConfig: ValidatorsConfig<AddingScheduleItem> = {
			repeats: [requiredValidator()],
			timeEndingDay: [requiredValidator()],
		};

		const formConfig: FormConfig<AddingScheduleItem> =
			this.formService.createFormControls({ formValues, initialValues, validatorsConfig });

		const dynamicValidators: ValidatorsConfig<AddingScheduleItem> = {
			endDate: [
				conditionalPipe(
					((repeats: UntypedFormControl) => repeats.value !== 'SINGLE_DATE').bind(null, formConfig.repeats),
					requiredValidator('Please select end date'),
					isDateAfter(formConfig.startDate)
				),
			],
			startDate: [
				requiredValidator('Please select start date'),
				conditionalPipe(
					((repeats: UntypedFormControl) => repeats.value !== 'SINGLE_DATE').bind(null, formConfig.repeats),
					isDateBefore(formConfig.endDate),
					isDateAfter(
						new UntypedFormControl(moment().toDate()),
						'Start date can\'t be in the past',
						value => {
							const startTime = moment(formConfig.startTime.value);
							return moment(value).hour(startTime.hour()).minute(startTime.minute()).toDate();
						}
					)
				),
				conditionalPipe(
					((repeats: UntypedFormControl) => repeats.value === 'SINGLE_DATE').bind(null, formConfig.repeats),
					isDateAfter(
						new UntypedFormControl(moment().toDate()),
						'Start date can\'t be in the past',
						value => {
							const startTime = moment(formConfig.startTime.value);
							return moment(value).hour(startTime.hour()).minute(startTime.minute()).toDate();
						}
					)
				),
			],
			startTime: [
				requiredValidator('Please select a start time'),
				conditionalPipe(
					((timeEndingDay: UntypedFormControl) => timeEndingDay.value === 0).bind(null, formConfig.timeEndingDay),
					isDateBefore(formConfig.endTime, 'The time entered must be before the end time.')
				),
			],
			endTime: [
				requiredValidator('Please select an end time'),
				conditionalPipe(
					((timeEndingDay: UntypedFormControl) => timeEndingDay.value === 0).bind(null, formConfig.timeEndingDay),
					isDateAfter(formConfig.startTime, 'The time entered must be after the start time.')
				),
			],
			/* eslint-disable max-len */
			weeklyRepeat: [
				conditionalPipe(((isMonthlyByDayOfMonth: UntypedFormControl, repeats: UntypedFormControl) => (!isMonthlyByDayOfMonth.value && repeats.value === 'MONTHLY') || repeats.value === 'WEEKLY').bind(null, formConfig.isMonthlyByDayOfMonth, formConfig.repeats), requiredValidator()),
			],
			monthlyDayOfMonth: [
				conditionalPipe(((isMonthlyByDayOfMonth: UntypedFormControl, repeats: UntypedFormControl) => isMonthlyByDayOfMonth.value && repeats.value === 'MONTHLY').bind(null, formConfig.isMonthlyByDayOfMonth, formConfig.repeats), requiredValidator()),
			],
			monthlyDayOfWeek: [
				conditionalPipe(((isMonthlyByDayOfMonth: UntypedFormControl, repeats: UntypedFormControl) => !isMonthlyByDayOfMonth.value && repeats.value === 'MONTHLY').bind(null, formConfig.isMonthlyByDayOfMonth, formConfig.repeats), requiredValidator()),
			],
			/* eslint-enable max-len */
		};

		for (const key in dynamicValidators) {
			if (dynamicValidators.hasOwnProperty(key)) {
				(formConfig[key] as UntypedFormControl).setValidators(dynamicValidators[key]);
			}
		}

		return {
			form: this.formService.formBuilder.group(formConfig),
			dynamicValidatorsKeys: Object.keys(dynamicValidators).map(key => key),
		};
	}

	listScheduleForm(scheduleItems: ScheduleItem[], schedule: Schedule) {
		const formConfig = {
			hidden: schedule.hidden,
			hasInUseTickets: schedule.hasInUseTickets,
			scheduleItems: this.formService.formBuilder.array([]),
			id: schedule.id,
			deleted: schedule.deleted,
		};

		scheduleItems.forEach(d => {
			formConfig.scheduleItems.push(this.formService.formBuilder.group({
				id: d.id,
				name: d.name,
				startDate: d.startDate,
				endDate: d.endDate,
				hidden: d.hidden,
				hasInUseTickets: d.hasInUseTickets,
				deleted: d.deleted,
				status: d.status,
			}));
		});

		return this.formService.formBuilder.group(formConfig);
	}

	getTimeEndingDate = () => ([
		{
			value: 0,
			label: 'Same day',
		},
		{
			value: 1,
			label: 'Next day',
		},
		{
			value: 2,
			label: '2nd day',
		},
		{
			value: 3,
			label: '3rd day',
		},
		{
			value: 4,
			label: '4th day',
		},
		{
			value: 5,
			label: '5th day',
		},
		{
			value: 6,
			label: '6th day',
		},
	]);

	getDaySequence(): { value: number; label: string }[] {
		const monthlyDayOfMonthOptions = [];
		for (let i = 0; i < 31; i++) {
			monthlyDayOfMonthOptions.push({
				value: i + 1,
				label: `${i + 1}th`,
			});
		}

		monthlyDayOfMonthOptions[0].label = '1st';
		monthlyDayOfMonthOptions[1].label = '2nd';
		monthlyDayOfMonthOptions[2].label = '3rd';

		return monthlyDayOfMonthOptions;
	}

	areSchedulesEqual(firstSc, secondSc) {
		let firstCopy = cloneDeep(firstSc);
		let secondCopy = cloneDeep(secondSc);

		const formatDate = (date: Date, time: Date) =>
			moment(date)
				.hours(moment(time).hours())
				.minutes(moment(time).minutes())
				.format('MM/DD/YYYY HH:mm');

		firstCopy.startDateTime = formatDate(firstCopy.startDate, firstCopy.startTime);
		secondCopy.startDateTime = formatDate(secondCopy.startDate, secondCopy.startTime);
		secondCopy.endDateTime = formatDate(secondCopy.endDate, secondCopy.endTime);
		firstCopy.endDateTime = formatDate(firstCopy.endDate, firstCopy.endTime);

		firstCopy = omit(firstCopy, ['id', 'startTime', 'startDate', 'endDate', 'endTime']);
		secondCopy = omit(secondCopy, ['id', 'startTime', 'startDate', 'endDate', 'endTime']);

		return deepEqual(firstCopy, secondCopy) && firstSc.id !== secondSc.id;
	}

	getScheduleDesc(scheduleItem: ScheduleItem[]) {
		const allDates = [];
		let firstLastDates = [];
		let startDate = new Date();
		let endDate = new Date();


		scheduleItem.map(item => {
			startDate = item.startDate;
			endDate = item.endDate;
			const startDateFormatted = moment(item.startDate).format('YYYY-MM-DD HH:mm:ss Z');
			const endDateFormmated = moment(item.endDate).format('YYYY-MM-DD HH:mm:ss Z');
			allDates.push(startDateFormatted, endDateFormmated);
			firstLastDates = allDates.sort();
		});
		startDate = firstLastDates[0];
		endDate = firstLastDates[firstLastDates.length - 1];

		const datesLabel = `Starting ${moment(startDate).format('Do MMM YYYY')}
			 at ${moment(startDate).format('LT')}
			 through ${moment(endDate).format('Do MMM YYYY')}
			 at ${moment(endDate).format('LT')}`;

		return datesLabel;
	}

	getDatesByWeeklyRepeats(dates: Date[], daysOfWeek: string[], isArrayBased?: boolean): any {
		const datesByDayName = {};

		dates.forEach(d => {
			const dayName = moment(d).format('dddd').toUpperCase();
			if (daysOfWeek.includes(dayName)) {
				if (datesByDayName[dayName]) {
					datesByDayName[dayName].push(d);
				} else {
					datesByDayName[dayName] = [d];
				}
			}
		});

		if (isArrayBased) {
			return Object.keys(datesByDayName).map(key => datesByDayName[key]);
		} else {
			return datesByDayName;
		}

	}

	getDatesByMonthlyRepeats(dates: Date[], daysOfWeek, isMonthlyByDayOfMonth, monthlyDayOfMonth, monthlyDayOfWeek): Date[] {

		const monthsStartDays = [];
		const allMonths = [];
		let scheduledDates = [];
		const startDate = dates[0];
		const endDate = dates[dates.length - 1];
		const dayConstants = {
			[ByDayOfWeek.FIRST]: 0,
			[ByDayOfWeek.SECOND]: 1,
			[ByDayOfWeek.THIRD]: 2,
			[ByDayOfWeek.FOURTH]: 3,
		};

		dates.forEach(d => {
			const isMonthAdded = !!monthsStartDays.find(el => el.getTime() === moment(d).startOf('month').toDate().getTime());

			if (!isMonthAdded) {
				monthsStartDays.push(moment(d).startOf('month').toDate());
			}
		});

		for (let i = 0; i < monthsStartDays.length; i++) {
			allMonths.push(this.datesService.getDates(monthsStartDays[i], moment(monthsStartDays[i]).endOf('month').toDate()));
		}

		if (isMonthlyByDayOfMonth) {
			allMonths.forEach(el => {
				if (moment(el[monthlyDayOfMonth - 1]).isBetween(startDate, endDate, null, '[]')) {
					scheduledDates.push(el[monthlyDayOfMonth - 1]);
				}
			});
		} else {
			allMonths.forEach(el => {

				const filteredDates = this.getDatesByWeeklyRepeats(el, daysOfWeek);

				Object.keys(filteredDates).map(dKey => {
					const dArr = filteredDates[dKey];
					const dayIndex =  monthlyDayOfWeek === ByDayOfWeek.LAST ? dArr.length - 1 : dayConstants[monthlyDayOfWeek];
					const selectedDate = dArr[dayIndex];
					if (moment(selectedDate).isBetween(startDate, endDate, null, '[]')) {
						if (scheduledDates[dKey]) {
							scheduledDates[dKey].push(dArr[dayIndex]);
						} else {
							scheduledDates[dKey] = [dArr[dayIndex]];
						}
					}
				});
			});

			scheduledDates = Object.keys(scheduledDates).map((dKey, i) =>
				scheduledDates[i] = scheduledDates[dKey]);
		}

		return scheduledDates;
	}

	checkAndUpdateScheduleIds(savedSchedules: Schedule[], form: UntypedFormGroup, pathToSchedules = 'schedules') {
		const formBuilder = new UntypedFormBuilder();
		form.removeControl(pathToSchedules);
		form.addControl(pathToSchedules, formBuilder.array([]));
		savedSchedules.forEach(schedule => {
			(
				form.get(pathToSchedules) as UntypedFormArray).push(this.listScheduleForm(schedule.scheduleItems, schedule));
		});
	}


	checkAndUpdateScheduleDates(savedForm: EventDetails, form: UntypedFormGroup) {
		if (savedForm && savedForm.schedules && savedForm.schedules.length) {
			form.get('startDateTime').setValue(savedForm.startDateTime);
			form.get('endDateTime').setValue(savedForm.endDateTime);
		}
	}

	getEventRepeatsOptions = (): SelectFieldOption<string>[] => (
		Object.keys(EventRepeats).map(el => ({
			value: el,
			label: capitalize(EventRepeats[el]).replace('_', ' '),
		}))
	);

	getByDayOfWeekOptions = (): SelectFieldOption<string>[] => (
		Object.keys(ByDayOfWeek).map(el => ({
			value: ByDayOfWeek[el],
			label: capitalize(ByDayOfWeek[el]),
		})
		)
	);

	getDaysOfWeekOptions = (): SelectFieldOption<string>[] => (
		Object.keys(DaysOfWeek).map(el => ({
			value: el,
			label: capitalize(el),
		}))
	);
}
