<div
	class="l-container"
	[ngClass]="{'mobile': data.isMobile}"
>
	<h3 class="mb-3 text-center"> Resend tickets via email </h3>
	<div class="l-input">
		<app-mat-text-control-field
			label="Email address"
			[control]="emailControl"
			placeholder="Email"
			customId="delete-confirmation-input"
			required="true"
		></app-mat-text-control-field>
	</div>
	<div class="l-buttons-container" [ngClass]="{'mobile': data.isMobile}">
		<button
			id="cancel-button"
			class="g-secondary-button"
			(click)="handleCancel()"
		> CANCEL </button>
		<button
			id="resend-tickets-button"
			[disabled]="emailControl.invalid"
			[ngClass]="{'disabled': emailControl.invalid}"
			class="g-primary-button"
			(click)="handleSendTickets()"
		> RESEND TICKETS </button>
	</div>
</div>