import { SelectFieldOption } from '@app/shared/form-field/select-field/select-field.model';

export interface OptionGroupItem<K = string> {
	title: string;
	key: K;
}

export class DateRangeFilter {
	from: Date = new Date();
	to: Date = new Date();
	filterType: BreakdownRangeFilterRange = BreakdownRangeFilterRange.ALL_TIME;
}

export enum BreakdownRangeFilterRange {
	DAYS_7,
	DAYS_14,
	DAYS_30,
	DAYS_60,
	ALL_TIME,
	CUSTOM,
}

export class BreakdownRangeFilterOption implements OptionGroupItem<BreakdownRangeFilterRange> {
	title: string;
	key: BreakdownRangeFilterRange;
	range?: number;
}

export interface BreakdownRangeFilterPreset {
	[key: string]: BreakdownRangeFilterOption;
}

export const BreakdownRangeFilterPresets: BreakdownRangeFilterPreset = {
	[BreakdownRangeFilterRange.DAYS_7]: {
		title: '7 Days',
		key: BreakdownRangeFilterRange.DAYS_7,
		range: 7,
	}, [BreakdownRangeFilterRange.DAYS_14]: {
		title: '14 Days',
		key: BreakdownRangeFilterRange.DAYS_14,
		range: 14,
	}, [BreakdownRangeFilterRange.DAYS_30]: {
		title: '30 Days',
		key: BreakdownRangeFilterRange.DAYS_30,
		range: 30,
	}, [BreakdownRangeFilterRange.DAYS_60]: {
		title: '60 Days',
		key: BreakdownRangeFilterRange.DAYS_60,
		range: 60,
	}, [BreakdownRangeFilterRange.ALL_TIME]: {
		title: 'All time',
		key: BreakdownRangeFilterRange.ALL_TIME,
	}, [BreakdownRangeFilterRange.CUSTOM]: {
		title: 'Custom',
		key: BreakdownRangeFilterRange.CUSTOM,
	},
};

export const BreakdownRangeFilterOptions: SelectFieldOption[] = Object
	.values(BreakdownRangeFilterPresets)
	.map(({ key, title }) => ({
		value: key,
		label: title,
	}));
