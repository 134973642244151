<div class="l-container" [ngClass]="{ 'mobile': isMobile }">
	<div class="mb-3">
		<app-web-content-header
			[headerContent]="headerContent"
			[mediumWidth]='true'
			[showBreadCrumb]="!isMobile"
			[showTitle]="!isMobile"
		>
		</app-web-content-header>
	</div>
	<app-guest-list-table [eventId]="eventId" [isMobile]="isMobile">
	</app-guest-list-table>
</div>
