import { Component, Inject } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { requiredValidator } from '@app/shared/form-field/form-validators';

@Component({
	selector: 'app-input-modal',
	templateUrl: './input-modal.component.html',
	styleUrls: ['./input-modal.component.sass'],
})
export class InputModalComponent {
	inputFormControl: UntypedFormControl;

	constructor(public dialogRef: MatDialogRef<InputModalComponent>,
		@Inject(MAT_DIALOG_DATA)
		public data: { title: string; text: string; buttonText: string;  centerText: boolean; inputText: string}) {}

	ngOnInit(){
		this.inputFormControl = new FormControl('', [requiredValidator('Required')]);
	}

	handlePrimary() {
		this.dialogRef.close(this.inputFormControl.value);
	}

	handleCancel() {
		this.dialogRef.close('');
	}

}
