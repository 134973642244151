import { Component, EventEmitter, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { FilterOptions, HeaderContent } from '@app/models/shared';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { StoreService } from '@app/services/store/store.service';
import { InternalURLCreator } from '@app/services/url/url.dictionary';
import { ConfirmationModalComponent } from '@app/shared/modals/confirmation-modal/confirmation-modal.component';
import { EventActionsConstants } from '@app/store/actions/event/event.actions.constants';
import { Actions, ofType } from '@ngrx/effects';
import { take, takeUntil } from 'rxjs';
import * as eventSelectors from '@app/store/selectors/event.selector';
import { DeleteEventLinkCampaign, GetEventLinkCampaigns } from '@app/store/actions/event/event.actions';
import { EventLinkCampaign } from '@app/models/event.model';
import { ProductType } from '@app/models/product.model';
import MoneyPresenter from '@app/utils/MoneyPresenter';
import { Clipboard } from '@angular/cdk/clipboard';
import { AddNotification } from '@app/store/actions/notification/notification.actions';
import { NotificationType } from '@app/models/notification.model';
import { AddLinkCampaignModalComponent } from '../../modals/add-link-campaign-modal/add-link-campaign-modal.component';
import { EVENT_ID_PARAM_KEY } from '@app/utils/consts';
import { FileSaverService } from '@app/services/file-saver/file-saver.service';

@Component({
	selector: 'app-link-campaigns',
	templateUrl: './link-campaigns.component.html',
	styleUrls: ['./link-campaigns.component.sass'],
})
export class LinkCampaignsComponent {
	isMobile: boolean;
	eventId: number;
	isEvent = true;

	showMore = false;
	readMoreText = 'Read More.';

	isCampaignsLoading = true;
	hasCampaigns = false;
	campaigns: MatTableDataSource<EventLinkCampaign> = new MatTableDataSource();

	totalVisits = 0;
	totalTickets = 0;
	totalQuicketTickets = 0;
	currencySymbol = 'R';

	displayColumns: string[] = ['campaignDetails', 'visits', 'sales', 'tickets', 'copy', 'delete'];
	pageSizeOptions = [4, 8, 12];

	paginatorInitialized = false;
	sortInitialized = false;

	headerContent: HeaderContent = {
		breadCrumbs: [
			{
				routeName: 'Marketing',
				routeTo: () => InternalURLCreator.marketing(this.eventId),
			},
			{
				routeName: 'Campaigns',
			},
		],
		description: `One of the key secrets to running a successful event is knowing if your marketing
		 efforts are paying off. With Quicket you can set up as many tracking links as you need to verify
		 your digital efforts.`,
		title: 'Campaigns',
	};

	linkUseExamples = [
		'Give a unique link to each promoter you use to find out how many sales they are making',
		'Use a unique link just for Twitter',
		'Use a unique link for your Facebook brand page',
		'Use a different link solely for your Facebook event page',
		'If you\'re paying for airtime on popular blogs give each of them their own links and track their individual traffic',
		'Use a unique link for your newsletters',
	];

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	@ViewChild('campaignPaginator') set paginator(_pagination: MatPaginator) {
		if (_pagination && !this.paginatorInitialized) {
			this.campaigns.paginator = _pagination;
		};
	}

	@ViewChild(MatSort) set sort(_sort: MatSort) {
		if (_sort && !this.sortInitialized) {
			this.sortInitialized = true;
			this.campaigns.sort = _sort;
			this.campaigns.sortingDataAccessor = (item, property) => {
				if (property === 'campaignDetails') {
					return item.name;
				}
				return item[property];
			};
		}
	};

	constructor(
		private breakpointService: BreakpointService,
		private store: StoreService,
		private activatedRoute: ActivatedRoute,
		private actions$: Actions,
		private dialog: MatDialog,
		private clipboard: Clipboard,
		private fileSaverService: FileSaverService
	) { }

	ngOnInit(): void {
		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});

		this.activatedRoute.parent.paramMap.pipe(take(1)).subscribe((params) => {
			this.eventId = +params.get(EVENT_ID_PARAM_KEY);
			this.store.select(eventSelectors.eventMetadata(this.eventId)).pipe(take(1))
				.subscribe((metaData) => {
					this.isEvent = (metaData && metaData.productType === ProductType.Event);
					this.currencySymbol = this.getCurrencySymbol(metaData.currencyISO);
				});
			this.initialiseLinkCampaign();
		});
	}


	initialiseLinkCampaign() {
		this.store.dispatch(new GetEventLinkCampaigns({ eventId: this.eventId }));

		this.actions$
			.pipe(
				ofType(EventActionsConstants.GET_EVENT_LINK_CAMPAIGNS_SUCCESS,
					EventActionsConstants.GET_EVENT_LINK_CAMPAIGNS_FAILED),
				takeUntil(this.destroyed$)
			)
			.subscribe(() => {
				this.isCampaignsLoading = false;
			});

		this.store.select(eventSelectors.eventLinkCampaigns(this.eventId)).pipe(takeUntil(this.destroyed$))
			.subscribe((linkCampaigns) => {
				if (linkCampaigns?.length) {
					this.campaigns.data = linkCampaigns;
					this.hasCampaigns = !!linkCampaigns.length;
					const totals = linkCampaigns.reduce((acc, campaign) => {
						acc.visits += campaign.visits;
						acc.tickets += campaign.tickets;
						acc.quicketTickets += campaign.sales;
						return acc;
					  }, { visits: 0, tickets: 0, quicketTickets: 0 });

					  this.totalVisits = totals.visits;
					  this.totalTickets = totals.tickets;
					  this.totalQuicketTickets = totals.quicketTickets;
				}
			});
	}

	handleShowMore() {
		this.showMore = !this.showMore;
		this.readMoreText = this.showMore ? 'Read Less' : 'Read More';
	}

	handleCreateCampaign() {
		this.dialog.open(AddLinkCampaignModalComponent, {
			data: {
				eventId: this.eventId,
				isEvent: this.isEvent,
				isMobile: this.isMobile,
			},
			panelClass: 'g-standard-dialog',
		});
	}

	handleDelete(id: number, name: string) {
		this.dialog.open(ConfirmationModalComponent, {
			data: {
				title: 'Delete Campaign?',
				text: `Are you sure you want to delete your ${name} campaign?`,
				buttonText: 'YES',
				isMobile: this.isMobile,
			},
			panelClass: 'g-standard-dialog',
		}
		)
			.afterClosed().subscribe((result) => {
				if (result) {
					this.store.dispatch(new DeleteEventLinkCampaign({
						eventId: this.eventId,
						id,
					}));
				}
			});
	}

	handleCopy(eventLink: string, campaignUrl: string) {
		const link = eventLink + '&lc=' + campaignUrl;
		this.clipboard.copy(link);
		this.store.dispatch(new AddNotification({
			id: 'link copied',
			actionType: null,
			action: null,
			type: NotificationType.SUCCESS,
			title: 'Link successfully copied to clipboard!',
			delay: 4000,
		}));
	}

	handleLinkClick(eventLink: string, campaignUrl: string) {
		const link = eventLink + '&lc=' + campaignUrl;
		window.open(link, '_blank');
	}


	handleFilter(filterOptions: FilterOptions) {
		const { searchTerm } = filterOptions;
		this.campaigns.filter = searchTerm.trim().toLowerCase();
	}

	handleDownloadLinkCampaigns() {
		this.fileSaverService.save({
			url: `/api/product/${this.eventId}/linkcampaigns/download`,
			fileName: `${this.eventId}-quicket-event-link-campaigns.xlsx`,
		});
	}

	getCurrencySymbol = (currencyCode: string) => MoneyPresenter.getBasicSymbol(currencyCode) || 'R';

	ngOnDestroy(): void {
		this.destroyed$.next();
	}

}
