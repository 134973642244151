<div class="loading-wrap" *ngIf="loadingSp" [style.backgroundColor]="loadingBackground">
	<div class="loading-spinner">
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 283.22 283.22"
			[attr.width]="sizes[loadingSize]"
			[attr.height]="sizes[loadingSize]"
		>
			<title>Q-Spinner</title>
			<g id="Layer_2" data-name="Layer 2">
				<g id="Layer_1-2" data-name="Layer 1">
					<rect
						class="spinner-back"
						width="283.22"
						height="283.22"
					/>
					<path
						[ngClass]="getCubeClass()"
						d="
						M227,215.51
						a36,36,0,0,1-10.5,4.23
						c-7.1,1.6-14.82.51-23.11-2.51
						a136.49,136.49,0,0,1-16.81-7.77
						A91,91,0,1,0,132,221.14
						a27.93,27.93,0,0,1,9.71,2.23
						c4,1.52,8.37,3.61,12.89,5.85,9.09,4.48,18.86,9.65,30.16,13.33
						a79.8,79.8,0,0,0,18.16,3.76,59,59,0,0,0,19.64-1.63,61.43,61.43,0,0,0,17.84-7.92,87.22,87.22,0,0,0,14-11.81
						L236.87,207.7
						A62.55,62.55,0,0,1,227,215.51Z
							M69.81,130.08A62.19,62.19,0,1,1,132,192.27,62.26,62.26,0,0,1,69.81,130.08Z"
					>
					</path>
				</g>
			</g>
		</svg>
		<span class="loading-text" *ngIf="loadingText">{{loadingText}}</span>
	</div>
</div>
<ng-content></ng-content>