import { NgModule } from '@angular/core';
import { VenuesDialogComponent } from './event-details-form/venues-dialog/venues-dialog.component';
import { ChartDialogComponent } from './event-tickets-form/chart-dialog/chart-dialog.component';
import { ScheduleDialogComponent } from './event-details-form/schedule-dialog/schedule-dialog.component';
import { EventLeavingConfirmationComponent } from './leaving-confirmation/leaving-confirmation.component';
import { CurrencyDialogComponent } from './event-tickets-form/currency-dialog/currency-dialog.component';
import { TimezoneDialogComponent } from './event-details-form/timezone-dialog/timezone-dialog.component';
import { EventDetailsFormComponent } from './event-details-form/event-details-form.component';
import { EventProfileFormComponent } from './event-profile-form/event-profile-form.component';
import { EventTicketsFormComponent } from './event-tickets-form/event-tickets-form.component';
import { SharedModule } from '@app/shared/shared.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MapsService } from '@app/services/maps/maps.service';
import { ChartSelectionComponent } from '@app/features/event-shared/event-tickets-form/chart-selecton/chart-selection.component';
import { EditorDialogComponent } from './event-details-form/editor-dialog/editor-dialog.component';
import { ChartEditingConfirmationComponent } from './event-tickets-form/chart-editing-confirmation/chart-editing-confirmation.component';
import { QuantityWarningDialogComponent } from './event-tickets-form/quantity-warning-dialog/quantity-warning-dialog.component';
import { BankSelectionComponent } from './bank-selection/bank-selection.component';
import { BankSelectionService } from './bank-selection/bank-selection.service';
import { NewBankAccountModalComponent } from './bank-selection/new-bank-account-modal/new-bank-account-modal.component';
import { SalesDateWarningDialogComponent } from './event-details-form/sales-date-warning-dialog/sales-date-warning.component';
import { LinkedProductsModalComponent } from './linked-products-modal/linked-products-modal.component';
import { ScheduleSelectionComponent } from '../control-panel/shared/schedule-selection-dropdown/schedule-selection.component';


@NgModule({
	declarations: [
		VenuesDialogComponent,
		ScheduleDialogComponent,
		EventLeavingConfirmationComponent,
		EditorDialogComponent,
		TimezoneDialogComponent,
		ChartDialogComponent,
		CurrencyDialogComponent,
		EventDetailsFormComponent,
		EventTicketsFormComponent,
		EventProfileFormComponent,
		ChartSelectionComponent,
		ChartEditingConfirmationComponent,
		QuantityWarningDialogComponent,
		BankSelectionComponent,
		NewBankAccountModalComponent,
		SalesDateWarningDialogComponent,
		LinkedProductsModalComponent,
		ScheduleSelectionComponent,
	],
	imports: [
		SharedModule,
		DragDropModule,
	],
	exports: [
		EventDetailsFormComponent,
		EventTicketsFormComponent,
		EventProfileFormComponent,
		EventLeavingConfirmationComponent,
		LinkedProductsModalComponent,
		ScheduleSelectionComponent,
	],
	providers: [
		MapsService,
		BankSelectionService,
	],
})
export class EventSharedModule {}
