import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { FormField } from '../form-field.model';

export enum ImageCropperType {
	BANNER = 0,
	LOGO = 1,
}

export interface RatioParam {
	ratio: number;
	minWidth: number;
	resizeToWidth: number;
}

export interface RationParamMap {
	[key: number]: RatioParam;
}

@Component({
	selector: 'app-image-cropper',
	templateUrl: './image-cropper.component.html',
	styleUrls: [
		'./image-cropper.component.sass',
	],
})

export class ImageCroppperFieldComponent extends FormField {
	static readonly RationParams: RationParamMap = {
		[ImageCropperType.BANNER]: {
			ratio: 3 / 1,
			minWidth: 600,
			resizeToWidth: null,
		},
		[ImageCropperType.LOGO]: {
			ratio: 1 / 1,
			minWidth: 40,
			resizeToWidth: null,
		},
	};
	@Input() type: ImageCropperType = ImageCropperType.BANNER;
	@Input() imageBase64: string;
	@Input() maintainAspectRatio = true;
	@Input() onlyScaleDown = true;
	@Input() outputType = 'base64';
	@Input() format = 'jpeg';
	@Input() roundCropper = false;
	@Output() imageCropped = new EventEmitter<ImageCroppedEvent>();
	@Output() imageLoaded = new EventEmitter();
	@Output() loadImageFailed = new EventEmitter();

	get RatioParams() {
		return ImageCroppperFieldComponent.RationParams[this.type];
	}
}
