<div class="l-container" [ngClass]="{'mobile': isMobile}">
	<div *ngIf="!isLoading; else showLoading">
		<h1 class="l-content">
			Webhook Test
		</h1>
		<p class="l-content">
			Response code received from server: {{testResponse}}
		</p>
		<div class="l-button">
			<button class="g-primary-button " (click)="handleCancel()">CLOSE</button>
		</div>
	</div>
	<ng-template #showLoading>
		<div class="l-loading">
			<mat-spinner></mat-spinner>
		</div>
	</ng-template>
</div>