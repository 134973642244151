<div class="l-container">
	<div *ngIf="!isMobile; else mobileHeader">
		<app-header class='l-web-header'></app-header>
		<app-side-nav [currentNavItemTitle]='currentNavItem.title' class='l-side-nav'></app-side-nav>
	</div>
	<ng-template #mobileHeader>
		<app-organiser-mobile-header [currentNavItem]='currentNavItem'
			[currentSubNavItem]='currentSubNavItem' [isSecondaryPage]='isSecondaryPage' class="l-mobile-header">
		</app-organiser-mobile-header>
	</ng-template>
	<div [ngClass]="{'l-mobile-content': isMobile, 'l-web-content': !isMobile}">
		<router-outlet></router-outlet>
	</div>
</div>
