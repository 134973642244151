import { Injectable } from '@angular/core';
import { ChartOptions, ChartConfiguration } from 'chart.js';
import { ReportKeyedItem } from '@app/models/report.model';
import { SchemeID } from '@app/models/dataSchema.model';
import { ChartInput, ChartInputValue, ReportByTicketType, TicketTypeInputValue } from '@app/models/organiser.model';
import { TicketType } from '@app/models/event.model';
import {
	BreakdownRangeFilterRange,
	DateRangeFilter
} from '@app/features/events/dashboard/components/overview-reports-filters/overview-reports-filters.config';
import moment from 'moment';

@Injectable()
export class ChartService {
	constructor() {}

	defaultChartOptions: ChartOptions = {
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			y: {
				beginAtZero: true,
				ticks: {},
			},
		},
	};

	generateChartDataset({
		collection,
		chartLabel,
		xAxisItemFormat,
		color,
		subColor,
		isMultiYear,
	}: {
		collection: ReportKeyedItem[];
		chartLabel: string;
		xAxisItemFormat: (key: SchemeID, isMultiYear: boolean) => string;
		color: string;
		subColor: string;
		isMultiYear?: boolean;
	}): ChartConfiguration<'bar'>['data'] {
		return {
			labels: collection.map((el) => xAxisItemFormat(el.key, isMultiYear)),
			datasets: [
				{
					label: chartLabel,
					backgroundColor: color,
					hoverBackgroundColor: subColor,
					data: collection.map((el) => el.value),
				},
			],
		};
	}

	setCustomYAxesLabel(cl: (value: number, index?: number) => string): ChartOptions {
		return {
			responsive: true,
			maintainAspectRatio: false,
			scales: {
				y: {
					beginAtZero: true,
					ticks: {
						callback: cl,
					},
				},
				/* xAxes: [{
					barPercentage: 1,
				}], */
			},
		};
	}

	createByTicketTypeGraphData(
		reportByTicketType: ReportByTicketType[],
		dateRange: DateRangeFilter,
		selectedTicketTypes: TicketType[]
	): { ticketsSold: ChartInput[]; income: ChartInput[] } {
		const ticketsSold: ChartInput[] = [];
		const income: ChartInput[] = [];
		const ticketTypeIds = new Set(selectedTicketTypes.map((type) => type.id));

		const dataMap = new Map<string, TicketTypeInputValue[]>();

		reportByTicketType.forEach((data) => {
			const filteredSeries = selectedTicketTypes.length ? data.series.filter((item) => ticketTypeIds.has(+item.id)) : data.series;
			if (filteredSeries.length > 0) {
				dataMap.set(data.name, filteredSeries);
			}
		});

		for (let currentDate = new Date(dateRange.from); currentDate <= dateRange.to; currentDate.setDate(currentDate.getDate() + 1)) {
			const formattedDate = moment(currentDate).format('YYYY-MM-DD');
			const series = dataMap.get(formattedDate) || [];

			const ticketsSoldSeries = series.map((item) => ({ name: item.name, value: +item.ticketsSold, id: item.id }));

			const incomeSeries = series.map((item) => ({ name: item.name, value: +item.income, id: item.id }));

			ticketsSold.push({ name: formattedDate, series: ticketsSoldSeries });
			income.push({ name: formattedDate, series: incomeSeries });
		}

		return { ticketsSold, income };
	}

	createPageVisitsGraphData(visits: ChartInput[], dateRange: DateRangeFilter): ChartInput[] {
		let from = new Date();
		let to = new Date();
		if (dateRange.filterType === BreakdownRangeFilterRange.ALL_TIME) {
			const { dataFrom, dataTo } = this.getDateRange(visits, new Date(), new Date());
			from = dataFrom;
			to = dataTo;
		} else {
			from = dateRange.from;
			to = dateRange.to;
		}

		const pageVisits: ChartInput[] = [];

		const dataMap = new Map<string, ChartInputValue[]>();

		visits.forEach((data) => {
			dataMap.set(data.name, data.series);
		});

		for (let currentDate = new Date(from); currentDate <= to; currentDate.setDate(currentDate.getDate() + 1)) {
			const formattedDate = moment(currentDate).format('YYYY-MM-DD');
			const series = dataMap.get(formattedDate) || [];

			const pageVisitsSeries = series.map((item) => ({ name: item.name, value: +item.value }));

			pageVisits.push({ name: formattedDate, series: pageVisitsSeries });
		}

		return pageVisits;
	}

	getDateRange(
		data: ChartInput[] | ReportByTicketType[],
		defaultStartDate: Date,
		defaultEndDate: Date
	): { dataFrom: Date; dataTo: Date } {
		let dataFrom = defaultStartDate;
		let dataTo = defaultEndDate;

		if (data.length === 1) {
			dataFrom = new Date(data[0].name);
		} else if (data.length > 1) {
			dataFrom = new Date(data[0].name);
			dataTo = new Date(data[data.length - 1].name);
		}

		dataFrom.setHours(0, 0, 0, 0);
		dataTo.setHours(23, 59, 59, 999);
		return { dataFrom, dataTo };
	}
}
