import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Question, QuestionType } from '@app/models/user.model';

@Component({
	selector: 'app-dynamic-form-fields',
	templateUrl: './dynamic-form-fields.component.html',
	styleUrls: ['./dynamic-form-fields.component.sass'],
})
export class DynamicFormFieldsComponent {
	@Input() isMobile: boolean;
	@Input() control: Question;
	@Input() editTicketFormGroup: FormGroup;
	@Input() purchaseId?: number;
	@Input() productId?: number;
	@Input() encrypt?: string;
	@Input() index?: number;
	@Input() isBuyerQuestion = false;
	@Output() questionUpdated = new EventEmitter();


	questionTypes = QuestionType;

	handleUpdatedQuestion(){
		this.questionUpdated.emit(this.control.id);
	}
}
