import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from '@app/models/store.model';
import { AppAction } from '@app/models/action.model';
import { map, distinctUntilChanged } from 'rxjs/operators';
import * as deepEqual from 'fast-deep-equal';
import { Subscription } from 'rxjs';

export namespace SCTypes {
	export type Selector<T = any> = (state: AppState) => T;
	export type Subscriber<T = any> = (value: T) => void;
	export type UnsubscribeFn = () => void;
}

@Injectable()
export class StoreService {
	constructor(
		private store$: Store<AppState>
	) { }

	connect<Sel extends SCTypes.Selector, Sub extends SCTypes.Subscriber>(
		connections: Map<Sel, Sub>,
		skipOptimization?: boolean
	) {
		const subscriptions: Subscription[] = [];

		const storeWorker = this.createStoreWorker(skipOptimization);

		for (const [selector, subscriber] of connections.entries()) {

			const subscription = this.select(selector)
				.pipe(storeWorker())
				.subscribe(subscriber);

			subscriptions.push(subscription);
		}

		return this.disconnect(subscriptions);
	}

	select = <T>(selector: SCTypes.Selector<T>) => (
		this.store$.pipe(
			select<AppState, T, T>(selector, {} as T)
		)
	);

	dispatch<T>(action: AppAction<T>) {
		this.store$.dispatch(action);
	}

	private disconnect = (subscription: Subscription[] = []) => (
		() => {
			subscription.forEach(c => c.unsubscribe());
		}
	);

	private createStoreWorker = (skipOptimization?: boolean) => (
		() =>
			skipOptimization
				? map(value => value)
				: distinctUntilChanged((prevValue, nextValue) => deepEqual(prevValue, nextValue))
	);
}
