<div
	*ngIf="!isUsersLoading; else usersLoading"
	class='mt-0'
	[ngClass]="{ 'l-container-mobile': isMobile, 'l-container-web': !isMobile }"
>
	<h3 class="l-title">
		List of Registrations
	</h3>
	<p>
		Below is a list of all the people who have registered for your event. you can see their status as well as
		view more information about their registration such as the fields they have filled in.
	</p>
	<div *ngIf="tableHasData; else noUsers">
		<app-web-filter
			[buttonText]="!isMobile ?'Download' : ''"
			(filterOutput)='handleFilterOutput($event)'
			(buttonClick)='handleDownloadReport()'
			searchPlaceholder='Search users'
		></app-web-filter>
		<div
			class="l-table-container"
			*ngIf="resultsLength; else noMatch"
		>
			<div
				*ngIf='isUsersFiltering'
				class='l-filtering'
			>
				<mat-spinner></mat-spinner>
			</div>
			<table
				mat-table
				[dataSource]="registrations"
				class='l-table web'
				matSort
				(matSortChange)="handleSorting($event)"
			>
				<ng-container matColumnDef="name">
					<th
						id="name-table-header"
						mat-header-cell
						*matHeaderCellDef
						mat-sort-header
					> Name </th>
					<td
						mat-cell
						*matCellDef="let registration; let i = index"
						id="name-{{i}}"
					>
						{{ registration.name }}
					</td>
				</ng-container>
				<ng-container matColumnDef="surname">
					<th
						id="surname-table-header"
						mat-header-cell
						*matHeaderCellDef
						mat-sort-header
					> Last Name </th>
					<td
						mat-cell
						*matCellDef="let registration; let i = index"
						id="last-name-{{i}}"
					>
						{{ registration.surname }}
					</td>
				</ng-container>
				<ng-container matColumnDef="email">
					<th
						id="email-table-header"
						mat-header-cell
						*matHeaderCellDef
						mat-sort-header
					> Email </th>
					<td
						mat-cell
						*matCellDef="let registration; let i = index"
						id="email-{{i}}"
					>{{ registration.email }} </td>
				</ng-container>
				<ng-container matColumnDef="identificationNumber">
					<th
						mat-header-cell
						*matHeaderCellDef
						mat-header
					> ID/Passport </th>
					<td
						mat-cell
						*matCellDef="let registration; let i = index"
						id="identificationNumber-{{i}}"
					>{{ registration.identificationNumber }} </td>
				</ng-container>
				<ng-container matColumnDef="status">
					<th
						id="status-table-header"
						mat-header-cell
						*matHeaderCellDef
						mat-sort-header
					> Status </th>
					<td
						mat-cell
						*matCellDef="let registration; let i = index"
						id="status-{{i}}"
					>
						<div class='l-info'>
							<div
								class="l-badge"
								[ngClass]="handleStatusClass(registration.status)"
							>
								{{ handleStatusMapping(registration.status) }}
							</div>
						</div>
					</td>
				</ng-container>
				<ng-container matColumnDef="action">
					<th
						mat-header-cell
						*matHeaderCellDef
					> Action </th>
					<td
						mat-cell
						*matCellDef="let registration; let i = index"
						id="action-{{i}}"
					>
						<div
							*ngIf="!isMobile"
							class='l-more'
						>
							<mat-icon
								id="user-details-redirect"
								(click)="!isMobile && handleUserDetailsRedirect(registration.id)"
							>arrow_outward</mat-icon>
						</div>
					</td>
				</ng-container>
				<tr
					mat-header-row
					*matHeaderRowDef="displayColumns"
				></tr>
				<tr
					mat-row
					*matRowDef="let registration; columns: displayColumns; let i = index"
					(click)="isMobile && handleUserDetailsRedirect(registration.id)"
					[id]="'registration-details-redirect-' + (i + 1)"
				></tr>
			</table>
		</div>
		<mat-paginator
			#productsPaginator
			[length]='resultsLength'
			[pageIndex]="pageIndex"
			[pageSizeOptions]="pageSizeOptions"
			(page)="handlePagination($event)"
		></mat-paginator>
		<ng-template #noMatch>
			<div class="l-empty-state">
				<h3 class="mb-3">Nothing to see here...</h3>
				<div class="mb-3">Nothing matches your search or filters</div>
			</div>
		</ng-template>
		<button
			id='download-csv-button-mobile'
			*ngIf='isMobile'
			class='g-primary-button l-download-csv-mobile'
			(click)="handleDownloadReport()"
		><mat-icon class='l-icon'>save_alt</mat-icon>DOWNLOAD
			CSV</button>
	</div>
</div>
<ng-template #usersLoading>
	<div class="l-loading">
		<mat-spinner></mat-spinner>
	</div>
</ng-template>
<ng-template #noUsers>
	<div class="l-empty-state">
		<h3 class="mb-3">No users Found</h3>
		<div class="mb-3 text-center px-2">No users have been registered.</div>
	</div>
</ng-template>