import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { OrganiserProfileActionConstants } from '../actions/organiserProfiles/organiserProfiles.constants';
import { normalize } from 'normalizr';
import { arrayOfProfiles } from '@app/schemes/profile.schema';
import * as organiserProfilesActions from '../actions/organiserProfiles/organiserProfiles.actions';
import { Store } from '@ngrx/store';
import { AppState } from '@app/models/store.model';
import { mapEventFrom, mapEventTo, chartCategoryToSeatingCategory } from '@app/utils/mappers/event';
import * as eventSelectors from '@app/store/selectors/event.selector';
import { catchError, switchMap, withLatestFrom, mergeMap } from 'rxjs/operators';
import { eventScheme } from '@app/schemes/event.schema';
import { EventDomainAPI } from '@app/api/domains/event';
import { ofTypeExt, handleSuccessRequest, handleFailedRequest } from '@app/utils/Store';
import { throwError, concat } from 'rxjs';
import { getResponseErrors } from '@app/utils/Response';
import { MembershipDomainAPI } from '@app/api/domains/membership';
import { Go } from '../actions/router/router.actions';

@Injectable()
export class OrganiserProfileEffects {
	constructor(
		private action$: Actions,
		private store$: Store<AppState>,
		private api?: EventDomainAPI,
		private memberApi?: MembershipDomainAPI
	) {}

	getProfiles$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<organiserProfilesActions.GetProfiles>(OrganiserProfileActionConstants.GET_PROFILES),
			switchMap(({ action }) =>
				this.api.getProfiles(action.payload.eventId).pipe(
					handleSuccessRequest((value) => {
						const {
							result: all,
							entities: { byId },
						} = normalize(value.payload, arrayOfProfiles);

						return new organiserProfilesActions.GetProfilesSuccess({
							profiles: {
								all,
								byId,
							},
						});
					}),
					catchError(
						handleFailedRequest(
							new organiserProfilesActions.GetProfilesFailed({
								msg: 'can\'t get profiles',
								action,
								critical: true,
							})
						)
					)
				)
			)
		)
	);

	createOrganiserProfile$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<organiserProfilesActions.CreateProfile>(OrganiserProfileActionConstants.CREATE_PROFILE),
			switchMap(({ action }) =>
				this.api.createProfile(action.payload.eventId, action.payload.profile).pipe(
					handleSuccessRequest(
						({ payload: profile }) =>
							new organiserProfilesActions.CreateProfileSuccess({
								profile,
							})
					),
					catchError(
						handleFailedRequest(
							new organiserProfilesActions.CreateProfileFailed({
								msg: 'can\'t create profile.',
								action,
								critical: true,
							})
						)
					)
				)
			)
		)
	);

	getEventOrganiser$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<organiserProfilesActions.GetEventOrganiser>(OrganiserProfileActionConstants.GET_EVENT_ORGANISER),
			switchMap(({ action, payload: { id } }) =>
				this.api.getEventOrganiser(id).pipe(
					handleSuccessRequest(
						({
							payload,
							payload: {
								seatingCategories,
								productDetails: { seatsIOChartKey },
							},
						}) => {
							const product = mapEventFrom(payload);

							const { entities: normalizedEntity } = normalize(product, eventScheme);

							return new organiserProfilesActions.GetEventOrganiserSuccess({
								id,
								normalizedEntity,
								entity: {
									original: {
										/* eslint-disable-next-line max-len */
										seatingCategories: chartCategoryToSeatingCategory(
											seatingCategories,
											product.ticketsDetails.seatingChartId
										),
										seatsIOChartKey,
									},
								},
							});
						}
					),
					catchError(
						handleFailedRequest(
							new organiserProfilesActions.GetEventOrganiserFailed({
								msg: 'can\'t get event organiser',
								action,
								critical: true,
							})
						)
					)
				)
			)
		)
	);

	saveEventOrganiser$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<organiserProfilesActions.SaveEventOrganiser>(OrganiserProfileActionConstants.SAVE_EVENT_ORGANISER),
			withLatestFrom(this.store$),
			switchMap(
				([
					{
						action,
						payload: { id, event },
					},
					store,
				]) => {
					const entity = eventSelectors.event(id)(store) || null;
					const { organiser } = mapEventTo(
						event,
						entity ? { ...entity.original, questions: entity.questions } : null,
						eventSelectors.currencies()(store)
					);

					return this.api.saveEventOrganiser(id, organiser).pipe(
						handleSuccessRequest(
							({
								payload,
								payload: {
									seatingCategories,
									productDetails: { seatsIOChartKey },
								},
							}) => {
								const product = mapEventFrom(payload);

								const { entities: normalizedEntity } = normalize(product, eventScheme);

								return new organiserProfilesActions.SaveEventOrganiserSuccess({
									id,
									normalizedEntity,
									entity: {
										original: {
											/* eslint-disable-next-line max-len */
											seatingCategories: chartCategoryToSeatingCategory(
												seatingCategories,
												product.ticketsDetails.seatingChartId
											),
											seatsIOChartKey,
										},
									},
									organiserId: organiser.id,
								});
							}
						),
						catchError(
							handleFailedRequest(
								new organiserProfilesActions.SaveEventOrganiserFailed({
									msg: 'can\'t save event organiser',
									action,
									critical: true,
								})
							)
						)
					);
				}
			)
		)
	);

	updateUserOrganiserProfiles = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<organiserProfilesActions.UpdateUserOrganiserProfile>(OrganiserProfileActionConstants.UPDATE_ORGANISER_PROFILE),
			switchMap(({ action, payload: { profile, isUpdating } }) =>
				this.memberApi.updateOrganiserProfile(profile).pipe(
					mergeMap((value) => {
						if (value.isSuccess && value.payload) {
							return concat([
								new organiserProfilesActions.UpdateUserOrganiserProfileSuccess({
									profile: value.payload,
									isUpdating,
								}),
								new organiserProfilesActions.SetOrganiserProfileModalFlag({
									isFormModalOpen: false,
								}),
							]);
						} else {
							return throwError(getResponseErrors(value));
						}
					}),
					catchError(
						handleFailedRequest(
							new organiserProfilesActions.UpdateUserOrganiserProfileFailed({
								msg: 'can\'t update this profile',
								action,
							})
						)
					)
				)
			)
		)
	);

	deleteUserOrganiserProfile = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<organiserProfilesActions.DeleteUserOrganiserProfile>(OrganiserProfileActionConstants.DELETE_ORGANISER_PROFILE),
			switchMap(({ action, payload: { id } }) =>
				this.memberApi.deleteOrganiserProfile(id).pipe(
					handleSuccessRequest(({ payload }) => new organiserProfilesActions.DeleteUserOrganiserProfileSuccess(payload)),
					catchError(
						handleFailedRequest(
							new organiserProfilesActions.DeleteUserOrganiserProfileFailed({
								msg: 'can\'t delete organiser profile',
								action,
							})
						)
					)
				)
			)
		)
	);

	getUserOrganiserProfiles = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<organiserProfilesActions.GetUserOrganiserProfiles>(OrganiserProfileActionConstants.GET_ORGANISER_PROFILES),
			switchMap(({ action }) =>
				this.memberApi.getOrganiserProfiles().pipe(
					handleSuccessRequest((value) => {
						const {
							result: all,
							entities: { byId },
						} = normalize(value.payload, arrayOfProfiles);

						return new organiserProfilesActions.GetUserOrganiserProfilesSuccess({
							profiles: {
								all,
								byId,
							},
						});
					}),
					catchError(
						handleFailedRequest(
							new organiserProfilesActions.GetUserOrganiserProfilesFailed({
								msg: 'can\'t get organiser profiles',
								action,
								critical: true,
								meta: {
									extraAction: new Go({ path: ['/login'] }),
									extraActionTitle: 'Go to login',
									allowClose: false,
								},
							})
						)
					)
				)
			)
		)
	);
}
