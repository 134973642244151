import { EventEntityState } from '@app/models/store.model';
import { EventCollections } from '@app/models/event.model';
import { Actions, CollectionsActionConstants } from './event-collections.actions';


export const entityCollectionsReducer = (initialState: EventEntityState['collections']) => (
	state = initialState,
	action: Actions
): EventCollections => {
	switch (action.type) {
		case CollectionsActionConstants.GET_EVENT_COLLECTIONS_SUCCESS:
		case CollectionsActionConstants.UPDATE_EVENT_COLLECTIONS_SUCCESS:
			return action.payload.collections;
		default:
			return state;
	}
};
