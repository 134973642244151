<div [ngClass]="{'p-3': isMobile, 'pt-5 px-5': !isMobile}">
	<div *ngIf="!isLoading; else showLoading">
		<div [ngClass]="{'l-header-container-mobile': isMobile,'l-header-container-web' : !isMobile }">
			<h3>GUEST DETAILS</h3>
			<div class="l-header-references">
				<p>{{selectedGuestInfo.orderReference}}</p>
				<p>#{{formatTicketReference(selectedGuestInfo.ticketReference)}}</p>
			</div>
		</div>
		<div *ngIf="data.isSeated" >
			<div class="l-seated-container">
				<label class="l-details-question-text">Seat:</label>
				<p class="l-details-answer-text">{{selectedGuestInfo.seat}}</p>
			</div>
		</div>
		<mat-table class="l-details-table" [dataSource]="selectedGuestInfo.questions">
			<ng-container matColumnDef="question">
				<mat-header-cell *matHeaderCellDef> Question </mat-header-cell>
				<mat-cell *matCellDef="let element"> {{element.question}}: </mat-cell>
			</ng-container>
			<ng-container matColumnDef="answer">
				<mat-header-cell *matHeaderCellDef> Answer </mat-header-cell>
				<mat-cell *matCellDef="let element" class="l-answer-cell"> {{element.answer}} </mat-cell>
			</ng-container>
			<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
			<mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
		</mat-table>
	</div>
	<div [ngClass]="{'l-button-container-mobile': isMobile}">
		<app-footer-button [isMobile]="isMobile" [isModal]="true" [primaryText]="primaryButtonText"
			[secondaryText]="secondaryButtonText" [isDisabled]="false" [isLoading]="isLoading"
			($primaryButton)="handleResendTickets()" ($secondaryButton)="handleCancel()">
		</app-footer-button>
	</div>
	<ng-template #showLoading>
		<div class="l-loading">
			<mat-spinner></mat-spinner>
		</div>
	</ng-template>
</div>
