<div class="l-container">
	<div class="g-h3 mt-3">
	  <h3>Filter By</h3>
	</div>
	<div class="l-options-container">
	  <div *ngIf="data.showFilterOptions" class="input-group-mobile mb-3">
		<label class="g-form-label">Status</label>
		<mat-select class="form-control" [(ngModel)]="selectedFilter" name="filter" placeholder="Type (All)">
		  <mat-optgroup *ngFor="let filterGroup of data.filterOptions" [label]="filterGroup.name">
			<mat-option *ngFor="let filter of filterGroup.filter" [value]="filter">
			  {{filter.viewValue}}
			</mat-option>
		  </mat-optgroup>
		</mat-select>
	  </div>

	  <div class="input-group-mobile">
		<label class="g-form-label">Sort By</label>
		<mat-select class="form-control" [(ngModel)]="selectedSort" name="sort" placeholder="Sort (All Dates)">
		  <mat-option *ngFor="let sort of sortOptions | keyvalue" [value]="sort.value">{{ sort.value }}</mat-option>
		</mat-select>
	  </div>
	</div>
	<button class="g-primary-button my-3 w-100" (click)="handleFilter()">SAVE</button>
  </div>
