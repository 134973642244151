import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-bank-info-bar',
	templateUrl: './bank-info-bar.component.html',
	styleUrls: ['./bank-info-bar.component.sass'],
})
export class BankInfoBarComponent {
	@Input() isMobile = false;
	@Input() infoTitleOne: string;
	@Input() infoValueOne: string;
	@Input() infoTitleTwo: string;
	@Input() infoValueTwo: string;
	@Input() showCurrencySymbol = false;
	@Input() buttonDisabled: boolean;

	@Output() handleClick: EventEmitter<void> = new EventEmitter<void>();

	handleButton() {
		this.handleClick.emit();
	}

}
