<div class="l-container" [ngClass]="{ 'mobile': isMobile }">
	<div class="mb-3">
		<app-web-content-header
			[headerContent]="headerContent"
			[mediumWidth]='true'
			[showBreadCrumb]="!isMobile"
			[showTitle]="!isMobile"
		>
		</app-web-content-header>
	</div>
	<app-orders-list [isMobile]='isMobile' [eventId]='eventId'>
	</app-orders-list>
</div>
