import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { QuestionType, Question, ValidationType } from '@app/models/user.model';
import { regexValidator } from '@app/shared/form-field/form-validators';
import { numberRegex, phoneRegex, urlRegex } from '@app/utils/regex';
import moment from 'moment';

@Injectable()
export class FormCreationService {
	constructor(private fb: FormBuilder) {}

	createFormGroup(): FormGroup {
		return this.fb.group({});
	}

	populateFormFromData(
		form: FormGroup,
		controls: Question[],
		isEditable: boolean = true,
		uniqueQuestions?: { [key: number]: string[] },
		allTicketsForm?: FormGroup
	): void {
		for (const control of controls) {
			switch (control.type) {
				case QuestionType.CheckBoxList:
					const checkboxValidators = [];
					if (control.required) {
						checkboxValidators.push(Validators.required);
					}
					this.createSimpleControl(form, control, checkboxValidators, isEditable);
					break;
				case QuestionType.RadioButtonList:
					const radioButtonValidators = [];
					if (control.required) {
						radioButtonValidators.push(Validators.required);
					}
					if (control.regularExpression) {
						radioButtonValidators.push(
							regexValidator(
								control.regularExpression,
								control.errorMessage ? control.errorMessage : 'Invalid choice selected.'
							)
						);
					}
					this.createSimpleControl(form, control, radioButtonValidators, isEditable);
					break;
				case QuestionType.DropDownList:
					const dropDownValidators = [];
					if (control.required) {
						dropDownValidators.push(Validators.required);
					}
					if (control.regularExpression) {
						dropDownValidators.push(
							regexValidator(
								control.regularExpression,
								control.errorMessage ? control.errorMessage : 'Invalid choice selected.'
							)
						);
					}
					this.createSimpleControl(form, control, dropDownValidators, isEditable);
					break;
				case QuestionType.Country:
					const countryValidators = [];
					if (control.required) {
						countryValidators.push(Validators.required);
					}
					this.createSimpleControl(form, control, countryValidators, isEditable);
					break;
				case QuestionType.DateField:
					const dateValidators = this.createDateValidators(control);
					this.createSimpleControl(form, control, dateValidators, isEditable);
					break;
				case QuestionType.FileUpload:
					const fileUploadValidators = this.createDefaultValidators(control);
					this.createSimpleControl(form, control, fileUploadValidators, isEditable);
					break;
				case QuestionType.Address:
					const addressValidators = [];
					if (control.required) {
						addressValidators.push(Validators.required);
					}
					this.createSimpleControl(form, control, addressValidators, isEditable);
					break;
				case QuestionType.Waiver:
					const waiverValidators = [];
					if (control.required) {
						waiverValidators.push(this.selectRequiredValidator());
					};
					this.createSimpleControl(form, control, waiverValidators, isEditable);
					break;
				case QuestionType.Cellphone:
					const cellphoneValidators = [];
					if (control.required) {
						cellphoneValidators.push(Validators.required);
					}
					if (uniqueQuestions && uniqueQuestions[control.id] && allTicketsForm) {
						cellphoneValidators.push(this.isUniqueValidator(allTicketsForm, control));
						cellphoneValidators.push(this.isEventLevelUniqueValidator(control?.uniqueAnswerError));
					}
					cellphoneValidators.push(this.cellphoneValidator());
					this.createSimpleControl(form, control, cellphoneValidators, isEditable);
					break;
				case QuestionType.SmallTextField:
					const smallTextFieldValidators = this.createDefaultValidators(control);
					if (uniqueQuestions && uniqueQuestions[control.id] && allTicketsForm) {
						smallTextFieldValidators.push(this.isUniqueValidator(allTicketsForm, control));
						smallTextFieldValidators.push(this.isEventLevelUniqueValidator(control?.uniqueAnswerError));
					}
					this.createSimpleControl(form, control, smallTextFieldValidators, isEditable);
					break;
				case QuestionType.SectionHeading:
					break;
				default:
					const defaultValidators = this.createDefaultValidators(control);
					this.createSimpleControl(form, control, defaultValidators, isEditable);
			}
		}
	}

	createSimpleControl(form: FormGroup, control: Question, validators: ValidatorFn[], isEditable: boolean) {
		form.addControl(control.id.toString(), this.fb.control({ value: control.answer, disabled: !isEditable }, validators));
	}

	createDateValidators(control: Question): ValidatorFn[] {
		const validators = [];
		const regularExpression = control.regularExpression;
		let min: number | undefined;
		let max: number | undefined;
		if (regularExpression) {
			const parts = regularExpression.split('#');
			if (parts[0]) {
				min = parseInt(parts[0], 10);
				if (min && min !== 0) {
					validators.push(this.minAgeValidator(min));
				}
			}
			if (parts[1]) {
				max = parseInt(parts[1], 10);
				if (max && max !== 0) {
					validators.push(this.maxAgeValidator(max));
				}
			}
		}
		if (control.required) {
			validators.push(this.dateFieldRequiredValidator());
		}
		return validators;
	}

	createDefaultValidators(control: Question): ValidatorFn[] {
		const validators = [];
		if (control.required) {
			validators.push(Validators.required);
		}
		switch (control.validationType) {
			case ValidationType.Email:
				validators.push(Validators.email);
				break;
			case ValidationType.Regex:
				if (control.errorMessage) {
					validators.push(regexValidator(control.regularExpression, control.errorMessage));
				} else {
					validators.push(Validators.pattern(control.regularExpression));
				}
				break;
			case ValidationType.Url:
				validators.push(this.urlValidator());
				break;
			case ValidationType.Number:
				validators.push(this.numberValidator());
				break;
			case ValidationType.Cellphone:
				validators.push(this.cellphoneValidator());
				break;
			default:
				break;
		}

		return validators;
	}

	urlValidator(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const forbidden = !urlRegex.test(control.value);
			return forbidden ? { invalidURL: { value: control.value } } : null;
		};
	}

	numberValidator(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const forbidden = !numberRegex.test(control.value);
			return forbidden ? { invalidNumber: { value: control.value } } : null;
		};
	}

	checkboxRequiredValidator(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: boolean } | null => {
			const hasTrue = Object.values(control.value).some((value: boolean) => value === true);
			return hasTrue ? null : { checkboxRequired: true };
		};
	}

	radioButtonRequiredValidator(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: boolean } | null => {
			const hasTrue = Object.values(control.value).some((value: boolean) => value === true);
			return hasTrue ? null : { radioButtonRequired: true };
		};
	}

	selectRequiredValidator(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: boolean } | null => (control.value ? null : { selectRequired: true });
	}

	dateFieldRequiredValidator(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: boolean } | null => (control.value ? null : { dateFieldRequired: true });
	}

	cellphoneValidator(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const forbidden = control.value && !phoneRegex.test(control.value);
			return forbidden ? { invalidPhone: { value: control.value } } : null;
		};
	}

	minAgeValidator(minAge: number): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (!control.value) {
				return null;
			}
			const currentDate = new Date();
			const selectedDateYear = moment(control.value, 'DD/MM/YYYY').year();
			const ageDifference = currentDate.getFullYear() - selectedDateYear;

			if (ageDifference < minAge || ageDifference < 0) {
				return { minAge: { value: minAge } };
			}
			return null;
		};
	}

	maxAgeValidator(maxAge: number): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (!control.value) {
				return null;
			}

			const currentDate = new Date();
			const selectedDateYear = moment(control.value, 'DD/MM/YYYY').year();
			const ageDifference = currentDate.getFullYear() - selectedDateYear;

			if (ageDifference > maxAge || ageDifference < 0) {
				return { maxAge: { value: maxAge } };
			}
			return null;
		};
	}

	isUniqueValidator(allTicketsForm: FormGroup, question: Question): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const otherControls: AbstractControl[] = Object.keys(allTicketsForm.controls)
				.map(key => allTicketsForm.get(key) as FormGroup)
				.map(ticketForm => ticketForm.get(question.id.toString()))
				.filter(otherControl => otherControl && control !== otherControl && control.value);

			const otherAnswers: string[] = otherControls
				.filter(otherControl => otherControl && otherControl.value !== null)
				.map(otherControl => otherControl ? otherControl.value : '');

			const forbidden = otherAnswers.includes(control.value);
			return forbidden ? { notUnique: { value: control.value } } : null;
		};
	}

	isEventLevelUniqueValidator(hasUniqueError: boolean): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null =>
			hasUniqueError && control.pristine ? { notEventUnique: { value: true } } : null;
	}

}
