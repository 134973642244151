import { enableProdMode, isDevMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { RootModule } from './app/root.module';
import { environment } from './environments/environment';
import config from '@app/config';

if (environment.production) {
	enableProdMode();
}

Sentry.init({
	dsn: config.sentryDSN,
	integrations: [
		// Registers and configures the Tracing integration,
		// which automatically instruments your application to monitor its
		// performance, including custom Angular routing instrumentation
		Sentry.browserTracingIntegration(),
	],

	environment: config.sentryEnv,
	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for tracing.
	// We recommend adjusting this value in production
	tracesSampleRate: isDevMode() ? 1.0 : 0.2,
});

platformBrowserDynamic().bootstrapModule(RootModule)
	.catch(err => console.log(err)); // eslint-disable-line no-console
