<div class="l-container">
	<h3 class="mb-3">Add Mail Account</h3>
	<form #mailAccountForm="ngForm" class="g-form-container my-5">
		<div *ngIf="!isLoading; else showLoading">

			<div class="input-group w-100 mb-3">
				<label class="g-form-label l-label">Account Name <mat-icon #accountNameTooltip="matTooltip"
						class="l-tooltip-icon" (click)="accountNameTooltip.toggle()"
						matTooltip="This is just an identifier for yourself - in case you want to add multiple accounts"
						matTooltipPosition="right">info</mat-icon></label>
				<input #name="ngModel" [(ngModel)]="mailAccount.name" type="text" name="name" class="form-control w-100"
					placeholder="Account Name" required />
				<div class="g-error-input" *ngIf="name.dirty || name.touched">
					<div *ngIf="name.errors?.required">An account name is Required</div>
				</div>
			</div>
			<div class="g-double-input-container">
				<div class="l-input input-group">
					<label class="g-form-label l-label">From Name <mat-icon #fromNameTooltip="matTooltip"
							class="l-tooltip-icon" (click)="fromNameTooltip.toggle()"
							matTooltip="This name will be shown as the From Name in the mails that get sent out."
							matTooltipPosition="right">info</mat-icon></label>
					<input #from="ngModel" [(ngModel)]="mailAccount.fromName" type="text" name="from" class="form-control w-100"
						placeholder="From Name" required />
					<div class="g-error-input" *ngIf="from.dirty || from.touched">
						<div *ngIf="from.errors?.required">A From Name is Required</div>
					</div>
				</div>
				<div class="l-input input-group">
					<label class="g-form-label l-label">From Email Address <mat-icon #emailTooltip="matTooltip"
							class="l-tooltip-icon" (click)="emailTooltip.toggle()"
							matTooltip="This must match the domain registered in your Mandrill account"
							matTooltipPosition="right">info</mat-icon></label>
					<input #email="ngModel" [(ngModel)]="mailAccount.fromEmail" type="text" name="email" class="form-control w-100"
						placeholder="Email Address" [pattern]='emailRegex' required />
					<div class="g-error-input" *ngIf="email.dirty || email.touched">
						<div *ngIf="email.errors?.required">Email is Required</div>
						<div *ngIf="email.errors?.pattern">Invalid Email</div>
					</div>
				</div>
			</div>
			<div class="input-group w-100 mb-3">
				<label class="g-form-label l-label">Mandrill API Key <mat-icon #apiTooltip="matTooltip"
						class="l-tooltip-icon" (click)="apiTooltip.toggle()"
						matTooltip="Please retrieve this from your Mandrill account"
						matTooltipPosition="right">info</mat-icon></label>
				<input #api="ngModel" [(ngModel)]="mailAccount.apiKey" type="text" name="api" class="form-control w-100"
					placeholder="xxx-xxx-xxx" required />
				<div class="g-error-input" *ngIf="api.dirty || api.touched">
					<div *ngIf="api.errors?.required">An API key is Required</div>
				</div>
			</div>
			<div class='l-mandrill-container'>
				<div class='l-mandrill-text'>The service used for managing mail accounts on Quicket is </div>
				<img class='l-mandrill-logo' src='./assets/icons/mandrill.png' />
			</div>
		</div>
		<ng-template #showLoading>
			<div class="l-loading">
				<mat-spinner></mat-spinner>
			</div>
		</ng-template>
	</form>

	<div class="l-buttons-container">
		<button id='cancel-button' class="g-secondary-button border-0" (click)="handleCancel()">CANCEL</button>
		<button id='download-report-button' class="g-primary-button" (click)="handleAddAccount()"
			[class.disabled]="mailAccountForm.invalid" [disabled]="mailAccountForm.invalid">ADD</button>
	</div>
</div>
