import { EventImage } from '@app/models/event.model';
import { SchemeID } from './dataSchema.model';
import { DraftBankAccount, Bank } from './user.model';
import { ProductType } from '@app/models/product.model';

export class EventTicketsDetails<T = Ticket> {
	isReservedSeating = false;
	seatingChartId: string = null;
	capacity: number = null;
	maxTicketsPerOrder: number = null;
	donationTarget: number = null;
	currencyCode: string = null;
	currencyId: SchemeID = null;
	bankAccountId: SchemeID = null;
	allowRefunds = false;
	allowNoCategories = false;
	serviceFeeForHost = false;
	commissionForHost = true;
	tickets: T[] = [];
	donationIncludeTickets = false;
	productType: ProductType = null;
	hasStreaming = false;
	allowRefundProtect = false;
}

export interface TicketQuestion {
	id: string | number;
	question: string;
	required: boolean;
	productQuestionId: number;
	enabled: boolean;
}

export enum TicketType {
	paid = 'paid',
	free = 'free',
	donation = 'donation',
}

export enum SubscriptionRecurringPeriod {
	Weekly = 1,
	Monthly = 2,
	Yearly = 3,
	Quarterly = 4,
	Biannualy = 5,
}

export enum ShowTicketStockType {
	None = 0,
	PercentRemaining = 1,
	PercentSold = 2,
	NumberRemaining = 3,
	NumberRemainingWithTotalOffered = 4,
}

export class Ticket {
	id: SchemeID = null;
	type: TicketType = TicketType.paid;
	name: string = null;
	price: number = null;
	quantity = null;
	isTypeHidden = false;
	categories: TicketCategory[] = [];
	salesStartDateTime: string | Date = null;
	salesEndDateTime: string | Date = null;
	description: string = null;
	scannerColour: string = null;
	stubInfo: string = null;
	questions: TicketQuestion[] = [];
	minPerOrder = 1;
	maxPerOrder = 20;
	maxPerUser = 20;
	incrementsOf = 1;
	validTo: string = null;
	validFrom: string = null;
	hasSales = false;
	sellAtTheDoor = false;
	autoCheckin = false;
	canVendor = false;
	image: EventImage = null;
	imageString: {
		fileName: string;
		url: string;
		base64: string;
	} = null;
	linkedToStream = false;
	hideUntil: string = null;
	hideAfter: string = null;
	benefits: string[] = [];
	recurringPeriod: SubscriptionRecurringPeriod = SubscriptionRecurringPeriod.Monthly;
	subscriptionPerkTypes: number[] = []; // add mapping when more perks are added
	lowerLimit = 10;
	isOpen = false;
	showTicketStockType: ShowTicketStockType = ShowTicketStockType.None;
}

export class EventTicketsSettings {
	id: SchemeID = null;
	image: EventImage = null;
	ticketStubDescription: string = null;
	includePDF = false;
	useCustom = false;
	includeMessage = false;
	customMessage: string = null;
	ticketPreviewLink: string = null;
}

export const TicketStockTypeMap = new Map([
	[ShowTicketStockType.None, 'Do not show ticket stock'],
	[ShowTicketStockType.PercentRemaining, 'Percent of tickets remaining'],
	[ShowTicketStockType.PercentSold, 'Percent of tickets sold'],
	[ShowTicketStockType.NumberRemaining, 'Number of tickets remaining'],
	[ShowTicketStockType.NumberRemainingWithTotalOffered, 'Number of tickets remaining out of amount for sale'],
]);

export const SubRecurringPeriodMap = new Map([
	[SubscriptionRecurringPeriod.Weekly, 'Week'],
	[SubscriptionRecurringPeriod.Monthly, 'Month'],
	[SubscriptionRecurringPeriod.Quarterly, '3 months'],
	[SubscriptionRecurringPeriod.Biannualy, '6 months'],
	[SubscriptionRecurringPeriod.Yearly, 'Year'],
]);



export enum StopSales {
	ENDSALES,
	SOLDOUT,
}

export interface UserChart {
	id: number;
	name: string;
	publishedVersionThumbnailUrl: string;
	draftVersionThumbnailUrl: string;
	key: string;
	status: 'NOT_USED' | 'PUBLISHED' | 'PUBLISHED_WITH_DRAFT';
}

export enum UserChartStatus {
	NOT_USED = 'NOT_USED',
	PUBLISHED = 'PUBLISHED',
	PUBLISHED_WITH_DRAFT = 'PUBLISHED_WITH_DRAFT',
}

export interface Currency {
	ianaTimezone: string;
	name: string;
	iso: string;
	baseUtcOffset: string;
	displayName: string;
	symbol: string;
	id: number;
	domainId: number;
	defaultCommission: number;
	defaultServiceFee: number;
}
export interface SeatsIOCategories {
	categories: {
		list: {
			accessible: boolean;
			color: string;
			id: number;
			key: number;
			label: string;
		}[];
	};
}

export class ChartCategory {
	label: string;
	seatsIOKey: number;
	capacity: number;
	id: number;
	isDeleted?: boolean;
}

export class SeatingCategory extends ChartCategory {
	chartId: string;
}

export class SeatingCategoryEntity {
	seatingCategories: SeatingCategory[];
}

export class SeatingCategoryPayload {
	eventId: number;
	chartId: string;
	entity: SeatingCategoryEntity;
}

export class SeatingCategorySuccessResponse {
	payload: SeatingCategoryPayload;
	type: string;
}

export class TicketCategory extends SeatingCategory {
	required: boolean;
}

export enum QuantityWarningType {
	zero = 'zero',
	one = 'one',
	both = 'both',
}

export class BankAccountSelectionForm {
	accountId: SchemeID = null;
}

export class DraftBankAccountForm implements DraftBankAccount {
	bankId: Bank = null;
	branchCode: string = null;
	holder: string = null;
	accountNumber: string = null;
	reenterAccountNumber: string = null;
	mobileNumber: string = null;
	reenterMobileNumber: string = null;
	isDefault = false;
	branch: number = null;
}

export enum TicketGroupsPageMode {
	List = 'List',
	Create = 'Create',
	Edit = 'Edit',
}
