import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
	selector: 'app-address-autocomplete',
	templateUrl: './address-autocomplete.component.html',
	styleUrls: ['./address-autocomplete.component.sass'],
})
export class AddressAutocompleteComponent{
	@Input() isMobile: boolean;
	@Input() label: string;
	@Input() tooltip: string;
	@Output() addressSelected: EventEmitter<google.maps.places.PlaceResult> = new EventEmitter<google.maps.places.PlaceResult>();

	autocomplete: google.maps.places.Autocomplete | undefined;

	@ViewChild('addressField', {static: false}) autocompleteField: ElementRef;

	ngAfterViewInit(): void {
		this.initializeAutocomplete();
	}

	initializeAutocomplete(): void {
		this.autocomplete = new google.maps.places.Autocomplete(
			this.autocompleteField.nativeElement,
			{
				fields: ['address_components', 'geometry', 'name'],
			}
		);

		this.autocomplete.addListener('place_changed', () => {
			const place: google.maps.places.PlaceResult = this.autocomplete.getPlace();
			if (place.geometry === undefined || place.geometry === null) {
				return;
			}
			this.handleAutocompleteSelected(place);
		});

		const addressInputElement = this.autocompleteField.nativeElement as HTMLElement;
		addressInputElement.addEventListener('focus', () => {
			const pacContainer = document.querySelector('.pac-container') as HTMLElement;
			pacContainer.classList.add('single-element');
			addressInputElement.parentNode.appendChild(pacContainer);
		});

		addressInputElement.addEventListener('keydown', (event: KeyboardEvent) => {
			if (event.key === 'Enter') {
				event.preventDefault();
			}
		});
	}

	ngOnDestroy(){
		const pacContainer = document.querySelector('.pac-container') as HTMLElement;

		if (pacContainer){
			pacContainer.remove();
		} else {
			const addressInputElement = this.autocompleteField.nativeElement;
			const appendedPacContainer = addressInputElement.parentNode.querySelector('.pac-container');

			if (appendedPacContainer){
				appendedPacContainer.remove();
			}
		}
	}

	handleAutocompleteSelected(address: google.maps.places.PlaceResult) {
		this.addressSelected.emit(address);
	}
}
