import { Component, EventEmitter, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MyRegistrations } from '@app/models/user.model';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { StoreService } from '@app/services/store/store.service';
import { GetUserMyRegistrations } from '@app/store/actions/user/user.actions';
import * as userSelectors from '@app/store/selectors/user.selector';
import { take, takeUntil } from 'rxjs';
import { MobileFilterBottomsheetComponent } from '../../../../shared/mobile-filter-bottomsheet/mobile-filter-bottomsheet.component';
import { MY_TICKETS_HEADER, MyTicketFilters, SIDENAV_ITEMS } from '@app/utils/consts';
import { FilterGroup, FilterOption, SearchAndFilter, SortOption } from '@app/models/filter-and-sort.model';
import { HeaderContent, SideNavItems } from '@app/models/shared';
import { InternalURLCreator, URLCreator } from '@app/services/url/url.dictionary';
import { Actions, ofType } from '@ngrx/effects';
import { UserActionsConstants } from '@app/store/actions/user/user.actions.constants';
import { cloneDeep } from 'lodash';
import { Go } from '@app/store/actions/router/router.actions';

@Component({
	selector: 'app-my-registrations',
	templateUrl: './my-registrations.component.html',
	styleUrls: ['./my-registrations.component.sass'],
})
export class MyRegistrationsComponent implements OnInit {
	headerContent: HeaderContent = MY_TICKETS_HEADER['myRegistrations'];
	myRegistrations: MyRegistrations[];
	filteredRegistrations: MyRegistrations[];

	currentRoute: SideNavItems = SIDENAV_ITEMS.myTickets;

	isTickets = true;
	isMobile = false;

	numberAppliedFilters: number;
	filterOptions: FilterGroup[] = MyTicketFilters;
	currentRegistrationSearchTerm = '';
	currentRegistrationSort: SortOption;

	cartId = 0;
	selectedTab = 0;
	hasCartIdParam = false;

	isTicketsLoading = true;
	isRegistrationsLoading = true;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private store: StoreService,
		private breakpointService: BreakpointService,
		private bottomSheet: MatBottomSheet,
		private actions$: Actions
	) {}

	ngOnInit() {
		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});

		this.store.dispatch(new GetUserMyRegistrations());

		this.actions$
			.pipe(
				ofType(UserActionsConstants.GET_USER_MY_REGISTRATIONS_SUCCESS, UserActionsConstants.GET_USER_MY_REGISTRATIONS_FAILED),
				takeUntil(this.destroyed$)
			)
			.subscribe(() => {
				setTimeout(() => (this.isRegistrationsLoading = false), 500);
			});

		this.initializeRegistrations();
	}

	initializeRegistrations() {
		this.store
			.select(userSelectors.userMyRegistrations())
			.pipe(takeUntil(this.destroyed$))
			.subscribe((myRegistrations) => {
				this.myRegistrations = myRegistrations || [];
				this.filteredRegistrations = [...this.myRegistrations];
				this.handleSort(SortOption.MostRecent);
				this.currentRegistrationSort = undefined;
			});
	}

	handleBottomSheetFilter() {
		const bottomSheetRef = this.bottomSheet.open(MobileFilterBottomsheetComponent, {
			data: {
				filterOptions: this.filterOptions,
				currentTicketFilter: null,
				currentTicketSort: this.currentRegistrationSort,
				showFilterOptions: false,
			},
		});

		bottomSheetRef
			.afterDismissed()
			.pipe(take(1))
			.subscribe((result: { sortOption: SortOption; filterOption: FilterOption }) => {
				if (result) {
					if (result.sortOption) {
						this.currentRegistrationSort = result.sortOption;
					}
					const searchAndFilter = { searchTerm: this.currentRegistrationSearchTerm, filterOption: result.filterOption };
					this.handleTicketFilterAndSearch(searchAndFilter);
				}
			});
	}

	handleTicketFilterAndSearch(searchAndFilter: SearchAndFilter) {
		const { searchTerm } = searchAndFilter;
		this.currentRegistrationSearchTerm = searchTerm;

		if (this.currentRegistrationSort) {
			this.numberAppliedFilters = 1;
		}

		if (searchTerm) {
			const lowerCaseSearchTerm = searchTerm.toLowerCase();
			this.filteredRegistrations = this.filteredRegistrations.filter(
				(registration) =>
					registration.productName.toLowerCase().includes(lowerCaseSearchTerm) ||
					('ref' + registration.preRegistrationId.toString()).includes(lowerCaseSearchTerm)
			);
		}

		this.handleSort(this.currentRegistrationSort);
	}

	handleRegistrationsSearch(searchAndFilter: SearchAndFilter) {
		const { searchTerm } = searchAndFilter;
		this.currentRegistrationSearchTerm = searchTerm;
		this.filteredRegistrations = this.myRegistrations;

		if (searchTerm) {
			const lowerCaseSearchTerm = searchTerm.toLowerCase();
			this.filteredRegistrations = this.filteredRegistrations.filter(
				(registration) =>
					registration.productName.toLowerCase().includes(lowerCaseSearchTerm) ||
					('ref' + registration.preRegistrationId.toString()).includes(lowerCaseSearchTerm)
			);
		}

		this.handleSort(this.currentRegistrationSort);
	}

	handleRegistrationMobileSearchTerm(searchTerm: string) {
		const searchAndFilter = { searchTerm: searchTerm, filterOption: null };
		this.handleRegistrationsSearch(searchAndFilter);
	}

	handleSort(sortOption: SortOption) {
		if (!sortOption) {
			return;
		}

		const sortFunctions = {
			[SortOption.StartDate]: (a, b) => new Date(a.dateFrom).getTime() - new Date(b.dateFrom).getTime(),
			[SortOption.NameAscending]: (a, b) => a.productName.localeCompare(b.productName),
			[SortOption.MostRecent]: (a, b) => (b.id ? b.id : b.productId) - (a.id ? a.id : a.productId),
			[SortOption.NameDescending]: (a, b) => b.productName.localeCompare(a.productName),
		};

		const sortAndAssign = (source, targetProp, sortFunc) => {
			if (source) {
				const sourceCopy = cloneDeep(source);
				this[targetProp] = sourceCopy.sort(sortFunc);
			}
		};

		const sortFunction = sortFunctions[sortOption];
		sortAndAssign(this.filteredRegistrations, 'filteredRegistrations', sortFunction);
		this.currentRegistrationSort = sortOption;
	}

	handleNavigationBack() {
		this.store.dispatch(new Go({ path: [InternalURLCreator.myAccount()] }));
	}

	handleRouterLink(id: string): string {
		return InternalURLCreator.registrationDetails(id);
	}

	handleEventNavigation() {
		window.open(URLCreator.eventsPage());
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
