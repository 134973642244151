import { NgModule } from '@angular/core';
import { AppRoutingModule } from '@app/router/app-routing/app-routing.module';
import { RootRoutingModule } from '@app/router/root-routing/root-routing.module';

@NgModule({
	imports: [
		AppRoutingModule,
		RootRoutingModule,
	],
	exports: [
		AppRoutingModule,
		RootRoutingModule,
	],
})
export class AppRouterModule {}
