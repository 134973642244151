import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EditEventLinkModalComponent } from '../edit-event-link-modal/edit-event-link-modal.component';

@Component({
	selector: 'app-send-invites-modal',
	templateUrl: './send-invites-modal.component.html',
	styleUrls: ['./send-invites-modal.component.sass'],
})
export class SendInvitesModalComponent {
	constructor (
		public dialogRef: MatDialogRef<EditEventLinkModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data: {isMobile: boolean; shortLink: string}
	) {}

	handleSendInvites() {
		this.dialogRef.close(true);
	}

	handleClose() {
		this.dialogRef.close();
	}
}
