import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StoreService } from '@app/services/store/store.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { AddNotification } from '@app/store/actions/notification/notification.actions';
import { NotificationType } from '@app/models/notification.model';

@Component({
	selector: 'app-share-promoter-network-modal',
	templateUrl: './share-promoter-network-modal.component.html',
	styleUrls: ['./share-promoter-network-modal.component.sass'],
})
export class SharePromoterNetworkModalComponent {

	linkCopied = false;

	constructor(
		private clipboard: Clipboard,
		private store: StoreService,
		public dialogRef: MatDialogRef<SharePromoterNetworkModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data: {isMobile: boolean; shortLink: string}
	) { }

	handleClose() {
		this.dialogRef.close();
	}

	handleCopyLink(link: string): void {
		this.clipboard.copy(link);
		this.store.dispatch(new AddNotification({
			id: 'link copied',
			actionType: null,
			action: null,
			type: NotificationType.SUCCESS,
			title: 'Link successfully copied to clipboard!',
			delay: 4000,
		}));
	}
}
