<div class="l-table-container">
	<table
		mat-table
		[dataSource]="dataSource"
		multiTemplateDataRows
		class='l-table expansion'
		cdkDropList
		(cdkDropListDropped)="handleDrag($event)"
		[cdkDropListDisabled]="dragDisabled"
		#tabContentTable
	>
		<ng-container matColumnDef="sorting">
			<th
				mat-header-cell
				*matHeaderCellDef
				class="l-sorting-column"
			></th>
			<td
				mat-cell
				*matCellDef="let scheduleContent"
				class="l-sorting-column"
				[ngClass]='{
					"expansion-parent": expandedDetailElement !== scheduleContent.value.contentUid, 
					"current-expansion-parent": expandedDetailElement === scheduleContent.value.contentUid
				}'
			>
				<img
					src="./assets/icons/dots-nine.svg"
					alt="Drag Icon"
					class="l-info-icon g-move"
					(mousedown)="dragDisabled = false;"
					(touchstart)="dragDisabled = false;"
				>
			</td>
		</ng-container>
		<ng-container matColumnDef="isExpanded">
			<th
				mat-header-cell
				*matHeaderCellDef
				class="l-expanded-column"
			></th>
			<td
				mat-cell
				*matCellDef="let scheduleContent"
				class="l-expanded-column"
				[ngClass]='{
					"expansion-parent": expandedDetailElement !== scheduleContent.value.contentUid, 
					"current-expansion-parent": expandedDetailElement === scheduleContent.value.contentUid
				}'
			>
				<mat-icon
					[@rotateIcon]="expandedDetailElement !== scheduleContent.value.contentUid ? 'default' : 'rotated'"
				>chevron_right</mat-icon>
			</td>
		</ng-container>
		<ng-container matColumnDef="name">
			<th
				mat-header-cell
				*matHeaderCellDef
			> Name </th>
			<td
				mat-cell
				*matCellDef="let scheduleContent"
				id="schedule-content-name"
				[ngClass]='{
					"expansion-parent": expandedDetailElement !== scheduleContent.value.contentUid, 
					"current-expansion-parent": expandedDetailElement === scheduleContent.value.contentUid
				}'
			>{{scheduleContent.value.name}}</td>
		</ng-container>
		<ng-container matColumnDef="visibility">
			<th
				mat-header-cell
				*matHeaderCellDef
			> Visibility </th>
			<td
				mat-cell
				*matCellDef="let scheduleContent"
				[ngClass]='{
					"expansion-parent": expandedDetailElement !== scheduleContent.value.contentUid, 
					"current-expansion-parent": expandedDetailElement === scheduleContent.value.contentUid
				}'
			><mat-slide-toggle
					class="standard-toggle"
					(click)="$event.stopPropagation()"
					[formControl]="scheduleContent.get('isVisible')"
					id="schedule-content-visibility"
				></mat-slide-toggle>
			</td>
		</ng-container>
		<ng-container matColumnDef="delete">
			<th
				mat-header-cell
				*matHeaderCellDef
			></th>
			<td
				mat-cell
				*matCellDef="let scheduleContent"
				[ngClass]='{
					"expansion-parent": expandedDetailElement !== scheduleContent.value.contentUid, 
					"current-expansion-parent": expandedDetailElement === scheduleContent.value.contentUid
				}'
			>
				<mat-icon
					(click)="handleDeleteTabContentType(scheduleContent)"
					id="schedule-content-delete"
				>delete_outline</mat-icon>
			</td>
		</ng-container>
		<ng-container matColumnDef="expandedDetail">
			<td
				mat-cell
				*matCellDef="let scheduleContent"
				[attr.colspan]="displayColumns.length"
				class="l-expanded-container"
			>
				<div
					[@detailExpand]="scheduleContent.value.contentUid == expandedDetailElement ? 'expanded' : 'collapsed'">
					<div>
						<app-mat-text-control-field
							[label]="'Name'"
							[placeholder]="'Name'"
							[control]="scheduleContent.get('name')"
							[outerClass]="'pb-3'"
							id="schedule-content-name"
							[required]="true"
							labelClass="g-form-label"
						>
						</app-mat-text-control-field>
					</div>
					<app-text-editor
						class="pb-3"
						label="Details"
						[control]="scheduleContent.get('description')"
						[editorStyle]="{'height': '320px'}"
						[imageUploadHandler]="editorImageUploadHandler"
						[required]="false"
						id="schedule-content-wysiwyg"
					></app-text-editor>
					<app-image-upload
						[eventId]="eventId"
						[label]="'Schedule Image'"
						[maxFileSize]="imageService.maxImageSize"
						[image]="scheduleContent.value.originalImage"
						[imageServiceType]="ImageServiceTypeEnum.CONTENT_TAB"
						[showPreferredResText]="false"
						[uploadText]="'This will appear on your event description page in this tab. \n'"
						[isMobile]="isMobile"
						[tabContentUid]="scheduleContent.value.contentUid"
						[imagePath]="tabbedContentService.getImagePreview(scheduleContent)"
						[handleTabContent]="true"
						[(isUploading)]="isImageUploading"
						(removeHandler)="tabbedContentService.onImageRemove(scheduleContent)"
						(handleTabUpload)="tabbedContentService.onSingleImageUpload($event, scheduleContent)"
						(maxFileSizeAchieved)="imageService.onImageMaxSizeAchived()"
						[isRound]="true"
						(isLoadingChange)="handleLoading($event)"
						class="l-listing-image pb-3"
						id="schedule-content-scheduleImage"
					>
					</app-image-upload>
					<div class="pb-2" [ngClass]="{'l-calendar-row' : !isMobile, 'l-calendar-mobile': isMobile}">
						<div [ngClass]="{'l-calendar-container': !isMobile, 'l-calendar-mobile-container': isMobile}">
							<label class='g-form-label'>Dates <span class='g-orange'>*</span></label>
							<div [ngClass]="{'l-date-container': !isMobile, 'l-date-container-mobile': isMobile}">
								<div class="l-datetime-container">
									<div class="l-date-input-container">
										<div class="l-date-input">
											<mat-icon class='l-icon pr-3'>calendar_today</mat-icon>
											<input
												[ngxMatDatetimePicker]="dateFromPicker"
												[disabled]="false"
												placeholder="Date From"
												(click)="dateFromPicker.open()"
												class="form-control"
												[formControl]="scheduleContent.get('dateFrom')"
												id="schedule-content-dateFrom"
											>
											<ngx-mat-datetime-picker
												#dateFromPicker
												[showSeconds]="false"
												[touchUi]="isMobile"
												[enableMeridian]="false"
												[disableMinute]="false"
												[hideTime]="false"
												[matDatepickerPosition]="'below'"
												[showSpinners]="false"
											>
												<ngx-mat-datepicker-actions>
													<button
														id="close-datepicker"
														class="g-secondary-button g-datepicker-button"
														ngxMatDatepickerCancel
													>CANCEL</button>
													<button
														id="apply-datepicker"
														class="g-primary-button g-datepicker-button"
														ngxMatDatepickerApply
													>APPLY</button>
												</ngx-mat-datepicker-actions>
											</ngx-mat-datetime-picker>
										</div>
										<small
											class="g-error-input"
											*ngIf="isControlInvalid(scheduleContent.get('dateFrom'))"
										>
											*{{getError(scheduleContent.get('dateFrom').errors)}}
										</small>
								</div>
							</div>
							<div [ngClass]="{'l-date-container': !isMobile, 'l-date-container-mobile pt-3': isMobile}">
								<div class="l-datetime-container">
									<div class="l-date-input-container">
										<div class="l-date-input">
											<mat-icon class='l-icon'>calendar_today</mat-icon>
											<input
												[ngxMatDatetimePicker]="dateToPicker"
												[disabled]="false"
												placeholder="Date To"
												(click)="dateToPicker.open()"
												class="form-control"
												[formControl]="scheduleContent.get('dateTo')"
												id="schedule-content-dateTo"
											>
											<ngx-mat-datetime-picker
												#dateToPicker
												[showSeconds]="false"
												[touchUi]="isMobile"
												[enableMeridian]="false"
												[disableMinute]="false"
												[hideTime]="false"
												[matDatepickerPosition]="'below'"
												[showSpinners]="false"
											>
												<ngx-mat-datepicker-actions>
													<button
														id="close-datepicker"
														class="g-secondary-button g-datepicker-button"
														ngxMatDatepickerCancel
													>CANCEL</button>
													<button
														id="apply-datepicker"
														class="g-primary-button g-datepicker-button"
														ngxMatDatepickerApply
													>APPLY</button>
												</ngx-mat-datepicker-actions>
											</ngx-mat-datetime-picker>
										</div>
										<small
											class="g-error-input"
											*ngIf="isControlInvalid(scheduleContent.get('dateTo'))"
										>
											*{{getError(scheduleContent.get('dateTo').errors)}}
										</small>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
</div>
</td>
</ng-container>
<tr
	mat-header-row
	*matHeaderRowDef="displayColumns"
></tr>
<tr
	mat-row
	*matRowDef="let scheduleContent; columns: displayColumns;"
	class='g-pointer'
	(click)="handleExpandContent(scheduleContent)"
	cdkDrag
>
</tr>
<tr
	mat-row
	*matRowDef="let scheduleContent; columns: ['expandedDetail']"
	class="l-detail-row"
	[class.collapsed-row]="expandedDetailElement !== scheduleContent.value.contentUid"
></tr>
</table>
</div>