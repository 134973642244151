import { OrganiserState } from '@app/models/store.model';
import { initialState } from './initialState';
import { Actions } from '@app/store/actions/organiser/organiser.actions';
import { OrganiserActionsConstants } from '@app/store/actions/organiser/organiser.actions.constants';
import { combineReducers } from '@ngrx/store';

const filteredProducts = (
	state = initialState.events.filteredProducts,
	action: Actions
): OrganiserState['events']['filteredProducts'] => {
	switch (action.type) {
		case OrganiserActionsConstants.GET_FILTERED_PRODUCTS_SUCCESS:
			return {
				...state,
				userProducts: action.payload.userProducts,
				totalProducts: action.payload.totalProducts,
			};
		default:
			return state;
	}
};

const organiserNames = (
	state = initialState.events.organiserNames,
	action: Actions
): OrganiserState['events']['organiserNames'] => {
	switch (action.type) {
		case OrganiserActionsConstants.GET_ORGANISER_NAMES_SUCCESS:
			return action.payload.organiserNames;
		default:
			return state;
	}
};

export const events = combineReducers({
	filteredProducts: filteredProducts,
	organiserNames: organiserNames,
});
