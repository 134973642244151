export enum TicketsActionsConstant {
	GET_CURRENCIES = 'GET_CURRENCIES',
	GET_CURRENCIES_SUCCESS = 'GET_CURRENCIES_SUCCESS',
	GET_CURRENCIES_FAILED = 'GET_CURRENCIES_FAILED',

	GET_SEATING_CATEGORIES = 'GET_SEATING_CATEGORIES',
	GET_SEATING_CATEGORIES_SUCCESS = 'GET_SEATING_CATEGORIES_SUCCESS',
	GET_SEATING_CATEGORIES_FAILED = 'GET_SEATING_CATEGORIES_FAILED',

	GET_EVENT_TICKETS = 'GET_EVENT_TICKETS',
	GET_EVENT_TICKETS_SUCCESS = 'GET_EVENT_TICKETS_SUCCESS',
	GET_EVENT_TICKETS_FAILED = 'GET_EVENT_TICKETS_FAILED',

	STOP_TICKET_SALES = 'STOP_TICKET_SALES',
	STOP_TICKET_SALES_SUCCESS = 'STOP_TICKET_SALES_SUCCESS',
	STOP_TICKET_SALES_FAILED = 'STOP_TICKET_SALES_FAILED',

	MARK_TICKETS_AS_SOLD_OUT = 'MARK_TICKETS_AS_SOLD_OUT',
	MARK_TICKETS_AS_SOLD_OUT_SUCCESS = 'MARK_TICKETS_AS_SOLD_OUT_SUCCESS',
	MARK_TICKETS_AS_SOLD_OUT_FAILED = 'MARK_TICKETS_AS_SOLD_OUT_FAILED',

	GET_TICKET_SETTINGS = 'GET_TICKET_SETTINGS',
	GET_TICKET_SETTINGS_SUCCESS = 'GET_TICKET_SETTINGS_SUCCESS',
	GET_TICKET_SETTINGS_FAILED = 'GET_TICKET_SETTINGS_FAILED',

	UPDATE_TICKET_SETTINGS = 'UPDATE_TICKET_SETTINGS',
	UPDATE_TICKET_SETTINGS_FAILED = 'UPDATE_TICKET_SETTINGS_FAILED',
	UPDATE_TICKET_SETTINGS_SUCCESS = 'UPDATE_TICKET_SETTINGS_SUCCESS',
}
