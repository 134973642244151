import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { UserControlPanelSidebarComponent } from './user-control-panel-sidebar/user-control-panel-sidebar.component';
import { PanelMenuModule } from '@app/shared/panelMenu/panel-menu.component';
import { UserControlPanelHeaderComponent } from './user-control-panel-header/user-control-panel-header.component';

@NgModule({
	declarations: [
		UserControlPanelSidebarComponent,
		UserControlPanelHeaderComponent,
	],
	imports: [
		SharedModule,
		PanelMenuModule,
	],
	exports: [
		UserControlPanelSidebarComponent,
		UserControlPanelHeaderComponent,
	],
})
export class UserControlPanelSharedModule {}
