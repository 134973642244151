<div
	*ngIf="!isTabsLoading else tabsListLoading"
	class="d-flex flex-column"
>
	<app-web-filter
		[searchPlaceholder]="searchPlaceholder"
		[isMobile]="isMobile"
		(buttonClick)="handleAddTab()"
		(filterOutput)="handleWebFilterOutput($event)"
		[buttonText]="webFilterButtonText"
	></app-web-filter>
	<div class="l-table-container pb-3">
		<table
			mat-table
			[dataSource]="tabs"
			*ngIf="tabs.length && !noResults; else noTabs"
			class='l-table web'
			matSort
			cdkDropList
			(cdkDropListDropped)="reOrderTabs($event)"
			[cdkDropListDisabled]="dragDisabled"
			#tabsTable
		>
			<ng-container matColumnDef="sorting">
				<th
					mat-header-cell
					*matHeaderCellDef
					class="l-sorting-column"
				></th>
				<td
					mat-cell
					*matCellDef="let tab"
					class="l-sorting-column"
				>
					<img
						*ngIf="!hasSearchTerm"
						src="./assets/icons/dots-nine.svg"
						alt="Drag Icon"
						class="l-info-icon g-move"
						(mousedown)="dragDisabled = false;"
						(touchstart)="dragDisabled = false;"
					>
				</td>
			</ng-container>
			<ng-container matColumnDef="tabName">
				<th
					mat-header-cell
					*matHeaderCellDef
				> Tab Name </th>
				<td
					mat-cell
					*matCellDef="let tab; index as i"
					[attr.id]="'tabName-' + i"
				>{{tab.name}}</td>
			</ng-container>
			<ng-container matColumnDef="tabType">
				<th
					mat-header-cell
					*matHeaderCellDef
				> Tab Type </th>
				<td
					mat-cell
					*matCellDef="let tab; ; index as i"
					[attr.id]="'tabType-' + i"
				>{{ tabContentTypeMap.get(tab.type) }}</td>
			</ng-container>
			<ng-container matColumnDef="visible">
				<th
					mat-header-cell
					*matHeaderCellDef
				> Visible </th>
				<td
					mat-cell
					*matCellDef="let tab; index as i"
					[attr.id]="'visible-' + i"
				>
					<mat-checkbox
						[checked]="tab.isVisible"
						(click)="handleTabVisibility(tab)"
					></mat-checkbox>
				</td>
			</ng-container>
			<ng-container matColumnDef="actions">
				<th
					mat-header-cell
					*matHeaderCellDef
				>Actions</th>
				<td
					mat-cell
					*matCellDef="let tab; index as i"
					[attr.id]="'actions-' + i"
				>
					<button
						mat-icon-button
						[matMenuTriggerFor]="menu"
					>
						<mat-icon>more_vert</mat-icon>
					</button>
					<mat-menu #menu="matMenu">
						<button
							mat-menu-item
							(click)="handleEditTab(tab)"
						>
							Edit
						</button>
						<button
							mat-menu-item
							(click)="handleTabDelete(tab)"
						>
							Delete
						</button>
					</mat-menu>
				</td>
			</ng-container>
			<tr
				mat-header-row
				*matHeaderRowDef="displayColumns"
			></tr>
			<tr
				mat-row
				*matRowDef="let row; columns: displayColumns;"
				cdkDrag
			></tr>
		</table>
	</div>
	<ng-template #noTabs>
		<div
			*ngIf="!noResults; else noSearchResults"
			class='l-empty-card'
			[ngClass]="{'l-empty-card--no-margin-top': isMobile}"
		>
			<div class="l-empty-state">
				<h3 class="mb-3">You have no tabs for this event</h3>
			</div>
		</div>
		<ng-template #noSearchResults>
			<div
				class='l-empty-card'
				[ngClass]="{'l-empty-card--no-margin-top': isMobile}"
			>
				<div class="l-empty-state">
					<h3 class="mb-3">Your search does not match any current tabs</h3>
				</div>
			</div>
		</ng-template>
	</ng-template>
</div>
<ng-template #tabsListLoading>
	<div class="l-loading">
		<mat-spinner></mat-spinner>
	</div>
</ng-template>