import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SchemeID } from '@app/models/dataSchema.model';
import { TabbedContentService } from '@app/services/events/manage-event/tabbed-content.service';
import { EditorImageUploadOptions } from '@app/shared/editor/editor.component';

@Component({
	selector: 'app-tab-content-content',
	templateUrl: './tab-content-content.component.html',
	styleUrls: ['./tab-content-content.component.sass'],
})
export class TabContentContentComponent {
	@Input() eventId: SchemeID;
	@Input() form: UntypedFormGroup;
	@Input() editorImageUploadHandler: EditorImageUploadOptions;


	constructor(
		private tabbedContentService: TabbedContentService
	) { }

	getContentControls(){
		return this.tabbedContentService.getTabContentControls(this.form)[0].get('description');
	}
}
