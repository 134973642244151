import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StoreService } from '@app/services/store/store.service';
import { TransferTicketsBack } from '@app/store/actions/user/user.actions';
import { UserActionsConstants } from '@app/store/actions/user/user.actions.constants';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs';

@Component({
	selector: 'app-transfer-back-modal',
	templateUrl: './transfer-back-modal.component.html',
	styleUrls: ['./transfer-back-modal.component.sass'],
})
export class TransferBackModalComponent implements OnInit {
	confirmation = false;
	isLoading = false;
	errorMessage: string;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		public store: StoreService,
		public actions$: Actions,
		public dialogRef: MatDialogRef<TransferBackModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data: {
			purchaseId: number;
			isMobile: boolean;
			eventIsCancelled: boolean;
			contactHostUrl: string;
		}) { }

	ngOnInit(): void {
		this.actions$
			.pipe(
				ofType(UserActionsConstants.TRANSFER_TICKETS_BACK_SUCCESS, UserActionsConstants.TRANSFER_TICKETS_BACK_FAILED),
				takeUntil(this.destroyed$)
			)
			.subscribe(() => {
				this.isLoading = false;
				this.dialogRef.close(true);
			});
	}

	handleCancel() {
		this.dialogRef.disableClose = false;
		this.dialogRef.close(false);
	}

	handleTransferBack() {
		this.isLoading = true;
		this.store.dispatch(new TransferTicketsBack({ purchaseId: this.data.purchaseId }));
	}

	handleContactHost() {
		window.open(this.data.contactHostUrl);
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
