import { Component, EventEmitter, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormSchemeControls } from '@app/models/common.model';
import { SchemeID } from '@app/models/dataSchema.model';
import { HeaderContent } from '@app/models/shared';
import { DraftEventDesignForm, EventDesign } from '@app/models/event.model';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { FormService } from '@app/services/form/form.service';
import { ImageServiceType, ImageString } from '@app/services/image/image.service';
import { StoreService } from '@app/services/store/store.service';
import { InternalURLCreator } from '@app/services/url/url.dictionary';
import { EditorImageUploadOptions } from '@app/shared/editor/editor.component';
import { ImageCropperType } from '@app/shared/form-field/image-cropper/image-cropper.component';
import { EventActionsConstants } from '@app/store/actions/event/event.actions.constants';
import { EVENT_ID_PARAM_KEY } from '@app/utils/consts';
import { Actions, ofType } from '@ngrx/effects';
import { Observable, take, takeUntil } from 'rxjs';
import * as eventSelectors from '@app/store/selectors/event.selector';
import { GetEventDesign, UpdateEventDesign } from '@app/store/actions/event/event.actions';
import { MatDialog } from '@angular/material/dialog';
import { PreviewComponent } from '@app/shared/preview-modal/preview-modal.component';
import { FormCanDeactivate } from '@app/services/guards/event.guard';
import { ConfirmationModalComponent } from '@app/shared/modals/confirmation-modal/confirmation-modal.component';

@Component({
	selector: 'app-event-design',
	templateUrl: './event-design.component.html',
	styleUrls: ['./event-design.component.sass'],
})
export class EventDesignComponent extends FormCanDeactivate implements OnInit {
	croppedImage = '';
	isMobile = false;
	isLoading = false;
	isButtonLoading = false;
	eventId: SchemeID;
	banner: ImageCropperType = ImageCropperType.BANNER;
	editorImageUploadHandler: EditorImageUploadOptions;
	form: UntypedFormGroup;
	ImageServiceTypeEnum = ImageServiceType;

	headerContent: HeaderContent = {
		breadCrumbs: [
			{
				routeName: 'Manage Event',
				routeTo: () => InternalURLCreator.manageEvent(this.eventId),
			},
			{
				routeName: 'Design',
			},
		],
		title: 'Design',
		description: `Customize your event page by uploading a banner and choosing a theme color for the text headers,
		buttons and links. Both are optional but can add a nice touch to your page.
		We suggest choosing a theme colour that stands out and complements your banner.`,
	};

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private activatedRoute: ActivatedRoute,
		private breakpointService: BreakpointService,
		private actions$: Actions,
		private storeService: StoreService,
		private formService: FormService,
		private dialog: MatDialog
	) {
		super();
	}

	ngOnInit(): void {
		this.activatedRoute.parent.paramMap.pipe(take(1)).subscribe((params) => {
			this.eventId = +params.get(EVENT_ID_PARAM_KEY);
		});

		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});

		this.initializePageData();

		this.actions$.pipe(
			ofType(EventActionsConstants.UPDATE_EVENT_DESIGN_SUCCESS, EventActionsConstants.UPDATE_EVENT_DESIGN_FAILED),
			takeUntil(this.destroyed$)
		)
			.subscribe(() => {
				if (this.form) {
					this.form.markAsPristine();
				}
				this.isButtonLoading = false;
			});
	}

	initializePageData() {
		this.isLoading = true;

		this.storeService.select(
			eventSelectors.eventDesign(this.eventId))
			.pipe(takeUntil(this.destroyed$))
			.subscribe(
				(design) => {
					if (design) {
						this.form = this.createEventDesignForm(design);
					}
				}
			);

		this.actions$
			.pipe(
				ofType(EventActionsConstants.GET_EVENT_DESIGN_SUCCESS, EventActionsConstants.GET_EVENT_DESIGN_FAILED),
				takeUntil(this.destroyed$)
			)
			.subscribe(() => {
				this.isLoading = false;
			});

		this.storeService.dispatch(new GetEventDesign({id: this.eventId}));
	}

	fromForm(controlKey: string): AbstractControl {
		if (this.form) {
			return this.form.get(controlKey);
		}
		return null;
	}

	setFormValue(controlKey: string, value: any) {
		this.fromForm(controlKey).setValue(value);
	}

	handleOnSubmit() {
		this.isButtonLoading = true;
		this.storeService.dispatch(new UpdateEventDesign({
			id: this.eventId,
			design: this.form.getRawValue(),
		}));
	}

	createEventDesignForm = (initialValues?: Partial<EventDesign>) => {
		const formValues = new DraftEventDesignForm();
		const formConfig: Partial<FormSchemeControls<EventDesign>> =
		this.formService.createFormControls({
			formValues,
			initialValues,
			validatorsConfig: {},
		});
		return this.formService.formBuilder.group(formConfig);
	};

	handleImageCropChange(base64: string) {
		if (base64) {
			this.setFormValue('imageString', base64);
			this.form.markAsDirty();
		}
	}

	handleImageStringChange(imageString: ImageString) {
		this.form.markAsDirty();
		this.setFormValue('imageString', imageString.base64);
	}

	handleOriginalImageChange(originalImage?: string) {
		this.setFormValue('originalImage', originalImage);
	}

	handleThumbnailChange(thumbnail: string) {
		this.setFormValue('thumbnail', thumbnail);
	}

	handleImageRemove() {
		this.setFormValue('originalImage', null);
		this.setFormValue('thumbnail', null);
		this.setFormValue('imageString', null);
		this.form.markAsDirty();
	}

	handlePreviewOpen() {
		this.dialog.open(PreviewComponent, {
			data: {
				eventId: this.eventId,
			},
			panelClass: 'g-full-page-dialog',
		});
	}

	isFormDeactivatable(): boolean | Observable<boolean> {
		return !this.form || this.form.pristine;
	}

	deactivationFallback(): Observable<boolean> {
		this.dialog
			.open(ConfirmationModalComponent, {
				data: {
					title: 'Unsaved Changes',
					text: 'Are you sure you want to leave this page? Any unsaved changes will be discarded.',
					buttonText: 'LEAVE',
					centerText: true,
					isMobile: this.isMobile,
				},
				panelClass: 'g-standard-dialog',
			})
			.afterClosed()
			.subscribe((result) => {
				this.closeDeactivationModal(result);
			});
		return this.modalCallback.asObservable();
	}

	closeDeactivationModal(isConfirmed: boolean): void {
		this.modalCallback.next(isConfirmed);
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
