import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ManageBookingTicket, ManageBookingTicketQuestion } from '@app/models/user.model';
import { StoreService } from '@app/services/store/store.service';
import { URLCreator } from '@app/services/url/url.dictionary';
import { DownloadTicketsSeperately } from '@app/user-features/manage-booking/manage-booking.actions';
import { Actions } from '@ngrx/effects';
import { interval } from 'rxjs';

@Component({
	selector: 'app-ticket-details-modal',
	templateUrl: './ticket-details-modal.component.html',
	styleUrls: ['./ticket-details-modal.component.sass'],
})
export class TicketDetailsModalComponent implements OnInit {
	password: string;
	confirmation = false;
	isLoading = true;
	errorMessage: string;

	downloadSeparately = false;

	selectedTickets: number[] = [];
	validTickets: ManageBookingTicket[] = [];
	invalidTickets: ManageBookingTicket[] = [];
	isAllSelected = false;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	displayedColumns: string[] = [];
	invalidDisplayedColumns: string[] = [];

	constructor(
		public store: StoreService,
		public actions$: Actions,
		public dialogRef: MatDialogRef<TicketDetailsModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data: {
			isMobile: boolean;
			tickets: ManageBookingTicket[];
			id: number;
			encrypt: string;
			reference: string;
		}) { }

	ngOnInit(): void {
		this.data.tickets.forEach((ticket) =>
			ticket.isValid ? this.validTickets.push(ticket) : this.invalidTickets.push(ticket)
		);
		this.updateDisplayedColumns();

		this.isLoading = false;
	}

	handleCancel() {
		this.dialogRef.close();
	}

	handleViewTicketPDF() {
		window.open(URLCreator.viewTickets(this.data.id, this.data.encrypt));
	}

	handleSelectAll(checked: boolean) {
		if (!checked) {
			this.selectedTickets = [];
		} else {
			this.selectedTickets = this.validTickets.map(ticket => ticket.id as number);
		}
	}

	handleCheckboxChange(ticket: number) {
		const index = this.selectedTickets.indexOf(ticket);
		if (index === -1) {
			this.selectedTickets.push(ticket);
		} else {
			this.selectedTickets.splice(index, 1);
		}

		if (this.selectedTickets.length < 1) {
			this.isAllSelected = false;
		}
	}

	handleEventLink(url: string) {
		window.open(url);
	}

	handleDownload() {
		if (this.downloadSeparately && this.selectedTickets.length > 10) {
			this.store.dispatch(new DownloadTicketsSeperately({
				ticketsInfo: {
					purchaseId: this.data.id,
					purchaseItemIds: this.selectedTickets,
				},
			}));
		} else if (this.downloadSeparately) {
			this.downloadTicketsSeparately();
		} else {
			this.downloadAll();
		}
	}

	downloadTicketsSeparately() {
		const urlArray = [];
		this.selectedTickets.forEach(ticketId => {
			URLCreator.downloadAllTickets(this.data.id, this.data.encrypt);
			const path = URLCreator.downloadAllTickets(this.data.id, this.data.encrypt) + '&pits=' + ticketId;
			urlArray.push(path);
		});

		urlArray.forEach(url => {
			window.open(url);
			interval(1000);
		});
	}

	downloadAll() {
		const ticketIds = this.selectedTickets.join('|');
		const path = URLCreator.downloadAllTickets(this.data.id, this.data.encrypt) + '&pits=' + ticketIds;
		window.open(path);
	}

	updateDisplayedColumns() {
		const hasStreamingTicket = this.data.tickets.some(ticket => ticket.isStreamingTicket);
		this.displayedColumns = ['download', 'ticketHolder', 'ticketType', 'ticketNumber'];
		this.invalidDisplayedColumns = this.displayedColumns.filter((column) => column !== 'download');
		if (hasStreamingTicket) {
			this.displayedColumns.push('streamAccessCode', 'streamAccessUrl');
		}
	}

	getTicketHolderName(questions: ManageBookingTicketQuestion[]): string {
		if (!questions) {
			return '--';
		};

		const firstNameQuestion = questions.find(question => question.question === 'First name');
		const surnameQuestion = questions.find(question => question.question === 'Surname');

		const firstName = firstNameQuestion && firstNameQuestion.answer ? firstNameQuestion.answer : '';
		const surname = surnameQuestion && surnameQuestion.answer ? surnameQuestion.answer : '';

		if (!firstName && !surname) {
			return '--';
		};

		return `${firstName} ${surname}`.trim();
	}

	isSelected(ticketId: number): boolean {
		return this.selectedTickets.includes(ticketId);
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
