import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DropdownOption } from '@app/models/shared';
import { handleOrder } from '@app/utils/common-helpers';
import { Dictionary } from '@ngrx/entity';

@Component({
	selector: 'app-dropdown-button',
	templateUrl: './dropdown-button.component.html',
	styleUrls: ['./dropdown-button.component.sass'],
	animations: [
		trigger('rotateIcon', [
			state('default', style({
				transform: 'rotate(270deg)',
			})),
			state('rotated', style({
				transform: 'rotate(360deg)',
			})),
			transition('default <=> rotated', animate('300ms ease-out')),
		]),
	],

})
export class DropdownButtonComponent {
	@Input() options: Dictionary<DropdownOption>;
	@Input() buttonText: string;
	@Input() smallButton = false;
	@Input() isLoading = false;
	@Output() optionSelected: EventEmitter<DropdownOption> = new EventEmitter();
	isOpen = false;
	handleDefaultOrder = handleOrder;

	handleMenuToggle() {
		this.isOpen = !this.isOpen;
	}

	handleClick(option: DropdownOption) {
		this.optionSelected.emit(option);
	}

}
