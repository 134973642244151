import { Component, EventEmitter, Input } from '@angular/core';
import { SectionRouteCard } from '@app/models/shared';
import { OverlayService } from '@app/services/overlay/overlay.service';
import { ORGANISER_NAV, QUICKET_LOGO } from '@app/utils/consts';
import { Dictionary } from '@ngrx/entity';
import { MobileNavMenuComponent } from '../mobile-nav-menu/mobile-nav-menu.component';
import { StoreService } from '@app/services/store/store.service';
import { Go } from '@app/store/actions/router/router.actions';

@Component({
	selector: 'app-organiser-mobile-header',
	templateUrl: './organiser-mobile-header.component.html',
	styleUrls: ['./organiser-mobile-header.component.sass'],
})
export class OrganiserMobileHeaderComponent {
	@Input() currentNavItem: SectionRouteCard;
	@Input() currentSubNavItem: SectionRouteCard;
	@Input() isSecondaryPage = false;
	navItems: Dictionary<SectionRouteCard> = ORGANISER_NAV;
	QUICKET_LOGO = QUICKET_LOGO;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private overlayService: OverlayService,
		private store: StoreService
	) { }

	handleNavMenu() {
		this.overlayService.open(MobileNavMenuComponent, {currentNavItem: this.currentNavItem});
	}

	handleNavigateBack() {
		this.store.dispatch(new Go({ path: [this.currentSubNavItem.routeTo()] }));
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
