import { NewPromoterNetworkInfo, PromoterNetworkInfo, PromoterNetworksPageInfo } from './../../../../../models/event.model';
import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { FilterOptions, HeaderContent, SectionRouteCard } from '@app/models/shared';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { StoreService } from '@app/services/store/store.service';
import { InternalURLCreator } from '@app/services/url/url.dictionary';
import { Go } from '@app/store/actions/router/router.actions';
import { EVENT_ID_PARAM_KEY } from '@app/utils/consts';
import { Actions, ofType } from '@ngrx/effects';
import { take, takeUntil } from 'rxjs';
import { CreatePromoterNetworkModalComponent } from '../../modals/create-promoter-network-modal/create-promoter-network-modal.component';
import { EventActionsConstants } from '@app/store/actions/event/event.actions.constants';
import * as eventSelectors from '@app/store/selectors/event.selector';
import { CreatePromoterNetwork, GetEventPromoterNetworks } from '@app/store/actions/event/event.actions';
import { ConfirmationModalComponent } from '@app/shared/modals/confirmation-modal/confirmation-modal.component';
import { SharePromoterNetworkModalComponent } from '../../modals/share-promoter-network-modal/share-promoter-network-modal.component';

@Component({
	selector: 'app-promoter-networks',
	templateUrl: './promoter-networks.component.html',
	styleUrls: ['./promoter-networks.component.sass'],
})
export class PromoterNetworksComponent implements OnInit {

	isMobile: boolean;
	eventId: number;
	destroyed$: EventEmitter<void> = new EventEmitter<void>();
	isProductsLoading = true;
	isProductsFiltering: boolean;

	hasNetworks = false;
	networks: MatTableDataSource<PromoterNetworkInfo> = new MatTableDataSource();
	promoterNetworksPageInfo: PromoterNetworksPageInfo;

	customDescription = 'Promoter networks are a powerful way to incentivise a group of people to...';
	readMore: boolean;
	readMoreText = 'Read More';
	includeReadMore = true;

	displayColumns: string[] = ['name', 'totalMembers', 'totalVisits', 'totalSales', 'commission', 'totalPaidOut', 'actions'];
	pageSizeOptions = [4, 8, 12];
	paginatorInitialized = false;
	sortInitialized = false;

	headerContent: HeaderContent = {
		breadCrumbs: [
			{
				routeName: 'Marketing',
				routeTo: () => InternalURLCreator.marketing(this.eventId),
			},
			{
				routeName: 'Promoter Networks',
			},
		],
		title: 'Promoter Networks',
		description: `Promoter networks are a powerful way to incentivise a group of people to
		promote your event to their own networks.`,
	};

	@ViewChild('productsPaginator') set paginator(_pagination: MatPaginator) {
		if (_pagination && !this.paginatorInitialized) {
			this.networks.paginator = _pagination;
		};
	}

	@ViewChild(MatSort) set sort(_sort: MatSort) {
		if (_sort && !this.sortInitialized) {
			this.sortInitialized = true;
			this.networks.sort = _sort;
			this.networks.sortingDataAccessor = (item, property) => item[property];
		}
	};

	constructor(
		private breakpointService: BreakpointService,
		private store: StoreService,
		private activatedRoute: ActivatedRoute,
		private actions$: Actions,
		private dialog: MatDialog
	) { }

	ngOnInit(): void {
		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});

		this.activatedRoute.parent.paramMap.pipe(take(1)).subscribe((params) => {
			this.eventId = +params.get(EVENT_ID_PARAM_KEY);
			this.store.dispatch(new GetEventPromoterNetworks({ id: this.eventId }));
		});

		this.actions$
			.pipe(
				ofType(EventActionsConstants.GET_EVENT_PROMOTER_NETWORKS_SUCCESS,
					EventActionsConstants.GET_EVENT_PROMOTER_NETWORKS_FAILED,
					EventActionsConstants.CREATE_PROMOTER_NETWORK_SUCCESS,
					EventActionsConstants.CREATE_PROMOTER_NETWORK_FAILED),
				takeUntil(this.destroyed$)
			)
			.subscribe(() => {
				this.isProductsLoading = false;
				this.isProductsFiltering = false;
			});

		this.store.select(eventSelectors.eventPromoterNetworks(this.eventId)).pipe(takeUntil(this.destroyed$))
			.subscribe((promoterNetworksPageInfo) => {
				if (promoterNetworksPageInfo) {
					this.promoterNetworksPageInfo = promoterNetworksPageInfo;
					this.networks.data = [...promoterNetworksPageInfo.networks].reverse();
					this.hasNetworks = this.promoterNetworksPageInfo.networks.length > 0;
					if (this.promoterNetworksPageInfo.mostInfluence.length > 18){
						this.promoterNetworksPageInfo = {
							...this.promoterNetworksPageInfo,
							mostInfluence: this.promoterNetworksPageInfo.mostInfluence.slice(0, 18) + '...',
						};
					}
				}
			});
	}

	openCreateNetworkModal() {
		this.dialog.open(CreatePromoterNetworkModalComponent,
			{
				data: {
					isMobile: this.isMobile,
					canEditReferralAmount: true,
					actionType: 'CREATE',
					modalTitle: 'Create Promoter Network',
					modalDescription: `This is the basic details page for your promoter network and allows you to
					configure your promoter network settings. Once setup, you can invite people to
					join your network.`,
				},
				panelClass: this.isMobile ? 'g-full-page-dialog' : 'g-standard-dialog',
			}
		)
			.afterClosed()
			.subscribe((newPromoter) => {
				if (newPromoter) {
					this.store.dispatch(new CreatePromoterNetwork({ id: this.eventId, newPromoterNetworkInfo: newPromoter }));
				}
			});
	}

	handleEdit(network: PromoterNetworkInfo) {
		this.dialog.open(CreatePromoterNetworkModalComponent,
			{
				data: {
					isMobile: this.isMobile,
					network: network,
					canEditReferralAmount: network.totalSales === 0,
					actionType: 'EDIT',
					modalTitle: 'Edit Promoter Network',
					modalDescription: `This is the basic details page for your promoter network and allows you to
					edit your promoter network settings.`,
				},
				panelClass: this.isMobile ? 'g-full-page-dialog' : 'g-standard-dialog',
			}
		)
			.afterClosed()
			.subscribe((newPromoter) => {
				if (newPromoter) {
					this.store.dispatch(new CreatePromoterNetwork({ id: this.eventId, newPromoterNetworkInfo: newPromoter }));
				}
			});
	}

	handleDelete(network: PromoterNetworkInfo) {
		this.dialog.open(ConfirmationModalComponent, {
			data: {
				title: 'Delete Network?',
				text: 'Are you sure you want to delete this network?',
				buttonText: 'YES',
				isMobile: this.isMobile,
			},
			panelClass: 'g-standard-dialog',
		}
		)
			.afterClosed().subscribe((result) => {
				if (result) {
					const newPromoter: NewPromoterNetworkInfo = {
						name: network.name,
						referralAmount: network.referralAmount / 100,
						additionalNotes: network.notes,
						openToPublic: network.isPublic,
						id: network.id,
						deleted: true,
					};
					this.store.dispatch(new CreatePromoterNetwork({ id: this.eventId, newPromoterNetworkInfo: newPromoter }));
				}
			});
	}

	handleShare(network: any) {
		this.dialog.open(SharePromoterNetworkModalComponent, {
			data: {
				isMobile: this.isMobile, shortLink: network.shortLink,
			},
			panelClass: 'g-standard-dialog',
		}
		);
	}

	handlePromoterNetworkRedirect(promoterNetworkId: number, networkName: string) {
		this.store.dispatch(new Go(
			{ path: [InternalURLCreator.eventPromoterNetwork(this.eventId, `${promoterNetworkId}-${networkName}`)] }));
	}

	handleReadMore() {
		this.readMore = !this.readMore;
		this.readMoreText = this.readMore ? 'Read Less' : 'Read More';
	}

	handleNavigate(card: SectionRouteCard): void {
		this.store.dispatch(new Go({ path: [card.routeTo(this.eventId)] }));
	}

	handleFilter(filterOptions: FilterOptions) {
		const { searchTerm } = filterOptions;
		this.networks.filter = searchTerm.trim().toLowerCase();
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
