import { EventsState, EventEntityState } from '@app/models/store.model';

export const initialState: EventsState = {
	venues: [],
	banks: [],
	currencies: [],
	eventTypes: {
		formats: [],
		categories: [],
	},
	tickets: {},
	entities: {
		byId: {},
		all: [],
		isFetching: null,
		active: null,
	},
	helpArticle: {
		isFetching: null,
		content: null,
	},
	dashboardModals: {},
	activeStepIndex: 0,
};

export const initialEntityState: EventEntityState = {
	original: {
		seatingCategories: [],
		seatsIOChartKey: null,
	},
	profile: null,
	details: null,
	questions: [],
	ticketsDetails: null,
	seatingCategories: [],
	ticketSettings: null,
	settings: null,
	linkCampaigns: {
		campaigns: {
			byId: {},
			all: [],
		},
		isFetching: false,
		isModalOpen: false,
	},
	tabbedContent: {
		tabs: {
			byId: {},
			all: [],
		},
		isFetching: false,
		isModalOpen: false,
	},
	design: null,
	facebookIntegration: null,
	metadata: null,
	dashboard: null,
	copyInfo: null,
	dashboardReports: {
		isFetching: false,
		value: null,
	},
	isFetching: null,
	ticketGroups: {
		groups: {
			byId: {},
			all: [],
		},
		ticketTypes: {
			byId: {},
			all: [],
		},
		isFetching: false,
		isModalOpen: false,
		useTicketGroups: false,
	},
	collectInfo: {
		event: {
			byId: {},
			all: [],
		},
		profile: {
			byId: {},
			all: [],
		},
		tickets: [],
		isModalOpen: false,
	},
	eventRegistrations: {
		registrationSettings: null,
		totalRegistrations: null,
		registrations: [],
	},
	definitions: null,
	streaming: {
		settings: null,
		streams: {
			byId: {},
			all: [],
		},
		ticketTypes: [],
		isModalOpen: false,
		isFetching: false,
		eventHasBegun: false,
	},
	collections: null,
	widget: null,
	scheduleTransfer: {
		scheduleItems: {
			byId: {},
			all: [],
		},
		settings: null,
		isFetching: false,
	},
	scanners: {
		scanners: {
			byId : {},
			all : [],
		},
	},
	webhooks: {
		webhooks: null,
		isFetching: false,
		testResponse: null,
		isModalOpen: false,
		isTestModalOpen: false,
	},
	requestFieldServices: {
		ratesUrl : null,
		isFetching: false,
	},
	atTheDoor: {
		atTheDoor: null,
		isFetching: false,
	},
	orders: {
		orders: null,
		activeOrder: null,
	},
	promotersPageInfo: null,
	promoterNetworksPageInfo: null,
	guestList: {
		guestListInfo: null,
		selectedGuestInfo: null,
	},
};
