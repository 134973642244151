<div class="l-container">
	<div
		class="l-content">
		<form
			*ngIf="form"
			[formGroup]="form"
			class="l-form-container"
			[ngClass]="{ 'mt-4': isMobile }"
			id="event-details-form"
		>
			<div class="g-double-input-container w-100">
				<div
					[ngClass]="{ 'input-group-mobile': isMobile, 'input-group-web flex-grow-1': !isMobile }">
					<app-mat-text-control-field
						[label]="'Event Name'"
						[placeholder]="'Event name'"
						[control]="fromForm('name')"
						[outerClass]="''"
						id="event-name-input"
						[required]="true"
						labelClass="g-form-label"
						tooltip="Make sure that your event name is short and clear. Any additional information can be added in the event description."
						#eventName
					>
					</app-mat-text-control-field>
				</div>
				<div
					[ngClass]="{ 'input-group-mobile': isMobile, 'input-group-web flex-grow-1': !isMobile }"
				>
					<label class="g-form-label">Age Restriction</label>
					<mat-select id='age-restriction-select' class="form-control w-100"
						name="ageRestriction"
						placeholder="Age Restriction"
						[formControl]="fromFeatures(ageRestrictions?.id)"
					>						
						<mat-option
							[value]="null"
							id="age-restriction-option-none"
						>
							None
						</mat-option>
						<mat-option
							*ngFor="let option of ageRestrictions?.options; let i = index"
							[value]="option"
							[id]="'age-restriction-option-' + (i+1)"
						>
							{{option}}
						</mat-option>
					</mat-select>
				</div>
			</div>
			<div class="g-double-input-container w-100">
				<div
					[ngClass]="{ 'input-group-mobile': isMobile, 'input-group-web flex-grow-1': !isMobile }"
				>
					<label class="g-form-label">Event Format</label>
					<mat-select
						*ngIf="(fromForm('productType').value === ProductTypeEnum.Event)"
						id='event-format-select' class="form-control w-100"
						name="eventFormat"
						placeholder="e.g. Festival"
						[formControl]="fromForm('formatId')"
					>
						<mat-option
							*ngFor="let eventFormat of eventFormats; let i = index"
							[value]="eventFormat.value"
							[id]="'event-format-option-' + (i+1)"
						>
							{{eventFormat.label}}
						</mat-option>
					</mat-select>
				</div>
				<div
					[ngClass]="{ 'input-group-mobile': isMobile, 'input-group-web flex-grow-1': !isMobile }"
				>
					<label class="g-form-label">Event Category</label>
					<mat-select id='event-category-select' class="form-control w-100"
						name="eventCategory"
						placeholder="e.g. Music"
						[formControl]="fromForm('categoryId')"
						(selectionChange)="handleCategoryChange()"
					>
						<mat-option
							*ngFor="let category of eventCategories; let i = index"
							[value]="category.value"
							[id]="'event-category-option-' + (i+1)"
						>
							{{category.label}}
						</mat-option>
					</mat-select>
				</div>
			</div>
			<div 
				class="g-double-input-container w-100" 
				*ngIf="fromForm('categoryId').value 
						&& eventSubcategories[fromForm('categoryId').value] 
						&& eventSubcategories[fromForm('categoryId').value].length"
			>
				<div
					[ngClass]="{ 'input-group-mobile': isMobile, 'input-group-web flex-grow-1': !isMobile }"
				>
					<label class="g-form-label">Event Subcategory</label>
					<mat-select id='event-subcategory-select' class="form-control w-100"
						name="eventSubcategory"
						placeholder="e.g. Electronic"
						[formControl]="fromForm('subCategoryId')"
					>
						<mat-option
							*ngFor="let subCategory of eventSubcategories[fromForm('categoryId').value]; let i = index"
							[value]="subCategory.value"
							[id]="'event-subcategory-option-' + (i+1)"
						>
							{{subCategory.label}}
						</mat-option>
					</mat-select>
				</div>
			</div>
			<div class="w-100">
				<label class="g-form-label">
					Event Description 
					<span class="g-orange">*</span>
					<mat-icon
						class="l-tooltip-icon"
						#descriptionTooltip="matTooltip"
						[matTooltip]="'Provide as much detail as possible about your event. This will help attendees understand what to expect when booking tickets.'"
						matTooltipPosition="above"
						(click)="descriptionTooltip.toggle()"
					>
						info
					</mat-icon>
				</label>
				<app-text-editor
					[control]="fromForm('description')"
					[editorStyle]="{'height': '320px', 'width': '100%'}"
					[imageUploadHandler]="editorImageUploadHandler"
					[required]="true"
					id="event-description"
				>
				</app-text-editor>
			</div>
			<hr class="w-100 g-light-line"/>
			<!-- Banner And Listing Image -->
			<div class="w-100" [ngClass]="{'l-image-container': !isMobile}">
				<app-image-upload-and-cropper
					[label]="'Event Banner Image'"
					[uploadText]="'This will appear across the top of your event page.\n'"
					[eventId]="eventId"
					[originalImage]="fromForm('originalImage').value"
					[base64]="base64String"
					[imageServiceType]="ImageServiceTypeEnum.EVENT"
					[isMobile]="isMobile"
					(croppedBase64Change)="handleImageCrop($event)"
					(imageStringChange)="handleImageStringChange($event)"
					(originalImageChange)="handleOriginalImageChange($event)"
					(imageRemoved)="handleImageRemove()"
					(thumbnailChange)="handleThumbnailChange($event)"
					tooltip="Accepted file types are JPG, PNG, GIF and BMP. You can add or change this image at any time from the event details page."
					class="l-banner-image"
					id="event-banner-image"
				>
				</app-image-upload-and-cropper>

				<app-image-upload
					[eventId]="eventId"
					[label]="'Event Listing Image'"
					[maxFileSize]="imageService.maxImageSize"
					[image]="image"
					[imageServiceType]="ImageServiceTypeEnum.EVENT"
					[uploadText]="'This will appear on Quicket\'s event listings page if your event is Public. \n'"
					[isMobile]="isMobile"
					(uploadHandler)="imageService.onImageUploadFinish($event, eventId)"
					(removeHandler)="imageService.onImageRemove($event, eventId, ImageServiceTypeEnum.EVENT)"
					(maxFileSizeAchieved)="imageService.onImageMaxSizeAchived()"
					(formDirty)="markFormAsDirty()"
					tooltip="Accepted file types are JPG, PNG, GIF and BMP. You can add or change this image at any time from the event details page."
					class="l-listing-image"
					id="event-listing-image"
				>
				</app-image-upload>
			</div>
			<!-- Banner And Listing Image End -->
			<hr class="w-100"/>
			<div class="d-flex flex-row w-100" [ngClass]="{'l-toggle-group-mobile' : isMobile,'l-toggle-container': !isMobile}">
				<label class="pt-2 g-form-label">Display banner image in emails
					<mat-icon
						class="l-tooltip-icon"
						#bannerImageTooltip="matTooltip"
						[matTooltip]="
								fromForm('displayBannerInEmails').value 
									? 'Toggle OFF to only display banner image on the event page.' 
									: 'Toggle ON to display the event\'s banner image at the top of event related emails, such as order confirmation and complimentary ticket emails.'"
						matTooltipPosition="above"
						(click)="bannerImageTooltip.toggle()"
					>
						info
					</mat-icon>
				</label>
				<mat-slide-toggle 
					class="standard-toggle l-toggle" name="displayBanner" [formControl]="fromForm('displayBannerInEmails')"
					id="display-banner-toggle"
				>
				</mat-slide-toggle>
			</div>
			<hr class="w-100 g-light-line"/>
			<div class="g-input-container w-100">
				<div
					*ngIf="!hasSoldTickets && !fromForm('isEventFinished').value"
					[ngClass]="{ 'input-group-mobile': isMobile, 'input-group-web-fill flex-grow-1': !isMobile }"
				>
					<label class="g-form-label">Event Occurrence</label>
					<mat-select id='event-occurrence-select' class="form-control w-100"
						name="eventOccurrence"
						placeholder="Event Occurrence"
						[formControl]="fromForm('isOneDayEvent')"
						(selectionChange)="handleOccurrenceChange()"
					>
						<mat-option [value]="true" [id]="'event-occurrence-select-1'">
							Schedule A Single Event
						</mat-option>
						<mat-option [value]="false" [id]="'event-occurrence-select-2'">
							Schedule A Series Of Events
						</mat-option>
					</mat-select>
				</div>
			</div>
			<div class="g-input-container w-100">
				<app-schedule-creator
					[singleEvent]="fromForm('isOneDayEvent').value"
					[startDate]="fromForm('startDateTime')"
					[endDate]="fromForm('endDateTime')"
					[isMobile]="isMobile"
					[schedules]="getFormValue('schedules')"
					[required]="true"
					[isEventCreation]="isEventCreation"
					(submitSchedule)="addSchedules($event)"
					(markFormDirty)="markFormDirty()"
					[isEventFinished]="fromForm('isEventFinished').value"
					[hasEventSoldTickets]="fromForm('hasSoldTickets').value"
					(noValidSchedules)="handleNoValidSchedules()"
					[hasSoldTickets]="hasSoldTickets"
				>
				</app-schedule-creator>
			</div>
			<div class="w-100"
			>
				<label class="g-form-label">
					Change Timezone
					<mat-icon
						*ngIf="fromForm('hasSoldTickets').value"
						class="l-tooltip-icon g-orange"
						#timezoneWarningTooltip="matTooltip"
						[matTooltip]="'You can\'t change the timezone once tickets have been sold. If you need to change the timezone, please contact support.'"
						matTooltipPosition="above"
						(click)="timezoneWarningTooltip.toggle()"
					>
						warning
					</mat-icon>
				</label>
				<app-timezone-selector
					[options]="timezones"
					[value]="fromForm('timezone').value"
					(timezoneSelect)='handleTimezoneChange($event)'
					[disabled]="fromForm('hasSoldTickets').value || fromForm('isEventFinished').value"
					id="event-timezone-selector"
				>
				</app-timezone-selector>
			</div>
			<hr class="w-100 g-light-line"/>
			<!-- Venue -->
			<!-- Venue Option Select-->
			<div class="g-input-container w-100">
				<div
					[ngClass]="{ 'input-group-mobile': isMobile, 'input-group-web-fill flex-grow-1': !isMobile }"
				>
					<label class="g-form-label">Venue <span class="g-orange"> *</span></label>
					<mat-select id='venue-select' class="form-control w-100"
						name="venue"
						placeholder="Select venue option"
						[(ngModel)]="venueOptionSelected"
						[ngModelOptions]="{standalone: true}"
						(selectionChange)="handleVenueOptionChange()">
						<mat-option [value]="1" [id]="'venue-select-1'">
							Add a New Venue
						</mat-option>
						<mat-option [value]="2" [id]="'venue-select-2'" *ngIf="previousVenues.length">
							Use a Previous Venue
						</mat-option>
						<mat-option [value]="3" [id]="'venue-select-3'">
							No Physical Venue/Online Event
						</mat-option>
					</mat-select>
				</div>
			</div>
			<!-- Venue Option Select End-->
			<!-- Location Search -->
			<div 
				*ngIf="(venueOptionSelected && venueOptionSelected === VenueOptionEnum.Add && !isLocationAssigned)"
				class="l-container justify-content-center w-100"
			>
				<div class="g-info-block" [@expandCollapse]="(venueOptionSelected && venueOptionSelected === VenueOptionEnum.Add && !isLocationAssigned) ? 'expanded' : 'collapsed'">
					<mat-icon class="g-info-block-icon">info</mat-icon>
					<p class="g-info-block-msg mb-0">Search for your location or enter in the GPS co-ordinates of your venue and we'll generate a map for your event page using Google Maps.</p>
				</div>
				<div
					class="mb-2" [ngClass]="{'d-flex justify-content-center w-100': isMobile, 'me-auto': !isMobile}"
				>
					<div [ngClass]="{ 'w-100 l-mobile-width mx-3': isMobile }" class="l-dual-toggle-container my-4">
						<div>
							Location Search<mat-slide-toggle id='location-search-toggle' (change)="handleLocationSearchToggle()"
								name="locationSearchToggle" class="double-checked px-4"></mat-slide-toggle>GPS Coordinates
						</div>
					</div>
				</div>
			</div>
			<!-- Location Search End -->
			<div *ngIf="(venueOptionSelected && venueOptionSelected === VenueOptionEnum.Add && !isLocationAssigned && isLocationSearch)" class="g-input-container w-100">
				<app-address-autocomplete
					[isMobile]="isMobile"
					[label]="'Venue Address'"
					(addressSelected)="handleAddressSelected($event)"
					tooltip="Enter the address of the venue. If you can't find the address, use the toggle above to enter the GPS coordinates instead."
					id="venue-address-autocomplete"
				>
				</app-address-autocomplete>
			</div>
			<!-- Coordinates -->
			<div
				*ngIf="(venueOptionSelected && venueOptionSelected === VenueOptionEnum.Add && !isLocationAssigned && !isLocationSearch)"
				class='d-flex flex-wrap w-100 gap-2'
			>
				<div class="l-coordinates-container">
					<div class="l-input input-group mb-3">
						<label class="g-form-label l-icon-label">
							Address latitude
							<mat-icon
								class="l-tooltip-icon"
								#latTooltip="matTooltip"
								matTooltip="Enter the latitude of the venue. You can find this by searching for the venue on Google Maps and copying the latitude value from the URL."
								matTooltipPosition="above"
								(click)="latTooltip.toggle()"
							>
								info
							</mat-icon>
						</label>
						<input
							type="number"
							class="form-control w-100"
							[formControl]="fromForm('venue.latitude')"
							id="address-latitude-input"
						/>
					</div>
					<div class="l-input input-group mb-3">
						<label class="g-form-label l-icon-label">
							Address longitude
							<mat-icon
								class="l-tooltip-icon"
								#longTooltip="matTooltip"
								matTooltip="Enter the longitude of the venue. You can find this by searching for the venue on Google Maps and copying the longitude value from the URL."
								matTooltipPosition="above"
								(click)="longTooltip.toggle()"
							>
								info
							</mat-icon>
						</label>
						<input
							type="number"
							class="form-control w-100"
							[formControl]="fromForm('venue.longitude')"
							id="address-longitude-input"
						/>
					</div>
				</div>
				<div class="l-coordinates-button mb-3">
					<button
						class="g-secondary-button l-location-button"
						[disabled]="!isValidCoordinates()"
						[class.disabled]="!isValidCoordinates()"
						(click)="getLocationByCoords()"
						id="get-location-coordinates-button"
					>
						<mat-icon>my_location</mat-icon>
						<span class='l-get-location-text'>GET LOCATION</span>
					</button>
				</div>
			</div>
			<small
				class="g-error-input"
				*ngIf="isControlInvalid(fromForm('venue.latitude'))"
			>
				*Latitude: {{getError(fromForm('venue.latitude').errors)}}
			</small>
			<small
				class="g-error-input" 
				*ngIf="isControlInvalid(fromForm('venue.longitude'))"
			>
				*Longitude: {{getError(fromForm('venue.longitude').errors)}}
			</small>
			<!-- Coordinates End -->
			<!-- Previous Venues -->
			<div *ngIf="(previousVenues && venueOptionSelected && venueOptionSelected === VenueOptionEnum.Previous && !isLocationAssigned)" class="g-input-container w-100">
				<label class="g-form-label">Select a previous venue</label>
				<mat-select id="previous-venue-select" class="form-control w-100"
					name="previousVenue"
					placeholder="Select a previous venue"
					[ngModelOptions]="{standalone: true}"
					(selectionChange)="handlePreviousVenueSelection($event)"
				>
					<mat-option
						*ngFor="let venue of previousVenues; let i = index"
						[value]="venue.id"
						[id]="'previous-venue-option-' + (i+1)"
					>
						{{venue.name}} || <i>{{venue.longAddress}}</i>
					</mat-option>
				</mat-select>
			</div>
			<!-- Previous Venues End -->
			<!-- Online Event -->
			<div *ngIf="fromForm('isOnline').value" class="l-container justify-content-center w-100">
				<div class="g-info-block" [@expandCollapse]="fromForm('isOnline').value ? 'expanded' : 'collapsed'">
					<i class="fa-solid fa-computer g-info-block-icon"></i>
					<p class="g-info-block-msg mb-0">This event currently has no venue. If needed, you can set a venue by selecting a different option above.</p>
				</div>
			</div>
			<!-- Online Event End -->
			<!-- Venue Inputs -->
			<div
				*ngIf="isLocationAssigned"
				class="w-100"
			>
				<div class="g-double-input-container w-100">
					<app-mat-text-control-field
						[ngClass]="{ 'input-group-mobile': isMobile, 'input-group-web flex-grow-1': !isMobile }"
						[label]="'Venue Name'"
						[placeholder]="'Venue Name'"
						[control]="fromForm('venue.name')"
						[required]="true"
						[outerClass]="''"
						labelClass="g-form-label"
						tooltip="The name of the venue where your event will take place. If you are unsure of your venue name, simply use 'To be announced'."
						id="venue-name-input"
						#venueName
					>
					</app-mat-text-control-field>
					<div
						[ngClass]="{ 'input-group-mobile': isMobile, 'input-group-web flex-grow-1': !isMobile }">
						<label class="g-form-label">City</label>
						<input id='venue-city-input'
							type="text" name="venueCity"
							class="form-control" placeholder="Venue City"
							[formControl]="fromForm('venue.city')" />
					</div>
				</div>
				<div class="g-input-container w-100" [ngClass]="{'mb-2': isMobile}">
					<div
						[ngClass]="{ 'input-group-mobile': isMobile, 'input-group-web-fill flex-grow-1': !isMobile }"
					>
						<app-mat-text-control-field
							[label]="'Address Line 1'"
							[placeholder]="'Address Line 1'"
							[control]="fromForm('venue.addressLine1')"
							labelClass="g-form-label"
							id="venue-address1-input"
						>
						</app-mat-text-control-field>
					</div>
				</div>
				<div class="g-input-container w-100" [ngClass]="{'mb-2': isMobile}">
					<div
						[ngClass]="{ 'input-group-mobile': isMobile, 'input-group-web-fill flex-grow-1': !isMobile }"
					>
						<app-mat-text-control-field
							[label]="'Address Line 2'"
							[placeholder]="'Address Line 2'"
							[control]="fromForm('venue.addressLine2')"
							labelClass="g-form-label"
							id="venue-address2-input"
						>
						</app-mat-text-control-field>
					</div>
				</div>
				<div class="g-double-input-container w-100" [ngClass]="{'mb-2': isMobile}">
					<div formGroupName="venue"
						[ngClass]="{ 'input-group-mobile': isMobile, 'input-group-autocomplete flex-grow-1': !isMobile }">
						<label class="g-form-label">Country</label>
						<mat-select-country id='venue-country-input' class="form-control l-country" placeholder="Country"
							[value]="country" [countries]="COUNTRIES"
							(onCountrySelected)="handleCountrySelect($event)" autocomplete="no"></mat-select-country>
					</div>
					<div
						[ngClass]="{ 'input-group-mobile': isMobile, 'input-group-web flex-grow-1': !isMobile }"
					>
						<app-mat-text-control-field
							[label]="'Postal Code'"
							[placeholder]="'Postal Code'"
							[control]="fromForm('venue.postalCode')"
							labelClass="g-form-label"
							id="venue-psostal-code-input"
						>
						</app-mat-text-control-field>
					</div>
				</div>
				<div
					*ngIf="eventHasCoordinates && showMap"
					class="g-input-container w-100"
				>
					<google-map
						width="100%"
						height="300px"
						[center]="mapCenter"
						[zoom]="fromForm('venue.mapZoom').value"
						id="venue-maps"
					>
						<map-marker
							[position]="mapMarker.position"
							[label]="mapMarker.label"
						>
						</map-marker>
					</google-map>
				</div>
			</div>
			<hr *ngIf="!fromForm('isOnline').value" class="w-100 g-light-line"/>
			<div class="d-flex flex-row w-100" [ngClass]="{'l-toggle-group-mobile' : isMobile,'l-toggle-container': !isMobile}" *ngIf="!fromForm('isOnline').value">
				<label class="pt-2 g-form-label">
					Show map on event page
					<mat-icon
						class="l-tooltip-icon"
						#showMapTooltip="matTooltip"
						[matTooltip]="'Display a map at the bottom of your event page to show the location of your event.'"
						matTooltipPosition="above"
						(click)="showMapTooltip.toggle()"
					>
						info
					</mat-icon>
				</label>
				<mat-slide-toggle 
					class="standard-toggle l-toggle" name="showMap" [formControl]="fromForm('showMap')"
					id="show-map-toggle"
				>
				</mat-slide-toggle>
			</div>
			<!-- Venue Inputs End -->
			<!-- Venue End -->
			<hr class="w-100 g-light-line"/>
			<div class="g-input-container">
				<div [ngClass]="{ 'input-group-mobile': isMobile, 'w-100': !isMobile }">
					<label class="g-form-label">Page Listing <span class="g-orange">*</span></label>
					<div class="d-flex flex-column align-items-start">
						<mat-radio-group 
							[formControl]="fromForm('isPublic')" 
							[disabled]="isEventLockedOnPrivate()" 
							class="d-flex flex-column"
							[ngClass]="{'gap-2': isMobile}"
						>
							<mat-radio-button  [value]="true" id="public-event-radio">
								<span class='fw-bold'>Public: </span>
								Anyone can find the event on Quicket or by using search engines such as Google.
							</mat-radio-button>
							<mat-radio-button [value]="false" id="private-event-radio">
								<span class='fw-bold'>Private: </span>
								Only people with the event link can view the event page and book tickets. Your event page will not be visible to search engines such as Google.
							</mat-radio-button>
						</mat-radio-group>
						<div class="g-error-input g-green" *ngIf="isEventLockedOnPrivate()" id="locked-on-private">
							This event's visibility has been locked to private.
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>
