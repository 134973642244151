import { Directive, Input, NgModule } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
	selector: '[appPasswordMatch]',
	providers: [{ provide: NG_VALIDATORS, useExisting: PasswordMatchDirective, multi: true }],
})
export class PasswordMatchDirective implements Validator {

	@Input() confirmPassword: AbstractControl;

	validate(control: AbstractControl): { [key: string]: any } | null {
		const confirmPasswordValue = control.value;
		const passwordValue = this.confirmPassword ? this.confirmPassword.value : '';

		if (confirmPasswordValue && passwordValue && confirmPasswordValue !== passwordValue) {
			return { 'confirmPassword': true };
		}

		return null;
	}

}

@NgModule({
	declarations: [PasswordMatchDirective],
	exports: [PasswordMatchDirective],
	imports: [],
})
export class PasswordMatchModule {}
