import { Component, EventEmitter, Input, Output } from '@angular/core';
import { getInitials } from '@app/utils/common-helpers';

@Component({
	selector: 'app-profile-card',
	templateUrl: './profile-card.component.html',
	styleUrls: ['./profile-card.component.sass'],
})
export class ProfileCardComponent {
	@Input() thumbnail: string;
	@Input() name: string;
	@Output() delete = new EventEmitter();

	getOrganiserNameInitials(): string{
		return getInitials(this.name);
	}

	handleDelete() {
		this.delete.emit();
	}

}
