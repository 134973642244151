import { Action } from '@ngrx/store';
import { NavigationExtras } from '@angular/router';
import { AppAction } from '@app/models/action.model';
import { URLCreatorList } from '@app/models/url.model';

export const GO = '[Router] Go';
export const REDIRECT = 'REDIRECT';
export const BACK = '[Router] Back';
export const FORWARD = '[Router] Forward';
export const RELOAD = '[Router] Reload';

export class Go extends AppAction<{
	path: string[];
	query?: object;
	extras?: NavigationExtras;
}> implements Action {
	readonly type = GO;
}

export class Back extends AppAction implements Action {
	readonly type = BACK;
}

export class Forward extends AppAction implements Action {
	readonly type = FORWARD;
}

export class Redirect<K extends keyof URLCreatorList> extends AppAction<{
	path: K;
	config: any[];
	extraLink?: string;
}> implements Action {
	readonly type = REDIRECT;
}

export class Reload extends AppAction {
	readonly type = RELOAD;
}

export type RouterActionsUnion =
	Go
	| Back
	| Forward
	| Redirect<keyof URLCreatorList>
	| Reload;
