<div
	*ngIf="!isMobile; else mobileCards"
	class="l-container"
>
	<app-web-content-header
		class="w-100"
		[headerContent]="headerContent"
		[isMobile]="isMobile"
		[reference]="resaleTickets.purchaseReference"
		[id]="purchaseId"
	></app-web-content-header>

	<div class="l-content">
		<div
			class="l-table-container"
			*ngIf="ticketsInResale.data?.length; else showEmptyListedTickets"
		>
			<mat-progress-bar
				mode="indeterminate"
				*ngIf="isCanceling"
			></mat-progress-bar>
			<table
				mat-table
				[dataSource]="ticketsInResale"
				class="l-table web"
			>
				<ng-container matColumnDef="ticketType">
					<th
						mat-header-cell
						*matHeaderCellDef
						class="l-medium"
					> Ticket Type </th>
					<td
						mat-cell
						*matCellDef="let ticket; let i = index"
						id="ticket-type-{{i + 1}}"
					> {{ticket.ticketTypeName}} </td>
				</ng-container>
				<ng-container matColumnDef="price">
					<th
						mat-header-cell
						*matHeaderCellDef
						class="l-small"
					> Price </th>
					<td
						mat-cell
						*matCellDef="let ticket; let i = index"
						id="currency-{{i + 1}}"
					>{{currencySymbol}} {{ticket.price | number :
						'.2-2'}} </td>
				</ng-container>
				<ng-container matColumnDef="status">
					<th
						mat-header-cell
						*matHeaderCellDef
					> Status </th>
					<td
						mat-cell
						*matCellDef="let ticket; let i = index"
						id="status-{{i + 1}}"
					>
						<div
							class="l-badge"
							[ngClass]="{
								'on-sale': ticket.status === 1,
								'pending': ticket.status === 4
							}"
						>
							{{ticket.statusDescription.toUpperCase()}} </div>
					</td>
				</ng-container>
				<ng-container matColumnDef="type">
					<th
						mat-header-cell
						*matHeaderCellDef
					> Type </th>
					<td
						mat-cell
						*matCellDef="let ticket; let i = index"
						id="type-{{i + 1}}"
					> {{ticket.type === 1 ? 'Public' : 'Private'}}
					</td>
				</ng-container>
				<ng-container matColumnDef="dateCreated">
					<th
						mat-header-cell
						*matHeaderCellDef
						class="l-small"
					> Date Created </th>
					<td
						mat-cell
						*matCellDef="let ticket; let i = index"
						id="date-created-{{i + 1}}"
					> {{ticket.dateCreated | formattedDatePipe}}
					</td>
				</ng-container>
				<ng-container matColumnDef="email">
					<th
						mat-header-cell
						*matHeaderCellDef
						class="l-small"
					> Email </th>
					<td
						mat-cell
						*matCellDef="let ticket; let i = index"
						id="email-{{i + 1}}"
					> {{ticket.email ? ticket.email : 'N/A'}} </td>
				</ng-container>
				<ng-container matColumnDef="bankDetails">
					<th
						mat-header-cell
						*matHeaderCellDef
						class="l-small"
					> Bank Details </th>
					<td
						mat-cell
						*matCellDef="let ticket; let i = index"
						id="bank-details-{{i + 1}}"
					> {{ticket.bankAccountHashed ?
						ticket.bankAccountHashed : 'N/A'}} </td>
				</ng-container>
				<ng-container matColumnDef="action">
				<th mat-header-cell *matHeaderCellDef> Action </th>
				<td mat-cell *matCellDef="let ticket; let i = index" id="action-{{i + 1}}">
					<div
						*ngIf="ticket.status === 1 && isFirstTicketOfGroup(resaleTickets.ticketsInResale, i)"
						id="cancel-resale-web"
						class="g-orange g-pointer"
						(click)="handleResaleCancel(ticket)"
					> {{ getCancelButtonText(ticket, i) }}
					</div>
				</td>
				</ng-container>
				<tr
					mat-header-row
					*matHeaderRowDef="inResaleDisplayColumns"
				></tr>
				<tr
					mat-row
					*matRowDef="let row; columns: inResaleDisplayColumns;"
				></tr>
			</table>
			<mat-paginator
				#inResalePaginator
				[length]='ticketsInResale.data?.length'
				[hidePageSize]="true"
				[pageSize]="10"
			></mat-paginator>
		</div>
		<ng-template #showEmptyListedTickets>
			<div class="l-empty-state">
				<h3 class="mb-3">No Currently Listed Tickets</h3>
				<div class="mb-3">You have not listed any of your tickets for resale.</div>
			</div>
		</ng-template>

	</div>
</div>
<ng-template #mobileCards>
	<div class="l-cards-container">
		<h3 *ngIf="isMobile">{{ title }}</h3>
		<app-web-content-header
			class="w-100"
			[headerContent]="headerContent"
			[isMobile]="isMobile"
			[reference]="resaleTickets.purchaseReference"
			[id]="purchaseId"
		></app-web-content-header>
		<div
			class="l-cards"
			*ngIf="ticketsInResale.data.length; else showMobileEmptyListedTickets"
		>
			<div *ngFor='let ticket of ticketsInResale.data; let i = index'>
				<app-mobile-resale-card
					id="mobile-resale-card-{{i+1}}"
					[ticket]='ticket'
					[title]='productName'
					[currencySymbol]='currencySymbol'
					[cancelButtonText]="getCancelButtonText(ticket, i)"
					(cancelTicket)='handleResaleCancel(ticket)'
				></app-mobile-resale-card>
			</div>
		</div>
		<ng-template #showMobileEmptyListedTickets>
			<div class="l-empty-state">
				<h3 class="mb-3">No Currently Listed Tickets</h3>
				<div class="mb-3">You have not listed any of your tickets for resale.</div>
			</div>
		</ng-template>
	</div>

</ng-template>