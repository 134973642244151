import { v4 } from 'uuid';
import { SchemeID } from '../models/dataSchema.model';
export const draftIDPrefix = '__DRAFT';
export const draftIDSeparator = '|';

export const getDraftID = (extra: string = '') =>
	`${draftIDPrefix}${draftIDSeparator}${v4()}${extra}`;

export const isDraftID = (id: SchemeID) =>
	(String(id)).includes(`${draftIDPrefix}${draftIDSeparator}`);
