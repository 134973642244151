import { Component, OnInit, Input } from '@angular/core';
import { Button } from '@app/shared/button/button.model';

@Component({
	selector: 'app-button',
	templateUrl: './button.component.html',
})

export class ButtonComponent extends Button implements OnInit {
	@Input() styleClass: string;
	constructor() {
		super();
	}

	ngOnInit() {
	}

	getClasses() {
		return {
			[`ui-button-${this.type}`]: !this.simple,
			[`ui-button-${this.size}`]: !this.simple,
			'ui-button-simple': this.simple,
			[this.styleClass]: !!this.styleClass,
			'ui-button-link': this.linkRef,
			'ui-button': true,
			'ui-widget': true,
			'ui-state-default': true,
			'ui-corner-all': true,
			'ui-button-text-icon-left': this.label === null ? false : true,
			'ui-button-icon-only': this.label === null ? true : false,
			'p-button-isloading' : this.loading,
		};
	}

	onClick(event?: MouseEvent): void {
		if (!this.loading) {
			this.click.emit(event);
		}
	}

	onFocus(event?: FocusEvent): void {
		this.focus.emit(event);
	}

	onBlur(event?: FocusEvent): void {
		this.blur.emit(event);
	}
}
