import { Component, ViewChild, ElementRef, Input } from '@angular/core';
import { FormField } from '../form-field.model';

@Component({
	selector: 'app-color-picker',
	templateUrl: './color-picker.component.html',
	styleUrls: ['./color-picker.component.sass', '../form-field.component.sass'],
})

export class ColorPickerFieldComponent extends FormField {
	@Input() previewOnTop = false;
	@ViewChild('input', { static: true })
		input: ElementRef<HTMLInputElement>;
	toggle: boolean;

	onFocus() {
		this.input.nativeElement.select();
	}

	onChange = (color: string) => {
		this.control.setValue(color);
		this.control.markAsDirty();
	};
}
