import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChartInput, TicketTypeColour } from '@app/models/organiser.model';

@Component({
	selector: 'app-chart-table-modal',
	templateUrl: './chart-table-modal.component.html',
	styleUrls: ['./chart-table-modal.component.sass'],
})
export class ChartTableModalComponent implements OnInit {
	isLoading = true;
	displayedColumns: string[] = ['name'];
	dateColumns: string[] = [];
	tableData = [];
	colorDictionary = {};
	columnTotals = [];
	total = 0;

	constructor(
		public dialogRef: MatDialogRef<ChartTableModalComponent>,
		@Inject(MAT_DIALOG_DATA)
		public data: {
			isMobile: boolean;
			displayData: ChartInput[];
			legendItems: TicketTypeColour[];
			appliedFilterText: string;
			title: string;
			currencySymbol: string;
		}
	) {}

	ngOnInit(): void {
		this.initializeColorDictionary();
		this.initializeTableData();
		this.isLoading = false;
	}

	initializeColorDictionary(): void {
		this.colorDictionary = this.data.legendItems.reduce((dict, item) => {
			if (!dict[item.name]) {
				dict[item.name] = item.value;
			}
			return dict;
		}, {});
	}

	initializeTableData(): void {
		const columnTotalsMap = new Map<string, number>();

		this.dateColumns = this.data.displayData.map((item) => item.name);
		this.tableData = this.data.legendItems
			.map((legendItem) => {
				const ticketRow = { name: legendItem.name, total: 0 };
				this.data.displayData.forEach((entry) => {
					let columnTotal = 0;
					entry.series.forEach((seriesEntry) => {
						if (seriesEntry.name === legendItem.name && seriesEntry.id === legendItem.id) {
							ticketRow[entry.name] = seriesEntry.value;
							ticketRow.total += +seriesEntry.value;
							columnTotal += +seriesEntry.value;
						}
					});
					columnTotalsMap.set(entry.name, (columnTotalsMap.get(entry.name) || 0) + columnTotal);
				});
				this.total += ticketRow.total;
				return ticketRow;
			})
			.filter((item) => item.total !== 0);

		this.columnTotals = this.dateColumns.map((column) => columnTotalsMap.get(column) || 0);
		this.displayedColumns = ['name', ...this.dateColumns, 'total'];
	}

	handleClose() {
		this.dialogRef.close();
	}
}
