import { NgModule } from '@angular/core';
import { ControlPanelSidebarComponent } from './control-panel-sidebar/control-panel-sidebar.component';
import { EventControlPanelHeaderComponent } from './control-panel-header/event-control-panel-header.component';
import { FeaturePermissionMessageComponent } from './feature-permission-message/feature-permission-message';
import { StopSalesComponent } from './stop-sales/stop-sales.component';
import { UserHelpComponent } from './user-help/user-help.component';
import { SharedModule } from '@app/shared/shared.module';
import { PanelMenuModule } from '@app/shared/panelMenu/panel-menu.component';
/* import { TourNgxPopperModule } from 'ngx-tour-ngx-popper';
 */
const components = [
	ControlPanelSidebarComponent,
	EventControlPanelHeaderComponent,
	FeaturePermissionMessageComponent,
	StopSalesComponent,
	UserHelpComponent,
];

@NgModule({
	imports: [
		SharedModule,
		PanelMenuModule,
		/* TourNgxPopperModule.forRoot(), */
	],
	declarations: [
		...components,
	],
	exports: [
		...components,
		/* TourNgxPopperModule, */
	],
	providers: [],
})
export class ControlPanelSharedModule { }
