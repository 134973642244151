import { Directive, Input, NgModule } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
	selector: '[appValueMatch]',
	providers: [{ provide: NG_VALIDATORS, useExisting: ValueMatchDirective, multi: true }],
})
export class ValueMatchDirective implements Validator {

	@Input() confirmValue: AbstractControl;

	validate(control: AbstractControl): { [key: string]: any } | null {
		const confirmValue = control.value;
		const value = this.confirmValue ? this.confirmValue.value : '';

		if (confirmValue && value && confirmValue !== value) {
			return { 'confirmValue': true };
		}

		return null;
	}
}

@NgModule({
	declarations: [ValueMatchDirective],
	exports: [ValueMatchDirective],
	imports: [],
})
export class ValueMatchModule {}
