import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { LinkedProductsComponent } from '@app/features/events/manage-event/modals/linked-products/linked-products.component';
import { EventOrganiserProfile, ProfileLinkedProducts } from '@app/models/profile.model';
import { URLCreator } from '@app/services/url/url.dictionary';
import { transformToFriendlyURL } from '@app/utils/URL';
import { takeUntil } from 'rxjs';
import { EditorImageUploadOptions } from '../editor/editor.component';
import { ImageService, ImageServiceType } from '@app/services/image/image.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { FileTypes } from '@app/utils/consts';
import { SafeUrl } from '@angular/platform-browser';
import { ImageCropperType } from '../form-field/image-cropper/image-cropper.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, ofType } from '@ngrx/effects';
import { OrganiserProfileActionConstants } from '@app/store/actions/organiserProfiles/organiserProfiles.constants';

@Component({
	selector: 'app-event-organiser-profile-form',
	templateUrl: './event-organiser-profile-form.component.html',
	styleUrls: ['./event-organiser-profile-form.component.sass'],
})
export class EventOrganiserProfileFormComponent implements OnInit, OnChanges {
	@Input() form: UntypedFormGroup;
	@Input() organiserProfileOptions = [];
	@Input() includeHeaderWithName: boolean;
	@Input() profile: EventOrganiserProfile;
	@Input() isEventCreation: boolean;
	@Input() isMobile: boolean;
	@Input() isOrganiserHub = false;

	@Output() createProfile = new EventEmitter();
	@Output() imageChange = new EventEmitter();

	isOrganiserSelection = false;

	editorImageUploadHandler: EditorImageUploadOptions;

	linkedProducts: ProfileLinkedProducts[] = [];
	viewProfileUrl: string;

	// UPLOAD BANNER VARIABLES

	ImageServiceTypeEnum = ImageServiceType;

	isImageUploading = false;
	isUploadDisabled = false;
	bannerUploadError: string;
	maxFileSizeMb = 5;
	allowedFileTypes = FileTypes.image;
	imagePath: string | SafeUrl;
	showCropper = false;
	imageCropperLoading = false;
	base64String: string;
	banner: ImageCropperType = ImageCropperType.BANNER;
	removeImage = false;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private dialog: MatDialog,
		public imageService: ImageService,
		private clipboard: Clipboard,
		private _snackBar: MatSnackBar,
		private actions$: Actions
	) {}

	ngOnInit() {
		this.isOrganiserSelection = this.form && this.form.get('id') && this.form.get('id').value;
		this.initEditorImageUploadHandler();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.organiserProfileOptions && changes.organiserProfileOptions.previousValue) {
			const { currentValue, previousValue } = changes.organiserProfileOptions;

			if (currentValue.length > previousValue.length) {
				this.isOrganiserSelection = true;
			}
		}
		if (changes.form) {
			this.subscribeToProfileChange();
		}
		if (changes.profile) {
			if (this.profile) {
				if (this.profile.isLinkedToProducts && this.profile.linkedProducts?.length) {
					this.linkedProducts = this.profile.linkedProducts.filter((products) => products.isActive === true);
				}
				this.viewProfileUrl =
					URLCreator.viewOrganiserProfile() + this.profile.id + '-' + transformToFriendlyURL(this.profile.name) + '/'; // eslint-disable-line max-len
			}
		}
	}

	initEditorImageUploadHandler() {
		this.editorImageUploadHandler = this.imageService.initEditorImagerUploadHandler(
			this.form.get('id').value,
			ImageServiceType.PRODUCT_DESCRIPTION,
			false
		);

		this.actions$
			.pipe(
				ofType(OrganiserProfileActionConstants.CREATE_PROFILE_FAILED),
				takeUntil(this.destroyed$)
			)
			.subscribe(() => {
				this.removeImage = true;
			});
	}

	subscribeToProfileChange() {
		if (this.form && this.form.get('linkedProducts')) {
			this.form
				.get('linkedProducts')
				.valueChanges.pipe(takeUntil(this.destroyed$))
				.subscribe((products) => {
					this.linkedProducts = products.filter((product) => product.isActive === true);
				});
		}
	}

	viewLinkedProducts() {
		this.dialog.open(LinkedProductsComponent, {
			data: {
				isMobile: this.isMobile,
				isLinkedProducts: this.profile.isLinkedToProducts,
				linkedProducts: this.linkedProducts,
			},
			panelClass: this.isMobile ? 'g-full-page-dialog' : 'g-standard-dialog',
		});
	}

	navigateToOrganiserProfile() {
		window.open(this.viewProfileUrl);
	}

	handleCopy() {
		this.clipboard.copy(this.form.get('profilePageEmbed').value);
		this._snackBar.open('Copied embed to clipboard', 'Close', {
			duration: 2000,
			panelClass: 'g-success-snackbar',
			horizontalPosition: 'center',
			verticalPosition: 'top',
		});
	}

	fromForm(controlKey: string): AbstractControl{
		if (this.form) {
			return this.form.get(controlKey);
		}
		return null;
	}

	setFormValue(controlKey: string, value: any) {
		this.fromForm(controlKey).setValue(value);
		this.fromForm(controlKey).markAsDirty();
	}

	toggleOrganiserSelection() {
		if (!this.form.get('id').value) {
			this.isOrganiserSelection = !this.isOrganiserSelection;
			this.createProfile.emit();
		}
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}

	// UPLOAD BANNER LOGIC

	handleImageCrop(base64: string){
		if (!base64){
			this.setFormValue('croppedImage', { base64: this.fromForm('imageString').value.base64, fileName: this.fromForm('id').value });
		} else {
			this.setFormValue('croppedImage', { base64: base64, fileName: this.fromForm('id').value });
		}
		this.imageChange.emit();
	}

	handleImageStringChange(imageString: any){
		this.setFormValue('imageString', imageString);
		this.imageChange.emit();
	}

	handleOriginalImageChange(originalImage?: string){
		this.setFormValue('originalImage', originalImage);
		this.imageChange.emit();
	}

	handleImageRemove(){
		this.setFormValue('originalImage', null);
		this.setFormValue('thumbnail', null);
		this.setFormValue('imageString', null);
		this.setFormValue('croppedImage', null);
		this.imageChange.emit();
		this.removeImage = false;
	}

	handleThumbnailChange(thumbnail: string){
		this.setFormValue('thumbnail', thumbnail);
		this.imageChange.emit();
	}
}
