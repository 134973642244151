<div [class]="isMobile ? 'input-group-mobile-large' : 'input-group-web-large'">
	<label class="g-form-label">
		<span [innerHTML]="label"></span>
		<span *ngIf='isRequired' class="g-orange"> *</span>
	</label>
	<div>
		<div *ngFor="let option of options">
			<div class="d-flex align-items-center">
				<mat-checkbox id="checkbox-field" [formControl]="displayGroup.controls[option]" (change)="handleOptionChange(option)">{{ option }}</mat-checkbox>
			</div>
		</div>
		<div *ngIf="this.control.addOtherOption  && !isDisabled">
			<mat-checkbox  id="other-checkbox-field" [(ngModel)]="otherEnabled" (change)="onCheckboxChange()">Other</mat-checkbox>
			<div *ngIf="otherEnabled">
				<label class="g-form-label">Please specify: </label>
				<div class="d-flex gap-2">
					<input type="text" class="form-control" [formControl]="otherOptionControl" (input)="handleOtherOptionChange($event.target.value)" />
				</div>
				<div *ngIf="inputFormControl.invalid && otherEnabled && otherOptionControl.invalid">
					<div class="g-error-input">
						Please enter a valid answer.	
					</div>
				</div>
			</div>
			<div *ngIf="inputFormControl.invalid">
				<div class="g-error-input">
					<div *ngIf="inputFormControl.hasError('checkboxRequired')">A selection is required.</div>
				</div>
			</div>
		</div>
	</div>
</div>
