<div class='l-container'>
	<div class="w-100 h-100">
		<div id="seating-chart" [loadingSp]="true"></div>
	</div>
	<div class="l-cancellation-wrap" *ngIf="isCancelConfirmation">
		<div class="l-cancellation-content">
			<div *ngIf="isDonationAdded" class="l-donation-content">
				<mat-icon>error_outline</mat-icon>
				<span class='text-center'>
					You have added donation tickets to your event. They will be automatically removed if you save this
					chart.
				</span>
			</div>
			<h3 class="mb-4 text-center">Are you sure you want to save this Chart and Exit?</h3>
			<div class="l-buttons-container">
				<button class="g-secondary-button" (click)="toggleCancellation()">NO</button>
				<button class="g-primary-button" (click)="handleClose()">YES</button>
			</div>
		</div>
	</div>
</div>
