import { Component, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SchemeID } from '@app/models/dataSchema.model';
import { HeaderContent } from '@app/models/shared';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { InternalURLCreator, URLCreator } from '@app/services/url/url.dictionary';
import { MANUAL_REFUND_ID_KEY } from '@app/utils/consts';
import { take, takeUntil } from 'rxjs';
import { StoreService } from '@app/services/store/store.service';
import { Go } from '@app/store/actions/router/router.actions';

@Component({
	selector: 'app-request-bank-details',
	templateUrl: './request-bank-details.component.html',
	styleUrls: ['./request-bank-details.component.sass'],
})
export class RequestBankDetailsComponent {
	url: string;
	manualRefundId: SchemeID;
	manualRefundEncrypt: string;
	eventId: SchemeID;
	isMobile = false;
	isLoading = true;

	headerContent: HeaderContent = {
		breadCrumbs: [
			{
				routeName: 'My Account',
				routeTo: () => InternalURLCreator.myAccount(),
			},
			{
				routeName: 'Request For Bank Details',
			},
		],
	};

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private activatedRoute: ActivatedRoute,
		private breakpointService: BreakpointService,
		private store: StoreService
	) {}

	ngOnInit(): void {
		this.activatedRoute.parent.queryParams.pipe(takeUntil(this.destroyed$)).subscribe((queryParams) => {
			if (queryParams['e']) {
				this.manualRefundEncrypt = queryParams['e'];
			}
		});

		this.activatedRoute.params.pipe(take(1)).subscribe((params) => {
			this.manualRefundId = +params[MANUAL_REFUND_ID_KEY];
			this.url = URLCreator.requestBankDetailsAspx(this.manualRefundId, this.manualRefundEncrypt);
		});

		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}

	handleNavigateBack(){
		this.store.dispatch(new Go({ path: [InternalURLCreator.myAccount()] }));
	}

	handleLoadedIframe(){
		this.isLoading = false;
	}
}
