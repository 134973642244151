import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormArray, FormGroup, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DatesService } from '@app/services/dates/dates.service';
import { Schedule } from '@app/models/schedule.model';
import * as moment from 'moment';
import { ProductStatus, ScheduleEarliestLatestDates } from '@app/models/event.model';
import { ConfirmationModalComponent } from '@app/shared/modals/confirmation-modal/confirmation-modal.component';
import { MultiScheduleSelectModalComponent } from './multi-schedule-select-modal/multi-schedule-select-modal.component';

@Component({
	selector: 'app-schedule-creator',
	templateUrl: './schedule-creator.component.html',
	styleUrls: ['./schedule-creator.component.sass'],
})
export class ScheduleCreatorComponent implements OnInit, OnChanges{
	@Input() singleEvent: boolean;
	@Input() startDate: UntypedFormControl;
	@Input() endDate: UntypedFormControl;
	@Input() schedules: FormArray;
	@Input() isMobile = false;
	@Input() required = false;
	@Input() isEventCreation = false;
	@Input() isEventFinished = false;
	@Input() hasSoldTickets = false;
	@Input() hasEventSoldTickets = false;
	@Output() submitSchedule = new EventEmitter<Schedule>();
	@Output() markFormDirty = new EventEmitter();
	@Output() noValidSchedules = new EventEmitter();

	dateFromMinDate: Date;
	dateToMinDate: Date;
	shortEventWarning = false;
	cantDeleteScheduleTooltip = 'You cannot delete this as you have sold tickets for your events on this schedule.';
	cantDeleteScheduleItemTooltip = 'You cannot delete this as you have sold tickets for your events on this date.';

	productStatusEnum = ProductStatus;

	constructor(
		public datesService: DatesService,
		private cdr: ChangeDetectorRef,
		private dialog: MatDialog
	) {}

	ngOnInit() {
		this.dateFromMinDate = this.getDateFromMinDate();
		this.dateToMinDate = this.getDateToMinDate();
		if (this.isEventFinished) {
			this.startDate.disable();
			this.endDate.disable();
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.schedules) {
			if (this.schedules && this.schedules.length && (this.hasSoldTickets || this.isEventFinished)) {
				this.disableDates();
			}
		}
	}

	getDateFromMinDate() {
		if (this.startDate && this.startDate.value) {
			if (new Date(this.startDate.value) < this.datesService.getStartOfTheDay()) {
				return null;
			}
		}
		return this.datesService.getStartOfTheDay();
	}

	getDateToMinDate() {
		if (this.startDate && this.startDate.value) {
			if (new Date(this.startDate.value) > moment().toDate()) {
				return this.startDate.value;
			}
		}
		return moment().toDate();
	}

	isControlInvalid(control: UntypedFormControl) {
		if (control) {
			return control.invalid && (control.dirty || control.touched);
		}
		return false;
	}

	getError(errors: { [key: string]: string }) {
		if (Object.keys(errors)[0] === 'matDatetimePickerMin') {
			return 'Date can\'t be before the minimum date.';
		}
		if (Object.keys(errors)[0] === 'matDatetimePickerMax') {
			return 'Date can\'t be after the maximum date.';
		}
		return errors && errors[Object.keys(errors)[0]];
	}

	handleChange() {
		this.cdr.detectChanges();
		this.checkEventDuration();
		this.dateToMinDate = this.getDateToMinDate();
		this.startDate.updateValueAndValidity();
		this.endDate.updateValueAndValidity();
	}

	checkEventDuration() {
		if (this.startDate.value && this.endDate.value) {
			const diffInMilliseconds: number = Math.abs(new Date(this.endDate.value).getTime() - new Date(this.startDate.value).getTime());
			const diffInMinutes: number = diffInMilliseconds / (1000 * 60);
			if (diffInMinutes < 30) {
				this.shortEventWarning = true;
				return;
			}
		}

		this.shortEventWarning = false;
	}

	handleAddDates() {
		const dialogRef = this.dialog.open(MultiScheduleSelectModalComponent, {
			data: {
				currentScheduleItemCount: this.getScheduleItemsCount(),
			},
			panelClass: this.isMobile ? 'g-full-page-dialog' : 'g-standard-dialog',
		});

		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.submitSchedule.emit(result);
			}
		});
	}

	getFromFormArray(formArray: UntypedFormArray, controlKey: string): AbstractControl {
		return formArray.get(controlKey);
	}

	getScheduleItems(scheduleIndex: number): FormArray {
		return this.schedules.at(scheduleIndex).get('scheduleItems') as FormArray;

	}

	disableDates() {
		this.schedules.controls.forEach(schedule => {
			const scheduleItems = (schedule.get('scheduleItems') as FormArray).controls;
			scheduleItems.forEach(item => {
				if (item.get('status').value === ProductStatus.Finished) {
					item.get('startDate').disable();
					item.get('endDate').disable();
				}
			});
		});
		this.handleChange();
	}

	getScheduleDateCount(scheduleIndex: number) {
		const dates = this.schedules.at(scheduleIndex).get('scheduleItems').value;
		return dates.filter((x) => !x.deleted).length;
	}

	getEarliestAndLatestDates(schedule: FormGroup): ScheduleEarliestLatestDates {
		let earliestStartDate: Date | null = null;
		let latestEndDate: Date | null = null;
		const scheduleItems = schedule.get('scheduleItems') as FormArray;

		scheduleItems.controls.forEach((itemFormControl: AbstractControl) => {
			const itemFormGroup = itemFormControl as FormGroup;
			const startDate: Date = new Date(itemFormGroup.get('startDate')?.value);
			const endDate: Date = new Date(itemFormGroup.get('endDate')?.value);

			if (!isNaN(startDate.getTime()) && (!earliestStartDate || startDate < earliestStartDate)) {
				earliestStartDate = startDate;
			}

			if (!isNaN(endDate.getTime()) && (!latestEndDate || endDate > latestEndDate)) {
				latestEndDate = endDate;
			}
		});

		if (this.isMobile) {
			return {
				earliest: `${moment(earliestStartDate).format('Do MMM YYYY')}`,
				latest: `${moment(latestEndDate).format('Do MMM YYYY')}`,
			};
		} else {
			return {
				earliest: `${moment(earliestStartDate).format('Do MMM YYYY')} at ${moment(earliestStartDate).format('LT')}`,
				latest: `${moment(latestEndDate).format('Do MMM YYYY')} at ${moment(latestEndDate).format('LT')}`,
			};
		}
	}

	handleScheduleHiding(event: MouseEvent, schedule: FormGroup) {
		event.stopPropagation();
		const scheduleVisibility = schedule.get('hidden').value;
		schedule.get('hidden').setValue(!scheduleVisibility);
		schedule.markAsDirty();
	}

	handleDeletingSchedule(event: MouseEvent, schedule: FormGroup) {
		event.stopPropagation();
		this.dialog
			.open(ConfirmationModalComponent, {
				data: {
					title: 'Delete Schedule?',
					text: 'Are you sure you want to delete your Schedule',
					buttonText: 'YES',
					isMobile: this.isMobile,
				},
				panelClass: 'g-standard-dialog',
			})
			.afterClosed()
			.subscribe((result) => {
				if (result) {
					schedule.get('deleted').setValue(true);
					schedule.markAsDirty();
					const scheduleItems = schedule.get('scheduleItems') as FormArray;
					scheduleItems.controls.forEach((item: FormGroup) => {
						item.get('deleted').setValue(true);
						item.markAsDirty();
					});
					this.checkHasSchedules();
				}
			});
	}

	handleDeletingScheduleItem(event: MouseEvent, schedule: FormGroup, scheduleItem: FormGroup) {
		event.stopPropagation();
		this.dialog
			.open(ConfirmationModalComponent, {
				data: {
					title: 'Delete Schedule Date?',
					text: 'Are you sure you want to delete this schedule date',
					buttonText: 'YES',
					isMobile: this.isMobile,
				},
				panelClass: 'g-standard-dialog',
			})
			.afterClosed()
			.subscribe((result) => {
				if (result) {
					scheduleItem.get('deleted').setValue(true);
					scheduleItem.markAsDirty();
					if (schedule.get('scheduleItems').value.filter((x) => !x.deleted).length === 0) {
						schedule.get('deleted').setValue(true);
					}
				}
			});
	}

	getScheduleItemsCount() {
		let totalScheduleItems = 0;
		this.schedules.controls.forEach((scheduleFormGroup: FormGroup) => {
			const itemsArray = scheduleFormGroup.get('scheduleItems') as FormArray;
			itemsArray.controls.forEach((item: FormGroup) => {
				if (item.get('deleted').value === false && new Date(item.get('endDate').value) > new Date()) {
					totalScheduleItems += 1;
				}
			});
		});
		return totalScheduleItems;
	}

	checkHasSchedules() {
		const validSchedules = this.schedules.controls.filter((scheduleFormGroup: FormGroup) =>
			!scheduleFormGroup.get('deleted').value
		);
		if (validSchedules.length === 0) {
			this.noValidSchedules.emit();
		}
	}

	maxFilter = (d: Date): boolean => {
		if (this.isEventCreation) {
			return true;
		}
		if (!this.endDate.value) {
			return true;
		}
		const to = new Date(this.endDate.value);
		if (d > to) {
			return false;
		}
		return true;
	};
}
