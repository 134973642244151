import { ResponseScheme } from '@app/models/http.model';
import { AppAction, FailedAction } from '@app/models/action.model';
import { catchError, mergeMap, Observable, of, OperatorFunction, throwError } from 'rxjs';
import { getResponseErrors, getResponseErrorCodes } from './Response';

export const handleSuccessRequest =
	<P, T extends ResponseScheme<P>, A extends AppAction<any>>(cb: (v: T, p?: P) => A | A[]) =>
		(source: Observable<T>) =>
			source.pipe(
				mergeMap((x) =>
					x.isSuccess
						? of(cb(x, x.payload)).pipe(mergeMap((actions) => (Array.isArray(actions) ? of(...actions) : of(actions))))
						: throwError(() => getResponseErrors(x))
				),
				catchError((error) => throwError(() => error))
			);

export const getFaileadRequestAction = <A extends FailedAction>(action: A, error): A => Object.assign(action, {
	payload: {
		...action.payload,
		error,
		serverMessages: getResponseErrorCodes(error),
		serverCustomErrorMessage: error?.error?.errors?.length ? error.error.errors[0].message : '',
	},
});

export const handleFailedRequest =
	<A extends FailedAction>(action: A) =>
		(error): Observable<A> =>
			of(getFaileadRequestAction(action, error));

export const ofTypeExt =
	<T extends AppAction<any>>(...allowedTypes: string[]): OperatorFunction<AppAction<any>, { action: T; payload: T['payload'] }> =>
		(source: Observable<T>) =>
			new Observable((observer) =>
				source.subscribe({
					next(action) {
						if (allowedTypes.includes(action.type)) {
							observer.next({
								action,
								payload: action.payload,
							});
						}
					},
					error(error) {
						observer.error(error);
					},
					complete() {
						observer.complete();
					},
				})
			);
