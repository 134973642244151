<div class="l-container" [class.l-container-mobile]='data.isMobile'>
	<div *ngIf="!data.isMobile; else mobileTitle" class="l-title">
		<h3 class="mb-3">Ticket Details</h3>
		<button id="view-ticket-pdf-web" class="g-primary-button" (click)="handleViewTicketPDF()">
			VIEW TICKET PDF
		</button>
	</div>
	<ng-template #mobileTitle class='l-mobile-title'>
		<h3 class="mb-4">Ticket Details</h3>
	</ng-template>
	<div class="mb-4">
		<p>
			View and download your tickets for order: <strong>{{data.reference}}</strong>
		</p>
		<button id="view-ticket-pdf-mobile" *ngIf="data.isMobile" class="g-primary-button my-3 w-100" (click)="handleViewTicketPDF()">
			VIEW TICKET PDF
		</button>
		<div class="l-download-separately" matTooltip="Purchases with more than 10 tickets will be emailed to you" #downloadTooltip="matTooltip" (click)="downloadTooltip.toggle()">
			<mat-checkbox [(ngModel)]="downloadSeparately">Download tickets separately</mat-checkbox>
			<mat-icon class="l-disabled l-icon">info</mat-icon>
		</div>
	</div>
	<div *ngIf='!isLoading; else showLoading'>
		<div *ngIf="validTickets.length">
			<div class='mb-1'>
				<strong>{{validTickets.length}} x valid {{validTickets.length === 1 ? 'ticket' : 'tickets'}}</strong>
			</div>
			<div class="l-table-container">
				<table mat-table [dataSource]="validTickets" class="l-table"
					[ngClass]="{ 'web': !data.isMobile, 'mobile': data.isMobile}">
					<ng-container matColumnDef="download">
						<th mat-header-cell *matHeaderCellDef>
							<div id="select-all-tickets" [ngClass]="{ 'd-flex justify-content-center flex-wrap': data.isMobile}">
								<mat-checkbox  [(ngModel)]="isAllSelected" (change)="handleSelectAll($event.checked)"></mat-checkbox>
								<span *ngIf="!data.isMobile">Select All</span>
							</div>
						</th>
						<td mat-cell *matCellDef="let ticket">
							<mat-checkbox
								id="select-ticket-checkbox"
								(change)="handleCheckboxChange(ticket.id)"
								[checked]="isSelected(ticket.id)"
							></mat-checkbox>
						</td>
					</ng-container>

					<ng-container matColumnDef="ticketHolder">
						<th mat-header-cell *matHeaderCellDef> Ticket Holder </th>
						<td mat-cell *matCellDef="let ticket"> {{getTicketHolderName(ticket.questions)}} </td>
					</ng-container>

					<ng-container matColumnDef="ticketType">
						<th mat-header-cell *matHeaderCellDef> Ticket Type </th>
						<td mat-cell *matCellDef="let ticket"> {{ticket.ticketType}} </td>
					</ng-container>

					<ng-container matColumnDef="ticketNumber">
						<th mat-header-cell *matHeaderCellDef> Ticket Number </th>
						<td mat-cell *matCellDef="let ticket"> QKT{{ticket.id}} </td>
					</ng-container>

					<ng-container matColumnDef="streamAccessCode">
						<th mat-header-cell *matHeaderCellDef> Access Code </th>
						<td mat-cell *matCellDef="let ticket"> {{ticket.streamAccessCode}} </td>
					</ng-container>

					<ng-container matColumnDef="streamAccessUrl">
						<th mat-header-cell *matHeaderCellDef> Event Link </th>
						<td mat-cell *matCellDef="let ticket"> <span class="g-green text-decoration-underline g-pointer"
								(click)='handleEventLink(ticket.streamAccessUrl)'>Access Event</span> </td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
					<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
				</table>
			</div>
		</div>

		<hr *ngIf="invalidTickets.length" />

		<div *ngIf="invalidTickets.length">
			<mat-accordion class='mb-4'>
				<mat-expansion-panel expanded="false">
					<mat-expansion-panel-header>
						<span>
							<strong>{{invalidTickets.length}} x invalid {{validTickets.length === 1 ? 'ticket' :
								'tickets'}}</strong>
						</span>
					</mat-expansion-panel-header>
					<div class="l-table-container">
						<table mat-table [dataSource]="invalidTickets" class="l-table"
							[ngClass]="{ 'web': !data.isMobile, 'mobile': data.isMobile}">
							<ng-container matColumnDef="ticketHolder">
								<th mat-header-cell *matHeaderCellDef> Ticket Holder </th>
								<td mat-cell *matCellDef="let ticket"> {{getTicketHolderName(ticket.questions)}} </td>
							</ng-container>

							<ng-container matColumnDef="ticketType">
								<th mat-header-cell *matHeaderCellDef> Ticket Type </th>
								<td mat-cell *matCellDef="let ticket"> {{ticket.ticketType}} </td>
							</ng-container>

							<ng-container matColumnDef="ticketNumber">
								<th mat-header-cell *matHeaderCellDef> Ticket Number </th>
								<td mat-cell *matCellDef="let ticket"> QKT{{ticket.id}} </td>
							</ng-container>

							<tr mat-header-row *matHeaderRowDef="invalidDisplayedColumns"></tr>
							<tr mat-row *matRowDef="let row; columns: invalidDisplayedColumns;"></tr>
						</table>
					</div>
				</mat-expansion-panel>
			</mat-accordion>
		</div>
	</div>
	<ng-template #showLoading>
		<div class='l-loading'>
			<mat-spinner></mat-spinner>
		</div>
	</ng-template>
	<div [class.l-buttons-container]="!data.isMobile" class="pt-3">
		<button id="cancel-ticket-details" class="g-secondary-button" (click)="handleCancel()" [disabled]="isLoading"
			[ngClass]="{ 'disabled': isLoading, 'w-100 mb-2': data.isMobile}">BACK</button>
		<button id="download-tickets" class="g-primary-button" [disabled]="isLoading || selectedTickets.length === 0"
			[ngClass]="{ 'disabled': isLoading || selectedTickets.length === 0, 'w-100': data.isMobile}"
			(click)="handleDownload()">
			DOWNLOAD {{ selectedTickets.length === 0 ? "" : selectedTickets.length }} {{ selectedTickets.length === 1 ?
			"TICKET" : "TICKETS"}}
		</button>
	</div>
</div>
