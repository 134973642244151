import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { FormValidators, FormSchemeControls } from '@app/models/common.model';

export type FormConfig<T> = Partial<FormSchemeControls<T>>;
export type ValidatorsConfig<T> = FormValidators<Partial<T>>;

export interface CreateFormControlsParams<T> {
	formValues: T;
	initialValues: Partial<T>;
	validatorsConfig: ValidatorsConfig<T>;
}


@Injectable()
export class FormService {
	constructor(
		public formBuilder: UntypedFormBuilder
	) { }

	createFormControls<T>(
		{
			formValues,
			initialValues,
			validatorsConfig,
		}: CreateFormControlsParams<T>,
		useAsGroup: string[] = []
	) {
		const formConfig: FormConfig<T> = {};
		Object.keys(formValues)
			.forEach(key => {
				const controlValue = initialValues && initialValues[key] != null
					? initialValues[key]
					: formValues[key];

				const controlValidators = validatorsConfig[key];

				if (useAsGroup.includes(key)) {
					formConfig[key] = this.formBuilder.group(controlValue, controlValidators);
				} else {
					formConfig[key] = this.formBuilder.control(controlValue, controlValidators);
				}
			});

		return formConfig;
	}

	setDynamicValidators<T>(
		formConfig: FormConfig<T>,
		dynamicValidators
	) {
		for (const key in dynamicValidators) {
			if (!Array.isArray(dynamicValidators[key])) {
				this.setDynamicValidators(formConfig[key], dynamicValidators[key]);
			} else {
				if (formConfig instanceof UntypedFormGroup) {
					formConfig.get(key).setValidators(dynamicValidators[key]);
				} else {
					(formConfig[key] as UntypedFormControl | UntypedFormGroup).setValidators(dynamicValidators[key]);
				}
			}
		}
	}

	updateDynamicValidators(
		form: UntypedFormGroup,
		dynamicValidators: any
	) {
		dynamicValidators.forEach(key => {
			if (typeof key === 'string') {
				form.get(key).updateValueAndValidity();
			} else {
				for (const k in key) {
					if (key.hasOwnProperty(k)) {
						this.updateDynamicValidators(form.get(k) as UntypedFormGroup, key[k]);
					}
				}
			}
		});
	}
}
