import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FileTypes } from '@app/utils/consts';
import { ImageCropperType } from '../form-field/image-cropper/image-cropper.component';
import { ImageFileSize } from '@app/models/shared';
import { ImageService, ImageServiceType, ImageString } from '@app/services/image/image.service';
import { StoreService } from '@app/services/store/store.service';
import { NotificationType } from '@app/models/notification.model';
import { AddNotification } from '@app/store/actions/notification/notification.actions';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { take } from 'rxjs';
import config from '@app/config';
import { SafeUrl } from '@angular/platform-browser';
import { SchemeID } from '@app/models/dataSchema.model';

@Component({
	selector: 'app-image-upload-and-cropper',
	templateUrl: './image-upload-and-cropper.component.html',
	styleUrls: ['./image-upload-and-cropper.component.sass'],
})
export class ImageUploadAndCropperComponent implements OnInit, OnChanges {
	@Input() label = 'Upload Image';
	@Input() uploadText = '';
	@Input() eventId: number | SchemeID;
	@Input() originalImage: string;
	@Input() maxFileSize: ImageFileSize;
	@Input() type: ImageCropperType = ImageCropperType.BANNER;
	@Input() base64: string;
	@Input() imageServiceType: ImageServiceType;
	@Input() isMobile = false;
	@Input() tooltip = '';
	@Input() removeImage = false;
	@Output() croppedBase64Change: EventEmitter<string> = new EventEmitter<string>();
	@Output() imageStringChange: EventEmitter<any> = new EventEmitter<any>();
	@Output() originalImageChange: EventEmitter<string> = new EventEmitter<string>();
	@Output() imageRemoved: EventEmitter<void> = new EventEmitter<void>();
	@Output() thumbnailChange: EventEmitter<string> = new EventEmitter<string>();

	imageUploadError: string;
	showCropper = false;
	imageCropperLoading = false;
	allowedFileTypes = FileTypes.image;
	isImageUploading = false;
	isUploadDisabled = false;
	croppedBase64: string;
	imageString: ImageString;
	imagePath: string | SafeUrl;

	preferredRes = '';

	@ViewChild('fileUpload') fileUpload!: ElementRef<HTMLInputElement>;

	constructor(
		public imageService: ImageService,
		private store: StoreService
	){
		imageService.init(this.imageServiceType);
	}

	ngOnInit(){
		this.imagePath = this.getImagePreview();

		if (!this.maxFileSize){
			this.maxFileSize = this.imageService.maxBigImageSize;
		}

		if (this.type === ImageCropperType.BANNER){
			this.preferredRes = '1500x500';
		} else if (this.type === ImageCropperType.LOGO){
			this.preferredRes = '800x800';
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.originalImage) {
			this.imagePath = this.getImagePreview();
		}
		if (changes.removeImage && changes.removeImage.currentValue) {
			this.handleImageRemove();
		}
	}

	isSizeValid(fileSize: number): boolean {
		if (fileSize <= (this.maxFileSize.bit)) {
			return true;
		} else {
			this.imageUploadError = `Please upload an image smaller than ${this.maxFileSize.mb}MB`;
			return false;
		}
	}

	isTypeValid(fileType: string): boolean {
		if (this.allowedFileTypes.includes(fileType)) {
			return true;
		} else {
			this.imageUploadError = 'Incorrect file format. Please upload an image.';
			return false;
		}
	}

	onFileSelected(event: Event) {
		this.isImageUploading = true;
		this.imageUploadError = '';
		const selectedFile: File = (event.target as HTMLInputElement).files[0];

		if (selectedFile && this.isSizeValid(selectedFile.size) && this.isTypeValid(selectedFile.type)) {
			this.processImageUpload(selectedFile);
		} else {
			this.isImageUploading = false;
		}
	}

	processImageUpload(file: File){
		this.imageService.encodeImage(file, base => {
			this.base64 = base;

			this.imageString = this.imageService.getImageString(base, file);
			this.imageStringChange.emit(this.imageString);

			this.setCroppedImage(null);
			this.thumbnailChange.emit(null);

			this.imagePath = this.getImagePreview();

			this.isImageUploading = false;
		});
	}

	handleCropImageVisibility() {
		this.showCropper = !this.showCropper;
		if (this.showCropper) {
			this.getBase64String();
		}
	}

	handleImageRemove() {
		this.showCropper = false;

		this.imageRemoved.emit();

		this.croppedBase64 = null;
		this.imageString = null;
		this.originalImage = null;

		this.isImageUploading = false;
		this.imagePath = this.getImagePreview();

		this.fileUpload.nativeElement.value = '';
	}

	getBase64String() {
		this.imageCropperLoading = true;

		if (this.croppedBase64) {
			this.base64 = this.croppedBase64;
			this.imagePath = this.getImagePreview();
			this.imageCropperLoading = false;
		} else if (this.imageString && this.imageString.base64) {
			this.base64 = this.imageString.base64;
			this.imagePath = this.getImagePreview();
			this.imageCropperLoading = false;
		} else {
			this.setImageString();
		}
	}

	getImagePreview() {
		return this.croppedBase64
			? this.croppedBase64
			: this.imageString
				? this.imageString.base64
				: this.originalImage
					? `${config.baseURL}${this.originalImage}`
					: '';
	}

	setImageString() {
		const imageURL = `${config.baseURL}${this.originalImage}`;

		this.imageService.downloadCurrentImage(imageURL).pipe(take(1)).subscribe((base: string) => {
			if (!base) {
				this.imageLoadingFailed();
				this.imageStringChange.emit(null);
				this.imagePath = this.getImagePreview();
				return;
			}
			this.setCroppedImage(base);
			this.imageString = {
				base64: base,
				url: base,
				fileName: this.eventId,
			};
			this.imageStringChange.emit(this.imageString);
			this.setOriginalImage(null);
			this.thumbnailChange.emit(null);
			this.base64 = base;
			this.imagePath = this.getImagePreview();
			this.imageCropperLoading = false;
		});
	}

	imageLoadingFailed() {
		this.isImageUploading = false;
		this.store.dispatch(
			new AddNotification({
				id: 'image-croppper',
				action: null,
				actionType: null,
				type: NotificationType.ERROR,
				title: 'Oops! We can\'t upload your image',
			})
		);
	}

	imageCropped(event: ImageCroppedEvent) {
		this.croppedBase64Change.emit(event.base64);
		this.croppedBase64 = event.base64;
		this.imagePath = this.getImagePreview();
	}

	setCroppedImage(base64: string) {
		this.croppedBase64 = base64;
		this.croppedBase64Change.emit(base64);
	}

	setOriginalImage(image?: string) {
		this.originalImage = image;
		this.originalImageChange.emit(image);
	}
}
