<div class="l-container">
	<div class='l-title'>{{data.title}}</div>
	<mat-select
		class="form-control l-select"
		[formControl]="currencyCtrl"
		placeholder="e.g. Rand"
		#singleSelect
		id="currency-select"
	>
		<mat-option>
			<ngx-mat-select-search
				[formControl]='currencyFilterCtrl'
				placeholderLabel="Search your currency..."
				noEntriesFoundLabel="No matching currencies found"
			></ngx-mat-select-search>
		</mat-option>
		<mat-option
			*ngFor="let currency of filteredCurrencies"
			[value]="currency"
			id="currency-option-{{currency.value.id}}"
		>
			{{currency.title}}
		</mat-option>
	</mat-select>
	<p class='mb-4'>
		{{data.text}}
	</p>
	<div class="l-buttons-container">
		<button
			class="g-secondary-button l-button"
			(click)="handleClose()"
			id="currency-close"
		>CANCEL</button>
		<button
			class="g-primary-button l-button"
			[disabled]='!currencyCtrl.value'
			[class.disabled]='!currencyCtrl.value'
			(click)="handleClose(currencyCtrl.value)"
			id="currency-save"
		>SAVE</button>
	</div>
</div>
