<mat-tab-group>
	<mat-tab [label]="data.isMobile ? 'NEW':'ADD NEW ACCOUNT'">
		<div class="l-container">
			<h3 class="mb-3">Add Bank Account</h3>
			<div class="mb-4">
				<p>
					{{ data.descriptions.addBankDescription }}
				</p>
			</div>
			<form #accountDetailsForm="ngForm" class="g-form-container my-5">
				<div *ngIf="!isBanksLoading; else banksLoading">
					<div *ngIf="!banksErrorMessage; else showBanksErrorMessage">
						<div class="input-group w-100 mb-3">
							<label class="g-form-label">Select Bank</label>
							<mat-select id="select-bank" class="form-control w-100" [(ngModel)]="bankDetails.bank" name="bank"
								#bank="ngModel" placeholder="Please Select" (selectionChange)="handleBankSelection()"
								required #singleSelect>
								<mat-option>
									<ngx-mat-select-search ngModel (ngModelChange)="handleBankFilter($event)"
										name="bankSearch" #bankSearch="ngModel"
										placeholderLabel="Search for your bank..."
										noEntriesFoundLabel="No matching bank found">
									</ngx-mat-select-search>
								</mat-option>
								<mat-option *ngFor="let bank of filteredBanks" [value]="bank">
									{{ bank.name }}
								</mat-option>
							</mat-select>
						</div>

						<div class="input-group w-100 mb-3" *ngIf="branches?.length">
							<label class="g-form-label">Select Branch</label>
							<mat-select class="form-control w-100" #branch="ngModel" name="branch" id="select-branch"
								placeholder="Please Select" [(ngModel)]="bankDetails.branch" required>
								<mat-option>
									<ngx-mat-select-search ngModel (ngModelChange)="handleBranchesFilter($event)"
										name="branchSearch" #branchSearch="ngModel"
										placeholderLabel="Search for your branch..."
										noEntriesFoundLabel="No matching branch found">
									</ngx-mat-select-search>
								</mat-option>
								<mat-option *ngFor="let branch of filteredBranches" [value]="branch">
									{{ branch.branchName }}
								</mat-option>
							</mat-select>
						</div>
						<div class="l-input input-group l-account-field">
							<label class="g-form-label">Account Holder Name<span class="g-orange">*</span></label>
							<input 
								#accountName="ngModel" 
								[(ngModel)]="bankDetails.accountHolder" 
								type="text" id="account-name"
								name="accountName" 
								class="form-control w-100" 
								placeholder="Name Surname" 
								#acccountHolder="ngModel"
								required 
							/>
							<div
								class="g-error-input"
								*ngIf="acccountHolder.invalid && acccountHolder.touched"
							>
								<div *ngIf="acccountHolder.errors?.required">required</div>
							</div>
						</div>
						<div
							*ngIf="bankDetails.bank && !bankDetails.bank.universalCode"
							class="input-group l-input l-account-field"
						>
							<label class="g-form-label">Branch Code<span class="g-orange">*</span></label>
							<input
								type="text"
								id="branch-code-input"
								[(ngModel)]="bankDetails.branch.branchCode"
								placeholder="eg. 0400005"
								name="branchCode"
								class="form-control w-100"
								#branchCode="ngModel"
								required
							/>
							<div
								class="g-error-input"
								*ngIf="branchCode.invalid && branchCode.touched"
							>
								<div *ngIf="branchCode.errors?.required">Branch code is required.</div>
							</div>
						</div>
						<div class="g-double-input-container l-double-input-container">
							<div class="l-input input-group">
								<label class="g-form-label">Account Number<span class="g-orange">*</span></label>
								<input id='new-account-input' 
									[(ngModel)]="bankDetails.accountNumber" 
									placeholder="12345678912" 
									type="number"
									name="accountNumber" 
									class="form-control w-100" 
									minlength="5" 
									maxlength="11" 
									pattern="\d{1,11}" 
									required
									#accountNumber="ngModel" 
									appValueMatch 
									[confirmValue]="confirmAccountValue"
									(ngModelChange)="validateAccountNumbers(accountDetailsForm)"
								/>
								<div class="g-error-input" *ngIf="accountNumber.invalid && accountNumber.touched">
									<div *ngIf="accountNumber.errors?.required">required</div>
									<div *ngIf="accountNumber.errors?.pattern">Only numbers are allowed.</div>
									<div *ngIf="accountNumber.errors?.confirmValue">Account numbers do not match.</div>
									<div *ngIf="accountNumber.errors?.minlength || accountNumber.maxlength">Only 5 to 11 digits is allowed.</div>
								</div>
							</div>
							<div class="input-group l-input">
								<label class="g-form-label">Confirm Account Number<span class="g-orange">*</span></label>
								<input 
									id='new-account-confirm-input' 
									[(ngModel)]="confirmAccountNumber" 
									placeholder="12345678912" 
									type="number" 
									name="confirmAccountValue" 
									class="form-control w-100" 
									minlength="5" 
									maxlength="11" 
									pattern="\d{1,11}" 
									required
									#confirmAccountValue="ngModel" 
									appValueMatch 
									[confirmValue]="accountNumber"
									(ngModelChange)="validateAccountNumbers(accountDetailsForm)"
								/>
								<div class="g-error-input" *ngIf="confirmAccountValue.invalid && confirmAccountValue.touched">
									<div *ngIf="confirmAccountValue.errors?.required">required</div>
									<div *ngIf="confirmAccountValue.errors?.pattern">Only numbers are allowed.</div>
									<div *ngIf="confirmAccountValue.errors?.confirmValue">Account numbers do not match.</div>
								</div>
							</div>
						</div>	
						<div class="l-checkbox-container">
							<mat-checkbox class="example-margin me-auto" [checked]='bankDetails.isDefault'
								[(ngModel)]='bankDetails.isDefault' name="defaultAccount">Default Account</mat-checkbox>
						</div>

						<app-additional-info *ngIf="data.collectAdditionalInfo" 
							[email]="email"
							[isPublic]="data.allowPublicResale && !data.allowPrivateResale"
							(additionalInfo)="handleAdditionalInfo($event)"
							[allowPublicResale]="data.allowPublicResale"
							[allowPrivateResale]="data.allowPrivateResale"
							[isMobile]="data.isMobile"
						>
						</app-additional-info>
					</div>
				</div>
				<ng-template #banksLoading>
					<div class="l-loading">
						<mat-spinner></mat-spinner>
					</div>
				</ng-template>
				<ng-template #showBanksErrorMessage>
					<div class="l-error">{{banksErrorMessage}}</div>
				</ng-template>
			</form>

			<div class="l-buttons-container">
				<button id="cancel-button" class="g-secondary-button border-0" (click)="handleCancel()">CANCEL</button>
				<button id="save-button" class="g-primary-button" (click)="handleSave()"
					[class.disabled]="accountDetailsForm.invalid || banksErrorMessage || !additionalInfoIsValid"
					[disabled]="accountDetailsForm.invalid || banksErrorMessage || !additionalInfoIsValid">SAVE</button>
			</div>
		</div>
	</mat-tab>
	<mat-tab [label]="data.isMobile ? 'LINK':'LINK EXISTING'">
		<div class="l-container">
			<h3 class="mb-3">Link Existing Account</h3>
			<div class="mb-4">
				<p>
					{{ data.descriptions.linkExistingDescription }}
				</p>
			</div>
			<form #existingAccountForm="ngForm" class="g-form-container my-5">
				<div *ngIf="!isBankAccountsFetching; else bankAccountsLoading" class="w-100">
					<div *ngIf="!accountsErrorMessage; else showAccountsErrorMessage">
						<div class="input-group w-100 mb-3">
							<label class="g-form-label">Select Bank Account</label>
							<mat-select id="select-existing-account" class="form-control w-100" [(ngModel)]="selectedAccount" name="account"
								#account="ngModel" placeholder="Please Select" required #singleSelect>
								<mat-option *ngFor="let account of accounts" [value]="account">
									<strong>{{ account.bankName }}:</strong> {{account.number |
									formattedAccountNumberPipe}}
								</mat-option>
							</mat-select>
						</div>
						<app-additional-info *ngIf="data.collectAdditionalInfo"
							[email]="email"
							[isPublic]="data.allowPublicResale && !data.allowPrivateResale"
							(additionalInfo)="handleAdditionalInfo($event)"
							[allowPublicResale]="data.allowPublicResale"
							[allowPrivateResale]="data.allowPrivateResale"
							[isMobile]="data.isMobile"
						>
						</app-additional-info>
					</div>
				</div>
				<ng-template #bankAccountsLoading>
					<div class="l-loading">
						<mat-spinner></mat-spinner>
					</div>
				</ng-template>
				<ng-template #showAccountsErrorMessage>
					<div class="l-error">{{accountsErrorMessage}}</div>
				</ng-template>
			</form>

			<div class="l-buttons-container">
				<button id="cancel-button" class="g-secondary-button border-0" (click)="handleCancel()">CANCEL</button>
				<button id="save-button" class="g-primary-button" (click)="handleAccountSave()"
					[class.disabled]="existingAccountForm.invalid || accountsErrorMessage || !additionalInfoIsValid"
					[disabled]="existingAccountForm.invalid || accountsErrorMessage || !additionalInfoIsValid">SAVE</button>
			</div>
		</div>

	</mat-tab>
</mat-tab-group>
