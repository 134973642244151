<div [ngClass]="{'l-graph-details-mobile': isMobile, 'l-graph-details-web': !isMobile}">
	<div>
		<div class="l-graph-title">
			Total {{ isSales ? 'Sales' : 'Tickets'}} 
			<span *ngIf='isYearView'>For Past Year</span>
			<span *ngIf='!isYearView'>For {{ selectedMonthLabel }}</span>
		</div>
		<div
			*ngIf="isSales; else isTickets"
			class="l-graph-total fade-in"
		>
			{{ (isYearView ? totalValue : totalMonthValue) | currency: currencySymbol + ' '}}
		</div>
		<ng-template #isTickets>
			<div class="l-graph-total fade-in">
				{{ isYearView ? totalValue : totalMonthValue }}
			</div>
		</ng-template>
	</div>
	<div
		*ngIf="showLegend"
		class='l-legend'
	>
		<div class='l-legend-content' >
			<div class='l-legend-key l-legend-key-direct'></div>
			Direct
			<mat-icon 
				class="l-tooltip" 
				matTooltip="Sales generated from users originating outside of Quicket."
				matTooltipPosition="right"
				#directTooltip="matTooltip"
				(click)="directTooltip.toggle()"
			>
				info
        	</mat-icon>
		</div>
		<div class='l-legend-content'>
			<div class='l-legend-key l-legend-key-quicket'></div>
			Quicket
			<mat-icon 
				class="l-tooltip" 
				matTooltip="Sales originating from the Quicket platform e.g. the event search page."
				matTooltipPosition="right"
				#directTooltip="matTooltip"
				(click)="directTooltip.toggle()"
			>
				info
        	</mat-icon>
		</div>
		<div class='l-legend-content'>
			<div class='l-legend-key l-legend-key-link-campaigns'></div>
			Link Campaigns
			<mat-icon 
				class="l-tooltip" 
				matTooltip="Sales originating from one of your link campaigns."
				matTooltipPosition="right"
				#directTooltip="matTooltip"
				(click)="directTooltip.toggle()"
			>
				info
        	</mat-icon>
		</div>
		<div *ngIf="showTotal" class='l-legend-content' matTooltip="Total sales data">
			<div class='l-legend-key l-legend-key-total'></div>
			Total
		</div>
	</div>
</div>
<div
	[ngClass]="{'l-graph-container-web': !isMobile}"
>
	<ngx-charts-area-chart-stacked
		[scheme]="colorScheme"
		legend="false"
		[xAxis]="xAxis"
		[yAxis]="yAxis"
		[results]="displayData"
		[legend]='false'
		[curve]="curveType"
		[wrapTicks]="true"
		[xAxisLabel]='selectedMonthLabel'
		[showXAxisLabel]='!isYearView'
		[yAxisTickFormatting]="handleFormatYAxisLabel"
		(select)='handleSalesGraphSelect($event)'
	>
	</ngx-charts-area-chart-stacked>
	<div
		*ngIf="!isYearView"
		(click)="handleGraphReset()"
		class="l-graph-toggle fade-in"
		[ngClass]="{'l-graph-toggle-web': !isMobile, 'l-has-legend': (!showLegend && isMobile)}"
	>
		<mat-icon *ngIf='isMobile else showText'>keyboard_return</mat-icon>
		<ng-template #showText>SHOW YEAR VIEW</ng-template>
	</div>
</div>