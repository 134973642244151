import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { RefundableTicket } from '@app/models/user.model';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
	selector: 'app-refund-card',
	templateUrl: './refund-card.component.html',
	styleUrls: ['./refund-card.component.sass'],
})
export class RefundCardComponent {
	@Input() data: RefundableTicket;
	@Input() isMobile = false;
	@Input() image: string;
	@Output() ticket: EventEmitter<RefundableTicket> = new EventEmitter();

	checkboxState = false;

	@ViewChild('tooltip') tooltip: MatTooltip;

	handleCheckboxChange() {
		this.ticket.emit(this.data);
	}

	handleContainerSelect() {
		if (!this.data.canRefund) {
		  this.tooltip.show();
		} else {
		  this.checkboxState = !this.checkboxState;
		  this.handleCheckboxChange();
		}
	  }
}
