import { animate, state, style, transition, trigger } from '@angular/animations';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { SchemeID } from '@app/models/dataSchema.model';
import { TabbedContentService } from '@app/services/events/manage-event/tabbed-content.service';
import { ImageService, ImageServiceType } from '@app/services/image/image.service';
import { EditorImageUploadOptions } from '@app/shared/editor/editor.component';

@Component({
	selector: 'app-tab-content-mixed-content',
	templateUrl: './tab-content-mixed-content.component.html',
	styleUrls: ['./tab-content-mixed-content.component.sass'],
	animations: [
		trigger('detailExpand', [
			state('collapsed', style({ height: '*', minHeight: '*', opacity: '0' })),
			state('expanded', style({ height: '*', opacity: '1' })),
			transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
		]),
		trigger('rotateIcon', [
			state('default', style({
				transform: 'rotate(0deg)',
			})),
			state('rotated', style({
				transform: 'rotate(90deg)',
			})),
			transition('default <=> rotated', animate('200ms ease-out')),
		]),
	],
})
export class TabContentMixedContentComponent {
	@Input() eventId: SchemeID;
	@Input() form: UntypedFormGroup;
	@Input() dataSource: MatTableDataSource<any>;
	@Input() isMobile: boolean;
	@Input() editorImageUploadHandler: EditorImageUploadOptions;
	@Input() isImageUploading: boolean;
	@Output() deleteTab = new EventEmitter<number>();
	@Output() isLoadingChange = new EventEmitter<boolean>();

	displayColumns: string[] = ['sorting', 'isExpanded', 'name', 'visibility', 'delete'];
	expandedDetailElement: number;
	ImageServiceTypeEnum = ImageServiceType;
	dragDisabled = true;

	constructor(
		private imageService: ImageService,
		private tabbedContentService: TabbedContentService
	) {
		this.imageService.init(ImageServiceType.CONTENT_TAB);
	}


	handleDeleteTabContentType(tabContent: UntypedFormGroup) {
		const contentIndex = this.form.get('content').value.findIndex(x => x.contentUid === tabContent.value.contentUid);
		this.deleteTab.emit(contentIndex);
	}

	handleDrag(event: CdkDragDrop<string[]>): void {
		this.dragDisabled = true;
		moveItemInArray(this.dataSource.data, event.previousIndex, event.currentIndex);
		this.tabbedContentService.getTabContentControls(this.form)[event.currentIndex]['controls']['sortOrder']
			.setValue(event.currentIndex + 1);
		this.tabbedContentService.getTabContentControls(this.form).forEach((t, index) => {
			(t as UntypedFormGroup).controls['sortOrder'].setValue(index + 1);
		});
		this.dataSource.data = this.tabbedContentService.getTabContentControls(this.form);
	}

	handleExpandContent(tabContentType) {
		this.expandedDetailElement = this.expandedDetailElement === tabContentType.value.contentUid
			? null : tabContentType.value.contentUid;
	}

	handleLoading(isLoading: boolean) {
		this.isImageUploading = isLoading;
		this.isLoadingChange.emit(this.isImageUploading);
	}
}
