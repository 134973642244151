<div class="l-container">
	<div
		*ngIf="showSearch"
		class="l-search-container"
		[class.l-web-search]='!isMobile'
		[ngClass]="{'l-hidden-search': !isSearchVisible}"
	>
		<mat-icon class="l-icon">search</mat-icon>
		<input
			id='search-bar-input'
			[(ngModel)]="filterOptions.searchTerm"
			(keyup)="handleSearchChanged()"
			type="text"
			class="form-control l-search"
			[placeholder]="searchPlaceholder"
			id="search"
		/>
	</div>
	<div
		*ngIf="eventStatuses"
		class="input-group"
		[ngClass]="{'l-web-filters': !isMobile, 'l-mobile-filters': isMobile}"
	>
		<mat-select
			id='event-status-select'
			class="form-control w-100 l-input"
			[(ngModel)]="eventStatus"
			(selectionChange)="handleEventStatus()"
			name="eventStatus"
			#status="ngModel"
			placeholder="Event Status"
			#singleSelect
		>
			<mat-option [value]="null">
				All
			</mat-option>
			<mat-option
				*ngFor="let status of eventStatuses | keyvalue"
				[value]="status.key"
			>
				{{ status.value }}
			</mat-option>
		</mat-select>
	</div>
	<div
		*ngIf="organiserProfiles"
		class="input-group"
		[ngClass]="{'l-web-filters': !isMobile, 'l-mobile-filters': isMobile}"
	>
		<mat-select
			id='organiser-profile-select'
			class="form-control w-100 l-input"
			[(ngModel)]="filterOptions.organiserId"
			(selectionChange)="handleEmit()"
			name="organiser"
			#organiser="ngModel"
			placeholder="Organiser Profile"
			#singleSelect
		>
			<mat-option [value]="null">
				All
			</mat-option>
			<mat-option
				*ngFor="let organiser of organiserProfiles"
				[value]="organiser.id"
			>
				{{ organiser.name }}
			</mat-option>
		</mat-select>
	</div>
	<div
		*ngIf="showRangeDate"
		class="l-date-range-picker"
		[ngClass]="{'l-web-filters': !isMobile, 'l-mobile-filters': isMobile}"
	>
		<mat-form-field>
			<mat-label>Date range</mat-label>
			<mat-date-range-input [rangePicker]="rangePicker">
				<input
					id="start-date-range"
					matStartDate
					[(ngModel)]="dateFrom"
					placeholder="dd/mm/yyyy"
					(dateChange)="handleDateChange()"
				>
				<input
					id="end-date-range"
					matEndDate
					[(ngModel)]="dateTo"
					placeholder="dd/mm/yyyy"
					(dateChange)="handleDateChange()"
				>
			</mat-date-range-input>
			<mat-datepicker-toggle
				id="date-range-toggle"
				matIconSuffix
				[for]="rangePicker"
			></mat-datepicker-toggle>
			<mat-date-range-picker #rangePicker>
				<mat-date-range-picker-actions>
					<button
						id="close-datepicker"
						class="g-secondary-button g-datepicker-button"
						matDateRangePickerCancel
					>CANCEL</button>
					<button
						id="apply-datepicker"
						class="g-primary-button g-datepicker-button"
						matDateRangePickerApply
					>APPLY</button>
				</mat-date-range-picker-actions>
			</mat-date-range-picker>
		</mat-form-field>
	</div>
	<div
		*ngIf="paymentStatuses"
		class="input-group"
		[ngClass]="{'l-web-filters': !isMobile, 'l-mobile-filters': isMobile}"
	>
		<mat-select
			id='payment-status-select'
			class="form-control w-100 l-input"
			[(ngModel)]="filterOptions.paymentStatus"
			(selectionChange)="handleEmit()"
			name="paymentStatuses"
			#statuses="ngModel"
			placeholder="Payment Status"
			#singleSelect
		>
			<mat-option [value]="null">
				Any
			</mat-option>
			<mat-option
				*ngFor="let status of paymentStatuses | keyvalue: handleDefaultOrder"
				[value]="status.value"
			>
				{{ status.value }}
			</mat-option>
		</mat-select>
	</div>
	<div
		*ngIf="buttonText"
		[ngClass]="{'l-web-filters l-button-container': !isMobile, 'l-mobile-filters': isMobile}"
	>
		<button
			[id]="buttonId ? buttonId : 'primary-filter-button'"
			class='g-primary-button w-100'
			(click)='handleEmitButton()'
			[disabled]="isButtonDisabled"
			[ngClass]="{
				'disabled': isButtonDisabled,
				'l-primary-filter-button-disabled-with-text': isButtonDisabled && disabledTooltipText
			}"
		>
			{{ buttonText }}
			<mat-icon
				*ngIf="isButtonDisabled && disabledTooltipText"
				class='l-error-icon g-pointer'
				#invalidTicket="matTooltip"
				matTooltip="{{disabledTooltipText}}"
				matTooltipPosition="above"
				(click)="invalidTicket.toggle()"
			>
				error
			</mat-icon>
		</button>
	</div>
	<div
		*ngIf="reportButton"
		[ngClass]="{'l-web-filters l-button-container': !isMobile, 'l-mobile-filters': isMobile}"
	>
		<button
			class="l-download-report g-primary-button w-100"
			(click)='handleEmitButton()'
		><mat-icon class='l-download-icon'>save_alt</mat-icon>DOWNLOAD REPORT</button>
	</div>
	<div
		*ngIf="dropDownButtonText"
		id='drop-down-button'
		[ngClass]="{'l-web-filters l-button-container': !isMobile, 'l-mobile-filters': isMobile}"
	>
		<app-dropdown-button
			[options]='dropDownOptions'
			[buttonText]="dropDownButtonText"
			(optionSelected)='handleDropdownOption($event)'
		></app-dropdown-button>
	</div>
</div>