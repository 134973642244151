<div [ngClass]="{ 'input-group-mobile-large': isMobile, 'input-group-web-large': !isMobile }">
	<label class="g-form-label">
		<span [innerHTML]="label"></span>
		<span *ngIf='isRequired' class="g-orange"> *</span>
	</label>
	<mat-select-country id="country-select" class="form-control p-0 g-icon-center" [value]="country" (onCountrySelected)="onCountrySelected($event)"
			[disabled]='isDisabled'></mat-select-country>
	<div *ngIf="inputFormControl.invalid">
		<div class="g-error-input">
			<div *ngIf="inputFormControl.hasError('required')">A selection is required.</div>
		</div>
	</div>
</div>
