<div class="l-container">
    <div *ngIf="!isLoading else pageLoading;">
        <div class="l-header-container">
            <div class="l-header">
                <h4 class="g-f-16 m-0"> Recent Orders </h4>
            </div>
            <div class="l-header">
                <button class="g-flat-button"
                    *ngIf="recentOrders.length > 0"
                    (click)="handleViewAll()"
                >
                    <h4 class="g-f-16 m-0">View All</h4>
                </button>
            </div>
        </div>
        <div class="l-data" *ngIf="recentOrders.length > 0; else noOrders">
            <table class="l-table">
                <tr *ngFor="let order of recentOrders | slice:0:6" class="l-table-row">
                    <td class="l-table-data">
                        <a 
                            [href]="order.link"
                            target="_blank"
                            class="l-order-link"
                        >
                        
                            #{{ order.purchaseId }}
                        </a>
                    </td>
                    <td class="l-table-data"> {{ currencySymbol }}{{ order.orderAmount }} </td>
                    <td class="l-table-data l-table-data-date"> {{ order.date | date:'dd/MM/yyyy' }}</td>
                </tr>
            </table>
        </div>
        <ng-template #noOrders>
            <div class="l-no-orders">
                <label class="g-form-label">No orders yet.</label>
            </div>
        </ng-template>
    </div>
    <ng-template #pageLoading>
        <div class="l-page-loader">
            <mat-spinner></mat-spinner>
        </div>
    </ng-template>
</div>