import { NgModule } from '@angular/core';
import { EventStreamingComponent } from './event-streaming.component';
import { SharedModule } from '@app/shared/shared.module';
import { EventSharedModule } from '@app/features/event-shared/event-shared.module';
import { EffectsModule } from '@ngrx/effects';
import { StreamingEffects } from './event-streaming.effects';
import { StreamingService } from './event-streaming.service';
import { DecimalPipe } from '@angular/common';
import { StreamingRoomsFormComponent } from './event-streaming-rooms-form/streaming-rooms-form.component';
import { NotificationModule } from '@app/services/notification/notification.module';
import { EventStreamigNotificationDictionary } from './event-streaming.model';
import { StreamingOrganiserCodeComponent } from './event-streaming-code-modal/organiser-code-modal.component';

@NgModule({
	imports: [
		SharedModule,
		EventSharedModule,
		EffectsModule.forFeature([StreamingEffects]),
		NotificationModule.forFeature([EventStreamigNotificationDictionary]),
	],
	declarations: [
		EventStreamingComponent,
		StreamingRoomsFormComponent,
		StreamingOrganiserCodeComponent,
	],
	exports: [
		EventStreamingComponent,
	],
	providers: [
		StreamingService,
		DecimalPipe,
	],
}) export class EventStreamingModule {}
