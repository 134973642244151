import { Injectable } from '@angular/core';
import { URLCreatorList } from '@app/models/url.model';
import { URLCreator } from '@app/services/url/url.dictionary';

export const defaultDashboardRoute = '/account/organiser-hub/events';
export const defaultUserDashboardRoute = '/account';

@Injectable()
export class URLService {
	static URLWithBase: (keyof URLCreatorList)[] = ['helpCentre', 'blog'];

	navigate = (path: keyof URLCreatorList, config: any[], extraLinkPart?: string) => () => {
		const url = URLCreator[path as any](...config) + (extraLinkPart || '');
		window.location.href = url;
	};
}
