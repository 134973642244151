<div class="l-event-creation-intro">
	<div
		class="l-intro-step"
		*ngFor="let introStep of introSteps; let i = index;"
		[hidden]="i !== activeIntroStep"
	>
		<div class="l-intro-step-icon">
			<i [class]="'fa fa-4x fa-' + introStep.icon"></i>
		</div>
		<div class="l-intro-step-desc-wrap">
			<span class="l-intro-step-title">
				{{introStep.title}}
			</span>
			<p class="l-intro-step-desc">
				{{introStep.desc}}
			</p>
			<button
				class="g-secondary-button mt-3"
				(click)="onNextIntroStep()"
				id="next-step-{{i}}"
			>
				{{ nextButtonLabel(i) }}
			</button>
			<div class="l-intro-step-indicators ">
				<div
					*ngFor="let ind of [null, null, null, null]; let indI = index;"
					[ngClass]="getIndicatorClasses(i, indI)"
					(click)="setIntroStep(indI)"
					id="step-indicator-{{indI}}"
				>
				</div>
			</div>
			<button
				class="g-flat-button l-skip-intro"
				(click)="skipIntro()"
				id="skip-intro"
			>
				{{ skipButtonLabel(i) }}
			</button>
		</div>
	</div>
</div>