import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { Notification, NotificationType } from '@app/models/notification.model';

@Component({
	selector: 'app-mobile-notification',
	templateUrl: './mobile-notification.component.html',
	styleUrls: ['./mobile-notification.component.sass'],
})
export class MobileNotificationComponent {
	NotificationType = NotificationType;
	constructor(public snackbarRef: MatSnackBarRef<MobileNotificationComponent>, @Inject(MAT_SNACK_BAR_DATA) public data: any) {}

	getNotificationIcon(ntf: Notification) {
		const type = {
			success: 'check',
			info: 'info',
			error: 'exclamation',
		};
		return {
			fa: true,
			[`fa-${type[ntf.type]}`]: true,
			[`${ntf.type}-colour`]: true,
		};
	}

	retry() {
		this.snackbarRef.dismissWithAction();
	}

	close() {
		this.snackbarRef.dismiss();
	}
}
