<div class="d-flex flex-column" [ngClass]="{'m-3': isMobile, 'm-4': !isMobile}">
	<app-web-content-header *ngIf="!isMobile; else mobileHeaderContent" [headerContent]="headerContent"
		[mediumWidth]="true"></app-web-content-header>
	<ng-template #mobileHeaderContent>
		<div class='my-3'>{{ headerContent.description }}</div>
	</ng-template>
	<div class="l-table-container">
		<div *ngIf="!isMailAccountsLoading else mailAccountsLoading" class="d-flex flex-column"
			[ngClass]="{'mt-1': isMobile, 'mt-4': !isMobile}">
			<div *ngIf="mailAccounts.data.length; else noMailAccounts" class='mt-0'>
				<app-web-filter class='mt-3' [isMobile]="isMobile" [buttonText]="'ADD MAIL ACCOUNT'"
					(filterOutput)='handleFilterOutput($event)' (buttonClick)='handleAddMailAccount()'></app-web-filter>
				<div class="l-table-container">
					<table mat-table [dataSource]="mailAccounts" class='l-table web' matSort>
						<ng-container matColumnDef="name">
							<th mat-header-cell *matHeaderCellDef mat-sort-header> Account Name </th>
							<td mat-cell *matCellDef="let account">{{account.name}}</td>
						</ng-container>
						<ng-container matColumnDef="fromName">
							<th mat-header-cell *matHeaderCellDef mat-sort-header> From Name </th>
							<td mat-cell *matCellDef="let account">{{ account.fromName }} </td>
						</ng-container>
						<ng-container matColumnDef="fromEmail">
							<th mat-header-cell *matHeaderCellDef mat-sort-header> From Email</th>
							<td mat-cell *matCellDef="let account">{{ account.fromEmail }} </td>
						</ng-container>
						<ng-container matColumnDef="typeName">
							<th mat-header-cell *matHeaderCellDef> Account Type </th>
							<td mat-cell *matCellDef="let account">{{ account.typeName}}</td>
						</ng-container>
						<ng-container matColumnDef="more">
							<th mat-header-cell *matHeaderCellDef></th>
							<td mat-cell *matCellDef="let account">
								<div class='l-more' (click)='handleDeleteMailAccount(account)'>
									<mat-icon class='l-icon'>delete_outline</mat-icon>
								</div>
							</td>
						</ng-container>
						<tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
						<tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>

						<tr *matNoDataRow>
							<td colspan="5">
								<div class="l-empty-state">
									<h3 class="mb-3">Nothing to see here...</h3>
									<div class="mb-3 l-empty-text">Nothing matches your search: <span
											class='l-search-term'>{{searchTerm}}</span></div>
								</div>
							</td>
						</tr>
					</table>
					<mat-paginator #paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
				</div>
			</div>
			<ng-template #noMailAccounts>
				<div class='l-empty-card'>
					<app-no-products-card [title]="'No Mail Accounts'"
						[content]="'You don\'t have any mail accounts. Connect your account now!'"
						[buttonText]="'ADD MAIL ACCOUNT'" (buttonClick)='handleAddMailAccount()'></app-no-products-card>
				</div>
			</ng-template>
		</div>
	</div>

	<ng-template #mailAccountsLoading>
		<div class="l-loading">
			<mat-spinner></mat-spinner>
		</div>
	</ng-template>
</div>
