import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { UserPreviousContactList } from '@app/models/event.model';
import { StoreService } from '@app/services/store/store.service';
import { Actions, ofType } from '@ngrx/effects';
import * as userContactsSelector from '@app/store/selectors/userContacts.selector';
import { takeUntil } from 'rxjs';
import { UserActionsConstants } from '@app/store/actions/user/user.actions.constants';
import { GetPreviousContactLists } from '@app/store/actions/user/user.actions';
import { FilterOptions } from '@app/models/shared';
import { MatTableDataSource } from '@angular/material/table';
import { SendInvites } from '@app/store/actions/event/event.actions';
import { MatSort } from '@angular/material/sort';
import { EventActionsConstants } from '@app/store/actions/event/event.actions.constants';

@Component({
	selector: 'app-previous-contact-list',
	templateUrl: './previous-contact-list.component.html',
	styleUrls: ['./previous-contact-list.component.sass'],
})
export class PreviousContactListComponent implements OnInit {
	isContactsLoading = true;
	isButtonLoading = false;
	isWeb = true;
	isButtonDisabled = true;
	hasInitialContactsLists: boolean;
	contactList: MatTableDataSource<UserPreviousContactList> = new MatTableDataSource();
	sortInitialized = false;
	selectedContacts: UserPreviousContactList[] = [];
	displayColumns: string[] = ['checkbox', 'name', 'contactsCount'];

	@Input() eventId: number;
	@Input() isMobile = false;

	@ViewChild(MatSort) set sort(_sort: MatSort) {
		if (_sort && !this.sortInitialized) {
			this.sortInitialized = true;
			this.contactList.sort = _sort;
		}
	};

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private store: StoreService,
		private actions$: Actions
	) { }

	ngOnInit() {
		this.store.select(userContactsSelector.userPreviousContactLists())
			.pipe(takeUntil(this.destroyed$)).subscribe((contacts: UserPreviousContactList[]) => {
				if (contacts) {
					if (contacts.length > 0) {
						this.hasInitialContactsLists = true;
					}
					this.contactList.data = contacts;
				}
			});
		this.actions$
			.pipe(
				ofType(UserActionsConstants.GET_PREVIOUS_CONTACT_LISTS_SUCCESS, UserActionsConstants.GET_PREVIOUS_CONTACT_LISTS_FAILED),
				takeUntil(this.destroyed$)
			).subscribe(() => {
				this.isContactsLoading = false;
			});
		this.store.dispatch(new GetPreviousContactLists({
			eventId: this.eventId,
		}));
	}

	handleCheckboxChange(contact: UserPreviousContactList) {
		const index = this.selectedContacts.indexOf(contact, 0);
		if (index === -1) {
			this.selectedContacts.push(contact);
		} else {
			this.selectedContacts.splice(index, 1);
		}
		this.isButtonDisabledCheck();
	}

	isSelected(contact: UserPreviousContactList): boolean {
		return this.selectedContacts.includes(contact);
	}

	handleSelectAllContacts() {
		if (this.selectedContacts.length === this.contactList.data.length) {
			this.selectedContacts = [];
		} else {
			this.selectedContacts = [...this.contactList.data];
		}
		this.isButtonDisabledCheck();
	}

	isAllSelected() {
		return this.selectedContacts.length === this.contactList.data.length ? true : false;
	}

	isButtonDisabledCheck() {
		this.isButtonDisabled = this.selectedContacts.length === 0;
	}

	handleFilterOutput(filterOutput: FilterOptions) {
		const {searchTerm} = filterOutput;
		this.contactList.filter = searchTerm.trim().toLowerCase();
	}

	handleSendInvites() {
		this.isButtonLoading = true;
		this.actions$
			.pipe(
				ofType(EventActionsConstants.SEND_INVITES_SUCCESS, EventActionsConstants.SEND_INVITES_FAILED),
				takeUntil(this.destroyed$)
			).subscribe(() => {
				this.isButtonLoading = false;
				this.isButtonDisabled = true;
			});
		this.store.dispatch(
			new SendInvites({
				eventId: this.eventId,
				contactsListId: this.selectedContacts.map(el => el.id),
			})
		);
	}
}
