import { Component, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Router, ActivatedRoute } from '@angular/router';
import { EventMetadata } from '@app/models/event-metadata.model';
import { URLCreator } from '@app/services/url/url.dictionary';
import { getCookie } from '@app/utils/Cookie';
import { CookieKey } from '@app/models/cookies.model';
import { Subscription, Observable } from 'rxjs';
import { ProductType } from '@app/models/product.model';
// import { FeatureFlag, FeatureFlagTreatment } from '@app/models/feature-flag.model';
// import { FeatureFlagService } from '@app/services/feature-flag/feature-flag.service';
import { transformToFriendlyURL } from '@app/utils/URL';

export interface MenuItemWithFlag extends MenuItem {
	featureFlag?: Observable<boolean>;
}

@Component({
	selector: 'app-control-panel-sidebar',
	templateUrl: './control-panel-sidebar.component.html',
	styleUrls: ['./control-panel-sidebar.component.sass'],
})
export class ControlPanelSidebarComponent implements OnChanges, OnDestroy {

	@Input() isEventSidebar = true;
	@Input() isTaxInvoiceAllowed: boolean;
	@Input() isEventSummaryAllowed: boolean;
	@Input() seatingChartId: string;
	@Input() event: EventMetadata;
	@Input() userEncryptionKey: string;
	@Input() isSidebar: boolean;
	@Input() isEventOngoing: boolean;
	@Input() isEventDDTEnabled: boolean;
	ProductTypeEnum = ProductType;

	paramsSub: Subscription;

	items: MenuItemWithFlag[] = [];
	constructor(
		private router: Router,
		private route: ActivatedRoute// ,
		// private featureFlagService: FeatureFlagService
	) {
		this.paramsSub = this.route.url
			.subscribe(() => {
				if (this.event) {
					this.items = this.getItems(this.event, {
						isEventSummary: this.isEventSummaryAllowed,
						isTaxInvoice: this.isTaxInvoiceAllowed,
						isSeatingChartAllowed: this.seatingChartId,
						isEventOngoing: this.isEventOngoing,
						isEventDDTEnabled: this.isEventDDTEnabled,
					});
				}
			});
	}

	ngOnDestroy() {
		this.paramsSub.unsubscribe();
	}

	ngOnChanges({ event, seatingChartId }: SimpleChanges) {
		if (
			event
			&& event.currentValue
			&& (event.currentValue as EventMetadata).id !== (
				event.previousValue
					? (event.previousValue as EventMetadata).id
					: null
			)
		) {
			this.items = this.getItems(event.currentValue,
				{
					isEventSummary: this.isEventSummaryAllowed,
					isTaxInvoice: this.isTaxInvoiceAllowed,
					isSeatingChartAllowed: this.seatingChartId,
					isEventOngoing: this.isEventOngoing,
					isEventDDTEnabled: this.isEventDDTEnabled,
				});
		}

		if (seatingChartId && this.event && seatingChartId.previousValue !== seatingChartId.currentValue) {
			this.items = this.getItems(this.event,
				{
					isEventSummary: this.isEventSummaryAllowed,
					isTaxInvoice: this.isTaxInvoiceAllowed,
					isSeatingChartAllowed: this.seatingChartId,
					isEventOngoing: this.isEventOngoing,
					isEventDDTEnabled: this.isEventDDTEnabled,
				});
		}
	}

	getItems(event: EventMetadata, arePagesVisible: {
		isTaxInvoice: boolean;
		isEventSummary: boolean;
		isSeatingChartAllowed: string;
		isEventOngoing: boolean;
		isEventDDTEnabled: boolean;
	}) {
		const scheduleItem = getCookie(CookieKey.ScheduleItem) || 0;
		const { id, currencyISO } = event;
		const eventPageItems: MenuItem[] = [
				{
					label: 'Profile',
					url: URLCreator.eventOrganiser(id),
				},
				{
					label: 'Event details',
					url: URLCreator.eventDetails(id),
				},
				{
					label: 'Event settings',
					url: URLCreator.eventDetailsSettings(id),
				},
				{
					label: 'Tabbed content',
					url: URLCreator.tabbedContent(id),
				},
				{
					label: 'Design',
					url: URLCreator.design(id),
				},
			],

			checkoutItems: MenuItem[] = [
				{
					label: 'Ticket types',
					url: URLCreator.ticketTypes(id),
				},
				{
					label: 'Ticket settings',
					url: URLCreator.ticketSettings(id),
				},
				{
					label: 'Ticket groups',
					url: URLCreator.ticketGroups(id),
				},
				{
					label: 'Collect info',
					url: URLCreator.checkoutQuestions(id),
				},
				{
					label: 'Merchandise',
					url: URLCreator.merchendise(id),
				},
				{
					label: 'Payment options',
					url: URLCreator.paymentOptions(id),
				},
			],
			manageItems: MenuItem[] = [
				{
					label: 'Definitions',
					url: URLCreator.eventSettings(id),
				},
				{
					label: 'User access',
					url: URLCreator.userAccess(id),
				},
				{
					label: 'Payout details',
					url: URLCreator.payoutDetails(id),
				},
				{
					label: 'Copy event',
					url: URLCreator.eventCopy(id),
				},
			],
			promoteItems: MenuItemWithFlag[] = [
			/* {
				label: 'Publish to Facebook',
				url: URLCreator.facebookIntegration(id),
				featureFlag: this.featureFlagService.isTreatmentMatchAsync(FeatureFlag.facebookEvents, FeatureFlagTreatment.on),
			}, */
				{
					label: 'Promotional Codes',
					url: URLCreator.discountCodes(id),
				},
				/* {
				label: 'Share and Save',
				url: URLCreator.shareAndSave(id),
			}, */
				{
					label: 'Link Campaigns',
					url: URLCreator.linkCampaigns(id),
				},
			],
			guestManagmentItems: MenuItem[] = [
				{
					label: 'Orders List',
					url: URLCreator.orders(id),
				},
				{
					label: 'Complimentaries',
					url: URLCreator.complimentaries(id),
				},
				{
					label: 'RSVPs',
					url: URLCreator.invitations(id),
				},
				{
					label: 'Printed Tickets',
					url: URLCreator.printedTicketsAspx(id),
				},
			/* {
				label: 'SMS your guests',
				url: URLCreator.SMSyourGuests(id),
			}, */
			],
			integrateItems: MenuItem[] = [
			/* {
				label: 'Sell on Facebook',
				url: URLCreator.sellOnFacebook(id),
			}, */
				{
					label: 'Zapier',
					url: URLCreator.zapier(id),
				},
				{
					label: 'Mailchimp',
					url: URLCreator.mailChimp(id),
				},
				{
					label: 'Widget',
					url: URLCreator.widget(id),
				},
				{
					label: 'Webhooks',
					url: URLCreator.webhooks(id),
				},
				{
					label: 'Tracking Scripts',
					url: URLCreator.trackingScriptsAspx(),
					target: '_blank',
				},
			],
			reportsItems: MenuItem[] = [
				{
					label: 'Visitors report',
					url: URLCreator.visitorsReport(id),
				},
				{
					label: 'Sales report',
					url: URLCreator.salesReport(id),
				},
				{
					label: 'Checkin report',
					url: URLCreator.checkinReport(id),
				},
				{
					label: 'Reviews report',
					url: URLCreator.reviewsReport(id),
				},
				{
					label: 'Export data',
					url: URLCreator.exportData(id),
				},
			],
			onTheDayItems: MenuItem[] = [
				{
					label: 'Scanners',
					url: URLCreator.scanners(id),
				},
				{
					label: 'Sell at the door',
					url: URLCreator.sellAtTheDoor(id),
				},
			/* {
				label: 'Search',
				url: URLCreator.scanSearch(id),
			}, */
			],
			helpItems: MenuItem[] = [
				{
					label: 'Help Centre',
					url: URLCreator.helpCentre(),
				},
				{
					label: 'Blog',
					url: URLCreator.blog(),
				},
			],
			/* fundraiserCheckoutItems: MenuItem[] = [
			{
				label: 'Collect info',
				url: URLCreator.collectInfo(id),
			},
		];*/
			fundraiserManageItems: MenuItem[] = [
				{
					label: 'Payout details',
					url: URLCreator.payoutDetails(id),
				},
				/* {
				label: 'User access',
				url: URLCreator.userAccess(id),
			}, */
				{
					label: 'Payment options',
					url: URLCreator.paymentOptions(id),
				},
			],
			fundraiserOrdersItems: MenuItem[] = [
				{
					label: 'Orders List',
					url: URLCreator.orders(id),
				},
			],
			fundraiserReportsItems: MenuItem[] = [
				{
					label: 'Export data',
					url: URLCreator.exportData(id),
				},
			],
			subscriptionCheckoutItems: MenuItem[] = [
				{
					label: 'Collect info',
					url: URLCreator.checkoutQuestions(id),
				},
				{
					label: 'Payment options',
					url: URLCreator.paymentOptions(id),
				},
			],
			subscriptionManageItems: MenuItem[] = [
				{
					label: 'Payout details',
					url: URLCreator.payoutDetails(id),
				},
			],
			subscriberManagmentItems: MenuItem[] = [
				{
					label: 'SMS your subscribers',
					url: URLCreator.SMSyourGuests(id),
				},
			],
			subscriptionIntegrateItems: MenuItem[] = [
				{
					label: 'Mailchimp',
					url: URLCreator.mailChimp(id),
				},
			],
			subscriptionReportsItems: MenuItem[] = [];

		if (currencyISO === 'ZAR') {
			guestManagmentItems.push({
				label: 'SMS your guests',
				url: URLCreator.SMSyourGuests(id),
			});
		}

		if (arePagesVisible && arePagesVisible.isEventOngoing) {
			guestManagmentItems.push({
				label: 'Transfer schedules',
				url: URLCreator.eventScheduleTransfer(id),
			});
		}

		if (arePagesVisible && arePagesVisible.isEventSummary) {
			reportsItems.push({
				label: 'Event summary',
				url: URLCreator.eventSummary(id, event.accountingVersion, event.encrypt, scheduleItem),
				target: '_blank',
			});
		}

		if (arePagesVisible && arePagesVisible.isTaxInvoice) {
			reportsItems.push({
				label: 'Tax invoice',
				url: URLCreator.taxInvoice(id, event.accountingVersion, event.encrypt, scheduleItem),
				target: '_blank',
			});
		}

		if (arePagesVisible && arePagesVisible.isEventSummary) {
			fundraiserReportsItems.push({
				label: 'Summary',
				url: URLCreator.eventSummary(id, event.accountingVersion, event.encrypt, scheduleItem),
			});
		}

		if (arePagesVisible && arePagesVisible.isTaxInvoice) {
			fundraiserReportsItems.push({
				label: 'Tax invoice',
				url: URLCreator.taxInvoice(id, event.accountingVersion, event.encrypt, scheduleItem),
			});
		}

		if (arePagesVisible && arePagesVisible.isEventSummary) {
			subscriptionReportsItems.push({
				label: 'Subscription summary',
				url: URLCreator.eventSummary(id, event.accountingVersion, event.encrypt, scheduleItem),
			});
		}

		if (arePagesVisible && arePagesVisible.isTaxInvoice) {
			subscriptionReportsItems.push({
				label: 'Tax invoice',
				url: URLCreator.taxInvoice(id, event.accountingVersion, event.encrypt, scheduleItem),
			});
		}

		if (event.hasVendors) {
			reportsItems.push({
				label: 'Vending report',
				url: URLCreator.vendingReport(id),
			});
		}

		if (event && event.productType === this.ProductTypeEnum.Event) {
			return [
				{
					label: 'Dashboard',
					styleClass: 'sidebar-link',
					icon: 'fa fa-home',
					url: URLCreator.dashboard(id, event.accountingVersion),
				},
				{
					label: 'Event Page',
					styleClass: 'sidebar-link',
					icon: 'fa fa-edit',
					items: eventPageItems,
					id: 'step.event-page',
					expanded: this.isExpanded(eventPageItems),
				},
				{
					label: 'Checkout',
					icon: 'fa fa-shopping-cart',
					styleClass: 'sidebar-link',
					items: checkoutItems,
					id: 'step.checkout',
					expanded: this.isExpanded(checkoutItems),
				},
				{
					label: 'Manage',
					icon: 'fa fa-cog',
					styleClass: 'sidebar-link',
					id: 'step.manage',
					items: manageItems,
					expanded: this.isExpanded(manageItems),
				},
				{
					label: 'Promote',
					icon: 'fa fa-bullhorn',
					styleClass: 'sidebar-link',
					items: promoteItems,
					id: 'step.promote',
					expanded: this.isExpanded(promoteItems),
				},
				{
					label: 'Guest Management',
					icon: 'fa fa-users',
					styleClass: 'sidebar-link',
					items: guestManagmentItems,
					id: 'step.guests',
				},
				{
					label: 'Integrate',
					icon: 'fa fa-code',
					styleClass: 'sidebar-link',
					items: integrateItems,
					id: 'step.integrate',
				},
				{
					label: 'Reports',
					icon: 'fa fa-chart-pie',
					styleClass: 'sidebar-link',
					items: reportsItems,
					id: 'step.reports',
				},
				{
					label: 'On the day',
					icon: 'fa fa-clock',
					styleClass: 'sidebar-link',
					items: onTheDayItems,
					id: 'step.on-the-day',
				},
				{
					label: 'Preview',
					icon: 'fa fa-eye',
					styleClass: 'sidebar-link',
					url: URLCreator.preview(id, event.name, event.productStatus),
					target: '_blank',
					id: 'step.preview',
					command: () => {
						this.items = this.getItems(this.event, {
							isEventSummary: this.isEventSummaryAllowed,
							isTaxInvoice: this.isTaxInvoiceAllowed,
							isSeatingChartAllowed: this.seatingChartId,
							isEventOngoing: this.isEventOngoing,
							isEventDDTEnabled: this.isEventDDTEnabled,
						});
					},
				},
				{
					label: 'Help',
					styleClass: 'sidebar-link',
					icon: 'fa fa-question-circle',
					items: helpItems,
				},
			] as MenuItem[];
		} else if (event && event.productType === this.ProductTypeEnum.Fundraiser) {
			return [
				/* {
					label: 'Checkout',
					icon: 'fa fa-shopping-cart',
					styleClass: 'sidebar-link',
					items: fundraiserCheckoutItems,
					id: 'step.checkout',
					expanded: this.isExpanded(checkoutItems),
				},*/
				{
					label: 'Manage',
					icon: 'fa fa-cog',
					styleClass: 'sidebar-link',
					id: 'step.manage',
					items: fundraiserManageItems,
					expanded: this.isExpanded(manageItems),
				},
				{
					label: 'Orders',
					icon: 'fa fa-users',
					styleClass: 'sidebar-link',
					items: fundraiserOrdersItems,
					id: 'step.guests',
				},
				{
					label: 'Reports',
					icon: 'fa fa-chart-pie',
					styleClass: 'sidebar-link',
					items: fundraiserReportsItems,
					id: 'step.reports',
				},
				{
					label: 'Help',
					styleClass: 'sidebar-link',
					icon: 'fa fa-question-circle',
					items: helpItems,
				},
			] as MenuItem[];
		} else if (event && event.productType === this.ProductTypeEnum.OrganiserSubscriptions) {
			return [
				{
					label: 'Checkout',
					icon: 'fa fa-shopping-cart',
					styleClass: 'sidebar-link',
					items: subscriptionCheckoutItems,
					id: 'step.checkout',
					expanded: this.isExpanded(subscriptionCheckoutItems),
				},
				{
					label: 'Manage',
					icon: 'fa fa-cog',
					styleClass: 'sidebar-link',
					id: 'step.manage',
					items: subscriptionManageItems,
					expanded: this.isExpanded(subscriptionManageItems),
				},
				{
					label: 'Subscribers',
					icon: 'fa fa-users',
					styleClass: 'sidebar-link',
					items: subscriberManagmentItems,
					id: 'step.guests',
					expanded: this.isExpanded(subscriberManagmentItems),
				},
				{
					label: 'Integrate',
					icon: 'fa fa-code',
					styleClass: 'sidebar-link',
					items: subscriptionIntegrateItems,
					id: 'step.integrate',
					expanded: this.isExpanded(subscriptionIntegrateItems),
				},
				{
					label: 'Reports',
					icon: 'fa fa-chart-pie',
					styleClass: 'sidebar-link',
					items: subscriptionReportsItems,
					id: 'step.reports',
					expanded: this.isExpanded(subscriptionReportsItems),
				},
				{
					label: 'Preview subscriptions',
					icon: 'fa fa-eye',
					styleClass: 'sidebar-link',
					url: URLCreator.viewOrganiserProfile() + event.organiserId + '-' + transformToFriendlyURL(event.organiserName),
					target: '_blank',
					id: 'step.preview',
					command: () => {
						this.items = this.getItems(this.event, {
							isEventSummary: this.isEventSummaryAllowed,
							isTaxInvoice: this.isTaxInvoiceAllowed,
							isSeatingChartAllowed: this.seatingChartId,
							isEventOngoing: this.isEventOngoing,
							isEventDDTEnabled: this.isEventDDTEnabled,
						});
					},
				},
			] as MenuItem[];
		}
	}

	getSidebarClasses() {
		return {
			'dashboard-sidebar': true,
			'dashboard-sidebar__open': this.isSidebar,
		};
	}

	isExpanded(items: MenuItem[]): boolean {
		const link = items
			.find(el => el.url.includes(this.router.url));

		if (link) {
			link.styleClass = `${link.styleClass} sidebar-active-link`;
			return true;
		}
		return false;
	}
}
