<div class="l-container">
	<div class='l-header-container'>
		<mat-icon class='l-lock'>lock</mat-icon>
		<h3 class='mb-0'>Linked Products</h3>
	</div>
	<div class="mb-3">This organiser profile is currently linked to the below products.
		Please select a new profile for each product before deleting the profile.
	</div>
	<div class='mb-5'>
		<ul *ngFor="let product of data.linkedProducts; let i = index">
			<li>
				<span class="l-badge" [ngClass]="{ 'l-active': product.isActive }">
					{{ product.isActive ? 'ACTIVE' : 'INACTIVE'}}
				</span>
				- {{product.name}}
				<span class="g-pointer g-green" (click)='handleRouting(product)'>
					(Manage {{getManageProductType(product)}})
				</span>
			</li>
		</ul>
	</div>
	<div class="l-buttons-container">
		<button class="g-primary-button l-orange" (click)="handleClose()">CLOSE</button>
	</div>


</div>
