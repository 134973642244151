import { Component, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HeaderContent } from '@app/models/shared';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { StoreService } from '@app/services/store/store.service';
import { InternalURLCreator } from '@app/services/url/url.dictionary';
import { GetEventSettings, UpdateEventSettings } from '@app/store/actions/event/event.actions';
import { EventActionsConstants } from '@app/store/actions/event/event.actions.constants';
import { EVENT_ID_PARAM_KEY } from '@app/utils/consts';
import { Actions, ofType } from '@ngrx/effects';
import { filter, Observable, switchMap, take, takeUntil } from 'rxjs';
import * as eventSelectors from '@app/store/selectors/event.selector';
import { EventSettingsService } from '@app/services/events/settings/event-settings.service';
import { AbstractControl, FormGroup } from '@angular/forms';
import { EventDetailsSettings } from '@app/models/event.model';
import { FormCanDeactivate } from '@app/services/guards/event.guard';
import { ConfirmationModalComponent } from '@app/shared/modals/confirmation-modal/confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
	selector: 'app-event-settings',
	templateUrl: './event-settings.component.html',
	styleUrls: ['./event-settings.component.sass'],
})
export class EventSettingsComponent extends FormCanDeactivate {
	headerContent: HeaderContent = {
		breadCrumbs: [
			{
				routeName: 'Settings',
				routeTo: () => InternalURLCreator.settings(this.eventId),
			},
			{
				routeName: 'Event Settings',
			},
		],
		title: 'Event Settings',
		description: `Various settings options to configure and customize aspects of your event, 
		ensuring a smooth and tailored experience.`,
	};
	customDescription = this.headerContent.description.substring(0, 50) + '...';
	readMore = true;
	readMoreText = 'Read More';

	eventId: number;
	isMobile: boolean;
	destroyed$: EventEmitter<void> = new EventEmitter<void>();
	isUpdating = false;
	isPageLoading = true;
	form: FormGroup;
	covidComplianceEnabled: boolean;

	constructor(
		private breakpointService: BreakpointService,
		private activatedRoute: ActivatedRoute,
		private actions$: Actions,
		private store: StoreService,
		private settingsService: EventSettingsService,
		private dialog: MatDialog
	) {
		super();
	}

	ngOnInit() {
		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});

		this.activatedRoute.parent.paramMap.pipe(take(1)).subscribe((params) => {
			this.eventId = +params.get(EVENT_ID_PARAM_KEY);
			this.store.select(eventSelectors.eventMetadata(this.eventId)).pipe(take(1))
				.subscribe((metaData) => {
					this.covidComplianceEnabled = metaData.covidComplianceEnabled;
				});
			this.store.dispatch(new GetEventSettings({ id: this.eventId }));
		});

		this.actions$
			.pipe(
				ofType(
					EventActionsConstants.GET_EVENT_SETTINGS_SUCCESS,
					EventActionsConstants.GET_EVENT_SETTINGS_FAILED
				),
				takeUntil(this.destroyed$)
			)
			.subscribe(() => {
				this.isPageLoading = false;
			});

		this.actions$
			.pipe(
				ofType(
					EventActionsConstants.UPDATE_EVENT_SETTINGS_FAILED,
					EventActionsConstants.UPDATE_EVENT_SETTINGS_SUCCESS
				),
				takeUntil(this.destroyed$)
			)
			.subscribe(() => {
				this.isUpdating = false;
				this.form.markAsPristine();
			});

		this.store
			.select(eventSelectors.eventSettings(this.eventId))
			.pipe(
				takeUntil(this.destroyed$),
				filter(settings => !!settings),
				switchMap((settings: EventDetailsSettings) => {
					this.form = this.settingsService.createEventDetailsSettingsForm(settings);
					return this.form.get('allowTicketTransfer').valueChanges;
				})
			)
			.subscribe((v: boolean) => {
				if (!v) {
					this.form.patchValue({
						lockTransferredTickets: false,
						cutOffTransfers: false,
					});
				}
			});
	}

	handleSave() {
		this.isUpdating = true;
		this.store.dispatch(
			new UpdateEventSettings({
				id: this.eventId,
				settings: {
					...this.form.getRawValue(),
				},
			})
		);
	}

	handleReadMore() {
		this.readMore = !this.readMore;
		this.readMoreText = this.readMore ? 'Read More' : 'Read Less';
	}

	fromForm(controlKey: string): AbstractControl {
		if (this.form) {
			return this.form.get(controlKey);
		}
		return null;
	}

	getAllowTicketEditTooltip(covidCompliant: boolean) {
		if (covidCompliant === true) {
			return 'Ticket editing cannot be switched off for events with Covid Compliance enabled.';
		} else {
			return `If disabled, ticket holders will not be able to edit their ticket details after purchase.
			This is useful in cases where you may want to ensure that every ticket holder has accepted a waiver
			or agreed to certain terms.`;
		}
	}

	isFormDeactivatable(): boolean | Observable<boolean> {
		return !this.form || this.form.pristine;
	}

	deactivationFallback(): Observable<boolean> {
		this.dialog
			.open(ConfirmationModalComponent, {
				data: {
					title: 'Unsaved Changes',
					text: 'Are you sure you want to leave this page? Any unsaved changes will be discarded.',
					buttonText: 'LEAVE',
					centerText: true,
					isMobile: this.isMobile,
				},
				panelClass: 'g-standard-dialog',
			})
			.afterClosed()
			.subscribe((result) => {
				this.closeDeactivationModal(result);
			});
		return this.modalCallback.asObservable();
	}

	closeDeactivationModal(isConfirmed: boolean): void {
		this.modalCallback.next(isConfirmed);
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
