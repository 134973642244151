<div class="d-flex w-100 pb-3 l-container">
	<div *ngFor='let crumb of displayedCrumbs; let i = index' class='d-flex align-items-center'>
		<div [id]="'breadcrumb-navigate-' + crumb.routeName"
			class="l-breadcrumb"
			[ngClass]="{'l-parent': i != displayedCrumbs.length - 1, 'l-child': i == displayedCrumbs.length - 1}"
			(click)="handleNavigation(crumb, i)"
		>
			{{ crumb.routeName }}
		</div>
		<mat-icon *ngIf='i != displayedCrumbs.length - 1' class="mx-3">chevron_right</mat-icon>
	</div>
</div>
