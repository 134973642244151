import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-my-account-header',
	templateUrl: './my-account-header.component.html',
	styleUrls: ['./my-account-header.component.sass'],
})
export class MyAccountHeaderComponent {
	@Input() isMobile: boolean;
	@Input() pageName: string;
	@Input() isParent = false;

	@Output() navigateBack: EventEmitter<void> = new EventEmitter();

	handleNavigationBack() {
		this.navigateBack.emit();
	}

}
