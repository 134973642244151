import { EventActionsConstants } from '@app/store/actions/event/event.actions.constants';
import * as eventActions from '@app/store/actions/event/event.actions';
import { initialState } from './initialState';
import { EventsState } from '@app/models/store.model';

export const helpArticle = (
	state = initialState.helpArticle,
	action: eventActions.Actions
): EventsState['helpArticle'] => {
	switch (action.type) {
		case EventActionsConstants.GET_USER_HELP:
			return {
				content: null,
				isFetching: true,
			};
		case EventActionsConstants.GET_USER_HELP_SUCCESS:
			return {
				isFetching: false,
				content: action.payload.articleContent,
			};
		case EventActionsConstants.GET_USER_HELP_FAILED:
			return {
				...state,
				isFetching: false,
			};
		default:
			return state;
	}
};
