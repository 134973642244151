import { Component, EventEmitter } from '@angular/core';
import { StoreService } from '@app/services/store/store.service';
import { Actions, ofType } from '@ngrx/effects';
import { take, takeUntil, combineLatest, map } from 'rxjs';
import { OrganiserActionsConstants } from '@app/store/actions/organiser/organiser.actions.constants';
import * as organiserSelectors from '@app/store/selectors/organiser.selector';
import { Dictionary } from '@ngrx/entity';
import { SectionRouteCard } from '@app/models/shared';
import { URLCreator } from '@app/services/url/url.dictionary';
import { handleOrder } from '@app/utils/common-helpers';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { ActivatedRoute } from '@angular/router';
import { GetMailChimpConnection, PostUnlinkMailChimp } from '@app/store/actions/organiser/organiser.actions';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '@app/shared/modals/confirmation-modal/confirmation-modal.component';
import { setCookie } from '@app/utils/Cookie';

const ORGANISER_ID_PARAM_KEY = 'id';
const MAILCHIMP_CODE_PARAM_KEY = 'code';
const ORGANISER_ID_MAILCHIMP = 'ORGANISER_ID_MAILCHIMP';

enum MailChimpKey {
	Link = 'link',
	New = 'new',
}

@Component({
	selector: 'app-mailchimp-integration',
	templateUrl: './mailchimp-integration.component.html',
	styleUrls: ['./mailchimp-integration.component.sass'],
})
export class MailchimpIntegrationComponent {
	isMobile = false;
	organiserId: number;
	isLoading = true;
	mailChimpCode: string;
	isConnected = false;
	linkAccountRoute: string;

	mailChimpCards: Dictionary<SectionRouteCard> = {
		[MailChimpKey.Link]: {
			title: 'Connect To Existing Mailchimp',
			iconPath: './assets/icons/mailchimp.png',
			routeTo: (id: number) => URLCreator.mailChimp(id),
		},
		[MailChimpKey.New]: {
			title: 'Create Mailchimp Account',
			iconPath: './assets/icons/plus.svg',
			routeTo: () => URLCreator.createMailChimpAccount(),
		},
	};

	helpCard: SectionRouteCard = {
		title: 'Quicket Help Center',
		iconPath: './assets/icons/question.svg',
		routeTo: () => URLCreator.mailChimpHelp(),
	};

	unlinkCard: SectionRouteCard = {
		title: 'Unlink MailChimp Account',
		iconPath: './assets/icons/mailchimp.png',
	};

	handleDefaultOrder = handleOrder;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private breakpointService: BreakpointService,
		private activatedRoute: ActivatedRoute,
		private store: StoreService,
		private actions$: Actions,
		private dialog: MatDialog
	) {}

	ngOnInit(): void {
		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});

		const organiserId$ = this.activatedRoute.parent.params.pipe(
			take(1),
			map((params) => params[ORGANISER_ID_PARAM_KEY]),
			map((organiserId) => parseInt(organiserId, 10))
		);

		const mailChimpCode$ = this.activatedRoute.parent.queryParams.pipe(
			takeUntil(this.destroyed$),
			map((queryParams) => queryParams[MAILCHIMP_CODE_PARAM_KEY])
		);

		combineLatest([organiserId$, mailChimpCode$])
			.pipe(takeUntil(this.destroyed$))
			.subscribe(([organiserId, mailChimpCode]) => {
				this.organiserId = organiserId;
				this.mailChimpCode = mailChimpCode;
				setCookie(ORGANISER_ID_MAILCHIMP, `${this.organiserId}`, 0.042);

				this.store.dispatch(new GetMailChimpConnection({ id: this.organiserId, code: this.mailChimpCode || '' }));
			});

		this.actions$
			.pipe(
				ofType(
					OrganiserActionsConstants.GET_MAILCHIMP_CONNECTION_SUCCESS,
					OrganiserActionsConstants.GET_MAILCHIMP_CONNECTION_FAILED,
					OrganiserActionsConstants.POST_UNLINK_MAILCHIMP_SUCCESS,
					OrganiserActionsConstants.POST_UNLINK_MAILCHIMP_FAILED
				),
				takeUntil(this.destroyed$)
			)
			.subscribe(() => {
				this.isLoading = false;
			});

		this.store
			.select(organiserSelectors.getMailChimpConnection())
			.pipe(takeUntil(this.destroyed$))
			.subscribe(({ connected, connectLink }) => {
				this.isConnected = connected;
				this.linkAccountRoute = connectLink;
			});
	}

	handleMailNavigate(mailChimpType: MailChimpKey) {
		const mailChimpCard = this.mailChimpCards[mailChimpType];
		const route = mailChimpType === MailChimpKey.Link ? this.linkAccountRoute : mailChimpCard.routeTo();

		if (mailChimpType === MailChimpKey.Link && this.linkAccountRoute) {
			window.location.href = route;
		} else if (mailChimpType === MailChimpKey.New) {
			window.open(route);
		}
	}

	handleUnlinkMailChimp() {
		const confirmationDialogRef = this.dialog.open(ConfirmationModalComponent, {
			data: {
				title: 'Are You Sure?',
				text: 'Are you sure you want to unlink you MailChimp Account?',
				buttonText: 'PROCEED',
				isMobile: this.isMobile,
			},
			panelClass: 'g-standard-dialog',
		});

		confirmationDialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.isLoading = true;
				this.store.dispatch(new PostUnlinkMailChimp({ id: this.organiserId }));
			}
		});
	}

	isCardDisabled(cardKey: MailChimpKey): boolean {
		return cardKey === MailChimpKey.Link && !this.linkAccountRoute;
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
