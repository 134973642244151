import { FailedAction, AppAction } from '@app/models/action.model';
import { TicketsActionsConstant as constants } from './tickets.constants';
import { SchemeID } from '@app/models/dataSchema.model';
import { SeatingCategory, Currency, EventTicketsSettings } from '@app/models/ticket.model';
import { NormalizedEventEntityPayload } from '@app/models/event.model';
import { EventEntityState } from '@app/models/store.model';
import { ProductType } from '@app/models/product.model';

export class GetSeatingCategories extends AppAction<{
	eventId: SchemeID;
	chartId: string;
}> {
	readonly type = constants.GET_SEATING_CATEGORIES;
}

export class GetSeatingCategoriesSuccess extends AppAction<{
	eventId: SchemeID;
	chartId: string;
	entity: {
		seatingCategories: SeatingCategory[];
	};
}> {
	readonly type = constants.GET_SEATING_CATEGORIES_SUCCESS;
}

export class GetSeatingCategoriesFailed extends FailedAction {
	readonly type = constants.GET_SEATING_CATEGORIES_FAILED;
}

export class GetCurrencies extends AppAction <{
	productType: ProductType;
}> {
	readonly type = constants.GET_CURRENCIES;
}

export class GetCurrenciesSuccess extends AppAction <{
	currencies: Currency[];
	productType: ProductType;
}> {
	readonly type = constants.GET_CURRENCIES_SUCCESS;
}

export class GetCurrenciesFailed extends FailedAction {
	readonly type = constants.GET_CURRENCIES_FAILED;
}

export class GetEventTickets extends AppAction<{
	id: number | string;
}> {
	readonly type = constants.GET_EVENT_TICKETS;
}

export class GetEventTicketsSuccess extends AppAction<{
	id: SchemeID;
	normalizedEntity: NormalizedEventEntityPayload;
	entity: Partial<EventEntityState>;
}> {
	readonly type = constants.GET_EVENT_TICKETS_SUCCESS;
}

export class GetEventTicketsFailed extends FailedAction {
	readonly type = constants.GET_EVENT_TICKETS_FAILED;
}

export class StopTicketSales extends AppAction<{
	eventId: SchemeID;
}> {
	readonly type = constants.STOP_TICKET_SALES;
}

export class StopTicketSalesSuccess extends AppAction<{
	eventId: SchemeID;
	normalizedEntity: NormalizedEventEntityPayload;
}> {
	readonly type = constants.STOP_TICKET_SALES_SUCCESS;
}

export class StopTicketSalesFailed extends FailedAction {
	readonly type = constants.STOP_TICKET_SALES_FAILED;
}

export class MarkAsSoldOut extends AppAction<{
	eventId: SchemeID;
}> {
	readonly type = constants.MARK_TICKETS_AS_SOLD_OUT;
}

export class MarkAsSoldOutSuccess extends AppAction<{
	eventId: SchemeID;
	normalizedEntity: NormalizedEventEntityPayload;
}> {
	readonly type = constants.MARK_TICKETS_AS_SOLD_OUT_SUCCESS;
}

export class MarkAsSoldOutFailed extends FailedAction {
	readonly type = constants.MARK_TICKETS_AS_SOLD_OUT_FAILED;
}


export class GetTicketSettings extends AppAction<{
	id: SchemeID;
}> {
	readonly type = constants.GET_TICKET_SETTINGS;
}

export class GetTicketSettingsSuccess extends AppAction<{
	settings: EventTicketsSettings;
	id: SchemeID;
}> {
	readonly type = constants.GET_TICKET_SETTINGS_SUCCESS;
}

export class GetTicketSettingsFailed extends FailedAction {
	readonly type = constants.GET_TICKET_SETTINGS_FAILED;
}

export class UpdateTicketSettings extends AppAction<{
	id: SchemeID;
	settings?: EventTicketsSettings;
}> {
	readonly type = constants.UPDATE_TICKET_SETTINGS;
}

export class UpdateTicketSettingsSuccess extends AppAction<{
	id: SchemeID;
	settings?: EventTicketsSettings;
}> {
	readonly type = constants.UPDATE_TICKET_SETTINGS_SUCCESS;
}

export class UpdateTicketSettingsFailed extends FailedAction {
	readonly type = constants.UPDATE_TICKET_SETTINGS_FAILED;
}

export type Actions =
	GetSeatingCategories
	| GetSeatingCategoriesSuccess
	| GetSeatingCategoriesFailed
	| GetCurrenciesSuccess
	| GetCurrenciesFailed
	| GetEventTickets
	| GetEventTicketsSuccess
	| GetEventTicketsFailed
	| StopTicketSales
	| StopTicketSalesFailed
	| StopTicketSalesSuccess
	| MarkAsSoldOut
	| MarkAsSoldOutFailed
	| MarkAsSoldOutSuccess
	| GetTicketSettings
	| GetTicketSettingsSuccess
	| GetTicketSettingsFailed
	| UpdateTicketSettings
	| UpdateTicketSettingsSuccess
	| UpdateTicketSettingsFailed;
