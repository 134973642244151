import { Component, Input } from '@angular/core';
import { SchemeID } from '@app/models/dataSchema.model';
import { RecentOrders } from '@app/models/event-metadata.model';
import { StoreService } from '@app/services/store/store.service';
import { InternalURLCreator } from '@app/services/url/url.dictionary';
import { Go } from '@app/store/actions/router/router.actions';

@Component({
	selector: 'app-recent-orders',
	templateUrl: './recent-orders.component.html',
	styleUrls: ['./recent-orders.component.sass'],
})
export class RecentOrdersComponent {
	@Input() recentOrders: RecentOrders[] = [];
	@Input() eventId: SchemeID;
	@Input() isLoading = true;
	@Input() currencySymbol: string;

	constructor(
		private store: StoreService
	) { }

	handleViewAll() {
		this.store.dispatch(new Go({ path: [InternalURLCreator.orders(this.eventId)] }));
	}
}
