import { Component, Inject, OnInit, Optional } from '@angular/core';
import { SectionRouteCard } from '@app/models/shared';
import { OVERLAY_DATA, OverlayService } from '@app/services/overlay/overlay.service';
import { StoreService } from '@app/services/store/store.service';
import { InternalURLCreator } from '@app/services/url/url.dictionary';
import { Go } from '@app/store/actions/router/router.actions';
import { handleOrder } from '@app/utils/common-helpers';
import { ORGANISER_NAV, QUICKET_LOGO } from '@app/utils/consts';
import { Dictionary } from '@ngrx/entity';

@Component({
	selector: 'app-mobile-nav-menu',
	templateUrl: './mobile-nav-menu.component.html',
	styleUrls: ['./mobile-nav-menu.component.sass'],
})
export class MobileNavMenuComponent implements OnInit {
	navItems: Dictionary<SectionRouteCard> = ORGANISER_NAV;
	currentNavItem: SectionRouteCard;
	QUICKET_LOGO = QUICKET_LOGO;
	handleDefaultOrder = handleOrder;
	constructor(
		private store: StoreService,
		private overlayService: OverlayService,
		@Optional() @Inject(OVERLAY_DATA) public data: {currentNavItem: SectionRouteCard}
	) { }

	ngOnInit(): void {
		this.currentNavItem = this.data.currentNavItem;
	}

	handleNavigation(navItem: SectionRouteCard) {
		this.store.dispatch(new Go({ path: [navItem.routeTo()] }));
		this.overlayService.close();
	}

	handleCustomerNavigation() {
		this.store.dispatch(new Go({ path: [InternalURLCreator.myAccount()] }));
		this.overlayService.close();
	}

}
