import { Component, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SchemeID } from '@app/models/dataSchema.model';
import { HeaderContent } from '@app/models/shared';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { InternalURLCreator, URLCreator } from '@app/services/url/url.dictionary';
import { EVENT_ID_PARAM_KEY, REGISTRATION_ID_KEY } from '@app/utils/consts';
import { combineLatest, take, takeUntil } from 'rxjs';

@Component({
	selector: 'app-user-registration-details',
	templateUrl: './user-registration-details.component.html',
	styleUrls: ['./user-registration-details.component.sass'],
})
export class UserRegistrationDetailsComponent {
	url: string;
	eventId: SchemeID;
	regId: SchemeID;
	isMobile = false;

	headerContent: HeaderContent = {
		breadCrumbs: [
			{
				routeName: 'Manage Checkout',
				routeTo: () => InternalURLCreator.tickets(this.eventId),
			},
			{
				routeName: 'Registrations',
				routeTo: () => InternalURLCreator.registrations(this.eventId),
			},
			{
				routeName: 'Registration Details',
			},
		],
		title: 'Registration Details',
	};

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(private activatedRoute: ActivatedRoute, private breakpointService: BreakpointService) { }

	ngOnInit(): void {

		combineLatest([
			this.activatedRoute.parent.paramMap,
			this.activatedRoute.paramMap,
		]).pipe(
			take(1)
		).subscribe(([parentParams, childParams]) => {
			this.eventId = +parentParams.get(EVENT_ID_PARAM_KEY);
			this.regId = +childParams.get(REGISTRATION_ID_KEY);

			this.url = URLCreator.userRegistrationDetails(this.eventId, this.regId);
		});

		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
