<div class="l-container">
	<div class="d-flex mx-3 mb-2 mt-4" *ngIf="!isMobile">
		<p id="breadcrumb-navigate-back" class="g-pointer g-highlight" (click)="handleNavigateBack()">Organiser Profiles
		</p>
		<mat-icon class="mx-3">chevron_right</mat-icon>
		<p class="fw-semibold">{{ isDetailsTab ? 'Details' : 'Mailchimp' }}</p>
	</div>
	<app-tab-nav-bar [ngClass]="{'l-web-tabs': !isMobile, 'l-mobile-tabs': isMobile}" [pages]='pages'></app-tab-nav-bar>
	<div>
		<router-outlet></router-outlet>
	</div>
</div>
