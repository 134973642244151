<div [ngClass]="{ 'input-group-mobile-large': isMobile, 'input-group-web-large': !isMobile }">
	<label class="g-form-label">
		<span [innerHTML]="label"></span>
		<span *ngIf='isRequired' class="g-orange"> *</span>
	</label>
	<input id="text-input-field" type="text" [formControl]="inputFormControl" class="form-control" />
	<div *ngIf="inputFormControl.invalid">
		<div class="g-error-input">
			<div *ngFor="let errorKey of errorKeys">
				<div *ngIf="inputFormControl.hasError(errorKey)">{{ errorMessages[errorKey] }}</div>
			</div>
			<div *ngIf="inputFormControl.hasError('regexPattern')">{{ inputFormControl.getError('regexPattern') }}</div>
		</div>
	</div>
</div>
