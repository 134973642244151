import { NgModule, Component, Input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { CommonModule } from '@angular/common';
/* import { TourNgxPopperModule } from 'ngx-tour-ngx-popper'; */
import { AppRouterModule } from '@app/router/router.module';
import { MenuItemWithFlag } from '@app/features/control-panel/shared/control-panel-sidebar/control-panel-sidebar.component';

export class BasePanelMenuItem {
	handleClick(event, item) {
		if (item.disabled) {
			event.preventDefault();
			return;
		}
		item.expanded = !item.expanded;
		if (!item.url) {
			event.preventDefault();
		}
		if (item.command) {
			item.command({
				originalEvent: event,
				item,
			});
		}
	}
}

@Component({
	selector: 'p-panelMenuSub',
	templateUrl: './panel-menu-sub.component.html',
	animations: [
		trigger('submenu', [
			state('hidden', style({
				height: '0px',
			})),
			state('visible', style({
				height: '*',
			})),
			transition('visible => hidden', animate('{{transitionParams}}')),
			transition('hidden => visible', animate('{{transitionParams}}')),
		]),
	],
})
export class PanelMenuSubComponent extends BasePanelMenuItem {

	@Input() item: MenuItemWithFlag;

	@Input() expanded: boolean;

	@Input() transitionOptions: string;
}

@Component({
	selector: 'p-panelMenu',
	templateUrl: './panel-menu.component.html',
	animations: [
		trigger('rootItem', [
			state('hidden', style({
				height: '0px',
			})),
			state('visible', style({
				height: '*',
			})),
			transition('visible => hidden', animate('{{transitionParams}}')),
			transition('hidden => visible', animate('{{transitionParams}}')),
		]),
	],
})
export class PanelMenuComponent extends BasePanelMenuItem {

	@Input() model: MenuItemWithFlag[];

	@Input() style: any;

	@Input() styleClass: string;

	@Input() multiple = true;

	@Input() transitionOptions = '400ms cubic-bezier(0.86, 0, 0.07, 1)';

	public animating: boolean;

	collapseAll() {
		for (const item of this.model) {
			if (item.expanded) {
				item.expanded = false;
			}
		}
	}

	handleClick(event, item) {
		if (!this.multiple) {
			for (const modelItem of this.model) {
				if (item !== modelItem && modelItem.expanded) {
					modelItem.expanded = false;
				}
			}
		}

		this.animating = true;
		super.handleClick(event, item);
	}

	onToggleDone() {
		this.animating = false;
	}

}

@NgModule({
	imports: [
		CommonModule ,
		AppRouterModule,
		/* TourNgxPopperModule, */
	],
	exports: [
		PanelMenuComponent,
	],
	declarations: [
		PanelMenuComponent,
		PanelMenuSubComponent,
	],
})
export class PanelMenuModule { }
