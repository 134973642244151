import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { EventControlPanelComponent } from './event-control-panel.component';
import { EventDashboardModule } from './dashboard/event-dashboard.module';
import { EventDetailsModule } from './details/event-details.module';
import { EventProfileModule } from './profile/event-profile.module';
import { EventTicketsModule } from './tickets/event-tickets.module';
import { ControlPanelSharedModule } from './shared/control-panel-shared.module';
import { EventInvitationsModule } from './invitations/event-invitations.module';
import { FacebookIntegrationModule } from './facebook/facebook-integration.module';
import { EventStreamingModule } from './streaming/event-streaming.module';
import { EventCollectionsModule } from './collections/event-collections.module';
import { EventWidgetModule } from './widget/widget.module';
import { EventAtTheDoorModule } from './at-the-door/at-the-door.module';

@NgModule({
	declarations: [
		EventControlPanelComponent,
	],
	imports: [
		SharedModule,
		EventDashboardModule,
		EventDetailsModule,
		EventProfileModule,
		EventTicketsModule,
		ControlPanelSharedModule,
		EventInvitationsModule,
		FacebookIntegrationModule,
		EventStreamingModule,
		EventCollectionsModule,
		EventWidgetModule,
		EventAtTheDoorModule,
	],
})
export class EventControlPanelModule {}
