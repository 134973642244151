<div [ngClass]="getFormFieldClasses(offset, 'select-field-wrap')">
	<label
		class="ui-label"
		*ngIf="label"
	>
			{{label}}
		<span *ngIf="required" class="ui-required">*</span>
		<i
			*ngIf="labelIcon"
			[class]="'fa fa-' + labelIcon"
			[pTooltip]="tooltip"
			[tooltipPosition]="tooltip ? tooltipPosition : null"
		></i>
	</label>
	<p-dropdown
		*ngIf="!multiple"
		[options]="options"
		[ngClass]="{'ui-dropdown-value': control.value !== null && control.value !== undefined}"
		[formControl]="control"
		[style]="{'width':'150px'}"
		[placeholder]="placeholder"
		class="form-field"
		[showClear]="allowReset"
		[scrollHeight]="getScrollHeight()"
		(onChange)="onChange($event)"
		[disabled]=disabled
		#dropdown
	></p-dropdown>
	<p-multiSelect
		[maxSelectedLabels]="options.length"
		[defaultLabel]="placeholder"
		*ngIf="multiple"
		[options]="options"
		[ngClass]="{'ui-dropdown-value': !!control.value}"
		[formControl]="control"
		[style]="{'width':'150px'}"
		[scrollHeight]="getScrollHeight()"
		class="form-field"
		[showHeader]="true"
		[showToggleAll]="true"
		(onBlur)="blur.emit($event)"
		#multiSelect
	></p-multiSelect>
	<small
		class="ui-error-msg"
		*ngIf="control.invalid && (control.dirty || control.touched)"
	>{{getError(control.errors)}}</small>
</div>