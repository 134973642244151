import { AppState } from '@app/models/store.model';

export const getSalesOverview = () =>
	(state: AppState) =>
		state.organiser && state.organiser.dashboard.salesOverview
			? state.organiser.dashboard.salesOverview
			: null;

export const getDashboardEvents = () =>
	(state: AppState) =>
		state.organiser && state.organiser.dashboard.dashboardEvents
			? state.organiser.dashboard.dashboardEvents
			: null;

export const getCurrentCampaigns = () =>
	(state: AppState) =>
		state.organiser && state.organiser.dashboard.topLinkCampaigns
			? state.organiser.dashboard.topLinkCampaigns
			: null;

export const getFilteredProducts = () =>
	(state: AppState) =>
		state.organiser && state.organiser.events.filteredProducts
			? state.organiser.events.filteredProducts
			: null;

export const getOrganiserNames = () =>
	(state: AppState) =>
		state.organiser && state.organiser.events.organiserNames
			? state.organiser.events.organiserNames
			: null;

export const getMailAccounts = () =>
	(state: AppState) =>
		state.organiser && state.organiser.mailAccounts
			? state.organiser.mailAccounts
			: null;

export const getMailChimpConnection = () =>
	(state: AppState) =>
		state.organiser && state.organiser.mailChimpConnection
			? state.organiser.mailChimpConnection
			: null;
