import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserReportInfo, UserReportOptions, UserReportType, UserReportTypes } from '@app/models/organiser.model';
import { StoreService } from '@app/services/store/store.service';
import { DownloadUserReports, GetUserReportsInfo } from '@app/store/actions/organiser/organiser.actions';
import { OrganiserActionsConstants } from '@app/store/actions/organiser/organiser.actions.constants';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs';

@Component({
	selector: 'app-generate-reports-modal',
	templateUrl: './generate-reports-modal.component.html',
	styleUrls: ['./generate-reports-modal.component.sass'],
})
export class GenerateReportsModalComponent implements OnInit {
	isLoading = true;
	userReportInfo: UserReportInfo;
	selectedReportType: UserReportType;
	selectedEvents: number[] = [];
	selectedOrganiser: number;
	dateFrom: Date = new Date();
	dateTo: Date = new Date();
	hasEvents = true;
	filteredEvents = [];
	selectAllSelected = false;
	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	formFieldOptions: { [key in UserReportTypes]: UserReportOptions[] } = {
		[UserReportTypes.FinancialReport]: [UserReportOptions.Products, UserReportOptions.Dates],
		[UserReportTypes.TicketBreakdown]: [UserReportOptions.Products],
		[UserReportTypes.OrganisersReport]: [UserReportOptions.Organisers, UserReportOptions.Dates],
		[UserReportTypes.OrganisersPOSReport]: [UserReportOptions.Organisers, UserReportOptions.Dates],
		[UserReportTypes.OrganiserCashupReport]: [UserReportOptions.Organisers, UserReportOptions.Dates],
	};

	constructor(
		public dialogRef: MatDialogRef<GenerateReportsModalComponent>,
		private actions$: Actions,
		private store: StoreService,
		@Inject(MAT_DIALOG_DATA) public data: {
			isMobile: boolean;
			userId: number;
		}
	) { }

	ngOnInit(): void {
		this.actions$
			.pipe(
				ofType(OrganiserActionsConstants.GET_USER_REPORTS_INFO_SUCCESS, OrganiserActionsConstants.GET_USER_REPORTS_INFO_FAILED),
				takeUntil(this.destroyed$)
			)
			.subscribe(({ type, payload: { userReportInfo } }) => {
				if (type === OrganiserActionsConstants.GET_USER_REPORTS_INFO_FAILED) {
					this.dialogRef.close();
				}
				if (type === OrganiserActionsConstants.GET_USER_REPORTS_INFO_SUCCESS) {
					this.userReportInfo = userReportInfo;
					this.filteredEvents = this.userReportInfo.products.filter(event => event.name !== null);
					if (this.filteredEvents.length === 0) {
						this.hasEvents = false;
					}
				}
				this.isLoading = false;
			});

		this.actions$
			.pipe(
				ofType(OrganiserActionsConstants.DOWNLOAD_USER_REPORTS_SUCCESS),
				takeUntil(this.destroyed$)
			).subscribe(() => {
				this.dialogRef.close();
			});

		this.actions$
			.pipe(
				ofType(OrganiserActionsConstants.DOWNLOAD_USER_REPORTS_FAILED),
				takeUntil(this.destroyed$)
			).subscribe(() => {
				this.isLoading = false;
			});

		this.store.dispatch(new GetUserReportsInfo({ id: this.data.userId }));
	}

	handleEventsFilter(input: string) {
		if (!this.userReportInfo) {
			return;
		}
		if (input) {
			const search = input?.toLowerCase();
			this.filteredEvents = this.userReportInfo.products.filter(event =>
				event.name && event.name.toLowerCase().includes(search)
			);
		} else {
			this.filteredEvents = this.userReportInfo.products.filter(event => event.name !== null);
		};
	}

	handleCancel() {
		this.dialogRef.close();
	}

	handleDownload() {
		this.isLoading = true;

		this.store.dispatch(new DownloadUserReports({
			id: this.data.userId, downloadUserReportsDto: {
				type: this.selectedReportType.id,
				productIds: this.selectedEvents,
				dateFrom: this.dateFrom,
				dateTo: this.dateTo,
				organiserId: this.selectedOrganiser,
			},
		}));
	}

	displayFormField(selectedReportType: UserReportType, formField: string) {
		const options = this.formFieldOptions[selectedReportType.name];
		return options.includes(formField as UserReportOptions);
	}

	dateFromFilter = (date: Date | null): boolean => {
		if (!date) {
			return false;
		}
		return !this.dateTo || date <= this.dateTo;
	};

	dateToFilter = (date: Date | null): boolean => {
		if (!date) {
			return false;
		}
		return !this.dateFrom || date >= this.dateFrom;
	};

	eventSelectChange() {
		if (this.isAllSelected()) {
			this.selectAllSelected = true;
		} else {
			this.selectAllSelected = false;
		}
	}

	isAllSelected(): boolean {
		return this.selectedEvents.length === this.filteredEvents.length;
	}

	toggleSelectAll(): void {
		if (this.isAllSelected()) {
			this.selectedEvents = [];
			this.selectAllSelected = false;
		} else {
			this.selectedEvents = this.filteredEvents.map(event => event.id);
			this.selectAllSelected = true;
		}
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}

}
