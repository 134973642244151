<div [ngClass]="{ 'pt-3 pb-2': !showBreadCrumb && !showTitle, 'mt-3': showBreadCrumb || showTitle }">
	<h3 class="mb-3 l-header-title" *ngIf="showTitle && !isMobile">{{ headerContent.title}}</h3>
	<app-breadcrumb *ngIf='showBreadCrumb && !isMobile' [crumbs]='headerContent.breadCrumbs' [reference]='reference'
		(navigation)='handleNavigation($event)'></app-breadcrumb>
	<p *ngIf="headerContent.description || customDescription" [class.l-small-width]='smallWidth' [class.l-medium-width]='mediumWidth' [class.l-long-width]='longWidth'
		[class.l-full-width]='fullWidth'>{{ headerContent.description || customDescription }}
		<span *ngIf="additionalText">
			{{additionalText}}
		</span>
		<span *ngIf="includeReadMore" id="web-header-read-more" (click)="handleReadMore()"
			class="text-decoration-underline g-pointer fw-bold g-highlight">
			{{readMoreText}}
		</span>
	</p>
</div>
