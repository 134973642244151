<div
	[ngClass]="{ 'l-modal-container': isModal, 'l-container-mobile': isMobile, 'l-container': !isMobile, 'l-multi-button-mobile mb-3': secondaryText && isMobile, 'l-full-width': fullWidth }">
	<button 
		[id]="secondaryButtonId"
		class="g-secondary-button" 
		[ngClass]="{ 'me-3': !isMobile, 'w-100': isMobile, 'l-enlarge-button': longSecondaryText && !isMobile}"
		*ngIf="secondaryText" (click)="handleSecondaryButtonClick()"
	>
		{{ secondaryText }}
	</button>
	<button 
		[id]="primaryButtonId" 
		class="g-primary-button" (click)="handlePrimaryButtonClick()" [disabled]="isDisabled || isLoading"
		[ngClass]="{ disabled: isDisabled, 'w-100': isMobile, 'l-enlarge-button': longPrimaryText && !isMobile}"
	>
		<div *ngIf="!isLoading else showLoading">{{ primaryText }}</div>
		<ng-template #showLoading><mat-spinner class='g-button-spinner'></mat-spinner></ng-template>
	</button>
</div>