<div
	id="select-refund"
	class="l-container"
	[ngClass]="{'l-mobile-container': isMobile}"
	(click)="handleContainerSelect()"
>
	<img
		[src]="image"
		*ngIf="!isMobile"
		class="l-image"
	/>
	<div class="l-content" [ngClass]="{'w-100': isMobile, 'web': !isMobile}">
		<div class="l-details">
			<div class="l-name">
				<h3
					class="l-title"
					id="ticket-type"
				>{{ data.productDealName }}</h3>
			</div>
			<div class="l-info">
				<strong>Ticket Holder: </strong><span id="ticket-holder" class="l-holder">{{ data.ticketHolder }}</span>
			</div>
			<div class="l-info">
				<strong>Ticket Number: </strong><span id="ticket-number">{{ data.purchaseItemId }}</span>
			</div>
			<div class="l-info">
				<strong>Barcode: </strong><span id="barcode">{{ data.barcode }}</span>
			</div>
			<div class="l-info">
				<strong>Price: </strong><span id="price">{{data.currencySymbol}}{{ data.price }}</span>
			</div>
		</div>
		<mat-checkbox
			id="refund-checkbox"
			*ngIf="data.canRefund"
			[checked]="checkboxState"
		></mat-checkbox>
		<mat-icon
			*ngIf="!data.canRefund"
			class="l-icon"
			#tooltip="matTooltip"
			[matTooltip]="data.ticketStatus"
			matTooltipPosition="left"
			(click)="tooltip.toggle()"
		>info</mat-icon>
	</div>
</div>