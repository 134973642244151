<div class="l-container">
	<div class="l-header">
		<h3 class="l-title" [ngClass]="{ mobile: data.isMobile }">Your event is now live, spread the word!</h3>
	</div>
	<div class="l-content" [ngClass]="{ mobile: data.isMobile }">
		<i class="l-icon fa fa-solid fa-address-book"></i>
		<p [ngClass]="{ 'text-center' : data.isMobile }">
			Send your contacts and past attendees an invitation to book tickets.
		</p>
	</div>
	<div class="l-buttons-container" [ngClass]="{ mobile: data.isMobile }">
		<button
			class="g-secondary-button"
			id="cancel-button"
			(click)="handleClose()"
		>
			CANCEL
		</button>
		<button
			class="g-primary-button"
			id="send-invites-modal-button"
			(click)="handleSendInvites()"
		>
			SEND INVITES
		</button>
	</div>
</div>
