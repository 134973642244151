import { Component, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { HeaderContent } from '@app/models/shared';
import { TicketGroupsPageMode } from '@app/models/ticket.model';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { StoreService } from '@app/services/store/store.service';
import { InternalURLCreator } from '@app/services/url/url.dictionary';
import { EventActionsConstants } from '@app/store/actions/event/event.actions.constants';
import { Go } from '@app/store/actions/router/router.actions';
import { EVENT_ID_PARAM_KEY } from '@app/utils/consts';
import { Actions, ofType } from '@ngrx/effects';
import { Observable, take, takeUntil } from 'rxjs';
import * as eventSelectors from '@app/store/selectors/event.selector';
import { EventTicketGroup, EventTicketGroupTicketType } from '@app/models/event.model';
import { GetEventTicketGroups, ReorderTicketGroup } from '@app/store/actions/event/event.actions';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { TicketGroupsService } from '@app/services/tickets/ticket-groups.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '@app/shared/modals/confirmation-modal/confirmation-modal.component';
import { FormCanDeactivate } from '@app/services/guards/event.guard';


@Component({
	selector: 'app-ticket-groups',
	templateUrl: './ticket-groups.component.html',
	styleUrls: ['./ticket-groups.component.sass'],
})
export class TicketGroupsComponent extends FormCanDeactivate implements OnInit {
	eventId: number;
	isMobile = false;
	form: UntypedFormGroup;
	isLoading = true;
	isSavingChanges = false;
	isEditing = false;
	mode: string = TicketGroupsPageMode.List;
	TicketGroupsPageMode = TicketGroupsPageMode;
	eventTicketGroups: EventTicketGroup[];
	ticketTypes: EventTicketGroupTicketType[];
	noTicketGroups = false;
	browserBackClicked = false;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	headerContent: HeaderContent = {
		breadCrumbs: [
			{
				routeName: 'Manage Checkout',
				routeTo: () => InternalURLCreator.tickets(this.eventId),
			},
			{
				routeName: 'Ticket Groups',
				routeTo: () => InternalURLCreator.ticketGroups(this.eventId),
			},
		],
		title: 'Ticket Groups',
		description: `Organise multiple ticket types into common sections or categories.
			Ticket buyers can expand and collapse these groups to view and select tickets.
			Unassigned ticket types will appear in a default group called OTHER.`,
	};

	constructor(
		private breakpointService: BreakpointService,
		private activatedRoute: ActivatedRoute,
		private store: StoreService,
		private action$: Actions,
		private ticketGroupsService: TicketGroupsService,
		private dialog: MatDialog
	) {
		super();
	 }

	ngOnInit(): void {
		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});

		this.activatedRoute.parent.paramMap.pipe(take(1)).subscribe((params) => {
			this.eventId = +params.get(EVENT_ID_PARAM_KEY);
		});

		this.initializeTicketGroups();

		this.action$
			.pipe(
				ofType(EventActionsConstants.GET_EVENT_TICKET_GROUPS_SUCCESS, EventActionsConstants.GET_EVENT_TICKET_GROUPS_FAILED),
				takeUntil(this.destroyed$)
			)
			.subscribe(() => {
				this.isLoading = false;
			});

		window.addEventListener('popstate', () => {
			this.browserBackClicked = true;
		}, false);
	}

	initializeTicketGroups() {
		this.store.select(eventSelectors.eventTicketGroups()).pipe(takeUntil(this.destroyed$))
			.subscribe(ticketGroups => {
				if (ticketGroups) {
					this.eventTicketGroups = ticketGroups;
					if (this.eventTicketGroups.length > 0) {
						this.noTicketGroups = true;
					}
				}
			});

		this.store.select(eventSelectors.eventTicketGroupTicketTypes()).pipe(takeUntil(this.destroyed$))
			.subscribe(ticketTypes => {
				this.ticketTypes = ticketTypes;
			});

		this.store.dispatch(new GetEventTicketGroups({ eventId: this.eventId }));
	}

	handleBreadCrumbNavigation(routeTo: CallableFunction) {
		if (
			routeTo(this.eventId) === InternalURLCreator.ticketGroups(this.eventId)
			&& this.mode !== TicketGroupsPageMode.List
		) {
			this.handleRoutingBackToListPage();
		} else {
			this.store.dispatch(new Go({ path: [routeTo(this.eventId)] }));
		}
	}

	handleRoutingBackToListPage() {
		if (this.form && !this.form.pristine) {
			this.handleUnsavedChanges().subscribe((navigateBack) => {
				if (navigateBack) {
					this.performRoutingBack();
					this.form.markAsPristine();
				}
			});
		} else {
			this.performRoutingBack();
		}
	}

	performRoutingBack() {
		this.headerContent.breadCrumbs = this.headerContent.breadCrumbs.slice(0, -1);
		this.headerContent.title = 'Ticket Groups';
		this.mode = TicketGroupsPageMode.List;
		this.setHeaderDescription();
	}

	setHeaderDescription() {
		let description = '';

		switch (this.mode) {
			case TicketGroupsPageMode.List:
				description = `Ticket groups are a way to organize multiple ticket types under common sections or categories.
							   Ticket buyers can expand and collapse the groups on your event page to view and select tickets in each group.
							   Any ticket types not added to a group will be displayed in a group called OTHER.`;
				break;
			case TicketGroupsPageMode.Create:
				description = 'Create a ticket group for your event below';
				break;
			case TicketGroupsPageMode.Edit:
				description = 'Edit your ticket group below';
				break;
		}

		this.headerContent.description = description;
	}

	onTicketGroupFormRequest = (ticketGroup?: EventTicketGroup) => {

		const availableTickets = this.ticketGroupsService.getAvailableTicketTypes(this.eventTicketGroups, this.ticketTypes);

		const linkedTicketTypes = ticketGroup
			? ticketGroup.linkedTicketTypes.map(id => this.ticketTypes.find(ticket => ticket.id === id))
			: [];

		this.form = this.ticketGroupsService.createTicketGroupForm([...linkedTicketTypes, ...availableTickets], ticketGroup);
	};

	handleAddEditTicketGroup(ticketGroup: EventTicketGroup) {
		if (ticketGroup) {
			this.onTicketGroupFormRequest(ticketGroup);
			this.isEditing = true;
			this.headerContent.breadCrumbs = [
				...this.headerContent.breadCrumbs,
				{
					routeName: 'Edit Ticket Group',
				},
			];
			this.mode = TicketGroupsPageMode.Edit;
			this.setHeaderDescription();
		} else {
			this.onTicketGroupFormRequest();
			this.headerContent.breadCrumbs = [
				...this.headerContent.breadCrumbs,
				{
					routeName: 'Add Ticket Group',
				},
			];
			this.mode = TicketGroupsPageMode.Create;
			this.setHeaderDescription();
		}
	}

	onReorder(event: CdkDragDrop<string[]>) {
		const prevOrder = this.eventTicketGroups.map(tg => tg.id);
		moveItemInArray(this.eventTicketGroups, event.previousIndex, event.currentIndex);
		const newOrder = this.eventTicketGroups.map(tg => tg.id);

		this.store.dispatch(
			new ReorderTicketGroup({
				eventId: this.eventId,
				prevOrder: prevOrder,
				order: newOrder,
			})
		);
	}

	handleDeleteTicketGroup() {
		this.isLoading = true;
		this.store.dispatch(new GetEventTicketGroups({ eventId: this.eventId }));
	}

	handleAddEditComplete() {
		this.isEditing = false;
		this.handleRoutingBackToListPage();
	}

	handleUnsavedChanges(): Observable<boolean> {
		const dialogRef = this.dialog.open(ConfirmationModalComponent, {
			data: {
				title: 'Unsaved Changes',
				text: 'Are you sure you want to leave this page? Any unsaved changes will be discarded.',
				buttonText: 'LEAVE',
				centerText: true,
				isMobile: this.isMobile,
			},
			panelClass: 'g-standard-dialog',
		});

		return dialogRef.afterClosed();
	}

	isFormDeactivatable(): boolean | Observable<boolean> {
		return (this.isMobile || !this.form || this.form.pristine) && this.mode === TicketGroupsPageMode.List;
	}

	deactivationFallback(): Observable<boolean> {
		this.handleUnsavedChanges()
			.subscribe((result) => {
				this.closeDeactivationModal(result);
				if (result) {
					if (this.form) {
						this.form.markAsPristine();
					}

					if (this.browserBackClicked) {
						this.browserBackClicked = false;
						this.handleRoutingBackToListPage();
						this.store.dispatch(new Go({ path: [InternalURLCreator.ticketGroups(this.eventId)] }));
						this.store.dispatch(new GetEventTicketGroups({ eventId: this.eventId }));
					}
				}
			});
		return this.modalCallback.asObservable();
	}

	closeDeactivationModal(isConfirmed: boolean): void {
		this.modalCallback.next(isConfirmed);
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
