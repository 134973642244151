<div [ngClass]="{ 'input-group-mobile-large': isMobile, 'input-group-web-large': !isMobile }">
    <label class="g-form-label">
		<span [innerHTML]="label"></span>
		<span *ngIf='isRequired' class="g-orange"> *</span>
	</label>
    <mat-select id="dropdown-select" [formControl]="inputFormControl" class="form-control w-100">
		<mat-option *ngFor="let option of options" [value]="option">
			{{ option }}
		</mat-option>
	</mat-select>
	<div *ngIf="inputFormControl.invalid">
		<div class="g-error-input">
			<div *ngIf="inputFormControl.hasError('required')">A selection is required.</div>
			<div *ngIf="inputFormControl.hasError('regexPattern')">{{ inputFormControl.getError('regexPattern') }}</div>
		</div>
	</div>
</div>
