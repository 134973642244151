import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { SchemeID } from '@app/models/dataSchema.model';
import { EventSetupProgress, EventSetupProgressDisplayItem, ProductStatus } from '@app/models/event.model';
import { URLCreator } from '@app/services/url/url.dictionary';
import { SubmitEventModalComponent } from '../../modals/submit-event-modal/submit-event-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
	selector: 'app-event-completion-progress',
	templateUrl: './event-completion-progress.component.html',
	styleUrls: ['./event-completion-progress.component.sass'],
})
export class EventCompletionProgressComponent {
	@Input() setupProgress: EventSetupProgress;
	@Input() isLoading = true;
	@Input() isMobile = false;
	@Input() eventId: SchemeID;
	@Input() productStatus: number;
	@Input() commission: number;
	@Input() serviceFeeForHost: boolean;
	@Input() commissionForHost: boolean;
	@Input() autoApproveEvents: boolean;

	@Output() skipItem = new EventEmitter<number>();
	@Output() submitEventForApproval = new EventEmitter();

	progressItems: EventSetupProgressDisplayItem[];

	constructor(
		private dialog: MatDialog
	) {}

	ngOnInit() {
		this.progressItems = this.getProgressItems();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.setupProgress) {
			this.progressItems = this.getProgressItems();
		}
	}

	getProgressItems(): EventSetupProgressDisplayItem[] {
		return [
			{
				id: this.setupProgress.bannerImage.id,
				text: 'Add banner image to event page',
				buttonText: 'Add banner image',
				link: URLCreator.design(this.eventId),
				isCompleted: this.setupProgress.bannerImage.isCompleted,
			},
			{
				id: this.setupProgress.bankAccount.id,
				text: 'Add a bank account for payout',
				buttonText: 'Add bank account',
				link: URLCreator.eventPayoutDetails(this.eventId),
				isCompleted: this.setupProgress.bankAccount.isCompleted,
			},
			{
				id: this.setupProgress.categories.id,
				text: 'Select a category for your event',
				buttonText: 'Select category',
				link: URLCreator.eventDetails(this.eventId),
				isCompleted: this.setupProgress.categories.isCompleted,
			},
			{
				id: this.setupProgress.collectInfo.id,
				text: 'Collect extra custom info from your guest',
				buttonText: 'Edit Checkout Questions',
				link: URLCreator.checkoutQuestions(this.eventId),
				isCompleted: this.setupProgress.collectInfo.isCompleted,
			},
		];
	}

	getSubmitButtonText() {
		if (this.autoApproveEvents) {
			return 'GO LIVE';
		} else if (this.productStatus === ProductStatus.Draft) {
			return 'SUBMIT FOR APPROVAL';
		} else if (this.productStatus === ProductStatus.AwaitingActivation) {
			return 'SUBMITTED FOR APPROVAL';
		} else if (this.productStatus === ProductStatus.Active) {
			return 'APPROVED';
		}
	}

	isInDraft() {
		return this.productStatus === ProductStatus.Draft;
	}

	getPercentCompleted(): number {
		return (this.getCompletedCount() / this.getItemCount()) * 100;
	}

	getProgressText(): string  {
		return `${this.getCompletedCount()}/${this.getItemCount()}`;
	}

	getItemCount(): number {
		return Object.keys(this.setupProgress).length;
	}

	getCompletedCount(): number {
		let completedCount = 0;

		Object.values(this.setupProgress).forEach(item => {
			if (item.isCompleted) {
				completedCount++;
			}
		});

		return completedCount;
	}

	skipProgressItem($event: number) {
		this.skipItem.emit($event);
	}

	submitForApproval() {
		const dialogRef = this.dialog.open(SubmitEventModalComponent, {
			data: {
				isMobile: this.isMobile,
				commission: this.commission,
				serviceFeeForHost: this.serviceFeeForHost,
				commissionForHost: this.commissionForHost,
			},
			panelClass: this.isMobile ? 'g-full-page-dialog' : 'g-standard-dialog',
		});

		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.submitEventForApproval.emit();
			}
		});
	}

}
