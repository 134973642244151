<div *ngIf="singleEvent; else multiSchedule">
	<div class="l-container">
		<div class="l-datetime-container">
			<label class="g-form-label">
				Event start date and time
				<span
					class="g-orange"
					*ngIf="required"
				>*</span>
			</label>
			<input
				[ngxMatDatetimePicker]="dateFromPicker"
				[disabled]="isEventFinished"
				[matDatepickerFilter]="maxFilter"
				placeholder="Date From"
				(click)="dateFromPicker.open()"
				class="form-control"
				[min]="dateFromMinDate"
				id="schedule-content-date-from"
				[formControl]="startDate"
				(dateChange)="handleChange()"
				[ngClass]="{'form-control-disabled' : isEventFinished}"
			>
			<ngx-mat-datetime-picker
				#dateFromPicker
				[disabled]="isEventFinished"
				[matDatepickerPosition]="'below'"
				[showSeconds]="false"
				[touchUi]="isMobile"
				[enableMeridian]="false"
				[disableMinute]="false"
				[hideTime]="false"
				[showSpinners]="false"
			>
				<ngx-mat-datepicker-actions>
					<button
						id="close-datepicker"
						class="g-secondary-button g-datepicker-button"
						ngxMatDatepickerCancel
					>CANCEL</button>
					<button
						id="apply-datepicker"
						class="g-primary-button g-datepicker-button"
						ngxMatDatepickerApply
					>APPLY</button>
				</ngx-mat-datepicker-actions>
			</ngx-mat-datetime-picker>
			<small
				class="g-error-input"
				*ngIf="isControlInvalid(startDate) && !isEventFinished"
			>
				*{{getError(startDate.errors)}}
			</small>
		</div>
		<div class="l-datetime-container">
			<label class="g-form-label">
				Event end date and time
				<span
					class="g-orange"
					*ngIf="required"
				>*</span>
			</label>
			<input
				[ngxMatDatetimePicker]="dateToPicker"
				[disabled]="isEventFinished"
				placeholder="Date To"
				(click)="dateToPicker.open()"
				class="form-control"
				[min]="dateToMinDate"
				id="schedule-content-date-to"
				[formControl]="endDate"
				(dateChange)="handleChange()"
				[ngClass]="{'form-control-disabled' : isEventFinished}"
			>
			<ngx-mat-datetime-picker
				#dateToPicker
				[disabled]="isEventFinished"
				[matDatepickerPosition]="'below'"
				[showSeconds]="false"
				[touchUi]="isMobile"
				[enableMeridian]="false"
				[disableMinute]="false"
				[hideTime]="false"
				[showSpinners]="false"
			>
				<ngx-mat-datepicker-actions>
					<button
						id="close-datepicker"
						class="g-secondary-button g-datepicker-button"
						ngxMatDatepickerCancel
					>CANCEL</button>
					<button
						id="apply-datepicker"
						class="g-primary-button g-datepicker-button"
						ngxMatDatepickerApply
					>APPLY</button>
				</ngx-mat-datepicker-actions>
			</ngx-mat-datetime-picker>
			<small
				class="g-error-input"
				*ngIf="isControlInvalid(endDate) && !isEventFinished"
			>
				*{{getError(endDate.errors)}}
			</small>
		</div>
	</div>
	<div
		*ngIf="shortEventWarning"
		class="g-orange l-warning"
	>
		<mat-icon class="l-warning-icon">warning</mat-icon>
		<span class="l-warning-text">Be aware that event duration is less than 30 minutes.</span>
	</div>
</div>
<ng-template #multiSchedule>
	<div class="l-multi-container">
		<div *ngIf='!isEventFinished' class="l-add-button-container">
			<button
				id="add-dates"
				class=" g-secondary-button"
				(click)='handleAddDates()'
			>ADD DATES</button>
		</div>
		<div class="pt-3">
			<div
				*ngFor="let schedule of schedules.controls; let i = index"
				class="mb-3"
			>
				<div *ngIf="!schedule.get('deleted').value && getScheduleDateCount(i) >= 1">
					<mat-accordion>
						<mat-expansion-panel [expanded]="expandPanels">
							<mat-expansion-panel-header>
								<div
									[ngClass]="{'l-expansion-mobile g-f-12': isMobile, 'g-subtitle': !isMobile}"
									class="l-expansion-header"
								>
									{{getScheduleDateCount(i)}} {{getScheduleDateCount(i) > 1 ? 'Dates' : 'Date'}} <span
										class="l-earliest-latest"
									>
										<span>
											Earliest : {{getEarliestAndLatestDates(schedule).earliest}}
										</span>
										<span>
											Latest&nbsp;&nbsp; : {{getEarliestAndLatestDates(schedule).latest}}
										</span>
									</span>
								</div>
								<div
									[ngClass]="{'l-icons': !isMobile, 'pt-2': isMobile}"
									class="d-flex gap-3 ps-3"
								>
									<div *ngIf="schedule.get('hidden').value; else scheduleNotHidden">
										<i
											id="unhide-schedule-{{i}}"
											class="fa fa-solid fa-eye-slash l-icon"
											(click)="handleScheduleHiding($event, schedule)"
										></i>
									</div>
									<ng-template #scheduleNotHidden>
										<i
											id="hide-schedule-{{i}}"
											class="fa fa-solid fa-eye l-icon"
											(click)="handleScheduleHiding($event, schedule)"
										></i>
									</ng-template>
									<div
										*ngIf="!getFromFormArray(schedule, 'hasInUseTickets').value && !isEventFinished; else cantDeleteSchedule">
										<i
											id="delete-schedule-{{i}}"
											class="fa fa-solid fa-trash l-icon"
											(click)="handleDeletingSchedule($event, schedule)"
										></i>
									</div>
									<ng-template #cantDeleteSchedule>
										<mat-icon
											class="l-tooltip-icon"
											#cantDeleteTooltip="matTooltip"
											[matTooltip]="cantDeleteScheduleTooltip"
											matTooltipPosition="above"
											(click)="cantDeleteTooltip.toggle()"
										>
											info
										</mat-icon>
									</ng-template>
								</div>
							</mat-expansion-panel-header>
							<div
								*ngFor="let item of getScheduleItems(i).controls; let j = index"
								class="l-item-style"
							>
								<div *ngIf="!item.get('deleted').value">
									<div class="l-name-and-actions-container w-100">
										<div class="l-name-container pt-3 pb-3 w-100">
											<label class="g-form-label">
												Name
											</label>
											<input
												id="schedule-item-name-{{j}}"
												[formControl]="item.get('name')"
												placeholder="Name"
												class="form-control"
											>
										</div>
										<div
											[ngClass]="{'pe-5 pb-2': !isMobile, 'pe-3' : isMobile}"
											class="l-actions-container d-flex pt-5 gap-3 ps-3"
										>
											<div *ngIf="item.get('hidden').value; else scheduleItemNotHidden">
												<i
													id="unhide-schedule-item-{{j}}"
													class="fa fa-solid fa-eye-slash l-hand-pointer l-icon"
													(click)="handleScheduleHiding($event, item)"
												></i>
											</div>
											<ng-template #scheduleItemNotHidden>
												<i
													id="hide-schedule-item-{{j}}"
													class="fa fa-solid fa-eye l-hand-pointer l-icon"
													(click)="handleScheduleHiding($event, item)"
												></i>
											</ng-template>
											<div
												*ngIf="!getFromFormArray(item, 'hasInUseTickets').value && !isEventFinished; else cantDeleteScheduleItem">
												<i
													id="delete-schedule-item-{{j}}"
													class="fa fa-solid fa-trash l-hand-pointer l-icon"
													(click)="handleDeletingScheduleItem($event, schedule, item)"
												></i>
											</div>
											<ng-template #cantDeleteScheduleItem>
												<mat-icon
													class="l-tooltip-icon"
													#cantDeleteItemTooltip="matTooltip"
													[matTooltip]="cantDeleteScheduleItemTooltip"
													matTooltipPosition="above"
													(click)="cantDeleteItemTooltip.toggle()"
												>
													info
												</mat-icon>
											</ng-template>
										</div>
									</div>
									<div class="l-container">
										<div class="l-datetime-container">
											<label class="g-form-label">
												Start date and time
												<span
													class="g-orange"
													*ngIf="required"
												>*</span>
											</label>
											<input
												id="schedule-item-start-date-{{j}}"
												[formControl]="item.get('startDate')"
												[ngxMatDatetimePicker]="startDatePicker"
												placeholder="HH:mm"
												(click)="startDatePicker.open()"
												class="form-control"
												[max]="item.get('endDate').value"
												[ngClass]="{'form-control-disabled' : isEventFinished || item.get('startDate').disabled}"
											>
											<ngx-mat-datetime-picker
												#startDatePicker
												[showSeconds]="false"
												[touchUi]="isMobile"
												[enableMeridian]="false"
												[disableMinute]="false"
												[hideTime]="false"
												[showSpinners]="false"
											>
												<ngx-mat-datepicker-actions>
													<button
														id="close-datepicker"
														class="g-secondary-button g-datepicker-button"
														ngxMatDatepickerCancel
													>CANCEL</button>
													<button
														id="apply-datepicker"
														class="g-primary-button g-datepicker-button"
														ngxMatDatepickerApply
													>APPLY</button>
												</ngx-mat-datepicker-actions>
											</ngx-mat-datetime-picker>
											<small
												class="g-error-input"
												*ngIf="isControlInvalid(getFromFormArray(item,'startDate')) && !isEventFinished && !item.get('startDate').disabled"
											>
												{{getError(getFromFormArray(item,'startDate').errors)}}
											</small>
										</div>
										<div class="l-datetime-container">
											<label class="g-form-label">
												End date and time
												<span
													class="g-orange"
													*ngIf="required"
												>*</span>
											</label>
											<input
												id="schedule-item-end-date-{{j}}"
												[formControl]="item.get('endDate')"
												[ngxMatDatetimePicker]="endDatePicker"
												placeholder="HH:mm"
												(click)="endDatePicker.open()"
												class="form-control"
												[min]="item.get('startDate').value"
												[ngClass]="{'form-control-disabled' : isEventFinished || item.get('endDate').disabled}"
											>
											<ngx-mat-datetime-picker
												#endDatePicker
												[showSeconds]="false"
												[touchUi]="isMobile"
												[enableMeridian]="false"
												[disableMinute]="false"
												[hideTime]="false"
												[showSpinners]="false"
											>
												<ngx-mat-datepicker-actions>
													<button
														id="close-datepicker"
														class="g-secondary-button g-datepicker-button"
														ngxMatDatepickerCancel
													>CANCEL</button>
													<button
														id="apply-datepicker"
														class="g-primary-button g-datepicker-button"
														ngxMatDatepickerApply
													>APPLY</button>
												</ngx-mat-datepicker-actions>
											</ngx-mat-datetime-picker>
											<small
												class="g-error-input"
												*ngIf="isControlInvalid(getFromFormArray(item,'endDate')) && !isEventFinished && !item.get('endDate').disabled"
											>
												{{getError(getFromFormArray(item,'endDate').errors)}}
											</small>
										</div>
									</div>
								</div>
							</div>
						</mat-expansion-panel>
					</mat-accordion>
				</div>
			</div>
		</div>
	</div>
</ng-template>
