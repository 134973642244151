import { NgModule } from '@angular/core';
import { EventDashboardComponent } from './event-dashboard.component';
import { EventListingModalComponent } from './event-listing-modal/event-listing-modal.component';
import { EventServiceFeeModalComponent } from './event-service-fee-modal/event-service-fee-modal.component';
import { EventApprovalModalComponent } from './event-approval-modal/event-approval-modal.component';
import { EventCancelModalComponent } from './event-cancel-modal/event-cancel-modal.component';
import { EventDatesChagngeModalComponent } from './event-dates-change-modal/event-dates-change-modal.component';
import { EventinviteModalComponent } from './event-invite-modal/event-invite-modal.component';
import { SharedModule } from '@app/shared/shared.module';
import { DashboardReportingModule } from '../dashboard-reporting/dashboard-reporting.module';
import { EventSchedulePublicModalComponent } from './event-schedule-public-modal/event-schedule-public-modal.component';
import { EventPublicCancelModalComponent } from './event-public-cancel-modal/event-public-cancel-modal.component';
import { EventCovidCompliantModalComponent } from './covid-compliant-modal/covid-compliant-modal.component';
import { EventActiveEventsFormComponent } from '@app/features/control-panel/dashboard/event-redirect-modal/event-redirect-form.component';
import { FormFieldModule } from '@app/shared/form-field/form-field.module';

@NgModule({
	declarations: [
		EventDashboardComponent,
		EventListingModalComponent,
		EventServiceFeeModalComponent,
		EventApprovalModalComponent,
		EventCancelModalComponent,
		EventDatesChagngeModalComponent,
		EventinviteModalComponent,
		EventSchedulePublicModalComponent,
		EventPublicCancelModalComponent,
		EventCovidCompliantModalComponent,
		EventActiveEventsFormComponent,
	],
	imports: [
		SharedModule,
		DashboardReportingModule,
		FormFieldModule,
	],
	exports: [
		EventDashboardComponent,
	],
}) export class EventDashboardModule {}
