import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RefundableTicket, RefundableTicketReturn } from '@app/models/user.model';
import { StoreService } from '@app/services/store/store.service';
import { PostRefundableTickets } from '@app/store/actions/user/user.actions';
import { UserActionsConstants } from '@app/store/actions/user/user.actions.constants';
import { GENERIC_ERROR_MESSAGE } from '@app/utils/consts';
import { Actions, ofType } from '@ngrx/effects';
import { take } from 'rxjs';

@Component({
	selector: 'app-refund-modal',
	templateUrl: './refund-modal.component.html',
	styleUrls: ['./refund-modal.component.sass'],
})
export class RefundModalComponent implements OnInit {
	errorMessage: string;
	isSuccess = false;
	isLoading = true;
	allSelectedTicketsFree = false;
	returnedTickets: RefundableTicket[] = [];

	constructor(
		public actions$: Actions,
		public store: StoreService,
		public dialogRef: MatDialogRef<RefundModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data: {
			purchaseId: number;
			tickets: RefundableTicket[];
			bankAccountId: number;
		}) { }

	ngOnInit(): void {
		this.actions$
			.pipe(
				ofType(UserActionsConstants.POST_REFUNDABLE_TICKETS_SUCCESS, UserActionsConstants.POST_REFUNDABLE_TICKETS_FAILED),
				take(1)
			)
			.subscribe(
				({ type, payload: { refundableTickets } }: { type: string; payload: { refundableTickets: RefundableTicketReturn } }
				) => {
					switch (type) {
						case UserActionsConstants.POST_REFUNDABLE_TICKETS_SUCCESS: {
							const { errorMessage, tickets } = refundableTickets;
							this.returnedTickets = tickets;
							this.isSuccess = errorMessage.includes('Success') || errorMessage.includes('successfully');

							if (errorMessage) {
								this.errorMessage = this.allSelectedTicketsFree
									? 'Your Free tickets were successfully cancelled.'
									: errorMessage;
							} else {
								this.errorMessage = GENERIC_ERROR_MESSAGE;
							}

							break;
						}

						default: {
							this.errorMessage = GENERIC_ERROR_MESSAGE;
							this.isSuccess = false;
						}
					}
					this.isLoading = false;
				});

		this.allSelectedTicketsFree = this.data.tickets.every(ticket => ticket.price === 0);
		this.store.dispatch(new PostRefundableTickets({
			purchaseId: this.data.purchaseId as number,
			tickets: this.data.tickets,
			bankAccountId: this.data.bankAccountId,
		}));
	}

	handleClose() {
		this.dialogRef.close({ isSuccess: this.isSuccess, tickets: this.returnedTickets });
	}
}
