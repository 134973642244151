<div class="l-page-container" [ngClass]="{ 'mobile': isMobile}">
    <div class="l-container" *ngIf="!isPageLoading">
        <div class="l-content">
            <app-web-content-header id="send-invites-header" [ngClass]="{ 'mx-3': isMobile, 'mx-5 mt-4': !isMobile }" [showBreadCrumb]='!isMobile'
                [headerContent]="headerContent" [mediumWidth]='true' [customDescription]="customDescription"
                [readMoreText]='readMoreText' includeReadMore='false'></app-web-content-header>
        </div>
    </div>
    <div class="l-dropdown-container">
        <label class="g-form-label">Send invitations by</label>
        <mat-select class='form-control' [value]="selectedOption" (selectionChange)="onOptionSelected($event.value)">
          <mat-option *ngFor="let option of invitationMethod" [value]="option">{{ option }}</mat-option>
        </mat-select>
    </div>
</div>
<div [ngSwitch]="selectedOption" id="selected-component" class="l-invite-component-container" [ngClass]="{ 'mobile': isMobile}">
	<app-add-contacts-manually *ngSwitchCase="sendInvitesMethod.AddContactsManually" [eventId]="eventId" [isMobile]="isMobile"></app-add-contacts-manually>
	<app-upload-csv-file *ngSwitchCase="sendInvitesMethod.UploadCSVFile" [eventId]="eventId" [isMobile]="isMobile"></app-upload-csv-file>
	<app-previous-contact-list *ngSwitchCase="sendInvitesMethod.PreviousContactList" [eventId]="eventId" [isMobile]="isMobile"></app-previous-contact-list>
	<app-previous-event *ngSwitchCase="sendInvitesMethod.PreviousEvent" [eventId]="eventId" [isMobile]="isMobile"></app-previous-event>
</div>
<ng-template #pageLoader class="l-loading">
    <mat-spinner></mat-spinner>
</ng-template>
