import { Injectable } from '@angular/core';
import config from '@app/config';

@Injectable({
	providedIn: 'root',
})
export class GoogleAnalyticsService {
	constructor() { }

	initializeGA() {
		window.dataLayer = window.dataLayer || [];
		window.gtag = window.gtag || function () {
			window.dataLayer.push(arguments);
		};

		window.gtag('js', new Date());
		window.gtag('config', config.GA4MeasurementId);
	}

	getClientID(): Promise<string> {
		return new Promise((resolve, reject) => {
			let isResolved = false;
			window.gtag('get', config.GA4MeasurementId, 'client_id', (clientID) => {
				if (clientID) {
					isResolved = true;
					resolve(clientID);
				} else {
					reject('Client ID not available');
				}
			});
			setTimeout(() => {
				if (!isResolved){
					reject('Client ID not available');
				}
			}, 0);
		});
	}
}
