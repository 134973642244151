import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-header-mobile',
	templateUrl: './header-mobile.component.html',
	styleUrls: ['./header-mobile.component.sass'],
})
export class HeaderMobileComponent {
	@Input() pageName: string;
	@Input() isParent = false;
	@Output() $navigateBack: EventEmitter<void> = new EventEmitter<void>();

	handleNavigationBack() {
		this.$navigateBack.emit();
	}
}


