import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { getCookie, deleteCookie } from '@app/utils/Cookie';
import { CookieKey } from '@app/models/cookies.model';

@Injectable({ providedIn: 'root' })
export class TokenService {
	static authTokenScheme = 'Bearer';

	getToken = () => (
		environment.production || environment.staging
			? getCookie(CookieKey.JWT)
			: localStorage.getItem(CookieKey.JWT)
	);

	removeToken = () => {
		if (environment.production) {
			deleteCookie(CookieKey.JWT);
		} else {
			localStorage.removeItem(CookieKey.JWT);
		}
	};

	setDevToken = () => {
		if (environment.token) {
			localStorage.setItem(CookieKey.JWT, environment.token);
		}
	};

	parseToken = () => {
		const token = this.getToken();
		if (token) {
			return JSON.parse(atob(token.split('.')[1]));
		}
		return null;
	};
}
