<div class="l-container">
    <div [ngClass]="{'mobile': isMobile}" class="l-header-container">
        <app-web-content-header
            [headerContent]="headerContent"
            [mediumWidth]='true'
            [handleRoutingExternally]="true"
            [showBreadCrumb]="!isMobile"
            [showTitle]="!isMobile"
            (handleExternalNavigation)='handleBreadCrumbNavigation($event)'
        >
        </app-web-content-header>
    </div>
    <div class="l-component-container" *ngIf="!isPageLoading else registrationsLoading;">
        <div [ngSwitch]="mode" class="l-component-container-inner">
            <app-registration-settings
                *ngSwitchCase="RegistrationPageMode.Settings"
                [isWeb]="isWeb"
                [isMobile]="isMobile"
                [eventId]="eventId"
                [isPageLoading]="isPageLoading"
                [isApprovalAutomatic]="isApprovalAutomatic"
                [isTimeValid]="isTimeValid"
                [isFormPristine]="isFormPristine"
                [tempSettings]="tempSettings"
                [registrationSettings]="registrationSettings"
                [hasSettings]="hasSettings"
                (saveSettings)="handleSaveSettingsChanges($event)"
                (openCollectRegInfo)="handleEditRegistrationForm()"
                (pristineChange)="pristineChange($event)"
            >
            </app-registration-settings>
            <app-question-list 
                *ngSwitchCase="RegistrationPageMode.Questions"
                class="mt-4"
                [eventId]="eventId"
                [isRegistration]="true"
                [questions]="questions"
                [isLoading]="isLoading"
                [noQuestions]="false"
                [isMobile]="isMobile"
                [displayFooter]="true"
                (questionPropertyToggle)="onEventQuestionPropertyToggle($event)"
                (eventQuestionDeletetion)="onEventQuestionDeletion($event)"
                (reorder)="onReorder($event)"
                (collectInfoFormRequest)="onCollectInfoFormRequest($event)"
                (backPress)="backToSettingsPage()"
            >
            </app-question-list>
            <app-add-edit-question
                *ngSwitchCase="RegistrationPageMode.AddEdit"
                class="l-add-edit-question"
                [isRegistration]="true"
                [question]="selectedQuestion"
                [questions]="questions"
                [profileCollectInfoOptions]="profileCollectInfoOptions"
                [collectInfoTickets]="collectInfoTickets"
                [isEditing]="isEditingQuestion"
                [isMobile]="isMobile"
                [form]="collectInfoForm"
                (save)="onCollectInfoFormClose($event)"
            >
            </app-add-edit-question>
        </div>
    </div>
</div>
<ng-template #registrationsLoading>
    <div class="l-page-loading">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>
