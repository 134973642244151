import { filter, delayWhen, withLatestFrom, tap, mergeMap, switchMap } from 'rxjs/operators';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { v4 } from 'uuid';
import { AddNotification, RemoveNotification } from '../actions/notification/notification.actions';
import { NotificationActionConstants as constants } from '@app/store/actions/notification/notification.constants';
import { Store } from '@ngrx/store';
import { AppState } from '@app/models/store.model';
import { NotificationService } from '@app/services/notification/notification.service';
import { FailedAction, AppAction, ActionTypeEndings } from '@app/models/action.model';
import { notifications as notificationsSelector } from '@app/store/selectors/notification.selector';
import { timer, of, concat } from 'rxjs';
import { NotificationType, NotificationDelay } from '@app/models/notification.model';
import { NotificationSourceService } from '@app/services/notification/notification-source.service';

@Injectable()
export class NotificationEffects {
	DELAY = NotificationDelay.DEFAULT;
	LONG_DELAY = NotificationDelay.LONG;
	constructor(
		private action$: Actions,
		private store$: Store<AppState>,
		private notificationSource: NotificationSourceService,
		private notificationService: NotificationService
	) {}

	failedAction$ = createEffect(
		() =>
			this.action$.pipe(
				filter((action) => endsWith(action.type, ActionTypeEndings.FAILED)),
				tap((action: FailedAction) => {
				})
			),
		{ dispatch: false }
	);

	catchAction$ = createEffect(() =>
		this.action$.pipe(
			filter(
				(action) =>
					this.notificationSource.getDictionary()[action.type] &&
					(endsWith(action.type, ActionTypeEndings.SUCCESS) ||
						(endsWith(action.type, ActionTypeEndings.FAILED) && !(action as FailedAction).payload.critical))
			),
			withLatestFrom(this.store$),
			mergeMap(([action, store]: [AppAction, AppState]) => {
				const { type: actionType } = action;
				const similarActiveActions = notificationsSelector()(store)
					.filter((el) => el.actionType === actionType)
					.map(({ id }) => new RemoveNotification({ id }));

				const notificationType = endsWith(actionType, ActionTypeEndings.SUCCESS)
					? NotificationType.SUCCESS
					: NotificationType.ERROR;

				const defaultDelay = notificationType === NotificationType.ERROR ? this.LONG_DELAY : this.DELAY;

				const { delay, title, desc } = this.notificationService.getNotificationDesc(actionType, action);

				const notificationAction = new AddNotification({
					id: v4(),
					actionType,
					action,
					type: notificationType,
					delay: delay || defaultDelay,
					title,
					desc,
				});

				if (similarActiveActions.length) {
					return concat([...similarActiveActions, notificationAction]);
				}

				return concat([notificationAction]);
			})
		)
	);

	autoCancel$ = createEffect(() =>
		this.action$.pipe(
			ofType<AddNotification>(constants.NOTIFICATION_ADD),
			delayWhen(({ payload: { delay } }) => timer(delay || this.DELAY)),
			withLatestFrom(this.store$),
			switchMap(
				([
					{
						payload: { id },
					},
					store,
				]) => {
					if (notificationsSelector()(store).some((el) => el.id === id)) {
						return of(new RemoveNotification({ id }));
					}

					return of({ type: 'CANCEL_NOTIFICATION' });
				}
			)
		)
	);
}

function endsWith(str: string, temp: string) {
	const index = str.indexOf(temp);
	return index !== -1 && index + temp.length === str.length && true;
}
