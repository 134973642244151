import { Injectable } from '@angular/core';
import { IFileDomainAPI } from '@app/models/file.api.model';
import { HttpService } from '@app/api/http/http.service';
import { AsyncResponse, RequestType } from '@app/models/http.model';
import { UserContactListItem } from '@app/models/user.model';
import config from '@app/config';

@Injectable()
export class FileDomainAPI implements IFileDomainAPI {
	constructor(
		private http: HttpService
	) {}

	removeImage = (imageId: number): AsyncResponse<number> =>
		this.http.makeRequest({
			url: `/api/delete/image/${imageId}`,
			type: RequestType.DELETE,
		});

	uploadContactsCSV = (file: File) => {
		const formData = new FormData();
		formData.append('file', file, file.name);

		return this.http.http.post(
			`${config.baseURL}/api/file/parsecsv`,
			formData
		) as AsyncResponse<UserContactListItem[]>;
	};
}
