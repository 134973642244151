import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { Actions, ofType } from '@ngrx/effects';
import { combineLatest } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';

import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { StoreService } from '@app/services/store/store.service';

import { MY_TICKETS_HEADER } from '@app/utils/consts';
import MoneyPresenter from '@app/utils/MoneyPresenter';
import { InternalURLCreator } from '@app/services/url/url.dictionary';

import { HeaderContent } from '@app/models/shared';
import {
	AccountTicketResaleItem,
	AccountTicketResalePurchaseItem,
	BankAccountReturn,
	AccountTicketResales
} from '@app/models/user.model';
import { Currency } from '@app/models/ticket.model';

import * as userSelectors from '@app/store/selectors/user.selector';
import { UserActionsConstants } from '@app/store/actions/user/user.actions.constants';
import { GetBankAccounts, GetResaleTickets } from '@app/store/actions/user/user.actions';
import { Go } from '@app/store/actions/router/router.actions';
import { GetCurrencies } from '@app/store/actions/tickets/tickets.actions';
import { TicketsActionsConstant } from '@app/store/actions/tickets/tickets.constants';
import { AddNotification } from '@app/store/actions/notification/notification.actions';
import { NotificationType } from '@app/models/notification.model';
import { ProductType } from '@app/models/product.model';
@Component({
	selector: 'app-ticket-resale',
	templateUrl: './ticket-resale.component.html',
	styleUrls: ['./ticket-resale.component.sass'],
})
export class TicketResaleComponent implements OnInit {
	isMobile = false;
	headerContent: HeaderContent = MY_TICKETS_HEADER['ticketResale'];
	purchaseId: number;

	isPageLoading = true;
	isTicketsLoading = true;

	resaleTickets: AccountTicketResales;
	ticketsForResale: AccountTicketResalePurchaseItem[];
	validTicketsForResale: AccountTicketResalePurchaseItem[] = [];
	invalidTicketsForResale: AccountTicketResalePurchaseItem[] = [];
	ticketsInResale: MatTableDataSource<AccountTicketResaleItem> = new MatTableDataSource();

	currencies: Currency[];
	currencySymbol: string;

	bankVerificationChecked = false;
	productName: string;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private breakpointService: BreakpointService,
		private activatedRoute: ActivatedRoute,
		private store: StoreService,
		private actions$: Actions,
		private router: Router
	) { }

	ngOnInit(): void {
		this.bankVerificationCheck();
		this.mobileBreakpoints();
		this.initializeTicketId();
	}

	mobileBreakpoints(): void {
		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});
	}

	initializeTicketId(): void {
		this.activatedRoute.params.pipe(take(1)).subscribe((params) => {
			this.purchaseId = +params['id'];
			this.resaleTicketsAndCurrencies();
		});
	}

	resaleTicketsAndCurrencies(): void {
		this.actions$
			.pipe(
				ofType(UserActionsConstants.GET_RESALE_TICKETS_SUCCESS, UserActionsConstants.GET_RESALE_TICKETS_FAILED),
				take(1)
			)
			.subscribe(() => {
				this.isTicketsLoading = false;
			});

		const resaleTickets$ = this.store.select(userSelectors.currentResaleTickets()).pipe(
			takeUntil(this.destroyed$),
			filter(resaleTickets => +resaleTickets.purchaseId === +this.purchaseId)
		);

		const currencies$ = this.actions$.pipe(
			ofType(TicketsActionsConstant.GET_CURRENCIES_SUCCESS),
			take(1),
			map(({ type, payload }: { type: string; payload: { currencies: Currency[] } }) => {
				if (type === TicketsActionsConstant.GET_CURRENCIES_SUCCESS) {
					return payload.currencies;
				} else {
					return [];
				}
			})
		);

		combineLatest([resaleTickets$, currencies$]).pipe(takeUntil(this.destroyed$)).subscribe(([resaleTickets, currencies]) => {
			this.resaleTickets = resaleTickets.resaleTickets;
			this.productName = resaleTickets.resaleTickets.productName;
			this.validTicketsForResale = [];
			this.invalidTicketsForResale = [];

			if (this.resaleTickets && !this.resaleTickets.allowPrivate && !this.resaleTickets.allowPublic) {
				this.store.dispatch(new AddNotification({
					id: 'resale-not-enabled',
					actionType: null,
					action: null,
					type: NotificationType.ERROR,
					title: 'Ticket resale is not enabled by event organiser.',
				}));
				this.handleNavigationBack();
			}

			this.resaleTickets.ticketsForResale.forEach((ticket) => {
				if (ticket.canResale) {
					this.validTicketsForResale.push(ticket);
				} else {
					this.invalidTicketsForResale.push(ticket);
				}
			});

			const defaultTicket = {
				isPublic: false,
				email: '',
			};
			this.validTicketsForResale = this.validTicketsForResale.map((ticket: AccountTicketResalePurchaseItem) => ({
				...ticket,
				...defaultTicket,
			}));

			this.ticketsInResale.data = this.resaleTickets.ticketsInResale;

			const resaleCurrency = currencies.find((currency) => currency.id === this.resaleTickets.currencyId);
			if (!resaleCurrency) {
				this.currencySymbol = '';
			} else {
				this.currencySymbol = MoneyPresenter.getBasicSymbol(resaleCurrency.iso);
			}

			this.isPageLoading = false;
		});

		this.store.dispatch(new GetResaleTickets({ purchaseId: this.purchaseId }));
		this.store.dispatch(new GetCurrencies({ productType: ProductType.Event }));
	}

	handleNavigationBack() {
		this.store.dispatch(new Go({ path: [InternalURLCreator.manageBooking(this.purchaseId)] }));
	}

	bankVerificationCheck() {
		this.actions$
			.pipe(
				ofType(UserActionsConstants.GET_BANK_ACCOUNTS_SUCCESS, UserActionsConstants.GET_BANK_ACCOUNTS_FAILED),
				take(1)
			)
			.subscribe(({ type, payload: { needsPasswordVerification, passwordVerificationRedirect } }:
			{
				type: string; payload: BankAccountReturn;
			}) => {
				if (type === UserActionsConstants.GET_BANK_ACCOUNTS_SUCCESS && needsPasswordVerification) {
					const currentUrl = '/app/%23' + this.router.url;
					window.location.href = passwordVerificationRedirect + '&redirect=' + currentUrl;
				} else {
					this.bankVerificationChecked = true;
				}
			});

		this.store.dispatch(new GetBankAccounts());
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}

}
