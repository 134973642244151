<div
	[ngClass]="data.type"
	class="l-container"
>
	<div class="l-icon-container">
		<i
			[ngClass]="getNotificationIcon(data)"
			class="l-icon"
		></i>
	</div>
	<div class="l-content-container">
		<div class="l-content">
			<div class="l-title">{{data.title}}</div>
			<div
				class="l-description"
				*ngIf="data.desc"
			>
				{{data.desc}}
			</div>
		</div>
	</div>
	<button
		class="l-retry"
		*ngIf="data.type === NotificationType.ERROR"
		mat-button
		(click)="retry()"
	>Retry</button>
	<button
		class="l-close"
		[ngClass]="{ 'l-error-close': data.type === NotificationType.ERROR }"
		mat-button
		(click)="close()"
	>Close</button>
</div>