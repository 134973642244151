<div [ngClass]="{ 'l-mobile-container': isMobile, 'l-container': !isMobile }">
	<div
		class="l-header-content w-100"
		[ngClass]="{ 'mobile': isMobile }"
	>
		<app-web-content-header
			[headerContent]="headerContent"
			longWidth='true'
			[showBreadCrumb]="!isMobile"
			[showTitle]="!isMobile"
			[readMoreText]='POSTExampleText'
			includeReadMore='true'
			(readMore)='handleShowPostExample()'
		>
		</app-web-content-header>
	</div>
	<div class="l-content-status">
		<div class="l-status-message" [ngClass]="{ 'show': showPOSTExample }">
			<p>{{POSTExample}}</p>
		</div>
	</div>
	<div *ngIf="!isLoading else webhooksLoading">
		<div class="l-content w-100" [ngClass]="{'mobile':isMobile}">
			<form
				*ngIf="form"
				[formGroup]="form"
				class="l-form-container"
				[ngClass]="{ 'mt-4': isMobile }"
			>
				<div [ngClass]="{ 'mobile': isMobile }" class="l-input-container">
					<app-mat-text-control-field
						class="l-input"
						[control]="fromForm('checkoutStarted')"
						[label]="'Checkout started'"
						customId="checkout-started"
						[tooltip]="checkoutStartedTooltip"
					></app-mat-text-control-field>
					<div class="pt-1 pb-3">
						<button
							[ngClass]="{ 'disabled': isSendTestDisabled('checkoutStarted'), 'mobile': isMobile }"
							class="g-primary-button l-send-button"
							id="checkout-started-test"
							[disabled]="isSendTestDisabled('checkoutStarted')"
							(click)="handleSendTest(fromForm('checkoutStarted').value, 'checkout_started')"
							> Send Test
						</button>
					</div>
				</div>
				<div [ngClass]="{ 'mobile': isMobile }" class="l-input-container">
					<app-mat-text-control-field
						class="l-input"
						[control]="fromForm('checkoutCancelled')"
						[label]="'Checkout Cancelled'"
						customId="checkout-cancelled"
						[tooltip]="checkoutCancelledTooltip"
					></app-mat-text-control-field>
					<div class="pt-1 pb-3">
						<button
							[ngClass]="{ 'disabled': isSendTestDisabled('checkoutCancelled'), 'mobile': isMobile }"
							class="g-primary-button l-send-button"
							id="checkout-cancelled-test"
							[disabled]="isSendTestDisabled('checkoutCancelled')"
							(click)="handleSendTest(fromForm('checkoutCancelled').value, 'checkout_cancelled')"
						> Send Test
					</button>
				</div>
				</div>
				<div [ngClass]="{ 'mobile': isMobile }" class="l-input-container">
					<app-mat-text-control-field
						class="l-input"
						[control]="fromForm('eftPending')"
						[label]="'EFT Pending'"
						customId="eft-pending"
						[tooltip]="eftPendingTooltip"
					></app-mat-text-control-field>
					<div class="pt-1 pb-3">
						<button
							[ngClass]="{ 'disabled': isSendTestDisabled('eftPending'), 'mobile': isMobile }"
							class="g-primary-button l-send-button"
							id="eft-pending-test"
							[disabled]="isSendTestDisabled('eftPending')"
							(click)="handleSendTest(fromForm('eftPending').value, 'eft_pending')"
							> Send Test
						</button>
					</div>
				</div>
				<div [ngClass]="{ 'mobile': isMobile }" class="l-input-container">
					<app-mat-text-control-field
						class="l-input"
						[control]="fromForm('checkoutCompleted')"
						[label]="'Checkout Completed'"
						customId="checkout-completed"
						[tooltip]="checkoutCompletedTooltip"
					></app-mat-text-control-field>
					<div class="pt-1 pb-3">
						<button
							[ngClass]="{ 'disabled': isSendTestDisabled('checkoutCompleted'), 'mobile': isMobile }"
							class="g-primary-button l-send-button"
							id="checkout-completed-test"
							[disabled]="isSendTestDisabled('checkoutCompleted')"
							(click)="handleSendTest(fromForm('checkoutCompleted').value, 'checkout_completed')"
							> Send Test
						</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>
<div class="l-sticky-bar">
	<app-footer-button
		[isMobile]="isMobile"
		[primaryText]="'SAVE CHANGES'"
		[isLoading]="isSavingChanges"
		[isDisabled]="!isSubmitAllowed(form)"
		($primaryButton)="proceedFormSubmit(form)"
		primaryButtonId="submit-webhooks-button"
	>
	</app-footer-button>
</div>
<ng-template #webhooksLoading>
	<div class="l-loading">
		<mat-spinner></mat-spinner>
	</div>
</ng-template>