import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { AngularMaterialModule } from '@app/shared/angular-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EventNavigationComponent } from './components/event-navigation/event-navigation.component';
import { EventDashboardComponent } from './dashboard/pages/event-dashboard/event-dashboard.component';
import { EventSideNavComponent } from './components/event-side-nav/event-side-nav.component';
import { ManageEventComponent } from './manage-event/pages/manage-event/manage-event.component';
import { ManageTicketsComponent } from './tickets/pages/manage-tickets/manage-tickets.component';
import { PaymentComponent } from './payment/pages/payment/payment.component';
import { MarketingComponent } from './marketing/pages/marketing/marketing.component';
import { GuestManagementComponent } from './guest-management/pages/guest-management/guest-management.component';
import { ReportsComponent } from './reports/pages/reports/reports.component';
import { SettingsComponent } from './settings/pages/settings/settings.component';
import { GuestListComponent } from './guest-management/pages/guest-list/guest-list.component';
import { IntegrationsComponent } from './settings/pages/integrations/integrations.component';
import { MobileEventHeaderComponent } from './components/mobile-event-header/mobile-event-header.component';
import { MobileEventNavComponent } from './components/mobile-event-nav/mobile-event-nav.component';
import { GuestListTableComponent } from './guest-management/components/guest-list-table/guest-list-table.component';
import { GuestListReportModalComponent } from './guest-management/modals/guest-list-report-modal/guest-list-report-modal.component';
import { GuestDetailsModalComponent } from './guest-management/modals/guest-details-modal/guest-details-modal.component';
import { PayoutsComponent } from './payment/pages/payouts/payouts.component';
import { OrdersComponent } from './guest-management/pages/orders/orders.component';
import { OrdersListComponent } from './guest-management/components/orders-list/orders-list.component';
import { PromotersComponent } from './marketing/pages/promoters/promoters.component';
import { IncentiviseGuestsModalComponent } from './marketing/modals/incentivise-guests-modal/incentivise-guests-modal.component';
import { RegistrationsComponent } from './tickets/pages/registrations/registrations.component';
import { RegistrationsTableComponent } from './tickets/components/registrations-table/registrations-table.component';
import { SendComplimentaryComponent } from './guest-management/pages/send-complimentary/send-complimentary.component';
import { SendRSVPsComponent } from './guest-management/pages/send-rsvps/send-rsvps.component';
import { SendSmsComponent } from './guest-management/pages/send-sms/send-sms.component';
import { PromoterNetworksComponent } from './marketing/pages/promoter-networks/promoter-networks.component';
import { CreatePromoterNetworkModalComponent } from './marketing/modals/create-promoter-network-modal/create-promoter-network-modal.component'; // eslint-disable-line max-len
import { SharePromoterNetworkModalComponent } from './marketing/modals/share-promoter-network-modal/share-promoter-network-modal.component';
import { EventDetailsComponent } from './manage-event/pages/event-details/event-details.component';
import { TabbedContentComponent } from './manage-event/pages/tabbed-content/tabbed-content.component';
import { TabbedContentListComponent } from './manage-event/components/tabbed-content-list/tabbed-content-list.component';
import { AddEditTabComponent } from './manage-event/components/add-edit-tab/add-edit-tab.component';
import { LinkCampaignsComponent } from './marketing/pages/link-campaigns/link-campaigns.component';
import { AddLinkCampaignModalComponent } from './marketing/modals/add-link-campaign-modal/add-link-campaign-modal.component';
import { EventScannersComponent } from './on-the-day/pages/scanners/event-scanners.component';
import { CreateScannerModalComponent } from './on-the-day/pages/scanners/modals/create-scanner-modal/create-scanner-modal.component';
import { ReadMoreModalComponent } from './on-the-day/pages/scanners/modals/read-more-modal/read-more-modal.component';
import { EventFieldServicesComponent } from './on-the-day/pages/event-field-services/event-field-services.component';
import { SendInvitesComponent } from './dashboard/pages/send-invites/send-invites.component';
import { PreviousEventComponent } from './dashboard/components/previous-event/previous-event.component';
import { UploadCSVFileComponent } from './dashboard/components/upload-csv-file/upload-csv-file.component';
import { AddContactsManuallyComponent } from './dashboard/components/add-contacts-manually/add-contacts-manually.component';
import { PreviousContactListComponent } from './dashboard/components/previous-contact-list/previous-contact-list.component';
import { MissingPermissionsComponent } from './components/missing-permissions/missing-permissions.component';
import { LottieModule } from 'ngx-lottie';
import { CheckoutQuestionsComponent } from './tickets/pages/checkout-questions/checkout-questions.component';
import { QuestionListComponent } from './components/question-list/question-list.component';
import { AddEditQuestionComponent } from './tickets/components/add-edit-question/add-edit-question.component';
import { TabContentContentComponent } from './manage-event/components/tab-content-content/tab-content-content.component';
import { TabContentSchedulesComponent } from './manage-event/components/tab-content-schedules/tab-content-schedules.component';
import { TabContentMixedContentComponent } from './manage-event/components/tab-content-mixed-content/tab-content-mixed-content.component';
import { TabContentNewsComponent } from './manage-event/components/tab-content-news/tab-content-news.component';
import { TabContentImageGalleryComponent } from './manage-event/components/tab-content-image-gallery/tab-content-image-gallery.component';
import { TabContentAttributeComponent } from './manage-event/components/tab-content-attribute/tab-content-attribute.component';
import { DefinitionsComponent } from './settings/pages/definitions/definitions.component';
import { EventDesignComponent } from './manage-event/pages/event-design/event-design.component';
import { EventCompletionProgressComponent } from './dashboard/components/event-completion-progress/event-completion-progress.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { CompletionProgressItemComponent } from './dashboard/components/completion-progress-item/completion-progress-item.component';
import { EventActionsMenuButtonComponent } from './dashboard/components/event-actions-menu-button/event-actions-menu-button.component';
import { EditEventLinkModalComponent } from './dashboard/modals/edit-event-link-modal/edit-event-link-modal.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardHeaderComponent } from './dashboard/components/dashboard-header/dashboard-header.component';
import { ShareMyEventButtonsComponent } from './components/share-my-event-buttons/share-my-event-buttons.component';
import { ScheduleToGoPublicModalComponent } from './dashboard/modals/schedule-to-go-public-modal/schedule-to-go-public-modal.component';
import { DashboardInfoButtonsComponent } from './dashboard/components/dashboard-info-buttons/dashboard-info-buttons.component';
import { RecentOrdersComponent } from './dashboard/components/recent-orders/recent-orders.component';
import { TotalGuestsComponent } from './dashboard/components/total-guests/total-guests.component';
import { MerchandiseComponent } from './tickets/pages/merchandise/merchandise.component';
import { PromotionalCodesComponent } from './marketing/pages/promotional-codes/promotional-codes.component';
import { UserAccessComponent } from './manage-event/pages/user-access/user-access.component';
import { PrintedTicketsComponent } from './guest-management/pages/printed-tickets/printed-tickets.component';
import { VisitorReportComponent } from './reports/pages/visitor-report/visitor-report.component';
import { SalesReportComponent } from './reports/pages/sales-report/sales-report.component';
import { CheckInReportComponent } from './reports/pages/check-in-report/check-in-report.component';
import { ReviewsReportComponent } from './reports/pages/reviews-report/reviews-report.component';
import { MailChimpComponent } from './settings/pages/mail-chimp/mail-chimp.component';
import { WidgetComponent } from './settings/pages/widget/widget.component';
import { OverviewReportsComponent } from './dashboard/components/overview-reports/overview-reports.component';
import { EventSettingsTicketingComponent } from './settings/pages/event-settings-ticketing/event-settings-ticketing.component';
import { TicketTypesComponent } from './tickets/pages/ticket-types/ticket-types.component';
import { StopSalesModalComponent } from './tickets/modals/stop-sales-modal/stop-sales-modal.component';
import { RegistrationSettingsComponent } from './tickets/components/registration-settings/registration-settings.component';
import { OverviewReportsFiltersComponent } from './dashboard/components/overview-reports-filters/overview-reports-filters.component';
import { SubmitEventModalComponent } from './dashboard/modals/submit-event-modal/submit-event-modal.component';
import { CancelPostponeComponent } from './manage-event/pages/cancel-postpone/cancel-postpone.component';
import { ExportDataComponent } from './reports/pages/export-data/export-data.component';
import { CopyEventComponent } from './manage-event/pages/copy-event/copy-event.component';
import { UserRegistrationDetailsComponent } from './tickets/pages/user-registration-details/user-registration-details.component';
import { EventOrganiserComponent } from './manage-event/pages/event-organiser/event-organiser.component';
import { LinkedProductsComponent } from './manage-event/modals/linked-products/linked-products.component';
import { TicketGroupsComponent } from './tickets/pages/ticket-groups/ticket-groups.component';
import { TicketGroupsListComponent } from './tickets/components/ticket-groups-list/ticket-groups-list.component';
import { TicketGroupsAddEditComponent } from './tickets/components/ticket-groups-add-edit/ticket-groups-add-edit.component';
import { EventSettingsComponent } from './settings/pages/event-settings/event-settings.component';
import { WebhooksComponent } from './settings/pages/webhooks/webhooks.component';
import { TestWebhooksModalComponent } from './settings/pages/modals/test-webhooks-modal/test-webhooks-modal.component';
import { MiddleClickNavigateModule } from '@app/directive/middle-click-navigate.directive';
import { TransferScheduleComponent } from './guest-management/pages/transfer-schedule/transfer-schedule.component';
import {
	EventSettingsTicketingDescriptionComponent
} from './settings/components/event-settings-description/event-settings-ticketing-description.component';
import { SendInvitesModalComponent } from './dashboard/modals/send-invites-modal/send-invites-modal.component';
import {
	RedirectFinishedEventModalComponent
} from './dashboard/modals/redirect-finished-event-modal/redirect-finished-event-modal.component';
import { SalesDateWarningModalComponent } from './manage-event/modals/sales-date-warning-modal/sales-date-warning-modal.component';
import { ChangeTicketTableComponent } from './guest-management/components/change-ticket-table/change-ticket-table.component';
import { OrderTicketsModalComponent } from './guest-management/modals/order-tickets-modal/order-tickets-modal.component';
import { ResendTicketsModalComponent } from './guest-management/modals/resend-tickets-modal/resend-tickets-modal.component';
import { OrderDetailsComponent } from './guest-management/pages/order-details/order-details.component';
import { MarkAsPaidModalComponent } from './guest-management/modals/mark-as-paid-modal/mark-as-paid-modal.component';
import { CancelTicketsTableComponent } from './guest-management/components/cancel-tickets-table/cancel-tickets-table.component';
import {
	CancelTicketsBankDetailsComponent
} from './guest-management/components/cancel-tickets-bank-details/cancel-tickets-bank-details.component';
import { PromoterNetworkComponent } from './marketing/pages/promoter-network/promoter-network.component';

@NgModule({
	declarations: [
		EventNavigationComponent,
		EventDashboardComponent,
		EventSideNavComponent,
		ManageEventComponent,
		ManageTicketsComponent,
		PaymentComponent,
		MarketingComponent,
		GuestManagementComponent,
		EventScannersComponent,
		ReportsComponent,
		SettingsComponent,
		GuestListComponent,
		GuestListTableComponent,
		GuestListComponent,
		GuestListTableComponent,
		IntegrationsComponent,
		MobileEventHeaderComponent,
		MobileEventNavComponent,
		GuestListReportModalComponent,
		GuestDetailsModalComponent,
		GuestListReportModalComponent,
		GuestDetailsModalComponent,
		PayoutsComponent,
		OrdersComponent,
		OrdersListComponent,
		PromotersComponent,
		IncentiviseGuestsModalComponent,
		RegistrationsComponent,
		RegistrationsTableComponent,
		SendComplimentaryComponent,
		SendRSVPsComponent,
		SendSmsComponent,
		EventDetailsComponent,
		PromoterNetworksComponent,
		CreatePromoterNetworkModalComponent,
		SharePromoterNetworkModalComponent,
		TabbedContentComponent,
		TabbedContentListComponent,
		AddEditTabComponent,
		LinkCampaignsComponent,
		AddLinkCampaignModalComponent,
		CreateScannerModalComponent,
		ReadMoreModalComponent,
		EventFieldServicesComponent,
		SendInvitesComponent,
		PreviousEventComponent,
		UploadCSVFileComponent,
		AddContactsManuallyComponent,
		PreviousContactListComponent,
		MissingPermissionsComponent,
		CheckoutQuestionsComponent,
		QuestionListComponent,
		AddEditQuestionComponent,
		TabContentContentComponent,
		TabContentSchedulesComponent,
		TabContentMixedContentComponent,
		TabContentNewsComponent,
		TabContentImageGalleryComponent,
		TabContentAttributeComponent,
		DefinitionsComponent,
		EventDesignComponent,
		EventCompletionProgressComponent,
		CompletionProgressItemComponent,
		DashboardHeaderComponent,
		ShareMyEventButtonsComponent,
		ScheduleToGoPublicModalComponent,
		DashboardInfoButtonsComponent,
		EventActionsMenuButtonComponent,
		EditEventLinkModalComponent,
		TotalGuestsComponent,
		RecentOrdersComponent,
		MerchandiseComponent,
		PromotionalCodesComponent,
		UserAccessComponent,
		PrintedTicketsComponent,
		VisitorReportComponent,
		SalesReportComponent,
		CheckInReportComponent,
		ReviewsReportComponent,
		MailChimpComponent,
		WidgetComponent,
		OverviewReportsComponent,
		EventSettingsTicketingComponent,
		TicketTypesComponent,
		StopSalesModalComponent,
		RegistrationSettingsComponent,
		OverviewReportsFiltersComponent,
		SubmitEventModalComponent,
		CancelPostponeComponent,
		ExportDataComponent,
		CopyEventComponent,
		UserRegistrationDetailsComponent,
		EventOrganiserComponent,
		LinkedProductsComponent,
		TicketGroupsComponent,
		TicketGroupsListComponent,
		TicketGroupsAddEditComponent,
		EventSettingsComponent,
		WebhooksComponent,
		TestWebhooksModalComponent,
		TransferScheduleComponent,
		EventSettingsTicketingDescriptionComponent,
		SendInvitesModalComponent,
		RedirectFinishedEventModalComponent,
		SalesDateWarningModalComponent,
		ChangeTicketTableComponent,
  		OrderTicketsModalComponent,
		ResendTicketsModalComponent,
		OrderDetailsComponent,
  		MarkAsPaidModalComponent,
  		CancelTicketsTableComponent,
    	CancelTicketsBankDetailsComponent,
		PromoterNetworkComponent,
	],
	imports: [
		CommonModule,
		SharedModule,
		AngularMaterialModule,
		ReactiveFormsModule,
		FormsModule,
		LottieModule,
		NgCircleProgressModule.forRoot({}),
		BrowserAnimationsModule,
		MiddleClickNavigateModule,
	],
})
export class EventsModule { }
