<a 
	[href]="getSharingURL()"
	target="_blank"
	data-pin-do="buttonBookmark"
	data-pin-custom="true"
>
	<button
		class="g-share-my-event-button"
	>
		<i class="fa-brands fa-pinterest-p"></i>
	</button>
</a>