import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'formattedTimePipe',
})
export class FormattedTimePipe implements PipeTransform {
	transform(dateString: string): string {
		const timestamp = Date.parse(dateString);
		const time = new Date(timestamp);

		const options: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit', hour12: false };

		return time.toLocaleTimeString(undefined, options);
	}
}
