<div class="l-container">
	<div class="l-empty">
		<ng-lottie
			width='220px'
			height='220px'
			[options]="options"
			class='l-lottie'></ng-lottie>
		<h3>{{ currentPageHeading || 'Unauthorised' }}</h3>
		<p class="mt-2">
			You are not authorised to <span class='fw-bold g-green'>read</span> or <span
				class='fw-bold g-green'>modify</span> content on this page. Please refer to the event's
			organiser for additional information.
		</p>
		<ng-container *ngIf="permissions.length">
			<ul class='l-list'>
				<li *ngFor="let perm of permissions">
					<span class="l-badge" [ngClass]="{ 'l-active': perm.access === 'Modify' }">
						{{ perm.access }}
					</span>
					- {{perm.title}}
				</li>
			</ul>
		</ng-container>
	</div>
</div>
