import { InternalURLCreator } from '@app/services/url/url.dictionary';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StoreService } from '@app/services/store/store.service';
import { Go } from '@app/store/actions/router/router.actions';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs';
import { OrganiserActionsConstants } from '@app/store/actions/organiser/organiser.actions.constants';
import * as organiserSelectors from '@app/store/selectors/organiser.selector';
import { GetFilteredProducts } from '@app/store/actions/organiser/organiser.actions';
import { BasicEventCard, NameAndID, ProductFilter, ProductListSortBy, SortOrder } from '@app/models/organiser.model';
import { PageEvent } from '@angular/material/paginator';
import { ProductStatus, ProductStatusMapping } from '@app/models/event.model';
import { ProductType } from '@app/models/product.model';
import { Sort, SortDirection } from '@angular/material/sort';
import { FilterOptions } from '@app/models/shared';

@Component({
	selector: 'app-product-list',
	templateUrl: './product-list.component.html',
	styleUrls: ['./product-list.component.sass'],
})
export class ProductListComponent {
	@Input() isMobile = false;
	@Input() organiserNames: NameAndID[];
	@Input() isEventsTab = true;
	@Input() userId: number;

	@Output() reportButtonClick: EventEmitter<void> = new EventEmitter<void>();

	isProductsLoading = true;
	isProductsFiltering = false;
	isFirstDispatch = true;
	hasNoEvents = false;

	eventStatuses = ProductStatusMapping;

	productFilter = new ProductFilter();
	totalProducts = 0;

	products: BasicEventCard[] = [];

	displayColumns: string[] = ['details', 'date', 'id', 'quantity', 'status', 'access'];
	pageSizeOptions = [10, 25, 50, 100];
	currentPageIndex = 0;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private store: StoreService,
		private actions$: Actions
	) { }

	ngOnInit(): void {
		this.initialiseFilteredProducts();
	}

	initialiseFilteredProducts() {
		this.dispatchFilteredProducts();

		this.actions$
			.pipe(
				ofType(OrganiserActionsConstants.GET_FILTERED_PRODUCTS_SUCCESS, OrganiserActionsConstants.GET_FILTERED_PRODUCTS_FAILED),
				takeUntil(this.destroyed$)
			)
			.subscribe(() => {
				this.isProductsLoading = false;
				this.isProductsFiltering = false;
			});

		this.store.select(organiserSelectors.getFilteredProducts()).pipe(takeUntil(this.destroyed$)).subscribe((filteredProducts) => {
			if (filteredProducts) {
				this.products = this.setDateValidity(filteredProducts.userProducts);
				this.totalProducts = filteredProducts.totalProducts;
				if (this.isFirstDispatch){
					if (!this.products.length){
						this.hasNoEvents = true;
					}
					this.isFirstDispatch = false;
				}
			}
		});
	}

	handleFilterOutput(filterOutput: FilterOptions) {
		const { searchTerm, eventStatus, organiserId } = filterOutput;
		this.productFilter.search = searchTerm || '';
		this.productFilter.statusFilter = eventStatus;
		this.productFilter.organiserFilter = organiserId;
		this.resetPagination();
		this.dispatchFilteredProducts();
	}

	dispatchFilteredProducts() {
		this.isProductsFiltering = true;

		const filters = {
			...this.productFilter,
			type: ProductType.Event,
		};
		this.store.dispatch(new GetFilteredProducts({ userId: this.userId, productsFilter: filters }));
	}

	handleStatusClass(statusValue: number) {
		return ProductStatus[statusValue].toLowerCase();
	}

	handleStatusMapping(statusValue: number) {
		return ProductStatusMapping[ProductStatus[statusValue]].toUpperCase();
	}

	handleNavigation(id: number) {
		const path =  InternalURLCreator.eventDashboard(id);
		this.store.dispatch(new Go({ path: [path] }));
	}

	handleRouterLink(id: number): string {
		return InternalURLCreator.eventDashboard(id);
	}

	handleCreationNavigation() {
		const path = InternalURLCreator.createEvent();
		this.store.dispatch(new Go({ path: [path] }));
	}

	handleEmailReport() {
		this.reportButtonClick.emit();
	}

	handlePagination(page: PageEvent) {
		this.productFilter.pageNumber = page.pageIndex + 1;
		this.productFilter.pageSize = page.pageSize;
		this.currentPageIndex = page.pageIndex;
		this.dispatchFilteredProducts();
	}

	handleSorting(sort: Sort) {
		this.productFilter.sortOrder = this.getSortOrder(sort.direction);
		this.productFilter.sortBy = this.getSortBy(sort.active);
		this.resetPagination();
		this.dispatchFilteredProducts();
	}

	resetPagination(){
		this.currentPageIndex = 0;
		this.productFilter.pageNumber = 1;
	}

	setDateValidity(products: BasicEventCard[]): BasicEventCard[] {
		return products.map(product => {
			if (product.date === '0001-01-01T00:00:00Z') {
				return { ...product, date: null };
			}
			return product;
		});
	}

	getSortOrder(direction: SortDirection): SortOrder | null {
		switch (direction) {
			case 'asc':
				return SortOrder.asc;
			default:
				return SortOrder.desc;
		}
	}

	getSortBy(active: string): ProductListSortBy {
		switch (active) {
			case 'id':
				return ProductListSortBy.id;
			case 'quantity':
				return ProductListSortBy.quantity;
			case 'date':
				return ProductListSortBy.startDate;
			default:
				return ProductListSortBy.eventDetails;
		}
	}

	getTypeContent(product: BasicEventCard) {
		return product.isOwner ? 'Owner' : 'Shared';
	}

	getAccessClass(product: BasicEventCard) {
		return product.isOwner ? 'owner' : 'shared';
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}

}
