<div [ngClass]="{ 'l-mobile-container': isMobile, 'l-container': !isMobile }">
	<div [class.pb-3]="!isMobile">
		<app-web-content-header 
			[headerContent]="headerContent"
			[mediumWidth]='true'
			[showBreadCrumb]="!isMobile"
			[showTitle]="!isMobile"
		>
		</app-web-content-header>
	</div>
	<ng-container *ngIf="!isLoading; else formLoading">
		<app-event-details-form
			[form]="form" [isMobile]="isMobile"
			[image]="image"
			[eventId]="eventId"
			[hasSoldTickets]="hasSoldTickets"
			[eventFormats]="eventFormats"
			[eventCategories]="eventCategories"
			[eventSubcategories]="eventSubcategories"
			[eventFeatureOptions]="eventFeatureOptions"
			[previousVenues]="previousVenuesOptions"
			[dynamicValidatorsKeys]="dynamicValidatorsKeys"
			[salesDateWarning]="isSalesDateWarningVisible"
		>
		</app-event-details-form>
	</ng-container>
	<ng-template #formLoading>
		<div class="l-loading">
			<mat-spinner></mat-spinner>
		</div>
	</ng-template>
</div>
<div class="l-sticky-bar" *ngIf="!isLoading">
	<app-footer-button [isMobile]="isMobile" [primaryText]="'SAVE'"
		[isDisabled]="!isSubmitAllowed()" ($primaryButton)="proceedFormSubmit()"
		[isLoading]="isUpdating"
	>
	</app-footer-button>
</div>
