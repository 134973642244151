import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SchemeID } from '@app/models/dataSchema.model';
import { CreateEdit, EventScanner, ShortLink } from '@app/models/event.model';

@Component({
	selector: 'app-create-scanner-modal',
	templateUrl: './create-scanner-modal.component.html',
	styleUrls: ['./create-scanner-modal.component.sass'],
})
export class CreateScannerModalComponent {
	label?: string = 'Create Promoter Network';
	scannerName: string;
	scannerPin: string;
	productId: SchemeID;
	id: SchemeID;
	shortLink: ShortLink;

	constructor(
		public dialogRef: MatDialogRef<CreateScannerModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data: {
			isMobile: boolean;
			scanner?: EventScanner;
			actionType: string;
			modalTitle: CreateEdit;
			productId: SchemeID;
		}
	) {
		if (data.actionType === CreateEdit.Edit) {
			this.scannerName = this.data.scanner.scannerName;
			this.scannerPin = this.data.scanner.scannerPin;
			this.productId = this.data.scanner.productId;
			this.id = this.data.scanner.id;
			this.shortLink = this.data.scanner.shortLink;
		}
	}

	handleClose() {
		this.dialogRef.close();
	}

	handleSave(): void {
		let newScanner = new EventScanner();

		newScanner = {
			scannerName: this.scannerName,
			scannerPin: this.scannerPin,
			productId: this.data.productId,
			...(this.data.actionType !== CreateEdit.Create && {
				id: this.id,
				shortLink: this.shortLink,
			}),
		};

		this.dialogRef.close(newScanner);
	}
}
