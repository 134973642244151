<div class="l-container" [ngClass]="{ 'mobile': isMobile }" *ngIf="!isCampaignsLoading else pageLoading">
	<div class='l-header-content' [ngClass]="{ 'mobile': isMobile }">
		<app-web-content-header [showBreadCrumb]='!isMobile' [headerContent]="headerContent" [mediumWidth]='true'
			[customDescription]="customDescription" [readMoreText]='readMoreText' includeReadMore='true'
			(readMore)='handleShowMore()' [showBreadCrumb]="!isMobile" [showTitle]="!isMobile"></app-web-content-header>
		<div *ngIf='hasCampaigns'>
			<div
				[ngClass]="{ 'l-download-button-mobile-container': isMobile, 'l-download-button-container': !isMobile }">
				<button class="g-primary-button" [ngClass]="{ 'w-100': isMobile, 'l-download-button': !isMobile }"
					(click)="handleDownloadLinkCampaigns()">DOWNLOAD
					CAMPAIGNS</button>
			</div>
		</div>
	</div>
	<div class="l-status-message" [ngClass]="{ 'show': showMore }">
		<p>Here are a few examples of smart uses:</p>
		<ol>
			<li *ngFor="let example of linkUseExamples">
				{{ example }}
			</li>
		</ol>
		<p>For each link you create, we will record the number of people who clicked on the link, as well as the number
			of tickets purchased for your event by people clicking the link. <span class='fw-bold g-green'>"Note:
			</span>
			<span class='fw-bold'>VISITS" is an indication of number
				of unique visitors, so no matter how many times somebody visits a link, it still only counts
				once.</span>
		</p>
		<p>
			Once you've created your links, come back at any time to see how they're performing!
		</p>
	</div>
	<div *ngIf='hasCampaigns; else noContent'>
		<div class="l-info-cards-container" [ngClass]="{ 'mobile': isMobile }">
			<app-info-card class="l-info-card" [isMobile]="isMobile" title="Total Visits"
			[data]="totalVisits ? totalVisits : '-'" tooltipText="Total visits across all your campaigns">
			</app-info-card>
			<app-info-card class="l-info-card" [isMobile]="isMobile"
				title="Total {{isEvent ? 'Tickets Sold' : 'Donations'}}" [data]="totalTickets ? totalTickets : '-'"
				tooltipText="Total number of tickets sold across all campaigns">
			</app-info-card>
			<app-info-card class="l-info-card" [isMobile]="isMobile" title="Total Sold Through Quicket"
				[data]="totalQuicketTickets ? totalQuicketTickets : '-'"
				tooltipText="Total monetary amount of tickets sold directly through Quicket">
			</app-info-card>
		</div>
		<div class="l-content" [ngClass]="{ 'mobile': isMobile }">
			<app-web-filter [isMobile]="isMobile" [buttonText]="'ADD LINK CAMPAIGN'" [searchPlaceholder]="'Search'"
				(filterOutput)="handleFilter($event)" (buttonClick)="handleCreateCampaign()"></app-web-filter>
			<div class="l-table-container">
				<table mat-table [dataSource]="campaigns" class='l-table web' matSort>
					<ng-container matColumnDef="campaignDetails">
						<th id="campaign-details-table-header" mat-header-cell *matHeaderCellDef mat-sort-header>
							Campaign </th>
						<td mat-cell *matCellDef="let campaign">
							<div class='l-campaign-data-container'>
								<div class='l-campaign-name'>{{ campaign.name }}</div>
								<div>View Link: <span id='view-link-value' class='l-campaign-link'
										(click)='handleLinkClick(campaign.eventLink, campaign.campaignUrl)'>{{
										campaign.campaignUrl }}</span></div>
								<div class='l-campaign-date'>{{ campaign.dateAdded | formattedDatePipe }}</div>
							</div>
						</td>
					</ng-container>
					<ng-container matColumnDef="visits">
						<th id="visits-table-header" mat-header-cell *matHeaderCellDef mat-sort-header> Visits </th>
						<td mat-cell *matCellDef="let campaign">{{ campaign.visits }}</td>
					</ng-container>
					<ng-container matColumnDef="sales">
						<th id="sales-table-header" mat-header-cell *matHeaderCellDef mat-sort-header> Sales </th>
						<td mat-cell *matCellDef="let campaign">{{ campaign.sales |
							currency:getCurrencySymbol(campaign.currencyCode) }}</td>
					</ng-container>
					<ng-container matColumnDef="tickets">
						<th id="tickets-table-header" mat-header-cell *matHeaderCellDef mat-sort-header> {{ isEvent ? 'Tickets' : 'Donations'}}
						</th>
						<td mat-cell *matCellDef="let campaign">{{ campaign.tickets }}</td>
					</ng-container>
					<ng-container matColumnDef="copy">
						<th mat-header-cell *matHeaderCellDef> Copy </th>
						<td mat-cell *matCellDef="let campaign">
							<div class='l-more' [id]="'copy-link-' + campaign.name" (click)='handleCopy(campaign.eventLink, campaign.campaignUrl)'>
								<mat-icon class='l-icon'>file_copy</mat-icon>
							</div>
						</td>
					</ng-container>
					<ng-container matColumnDef="delete">
						<th mat-header-cell *matHeaderCellDef> Delete </th>
						<td mat-cell *matCellDef="let campaign">
							<div class='l-more' [id]="'delete-button-' + campaign.name" (click)='handleDelete(campaign.id, campaign.name)'>
								<mat-icon class='l-icon'>delete_outline</mat-icon>
							</div>
						</td>
					</ng-container>
					<tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
					<tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
					<tr *matNoDataRow>
						<td [colSpan]="displayColumns.length">
							<div class="l-empty-state">
								<h3 class="mb-3">Nothing to see here...</h3>
								<div class="mb-3">Nothing matches your search</div>
							</div>
						</td>
					</tr>
				</table>
			</div>
			<mat-paginator #campaignPaginator [pageSizeOptions]="pageSizeOptions"></mat-paginator>
		</div>
	</div>
	<ng-template #noContent>
		<div class="l-campaign-content-empty" [ngClass]="{ 'mobile': isMobile }">
			<img src=".\assets\placeholders\promoter-network-empty.png" class="l-image">
			<h3>Create A Link Campaign</h3>
			<p class="l-no-campaign-description" [ngClass]="{ 'mobile': isMobile }">
				You currently have no Link Campaigns.</p>
			<button class="g-primary-button" id="create-link-campaign-button" (click)="handleCreateCampaign()">CREATE
				A LINK CAMPAIGN</button>
		</div>
	</ng-template>
</div>
<ng-template #pageLoading>
	<div class="l-page-loader">
		<mat-spinner></mat-spinner>
	</div>
</ng-template>
