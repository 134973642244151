import { Component, EventEmitter, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SchemeID } from '@app/models/dataSchema.model';
import { StoreService } from '@app/services/store/store.service';
import { TestEventWebhooks } from '@app/store/actions/event/event.actions';
import { takeUntil } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { EventActionsConstants } from '@app/store/actions/event/event.actions.constants';
import * as eventSelectors from '@app/store/selectors/event.selector';


@Component({
	selector: 'app-test-webhooks-modal',
	templateUrl: './test-webhooks-modal.component.html',
	styleUrls: ['./test-webhooks-modal.component.sass'],
})
export class TestWebhooksModalComponent {
	isLoading = true;
	isMobile = false;
	destroyed$: EventEmitter<void> = new EventEmitter<void>();
	primaryButtonText = 'CLOSE';
	testResponse: string;
	constructor(
		private store: StoreService,
		private action$: Actions,
		public dialogRef: MatDialogRef<TestWebhooksModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data: {
			eventId: SchemeID;
			webhook: string;
			type: string;
			isMobile: boolean;
		}
	) { }


	ngOnInit() {
		this.isMobile = this.data.isMobile;

		this.action$
			.pipe(
				ofType(EventActionsConstants.TEST_EVENT_WEBHOOKS_SUCCESS, EventActionsConstants.TEST_EVENT_WEBHOOKS_FAILED),
				takeUntil(this.destroyed$)
			)
			.subscribe(() => {
				this.isLoading = false;
			});

		this.store.select(eventSelectors.testWebhooks(this.data.eventId)).pipe(takeUntil(this.destroyed$)).subscribe((testResponse) => {
			if (testResponse){
				this.testResponse = testResponse;
			}
		});

		this.store.dispatch(new TestEventWebhooks({
			eventId: this.data.eventId,
			testWebhook: {
				url: this.data.webhook,
				responseCode: '',
				action: this.data.type,
			},
		}));

	}

	handleCancel() {
		this.dialogRef.close();
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
