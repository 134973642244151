<div class="l-graph-details">
	<div class="l-graph-info-container">
		<div class="l-graph-info-content">
			<div class="l-graph-title">
				{{ title }} <span *ngIf="isMonthView">for {{ selectedMonthLabel }}</span>
			</div>
			<div
				*ngIf="currencySymbol; else isTickets"
				class="l-graph-total fade-in"
			>
				{{ totalFilteredValue | currency: currencySymbol + ' '}}
			</div>
			<ng-template #isTickets>
				<div class="l-graph-total fade-in">
					{{ totalFilteredValue | number }}
				</div>
			</ng-template>
		</div>
		<button
			*ngIf="currentTicketTypeFilter"
			[disabled]="totalFilteredValue === 0"
			[ngClass]="{ 'disabled': totalFilteredValue === 0 }"
			class="g-secondary-button l-chart-view-toggle"
			[matTooltip]="totalFilteredValue === 0 ? 'No data to view' : 'View your data as a table with the currently applied filters'"
			matTooltipPosition="left"
			(click)="handleTicketsSoldModal()"
		>
			<img
				class="l-icon"
				[ngClass]="{ 'disabled': totalFilteredValue === 0 }"
				src="./assets/icons/view-table.svg"
			/>
			<span class="l-chart-view-text">View as Table</span>
		</button>
	</div>
	<div
		*ngIf="showLegend && !isMobile"
		class='l-legend'
	>
		<div
			*ngFor="let item of legendItems"
			class='l-legend-content'
		>
			<div
				class='l-legend-item'
				[style.background-color]="item.color"
			></div> {{item.name}}
		</div>
	</div>
</div>
<div class="l-graph-container">
	<ngx-charts-bar-vertical-stacked
		[scheme]="colorScheme"
		[customColors]="legendItems"
		[legend]="false"
		[xAxis]="xAxis"
		[yAxis]="yAxis"
		[results]="displayData"
		[wrapTicks]="true"
		[xAxisLabel]='selectedMonthLabel'
		[showXAxisLabel]='isMonthView'
		[yAxisTickFormatting]="handleFormatYAxisLabel"
		(select)='handleGraphSelect($event)'
	>
		<ng-template
			*ngIf="currentTicketTypeFilter"
			#tooltipTemplate
			let-model="model"
		>
			<div class="my-2">
				<div class="l-legend-total">TOTAL: {{currencySymbol}} {{ calculateTotal(model) | number}}</div>
				<div class="l-legend-content my-1">
					<div
						class='l-legend-item'
						[style.background-color]="getTooltipColour(model)"
					></div>{{ model.name | truncate: 60 }}: {{currencySymbol}} {{ model.value | number }}
				</div>
			</div>
		</ng-template>
	</ngx-charts-bar-vertical-stacked>
	<div
		*ngIf="isMonthView"
		(click)="handleGraphReset()"
		class="l-graph-toggle fade-in"
		[ngClass]="{'l-graph-toggle-mobile': isMobile}"
	>
		<mat-icon *ngIf='isMobile else showText'>keyboard_return</mat-icon>
		<ng-template #showText>SHOW YEAR VIEW</ng-template>
	</div>
</div>