<div
	class="l-container"
	[class.mobile]="data.isMobile"
>
	<h3 class="mb-3 text-center">Unsaved Changes</h3>
	<p class="mb-4 text-center">You have unsaved changes{{ getUnsavedStepTitle() && getUnsavedStepTitle().length ? '' : '.' }}
		<span *ngIf="getUnsavedStepTitle()">
			at <b>{{getUnsavedStepTitle()}}</b> {{ getUnsavedStepTitle().length > 1 ? 'steps.' : 'step.' }}
		</span>
		<br>
		Are you sure you want to leave this page?
	</p>
	<div class="l-buttons-container">
		<button
			id="cancel"
			class="g-secondary-button"
			(click)="handleCancel()"
		>CANCEL</button>
		<button
			id="leave"
			class="g-primary-button"
			(click)="handleDelete()"
		>LEAVE</button>
	</div>
</div>