<app-my-account-header
	[isMobile]='isMobile'
	pageName="Refund Request"
	(navigateBack)="handleNavigationBack()"
>
</app-my-account-header>
<div class="g-account-container">
	<div
		class="g-account-content h-100"
		[ngClass]="{ 'l-mobile-content': isMobile, 'l-web-content': !isMobile }"
	>
		<app-web-content-header
			class="w-100"
			[headerContent]="headerContent"
			[isMobile]="isMobile"
			[id]="purchaseId"
			[customDescription]="customDescription"
			[reference]="reference"
		></app-web-content-header>
		<div
			class="l-content-container"
			[ngClass]="{ 'mobile': isMobile, 'web': !isMobile }"
			*ngIf="!isLoading && !isTicketFetchLoading; else pageLoader"
		>
			<div class="l-card-container">
				<ng-container *ngIf="refundableTickets.length > 0; else noTickets">
					<div
						*ngFor="let ticket of refundableTickets"
						class="l-card"
					>
						<app-refund-card
							[id]="ticket.purchaseItemId"
							[data]="ticket"
							[image]="productImage"
							[isMobile]="isMobile"
							class="l-card-content"
							[ngClass]="{ 'l-full-width': refundableTickets.length === 1 }"
							(ticket)="handleCheckboxChange(ticket)"
						></app-refund-card>
					</div>
				</ng-container>
				<ng-template #noTickets>
					<div
						class="g-orange l-status-message"
						[ngClass]="{'l-mobile-status-message': isMobile}"
					>
						<span *ngIf="errorMessage">{{ errorMessage }}</span>
						<span *ngIf="!errorMessage">No Tickets Available</span>
					</div>
				</ng-template>
			</div>
			<mat-checkbox
				*ngIf="refundableTickets.length > 0"
				id='terms-confirmation-checkbox'
				class="mt-4"
				#confirm='ngModel'
				name='confirm'
				[(ngModel)]='confirmation'
				[disabled]="!selectedTickets.length"
				required
			>
				I confirm that I want to be refunded for these tickets and that the refund is irreversible.
				<span *ngIf="!isMobile">I am aware of the fees involved as described below.</span>
			</mat-checkbox>
		</div>
		<ng-template #pageLoader>
			<div class="l-page-loader">
				<mat-spinner></mat-spinner>
			</div>
		</ng-template>
	</div>
</div>
<div
	class="g-fixed-footer"
	*ngIf="!isLoading && !isTicketFetchLoading"
>
	<app-footer-button
		#footerButton
		class="w-100"
		[primaryText]="'REFUND TICKETS'"
		[isDisabled]="isFooterButtonDisabled"
		($primaryButton)="handlePrimaryButton()"
		($secondaryButton)="handleNavigationBack()"
		[secondaryText]="'BACK'"
		[isMobile]="isMobile"
		[primaryButtonId]="'refund-tickets-button'"
		[secondaryButtonId]="'back-button'"
	>
	</app-footer-button>
	<div
		class="l-disclaimer"
		*ngIf="!isMobile"
	>
		Note: All refunds are subject to a 4.9% cancellation fee (ex VAT) on the value of the tickets being
		cancelled
		and any purchase service fee paid is non-recoverable, as per our <span
			id='terms-link'
			(click)="handleTermsNavigation()"
			class="g-pointer fw-bold text-decoration-underline g-highlight"
		>terms and conditions</span>.
		By requesting a refund, you consent to agree to the cancellation charges.
	</div>
</div>