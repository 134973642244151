<div
	*ngIf="!isReportsLoading else pageLoading;"
	class="l-container"
>
	<div
		class="l-content"
		[ngClass]="{ 'mobile': isMobile }"
	>
		<h3> Overview Reports </h3>

		<div class="l-info-cards-container">
			<app-info-card
				class="l-revenue-info-card"
				title="Total Income"
				[data]="reportsData.totalEventIncome === 0 ? '-' : formatNumberWithSeparators(reportsData.totalEventIncome)"
				tooltipText="Total event income over all time"
				[currencySymbol]="currencySymbol"
				[isMobile]="isMobile"
			>
			</app-info-card>
			<app-info-card
				class="l-revenue-info-card"
				title="Total Tickets Sold"
				[data]="reportsData.totalTicketsSold === 0 ? '-' : formatNumberWithSeparators(reportsData.totalTicketsSold)"
				tooltipText="Total tickets sold (including all ticket types) over all time"
				[isMobile]="isMobile"
			>
			</app-info-card>
			<app-info-card
				class="l-revenue-info-card"
				title="Total Visits"
				[data]="reportsData.totalVisits === 0 ? '-' : formatNumberWithSeparators(reportsData.totalVisits)"
				tooltipText="Total event page visits over all time"
				[isMobile]="isMobile"
			>
			</app-info-card>
			<app-info-card
				class="l-revenue-info-card"
				title="Conversion Rate"
				[data]="reportsData.totalVisits === 0 ? 'N/A' : reportsData.conversionRate.toString() + '%'"
				tooltipText="The percentage of event page visits that converted to a sale over all time"
				[isMobile]="isMobile"
			>
			</app-info-card>
		</div>

		<app-overview-reports-filters
			[ticketTypesFilterOptions]="ticketTypesDropdownOptions"
			[scheduleItems]="scheduleItems"
			[salesFrom]="salesDataMinDate"
			[salesTo]="salesDataMaxDate"
			[eventCreationDateString]="reportsData.startDate"
			[eventEndDateString]="reportsData.endDate"
			(breakdownRangeFilterChange)="filterGraphDataByDateRange($event)"
			(scheduleItemFilterChange)="onScheduleItemFilterChange($event)"
		></app-overview-reports-filters>

		<div class="l-select-types-container">
			<div
				class="input-group mb-3 l-max-width"
				[ngClass]="{ 'w-100': isMobile, 'w-50': !isMobile }"
			>
				<label class="g-form-label">Select Ticket Types</label>
				<mat-select
					id='ticket-types-select'
					class="form-control w-100"
					#ticketTypesSelect="ngModel"
					name="ticketTypes"
					placeholder="Please Select Ticket Types"
					[(ngModel)]="selectedTicketTypes"
					(selectionChange)="updateSelectedTicketTypes($event.value)"
					required
					multiple
					[compareWith]="compareTicketTypes"
				>
					<mat-option>
						<ngx-mat-select-search
							ngModel
							(ngModelChange)="filterTicketTypes($event)"
							name="ticketTypesSelect"
							#ticketTypesSearch="ngModel"
							placeholderLabel="Select your ticket types..."
							noEntriesFoundLabel="No matching ticket types found"
						>
						</ngx-mat-select-search>
					</mat-option>

					<mat-option
						*ngFor="let type of filteredTicketTypes"
						[value]="type"
					>
						{{ type.name }}
					</mat-option>
				</mat-select>
			</div>
			<div class="l-select-all-container">
				<mat-checkbox
					class="l-select-all"
					(change)="toggleSelectAll($event)"
					[checked]="isAllSelected()"
					id="select-all-toggle"
				>
					Select All
				</mat-checkbox>
			</div>
		</div>

		<div
			*ngIf="eventIncomeGraphPoints"
			class="l-graphs-container"
		>
			<app-chart
				*ngIf="eventIncomeGraphPoints"
				class="l-chart"
				[ngClass]="{'l-mobile-padding': isMobile}"
				[chartData]='eventIncomeGraphPoints'
				[totalValue]='reportsData.totalEventIncome'
				[isMobile]='isMobile'
				[appliedFilterText]="appliedFilterText"
				[isCurrency]="true"
				[currencySymbol]="currencySymbol"
				[currentTicketTypeFilter]="currentTicketTypeFilter"
				title="Event Income"
			>
			</app-chart>

			<app-chart
				*ngIf="ticketTypesSoldGraphPoints"
				class="l-chart"
				[ngClass]="{'l-mobile-padding': isMobile}"
				[chartData]='ticketTypesSoldGraphPoints'
				[totalValue]='reportsData.totalTicketsSold'
				[isMobile]='isMobile'
				[appliedFilterText]="appliedFilterText"
				[currentTicketTypeFilter]="currentTicketTypeFilter"
				title="Tickets Sold"
			>
			</app-chart>

			<app-chart
				*ngIf="pageVisitsGraphPoints"
				class="l-chart"
				[ngClass]="{'l-mobile-padding': isMobile}"
				[chartData]='pageVisitsGraphPoints'
				[totalValue]='reportsData.totalVisits'
				[isMobile]='isMobile'
				title="Event Page Visits"
			>
			</app-chart>
		</div>

		<div
			*ngIf="isEventFinished"
			class="l-info-cards-container"
		>
			<app-info-card
				class="l-ratings-info-card"
				title="Average Rating"
				[data]="reportsData.totalReviews === 0 ? 'N/A' : reportsData.averageRating.toString()+ '/5'"
				[isMobile]="isMobile"
			>
			</app-info-card>
			<app-info-card
				class="l-ratings-info-card"
				title="Number of Reviews"
				[data]="reportsData.totalReviews === 0 ? '-' : reportsData.totalReviews.toString()"
				[isMobile]="isMobile"
			>
			</app-info-card>
		</div>
	</div>
</div>
<ng-template #pageLoading>
	<div class="l-page-loader">
		<mat-spinner></mat-spinner>
	</div>
</ng-template>