import { EventEmitter, Injectable } from '@angular/core';
import { FormService } from '@app/services/form/form.service';
import { FormValidators, FormSchemeControls } from '@app/models/common.model';
import { requiredValidator } from '@app/shared/form-field/form-validators';
import { UntypedFormGroup, UntypedFormArray, AbstractControl } from '@angular/forms';
import { DraftEventTab,
		 DraftEventTabContent,
		 DraftEventTabContentForm,
		 DraftEventTabForm,
		 EventTabType,
		 TabContentImage } from '@app/models/event.model';
import { Subscription, takeUntil } from 'rxjs';
import { StoreService } from '@app/services/store/store.service';
import { EventActionsConstants } from '@app/store/actions/event/event.actions.constants';
import config from '@app/config';

@Injectable()
export class TabbedContentService {
	eventTabTypeEnum = EventTabType;
	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private formService: FormService,
		private store: StoreService
	) { }

	createTabContentForm(initialValues?: Partial<DraftEventTab>) {
		const formValues = new DraftEventTabForm();

		const validatorsConfig: FormValidators<Partial<DraftEventTabForm>> = {
			name: [requiredValidator()],
			type: [requiredValidator()],
		};

		const formConfig: Partial<FormSchemeControls<DraftEventTabForm>> = this.formService.createFormControls({
			formValues,
			initialValues,
			validatorsConfig,
		});

		if (initialValues && initialValues.type) {
			formConfig.type.disable();
		};
		const tabContentConfig = (initialValues ? initialValues.content : []).map((item, index) =>
			this.createTabContentItemForm(item, index));
		formConfig.content = this.formService.formBuilder.array(tabContentConfig);
		return this.formService.formBuilder.group(formConfig);
	}

	createTabContentItemForm(initialValues?: Partial<DraftEventTabContent>, index: number = 0) {
		const formValues = new DraftEventTabContentForm();
		formValues.contentUid = new Date().getTime() + index;
		const validatorsConfig: FormValidators<Partial<DraftEventTabContentForm>> = {
		};

		const formConfig: Partial<FormSchemeControls<DraftEventTabContentForm>> = this.formService.createFormControls({
			formValues,
			initialValues,
			validatorsConfig,
		});

		return this.formService.formBuilder.group(formConfig);
	}

	getAddingContentValidation(form: UntypedFormGroup) {
		const scheduleType = form && form.get('type').value === EventTabType.Schedule;
		const newsType = form && form.get('type').value === EventTabType.News;
		const attributesType = form && form.get('type').value === EventTabType.Attributes;
		const mixedContentType = form && form.get('type').value === EventTabType.MixedContent;
		if (form) {
			const controls = (form.get('content') as UntypedFormArray).controls;
			controls.forEach(c => {
				if (scheduleType) {
					c.get('name').setValidators(requiredValidator());
					c.get('name').updateValueAndValidity();
					c.get('dateFrom').setValidators(requiredValidator());
					c.get('dateFrom').updateValueAndValidity();
					c.get('dateTo').setValidators(requiredValidator());
					c.get('dateTo').updateValueAndValidity();
				};
				if (newsType) {
					c.get('name').setValidators(requiredValidator());
					c.get('name').updateValueAndValidity();
				};
				if (attributesType) {
					c.get('name').setValidators(requiredValidator());
					c.get('name').updateValueAndValidity();
				};
				if (mixedContentType) {
					c.get('name').setValidators(requiredValidator());
					c.get('name').updateValueAndValidity();
				};
			});
		};
	}

	setValidatorsOnAdd(form: UntypedFormGroup): Subscription {
		return form && form.get('type').valueChanges
			.pipe(takeUntil(this.destroyed$)).subscribe(type => {
				if (type && type === EventTabType.Content) {
					this.getTabContentControls(form).forEach(c => {
						c.get('name').setValidators(requiredValidator());
						c.get('name').updateValueAndValidity();
					});
				} else if (type && type === EventTabType.Schedule) {
					this.getTabContentControls(form).forEach(c => {
						c.get('dateFrom').setValidators(requiredValidator());
						c.get('dateFrom').updateValueAndValidity();
						c.get('dateTo').setValidators(requiredValidator());
						c.get('dateTo').updateValueAndValidity();
						c.get('name').setValidators(requiredValidator());
						c.get('name').updateValueAndValidity();
					});
				} else if (type && type === EventTabType.News) {
					this.getTabContentControls(form).forEach(c => {
						c.get('name').setValidators(requiredValidator());
						c.get('name').updateValueAndValidity();
					});
				} else if (type && type === EventTabType.Attributes) {
					this.getTabContentControls(form).forEach(c => {
						c.get('name').setValidators(requiredValidator());
						c.get('name').updateValueAndValidity();
					});
				} else if (type && type === EventTabType.MixedContent) {
					this.getTabContentControls(form).forEach(c => {
						c.get('name').setValidators(requiredValidator());
						c.get('name').updateValueAndValidity();
					});
				}
			});
	}

	onSingleImageUpload(tabContentImage: TabContentImage, content: UntypedFormGroup) {
		this.store.dispatch({
			type: EventActionsConstants.TABBED_CONTENT_UPLOAD_IMAGE,
			payload: {
				eventId: tabContentImage.eventId,
				imageType: tabContentImage.imageType,
				imageData: tabContentImage.imageData,
				replace: true,
				tabContentUid: content.value.contentUid,
				imageString: tabContentImage.imageString,
			},
		});
		content.markAsTouched();
	}

	onImageGalleryUpload(tabContentImage: TabContentImage, form: UntypedFormGroup) {
		this.store.dispatch({
			type: EventActionsConstants.TABBED_CONTENT_UPLOAD_IMAGE,
			payload: {
				eventId: tabContentImage.eventId,
				imageType: tabContentImage.imageType,
				imageData: tabContentImage.imageData,
				replace: false,
				imageString: tabContentImage.imageString,
			},
		});
		form.markAsTouched();
	}

	onImageRemove(content: UntypedFormGroup) {
		content.patchValue({
			originalImage: null,
			imageString: null,
			thumbnail: null,
			pictureId: 0,
		});
		content.markAsTouched();
	}

	getImagePreview(tabContent: UntypedFormGroup) {
		return tabContent.value.originalImage
			? `${config.baseURL}${tabContent.value.originalImage}`
			: tabContent.value.thumbnail && tabContent.value.pictureId !== 0 ? `${config.baseURL}${tabContent.value.thumbnail}`
				: '';
	}

	getTabContentControls(form: UntypedFormGroup): AbstractControl[] {
		return (form.get('content') as UntypedFormArray).controls;
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}

}
