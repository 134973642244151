/// <reference types="@types/facebook-js-sdk" />
import { Injectable, InjectionToken, Inject, NgModule, FactoryProvider } from '@angular/core';
import { FacebookUser, FacebookUserEvent, FacebookUserPage } from '@app/models/event.model';
import config from '@app/config';

export const Facebook = new InjectionToken<facebook.FacebookStatic>('Facebook');

export function FacebookFactory() {
	const fb: facebook.FacebookStatic = window['FB'];
	if (fb) {
		fb.init({
			appId: config.facebookAppId,
			autoLogAppEvents: true,
			xfbml: true,
			version: 'v3.2',
		});
	}
	return fb;
}

export const FacebookProvider: FactoryProvider = {
	provide: Facebook,
	useFactory: FacebookFactory,
};

export enum FacebookErrorCode {
	AUTH_FAILED,
	USER_INFO_FAILED,
	PERMISSIONS_NOT_GRANTED,
	UNEXPECTED,
	EVENTS_FETCHING_FAILED,
	PAGES_FETCHING_FAILED,
}

export class FacebookError extends Error {
	constructor(public code: FacebookErrorCode) {
		super();
	}
}

@Injectable()
export class FacebookService {
	static readonly pagePermissions = [
		'pages_manage_posts',
		// 'pages_show_list', 'pages_manage_engagement', 'pages_manage_posts', 'pages_read_engagement', 'pages_read_user_content',
	];

	static readonly userPermissions = [
		'email',
	];

	constructor(
		@Inject(Facebook) private facebook: facebook.FacebookStatic
	) { }

	share(
		link: string,
		cb?: (res: facebook.ShareDialogResponse) => void
	) {
		this.facebook.ui({
			method: 'share',
			href: link,
		}, cb);
	}

	private getLoginStatus = (): Promise<facebook.StatusResponse> => (
		new Promise((resolve) => {
			this.facebook.getLoginStatus(resolve, true);
		})
	);

	private authenticate = (): Promise<facebook.StatusResponse> => (
		new Promise(resolve => {
			const scope = `${FacebookService.pagePermissions.join()},${FacebookService.userPermissions.join()}`;
			this.facebook.login(
				resolve,
				{
					scope,
					auth_type: 'rerequest',
					return_scopes: true,
				}
			);
		})
	);

	private getUserInfo = (authResponse: facebook.AuthResponse): Promise<FacebookUser> => (
		new Promise((resolve, reject) => {
			this.facebook.api(
				'/me',
				{
					fields: 'picture,name,email',
				},
				(response: { name: string; picture: { data: { url: string } }; email: string }) => {
					if (response) {
						const user: FacebookUser = {
							id: authResponse.userID,
							name: response.name,
							token: authResponse.accessToken,
							connected: true,
							arePermissionsGranted: true,
							imageUrl: response.picture && response.picture.data
								? response.picture.data.url
								: null,
							email: response.email,
						};

						resolve(user);
					} else {
						reject(new FacebookError(FacebookErrorCode.USER_INFO_FAILED));
					}
				}
			);
		})
	);

	private getUserEvents = (): Promise<FacebookUserEvent[]> => (
		new Promise((resolve, reject) => {
			this.facebook.api(
				'/me/events',
				{
					type: 'created',
				},
				(response: { data: any[]; paging: any }) => {
					if (response) {
						resolve(response.data);
					} else {
						reject(new FacebookError(FacebookErrorCode.EVENTS_FETCHING_FAILED));
					}
				}
			);
		})
	);

	private getUserPages = (): Promise<FacebookUserPage[]> => (
		new Promise((resolve, reject) => {
			this.facebook.api(
				'/me/accounts',
				{},
				(response: { data: any[]; paging: any }) => {
					if (response) {
						resolve(response.data);
					} else {
						reject(new FacebookError(FacebookErrorCode.PAGES_FETCHING_FAILED));
					}
				}
			);
		})
	);

	login = (forceLogin?: boolean): Promise<{ user: FacebookUser; events: FacebookUserEvent[]; pages: FacebookUserPage[] }> => (
		new Promise<{ user: FacebookUser; events: FacebookUserEvent[]; pages: FacebookUserPage[] }>(async (res, rej) => {
			try {
				let authResponse: facebook.AuthResponse;
				const loginStatus = forceLogin
					? null
					: await this.getLoginStatus();

				if (loginStatus && loginStatus.status === 'connected') {
					authResponse = loginStatus.authResponse;
				} else {
					const authStatus = await this.authenticate();
					if (authStatus && authStatus.status === 'connected' && authStatus.authResponse.grantedScopes) {
						const arePermissionsGranted =
							FacebookService.pagePermissions
								.every(per => authStatus.authResponse.grantedScopes.includes(per))
							&& FacebookService.userPermissions
								.every(per => authStatus.authResponse.grantedScopes.includes(per));

						if (arePermissionsGranted) {
							authResponse = authStatus.authResponse;
						} else {
							throw new FacebookError(FacebookErrorCode.PERMISSIONS_NOT_GRANTED);
						}
					} else {
						throw new FacebookError(FacebookErrorCode.AUTH_FAILED);
					}
				}

				const user = await this.getUserInfo(authResponse);
				const events = await this.getUserEvents();
				const pages = await this.getUserPages();
				res({
					user,
					events,
					pages,
				});
			} catch (error) {
				if (error.code !== undefined) {
					rej(error);
				} else {
					const unexpectedError = new FacebookError(FacebookErrorCode.UNEXPECTED);
					unexpectedError.message = error.message;
					rej(unexpectedError);
				}
				rej(error);
			}
		})
	);
}

@NgModule({
	providers: [
		FacebookProvider,
		FacebookService,
	],
}) export class FacebookModule { }
