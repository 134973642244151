<div class="l-container" [class.l-container-mobile]='data.isMobile'>
	<h3 class="mb-3">Edit Invoice</h3>
	<ng-container *ngIf="!isLoading; else showLoading">
		<form #existingInvoiceForm="ngForm" [formGroup]="invoiceForm" class="my-5 d-flex gap-3 flex-column">
			<div class="w-100" *ngFor="let line of formControlNames; let i = index">
				<label class="g-form-label">Billing Line {{i + 1}}</label>
				<input id="invoice-line-input" [formControl]="invoiceForm.get(line)" type="text" name="Line{{i + 1}}" class="form-control w-100"
					[placeholder]="'e.g. '+ placeholders[i] " />
			</div>
		</form>
	</ng-container>
	<ng-template #showLoading>
		<div class='l-loading'>
			<mat-spinner></mat-spinner>
		</div>
	</ng-template>

	<div class='l-buttons-container'>
		<button id="cancel-invoice-edit" class="g-secondary-button border-0" (click)="handleCancel()"
			[disabled]="isLoading || isUpdateLoading">CANCEL</button>
		<button id="save-invoice-edit" class="g-primary-button" (click)="handleOnSave()"
			[disabled]="isButtonDisabled"
			[class.disabled]="isButtonDisabled">
			<div *ngIf='!isUpdateLoading; else requestLoading'>SAVE</div>
			<ng-template #requestLoading><mat-spinner class='g-button-spinner'></mat-spinner></ng-template>

		</button>
	</div>
</div>
