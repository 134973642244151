import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-edit-event-alert',
	templateUrl: './edit-event-alert.component.html',
	styleUrls: ['./edit-event-alert.component.sass'],
})

export class EditEventAlertComponent {
	@Input() isMobile = false;
}

