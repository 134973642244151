import { Injectable } from '@angular/core';
import { StoreService } from '@app/services/store/store.service';
import { isAdmin as isAdminSelector } from '@app/store/selectors/user.selector';
import { eventFeatureAccess } from '@app/store/selectors/event.selector';
import { ACLItemAccess, ACLItem } from '@app/models/url.model';
import { tap, map, withLatestFrom, take } from 'rxjs/operators';
import { HelpArticle } from '@app/models/help.model';
import { Subject, BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { EventPermission, EventMetadataPermission } from '@app/models/event-metadata.model';
import { UserEventAccess } from '@app/models/event.model';

export interface IFeatureKey {
	featureAccess: ACLItemAccess;
}

export interface PermissionSnapshot {
	featureKey: ACLItem;
	helpArticle: HelpArticle;
	access: FeatureKeyAccessPermission;
	isAdmin: boolean;
	accessType: ACLItemAccess;
	isAccessGranted: boolean;
}

export interface FeatureKeyAccessPermission {
	isOwner: boolean;
	permission: EventPermission[];
	allPermissions: EventMetadataPermission;
}

@Injectable()
export class PermissionService {
	private permissionStream = new Subject<boolean>();
	private featureKeyStream = new BehaviorSubject<ACLItem>(null);
	private helpArticleStream = new BehaviorSubject<HelpArticle>(null);
	permissionStream$ = this.permissionStream.asObservable();
	featureKeyStream$ = this.featureKeyStream.asObservable();
	helpArticleStream$ = this.helpArticleStream.asObservable();

	constructor(
		private store: StoreService
	) { }

	createPermissionCheck(customFeature?: ACLItem): Observable<PermissionSnapshot> {
		const access$ = this.store
			.select(eventFeatureAccess(customFeature || this.featureKeyStream.value));

		const isAdmin$ = this.store
			.select(isAdminSelector());

		return access$
			.pipe(
				withLatestFrom(isAdmin$),
				map(([access, isAdmin]: [FeatureKeyAccessPermission, boolean]) => {
					const accessType = this.getAccessType(access, isAdmin);
					return {
						featureKey: customFeature || this.featureKeyStream.value,
						helpArticle: this.helpArticleStream.value,
						access,
						isAdmin,
						accessType,
						isAccessGranted: this.isAccessGranted(accessType),
					};
				}),
				tap(value => {
					if (!customFeature) {
						this.setAccess(value.isAccessGranted);
					}
				})
			);
	}

	setAccess(access: boolean) {
		this.permissionStream.next(access);
	}

	setKey(key: ACLItem) {
		this.featureKeyStream.next(key);
	}

	setHelpArticle(key: HelpArticle) {
		this.helpArticleStream.next(key);
	}

	getAccessType(access: FeatureKeyAccessPermission, isAdmin: boolean) {
		if (access.isOwner || isAdmin) {
			return ACLItemAccess.WRITE;
		} else {
			if (access.permission && access.permission.length) {
				if (access.permission.includes(ACLItemAccess.WRITE)) {
					return ACLItemAccess.WRITE;
				}
				if (access.permission.includes(ACLItemAccess.READ)) {
					return ACLItemAccess.READ;
				}
				return ACLItemAccess.NOACCESS;
			} else {
				return ACLItemAccess.NOACCESS;
			}
		}
	}

	isAccessGranted(access: ACLItemAccess) {
		return access === ACLItemAccess.READ || access === ACLItemAccess.WRITE;
	}

	getAllUserPermissions(permissions: EventMetadataPermission) {
		return Object.keys(permissions)
			.map(key => ({
				title: permissions[key].name,
				access: permissions[key].access.includes(ACLItemAccess.WRITE) ? 'Modify' : 'Read-Only',
			}));
	}

	getUserAccess(customFeature?: ACLItem): Observable<UserEventAccess> {
		if (!this.featureKeyStream.value) {
			return of({
				isAccessGranted: true,
			});
		}
		const access$ = this.store.select(eventFeatureAccess(customFeature || this.featureKeyStream.value));
		const isAdmin$ = this.store.select(isAdminSelector());

		return combineLatest([access$, isAdmin$]).pipe(
			take(1),
			map(([access, isAdmin]: [FeatureKeyAccessPermission, boolean]) => {
				const accessType = this.getAccessType(access, isAdmin);
				return {
					isAccessGranted: this.isAccessGranted(accessType),
					accessType,
				};
			})
		);
	}

}
