<header class="header">
	<a
		href="/"
		class="header-logo-link"
	>
		<img
			class="header-logo"
			[src]="QUICKET_LOGO"
			alt="logo"
		>
	</a>
	<div
		*ngIf="withSearch"
		class="l-search-container"
		[ngClass]="{ 'l-mobile-search-container': isMobile }"
	>
		<mat-icon class="l-icon">search</mat-icon>
		<input
			type="text"
			class="form-control l-search"
			placeholder=""
			id="search"
		/>
	</div>
	<nav
		class="l-nav-container"
		id="header-dropdown-menu"
	>
		<button
			class="l-dropdown-nav"
			#button
			[matMenuTriggerFor]="dropdown"
			#dropdownTrigger="matMenuTrigger"
			(mouseenter)="handleButtonEnter(dropdownTrigger)"
			(mouseleave)="handleButtonLeave(dropdownTrigger)"
		>
			<div class="l-nav-item">
				<span class="l-user">{{ (user$ | async).info.userName }}</span>
				<i class="fa fa-chevron-down"></i>
			</div>
		</button>

		<mat-menu
			#dropdown="matMenu"
			direction="down"
			yPosition="below"
			class="l-nav-header-menu"
		>
			<div
				(mouseenter)="handleMenuEnter()"
				(mouseleave)="handleMenuLeave(dropdownTrigger)"
			>
				<ng-container *ngFor="let item of navMenuItems">
					<li
						[class]="'l-menu-item ' + (item.extraClass || '')"
						mat-menu-item
					>
						<a
							class="l-menu-link"
							[id]="formatId(item.title, 'header-nav')"
							[href]="item.href"
						>{{ item.title }}
						</a>
					</li>
				</ng-container>
			</div>
		</mat-menu>
	</nav>
</header>