import { ACLItemAccess } from '@app/models/url.model';
import { SchemeID } from '@app/models/dataSchema.model';
import { ProductCreationStep } from './event.model';
import { ProductStatus } from '@app/models/event.model';
import { ProductType } from './product.model';
import { ScheduleItem } from './schedule.model';
import { ChartInput, ReportByTicketType } from './organiser.model';

export class EventMetadata {
	name: string = null;
	permissions: EventMetadataPermission = null;
	id: number = null;
	accountingVersion: number = null;
	encrypt: string = null;
	isOwner = false;
	featureKey = null;
	creationStep: ProductCreationStep;
	productStatus: ProductStatus;
	isNewPageVersion: boolean;
	productType: ProductType;
	hasStreaming: boolean;
	currencyISO: string;
	email: string;
	fullName: string;
	phone: string;
	userId: SchemeID;
	organiserId: SchemeID;
	organiserName: string;
	isOngoing: boolean;
	scheduleItems: ScheduleItem[];
	directDistributionEnabled: boolean;
	directDistributionId: SchemeID;
	covidComplianceEnabled: boolean;
	hasOnlineContent: boolean;
	hasVendors: boolean;
	usesPayfast: boolean;
	isSeated: boolean;
	dateFrom: Date;
}

export class EventMetadataPermission {
	[key: string]: {
		key: string;
		name: string;
		access: EventPermission[];
	}
}

export class EventCopyInfo {
	id: number = null;
	name: string = null;
	ianaTimezone: string = null;
	hasSchedule: boolean = null;
	tickets: {
		price: string;
		name: string;
		id: number;
	}[] = [];
	questions: {
		id: number;
		name: string;
	}[] = [];
}

export class EventCopy {
	id?: SchemeID;
	name: string = null;
	startDate: string = null;
	ianaTimezone: string = null;
	hasSchedule: boolean = null;
	endDate: string = null;
	tickets: {
		[key: string]: {
			id: number;
			name: string;
			price: string;
			checked: boolean;
		};
	} = {};
	questions: {
		[key: string]: {
			id: number;
			name: string;
			checked: boolean;
		};
	} = {};
	copyMerchendise = true;
	copyTabbedContent = true;
	copyTicketGroups = true;
	copyCustomCss = true;
	copyImage = true;
	copyTheme = true;
}

export interface EventDashboardReports {
	startDate: string;
	endDate: string;
	totalEventIncome: number;
	totalTicketsSold: number;
	totalVisits: number;
	reportByTicketType: ReportByTicketType[];
	pageVisits: ChartInput[];
	recentOrders: RecentOrders[];
	conversionRate: number;
	averageRating: number;
	totalReviews: number;
}

export interface RecentOrders {
	email: string;
	purchaseId: SchemeID;
	date: Date;
	orderAmount: number;
	numberOfTickets: number;
	subscriptionOption: string;
	link: string;
}

export type EventPermission = ACLItemAccess.READ | ACLItemAccess.WRITE;
