import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Question } from '@app/models/user.model';
@Component({
	selector: 'app-terms-and-conditions',
	templateUrl: './terms-and-conditions.component.html',
	styleUrls: ['./terms-and-conditions.component.sass'],
})
export class TermsAndConditionsComponent {
	@Input() isMobile: boolean;
	@Input() inputFormControl: FormControl;
	@Input() label: string;
	@Input() control: Question;
	@Input() isRequired = false;
}
