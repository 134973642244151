import { NgModule } from '@angular/core';
import { EventDetailsComponent } from '@app/features/control-panel/details/event-details.component';
import { EventSharedModule } from '@app/features/event-shared/event-shared.module';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
	imports: [
		EventSharedModule,
		SharedModule,
	],
	exports: [
		EventDetailsComponent,
	],
	declarations: [
		EventDetailsComponent,
	],
})
export class EventDetailsModule { }
