<div class='l-container' [ngClass]="{ 'mobile': isMobile}">
	<div>
		<label class="g-form-label">Contact List Name</label>
		<input id="contact-list-name" type="text" [(ngModel)]="contactListName" class="form-control w-100 input-group" #manualContactListName="ngModel" required>
		<div class="g-error-input" *ngIf=" manualContactListName.touched || manualContactListName.dirty">
			<div *ngIf="manualContactListName.errors?.required">Contact list name is required.</div>
		</div>
	</div>
	<form [formGroup]="manualContactsForm">
		<div class="l-table-container" *ngIf="manualContactsForm && manualContactsForm.length">
			<table mat-table [dataSource]="manualContactsForm.controls" #manualContacts class="web l-table-content">
			<ng-container matColumnDef="name">
				<th mat-header-cell *matHeaderCellDef> Name </th>
				<td mat-cell *matCellDef="let contact">
						<input id="contact-name" type="text" class="form-control w-100 l-input" placeholder="John" [formControl]="fromForm(contact,'name')">
				</td>
			</ng-container>
			<ng-container matColumnDef="surname">
				<th mat-header-cell *matHeaderCellDef> Surname </th>
				<td mat-cell *matCellDef="let contact">
						<input id="contact-surname" type="text" class="form-control w-100 l-input" placeholder="Doe" [formControl]="fromForm(contact, 'surname')">
				</td>
			</ng-container>
			<ng-container matColumnDef="email">
				<th mat-header-cell *matHeaderCellDef> Email <span class='g-orange'>*</span> </th>
				<td mat-cell *matCellDef="let contact">
						<input id="contact-email" type="text" class="form-control w-100 l-input" [ngClass]="{ 'l-error': (fromForm(contact, 'email').invalid && (fromForm(contact, 'email').dirty || fromForm(contact, 'email').touched))}" placeholder="johndoe@email.com" name=email  [formControl]="fromForm(contact,'email')">
				</td>
			</ng-container>
			<ng-container matColumnDef="delete">
				<th mat-header-cell *matHeaderCellDef></th>
					<td mat-cell *matCellDef="let contact; let i = index"> <mat-icon id="delete-contact"
							(click)="deleteContactRow(i)" class='g-pointer'>delete_outline</mat-icon></td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
		</table>
	</div>
	<button id="add-contact" class="g-secondary-button l-button mt-3" (click)="addContactRow()">ADD
		CONTACT<mat-icon>add</mat-icon></button>
	</form>
	<div class="mt-5 mb-3" [ngClass]="{ 'l-consent-mobile': isMobile}">
		<mat-checkbox [(ngModel)]="consentChecked">
			I agree that I have permission to contact these people
		</mat-checkbox>
	</div>	
</div>
<div class="l-sticky-bar">
	<app-footer-button
		id="send-invites-button"
		primaryText="SEND INVITES"
		[isLoading]="isButtonLoading"
		($primaryButton)="handleSendInvites()"
		[isDisabled]="manualContactsForm.invalid || isButtonLoading || manualContactListName.invalid || !consentChecked"
		[isMobile]="isMobile"
		[fullWidth]="true"
	></app-footer-button>
</div>
