<div [ngClass]="{'p-3 mt-3': isMobile, 'l-desktop-modal px-5': !isMobile}">
	<h3>Add dates to your event</h3>
	<form
		id="multi-schedule-form"
		*ngIf="form"
		[formGroup]="form"
		class="l-form-container"
		[ngClass]="{ 'mt-4': isMobile }"
		id="multi-schedule-form"
	>
		<label class="g-form-label pt-3">Event repeats<span class="g-orange"> *</span></label>
		<mat-select
			id="event-repeats-options"
			[formControl]="fromForm('repeats')"
			class="form-control w-100"
			placeholder="e.g. Weekly"
		>
			<mat-option
				*ngFor="let option of repeatsOptions"
				[value]="option.value"
			>
				{{ option.label }}
			</mat-option>
		</mat-select>
		<small
			class="g-error-input"
			*ngIf="isControlInvalid(fromForm('repeats'))"
		>
			{{getError(fromForm('repeats').errors)}}
		</small>
		<div [ngSwitch]="fromForm('repeats').value">
			<div *ngSwitchCase="'WEEKLY'">
				<label class="g-form-label mt-2">Every<span class="g-orange"> *</span></label>
				<mat-select
					id="event-repeats-weekly"
					[formControl]="fromForm('weeklyRepeat')"
					class="form-control w-100"
					placeholder="e.g. Monday, Friday"
					multiple
				>
					<mat-option
						*ngFor="let option of daysOfWeekOptions"
						[value]="option.value"
					>
						{{ option.label }}
					</mat-option>
				</mat-select>
				<small
					class="g-error-input"
					*ngIf="isControlInvalid(fromForm('weeklyRepeat'))"
				>
					{{getError(fromForm('weeklyRepeat').errors)}}
				</small>
			</div>
			<div *ngSwitchCase="'MONTHLY'" class="l-monthly-container">
				<ng-container *ngIf="form.get('isMonthlyByDayOfMonth').value">
					<label class="g-form-label">Every<span class="g-orange"> *</span></label>
					<mat-select
						id="event-repeats-monthly"
						[formControl]="fromForm('monthlyDayOfMonth')"
						class="form-control w-100"
						placeholder="e.g. n-th day of month"
					>
						<mat-option
							*ngFor="let option of monthlyDayOfMonthOptions"
							[value]="option.value"
						>
							{{ option.label }}
						</mat-option>
					</mat-select>
					<small
						class="g-error-input"
						*ngIf="isControlInvalid(fromForm('monthlyDayOfMonth'))"
					>
						{{getError(fromForm('monthlyDayOfMonth').errors)}}
					</small>
				</ng-container>
				<ng-container *ngIf="!form.get('isMonthlyByDayOfMonth').value">
					<label class="g-form-label">Every<span class="g-orange"> *</span></label>
					<mat-select
						id="event-repeats-monthly-day-of-month"
						[formControl]="fromForm('monthlyDayOfWeek')"
						class="form-control w-100"
						placeholder="n-th day of month"
					>
						<mat-option
							*ngFor="let option of monthlyDayOfWeekOptions"
							[value]="option.value"
						>
							{{ option.label }}
						</mat-option>
					</mat-select>
					<small
						class="g-error-input"
						*ngIf="isControlInvalid(fromForm('monthlyDayOfWeek'))"
					>
						{{getError(fromForm('monthlyDayOfWeek').errors)}}
					</small>
					<label class="g-form-label">Day Of The Week<span class="g-orange"> *</span></label>
					<mat-select
						id="event-repeats-monthly-day-of-week"
						[formControl]="fromForm('weeklyRepeat')"
						class="form-control w-100"
						placeholder="e.g. Monday, Friday"
					>
						<mat-option
							*ngFor="let option of daysOfWeekOptions"
							[value]="option.value"
						>
							{{ option.label }}
						</mat-option>
					</mat-select>
					<small
						class="g-error-input"
						*ngIf="isControlInvalid(fromForm('weeklyRepeat'))"
					>
						{{getError(fromForm('weeklyRepeat').errors)}}
					</small>
				</ng-container>
				<button
					id="change-monthly-repeat-option-button"
					class=" g-secondary-button l-day-of-button"
					(click)="toggleMonthDaySelection()"
				>Select by day of {{
					fromForm('isMonthlyByDayOfMonth').value ? 'week' : 'month' }}</button>
			</div>
		</div>
		<div class="l-container">
			<div class="l-date-time-container">
				<label class="g-form-label">At<span class="g-orange">*</span></label>
				<input
					id="start-time"
					class="form-control"
					matInput
					[formControl]="startAndEndTimeFormGroup.get('startTimeControl')"
					mask="Hh:m0"
					inputmode="numeric"
					[max]="fromForm('endTime').value"
					placeholder="HH:mm"
					(focus)="markTimeAsTouched('startTime')"
				>
				<small
					class="g-error-input"
					*ngIf="isControlInvalid(fromForm('startTime'))"
				>
					{{getError(fromForm('startTime').errors)}}
				</small>
			</div>
			<div class="l-date-time-container">
				<label class="g-form-label">To<span class="g-orange">*</span></label>
				<input
					id="end-time"
					class="form-control"
					matInput
					[formControl]="startAndEndTimeFormGroup.get('endTimeControl')"
					mask="Hh:m0"
					inputmode="numeric"
					[min]="fromForm('startTime').value"
					placeholder="HH:mm"
					(focus)="markTimeAsTouched('endTime')"
				>
				<small
					class="g-error-input"
					*ngIf="isControlInvalid(fromForm('endTime'))"
				>
					{{getError(fromForm('endTime').errors)}}
				</small>
			</div>
			<div class="l-select-container">
				<label class="g-form-label">On the<span class="g-orange"> *</span></label>
				<mat-select
					id="time-ending-day"
					[formControl]="fromForm('timeEndingDay')"
					class="form-control w-100"
					placeholder="n-th day"
				>
					<mat-option
						*ngFor="let option of timeEndingDayOptions"
						[value]="option.value"
					>
						{{ option.label }}
					</mat-option>
				</mat-select>
				<small
					class="g-error-input"
					*ngIf="isControlInvalid(fromForm('timeEndingDay'))"
				>
					{{getError(fromForm('weeklyRepeat').errors)}}
				</small>
			</div>
		</div>
		<div
			*ngIf="shortEventWarning"
			class="g-orange"
		>
			<mat-icon class="l-warning-icon">warning</mat-icon>
			<span class="l-warning-text">Be aware that your event duration is less than 30 minutes.</span>
		</div>
		<div class="l-container">
			<div class="l-date-time-container">
				<label class="g-form-label">{{fromForm('repeats').value !== eventRepeats.SINGLE_DATE ? 'Repeat from' :
					'On'}}<span class="g-orange">*</span></label>
				<input
					id="start-date"
					matInput
					[matDatepicker]="picker"
					[disabled]="false"
					placeholder="DD/MM/YYYY"
					(click)="picker.open()"
					class="form-control"
					[max]="fromForm('repeats').value !== eventRepeats.SINGLE_DATE ? fromForm('repeats').value : undefined"
					[formControl]="fromForm('startDate')"
					(dateChange)="handleStartDateChange($event)"
				>
				<mat-datepicker
					#picker
					[touchUi]="isMobile"
				>
					<mat-date-range-picker-actions>
						<button
							id="close-datepicker"
							class="g-secondary-button g-datepicker-button"
							matDatepickerCancel
						>CANCEL</button>
						<button
							id="apply-datepicker"
							class="g-primary-button g-datepicker-button"
							matDatepickerApply
						>APPLY</button>
					</mat-date-range-picker-actions>
				</mat-datepicker>
				<small
					class="g-error-input"
					*ngIf="isControlInvalid(fromForm('startDate'))"
				>
					{{getError(fromForm('startDate').errors)}}
				</small>
			</div>
			<div
				class="l-date-time-container"
				*ngIf="form.get('repeats').value !== eventRepeats.SINGLE_DATE"
			>
				<label class="g-form-label">Until<span class="g-orange">*</span></label>
				<input
					id="end-date"
					matInput
					[matDatepicker]="picker"
					[disabled]="false"
					placeholder="DD/MM/YYYY"
					(click)="picker.open()"
					class="form-control"
					[min]="fromForm('startDate').value !== eventRepeats.SINGLE_DATE ? fromForm('startDate').value : undefined"
					[formControl]="fromForm('endDate')"
					(dateChange)="handleEndDateChange($event)"
				>
				<mat-datepicker
					#picker
					[touchUi]="isMobile"
				>
					<mat-date-range-picker-actions>
						<button
							id="close-datepicker"
							class="g-secondary-button g-datepicker-button"
							matDatepickerCancel
						>CANCEL</button>
						<button
							id="apply-datepicker"
							class="g-primary-button g-datepicker-button"
							matDatepickerApply
						>APPLY</button>
					</mat-date-range-picker-actions>
				</mat-datepicker>
				<small
					class="g-error-input"
					*ngIf="isControlInvalid(fromForm('endDate'))"
				>
					{{getError(fromForm('endDate').errors)}}
				</small>
			</div>
		</div>
	</form>
	<div
		*ngIf="tooManyScheduleItems && form.pristine"
		class="g-orange pt-3 w-100"
	>
		<mat-icon class="l-warning-icon">warning</mat-icon>
		<span class="l-warning-text">Your event will have over one thousand schedule dates, please reduce this number to
			submit</span>
	</div>
	<div
		*ngIf="isEmptySchedule"
		class="g-orange pt-3 w-100"
	>
		<mat-icon class="l-warning-icon">warning</mat-icon>
		<span class="l-warning-text">Your schedule has no dates, please change your options to include a minimum of one
			date</span>
	</div>
	<div [ngClass]="{'pt-3 l-sticky-bar': isMobile, 'pt-3': !isMobile}">
		<app-footer-button
			[primaryText]="'SAVE'"
			($primaryButton)="submitMultiSchedule()"
			[isDisabled]="!isSubmitAllowed()"
			($secondaryButton)="handleCancel()"
			[secondaryText]="'CANCEL'"
			[isModal]="true"
			primaryButtonId="'add-schedule'"
			secondaryButtonId="'close-schedule-modal'"
		>
		</app-footer-button>
	</div>
</div>