import { Component, Input } from '@angular/core';
import { TopLinkCampaigns } from '@app/models/event.model';

@Component({
	selector: 'app-campaign-card',
	templateUrl: './campaign-card.component.html',
	styleUrls: ['./campaign-card.component.sass'],
})
export class CampaignCardComponent {
	@Input() data: TopLinkCampaigns;
	@Input() isMobile = false;
}
