import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { UploadContactsCSV } from '@app/store/actions/file/file.actions';
import { StoreService } from '@app/services/store/store.service';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs';
import { FileActionsConstants } from '@app/store/actions/file/file.actions.constants';
import * as userContactsSelector from '@app/store/selectors/userContacts.selector';
import { ContactsSource, UserContactsInviteBatch } from '@app/models/user.model';
import { CreateContactList } from '@app/store/actions/user/user.actions';
import { EventActionsConstants } from '@app/store/actions/event/event.actions.constants';

@Component({
	selector: 'app-upload-csv-file',
	templateUrl: './upload-csv-file.component.html',
	styleUrls: ['./upload-csv-file.component.sass'],
})
export class UploadCSVFileComponent implements OnInit {
	isContactsLoading = false;
	isButtonLoading = false;
	isButtonDisabled = true;
	consentChecked = false;
	contactListName = '';
	currentDate: Date = new Date();
	csvUpload = new UserContactsInviteBatch();
	readonly maxCSVFileSize = 5242880;
	customSuccessMessage: string;

	@Input() eventId: number;
	@Input() isMobile = false;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private datePipe: DatePipe,
		private store: StoreService,
		private actions$: Actions
	) { }

	ngOnInit() {
		this.csvUpload.contactListName = `CSV - ${this.datePipe.transform(this.currentDate, 'dd-MM-YYYY')}`;
		this.actions$
			.pipe(
				ofType(FileActionsConstants.UPLOAD_CONTACTS_CSV_SUCCESS, FileActionsConstants.UPLOAD_CONTACTS_CSV_FAILED),
				takeUntil(this.destroyed$)
			).subscribe(({ type }: { type: string }) => {
				this.isButtonLoading = false;
				if (type === FileActionsConstants.UPLOAD_CONTACTS_CSV_FAILED) {
					this.customSuccessMessage = null;
				}
			});

		this.store.select(userContactsSelector.userParsedContactsList()).pipe(takeUntil(this.destroyed$)).subscribe(contacts => {
			if (contacts) {
				this.csvUpload.contacts = contacts;
				this.isButtonDisabledCheck();
			}
		});
	}

	onCSVFileSelect({ files: [file] }: { files: File[] }) {
		this.isButtonLoading = true;
		this.customSuccessMessage = file.name ? file.name : 'File' + 'Uploaded!';
		this.store.dispatch(new UploadContactsCSV({ file }));
	}

	handleSendInvites() {
		this.isButtonLoading = true;
		this.actions$
			.pipe(
				ofType(EventActionsConstants.SEND_INVITES_SUCCESS, EventActionsConstants.SEND_INVITES_FAILED),
				takeUntil(this.destroyed$)
			).subscribe(() => {
				this.isButtonLoading = false;
				this.isButtonDisabled = true;
			});
		this.store.dispatch(
			new CreateContactList({
				name: this.csvUpload.contactListName,
				eventId: this.eventId,
				autoSendInvites: true,
				source: ContactsSource.CSV,
				contacts: this.csvUpload.contacts,
			})
		);
	}

	isButtonDisabledCheck() {
		this.isButtonDisabled = this.csvUpload.contactListName === '' || this.csvUpload.contacts.length === 0 || !this.consentChecked;
	}
}
