import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-incentivise-guests-modal',
	templateUrl: './incentivise-guests-modal.component.html',
	styleUrls: ['./incentivise-guests-modal.component.sass'],
})
export class IncentiviseGuestsModalComponent {
	label?: string = 'Incentive message';
	incentiveMessage = '';

	constructor (
		public dialogRef: MatDialogRef<IncentiviseGuestsModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data: {currentIncentive: string; isMobile: boolean}
	) { }

	ngOnInit() {
		if (this.data.currentIncentive) {
		  this.incentiveMessage = this.data.currentIncentive;
		}
	  }

	handleClose() {
		this.dialogRef.close();
	}

	handleSave() {
		this.dialogRef.close(this.incentiveMessage);
	}
}
