import { Component, EventEmitter, OnInit } from '@angular/core';
import { SearchAndFilter, SortOption } from '@app/models/filter-and-sort.model';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { StoreService } from '@app/services/store/store.service';
import { UserActionsConstants } from '@app/store/actions/user/user.actions.constants';
import { Actions, ofType } from '@ngrx/effects';
import { take, takeUntil } from 'rxjs/operators';
import * as userSelectors from '@app/store/selectors/user.selector';
import { UserSavedEvent } from '@app/models/user.model';
import { GetUserSavedEvents } from '@app/store/actions/user/user.actions';
import { InternalURLCreator, URLCreator } from '@app/services/url/url.dictionary';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MobileFilterBottomsheetComponent } from '@app/shared/mobile-filter-bottomsheet/mobile-filter-bottomsheet.component';
import { cloneDeep } from 'lodash';
import { HeaderContent, SideNavItems } from '@app/models/shared';
import { MY_ACCOUNT_HEADER, SIDENAV_ITEMS } from '@app/utils/consts';
import { Go } from '@app/store/actions/router/router.actions';

@Component({
	selector: 'app-saved-events-dashboard',
	templateUrl: './saved-events-dashboard.component.html',
	styleUrls: ['./saved-events-dashboard.component.sass'],
})
export class SavedEventsDashboardComponent implements OnInit {
	unfilteredEvents: UserSavedEvent[];
	filteredEvents: UserSavedEvent[];
	isMobile = false;
	isEventsLoading = true;
	numberAppliedFilters: number;
	currentSearchTerm = '';
	currentSort: SortOption;
	headerContent: HeaderContent = MY_ACCOUNT_HEADER['mySavedEvents'];

	currentRoute: SideNavItems = SIDENAV_ITEMS.savedEvents;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private breakpointService: BreakpointService,
		private store: StoreService,
		private actions$: Actions,
		private bottomSheet: MatBottomSheet
	) { }

	ngOnInit(): void {
		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});
		this.store.dispatch(new GetUserSavedEvents());

		this.store.select(userSelectors.userSavedEvents())
			.pipe(takeUntil(this.destroyed$)).subscribe((savedEvents) => {
				this.unfilteredEvents = savedEvents || [];
				this.filteredEvents = [...this.unfilteredEvents];
				this.handleSort(SortOption.MostRecent);
			});

		this.actions$.pipe(
			ofType(
				UserActionsConstants.GET_USER_SAVED_EVENTS_SUCCESS,
				UserActionsConstants.GET_USER_SAVED_EVENTS_FAILED
			),
			take(1)
		)
			.subscribe(() => {
				this.isEventsLoading = false;
			});
	}

	handleNavigationBack() {
		this.store.dispatch(new Go({ path: [InternalURLCreator.myAccount()] }));
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}

	handleSavedFilterAndSearch(searchAndFilter: SearchAndFilter) {
		const { searchTerm } = searchAndFilter;
		this.currentSearchTerm = searchTerm;
		this.filteredEvents = this.unfilteredEvents;

		this.numberAppliedFilters =
			this.currentSort ? 1 : 0;

		if (searchTerm) {
			const lowerCaseSearchTerm = searchTerm.toLowerCase();
			this.filteredEvents = this.filteredEvents.filter(event =>
				event.name.toLowerCase().includes(lowerCaseSearchTerm)
			);
		}

		this.handleSort(this.currentSort);
	}

	handleSort(sortOption: SortOption) {
		if (!sortOption) {
			return;
		}
		const sortFunctions = {
			[SortOption.StartDate]: (a, b) => new Date(a.dateFrom).getTime() - new Date(b.dateFrom).getTime(),
			[SortOption.NameAscending]: (a, b) => a.name.localeCompare(b.name),
			[SortOption.MostRecent]: (a, b) => new Date(a.dateFrom).getTime() - new Date(b.dateFrom).getTime(),
			[SortOption.NameDescending]: (a, b) => b.name.localeCompare(a.name),
		};

		const sortAndAssign = (source: UserSavedEvent[], targetProp: string, sortFunc) => {
			if (source) {
				const sourceCopy = cloneDeep(source);
				this[targetProp] = sourceCopy.sort(sortFunc);
			}
		};

		const sortFunction = sortFunctions[sortOption];
		sortAndAssign(this.filteredEvents, 'filteredEvents', sortFunction);
		this.currentSort = sortOption;
	}

	handleMobileSearchTerm(searchTerm: string) {
		this.currentSearchTerm = searchTerm;
		const searchAndFilter = { searchTerm: searchTerm, filterOption: null };
		this.handleSavedFilterAndSearch(searchAndFilter);
	}

	handleEventsPageNavigation() {
		window.location.href = URLCreator.eventsPage();
	}

	handleBottomSheetFilter() {
		const bottomSheetRef = this.bottomSheet.open(MobileFilterBottomsheetComponent, {
			data: {
				currentSort: this.currentSort,
				showFilterOptions: false,
			},
		});

		bottomSheetRef.afterDismissed().pipe(take(1)).subscribe((result: { sortOption: SortOption }) => {
			if (result) {
				if (result.sortOption) {
					this.currentSort = result.sortOption;
				}
				const searchAndFilter = { searchTerm: this.currentSearchTerm, filterOption: null };
				this.handleSavedFilterAndSearch(searchAndFilter);
			}
		});
	}
}
