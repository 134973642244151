import * as eventActions from '@app/store/actions/event/event.actions';
import * as ticketActions from '@app/store/actions/tickets/tickets.actions';
import * as fileActions from '@app/store/actions/file/file.actions';
import { EventActionsConstants } from '@app/store/actions/event/event.actions.constants';
import { EventEntityState, EventDashboardReportsState } from '@app/models/store.model';
import { combineReducers } from '@ngrx/store';
import { TicketsActionsConstant } from '@app/store/actions/tickets/tickets.constants';
import { FileActionsConstants } from '@app/store/actions/file/file.actions.constants';
import { OrganiserProfileActionConstants } from '@app/store/actions/organiserProfiles/organiserProfiles.constants';
import * as profileActions from '@app/store/actions/organiserProfiles/organiserProfiles.actions';
import { EventOrganiserProfile } from '@app/models/profile.model';
import { EventDetails, EventDashboard } from '@app/models/event.model';
import { EventTicketsDetails, EventTicketsSettings, SeatingCategory } from '@app/models/ticket.model';
import { EventMetadata } from '@app/models/event-metadata.model';
import { initialState, initialEntityState } from './initialState';
import { SchemeID, NormalizedSchemeField } from '@app/models/dataSchema.model';
import { StreamingActionConstants,
	Actions as StreamingActions } from '@app/features/control-panel/streaming/event-streaming.actions';
import { entityStreamingReducer } from '@app/features/control-panel/streaming/event-streaming.reducer';
import { entityCollectionsReducer } from '@app/features/control-panel/collections/event-collections.reducer';
import { CollectionsActionConstants,
	Actions as CollectionsActions } from '@app/features/control-panel/collections/event-collections.actions';
import { entityWidgetReducer } from '@app/features/control-panel/widget/widget.reducer';
import { WidgetActionConstants,
	Actions as WidgetActions } from '@app/features/control-panel/widget/widget.actions';

import { EventAtTheDoorConstants, Actions as AtTheDoorActions } from '@app/features/control-panel/at-the-door/at-the-door.actions';
import { entityEventAtTheDoorReducer } from '@app/features/control-panel/at-the-door/at-the-door.reducer';
import { omit } from 'lodash';

type EntityFetchingActions =
	eventActions.Actions
	| ticketActions.Actions
	| profileActions.Actions
	| StreamingActions
	| CollectionsActions
	| WidgetActions
	| AtTheDoorActions;

const entityIsFetching = (
	state = initialState.entities.isFetching,
	action: EntityFetchingActions
): boolean => {
	switch (action.type) {
		case EventActionsConstants.GET_EVENT_WEBHOOKS:
		case EventActionsConstants.GET_EVENT_WEBHOOKS_FAILED:
		case EventActionsConstants.GET_EVENT_WEBHOOKS_SUCCESS:
		case EventActionsConstants.UPDATE_EVENT_WEBHOOKS:
		case EventActionsConstants.UPDATE_EVENT_WEBHOOKS_FAILED:
		case EventActionsConstants.UPDATE_EVENT_WEBHOOKS_SUCCESS:
		case EventActionsConstants.TEST_EVENT_WEBHOOKS:
		case EventActionsConstants.TEST_EVENT_WEBHOOKS_FAILED:
		case EventActionsConstants.TEST_EVENT_WEBHOOKS_SUCCESS:
		case EventActionsConstants.SET_EVENT_REDIRECT_PRODUCT_ID:
		case EventActionsConstants.UPDATE_EVENT:
		case EventActionsConstants.GET_EVENT_DETAILS:
		case TicketsActionsConstant.GET_EVENT_TICKETS:
		case EventActionsConstants.GET_EVENT_DASHBOARD:
		case EventActionsConstants.GET_EVENT_SETTINGS:
		case EventActionsConstants.UPDATE_EVENT_SETTINGS:
		case TicketsActionsConstant.GET_TICKET_SETTINGS:
		case TicketsActionsConstant.UPDATE_TICKET_SETTINGS:
		case OrganiserProfileActionConstants.GET_EVENT_ORGANISER:
		case OrganiserProfileActionConstants.SAVE_EVENT_ORGANISER:
		case EventActionsConstants.SET_EVENT_LISTING:
		case EventActionsConstants.SET_EVENT_SHORT_LINK:
		case EventActionsConstants.SUBMIT_EVENT_FOR_APPROVAL:
		case EventActionsConstants.SET_EVENT_SERVICE_FEE:
		case EventActionsConstants.SET_EVENT_COVID_COMPLIANT:
		case EventActionsConstants.CANCEL_EVENT:
		case EventActionsConstants.CHANGE_EVENT_DATES:
		case EventActionsConstants.GET_FACEBOOK_INTEGRATION_DATA:
		case StreamingActionConstants.GET_EVENT_STREAMING:
		case CollectionsActionConstants.GET_EVENT_COLLECTIONS:
		case WidgetActionConstants.GET_EVENT_WIDGET:
		case CollectionsActionConstants.UPDATE_EVENT_COLLECTIONS:
		case EventActionsConstants.SCHEDULE_EVENT_PUBLIC:
		case EventActionsConstants.GET_EVENT_SCHEDULE_TRANSFER:
			return true;
		case EventActionsConstants.SET_EVENT_REDIRECT_PRODUCT_ID_SUCCESS:
		case EventActionsConstants.SET_EVENT_REDIRECT_PRODUCT_ID_FAILED:
		case EventActionsConstants.SET_EVENT_REDIRECT_MODAL_FLAG:
		case EventActionsConstants.UPDATE_EVENT_FAILED:
		case EventActionsConstants.UPDATE_EVENT_SUCCESS:
		case EventActionsConstants.GET_EVENT_DETAILS_FAILED:
		case EventActionsConstants.GET_EVENT_DETAILS_SUCCESS:
		case TicketsActionsConstant.GET_EVENT_TICKETS_FAILED:
		case TicketsActionsConstant.GET_EVENT_TICKETS_SUCCESS:
		case EventActionsConstants.GET_EVENT_SETTINGS_FAILED:
		case EventActionsConstants.GET_EVENT_SETTINGS_SUCCESS:
		case TicketsActionsConstant.GET_TICKET_SETTINGS_FAILED:
		case TicketsActionsConstant.GET_TICKET_SETTINGS_SUCCESS:
		case EventActionsConstants.UPDATE_EVENT_SETTINGS_FAILED:
		case EventActionsConstants.UPDATE_EVENT_SETTINGS_SUCCESS:
		case TicketsActionsConstant.UPDATE_TICKET_SETTINGS_FAILED:
		case TicketsActionsConstant.UPDATE_TICKET_SETTINGS_SUCCESS:
		case OrganiserProfileActionConstants.GET_EVENT_ORGANISER_FAILED:
		case OrganiserProfileActionConstants.GET_EVENT_ORGANISER_SUCCESS:
		case OrganiserProfileActionConstants.SAVE_EVENT_ORGANISER_FAILED:
		case OrganiserProfileActionConstants.SAVE_EVENT_ORGANISER_SUCCESS:
		case EventActionsConstants.SET_EVENT_LISTING_FAILED:
		case EventActionsConstants.SET_EVENT_LISTING_SUCCESS:
		case EventActionsConstants.SUBMIT_EVENT_FOR_APPROVAL_SUCCESS:
		case EventActionsConstants.SUBMIT_EVENT_FOR_APPROVAL_FAILED:
		case EventActionsConstants.GET_EVENT_DASHBOARD_SUCCESS:
		case EventActionsConstants.GET_EVENT_DASHBOARD_FAILED:
		case EventActionsConstants.SET_EVENT_SERVICE_FEE_FAILED:
		case EventActionsConstants.SET_EVENT_SERVICE_FEE_SUCCESS:
		case EventActionsConstants.SET_EVENT_COVID_COMPLIANT_FAILED:
		case EventActionsConstants.SET_EVENT_COVID_COMPLIANT_SUCCESS:
		case EventActionsConstants.CANCEL_EVENT_SUCCESS:
		case EventActionsConstants.CANCEL_EVENT_FAILED:
		case EventActionsConstants.CHANGE_EVENT_DATES_SUCCESS:
		case EventActionsConstants.CHANGE_EVENT_DATES_FAILED:
		case EventActionsConstants.SET_EVENT_SHORT_LINK_FAILED:
		case EventActionsConstants.SET_EVENT_SHORT_LINK_SUCCESS:
		case EventActionsConstants.GET_FACEBOOK_INTEGRATION_DATA_FAILED:
		case EventActionsConstants.GET_FACEBOOK_INTEGRATION_DATA_SUCCESS:
		case EventActionsConstants.UPDATE_EVENT_FACEBOOK_DATA_FAILED:
		case StreamingActionConstants.GET_EVENT_STREAMING_SUCCESS:
		case StreamingActionConstants.GET_EVENT_STREAMING_FAILED:
		case CollectionsActionConstants.GET_EVENT_COLLECTIONS_SUCCESS:
		case CollectionsActionConstants.GET_EVENT_COLLECTIONS_FAILED:
		case WidgetActionConstants.GET_EVENT_WIDGET_SUCCESS:
		case WidgetActionConstants.GET_EVENT_WIDGET_FAILED:
		case CollectionsActionConstants.UPDATE_EVENT_COLLECTIONS_SUCCESS:
		case CollectionsActionConstants.UPDATE_EVENT_COLLECTIONS_FAILED:
		case EventActionsConstants.SCHEDULE_EVENT_PUBLIC_SUCCESS:
		case EventActionsConstants.SCHEDULE_EVENT_PUBLIC_FAILED:
		case StreamingActionConstants.SET_EVENT_STREAMING_SETTINGS_SUCCESS:
		case StreamingActionConstants.SET_ORGANISER_PREVIEW_CODE_SUCCESS:
		case EventActionsConstants.GET_EVENT_SCHEDULE_TRANSFER_SUCCESS:
		case EventActionsConstants.GET_EVENT_SCHEDULE_TRANSFER_FAILED:
			return false;
		case EventActionsConstants.SET_EVENT_ENTITY_FETCHING_FLAG:
			return action.payload.isFetching;
		case EventActionsConstants.GET_EVENT_REQUEST_FIELD_SERVICES:
		case EventActionsConstants.GET_EVENT_REQUEST_FIELD_SERVICES_SUCCESS:
		case EventActionsConstants.GET_EVENT_REQUEST_FIELD_SERVICES_FAILED:
		default:
			return state;
	}
};

const entityOriginal = (
	state = initialEntityState.original,
	action: eventActions.Actions | ticketActions.Actions | profileActions.Actions): {
	seatingCategories: SeatingCategory[];
	seatsIOChartKey: string;
} => {
	switch (action.type) {
		case EventActionsConstants.GET_EVENT_SUCCESS:
		case EventActionsConstants.CREATE_EVENT_SUCCESS:
		case EventActionsConstants.GET_EVENT_DETAILS_SUCCESS:
		case TicketsActionsConstant.GET_EVENT_TICKETS_SUCCESS:
		case OrganiserProfileActionConstants.GET_EVENT_ORGANISER_SUCCESS:
		case EventActionsConstants.UPDATE_EVENT_SUCCESS:
			return action.payload.entity.original;
		case OrganiserProfileActionConstants.SAVE_EVENT_ORGANISER_SUCCESS:
			return state || initialEntityState.original;
		default:
			return state;
	}
};

const entityQuestions = (state = initialEntityState.questions, action: eventActions.Actions | ticketActions.Actions) => {
	switch (action.type) {
		case EventActionsConstants.GET_EVENT_SUCCESS:
		case EventActionsConstants.CREATE_EVENT_SUCCESS:
		case TicketsActionsConstant.GET_EVENT_TICKETS_SUCCESS:
			return action.payload.entity.questions;
		default:
			return state;
	}
};

const entitySeatingCategories = (
	state = initialEntityState.seatingCategories,
	action: eventActions.Actions | ticketActions.Actions
): SeatingCategory[] => {
	switch (action.type) {
		case TicketsActionsConstant.GET_SEATING_CATEGORIES_SUCCESS:
		case TicketsActionsConstant.GET_EVENT_TICKETS_SUCCESS:
		case EventActionsConstants.UPDATE_EVENT_SUCCESS:
			return action.payload.entity.seatingCategories;
			/* 		case TicketsActionsConstant.GET_SEATING_CATEGORIES:
		case TicketsActionsConstant.GET_EVENT_TICKETS:
			return initialEntityState.seatingCategories; */
		default:
			return state;
	}
};

const entityProfile = (
	state = initialEntityState.profile,
	action: eventActions.Actions | ticketActions.Actions | profileActions.Actions): EventOrganiserProfile => {
	switch (action.type) {
		case EventActionsConstants.GET_EVENT_SUCCESS:
		case EventActionsConstants.CREATE_EVENT_SUCCESS:
		case EventActionsConstants.GET_EVENT_DETAILS_SUCCESS:
		case TicketsActionsConstant.GET_EVENT_TICKETS_SUCCESS:
		case OrganiserProfileActionConstants.GET_EVENT_ORGANISER_SUCCESS:
		case OrganiserProfileActionConstants.SAVE_EVENT_ORGANISER_SUCCESS:
		case EventActionsConstants.UPDATE_EVENT_SUCCESS:
			return action.payload.normalizedEntity.event[action.payload.id].profile;
		case EventActionsConstants.GET_EVENT_DETAILS:
		case TicketsActionsConstant.GET_EVENT_TICKETS:
		case OrganiserProfileActionConstants.GET_EVENT_ORGANISER:
			return initialEntityState.profile;
		default:
			return state;
	}
};

const entityDetails = (
	state = initialEntityState.details,
	action: eventActions.Actions | ticketActions.Actions | profileActions.Actions | fileActions.Actions
): EventDetails => {
	switch (action.type) {
		case EventActionsConstants.GET_EVENT_SUCCESS:
		case EventActionsConstants.CREATE_EVENT_SUCCESS:
		case EventActionsConstants.GET_EVENT_DETAILS_SUCCESS:
		case OrganiserProfileActionConstants.GET_EVENT_ORGANISER_SUCCESS:
		case OrganiserProfileActionConstants.SAVE_EVENT_ORGANISER_SUCCESS:
		case EventActionsConstants.UPDATE_EVENT_SUCCESS:
		case TicketsActionsConstant.GET_EVENT_TICKETS_SUCCESS:
			return action.payload.normalizedEntity.event[action.payload.id].details;
		case EventActionsConstants.GET_EVENT_DETAILS:
		case TicketsActionsConstant.GET_EVENT_TICKETS:
		case OrganiserProfileActionConstants.GET_EVENT_ORGANISER:
			return initialEntityState.details;
		case FileActionsConstants.REMOVE_EVENT_IMAGE:
			return {
				...state,
				image: null,
			};
		case FileActionsConstants.REMOVE_EVENT_IMAGE_FAILED:
			return {
				...state,
				image: action.payload.action.payload.image,
			};
		case FileActionsConstants.UPLOAD_EVENT_IMAGE_SUCCESS:
			return {
				...state,
				image: action.payload.image,
			};
		default:
			return state;
	}
};

const entityTicketsDetails = (
	state = initialEntityState.ticketsDetails,
	action: eventActions.Actions | ticketActions.Actions | profileActions.Actions
): EventTicketsDetails => {
	switch (action.type) {
		case EventActionsConstants.CREATE_EVENT_SUCCESS:
		case EventActionsConstants.GET_EVENT_DETAILS_SUCCESS:
		case OrganiserProfileActionConstants.GET_EVENT_ORGANISER_SUCCESS:
		case OrganiserProfileActionConstants.SAVE_EVENT_ORGANISER_SUCCESS:
		case EventActionsConstants.UPDATE_EVENT_SUCCESS:
		case EventActionsConstants.GET_EVENT_SUCCESS:
		case TicketsActionsConstant.GET_EVENT_TICKETS_SUCCESS:
			return action.payload.normalizedEntity.ticketsDetails[action.payload.id];
		case TicketsActionsConstant.STOP_TICKET_SALES_SUCCESS:
		case TicketsActionsConstant.MARK_TICKETS_AS_SOLD_OUT_SUCCESS:
			return action.payload.normalizedEntity.ticketsDetails[action.payload.eventId];
		case EventActionsConstants.GET_EVENT_DETAILS:
		case TicketsActionsConstant.GET_EVENT_TICKETS:
		case OrganiserProfileActionConstants.GET_EVENT_ORGANISER:
			return initialEntityState.ticketsDetails;
		default:
			return state;
	}
};

const entityTicketSettings = (
	state = initialEntityState.ticketSettings,
	action: fileActions.Actions | ticketActions.Actions
): EventTicketsSettings => {
	switch (action.type) {
		case TicketsActionsConstant.GET_TICKET_SETTINGS:
			return initialEntityState.ticketSettings;
		case TicketsActionsConstant.GET_TICKET_SETTINGS_SUCCESS:
		case TicketsActionsConstant.UPDATE_TICKET_SETTINGS_SUCCESS:
			return action.payload.settings;
		case FileActionsConstants.REMOVE_TICKET_IMAGE:
			return {
				...state,
				image: null,
			};
		case FileActionsConstants.UPLOAD_TICKET_IMAGE_SUCCESS:
			return {
				...state,
				image: action.payload.image,
			};
		case FileActionsConstants.REMOVE_TICKET_IMAGE_FAILED:
			return {
				...state,
				image: action.payload.action.payload.image,
			};
		default:
			return state;
	}
};

const entityMetadata = (state = initialEntityState.metadata, action: eventActions.Actions): EventMetadata => {
	switch (action.type) {
		case EventActionsConstants.GET_EVENT_METADATA_SUCCESS:
			return action.payload.metadata;
		default:
			return state;
	}
};

const entityDashboardReports = (state = initialEntityState.dashboardReports, action: eventActions.Actions): EventDashboardReportsState => {
	switch (action.type) {
		case EventActionsConstants.GET_EVENT_DASHBOARD_REPORTS:
			return {
				...state,
				isFetching: true,
			};
		case EventActionsConstants.GET_EVENT_DASHBOARD_REPORTS_FAILED:
			return initialEntityState.dashboardReports;
		case EventActionsConstants.GET_EVENT_DASHBOARD_REPORTS_SUCCESS:
			return {
				isFetching: false,
				value: action.payload.data,
			};
		default:
			return state;
	}
};

const entityDashboard = (state = initialEntityState.dashboard, action: eventActions.Actions): EventDashboard => {
	switch (action.type) {
		case EventActionsConstants.GET_EVENT_DASHBOARD:
			return initialEntityState.dashboard;
		case EventActionsConstants.GET_EVENT_DASHBOARD_SUCCESS:
			return {
				...state,
				...action.payload.dashboard,
			};
		case EventActionsConstants.SET_EVENT_SERVICE_FEE_SUCCESS:
			return {
				...state,
				serviceFeeForHost: action.payload.isServiceFeeForHost,
			};
		case EventActionsConstants.SET_EVENT_COVID_COMPLIANT_SUCCESS:
			return {
				...state,
				covidComplianceEnabled: action.payload.covidComplianceEnabled.covidComplianceEnabled,
			};
		case EventActionsConstants.SUBMIT_EVENT_FOR_APPROVAL_SUCCESS:
			return {
				...state,
				productStatus: action.payload.status,
			};
		case EventActionsConstants.SET_EVENT_LISTING_SUCCESS:
			return {
				...state,
				isPublic: action.payload.isPublic,
			};
		case EventActionsConstants.SET_EVENT_SHORT_LINK_SUCCESS:
			return {
				...state,
				shortLink: action.payload.link,
			};
		case EventActionsConstants.CHANGE_EVENT_DATES_SUCCESS:
			const { startDate, endDate } = action.payload;
			return {
				...state,
				startDate,
				endDate,
			};
		case EventActionsConstants.CANCEL_EVENT_SUCCESS:
			return {
				...state,
				productStatus: action.payload.productStatus,
			};
		case EventActionsConstants.SCHEDULE_EVENT_PUBLIC_SUCCESS:
			return {
				...state,
				scheduledToGoPublicOn: action.payload.scheduledToGoPublicOn,
			};
		case EventActionsConstants.DELETE_SCHEDULE_EVENT_PUBLIC_SUCCESS:
			return {
				...state,
				scheduledToGoPublicOn: null,
			};
		case EventActionsConstants.SET_EVENT_REDIRECT_MODAL_FLAG:
			return {
				...state,
				isModalOpen: action.payload.isModalOpen,
			};
		case EventActionsConstants.SET_EVENT_REDIRECT_PRODUCT_ID_SUCCESS:
			return {
				...state,
				redirectProductId: action.payload.redirectProductId,
				isModalOpen: false,
			};
		case EventActionsConstants.UPDATE_EVENT_SKIP_COMPLETION_ITEM_SUCCESS:
			return {
				...state,
				eventSetupProgress: action.payload.dashboard.eventSetupProgress,
			};
		default:
			return state;
	}
};

const entityFacebookIntegration = (
	state = initialEntityState.facebookIntegration,
	action: eventActions.Actions
): EventEntityState['facebookIntegration'] => {
	switch (action.type) {
		case EventActionsConstants.GET_FACEBOOK_INTEGRATION_DATA:
			return initialEntityState.facebookIntegration;
		case EventActionsConstants.GET_FACEBOOK_INTEGRATION_DATA_SUCCESS:
		case EventActionsConstants.UPDATE_EVENT_FACEBOOK_DATA_SUCCESS:
			return action.payload.data;
		default:
			return state;
	}
};

const entityLinkCampaignsReducer = (
	state = initialEntityState.linkCampaigns,
	action: eventActions.Actions
): EventEntityState['linkCampaigns'] => {
	switch (action.type) {
		case EventActionsConstants.GET_EVENT_LINK_CAMPAIGNS_SUCCESS:
			return {
				...state,
				campaigns: action.payload.campaigns,
			};
		case EventActionsConstants.DELETE_EVENT_LINK_CAMPAIGN:
			return {
				...state,
				isFetching: true,
			};
		case EventActionsConstants.DELETE_EVENT_LINK_CAMPAIGN_SUCCESS:
			return {
				...state,
				isFetching: false,
				campaigns: {
					...state.campaigns,
					all: state.campaigns.all.filter(id => id !== action.payload.id),
					byId: omit(state.campaigns.byId, action.payload.id),
				},
			};
		case EventActionsConstants.DELETE_EVENT_LINK_CAMPAIGN_FAILED:
			return {
				...state,
				isFetching: false,
			};
		case EventActionsConstants.ADD_EVENT_LINK_CAMPAIGN:
			return {
				...state,
				isFetching: true,
			};
		case EventActionsConstants.ADD_EVENT_LINK_CAMPAIGN_SUCCESS:
			return {
				...state,
				isFetching: false,
				campaigns: {
					all: [
						...state.campaigns.all,
						action.payload.campaign.id,
					],
					byId: {
						...state.campaigns.byId,
						[action.payload.campaign.id]: action.payload.campaign,
					},
				},
			};
		case EventActionsConstants.ADD_EVENT_LINK_CAMPAIGN_FAILED:
			return {
				...state,
				isFetching: false,
			};
		default:
			return state;
	}
};

const entityEventOrdersReducer = (
	state = initialEntityState.orders,
	action: eventActions.Actions
): EventEntityState['orders'] => {
	switch ( action.type ){
		case EventActionsConstants.GET_EVENT_ORDERS_SUCCESS:
			return {
				...state,
				orders: action.payload.orders,
			};
		case EventActionsConstants.GET_ORDER_DETAILS_SUCCESS:
			return {
				...state,
				activeOrder: action.payload.order,
			};
		default:
			return state;
	}
};

const entityPromoters = (
	state = initialEntityState.promotersPageInfo,
	action: eventActions.Actions
): EventEntityState['promotersPageInfo'] => {
	switch (action.type) {
		case EventActionsConstants.GET_EVENT_PROMOTERS_SUCCESS:
		case EventActionsConstants.SET_PROMOTERS_INCENTIVE_SUCCESS:
			return action.payload.promotersPageInfo;
		default:
			return state;
	}
};

const entityPromoterNetworks = (
	state = initialEntityState.promoterNetworksPageInfo,
	action: eventActions.Actions
): EventEntityState['promoterNetworksPageInfo'] => {
	switch (action.type) {
		case EventActionsConstants.GET_EVENT_PROMOTER_NETWORKS_SUCCESS:
		case EventActionsConstants.CREATE_PROMOTER_NETWORK_SUCCESS:
			return action.payload.promoterNetworksPageInfo;
		default:
			return state;
	}
};

const entityGuestList = (
	state = initialEntityState.guestList,
	action: eventActions.Actions
): EventEntityState['guestList'] => {
	switch (action.type) {
		case EventActionsConstants.GET_EVENT_GUEST_LIST_SUCCESS:
			return {
				...state,
				guestListInfo : action.payload.guestListResultInfo,
			};
		case EventActionsConstants.GET_SELECTED_GUEST_INFO_SUCCESS:
			return {
				...state,
				selectedGuestInfo : action.payload.selectedGuestInfo,
			};
		default:
			return state;
	}
};

const eventRegistrations = (
	state = initialEntityState.eventRegistrations,
	action: eventActions.Actions
): EventEntityState['eventRegistrations'] => {
	switch (action.type) {
		case EventActionsConstants.GET_REGISTRATION_SETTINGS_SUCCESS:
		case EventActionsConstants.UPDATE_REGISTRATION_SETTINGS_SUCCESS:
			return {
				...state,
				registrationSettings: action.payload.registrationSettings,
			};
		case EventActionsConstants.GET_REGISTRATIONS_SUCCESS:
			return {
				...state,
				registrations: action.payload.eventRegistrations.registrations,
				totalRegistrations: action.payload.eventRegistrations.totalRegistrations,
			};
		default:
			return state;
	}
};

export const entityEventScannersReducer = (
	state = initialEntityState.scanners,
	action: eventActions.Actions
): EventEntityState['scanners'] => {
	switch (action.type) {
		case EventActionsConstants.GET_EVENT_SCANNERS:
		case EventActionsConstants.ADD_EVENT_SCANNERS:
		case EventActionsConstants.EDIT_EVENT_SCANNERS:
		case EventActionsConstants.NEW_PIN_EVENT_SCANNER:
			return {
				...state,
			};
		case EventActionsConstants.GET_EVENT_SCANNERS_SUCCESS:
			return {
				...state,
				scanners: action.payload.scanners,
			};
		case EventActionsConstants.GET_EVENT_SCANNERS_FAILED:
		case EventActionsConstants.ADD_EVENT_SCANNERS_FAILED:
		case EventActionsConstants.EDIT_EVENT_SCANNERS_FAILED:
		case EventActionsConstants.NEW_PIN_EVENT_SCANNER_FAILED:
			return {
				...state,
			};
		case EventActionsConstants.ADD_EVENT_SCANNERS_SUCCESS:
		case EventActionsConstants.EDIT_EVENT_SCANNERS_SUCCESS:
		case EventActionsConstants.NEW_PIN_EVENT_SCANNER_SUCCESS:
			return {
				...state,
				scanners: action.payload.scanners,
			};
	}
};

export const entityEventFieldServicesReducer = (
	state = initialEntityState.requestFieldServices,
	action: eventActions.Actions
): EventEntityState['requestFieldServices'] => {
	switch (action.type) {
		case EventActionsConstants.GET_EVENT_REQUEST_FIELD_SERVICES:
			return {
				...state,
			};
		case EventActionsConstants.GET_EVENT_REQUEST_FIELD_SERVICES_SUCCESS:
			return{
				...state,
				ratesUrl : action.payload.ratesUrl,
			};
		case EventActionsConstants.GET_EVENT_REQUEST_FIELD_SERVICES_FAILED:
			return {
				...state,
			};
	}
};

const entityDesign = (
	state = initialEntityState.design,
	action: eventActions.Actions
): EventEntityState['design'] => {
	switch (action.type) {
		case EventActionsConstants.GET_EVENT_DESIGN_SUCCESS:
		case EventActionsConstants.UPDATE_EVENT_DESIGN_SUCCESS:
			return action.payload.design;
		default:
			return state;
	}
};

const entityTabbedContent = (
	state = initialEntityState.tabbedContent,
	action: eventActions.Actions
): EventEntityState['tabbedContent'] => {
	switch (action.type) {
		case EventActionsConstants.GET_EVENT_TABBED_CONTENT_SUCCESS:
			return {
				...state,
				tabs : action.payload.tabs,
			};
		case EventActionsConstants.SET_TAB_CONTENT_VISIBILITY_SUCCESS:
			return {
				...state,
				tabs: {
					...state.tabs,
					byId: {
						...state.tabs.byId,
						[action.payload.id]: {
							...state.tabs.byId[action.payload.id],
							isVisible: action.payload.isVisible,
						},
					},
				},
			};
		case EventActionsConstants.SET_TAB_CONTENT_VISIBILITY_FAILED: {
			const { prevValue, id } = action.payload.action.payload;
			return {
				...state,
				tabs: {
					...state.tabs,
					byId: {
						...state.tabs.byId,
						[id]: {
							...state.tabs.byId[id],
							isVisible: prevValue,
						},
					},
				},
			};
		};
		case EventActionsConstants.REORDER_TABBED_CONTENT:
			return{
				...state,
				tabs: {
					...state.tabs,
					all: [...action.payload.order],
				},
			};
		case EventActionsConstants.REORDER_TABBED_CONTENT_FAILED:
			return {
				...state,
				tabs: {
					...state.tabs,
					all: [...action.payload.action.payload.prevOrder],
				},
			};
		case EventActionsConstants.DELETE_TAB_CONTENT_SUCCESS:
			return {
				...state,
				isFetching: false,
				tabs: {
					...state.tabs,
					all: state.tabs.all.filter(id => id !== action.payload.id),
				},
			};
		case EventActionsConstants.UPSERT_TAB_CONTENT:
			return {
				...state,
				isFetching: true,
			};
		case EventActionsConstants.DELETE_TAB_CONTENT_FAILED:
			return {
				...state,
				isFetching: false,
			};
		case EventActionsConstants.UPSERT_TAB_CONTENT_SUCCESS:
			return {
				...state,
				isFetching: false,
				tabs: {
					all: [
						...state.tabs.all,
						...(action.payload.isUpdating
							? []
							: [action.payload.tab.id]),
					],
					byId: {
						...state.tabs.byId,
						[action.payload.tab.id]: action.payload.tab,
					},
				},
			};
		default:
			return state;
	}
};

export const entityEventQuestionsReducer = (
	state = initialEntityState.collectInfo,
	action: eventActions.Actions
): EventEntityState['collectInfo']  => {
	switch (action.type) {
		case EventActionsConstants.GET_EVENT_COLLECT_INFO_SUCCESS:
			return {
				...state,
				event: action.payload.collectInfo,
				tickets: action.payload.tickets,
			};
		case EventActionsConstants.UPSERT_EVENT_COLLECT_INFO:
			return {
				...state,
			};
		case EventActionsConstants.UPSERT_EVENT_COLLECT_INFO_SUCCESS:
			return {
				...state,
				event: {
					...state.event,
					byId: {
						...state.event.byId,
						[action.payload.collectInfo.id]: action.payload.collectInfo,
					},
					all: [...state.event.all, ...(action.payload.isUpdating ? [] : [action.payload.collectInfo.id])],
				},
			};
		case EventActionsConstants.UPSERT_EVENT_COLLECT_INFO_FAILED:
			return {
				...state,
			};
		case EventActionsConstants.GET_PROFILE_COLLECT_INFO_SUCCESS:
			return {
				...state,
				profile: action.payload.collectInfo,
			};
		case EventActionsConstants.SET_COLLECT_INFO_PROPERTY:
			return {
				...state,
				event: {
					...state.event,
					byId: {
						...state.event.byId,
						[action.payload.id]: {
							...state.event.byId[action.payload.id],
							[action.payload.name]: action.payload.value,
						},
					},
				},
			};
		case EventActionsConstants.SET_COLLECT_INFO_PROPERTY_FAILED: {
			const { id, name, prevValue } = action.payload.action.payload;
			return {
				...state,
				event: {
					...state.event,
					byId: {
						...state.event.byId,
						[id]: {
							...state.event.byId[id],
							[name]: prevValue,
						},
					},
				},
			};
		}
		case EventActionsConstants.DELETE_COLLECT_INFO:
			return {
				...state,
			};
		case EventActionsConstants.DELETE_COLLECT_INFO_SUCCESS:
			return {
				...state,
				event: {
					...state.event,
					all: state.event.all.filter(id => id !== action.payload.id),
					byId: omit(state.event.byId, action.payload.id),
				},
			};
		case EventActionsConstants.DELETE_COLLECT_INFO_FAILED:
			return {
				...state,
			};
		case EventActionsConstants.REORDER_COLLECT_INFO:
			return {
				...state,
				event: {
					...state.event,
					all: [...action.payload.order],
				},
			};
		case EventActionsConstants.REORDER_COLLECT_INFO_FAILED:
			return {
				...state,
				event: {
					...state.event,
					all: [...action.payload.action.payload.prevOrder],
				},
			};
		default:
			return state;
	}
};

export const entityCopyInfoReducer = (
	state = initialEntityState.copyInfo,
	action: eventActions.Actions
): EventEntityState['copyInfo'] => {
	switch (action.type) {
		case EventActionsConstants.GET_COPY_EVENT_INFO_SUCCESS:
			return action.payload.copyInfo;
		default:
			return state;
	}
};

export const entityDefinitions = (
	state = initialEntityState.definitions,
	action: eventActions.Actions
): EventEntityState['definitions'] => {
	switch (action.type) {
		case EventActionsConstants.GET_EVENT_DEFINITIONS_SUCCESS:
		case EventActionsConstants.UPDATE_EVENT_DEFINITIONS_SUCCESS:
			return action.payload.definitions;
		default:
			return state;
	}
};

export const entityTicketGroups = (
	state = initialEntityState.ticketGroups,
	action: eventActions.Actions
): EventEntityState['ticketGroups'] => {
	switch (action.type) {
		case EventActionsConstants.GET_EVENT_TICKET_GROUPS_SUCCESS:
			return {
				...state,
				groups: action.payload.ticketGroups,
				ticketTypes: action.payload.ticketTypes,
				useTicketGroups: action.payload.useTicketGroups,
			};
		case EventActionsConstants.SET_TICKET_GROUPS_USAGE:
			return {
				...state,
				useTicketGroups: action.payload.useTicketGroups,
			};
		case EventActionsConstants.SET_TICKET_GROUPS_USAGE_FAILED:
			return {
				...state,
				useTicketGroups: action.payload.action.payload.prevValue,
			};
		case EventActionsConstants.UPSERT_TICKET_GROUP:
		case EventActionsConstants.DELETE_TICKET_GROUP:
			return {
				...state,
			};
		case EventActionsConstants.UPSERT_TICKET_GROUP_FAILED:
		case EventActionsConstants.DELETE_TICKET_GROUP_FAILED:
			return {
				...state,
			};
		case EventActionsConstants.DELETE_TICKET_GROUP_SUCCESS:
			return {
				...state,
				groups: {
					...state.groups,
					all: state.groups.all.filter(id => id !== action.payload.id),
					byId: omit(state.groups.byId, action.payload.id),
				},
			};
		case EventActionsConstants.UPSERT_TICKET_GROUP_SUCCESS:
			return {
				...state,
				groups: {
					...state.groups,
					all: [
						...state.groups.all,
						...(action.payload.isUpdating
							? []
							: [action.payload.ticketGroup.id]),
					],
					byId: {
						...state.groups.byId,
						[action.payload.ticketGroup.id]: action.payload.ticketGroup,
					},
				},
			};
		case EventActionsConstants.REORDER_TICKET_GROUP:
			return {
				...state,
				groups: {
					...state.groups,
					all: [...action.payload.order],
				},
			};
		case EventActionsConstants.REORDER_TICKET_GROUP_FAILED:
			return {
				...state,
				groups: {
					...state.groups,
					all: [...action.payload.action.payload.prevOrder],
				},
			};
		default:
			return state;
	}
};

export const entitySettingsReducer = (
	state = initialEntityState.settings,
	action: eventActions.Actions
): EventEntityState['settings'] => {
	switch (action.type) {
		case EventActionsConstants.GET_EVENT_SETTINGS_SUCCESS:
		case EventActionsConstants.UPDATE_EVENT_SETTINGS_SUCCESS:
			return action.payload.settings;
		default:
			return state;
	}
};

export const entityWebhooks = (
	state = initialEntityState.webhooks,
	action: eventActions.Actions
): EventEntityState['webhooks'] => {
	switch (action.type) {
		case EventActionsConstants.GET_EVENT_WEBHOOKS:
		case EventActionsConstants.UPDATE_EVENT_WEBHOOKS:
		case EventActionsConstants.TEST_EVENT_WEBHOOKS:
			return {
				...state,
				isFetching: true,
			};
		case EventActionsConstants.GET_EVENT_WEBHOOKS_SUCCESS:
		case EventActionsConstants.UPDATE_EVENT_WEBHOOKS_SUCCESS:
			return {
				...state,
				isFetching: false,
				webhooks: action.payload.webhooks,
			};
		case EventActionsConstants.GET_EVENT_WEBHOOKS_FAILED:
			return {
				...state,
				isFetching: false,
			};
		case EventActionsConstants.TEST_EVENT_WEBHOOKS_SUCCESS:
			return {
				...state,
				isFetching: false,
				testResponse: action.payload.testWebhook.responseCode,
				isTestModalOpen: true,
			};
	}
};

export const entityScheduleTransferReducer = (
	state = initialEntityState.scheduleTransfer,
	action: eventActions.Actions
): EventEntityState['scheduleTransfer'] => {
	switch (action.type) {
		case EventActionsConstants.GET_EVENT_SCHEDULE_TRANSFER_SUCCESS:
			return {
				...state,
				scheduleItems: action.payload.scheduleItems,
				settings: action.payload.settings,
			};
		case EventActionsConstants.UPDATE_EVENT_SCHEDULE_TRANSFER:
			return {
				...state,
			};
		case EventActionsConstants.UPDATE_EVENT_SCHEDULE_TRANSFER_SUCCESS:
			return {
				...state,
				scheduleItems: action.payload.scheduleItems,
				settings: action.payload.settings,
			};
		case EventActionsConstants.UPDATE_EVENT_SCHEDULE_TRANSFER_FAILED:
		case EventActionsConstants.GET_EVENT_SCHEDULE_TRANSFER_FAILED:
			return {
				...state,
			};
		default:
			return state;
	}
};


const entityReducer = combineReducers({
	original: entityOriginal,
	profile: entityProfile,
	details: entityDetails,
	questions: entityQuestions,
	ticketsDetails: entityTicketsDetails,
	seatingCategories: entitySeatingCategories,
	ticketSettings: entityTicketSettings,
	facebookIntegration: entityFacebookIntegration,
	settings: entitySettingsReducer,
	ticketGroups: entityTicketGroups,
	design: entityDesign,
	metadata: entityMetadata,
	dashboard: entityDashboard,
	isFetching: entityIsFetching,
	dashboardReports: entityDashboardReports,
	definitions: entityDefinitions,
	collectInfo: entityEventQuestionsReducer,
	streaming: entityStreamingReducer(initialEntityState.streaming),
	collections: entityCollectionsReducer(initialEntityState.collections),
	widget: entityWidgetReducer(initialEntityState.widget),
	scheduleTransfer: entityScheduleTransferReducer,
	webhooks: entityWebhooks,
	requestFieldServices: entityEventFieldServicesReducer,
	atTheDoor: entityEventAtTheDoorReducer(initialEntityState.atTheDoor),
	scanners: entityEventScannersReducer,
	orders: entityEventOrdersReducer,
	promotersPageInfo: entityPromoters,
	promoterNetworksPageInfo: entityPromoterNetworks,
	guestList: entityGuestList,
	eventRegistrations: eventRegistrations,
	tabbedContent: entityTabbedContent,
	linkCampaigns: entityLinkCampaignsReducer,
	copyInfo: entityCopyInfoReducer,
});

type EntityReducerActions = eventActions.Actions
| ticketActions.Actions
| fileActions.Actions
| profileActions.Actions
| StreamingActions
| CollectionsActions
| WidgetActions
| AtTheDoorActions;

const entityReducerSelector = (
	state: NormalizedSchemeField<EventEntityState<SchemeID>>,
	action: EntityReducerActions
) =>
	(id: SchemeID) =>
		reducer =>
			({
				...state,
				[id]: reducer(state[id], action),
			});

export const entitiesById = (
	state = initialState.entities.byId,
	action: EntityReducerActions
) => {
	switch (action.type) {
		case EventActionsConstants.SET_EVENT_REDIRECT_PRODUCT_ID:
		case EventActionsConstants.SET_EVENT_REDIRECT_PRODUCT_ID_SUCCESS:
		case EventActionsConstants.SET_EVENT_REDIRECT_MODAL_FLAG:
		case EventActionsConstants.UPDATE_EVENT:
		case EventActionsConstants.UPDATE_EVENT_SUCCESS:
		case EventActionsConstants.GET_EVENT_SUCCESS:
		case EventActionsConstants.CREATE_EVENT_SUCCESS:
		case EventActionsConstants.GET_EVENT_DETAILS_SUCCESS:
		case TicketsActionsConstant.GET_EVENT_TICKETS_SUCCESS:
		case OrganiserProfileActionConstants.GET_EVENT_ORGANISER_SUCCESS:
		case EventActionsConstants.GET_EVENT_DETAILS:
		case TicketsActionsConstant.GET_EVENT_TICKETS:
		case OrganiserProfileActionConstants.GET_EVENT_ORGANISER:
		case EventActionsConstants.GET_EVENT_SETTINGS:
		case EventActionsConstants.GET_EVENT_SETTINGS_SUCCESS:
		case EventActionsConstants.UPDATE_EVENT_SETTINGS:
		case EventActionsConstants.UPDATE_EVENT_SETTINGS_SUCCESS:
		case TicketsActionsConstant.GET_TICKET_SETTINGS:
		case TicketsActionsConstant.GET_TICKET_SETTINGS_SUCCESS:
		case TicketsActionsConstant.UPDATE_TICKET_SETTINGS_SUCCESS:
		case EventActionsConstants.GET_EVENT_METADATA_SUCCESS:
		case OrganiserProfileActionConstants.SAVE_EVENT_ORGANISER_SUCCESS:
		case OrganiserProfileActionConstants.SAVE_EVENT_ORGANISER:
		case TicketsActionsConstant.UPDATE_TICKET_SETTINGS:
		case EventActionsConstants.SET_EVENT_LISTING_SUCCESS:
		case EventActionsConstants.SET_EVENT_LISTING:
		case EventActionsConstants.SET_EVENT_SERVICE_FEE:
		case EventActionsConstants.SET_EVENT_SERVICE_FEE_SUCCESS:
		case EventActionsConstants.SET_EVENT_COVID_COMPLIANT:
		case EventActionsConstants.SET_EVENT_COVID_COMPLIANT_SUCCESS:
		case EventActionsConstants.SUBMIT_EVENT_FOR_APPROVAL:
		case EventActionsConstants.SUBMIT_EVENT_FOR_APPROVAL_SUCCESS:
		case EventActionsConstants.GET_EVENT_DASHBOARD:
		case EventActionsConstants.GET_EVENT_DASHBOARD_SUCCESS:
		case EventActionsConstants.SET_EVENT_SHORT_LINK_SUCCESS:
		case EventActionsConstants.SET_EVENT_SHORT_LINK:
		case EventActionsConstants.GET_EVENT_DASHBOARD_REPORTS:
		case EventActionsConstants.GET_EVENT_DASHBOARD_REPORTS_SUCCESS:
		case EventActionsConstants.CANCEL_EVENT:
		case EventActionsConstants.CANCEL_EVENT_SUCCESS:
		case EventActionsConstants.CHANGE_EVENT_DATES:
		case EventActionsConstants.CHANGE_EVENT_DATES_SUCCESS:
		case EventActionsConstants.SET_EVENT_ENTITY_FETCHING_FLAG:
		case CollectionsActionConstants.GET_EVENT_COLLECTIONS:
		case CollectionsActionConstants.GET_EVENT_COLLECTIONS_SUCCESS:
		case WidgetActionConstants.GET_EVENT_WIDGET:
		case WidgetActionConstants.GET_EVENT_WIDGET_SUCCESS:
		case CollectionsActionConstants.UPDATE_EVENT_COLLECTIONS:
		case CollectionsActionConstants.UPDATE_EVENT_COLLECTIONS_SUCCESS:
		case EventActionsConstants.SCHEDULE_EVENT_PUBLIC:
		case EventActionsConstants.SCHEDULE_EVENT_PUBLIC_SUCCESS:
		case EventActionsConstants.DELETE_SCHEDULE_EVENT_PUBLIC:
		case EventActionsConstants.DELETE_SCHEDULE_EVENT_PUBLIC_SUCCESS:
		case EventActionsConstants.GET_EVENT_PROMOTERS_SUCCESS:
		case EventActionsConstants.SET_PROMOTERS_INCENTIVE_SUCCESS:
		case EventActionsConstants.GET_EVENT_PROMOTER_NETWORKS_SUCCESS:
		case EventActionsConstants.CREATE_PROMOTER_NETWORK_SUCCESS:
		case EventActionsConstants.GET_EVENT_GUEST_LIST_SUCCESS:
		case EventActionsConstants.GET_SELECTED_GUEST_INFO:
		case EventActionsConstants.GET_SELECTED_GUEST_INFO_SUCCESS:
		case EventActionsConstants.GET_REGISTRATIONS_SUCCESS:
		case EventActionsConstants.UPDATE_REGISTRATION_SETTINGS:
		case EventActionsConstants.UPDATE_REGISTRATION_SETTINGS_SUCCESS:
		case EventActionsConstants.GET_REGISTRATION_SETTINGS:
		case EventActionsConstants.GET_REGISTRATION_SETTINGS_SUCCESS:
		case EventActionsConstants.GET_EVENT_SCANNERS:
		case EventActionsConstants.GET_EVENT_SCANNERS_SUCCESS:
		case EventActionsConstants.NEW_PIN_EVENT_SCANNER:
		case EventActionsConstants.NEW_PIN_EVENT_SCANNER_SUCCESS:
		case EventActionsConstants.ADD_EVENT_SCANNERS:
		case EventActionsConstants.ADD_EVENT_SCANNERS_SUCCESS:
		case EventActionsConstants.EDIT_EVENT_SCANNERS:
		case EventActionsConstants.EDIT_EVENT_SCANNERS_SUCCESS:
		case EventActionsConstants.GET_EVENT_REQUEST_FIELD_SERVICES:
		case EventActionsConstants.GET_EVENT_REQUEST_FIELD_SERVICES_SUCCESS:
		case EventActionsConstants.GET_EVENT_DESIGN:
		case EventActionsConstants.GET_EVENT_DESIGN_SUCCESS:
		case EventActionsConstants.UPDATE_EVENT_DESIGN:
		case EventActionsConstants.UPDATE_EVENT_DESIGN_SUCCESS:
		case EventActionsConstants.GET_EVENT_DEFINITIONS:
		case EventActionsConstants.GET_EVENT_DEFINITIONS_SUCCESS:
		case EventActionsConstants.UPDATE_EVENT_DEFINITIONS:
		case EventActionsConstants.UPDATE_EVENT_DEFINITIONS_SUCCESS:
			return entityReducerSelector(state, action)(action.payload.id)(entityReducer);
		case EventActionsConstants.GET_EVENT_WEBHOOKS:
		case EventActionsConstants.GET_EVENT_WEBHOOKS_SUCCESS:
		case EventActionsConstants.UPDATE_EVENT_WEBHOOKS:
		case EventActionsConstants.UPDATE_EVENT_WEBHOOKS_SUCCESS:
		case EventActionsConstants.TEST_EVENT_WEBHOOKS:
		case EventActionsConstants.TEST_EVENT_WEBHOOKS_SUCCESS:
		case EventActionsConstants.GET_FACEBOOK_INTEGRATION_DATA:
		case EventActionsConstants.GET_FACEBOOK_INTEGRATION_DATA_SUCCESS:
		case EventActionsConstants.UPDATE_EVENT_FACEBOOK_DATA:
		case EventActionsConstants.UPDATE_EVENT_FACEBOOK_DATA_SUCCESS:
		case EventAtTheDoorConstants.GET_EVENT_ATTHEDOOR:
		case EventAtTheDoorConstants.GET_EVENT_ATTHEDOOR_SUCCESS:
		case TicketsActionsConstant.STOP_TICKET_SALES_SUCCESS:
		case TicketsActionsConstant.MARK_TICKETS_AS_SOLD_OUT_SUCCESS:
		case TicketsActionsConstant.GET_SEATING_CATEGORIES:
		case FileActionsConstants.REMOVE_EVENT_IMAGE:
		case FileActionsConstants.REMOVE_TICKET_IMAGE:
		case FileActionsConstants.UPLOAD_EVENT_IMAGE_SUCCESS:
		case FileActionsConstants.UPLOAD_TICKET_IMAGE_SUCCESS:
		case TicketsActionsConstant.GET_SEATING_CATEGORIES_SUCCESS:
		case StreamingActionConstants.SET_EVENT_STREAMING_ENABLED:
		case StreamingActionConstants.SET_EVENT_STREAMING_ENABLED_SUCCESS:
		case EventActionsConstants.GET_EVENT_TICKET_GROUPS:
		case EventActionsConstants.GET_EVENT_TICKET_GROUPS_SUCCESS:
		case EventActionsConstants.DELETE_TICKET_GROUP:
		case EventActionsConstants.DELETE_TICKET_GROUP_SUCCESS:
		case EventActionsConstants.UPSERT_TICKET_GROUP:
		case EventActionsConstants.UPSERT_TICKET_GROUP_SUCCESS:
		case EventActionsConstants.SET_TICKET_GROUPS_USAGE:
		case EventActionsConstants.SET_TICKET_GROUPS_USAGE_SUCCESS:
		case EventActionsConstants.GET_EVENT_LINK_CAMPAIGNS:
		case EventActionsConstants.GET_EVENT_LINK_CAMPAIGNS_SUCCESS:
		case EventActionsConstants.DELETE_EVENT_LINK_CAMPAIGN:
		case EventActionsConstants.DELETE_EVENT_LINK_CAMPAIGN_SUCCESS:
		case EventActionsConstants.ADD_EVENT_LINK_CAMPAIGN:
		case EventActionsConstants.ADD_EVENT_LINK_CAMPAIGN_SUCCESS:
		case EventActionsConstants.SET_COLLECT_INFO_PROPERTY:
		case EventActionsConstants.SET_COLLECT_INFO_PROPERTY_SUCCESS:
		case EventActionsConstants.DELETE_COLLECT_INFO:
		case EventActionsConstants.DELETE_COLLECT_INFO_SUCCESS:
		case EventActionsConstants.GET_PROFILE_COLLECT_INFO:
		case EventActionsConstants.GET_PROFILE_COLLECT_INFO_SUCCESS:
		case EventActionsConstants.GET_EVENT_COLLECT_INFO:
		case EventActionsConstants.GET_EVENT_COLLECT_INFO_SUCCESS:
		case EventActionsConstants.UPSERT_EVENT_COLLECT_INFO:
		case EventActionsConstants.REORDER_COLLECT_INFO:
		case EventActionsConstants.REORDER_COLLECT_INFO_SUCCESS:
		case EventActionsConstants.UPSERT_EVENT_COLLECT_INFO_SUCCESS:
		case EventActionsConstants.REORDER_TICKET_GROUP:
		case EventActionsConstants.REORDER_TICKET_GROUP_SUCCESS:
		case StreamingActionConstants.GET_EVENT_STREAMING:
		case StreamingActionConstants.GET_EVENT_STREAMING_SUCCESS:
		case StreamingActionConstants.DELETE_EVENT_STREAMING_ROOM:
		case StreamingActionConstants.DELETE_EVENT_STREAMING_ROOM_SUCCESS:
		case StreamingActionConstants.SET_EVENT_STREAMING_SETTINGS:
		case StreamingActionConstants.SET_EVENT_STREAMING_SETTINGS_SUCCESS:
		case StreamingActionConstants.UPDATE_EVENT_STREAMING:
		case StreamingActionConstants.UPDATE_EVENT_STREAMING_SUCCESS:
		case StreamingActionConstants.REQUEST_VOD_DOWNLOAD_LINK:
		case StreamingActionConstants.REQUEST_VOD_DOWNLOAD_LINK_SUCCESS:
		case StreamingActionConstants.SET_STREAMING_FORM_MODAL_FLAG:
		case StreamingActionConstants.SET_EVENT_STREAMING_FETCHING:
		case StreamingActionConstants.REORDER_STREAMS:
		case StreamingActionConstants.REORDER_STREAMS_SUCCESS:
		case StreamingActionConstants.SET_ORGANISER_PREVIEW_CODE:
		case StreamingActionConstants.SET_ORGANISER_PREVIEW_CODE_SUCCESS:
		case StreamingActionConstants.DELETE_ORGANISER_PREVIEW_CODE:
		case StreamingActionConstants.DELETE_ORGANISER_PREVIEW_CODE_SUCCESS:
		case EventActionsConstants.GET_EVENT_SCHEDULE_TRANSFER:
		case EventActionsConstants.GET_EVENT_SCHEDULE_TRANSFER_SUCCESS:
		case EventActionsConstants.UPDATE_EVENT_SCHEDULE_TRANSFER:
		case EventActionsConstants.UPDATE_EVENT_SCHEDULE_TRANSFER_SUCCESS:
		case EventActionsConstants.GET_EVENT_ORDERS:
		case EventActionsConstants.GET_EVENT_ORDERS_SUCCESS:
		case EventActionsConstants.GET_EVENT_TABBED_CONTENT:
		case EventActionsConstants.GET_EVENT_TABBED_CONTENT_SUCCESS:
		case EventActionsConstants.REORDER_TABBED_CONTENT:
		case EventActionsConstants.REORDER_TABBED_CONTENT_SUCCESS:
		case EventActionsConstants.SET_TAB_CONTENT_VISIBILITY:
		case EventActionsConstants.SET_TAB_CONTENT_VISIBILITY_SUCCESS:
		case EventActionsConstants.DELETE_TAB_CONTENT:
		case EventActionsConstants.DELETE_TAB_CONTENT_SUCCESS:
		case EventActionsConstants.UPDATE_EVENT_SKIP_COMPLETION_ITEM_SUCCESS:
		case EventActionsConstants.COPY_EVENT:
		case EventActionsConstants.COPY_EVENT_SUCCESS:
		case EventActionsConstants.GET_COPY_EVENT_INFO:
		case EventActionsConstants.GET_COPY_EVENT_INFO_SUCCESS:
		case EventActionsConstants.GET_ORDER_DETAILS_SUCCESS:
			return entityReducerSelector(state, action)(action.payload.eventId)(entityReducer);
		case FileActionsConstants.REMOVE_EVENT_IMAGE_FAILED:
		case EventActionsConstants.GET_FACEBOOK_INTEGRATION_DATA_FAILED:
		case EventActionsConstants.UPDATE_EVENT_FACEBOOK_DATA_FAILED:
		case StreamingActionConstants.SET_EVENT_STREAMING_ENABLED_FAILED:
		case EventActionsConstants.SET_COLLECT_INFO_PROPERTY_FAILED:
		case EventActionsConstants.DELETE_COLLECT_INFO_FAILED:
		case EventActionsConstants.GET_PROFILE_COLLECT_INFO_FAILED:
		case EventActionsConstants.GET_EVENT_COLLECT_INFO_FAILED:
		case EventActionsConstants.UPSERT_EVENT_COLLECT_INFO_FAILED:
		case EventActionsConstants.REORDER_COLLECT_INFO_FAILED:
		case EventActionsConstants.REORDER_TICKET_GROUP_FAILED:
		case StreamingActionConstants.DELETE_EVENT_STREAMING_ROOM_FAILED:
		case StreamingActionConstants.REORDER_STREAMS_FAILED:
		case EventActionsConstants.GET_EVENT_SCANNERS_FAILED:
		case EventActionsConstants.NEW_PIN_EVENT_SCANNER_FAILED:
		case EventActionsConstants.ADD_EVENT_SCANNERS_FAILED:
		case EventActionsConstants.GET_EVENT_ORDERS_FAILED:
		case EventActionsConstants.GET_EVENT_GUEST_LIST_FAILED:
		case EventActionsConstants.GET_EVENT_TABBED_CONTENT_FAILED:
		case EventActionsConstants.REORDER_TABBED_CONTENT_FAILED:
		case EventActionsConstants.SET_TAB_CONTENT_VISIBILITY_FAILED:
		case EventActionsConstants.DELETE_TAB_CONTENT_FAILED:
			return entityReducerSelector(state, action)(action.payload.action.payload.eventId)(entityReducer);
		case EventActionsConstants.SET_EVENT_REDIRECT_PRODUCT_ID_FAILED:
		case EventActionsConstants.EDIT_EVENT_SCANNERS_FAILED:
		case EventActionsConstants.GET_COPY_EVENT_INFO_FAILED:
		case EventActionsConstants.UPDATE_EVENT_FAILED:
		case EventActionsConstants.GET_EVENT_DETAILS_FAILED:
		case TicketsActionsConstant.GET_EVENT_TICKETS_FAILED:
		case TicketsActionsConstant.GET_TICKET_SETTINGS_FAILED:
		case EventActionsConstants.UPDATE_EVENT_SETTINGS_FAILED:
		case EventActionsConstants.GET_EVENT_SETTINGS_FAILED:
		case TicketsActionsConstant.UPDATE_TICKET_SETTINGS_FAILED:
		case OrganiserProfileActionConstants.GET_EVENT_ORGANISER_FAILED:
		case OrganiserProfileActionConstants.SAVE_EVENT_ORGANISER_FAILED:
		case EventActionsConstants.SET_EVENT_SERVICE_FEE_FAILED:
		case EventActionsConstants.SET_EVENT_COVID_COMPLIANT_FAILED:
		case EventActionsConstants.SET_EVENT_LISTING_FAILED:
		case EventActionsConstants.SUBMIT_EVENT_FOR_APPROVAL_FAILED:
		case EventActionsConstants.GET_EVENT_DASHBOARD_FAILED:
		case EventActionsConstants.GET_EVENT_DASHBOARD_REPORTS_FAILED:
		case EventActionsConstants.SET_EVENT_SHORT_LINK_FAILED:
		case EventActionsConstants.CANCEL_EVENT_FAILED:
		case EventActionsConstants.CHANGE_EVENT_DATES_FAILED:
		case EventActionsConstants.COPY_EVENT_FAILED:
		case StreamingActionConstants.GET_EVENT_STREAMING_FAILED:
		case CollectionsActionConstants.GET_EVENT_COLLECTIONS_FAILED:
		case WidgetActionConstants.GET_EVENT_WIDGET_FAILED:
		case CollectionsActionConstants.UPDATE_EVENT_COLLECTIONS_FAILED:
		case EventActionsConstants.SCHEDULE_EVENT_PUBLIC_FAILED:
		case EventActionsConstants.DELETE_SCHEDULE_EVENT_PUBLIC_FAILED:
		case EventActionsConstants.GET_SELECTED_GUEST_INFO_FAILED:
		case EventActionsConstants.GET_EVENT_DEFINITIONS_FAILED:
		case EventActionsConstants.UPDATE_EVENT_DEFINITIONS_FAILED:
		case EventActionsConstants.UPDATE_EVENT_DESIGN_FAILED:
		case EventActionsConstants.GET_EVENT_DESIGN_FAILED:
			return entityReducerSelector(state, action)(action.payload.action.payload.id)(entityReducer);
		case FileActionsConstants.REMOVE_TICKET_IMAGE_FAILED:
		case EventActionsConstants.GET_EVENT_TICKET_GROUPS_FAILED:
		case EventActionsConstants.SET_TICKET_GROUPS_USAGE_FAILED:
		case EventActionsConstants.DELETE_TICKET_GROUP_FAILED:
		case EventActionsConstants.UPSERT_TICKET_GROUP_FAILED:
		case EventActionsConstants.GET_EVENT_LINK_CAMPAIGNS_FAILED:
		case EventActionsConstants.ADD_EVENT_LINK_CAMPAIGN_FAILED:
		case StreamingActionConstants.UPDATE_EVENT_STREAMING_FAILED:
		case StreamingActionConstants.REQUEST_VOD_DOWNLOAD_LINK_FAILED:
		case StreamingActionConstants.SET_EVENT_STREAMING_SETTINGS_FAILED:
		case StreamingActionConstants.GET_EVENT_STREAMING_FAILED:
		case StreamingActionConstants.SET_ORGANISER_PREVIEW_CODE_FAILED:
		case StreamingActionConstants.DELETE_ORGANISER_PREVIEW_CODE_FAILED:
		case EventActionsConstants.GET_EVENT_SCHEDULE_TRANSFER_FAILED:
		case EventActionsConstants.UPDATE_EVENT_SCHEDULE_TRANSFER_FAILED:
			return entityReducerSelector(state, action)(action.payload.action.payload.eventId)(entityReducer);
		case EventActionsConstants.GET_EVENT_METADATA:
			return initialState.entities.byId;
		case EventActionsConstants.GET_EVENT_REQUEST_FIELD_SERVICES_FAILED:
		default:
			return state;
	}
};
