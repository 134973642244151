import { AppState } from '@app/models/store.model';

export const user = () =>
	(state: AppState) => state.user;

export const userDesignerKey = () =>
	(state: AppState) => state.user ? state.user.info.seatsDesignerKey : null;

export const isAdmin = () =>
	(state: AppState) => state.user ? state.user.info.isAdmin : false;

export const isUserVerified = () =>
	(state: AppState) => state.user && state.user.info && !!state.user.info.id;

export const isUserFetching = () =>
	(state: AppState) => state.user.isFetching;

export const userInfo = () =>
	(state: AppState) => state.user ? state.user.info : null;

export const emailEncrypt = () =>
	(state: AppState) => state.user ? state.user.info.emailEncrypt : null;

export const userContactInfo = () =>
	(state: AppState) => state.user ? state.user.info.contactInfo : null;

export const userId = () =>
	(state: AppState) => state.user ? state.user.info.id : null;

export const UserAPIToken = () =>
	(state: AppState) => state.user ? state.user.info.apiToken : null;

export const IsAPIModalOpen = () =>
	(state: AppState) => state.user.isAPIModalOpen;

export const getUserReferral = () =>
	(state: AppState) =>
		state.user && state.user.earnCommission.referrals
			? state.user.earnCommission.referrals
			: null;

export const userMyTickets = () =>
	(state: AppState) =>
		state.user && state.user.myTickets
			? state.user.myTickets.bookings.all.map(bookingId => state.user.myTickets.bookings.byId[bookingId])
			: null;

export const getUserTicket = (id: number) =>
	(state: AppState) =>
		state.user && state.user.myTickets
			? state.user.myTickets.bookings.byId[id]
			: null;

export const userMyRegistrations = () =>
	(state: AppState) =>
		state.user && state.user.myTickets
			? state.user.myTickets.registrations
			: null;

export const currentRefundableTickets = () =>
	(state: AppState) =>
		state.user && state.user.myTickets
			? state.user.myTickets.currentRefundableTickets
			: null;

export const isFundraiserInvoiceModalOpen = () =>
	(state: AppState) =>
		state.user && state.user.myTickets
			? state.user.myTickets.isFundraiserModalOpen
			: null;

export const userMyTicketsIsFetching = () =>
	(state: AppState) =>
		state.user && state.user.myTickets
			? state.user.myTickets.isFetching
			: null;

export const currentTransferableTickets = () =>
	(state: AppState) =>
		state.user && state.user.myTickets.currentTransferableTickets
			? state.user.myTickets.currentTransferableTickets
			: null;

export const currentResaleTickets = () =>
	(state: AppState) =>
		state.user && state.user.myTickets.currentResaleTickets
			? state.user.myTickets.currentResaleTickets
			: null;

export const promoterNetworks = () =>
	(state: AppState) =>
		state.user && state.user.earnCommission.promoterNetworks
			? state.user.earnCommission.promoterNetworks
			: null;

export const currentPromoterDetails = () =>
	(state: AppState) =>
		state.user && state.user.earnCommission.currentPromoterDetails
			? state.user.earnCommission.currentPromoterDetails
			: null;

export const userManageBooking = () =>
	(state: AppState) =>
		state.user && state.user.manageBooking
			? state.user.manageBooking.booking
			: null;

export const userManageBookingInvoice = () =>
	(state: AppState) =>
		state.user && state.user.manageBooking && state.user.manageBooking.booking
			? state.user.manageBooking.booking.invoice
			: null;

export const isUserManageBookingFetching = () =>
	(state: AppState) =>
		state.user && state.user.manageBooking
			? state.user.manageBooking.isFetching
			: null;

export const isUpdateBookingSuccess = () =>
	(state: AppState) =>
		state.user && state.user.manageBooking
			? state.user.manageBooking.isUpdateSuccess
			: null;

export const isUpdateBookingFailed = () =>
	(state: AppState) =>
		state.user && state.user.manageBooking
			? state.user.manageBooking.isUpdateFailed
			: null;

export const userManageBookingTickets = () =>
	(state: AppState) =>
		state.user && state.user.manageBooking && state.user.manageBooking.booking
			? state.user.manageBooking.booking.tickets
			: null;

export const isEditInvoiceModalOpen = () =>
	(state: AppState) =>
		state.user && state.user.manageBooking
			? state.user.manageBooking.isInvoiceModalOpen
			: null;

export const isBookingChangeDatesModalOpen = () =>
	(state: AppState) =>
		state.user && state.user.manageBooking
			? state.user.manageBooking.isChangeDatesModalOpen
			: null;

export const userManageBookingSchedules = () =>
	(state: AppState) =>
		state.user && state.user.manageBooking && state.user.manageBooking.booking
			? state.user.manageBooking.booking.schedules
			: null;

export const userSavedEvents = () =>
	(state: AppState) =>
		state.user && state.user.userSavedEvents
			? state.user.userSavedEvents.userSavedEvents
			: null;
