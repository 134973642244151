<div *ngIf="!isGuestLoading else guestListLoading" class="d-flex flex-column">
	<app-schedule-selector *ngIf="isOngoing" [scheduleItems]="scheduleItems"
	(SelectedScheduleOutput)="handleScheduleFilterOutput($event)" [isMobile]="isMobile"></app-schedule-selector>
	<app-web-filter [searchPlaceholder]="searchPlaceholder" [isMobile]="isMobile" [reportButton]="true"
	(buttonClick)="handleDownloadReport()" (filterOutput)="handleWebFilterOutput($event)"></app-web-filter>
	<div *ngIf="hasInitialGuests; else noGuests" class='mt-0 pb-5'>
		<div class="l-table-container" *ngIf="guests.length; else noMatch">
			<div *ngIf='isGuestListFiltering' class='l-filtering'>
				<mat-spinner></mat-spinner>
			</div>
			<table mat-table [dataSource]="guests"
				class='l-table web' matSort
				(matSortChange)="handleSort($event)">
				<ng-container matColumnDef="name">
					<th mat-header-cell *matHeaderCellDef mat-sort-header> Guest Names </th>
					<td mat-cell *matCellDef="let guest">{{guest.guestName}}</td>
				</ng-container>
				<ng-container matColumnDef="ticketType">
					<th mat-header-cell *matHeaderCellDef mat-sort-header> Ticket Type </th>
					<td mat-cell *matCellDef="let guest">{{ guest.ticketType }} </td>
				</ng-container>
				<ng-container matColumnDef="email">
					<th mat-header-cell *matHeaderCellDef mat-sort-header> Email</th>
					<td mat-cell *matCellDef="let guest">{{ guest.guestEmail }} </td>
				</ng-container>
				<ng-container matColumnDef="contact">
					<th mat-header-cell *matHeaderCellDef> Contact Number </th>
					<td mat-cell *matCellDef="let guest"> {{ guest.guestContactNumber }}
					</td>
				</ng-container>
				<ng-container matColumnDef="moreInfo">
					<th mat-header-cell *matHeaderCellDef></th>
					<td mat-cell *matCellDef="let guest">
						<div (click)="handleMoreInfo(guest.purchaseId, guest.purchaseItemId)">
							<mat-icon>more_vert</mat-icon>
						</div>
					</td>
				</ng-container>
				<tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
			</table>
		</div>
		<mat-paginator #eventsPaginator [length]="resultsLength" [pageSizeOptions]="pageSizeOptions"
			[pageIndex]="currentPageIndex" [pageSize]="pageSize" (page)="handlePagination($event)"></mat-paginator>
		<ng-template #noMatch>
			<div class="l-empty-state">
				<h3 class="mb-3">Nothing to see here...</h3>
				<div class="mb-3">Nothing matches your search or filters</div>
			</div>
		</ng-template>
	</div>
	<ng-template #noGuests>
		<div class='l-empty-card'>
			<div class="l-empty-state">
				<h3 class="mb-3">You currently have no guests for your event.</h3>
			</div>
		</div>
	</ng-template>
</div>
<ng-template #guestListLoading>
	<div class="l-loading">
		<mat-spinner></mat-spinner>
	</div>
</ng-template>
