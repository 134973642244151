<div *ngIf="!isMobile; else mobileHeaderDescription">
    <p class="l-header" [ngClass]="{ mobile: isMobile }">
        Customise the ticket and confirmation email that ticket buyers receive. Please note that the ticket stub information and
        image provided here will apply to all ticket types. To add unique image or ticket stub information for each ticket type,
        please go to your
        <span id="clickable-route-to-tickets-page" class="g-pointer g-highlight g-green" (click)="goToTicketsPage()"
            >tickets page</span
        >, and click on the cog icon next to each ticket.
    </p>
</div>
<ng-template #mobileHeaderDescription>
    <p *ngIf="!readMore; else showAll" class="l-header">
        {{ customDescription }}
        <span (click)="handleReadMore()" id="read-less-button" class="g-pointer g-highlight g-green">
            {{ readMoreText }}
        </span>
    </p>
</ng-template>
<ng-template #showAll>
    <p class="l-header" [ngClass]="{ mobile: isMobile }">
        Customise the ticket and confirmation email that ticket buyers receive. Please note that the ticket stub information and
        image provided here will apply to all ticket types. To add unique image or ticket stub information for each ticket type,
        please go to your
        <span id="clickable-route-to-tickets-page" class="g-pointer g-highlight g-green" (click)="goToTicketsPage()"
            >tickets page</span
        >, and click on the cog icon next to each ticket.
        <span (click)="handleReadMore()" id="read-less-button" class="g-pointer g-highlight g-green">
            {{ readMoreText }}
        </span>
    </p>
</ng-template>