import { Component, EventEmitter, OnInit } from '@angular/core';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { ActivatedRoute } from '@angular/router';
import { EVENT_ID_PARAM_KEY, SEND_OPTIONS } from '@app/utils/consts';
import { take } from 'rxjs';
import { SchemeID } from '@app/models/dataSchema.model';
import { Dictionary } from '@ngrx/entity';
import { DropdownOption, HeaderContent } from '@app/models/shared';
import { InternalURLCreator } from '@app/services/url/url.dictionary';

@Component({
	selector: 'app-orders',
	templateUrl: './orders.component.html',
	styleUrls: ['./orders.component.sass'],
})
export class OrdersComponent implements OnInit {
	isMobile = false;

	eventId: SchemeID;
	sendOptions: Dictionary<DropdownOption> = SEND_OPTIONS;

	headerContent: HeaderContent = {
		breadCrumbs: [
			{
				routeName: 'Guest Management',
				routeTo: () => InternalURLCreator.guestManagement(this.eventId),
			},
			{
				routeName: 'Orders',
			},
		],
		title: 'Orders',
		description: 'View your orders below. You can access purchaser details, edit tickets, or cancel them as needed.',
	};

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private breakpointService: BreakpointService,
		private activatedRoute: ActivatedRoute
	) {
	}

	ngOnInit(): void {
		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});

		this.activatedRoute.parent.paramMap.pipe(take(1)).subscribe((params) => {
			this.eventId = +params.get(EVENT_ID_PARAM_KEY);
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}

