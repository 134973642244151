import { Injectable } from '@angular/core';
import { FormService } from '@app/services/form/form.service';
import { EventWidget } from '@app/models/event.model';
import { FormSchemeControls } from '@app/models/common.model';


@Injectable()
export class WidgetService {
	constructor(
		private formService: FormService
	) {}

	createWidgetForm = (initialValues?: Partial<EventWidget>) => {
		const formValues = new EventWidget();

		const formConfig: Partial<FormSchemeControls<EventWidget>> = this.formService.createFormControls({
			formValues,
			initialValues,
			validatorsConfig: {},
		});

		return this.formService.formBuilder.group(formConfig);
	};
}
