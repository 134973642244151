import { Component, EventEmitter, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SchemeID } from '@app/models/dataSchema.model';
import { EventScheduleTransferOptions, EventScheduleTransferSettings } from '@app/models/event.model';
import { HeaderContent } from '@app/models/shared';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { GuestManagementService } from '@app/services/events/guest-management/guest-management.service';
import { StoreService } from '@app/services/store/store.service';
import { InternalURLCreator } from '@app/services/url/url.dictionary';
import { GetEventScheduleTransfer, UpdateEventScheduleTransfer } from '@app/store/actions/event/event.actions';
import { EventActionsConstants } from '@app/store/actions/event/event.actions.constants';
import * as eventSelectors from '@app/store/selectors/event.selector';
import { EVENT_ID_PARAM_KEY } from '@app/utils/consts';
import { Actions, ofType } from '@ngrx/effects';
import { combineLatest, take, takeUntil } from 'rxjs';
import { DatePipe } from '@angular/common';
import moment from 'moment';
import { SelectFieldOption } from '@app/shared/form-field/select-field/select-field.model';

@Component({
	selector: 'app-transfer-schedule',
	templateUrl: './transfer-schedule.component.html',
	styleUrls: ['./transfer-schedule.component.sass'],
})
export class TransferScheduleComponent implements OnInit {
	isMobile = false;
	isSchedulesLoading = true;
	hasSales: boolean;
	eventId: number;
	eventDetailsLink: string;

	headerContent: HeaderContent = {
		breadCrumbs: [
			{
				routeName: 'Guest Management',
				routeTo: () => InternalURLCreator.guestManagement(this.eventId),
			},
			{
				routeName: 'Transfer Schedules',
			},
		],
		title: 'Transfer Schedules',
	};
	tooltipText = 'Ticket buyers will receive a confirmation email with the new date that includes the reason they were transferred.';
	customDescription = 'Transfer attendees from one schedule in your event to another by selecting the old and new dates below...';
	readMore = false;
	readMoreText = 'Read more';

	form: UntypedFormGroup;
	moveFromOptions:  SelectFieldOption<SchemeID>[] = [];
	moveToOptions:  SelectFieldOption<SchemeID>[] = [];
	scheduleTransferSettings: EventScheduleTransferSettings;
	scheduleTransferOptions: EventScheduleTransferOptions[];

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private activatedRoute: ActivatedRoute,
		private breakpointService: BreakpointService,
		private store: StoreService,
		private guestManagementService: GuestManagementService,
		private actions$: Actions,
		private datePipe: DatePipe
	) {}

	ngOnInit(): void {
		this.activatedRoute.parent.paramMap.pipe(take(1)).subscribe((params) => {
			this.eventId = +params.get(EVENT_ID_PARAM_KEY);
		});

		this.eventDetailsLink =  InternalURLCreator.eventDetails(this.eventId);

		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});
		this.eventListeners();
		this.scheduleOptionsAndSettings();
		this.store.dispatch(new GetEventScheduleTransfer({eventId: this.eventId}));
	}

	scheduleOptionsAndSettings() {
		const transferOptions$ = this.store.select(eventSelectors.eventScheduleTransferOptions())
			.pipe(takeUntil(this.destroyed$));

		const transferSettings$ = this.store.select(eventSelectors.eventScheduleTransferSettings())
			.pipe(takeUntil(this.destroyed$));

		combineLatest([transferOptions$, transferSettings$]).pipe(takeUntil(this.destroyed$))
			.subscribe(([scheduleTransferOptions, scheduleTransferSettings]) => {
				this.scheduleTransferOptions = scheduleTransferOptions;
				this.scheduleTransferSettings = scheduleTransferSettings;
				this.sortEventOptions();
				this.form = this.guestManagementService.createScheduleTransferSettingsForm(this.scheduleTransferSettings);
			});
	}

	sortEventOptions() {
		if (this.scheduleTransferOptions && this.scheduleTransferSettings) {
			const today = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ssZ');
			this.moveFromOptions = this.scheduleTransferOptions.filter(scheduleItem =>
				scheduleItem.validTicketCount > 0).map(
				({ dateFrom, validTicketCount, id, dateTo, hidden, scheduleItemName, isCancelled }) => ({
					label: this.getScheduleItemLabel(dateFrom, dateTo, validTicketCount, hidden, scheduleItemName, isCancelled),
					value: id,
				})
			);
			if (this.scheduleTransferSettings && this.scheduleTransferSettings.isSeated === true) {
				this.moveToOptions = this.scheduleTransferOptions.filter(scheduleItem => scheduleItem.validTicketCount === 0 &&
						moment(scheduleItem.dateTo).isAfter(moment(today))).map(
					({ dateFrom, validTicketCount, id, dateTo, hidden, scheduleItemName, isCancelled }) => ({
						label: this.getScheduleItemLabel(dateFrom, dateTo, validTicketCount, hidden, scheduleItemName, isCancelled),
						value: id,
					})
				);
			} else if (this.scheduleTransferSettings && this.scheduleTransferSettings.isSeated === false) {
				this.moveToOptions = this.scheduleTransferOptions.filter(scheduleItem =>
					moment(scheduleItem.dateTo).isAfter(moment(today))).map(
					({ dateFrom, validTicketCount, id, dateTo, hidden, scheduleItemName, isCancelled }) => ({
						label: this.getScheduleItemLabel(dateFrom, dateTo, validTicketCount, hidden, scheduleItemName, isCancelled),
						value: id,
					})
				);
			}
			this.hasSales = this.scheduleTransferOptions.some(scheduleItem => scheduleItem.validTicketCount > 0);
		}
	}

	getScheduleItemLabel (
		date: Date,
		dateTo: Date,
		ticketCount: number,
		hidden: boolean,
		scheduleItemName: string,
		isCancelled: boolean
	) {
		return `${this.datePipe.transform(date, 'd MMM y, h:mm a')}
		- ${this.datePipe.transform(dateTo, 'd MMM y, h:mm a')}
		- ${scheduleItemName && scheduleItemName.length > 0 ?  scheduleItemName + ' - ' : ''}
		(${ticketCount}) attendees ${hidden ? ' (hidden)' : ''} ${isCancelled ? ' - (CANCELLED)' : ''}`;
	}


	eventListeners() {
		this.actions$
			.pipe(
				ofType(
					EventActionsConstants.GET_EVENT_SCHEDULE_TRANSFER_SUCCESS,
					EventActionsConstants.GET_EVENT_SCHEDULE_TRANSFER_FAILED,
					EventActionsConstants.UPDATE_EVENT_SCHEDULE_TRANSFER_SUCCESS,
					EventActionsConstants.UPDATE_EVENT_SCHEDULE_TRANSFER_FAILED
				),
				takeUntil(this.destroyed$)
			)
			.subscribe(() => {
				this.isSchedulesLoading = false;
			});
	}

	submitForm() {
		this.isSchedulesLoading = true;
		const formValues: EventScheduleTransferSettings = this.form.getRawValue();

		this.store.dispatch(new UpdateEventScheduleTransfer({
			eventId: this.eventId,
			settings: formValues,
		}));
	}

	fromForm(controlKey: string): AbstractControl {
		if (this.form) {
			return this.form.get(controlKey);
		}
		return null;
	}

	isSubmitAllowed() {
		return this.form && this.form.valid && !this.form.pristine;
	}

	updateValidators() {
		this.fromForm('moveFromId').updateValueAndValidity();
		this.fromForm('moveToId').updateValueAndValidity();
	}

	handleReadMore() {
		this.readMore = !this.readMore;
		this.readMoreText = this.readMore ? 'Read Less' : 'Read More';
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}
}
