import { Component, EventEmitter } from '@angular/core';
import { HeaderContent } from '@app/models/shared';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { StoreService } from '@app/services/store/store.service';
import { InternalURLCreator, URLCreator } from '@app/services/url/url.dictionary';
import { Go } from '@app/store/actions/router/router.actions';
import { MY_ACCOUNT_HEADER } from '@app/utils/consts';
import { takeUntil } from 'rxjs';

@Component({
	selector: 'app-vending',
	templateUrl: './vending.component.html',
	styleUrls: ['./vending.component.sass'],
})
export class VendingComponent {
	url: string;
	isMobile = false;

	headerContent: HeaderContent = MY_ACCOUNT_HEADER['vending'];


	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private breakpointService: BreakpointService,
		private store: StoreService
	) {}

	ngOnInit(): void {
		this.url = URLCreator.vending();

		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}

	handleNavigateBack(){
		this.store.dispatch(new Go({ path: [InternalURLCreator.myAccount()] }));
	}

}
