import { Component, EventEmitter } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { StoreService } from '@app/services/store/store.service';
import { InternalURLCreator } from '@app/services/url/url.dictionary';
import { Go } from '@app/store/actions/router/router.actions';
import { getCurrentPage } from '@app/utils/common-helpers';
import { OrganiserHubSubRoutes } from '@app/utils/consts';
import { filter, takeUntil } from 'rxjs';

@Component({
	selector: 'app-organiser-profile-parent',
	templateUrl: './organiser-profile-parent.component.html',
	styleUrls: ['./organiser-profile-parent.component.sass'],
})
export class OrganiserProfileParentComponent {
	isMobile = false;
	pages = [OrganiserHubSubRoutes.DETAILS, OrganiserHubSubRoutes.MAILCHIMP];
	currentPage: string;
	isDetailsTab: boolean;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private breakpointService: BreakpointService,
		private store: StoreService,
		private router: Router
	) { }

	ngOnInit(): void {
		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});
		this.handleNavigation(this.router.url);


		this.router.events.pipe(
			filter(event => event instanceof NavigationEnd),
			takeUntil(this.destroyed$)
		).subscribe((event: NavigationEnd) => {
			this.handleNavigation(event.urlAfterRedirects);
		});
	}

	handleNavigation(url: string) {
		this.currentPage = getCurrentPage(url);
		this.isDetailsTab = this.currentPage === OrganiserHubSubRoutes.DETAILS;
	}

	handleNavigateBack() {
		this.store.dispatch(new Go({ path: [InternalURLCreator.orgProfile()] }));
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}

}
