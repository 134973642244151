import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
	selector: 'app-mat-slide-control-field',
	templateUrl: './mat-slide-control-field.component.html',
	styleUrls: ['./mat-slide-control-field.component.sass'],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => MatSlideControlFieldComponent),
		multi: true,
	}],
})
export class MatSlideControlFieldComponent implements ControlValueAccessor {
	value = false;
	@Input() disabled: boolean;
	@Input() label = '';
	@Input() tooltip = '';
	@Input() inputClass = 'l-form-item';
	@Input() isMobile = false;
	@Input() customId = '';
	@Input() required = false;
	@Input() selected = false;

	@Output() change = new EventEmitter<boolean>();

	onControlChange: Function = () => {};
	onControlFocus: Function = () => {};

	ngOnInit(): void {
		this.value = this.selected;
	}

	writeValue(obj: boolean): void {
		this.value = obj;
	}

	registerOnChange(fn: any): void {
		this.onControlChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onControlFocus = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	onChange(event: MatSlideToggleChange) {
		this.onControlChange(event.checked);
		this.change.emit(event.checked);
	}

	onBlur() {
		this.onControlFocus();
	}
}
