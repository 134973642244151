<div class="l-info-button-container">
    <button *ngIf="dashboard.isScheduledEvent" class="l-info-button" id="dashboard-header-event-series-tag">
        Event Series
        <mat-icon class="l-info-button-icon">history</mat-icon>
    </button>
    <a [href]="getPreviewLink()" target="_blank" id="dashboard-header-event-preview-button">
        <button id="preview-button" class="l-info-button">
            Preview
            <mat-icon class="l-info-button-icon">visibility</mat-icon>
        </button>
    </a>
    <button class="l-info-button l-info-button-status" [ngClass]="statusButton.class" id="dashboard-header-event-status-tag">
        {{ statusButton.text }} 
        <mat-icon class="l-info-button-icon">{{ statusButton.icon }}</mat-icon>
    </button>
    <button 
        class="l-info-button l-info-button-gray"
        id="expand-event-visibility-button"
        [matMenuTriggerFor]="visibilityMenu"
		id="dashboard-header-event-visibility-tag"
    >
        {{ dashboard.isPublic ? 'Public' : 'Private' }} 
        <mat-icon class="l-info-button-icon">expand_more</mat-icon>
    </button>
    <mat-menu #visibilityMenu>
        <button (click)="switchEventVisibility()" id="change-visibility-button" mat-menu-item>
            <mat-icon class="l-icon" id="event-visibility-button">content_copy</mat-icon>
            Make {{ dashboard.isPublic ? 'Private' : 'Public' }}
        </button>
        <button 
            *ngIf="!dashboard.isPublic" 
            (click)="scheduleToPublic()"
            id="schedule-to-go-public-modal-button"
            mat-menu-item
        >
            <mat-icon class="l-icon">pending_actions</mat-icon>
            {{ dashboard.scheduledToGoPublicOn ? 'Remove schedule' : 'Schedule Public' }}
        </button>
    </mat-menu>
    <button 
        class="l-info-button" 
        id="dashboard-header-event-redirect"
        (click)="handleRedirectModal()"
        *ngIf="displayRedirectButton"
    >
        Redirect event
        <mat-icon class="l-info-button-icon">refresh</mat-icon>
    </button>
</div>