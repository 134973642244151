import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-stop-sales-modal',
	templateUrl: './stop-sales-modal.component.html',
	styleUrls: ['./stop-sales-modal.component.sass'],
})
export class StopSalesModalComponent {
	endTicketSales = false;
	soldOut = false;

	constructor(
		public dialogRef: MatDialogRef<StopSalesModalComponent>,
		@Inject(MAT_DIALOG_DATA)
		public data: {
			isMobile: boolean;
		}
	) { }

	handleClose() {
		this.dialogRef.close(
			{ endTicketSales: this.endTicketSales, soldOut: this.soldOut }
		);
	}

	handleCancel() {
		this.dialogRef.close();
	}
}
