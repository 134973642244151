import { AppState } from '@app/models/store.model';

export const userPreviousContactListsState = () =>
	(state: AppState) => state.userContacts.previousLists;

export const userPreviousContactLists = () =>
	(state: AppState) => state.userContacts.previousLists.all;

export const isUserPreviousContactListsFetching = () =>
	(state: AppState) => state.userContacts.previousLists.isFetching;

export const userParsedContacts = () =>
	(state: AppState) => state.userContacts.parsedContacts;

export const userParsedContactsList = () =>
	(state: AppState) => state.userContacts.parsedContacts.all;

export const isUserParsedContactsFetching = () =>
	(state: AppState) => state.userContacts.parsedContacts.isFetching;

export const userPreviousEvents = () =>
	(state: AppState) => state.userContacts.previousEvents.all;

export const userPreviousEventsAttendees = () =>
	(state: AppState) => state.userContacts.previousEvents.attendees;

export const isUserPreviousEventsFetching = () =>
	(state: AppState) => state.userContacts.previousEvents.isFetching;

export const isSendInvitesFetching = () =>
	(state: AppState) => state.userContacts.isSendInvitesFetching;

export const activeContactsSource = () =>
	(state: AppState) => state.userContacts.activeContactsSource;
