import { Component, Input, OnInit } from '@angular/core';
import { ChartInput, ChartInputValue } from '@app/models/organiser.model';
import { curveStep } from 'd3-shape';

@Component({
	selector: 'app-dashboard-chart',
	templateUrl: './dashboard-chart.component.html',
	styleUrls: ['./dashboard-chart.component.sass'],
})
export class DashboardChartComponent implements OnInit {
	@Input() chartData: ChartInput[];
	@Input() totalValue: number;
	@Input() showLegend = false;
	@Input() isMobile = false;
	@Input() isSales = true;
	@Input() showTotal = true;
	@Input() currencySymbol: string;

	displayData: ChartInput[];
	isYearView = true;
	selectedMonthLabel: string;
	totalMonthValue: number;

	showLabels = true;
	xAxis = true;
	yAxis = true;
	roundDomains = true;
	curveType = curveStep;

	colorScheme = {
		domain: ['#5eb700', '#FF7C00', '#9683E3', '#7CB2E8'],
	};

	ngOnInit(): void {
		this.displayData = this.chartData.reduce((acc: ChartInput[], curr: ChartInput) => {
			acc.push({
				name: curr.name,
				series: this.aggregateToYearView(curr.series),
			});
			return acc;
		}, []);
	}

	aggregateToYearView(series: ChartInputValue[]): ChartInputValue[] {
		const monthlyAggregates: { [key: string]: number } = {};

		series.forEach((item) => {
			const date = new Date(item.name);
			const monthYear = date.toLocaleString('default', { month: 'short' }) + ' \'' + date.getFullYear().toString().slice(-2);

			if (!monthlyAggregates[monthYear]) {
				monthlyAggregates[monthYear] = 0;
			}
			monthlyAggregates[monthYear] += Number(item.value);
		});

		const result = Object.keys(monthlyAggregates).map((monthYear) => ({
			name: monthYear,
			value: monthlyAggregates[monthYear],
		}));

		return result;
	}

	handleSalesGraphSelect(event: { value: number; name: string; series: string }) {
		if (this.isYearView) {
			this.isYearView = false;
			this.displayData = this.chartData.reduce((acc: ChartInput[], curr: ChartInput) => {
				acc.push({
					name: curr.name,
					series: this.aggregateToMonthView(event.name, curr.series),
				});
				return acc;
			}, []);
		}
	}

	aggregateToMonthView(monthYear: string, series: ChartInputValue[]): ChartInputValue[] {
		const [monthInput, yearInput] = monthYear.split(' ');
		const year = `20${yearInput.substring(1)}`;
		const monthIndex = new Date(`${monthInput} 1 ${year}`).getMonth();
		this.selectedMonthLabel = monthInput + ' ' + year;

		let totalValue = 0;

		const filteredData = series.filter((item) => {
			const itemDate = new Date(item.name);
			return itemDate.getFullYear().toString() === year && itemDate.getMonth() === monthIndex;
		});

		const result = filteredData.map((item) => {
			const itemDate = new Date(item.name);
			totalValue += Number(item.value);
			return {
				name: itemDate.getDate().toString(),
				value: +item.value,
			};
		});

		this.totalMonthValue = totalValue;

		return result;
	}

	handleGraphReset() {
		this.displayData = this.chartData.reduce((acc: ChartInput[], curr: ChartInput) => {
			acc.push({
				name: curr.name,
				series: this.aggregateToYearView(curr.series),
			});
			return acc;
		}, []);

		this.isYearView = true;
	}

	handleFormatYAxisLabel(value: number) {
		let formattedValue = value >= 1000 ? (value / 1000).toFixed(1) + 'k' : Math.floor(value).toString();

		if (this.isSales) {
			formattedValue = 'R' + formattedValue;
		}

		return formattedValue;
	}
}
