<div class="l-container" [ngClass]="{ 'mobile' : isMobile }">
    <div *ngIf="!isLoading && setupProgress else pageLoading;">
        <div class="l-container-flex">
            <div class="l-visual-container">
                <div class="l-header">
                    Let's complete your event setup
                    <mat-icon 
                        class="l-tooltip" 
                        matTooltip="Completing these steps ensures that your event is ready to publish. You can skip those that do not apply to your event."
                        matTooltipPosition="right"
						#progressTooltip="matTooltip"
						(click)="progressTooltip.toggle()"
                    >
                        info
                    </mat-icon>
                </div>
                <circle-progress
                    id="circle-progress"
                    [percent]="getPercentCompleted()"
                    [radius]="70"
                    [outerStrokeWidth]="16"
                    [innerStrokeWidth]="16"
                    [outerStrokeColor]="'rgba(94, 183, 0, 1)'"
                    [innerStrokeColor]="'#F0F4F1'"
                    [animation]="true"
                    [animationDuration]="500"
                    [showUnits]="true"
                    [showInnerStroke]="true"
                    [space]="-16"
                    [showUnits]="false"
                    [showSubtitle]="false"
                    [title]="getProgressText()"
                    [titleFontSize]="24"
                    [titleFontWeight]="700"
                    [titleFontFamily]="'Heebo'"
                    class="l-circle-progress"
                ></circle-progress>
                <div>
                    <button
                        [ngClass]="{ '
                            l-submit-button': true, 
                            'l-submit-button-go-live':  autoApproveEvents,
                        }"
                        [disabled]="!isInDraft()"
                        (click)="submitForApproval()"
                        id="submit-for-approval-button"
                    >
                        {{ getSubmitButtonText() }}
                    </button>
                </div>
            </div>
            <div class="l-items">
                <app-completion-progress-item
                    *ngFor="let item of progressItems"
                    [id]="item.id"
                    [text]="item.text"
                    [buttonText]="item.buttonText"
                    [completed]="item.isCompleted"
                    [link]="item.link"
                    [displaySkip]="!item.isCompleted"
                    (actionClick)="skipProgressItem($event)"
                >
                </app-completion-progress-item>
            </div>
        </div>
    </div>
    <ng-template #pageLoading>
        <div class="l-page-loader">
            <mat-spinner></mat-spinner>
        </div>
    </ng-template>
</div>
