import { omit } from 'lodash';
import { EventOrganiserProfile } from '@app/models/profile.model';
import {
	EventEntity,
	EventQuestion,
	EventOnlineStreamingRooms,
	ProductOnlineStreamingRooms,
	ProductCreationStep,
	EventPart
} from '@app/models/event.model';
import { setTimezoneTo, setTimezoneFrom } from '@app/utils/Date';
import { Currency, Ticket, TicketQuestion, SeatingCategory, TicketCategory, ChartCategory } from '@app/models/ticket.model';
import { EventCopy } from '@app/models/event-metadata.model';
import { isDraftID } from '../IdGenerator';
import { Product, ProductDetails, ProductDealQuestion, ProductType } from '@app/models/product.model';
import { SchemeID } from '@app/models/dataSchema.model';
import { ScheduleItem } from '@app/models/schedule.model';
import { Guest, Order, OrderAction } from '@app/models/order.model';

const embedYoutubeReplacement = {
	rawStart: '<iframe class',
	desiredStart: '<div class="embed-responsive embed-responsive-16by9">',
	rawEnd: '</iframe>',
	desiredEnd: '</iframe></div>',
};

export function chartCategoryToSeatingCategory(
	chartCategories: ChartCategory[],
	chartId: string
): SeatingCategory[] | TicketCategory[] {
	return (chartCategories || [])
		.map((c: ChartCategory) => ({
			...c,
			chartId,
		}));
}

export function seatingToChartCategory(
	seatingCategories: (TicketCategory |SeatingCategory)[]
): ChartCategory[] {
	return seatingCategories
		.map((el: TicketCategory | SeatingCategory) => omit(el, 'chartId'));
}

export function mapEventTo(
	e: EventEntity,
	original: { seatingCategories: SeatingCategory[]; questions: EventQuestion[]; seatsIOChartKey: string },
	currencies: Currency[]
): Product {
	const o = new Product();
	o.productDetails = new ProductDetails();
	const editedEventEntity = Object.assign({}, new EventEntity(), e);
	o.questions = original.questions;

	o.organiser = editedEventEntity.profile;
	const organiserCopy = {
		...o.organiser,
		imageString: editedEventEntity.profile.croppedImage,
	};
	o.organiser = organiserCopy;

	o.productDetails.name = editedEventEntity.details.name;
	o.organiser = editedEventEntity.profile;
	o.productDetails.id = editedEventEntity.details.eventId;
	o.productDetails.description = mapEventDescTo(editedEventEntity.details.description);
	o.productDetails.startDate = setTimezoneTo(editedEventEntity.details.startDateTime, editedEventEntity.details.timezone);
	o.productDetails.endDate = setTimezoneTo(editedEventEntity.details.endDateTime, editedEventEntity.details.timezone);
	o.productDetails.ianaTimezone = editedEventEntity.details.timezone;
	o.productDetails.isOneDayEvent = editedEventEntity.details.isOneDayEvent;
	o.productDetails.isOnline = editedEventEntity.details.isOnline;
	o.productDetails.maxCapacity = editedEventEntity.ticketsDetails.capacity;
	o.productDetails.maxTicketsPerOrder = editedEventEntity.ticketsDetails.maxTicketsPerOrder;
	o.productDetails.useSeatNumbers = editedEventEntity.ticketsDetails.isReservedSeating;
	o.productDetails.serviceFeeForHost = editedEventEntity.ticketsDetails.serviceFeeForHost;
	o.productDetails.commissionForHost = editedEventEntity.ticketsDetails.commissionForHost;
	o.productDetails.allowRefunds = editedEventEntity.ticketsDetails.allowRefunds;
	o.productDetails.donationIncludeTickets = editedEventEntity.ticketsDetails.donationIncludeTickets;
	o.productDetails.allowRefundProtect = editedEventEntity.ticketsDetails.allowRefundProtect;
	o.productDetails.hasStreaming = editedEventEntity.ticketsDetails.hasStreaming;
	o.productDetails.productType = editedEventEntity.ticketsDetails.productType;
	o.productDetails.donationTarget =
		(editedEventEntity.ticketsDetails.productType === ProductType.Fundraiser)
			? editedEventEntity.ticketsDetails.donationTarget
			: (editedEventEntity.ticketsDetails.tickets || []).some(el => el.type === 'donation')
				? editedEventEntity.ticketsDetails.donationTarget
				: null;
	o.productDetails.showMap = editedEventEntity.details.showMap;
	o.productDetails.hasVendors = editedEventEntity.details.hasVendors;
	o.productDetails.public = editedEventEntity.details.isPublic === null ? null : editedEventEntity.details.isPublic;
	o.productDetails.formatId = editedEventEntity.details.formatId;
	o.productDetails.categoryId = editedEventEntity.details.categoryId;
	o.productDetails.subCategoryId = editedEventEntity.details.subCategoryId;
	o.productDetails.taxRateId = editedEventEntity.details.taxRateId;
	o.productDetails.commission = editedEventEntity.details.commission;
	o.productDetails.serviceFee = editedEventEntity.details.serviceFee;
	o.productDetails.indefinite = editedEventEntity.details.indefinite;
	o.productDetails.productType = editedEventEntity.details.productType;
	o.productDetails.originalImage = editedEventEntity.details.originalImage;
	o.productDetails.hasOnlineContent = editedEventEntity.details.hasOnlineContent;
	o.productDetails.eventEndDateChanged = editedEventEntity.details.eventEndDateChanged;
	o.productDetails.thumbnail = editedEventEntity.details.thumbnail;
	o.productDetails.imageString = editedEventEntity.details.croppedImage;
	o.productDetails.fundraiserCertificate = editedEventEntity.details.fundraiserCertificate;
	o.productDetails.pdfLogoUrl = editedEventEntity.details.pdfLogoUrl;
	o.productDetails.displayBannerInEmails = editedEventEntity.details.displayBannerInEmails;
	o.schedules = editedEventEntity.details.schedules
		? editedEventEntity.details.schedules.map(sched => ({
			id: sched.id,
			hidden: sched.hidden,
			hasInUseTickets: sched.hasInUseTickets,
			deleted: sched.deleted,
			scheduleItems: mapScheduleItems(sched.scheduleItems, editedEventEntity),
		}))
		: [];
	o.seatingCategories = editedEventEntity.ticketsDetails.tickets
		&& editedEventEntity.ticketsDetails.tickets[0]
		&& editedEventEntity.ticketsDetails.tickets[0].categories
		&& original
		? mapEventSeatingCategoriesTo(
			editedEventEntity.ticketsDetails.tickets[0].categories,
			original.seatsIOChartKey !== editedEventEntity.ticketsDetails.seatingChartId,
			original.seatingCategories
		)
		: null;
	o.productDetails.seatsIOChartKey = editedEventEntity.ticketsDetails.seatingChartId;
	o.productDetails.bankAccountId = editedEventEntity.ticketsDetails.bankAccountId;

	if (editedEventEntity.details.venue.name) {
		o.venue = editedEventEntity.details.venue;
	}
	o.currency = currencies.find(el => el.iso === editedEventEntity.ticketsDetails.currencyCode);
	o.eventFeatureAnswers = editedEventEntity.details.eventFeatureAnswers;
	o.eventFeatureOptions = editedEventEntity.details.eventFeatureOptions;
	o.tickets = editedEventEntity.ticketsDetails.tickets.map((el, i) => {
		const isDraftTicket = isDraftID(el.id);
		return ({
			id: isDraftTicket ? null : el.id,
			name: el.name,
			description: el.description,
			price: el.price === null ? null : el.price.toString(),
			sortOrder: i,
			ticketStubInformation: el.stubInfo,
			isTypeHidden: el.isTypeHidden,
			minItemsCanSell: el.minPerOrder,
			maxItemsCanSell: el.quantity,
			maxTicketsPerUser: el.maxPerUser,
			maxTicketsPerPurchase: el.maxPerOrder,
			salesStart: setTimezoneTo(el.salesStartDateTime, editedEventEntity.details.timezone),
			salesEnd: setTimezoneTo(el.salesEndDateTime, editedEventEntity.details.timezone),
			scanningValidFrom: setTimezoneTo(el.validFrom, editedEventEntity.details.timezone),
			scanningValidTo: setTimezoneTo(el.validTo, editedEventEntity.details.timezone),
			incrementOf: el.incrementsOf,
			donation: el.type === 'donation',
			hasSales: el.hasSales,
			atTheDoor: el.sellAtTheDoor,
			autoCheckin: el.autoCheckin,
			canVend: el.canVendor,
			scannerColour: el.scannerColour,
			categories: mapTicketCategoriesTo(el.categories, isDraftTicket),
			questions: mapTicketQuestionsTo(el.questions, isDraftTicket),
			imageString: el.imageString ? el.imageString.base64 : null,
			image: el.image,
			linkedToStream: el.linkedToStream,
			hideUntil: setTimezoneTo(el.hideUntil, editedEventEntity.details.timezone),
			hideAfter: setTimezoneTo(el.hideAfter, editedEventEntity.details.timezone),
			benefits: el.benefits,
			recurringPeriod: el.recurringPeriod,
			subscriptionPerkTypes: el.subscriptionPerkTypes,
			lowerLimit: el.lowerLimit,
			showTicketStockType: el.showTicketStockType,
		});
	});
	o.image = editedEventEntity.details.image;
	return o;
}

export function mapScheduleItems(eventItems: ScheduleItem[], details: EventEntity): ScheduleItem[] {
	return (eventItems || []).map(el => ({
		...el,
		id: el.id,
		name: el.name,
		startDate: new Date(setTimezoneTo(el.startDate, details.details.timezone)),
		endDate: new Date(setTimezoneTo(el.endDate, details.details.timezone)),
		hidden: el.hidden,
		deleted: el.deleted,
		hasInUseTickets: el.hasInUseTickets,
	}));
}

export function mapScheduleItemsBack(eventItems: ScheduleItem[], product: Product): ScheduleItem[] {
	return (eventItems || []).map(el => ({
		...el,
		id: el.id,
		name: el.name,
		startDate: new Date(setTimezoneFrom(el.startDate, product.productDetails.ianaTimezone)),
		endDate: new Date(setTimezoneFrom(el.endDate, product.productDetails.ianaTimezone)),
		hidden: el.hidden,
		deleted: el.deleted,
		hasInUseTickets: el.hasInUseTickets,
	}));
}

export function mapEventSeatingCategoriesTo(categories = [], isChartChanged, prevCategories) {
	const mappedCategories = [];
	const nextCategories = categories.map(el => ({ ...el, isDeleted: false }));

	if (isChartChanged) {
		return [
			...(prevCategories || []).map(el => ({ ...el, isDeleted: true })),
			...nextCategories,
		];
	} else {
		const concattedArray = nextCategories.concat(prevCategories || []);
		concattedArray.forEach(cat => {
			if (cat.id === null) {
				mappedCategories.push(cat);
			} else {
				if (!mappedCategories.find(el => el.id === cat.id)) {
					mappedCategories.push({ ...cat, isDeleted: !nextCategories.find(e => e.id === cat.id) && cat.id !== null });
				}
			}
		});
	}
	return seatingToChartCategory(mappedCategories);
}

export function mapEventFrom(o: Product) {
	const e = new EventEntity();
	e.profile = o.organiser ? o.organiser : new EventOrganiserProfile();

	e.details.name = o.productDetails.name;
	e.details.description = mapEventDescFrom(o.productDetails.description);
	e.details.startDateTime = setTimezoneFrom(o.productDetails.startDate, o.productDetails.ianaTimezone);
	e.details.endDateTime = setTimezoneFrom(o.productDetails.endDate, o.productDetails.ianaTimezone);
	e.details.schedules = o.schedules
		? o.schedules.map(sched => ({
			id: sched.id,
			hidden: sched.hidden,
			hasInUseTickets: sched.hasInUseTickets,
			deleted: sched.deleted,
			scheduleItems: mapScheduleItemsBack(sched.scheduleItems, o),
		}))
		: [];
	e.details.isOneDayEvent = o.productDetails.isOneDayEvent;
	e.details.hasVendors = o.productDetails.hasVendors;
	e.details.eventId = o.productDetails.id;
	e.details.ticketStubDescription = o.productDetails.ticketStubDescription;
	e.details.productType = o.productDetails.productType;
	e.details.indefinite = o.productDetails.indefinite;
	e.details.hasOnlineContent = o.productDetails.hasOnlineContent;
	e.details.eventEndDateChanged = o.productDetails.eventEndDateChanged;
	e.details.eventFeatureOptions = o.eventFeatureOptions;
	e.details.eventFeatureAnswers = o.eventFeatureAnswers;
	e.details.fundraiserCertificate = o.productDetails.fundraiserCertificate;
	e.details.pdfLogoUrl = o.productDetails.pdfLogoUrl;
	e.details.displayBannerInEmails = o.productDetails.displayBannerInEmails;
	e.details.lockedOnPrivate = o.productDetails.lockedOnPrivate;
	e.details.creationStep = o.productDetails.creationStep;

	if (o.venue) {
		e.details.venue = o.venue;
	}

	e.details.timezone = o.productDetails.ianaTimezone;
	e.details.isOnline = o.productDetails.isOnline;
	e.details.isPublic = o.productDetails.public === null ? null : o.productDetails.public;
	e.details.formatId = o.productDetails.formatId;
	e.details.categoryId = o.productDetails.categoryId;
	e.details.subCategoryId = o.productDetails.subCategoryId;
	e.details.showMap = o.productDetails.showMap;

	e.details.taxRateId = o.productDetails.taxRateId;
	e.details.commission = o.productDetails.commission;
	e.details.serviceFee = o.productDetails.serviceFee;
	e.details.hasSoldTickets = o.productDetails.hasSoldTickets;
	e.details.isEventFinished = o.productDetails.isEventFinished;
	e.details.image = o.image;
	e.details.originalImage = o.productDetails.originalImage;
	e.details.imageString = o.productDetails.imageString;
	e.details.thumbnail = o.productDetails.thumbnail;
	e.details.croppedImage = o.productDetails.croppedImage;

	e.ticketsDetails.isReservedSeating = o.productDetails.useSeatNumbers;
	e.ticketsDetails.seatingChartId = o.productDetails.seatsIOChartKey;
	e.ticketsDetails.donationTarget = o.productDetails.donationTarget;
	e.ticketsDetails.capacity = o.productDetails.maxCapacity;
	e.ticketsDetails.maxTicketsPerOrder = o.productDetails.maxTicketsPerOrder;
	e.ticketsDetails.currencyCode = o.currency ? o.currency.iso : null;
	e.ticketsDetails.currencyId = o.currency ? o.currency.id : null;
	e.ticketsDetails.tickets = o.tickets ? o.tickets.map(el => ({
		id: el.id,
		type: el.donation ? 'donation' : Number(el.price) ? 'paid' : 'free',
		name: el.name,
		price: el.price === null ? null : Number(el.price),
		quantity: el.maxItemsCanSell,
		isTypeHidden: el.isTypeHidden,
		categories: chartCategoryToSeatingCategory(el.categories || [],  o.productDetails.seatsIOChartKey),
		salesStartDateTime: setTimezoneFrom(el.salesStart, o.productDetails.ianaTimezone),
		salesEndDateTime: setTimezoneFrom(el.salesEnd, o.productDetails.ianaTimezone),
		description: el.description,
		stubInfo: el.ticketStubInformation,
		questions: mapTicketQuesionsFrom(el.questions, o.questions),
		minPerOrder: el.minItemsCanSell,
		maxPerOrder: el.maxTicketsPerPurchase,
		maxPerUser: el.maxTicketsPerUser,
		incrementsOf: !el.incrementOf ? 1 : el.incrementOf,
		validTo: setTimezoneFrom(el.scanningValidTo, o.productDetails.ianaTimezone),
		validFrom: setTimezoneFrom(el.scanningValidFrom, o.productDetails.ianaTimezone),
		hasSales: el.hasSales,
		canVendor: el.canVend,
		sellAtTheDoor: el.atTheDoor,
		autoCheckin: el.autoCheckin,
		scannerColour: el.scannerColour,
		imageString: null,
		image: el.image,
		linkedToStream: el.linkedToStream,
		hideUntil: setTimezoneFrom(el.hideUntil, o.productDetails.ianaTimezone),
		hideAfter: setTimezoneFrom(el.hideAfter, o.productDetails.ianaTimezone),
		benefits: el.benefits,
		recurringPeriod: el.recurringPeriod,
		subscriptionPerkTypes: el.subscriptionPerkTypes,
		lowerLimit: el.lowerLimit,
		showTicketStockType: el.showTicketStockType,
	} as Ticket)) : [];
	e.ticketsDetails.allowRefunds = o.productDetails.allowRefunds;
	e.ticketsDetails.donationIncludeTickets = o.productDetails.donationIncludeTickets;
	e.ticketsDetails.allowRefundProtect = o.productDetails.allowRefundProtect;
	e.ticketsDetails.hasStreaming = o.productDetails.hasStreaming;
	e.ticketsDetails.productType = o.productDetails.productType;
	e.ticketsDetails.serviceFeeForHost = o.productDetails.serviceFeeForHost;
	e.ticketsDetails.commissionForHost = o.productDetails.commissionForHost;
	e.ticketsDetails.allowNoCategories = o.productDetails.allowNoCategories;

	return e;
}


export function mapTicketCategoriesFrom(
	ticketCategories: SeatingCategory[],
	allCategories: SeatingCategory[]
): TicketCategory[] {

	return (allCategories || []).map(el => ({
		...el,
		required: (ticketCategories || []).some(e => e.seatsIOKey === el.seatsIOKey && e.chartId === el.chartId),
	}));
}

export function mapTicketCategoriesTo(ticketCategories, isDraftTicket) {
	return ticketCategories
		? ticketCategories
			.map(el => omit(el, 'chartId'))
			.filter(el => el.required)
		: null;
}

export function mapTicketQuesionsFrom(ticketQuestions:
ProductDealQuestion[],
questions: EventQuestion[]
): TicketQuestion[] {
	return (questions || []).map(q => {
		const ticketCollection = ticketQuestions && ticketQuestions.find(el => el.productQuestionId === q.id);

		return ({
			question: q.question,
			productQuestionId: q.id,
			id: ticketCollection ? ticketCollection.id : null,
			required: ticketCollection ? ticketCollection.required : false,
			enabled: q.enabled,
		});
	});
}

export function mapTicketQuestionsTo(ticketQuestion: TicketQuestion[], isDraftTicket: boolean): ProductDealQuestion[] {

	const ticketQuestions =
		(ticketQuestion || [])
			.filter(el => el.required)
			.map(el => ({
				id: isDraftTicket ? null : el.id,
				productQuestionId: el.productQuestionId,
				required: el.required,
			}));

	return ticketQuestions.length ? ticketQuestions : null;
}

export function mapEventCopyInfoTo(copyInfo: EventCopy, timezone: string) {
	return {
		...copyInfo,
		startDate: setTimezoneTo(copyInfo.startDate, timezone),
		endDate: setTimezoneTo(copyInfo.endDate, timezone),
		tickets: Object.keys(copyInfo.tickets)
			.map(key => copyInfo.tickets[key])
			.filter(el => el.checked)
			.map(el => omit(el, ['checked'])),
		questions: Object.keys(copyInfo.questions)
			.map(key => copyInfo.questions[key])
			.filter(el => el.checked)
			.map(el => omit(el, ['checked'])),
	};
}

export function mapEventDescTo(desc: string): string {
	if (desc && !desc.includes('embed-responsive') && desc.includes(embedYoutubeReplacement.rawStart)) {
		return desc
			.split(embedYoutubeReplacement.rawStart).join(embedYoutubeReplacement.desiredStart + embedYoutubeReplacement.rawStart)
			.split(embedYoutubeReplacement.rawEnd).join(embedYoutubeReplacement.desiredEnd);
	}

	return desc;
}

// TODO: implement replacement
export function mapEventDescFrom(desc: string): string {
	return desc;
}

export function productStreamIdtoId(streamId: SchemeID): SchemeID {
	const id = streamId;
	return id;
}

export function eventStreamIdtoProductStreamId(id: SchemeID): SchemeID {
	const streamId = id;
	return streamId;
}

export function productStreamToEventStream(apiModel: ProductOnlineStreamingRooms[]):
EventOnlineStreamingRooms[] {
	const stream =
			(apiModel || []).map(el => ({
				id: el.streamId,
				name: el.name,
				enabled: el.enabled,
				type: el.type,
				description: el.description,
				ticketLinks: el.ticketLinks,
				isGated: el.isGated,
				url: el.url,
				embed: el.embed,
				instructions: el.instructions,
				displayDirectLink: el.displayDirectLink,
				publishLogin: el.publishLogin,
				publishName: el.publishName,
				publishPassword: el.publishPassword,
				publishUrl: el.publishUrl,
				convertStreamToVideo: el.convertStreamToVideo,
				uploadId: el.uploadId,
				thumbnailUrl: el.thumbnailUrl,
				imageString: el.imageString,
				streamThumbnailUrl: el.streamThumbnailUrl,
				streamUrl: el.streamUrl,
				linkUnknown: el.linkUnknown,
				hideFrom : el.hideFrom,
				hideTo: el.hideTo,
			}));

	return stream.length ? stream : [];
}

export function eventStreamToProductStream(stream: EventOnlineStreamingRooms):
ProductOnlineStreamingRooms {
	const apiModel = new ProductOnlineStreamingRooms();
	apiModel.streamId = stream.id;
	apiModel.name = stream.name;
	apiModel.enabled = stream.enabled;
	apiModel.type = stream.type;
	apiModel.description = stream.description;
	apiModel.ticketLinks = stream.ticketLinks;
	apiModel.isGated = stream.isGated;
	apiModel.url = stream.url;
	apiModel.embed = stream.embed;
	apiModel.instructions = stream.instructions;
	apiModel.displayDirectLink = stream.displayDirectLink;
	apiModel.publishLogin = stream.publishLogin;
	apiModel.publishName = stream.publishName;
	apiModel.publishPassword = stream.publishPassword;
	apiModel.publishUrl = stream.publishUrl;
	apiModel.convertStreamToVideo = stream.convertStreamToVideo;
	apiModel.uploadId = stream.uploadId;
	apiModel.thumbnailUrl = stream.thumbnailUrl;
	apiModel.imageString = stream.imageString;
	apiModel.streamThumbnailUrl = stream.streamThumbnailUrl;
	apiModel.streamUrl = stream.streamUrl;
	apiModel.linkUnknown = stream.linkUnknown;
	apiModel.hideTo = stream.hideTo;
	apiModel.hideFrom = stream.hideFrom;

	return apiModel;
}

export function updateProductStreamToEventStream(apiModel: ProductOnlineStreamingRooms):
EventOnlineStreamingRooms {
	const stream = new EventOnlineStreamingRooms();
	stream.id = apiModel.streamId;
	stream.name = apiModel.name;
	stream.enabled = apiModel.enabled;
	stream.type = apiModel.type;
	stream.description = apiModel.description;
	stream.ticketLinks = apiModel.ticketLinks;
	stream.isGated = apiModel.isGated;
	stream.url = apiModel.url;
	stream.embed = apiModel.embed;
	stream.instructions = apiModel.instructions;
	stream.displayDirectLink = apiModel.displayDirectLink;
	stream.publishLogin = apiModel.publishLogin;
	stream.publishName = apiModel.publishName;
	stream.publishPassword = apiModel.publishPassword;
	stream.publishUrl = apiModel.publishUrl;
	stream.convertStreamToVideo = apiModel.convertStreamToVideo;
	stream.uploadId = apiModel.uploadId;
	stream.thumbnailUrl = apiModel.thumbnailUrl;
	stream.imageString = apiModel.imageString;
	stream.streamThumbnailUrl = apiModel.streamThumbnailUrl;
	stream.streamUrl = apiModel.streamUrl;
	stream.linkUnknown = apiModel.linkUnknown;
	stream.hideFrom = apiModel.hideFrom;
	stream.hideTo = apiModel.hideTo;

	return stream;
}

export function mapOrderReturnToOrder(order: Order): Order {
	return {
		eventId: order.eventId,
		eventName: order.eventName,
		orderId: order.orderId,
		reference: order.reference,
		userId: order.userId,
		email: order.email,
		dateAdded: order.dateAdded,
		amount: order.amount,
		isComplimentary: order.isComplimentary,
		paymentState: order.paymentState,
		paymentType: order.paymentType,
		paymentDate: order.paymentDate,
		isVended: order.isVended,
		paymentCollectionBy: order.paymentCollectionBy,
		canRefund: order.canRefund,
		encrypt: order.encrypt,
		totalGuests: order.totalGuests,
		guests: order.guests.map(mapGuestReturnToGuest),
		actions: mapActionReturnToAction(order.actions),
		isTransferred: order.isTransferred,
		questionAnswers: order.questionAnswers || [],
		teamName: order.teamName,
		hasRefundProtect: order.hasRefundProtect,
		refundRequiresBankAccount: order.refundRequiresBankAccount,
		refundFee: order.refundFee,
		currencySymbol: order.currencySymbol,
		paid: order.paid,
	};
}

export function mapGuestReturnToGuest(guest: any): Guest {
	return {
		orderId: guest.OrderId,
		ticketId: guest.TicketId,
		barcode: guest.Barcode,
		dateAdded: guest.DateAdded,
		ticketType: guest.TicketType,
		ticketTypeId: guest.TicketTypeId,
		seatNumber: guest.SeatNumber,
		discountCode: guest.DiscountCode,
		discountAmount: guest.DiscountAmount,
		checkedIn: guest.CheckedIn,
		checkinDate: guest.CheckinDate,
		checkedInBy: guest.CheckedInBy,
		complimentary: guest.Complimentary,
		price: guest.Price,
		amountPaid: guest.AmountPaid,
		valid: guest.Valid,
		transferredToOrderId: guest.TransferredToOrderId,
		frozen: guest.Frozen,
		ticketInformation: {
			firstName: guest.TicketInformation?.['First name'] || null,
			lastName: guest.TicketInformation?.['Surname'] || null,
			email: guest.TicketInformation?.['Email'] || null,
			pricePaid: guest.TicketInformation?.['Price paid'] || null,
		},
	};
}

export function mapActionReturnToAction(action: any): OrderAction {
	return {
		editAttendeeInfo: action?.['Edit attendee info'] || null,
		printTickets: action?.['Print tickets'] || null,
	};
}



export function creationStepToActiveStep(creationStep: ProductCreationStep): EventPart {
	const stepMapping: { [key in ProductCreationStep]?: EventPart } = {
		[ProductCreationStep.StepOne]: EventPart.PROFILE,
		[ProductCreationStep.StepTwo]: EventPart.DETAILS,
		[ProductCreationStep.StepThree]: EventPart.TICKETS,
		[ProductCreationStep.SuccessfullyCreated]: EventPart.PROFILE,
	};

	return stepMapping[creationStep] ?? EventPart.PROFILE;
}
