<app-web-filter
	[isMobile]="isMobile"
	searchPlaceholder='Search Ticket Name'
	dropDownButtonText='ADD'
	[dropDownOptions]='ticketOptions'
	(filterOutput)="handleFilter($event)"
	(dropDownButtonSelection)="handleAddTicket($event)"
	[isSearchVisible]="!isEventCreation"
	class='l-filter'
></app-web-filter>
<div class="l-table-container">
	<table
		mat-table
		[dataSource]="dataSource"
		multiTemplateDataRows
		class='l-table expansion'
		cdkDropList
		(cdkDropListDropped)="handleTicketDrag($event)"
		[cdkDropListDisabled]="dragDisabled"
		#ticketTypeTable
	>
		<ng-container matColumnDef="sorting">
			<th
				mat-header-cell
				*matHeaderCellDef
				class="l-sorting-column"
			></th>
			<td
				mat-cell
				*matCellDef="let ticket"
				class="l-sorting-column"
				[ngClass]='{"expansion-parent": expandedDetailElement !== ticket.value.id, "current-expansion-parent": expandedDetailElement === ticket.value.id}'
			>
				<img
					*ngIf="!hasSearchTerm"
					src="./assets/icons/dots-nine.svg"
					alt="Drag Icon"
					class="g-move"
					(mousedown)="dragDisabled = false;"
					(touchstart)="dragDisabled = false;"
				>
			</td>
		</ng-container>
		<ng-container matColumnDef="isExpanded">
			<th
				mat-header-cell
				*matHeaderCellDef
			></th>
			<td
				mat-cell
				*matCellDef="let ticket"
				[ngClass]='{"expansion-parent": expandedDetailElement !== ticket.value.id, "current-expansion-parent": expandedDetailElement === ticket.value.id}'
			>
				<mat-icon
					(click)="handleExpandContent(ticket)"
					class='g-pointer d-flex'
					[@rotateIcon]="expandedDetailElement !== ticket.value.id ? 'rotated' : 'default'"
					id="ticket-expand"
				>expand_more
				</mat-icon>
			</td>
		</ng-container>
		<ng-container matColumnDef="name">
			<th
				mat-header-cell
				*matHeaderCellDef
				class="l-name-column"
			> Ticket Name </th>
			<td
				mat-cell
				*matCellDef="let ticket"
				[ngClass]='{"expansion-parent": expandedDetailElement !== ticket.value.id, "current-expansion-parent": expandedDetailElement === ticket.value.id}'
			>
				<app-mat-text-control-field
					[control]="ticket.get('name')"
					outerClass="''"
					placeholder="e.g. Adults"
					customId="ticket-name"
					id="ticketNameElement"
					(mousedown)="$event.stopPropagation()"
					(mousemove)="$event.stopPropagation()"
					[errorOutline]="true"
				>
				</app-mat-text-control-field>
			</td>
		</ng-container>
		<ng-container matColumnDef="price">
			<th
				mat-header-cell
				*matHeaderCellDef
				class="l-price-column"
			> Price </th>
			<td
				mat-cell
				*matCellDef="let ticket"
				[ngClass]='{"expansion-parent": expandedDetailElement !== ticket.value.id, "current-expansion-parent": expandedDetailElement === ticket.value.id}'
			>
				<ng-container [ngSwitch]="ticket.get('type').value">
					<input
						*ngSwitchCase="'paid'"
						class='form-control'
						[ngClass]="{'l-error-outline': getError(ticket.get('price').errors)}"
						placeholder='0.00'
						[formControl]="ticket.get('price')"
						[prefix]="currencySymbol + ' ' "
						type='text'
						[leadZero]="true"
						[allowNegativeNumbers]="false"
						mask="separator.2"
						thousandSeparator=","
						decimalMarker="."
						id="ticket-price"
						(mousedown)="$event.stopPropagation()"
						(mousemove)="$event.stopPropagation()"
					/>
					<div
						*ngSwitchCase="'free'"
						class='g-green fw-bold'
						id="ticket-price"
					>FREE</div>
					<div
						*ngSwitchCase="'donation'"
						class='g-green fw-bold'
						id="ticket-price"
					>DONATION</div>
				</ng-container>
			</td>
		</ng-container>
		<ng-container matColumnDef="quantity">
			<th
				mat-header-cell
				*matHeaderCellDef
				class='l-quantity-column'
			> Quantity </th>
			<td
				mat-cell
				*matCellDef="let ticket"
				[ngClass]='{"expansion-parent": expandedDetailElement !== ticket.value.id, "current-expansion-parent": expandedDetailElement === ticket.value.id}'
			>
				<input
					*ngIf="ticket.get('type').value !== 'donation' && !seatingChartId"
					class='form-control'
					[ngClass]="{'l-error-outline': getError(ticket.get('quantity').errors)}"
					placeholder='0'
					[formControl]="ticket.get('quantity')"
					[allowNegativeNumbers]="false"
					mask="separator"
					thousandSeparator=","
					id="ticket-quantity"
					(mousedown)="$event.stopPropagation()"
					(mousemove)="$event.stopPropagation()"
				/>
				<div
					*ngIf="ticket.get('type').value !== 'donation' && seatingChartId"
					class='g-green fw-bold'
					id="ticket-quantity"
				>Handled by seating chart</div>
				<div
					*ngIf="ticket.get('type').value === 'donation'"
					class='g-green fw-bold'
					id="ticket-quantity"
				>---</div>
			</td>
		</ng-container>
		<ng-container matColumnDef="actions">
			<th
				mat-header-cell
				*matHeaderCellDef
			> Actions </th>
			<td
				mat-cell
				*matCellDef="let ticket"
				[ngClass]='{"expansion-parent": expandedDetailElement !== ticket.value.id, "current-expansion-parent": expandedDetailElement === ticket.value.id}'
			>
				<button
					mat-icon-button
					[matMenuTriggerFor]="menu"
					aria-label="Example icon-button with a menu"
					id="ticket-action"
				>
					<mat-icon>more_vert</mat-icon>
				</button>
				<mat-menu #menu="matMenu">
					<button
						mat-menu-item
						(click)="toggleControlValue(ticket.get('isTypeHidden'))"
						id="ticket-hide"
					>
						<mat-icon>{{ ticket.get('isTypeHidden').value ? 'visibility_off' : 'visibility'}}</mat-icon>
						<span>{{ ticket.get('isTypeHidden').value ? 'Show Ticket' : 'Hide Ticket'}}</span>
					</button>
					<button
						mat-menu-item
						(click)="handleDeleteTicket(ticket)"
						id="ticket-delete"
					>
						<mat-icon>delete_outline</mat-icon>
						<span>Delete</span>
					</button>
				</mat-menu>
			</td>
		</ng-container>
		<ng-container matColumnDef="error">
			<th
				mat-header-cell
				*matHeaderCellDef
			></th>
			<td
				mat-cell
				*matCellDef="let ticket"
				[ngClass]='{"expansion-parent": expandedDetailElement !== ticket.value.id, "current-expansion-parent": expandedDetailElement === ticket.value.id}'
				class='p-0 l-error-container'
			>
				<mat-icon
					*ngIf="isInvalidTicket(ticket)"
					class='l-error-icon g-pointer'
					#invalidTicket="matTooltip"
					matTooltip="This ticket type has some invalid or missing information."
					matTooltipPosition="above"
					(click)="invalidTicket.toggle()"
				>
					error
				</mat-icon>

				<mat-icon
					*ngIf="isHiddenTicket(ticket)"
					class='d-flex'
					#hiddenTicket="matTooltip"
					matTooltip="This ticket is hidden"
					matTooltipPosition="above"
					(click)="hiddenTicket.toggle()"
				>
					visibility_off
				</mat-icon>

				<div
					*ngIf="isSaleEnded(ticket)"
					class='l-sold-out'
					#saleEnded="matTooltip"
					matTooltip="Sales end date has passed"
					matTooltipPosition="above"
					(click)="saleEnded.toggle()"
				>
					SALE ENDED
				</div>
			</td>
		</ng-container>
		<ng-container matColumnDef="expandedDetail">
			<td
				mat-cell
				*matCellDef="let ticket"
				[attr.colspan]="displayColumns.length"
				class='l-expanded-container'
			>
				<div
					[@detailExpand]="ticket.value.id != expandedDetailElement ? 'collapsed' : 'expanded'"
					class='d-flex flex-column'
				>
					<h3 class="my-4">{{ fromForm('name', ticket).value ? fromForm('name', ticket).value :
						fromForm('type', ticket).value + ' Ticket' | titlecase }} Details</h3>
					<div class='d-flex flex-column'>
						<div class="l-form-item-container mb-4 l-max-width">
							<label class="g-form-label">Timezone</label>
							<div class='l-form-item-content'>
								<mat-icon class='g-green'>public</mat-icon>
								<input
									class="form-control"
									disabled
									[value]="timezoneTitle"
									id="ticket-timezone"
								/>
							</div>
						</div>
						<div class="g-double-input-container w-100 mt-4">
							<div
								[ngClass]="{ 'input-group-mobile': isMobile, 'input-group-web flex-grow-1': !isMobile }">
								<label class="g-form-label">Sales Start Date And Time</label>
								<div>
									<input
										[ngxMatDatetimePicker]="startDatePicker"
										[disabled]="false"
										placeholder="DD/MM/YYYY HH:mm"
										(click)="startDatePicker.open()"
										class="form-control"
										[formControl]="ticket.get('salesStartDateTime')"
										[max]="ticket.get('salesEndDateTime').value ? ticket.get('salesEndDateTime').value : undefined"
										id="ticket-start-date"
										(dateChange)='handleChange()'
									>
									<ngx-mat-datetime-picker
										#startDatePicker
										[showSeconds]="false"
										[touchUi]="isMobile"
										[enableMeridian]="false"
										[disableMinute]="false"
										[hideTime]="false"
										[matDatepickerPosition]="'below'"
										[showSpinners]="false"
									>
										<ngx-mat-datepicker-actions>
											<button
												id="close-datepicker"
												class="g-secondary-button g-datepicker-button"
												ngxMatDatepickerCancel
											>CANCEL</button>
											<button
												id="apply-datepicker"
												class="g-primary-button g-datepicker-button"
												ngxMatDatepickerApply
											>APPLY</button>
										</ngx-mat-datepicker-actions>
									</ngx-mat-datetime-picker>
								</div>
								<div
									class="g-error-input"
									*ngIf="ticket.get('salesStartDateTime').invalid"
								>
									*{{getError(ticket.get('salesStartDateTime').errors)}}
								</div>
								<app-apply-all-form
									*ngIf="!ticket.get('salesStartDateTime').invalid"
									class='mt-2'
									message='Apply to all ticket types'
									[isDisabled]="isSalesCopyDisabled('start', ticket)"
									(selected)="copySalesDates('start', ticket)"
								></app-apply-all-form>
							</div>
							<div
								[ngClass]="{ 'input-group-mobile': isMobile, 'input-group-web flex-grow-1': !isMobile }">
								<label class="g-form-label">Sales End Date And Time</label>
								<div>
									<input
										[ngxMatDatetimePicker]="endDatePicker"
										[disabled]="false"
										placeholder="DD/MM/YYYY HH:mm"
										(click)="endDatePicker.open()"
										class="form-control"
										[formControl]="ticket.get('salesEndDateTime')"
										[min]="ticket.get('salesStartDateTime').value ? ticket.get('salesStartDateTime').value : undefined"
										id="ticket-end-date"
										(dateChange)='handleChange()'
									>
									<ngx-mat-datetime-picker
										#endDatePicker
										[showSeconds]="false"
										[touchUi]="isMobile"
										[enableMeridian]="false"
										[disableMinute]="false"
										[hideTime]="false"
										[matDatepickerPosition]="'below'"
										[showSpinners]="false"
									>
										<ngx-mat-datepicker-actions>
											<button
												id="close-datepicker"
												class="g-secondary-button g-datepicker-button"
												ngxMatDatepickerCancel
											>CANCEL</button>
											<button
												id="apply-datepicker"
												class="g-primary-button g-datepicker-button"
												ngxMatDatepickerApply
											>APPLY</button>
										</ngx-mat-datepicker-actions>
									</ngx-mat-datetime-picker>
								</div>
								<div
									class="g-error-input"
									*ngIf="ticket.get('salesEndDateTime').invalid"
								>
									*{{getError(ticket.get('salesEndDateTime').errors)}}
								</div>
								<app-apply-all-form
									*ngIf="!ticket.get('salesEndDateTime').invalid"
									class='mt-2'
									message='Apply to all ticket types'
									[isDisabled]="isSalesCopyDisabled('end', ticket)"
									(selected)="copySalesDates('end', ticket)"
								></app-apply-all-form>
							</div>
						</div>
						<div class="g-double-input-container w-100 mt-4 mb-3">
							<div
								[ngClass]="{ 'input-group-mobile': isMobile, 'input-group-web flex-grow-1': !isMobile }">
								<app-mat-text-area-control-field
									[control]="ticket.get('description')"
									[inputClass]="'l-form-field form-control l-text-area'"
									label="Ticket Description"
									placeholder="Tell guests more about this ticket type and what it includes"
									customId="ticket-description"
									[showError]="true"
								>
								</app-mat-text-area-control-field>
							</div>
							<div
								[ngClass]="{ 'input-group-mobile': isMobile, 'input-group-web flex-grow-1': !isMobile }">
								<app-mat-text-area-control-field
									[control]="ticket.get('stubInfo')"
									[inputClass]="'l-form-field form-control l-text-area'"
									label="Ticket Stub Information"
									placeholder="Information displayed on each ticket"
									customId="ticket-stub-info"
									[showError]="true"
								>
								</app-mat-text-area-control-field>
							</div>
						</div>
					</div>
					<hr />

					<h3 class='my-4'>Additional Details</h3>

					<app-mat-select-control-field
						label="Show Ticket Stock As"
						placeholder="e.g. Percent remaining"
						[control]="ticket.get('showTicketStockType')"
						[options]="ticketStockOptions"
						tooltip="If selected, ticket buyers will be able to see how many tickets are left or have been sold."
						class='mb-4 l-max-width'
						customId="ticket-stock"
					>
					</app-mat-select-control-field>

					<div
						class="l-checkbox-container mb-4"
						*ngIf="getTicketQuestions(ticket).length"
					>
						<label class="g-form-label">Data Collection<mat-icon
								#dataCollectionTooltip="matTooltip"
								class="l-tooltip-icon"
								matTooltip="Collect information from ticket buyers at checkout. You can add more questions from your event dashboard"
								matTooltipPosition="above"
								(click)="dataCollectionTooltip.toggle()"
							>info</mat-icon></label>
						<div class='l-checkbox-content'>
							<mat-checkbox
								*ngFor="let question of getTicketQuestions(ticket)"
								[formControl]="question.get('required')"
							>{{question.get('question').value}}</mat-checkbox>
						</div>
					</div>

					<div
						class="l-checkbox-container mb-4"
						*ngIf="getTicketCategoriesKeys(ticket).length && ticket.get('type').value !== 'donation' && isReservedSeating"
					>
						<label class="g-form-label">Categories</label>
						<div class='l-checkbox-content'>
							<mat-checkbox
								*ngFor="let category of getTicketCategoriesKeys(ticket)"
								[formControl]="category.get('required')"
							>{{category.get('label').value}}</mat-checkbox>
						</div>
						<div
							*ngIf="ticket.get('categories').invalid"
							class='g-error-input'
						>*{{getCategoriesError(ticket.get('categories').errors)}}</div>
					</div>

					<div class='l-form-item-container'>
						<div
							class="g-double-input-container w-100 my-4"
							*ngIf="ticket.get('type').value !== 'donation'"
						>
							<div
								[ngClass]="{ 'input-group-mobile': isMobile, 'input-group-web flex-grow-1': !isMobile }">
								<label class="g-form-label">Minimum Tickets Per Order<mat-icon
										#minTicketsTooltip="matTooltip"
										class="l-tooltip-icon"
										matTooltip="The smallest amount of tickets that can be booked in one order. E.g. if 2 is entered, then ticket buyers can only book 2 or more tickets"
										matTooltipPosition="above"
										(click)="minTicketsTooltip.toggle()"
									>info</mat-icon></label>
								<input
									class='form-control'
									placeholder='1'
									[formControl]="ticket.get('minPerOrder')"
									[allowNegativeNumbers]="false"
									id="ticket-min-order"
									mask="separator"
									thousandSeparator=","
									maxlength="12"
								/>
								<div
									class="g-error-input"
									*ngIf="ticket.get('minPerOrder').invalid && ((ticket.get('minPerOrder').dirty || ticket.get('minPerOrder').touched))"
								>
									*{{getError(ticket.get('minPerOrder').errors)}}
								</div>
							</div>
							<div
								[ngClass]="{ 'input-group-mobile': isMobile, 'input-group-web flex-grow-1': !isMobile }">
								<label class="g-form-label">Maximum Tickets Per Order<mat-icon
										#maxTicketsTooltip="matTooltip"
										class="l-tooltip-icon"
										matTooltip="The largest amount of tickets that can be booked in one order"
										matTooltipPosition="above"
										(click)="maxTicketsTooltip.toggle()"
									>info</mat-icon></label>
								<input
									class='form-control'
									placeholder='20'
									[formControl]="ticket.get('maxPerOrder')"
									[allowNegativeNumbers]="false"
									id="ticket-max-order"
									mask="separator"
									thousandSeparator=","
									maxlength="12"
								/>
								<div
									class="g-error-input"
									*ngIf="ticket.get('maxPerOrder').invalid && ((ticket.get('maxPerOrder').dirty || ticket.get('maxPerOrder').touched))"
								>
									*{{getError(ticket.get('maxPerOrder').errors)}}
								</div>
							</div>
						</div>

						<div
							class="g-double-input-container w-100 my-4"
							*ngIf="ticket.get('type').value !== 'donation'"
						>
							<div
								[ngClass]="{ 'input-group-mobile': isMobile, 'input-group-web flex-grow-1': !isMobile }">
								<label class="g-form-label">Tickets Available In Increments Of<mat-icon
										#minTicketsTooltip="matTooltip"
										class="l-tooltip-icon"
										matTooltip="The gap between consecutive numbers of tickets available to book. E.g. if 3 is entered, with a minimum of 1, the available ticket quantities will be 1, 4, 7, etc"
										matTooltipPosition="above"
										(click)="minTicketsTooltip.toggle()"
									>info</mat-icon></label>
								<input
									class='form-control'
									placeholder='1'
									[formControl]="ticket.get('incrementsOf')"
									[allowNegativeNumbers]="false"
									id="ticket-increments"
									mask="separator"
									thousandSeparator=","
									maxlength="12"
								/>
								<div
									class="g-error-input"
									*ngIf="ticket.get('incrementsOf').invalid && ((ticket.get('incrementsOf').dirty || ticket.get('incrementsOf').touched))"
								>
									*{{getError(ticket.get('incrementsOf').errors)}}
								</div>
							</div>
							<div
								[ngClass]="{ 'input-group-mobile': isMobile, 'input-group-web flex-grow-1': !isMobile }">
								<label class="g-form-label">Maximum Tickets Per User<mat-icon
										#maxTicketsTooltip="matTooltip"
										class="l-tooltip-icon"
										matTooltip="The largest amount of tickets that a user can book, whether in one order or across multiple orders"
										matTooltipPosition="above"
										(click)="maxTicketsTooltip.toggle()"
									>info</mat-icon></label>
								<input
									class='form-control'
									placeholder='20'
									[formControl]="ticket.get('maxPerUser')"
									[allowNegativeNumbers]="false"
									id="ticket-max-user"
									mask="separator"
									thousandSeparator=","
									maxlength="12"
								/>
								<div
									class="g-error-input"
									*ngIf="ticket.get('maxPerUser').invalid && ((ticket.get('maxPerUser').dirty || ticket.get('maxPerUser').touched))"
								>
									*{{getError(ticket.get('maxPerUser').errors)}}
								</div>
							</div>
						</div>

						<div class="g-double-input-container w-100 my-4">
							<div
								[ngClass]="{ 'input-group-mobile': isMobile, 'input-group-web flex-grow-1': !isMobile }">
								<label class="g-form-label">Hide This Ticket Type Until<mat-icon
										#hideTicketsUntilTooltip="matTooltip"
										class="l-tooltip-icon"
										matTooltip="This ticket type will be hidden and only revealed on the date selected"
										matTooltipPosition="above"
										(click)="hideTicketsUntilTooltip.toggle()"
									>info</mat-icon></label>
								<div>
									<input
										[ngxMatDatetimePicker]="hideUntilDatePicker"
										[disabled]="false"
										placeholder="DD/MM/YYYY HH:mm"
										(click)="hideUntilDatePicker.open()"
										class="form-control"
										[formControl]="ticket.get('hideUntil')"
										[max]="ticket.get('hideAfter').value ? ticket.get('hideAfter').value : undefined"
										id="ticket-hide-until"
										(dateChange)='handleChange()'
									>
									<ngx-mat-datetime-picker
										#hideUntilDatePicker
										[showSeconds]="false"
										[touchUi]="isMobile"
										[enableMeridian]="false"
										[disableMinute]="false"
										[hideTime]="false"
										[matDatepickerPosition]="'below'"
										[showSpinners]="false"
									>
										<ngx-mat-datepicker-actions>
											<button
												id="close-datepicker"
												class="g-secondary-button g-datepicker-button"
												ngxMatDatepickerCancel
											>CANCEL</button>
											<button
												id="apply-datepicker"
												class="g-primary-button g-datepicker-button"
												ngxMatDatepickerApply
											>APPLY</button>
										</ngx-mat-datepicker-actions>
									</ngx-mat-datetime-picker>
									<div
										class="g-error-input"
										*ngIf="ticket.get('hideUntil').invalid"
									>
										*{{getError(ticket.get('hideUntil').errors)}}
									</div>
								</div>
							</div>
							<div
								[ngClass]="{ 'input-group-mobile': isMobile, 'input-group-web flex-grow-1': !isMobile }">
								<label class="g-form-label">Hide This Ticket Type After<mat-icon
										#hideTicketsAfterTooltip="matTooltip"
										class="l-tooltip-icon"
										matTooltip="This ticket type will be visible and become hidden on the date selected"
										matTooltipPosition="above"
										(click)="hideTicketsAfterTooltip.toggle()"
									>info</mat-icon></label>
								<div>
									<input
										[ngxMatDatetimePicker]="hideAfterDatePicker"
										[disabled]="false"
										placeholder="DD/MM/YYYY HH:mm"
										(click)="hideAfterDatePicker.open()"
										class="form-control"
										[formControl]="ticket.get('hideAfter')"
										[min]="ticket.get('hideUntil').value ? ticket.get('hideUntil').value : undefined"
										id="ticket-hide-after"
										(dateChange)='handleChange()'
									>
									<ngx-mat-datetime-picker
										#hideAfterDatePicker
										[showSeconds]="false"
										[touchUi]="isMobile"
										[enableMeridian]="false"
										[disableMinute]="false"
										[hideTime]="false"
										[matDatepickerPosition]="'below'"
										[showSpinners]="false"
									>
										<ngx-mat-datepicker-actions>
											<button
												id="close-datepicker"
												class="g-secondary-button g-datepicker-button"
												ngxMatDatepickerCancel
											>CANCEL</button>
											<button
												id="apply-datepicker"
												class="g-primary-button g-datepicker-button"
												ngxMatDatepickerApply
											>APPLY</button>
										</ngx-mat-datepicker-actions>
									</ngx-mat-datetime-picker>
									<div
										class="g-error-input"
										*ngIf="ticket.get('hideAfter').invalid"
									>
										*{{getError(ticket.get('hideAfter').errors)}}
									</div>
								</div>
							</div>
						</div>

						<div class="g-double-input-container w-100 my-4">
							<div
								[ngClass]="{ 'input-group-mobile': isMobile, 'input-group-web flex-grow-1': !isMobile }">
								<label class="g-form-label">Ticket Type Valid From Date<mat-icon
										#validFromTooltip="matTooltip"
										class="l-tooltip-icon"
										matTooltip="The earliest date and time that this ticket type can be scanned at the event"
										matTooltipPosition="above"
										(click)="validFromTooltip.toggle()"
									>info</mat-icon></label>
								<div>
									<input
										[ngxMatDatetimePicker]="validFromDatePicker"
										[disabled]="false"
										placeholder="DD/MM/YYYY HH:mm"
										(click)="validFromDatePicker.open()"
										class="form-control"
										[formControl]="ticket.get('validFrom')"
										[max]="ticket.get('validTo').value ? ticket.get('validTo').value : undefined"
										id="ticket-valid-from"
										(dateChange)='handleChange()'
									>
									<ngx-mat-datetime-picker
										#validFromDatePicker
										[showSeconds]="false"
										[touchUi]="isMobile"
										[enableMeridian]="false"
										[disableMinute]="false"
										[hideTime]="false"
										[matDatepickerPosition]="'below'"
										[showSpinners]="false"
									>
										<ngx-mat-datepicker-actions>
											<button
												id="close-datepicker"
												class="g-secondary-button g-datepicker-button"
												ngxMatDatepickerCancel
											>CANCEL</button>
											<button
												id="apply-datepicker"
												class="g-primary-button g-datepicker-button"
												ngxMatDatepickerApply
											>APPLY</button>
										</ngx-mat-datepicker-actions>
									</ngx-mat-datetime-picker>
									<div
										class="g-error-input"
										*ngIf="ticket.get('validFrom').invalid"
									>
										*{{getError(ticket.get('validFrom').errors)}}
									</div>
								</div>
							</div>
							<div
								[ngClass]="{ 'input-group-mobile': isMobile, 'input-group-web flex-grow-1': !isMobile }">
								<label class="g-form-label">Ticket Type Valid To Date<mat-icon
										#validToTooltip="matTooltip"
										class="l-tooltip-icon"
										matTooltip="The latest date and time that this ticket type can be scanned at the event"
										matTooltipPosition="above"
										(click)="validToTooltip.toggle()"
									>info</mat-icon></label>
								<div>
									<input
										[ngxMatDatetimePicker]="validToDatePicker"
										[disabled]="false"
										placeholder="DD/MM/YYYY HH:mm"
										(click)="validToDatePicker.open()"
										class="form-control"
										[formControl]="ticket.get('validTo')"
										[min]="ticket.get('validFrom').value ? ticket.get('validFrom').value : undefined"
										id="ticket-valid-to"
										(dateChange)='handleChange()'
									>
									<ngx-mat-datetime-picker
										#validToDatePicker
										[showSeconds]="false"
										[touchUi]="isMobile"
										[enableMeridian]="false"
										[disableMinute]="false"
										[hideTime]="false"
										[matDatepickerPosition]="'below'"
										[showSpinners]="false"
									>
										<ngx-mat-datepicker-actions>
											<button
												id="close-datepicker"
												class="g-secondary-button g-datepicker-button"
												ngxMatDatepickerCancel
											>CANCEL</button>
											<button
												id="apply-datepicker"
												class="g-primary-button g-datepicker-button"
												ngxMatDatepickerApply
											>APPLY</button>
										</ngx-mat-datepicker-actions>
									</ngx-mat-datetime-picker>
									<div
										class="g-error-input"
										*ngIf="ticket.get('validTo').invalid"
									>
										*{{getError(ticket.get('validTo').errors)}}
									</div>
								</div>
							</div>
						</div>

						<div>
							<app-color-picker
								[label]="'Scan success colour'"
								[control]="ticket.get('scannerColour')"
								[placeholder]="'e.g. #fff'"
								id="ticket-color"
							></app-color-picker>
						</div>

						<app-image-upload
							[eventId]="eventId"
							[label]="'Ticket Image'"
							[maxFileSize]="imageService.maxImageSize"
							[imageServiceType]="ImageServiceTypeEnum.TICKET"
							[uploadText]="'upload your JPG, PNG GIF or BMP ticket image.'"
							[customUpload]="true"
							[imagePath]="getImagePath(ticket)"
							[(isUploading)]="isImageUploading"
							(handleExternally)="onImageUpload($event, ticket)"
							(removeHandler)="onImageRemove(ticket)"
							(maxFileSizeAchieved)="imageService.onImageMaxSizeAchived()"
							class="l-image"
							id="ticket-image"
						>
						</app-image-upload>

						<div
							class="l-toggle-container mt-4"
							[ngClass]="{'mobile' : isMobile}"
						>
							<label class="g-form-label">Sell at the door</label>
							<mat-slide-toggle
								class="standard-toggle"
								[formControl]="ticket.get('sellAtTheDoor')"
								id="ticket-sell-at-door"
								(change)="handleSellAtTheDoorToggle($event, ticket)"
							>
							</mat-slide-toggle>
						</div>

						<div
							class="l-toggle-container mt-4"
							[ngClass]="{'mobile' : isMobile}"
							*ngIf="ticket.get('sellAtTheDoor').value"
						>
							<label class="g-form-label">
								Automatically check this ticket in
								<mat-icon
									#autoCheckinTooltip="matTooltip"
									class="l-tooltip-icon"
									matTooltip="If selected, the buyer's ticket will be automatically checked in upon the completion of the purchase if it is sold at the door. This will not affect online sales."
									matTooltipPosition="above"
									(click)="autoCheckinTooltip.toggle()"
								>info</mat-icon>
							</label>
							<mat-slide-toggle
								class="standard-toggle"
								[formControl]="ticket.get('autoCheckin')"
								id="ticket-auto-checkin"
							>
							</mat-slide-toggle>
						</div>

						<div
							class="l-toggle-container mt-4"
							[ngClass]="{'mobile' : isMobile}"
							*ngIf="hasVendors"
						>
							<label class="g-form-label">Vendor ticket</label>
							<mat-slide-toggle
								class="standard-toggle"
								[formControl]="ticket.get('canVendor')"
								id="ticket-vendor"
							>
							</mat-slide-toggle>
						</div>

					</div>

					<div
						class="l-toggle-container mt-4"
						[ngClass]="{'mobile' : isMobile}"
					>
						<label class="g-form-label">Hide this ticket type from your event page</label>
						<mat-slide-toggle
							class="standard-toggle"
							[formControl]="ticket.get('isTypeHidden')"
							id="ticket-hide"
						>
						</mat-slide-toggle>
					</div>

				</div>
			</td>
		</ng-container>
		<tr
			mat-header-row
			*matHeaderRowDef="displayColumns"
		></tr>
		<tr
			mat-row
			*matRowDef="let ticket; columns: displayColumns;"
			cdkDrag
			id="sourceElement"
		>
		</tr>
		<tr
			mat-row
			*matRowDef="let ticket; columns: ['expandedDetail']"
			class="l-detail-row"
			[class.collapsed-row]="expandedDetailElement !== ticket.value.id"
		></tr>
		<tr *matNoDataRow>
			<td [colSpan]="displayColumns.length">
				<div class="l-empty-state">
					<h3 class="mb-3">
						{{hasSearchTerm ? 'Nothing to see here' : 'Add ticket types...'}}
					</h3>
					<div class="mb-3">
						{{ hasSearchTerm ? 'You have no tickets of that name' : 'You currently have no ticket types.' }}
					</div>
				</div>
			</td>
		</tr>
	</table>
</div>