import { Component, EventEmitter, OnInit } from '@angular/core';
import { EventCurrencyOption, TopLinkCampaigns } from '@app/models/event.model';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { StoreService } from '@app/services/store/store.service';
import { filter, take, takeUntil } from 'rxjs';
import { Go } from '@app/store/actions/router/router.actions';
import { InternalURLCreator } from '@app/services/url/url.dictionary';
import { Actions, ofType } from '@ngrx/effects';
import {
	GetCurrentCampaigns,
	GetDashboardEvents,
	GetSalesOverview
} from '@app/store/actions/organiser/organiser.actions';
import { OrganiserActionsConstants } from '@app/store/actions/organiser/organiser.actions.constants';
import * as organiserSelectors from '@app/store/selectors/organiser.selector';
import * as userSelectors from '@app/store/selectors/user.selector';
import {
	ChartInput,
	DateRange,
	NameAndID,
	OrganiserDashboardEvent
} from '@app/models/organiser.model';
import { MatDialog } from '@angular/material/dialog';
import { GenerateReportsModalComponent } from '../../modals/generate-reports-modal/generate-reports-modal.component';
import { CurrencySelectModalComponent } from '@app/shared/currency-select-modal/currency-select-modal.component';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.sass'],
})
export class DashboardComponent implements OnInit {
	isMobile = false;

	userId: number;
	upcomingEvents: OrganiserDashboardEvent[] = [];
	pastEvents: OrganiserDashboardEvent[] = [];
	currentCampaigns: TopLinkCampaigns[] = [];

	salesData: ChartInput[] = [];
	totalSales = '0';
	totalMonthSales: number;
	ticketsData: ChartInput[] = [];
	totalTickets = 0;

	eventNames: NameAndID[];

	isSalesOverviewLoading = true;
	isDashboardEventsLoading = true;
	isCurrentCampaignsLoading = true;

	currencies: EventCurrencyOption[];
	selectedCurrencyId = 0;
	selectedCurrencyName = '';
	selectedCurrencySymbol = '';
	dateRange: DateRange;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private breakpointService: BreakpointService,
		private store: StoreService,
		private actions$: Actions,
		private dialog: MatDialog
	) { }

	ngOnInit(): void {
		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});

		this.store.select(userSelectors.userId()).pipe(takeUntil(this.destroyed$), filter((user) => user != null)).subscribe((userId) => {
			this.userId = userId;
			this.handleSaleOverview();
			this.handleCurrentCampaigns();
			this.handleDashboardEvents();
		});
	}

	handleSaleOverview() {
		this.actions$
			.pipe(
				ofType(OrganiserActionsConstants.GET_SALES_OVERVIEW_SUCCESS, OrganiserActionsConstants.GET_SALES_OVERVIEW_FAILED),
				take(1)
			)
			.subscribe(() => {
				this.isSalesOverviewLoading = false;
			});

		const currentDate = new Date();
		const oneYearAgo = new Date(currentDate.getTime());
		oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

		this.dateRange = {
			dateFrom: oneYearAgo.toISOString().split('T')[0],
			dateTo: currentDate.toISOString().split('T')[0],
		};

		this.store.dispatch(new GetSalesOverview({ id: this.userId, dateRange: this.dateRange, currencyId: this.selectedCurrencyId }));


		this.store.select(organiserSelectors.getSalesOverview())
			.pipe(takeUntil(this.destroyed$), filter((data) => data != null))
			.subscribe(({ sales, totalSales, tickets, totalTickets, currencies, currencyId, currencyName, currencySymbol }) => {
				this.totalSales = totalSales % 1 === 0 ? totalSales.toFixed(0) : totalSales.toFixed(2);
				this.totalTickets = totalTickets;
				this.salesData = sales;
				this.ticketsData = tickets;
				this.isSalesOverviewLoading = false;
				this.currencies = currencies;
				this.selectedCurrencyId = currencyId;
				this.selectedCurrencyName = currencyName;
				this.selectedCurrencySymbol = currencySymbol;
			});
	}

	handleCurrencyDialog() {
		this.dialog
			.open(CurrencySelectModalComponent, {
				data: {
					currencies: this.currencies,
					currencyId: this.selectedCurrencyId,
					title: 'Select your currency',
					text: 'This applies to your sales and ticket graph',
				},
				panelClass: 'g-standard-dialog',
			})
			.afterClosed()
			.subscribe((currency: EventCurrencyOption) => {
				if (currency) {
					this.isSalesOverviewLoading = true;
					this.store.dispatch(new GetSalesOverview({
						id: this.userId,
						dateRange: this.dateRange,
						currencyId: +currency.value.id,
					}));
				}
			});
	}

	handleCurrentCampaigns() {
		this.actions$
			.pipe(
				ofType(OrganiserActionsConstants.GET_CURRENT_CAMPAIGNS_SUCCESS, OrganiserActionsConstants.GET_CURRENT_CAMPAIGNS_FAILED),
				take(1)
			)
			.subscribe(() => {
				this.isCurrentCampaignsLoading = false;
			});
		this.store.dispatch(new GetCurrentCampaigns({ id: this.userId }));
		this.store.select(organiserSelectors.getCurrentCampaigns())
			.pipe(takeUntil(this.destroyed$), filter((linkCampaigns) => linkCampaigns != null))
			.subscribe((campaigns) => {
				this.currentCampaigns = campaigns;
			});
	}

	handleDashboardEvents() {
		this.actions$
			.pipe(
				ofType(OrganiserActionsConstants.GET_DASHBOARD_EVENTS_SUCCESS, OrganiserActionsConstants.GET_DASHBOARD_EVENTS_FAILED),
				take(1)
			)
			.subscribe(() => {
				this.isDashboardEventsLoading = false;
			});

		this.store.dispatch(new GetDashboardEvents({ id: this.userId }));
		this.store.select(organiserSelectors.getDashboardEvents()).pipe(takeUntil(this.destroyed$)).subscribe((event) => {
			if (event) {
				this.upcomingEvents = event.upcoming;
				this.pastEvents = event.past;
			}
		});
	}

	handleEmailReport() {
		this.dialog.open(GenerateReportsModalComponent, {
			data: {
				isMobile: this.isMobile,
				userId: this.userId,
			},
			panelClass: 'g-standard-dialog',
		});
	}

	handleRouterLink(id: string): string {
		return InternalURLCreator.eventDashboard(id);
	}

	handleCreateEventNavigation() {
		this.store.dispatch(new Go({ path: [InternalURLCreator.createEvent()] }));
	}

	handleCreateChampaignNavigation() {
		this.store.dispatch(new Go({ path: [InternalURLCreator.earnCommission()] }));
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
