<div 
	[ngClass]="outerClass" 
	[class.l-has-tooltip]="tooltip"
	[class.l-padding-bottom]="paddingBottom"
>
	<label *ngIf="label" [ngClass]="labelClass" class="l-label">
		{{label}}
		<span class="g-orange" *ngIf="required">*</span>
		<mat-icon 
			*ngIf="tooltip" 
			class="l-tooltip"
			#controlFieldTooltip="matTooltip"
			[matTooltip]="tooltip"
			matTooltipPosition="right"
			(click)="controlFieldTooltip.toggle()"
		>
			info
		</mat-icon>
	</label>
	<div class="d-flex flex-row">
		<div *ngIf="customIcon" class="l-custom-icon-container">
			<mat-icon class="l-custom-icon">{{customIcon}}</mat-icon>
		</div>
		<input 
			[formControl]="control" 
			type="text" 
			[placeholder]="placeholder"
			class="form-control"
			[ngClass]="{'l-error': (errorOutline && getError(control.errors))}"
			[class.l-input-and-icon]="customIcon"
			[id]="customId"
			#inputRef
		>
	</div>
		<small  class="g-error-input" *ngIf="isControlInvalid() && !errorOutline">
			*{{getError(control.errors)}}
		</small>
</div>
