<div [ngClass]="{'p-3': isMobile, 'pt-5 px-5': !isMobile}">
	<h3 class="mb-3">Download Report</h3>
	<form #reportForm="ngForm" class="g-form-container" [ngClass]="{'my-2': isMobile, 'my-3': !isMobile}">
		<div *ngIf="!isLoading; else showLoading" class="w-100">
			<div *ngIf="data.isOngoing">
				<label class="g-form-label">Select Schedule</label>
				<div *ngIf="schedules.length" class="input-group w-100 mb-3 l-max-width">
					<mat-select id='schedule-select' class="form-control w-100" [(ngModel)]="selectedScheduleId"
						name="scheduleId" [disabled]="isAllScheduleSelected" placeholder="Please Select a Schedule"
						required (ngModelChange)="onScheduleSelectChange()">
						<mat-option [value]="0">
							All Schedules
						</mat-option>
						<mat-option *ngFor="let scheduleItem of scheduleItems" [value]="scheduleItem.id">
							<ng-container *ngIf="isSameDaySchedule(scheduleItem); else differentDay">
								{{ scheduleItem.startDate | date: 'MM/dd/yyyy, h:mm a' }} - 
								{{ scheduleItem.endDate | date: 'h:mm a' }}
							</ng-container>
							<ng-template #differentDay>
								{{ scheduleItem.startDate | date: 'MM/dd/yyyy, h:mm a' }} - 
								{{ scheduleItem.endDate | date: 'MM/dd/yyyy, h:mm a' }}
							</ng-template>
						</mat-option>
					</mat-select>
				</div>
			</div>
			<div class="input-group w-100 mb-3 l-max-width">
				<label class="g-form-label">Select Report Type</label>
				<mat-select id='report-type-select' class="form-control w-100" [(ngModel)]="selectedReportType"
					name="reportType" placeholder="Please Select a Report Type" required>
					<mat-option *ngFor="let type of reportTypes" [value]="type">
						{{ type }}
					</mat-option>
				</mat-select>
			</div>
			<div *ngIf="selectedReportType === 'GUEST LIST'" class="input-group w-100 mb-3 l-max-width">
				<label class="g-form-label">Select Format</label>
				<mat-select id='format-select' class="form-control w-100" [(ngModel)]="selectedFormat" name="formatType"
					placeholder="Please Select a File Format" required>
					<mat-option *ngFor="let format of formats" [value]="format">
						{{ format }}
					</mat-option>
				</mat-select>
			</div>
		</div>
		<ng-template #showLoading>
			<div class="l-loading">
				<mat-spinner></mat-spinner>
			</div>
		</ng-template>
	</form>
	<app-footer-button [isMobile]="isMobile" [isModal]="isModal" [primaryText]="primaryButtonText"
		[secondaryText]="secondaryButtonText" [isDisabled]="!canDownload()" [isLoading]="isButtonLoading"
		($primaryButton)="handleDownload()" ($secondaryButton)="handleCancel()">
	</app-footer-button>
</div>