import { Component, Input } from '@angular/core';
import { OrganiserDashboardEvent } from '@app/models/organiser.model';

@Component({
	selector: 'app-event-card',
	templateUrl: './event-card.component.html',
	styleUrls: ['./event-card.component.sass'],
})
export class EventCardComponent {
	@Input() data: OrganiserDashboardEvent;
	@Input() isMobile = false;
}
