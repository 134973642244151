import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
	ProductStatus,
	ProductStatusMapping,
	RegistrationStatus,
	RegistrationStatusMapping
} from '@app/models/event.model';
type DataType = 'ticket' | 'registration' | 'promoter-network';

@Component({
	selector: 'app-ticket-card',
	templateUrl: './ticket-card.component.html',
	styleUrls: ['./ticket-card.component.sass'],
})
export class TicketCardComponent implements OnInit {
	@Input() productStatus: number;
	@Input() eventImage: string;
	@Input() productName: string;
	@Input() lineOne: string;
	@Input() lineTwo: string;
	@Input() isTicket = false;
	@Input() venue: string;
	@Input() bookingAmount: number;
	@Input() currencySymbol: string;
	@Input() reference: string;
	@Input() isRegistration = false;

	@Input() type: DataType = 'ticket';
	@Input() isMobile = false;

	@Output() linkClick = new EventEmitter();

	status: string;
	statusClass: string;
	manageText: string;

	ngOnInit() {
		switch (this.type) {
			case 'ticket':
				this.handleStatus(this.productStatus, ProductStatus, ProductStatusMapping);
				this.manageText = 'MANAGE BOOKING';
				break;
			case 'registration':
				this.handleStatus(this.productStatus, RegistrationStatus, RegistrationStatusMapping);
				this.manageText = 'MANAGE REGISTRATION';
				break;
			case 'promoter-network':
				this.handleStatus(this.productStatus, ProductStatus, ProductStatusMapping);
				this.manageText = 'VIEW DETAILS';
				break;
		}
	}

	handleStatus(
		statusValue: number,
		statusEnum: typeof ProductStatus | typeof RegistrationStatus,
		statusMappingEnum: typeof ProductStatusMapping | typeof RegistrationStatusMapping
	) {
		this.status = statusMappingEnum[statusEnum[statusValue]].toUpperCase();
		this.statusClass = statusEnum[statusValue].toLowerCase();
	}

	handleLinkClick() {
		this.linkClick.emit();
	}

	getScrollDuration(length: number): string {
		const duration = length * 0.2; // Adjust the multiplier as needed
		return `${duration}s`;
	  }

	GetScrollTimeStyle(wordCount: number) {
		return {
			transition: Math.floor(Math.sqrt(wordCount) - 3).toString() + 's',
		};
	}
}
