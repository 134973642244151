<div class="l-container">
    <div class="l-icon-container">
        <mat-icon class="l-icon">{{ this.data.scheduledToGoPublicOn ? 'cancel' : 'calendar_today' }}</mat-icon>
    </div>
    <div *ngIf="data.scheduledToGoPublicOn else notScheduled;">
        <div class="l-cancel-schedule-text">
            Removing the schedule will keep your event private indefinitely.
            Are you sure you want to remove the schedule to set your event public on
            <span class="l-scheduled-date">
                {{ (getScheduledToGoPublicOn() | date:'EEEE, d LLLL yyyy, HH:mm') }} 
            </span>
        </div>
        <div class="l-buttons-container">
            <button class="g-secondary-button border-0" (click)="handleCancel()">CANCEL</button>
            <button class="g-primary-button" 
                (click)="handleSave()" 
            >SAVE</button>
        </div>
    </div>
    <ng-template #notScheduled>
        <div>
            Select the date you'd like your event to become public. 
            The event will stay private until the date and time selected.
        </div>
        <div class="l-date-container">
            <form #schedulePublicForm="ngForm" class="g-form-container my-5">
                <input 
                    [ngxMatDatetimePicker]="dateFromPicker" 
                    [disabled]="false"
                    placeholder="DD/MM/YYYY, HH:mm" 
                    (click)="dateFromPicker.open()" 
                    class="form-control"
                    [min]="datesService.getStartOfTheDay()"
                    id="schedule-content-dateFrom"
                    [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="dateToGoLive"
                    required
                    #dateForm="ngModel"
                >
                <ngx-mat-datetime-picker
                    #dateFromPicker
                    [showSeconds]="false" 
                    [touchUi]="data.isMobile"
                    [enableMeridian]="false" 
                    [disableMinute]="false" 
                    [hideTime]="false"
                    [defaultTime]="[0,0,0]"
					[showSpinners]="false"
                >
					<ngx-mat-datepicker-actions>
						<button
							id="close-datepicker"
							class="g-secondary-button g-datepicker-button"
							ngxMatDatepickerCancel
						>CANCEL</button>
						<button
							id="apply-datepicker"
							class="g-primary-button g-datepicker-button"
							ngxMatDatepickerApply
						>APPLY</button>
					</ngx-mat-datepicker-actions>
                </ngx-mat-datetime-picker>
            </form>
            <div class="l-buttons-container">
                <button class="g-secondary-button border-0" (click)="handleCancel()">CANCEL</button>
                <button class="g-primary-button" 
                    (click)="handleSave()" 
                    [class.disabled]="dateForm?.invalid"
                    [disabled]="dateForm.invalid"
                    id="schedule-to-go-public-button"
                >
                    SAVE
                </button>
            </div>
        </div>
    </ng-template>
</div>
