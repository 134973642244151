import { Component, OnInit } from '@angular/core';
import { TokenService } from '@app/services/token/token.service';
import { StoreService } from '@app/services/store/store.service';
import { Observable } from 'rxjs';
import { isUserFetching } from '@app/store/selectors/user.selector';
import { GoogleAnalyticsService } from './services/google-analytics/google-analytics.service';

@Component({
	selector: 'app-root',
	templateUrl: './root.component.html',
	styleUrls: ['./root.component.sass'],
})
export class RootComponent implements OnInit {
	isFetching$: Observable<boolean>;

	constructor(
		private tokenService: TokenService,
		private storeService: StoreService,
		private googleAnalyticsService: GoogleAnalyticsService
	) {
		this.tokenService.setDevToken();
		this.googleAnalyticsService.initializeGA();
	}

	ngOnInit() {
		this.isFetching$ = this.storeService.select(isUserFetching());
	}
}
