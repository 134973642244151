<div class="l-container">
	<div *ngIf='thumbnail; else showInitials'>
		<img
			[src]="thumbnail"
			class="l-image"
		/>
	</div>
	<ng-template #showInitials>
		<div class="l-initials">
			{{ getOrganiserNameInitials() }}
		</div>
	</ng-template>
	<h3 class='l-name'>{{ name }}</h3>
	<mat-icon
		id="delete-organiser-profile"
		class='l-delete'
		(click)='$event.stopPropagation(); $event.preventDefault(); handleDelete()'
	>
		delete_outline
	</mat-icon>
</div>