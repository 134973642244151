import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { RequestType, ResponseScheme } from '@app/models/http.model';
import { IMembershipDomainAPI } from '@app/models/membership.api.model';
import {
	User,
	UserContactListDraftItem,
	PreviousEvent,
	ContactsSource,
	BankAccount,
	Bank,
	DraftBankAccount,
	UserContactInfo,
	Passwords,
	EmailSubscription,
	EmailPreference,
	CurrentBankAccount,
	MyRegistrations,
	RefundableTicketReturn,
	RefundableTicket,
	TransferTicketReturn,
	TransferTicketRequest,
	MailTicketsPdf,
	EditTicketDetails,
	RegistrationDetails,
	CheckoutFileUpload,
	BankAccountReturn,
	AccountTicketResales,
	CreateTicketResale,
	UserSavedEvent,
	PromoterNetwork,
	Referrals,
	UpdateReferrals
} from '@app/models/user.model';
import { SchemeID } from '@app/models/dataSchema.model';
import { TokenService } from '@app/services/token/token.service';
import { HttpService } from '../http/http.service';
import { AsyncResponse } from '@app/models/http.model';
import { UserContactInfoForm, UserPreviousContactList } from '@app/models/event.model';
import { UserChart } from '@app/models/ticket.model';
import { EventOrganiserProfile } from '@app/models/profile.model';
import { ManageBooking, ManageBookingInvoice, MyTickets } from '@app/models/user.model';
import config from '@app/config';

const roleClaim = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';

@Injectable()
export class MembershipDomainAPI implements IMembershipDomainAPI {
	constructor(
		private http: HttpService,
		private tokenService: TokenService
	) { }

	verifyToken = (token: string): AsyncResponse<User> =>
		this.http.makeRequest({
			url: '/api/membership/info',
			type: RequestType.GET,
			payload: null,
			headers: {
				'Authorization': `Bearer ${token}`,
			},
		})
			.pipe(
				map((value: ResponseScheme<User>) => {
					let isAdmin = false;
					const parsedToken = this.tokenService.parseToken();
					if (parsedToken) {
						isAdmin = parsedToken[roleClaim] && parsedToken[roleClaim].includes('Administrator');
					}

					value.payload.isAdmin = isAdmin;
					return value;
				})
			);

	updateAPIToken = (): AsyncResponse<User> =>
		this.http.makeRequest({
			url: '/api/membership/user/new-api-token',
			type: RequestType.POST,
		});

	getSeatsIoAccount = (eventId: SchemeID): AsyncResponse<string> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/seats/designerkey`,
			type: RequestType.GET,
		});

	getUserCharts = (eventId: SchemeID): AsyncResponse<{
		items: UserChart[];
	}> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/seats/chart`,
			type: RequestType.GET,
		});

	setUserInfo = (userInfo: UserContactInfoForm): AsyncResponse<UserContactInfoForm> =>
		this.http.makeRequest({
			url: '/api/membership/info',
			type: RequestType.POST,
			payload: userInfo,
		});

	createContactList = (contacts: UserContactListDraftItem[], source: ContactsSource, name: string): AsyncResponse<{
		itemsImported: number;
		contactListId: SchemeID;
		contactListName: string;
	}> =>
		this.http.makeRequest({
			url: '/api/membership/contacts',
			type: RequestType.POST,
			payload: {
				contacts,
				source,
				name,
			},
		});

	getPreviousContactLists = (eventId: SchemeID): AsyncResponse<UserPreviousContactList[]> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/contactlists`,
			type: RequestType.GET,
		});

	getPreviousEvents = (eventId: SchemeID): AsyncResponse<PreviousEvent[]> =>
		this.http.makeRequest({
			url: `/api/product/${eventId}/previousevents`,
			type: RequestType.GET,
		});

	getPreviousEventsAttendees = (eventIds: SchemeID[]): AsyncResponse<UserContactListDraftItem[]> =>
		this.http.makeRequest({
			url: '/api/membership/previousevents/attendees',
			type: RequestType.POST,
			payload: eventIds,
		});

	getBankAccounts = (): AsyncResponse<BankAccountReturn> =>
		this.http.makeRequest({
			url: '/api/membership/bankaccounts',
			type: RequestType.GET,
		});

	addBankAccount = (account: DraftBankAccount): AsyncResponse<BankAccount> =>
		this.http.makeRequest({
			url: '/api/membership/bankaccounts',
			type: RequestType.POST,
			payload: account,
		});

	updateBankAccount = (account: CurrentBankAccount): AsyncResponse<BankAccount> =>
		this.http.makeRequest({
			url: '/api/membership/bankaccounts',
			type: RequestType.PATCH,
			payload: account,
		});

	deleteBankAccount = (accountId: number): AsyncResponse<number> =>
		this.http.makeRequest({
			url: `/api/membership/delete-bank-account?${this.http.stringifyQueryParams({ accountId: accountId })}`,
			type: RequestType.PATCH,
		});

	getBanks = (currencyId: SchemeID): AsyncResponse<Bank[]> =>
		this.http.makeRequest({
			url: '/api/membership/banks',
			type: RequestType.GET,
			payload: {
				currencyId,
			},
		});

	getAllBanks = (): AsyncResponse<Bank[]> =>
		this.http.makeRequest({
			url: '/api/membership/all-banks',
			type: RequestType.GET,
		});

	getOrganiserProfiles = (): AsyncResponse<EventOrganiserProfile[]> =>
		this.http.makeRequest({
			url: '/api/organisers',
			type: RequestType.GET,
		});

	getUserManageBooking = (id: SchemeID): AsyncResponse<ManageBooking> =>
		this.http.makeRequest({
			url: `/api/account/orders/${id}`,
			type: RequestType.GET,
		});

	updateBookingInvoice = (id: SchemeID, invoice: ManageBookingInvoice): AsyncResponse<{
		id: SchemeID;
		invoice: ManageBookingInvoice;
	}> => this.http.makeRequest({
		url: `/api/account/orders/${id}/editinvoice`,
		type: RequestType.POST,
		payload: invoice,
	});

	updateManageBooking = (id: SchemeID, newScheduleItemId: SchemeID): AsyncResponse<ManageBooking> => this.http.makeRequest({
		url: `/api/account/orders/${id}/`,
		type: RequestType.POST,
		payload: {
			newScheduleItemId,
		},
	});

	updateOrganiserProfile = (profile: EventOrganiserProfile): AsyncResponse<EventOrganiserProfile> =>
		this.http.makeRequest({
			url: `/api/organisers/${profile.id}`,
			type: RequestType.PUT,
			payload: profile,
		});

	deleteOrganiserProfile = (id: SchemeID): AsyncResponse<{ id: SchemeID }> =>
		this.http.makeRequest({
			url: `/api/organisers/${id}`,
			type: RequestType.DELETE,
		});

	getUserMyTickets = (cartId: SchemeID): AsyncResponse<MyTickets[]> =>
		(this.http.makeRequest({
			url: `/api/account/orders?cartid=${cartId}`,
			type: RequestType.GET,
		}));

	getUserMyRegistrations = (): AsyncResponse<MyRegistrations[]> =>
		(this.http.makeRequest({
			url: '/api/account/registrations',
			type: RequestType.GET,
		}));

	getRefundableTickets = (id: number): AsyncResponse<RefundableTicketReturn> =>
		(this.http.makeRequest({
			url: `/api/account/orders/${id}/refund`,
			type: RequestType.GET,
		}));

	postRefundableTickets = (
		id: number,
		tickets: RefundableTicket[],
		bankAccountId: number
	): AsyncResponse<RefundableTicketReturn> =>
		(this.http.makeRequest({
			url: `/api/account/orders/${id}/refund`,
			type: RequestType.POST,
			payload: {
				tickets: tickets,
				bankAccountId: bankAccountId,
			},
		}));

	getTransferableTickets = (purchaseId: number): AsyncResponse<TransferTicketReturn> =>
		(this.http.makeRequest({
			url: `/api/account/orders/${purchaseId}/transfer`,
			type: RequestType.GET,
		}));

	transferTickets = (purchaseId: number, tickets: TransferTicketRequest): AsyncResponse<TransferTicketReturn> =>
		(this.http.makeRequest({
			url: `/api/account/orders/${purchaseId}/transfer`,
			type: RequestType.POST,
			payload: tickets,
		}));

	transferTicketsBack = (purchaseId: number): AsyncResponse<number> =>
		(this.http.makeRequest({
			url: `/api/account/orders/${purchaseId}/transferback`,
			type: RequestType.POST,
		}));

	updateFundraiserInvoice = (id: SchemeID, booking: MyTickets): AsyncResponse<MyTickets> =>
		this.http.makeRequest({
			url: `/api/account/orders/${id}/fundraiserinvoice`,
			type: RequestType.POST,
			payload: booking,
		});

	getUserInfo = (): AsyncResponse<UserContactInfo> =>
		this.http.makeRequest({
			url: '/api/membership/contact-info',
			type: RequestType.GET,
		});

	updateUserInfo = (userInfo: UserContactInfo): AsyncResponse<UserContactInfo> =>
		this.http.makeRequest({
			url: '/api/membership/contact-info',
			type: RequestType.PATCH,
			payload: userInfo,
		});

	updatePassword = (passwords: Passwords): AsyncResponse<Passwords> =>
		this.http.makeRequest({
			url: '/api/membership/user/change-password',
			type: RequestType.PUT,
			payload: passwords,
		});

	deleteUserAccount = (password: SchemeID): AsyncResponse<string> =>
		this.http.makeRequest({
			url: '/api/membership/user/delete-account',
			type: RequestType.PUT,
			payload: { password },
		});

	getEmailPreferences = (e: string): AsyncResponse<EmailSubscription> =>
		this.http.makeRequest({
			url: `/api/membership/subscriptions?e=${e}`,
			type: RequestType.GET,
		});

	updateEmailPreferences = (e: string, preferences: EmailPreference): AsyncResponse<EmailSubscription> =>
		this.http.makeRequest({
			url: `/api/membership/subscriptions?e=${e}`,
			type: RequestType.POST,
			payload: preferences,
		});

	downloadTicketsSeperately = (tickets: MailTicketsPdf): AsyncResponse<string> =>
		this.http.makeRequest({
			url: `/api/account/orders/${tickets.purchaseId}/downloadticketsseperately`,
			type: RequestType.PUT,
			payload: tickets,
		});

	getEditTicketDetails = (id: number): AsyncResponse<EditTicketDetails> =>
		this.http.makeRequest({
			url: `/api/account/orders/${id}/tickets/editdetails`,
			type: RequestType.GET,
		});

	updateEditTicketDetails = (id: number, editTicketDetails: EditTicketDetails): AsyncResponse<EditTicketDetails> =>
		this.http.makeRequest({
			url: `/api/account/orders/${id}/tickets/editdetails`,
			type: RequestType.POST,
			payload: editTicketDetails,
		});

	getRegistrationDetails = (id: number): AsyncResponse<RegistrationDetails> =>
		this.http.makeRequest({
			url: `/api/account/registrations/${id}`,
			type: RequestType.GET,
		});

	updateRegistrationDetails = (id: number, registrationDetails: RegistrationDetails): AsyncResponse<RegistrationDetails> =>
		this.http.makeRequest({
			url: `/api/account/registrations/${id}`,
			type: RequestType.POST,
			payload: registrationDetails,
		});


	uploadFile = ( formData: FormData, name: string, purchaseId: number, encrypt: string, productId: number) =>
		this.http.http.post(
			`${config.baseURL}/api/order/${purchaseId}/checkout/file/upload?encrypt=${encrypt}&productId=${productId}&name=${name}`,
			formData
		) as AsyncResponse<CheckoutFileUpload>;

	getResaleTickets = (purchaseId: number): AsyncResponse<AccountTicketResales> =>
		this.http.makeRequest({
			url: `/api/account/orders/${purchaseId}/resale`,
			type: RequestType.GET,
		});

	postResaleTickets = (purchaseId: number, tickets: CreateTicketResale): AsyncResponse<AccountTicketResales> =>
		this.http.makeRequest({
			url: `/api/account/orders/${purchaseId}/resale`,
			type: RequestType.POST,
			payload: tickets,
		});

	cancelResaleTickets = (purchaseId: number, ticketResaleId: number): AsyncResponse<AccountTicketResales> =>
		this.http.makeRequest({
			url: `/api/account/orders/${purchaseId}/resale/${ticketResaleId}/cancel`,
			type: RequestType.PUT,
		});

	getUserSavedEvents = (): AsyncResponse<UserSavedEvent[]> =>
		this.http.makeRequest({
			url: '/api/account/savedevents',
			type: RequestType.GET,
		});

	getAllPromoterNetworks = (): AsyncResponse<PromoterNetwork[]> =>
		this.http.makeRequest({
			url: '/api/account/promoter-networks/',
			type: RequestType.GET,
		});

	getPromoterNetwork = (id: number): AsyncResponse<PromoterNetwork> =>
		this.http.makeRequest({
			url: `/api/account/promoter-networks/${id}`,
			type: RequestType.GET,
		});

	updatePromoterNetworkLink = (id: number, code: string): AsyncResponse<PromoterNetwork> =>
		this.http.makeRequest({
			url: `/api/account/promoter-networks/${id}/code?code=${code}`,
			type: RequestType.POST,
		});

	updatePromoterBankAccount = (id: number, accountId: number): AsyncResponse<PromoterNetwork> =>
		this.http.makeRequest({
			url: `/api/account/promoter-networks/${id}/bankaccount?bankAccountId=${accountId}`,
			type: RequestType.POST,
		});

	getReferralDetails = (): AsyncResponse<Referrals> =>
		this.http.makeRequest({
			url: '/api/account/referrals',
			type: RequestType.GET,
		});

	updateReferralCode = (newCode: string): AsyncResponse<UpdateReferrals>  =>
		this.http.makeRequest ({
			url: `/api/account/referrals/code?code=${newCode}`,
			type: RequestType.POST,
		});

	updateReferralBankAccount = (id: number): AsyncResponse<UpdateReferrals> =>
		this.http.makeRequest({
			url: `/api/account/referrals/bankaccount?id=${id}`,
			type: RequestType.POST,
		});
}
