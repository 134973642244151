import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { FilterOptions } from '@app/models/shared';
import { ContactsSource, PreviousEvent, UserContactListDraftItem, UserContactsInviteBatch } from '@app/models/user.model';
import { StoreService } from '@app/services/store/store.service';
import { InternalURLCreator } from '@app/services/url/url.dictionary';
import { EventActionsConstants } from '@app/store/actions/event/event.actions.constants';
import { Go } from '@app/store/actions/router/router.actions';
import { CreateContactList, GetEventsAttendees, GetPreviousEvents } from '@app/store/actions/user/user.actions';
import { UserActionsConstants } from '@app/store/actions/user/user.actions.constants';
import * as userContactsSelector from '@app/store/selectors/userContacts.selector';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs';

@Component({
	selector: 'app-previous-event',
	templateUrl: './previous-event.component.html',
	styleUrls: ['./previous-event.component.sass'],
})
export class PreviousEventComponent implements OnInit {

	isPreviousEventsLoading = true;
	isPreviousContactsLoading = false;
	isButtonLoading = false;
	isButtonDisabled = true;
	hasInitialEvents: boolean;
	hasInitialAttendees: boolean;
	hasAttendeesDispatched: boolean;
	sortInitialized = false;
	displayColumns: string[] = ['checkbox', 'eventName', 'eventDate', 'numberOfContacts'];
	attendeesDisplayColumns: string[] = ['checkbox', 'name', 'surname', 'email'];
	currentDate: Date = new Date();
	selectedEvents: PreviousEvent[] = [];
	userContactsInviteBatch = new UserContactsInviteBatch();

	events: MatTableDataSource<PreviousEvent> = new MatTableDataSource();
	attendees: MatTableDataSource<UserContactListDraftItem> = new MatTableDataSource();

	@Input() eventId: number;
	@Input() isMobile = false;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	@ViewChild('previousEventsAttendees') table: MatTable<UserContactListDraftItem>;
	@ViewChild('attendees') attendeesBlock!: ElementRef;
	@ViewChild(MatSort) set sort(_sort: MatSort) {
		if (_sort && !this.sortInitialized) {
			this.sortInitialized = true;
			this.events.sort = _sort;
		}
	};

	constructor(
		private store: StoreService,
		private datePipe: DatePipe,
		private actions$: Actions
	) { }

	ngOnInit() {
		this.userContactsInviteBatch.contactListName = `Previous event attendees-${this.datePipe.transform(this.currentDate, 'dd-MMM-YY')}`;

		this.store.select(userContactsSelector.userPreviousEventsAttendees())
			.pipe(takeUntil(this.destroyed$))
			.subscribe((attendees: UserContactListDraftItem[]) => {
				if (attendees && attendees.length) {
					this.hasInitialAttendees = true;
					this.attendees.data = attendees;
				}
			});

		this.store.select(userContactsSelector.userPreviousEvents()).pipe(takeUntil(this.destroyed$))
			.subscribe((events: PreviousEvent[]) => {
				if (events) {
					if (events.length > 0) {
						this.hasInitialEvents = true;
						this.events.data = events;
					}
				}
			});

		this.actions$
			.pipe(
				ofType(UserActionsConstants.GET_PREVIOUS_EVENTS_SUCCESS, UserActionsConstants.GET_PREVIOUS_EVENTS_FAILED),
				takeUntil(this.destroyed$)
			).subscribe(() => {
				this.isPreviousEventsLoading = false;
			});

		this.actions$
			.pipe(
				ofType(UserActionsConstants.GET_EVENTS_ATTENDEES_SUCCESS, UserActionsConstants.GET_EVENTS_ATTENDEES_FAILED),
				takeUntil(this.destroyed$)
			).subscribe(() => {
				this.isPreviousContactsLoading = false;
			});


		this.store.dispatch(new GetPreviousEvents({
			eventId: this.eventId,
		}));
	}

	handleSelectAllEvents() {
		if (this.selectedEvents.length === this.events.data.length) {
			this.selectedEvents = [];
		} else {
			this.selectedEvents = [...this.events.data];
		}
	}

	handleSelectAllAttendees() {
		if (this.userContactsInviteBatch.contacts.length === this.attendees.data.length) {
			this.userContactsInviteBatch.contacts = [];
		} else {
			this.userContactsInviteBatch.contacts = [...this.attendees.data];
		}
		this.isButtonDisabledCheck();
	}

	handleCheckboxChange(event: PreviousEvent) {
		const index = this.selectedEvents.indexOf(event);
		if (index === -1) {
			this.selectedEvents.push(event);
		} else {
			this.selectedEvents.splice(index, 1);
		}
	}

	handleAttendeeCheckboxChange(attendee: UserContactListDraftItem) {
		const index = this.userContactsInviteBatch.contacts.indexOf(attendee);
		this.userContactsInviteBatch.contacts = [...this.userContactsInviteBatch.contacts];
		if (index === -1) {
			this.userContactsInviteBatch.contacts.push(attendee);
		} else {
			this.userContactsInviteBatch.contacts.splice(index, 1);
		}
		this.isButtonDisabledCheck();
	}

	isSelected(event: PreviousEvent): boolean {
		return this.selectedEvents.includes(event);
	}

	isAllSelected() {
		return this.selectedEvents.length === this.events.data.length ? true : false;
	}

	isAllAttendeesSelected() {
		return this.userContactsInviteBatch.contacts.length === this.attendees.data.length ? true : false;
	}

	isAttendeeSelected(attendee: UserContactListDraftItem): boolean {
		return this.userContactsInviteBatch.contacts.includes(attendee);
	}

	getEventsAttendees() {
		this.isPreviousContactsLoading = true;
		this.store.dispatch(new GetEventsAttendees({
			eventIds: this.selectedEvents
				.map(el => el.id),
		}));
	}

	handleEventFilterOutput(filterOutput: FilterOptions) {
		const {searchTerm} = filterOutput;
		this.events.filter = searchTerm.trim().toLowerCase();
	}

	handleAttendeesFilterOutput(filterOutput: FilterOptions) {
		const {searchTerm} = filterOutput;
		this.attendees.filter = searchTerm.trim().toLowerCase();
	}

	isNullOrEmpty(str: string): boolean {
		return !str || str.trim() === '';
	}

	isButtonDisabledCheck() {
		if (this.isNullOrEmpty(this.userContactsInviteBatch.contactListName)
		|| this.userContactsInviteBatch.contacts.length === 0) {
			this.isButtonDisabled = true;
		} else {
			this.isButtonDisabled = false;
		}
	}

	handleRouteToDashboard() {
		this.store.dispatch(new Go({ path: [InternalURLCreator.eventDashboard(this.eventId)] }));
	}

	handleSendInvites() {
		this.isButtonLoading = true;
		this.actions$
			.pipe(
				ofType(EventActionsConstants.SEND_INVITES_SUCCESS, EventActionsConstants.SEND_INVITES_FAILED),
				takeUntil(this.destroyed$)
			).subscribe(() => {
				this.isButtonLoading = false;
				this.isButtonDisabled = true;
			});
		this.store.dispatch(new CreateContactList({
			name: this.userContactsInviteBatch.contactListName,
			eventId: this.eventId,
			contacts: this.userContactsInviteBatch.contacts,
			source: ContactsSource.PreviousEvent,
			autoSendInvites: true,
		})
		);
	}
}
