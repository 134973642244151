import { AppAction, FailedAction } from '@app/models/action.model';
import { TopLinkCampaigns } from '@app/models/event.model';
import {
	BasicEventCard,
	ProductFilter,
	MailAccount,
	NameAndID,
	OrganiserDashboardEvents,
	MailChimpConnection,
	SalesOverview,
	DateRange,
	DownloadUserReportsInfo,
	UserReportInfo
} from '@app/models/organiser.model';
import { OrganiserActionsConstants as constants } from './organiser.actions.constants';

export class GetSalesOverview extends AppAction<{
	id: number;
	dateRange: DateRange;
	currencyId: number;
}>{
	readonly type = constants.GET_SALES_OVERVIEW;
}

export class GetSalesOverviewSuccess extends AppAction<{
	salesOverview: SalesOverview;
}> {
	readonly type = constants.GET_SALES_OVERVIEW_SUCCESS;
}

export class GetSalesOverviewFailed extends FailedAction {
	readonly type = constants.GET_SALES_OVERVIEW_FAILED;
}

export class GetCurrentCampaigns extends AppAction<{
	id: number;
}>{
	readonly type = constants.GET_CURRENT_CAMPAIGNS;
}

export class GetCurrentCampaignsSuccess extends AppAction<{
	topLinkCampaigns: TopLinkCampaigns[];
}> {
	readonly type = constants.GET_CURRENT_CAMPAIGNS_SUCCESS;
}

export class GetCurrentCampaignsFailed extends FailedAction {
	readonly type = constants.GET_CURRENT_CAMPAIGNS_FAILED;
}

export class GetFilteredProducts extends AppAction<{
	userId: number;
	productsFilter: ProductFilter;
}> {
	readonly type = constants.GET_FILTERED_PRODUCTS;
}

export class GetFilteredProductsSuccess extends AppAction<{
	userProducts: BasicEventCard[];
	totalProducts: number;
}> {
	readonly type = constants.GET_FILTERED_PRODUCTS_SUCCESS;
}

export class GetFilteredProductsFailed extends FailedAction {
	readonly type = constants.GET_FILTERED_PRODUCTS_FAILED;
}

export class GetOrganiserNames extends AppAction<{
	id: number;
}> {
	readonly type = constants.GET_ORGANISER_NAMES;
}

export class GetOrganiserNamesSuccess extends AppAction<{
	organiserNames: NameAndID[];
}> {
	readonly type = constants.GET_ORGANISER_NAMES_SUCCESS;
}

export class GetOrganiserNamesFailed extends FailedAction {
	readonly type = constants.GET_ORGANISER_NAMES_FAILED;
}

export class GetMailAccountsByUserId extends AppAction<{
	id: number;
}> {
	readonly type = constants.GET_MAIL_ACCOUNT_BY_USER_ID;
}

export class GetMailAccountsByUserIdSuccess extends AppAction<{
	mailAccounts: MailAccount[];
}> {
	readonly type = constants.GET_MAIL_ACCOUNT_BY_USER_ID_SUCCESS;
}

export class GetMailAccountsByUserIdFailed extends FailedAction {
	readonly type = constants.GET_MAIL_ACCOUNT_BY_USER_ID_FAILED;
}

export class AddMailAccount extends AppAction<{
	id: number;
	mailAccount: MailAccount;
}> {
	readonly type = constants.ADD_MAIL_ACCOUNT;
}

export class AddMailAccountSuccess extends AppAction<{
	mailAccounts: MailAccount[];
}> {
	readonly type = constants.ADD_MAIL_ACCOUNT_SUCCESS;
}

export class AddMailAccountFailed extends FailedAction {
	readonly type = constants.ADD_MAIL_ACCOUNT_FAILED;
}

export class DeleteMailAccount extends AppAction<{
	id: number;
	mailAccountId: number;
}> {
	readonly type = constants.DELETE_MAIL_ACCOUNT;
}

export class DeleteMailAccountSuccess extends AppAction<{
	mailAccounts: MailAccount[];
}> {
	readonly type = constants.DELETE_MAIL_ACCOUNT_SUCCESS;
}

export class DeleteMailAccountFailed extends FailedAction {
	readonly type = constants.DELETE_MAIL_ACCOUNT_FAILED;
}

export class GetMailChimpConnection extends AppAction<{
	id: number;
	code: string;
}> {
	readonly type = constants.GET_MAILCHIMP_CONNECTION;
}

export class GetMailChimpConnectionSuccess extends AppAction<{
	mailChimpConnection: MailChimpConnection;
}> {
	readonly type = constants.GET_MAILCHIMP_CONNECTION_SUCCESS;
}

export class GetMailChimpConnectionFailed extends FailedAction {
	readonly type = constants.GET_MAILCHIMP_CONNECTION_FAILED;
}

export class PostUnlinkMailChimp extends AppAction<{
	id: number;
}> {
	readonly type = constants.POST_UNLINK_MAILCHIMP;
}

export class PostUnlinkMailChimpSuccess extends AppAction<{
	mailChimpConnection: MailChimpConnection;
}> {
	readonly type = constants.POST_UNLINK_MAILCHIMP_SUCCESS;
}

export class PostUnlinkMailChimpFailed extends FailedAction {
	readonly type = constants.POST_UNLINK_MAILCHIMP_FAILED;
}

export class GetDashboardEvents extends AppAction<{
	id: number;
}> {
	readonly type = constants.GET_DASHBOARD_EVENTS;
}

export class GetDashboardEventsSuccess extends AppAction<{
	dashboardEvents: OrganiserDashboardEvents;
}> {
	readonly type = constants.GET_DASHBOARD_EVENTS_SUCCESS;
}

export class GetDashboardEventsFailed extends FailedAction {
	readonly type = constants.GET_DASHBOARD_EVENTS_FAILED;
}

export class GetUserReportsInfo extends AppAction<{
	id: number;
}> {
	readonly type = constants.GET_USER_REPORTS_INFO;
}

export class GetUserReportsInfoSuccess extends AppAction<{
	userReportInfo: UserReportInfo;
}> {
	readonly type = constants.GET_USER_REPORTS_INFO_SUCCESS;
}

export class GetUserReportsInfoFailed extends FailedAction {
	readonly type = constants.GET_USER_REPORTS_INFO_FAILED;
}

export class DownloadUserReports extends AppAction<{
	id: number;
	downloadUserReportsDto: DownloadUserReportsInfo;
}> {
	readonly type = constants.DOWNLOAD_USER_REPORTS;
}

export class DownloadUserReportsSuccess extends AppAction<{
	successResponse: string;
}> {
	readonly type = constants.DOWNLOAD_USER_REPORTS_SUCCESS;
}

export class DownloadUserReportsFailed extends FailedAction {
	readonly type = constants.DOWNLOAD_USER_REPORTS_FAILED;
}

export type Actions =
	| GetSalesOverview
	| GetSalesOverviewSuccess
	| GetSalesOverviewFailed
	| GetCurrentCampaigns
	| GetCurrentCampaignsSuccess
	| GetCurrentCampaignsFailed
	| GetFilteredProducts
	| GetFilteredProductsSuccess
	| GetFilteredProductsFailed
	| GetOrganiserNames
	| GetOrganiserNamesSuccess
	| GetOrganiserNamesFailed
	| GetMailAccountsByUserId
	| GetMailAccountsByUserIdSuccess
	| GetMailAccountsByUserIdFailed
	| AddMailAccount
	| AddMailAccountSuccess
	| AddMailAccountFailed
	| DeleteMailAccount
	| DeleteMailAccountSuccess
	| DeleteMailAccountFailed
	| GetMailChimpConnection
	| GetMailChimpConnectionSuccess
	| GetMailChimpConnectionFailed
	| PostUnlinkMailChimp
	| PostUnlinkMailChimpSuccess
	| PostUnlinkMailChimpFailed
	| GetDashboardEvents
	| GetDashboardEventsSuccess
	| GetDashboardEventsFailed
	| GetUserReportsInfo
	| GetUserReportsInfoSuccess
	| GetUserReportsInfoFailed
	| DownloadUserReports
	| DownloadUserReportsSuccess
	| DownloadUserReportsFailed;
