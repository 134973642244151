import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ServiceRequest, RequestType } from '@app/models/http.model';
import * as queryString from 'query-string';
import config from '@app/config';
import { Observable } from 'rxjs';

interface QueryStringParams {
	[key: string]: any;
}

interface QueryStringOptions {
	encode?: boolean;
	strict?: boolean;
	arrayFormat?: 'none' | 'index'| 'bracket';
}

@Injectable()
export class HttpService {
	static baseURL = config.baseURL;
	static baseAPI_URL = config.baseAPI_URL;
	constructor(
		public http: HttpClient
	) { }

	makeRequest<T>({ type, url, payload, headers: requestHeaders }: ServiceRequest): Observable<T> {
		let urlWithBase = `${HttpService.baseURL}${url}`;
		let headers = new HttpHeaders(requestHeaders);

		switch (type) {
			case RequestType.GET:
				if (payload) {
					urlWithBase = `${urlWithBase}?${queryString.stringify(payload)}`;
				}
				return this.http[type]<T>(
					urlWithBase,
					{ headers }
				);
				break;
			case RequestType.DELETE:
				if (payload) {
					urlWithBase = `${urlWithBase}?${queryString.stringify(payload)}`;
				}
				return this.http[type]<T>(
					urlWithBase,
					{ headers }
				);
			case RequestType.POST:
			case RequestType.PATCH:
			case RequestType.PUT:
				if (!headers.has('Content-Type')) {
					headers = headers.append('Content-Type', 'application/json');
				}

				return this.http[type]<T>(
					urlWithBase,
					payload,
					{ headers }
				);
		}
	}

	makeAPIRequest<T>({ type, url, payload, headers: requestHeaders }: ServiceRequest): Observable<T> {
		let urlWithBase = `${HttpService.baseAPI_URL}${url}`;
		let headers = new HttpHeaders(requestHeaders);

		switch (type) {
			case RequestType.GET:
				if (payload) {
					urlWithBase = `${urlWithBase}?${queryString.stringify(payload)}`;
				}
				return this.http[type]<T>(
					urlWithBase,
					{ headers }
				);
			case RequestType.DELETE:
				if (payload) {
					urlWithBase = `${urlWithBase}?${queryString.stringify(payload)}`;
				}
				return this.http[type]<T>(
					urlWithBase,
					{ headers }
				);
			case RequestType.POST:
			case RequestType.PATCH:
			case RequestType.PUT:
				if (!headers.has('Content-Type')) {
					headers = headers.append('Content-Type', 'application/json');
				}

				return this.http[type]<T>(
					urlWithBase,
					payload,
					{ headers }
				);
		}
	}

	stringifyQueryParams = (
		params: QueryStringParams,
		options?: QueryStringOptions
	): string => (
		queryString.stringify(params, options)
	);

	parseQueryParams = (
		str: string,
		options?: QueryStringOptions
	): QueryStringParams => (
		queryString.parse(str, options)
	);
}
