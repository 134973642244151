import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ManageBookingInvoice } from '@app/models/user.model';
import { UntypedFormGroup } from '@angular/forms';
import {
	ManageBookingConstants,
	UpdateBookingInvoice
} from '@app/user-features/manage-booking/manage-booking.actions';
import { StoreService } from '@app/services/store/store.service';
import { takeUntil } from 'rxjs';
import { ManageBookingService } from '@app/user-features/manage-booking/manage-booking.service';
import { Actions, ofType } from '@ngrx/effects';

@Component({
	selector: 'app-edit-invoice-modal',
	templateUrl: './edit-invoice-modal.component.html',
	styleUrls: ['./edit-invoice-modal.component.sass'],
})

export class EditInvoiceModalComponent implements OnInit {
	isLoading = true;
	isUpdateLoading = false;
	invoiceForm: UntypedFormGroup;

	formControlNames = ['line1', 'line2', 'line3', 'line4', 'line5'];
	placeholders = ['Company name', 'VAT number', '12 Main Rd', 'Cape Town, Western Cape', 'South Africa'];

	get isButtonDisabled(): boolean {
		return this.isLoading
			|| this.isUpdateLoading
			|| this.invoiceForm.pristine
			|| this.invoiceForm.invalid;
	}

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		public dialogRef: MatDialogRef<EditInvoiceModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data: {
			purchaseId: number;
			invoice: ManageBookingInvoice;
			isMobile: false;
		},
		private store: StoreService,
		private bookingService: ManageBookingService,
		private actions$: Actions
	) { }

	ngOnInit(): void {
		this.invoiceForm = this.bookingService.createEditInvoiceForm(this.data.invoice);
		this.isLoading = false;
	}

	handleCancel() {
		this.dialogRef.close();
	}

	handleOnSave() {
		this.actions$.pipe(
			ofType(ManageBookingConstants.UPDATE_BOOKING_INVOICE_SUCCESS, ManageBookingConstants.UPDATE_BOOKING_INVOICE_FAILED),
			takeUntil(this.destroyed$)
		).subscribe(({ type }: { type: string }) => {
			this.isUpdateLoading = false;
			if (type === ManageBookingConstants.UPDATE_BOOKING_INVOICE_SUCCESS) {
				this.dialogRef.close();
			}
		});

		this.isUpdateLoading = true;
		this.store.dispatch(
			new UpdateBookingInvoice({
				id: this.data.purchaseId,
				invoice: this.invoiceForm.value,
			})
		);
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}
}
