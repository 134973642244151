import { AppAction } from '@app/models/action.model';
export class NotificationDesc {
	title: string;
	desc?: string;
	delay?: NotificationDelay;
}

export enum NotificationType {
	SUCCESS = 'success',
	ERROR = 'error',
	INFO = 'info',
}

export enum NotificationDelay {
	DEFAULT = 6000,
	LONG = 9000,
}

export class Notification extends NotificationDesc {
	id: string;
	actionType: string;
	action: AppAction;
	type: NotificationType;
	delay?: number;
}
