import { ActivatedRoute } from '@angular/router';
import { Component, EventEmitter, ViewChild } from '@angular/core';
import { FilterOptions, HeaderContent, SectionRouteCard } from '@app/models/shared';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { StoreService } from '@app/services/store/store.service';
import { Go } from '@app/store/actions/router/router.actions';
import { EVENT_ID_PARAM_KEY} from '@app/utils/consts';
import { take, takeUntil } from 'rxjs';
import { InternalURLCreator } from '@app/services/url/url.dictionary';
import { MatDialog } from '@angular/material/dialog';
import { IncentiviseGuestsModalComponent } from '../../modals/incentivise-guests-modal/incentivise-guests-modal.component';
import { ProductStatusMapping, PromoterInfo, PromotersPageInfo } from '@app/models/event.model';
import { ProductFilter } from '@app/models/organiser.model';
import { MatTableDataSource } from '@angular/material/table';
import * as eventSelectors from '@app/store/selectors/event.selector';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Actions, ofType } from '@ngrx/effects';
import { GetEventPromoters, SetPromotersIncentive } from '@app/store/actions/event/event.actions';
import { EventActionsConstants } from '@app/store/actions/event/event.actions.constants';
import { FileSaverService } from '@app/services/file-saver/file-saver.service';

@Component({
	selector: 'app-promoters',
	templateUrl: './promoters.component.html',
	styleUrls: ['./promoters.component.sass'],
})
export class PromotersComponent {
	hasPromoters = false;
	isMobile = false;
	eventId: number;
	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	isProductsLoading = true;
	isProductsFiltering = false;

	eventStatuses = ProductStatusMapping;

	productFilter = new ProductFilter();

	promoters: MatTableDataSource<PromoterInfo> = new MatTableDataSource();
	promotersPageInfo: PromotersPageInfo;

	displayColumns: string[] = ['name', 'numberOfClicks', 'numberOfTickets', 'salesValue'];
	pageSizeOptions = [4, 8, 12];

	paginatorInitialized = false;
	sortInitialized = false;

	headerContent: HeaderContent = {
		breadCrumbs: [
			{
				routeName: 'Marketing',
				routeTo: () => InternalURLCreator.marketing(this.eventId),
			},
			{
				routeName: 'Promoters',
			},
		],
		title: 'Promoters',
		description: `Below is a list of people who have promoted your event because of your incentive. For each promoter,
    we record the number of people who clicked on that promoter's link, as well as the number of people
    who actually purchased a ticket for your event after clicking on that promoter's link. You can also
    download this list as a CSV file.`,
	};

	customDescription = 'Below is a list of people who have promoted your event because of your incenti...';
	readMoreText = 'Read More';
	includeReadMore = true;
	readMore = false;

	@ViewChild('productsPaginator') set paginator(_pagination: MatPaginator) {
		if (_pagination && !this.paginatorInitialized) {
			this.promoters.paginator = _pagination;
		};
	}
	@ViewChild(MatSort) set sort(_sort: MatSort) {
		if (_sort && !this.sortInitialized) {
			this.sortInitialized = true;
			this.promoters.sort = _sort;
			this.promoters.sortingDataAccessor = (item, property) => item[property];
		}
	};

	constructor(
		private breakpointService: BreakpointService,
		private store: StoreService,
		private activatedRoute: ActivatedRoute,
		private dialog: MatDialog,
		private actions$: Actions,
		private fileSaverService: FileSaverService
	) { }

	ngOnInit(): void {
		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});

		this.activatedRoute.parent.paramMap.pipe(take(1)).subscribe((params) => {
			this.eventId = +params.get(EVENT_ID_PARAM_KEY);
			this.store.dispatch(new GetEventPromoters({id: this.eventId}));
		});

		this.actions$
			.pipe(
				ofType(EventActionsConstants.GET_EVENT_PROMOTERS_SUCCESS, EventActionsConstants.GET_EVENT_PROMOTERS_FAILED),
				takeUntil(this.destroyed$)
			)
			.subscribe(() => {
				this.isProductsLoading = false;
				this.isProductsFiltering = false;
			});

		this.store.select(eventSelectors.eventPromoters(this.eventId)).pipe(takeUntil(this.destroyed$)).subscribe((promotersPageInfo) => {
			if (promotersPageInfo) {
				this.promotersPageInfo = promotersPageInfo;
				this.promoters.data = promotersPageInfo.promoters;
				this.hasPromoters = this.promotersPageInfo.promoters.length > 0;
			}
		});
	}

	openIncentiveModal() {
		this.dialog.open(IncentiviseGuestsModalComponent,
			{
				data: {currentIncentive: this.promotersPageInfo.incentiveMessage, isMobile: this.isMobile},
				panelClass: 'g-standard-dialog'}
		)
			.afterClosed()
			.subscribe((newIncentive) => {
				if (newIncentive) {
					this.store.dispatch(new SetPromotersIncentive({id: this.eventId, incentive: newIncentive}));
				}
			});
	}

	handleFilter(filterOptions: FilterOptions) {
		const {searchTerm} = filterOptions;
		this.promoters.filter = searchTerm.trim().toLowerCase();
	}

	handleNavigate(card: SectionRouteCard): void {
		this.store.dispatch(new Go({ path: [card.routeTo(this.eventId)] }));
	}

	handleDownload() {
		this.fileSaverService.save({
			url: `/api/product/${this.eventId}/promoters/csvdownload`,
			fileName: `${this.eventId}-quicket-promoters.csv`,
		});
	}

	handleReadMore() {
		this.readMore = !this.readMore;
		this.readMoreText = this.readMore ? 'Read Less' : 'Read More';
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
