<div class="l-container">
	<div class="l-content l-header-content" [ngClass]="{ 'mobile': isMobile }">
		<app-web-content-header *ngIf='!isMobile; else mobileHeaderContent' class="l-header"
			[headerContent]="headerContent" [mediumWidth]="true"></app-web-content-header>
		<ng-template #mobileHeaderContent>
			<div class="l-mobile-description">
				<p *ngIf="!readMore" class="l-header">{{ customDescription }}
					<span *ngIf="includeReadMore" (click)="handleReadMore()" class="g-pointer g-highlight g-green">
						{{readMoreText}}
					</span>
				</p>
				<p *ngIf="readMore" class="l-header" [ngClass]="{ 'mobile': isMobile }">{{ headerContent.description }}
					<span *ngIf="includeReadMore" (click)="handleReadMore()" class="g-pointer g-highlight g-green">
						{{readMoreText}}
					</span>
				</p>
			</div>
		</ng-template>
		<div *ngIf="!isProductsLoading; else pageLoading">
			<div *ngIf='!hasNetworks; else networksContent' class="l-networks-content-empty"
				[ngClass]="{ 'mobile': isMobile }">
	
				<img src=".\assets\placeholders\promoter-network-empty.png" class="l-image">
	
				<h3 [ngClass]="{ 'mobile': isMobile }">Create A Promoter Network</h3>
	
				<p class="l-no-networks-description" [ngClass]="{ 'mobile': isMobile }">
					Create as many networks as you like, and specify the incentive for each network.
					Note: People who join a network are your promoters, and will be paid whatever
					amount you specify for generating ticket sales on your behalf.</p>
				
				<div *ngIf="!isMobile" class="l-create-promoter-network-button-no-promoter-data-container">
					<button class="g-primary-button l-create-promoter-network-button-no-promoter-data"
						id='create-promoter-network-button' (click)="openCreateNetworkModal()">CREATE A PROMOTER
						NETWORK</button>
				</div>
			</div>
			<ng-template #networksContent>
				<div class="l-info-cards-container" [ngClass]="{ 'mobile': isMobile }">
					<app-info-card class="l-info-card" id="total-visits-card" [isMobile]="isMobile" [title]="'Total Visits From Link'"
						[data]="promoterNetworksPageInfo.totalVisits === 0 ? '-' : promoterNetworksPageInfo.totalVisits" [tooltipText]="'Total number of event page visits from network links.'">
					</app-info-card>
					<app-info-card class="l-info-card" id="most-influence-card" [isMobile]="isMobile" [title]="'Most Influence'"
						[data]="promoterNetworksPageInfo.mostInfluence" [tooltipText]="'Your most influential promoter network based on the total value from tickets sold on Quicket.'">
					</app-info-card>
					<app-info-card class="l-info-card" id="total-tickets-sold-card" [isMobile]="isMobile" [title]="'Total Tickets Sold'"
						[data]="promoterNetworksPageInfo.totalTicketsSold === 0 ? '-' : promoterNetworksPageInfo.totalTicketsSold" [tooltipText]="'Total amount of tickets sold on Quicket using the network links.'">
					</app-info-card>
					<app-info-card class="l-info-card" id="total-sold-through-quicket-card" [isMobile]="isMobile" [title]="'Total Sold On Quicket'"
						[data]="promoterNetworksPageInfo.totalSalesValue === 0 ? '-' : promoterNetworksPageInfo.totalSalesValue" [tooltipText]="'Total value from tickets sold on Quicket using the network links.'"
						[currencySymbol]="promoterNetworksPageInfo.totalSalesValue === 0 ? '' : promoterNetworksPageInfo.currencySymbol">
					</app-info-card>
				</div>
				<div class="l-networks-content-container" [ngClass]="{ 'mobile': isMobile }">
					<app-web-filter id="filter-component" [isMobile]="isMobile" [buttonText]="'ADD NETWORK'"
						[searchPlaceholder]="'Search networks'" (filterOutput)="handleFilter($event)"
						(buttonClick)="openCreateNetworkModal()"></app-web-filter>
					<div class="l-table-container">
						<div *ngIf='isProductsFiltering' class='l-filtering'>
							<mat-spinner></mat-spinner>
						</div>
						<table mat-table [dataSource]="networks" class='l-table web' matSort>
							<ng-container matColumnDef="name">
								<th id="network-name-table-header" mat-header-cell *matHeaderCellDef mat-sort-header id="name-sortable">
									Network Name </th>
								<td mat-cell *matCellDef="let network"><span class="l-link-text" (click)="handlePromoterNetworkRedirect(network.id, network.name)">{{ network.name }}</span></td>
							</ng-container>
							<ng-container matColumnDef="totalMembers">
								<th mat-header-cell *matHeaderCellDef mat-sort-header id="promoters-sortable"> Promoters </th>
								<td mat-cell *matCellDef="let network">{{ network.totalMembers }}</td>
							</ng-container>
							<ng-container matColumnDef="totalVisits">
								<th mat-header-cell *matHeaderCellDef mat-sort-header id="visits-sortable"> Visits </th>
								<td mat-cell *matCellDef="let network">{{ network.totalVisits }}</td>
							</ng-container>
							<ng-container matColumnDef="totalSales">
								<th mat-header-cell *matHeaderCellDef mat-sort-header id="sales-sortable"> Sales ({{promoterNetworksPageInfo.currencyISO}})
								</th>
								<td mat-cell *matCellDef="let network">{{ network.totalSales }}
								<span *ngIf="network.totalTickets > 0">({{ network.totalTickets }})</span></td>
							</ng-container>
							<ng-container matColumnDef="commission">
								<th mat-header-cell *matHeaderCellDef mat-sort-header id="commission-sortable"> Commission ({{promoterNetworksPageInfo.currencyISO}})
								</th>
								<td mat-cell *matCellDef="let network">{{ network.commission }}</td>
							</ng-container>
							<ng-container matColumnDef="totalPaidOut">
								<th mat-header-cell *matHeaderCellDef mat-sort-header id="income-sortable"> Income ({{promoterNetworksPageInfo.currencyISO}})
								</th>
								<td mat-cell *matCellDef="let network">{{ network.totalPaidOut }}</td>
							</ng-container>
							<ng-container matColumnDef="actions">
								<th mat-header-cell *matHeaderCellDef> Actions </th>
								<td mat-cell *matCellDef="let network">
									<button mat-icon-button [matMenuTriggerFor]="menu">
										<mat-icon>more_vert</mat-icon>
									</button>
									<mat-menu #menu="matMenu">
										<button mat-menu-item id="menu-edit" (click)="handleEdit(network)">Edit</button>
										<button mat-menu-item id="menu-delete" (click)="handleDelete(network)">Delete</button>
										<button mat-menu-item id="menu-share" (click)="handleShare(network)">Share</button>
									</mat-menu>
								</td>
							</ng-container>
							<tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
							<tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
							<tr *matNoDataRow>
								<td colSpan="7">
									<div class="l-empty-state">
										<h3 class="mb-3">Nothing to see here...</h3>
										<div class="mb-3">Nothing matches your search or filters</div>
									</div>
								</td>
							</tr>
						</table>
					</div>
					<mat-paginator #productsPaginator [pageSizeOptions]="pageSizeOptions"></mat-paginator>
				</div>
			</ng-template>
		</div>
		<div *ngIf="isMobile" class="l-create-promoter-network-button-no-promoter-data-container-mobile">
			<button class="g-primary-button l-create-promoter-network-button-no-promoter-data-mobile"
				id='create-promoter-network-button' (click)="openCreateNetworkModal()">CREATE A PROMOTER
				NETWORK</button>
		</div>
	</div>
</div>
<ng-template #pageLoading>
	<div class="l-page-loader">
		<mat-spinner></mat-spinner>
	</div>
</ng-template>
