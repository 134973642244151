<div class="l-container" [ngClass]="{'mobile': data.isMobile}">
	<h3 class="mb-3">Add Link Campaign</h3>
	<form [formGroup]="linkCampaignsForm" class="g-form-container my-3">
		<div *ngIf="!isLoading; else showLoading" class='w-100'>
			<div class="input-group w-100 mb-3">
				<label class="g-form-label l-label">Link Name<span class='g-orange'>*</span> <mat-icon
						#linkNameTooltip="matTooltip" class="l-tooltip-icon"
						matTooltip="The name you will use to identify this link campaign, not seen by the user."
						matTooltipPosition="right" (click)="linkNameTooltip.toggle()">info</mat-icon></label>
				<input id="link-name-input" type="text" placeholder="e.g. Facebook Event"
					[formControl]="fromForm('name')" class="form-control w-100" maxlength='100' />
				<div class="g-error-input"
					*ngIf="fromForm('name').invalid && ((fromForm('name').dirty || fromForm('name').touched))">
					{{getError(fromForm('name').errors)}}
				</div>
			</div>
			<div class="input-group w-100 mb-3">
				<label class="g-form-label l-label">Campaign URL <mat-icon #accountNameTooltip="matTooltip"
						class="l-tooltip-icon" [matTooltip]="urlTooltip"
						matTooltipPosition="right" (click)="accountNameTooltip.toggle()">info</mat-icon></label>
				<input id="campaign-url-input" type="text" placeholder="e.g. fb-event"
					[formControl]="fromForm('campaignUrl')" class="form-control w-100" maxlength='100' />
				<div class="g-error-input"
					*ngIf="fromForm('campaignUrl').invalid && (fromForm('campaignUrl').dirty || fromForm('campaignUrl').touched)">
					{{getError(fromForm('campaignUrl').errors)}}
				</div>
			</div>
		</div>
		<ng-template #showLoading>
			<div class="l-loading">
				<mat-spinner></mat-spinner>
			</div>
		</ng-template>
	</form>

	<div class="l-buttons-container">
		<button id='cancel-button' class="g-secondary-button border-0" (click)="handleCancel()">CANCEL</button>
		<button id='add-campaign-button' class="g-primary-button" (click)="handleAddCampaign()"
			[class.disabled]="linkCampaignsForm.invalid || isLoading"
			[disabled]="linkCampaignsForm.invalid || isLoading">ADD</button>
	</div>
</div>
