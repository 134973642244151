import { ImageString } from '@app/services/image/image.service';
import { SchemeID } from './dataSchema.model';
import { ProductType } from './product.model';

export class EventOrganiserProfile {
	id: number = null;
	name: string = null;
	email: string = null;
	telephone?: string = null;
	showTelephone = false;
	mobile?: string = null;
	showMobile = false;
	showWebsite = false;
	vatNumber?: string = null;
	facebookUrl?: string = null;
	websiteUrl?: string = null;
	twitterHandle?: string = null;
	showTwitterFeed = false;
	twitterHashtag: string = null;
	originalImage: string = null;
	imageString: ImageString | string = null;
	thumbnail: string = null;
	croppedImage: ImageString | string = null;
	spotifyLink: string = null;
	displayReviews = false;
	subscribers: number = null;
	description: string = null;
	subscriptionProductId: SchemeID = null;
	displayPrivateEvents = false;
	isLinkedToProducts = false;
	numberOfEvents: number = null;
	numberOfFundraisers: number = null;
	numberOfSubscriptions: number = null;
	linkedProducts: ProfileLinkedProducts[] = [];
	profilePageEmbed: string = null;
	allowReviews = true;
	company: string = null;
	address: AddressLines = null;

}

export class AddressLines {
	type = 1;
	lineOne: string = null;
	lineTwo: string = null;
	lineThree: string = null;
	lineFour: string = null;
	lineFive: string = null;
}

export interface ProfileLinkedProducts {
	id: SchemeID;
	name: string;
	productType: ProductType;
	isActive: boolean;
}

