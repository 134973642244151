<div class="l-container">
	<div class="l-content l-header-content" [ngClass]="{ 'mobile': isMobile }">
        <app-web-content-header 
			class="l-header" 
			[ngClass]="{ 'mobile': isMobile }"
			[headerContent]="headerContent"
        	[mediumWidth]="true"
			[showBreadCrumb]="!isMobile"
			[showTitle]="!isMobile"
			(handleExternalNavigation)='handleBreadCrumbNavigation($event)'
			[handleRoutingExternally]="true"
		>
		</app-web-content-header>
	</div>
	<div *ngIf="mode == CollectInfoPageMode.List; else createOrEdit">
		<app-question-list 
			[eventId]="eventId"
			[isRegistration]="false"
			[questions]="questions"
			[isLoading]="isLoading"
			[noQuestions]="noQuestions"
			[isMobile]="isMobile"
			(questionPropertyToggle)="onEventQuestionPropertyToggle($event)"
			(eventQuestionDeletetion)="onEventQuestionDeletion($event)"
			(reorder)="onReorder($event)"
			(collectInfoFormRequest)="onCollectInfoFormRequest($event)"
		>
		</app-question-list>
	</div>
	<ng-template #createOrEdit>
		<app-add-edit-question
			class="l-add-edit-question"
			[isRegistration]="false"
			[question]="selectedQuestion"
			[questions]="questions"
			[profileCollectInfoOptions]="profileCollectInfoOptions"
			[collectInfoTickets]="collectInfoTickets"
			[isEditing]="mode == CollectInfoPageMode.Edit"
			[isMobile]="isMobile"
			[form]="collectInfoForm"
			(save)="onCollectInfoFormClose($event)"
		>
		</app-add-edit-question>
	</ng-template>
</div>