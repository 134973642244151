import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import * as RouterActions from '../actions/router/router.actions';
import { URLService } from '@app/services/url/url.service';
import { Redirect } from '../actions/router/router.actions';
import { URLCreatorList } from '@app/models/url.model';

@Injectable()
export class RouterEffects {
	constructor(private actions$: Actions, private router: Router, private location: Location, private urlService: URLService) {}

	navigate$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(RouterActions.GO),
				map((action: RouterActions.Go) => action.payload),
				tap(({ path, query: queryParams, extras }) => this.router.navigate(path, { queryParams, ...extras }))
			),
		{ dispatch: false }
	);

	navigateBack$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(RouterActions.BACK),
				tap(() => this.location.back())
			),
		{ dispatch: false }
	);

	navigateForward$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(RouterActions.FORWARD),
				tap(() => this.location.forward())
			),
		{ dispatch: false }
	);

	redirect$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(RouterActions.REDIRECT),
				tap(({ payload: { path, config, extraLink } }: Redirect<keyof URLCreatorList>) =>
					this.urlService.navigate(path, config, extraLink)()
				)
			),
		{ dispatch: false }
	);

	reload$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(RouterActions.RELOAD),
				tap(() => {
					window.location.reload();
				})
			),
		{ dispatch: false }
	);
}
