import { schema } from 'normalizr';
import { ticketsDescScheme } from './ticket.scheme';
import { EventEntity } from '@app/models/event.model';

export const eventScheme = new schema.Entity(
	'event',
	{
		ticketsDetails: ticketsDescScheme,
	},
	{
		idAttribute: (value: EventEntity) => (
			value.details.eventId.toString()
		),
	}
);

export const arrayOfEventScheme = () => new schema.Array(eventScheme);
