import { NgModule } from '@angular/core';
import { UserManageBookingComponent } from './manage-booking.component';
import { SharedModule } from '@app/shared/shared.module';
import { BookingViewTicketsModalComponent } from './booking-view-tickets-modal/booking-view-tickets-modal.component';
import { BookingEditInvoiceModalComponent } from './booking-edit-invoice-modal/booking-edit-invoice-modal.component';
import { ManageBookingService } from './manage-booking.service';
import { BookingChangeDatesModalComponent } from './booking-change-dates-modal/booking-change-dates-modal.component';
// import { SeatsioAngularModule } from '@seatsio/seatsio-angular';



@NgModule({
	imports: [
		SharedModule,
		// SeatsioAngularModule,
	],
	declarations: [
		UserManageBookingComponent,
		BookingViewTicketsModalComponent,
		BookingEditInvoiceModalComponent,
		BookingChangeDatesModalComponent,
	],
	exports: [
		UserManageBookingComponent,
	],
	providers: [
		ManageBookingService,
	],
})
export class UserManageBookingModule {}
