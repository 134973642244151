<div class="l-container" [ngClass]="{ 'mobile': isMobile}">
	<strong>Step 1:</strong>
	<div>Below is an example of the correct format. Columns should be name, surname, email = in that order and with the correct column names.</div>
	<div class="l-image-example">
		<img [class.l-image]='isMobile' src="./assets/send-invites-CSV-example.png"/>
	</div>
	<strong>Step 2:</strong>
	<div>
		Once you are done you will need to save your spreadsheet into the CSV format. When choosing where to save your spreadsheet make sure to select CSV (Comma delimited) under the selection for Save as Type.
	</div>
	<div class="l-image-example">
		<img [class.l-image]='isMobile' src="./assets/send-invites-CSV-save-as-example.png"/>
	</div>
	<div *ngIf="csvUpload.contacts && csvUpload.contacts.length" class="mt-3 mb-3" >
		<label class="g-form-label">Contact List Name</label>
		<div class="l-input-container">
			<input type="text" id="contact-list-name" #attendeesBlock class="form-control w-100 input-group" #invitesContactListName="ngModel" [(ngModel)]="csvUpload.contactListName" (ngModelChange)="isButtonDisabledCheck()" required>
		</div>
		<div class="g-error-input" *ngIf=" invitesContactListName.touched || invitesContactListName.dirty">
			<div *ngIf="invitesContactListName.errors?.required">Contact list name is required.</div>
		</div>
	</div>
	<label class="g-form-label">File to import</label>
	<app-file-upload-field
		id="csv-file-upload"
		[accept]="'.csv'"
		[maxFileSize]="maxCSVFileSize"
		[uploadLabel]="'Upload CSV file'"
		[customUpload]="true"
		dropBoxMessage="Import a CSV of contacts to Quicket. Please make sure the CSV is in the correct format before uploading."
		[ignoreImageTemplate]="true"
		[customSuccessMessage]='customSuccessMessage'
		(uploadHandler)="onCSVFileSelect($event)"
	></app-file-upload-field>
	<div class="mt-3 mb-4">
		<mat-checkbox [(ngModel)]="consentChecked" (change)="isButtonDisabledCheck()">
			I agree that I have permission to contact these people
		</mat-checkbox>
	</div>	
	
</div>
<div class="l-sticky-bar">
	<app-footer-button
		id="send-invites-button"
		primaryText="SEND INVITES"
		[isLoading]="isButtonLoading"
		($primaryButton)="handleSendInvites()"
		[isDisabled]="isButtonDisabled || isButtonLoading"
		[isMobile]="isMobile"
		[fullWidth]="true"
		class="mt-3"
	></app-footer-button>
</div>
