import { Component, EventEmitter } from '@angular/core';
import { HeaderContent } from '@app/models/shared';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { InternalURLCreator, URLCreator } from '@app/services/url/url.dictionary';
import { takeUntil } from 'rxjs';

@Component({
	selector: 'app-direct-distribution',
	templateUrl: './direct-distribution.component.html',
	styleUrls: ['./direct-distribution.component.sass'],
})
export class DirectDistributionComponent {
	url: string;
	isMobile = false;
	isLoading = true;

	headerContent: HeaderContent = {
		breadCrumbs: [
			{
				routeName: 'Organiser Profiles',
				routeTo: () => InternalURLCreator.orgProfile(),
			},
			{
				routeName: 'Direct Distribution',
			},
		],
	};

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private breakpointService: BreakpointService
	) {}

	ngOnInit(): void {
		this.url = URLCreator.directDistributionAspx();

		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}


	handleLoadedIFrame(){
		this.isLoading = false;
	}
}
