import { Injectable } from '@angular/core';
import { FormSchemeControls, FormValidators } from '@app/models/common.model';
import { EventScheduleTransferSettings } from '@app/models/event.model';
import { FormService } from '@app/services/form/form.service';
import { dissimilarFieldValidator, maxLength, minLength, requiredValidator } from '@app/shared/form-field/form-validators';

@Injectable()
export class GuestManagementService {
	constructor(
		private formService: FormService
	) {}

	createScheduleTransferSettingsForm(initialValues?: Partial<EventScheduleTransferSettings>) {
		const formValues = new EventScheduleTransferSettings();

		const validatorsConfig: FormValidators<Partial<EventScheduleTransferSettings>> = {
			moveFromId: [requiredValidator()],
			moveToId: [requiredValidator()],
			reason: [requiredValidator(), minLength(2), maxLength(300)],
			confirmTransfer: [requiredValidator()],
		};

		const formConfig: Partial<FormSchemeControls<EventScheduleTransferSettings>> =
			this.formService.createFormControls({
				formValues,
				initialValues,
				validatorsConfig,
			});
		formConfig.moveFromId.setValidators(dissimilarFieldValidator(formConfig.moveToId));
		formConfig.moveToId.setValidators(dissimilarFieldValidator(formConfig.moveFromId));
		return this.formService.formBuilder.group(formConfig);
	}
}
