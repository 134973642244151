<app-my-account-header
	[isMobile]='isMobile'
	pageName="Edit Registration"
	(navigateBack)="handleNavigationBack()"
>
</app-my-account-header>
<div class="g-account-container">
	<div
		class="g-account-content"
		[ngClass]="{ 'mobile': isMobile, 'web': !isMobile }"
	>
		<app-web-content-header
			class="w-100"
			[headerContent]="headerContent"
			[isMobile]="isMobile"
			[additionalText]="currentRegistration?.productName"
			longWidth="true"
			[reference]="'REF'+registrationId"
			[id]="registrationId"
		></app-web-content-header>
		<div
			[class.l-questions-mobile]="isMobile"
			[class.l-questions-web]="!isMobile"
			class="d-flex flex-column align-items-start mb-3"
		>
			<div
				*ngIf="displayUpdateTicketWarning"
				class="d-flex gap-3 align-items-center mb-3" 
				[ngClass]="{ 'mx-3': isMobile }"
			>
				<mat-icon class="l-info-icon">error_outline</mat-icon>
				<div>If updated here, all tickets for this event with this ID / Passport number will also be
					updated.</div>
			</div>
			<ng-container *ngIf="!registrationLoading; else showLoading">
				<ng-container *ngIf="registrationFormData?.statusMessage">
					<div class="g-orange l-status-message mb-3">{{ statusMessage}}</div>
				</ng-container>
				<ng-container *ngIf="registrationFormData?.errorMessage || errorMessage">
					<div class="g-red l-error-message mb-3" [class.mobile]="isMobile">
						<mat-icon>error</mat-icon>
						{{ errorMessage }}
					</div>
				</ng-container>
				<form [formGroup]="registrationForm" class="w-100">
					<div *ngFor="let control of registrationFormData?.questions" class="mb-3 w-100">
						<app-dynamic-form-fields [control]="control" [isMobile]="isMobile"
							[editTicketFormGroup]="registrationForm" [index]="control.id">
						</app-dynamic-form-fields>
					</div>
				</form>
			</ng-container>
			<ng-template #showLoading>
				<div class="l-loading">
					<mat-spinner></mat-spinner>
				</div>
			</ng-template>
		</div>

	</div>

	<app-footer-button #footerButton class="w-100 mt-auto pt-3 l-sticky-bar l-z-index" [primaryText]="'SAVE REGISTRATION'"
		[isDisabled]="isFooterButtonDisabled" ($primaryButton)="handleSubmit()"
		($secondaryButton)="handleNavigationBack()" [secondaryText]="'CANCEL'" [isMobile]="isMobile">
	</app-footer-button>

</div>
