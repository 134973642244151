import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Question } from '@app/models/user.model';
import { FormCreationService } from '@app/services/form-creation/form-creation.service';
import { stringExistsValidator } from '@app/shared/form-field/form-validators';
import { formErrorMessage } from '@app/utils/consts';
import { Subject } from 'rxjs';

@Component({ template: '' })
export class MultiChoiceBaseControlComponent implements OnInit {
	@Input() isMobile: boolean;
	@Input() inputFormControl: FormControl;
	@Input() label: string;
	@Input() control: Question;

	otherEnabled = false;
	options: string[];
	isDisabled = false;
	otherAnswer = '';
	otherOptionControl = new FormControl('', []);
	protected destroyed$ = new Subject<void>();


	errorMessages = formErrorMessage;
	get errorKeys(): string[] {
		return Object.keys(formErrorMessage);
	}

	isRequired = false;

	constructor(protected fb: FormBuilder, private formCreation: FormCreationService) { }

	ngOnInit(): void {
		this.handleOtherEnabled();

		this.isDisabled = this.inputFormControl.disabled;
		this.options = this.control.options.split('##');

		if (this.control.addOtherOption) {
			const validators = [];
			validators.push(this.formCreation.createDefaultValidators(this.control));
			validators.push(stringExistsValidator(this.options));

			this.otherOptionControl.setValidators(validators);
			this.updateInputFormControlErrors();

			this.updateValidators();
			this.otherAnswer = this.control.answer.split(',').map(
				item => item.trim()
			).filter(
				option => !this.options.includes(option)
			).toString();

			if (this.otherAnswer.length > 0) {
				this.otherOptionControl.setValue(this.otherAnswer.toString());
			}
		}

		this.isRequired = this.inputFormControl.hasValidator(Validators.required);
	}

	updateValidators() {
		if (!this.otherEnabled) {
			this.otherOptionControl.clearValidators();
		} else {
			this.otherOptionControl.setValidators([
				Validators.required,
				stringExistsValidator(this.options),
			]);
		}
	}

	updateInputFormControlErrors() {
		const inputErrors = this.inputFormControl.errors || {};
		const otherOptionInvalid = this.otherOptionControl.invalid;
		const otherOptionRequiredError = this.otherOptionControl.getError('required');

		if (otherOptionInvalid) {
			inputErrors['otherOptionInvalid'] = true;
		} else {
			delete inputErrors['otherOptionInvalid'];
		}

		if (otherOptionRequiredError) {
			inputErrors['otherOptionRequired'] = otherOptionRequiredError;
			inputErrors['otherOptionInvalid'] = true;

		} else {
			delete inputErrors['otherOptionRequired'];
		}

		this.inputFormControl.setErrors(Object.keys(inputErrors).length ? inputErrors : null);
	}

	handleOtherEnabled() {
		if (this.control.addOtherOption && this.otherOptionControl.value) {
			this.otherEnabled = true;
		} else {
			this.otherEnabled = false;
		}
	}
}
