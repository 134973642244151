import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BreadCrumb } from '@app/models/shared';

@Component({
	selector: 'app-breadcrumb',
	templateUrl: './breadcrumb.component.html',
	styleUrls: ['./breadcrumb.component.sass'],
})
export class BreadcrumbComponent {
	_originalCrumbs: BreadCrumb[] = [];
	_reference: string;

	displayedCrumbs: BreadCrumb[] = [];

	@Input() set crumbs(value: BreadCrumb[]) {
		this._originalCrumbs = value;
		this.updateCrumbs();
	}

	@Input() set reference(value: string) {
		this._reference = value;
		this.updateCrumbs();
	}

	@Output() navigation: EventEmitter<CallableFunction> = new EventEmitter();

	updateCrumbs() {
		this.displayedCrumbs = [...this._originalCrumbs];
		if (this._reference) {
			this.displayedCrumbs.push({
				routeName: this._reference,
			});
		}
	}

	handleNavigation(crumb: BreadCrumb, index: number) {
		if (crumb.routeTo && index !== this.displayedCrumbs.length - 1) {
			this.navigation.emit(crumb.routeTo);
		}
	}
}
