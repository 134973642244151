import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OrderModalType } from '@app/models/event.model';
import { ChangedTicketTypes, Order, OrderModalStep, PaymentCollectionBy, RefundBankAccount, RefundPayee } from '@app/models/order.model';
import { StoreService } from '@app/services/store/store.service';
import { CancelTickets, ChangeTicketTypes } from '@app/store/actions/event/event.actions';
import { EventActionsConstants } from '@app/store/actions/event/event.actions.constants';
import { Actions, ofType } from '@ngrx/effects';
import { take } from 'rxjs';

@Component({
	selector: 'app-order-tickets-modal',
	templateUrl: './order-tickets-modal.component.html',
	styleUrls: ['./order-tickets-modal.component.sass'],
})
export class OrderTicketsModalComponent implements OnInit {
	heading = '';
	description = '';
	nextButtonText = '';
	previousButtonText = '';

	isLoading = false;
	isDisabled = true;
	ticketCount = 0;
	totalCancellationTicketCost = 0;
	currentStep = OrderModalStep.DETAILS;

	bankAccount: RefundBankAccount = new RefundBankAccount();
	refundFeePayableBy: RefundPayee;
	changedTicketTypes: ChangedTicketTypes;
	cancelTicketIds: number[] = [];

	orderModalType = OrderModalType;

	get isSubmitDisabled() {
		return this.isDisabled || this.isLoading;
	  }

	constructor(
		public dialogRef: MatDialogRef<OrderTicketsModalComponent>,
		private store: StoreService,
		private actions$: Actions,
		@Inject(MAT_DIALOG_DATA)
		public data: {
			isMobile: boolean;
			type: OrderModalType;
			order: Order;
			eventId: number;
			orderId: number;
		}
	) {}

	ngOnInit(): void {
		if (this.data.type === OrderModalType.ChangeTicketTypes) {
			this.heading = 'Change Ticket Type';
		} else {
			this.heading = 'Cancel Tickets';
		}
		this.handleStepText();

		this.actions$
			.pipe(
				ofType(
					EventActionsConstants.CHANGE_TICKET_TYPES_SUCCESS,
					EventActionsConstants.CANCEL_TICKETS_FAILED,
					EventActionsConstants.CANCEL_TICKETS_SUCCESS,
					EventActionsConstants.CHANGE_TICKET_TYPES_FAILED
				),
				take(1)
			)
			.subscribe(() => {
				this.dialogRef.close(true);
			});

		if (this.data.order.isTransferred && this.data.order.paymentCollectionBy === PaymentCollectionBy.ORGANISER) {
			this.refundFeePayableBy = RefundPayee.US;
		}
	}

	handleStepText() {
		if (this.data.type === OrderModalType.ChangeTicketTypes) {
			if (this.currentStep === OrderModalStep.DETAILS) {
				this.description = `You are changing the ticket type of the tickets. 
				If there is a price difference, 
				you will be required to settle the collection or refund the balance with the ticket holder yourself.`;
				this.nextButtonText = 'CHANGE TICKET';
				this.previousButtonText = 'CANCEL';
			} else if (this.currentStep === OrderModalStep.CONFIRMATION) {
				this.description = `You are changing the ticket type of ${this.ticketCount} ticket${this.ticketCount > 1 ? 's' : ''}. 
				If there is a price difference, 
				you will be required to settle the collection or refund the balance with the ticket holder yourself. 
				Click Confirm to proceed.`;
				this.nextButtonText = 'CONFIRM';
				this.previousButtonText = 'BACK';
			}
		} else if (this.data.type === OrderModalType.CancelTickets) {
			if (this.currentStep === OrderModalStep.DETAILS) {
				this.description = '';
				this.nextButtonText = 'CANCEL TICKET';
				this.previousButtonText = 'CANCEL';
			} else if (this.currentStep === OrderModalStep.CONFIRMATION) {
				this.description = `You are about to cancel the below ticket${this.ticketCount > 1 ? 's' : ''}. Click Confirm to proceed.`;
				this.nextButtonText = 'CANCEL TICKET';
				this.previousButtonText = 'BACK';
			} else if (this.currentStep === OrderModalStep.BANK_DETAILS) {
				this.description = 'Please provide the bank account details of the account into which the refund must be paid.';
				this.nextButtonText = 'CANCEL TICKET';
				this.previousButtonText = 'BACK';
			}
		}
		return '';
	}

	handleTicketTypesChange(tickets: ChangedTicketTypes) {
		this.ticketCount = tickets.ticketIds.split(',').length;
		this.changedTicketTypes = tickets;
		if (tickets.ticketIds.length > 0) {
			this.isDisabled = false;
		} else {
			this.isDisabled = true;
		}
	}

	handleCancelTicketsSelected(selectedTickets: { ticketIds: number[]; totalAmount: number }) {
		this.isDisabled = !selectedTickets.ticketIds.length;
		this.cancelTicketIds = selectedTickets.ticketIds;
		this.totalCancellationTicketCost = selectedTickets.totalAmount;
	}

	handleRefunderSelected(refunder: RefundPayee) {
		this.refundFeePayableBy = refunder;
		this.isDisabled = false;
	}

	handleBankDetails(bankDetails: { bankAccount: RefundBankAccount; isValid: boolean }) {
		if (bankDetails.isValid) {
			this.isDisabled = false;
		} else {
			this.isDisabled = true;
		}
		this.bankAccount = bankDetails.bankAccount;
	}

	handleNext() {
		if (this.isDisabled) {
			return;
		}

		if (this.currentStep === OrderModalStep.CONFIRMATION) {
			this.confirmAction();
		} else if (this.currentStep === OrderModalStep.BANK_DETAILS) {
			this.handleDispatchCancelTickets();
		} else {
			this.moveToConfirmation();
		}
		this.handleStepText();
	}

	confirmAction() {
		if (this.data.type === OrderModalType.ChangeTicketTypes) {
			this.dispatchChangeTicketTypes();
		} else if (this.data.type === OrderModalType.CancelTickets) {
			this.handleCancelTickets();
		}
	}

	dispatchChangeTicketTypes() {
		this.store.dispatch(
			new ChangeTicketTypes({
				orderId: this.data.orderId,
				eventId: this.data.eventId,
				changedTickets: this.changedTicketTypes,
			})
		);
		this.isLoading = true;
	}

	handleCancelTickets() {
		if (this.requiresBankDetails()) {
			this.currentStep = OrderModalStep.BANK_DETAILS;
			this.isDisabled = true;
		} else {
			this.handleDispatchCancelTickets();
		}
	}

	moveToConfirmation() {
		this.currentStep = OrderModalStep.CONFIRMATION;
		if (this.data.type === OrderModalType.CancelTickets && this.refundFeePayableBy === undefined) {
			this.isDisabled = true;
		}
	}

	requiresBankDetails(): boolean {
		return this.data.order.refundRequiresBankAccount || (this.data.order.canRefund && this.totalCancellationTicketCost > 0);
	}

	handleDispatchCancelTickets() {
		this.isLoading = true;
		this.isDisabled = true;
		this.store.dispatch(
			new CancelTickets({
				orderId: this.data.orderId,
				eventId: this.data.eventId,
				cancelTickets: {
					ticketIds: this.cancelTicketIds,
					bankAccount: this.bankAccount,
					refundFeesPayableBy: this.refundFeePayableBy,
					makePayment: !(this.data.order.isTransferred && this.data.order.paymentCollectionBy === PaymentCollectionBy.ORGANISER),
				},
			})
		);
	}

	handleCancel() {
		if (this.currentStep === OrderModalStep.CONFIRMATION) {
			this.currentStep = OrderModalStep.DETAILS;
		} else if (this.currentStep === OrderModalStep.BANK_DETAILS) {
			this.bankAccount = null;
			this.isDisabled = false;
			this.currentStep = OrderModalStep.CONFIRMATION;
		} else {
			this.dialogRef.close(false);
		}
		this.handleStepText();
	}
}
