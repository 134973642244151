import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { StoreService } from '@app/services/store/store.service';
import { Go } from '@app/store/actions/router/router.actions';
import { MY_TICKETS_HEADER, ManageBookingCards, TICKET_DETAIL_CARDS, manageBookingCardTooltipTexts } from '@app/utils/consts';
import { take, takeUntil } from 'rxjs/operators';
import * as userSelectors from '@app/store/selectors/user.selector';
import { DetailParameters, ManageBooking, ManageBookingInvoice } from '@app/models/user.model';
import { MatDialog } from '@angular/material/dialog';
import { UntypedFormGroup } from '@angular/forms';
import { EditInvoiceModalComponent } from '../../modals/edit-invoice-modal/edit-invoice-modal.component';
import { ProductStatus, ProductStatusMapping } from '@app/models/event.model';
import { HeaderContent, SectionRouteCard } from '@app/models/shared';
import { Dictionary } from '@ngrx/entity';
import { GetUserManageBooking, ManageBookingConstants } from '@app/user-features/manage-booking/manage-booking.actions';
import { SchemeID } from '@app/models/dataSchema.model';
import { Actions, ofType } from '@ngrx/effects';
import { AddNotification } from '@app/store/actions/notification/notification.actions';
import { NotificationType } from '@app/models/notification.model';
import { InternalURLCreator, URLCreator } from '@app/services/url/url.dictionary';
import { TicketDetailsModalComponent } from '../../modals/ticket-details-modal/ticket-details-modal.component';
import { TransferBackModalComponent } from '../../modals/transfer-back-modal/transfer-back-modal.component';
import { handleOrder } from '@app/utils/common-helpers';
import { convertToIdFormat } from '@app/utils/common-helpers';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { TICKET_ID_PARAM_KEY } from '../my-tickets/my-tickets.component';

@Component({
	selector: 'app-manage-booking',
	templateUrl: './manage-booking.component.html',
	styleUrls: ['./manage-booking.component.sass'],
	animations: [
		trigger('detailExpand', [
			state('collapsed', style({ height: '0px', minHeight: '0', opacity: 0,  display: 'none' })),
			state('expanded', style({ height: '*', opacity: 1 })),
			transition('expanded <=> collapsed', [animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)')]),
		]),
	],
})
export class ManageBookingComponent implements OnInit {
	isMobile = false;
	ticketId: number;
	handleDefaultOrder = handleOrder;
	booking: ManageBooking;
	isLoading = true;
	status: string;
	statusClass: string;
	ticketCardData = TICKET_DETAIL_CARDS;
	helperCardData: Dictionary<SectionRouteCard> = {
		sendMessage: {
			title: 'Send Message',
			iconPath: './assets/icons/question-mark',
			routeTo: () => URLCreator.contactHost(this.booking.id),
		},
		helpCenter: {
			title: 'Visitor Help Center',
			iconPath: './assets/icons/sms',
			routeTo: () => URLCreator.helpCentre(),
		},
	};

	headerContent: HeaderContent = MY_TICKETS_HEADER['manageBooking'];

	detailParameters: DetailParameters = {
		canTransfer: true,
		canRefund: true,
		canTicketResale: true,
		allowTicketEditing: true,
		canTransferBack: false,
		contactHost: false,
	};
	editInvoiceForm: UntypedFormGroup;

	showDetails = false;
	get detailExpandState() {
		return this.showDetails ? 'expanded' : 'collapsed';
	}

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private breakpointService: BreakpointService,
		private activatedRoute: ActivatedRoute,
		private store: StoreService,
		private dialog: MatDialog,
		private actions$: Actions
	) {}

	ngOnInit(): void {
		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});

		this.actions$
			.pipe(
				ofType(ManageBookingConstants.GET_USER_MANAGE_BOOKING_SUCCESS, ManageBookingConstants.GET_USER_MANAGE_BOOKING_FAILED),
				takeUntil(this.destroyed$)
			)
			.subscribe(({ type, payload: { booking } }: { type: string; payload: { booking: ManageBooking } }) => {
				if (type === ManageBookingConstants.GET_USER_MANAGE_BOOKING_SUCCESS) {
					this.booking = booking;
					this.headerContent.title = 'Manage Booking for ' + this.booking.reference;
					this.status = ProductStatusMapping[ProductStatus[this.booking.productStatus]].toUpperCase();
					this.statusClass = ProductStatus[this.booking.productStatus].toLowerCase();

					this.detailParameters = {
						canTransfer: this.booking.canTransfer,
						canRefund: this.booking.canRefund,
						canTicketResale: this.booking.canTicketResale,
						allowTicketEditing: this.booking.allowTicketEditing,
						canTransferBack: this.booking.canTransferBack,
						contactHost: this.booking.enableContactHost,
					};
					this.isLoading = false;

				} else {
					this.store.dispatch(
						new AddNotification({
							id: 'manage-booking',
							actionType: null,
							action: null,
							type: NotificationType.ERROR,
							title: 'Oops! We can\'t get your booking details',
							delay: 4000,
						})
					);
					this.handleNavigationBack();
				}
			});

		this.actions$
			.pipe(
				ofType(ManageBookingConstants.UPDATE_BOOKING_INVOICE_SUCCESS),
				takeUntil(this.destroyed$)
			)
			.subscribe(({ type,  payload: { invoice } }: { type: string; payload: { invoice: ManageBookingInvoice }}) => {
				const updatedBooking = {...this.booking, invoice};
				this.booking = updatedBooking;
			});

		this.activatedRoute.params.pipe(take(1)).subscribe((params) => {
			if (!params) {
				this.handleNavigationBack();
			}
			this.ticketId = params[TICKET_ID_PARAM_KEY];

			this.store
				.select(userSelectors.userManageBooking())
				.pipe(take(1))
				.subscribe((booking) => {
					if (!booking || booking.id !== (this.ticketId as SchemeID)) {
						this.store.dispatch(new GetUserManageBooking({ id: this.ticketId as SchemeID }));
					} else {
						this.booking = booking;
						this.status = ProductStatusMapping[ProductStatus[this.booking.productStatus]].toUpperCase();
						this.statusClass = ProductStatus[this.booking.productStatus].toLowerCase();
						this.isLoading = false;
					}
				});
		});
	}

	handleNavigationBack() {
		this.store.dispatch(new Go({ path: [InternalURLCreator.myTickets()] }));
	}

	handlePrimaryButton() {
		this.dialog.open(TicketDetailsModalComponent, {
			data: {
				isMobile: this.isMobile,
				id: this.booking.id,
				encrypt: this.booking.encrypt,
				tickets: this.booking.tickets,
				reference: this.booking.reference,
			},
			panelClass: this.isMobile ? 'g-full-page-dialog' : 'g-wide-dialog',
		});
	}

	handleNavigateTo(path: CallableFunction, key: string) {
		if (!this.isCardDisabled(key)) {
			switch (key) {
				case ManageBookingCards.VIEW_INVOICE:
					window.open(this.booking.invoiceUrl);
					break;
				case ManageBookingCards.EDIT_INVOICE:
					this.handleEditInvoiceModal();
					break;
				case ManageBookingCards.TRANSFER_BACK:
					this.handleTransferBack();
					break;
				default:
					this.store.dispatch(new Go({ path: [path(this.ticketId)] }));
			}
		}
	}

	handleMiddleClick(path: CallableFunction, key: string): string {
		if (!this.isCardDisabled(key)) {
			switch (key) {
				case ManageBookingCards.VIEW_INVOICE:
				case ManageBookingCards.EDIT_INVOICE:
				case ManageBookingCards.TRANSFER_BACK:
					break;
				default:
					return path(this.ticketId);
			}
		}
	}

	handleNavigateHelp(path: string) {
		window.open(path);
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}

	handleEditInvoiceModal(): void {
		this.dialog.open(EditInvoiceModalComponent, {
			data: {
				purchaseId: this.booking.id,
				invoice: this.booking.invoice,
				isMobile: this.isMobile,
			},
			panelClass: this.isMobile ? 'g-full-page-dialog' : 'g-standard-dialog',
		});
	}

	handleTransferBack() {
		const transferBackRef = this.dialog.open(TransferBackModalComponent, {
			data: {
				purchaseId: this.booking.id,
				isMobile: this.isMobile,
				eventIsCancelled: this.booking.eventIsCancelled,
				contactHostUrl: this.booking.contactHostUrl,
			},
			panelClass: 'g-standard-dialog',
		});
		transferBackRef.afterClosed().subscribe((isSuccess: boolean) => {
			if (isSuccess) {
				this.isLoading = true;
				this.store.dispatch(new GetUserManageBooking({ id: this.ticketId as SchemeID }));
			}
		});
	}

	handleMapNavigate() {
		window.open(this.booking.googleMapsUrl);
	}

	handleEventNavigate() {
		window.open(this.booking.eventLink, '_blank');
	}

	isCardDisabled(key: string): boolean {
		switch (key) {
			case ManageBookingCards.TRANSFER_TICKET:
				return this.booking.eventIsCancelled ? true : !this.detailParameters.canTransfer;
			case ManageBookingCards.REQUEST_REFUND:
				return !this.detailParameters.canRefund;
			case ManageBookingCards.TICKET_RESALE:
				return this.booking.eventIsCancelled ? true : !this.detailParameters.canTicketResale;
			case ManageBookingCards.EDIT_TICKET:
				return this.booking.eventIsCancelled ? true : !this.detailParameters.allowTicketEditing;
			case ManageBookingCards.VIEW_INVOICE:
				return !this.booking.cartId;
			case ManageBookingCards.EDIT_INVOICE:
				return !this.booking.cartId;
			default:
				return false;
		}
	}

	getFilteredCardData(): Partial<{ [key in ManageBookingCards]: SectionRouteCard }> {
		if (this.detailParameters && this.detailParameters.canTransferBack) {
			return TICKET_DETAIL_CARDS;
		} else {
			const { [ManageBookingCards.TRANSFER_BACK]: omitted, ...rest } = TICKET_DETAIL_CARDS;
			return rest;
		}
	}

	getTooltipMessage(itemTitle: string){
		return manageBookingCardTooltipTexts[itemTitle];
	}

	formatId(itemTitle: string, prefix: string): string {
		return convertToIdFormat(itemTitle, prefix);
	}
}
