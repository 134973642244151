<div class="l-container" [ngClass]="{ 'l-mobile-container': isMobile }">
	<div class="l-search-container" [ngClass]="{ 'l-mobile-search-container': isMobile }">
	  <mat-icon class="l-icon">search</mat-icon>
	  <input [(ngModel)]="searchTerm" (input)="isMobile ? handleMobileSearchEmit() : handleFilterEmit()" type="text" name="search"
		class="form-control l-search" [ngClass]="{ 'l-dark': !isMobile, 'l-light': isMobile }" placeholder="Search"
		id="search-bar" />
	</div>
	<ng-container *ngIf="!isMobile">
	  <div id="status-filter" *ngIf="showFilter" class="input-group l-filters">
		<mat-select class="form-control w-100" #filter="ngModel" name="filter" placeholder="Status (All)"
		  [(ngModel)]="selectedFilter" (selectionChange)="handleFilterEmit()">
		  <mat-optgroup *ngFor="let filterGroup of filterOptions" [label]="filterGroup.name">
			<mat-option *ngFor="let filter of filterGroup.filter" [value]="filter">
			  {{filter.viewValue}}
			</mat-option>
		  </mat-optgroup>
		</mat-select>
	  </div>
	  <div id="dates-filter" class="input-group l-filters">
		<mat-select class="form-control w-100" #sort="ngModel" name="sort" placeholder="Sort (All Dates)"
		  [(ngModel)]="selectedSort" (selectionChange)="handleSortEmit()">
		  <mat-option *ngFor="let sort of sortOptions | keyvalue" [value]="sort.value">{{ sort.value }}</mat-option>
		</mat-select>
	  </div>
	</ng-container>
	<div id="mobile-filter" class="l-filter-button" [matBadge]="numberAppliedFilters" matBadgePosition="before" *ngIf="isMobile" (click)="handleMobileFilter()">
	  <mat-icon>filter_list</mat-icon>
	</div>
  </div>
