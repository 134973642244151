import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-sales-date-warning-modal',
	templateUrl: './sales-date-warning-modal.component.html',
	styleUrls: ['./sales-date-warning-modal.component.sass'],
})
export class SalesDateWarningModalComponent {
	constructor(
		public dialogRef: MatDialogRef<SalesDateWarningModalComponent>,
		@Inject(MAT_DIALOG_DATA)
		public data: {
			isMobile: boolean;
			isScheduledEvent: boolean;
			salesEndDate: Date;
		}
	) {}

	handleClose(confirm: boolean) {
		this.dialogRef.close(confirm);
	}
}
