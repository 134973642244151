import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StoreService } from '@app/services/store/store.service';
import { InternalURLCreator } from '@app/services/url/url.dictionary';
import { Go } from '@app/store/actions/router/router.actions';
import { DeleteUser } from '@app/store/actions/user/user.actions';
import { UserActionsConstants } from '@app/store/actions/user/user.actions.constants';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs';

@Component({
	selector: 'app-delete-account-modal',
	templateUrl: './delete-account-modal.component.html',
	styleUrls: ['./delete-account-modal.component.sass'],
})
export class DeleteAccountModalComponent implements OnInit {
	password: string;
	confirmation = false;
	isLoading = false;
	errorMessage: string;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(public dialogRef: MatDialogRef<DeleteAccountModalComponent>, private store: StoreService, private actions$: Actions,
		@Inject(MAT_DIALOG_DATA) public data: {
			isMobile: boolean;
		}) { }

	ngOnInit(): void {
		this.actions$.pipe(ofType(UserActionsConstants.DELETE_USER_SUCCESS, UserActionsConstants.DELETE_USER_FAILED),
			takeUntil(this.destroyed$))
			.subscribe(({ type, payload }: { type: string; payload: { msg?: string; route?: string } }) => {
				this.isLoading = false;
				if (type === UserActionsConstants.DELETE_USER_SUCCESS) {
					window.location.href = payload.route;
					this.handleCancel();
				} else {
					this.errorMessage = payload.msg;
				}
			});
	}

	handleCancel() {
		this.dialogRef.disableClose = false;
		this.dialogRef.close();
	}

	handleDelete() {
		this.isLoading = true;
		this.store.dispatch(new DeleteUser({password: this.password}));
	}

	handleEmailNavigation() {
		this.dialogRef.disableClose = false;
		this.dialogRef.close();
		this.store.dispatch(new Go({ path: [InternalURLCreator.emailPreference()] }));
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
