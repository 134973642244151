import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { SchemeID } from '@app/models/dataSchema.model';
import { DraftEventTicketGroup } from '@app/models/event.model';
import { ImageService, ImageServiceType } from '@app/services/image/image.service';
import { StoreService } from '@app/services/store/store.service';
import { TicketGroupsService } from '@app/services/tickets/ticket-groups.service';
import { MatTextControlFieldComponent } from '@app/shared/mat-form-field/mat-text-control-field/mat-text-control-field.component';
import { UpsertTicketGroup } from '@app/store/actions/event/event.actions';
import { EventActionsConstants } from '@app/store/actions/event/event.actions.constants';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs';

@Component({
	selector: 'app-ticket-groups-add-edit',
	templateUrl: './ticket-groups-add-edit.component.html',
	styleUrls: ['./ticket-groups-add-edit.component.sass'],
})
export class TicketGroupsAddEditComponent{
	@Input() isMobile = false;
	@Input() eventId: SchemeID;
	@Input() form: UntypedFormGroup;
	@Input() isEditing: boolean;
	@Output() routeBackToListPage = new EventEmitter();
	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	@ViewChild('ticketGroupName', { static: false }) ticketGroupName: MatTextControlFieldComponent;

	ImageServiceTypeEnum = ImageServiceType;
	linkedTicketControls: AbstractControl<any, any>[];
	isImageUploading = false;
	isSavingChanges = false;

	constructor(
		private imageService: ImageService,
		private store: StoreService,
		private action$: Actions,
		private ticketGroupsService: TicketGroupsService
	) {
		this.imageService.init(ImageServiceType.TICKET);
	}

	ngOnInit(): void {
		this.linkedTicketControls = this.ticketGroupsService.getLinkedTicketControls(this.form);

		this.action$
			.pipe(
				ofType(EventActionsConstants.UPSERT_TICKET_GROUP_SUCCESS, EventActionsConstants.UPSERT_TICKET_GROUP_FAILED),
				takeUntil(this.destroyed$)
			)
			.subscribe(({ type }: { type: string }) => {
				if (type === EventActionsConstants.UPSERT_TICKET_GROUP_SUCCESS) {
					this.routeBackToListPage.emit();
				}
				this.isSavingChanges = false;
			});
	}

	ngAfterViewInit() {
		setTimeout(() => this.scrollToTicketGroupName(), 0);
	}

	scrollToTicketGroupName() {
		if (this.ticketGroupName && this.ticketGroupName.inputRef) {
			this.ticketGroupName.inputRef.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	}

	fromForm(controlKey: string): AbstractControl {
		if (this.form) {
			return this.form.get(controlKey);
		}
		return null;
	}

	updateSelectedTickets(id: number, isChecked: boolean): void {
		const ticketIndex = this.linkedTicketControls.findIndex(ticket => ticket.value.id === id);
		if (ticketIndex !== -1) {
			this.linkedTicketControls.at(ticketIndex).patchValue({
				checked: isChecked,
			});
		}
		this.form.markAsDirty();
	}

	isSubmitAllowed = (form: UntypedFormGroup): boolean => form && form.valid && !this.isSavingChanges && !form.pristine;

	handleSavingTicketGroup = (draftTicketGroup: DraftEventTicketGroup) => {
		this.form.markAsPristine();
		const ticketGroup = this.ticketGroupsService.prepareTicketGroupToSubmit(draftTicketGroup);
		this.store.dispatch(new UpsertTicketGroup({
			eventId: this.eventId,
			ticketGroup,
			isUpdating: !!draftTicketGroup.id,
		}));
		this.isSavingChanges = true;
	};

	onCancel() {
		this.routeBackToListPage.emit();
	}

	getTicketGroupImage() {
		if (this.form) {
			return this.form.get('thumbnail').value;
		}
	}

	hasFormErrors(controlKey: string) {
		return this.fromForm(controlKey).invalid && ((this.fromForm(controlKey).dirty || this.fromForm(controlKey).touched));
	}

	getError(errors: { [key: string]: string }) {
		return errors && errors[Object.keys(errors)[0]];
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
