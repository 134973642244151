import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SchemeID } from '@app/models/dataSchema.model';
import { HeaderContent } from '@app/models/shared';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { InternalURLCreator, URLCreator } from '@app/services/url/url.dictionary';
import { EVENT_ID_PARAM_KEY } from '@app/utils/consts';
import { take, takeUntil } from 'rxjs';

@Component({
	selector: 'app-sales-report',
	templateUrl: './sales-report.component.html',
	styleUrls: ['./sales-report.component.sass'],
})
export class SalesReportComponent implements OnInit {
	url: string;
	eventId: SchemeID;
	isMobile = false;

	headerContent: HeaderContent = {
		breadCrumbs: [
			{
				routeName: 'Reports',
				routeTo: () => InternalURLCreator.reports(this.eventId),
			},
			{
				routeName: 'Sales Report',
			},
		],
		title: 'Sales Report',
		description: `The following information gives a breakdown of key sales information for your event. 
		The data is gathered from our own servers as well as from Google Analytics. 
		Please note that we cannot verify the accuracy of any data supplied from Google Analytics.`,
	};

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(private activatedRoute: ActivatedRoute, private breakpointService: BreakpointService) {}

	ngOnInit(): void {
		this.activatedRoute.parent.paramMap.pipe(take(1)).subscribe((params) => {
			this.eventId = +params.get(EVENT_ID_PARAM_KEY);
			this.url = URLCreator.salesReport(this.eventId);
		});

		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
