import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-share-my-event-buttons',
	templateUrl: './share-my-event-buttons.component.html',
	styleUrls: ['./share-my-event-buttons.component.sass'],
})
export class ShareMyEventButtonsComponent {
	@Input() eventLink = '';
	@Input() eventName = '';
}
