import { Injectable } from '@angular/core';
import { NotificationSource, NotificationDictionary } from './notification.dictionary';

@Injectable()
export class NotificationSourceService {
	private source: NotificationSource = {};

	addNotifications = (Notifications: NotificationDictionary) => {
		this.source = {
			...this.source,
			...Notifications.source,
		};
	};

	getDictionary = () => this.source;
}
