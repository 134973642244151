export interface SearchAndFilter {
	searchTerm: string;
	filterOption: FilterOption;
}

export interface FilterOption {
	filterType: string;
	selectedFilter?: Filter;
}

export enum SortOption {
	MostRecent = 'Most Recent',
	NameAscending = 'Name Ascending',
	NameDescending = 'Name Descending',
	StartDate = 'Start Date',
}

export interface FilterGroup {
	name: string;
	filter: Filter[];
}

export interface Filter {
	value: number;
	viewValue: string;
}

export enum TicketFilterGroups {
	status = 'Status',
}

export enum EventFilterGroups {
	type = 'Type',
}
