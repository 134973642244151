<div class="l-container" *ngIf="!isLoading; else pageLoading">
	<div class="l-content" [ngClass]="{ 'mobile': isMobile }">
		<div class="l-title" [ngClass]="{ 'mobile': isMobile, 'web': !isMobile }">
			<h3 *ngIf='!isMobile'>Manage Event</h3>
			<p>
				Take full control of your event with our comprehensive set of tools, crafted to simplify 
				event organization and deliver a smooth and successful event experience
			</p>
		</div>
		<div class="l-cards-container" [ngClass]="{ 'mobile': isMobile, 'web': !isMobile }">
			<a *ngFor="let card of cardsData | keyvalue: handleDefaultOrder" [routerLink]="[card.value.routeTo(eventId)]"
				[ngClass]="{'l-card': !isMobile }" class="g-flat-link g-pointer">
				<ng-container *ngIf="!isMobile; else showMobile">
					<app-section-route-card class="g-pointer l-card-content"
						[card]="card.value"></app-section-route-card>
				</ng-container>
				<ng-template #showMobile>
					<app-mobile-section-route-card [icon]="card.value.iconPath" [text]="card.value.title"
						[description]='card.value.description' [asCard]='true'></app-mobile-section-route-card>
				</ng-template>
			</a>
			<div *ngIf=checkCanUserDeleteEvent() (click)="handleDeleteDraft()" 
				[ngClass]="{'l-card': !isMobile }" class="g-flat-link g-pointer">
				<ng-container *ngIf="!isMobile; else showMobile">
					<app-section-route-card class="g-pointer l-card-content"
						[card]="deleteDraftEventCard"></app-section-route-card>
				</ng-container>
				<ng-template #showMobile>
					<app-mobile-section-route-card [icon]="deleteDraftEventCard.iconPath" [text]="deleteDraftEventCard.title"
						[description]='deleteDraftEventCard.description' [asCard]='true'></app-mobile-section-route-card>
				</ng-template>
			</div>
		</div>
	</div>
</div>
<ng-template #pageLoading>
	<div class="l-page-loader">
		<mat-spinner></mat-spinner>
	</div>
</ng-template>