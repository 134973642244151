import { NgModule } from '@angular/core';
import { StepperHeaderComponent } from './stepper-header/stepper-header.component';
import { StepperHeaderStepComponent } from './stepper-header-step/stepper-header-step.component';
import { EventSharedModule } from '@app/features/event-shared/event-shared.module';
import { SharedModule } from '@app/shared/shared.module';
import { EventUserIntroComponent } from './user-intro/user-intro.component';
import { EventCreationComponent } from '@app/features/event-creation/event-creation.component';
import { EventUserContactInfoComponent } from './user-contact-info/user-contact-info.component';
import { EditEventAlertComponent } from './edit-event-alert/edit-event-alert.component';
import { AngularMaterialModule } from '@app/shared/angular-material.module';
import { LottieModule } from 'ngx-lottie';
import { LeavingConfirmationModalComponent } from './modals/leaving-confirmation-modal/leaving-confirmation-modal.component';

@NgModule({
	declarations: [
		StepperHeaderComponent,
		StepperHeaderStepComponent,
		EventUserIntroComponent,
		EventUserContactInfoComponent,
		EventCreationComponent,
		EditEventAlertComponent,
  		LeavingConfirmationModalComponent,
	],
	imports: [
		EventSharedModule,
		SharedModule,
		AngularMaterialModule,
		LottieModule,
	],
})
export class EventCreationModule {}
