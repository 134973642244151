<div class="l-table-container">
	<table
		mat-table
		[dataSource]="dataSource"
		multiTemplateDataRows
		class='l-table expansion'
		cdkDropList
		(cdkDropListDropped)="handleDrag($event)"
		[cdkDropListDisabled]="dragDisabled"
		#tabContentTable
	>
		<ng-container matColumnDef="sorting">
			<th
				mat-header-cell
				*matHeaderCellDef
				class="l-sorting-column"
			></th>
			<td
				mat-cell
				*matCellDef="let newsContent"
				class="l-sorting-column"
				[ngClass]='{
					"expansion-parent": expandedDetailElement !== newsContent.value.contentUid, 
					"current-expansion-parent": expandedDetailElement === newsContent.value.contentUid
				}'
			>
				<img
					src="./assets/icons/dots-nine.svg"
					alt="Drag Icon"
					class="l-info-icon g-move"
					(mousedown)="dragDisabled = false;"
					(touchstart)="dragDisabled = false;"
				>
			</td>
		</ng-container>
		<ng-container matColumnDef="isExpanded">
			<th
				mat-header-cell
				*matHeaderCellDef
				class="l-expanded-column"
			></th>
			<td
				mat-cell
				*matCellDef="let newsContent"
				class="l-expanded-column"
				[ngClass]='{
					"expansion-parent": expandedDetailElement !== newsContent.value.contentUid, 
					"current-expansion-parent": expandedDetailElement === newsContent.value.contentUid
				}'
			>
				<mat-icon
					[@rotateIcon]="expandedDetailElement !== newsContent.value.contentUid ? 'default' : 'rotated'">chevron_right</mat-icon>
			</td>
		</ng-container>
		<ng-container matColumnDef="name">
			<th
				mat-header-cell
				*matHeaderCellDef
			> Name </th>
			<td
				mat-cell
				*matCellDef="let newsContent"
				id="news-content-name"
				[ngClass]='{
					"expansion-parent": expandedDetailElement !== newsContent.value.contentUid, 
					"current-expansion-parent": expandedDetailElement === newsContent.value.contentUid
				}'
			>{{newsContent.value.name}}</td>
		</ng-container>
		<ng-container matColumnDef="visibility">
			<th
				mat-header-cell
				*matHeaderCellDef
			> Visibility </th>
			<td
				mat-cell
				*matCellDef="let newsContent"
				[ngClass]='{
					"expansion-parent": expandedDetailElement !== newsContent.value.contentUid, 
					"current-expansion-parent": expandedDetailElement === newsContent.value.contentUid
				}'
			><mat-slide-toggle
					class="standard-toggle"
					(click)="$event.stopPropagation()"
					[formControl]="newsContent.get('isVisible')"
					name="displayReviews"
					id="news-content-visibility"
				></mat-slide-toggle>
			</td>
		</ng-container>
		<ng-container matColumnDef="delete">
			<th
				mat-header-cell
				*matHeaderCellDef
			></th>
			<td
				mat-cell
				*matCellDef="let newsContent"
				[ngClass]='{
					"expansion-parent": expandedDetailElement !== newsContent.value.contentUid, 
					"current-expansion-parent": expandedDetailElement === newsContent.value.contentUid
				}'
			>
				<mat-icon
					(click)="handleDeleteTabContentType(newsContent)"
					id="news-content-delete"
				>delete_outline</mat-icon>
			</td>
		</ng-container>
		<ng-container matColumnDef="expandedDetail">
			<td
				mat-cell
				*matCellDef="let newsContent"
				[attr.colspan]="displayColumns.length"
				class="l-expanded-container"
			>
				<div [@detailExpand]="newsContent.value.contentUid != expandedDetailElement ? 'collapsed' : 'expanded'">
					<app-mat-text-control-field
						[label]="'Name'"
						[placeholder]="'Name'"
						[control]="newsContent.get('name')"
						[outerClass]="'pb-3'"
						id="news-content-name"
						[required]="true"
						labelClass="g-form-label"
					>
					</app-mat-text-control-field>
					<div class="pb-3">
						<app-text-editor
							class="pb-3"
							label="Details"
							[control]="newsContent.get('description')"
							[editorStyle]="{'height': '320px'}"
							[imageUploadHandler]="editorImageUploadHandler"
							[required]="isRequired"
							id="news-content-wysiwyg"
						></app-text-editor>
					</div>
				</div>
			</td>
		</ng-container>
		<tr
			mat-header-row
			*matHeaderRowDef="displayColumns"
		></tr>
		<tr
			mat-row
			*matRowDef="let newsContent; columns: displayColumns;"
			class='g-pointer'
			(click)="handleExpandContent(newsContent)"
			cdkDrag
		>
		</tr>
		<tr
			mat-row
			*matRowDef="let newsContent; columns: ['expandedDetail']"
			class="l-detail-row"
			[class.collapsed-row]="expandedDetailElement !== newsContent.value.contentUid"
		></tr>
	</table>
</div>