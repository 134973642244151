import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-error-display-modal',
	templateUrl: './error-display-modal.component.html',
	styleUrls: ['./error-display-modal.component.sass'],
})
export class ErrorDisplayModalComponent {
	constructor(public dialogRef: MatDialogRef<ErrorDisplayModalComponent>,
		@Inject(MAT_DIALOG_DATA)
		public data: { title: string; text: string }) { }

	handleClose() {
		this.dialogRef.close();
	}
}
