import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { EditorImageUploadOptions } from '../editor/editor.component';
import { ImageService, ImageServiceType } from '@app/services/image/image.service';
import { SchemeID } from '@app/models/dataSchema.model';
import { TimezoneService } from '@app/services/timezone/timezone.service';
import { EventCategory, EventFeatureOptions, EventImage, Timezone, Venue } from '@app/models/event.model';
import { SelectFieldOption } from '../form-field/select-field/select-field.model';
import { ProductType, ProductVenueOption } from '@app/models/product.model';
import { Country } from '@app/models/user.model';
import { MapsService } from '@app/services/maps/maps.service';
import { MatSelectChange } from '@angular/material/select';
import { MapInfoWindow } from '@angular/google-maps';
import { AddNotification } from '@app/store/actions/notification/notification.actions';
import { StoreService } from '@app/services/store/store.service';
import { NotificationType } from '@app/models/notification.model';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { FileTypes } from '@app/utils/consts';
import { SafeUrl } from '@angular/platform-browser';
import { ImageCropperType } from '../form-field/image-cropper/image-cropper.component';
import { Schedule } from '@app/models/schedule.model';
import { ScheduleService } from '@app/services/schedule/schedule.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { FormService } from '@app/services/form/form.service';
import { MatTextControlFieldComponent } from '../mat-form-field/mat-text-control-field/mat-text-control-field.component';
import { COUNTRIES_DB } from '@angular-material-extensions/select-country';
import { first, takeUntil } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import {
	SalesDateWarningModalComponent
} from '@app/features/events/manage-event/modals/sales-date-warning-modal/sales-date-warning-modal.component';
import { Go } from '@app/store/actions/router/router.actions';
import { InternalURLCreator } from '@app/services/url/url.dictionary';
import * as eventSelectors from '@app/store/selectors/event.selector';
import { requiredValidator, scheduleHasValid } from '../form-field/form-validators';

@Component({
	selector: 'app-event-details-form',
	templateUrl: './event-details-form.component.html',
	styleUrls: ['./event-details-form.component.sass'],
	animations: [
		trigger('expandCollapse', [
			state(
				'collapsed',
				style({
					opacity: 0,
					maxHeight: '0px',
					padding: '0px 16px', // Assuming you want to keep horizontal padding even when collapsed
					marginBottom: '0px',
					overflow: 'hidden',
				})
			),
			state(
				'expanded',
				style({
					opacity: 1,
					maxHeight: '500px', // Adjust based on your content's max height
					padding: '16px', // Adjust according to your actual padding
					marginBottom: '16px', // Adjust to your actual margin
					overflow: 'hidden',
				})
			),
			transition('expanded <=> collapsed', [animate('0.3s ease-in-out')]),
		]),
	],
})
export class EventDetailsFormComponent {
	@Input() form: UntypedFormGroup;
	@Input() isMobile: boolean;
	@Input() eventId: SchemeID;
	@Input() hasSoldTickets: boolean;
	@Input() previousVenues: Venue[];
	@Input() eventFormats: SelectFieldOption<SchemeID>[];
	@Input() eventCategories: EventCategory[];
	@Input() eventSubcategories: SelectFieldOption<SchemeID>[];
	@Input() eventFeatureOptions: EventFeatureOptions[];
	@Input() disableScheduleEditing: boolean;
	@Input() dynamicValidatorsKeys: string[];
	@Input() image: EventImage;
	@Input() isEventCreation = false;
	@Input() salesDateWarning: boolean;

	editorImageUploadHandler: EditorImageUploadOptions;

	country: Country;
	timezones: Timezone[];

	venueOptionSelected: number;
	isLocationAssigned = false;
	isLocationSearch = false;
	showMap = false;
	eventHasCoordinates = false;
	mapCenter: google.maps.LatLngLiteral;
	mapMarker = {
		position: {
			lat: null,
			lng: null,
		},
	};

	ProductTypeEnum = ProductType;
	VenueOptionEnum = ProductVenueOption;
	ImageServiceTypeEnum = ImageServiceType;

	isImageUploading = false;
	isUploadDisabled = false;
	bannerUploadError: string;
	maxFileSizeMb = 5;
	allowedFileTypes = FileTypes.image;
	imagePath: string | SafeUrl;
	showCropper = false;
	imageCropperLoading = false;
	base64String: string;
	banner: ImageCropperType = ImageCropperType.BANNER;

	ageRestrictions: EventFeatureOptions;
	isScheduledEvent: boolean;

	COUNTRIES = COUNTRIES_DB;

	@ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;
	@ViewChild('venueName', { static: false }) venueName: MatTextControlFieldComponent;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		public imageService: ImageService,
		private timezoneService: TimezoneService,
		private mapsService: MapsService,
		private ngZone: NgZone,
		private store: StoreService,
		private cdr: ChangeDetectorRef,
		public scheduleService: ScheduleService,
		private formService: FormService,
		private dialog: MatDialog
	) {
		this.imageService.init(ImageServiceType.EVENT);
	}

	ngOnInit() {
		this.initEditorImageUploadHandler();
		this.getCountry();
		this.getTimezones();
		this.getMapCenter();
		this.initVenueInputs();
		this.checkIfTicketsSold();
		this.eventOccurrence();
		this.hasSchedules();

		// Marking as pristine in case form is dirty on load.
		if (this.form && this.form.dirty) {
			this.form.markAsPristine();
		}

		// To display validation error if no event name
		if (!this.fromForm('name').value) {
			this.fromForm('name').markAsTouched();
		}
	}

	ngAfterViewInit(): void {
		if (this.eventHasCoordinates) {
			this.mapsService
				.initializeMaps()
				.then(() => {
					this.showMap = true;
				})
				.catch(() => {
					this.showMap = false;
					this.store.dispatch(
						new AddNotification({
							id: 'maps-load-failed',
							title: 'Oops! We can\'t load google maps - please try again later or contact support.',
							actionType: null,
							action: null,
							type: NotificationType.ERROR,
						})
					);
				});
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.eventFeatureOptions && changes.eventFeatureOptions.currentValue) {
			this.getAgeRestrictions();
		}

		if (changes.salesDateWarning && changes.salesDateWarning.currentValue) {
			this.dialog.open(SalesDateWarningModalComponent, {
				data: {
					isMobile: this.isMobile,
					isScheduledEvent: this.isScheduledEvent,
					salesEndDate: this.fromForm('endDateTime').value,
				},
				panelClass: this.isMobile ? 'g-full-page-dialog' : 'g-standard-dialog',
			}).afterClosed().subscribe((result) => {
				if (result) {
					this.store.dispatch(new Go({ path: [InternalURLCreator.ticketTypes(this.eventId)] }));
				}
				this.salesDateWarning = false;
			});
		}
	}

	getAgeRestrictions() {
		this.ageRestrictions = this.eventFeatureOptions.find((option) => option.name === 'Age restriction');
	}

	getTimezones() {
		this.timezones = this.timezoneService.createTimezones();
	}

	getCountry() {
		const countryName = this.fromForm('venue.country').value;

		if (countryName) {
			this.country = this.COUNTRIES.find((x) => x.name === countryName);
		}
	}

	getMapCenter() {
		if (
			this.form.get('productType').value === this.ProductTypeEnum.Event &&
			this.fromForm('venue.latitude') &&
			this.fromForm('venue.longitude')
		) {
			this.mapCenter = {
				lat: this.fromForm('venue.latitude').value,
				lng: this.fromForm('venue.longitude').value,
			};

			this.mapMarker.position.lat = this.mapCenter.lat;
			this.mapMarker.position.lng = this.mapCenter.lng;

			this.eventHasCoordinates = true;
		} else {
			this.eventHasCoordinates = false;
		}
	}

	getLocationByCoords() {
		this.mapsService.getPlaceByCoordinates({
			longitude: Number(this.form.get('venue.longitude').value),
			latitude: Number(this.form.get('venue.latitude').value),
			locationCb: (place: google.maps.places.PlaceResult[]) => {
				this.ngZone.run(() => {
					if (place) {
						this.venueOptionSelected = 0;
						this.form.get('venue.mapZoom').setValue(this.mapsService.defaultMapZoom);
						const placePos = {
							latitude: place[0].geometry.location.lat(),
							longitude: place[0].geometry.location.lng(),
						};
						this.fillAddressComponents(place[0], placePos);
						this.cdr.detectChanges();

						this.venueName.inputRef.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
						this.venueName.inputRef.nativeElement.focus();
					}
				});
			},
		});
	}

	eventOccurrence() {
		this.fromForm('isOneDayEvent')
			.valueChanges.pipe(takeUntil(this.destroyed$))
			.subscribe(() => {
				this.updateValidators();
			});
	}

	hasSchedules() {
		const schedulesForm = (this.form.get('schedules') as UntypedFormArray).controls;
		this.isScheduledEvent = !!schedulesForm.length;
	}

	getError(errors: { [key: string]: string }) {
		return errors && errors[Object.keys(errors)[0]];
	}

	initVenueInputs() {
		if (this.fromForm('venue.name').value) {
			this.isLocationAssigned = true;
		}

		if (this.fromForm('isOnline').value) {
			this.venueOptionSelected = this.VenueOptionEnum.None;
		}
	}

	initEditorImageUploadHandler() {
		this.editorImageUploadHandler = this.imageService.initEditorImagerUploadHandler(
			this.eventId,
			ImageServiceType.PRODUCT_DESCRIPTION,
			false
		);
	}

	fromForm(controlKey: string): AbstractControl {
		if (this.form) {
			return this.form.get(controlKey);
		}
		return null;
	}

	getFormValue(value: string) {
		return this.form.get(value);
	}

	fromFeatures(id: SchemeID): AbstractControl {
		if (this.form) {
			return (this.form.get('eventFeatureAnswers') as UntypedFormArray).controls
				.find((control) => control.value.id === id)
				.get('answer');
		}
		return null;
	}

	handleCountrySelect(event: Country) {
		this.setFormValue('venue.country', event.name);
		this.getCountry();
	}

	handleTimezoneChange(event: string) {
		this.setFormValue('timezone', event);
	}

	handleAddressSelected(place: google.maps.places.PlaceResult) {
		this.ngZone.run(() => {
			const placePos = {
				latitude: place.geometry.location.lat(),
				longitude: place.geometry.location.lng(),
			};
			this.fillAddressComponents(place, placePos);
			this.venueOptionSelected = 0;
		});
	}

	handleVenueOptionChange() {
		if (this.venueOptionSelected === this.VenueOptionEnum.None) {
			this.setFormValue('isOnline', true);
		} else if (this.fromForm('isOnline').value) {
			this.setFormValue('isOnline', false);
		}

		this.checkIfEventIsOnline();
	}

	handleLocationSearchToggle() {
		this.isLocationSearch = !this.isLocationSearch;

		if (!this.isLocationSearch) {
			this.mapsService.initGeocoder();
		} else {
			this.setFormValue('venue.latitude', null);
			this.setFormValue('venue.longitude', null);
		}
	}

	handlePreviousVenueSelection(event: MatSelectChange) {
		const venue = this.previousVenues.find((previousVenue) => previousVenue.id === event.value);
		this.setFormValue('venue', venue);
		this.isLocationAssigned = true;
		this.venueOptionSelected = 0;
		this.getCountry();
		this.getMapCenter();
	}

	handleOccurrenceChange() {
		this.setFormValue('startDateTime', null);
		this.setFormValue('endDateTime', null);

		const schedulesArray = this.fromForm('schedules') as UntypedFormArray;
		schedulesArray.clear();

		if (this.fromForm('isOneDayEvent').value === false) {
			this.fromForm('schedules').setValidators([requiredValidator()]);
			this.store.select(eventSelectors.eventSchedules())
				.pipe(first())
				.subscribe(schedules => {
					schedules.forEach((schedule) => {
						const scheduleToAdd = this.scheduleService.listScheduleForm(schedule.scheduleItems, schedule);
						(this.fromForm('schedules') as UntypedFormArray).push(scheduleToAdd);
					});
				});
		} else {
			this.fromForm('schedules').clearValidators();
		};

		this.updateValidators();
	}

	handleCategoryChange() {
		this.fromForm('subCategoryId').setValue(null);
		this.setFormValue('subCategoryId', null);
	}

	handlePublicPrivateChange(event: MatCheckboxChange) {
		this.setFormValue('isPublic', event.source.value === 'public');
	}

	handleImageCrop(base64: string) {
		if (!base64) {
			this.setFormValue('croppedImage', { base64: this.fromForm('imageString').value.base64, fileName: this.eventId });
		} else {
			this.setFormValue('croppedImage', { base64: base64, fileName: this.eventId });
		}
	}

	handleImageStringChange(imageString: any) {
		this.setFormValue('imageString', imageString);
	}

	handleOriginalImageChange(originalImage?: string) {
		this.setFormValue('originalImage', originalImage);
	}

	handleImageRemove() {
		this.setFormValue('originalImage', null);
		this.setFormValue('thumbnail', null);
		this.setFormValue('imageString', null);
		this.setFormValue('croppedImage', null);
	}

	handleThumbnailChange(thumbnail: string) {
		this.setFormValue('thumbnail', thumbnail);
	}

	checkIfTicketsSold() {
		if (this.fromForm('hasSoldTickets').value) {
			this.fromForm('timezone').disable();
		}
	}

	fillAddressComponents(place: google.maps.places.PlaceResult, placePos: { longitude?: number; latitude?: number } = {}) {
		const components = this.mapsService.parsePlace(place);
		const { addressLine1, addressLine2, longAddress } = this.mapsService.getAddressLine(components, place);

		this.fromForm('venue').patchValue({
			id: null,
			name: components.name ? components.name : null,
			country: components.country ? components.country : null,
			postalCode: components.postal_code ? components.postal_code : null,
			city: components.locality ? components.locality : null,
			addressLine1: addressLine1 || longAddress,
			addressLine2,
			longAddress,
			province: components.administrative_area_level_1,
			...placePos,
		});

		this.getCountry();
		this.getMapCenter();

		this.setFormValue('showMap', true);
		this.setFormValue('venue.mapZoom', this.mapsService.defaultMapZoom);

		this.isLocationAssigned = true;
		this.fromForm('venue.name').markAsTouched();
	}

	clearLocation(skipLocationSearch?: boolean) {
		this.setFormValue('venue', new Venue());
		this.setFormValue('showMap', false);
		this.isLocationAssigned = false;
		if (!skipLocationSearch) {
			this.isLocationSearch = true;
		}
	}

	addSchedules(schedule: Schedule) {
		const scheduleId = schedule.id;
		const scheduleItems = schedule.scheduleItems;
		const newSchedule = this.scheduleService.listScheduleForm(scheduleItems, schedule);
		if (scheduleId) {
			const index = (this.fromForm('schedules') as UntypedFormArray).value.findIndex((el) => el.id === schedule.id);
			(this.fromForm('schedules') as UntypedFormArray).removeAt(index);
			(this.fromForm('schedules') as UntypedFormArray).insert(index, newSchedule);
		} else {
			(this.fromForm('schedules') as UntypedFormArray).push(newSchedule);
		}
		this.markFormDirty();
	}

	markFormDirty() {
		this.form.markAsDirty();
	}

	isValidCoordinates() {
		const hasValue = (value: number) => value !== null && value !== undefined;
		const longitude = this.form.get('venue.longitude');
		const latitude = this.form.get('venue.latitude');

		return latitude.valid && longitude.valid && hasValue(latitude.value) && hasValue(longitude.value);
	}

	isEventLockedOnPrivate() {
		return this.fromForm('lockedOnPrivate').value;
	}

	setFormValue(controlKey: string, value: any) {
		this.fromForm(controlKey).setValue(value);
		this.fromForm(controlKey).markAsDirty();
	}

	isControlInvalid(control: AbstractControl) {
		return control.invalid && (control.dirty || control.touched);
	}

	updateValidators() {
		this.formService.updateDynamicValidators(this.form, this.dynamicValidatorsKeys);
	}

	checkIfEventIsOnline() {
		const isEventOnline = this.fromForm('isOnline').value;
		this.clearLocation();
		this.fromForm('showMap').setValue(!isEventOnline);
		this.updateValidators();
	}

	markFormAsDirty() {
		this.form.markAsDirty();
	}

	handleNoValidSchedules() {
		this.fromForm('schedules').setValidators([scheduleHasValid()]);
		this.updateValidators();
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
