import { EventOrganiserProfile } from '@app/models/profile.model';
import { EventTicketsDetails, Ticket, Currency } from '@app/models/ticket.model';
import { Schedule } from '@app/models/schedule.model';
import { SchemeID, NormalizeFields } from '@app/models/dataSchema.model';
import { UserContactInfo } from '@app/models/user.model';
import { ImageServiceType, ImageString } from '@app/services/image/image.service';
import { ProductType } from './product.model';
import { ACLItemAccess } from './url.model';
import { SelectFieldOption } from '@app/shared/form-field/select-field/select-field.model';
import { NotificationDictionary } from '@app/services/notification/notification.dictionary';
import { EventActionsConstants } from '@app/store/actions/event/event.actions.constants';
import { SetCollectInfoPropertyFailed,
	UpsertEventCollectInfoFailed,
	UpsertEventCollectInfoSuccess
} from '@app/store/actions/event/event.actions';

export enum EventPart {
	PROFILE,
	DETAILS,
	TICKETS,
	SUCCESS
}

export enum ProductCreationStep {
	StepOne = 1,
	StepTwo = 2,
	StepThree = 3,
	SuccessfullyCreated = 10,
}

export enum ProductStatus {
	Draft = 1,
	Active = 10,
	Hidden = 20,
	Finished = 30,
	AwaitingActivation = 40,
	Cancelled = 50,
}

export enum ProductStatusMapping {
	Draft = 'Draft',
	Active = 'Upcoming',
	Hidden = 'Hidden',
	Finished = 'Past',
	AwaitingActivation = 'Provisional',
	Cancelled = 'Cancelled',
}

export enum RegistrationStatus {
	Pending = 1,
	Approved = 2,
	Denied = 3,
	UpDateNeeded = 4,
}

export enum RegistrationStatusMapping {
	Pending = 'Pending',
	Approved = 'Approved',
	Denied = 'Denied',
	UpDateNeeded = 'Update Needed',
}

export enum dataType {
	ticket = 'ticket',
	registration = 'registration',
	product = 'product',
}
export enum EventStatus {
	public = 1,
	private = 2,
}

export enum EventStatusMapping {
	public = 'Public',
	private = 'Private',
}

export enum ApprovalType {
	None = 1,
	Manual = 2,
	Automatic = 3,
}
export enum RegistrationSortByEnum {
	name = 1,
	surname = 2,
	email = 3,
	status = 4,
}

export enum RegistrationOrderByEnum {
	ASC = 0,
	DESC = 1,
}

export class RegistrationFilter {
	size = 4;
	number = 1;
	sortBy = RegistrationSortByEnum.name;
	orderBy = RegistrationOrderByEnum.ASC;
	search = '';
}

export class EventRegistrationSettings {
	enabled: boolean = null;
	dateFrom: Date = null;
	dateTo: Date = null;
	checkOnTransfer: boolean = null;
	forceRegistration: boolean = null;
	sendEmailOnApproval: boolean = null;
	description: string = null;
	pendingApprovalMessage: string = null;
	approvalMessage: string = null;
	deniedMessage: string = null;
	approvalType: ApprovalType;
}

export class EventRegistration {
	id: number = null;
	name: string = null;
	surname: string = null;
	email: string = null;
	identificationNumber: string = null;
	status: RegistrationStatusMapping = null;
}

export class EventRegistrations {
	registrations: EventRegistration[];
	totalRegistrations: number = null;
}

export enum IconType {
	SVG = '.svg',
	PNG = '.png',
	JPG = '.jpg',
}

export enum PaymentStatus {
	Paid = 'Paid',
	Unpaid = 'Unpaid',
	Reserved = 'Reserved',
	Complimentary = 'Complimentary',
	Transferred = 'Transferred',
	Cancelled = 'Cancelled',
}

export enum SendInvitesMethod {
	PreviousContactList = 'Using a previous contact list',
	UploadCSVFile = 'Uploading a CSV file',
	AddContactsManually = 'Adding contacts manually',
	PreviousEvent = 'Using a previous event\'s contacts',
}

export class Venue {
	id: number = null;
	name: string = null;
	addressLine1: string = null;
	addressLine2: string = null;
	latitude = null;
	longitude = null;
	city: string = null;
	country: string = null;
	province: string = null;
	postalCode: string = null;
	longAddress: string = null;
	mapZoom = 16;
}

export class EventDetails {
	eventId: number = null;
	name: string = null;
	description: string = null;
	startDateTime: string = null;
	endDateTime: string = null;
	schedules: Schedule[] = [];
	isOneDayEvent = true;
	showMap = false;
	isOnline = false;
	image: EventImage = null;
	timezone: string = null;
	venue: Venue = new Venue();
	isPublic: boolean = null;
	ticketStubDescription: string = null;
	hasVendors: boolean = null;
	hasSoldTickets: boolean = null;
	commission: number = null;
	taxRateId: number = null;
	serviceFee: number = null;
	isEventFinished: boolean = null;
	formatId: number = null;
	categoryId: number = null;
	subCategoryId: number = null;
	productType: ProductType = null;
	indefinite = false;
	eventFeatureOptions: EventFeatureOptions[] = [];
	eventFeatureAnswers: EventFeatureAnswers[] = [];
	originalImage: string  = null;
	imageString: ImageString | string  = null;
	thumbnail: string  = null;
	croppedImage: ImageString | string  = null;
	hasOnlineContent = false;
	eventEndDateChanged = false;
	fundraiserCertificate: FundraiserCertificate = null;
	pdfLogoUrl: string = null;
	displayBannerInEmails: boolean = null;
	lockedOnPrivate: boolean = null;
	creationStep: ProductCreationStep = null;
}

export class FundraiserCertificate {
	base64: string = null;
	fileName: string = null;
	thumbUrl: string = null;
}

export class EventFeatureOptions {
	id: SchemeID;
	name: string;
	options: string[];
	mandatory: boolean;
	featured: boolean;
	answer: string;
}

export class EventFeatureAnswers {
	id: SchemeID;
	answer: string;
}

export interface EventDashboardScheduleItem {
	id: SchemeID;
	from: Date;
	to: Date;
	isCancelled?: boolean;
}

export interface EventDashboard {
	id: SchemeID;
	name: string;
	productStatus: ProductStatus;
	hasPaidTickets: boolean;
	commissionForHost: boolean;
	commission: number;
	startDate: string;
	endDate: string;
	payouts: number;
	hasSoldTickets: boolean;
	bankName: string;
	isLinkedToPaymentGateway: boolean;
	areAllTicketsFree: boolean;
	autoApproveEvents: boolean;
	serviceFeeForHost: boolean;
	eventLink: string;
	currency: Currency;
	ianaTimezone: string;
	isPublic: boolean;
	shortLink?: string;
	// ticketsTurnover: number;
	isScheduledEvent: boolean;
	scheduleItems: EventDashboardScheduleItem[];
	creationStep: ProductCreationStep;
	collectionsEnabled: boolean;
	productType: ProductType;
	isPublishedToFacebook: boolean;
	stripeConnectUrl: string;
	payoutDate: string;
	scheduledToGoPublicOn: Date;
	onlineContentNeeded: boolean;
	streamingNoLinkAttached: boolean;
	hasVenue: boolean;
	covidComplianceEnabled: boolean;
	organiserProfileLink: string;
	userActiveEvents: UserActiveEvents[];
	isModalOpen: boolean;
	redirectProductId: number;
	lockedOnPrivate: boolean;
	bannerUrl: string;
	eventSetupProgress: EventSetupProgress;
	totalGuests: DashboardTotalGuests;
}

export class EventSetupProgress {
	categories: EventSetupProgressItem;
	bannerImage: EventSetupProgressItem;
	bankAccount: EventSetupProgressItem;
	collectInfo: EventSetupProgressItem;
}

export class EventSetupProgressItem {
	id: number;
	isCompleted: boolean;
}

export class EventSetupProgressDisplayItem {
	id: number;
	text: string;
	buttonText: string;
	isCompleted: boolean;
	link: string;
}

export class DashboardTotalGuests {
	total: number;
	maxCapacity: number;
}

export class CovidComplianceEnabled {
	covidComplianceEnabled = false;
	hasEntryRequirements: boolean = null;
	entryRequirements: string[] = [];
}

export enum CovidEntryRequirements {
	ProofOfCovid = 'Proof of having Covid in the past 3 months',
	AntibodyTest = 'Provide a Covid antibody test', // not currently needed
	NegativeCovidTest = 'Provide a negative Covid test no more than 72 hours old',
	ProofOfFirstVaccine = 'Proof of receiving the first vaccination dose',
	OnsiteTesting = 'Agree to onsite Covid test at the event',
	VaccineCertificate = 'Covid Vaccination Certificate',
}

export class EventScheduledPublicDate {
	scheduledToGoPublicOn: Date = null;
}

export interface EventDetailsSettings {
	id: SchemeID;
	allowSms: boolean;
	allowTicketEditing: boolean;
	allowTicketTransfer: boolean;
	lockTransferredTickets: boolean;
	cutOffTransfers: boolean;
	allowCopyFromAbove: boolean;
	allowSharing: boolean;
	showIncentive: boolean;
	expressCheckout: boolean;
	incentiveMessage: string;
	purchaseSuccessMessage: string;
	purchaseTimeLimit: number;
	eftDaysToPay: number;
	alertMessage: string;
	allowPrivateResale: boolean;
	allowPublicResale: boolean;
}

export class DraftDetailsSettingsForm implements EventDetailsSettings {
	id = null;
	allowSms = false;
	allowTicketEditing = false;
	allowTicketTransfer = false;
	lockTransferredTickets = false;
	cutOffTransfers = false;
	allowCopyFromAbove = false;
	allowSharing = false;
	showIncentive = false;
	expressCheckout = false;
	incentiveMessage = null;
	purchaseSuccessMessage = null;
	purchaseTimeLimit = null;
	eftDaysToPay = null;
	alertMessage = null;
	allowPrivateResale = true;
	allowPublicResale = false;
}

export class EventEntity {
	profile?: EventOrganiserProfile = new EventOrganiserProfile();
	details?: EventDetails = new EventDetails();
	ticketsDetails?: EventTicketsDetails = new EventTicketsDetails();
}

export interface EventQuestion {
	id: number;
	question: string;
	profileQuestionId: SchemeID;
	enabled: boolean;
	required: boolean;
}

export interface EventCollectInfoBase {
	id: SchemeID;
	question: string;
	type: EventCollectInfoType;
	// choice
	choiceOptions: string[];
	// small text
	textboxWidth: EventCollectInfoTextboxWidth;
	textboxValidation: EventCollectInfoTextboxValidation;
	textboxRegEx: string;
}

export interface EventCollectInfo extends EventCollectInfoBase {
	profileQuestionId: SchemeID;
	enabled: boolean;
	required: boolean;
	applyToBuyer: boolean;
	showOnInvoice: boolean;
	showOnTicket: boolean;
	showOnCheckin: boolean;
	includeOnTransferredTickets: boolean;
	selectedTickets: SchemeID[];
	isUnique: boolean;
	// date
	minAge: number;
	maxAge: number;
	// small text
	textboxRegExMessage: string;
	// terms
	termsAndCond: string;
	// file
	fileValidation: EventCollectInfoFileValidation;
	// choice
	isOtherOption: boolean;
	allowEditing: boolean;
	isRegistrationQuestion: boolean;
}

export interface EventCollectInfoTicket {
	id: SchemeID;
	name: string;
}

export enum EventCollectInfoType {
	SmallTextField = 1,
	LargeTextField = 2,
	RadioButtonList = 3,
	CheckBoxList = 4,
	DropDownList = 5,
	Waiver = 6, // terms and cond
	DateField = 7,
	SectionHeading = 8,
	Address = 9,
	Country = 10,
	FileUpload = 11,
}

export enum DefaultQuestionType {
	IdPassport = 4,
	DateOfBirth = 5,
	Company = 7,
	Size = 8,
	TwitterHandle = 9,
	Gender = 10,
	Address = 11,
	Organisation = 12,
	Telephone = 13,
	Country = 14,
	FutureContact = 15,
	Name = 48,
	Message = 49,
}

export enum EventCollectInfoTextboxWidth {
	Default = null,
	Small = 1,
	Medium = 2,
	Large = 3,
}

export enum EventCollectInfoTextboxValidation {
	None = null,
	Number = 1,
	Email = 2,
	Url = 3,
	RegExp = 4,
}

export enum EventCollectInfoFileValidation {
	Document = 1,
	Image = 2,
	Either = 3,
}

export interface EventImage {
	itemId: number;
	itemTypeId: number;
	userId: number;
	dateAdded: string;
	mimeType: string;
	contentLength: number;
	url: string;
	thumbUrl: string;
	id: number;
}

export interface EventEntityPayload {
	event: {
		details: EventDetails;
		profile: EventOrganiserProfile;
		ticketsDetails: SchemeID;
	};
	ticket: Ticket;
	ticketsDetails: EventTicketsDetails;
}

export interface Timezone {
	name: string;
	title: string;
	offset: string;
}

export class EventDatesChange {
	startDateTime: Date = null;
	endDateTime: Date = null;
}

export type NormalizedEventEntityPayload = NormalizeFields<EventEntityPayload>;

export interface EventFormat {
	id: SchemeID;
	name: string;
}

export interface EventCategory<C = EventSubcategory> {
	id: SchemeID;
	name: string;
	subcategories: C[];
}

export interface EventSubcategory {
	id: SchemeID;
	name: string;
}

export enum EventDashboardModal {
	EventListing = 'eventListing',
	ServiceFee = 'serviceFee',
	Approval = 'approval',
	Cancellation = 'cancellation',
	DatesChanging = 'datesChanging',
	SchedulePublic = 'schedulePublic',
	DetelePublicSchedule = 'deletePublicSchedule',
	CovidCompliant = 'covidCompliant',
}

export class UserContactInfoForm implements UserContactInfo {
	firstName: string = null;
	surname: string = null;
	phone: string = null;
}

export interface UserPreviousContactList {
	id: SchemeID;
	name: string;
	contactsCount: number;
}

export class UserInviteContact {
	name = '';
	surname = '';
	email = '';
}

export interface EventFacebookData {
	id: SchemeID;
	facebookEventId: SchemeID;
	name: string;
	startDate: Date;
	endDate: Date;
	venue: Venue;
	description: string;
	image: EventImage;
	isPublic: boolean;
	facebookPage: string;
}

export class EventFacebookDataForm implements EventFacebookData {
	facebookEventId = null;
	name = null;
	startDate = null;
	endDate = null;
	venue = null;
	description = null;
	image = null;
	id = null;
	isPublic = false;
	imageString: string = null;
	facebookPage = null;
}

export interface FacebookUser {
	id: string;
	name: string;
	email: string;
	imageUrl: string;
	token: string;
	arePermissionsGranted: boolean;
	connected: boolean;
}

export interface FacebookUserEvent {
	name: string;
	id: string;
}

export interface FacebookUserPage {
	id: string;
	name: string;
	access_token: string;
}
export enum EventTabType {
	/* eslint-disable @typescript-eslint/no-shadow */
	Schedule = 1,
	MixedContent = 2,
	Content = 3,
	News = 4,
	ImageGallery = 5,
	Attributes = 6,
}

export interface EventTabContent {
	id: SchemeID;
	name: string;
	description: string;
	imageString: ImageString;
	originalImage: string;
	thumbnail: string;
	dateFrom: Date;
	dateTo: Date;
	isVisible: boolean;
	sortOrder: number;
	contentUid?: number;
	pictureId: number;
}

export interface EventTab {
	id: SchemeID;
	name: string;
	type: EventTabType;
	isVisible: boolean;
	content: EventTabContent[];
}

export interface EventTicketGroups {
	ticketGroups: EventTicketGroup[];
	ticketTypes: EventTicketGroupTicketType[];
	useTicketGroups: boolean;
}

export interface EventTicketGroup<T = SchemeID> {
	id: SchemeID;
	name: string;
	description: string;
	sortOrder: number;
	thumbnail: string;
	isImageShown: boolean;
	isExpanded: boolean;
	imageString: ImageString | string;
	linkedTicketTypes: T[];
}

export interface EventTicketGroupTicketType {
	id: SchemeID;
	name: string;
}

export interface EventLinkCampaign {
	id: SchemeID;
	name: string;
	campaignUrl: string;
	eventLink: string;
	dateAdded: string;
	visits: number;
	sales: number;
	tickets: number;
	currencyCode: string;
}

export interface TopLinkCampaigns {
	productId: number;
	eventName: string;
	campaignName: string;
	dateAdded: string;
	link: string;
	productType: ProductType;
}

export interface EventDesign {
	id: SchemeID;
	originalImage: string;
	imageString: ImageString | string;
	thumbnail: string;
	color: string;
	displayBannerInEmails: boolean;
}

export class DraftEventDesignForm implements EventDesign {
	id = null;
	imageString = null;
	color = null;
	originalImage = null;
	thumbnail = null;
	displayBannerInEmails = false;
}

export class EventDefinitions {
	id: SchemeID = null;
	event: string = null;
	events: string = null;
	ticket: string = null;
	tickets: string = null;
	buyer: string = null;
	register: string = null;
	usePromoCode: string = null;
	enterPromoCode: string = null;
	buttonText: string = null;
	howToBookDesktop: string = null;
	howToBookMobile: string = null;
	chooseYourSeats: string = null;
	selectYourMerchandise: string = null;
}

export class EventOnlineStreaming {
	settings: EventOnlineStreamingSettings;
	streams: EventOnlineStreamingRooms[];
	ticketTypes: EventStreamingTicketTypes[];
	eventHasBegun: boolean;
}

export class EventOnlineStreamingSettings {
	timeBeforeType: string;
	timeBefore: number;
	notifyAttendees: boolean;
	showCollectionsOnStream: boolean;
	currencyCode: string;
	includeChat: boolean;
	livestreamUrl: string;
	showLivestreamCount: boolean;
	videoStreamingCost: number;
	videoStorageCost: number;
	videoEncodingCost: number;
	streamPageMessage: string;
	organiserCode: string;
	bannerOriginalImage: string;
	bannerImageString: ImageString | string;
	bannerThumbnail: string;
	logoOriginalImage: string;
	logoImageString: ImageString | string;
	logoThumbnail: string;
	maxCodeUses: number;
}

export class EventOnlineStreamingRooms {
	// General
	id: SchemeID;
	name: string;
	enabled: boolean;
	type: string;
	description: string;
	ticketLinks: SchemeID[];
	isGated: boolean;
	imageString: string;
	streamThumbnailUrl: string;
	streamUrl: string;
	hideFrom: string;
	hideTo: string;
	// Provide a link
	url: string;
	embed: boolean;
	instructions: string;
	displayDirectLink: boolean;
	linkUnknown: boolean;
	// Quicket Hosted Streaming
	publishLogin: string;
	publishName: string;
	publishPassword: string;
	publishUrl: string;
	convertStreamToVideo: boolean;
	// Upload video file
	uploadId: SchemeID;
	thumbnailUrl: string;
}

export class EventStreamingTicketTypes {
	id: SchemeID;
	name: string;
}

export class EventStreamingMuxUpload {
	uploadId: SchemeID;
	url: string;
	streamId: SchemeID;
}

export class EventStreamingThumbnail {
	url: string;
}

export class ProductOnlineStreamingRooms {
	streamId: SchemeID;
	name: string;
	enabled: boolean;
	type: string;
	description: string;
	ticketLinks: SchemeID[];
	isGated: boolean;
	url: string;
	linkUnknown: boolean;
	embed: boolean;
	instructions: string;
	displayDirectLink: boolean;
	publishLogin: string;
	publishName: string;
	publishPassword: string;
	publishUrl: string;
	convertStreamToVideo: boolean;
	uploadId: SchemeID;
	thumbnailUrl: string;
	imageString: string;
	streamThumbnailUrl: string;
	streamUrl: string;
	hideFrom: string;
	hideTo: string;
}

export class EventCollections {
	id: SchemeID = null;
	title: string = null;
	enabled = false;
	description: string = null;
	hasStreamingEnabled = false;
	showOnStreamingPage = false;
	buttonText = 'DONATE';
	imageString: ImageString | string = null;
	thumbnail: string = null;
	currencyCode: string = null;
	widgetCode: string = null;
	collectPageUrl: string = null;
	collectionPriceOptions = [20, 50, 100];
	priceOptionsEnabled = true;
	productType: ProductType = null;
	allowCollections = true;
}

export class EventWidget {
	id: SchemeID = null;
	widgetCode: string = null;
}

export class EventScheduleTransfer {
	scheduleItems: EventScheduleTransferOptions[];
	settings: EventScheduleTransferSettings;
}

export class EventScheduleTransferOptions {
	id: SchemeID;
	dateFrom: Date;
	dateTo: Date;
	validTicketCount: number;
	dateHasPassed: boolean;
	hidden: boolean;
	scheduleItemName: string;
	isCancelled?: boolean;
}

export class EventScheduleTransferSettings {
	moveFromId: SchemeID = null;
	moveToId: SchemeID = null;
	isSeated = false;
	reason: string = null;
	confirmTransfer = false;
}

export class EventScanner {
	id: SchemeID = null;
	productId: SchemeID = null;
	scannerName: string = null;
	scannerPin: string = null;
	shortLink: ShortLink = null;
}

export class ShortLink {
	id: SchemeID = null;
	ShortLink: string = null;
	FullLink: string = null;
	DateAdded: string = null;
	ItemId: number = null;
	ScheduleItemId: number = null;
	UserId: number = null;
}
export class EventWebhooks {
	checkoutStarted: string = null;
	checkoutCancelled: string = null;
	checkoutCompleted: string = null;
	eftPending: string = null;
}

export class TestEventWebhook {
	url: string = null;
	responseCode: string = null;
	action: string = null;
}

export class UserActiveEvents {
	id: SchemeID = null;
	name: string = null;
}

export class RedirectProductId {
	id: SchemeID = null;
}

export class EventAtTheDoor {
	eventId: SchemeID;
	shortUrl: string = null;
	url: string = null;
}
export interface PromotersPageInfo {
	totalTicketsSold: number;
	totalVisits: number;
	totalSalesValue: number;
	currencySymbol: string;
	currencyISO: string;
	incentiveMessage: string;
	promoters: PromoterInfo[];
}

export class PromoterNetworksPageInfo {
	totalVisits = 0;
	mostInfluence = 'N/A';
	totalTicketsSold = 0;
	totalSalesValue = 0;
	currencySymbol = '';
	currencyISO = '';
	incentiveMessage = '';
	networks: PromoterNetworkInfo[] = [];
}

export class EventOrder {
	orderId: SchemeID = null;
	dateAdded: string = null;
	email: string = null;
	amount: number = null;
	currency = {
		symbol: '',
		iso: '',
	};
	paymentState: string = null;
	source: string = null;
	orderDetailsUrl: string = null;
	cartId: SchemeID = null;
}

export class EventOrdersList {
	id: SchemeID = null;
	results: EventOrder[] = null;
	pageSize: number = null;
	pages: number = null;
	totalRecords: number = null;
}

export class EventOrderFilter {
	dateFrom: string = null;
	dateTo: string = null;
	search: string;
	paidStatus = 'ANY';
	scheduleItemId: SchemeID = 0;
	page = 1;
	pageSize = 10;
	sortCol = 'DateAdded';
	sortDir = 'DESC';
}


export interface PromoterInfo {
	name: string;
	numberOfClicks: number;
	numberOfTickets: number;
	salesValue: number;
}

export interface PromoterNetworkInfo {
	name: string;
	totalMembers: number;
	totalVistis: number;
	totalSales: number;
	totalTickets: number;
	commission: number;
	referralAmount: number;
	deleted: boolean;
	totalPaidOut: number;
	id: number;
	notes: string;
	isPublic: boolean;
	encrypt: string;
	shortLink: string;
}

export interface NewPromoterNetworkInfo {
	name: string;
	referralAmount: number;
	additionalNotes: string;
	openToPublic: boolean;
	id?: number;
	deleted: boolean;
}

export interface IncentiveMessage {
	message: string;
}

export class GuestInfo {
	guestName: string = null;
	ticketType: string = null;
	guestEmail: string = null;
	purchaseId: number = null;
	purchaseItemId: number = null;
	guestContactNumber: number = null;
}

export class ListResultInfo{
	results: GuestInfo[] = null;
	records: number = null;
}

export class SelectedGuestInfo{
	orderReference: string = null;
	ticketReference: string = null;
	seat: string = null;
	questions: Question[] = null;
}

export class Question{
	question: string = null;
	answer: string = null;
}
export class GuestListRequestInfo{
	eventId: SchemeID = null;
	pageSize?: number = 10;
	startingResultIndex?: number = 0;
	sortDirection?: string = 'ASC';
	sortCol?: number = 0;
	search?: string;
	scheduleItemId?: SchemeID = 0;
}

export class ResendTicketsResult{
	purchaserEmail: string;
}

export enum GuestListSortBy {
	guestName = 0,
	ticketType = 1,
	guestEmail = 2,
}

export enum GuestListSortOrder {
	'asc' = 1,
	'desc' = 0,
}

export enum GuestListReportType {
	guestList = 'GUEST LIST',
	rsvp = 'RSVP',
	seating = 'SEATING',
	print = 'PRINT',
}

export enum GuestListReportFormatType {
	excel = 'EXCEL',
	csv = 'CSV',
}

export const tabContentTypeMap = new Map([
	[EventTabType.Content, 'Content'],
	[EventTabType.Attributes, 'Attribute'],
	[EventTabType.News, 'News'],
	[EventTabType.ImageGallery, 'Image Gallery'],
	[EventTabType.Schedule, 'Schedule'],
	[EventTabType.MixedContent, 'Mixed Content'],
]);

export enum TabbedContentPageView {
	view = 'VIEW',
	add = 'ADD',
	edit = 'EDIT',
}

export type DraftEventTab = EventTab;
export type DraftEventTabContent = EventTabContent;

export class DraftEventTabForm implements DraftEventTab {
	id = null;
	name = null;
	type = null;
	isVisible = true;
	content = [];
}

export class DraftEventTabContentForm implements DraftEventTabContent {
	id = null;
	name = null;
	description = null;
	originalImage = null;
	imageString = null;
	thumbnail = null;
	dateFrom = null;
	dateTo = null;
	isVisible = true;
	sortOrder = null;
	contentUid?: number = null;
	pictureId = null;
}

export interface TabContentImage {
	eventId: SchemeID;
	imageType: ImageServiceType;
	imageData: FormData;
	replace: boolean;
	imageString: string | ArrayBuffer;
}

export type DraftEventLinkCampaign = EventLinkCampaign;
export class DraftEventLinkCampaignForm implements DraftEventLinkCampaign {
	id = null;
	name = null;
	campaignUrl = null;
	eventLink = null;
	dateAdded = null;
	visits = null;
	sales = null;
	tickets = null;
	currencyCode = null;
}

export enum CreateEdit {
	Create = 'CREATE',
	Edit = 'EDIT',
}

export class EventQuestionToggle {
	name: keyof EventCollectInfo;
	id: SchemeID;
	value: boolean;
	prevValue: boolean;
}

export enum CollectInfoPageMode {
	List = 'List',
	Create = 'Create',
	Edit = 'Edit',
}

export enum RegistrationPageMode {
	Settings = 'Settings',
	Questions = 'Questions',
	AddEdit = 'AddEdit',
}

export type DraftEventCollectInfo = EventCollectInfo;

export class DraftEventCollectionBase implements EventCollectInfoBase {
	id: SchemeID = null;
	question: string = null;
	type: EventCollectInfoType = null;
	choiceOptions: string[] = [];
	textboxWidth: EventCollectInfoTextboxWidth = null;
	textboxValidation: EventCollectInfoTextboxValidation = null;
	textboxRegEx: string = null;
}

export class DraftEventCollectInfoForm implements DraftEventCollectInfo {
	id: SchemeID = null;
	question: string = null;
	profileQuestionId: SchemeID = null;
	enabled = true;
	required = true;
	type: EventCollectInfoType = null;
	applyToBuyer = false;
	showOnInvoice = false;
	showOnTicket = false;
	showOnCheckin = false;
	includeOnTransferredTickets = true;
	selectedTickets: SchemeID[] = [];
	isUnique = false;
	// date
	minAge: number = null;
	maxAge: number = null;
	// small text
	textboxWidth: EventCollectInfoTextboxWidth = null;
	textboxValidation: EventCollectInfoTextboxValidation = null;
	textboxRegEx: string = null;
	textboxRegExMessage: string = null;
	// terms
	termsAndCond: string = null;
	// file
	fileValidation: EventCollectInfoFileValidation = EventCollectInfoFileValidation.Document;
	// choice
	isOtherOption = false;
	choiceOptions: string[] = [];
	allowEditing: true;
	isRegistrationQuestion = false;
}

export class EventCopySeatingChartRequest {
	oldChartKey: string;
	newChartName: string;
	productId: SchemeID;
}

export class EventCopySeatingChartResult {
	message: string;
	success: boolean;
}

export const eventQuestionTypeMap = new Map([
	[EventCollectInfoType.SmallTextField, 'Textbox field (single line)'],
	[EventCollectInfoType.LargeTextField, 'Text area field (multiple lines)'],
	[EventCollectInfoType.RadioButtonList, 'Radio button list'],
	[EventCollectInfoType.CheckBoxList, 'Checkbox list'],
	[EventCollectInfoType.DropDownList, 'Drop down list'],
	[EventCollectInfoType.Waiver, 'Terms and Conditions'],
	[EventCollectInfoType.DateField, 'Date field'],
	[EventCollectInfoType.SectionHeading, 'Section heading'],
	[EventCollectInfoType.Address, 'Address'],
	[EventCollectInfoType.Country, 'Country'],
	[EventCollectInfoType.FileUpload, 'File upload'],
]);

export const collectInfoTextboxWidthMap = new Map([
	[EventCollectInfoTextboxWidth.Default, 'Default'],
	[EventCollectInfoTextboxWidth.Small, 'Small'],
	[EventCollectInfoTextboxWidth.Medium, 'Medium'],
	[EventCollectInfoTextboxWidth.Large, 'Large'],
]);

export const collectInfoTexboxValidationMap = new Map([
	[EventCollectInfoTextboxValidation.None, 'None'],
	[EventCollectInfoTextboxValidation.Number, 'Number'],
	[EventCollectInfoTextboxValidation.Email, 'Email'],
	[EventCollectInfoTextboxValidation.Url, 'URL'],
	[EventCollectInfoTextboxValidation.RegExp, 'Regular Expression'],
]);

export const collectInfoFileValidationMap = new Map([
	[EventCollectInfoFileValidation.Document, 'Document'],
	[EventCollectInfoFileValidation.Image, 'Image'],
	[EventCollectInfoFileValidation.Either, 'Either'],
]);

export const collectInfoChoiceTypeOptions: SelectFieldOption[] = [
	{
		label: eventQuestionTypeMap.get(EventCollectInfoType.CheckBoxList),
		value: EventCollectInfoType.CheckBoxList,
	},
	{
		label: eventQuestionTypeMap.get(EventCollectInfoType.DropDownList),
		value: EventCollectInfoType.DropDownList,
	},
	{
		label: eventQuestionTypeMap.get(EventCollectInfoType.RadioButtonList),
		value: EventCollectInfoType.RadioButtonList,
	},
	{
		label: eventQuestionTypeMap.get(EventCollectInfoType.Country),
		value: EventCollectInfoType.Country,
	},
];

export const collectInfoTextboxWidthOptions: SelectFieldOption[] = [
	{
		label: collectInfoTextboxWidthMap.get(EventCollectInfoTextboxWidth.Default),
		value: null,
	},
	{
		label: collectInfoTextboxWidthMap.get(EventCollectInfoTextboxWidth.Small),
		value: EventCollectInfoTextboxWidth.Small,
	},
	{
		label: collectInfoTextboxWidthMap.get(EventCollectInfoTextboxWidth.Medium),
		value: EventCollectInfoTextboxWidth.Medium,
	},
	{
		label: collectInfoTextboxWidthMap.get(EventCollectInfoTextboxWidth.Large),
		value: EventCollectInfoTextboxWidth.Large,
	},
];

export const collectInfoTexboxValidationOptions: SelectFieldOption[] = [
	{
		label: collectInfoTexboxValidationMap.get(EventCollectInfoTextboxValidation.None),
		value: null,
	},
	{
		label: collectInfoTexboxValidationMap.get(EventCollectInfoTextboxValidation.Number),
		value: EventCollectInfoTextboxValidation.Number,
	},
	{
		label: collectInfoTexboxValidationMap.get(EventCollectInfoTextboxValidation.Email),
		value: EventCollectInfoTextboxValidation.Email,
	},
	{
		label: collectInfoTexboxValidationMap.get(EventCollectInfoTextboxValidation.Url),
		value: EventCollectInfoTextboxValidation.Url,
	},
	{
		label: collectInfoTexboxValidationMap.get(EventCollectInfoTextboxValidation.RegExp),
		value: EventCollectInfoTextboxValidation.RegExp,
	},
];

export const collectInfoFileValidationOptions: SelectFieldOption[] = [
	{
		label: collectInfoFileValidationMap.get(EventCollectInfoFileValidation.Document),
		value: EventCollectInfoFileValidation.Document,
	},
	{
		label: collectInfoFileValidationMap.get(EventCollectInfoFileValidation.Image),
		value: EventCollectInfoFileValidation.Image,
	},
	{
		label: collectInfoFileValidationMap.get(EventCollectInfoFileValidation.Either),
		value: EventCollectInfoFileValidation.Either,
	},
];


export const collectInfoPropertyNameMap = new Map<keyof EventCollectInfo, (active: boolean) => string>([
	['enabled', active => active ? 'enabled' : 'disabled'],
	['required', active => active ? 'required' : 'optional'],
]);

export class CollectInfoNotificationDictionary extends NotificationDictionary {
	source = {
		[EventActionsConstants.DELETE_COLLECT_INFO_SUCCESS]: () => ({
			title: 'Question succesfully deleted',
		}),
		[EventActionsConstants.DELETE_COLLECT_INFO_FAILED]: () => ({
			title: 'Oops! We can\'t delete this question',
		}),
		[EventActionsConstants.SET_COLLECT_INFO_PROPERTY_FAILED]: ({ payload: { action: { payload } } }: SetCollectInfoPropertyFailed) => ({
			title: `Oops! We can't set question as ${collectInfoPropertyNameMap.get(payload.name)(payload.value as boolean)}`,
		}),
		[EventActionsConstants.UPSERT_EVENT_COLLECT_INFO_FAILED]: ({ payload: { action: { payload } } }: UpsertEventCollectInfoFailed) => ({
			title: `Oops! We can't ${payload.isUpdating ? 'update' : 'add'} question`,
		}),
		[EventActionsConstants.UPSERT_EVENT_COLLECT_INFO_SUCCESS]: (action: UpsertEventCollectInfoSuccess) => ({
			title: `Question successfully ${action.payload.isUpdating ? 'updated' : 'added'}`,
		}),
		[EventActionsConstants.GET_PROFILE_COLLECT_INFO_FAILED]: () => ({
			title: 'Oops! We can\'t get profile questions',
		}),
		[EventActionsConstants.REORDER_COLLECT_INFO_FAILED]: () => ({
			title: 'Oops! We can\'t reorder questions',
		}),
	};
}

export enum OnlineContentPageView {
	view = 'VIEW',
	addEdit = 'ADD/EDIT'
}

export const addEditOnlineContentOptions = new Map<string, string>([
	['Provide a Link', 'Url'],
	['Quicket Hosted Streaming', 'Mux'],
	['Upload Video File', 'Upload'],
]);

export interface UserEventAccess {
	isAccessGranted: boolean;
	accessType?: ACLItemAccess;
}

export class TabbedContentImageUploadSuccess {
	eventId: number | SchemeID;
	image: EventImage;
	tabContentUid: number;
	imageString: string;
}

export interface EventCurrency {
	value: EventCurrencyValue;
	title: string;
}

export interface EventCurrencyValue {
	id: SchemeID;
	code: string;
	defaultCommission: number;
	defaultServiceFee: number;
}

export interface EventCurrencyOption {
	value: {
		id: SchemeID;
		code: string;
	};
	title: string;
}

export class ScheduleEarliestLatestDates{
	earliest: string;
	latest: string;
}

export enum ScheduleStartEndTimes{
	start = 'startTime',
	end = 'endTime'
}

export type DraftEventTicketGroup = EventTicketGroup<DraftTicketGroupTicketType>;

export interface DraftTicketGroupTicketType extends EventTicketGroupTicketType {
	checked: boolean;
}

export class DraftTicketGroupTicketTypeForm implements DraftTicketGroupTicketType {
	id: SchemeID = null;
	name: string = null;
	checked = false;
}

export class DraftEventTicketGroupForm implements DraftEventTicketGroup {
	id: SchemeID = null;
	name: string = null;
	description: string = null;
	sortOrder: number = null;
	isImageShown = true;
	isExpanded = true;
	imageString: ImageString | string = null;
	thumbnail: string = null;
	linkedTicketTypes: DraftTicketGroupTicketType[] = [];
}

export class TicketGroupImage {
	imageString: string;
	thumbnail: string;
}

export interface TicketType {
	id: number;
	name: string;
}

export enum OrderModalType {
	CancelTickets,
	ChangeTicketTypes,
}
