<div class="l-container" *ngIf="!isPageLoading; else pageLoading">
	<div class="l-content l-header-content" [ngClass]="{ 'mobile': isMobile }">
		<app-web-content-header 
			class="l-header"
			[headerContent]="headerContent"
			[fullWidth]="true"
			[showBreadCrumb]='false'
			*ngIf="!isMobile"
		></app-web-content-header>
		<p class="m-0"> If you need help running the door at your event, Quicket may be able
			to provide you with equipment and staff. </p>
		<p> Please email <span class="g-green"> fieldservices@quicket.co.za </span>
			for enquiries regarding Quicket Field Services. </p>
	</div>
	<div class="l-content">
		<div class="l-page-content" [ngClass]="{ 'mobile': isMobile }">
			<img class="l-image" src=".\assets\placeholders\field-services.png">
			<h3>Please Note</h3>
			<p class="l-description" [ngClass]="{ 'mobile': isMobile }">
				Quicket field services are only available in certain areas.
				Please note that prices are indicative and non-binding, for an official quote please email us at
				<span class="g-green"> fieldservices@quicket.co.za </span></p>
			<div class="l-download-button-container" [ngClass]="{ 'mobile': isMobile }">
				<button class="g-primary-button l-download-button" id="download-button"
				(click)="handleDownload()">DOWNLOAD PRICE LIST</button>
			</div>
		</div>
	</div>
</div>
<ng-template #pageLoading>
	<div class="l-page-loader">
		<mat-spinner></mat-spinner>
	</div>
</ng-template>
