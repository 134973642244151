import { Routes } from '@angular/router';
import { AppComponent } from '@app/core/app/app.component';
import { eventRoutes, organiserDashboardRoutes } from '@app/router/event-routing/event-routing.config';
import { EventCreationComponent } from '@app/features/event-creation/event-creation.component';
import { AppGuard } from '@app/services/guards/app.guard';
import { CanDeactivateEventPage, CanActivateEventChild } from '@app/services/guards/event.guard';
import { EventComponent } from '@app/core/event/event.component';
import { defaultDashboardRoute, defaultUserDashboardRoute } from '@app/services/url/url.service';
import { UserComponent } from '@app/core/user/user.component';
import { userRoutes } from '../user-routing/user-routing.config';
import { ProductRoute } from '@app/models/url.model';
import { OrganiserHubComponent } from '@app/features/organiser-hub/pages/organiser-hub/organiser-hub.component';
import { EventNavigationComponent } from '@app/features/events/components/event-navigation/event-navigation.component';

export const routes: Routes = [
	{
		path: 'account',
		component: AppComponent,
		canActivate: [AppGuard],
		children: [
			{
				path: '',
				component: UserComponent,
				children: userRoutes,
				data: { productType: ProductRoute.USER },
			},
			{
				path: 'event',
				component: EventComponent,
				children: [
					{
						path: 'new',
						component: EventCreationComponent,
						canDeactivate: [CanDeactivateEventPage],
						data: { productType: ProductRoute.EVENT },
					},
					{
						path: ':id',
						component: EventNavigationComponent,
						children: eventRoutes,
						canActivateChild: [CanActivateEventChild],
					},
					{
						path: '**',
						redirectTo: defaultDashboardRoute,
					},
				],
			},
			{
				path: 'organiser-hub',
				component: EventComponent,
				children: [
					{
						path: '',
						component: OrganiserHubComponent,
						children: organiserDashboardRoutes,
					},
				],
			},
			{
				path: '**',
				redirectTo: defaultDashboardRoute,
			},
		],
	},
	{
		path: '',
		redirectTo: defaultUserDashboardRoute,
		pathMatch: 'full',
	},
];
