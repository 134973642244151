import { UserActionsConstants } from '@app/store/actions/user/user.actions.constants';
import { Actions } from '@app/store/actions/user/user.actions';
import { UserChartsState } from '@app/models/store.model';

export const initialState: UserChartsState = {
	all: [],
	isFetching: false,
};


export const userChartsReducer = (
	state = initialState,
	action: Actions
): UserChartsState => {
	switch (action.type) {
		case UserActionsConstants.GET_USER_CHARTS_SUCCESS:
			return {
				isFetching: false,
				all: action.payload.charts,
			};
		case UserActionsConstants.GET_USER_CHARTS_FAILED:
			return {
				...state,
				isFetching: false,
			};
		case UserActionsConstants.GET_USER_CHARTS:
			return {
				isFetching: true,
				all: [],
			};
		default:
			return state;
	}
};
