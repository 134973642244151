import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import config from '@app/config';
import { SchemeID } from '@app/models/dataSchema.model';
import { EventImage, TabContentImage } from '@app/models/event.model';
import { NotificationType } from '@app/models/notification.model';
import { ImageFileSize } from '@app/models/shared';
import { ImageServiceType } from '@app/services/image/image.service';
import { StoreService } from '@app/services/store/store.service';
import { EventActionsConstants } from '@app/store/actions/event/event.actions.constants';
import { AddNotification } from '@app/store/actions/notification/notification.actions';
import { FileTypes } from '@app/utils/consts';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs';

@Component({
	selector: 'app-image-upload',
	templateUrl: './image-upload.component.html',
	styleUrls: ['./image-upload.component.sass'],
})
export class ImageUploadComponent implements OnInit, OnChanges {
	@Input() eventId: number | SchemeID;
	@Input() label = 'Upload Image';
	@Input() maxFileSize: ImageFileSize;
	@Input() image: EventImage;
	@Input() imageServiceType: ImageServiceType;
	@Input() preferredRes = '800x800';
	@Input() showPreferredResText = true;
	@Input() uploadText = '';
	@Input() showRemoveText = true;
	@Input() isMobile = false;
	@Input() isRound = false;
	@Input() aspectRatio = '1/1';
	@Input() maxHeight = '230px';
	@Input() imagePath: string;
	@Input() isUploading = false;
	@Input() handleTabContent = false;
	@Input() allowedFileTypes = FileTypes.image;
	@Input() customPreferredResText = false;
	@Input() tooltip = '';
	@Input() customUpload = false;
	@Input() showPreviewImage = true;
	@Input() handleTicketGroups = false;
	@Output() uploadHandler: EventEmitter<EventImage> = new EventEmitter<EventImage>();
	@Output() removeHandler: EventEmitter<EventImage> = new EventEmitter<EventImage>();
	@Output() maxFileSizeAchieved: EventEmitter<void> = new EventEmitter<void>();
	@Output() handleTabUpload: EventEmitter<TabContentImage> = new EventEmitter<TabContentImage>();
	@Output() isLoadingChange = new EventEmitter<boolean>();
	@Output() handleExternally: EventEmitter<File> = new EventEmitter<File>();
	@Output() sendImageString = new EventEmitter<string | ArrayBuffer>();
	@Output() formDirty: EventEmitter<void> = new EventEmitter<void>();

	@ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();
	constructor(
		private store: StoreService,
		private actions$: Actions
	) { }

	ngOnInit(): void {
		if (!this.imagePath) {
			if (this.showPreviewImage){
				this.imagePath = this.getImagePreview();
			}
		}

		this.actions$
			.pipe(
				ofType(EventActionsConstants.EVENT_IMAGE_UPLOAD_SUCCESS, EventActionsConstants.EVENT_IMAGE_UPLOAD_FAILED),
				takeUntil(this.destroyed$)
			)
			.subscribe(({ type, payload }:
			{ type: string; payload: { eventId: number | SchemeID; image: EventImage } }) => {
				if (type === EventActionsConstants.EVENT_IMAGE_UPLOAD_SUCCESS) {
					this.uploadHandler.emit(payload.image);
					this.image = payload.image;
					if (this.showPreviewImage){
						this.imagePath = this.getImagePreview();
					}
					this.formDirty.emit();
				} else {
					this.store.dispatch(
						new AddNotification({
							id: 'image-upload-failed',
							title: 'Oops! Something went wrong with the upload. Please try again in a moment or contact support.',
							actionType: null,
							action: null,
							type: NotificationType.ERROR,
						})
					);
				}
				this.isUploading = false;
			});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.imagePath && (changes.imagePath.currentValue !== changes.imagePath.previousValue) && this.customUpload) {
			this.isUploading = false;
		}
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}

	getImagePreview() {
		return this.image
			? `${config.baseURL}${this.image.url}`
			: '';
	}

	handleImageRemove() {
		this.removeHandler.emit(this.image);

		this.image = null;
		if (this.showPreviewImage){
			this.imagePath = this.getImagePreview();
		}
		this.fileInput.nativeElement.value = '';
		this.formDirty.emit();
	}

	handleImageUpload(event: Event) {
		this.toggleLoading();
		const file: File = (event.target as HTMLInputElement).files[0];
		const isSomeFileBigger = file.size > this.maxFileSize.bit;
		if (isSomeFileBigger) {
			this.maxFileSizeAchieved.emit();

			this.image = null;
			if (this.showPreviewImage){
				this.imagePath = this.getImagePreview();
			}
			this.toggleLoading();
			this.fileInput.nativeElement.value = '';
			return;
		}
		if (this.handleTicketGroups){
			const reader = new FileReader();
			reader.onload = (e) => {
				const imageString = e.target.result;

				this.sendImageString.emit(imageString);
			};
			reader.readAsDataURL(file);
		}
		const formData = new FormData();
		formData.append(null, file, file.name);
		if (this.handleTabContent) {
			const reader = new FileReader();
			reader.onload = (e) => {
				const imageString = e.target.result;
				const tabContentImage: TabContentImage = {
					eventId: this.eventId,
					imageType: this.imageServiceType,
					imageData: formData,
					replace: true,
					imageString: imageString,
				};
				this.handleTabUpload.emit(tabContentImage);
			};
			reader.readAsDataURL(file);
		} else if (this.customUpload) {
			this.handleExternally.emit(file);
		} else {
			this.store.dispatch({
				type: EventActionsConstants.EVENT_IMAGE_UPLOAD,
				payload: {
					eventId: this.eventId,
					imageType: this.imageServiceType,
					imageData: formData,
					replace: true,
				},
			});
		}
	}

	toggleLoading() {
		this.isUploading = !this.isUploading;
		this.isLoadingChange.emit(this.isUploading);
	}

}
