import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
	selector: 'app-info-card',
	templateUrl: './info-card.component.html',
	styleUrls: ['./info-card.component.sass'],
})
export class InfoCardComponent implements OnChanges {
	@Input() title: string;
	@Input() data: string;
	@Input() tooltipText: string;
	@Input() currencySymbol: string;
	@Input() isMobile = false;
	@Input() actionText: string;
	@Input() actionIcon: string;
	@Input() prefix: string;
	@Input() suffix: string;
	@Input() border = true;
	@Input() isLoading = false;
	@Output() actionClick = new EventEmitter<void>();

	isTooltipOpen = false;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	ngOnChanges(changes: SimpleChanges) {
		if (changes.isLoading) {
			this.isLoading = changes.isLoading.currentValue;
		}
	}

	toggleTooltip(): void {
		this.isTooltipOpen = !this.isTooltipOpen;
	}

	handleActionClicked(){
		this.actionClick.emit();
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
