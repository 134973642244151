<div class="l-container">
	<div class="l-header">
		<h3 class="l-title" [ngClass]="{ mobile: data.isMobile }">Edit Your Event Link</h3>
		<p class="l-content" [ngClass]="{ mobile: data.isMobile }">
			Below is your event short link. You may edit it to make the link shorter and more usable.
		</p>
	</div>
	<div class="l-link-container" [ngClass]="{ mobile: data.isMobile }">
		<div *ngIf="shortLinkControl" class="l-input-container">
			<app-mat-text-control-field
				[control]="shortLinkControl"
				[label]="'Edit Short Link'"
				[required]="true"
				customId="edit-shortlink-input"
			></app-mat-text-control-field>
			<div class="g-error-input" *ngIf="shortLinkControl.invalid && (shortLinkControl.dirty || shortLinkControl.touched)">
				{{ getError(shortLinkControl.errors) }}
			</div>
		</div>
	</div>
	<div class="l-buttons-container" [ngClass]="{ mobile: data.isMobile }">
		<button
			class="g-secondary-button"
			id="cancel-button"
			(click)="handleClose()"
		>
			CANCEL
		</button>
		<button
			class="g-primary-button"
			id="save-button"
			(click)="handleEditLink()"
			[disabled]="!shortLinkControl || shortLinkControl.invalid"
		>
			SAVE
		</button>
	</div>
</div>
