<app-my-account-header
	[isMobile]='isMobile'
	pageName="Payouts"
	(navigateBack)="handleNavigationBack()"
>
</app-my-account-header>
<div class="g-account-container">
	<div
		class="g-account-content h-100"
		[ngClass]="{ 'mobile': isMobile, 'web': !isMobile }"
	>
		<app-web-content-header
			class="w-100"
			[headerContent]="headerContent"
			[isMobile]="isMobile"
		></app-web-content-header>

		<div *ngIf="(!isLoading && !isPageLoading); else mainLoader">
			<div
				class="l-accounts"
				*ngIf="bankAccounts?.length; else showEmptyState"
			>
				<div [ngClass]="{ 'd-flex justify-content-between flex-column mb-3': isMobile }">
					<div
						class="l-card-container"
						[ngClass]="{ 'justify-content-center mb-3': isMobile }"
					>
						<div
							class="l-card"
							*ngFor="let account of bankAccounts"
							(click)='handleAccount(false, account)'
						>
							<app-payment-card
								[data]="account"
								(delete)='handleDelete($event)'
								class="l-card-content g-pointer"
								[ngClass]="{ 'l-full-width-card': bankAccounts.length === 1 }"
							></app-payment-card>
						</div>
					</div>
				</div>
			</div>
			<ng-template #showEmptyState>
				<div
					[ngClass]="{ 'l-empty-card-container': !isMobile, 'mt-4 d-flex flex-column align-items-center': isMobile }">
					<img
						class="d-flex m-auto"
						src="assets/payments/empty-saved-cards.svg"
					/>
					<h3 class="d-flex justify-content-center">Add Bank Account</h3>
					<p [ngClass]="{ 'l-empty-card-text-mobile': isMobile, 'l-empty-card-text': !isMobile }">
						Bank Account details are relevant for event payouts, ticket refunds and <strong><u
								class="g-pointer g-green"
								(click)="handleCommissionNavigation()"
							>commission payments</u></strong>
					</p>
					<button
						class='g-primary-button mt-4'
						[ngClass]="{ 'mx-3 justify-content-center': isMobile }"
						[disabled]='isBanksLoading'
						(click)="handleAccount(true)"
					>
						<div *ngIf="!isBanksLoading else showLoading">ADD BANK ACCOUNT
						</div>
						<ng-template #showLoading><mat-spinner class='g-button-spinner'></mat-spinner></ng-template>
					</button>
				</div>
			</ng-template>
		</div>
		<ng-template #mainLoader>
			<div class='l-loading'>
				<mat-spinner></mat-spinner>
			</div>
		</ng-template>
	</div>
	<app-footer-button
		*ngIf="!isPageLoading && bankAccounts?.length"
		class="g-fixed-footer"
		[primaryText]="'ADD BANK ACCOUNT'"
		[isDisabled]="isPageLoading && isBanksLoading"
		($primaryButton)="handleAccount(true)"
		[isLoading]="isPageLoading || isBanksLoading"
		[isMobile]="isMobile"
	>
	</app-footer-button>
</div>