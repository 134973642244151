import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-leaving-confirmation-modal',
	templateUrl: './leaving-confirmation-modal.component.html',
	styleUrls: ['./leaving-confirmation-modal.component.sass'],
})
export class LeavingConfirmationModalComponent {
	constructor(
		public dialogRef: MatDialogRef<LeavingConfirmationModalComponent>,
		@Inject(MAT_DIALOG_DATA)
		public data: {
			isMobile: boolean;
			unsavedStep: number[];
		}
	) {}

	matchIndex(index: number) {
		switch (index) {
			case 0:
				return 'Organiser profile';
			case 1:
				return 'Event details';
			case 2:
				return 'Ticket types';
		}
	}

	getUnsavedStepTitle() {
		if (this.data.unsavedStep.length) {
			return this.data.unsavedStep.map((el) => `'${this.matchIndex(el)}'`).join(', ');
		} else {
			return null;
		}
	}

	handleDelete() {
		this.dialogRef.close(true);
	}

	handleCancel() {
		this.dialogRef.close(false);
	}
}
