import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CheckoutFileUpload, Question, UploadFile, ValidationType } from '@app/models/user.model';
import { StoreService } from '@app/services/store/store.service';
import { UserActionsConstants } from '@app/store/actions/user/user.actions.constants';
import { QuestionFileTypes, formErrorMessage } from '@app/utils/consts';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs';
import { FileUpload } from '@app/store/actions/user/user.actions';

@Component({
	selector: 'app-file-upload',
	templateUrl: './file-upload.component.html',
	styleUrls: ['./file-upload.component.sass'],
})
export class FileUploadComponent implements OnInit, OnChanges{
	@Input() isMobile: boolean;
	@Input() inputFormControl: FormControl;
	@Input() label: string;
	@Input() control: Question;
	@Input() purchaseId: number;
	@Input() encrypt: string;
	@Input() productId: number;
	@Output() questionUpdated = new EventEmitter();

	file: UploadFile;
	stringFile: string;
	fileName = '';
	uploadType = '';
	requiredFileTypes: string[] = [];
	fileTypes = QuestionFileTypes;
	isLoading = false;
	downloadUrl: string;
	uploadErrorMessage: string;
	uploadName: string;

	isDisabled = false;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	errorMessages = formErrorMessage;
	get errorKeys(): string[] {
		return Object.keys(formErrorMessage);
	}

	@ViewChild('fileUpload') fileUploadInput: ElementRef;

	constructor(private store: StoreService, private actions$: Actions) { }

	ngOnInit(): void {
		this.stringFile = this.inputFormControl.value;
		this.isDisabled = this.inputFormControl.disabled;

		if (this.stringFile) {
			try {
				this.file = JSON.parse(this.stringFile);
				this.fileName = this.file.originalFileName;
				this.downloadUrl = this.file.downloadUrl;
			} catch (error) {
				this.fileName = this.stringFile;
			}

			this.inputFormControl.setValue(this.fileName);
		}

		this.requiredFileTypes = this.getRequiredFileTypes();

		this.actions$
			.pipe(
				ofType(UserActionsConstants.FILE_UPLOAD_SUCCESS, UserActionsConstants.FILE_UPLOAD_FAILED),
				takeUntil(this.destroyed$)
			)
			.subscribe(({ type, payload }:
			{ type: string; payload: CheckoutFileUpload }) => {
				if (type === UserActionsConstants.FILE_UPLOAD_SUCCESS) {
					this.handleFileUploadAction(payload);
				} else {
					this.uploadErrorMessage = 'Something went wrong';
					this.isLoading = false;
				}
			});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.inputFormControl) {
			if (!changes.inputFormControl.isFirstChange() && changes.inputFormControl.currentValue.value !== this.fileName) {
				this.inputFormControl.setValue(this.fileName);
			}
		}
	}

	handleFileUploadAction(fileUpload: CheckoutFileUpload) {
		const { success, error, downloadUrl, purchaseItemId, questionId } = fileUpload;
		if (this.control.purchaseItemId === purchaseItemId && this.control.id === questionId) {
			this.processFileUploadResponse(success, downloadUrl, error);
		}
	}

	processFileUploadResponse(success: boolean, downloadUrl: string, error: string) {
		if (success) {
			this.downloadUrl = downloadUrl;
			this.inputFormControl.setValue(this.fileName);
			this.uploadErrorMessage = null;
			this.questionUpdated.emit();
			this.inputFormControl.markAsDirty();
		} else {
			this.uploadErrorMessage = error || 'Something went wrong';
		}
		this.isLoading = false;
	}

	getRequiredFileTypes(): string[] {
		let requiredTypes = [];
		switch (this.control.validationType) {
			case ValidationType.FileDocument:
				requiredTypes = requiredTypes.concat(this.fileTypes.document);
				this.uploadType = 'Document';
				break;
			case ValidationType.FileImage:
				requiredTypes = requiredTypes.concat(this.fileTypes.image);
				this.uploadType = 'Image';
				break;
			case ValidationType.FileAnything:
				requiredTypes = requiredTypes.concat(this.fileTypes.image, this.fileTypes.document);
				this.uploadType = 'Image or Document';
				break;
			default:
				break;
		}
		return requiredTypes;
	}

	onFileSelected(event: Event) {
		if (event) {
			const selectedFile: File = (event.target as HTMLInputElement).files[0];
			if (this.isValidFile(selectedFile)) {
				this.uploadFile(selectedFile);
			} else {
				this.handleInvalidFile(selectedFile);
			}
		}
	}

	isValidFile(file: File): boolean {
		return file && this.requiredFileTypes.includes(file.type) && file.size <= (1 * 1024 * 1024);
	}

	uploadFile(file: File) {
		this.isLoading = true;
		this.uploadErrorMessage = null;
		this.fileName = file.name;

		const formData = new FormData();
		formData.append('file', file, file.name);
		this.uploadName = `${this.control.purchaseItemId}-${this.control.id}.${file.name.split('.').pop()}`;

		this.store.dispatch(new FileUpload({
			formData: formData,
			name: this.uploadName,
			purchaseId: this.purchaseId,
			encrypt: this.encrypt,
			productId: this.productId,
			purchaseItemId: this.control.purchaseItemId,
			questionId: this.control.id,
		}));
	}

	handleInvalidFile(file: File) {
		if (file.size > (1 * 1024 * 1024)) {
			this.uploadErrorMessage = 'Please upload a file smaller than 1MB';
			this.fileUploadInput.nativeElement.value = '';
			return;
		}
		this.inputFormControl.setValue(null);
		this.fileName = '';
		this.fileUploadInput.nativeElement.value = '';
		if (this.control.validationType === ValidationType.FileImage) {
			this.inputFormControl.setErrors({ invalidImage: true });
		};
		if (this.control.validationType === ValidationType.FileDocument) {
			this.inputFormControl.setErrors({ invalidDocument: true });
		};
		if (this.control.validationType === ValidationType.FileAnything) {
			this.inputFormControl.setErrors({ invalidFileType: true });
		};
	}

	handleFileDownload() {
		window.open(this.downloadUrl);
	}

	handleDelete() {
		this.downloadUrl = null;
		this.uploadErrorMessage = null;
		this.inputFormControl.setValue(null);
		this.fileName = '';
		this.fileUploadInput.nativeElement.value = '';
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
