export const transformToFriendlyURL = (src: string): string => {
	const srcAfterReplacement = (src || '')
		.toLowerCase()
		.replace(/[^a-z0-9\-\s]/gm, '')
		.trim()
		.replace(/\s/gm, '-')
		.replace(/&\w+;/gm, '')
		.replace(/-{2,}/gm, '-');

	if (srcAfterReplacement.length > 80) {
		return srcAfterReplacement.substring(0, 79);
	}

	return srcAfterReplacement;
};

export const trimLeft = (src: string, replacement = ''): string => {
	let srcToProceed = src;
	if (src === undefined) {
		srcToProceed = '\s';
	}
	return srcToProceed.replace(new RegExp('^[' + srcToProceed + ']+'), replacement);
};

export const trimRight = (src: string, replacement = ''): string => {
	let srcToProceed = src;
	if (src === undefined) {
		srcToProceed = '\s';
	}
	return srcToProceed.replace(new RegExp('[' + srcToProceed + ']+$'), replacement);
};
