import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StoreService } from '@app/services/store/store.service';
import { Actions, ofType } from '@ngrx/effects';
import { filter, takeUntil } from 'rxjs';
import * as eventSelector from '@app/store/selectors/event.selector';
import { PageEvent } from '@angular/material/paginator';
import {
	RegistrationSortByEnum,
	EventRegistration,
	RegistrationFilter,
	RegistrationStatus,
	RegistrationStatusMapping,
	RegistrationOrderByEnum
} from '@app/models/event.model';
import { GetRegisteredUsers } from '@app/store/actions/event/event.actions';
import { EventActionsConstants } from '@app/store/actions/event/event.actions.constants';
import { FileSaverService } from '@app/services/file-saver/file-saver.service';
import { URLCreator } from '@app/services/url/url.dictionary';
import { Sort } from '@angular/material/sort';
import { FilterOptions } from '@app/models/shared';

@Component({
	selector: 'app-registrations-table',
	templateUrl: './registrations-table.component.html',
	styleUrls: ['./registrations-table.component.sass'],
})
export class RegistrationsTableComponent {
	@Input() isMobile = false;
	@Input() eventId: number;
	@Output() reportButtonClick: EventEmitter<void> = new EventEmitter<void>();
	isUsersLoading = true;
	isUsersFiltering = false;
	userFilter = new RegistrationFilter();
	resultsLength = 0;
	tableHasData = false;
	hasDispatched = false;
	registrations: EventRegistration[] = [];
	pageIndex: number;

	displayColumns: string[] = ['name', 'surname', 'email', 'identificationNumber', 'status', 'action'];
	pageSizeOptions = [4, 8, 12];

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private store: StoreService,
		private actions$: Actions,
		private fileSaverService: FileSaverService
	) { }

	ngOnInit(): void {
		this.initialiseFilteredUsers();
	}

	initialiseFilteredUsers() {
		this.dispatchFilteredRegistrations();
		this.actions$
			.pipe(
				ofType(EventActionsConstants.GET_REGISTRATIONS_SUCCESS, EventActionsConstants.GET_REGISTRATIONS_FAILED),
				takeUntil(this.destroyed$)
			)
			.subscribe(() => {
				this.isUsersLoading = false;
				this.isUsersFiltering = false;
				if (!this.hasDispatched) {
					this.hasDispatched = true;
					this.tableHasData = this.resultsLength > 0 ? true : false;
				}
			});

		this.store.select(eventSelector.eventRegisteredUsers(this.eventId)).pipe(
			takeUntil(this.destroyed$), filter((users) => !!users)).subscribe((filteredRegistrations) => {
			this.registrations = filteredRegistrations.registrations;
			this.resultsLength = filteredRegistrations.totalRegistrations;
		});
	}

	handleFilterOutput(filterOutput: FilterOptions) {
		const { searchTerm } = filterOutput;
		this.userFilter.search = searchTerm || '';
		this.resetTableFilter();
		this.dispatchFilteredRegistrations();
	}

	dispatchFilteredRegistrations() {
		this.isUsersFiltering = true;
		const filters = {
			...this.userFilter,
		};
		this.store.dispatch(new GetRegisteredUsers({id: this.eventId, filter: filters }));
	}

	handleStatusClass(statusValue: number) {
		return RegistrationStatus[statusValue].toLowerCase();
	}

	handleStatusMapping(statusValue: number) {
		return RegistrationStatusMapping[RegistrationStatus[statusValue]].toUpperCase();
	}

	handleDownloadReport() {
		this.fileSaverService.save({
			url: `/api/product/${this.eventId}/registrations/download`,
			fileName: `${this.eventId}-quicket-event-registered-users.xlsx`,
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}

	handlePagination(event: PageEvent) {
		this.pageIndex = event.pageIndex;
		this.userFilter.number = event.pageIndex + 1;
		this.userFilter.size = event.pageSize;
		this.dispatchFilteredRegistrations();
	}

	handleSorting(event: Sort) {
		this.userFilter.sortBy = RegistrationSortByEnum[event.active];
		if (event.direction !== '') {
			this.userFilter.orderBy = RegistrationOrderByEnum[event.direction.toUpperCase()];
		}
		this.resetTableFilter();
		this.dispatchFilteredRegistrations();
	}

	resetTableFilter() {
		this.pageIndex = 0;
		this.userFilter.number = 1;
	}

	handleUserDetailsRedirect(regId: number) {
		window.open(URLCreator.eventUserRegistrationDetails(this.eventId, regId));
	}
}
