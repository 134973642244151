<app-my-account-header
	[isMobile]='isMobile'
	pageName="Contact Information"
	(navigateBack)="handleNavigationBack()"
>
</app-my-account-header>
<div class="g-account-container">
	<div
		class="g-account-content"
		[ngClass]="{ 'mobile': isMobile, 'web': !isMobile }"
	>
		<app-web-content-header
			class="w-100"
			[headerContent]="headerContent"
			[longWidth]="true"
			[isMobile]="isMobile"
		></app-web-content-header>

		<form
			#contactInformationForm="ngForm"
			class="g-form-container w-100"
			[ngClass]="{ 'mt-4': isMobile, 'my-5': !isMobile }"
		>
			<div class="g-double-input-container">
				<div [ngClass]="{ 'g-full-width': isMobile, 'g-double-input': !isMobile }">
					<label class="g-form-label">Email <span class="g-orange">*</span></label>
					<input
						id='email-input'
						#email="ngModel"
						[(ngModel)]="contactInformation.email"
						type="email"
						name="email"
						class="form-control"
						placeholder="jane@doe.com"
						[pattern]='emailRegex'
						required
					/>
					<div
						class="g-error-input"
						*ngIf="contactInformationForm.invalid && (contactInformationForm.dirty || contactInformationForm.touched)"
					>
						<div *ngIf="email.errors?.pattern">Invalid Email</div>
					</div>
				</div>
				<div [ngClass]="{ 'g-full-width': isMobile, 'g-double-input': !isMobile }">
					<app-phone-input
						label="Cellphone"
						outerClass="''"
						[control]="phoneForm.get('cellphone')"
						[isMobile]="isMobile"
						[large]="false"
					>
					</app-phone-input>
				</div>
			</div>
			<div class="g-double-input-container w-100 justify-content-start">
				<div [ngClass]="{ 'g-full-width': isMobile, 'g-double-input': !isMobile }">
					<app-phone-input
						label="Telephone"
						outerClass="''"
						[control]="phoneForm.get('telephone')"
						[isMobile]="isMobile"
						[large]="false"
					>
					</app-phone-input>
				</div>
			</div>
			<div
				class="l-empty"
				[ngClass]="{ 'g-full-width': isMobile, 'g-double-input': !isMobile }"
			></div>
			<div class="w-100 d-flex justify-content-center">
				<mat-divider class="w-100 mt-3"></mat-divider>
			</div>

			<div
				class="mb-2"
				[ngClass]="{'d-flex justify-content-center w-100': isMobile, 'me-auto': !isMobile}"
			>
				<div class="d-flex flex-column my-4">
					<label class="g-form-label">Address</label>
					<div>
						Street Address<mat-slide-toggle
							id='address-toggle'
							#isPOBox="ngModel"
							[(ngModel)]="userAddress.isPoBox"
							name="isPOBox"
							class="double-checked px-4"
						></mat-slide-toggle>PO Box
					</div>
				</div>
			</div>

			<div
				*ngIf="!userAddress.isPoBox; else POBox"
				class="w-100"
			>

				<div class="g-double-input-container">
					<div [ngClass]="{ 'g-full-width': isMobile, 'g-double-input': !isMobile }">
						<label class="g-form-label">Number</label>
						<input
							id='address-number-input'
							#number="ngModel"
							[(ngModel)]="userAddress.number"
							type="text"
							name="number"
							class="form-control"
							placeholder="House/Apt Number"
						/>
					</div>
					<div [ngClass]="{ 'g-full-width': isMobile, 'g-double-input': !isMobile }">
						<label class="g-form-label">Street</label>
						<input
							id='address-street-input'
							#street="ngModel"
							[(ngModel)]="userAddress.street"
							type="text"
							name="street"
							class="form-control"
							placeholder="Street"
						/>
					</div>
				</div>
				<div class="g-double-input-container">
					<div [ngClass]="{ 'g-full-width': isMobile, 'g-double-input': !isMobile }">
						<label class="g-form-label">Suburb</label>
						<input
							id='address-suburb-input'
							#suburb="ngModel"
							[(ngModel)]="userAddress.suburb"
							type="text"
							name="suburb"
							class="form-control"
							placeholder="Suburb"
						/>
					</div>
					<div [ngClass]="{ 'g-full-width': isMobile, 'g-double-input': !isMobile }">
						<label class="g-form-label">City/Town</label>
						<input
							id='address-city-input'
							#city="ngModel"
							[(ngModel)]="userAddress.city"
							type="text"
							name="city"
							class="form-control"
							placeholder="City/Town"
						/>
					</div>
				</div>
				<div class="g-double-input-container">
					<div [ngClass]="{ 'g-full-width': isMobile, 'g-double-input': !isMobile }">
						<label class="g-form-label">Postal Code</label>
						<input
							id='address-postal-input'
							#postalCode="ngModel"
							[(ngModel)]="userAddress.postalCode"
							type="text"
							name="postalCode"
							class="form-control"
							placeholder="Postal Code"
						/>
					</div>
					<div [ngClass]="{ 'g-full-width': isMobile, 'g-double-input': !isMobile }">
						<label class="g-form-label">Country</label>
						<mat-select-country
							id='address-country-input'
							class="form-control p-0"
							placeholder="Country"
							[value]="country"
							(onCountrySelected)="handleCountrySelect($event)"
						></mat-select-country>
					</div>
				</div>
			</div>
			<ng-template #POBox>
				<div class="g-double-input-container">
					<div [ngClass]="{ 'g-full-width': isMobile, 'g-double-input': !isMobile }">
						<label class="g-form-label">PO Box</label>
						<input
							id='address-po-box-input'
							#number="ngModel"
							[(ngModel)]="userAddress.number"
							type="text"
							name="number"
							class="form-control"
							placeholder="PO Box Number"
						/>
					</div>
					<div [ngClass]="{ 'g-full-width': isMobile, 'g-double-input': !isMobile }">
						<label class="g-form-label">Post Office</label>
						<input
							id='address-post-office-input'
							#street="ngModel"
							[(ngModel)]="userAddress.street"
							type="text"
							name="street"
							class="form-control"
							placeholder="Post Office Name"
						/>
					</div>
				</div>
				<div class="g-double-input-container">
					<div [ngClass]="{ 'g-full-width': isMobile, 'g-double-input': !isMobile }">
						<label class="g-form-label">City/Town</label>
						<input
							id='address-city-input'
							#city="ngModel"
							[(ngModel)]="userAddress.city"
							type="text"
							name="city"
							class="form-control"
							placeholder="City/Town"
						/>
					</div>
					<div [ngClass]="{ 'g-full-width': isMobile, 'g-double-input': !isMobile }">
						<label class="g-form-label">Postal Code</label>
						<input
							id='address-postal-input'
							#postalCode="ngModel"
							[(ngModel)]="userAddress.postalCode"
							type="text"
							name="postalCode"
							class="form-control"
							placeholder="Postal Code"
						/>
					</div>
				</div>

				<div class="mb-2 w-100 d-flex">
					<div [ngClass]="{ 'g-full-width': isMobile, 'g-half-width': !isMobile }">
						<label class="g-form-label">Country</label>
						<mat-select-country
							id='address-country-input'
							class="form-control p-0"
							placeholder="Country"
							[value]="country"
							(onCountrySelected)="handleCountrySelect($event)"
						></mat-select-country>
					</div>
				</div>
			</ng-template>
		</form>
	</div>
</div>
<app-footer-button
	class="g-fixed-footer"
	[primaryText]="'SAVE CHANGES'"
	[isDisabled]="contactInformationForm.invalid || phoneForm.invalid || (contactInformationForm.pristine && phoneForm.pristine)"
	[isLoading]="isLoading"
	($primaryButton)="handlePrimaryButton()"
	[isMobile]="isMobile"
>
</app-footer-button>