<div class="l-container">
	<h1>Basic Details</h1>
	<div class="l-event-user-info">
		<p>
			We need some basic contact details in case we need contact you. These details won't be shown publicly.
		</p>
		<ng-container *ngIf="form">
			<div class="l-form-content">
				<app-mat-text-control-field
					label="First Name"
					placeholder="e.g. Jane"
					[control]="form.get('firstName')"
					id="basic-name-input"
					labelClass="g-form-label"
				>
				</app-mat-text-control-field>
				<app-mat-text-control-field
					label="Surname"
					placeholder="e.g. Doe"
					[control]="form.get('surname')"
					id="basic-surname-input"
					labelClass="g-form-label"
				>
				</app-mat-text-control-field>
				<app-phone-input
					label="Phone"
					[control]="form.get('phone')"
					customId="basic-phone-input"
				>
				</app-phone-input>
				<p class="l-event-user-info-errors">
					{{ form.errors && form.errors['form'] || '' }}
				</p>
			</div>
			<div class="l-event-user-info-controls">
				<button
					class="g-primary-button mt-3"
					(click)="onSubmit()"
					[disabled]="isSubmitDisabled()"
					[ngClass]="{'disabled': isSubmitDisabled()}"
					id="submit-basic-info"
				>
					SAVE
				</button>
				<button
					class="g-flat-button l-skip-intro"
					(click)="onSkip()"
					id="skip-button"
				>
					Skip
				</button>
			</div>
		</ng-container>
	</div>
</div>