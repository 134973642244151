import { EventOrganiserProfile } from '@app/models/profile.model';
import * as profileActions from '../actions/organiserProfiles/organiserProfiles.actions';
import { combineReducers, ActionReducer, ActionReducerMap } from '@ngrx/store';
import { OrganiserProfileActionConstants as profileConstants } from '../actions/organiserProfiles/organiserProfiles.constants';
import { NormalizedSchemeWithFetchingModals } from '@app/models/dataSchema.model';
import { omit } from 'lodash';

type OrganiserProfileState = NormalizedSchemeWithFetchingModals<EventOrganiserProfile>;

export const initialState: OrganiserProfileState = {
	byId: {},
	all: [],
	isFetching: false,
	isFormModalOpen: false,
	isLinkedProductModalOpen: false,
};

const all = (
	state = initialState.all,
	action: profileActions.Actions
): OrganiserProfileState['all'] => {
	switch (action.type) {
		case profileConstants.GET_PROFILES_SUCCESS:
		case profileConstants.GET_ORGANISER_PROFILES_SUCCESS:
			return action.payload.profiles.all;
		case profileConstants.CREATE_PROFILE_SUCCESS:
			return [
				...state,
				action.payload.profile.id,
			];
		case profileConstants.UPDATE_ORGANISER_PROFILE_SUCCESS:
			return action.payload.isUpdating ? [
				...state,
			]
				: [
					...state,
					action.payload.profile.id,
				];
		case profileConstants.DELETE_ORGANISER_PROFILE_SUCCESS:
			return state.filter(id => id !== action.payload.id);
		default:
			return state;
	}
};

const byId = (
	state = initialState.byId,
	action: profileActions.Actions
): OrganiserProfileState['byId'] => {
	switch (action.type) {
		case profileConstants.GET_PROFILES_SUCCESS:
		case profileConstants.GET_ORGANISER_PROFILES_SUCCESS:
			return action.payload.profiles.byId;
		case profileConstants.CREATE_PROFILE_SUCCESS:
		case profileConstants.UPDATE_ORGANISER_PROFILE_SUCCESS:
			return {
				...state,
				[action.payload.profile.id]: action.payload.profile,
			};
		case profileConstants.DELETE_ORGANISER_PROFILE_SUCCESS:
			return omit(state, action.payload.id);
		case profileConstants.SAVE_EVENT_ORGANISER_SUCCESS:
		case profileConstants.SYNC_ORGANISER_PROFILE:
			return {
				...state,
				[action.payload.organiserId] : action.payload.normalizedEntity.event[action.payload.id].profile,
			};
		default:
			return state;
	}
};

const isFetching = (
	state = initialState.isFetching,
	action: profileActions.Actions
): OrganiserProfileState['isFetching'] => {
	switch (action.type) {
		case profileConstants.CREATE_PROFILE:
		case profileConstants.GET_PROFILES:
		case profileConstants.GET_ORGANISER_PROFILES:
		case profileConstants.UPDATE_ORGANISER_PROFILE:
		case profileConstants.DELETE_ORGANISER_PROFILE:
			return true;
		case profileConstants.GET_PROFILES_FAILED:
		case profileConstants.GET_PROFILES_SUCCESS:
		case profileConstants.CREATE_PROFILE_FAILED:
		case profileConstants.CREATE_PROFILE_SUCCESS:
		case profileConstants.GET_ORGANISER_PROFILES_SUCCESS:
		case profileConstants.GET_ORGANISER_PROFILES_FAILED:
		case profileConstants.UPDATE_ORGANISER_PROFILE_FAILED:
		case profileConstants.UPDATE_ORGANISER_PROFILE_SUCCESS:
		case profileConstants.DELETE_ORGANISER_PROFILE_FAILED:
		case profileConstants.DELETE_ORGANISER_PROFILE_SUCCESS:
			return false;
		default:
			return state;
	}
};

const isFormModalOpen = (
	state = initialState.isFormModalOpen,
	action: profileActions.Actions
): OrganiserProfileState['isFormModalOpen'] => {
	switch (action.type) {
		case profileConstants.SET_ORGANISER_PROFILE_MODAL_FLAG:
			return action.payload.isFormModalOpen;
		default:
			return state;
	}
};

const isLinkedProductModalOpen = (
	state = initialState.isLinkedProductModalOpen,
	action: profileActions.Actions
): OrganiserProfileState['isLinkedProductModalOpen'] => {
	switch (action.type) {
		case profileConstants.SET_PROFILE_LINKED_PRODUCT_MODAL_FLAG:
			return action.payload.isLinkedProductModalOpen;
		default:
			return state;
	}
};

const reducers: ActionReducerMap<OrganiserProfileState, profileActions.Actions> = {
	all,
	byId,
	isFetching,
	isFormModalOpen,
	isLinkedProductModalOpen,
};

export const organiserReducer: ActionReducer<
OrganiserProfileState,
profileActions.Actions
> = combineReducers(reducers);
