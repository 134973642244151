<div [ngClass]="{'l-container': border, 'l-container-no-border': !border}">
	<div class="l-header-container">
		<div class="l-title-header">
			<h4 class="l-title"> {{title}} </h4>
			<mat-icon *ngIf="tooltipText" class="l-tooltip-icon" #tooltip="matTooltip" [matTooltip]="tooltipText"
				matTooltipPosition="above" (click)="tooltip.toggle()">
				info
			</mat-icon>
		</div>
		<div *ngIf="actionText" class="l-action-header-container">
			<div *ngIf="!isLoading; else showLoading" class="l-action-header" (click)="handleActionClicked()" id="info-card-action-button">
				<h4 class="l-title"> {{actionText}} </h4>
				<i *ngIf="actionIcon" [class]="actionIcon" class="l-icon"></i>
			</div>
			<ng-template #showLoading>
				<mat-spinner class='l-loading'></mat-spinner>
			</ng-template>
		</div>
	</div>
	<p class="l-data" *ngIf="data" [id]="'info-card-value-' + title"><span *ngIf="currencySymbol">{{currencySymbol}}</span> {{data}} </p>
	<p class="l-data" *ngIf="prefix" [id]="'info-card-value-' + title">{{prefix}}<small *ngIf="suffix" class="l-suffix"> / {{suffix}}</small></p>
</div>
