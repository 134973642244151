import { URLCreatorList } from '@app/models/url.model';
import config from '@app/config';
import { transformToFriendlyURL } from '@app/utils/URL';
import { ProductStatus } from '@app/models/event.model';

const { baseURL } = config;

export const URLCreator: URLCreatorList = {
	/* ASPX */
	joinPromoterNetworkAspx: (id, networkId, encrypt) =>
		`${baseURL}/account/events/affiliates/joinaffiliateprogram.aspx?y=${id}&apid=${networkId}&enc=${encrypt}&no-redirect=true`,
	trackingScriptsAspx: () => `${baseURL}/account/users/trackingscripts.aspx`,
	directDistributionAspx: () => `${baseURL}/account/users/directdistribution.aspx`,
	requestBankDetailsAspx: (id, encrypt) => `${baseURL}/account/users/requestforbankdetails.aspx?id=${id}&enc=${encrypt}`,
	paymentOptionsAspx: (id) => `${baseURL}/account/events/manage/payment/paymentmethod.aspx?y=${id}`,

	/* Event */
	createEvent: () => `${baseURL}/app/#/account/event/new`,
	dashboard: (id, accountingVersion) =>
		`${baseURL}${accountingVersion === 2 ? `/app/#/account/event/${id}/dashboard` : `/account/events/manage/dashboard.aspx?y=${id}`}`,
	eventDetails: (id) => `${baseURL}/app/#/account/event/${id}/details`,
	eventOrganiser: (id) => `${baseURL}/app/#/account/event/${id}/profile`,
	eventDetailsSettings: (id) => `${baseURL}/app/#/account/event/${id}/details-settings`,
	tabbedContent: (id) => `${baseURL}/app/#/account/event/${id}/tabbed-content`,
	design: (id) =>	`${baseURL}/app/#/account/event/${id}/design`,
	ticketTypes: (id) => `${baseURL}/app/#/account/event/${id}/tickets`,
	ticketSettings: (id) => `${baseURL}/app/#/account/event/${id}/tickets-settings`,
	ticketGroups: (id) => `${baseURL}/app/#/account/event/${id}/ticket-groups`,
	checkoutQuestions: (id) => `${baseURL}/app/#/account/event/${id}/checkout-questions`,
	merchendise: (id) => `${baseURL}/account/events/manage/merchandise/default.aspx?y=${id}`,
	eventSettings: (id) => `${baseURL}/app/#/account/event/${id}/definitions`,
	userAccess: (id) => `${baseURL}/account/events/manage/edit/accesscontrol.aspx?y=${id}`,
	payoutDetails: (id) => `${baseURL}/account/events/manage/edit/payoutdetails.aspx?y=${id}`,
	eventPayoutDetails: (id) => `${baseURL}/app/#/account/event/${id}/payouts`,
	discountCodes: (id) => `${baseURL}/account/events/manage/promote/discountcodes.aspx?y=${id}`,
	linkCampaigns: (id) => `${baseURL}/app/#/account/event/${id}/link-campaigns`,
	complimentaries: (id) => `${baseURL}/account/events/manage/guests/complimentaries.aspx?y=${id}`,
	invitations: (id) => `${baseURL}/account/events/manage/guests/invitations.aspx?y=${id}`,
	contactInvitations: (id) => `${baseURL}/app/#/account/event/${id}/invitations`,
	printedTicketsAspx: (id) => `${baseURL}/account/events/manage/guests/preprintedtickets.aspx?y=${id}`,
	SMSyourGuests: (id) => `${baseURL}/account/events/manage/guests/smsyourguests.aspx?y=${id}`,
	zapier: () => 'https://zapier.com/developer/public-invite/148303/c33f642cd4f9f875a641da960356ef6f/',
	mailChimp: (id) => `${baseURL}/account/events/manage/integrate/mailchimp.aspx?y=${id}&displayPage=true`,
	createMailChimpAccount: () => 'http://eepurl.com/BMY_T',
	widget: (id) => `${baseURL}/account/events/manage/promote/widget.aspx?y=${id}`,
	webhooks: (id) => `${baseURL}/app/#/account/event/${id}/webhooks`,
	exportData: (id) => `${baseURL}/account/events/manage/reports/export.aspx?y=${id}`,
	visitorsReport: (id) => `${baseURL}/account/events/manage/reports/visitorsreport.aspx?y=${id}`,
	salesReport: (id) => `${baseURL}/account/events/manage/reports/salesreport.aspx?y=${id}`,
	checkinReport: (id) => `${baseURL}/account/events/manage/reports/checkinreport.aspx?y=${id}`,
	reviewsReport: (id) => `${baseURL}/account/events/manage/reports/reviewsreport.aspx?y=${id}`,
	vendingReport: (id) => `${baseURL}/account/events/manage/reports/vendorreport.aspx?y=${id}`,
	eventSummary: (id, accountingVersion, encrypt, scheduleItem) =>
		`${baseURL}/LiveContent/${accountingVersion === 1 ? 'eventstatement' : 'eventsummary'
		}.aspx?y=${id}&py=${encrypt}&sid=${scheduleItem}&t=`,
	taxInvoice: (id, accountingVersion, encrypt, scheduleItem) =>
		`${baseURL}/LiveContent/${accountingVersion === 1 ? 'taxinvoice' : 'eventinvoice'
		}.aspx?y=${id}&py=${encrypt}&sid=${scheduleItem}&t=`,
	sellAtTheDoor: (id = null) => `${baseURL}/app/#/account/event/${id}/at-the-door`,
	requestFieldServices: (id) => `${baseURL}/app/#/account/event/${id}/request-field-services`,
	preview: (id, name = '', productStatus) =>
		`${baseURL}/events/${id}-${transformToFriendlyURL(name)}
		${productStatus === ProductStatus.Draft || productStatus === ProductStatus.AwaitingActivation ? '?preview=t' : ''}`,
	facebookIntegration: (id) => `${baseURL}/app/#/account/event/${id}/facebook-integration`,
	eventCopy: (id) => `${baseURL}/app/#/account/event/${id}/copy`,
	promoterNetwork: (id, networkId) => `${baseURL}/account/events/manage/promote/viewaffiliateprogram.aspx?y=${id}&apid=${networkId}`,
	cancelPostpone: (id, type) => `${baseURL}/account/events/manage/edit/cancelpostpone.aspx?y=${id}&type=${type}`,
	eventScheduleTransfer: (id) => `${baseURL}/app/#/account/event/${id}/transfer-schedules`,
	userRegistrationDetails: (eventId, regId) => `${baseURL}/account/events/manage/registrations/view.aspx?y=${eventId}&regid=${regId}`,
	printGuests: (id, scheduleItemId) => `${baseURL}/account/events/manage/guests/printguests.aspx?y=${id}&sid=${scheduleItemId}`,

	eventDashboard: (id) => `${baseURL}/app/#/account/event/${id}/dashboard`,
	sendInvites: (id) => `${baseURL}/app/#/account/event/${id}/send-invites`,
	manageEvent: (id) => `${baseURL}/app/#/account/event/${id}/manage-event`,
	tickets: (id) => `${baseURL}/app/#/account/event/${id}/tickets`,
	paymentOptions: (id) => `${baseURL}/app/#/account/event/${id}/payment-options`,
	eventPayout: (id) => `${baseURL}/app/#/account/event/${id}/payouts`,
	marketing: (id) => `${baseURL}/app/#/account/event/${id}/marketing`,
	eventPromoters: (id) => `${baseURL}/app/#/account/event/${id}/promoters`,
	eventPromoterNetworks: (id) => `${baseURL}/app/#/account/event/${id}/promoter-networks`,
	eventPromoterNetwork: (id, networkId) => `${baseURL}/app/#/account/event/${id}/promoter-networks/${networkId}`,
	orders: (id) => `${baseURL}/app/#/account/event/${id}/orders`,
	scanners: (id) => `${baseURL}/app/#/account/event/${id}/scanners`,
	fieldServices: (id) => `${baseURL}/app/#/account/event/${id}/request-field-services`,
	atTheDoor: (id) => `${baseURL}/app/#/account/event/${id}/at-the-door`,
	reports: (id) => `${baseURL}/app/#/account/event/${id}/reports`,
	settings: (id) => `${baseURL}/app/#/account/event/${id}/settings`,
	eventSettingsTicketing: (id) => `${baseURL}/app/#/account/event/${id}/ticket-settings`,
	guestList: (id) => `${baseURL}/app/#/account/event/${id}/guest-list`,
	integrations: (id) => `${baseURL}/app/#/account/event/${id}/integrations`,
	ordersList: (id) => `${baseURL}/account/events/manage/orders/default.aspx?y=${id}`,
	registrations: (id) => `${baseURL}/app/#/account/event/${id}/registrations`,
	sendComplimentaryTickets: (id) => `${baseURL}/app/#/account/event/${id}/send-complimentary`,
	sendRSVPs: (id) => `${baseURL}/app/#/account/event/${id}/send-RSVP`,
	sendSMSs: (id) => `${baseURL}/app/#/account/event/${id}/send-SMS`,
	eventMerchandise: (id) => `${baseURL}/app/#/account/event/${id}/merchandise`,
	promotionalCodes: (id) => `${baseURL}/app/#/account/event/${id}/promotional-codes`,
	eventUserAccess: (id) => `${baseURL}/app/#/account/event/${id}/user-access`,
	orderDetails: (id) => `${baseURL}/app/#/account/event/${id}/order-details`,
	eventVisitorsReport: (id) => `${baseURL}/app/#/account/event/${id}/visitors-report`,
	eventSalesReport: (id) => `${baseURL}/app/#/account/event/${id}/sales-report`,
	eventCheckInReport: (id) => `${baseURL}/app/#/account/event/${id}/check-in-report`,
	eventReviewsReport: (id) => `${baseURL}/app/#/account/event/${id}/reviews-report`,
	eventExportData: (id) => `${baseURL}/app/#/account/event/${id}/export-data`,
	eventMailChimp: (id) => `${baseURL}/app/#/account/event/${id}/mailchimp`,
	eventWidget: (id) => `${baseURL}/app/#/account/event/${id}/widget`,
	campaigns: (id) => `${baseURL}/app/#/account/event/${id}/link-campaigns`,
	definitions: (id) => `${baseURL}/app/#/account/event/${id}/definitions`,
	cancel: (id) => `${baseURL}/app/#/account/event/${id}/cancel`,
	postpone: (id) => `${baseURL}/app/#/account/event/${id}/postpone`,
	copyEvent: (id) => `${baseURL}/app/#/account/event/${id}/copy`,
	eventUserRegistrationDetails: (id, regId) => `${baseURL}/app/#/account/event/${id}/registrations/${regId}/user-registration-details`,
	settingsEvent: (id) => `${baseURL}/app/#/account/event/${id}/details-settings`,
	organiserProfile: (id) => `${baseURL}/app/#/account/event/${id}/profile`,
	eventWebhooks: (id) => `${baseURL}/app/#/account/event/${id}/webhooks`,
	guestManagement: (id) => `${baseURL}/app/#/account/event/${id}/guest-management`,
	printedTickets: (id) => `${baseURL}/app/#/account/event/${id}/printed-tickets`,

	/* Angular Elements */
	eventsPage: () => `${baseURL}/events`,
	eventFeaturesPage: () => `${baseURL}/sell-tickets`,

	/* User */
	savedEvents: () => `${baseURL}/app/#/account/my-saved-events`,
	userProfile: () => `${baseURL}/app/#/account`,
	viewOrganiserProfile: () => `${baseURL}/organisers/`, // add id & namein linkRef
	manageTicketDashboard: () => `${baseURL}/app/#/account/orders`,
	viewTickets: (id, encrypt) => `${baseURL}/LiveContent/tickets.aspx?x=${id}&px=${encrypt}`,
	downloadAllTickets: (id, encrypt) => `${baseURL}/account/tickets/fetchpdf.aspx?x=${id}&px=${encrypt}`,
	viewInvoice: (id, encrypt) => `${baseURL}/livecontent/PReceipt.aspx?x=${id}&px=${encrypt}`,
	vending: () => `${baseURL}/account/vend/default.aspx`,

	logout: () => `${baseURL}/account/authentication/logout.aspx`,
	login: (redirectUrl?: string) => `${baseURL}/account/authentication/login.aspx?ReturnUrl=${redirectUrl}`,

	/* My Account */
	myAccount: () => `${baseURL}/app/#/account`,
	personalInfo: () => `${baseURL}/app/#/account/personal-info`,
	contactInfo: () => `${baseURL}/app/#/account/contact-info`,
	emailPreference: () => `${baseURL}/app/#/account/email-preference`,
	changePassword: () => `${baseURL}/app/#/account/change-password`,
	payouts: () => `${baseURL}/app/#/account/payouts`,
	apiKey: () => `${baseURL}/app/#/account/api`,
	requestBankDetails: (id, encrypt) => `${baseURL}/app/#/account/request-bank-details/${id}?e=${encrypt}`,
	myVending: () => `${baseURL}/app/#/account/vending`,

	/* Tickets & Registrations */
	myTickets: (cartId?) => `${baseURL}/app/#/account/orders?cartId=${cartId}`,
	myRegistrations: () => `${baseURL}/app/#/account/my-registrations`,
	manageBooking: (id) => `${baseURL}/app/#/account/orders/${id}/manage`,
	transferTickets: (id) => `${baseURL}/app/#/account/orders/${id}/transfer`,
	refundTickets: (id) => `${baseURL}/app/#/account/orders/${id}/refund`,
	resaleTickets: (id) => `${baseURL}/app/#/account/orders/${id}/resale`,
	editTickets: (id) => `${baseURL}/app/#/account/orders/${id}/edit-ticket`,
	registrationDetails: (id) => `${baseURL}/app/#/account/orders/${id}/registration-details`,

	/* Earn Commission */
	earnCommission: () => `${baseURL}/app/#/account/earn-commission`,
	promoterNetworksList: () => `${baseURL}/app/#/account/earn-commission/promoter-networks`,
	referrals: () => `${baseURL}/app/#/account/earn-commission/referrals`,
	promoterDetails: (id) => `${baseURL}/app/#/account/earn-commission/${id}/promoter-details`,
	joinPromoterNetwork: (id, networkId, encrypt) =>
		`${baseURL}/app/#/account/earn-commission/${id}/promoter-networks/${networkId}/join?e=${encrypt}`,

	/* Organiser Hub */
	orgDashboard: () => `${baseURL}/app/#/account/organiser-hub`,
	orgEvents: () => `${baseURL}/app/#/account/organiser-hub/events`,
	orgProfile: () => `${baseURL}/app/#/account/organiser-hub/profiles`,
	orgProfileDetails: (id) => `${baseURL}/app/#/account/organiser-hub/profiles/${id}/details`,
	orgProfileMailChimp: (id) => `${baseURL}/app/#/account/organiser-hub/${id}/mailchimp`,
	orgMailAccounts: () => `${baseURL}/app/#/account/organiser-hub/mail-accounts`,
	orgDirectDistribution: () => `${baseURL}/app/#/account/organiser-hub/direct-distribution`,
	orgTrackingScripts: () => `${baseURL}/app/#/account/organiser-hub/tracking-scripts`,

	/* Other */
	helpCentre: () => 'https://help.quicket.com/',
	blog: () => 'https://blog.quicket.com/',
	careers: () => 'https://careers.quicket.co.za/jobs/Careers',
	privacyPolicy: () => 'https://help.quicket.com/portal/en/kb/articles/privacy-policy',
	community: () => 'https://help.quicket.com/portal/en/community',
	security: () => 'https://www.quicket.co.za/quicket-security.aspx',
	fees: () => `${baseURL}/fees.aspx`,
	terms: () => `${baseURL}/terms-and-conditions.aspx`,
	contactUs: () => `${baseURL}/contact-us`,
	home: () => `${baseURL}`,
	contactHost: (id) => `${baseURL}/events/contacthost.aspx?pid=${id}`,
	referralArticle: () => 'https://help.quicket.com/portal/en/kb/articles/join-quicket-s-event-referral-program',
	downloadScannerAppAndroid: () => 'https://play.google.com/store/apps/details?id=com.quicket.quicketaccesscontrol&referrer=utm_source%3Dquicket%26utm_medium%3Dweb', // eslint-disable-line max-len
	downloadScannerAppIos: () => 'https://apps.apple.com/app/apple-store/id6498889417?pt=118289476&ct=quicket_web&mt=8',
	streamingHelp: () => 'https://help.quicket.com/portal/en/kb/quicket-support/event-organisers/streaming',
	mailChimpHelp: () => 'https://help.quicket.com/portal/en/kb/articles/what-is-mailchimp-and-how-could-it-help-me',
};

const BASE_MY_ACCOUNT_PATH = '/account';
const BASE_TICKETS_AND_REGISTRATION_PATH = '/account/orders';
const BASE_EARN_COMMISSION_PATH = '/account/earn-commission';
const BASE_ORGANISER_HUB_PATH = '/account/organiser-hub';
const BASE_EVENT_PATH = '/account/event';

export const InternalURLCreator: Partial<URLCreatorList> = {
	/* My Account */
	myAccount: () => BASE_MY_ACCOUNT_PATH,
	personalInfo: () => `${BASE_MY_ACCOUNT_PATH}/personal-info`,
	contactInfo: () => `${BASE_MY_ACCOUNT_PATH}/contact-info`,
	emailPreference: () => `${BASE_MY_ACCOUNT_PATH}/email-preference`,
	changePassword: () => `${BASE_MY_ACCOUNT_PATH}/change-password`,
	payouts: () => `${BASE_MY_ACCOUNT_PATH}/payouts`,
	apiKey: () => `${BASE_MY_ACCOUNT_PATH}/api`,
	requestBankDetails: (id, encrypt) => `${BASE_MY_ACCOUNT_PATH}/request-bank-details/${id}?e=${encrypt}`,
	savedEvents: () => `${BASE_MY_ACCOUNT_PATH}/my-saved-events`,
	myRegistrations: () => `${BASE_MY_ACCOUNT_PATH}/my-registrations`,
	myVending: () => `${BASE_MY_ACCOUNT_PATH}/vending`,

	/* Tickets & Registrations */
	myTickets: () => `${BASE_TICKETS_AND_REGISTRATION_PATH}`,
	manageBooking: (id) => `${BASE_TICKETS_AND_REGISTRATION_PATH}/${id}/manage`,
	transferTickets: (id) => `${BASE_TICKETS_AND_REGISTRATION_PATH}/${id}/transfer`,
	refundTickets: (id) => `${BASE_TICKETS_AND_REGISTRATION_PATH}/${id}/refund`,
	resaleTickets: (id) => `${BASE_TICKETS_AND_REGISTRATION_PATH}/${id}/resale`,
	editTickets: (id) => `${BASE_TICKETS_AND_REGISTRATION_PATH}/${id}/edit-ticket`,
	registrationDetails: (id) => `${BASE_TICKETS_AND_REGISTRATION_PATH}/${id}/registration-details`,

	/* Earn Commission */
	earnCommission: () => `${BASE_EARN_COMMISSION_PATH}`,
	promoterNetworksList: () => `${BASE_EARN_COMMISSION_PATH}/promoter-networks`,
	referrals: () => `${BASE_EARN_COMMISSION_PATH}/referrals`,
	promoterDetails: (id) => `${BASE_EARN_COMMISSION_PATH}/${id}/promoter-details`,
	joinPromoterNetwork: (id, networkId, encrypt) => `${BASE_EARN_COMMISSION_PATH}/${id}/promoter-networks/${networkId}/join?e=${encrypt}`,

	/* Organiser Hub */
	orgDashboard: () => `${BASE_ORGANISER_HUB_PATH}`,
	orgEvents: () => `${BASE_ORGANISER_HUB_PATH}/events`,
	orgProfile: () => `${BASE_ORGANISER_HUB_PATH}/profiles`,
	orgProfileDetails: (id) => `${BASE_ORGANISER_HUB_PATH}/profiles/${id}/details`,
	orgProfileMailChimp: (id) => `${BASE_ORGANISER_HUB_PATH}/${id}/mailchimp`,
	orgMailAccounts: () => `${BASE_ORGANISER_HUB_PATH}/mail-accounts`,
	orgDirectDistribution: () => `${BASE_ORGANISER_HUB_PATH}/direct-distribution`,
	orgTrackingScripts: () => `${BASE_ORGANISER_HUB_PATH}/tracking-scripts`,

	/* Event */
	createEvent: () => `${BASE_EVENT_PATH}/new`,
	design: (id) => `${BASE_EVENT_PATH}/${id}/design`,
	eventDetails: (id) => `${BASE_EVENT_PATH}/${id}/details`,
	eventDashboard: (id) => `${BASE_EVENT_PATH}/${id}/dashboard`,
	sendInvites: (id) => `${BASE_EVENT_PATH}/${id}/send-invites`,
	manageEvent: (id) => `${BASE_EVENT_PATH}/${id}/manage-event`,
	tabbedContent: (id) => `${BASE_EVENT_PATH}/${id}/tabbed-content`,
	tickets: (id) => `${BASE_EVENT_PATH}/${id}/manage-checkout`,
	paymentOptions: (id) => `${BASE_EVENT_PATH}/${id}/payment-options`,
	eventPayout: (id) => `${BASE_EVENT_PATH}/${id}/payouts`,
	marketing: (id) => `${BASE_EVENT_PATH}/${id}/marketing`,
	eventPromoters: (id) => `${BASE_EVENT_PATH}/${id}/promoters`,
	eventPromoterNetworks: (id) => `${BASE_EVENT_PATH}/${id}/promoter-networks`,
	eventPromoterNetwork: (id, networkId) => `${BASE_EVENT_PATH}/${id}/promoter-networks/${networkId}`,
	scanners: (id) => `${BASE_EVENT_PATH}/${id}/scanners`,
	fieldServices: (id) => `${BASE_EVENT_PATH}/${id}/request-field-services`,
	atTheDoor: (id) => `${BASE_EVENT_PATH}/${id}/at-the-door`,
	reports: (id) => `${BASE_EVENT_PATH}/${id}/reports`,
	settings: (id) => `${BASE_EVENT_PATH}/${id}/settings`,
	eventSettingsTicketing: (id) => `${BASE_EVENT_PATH}/${id}/ticket-settings`,
	integrations: (id) => `${BASE_EVENT_PATH}/${id}/integrations`,
	registrations: (id) => `${BASE_EVENT_PATH}/${id}/registrations`,
	eventMerchandise: (id) => `${BASE_EVENT_PATH}/${id}/merchandise`,
	promotionalCodes: (id) => `${BASE_EVENT_PATH}/${id}/promotional-codes`,
	eventUserAccess: (id) => `${BASE_EVENT_PATH}/${id}/user-access`,
	orderDetails: (id) => `${BASE_EVENT_PATH}/${id}/order-details`,
	eventVisitorsReport: (id) => `${BASE_EVENT_PATH}/${id}/visitors-report`,
	eventSalesReport: (id) => `${BASE_EVENT_PATH}/${id}/sales-report`,
	eventCheckInReport: (id) => `${BASE_EVENT_PATH}/${id}/check-in-report`,
	eventReviewsReport: (id) => `${BASE_EVENT_PATH}/${id}/reviews-report`,
	eventExportData: (id) => `${BASE_EVENT_PATH}/${id}/export-data`,
	eventMailChimp: (id) => `${BASE_EVENT_PATH}/${id}/mailchimp`,
	eventWidget: (id) => `${BASE_EVENT_PATH}/${id}/widget`,
	ticketTypes: (id) => `${BASE_EVENT_PATH}/${id}/tickets`,
	cancel: (id) => `${BASE_EVENT_PATH}/${id}/cancel`,
	postpone: (id) => `${BASE_EVENT_PATH}/${id}/postpone`,
	settingsEvent: (id) => `${BASE_EVENT_PATH}/${id}/details-settings`,
	organiserProfile: (id) => `${BASE_EVENT_PATH}/${id}/profile`,

	guestManagement: (id) => `${BASE_EVENT_PATH}/${id}/guest-management`,
	sendComplimentaryTickets: (id) => `${BASE_EVENT_PATH}/${id}/send-complimentary`,
	sendRSVPs: (id) => `${BASE_EVENT_PATH}/${id}/send-RSVP`,
	sendSMSs: (id) => `${BASE_EVENT_PATH}/${id}/send-SMS`,
	orders: (id) => `${BASE_EVENT_PATH}/${id}/orders`,
	guestList: (id) => `${BASE_EVENT_PATH}/${id}/guest-list`,
	printedTickets: (id) => `${BASE_EVENT_PATH}/${id}/printed-tickets`,
	eventScheduleTransfer: (id) => `${BASE_EVENT_PATH}/${id}/transfer-schedules`,

	campaigns: (id) => `${BASE_EVENT_PATH}/${id}/link-campaigns`,
	checkoutQuestions: (id) => `${BASE_EVENT_PATH}/${id}/checkout-questions`,
	definitions: (id) => `${BASE_EVENT_PATH}/${id}/definitions`,
	payoutDetails: (id) => `${BASE_EVENT_PATH}/${id}/payouts`,
	copyEvent: (id) => `${BASE_EVENT_PATH}/${id}/copy`,
	eventUserRegistrationDetails: (id, regId) => `${BASE_EVENT_PATH}/${id}/registrations/${regId}/user-registration-details`,
	ticketGroups: (id) => `${BASE_EVENT_PATH}/${id}/ticket-groups`,
	eventWebhooks: (id) => `${BASE_EVENT_PATH}/${id}/webhooks`,
};
