import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StoreService } from '@app/services/store/store.service';
import { SelectFieldOption } from '@app/shared/form-field/select-field/select-field.model';
import { MarkAsPaid } from '@app/store/actions/event/event.actions';
import { EventActionsConstants } from '@app/store/actions/event/event.actions.constants';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs';
import { ViewOrderGateway } from '@app/models/order.model';

@Component({
	selector: 'app-mark-as-paid-modal',
	templateUrl: './mark-as-paid-modal.component.html',
	styleUrls: ['./mark-as-paid-modal.component.sass'],
})
export class MarkAsPaidModalComponent implements OnInit {
	isSubmitLoading = false;
	form: FormGroup;
	paymentOptions: SelectFieldOption<number>[] = [];

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private store: StoreService,
		private actions$: Actions,
		public dialogRef: MatDialogRef<MarkAsPaidModalComponent>,
		@Inject(MAT_DIALOG_DATA)
		public data: {
			isMobile: boolean;
			orderId: number;
			eventId: number;
			gateways: ViewOrderGateway[];
			cartAmount: string;
			productPaymentGatewayDetailsLinkId: number;
		}
	) {}

	get isDisabled(): boolean {
		return !this.form.valid || this.isSubmitLoading;
	}

	ngOnInit(): void {
		this.form = new FormGroup({
			paymentMethod: new FormControl<number>(null, Validators.required),
			terms: new FormControl<boolean>(false, Validators.requiredTrue),
		});
		this.initialisePaymentMethod();
		this.initialiseMarkAsPaidListener();
	}

	initialisePaymentMethod() {
		if (this.data.gateways.length) {
			this.paymentOptions = this.data.gateways.map((gateway) => ({
				value: gateway.productPaymentGatewayDetailsLinkId,
				label: gateway.name + (gateway.active ? '' : ' (Disabled)'),
			}));
			this.form
				.get('paymentMethod')
				.setValue(
					this.data.productPaymentGatewayDetailsLinkId
				);
		}
	}

	initialiseMarkAsPaidListener() {
		this.actions$
			.pipe(ofType(EventActionsConstants.MARK_AS_PAID_SUCCESS, EventActionsConstants.MARK_AS_PAID_FAILED), takeUntil(this.destroyed$))
			.subscribe(({ type }: { type: string }) => {
				this.isSubmitLoading = false;
				if (type === EventActionsConstants.MARK_AS_PAID_SUCCESS) {
					this.dialogRef.close(true);
				}
			});
	}

	handleMarkAsPaid() {
		this.store.dispatch(
			new MarkAsPaid({
				eventId: this.data.eventId,
				orderId: this.data.orderId,
				productPaymentGatewayDetailsLinkId: +this.form.get('paymentMethod').value,
			})
		);
		this.isSubmitLoading = true;
	}

	handleCancel() {
		if (!this.isSubmitLoading) {
			this.dialogRef.close(false);
		}
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
