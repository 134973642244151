<div class="l-container">
	<div class="l-header">
		<h3 class="l-title" [ngClass]="{ 'mobile': data.isMobile }">{{ data.modalTitle }}</h3>
	</div>
	<div class="l-content">
		<p class="l-medium-width">{{ data.modalDescription }}</p>
	</div>
	<div class="l-button-container">
		<button class="g-primary-button" id="close-button" (click)="handleClose()">OKAY</button>
	</div>
</div>