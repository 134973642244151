import { EventActionsConstants, EventActionsConstants as constants } from './event.actions.constants';
import { FailedAction, AppAction } from '@app/models/action.model';
import {
	Venue,
	EventEntity,
	EventDashboard,
	EventDashboardModal,
	EventFacebookData,
	EventFacebookDataForm,
	EventOrderFilter,
	EventOrdersList,
	EventImage,
	PromotersPageInfo,
	ListResultInfo,
	GuestListRequestInfo,
	SelectedGuestInfo,
	ResendTicketsResult,
	EventRegistrationSettings,
	EventRegistrations,
	RegistrationFilter,
	PromoterNetworksPageInfo,
	NewPromoterNetworkInfo,
	EventLinkCampaign,
	DraftEventLinkCampaign,
	EventScanner,
	EventCollectInfo,
	EventCollectInfoTicket,
	EventCollectInfoBase,
	EventTab,
	DraftEventTab,
	EventDefinitions,
	EventTicketGroup,
	EventTicketGroupTicketType,
	EventCopySeatingChartRequest,
	EventCopySeatingChartResult,
	EventDetailsSettings,
	EventDesign,
	TestEventWebhook,
	EventWebhooks,
	EventScheduleTransferOptions,
	EventScheduleTransferSettings
} from '@app/models/event.model';
import { NormalizedScheme, SchemeID } from '@app/models/dataSchema.model';
import { HelpArticle } from '@app/models/help.model';
import { EventMetadata, EventDashboardReports, EventCopy, EventCopyInfo } from '@app/models/event-metadata.model';
import { EventEntityState } from '@app/models/store.model';
import {
	NormalizedEventEntityPayload,
	ProductStatus,
	EventCategory,
	EventFormat
} from '@app/models/event.model';
import { ProductType } from '@app/models/product.model';
import { CovidComplianceEnabled } from '@app/models/event.model';
import { ImageServiceType } from '@app/services/image/image.service';
import { CancelTicketsRequest, ChangedTicketTypes, Event, ViewOrder } from '@app/models/order.model';

export class GetPreviousVenues extends AppAction<{
	eventId: SchemeID;
}> {
	readonly type = constants.GET_VENUES;
}

export class GetPreviousVenuesSuccess extends AppAction<{
	venues: Venue[];
}> {
	readonly type = constants.GET_VENUES_SUCCESS;
}

export class GetPreviousVenuesFailed extends FailedAction {
	readonly type = constants.GET_VENUES_FAILED;
}

export class GetEvent extends AppAction <{
	productType: ProductType;
}> {
	readonly type = constants.GET_EVENT;
}

export class GetEventSuccess extends AppAction<{
	id: SchemeID;
	normalizedEntity: NormalizedEventEntityPayload;
	entity: Partial<EventEntityState>;
	productType: ProductType;
}> {
	readonly type = constants.GET_EVENT_SUCCESS;
}

export class GetEventFailed extends FailedAction {
	readonly type = constants.GET_EVENT_FAILED;
}

export class CreateEvent extends AppAction <{
	productType: ProductType;
}> {
	readonly type = constants.CREATE_EVENT;
}

export class CreateEventSuccess extends AppAction<{
	id: SchemeID;
	normalizedEntity: NormalizedEventEntityPayload;
	entity: Partial<EventEntityState>;
	productType: ProductType;
}> {
	readonly type = constants.CREATE_EVENT_SUCCESS;
}

export class CreateEventFailed extends FailedAction {
	readonly type = constants.CREATE_EVENT_FAILED;
}

export class UpdateEvent extends AppAction<{
	id: SchemeID;
	event: EventEntity;
	eventPart: number;
	isUpdating?: boolean;
}> {
	readonly type = constants.UPDATE_EVENT;
}

export class UpdateEventSuccess extends AppAction<{
	id: SchemeID;
	normalizedEntity: NormalizedEventEntityPayload;
	entity: Partial<EventEntityState>;
	eventPart: number;
}> {
	readonly type = constants.UPDATE_EVENT_SUCCESS;
}

export class UpdateEventFailed extends FailedAction {
	readonly type = constants.UPDATE_EVENT_FAILED;
}

export class SetActiveStepIndex extends AppAction<{ index: number }> {
	readonly type = constants.SET_ACTIVE_STEP_INDEX;
}

export class DetachEvent extends AppAction {
	readonly type = constants.DETACH_EVENT;
}

export class GetEventDetails extends AppAction<{
	id: number | string;
}> {
	readonly type = constants.GET_EVENT_DETAILS;
}

export class GetEventDetailsSuccess extends AppAction<{
	id: SchemeID;
	normalizedEntity: NormalizedEventEntityPayload;
	entity: Partial<EventEntityState>;
}> {
	readonly type = constants.GET_EVENT_DETAILS_SUCCESS;
}

export class GetEventDetailsFailed extends FailedAction {
	readonly type = constants.GET_EVENT_DETAILS_FAILED;
}

export class GetEventMetadata extends AppAction<{
	id: SchemeID;
}> {
	readonly type = constants.GET_EVENT_METADATA;
}

export class GetEventMetadataFailed extends FailedAction {
	readonly type = constants.GET_EVENT_METADATA_FAILED;
}

export class GetEventMetadataSuccess extends AppAction<{
	id: SchemeID;
	metadata: EventMetadata;
}> {
	readonly type = constants.GET_EVENT_METADATA_SUCCESS;
}

export class GetUserHelp extends AppAction<{
	type: HelpArticle;
}> {
	readonly type = constants.GET_USER_HELP;
}

export class GetUserHelpSuccess extends AppAction<{
	articleContent: string;
}> {
	readonly type = constants.GET_USER_HELP_SUCCESS;
}

export class GetUserHelpFailed extends FailedAction {
	readonly type = constants.GET_USER_HELP_FAILED;
}

export class SetEventListing extends AppAction<{
	id: SchemeID;
	isPublic: boolean;
}> {
	readonly type = constants.SET_EVENT_LISTING;
}

export class SetEventListingSuccess extends AppAction<{
	id: SchemeID;
	isPublic: boolean;
}> {
	readonly type = constants.SET_EVENT_LISTING_SUCCESS;
}

export class SetEventListingFailed extends FailedAction {
	readonly type = constants.SET_EVENT_LISTING_FAILED;
}

export class SetEventServiceFee extends AppAction<{
	id: SchemeID;
	isServiceFeeForHost: boolean;
}> {
	readonly type = constants.SET_EVENT_SERVICE_FEE;
}

export class SetEventServiceFeeSuccess extends AppAction<{
	id: SchemeID;
	isServiceFeeForHost: boolean;
}> {
	readonly type = constants.SET_EVENT_SERVICE_FEE_SUCCESS;
}

export class SetEventServiceFeeFailed extends FailedAction {
	readonly type = constants.SET_EVENT_SERVICE_FEE_FAILED;
}

export class SubmitEventForApproval extends AppAction<{
	id: SchemeID;
}> {
	readonly type = constants.SUBMIT_EVENT_FOR_APPROVAL;
}

export class SubmitEventForApprovalSuccess extends AppAction<{
	id: SchemeID;
	status: ProductStatus;
}> {
	readonly type = constants.SUBMIT_EVENT_FOR_APPROVAL_SUCCESS;
}

export class SubmitEventForApprovalFailed extends FailedAction {
	readonly type = constants.SUBMIT_EVENT_FOR_APPROVAL_FAILED;
}

export class GetEventDashboard extends AppAction<{
	id: SchemeID;
}> {
	readonly type = constants.GET_EVENT_DASHBOARD;
}

export class GetEventDashboardSuccess extends AppAction<{
	id: SchemeID;
	dashboard: EventDashboard;
}> {
	readonly type = constants.GET_EVENT_DASHBOARD_SUCCESS;
}

export class GetEventDashboardFailed extends FailedAction {
	readonly type = constants.GET_EVENT_DASHBOARD_FAILED;
}

export class SetEventShortLink extends AppAction<{
	id: SchemeID;
	link: string;
}> {
	readonly type = constants.SET_EVENT_SHORT_LINK;
}

export class SetEventShortLinkSuccess extends AppAction<{
	id: SchemeID;
	link: string;
}> {
	readonly type = constants.SET_EVENT_SHORT_LINK_SUCCESS;
}

export class SetEventShortLinkFailed extends FailedAction {
	readonly type = constants.SET_EVENT_SHORT_LINK_FAILED;
}

export class GetEventDashboardReports extends AppAction<{
	id: SchemeID;
	scheduleItemId: SchemeID;
}> {
	readonly type = constants.GET_EVENT_DASHBOARD_REPORTS;
}

export class GetEventDashboardReportsFailed extends FailedAction {
	readonly type = constants.GET_EVENT_DASHBOARD_REPORTS_FAILED;
}

export class GetEventDashboardReportsSuccess extends AppAction<{
	id: SchemeID;
	data: EventDashboardReports;
}> {
	readonly type = constants.GET_EVENT_DASHBOARD_REPORTS_SUCCESS;
}

export class ChangeEventDates extends AppAction<{
	id: SchemeID;
	startDateTime: Date;
	endDateTime: Date;
}> {
	readonly type = constants.CHANGE_EVENT_DATES;
}

export class ChangeEventDatesSuccess extends AppAction<{
	id: SchemeID;
	startDate: string;
	endDate: string;
}> {
	readonly type = constants.CHANGE_EVENT_DATES_SUCCESS;
}

export class ChangeEventDatesFailed extends FailedAction {
	readonly type = constants.CHANGE_EVENT_DATES_FAILED;
}

export class CancelEvent extends AppAction<{
	id: SchemeID;
}> {
	readonly type = constants.CANCEL_EVENT;
}

export class CancelEventSuccess extends AppAction<{
	id: SchemeID;
	isAutoCancelled: boolean;
	productStatus: number;
}> {
	readonly type = constants.CANCEL_EVENT_SUCCESS;
}

export class CancelEventFailed extends FailedAction {
	readonly type = constants.CANCEL_EVENT_FAILED;
}

export class GetEventTypes extends AppAction {
	readonly type = constants.GET_EVENT_TYPES;
}

export class GetEventTypesSuccess extends AppAction<{
	categories: EventCategory[];
	formats: EventFormat[];
}> {
	readonly type = constants.GGET_EVENT_TYPES_SUCCESS;
}

export class GetEventTypesFailed extends FailedAction {
	readonly type = constants.GET_EVENT_TYPES_FAILED;
}

export class SetEventDashboardModalFlag extends AppAction<{
	type: EventDashboardModal;
	isOpen?: boolean;
}> {
	readonly type = constants.SET_EVENT_DASHBOARD_MODAL_FLAG;
}

export class SetEventEntityFetchingFlag extends AppAction<{
	id: SchemeID;
	isFetching: boolean;
}> {
	readonly type = constants.SET_EVENT_ENTITY_FETCHING_FLAG;
}

export class SetEventFetchingFlag extends AppAction<{
	isFetching: boolean;
}> {
	readonly type = constants.SET_EVENT_FETCHING_FLAG;
}

export class SendInvites extends AppAction<{
	eventId: SchemeID;
	contactsListId: SchemeID[];
}> {
	readonly type = constants.SEND_INVITES;
}

export class SendInvitesSuccess extends AppAction {
	readonly type = constants.SEND_INVITES_SUCCESS;
}

export class SendInvitesFailed extends FailedAction {
	readonly type = constants.SEND_INVITES_FAILED;
}

export class GetFacebookIntegrationData extends AppAction<{
	eventId: SchemeID;
}> {
	readonly type = constants.GET_FACEBOOK_INTEGRATION_DATA;
}

export class GetFacebookIntegrationDataSuccess extends AppAction<{
	eventId: SchemeID;
	data: EventFacebookData;
}> {
	readonly type = constants.GET_FACEBOOK_INTEGRATION_DATA_SUCCESS;
}

export class GetFacebookIntegrationDataFailed extends FailedAction {
	readonly type = constants.GET_FACEBOOK_INTEGRATION_DATA_FAILED;
}

export class UpdateEventFacebookData extends AppAction<{
	eventId: SchemeID;
	data: EventFacebookDataForm;
	token: string;
}> {
	readonly type = constants.UPDATE_EVENT_FACEBOOK_DATA;
}

export class UpdateEventFacebookDataSuccess extends AppAction<{
	eventId: SchemeID;
	data: EventFacebookData;
	isAdding?: boolean;
}> {
	readonly type = constants.UPDATE_EVENT_FACEBOOK_DATA_SUCCESS;
}

export class UpdateEventFacebookDataFailed extends FailedAction {
	readonly type = constants.UPDATE_EVENT_FACEBOOK_DATA_FAILED;
}

export class ScheduleEventPublic extends AppAction<{
	id: SchemeID;
	scheduledToGoPublicOn: Date;
}> {
	readonly type = constants.SCHEDULE_EVENT_PUBLIC;
}

export class ScheduleEventPublicSuccess extends AppAction<{
	id: SchemeID;
	scheduledToGoPublicOn: Date;
}> {
	readonly type = constants.SCHEDULE_EVENT_PUBLIC_SUCCESS;
}

export class ScheduleEventPublicFailed extends FailedAction {
	readonly type = constants.SCHEDULE_EVENT_PUBLIC_FAILED;
}

export class DeleteScheduleEventPublic extends AppAction <{
	id: SchemeID;
}> {
	readonly type = constants.DELETE_SCHEDULE_EVENT_PUBLIC;
}

export class DeleteScheduleEventPublicSuccess extends AppAction <{
	id: SchemeID;
}> {
	readonly type = constants.DELETE_SCHEDULE_EVENT_PUBLIC_SUCCESS;
}

export class DeleteScheduleEventPublicFailed extends FailedAction {
	readonly type = constants.DELETE_SCHEDULE_EVENT_PUBLIC_FAILED;
}

export class SetEventCovidCompliant extends AppAction <{
	id: SchemeID;
	covidComplianceEnabled: CovidComplianceEnabled;
}> {
	readonly type = constants.SET_EVENT_COVID_COMPLIANT;
}

export class SetEventCovidCompliantSuccess extends AppAction <{
	id: SchemeID;
	covidComplianceEnabled: CovidComplianceEnabled;
}> {
	readonly type = constants.SET_EVENT_COVID_COMPLIANT_SUCCESS;
}

export class SetEventCovidCompliantFailed extends FailedAction {
	readonly type = constants.SET_EVENT_COVID_COMPLIANT_FAILED;
}

export class SetEventRedirectFlag extends AppAction<{
	id: SchemeID;
	isModalOpen: boolean;
}> {
	readonly type = constants.SET_EVENT_REDIRECT_MODAL_FLAG;
}

export class SetEventRedirectProductId extends AppAction<{
	id: SchemeID;
	redirectProductId: number;
}> {
	readonly type = constants.SET_EVENT_REDIRECT_PRODUCT_ID;
}

export class SetEventRedirectProductIdSuccess extends AppAction<{
	id: SchemeID;
	redirectProductId: number;
}> {
	readonly type = constants.SET_EVENT_REDIRECT_PRODUCT_ID_SUCCESS;
}

export class SetEventRedirectProductIdFailed extends FailedAction {
	readonly type = constants.SET_EVENT_REDIRECT_PRODUCT_ID_FAILED;
}

export class GetEventOrders extends AppAction <{
	eventId: SchemeID;
	filters: EventOrderFilter;
}> {
	readonly type = constants.GET_EVENT_ORDERS;
}

export class GetEventOrdersSuccess extends AppAction <{
	eventId: SchemeID;
	orders: EventOrdersList;
}>{
	readonly type  = constants.GET_EVENT_ORDERS_SUCCESS;
}

export class GetEventOrdersFailed extends FailedAction {
	readonly type = constants.GET_EVENT_ORDERS_FAILED;
}

export class GetOrderDetails extends AppAction <{
	orderId: SchemeID;
	eventId: SchemeID;
}> {
	readonly type = constants.GET_ORDER_DETAILS;
}

export class GetOrderDetailsSuccess extends AppAction <{
	eventId: SchemeID;
	order: ViewOrder;
}>{
	readonly type  = constants.GET_ORDER_DETAILS_SUCCESS;
}

export class GetOrderDetailsFailed extends FailedAction {
	readonly type = constants.GET_ORDER_DETAILS_FAILED;
}

export class EventImageUpload extends AppAction <{
	eventId: SchemeID;
	imageType: ImageServiceType;
	imageData: FormData;
	replace: boolean;
}> {
	readonly type = constants.EVENT_IMAGE_UPLOAD;
}

export class EventImageUploadSuccess extends AppAction <{
	eventId: SchemeID;
	image: EventImage;
}> {
	readonly type = constants.EVENT_IMAGE_UPLOAD_SUCCESS;
}

export class EventImageUploadFailed extends FailedAction {
	readonly type = constants.EVENT_IMAGE_UPLOAD_FAILED;
}

export class GetEventPromoters extends AppAction<{
	id: SchemeID;
}> {
	readonly type = constants.GET_EVENT_PROMOTERS;
}

export class GetEventPromotersSuccess extends AppAction<{
	id: SchemeID;
	promotersPageInfo: PromotersPageInfo;
}> {
	readonly type = constants.GET_EVENT_PROMOTERS_SUCCESS;
}

export class GetEventPromotersFailed extends FailedAction {
	readonly type = constants.GET_EVENT_PROMOTERS_FAILED;
}

export class SetPromotersIncentive extends AppAction<{
	id: SchemeID;
	incentive: string;
}> {
	readonly type = constants.SET_PROMOTERS_INCENTIVE;
}

export class SetPromotersIncentiveSuccess extends AppAction<{
	id: SchemeID;
	promotersPageInfo: PromotersPageInfo;
}> {
	readonly type = constants.SET_PROMOTERS_INCENTIVE_SUCCESS;
}

export class SetPromotersIncentiveFailed extends FailedAction {
	readonly type = constants.SET_PROMOTERS_INCENTIVE_FAILED;
}

export class GetEventPromoterNetworks extends AppAction<{
	id: SchemeID;
}> {
	readonly type = constants.GET_EVENT_PROMOTER_NETWORKS;
}

export class GetEventPromoterNetworksSuccess extends AppAction<{
	id: SchemeID;
	promoterNetworksPageInfo: PromoterNetworksPageInfo;
}> {
	readonly type = constants.GET_EVENT_PROMOTER_NETWORKS_SUCCESS;
}

export class GetEventPromoterNetworksFailed extends FailedAction {
	readonly type = constants.GET_EVENT_PROMOTER_NETWORKS_FAILED;
}

export class CreatePromoterNetwork extends AppAction<{
	id: SchemeID;
	newPromoterNetworkInfo: NewPromoterNetworkInfo;
}> {
	readonly type = constants.CREATE_PROMOTER_NETWORK;
}

export class CreatePromoterNetworkSuccess extends AppAction<{
	id: SchemeID;
	promoterNetworksPageInfo: PromoterNetworksPageInfo;
}> {
	readonly type = constants.CREATE_PROMOTER_NETWORK_SUCCESS;
}

export class CreatePromoterNetworkFailed extends FailedAction {
	readonly type = constants.CREATE_PROMOTER_NETWORK_FAILED;
}

export class GetEventGuestList extends AppAction <{
	guestListRequestInfo: GuestListRequestInfo;
}> {
	readonly type = constants.GET_EVENT_GUEST_LIST;
}

export class GetEventGuestListSuccess extends AppAction <{
	id: SchemeID;
	guestListResultInfo: ListResultInfo;
}> {
	readonly type = constants.GET_EVENT_GUEST_LIST_SUCCESS;
}

export class GetEventGuestListFailed extends FailedAction {
	readonly type = constants.GET_EVENT_GUEST_LIST_FAILED;
}

export class GetSelectedGuestInfo extends AppAction <{
	id: SchemeID;
	purchaseId: number;
	purchaseItemId: number;
}> {
	readonly type = constants.GET_SELECTED_GUEST_INFO;
}

export class GetSelectedGuestInfoSuccess extends AppAction <{
	id: SchemeID;
	selectedGuestInfo: SelectedGuestInfo;
}> {
	readonly type = constants.GET_SELECTED_GUEST_INFO_SUCCESS;
}

export class GetSelectedGuestInfoFailed extends FailedAction {
	readonly type = constants.GET_SELECTED_GUEST_INFO_FAILED;
}

export class  ResendTickets extends AppAction <{
	eventId: SchemeID;
	purchaseId: number;
}> {
	readonly type = constants.RESEND_TICKETS;
}

export class ResendTicketsSuccess extends AppAction <{
	eventId: SchemeID;
	mailSendingResult: ResendTicketsResult;
}> {
	readonly type = constants.RESEND_TICKETS_SUCCESS;
}

export class ResendTicketsFailed extends FailedAction {
	readonly type = constants.RESEND_TICKETS_FAILED;
}

export class GetEventRegistrationsSettings extends AppAction<{
	id: SchemeID;
}> {
	readonly type = constants.GET_REGISTRATION_SETTINGS;
}

export class GetEventRegistrationsSettingsSuccess extends AppAction<{
	id: SchemeID;
	registrationSettings: EventRegistrationSettings;
}> {
	readonly type = constants.GET_REGISTRATION_SETTINGS_SUCCESS;
}

export class GetEventRegistrationsSettingsFailed extends FailedAction {
	readonly type = constants.GET_REGISTRATION_SETTINGS_FAILED;
}

export class UpdateEventRegistrationsSettings extends AppAction<{
	id: SchemeID;
	registrationSettings: EventRegistrationSettings;
}> {
	readonly type = constants.UPDATE_REGISTRATION_SETTINGS;
}

export class UpdateEventRegistrationSettingsSuccess extends AppAction<{
	id: SchemeID;
	registrationSettings: EventRegistrationSettings;
}> {
	readonly type = constants.UPDATE_REGISTRATION_SETTINGS_SUCCESS;
}

export class UpdateEventRegistrationsSettingsFailed extends FailedAction {
	readonly type = constants.UPDATE_REGISTRATION_SETTINGS_FAILED;
}

export class GetRegisteredUsers extends AppAction<{
	id: SchemeID;
	filter: RegistrationFilter;
}> {
	readonly type = constants.GET_REGISTRATIONS;
}

export class GetRegisteredUsersSuccess extends AppAction<{
	id: SchemeID;
	eventRegistrations: EventRegistrations;
}> {
	readonly type = constants.GET_REGISTRATIONS_SUCCESS;
}

export class GetRegisteredUsersFailed extends FailedAction {
	readonly type = constants.GET_REGISTRATIONS_FAILED;
}

export class GetEventLinkCampaigns extends AppAction <{
	eventId: SchemeID;
}> {
	readonly type = constants.GET_EVENT_LINK_CAMPAIGNS;
}

export class GetEventLinkCampaignsSuccess extends AppAction<{
	eventId: SchemeID;
	campaigns: NormalizedScheme<EventLinkCampaign>;
}> {
	readonly type = constants.GET_EVENT_LINK_CAMPAIGNS_SUCCESS;
}

export class GetEventLinkCampaignsFailed extends FailedAction {
	readonly type = constants.GET_EVENT_LINK_CAMPAIGNS_FAILED;
}

export class DeleteEventLinkCampaign extends AppAction <{
	eventId: SchemeID;
	id: SchemeID;
}> {
	readonly type = constants.DELETE_EVENT_LINK_CAMPAIGN;
}

export class DeleteEventLinkCampaignSuccess extends AppAction <{
	eventId: SchemeID;
	id: SchemeID;
}> {
	readonly type = constants.DELETE_EVENT_LINK_CAMPAIGN_SUCCESS;
}

export class DeleteEventLinkCampaignFailed extends FailedAction {
	readonly type = constants.DELETE_EVENT_LINK_CAMPAIGN_FAILED;
}

export class AddEventLinkCampaign extends AppAction <{
	eventId: SchemeID;
	campaign: DraftEventLinkCampaign;
}> {
	readonly type = constants.ADD_EVENT_LINK_CAMPAIGN;
}
export class GetEventScanners extends AppAction <{
	id: SchemeID;
	scheduleItemId: SchemeID;
}> {
	readonly type = constants.GET_EVENT_SCANNERS;
}

export class GetEventScannersSuccess extends AppAction<{
	id: SchemeID;
	scheduleItemId: SchemeID;
	scanners: NormalizedScheme<EventScanner>;
}> {
	readonly type = constants.GET_EVENT_SCANNERS_SUCCESS;
}

export class GetEventScannersFailed extends FailedAction {
	readonly type = constants.GET_EVENT_SCANNERS_FAILED;
}

export class AddEventScanner extends AppAction <{
	id: SchemeID;
	scanner: EventScanner;
	scheduleItemId: SchemeID;
}> {
	readonly type = constants.ADD_EVENT_SCANNERS;
}

export class AddEventScannerSuccess extends AppAction<{
	id: SchemeID;
	scheduleItemId: SchemeID;
	scanners: NormalizedScheme<EventScanner>;
}> {
	readonly type = constants.ADD_EVENT_SCANNERS_SUCCESS;
}

export class AddEventScannerFailed extends FailedAction {
	readonly type = constants.ADD_EVENT_SCANNERS_FAILED;
}

export class EditEventScanner extends AppAction <{
	id: SchemeID;
	scanner: EventScanner;
	scheduleItemId: SchemeID;
}> {
	readonly type = constants.EDIT_EVENT_SCANNERS;
}

export class EditEventScannerSuccess extends AppAction <{
	id: SchemeID;
	scheduleItemId: SchemeID;
	scanners: NormalizedScheme<EventScanner>;
}> {
	readonly type = constants.EDIT_EVENT_SCANNERS_SUCCESS;
}

export class AddEventLinkCampaignSuccess extends AppAction <{
	eventId: SchemeID;
	campaign: EventLinkCampaign;
}> {
	readonly type = constants.ADD_EVENT_LINK_CAMPAIGN_SUCCESS;
}

export class AddEventLinkCampaignFailed extends FailedAction {
	readonly type = constants.ADD_EVENT_LINK_CAMPAIGN_FAILED;
}

export class GetEventTabbedContent extends AppAction<{
	eventId: SchemeID;
}> {
	readonly type = constants.GET_EVENT_TABBED_CONTENT;
}

export class GetEventTabbedContentSuccess extends AppAction<{
	eventId: SchemeID;
	tabs: NormalizedScheme<EventTab>;
}> {
	readonly type = constants.GET_EVENT_TABBED_CONTENT_SUCCESS;
}

export class GetEventTabbedContentFailed extends FailedAction {
	readonly type = constants.GET_EVENT_TABBED_CONTENT_FAILED;
}

export class ReorderTabbedContent extends AppAction<{
	eventId: SchemeID;
	order: SchemeID[];
	prevOrder: SchemeID[];
}> {
	readonly type = constants.REORDER_TABBED_CONTENT;
}

export class ReorderTabbedContentSuccess extends AppAction<{
	eventId: SchemeID;
	order: SchemeID[];
}> {
	readonly type = constants.REORDER_TABBED_CONTENT_SUCCESS;
}

export class ReorderTabbedContentFailed extends FailedAction<ReorderTabbedContent> {
	readonly type = constants.REORDER_TABBED_CONTENT_FAILED;
}

export class SetEventTabContentVisibility extends AppAction<{
	id: SchemeID;
	eventId: SchemeID;
	isVisible: boolean;
	prevValue: boolean;
}> {
	readonly type = constants.SET_TAB_CONTENT_VISIBILITY;
}
export class SetEventTabContentVisibilitySuccess extends AppAction<{
	id: SchemeID;
	eventId: SchemeID;
	isVisible: boolean;
}> {
	readonly type = constants.SET_TAB_CONTENT_VISIBILITY_SUCCESS;
}

export class SetEventTabContentVisibilityFailed extends FailedAction<SetEventTabContentVisibility> {
	readonly type = constants.SET_TAB_CONTENT_VISIBILITY_FAILED;
}

export class DeleteTabContent extends AppAction<{
	eventId: SchemeID;
	id: SchemeID;
}> {
	readonly type = constants.DELETE_TAB_CONTENT;
}

export class DeleteTabContentSuccess extends AppAction<{
	eventId: SchemeID;
	id: SchemeID;
}> {
	readonly type = constants.DELETE_TAB_CONTENT_SUCCESS;
}

export class DeleteTabContentFailed extends FailedAction {
	readonly type = constants.DELETE_TAB_CONTENT_FAILED;
}

export class UpsertTabContent extends AppAction<{
	eventId: SchemeID;
	tab: DraftEventTab;
	isUpdating: boolean;
}> {
	readonly type = constants.UPSERT_TAB_CONTENT;
}

export class UpsertTabContentSuccess extends AppAction<{
	eventId: SchemeID;
	tab: EventTab;
	isUpdating: boolean;
}> {
	readonly type = constants.UPSERT_TAB_CONTENT_SUCCESS;
}

export class UpsertTabContentFailed extends FailedAction {
	readonly type = constants.UPSERT_TAB_CONTENT_FAILED;
}

export class TabbedContentImageUpload extends AppAction <{
	eventId: SchemeID;
	imageType: ImageServiceType;
	imageData: FormData;
	replace: boolean;
	tabContentUid?: number;
	imageString: string;
}> {
	readonly type = constants.TABBED_CONTENT_UPLOAD_IMAGE;
}

export class TabbedContentImageUploadSuccess extends AppAction <{
	eventId: SchemeID;
	image: EventImage;
	tabContentUid?: number;
	imageString: string;
}> {
	readonly type = constants.TABBED_CONTENT_UPLOAD_IMAGE_SUCCESS;
}

export class EditEventScannerFailed extends FailedAction {
	readonly type = constants.EDIT_EVENT_SCANNERS_FAILED;
}

export class NewPinEventScanner extends AppAction <{
	id: SchemeID;
	scheduleItemId: SchemeID;
	scanner: EventScanner;
}> {
	readonly type = constants.NEW_PIN_EVENT_SCANNER;
}

export class NewPinEventScannerSuccess extends AppAction <{
	id: SchemeID;
	scheduleItemId: SchemeID;
	scanners: NormalizedScheme<EventScanner>;
}> {
	readonly type = constants.NEW_PIN_EVENT_SCANNER_SUCCESS;
}

export class NewPinEventScannerFailed extends FailedAction<NewPinEventScanner> {
	readonly type = constants.NEW_PIN_EVENT_SCANNER_FAILED;
}

export class GetEventRequestFieldServices extends AppAction <{
	id: SchemeID;
}> {
	readonly type = constants.GET_EVENT_REQUEST_FIELD_SERVICES;
}

export class GetEventRequestFieldServicesSuccess extends AppAction <{
	id: SchemeID;
	ratesUrl: string;
}> {
	readonly type = constants.GET_EVENT_REQUEST_FIELD_SERVICES_SUCCESS;
}
export class GetEventRequestFieldServicesFailed extends FailedAction {
	readonly type = constants.GET_EVENT_REQUEST_FIELD_SERVICES_FAILED;
}

export class TabbedContentImageUploadFailed extends FailedAction {
	readonly type = constants.TABBED_CONTENT_UPLOAD_IMAGE_FAILED;
}

export class GetEventCollectInfo extends AppAction<{
	eventId: SchemeID;
	isRegistration: boolean;
}> {
	readonly type = constants.GET_EVENT_COLLECT_INFO;
}

export class GetEventCollectInfoSuccess extends AppAction<{
	eventId: SchemeID;
	collectInfo: NormalizedScheme<EventCollectInfo>;
	tickets: EventCollectInfoTicket[];
}> {
	readonly type = constants.GET_EVENT_COLLECT_INFO_SUCCESS;
}

export class GetEventCollectInfoFailed extends FailedAction {
	readonly type = constants.GET_EVENT_COLLECT_INFO_FAILED;
}

export class UpsertEventCollectInfo extends AppAction<{
	eventId: SchemeID;
	collectInfo: EventCollectInfo;
	isUpdating: boolean;
}> {
	readonly type = constants.UPSERT_EVENT_COLLECT_INFO;
}

export class UpsertEventCollectInfoSuccess extends AppAction<{
	eventId: SchemeID;
	collectInfo: EventCollectInfo;
	isUpdating: boolean;
}> {
	readonly type = constants.UPSERT_EVENT_COLLECT_INFO_SUCCESS;
}

export class UpsertEventCollectInfoFailed extends FailedAction<UpsertEventCollectInfo> {
	readonly type = constants.UPSERT_EVENT_COLLECT_INFO_FAILED;
}

export class GetProfileCollectInfo extends AppAction<{
	eventId: SchemeID;
	isRegistration: boolean;
}> {
	readonly type = constants.GET_PROFILE_COLLECT_INFO;
}

export class GetProfileCollectInfoSuccess extends AppAction<{
	eventId: SchemeID;
	collectInfo: NormalizedScheme<EventCollectInfoBase>;
}> {
	readonly type = constants.GET_PROFILE_COLLECT_INFO_SUCCESS;
}

export class GetEventDefinitions extends AppAction <{
	id: SchemeID;
}> {
	readonly type = EventActionsConstants.GET_EVENT_DEFINITIONS;
}

export class GetEventDefinitionsSuccess extends AppAction<{
	id: SchemeID;
	definitions: EventDefinitions;
}> {
	readonly type = EventActionsConstants.GET_EVENT_DEFINITIONS_SUCCESS;
}

export class GetEventDefinitionsFailed extends FailedAction {
	readonly type = EventActionsConstants.GET_EVENT_DEFINITIONS_FAILED;
}

export class UpdateEventDefinitions extends AppAction<{
	id: SchemeID;
	definitions: EventDefinitions;
}> {
	readonly type = EventActionsConstants.UPDATE_EVENT_DEFINITIONS;
}

export class UpdateEventDefinitionsSuccess extends AppAction<{
	id: SchemeID;
	definitions: EventDefinitions;
}> {
	readonly type = EventActionsConstants.UPDATE_EVENT_DEFINITIONS_SUCCESS;
}

export class GetProfileCollectInfoFailed extends FailedAction {
	readonly type = constants.GET_PROFILE_COLLECT_INFO_FAILED;
}

export class UpdateEventDefinitionsFailed extends FailedAction {
	readonly type = EventActionsConstants.UPDATE_EVENT_DEFINITIONS_FAILED;
}

type CollectionInfoProperty = keyof EventCollectInfo;

export class SetCollectInfoProperty<T extends CollectionInfoProperty = CollectionInfoProperty> extends AppAction<{
	id: SchemeID;
	eventId: SchemeID;
	value: EventCollectInfo[T];
	prevValue: EventCollectInfo[T];
	name: T;
}> {
	readonly type = constants.SET_COLLECT_INFO_PROPERTY;
}

export class SetCollectInfoPropertySuccess<T extends CollectionInfoProperty = CollectionInfoProperty> extends AppAction<{
	id: SchemeID;
	eventId: SchemeID;
	value: EventCollectInfo[T];
	name: T;
}> {
	readonly type = constants.SET_COLLECT_INFO_PROPERTY_SUCCESS;
}

export class SetCollectInfoPropertyFailed extends FailedAction<SetCollectInfoProperty> {
	readonly type = constants.SET_COLLECT_INFO_PROPERTY_FAILED;
}

export class DeleteCollectInfo extends AppAction<{
	eventId: SchemeID;
	id: SchemeID;
}> {
	readonly type = constants.DELETE_COLLECT_INFO;
}

export class DeleteCollectInfoSuccess extends AppAction<{
	eventId: SchemeID;
	id: SchemeID;
}> {
	readonly type = constants.DELETE_COLLECT_INFO_SUCCESS;
}

export class DeleteCollectInfoFailed extends FailedAction {
	readonly type = constants.DELETE_COLLECT_INFO_FAILED;
}

export class GetEventDesign extends AppAction<{
	id: SchemeID;
}> {
	readonly type = constants.GET_EVENT_DESIGN;
}


export class GetEventDesignSuccess extends AppAction<{
	id: SchemeID;
	design: EventDesign;
}> {
	readonly type = constants.GET_EVENT_DESIGN_SUCCESS;
}

export class GetEventDesignFailed extends FailedAction {
	readonly type = constants.GET_EVENT_DESIGN_FAILED;
}

export class UpdateEventDesign extends AppAction<{
	id: SchemeID;
	design: EventDesign;
}> {
	readonly type = constants.UPDATE_EVENT_DESIGN;
}

export class UpdateEventDesignSuccess extends AppAction<{
	id: SchemeID;
	design: EventDesign;
}> {
	readonly type = constants.UPDATE_EVENT_DESIGN_SUCCESS;
}

export class UpdateEventDesignFailed extends FailedAction {
	readonly type = constants.UPDATE_EVENT_DESIGN_FAILED;
}


export class ReorderCollectInfo extends AppAction<{
	eventId: SchemeID;
	order: SchemeID[];
	prevOrder: SchemeID[];
	isRegistration: boolean;
}> {
	readonly type = constants.REORDER_COLLECT_INFO;
}

export class ReorderCollectInfoSuccess extends AppAction<{
	eventId: SchemeID;
	order: SchemeID[];
	prevOrder: SchemeID[];
}> {
	readonly type = constants.REORDER_COLLECT_INFO_SUCCESS;
}

export class ReorderCollectInfoFailed extends FailedAction<ReorderCollectInfo> {
	readonly type = constants.REORDER_COLLECT_INFO_FAILED;
}

export class GetEventCopyInfo extends AppAction<{
	eventId: SchemeID;
}> {
	readonly type = constants.GET_COPY_EVENT_INFO;
}

export class GetEventCopyInfoSuccess extends AppAction<{
	eventId: SchemeID;
	copyInfo: EventCopyInfo;
}> {
	readonly type = constants.GET_COPY_EVENT_INFO_SUCCESS;
}

export class GetEventCopyInfoFailed extends FailedAction {
	readonly type = constants.GET_COPY_EVENT_INFO_FAILED;
}

export class CopyEvent extends AppAction<{
	eventId: SchemeID;
	copyInfo: EventCopy;
	timezone: string;
}> {
	readonly type = constants.COPY_EVENT;
}

export class CopyEventSuccess extends AppAction<{
	eventId: SchemeID;
	copyInfo: EventCopy;
}> {
	readonly type = constants.COPY_EVENT_SUCCESS;
}

export class CopyEventFailed extends FailedAction {
	readonly type = constants.COPY_EVENT_FAILED;
}

export class SkipEventCompletionItem extends AppAction<{
	id: SchemeID;
	itemId: number;
}> {
	readonly type = constants.UPDATE_EVENT_SKIP_COMPLETION_ITEM;
}

export class SkipEventCompletionItemSuccess extends AppAction<{
	eventId: SchemeID;
	dashboard: EventDashboard;
}> {
	readonly type = constants.UPDATE_EVENT_SKIP_COMPLETION_ITEM_SUCCESS;
}

export class SkipEventCompletionItemFailed extends FailedAction {
	readonly type = constants.UPDATE_EVENT_SKIP_COMPLETION_ITEM_FAILED;
}

export class GetEventTicketGroups extends AppAction<{
	eventId: SchemeID;
}> {
	readonly type = EventActionsConstants.GET_EVENT_TICKET_GROUPS;
}

export class GetEventTicketGroupsSuccess extends AppAction<{
	eventId: SchemeID;
	ticketGroups: NormalizedScheme<EventTicketGroup>;
	ticketTypes: NormalizedScheme<EventTicketGroupTicketType>;
	useTicketGroups: boolean;
}> {
	readonly type = EventActionsConstants.GET_EVENT_TICKET_GROUPS_SUCCESS;
}

export class GetEventTicketGroupsFailed extends FailedAction {
	readonly type = EventActionsConstants.GET_EVENT_TICKET_GROUPS_FAILED;
}

export class DeleteEventTicketGroup extends AppAction<{
	id: SchemeID;
	eventId: SchemeID;
}> {
	readonly type = EventActionsConstants.DELETE_TICKET_GROUP;
}

export class DeleteEventTicketGroupSuccess extends AppAction<{
	id: SchemeID;
	eventId: SchemeID;
}> {
	readonly type = EventActionsConstants.DELETE_TICKET_GROUP_SUCCESS;
}

export class DeleteEventTicketGroupFailed extends FailedAction {
	readonly type = EventActionsConstants.DELETE_TICKET_GROUP_FAILED;
}

export class UpsertTicketGroup extends AppAction<{
	eventId: SchemeID;
	ticketGroup: EventTicketGroup;
	isUpdating: boolean;
}> {
	readonly type = EventActionsConstants.UPSERT_TICKET_GROUP;
}

export class UpsertTicketGroupSuccess extends AppAction<{
	eventId: SchemeID;
	ticketGroup: EventTicketGroup;
	isUpdating: boolean;
}> {
	readonly type = EventActionsConstants.UPSERT_TICKET_GROUP_SUCCESS;
}

export class UpsertTicketGroupFailed extends FailedAction {
	readonly type = EventActionsConstants.UPSERT_TICKET_GROUP_FAILED;
}

export class SetTicketGroupsUsage extends AppAction<{
	eventId: SchemeID;
	useTicketGroups: boolean;
	prevValue: boolean;
}> {
	readonly type = EventActionsConstants.SET_TICKET_GROUPS_USAGE;
}

export class SetTicketGroupsUsageSuccess extends AppAction<{
	eventId: SchemeID;
	useTicketGroups: boolean;
}> {
	readonly type = EventActionsConstants.SET_TICKET_GROUPS_USAGE_SUCCESS;
}

export class SetTicketGroupsUsageFailed extends FailedAction<SetTicketGroupsUsage> {
	readonly type = EventActionsConstants.SET_TICKET_GROUPS_USAGE_FAILED;
}

export class ReorderTicketGroup extends AppAction<{
	eventId: SchemeID;
	order: SchemeID[];
	prevOrder: SchemeID[];
}> {
	readonly type = EventActionsConstants.REORDER_TICKET_GROUP;
}

export class ReorderTicketGroupSuccess extends AppAction<{
	eventId: SchemeID;
	order: SchemeID[];
}> {
	readonly type = EventActionsConstants.REORDER_TICKET_GROUP_SUCCESS;
}

export class ReorderTicketGroupFailed extends FailedAction<ReorderTicketGroup> {
	readonly type = EventActionsConstants.REORDER_TICKET_GROUP_FAILED;
}

export class GetEventSettings extends AppAction<{
	id: SchemeID;
}> {
	readonly type = constants.GET_EVENT_SETTINGS;
}

export class GetEventSettingsSuccess extends AppAction<{
	settings: EventDetailsSettings;
	id: SchemeID;
}> {
	readonly type = constants.GET_EVENT_SETTINGS_SUCCESS;
}

export class GetEventSettingsFailed extends FailedAction {
	readonly type = constants.GET_EVENT_SETTINGS_FAILED;
}

export class UpdateEventSettings extends AppAction<{
	id: SchemeID;
	settings?: EventDetailsSettings;
}> {
	readonly type = constants.UPDATE_EVENT_SETTINGS;
}

export class UpdateEventSettingsSuccess extends AppAction<{
	id: SchemeID;
	settings?: EventDetailsSettings;
}> {
	readonly type = constants.UPDATE_EVENT_SETTINGS_SUCCESS;
}

export class UpdateEventSettingsFailed extends FailedAction {
	readonly type = constants.UPDATE_EVENT_SETTINGS_FAILED;
}

export class GetEventWebhooks extends AppAction<{
	eventId: SchemeID;
}> {
	readonly type = constants.GET_EVENT_WEBHOOKS;
}

export class GetEventWebhooksSuccess extends AppAction<{
	eventId: SchemeID;
	webhooks: EventWebhooks;
}> {
	readonly type = constants.GET_EVENT_WEBHOOKS_SUCCESS;
}

export class GetEventWebhooksFailed extends FailedAction {
	readonly type = constants.GET_EVENT_WEBHOOKS_FAILED;
}
export class UpdateEventWebhooks extends AppAction<{
	eventId: SchemeID;
	webhooks: EventWebhooks;
	isUpdating: boolean;
}> {
	readonly type = constants.UPDATE_EVENT_WEBHOOKS;
}

export class UpdateEventWebhooksSuccess extends AppAction<{
	eventId: SchemeID;
	webhooks: EventWebhooks;
	isUpdating: boolean;
}> {
	readonly type = constants.UPDATE_EVENT_WEBHOOKS_SUCCESS;
}

export class UpdateEventWebhooksFailed extends FailedAction {
	readonly type = constants.UPDATE_EVENT_WEBHOOKS_FAILED;
}

export class TestEventWebhooks extends AppAction<{
	eventId: SchemeID;
	testWebhook: TestEventWebhook;
}> {
	readonly type = constants.TEST_EVENT_WEBHOOKS;
}

export class TestEventWebhooksSuccess extends AppAction<{
	eventId: SchemeID;
	testWebhook: TestEventWebhook;
}> {
	readonly type = constants.TEST_EVENT_WEBHOOKS_SUCCESS;
}

export class TestEventWebhooksFailed extends FailedAction {
	readonly type = constants.TEST_EVENT_WEBHOOKS_FAILED;
}

export class CopySeatingChart extends AppAction<{
	copySeatingChartRequest: EventCopySeatingChartRequest;
}> {
	readonly type = constants.COPY_SEATING_CHART;
}

export class CopySeatingChartSuccess extends AppAction<{
	copySeatingChartResult: EventCopySeatingChartResult;
}> {
	readonly type = constants.COPY_SEATING_CHART_SUCCESS;
}

export class CopySeatingChartFailed extends FailedAction {
	readonly type = constants.COPY_SEATING_CHART_FAILED;
}

export class GetEventScheduleTransfer extends AppAction <{
	eventId: SchemeID;
}> {
	readonly type = constants.GET_EVENT_SCHEDULE_TRANSFER;
}

export class GetEventScheduleTransferSuccess extends AppAction <{
	eventId: SchemeID;
	scheduleItems: NormalizedScheme<EventScheduleTransferOptions>;
	settings: EventScheduleTransferSettings;
}> {
	readonly type = constants.GET_EVENT_SCHEDULE_TRANSFER_SUCCESS;
}

export class GetEventScheduleTransferFailed extends FailedAction {
	readonly type = constants.GET_EVENT_SCHEDULE_TRANSFER_FAILED;
}

export class UpdateEventScheduleTransfer extends AppAction <{
	eventId: SchemeID;
	settings: EventScheduleTransferSettings;
}> {
	readonly type = constants.UPDATE_EVENT_SCHEDULE_TRANSFER;
}

export class UpdateEventScheduleTransferSuccess extends AppAction <{
	eventId: SchemeID;
	scheduleItems: NormalizedScheme<EventScheduleTransferOptions>;
	settings: EventScheduleTransferSettings;
}> {
	readonly type = constants.UPDATE_EVENT_SCHEDULE_TRANSFER_SUCCESS;
}

export class UpdateEventScheduleTransferFailed extends FailedAction {
	readonly type = constants.UPDATE_EVENT_SCHEDULE_TRANSFER_FAILED;
}

export class DeleteEvent extends AppAction <{
	eventId: SchemeID;
}> {
	readonly type = constants.DELETE_EVENT;
}

export class DeleteEventSuccess extends AppAction <{
	success: boolean;
}> {
	readonly type = constants.DELETE_EVENT_SUCCESS;
}

export class DeleteEventFailed extends FailedAction {
	readonly type = constants.DELETE_EVENT_FAILED;
}

export class ChangeTicketTypes extends AppAction <{
	orderId: SchemeID;
	eventId: SchemeID;
	changedTickets: ChangedTicketTypes;
}> {
	readonly type = constants.CHANGE_TICKET_TYPES;
}

export class ChangeTicketTypesSuccess extends AppAction <{
	eventId: SchemeID;
}>{
	readonly type  = constants.CHANGE_TICKET_TYPES_SUCCESS;
}

export class ChangeTicketTypesFailed extends FailedAction {
	readonly type = constants.CHANGE_TICKET_TYPES_FAILED;
}

export class CancelTickets extends AppAction <{
	orderId: SchemeID;
	eventId: SchemeID;
	cancelTickets: CancelTicketsRequest;
}> {
	readonly type = constants.CANCEL_TICKETS;
}

export class CancelTicketsSuccess extends AppAction <{
	eventId: SchemeID;
}>{
	readonly type  = constants.CANCEL_TICKETS_SUCCESS;
}

export class CancelTicketsFailed extends FailedAction {
	readonly type = constants.CANCEL_TICKETS_FAILED;
}

export class ResendOrderTickets extends AppAction <{
	purchaseId: SchemeID;
	email: string;
}> {
	readonly type = constants.RESEND_ORDER_TICKETS;
}

export class ResendOrderTicketsSuccess extends AppAction <{
	response: string;
}> {
	readonly type = constants.RESEND_ORDER_TICKETS_SUCCESS;
}

export class ResendOrderTicketsFailed extends FailedAction {
	readonly type = constants.RESEND_ORDER_TICKETS_FAILED;
}

export class GetEventOldAPI extends AppAction <{
	eventId: SchemeID;
}> {
	readonly type = constants.GET_EVENT_OLD_API;
}

export class GetEventOldAPISuccess extends AppAction <{
	eventId: SchemeID;
	event: Event;
}>{
	readonly type  = constants.GET_EVENT_OLD_API_SUCCESS;
}

export class GetEventOldAPIFailed extends FailedAction {
	readonly type = constants.GET_EVENT_OLD_API_FAILED;
}

export class MarkAsPaid extends AppAction <{
	eventId: SchemeID;
	orderId: SchemeID;
	productPaymentGatewayDetailsLinkId: number;
}> {
	readonly type = constants.MARK_AS_PAID;
}

export class MarkAsPaidSuccess extends AppAction <{
	success: boolean;
}>{
	readonly type  = constants.MARK_AS_PAID_SUCCESS;
}

export class MarkAsPaidFailed extends FailedAction {
	readonly type = constants.MARK_AS_PAID_FAILED;
}

export type Actions =
	| GetPreviousVenues
	| GetPreviousVenuesFailed
	| GetPreviousVenuesSuccess
	| GetEvent
	| GetEventFailed
	| GetEventSuccess
	| CreateEvent
	| CreateEventFailed
	| CreateEventSuccess
	| UpdateEvent
	| UpdateEventFailed
	| UpdateEventSuccess
	| SetActiveStepIndex
	| DetachEvent
	| GetEventDetails
	| GetEventDetailsFailed
	| GetEventDetailsSuccess
	| GetEventMetadata
	| GetEventMetadataFailed
	| GetEventMetadataSuccess
	| GetUserHelp
	| GetUserHelpFailed
	| GetUserHelpSuccess
	| SetEventListing
	| SetEventListingFailed
	| SetEventListingSuccess
	| SetEventServiceFee
	| SetEventServiceFeeFailed
	| SetEventServiceFeeSuccess
	| SubmitEventForApproval
	| SubmitEventForApprovalFailed
	| SubmitEventForApprovalSuccess
	| GetEventDashboard
	| GetEventDashboardFailed
	| GetEventDashboardSuccess
	| SetEventShortLink
	| SetEventShortLinkSuccess
	| SetEventShortLinkFailed
	| GetEventDashboardReports
	| GetEventDashboardReportsFailed
	| GetEventDashboardReportsSuccess
	| ChangeEventDates
	| ChangeEventDatesSuccess
	| ChangeEventDatesFailed
	| CancelEvent
	| CancelEventSuccess
	| CancelEventFailed
	| GetEventTypes
	| GetEventTypesSuccess
	| GetEventTypesFailed
	| SetEventDashboardModalFlag
	| SetEventEntityFetchingFlag
	| SetEventFetchingFlag
	| SendInvites
	| SendInvitesSuccess
	| SendInvitesFailed
	| GetFacebookIntegrationData
	| GetFacebookIntegrationDataFailed
	| GetFacebookIntegrationDataSuccess
	| UpdateEventFacebookData
	| UpdateEventFacebookDataFailed
	| UpdateEventFacebookDataSuccess
	| ScheduleEventPublic
	| ScheduleEventPublicSuccess
	| ScheduleEventPublicFailed
	| DeleteScheduleEventPublic
	| DeleteScheduleEventPublicSuccess
	| DeleteScheduleEventPublicFailed
	| SetEventCovidCompliant
	| SetEventCovidCompliantSuccess
	| SetEventCovidCompliantFailed
	| SetEventRedirectFlag
	| SetEventRedirectProductId
	| SetEventRedirectProductIdSuccess
	| SetEventRedirectProductIdFailed
	| GetEventOrders
	| GetEventOrdersSuccess
	| GetEventOrdersFailed
	| GetEventPromoters
	| GetEventPromotersSuccess
	| GetEventPromotersFailed
	| SetPromotersIncentive
	| SetPromotersIncentiveSuccess
	| SetPromotersIncentiveFailed
	| GetEventPromoterNetworks
	| GetEventPromoterNetworksSuccess
	| GetEventPromoterNetworksFailed
	| CreatePromoterNetwork
	| CreatePromoterNetworkSuccess
	| CreatePromoterNetworkFailed
	| GetEventGuestList
	| GetEventGuestListSuccess
	| GetEventGuestListFailed
	| GetSelectedGuestInfo
	| GetSelectedGuestInfoSuccess
	| GetSelectedGuestInfoFailed
	| ResendTickets
	| ResendTicketsSuccess
	| ResendTicketsFailed
	| GetEventRegistrationsSettings
	| GetEventRegistrationsSettingsSuccess
	| GetEventRegistrationsSettingsFailed
	| UpdateEventRegistrationsSettings
	| UpdateEventRegistrationSettingsSuccess
	| UpdateEventRegistrationsSettingsFailed
	| GetRegisteredUsers
	| GetRegisteredUsersSuccess
	| GetRegisteredUsersFailed
	| EventImageUpload
	| EventImageUploadSuccess
	| EventImageUploadFailed
	| GetRegisteredUsersFailed
	| GetEventScanners
	| GetEventScannersSuccess
	| GetEventScannersFailed
	| AddEventScanner
	| AddEventScannerSuccess
	| AddEventScannerFailed
	| EditEventScanner
	| EditEventScannerSuccess
	| EditEventScannerFailed
	| NewPinEventScanner
	| NewPinEventScannerSuccess
	| NewPinEventScannerFailed
	| GetEventTabbedContent
	| GetEventTabbedContentSuccess
	| GetEventTabbedContentFailed
	| ReorderTabbedContent
	| ReorderTabbedContentSuccess
	| ReorderTabbedContentFailed
	| SetEventTabContentVisibility
	| SetEventTabContentVisibilitySuccess
	| SetEventTabContentVisibilityFailed
	| DeleteTabContent
	| DeleteTabContentSuccess
	| DeleteTabContentFailed
	| UpsertTabContent
	| UpsertTabContentSuccess
	| UpsertTabContentFailed
	| TabbedContentImageUpload
	| TabbedContentImageUploadSuccess
	| TabbedContentImageUploadFailed
	| EventImageUploadFailed
	| GetEventLinkCampaigns
	| GetEventLinkCampaignsSuccess
	| GetEventLinkCampaignsFailed
	| DeleteEventLinkCampaign
	| DeleteEventLinkCampaignSuccess
	| DeleteEventLinkCampaignFailed
	| AddEventLinkCampaign
	| AddEventLinkCampaignSuccess
	| AddEventLinkCampaignFailed
	| GetEventRequestFieldServices
	| GetEventRequestFieldServicesSuccess
	| GetEventRequestFieldServicesFailed
	| ResendTicketsFailed
	| SetEventRedirectProductIdFailed
	| GetEventCollectInfo
	| GetEventCollectInfoSuccess
	| GetEventCollectInfoFailed
	| SetCollectInfoProperty
	| SetCollectInfoPropertyFailed
	| SetCollectInfoPropertySuccess
	| DeleteCollectInfo
	| DeleteCollectInfoSuccess
	| DeleteCollectInfoFailed
	| GetProfileCollectInfo
	| GetProfileCollectInfoSuccess
	| GetProfileCollectInfoFailed
	| UpsertEventCollectInfo
	| UpsertEventCollectInfoFailed
	| UpsertEventCollectInfoSuccess
	| ReorderCollectInfo
	| ReorderCollectInfoFailed
	| ReorderCollectInfoSuccess
	| GetEventDefinitions
	| GetEventDefinitionsSuccess
	| GetEventDefinitionsFailed
	| UpdateEventDefinitions
	| UpdateEventDefinitionsSuccess
	| UpdateEventDefinitionsFailed
	| GetEventRequestFieldServicesFailed
	| SkipEventCompletionItem
	| SkipEventCompletionItemSuccess
	| SkipEventCompletionItemFailed
	| GetEventDesign
	| GetEventDesignSuccess
	| GetEventDesignFailed
	| UpdateEventDesign
	| UpdateEventDesignSuccess
	| UpdateEventDesignFailed
	| GetEventCopyInfo
	| GetEventCopyInfoSuccess
	| GetEventCopyInfoFailed
	| CopyEvent
	| CopyEventSuccess
	| CopyEventFailed
	| GetEventTicketGroups
	| GetEventTicketGroupsSuccess
	| GetEventTicketGroupsFailed
	| DeleteEventTicketGroup
	| DeleteEventTicketGroupSuccess
	| DeleteEventTicketGroupFailed
	| UpsertTicketGroup
	| UpsertTicketGroupSuccess
	| UpsertTicketGroupFailed
	| SetTicketGroupsUsage
	| SetTicketGroupsUsageSuccess
	| SetTicketGroupsUsageFailed
	| ReorderTicketGroup
	| ReorderTicketGroupSuccess
	| ReorderTicketGroupFailed
	| GetEventWebhooks
	| GetEventWebhooksSuccess
	| GetEventWebhooksFailed
	| UpdateEventWebhooks
	| UpdateEventWebhooksSuccess
	| UpdateEventWebhooksFailed
	| TestEventWebhooks
	| TestEventWebhooksSuccess
	| TestEventWebhooksFailed
	| ReorderTicketGroupFailed
	| GetEventSettings
	| GetEventSettingsFailed
	| GetEventSettingsSuccess
	| UpdateEventSettings
	| UpdateEventSettingsFailed
	| UpdateEventSettingsSuccess
	| GetEventScheduleTransfer
	| GetEventScheduleTransferSuccess
	| GetEventScheduleTransferFailed
	| UpdateEventScheduleTransfer
	| UpdateEventScheduleTransferSuccess
	| UpdateEventScheduleTransferFailed
	| DeleteEvent
	| DeleteEventSuccess
	| DeleteEventFailed
	| GetOrderDetails
	| GetOrderDetailsSuccess
	| GetOrderDetailsFailed
	| ChangeTicketTypes
	| ChangeTicketTypesSuccess
	| ChangeTicketTypesFailed
	| ResendOrderTickets
	| ResendOrderTicketsSuccess
	| ResendOrderTicketsFailed
	| CancelTickets
	| CancelTicketsSuccess
	| CancelTicketsFailed
	| GetEventOldAPI
	| GetEventOldAPISuccess
	| GetEventOldAPIFailed
	| MarkAsPaid
	| MarkAsPaidSuccess
	| MarkAsPaidFailed;
