import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { Component, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SchemeID } from '@app/models/dataSchema.model';
import { HeaderContent } from '@app/models/shared';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { InternalURLCreator } from '@app/services/url/url.dictionary';
import { EVENT_ID_PARAM_KEY } from '@app/utils/consts';
import { take, takeUntil } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { EventActionsConstants } from '@app/store/actions/event/event.actions.constants';
import { CopyEvent, GetEventCopyInfo } from '@app/store/actions/event/event.actions';
import { StoreService } from '@app/services/store/store.service';
import * as eventSelectors from '@app/store/selectors/event.selector';
import { EventCopyService } from '@app/services/event-copy/event-copy.service';
import { Go } from '@app/store/actions/router/router.actions';
import { DatesService } from '@app/services/dates/dates.service';
import { EventCopy } from '@app/models/event-metadata.model';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '@app/shared/modals/confirmation-modal/confirmation-modal.component';

@Component({
	selector: 'app-copy-event',
	templateUrl: './copy-event.component.html',
	styleUrls: ['./copy-event.component.sass'],
})
export class CopyEventComponent {
	eventId: SchemeID;
	isMobile = false;
	destroyed$: EventEmitter<void> = new EventEmitter<void>();
	isPageLoading = true;
	isPageUpdating = false;
	form: UntypedFormGroup;
	selectAll = false;

	headerContent: HeaderContent = {
		breadCrumbs: [
			{
				routeName: 'Manage Event',
				routeTo: () => InternalURLCreator.manageEvent(this.eventId),
			},
			{
				routeName: 'Copy Event',
			},
		],
		title: 'Copy Event',
		description: `Use this page to copy this event's details into a new event. Simply fill in some basic
		details below and then click "Copy this event". Your new event will be created in "Draft" mode, which
		means you will be able to do any final touches to it before activating it.`,
	};

	customDescription = 'Use this tool to copy this event\'s details into a new event. Simply fill in some...';
	readMoreText = 'Read More';
	includeReadMore = true;
	readMore = false;

	constructor(
		private breakpointService: BreakpointService,
		private activatedRoute: ActivatedRoute,
		private actions$: Actions,
		private store: StoreService,
		private eventCopyService: EventCopyService,
		public datesService: DatesService,
		private dialog: MatDialog
	) {}

	ngOnInit(): void {
		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});

		this.activatedRoute.parent.paramMap.pipe(take(1)).subscribe((params) => {
			this.eventId = +params.get(EVENT_ID_PARAM_KEY);
			this.store.dispatch(new GetEventCopyInfo({ eventId: this.eventId }));
		});

		this.actions$
			.pipe(
				ofType(EventActionsConstants.GET_COPY_EVENT_INFO_SUCCESS, EventActionsConstants.GET_COPY_EVENT_INFO_FAILED),
				takeUntil(this.destroyed$)
			)
			.subscribe(() => {
				this.isPageLoading = false;
			});

		this.actions$
			.pipe(
				ofType(EventActionsConstants.COPY_EVENT_SUCCESS, EventActionsConstants.COPY_EVENT_FAILED),
				takeUntil(this.destroyed$)
			)
			.subscribe(({ type, payload: { copyInfo } }: { type: EventActionsConstants; payload: { copyInfo: any } }) => {
				if (type === EventActionsConstants.COPY_EVENT_SUCCESS){
					this.store.dispatch(new Go({ path: [InternalURLCreator.eventDashboard(copyInfo.id)]}));
				}
				this.isPageUpdating = false;
				this.form.markAsPristine();
			});

		this.store
			.select(eventSelectors.eventCopyInfo(this.eventId))
			.pipe(takeUntil(this.destroyed$))
			.subscribe((eventCopyInfo) => {
				if (eventCopyInfo) {
					if (!this.form) {
						const modifiedEventCopyInfo = { ...eventCopyInfo };
						modifiedEventCopyInfo.name = `${eventCopyInfo.name} (copy)`;
						this.form = this.eventCopyService.createEventCopyForm(modifiedEventCopyInfo);
					}
				}
			});
	}

	handleReadMore() {
		this.readMore = !this.readMore;
		this.readMoreText = this.readMore ? 'Read Less' : 'Read More';
	}

	fromForm(controlKey: string): AbstractControl {
		if (this.form) {
			return this.form.get(controlKey);
		}
		return null;
	}

	getFormGroupKeys(form: UntypedFormGroup, groupName: string) {
		return Object.keys(form.get(groupName).value);
	}

	markFormDirty() {
		this.form.markAsDirty();
	}

	handleSave() {
		this.isPageUpdating = true;
		const formValues: EventCopy = this.form.getRawValue();
		this.store.dispatch(new CopyEvent({
			eventId: this.eventId,
			copyInfo: formValues,
			timezone: formValues.ianaTimezone,
		}));
	}

	handleBack() {
		this.dialog.open(ConfirmationModalComponent, {
			data: {
				title: 'Cancel Copy Event Changes?',
				text: 'Are you sure you want to cancel your copy event changes',
				buttonText: 'YES',
				isMobile: this.isMobile,
			},
			panelClass: 'g-standard-dialog',
		})
			.afterClosed().subscribe((result) => {
				if (result) {
					this.store.dispatch(new Go({ path: [InternalURLCreator.manageEvent(this.eventId)] }));
				}
			});
	}

	handleSelectAll() {
		this.selectAll = !this.selectAll;
		this.form.patchValue({
			copyCustomCss: this.selectAll,
			copyImage: this.selectAll,
			copyMerchendise: this.selectAll,
			copyTabbedContent: this.selectAll,
			copyTheme: this.selectAll,
			copyTicketGroups: this.selectAll,
		});
		this.setGroupToValueOfSelectAll('tickets');
		this.setGroupToValueOfSelectAll('questions');
	}

	setGroupToValueOfSelectAll(group: string) {
		const formGroupKeys = this.getFormGroupKeys(this.form, group);
		for (const key of formGroupKeys) {
			if (this.form.get(`${group}.${key}`)) {
				this.form.get(`${group}.${key}`).patchValue({
				  checked: this.selectAll,
				});
			}
		}
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
