import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SavedEventsDashboardComponent } from './pages/saved-events-dashboard/saved-events-dashboard.component';
import { SharedModule } from '@app/shared/shared.module';
import { AngularMaterialModule } from '@app/shared/angular-material.module';
import { EventCardComponent } from './components/event-card/event-card.component';
@NgModule({
	declarations: [
		SavedEventsDashboardComponent,
		EventCardComponent,
	],
	imports: [
		CommonModule,
		SharedModule,
		AngularMaterialModule,
	],
})
export class MySavedEventsModule { }
