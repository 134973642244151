import { Component, Input, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ChartInput, TicketTypeColour } from '@app/models/organiser.model';
import { ChartTableModalComponent } from '../chart-table-modal/chart-table-modal.component';
import { TicketType } from '@app/models/event.model';
import { ChartTooltip } from '@app/models/shared';
import { CHART_COLOURS } from '@app/utils/consts';

@Component({
	selector: 'app-chart',
	templateUrl: './chart.component.html',
	styleUrls: ['./chart.component.sass'],
})
export class ChartComponent {
	@Input() chartData: ChartInput[];
	@Input() totalValue: number;
	@Input() showLegend = false;
	@Input() isMobile = false;
	@Input() title?: string;
	@Input() currencySymbol = '';
	@Input() appliedFilterText: string;
	@Input() currentTicketTypeFilter: TicketType[];

	displayData: ChartInput[];
	isMonthView = false;
	selectedMonthLabel: string;
	totalFilteredValue = 0;

	showLabels = true;
	xAxis = true;
	yAxis = true;

	totals = new Map<string, number>();

	colorMap = new Map<string, string>();
	colorScheme = {
		domain: CHART_COLOURS,
	};
	legendItems: TicketTypeColour[] = [];

	constructor(private dialog: MatDialog) {}

	ngOnInit(): void {
		this.checkLengthOfSeriesAndAggregate(this.chartData);
		this.createLegendItemsAndTotals();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.chartData && !changes.chartData.firstChange) {
			this.totalFilteredValue = 0;
			this.checkLengthOfSeriesAndAggregate(changes.chartData.currentValue);
			this.createLegendItemsAndTotals();
		}
	}

	createLegendItemsAndTotals(): void {
		if (!this.currentTicketTypeFilter) {
			return;
		}

		this.legendItems = [];
		this.currentTicketTypeFilter.forEach((ticket, index) => {
			if (!this.colorMap.has(ticket.name)) {
			  const color = CHART_COLOURS[index];
			  this.colorMap.set(ticket.name, color);
			}
			this.legendItems.push({
				name: ticket.name,
				value: this.colorMap.get(ticket.name),
				id: ticket.id.toString(),
			});
		  });

		this.totals = this.calculateTotalDictionary();
	}

	checkLengthOfSeriesAndAggregate(allChartInput: ChartInput[]) {
		if (allChartInput.length !== 0) {
			if (allChartInput.length > 30) {
				this.aggregateToGreaterThanThirtyDayView(allChartInput);
			} else {
				this.aggregateToLessThanThirtyDayView(allChartInput);
			}
		}
		this.isMonthView = false;
	}

	handleGraphSelect(event: { value: number; name: string; series: string }) {
		if (!this.isMonthView && this.chartData.length > 30) {
			this.isMonthView = true;
			this.totalFilteredValue = 0;
			this.handleSingleMonthView(event.series);
		}
	}

	handleGraphReset() {
		this.totalFilteredValue = 0;
		this.aggregateToGreaterThanThirtyDayView(this.chartData);
		this.isMonthView = false;
		this.totals = this.calculateTotalDictionary();
	}

	handleFormatYAxisLabel(value: number) {
		let formattedValue = value >= 1000 ? (value / 1000).toFixed(1) + 'k' : value.toString();

		if (this.currencySymbol) {
			formattedValue = this.currencySymbol + ' ' + formattedValue;
		}

		return formattedValue;
	}

	handleTicketsSoldModal() {
		this.dialog.open(ChartTableModalComponent, {
			data: {
				isMobile: this.isMobile,
				displayData: this.displayData,
				legendItems: this.legendItems,
				appliedFilterText: this.isMonthView ? this.selectedMonthLabel : this.appliedFilterText,
				title: this.title,
				currencySymbol: this.currencySymbol,
			},
			panelClass: this.isMobile ? 'g-full-page-dialog' : 'g-ultra-wide-dialog',
		});
	}

	calculateTotalDictionary() {
		return new Map(this.displayData.map((item) => [item.name, item.series.reduce((sum, entry) => sum + entry.value, 0)]));
	}

	calculateTotal(model: ChartTooltip) {
		return this.totals.get(model.series) || 0;
	}

	getTooltipColour(model: ChartTooltip) {
		return this.colorMap.get(model.label);
	}

	aggregateToGreaterThanThirtyDayView(chartData: ChartInput[]) {
		const monthlyAggregates: { [key: string]: { [key: string]: number } } = {};
		this.displayData = chartData.reduce((acc: ChartInput[], curr: ChartInput) => {
			const date = new Date(curr.name);
			const monthYear = date.toLocaleString('default', { month: 'short' }) + ' \'' + date.getFullYear().toString().slice(-2);

			if (!monthlyAggregates[monthYear]) {
				monthlyAggregates[monthYear] = {};
			}

			curr.series.forEach(({ name, value, id }) => {
				const key = id ? `${name}::${id}` : name;

				if (!monthlyAggregates[monthYear][key]) {
					monthlyAggregates[monthYear][key] = 0;
				}
				monthlyAggregates[monthYear][key] += value;
			});

			let monthEntry = acc.find((entry) => entry.name === monthYear);
			if (!monthEntry) {
				monthEntry = {
					name: monthYear,
					series: [],
				};
				acc.push(monthEntry);
			}

			monthEntry.series = Object.entries(monthlyAggregates[monthYear]).map(([key, totalValue]) => {
				const [ticketName, ticketId] = key.includes('::') ? key.split('::') : [key, undefined];
				return {
					name: ticketName,
					value: totalValue,
					id: ticketId,
				};
			});

			return acc;
		}, []);

		this.totalFilteredValue = this.displayData.reduce((sum, monthEntry) => {
			const monthTotal = monthEntry.series.reduce((monthSum, entry) => monthSum + entry.value, 0);
			return sum + monthTotal;
		}, 0);
	}

	aggregateToLessThanThirtyDayView(chartData: ChartInput[]) {
		this.displayData = chartData.map((item) => {
			const itemDate = new Date(item.name);
			return {
				name: `${itemDate.getDate()} ${itemDate.toLocaleString('default', { month: 'short' })}`,
				series: item.series,
			};
		});

		this.totalFilteredValue = this.displayData.reduce((sum, monthEntry) => {
			const monthTotal = monthEntry.series.reduce((monthSum, entry) => monthSum + entry.value, 0);
			return sum + monthTotal;
		}, 0);
	}

	handleSingleMonthView(monthYear: string) {
		const [monthInput, yearInput] = monthYear.split(' ');
		const year = `20${yearInput.substring(1)}`;
		const monthIndex = new Date(`${monthInput} 1 ${year}`).getMonth();
		this.selectedMonthLabel = monthInput + ' ' + year;

		const filteredData = this.chartData.filter((item) => {
			const itemDate = new Date(item.name);
			return itemDate.getFullYear().toString() === year && itemDate.getMonth() === monthIndex;
		});

		this.displayData = filteredData.map((item) => ({
			name: item.name.split('-')[2],
			series: item.series,
		}));

		this.totalFilteredValue = this.displayData.reduce((sum, day) => {
			const dayTotal = day.series.reduce((daySum, entry) => daySum + entry.value, 0);
			return sum + dayTotal;
		}, 0);

		this.totals = this.calculateTotalDictionary();
	}
}
