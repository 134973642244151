export enum FileActionsConstants {
	UPLOAD_EVENT_IMAGE_SUCCESS = 'UPLOAD_EVENT_IMAGE_SUCCESS',
	UPLOAD_EVENT_IMAGE_FAILED = 'UPLOAD_EVENT_IMAGE_FAILED',

	REMOVE_EVENT_IMAGE = 'REMOVE_EVENT_IMAGE',
	REMOVE_EVENT_IMAGE_SUCCESS = 'REMOVE_EVENT_IMAGE_SUCCESS',
	REMOVE_EVENT_IMAGE_FAILED = 'REMOVE_EVENT_IMAGE_FAILED',

	UPLOAD_TICKET_IMAGE_SUCCESS = 'UPLOAD_TICKET_IMAGE_SUCCESS',
	UPLOAD_TICKET_IMAGE_FAILED = 'UPLOAD_TICKET_IMAGE_FAILED',

	REMOVE_TICKET_IMAGE = 'REMOVE_TICKET_IMAGE',
	REMOVE_TICKET_IMAGE_SUCCESS = 'REMOVE_TICKET_IMAGE_SUCCESS',
	REMOVE_TICKET_IMAGE_FAILED = 'REMOVE_TICKET_IMAGE_FAILED',

	UPLOAD_CONTACTS_CSV = 'UPLOAD_CONTACTS_CSV',
	UPLOAD_CONTACTS_CSV_SUCCESS = 'UPLOAD_CONTACTS_CSV_SUCCESS',
	UPLOAD_CONTACTS_CSV_FAILED = 'UPLOAD_CONTACTS_CSV_FAILED',
}
