<div class="l-container">
	<h3 class="mb-3 text-center">{{data.title}}</h3>
	<div class="mb-4" [class.text-center]='data.centerText'>
		<p>{{data.text}}</p>
	</div>
	<div class="mb-3">
		<app-mat-text-control-field
			[label]="data.inputText"
			[control]="inputFormControl"
		>
		</app-mat-text-control-field>
	</div>
	<div class="l-buttons-container">
		<button *ngIf="data.buttonText" class="g-primary-button" (click)="handlePrimary()">{{data.buttonText}}</button>
		<button class="g-secondary-button l-border" (click)="handleCancel()">CANCEL</button>
	</div>
</div>
