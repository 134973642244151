<div *ngIf="!isBanksLoading; else showLoading">
	<div class="g-form-container">
		<div class="input-group w-100 mb-3">
			<label class="g-form-label">Select Bank<span class="g-orange">*</span></label>
			<mat-select
				id='bank-select'
				class="form-control w-100"
				[formControl]="bankSelect"
				placeholder="Please Select"
				required
				#singleSelect
			>
				<mat-option>
					<ngx-mat-select-search
						ngModel
						(ngModelChange)="handleBanksFilter($event)"
						name="bankSearch"
						#bankSearch="ngModel"
						placeholderLabel="Search for your bank..."
						noEntriesFoundLabel="No matching bank found"
					>
					</ngx-mat-select-search>
				</mat-option>
				<mat-option
					*ngFor="let bank of filteredBanks"
					[value]="bank"
				>
					{{ bank.name }}
				</mat-option>
			</mat-select>
		</div>
		<div class="g-double-input-container w-100 mb-0">
			<div class="input-group l-input">
				<app-mat-text-control-field
					label="Account Holder"
					placeholder="e.g. Jane Doe"
					[control]="fromForm('accountHolder', bankAccountForm)"
					id="account-holder"
					labelClass="g-form-label"
					[required]="true"
				>
				</app-mat-text-control-field>
			</div>
			<div class="input-group l-input">
				<app-mat-number-control-field
					label="Account Number"
					placeholder="1234567890"
					[control]="fromForm('accountNumber', bankAccountForm)"
					[allowNegative]="false"
					customId="account-number"
					required="true"
				></app-mat-number-control-field>
			</div>
		</div>
		<div
			*ngIf="requestBranchCode"
			class="input-group w-100"
		>
			<app-mat-number-control-field
				label="Branch Code"
				placeholder="1234567890"
				[control]="fromForm('branchCode', bankAccountForm)"
				[allowNegative]="false"
				customId="account-number"
				required="true"
			></app-mat-number-control-field>
		</div>
	</div>
</div>
<ng-template #showLoading>
	<div class="l-loading">
		<mat-spinner></mat-spinner>
	</div>
</ng-template>