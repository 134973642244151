import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { HeaderContent } from '@app/models/shared';
import { Clipboard } from '@angular/cdk/clipboard';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { StoreService } from '@app/services/store/store.service';
import { BANK_ACCOUNT_MODAL, EARN_COMMISSION_HEADER } from '@app/utils/consts';
import { combineLatest, map, takeUntil, tap } from 'rxjs';
import * as userSelectors from '@app/store/selectors/user.selector';
import { AccountModalReturn, BankAccount, ReferralCommissionEarned, Referrals, UpdateReferrals } from '@app/models/user.model';
import { Actions, ofType } from '@ngrx/effects';
import { UserActionsConstants } from '@app/store/actions/user/user.actions.constants';
import { GetReferralDetails, UpdateReferralBankAccount, UpdateReferralCode } from '@app/store/actions/user/user.actions';
import { InternalURLCreator, URLCreator } from '@app/services/url/url.dictionary';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Go } from '@app/store/actions/router/router.actions';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { BankAccountModalComponent } from '@app/shared/bank-account-modal/bank-account-modal.component';
import { EarnCommissionBaseComponent } from '../../components/earn-commission-base-control/earn-commission-base-control.component';
import { LinkCardComponent } from '@app/shared/link-card/link-card.component';

@Component({
	selector: 'app-referrals',
	templateUrl: './referrals.component.html',
	styleUrls: ['./referrals.component.sass'],
})
export class ReferralsComponent extends EarnCommissionBaseComponent implements OnInit {
	headerContent: HeaderContent = EARN_COMMISSION_HEADER['referrals'];
	referralInfo: Referrals;
	customDescription: string;
	totalCommission: number;
	readMoreText = 'Read More.';
	linkedAccount: string;
	bankAccounts: BankAccount[];
	currentAccount: BankAccount;

	referralCommission: MatTableDataSource<ReferralCommissionEarned> = new MatTableDataSource();
	referralCommissionColumns: string[] = ['info', 'date', 'account', 'balance'];

	@ViewChild('commissionPaginator') set paginator(_pagination: MatPaginator) {
		if (_pagination) {
			this.referralCommission.paginator = _pagination;
		};
	}

	@ViewChild(LinkCardComponent) linkCardComponent!: LinkCardComponent;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		breakpointService: BreakpointService,
		store: StoreService,
		actions$: Actions,
		clipboard: Clipboard,
		router: Router,
		private dialog: MatDialog
	) {
		super(store, breakpointService, actions$, clipboard, router);
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.bankAccountsAndReferrals();
	}

	bankAccountsAndReferrals(): void {
		const referrals$ = this.store.select(userSelectors.getUserReferral()).pipe(
			takeUntil(this.destroyed$),
			tap((referrals) => {
				if (!referrals.code) {
					this.store.dispatch(new GetReferralDetails());
				}
			}), map((referrals) => referrals)
		);

		combineLatest([referrals$, this.bankAccounts$]).pipe(takeUntil(this.destroyed$)).subscribe(([referrals, bankAccounts]) => {
			this.bankAccounts = bankAccounts;
			this.referralInfo = referrals;
			this.linkedAccount = this.referralInfo.selectedBankAccountSemiHashed;

			this.totalCommission = this.referralInfo.referralCommissionsEarned.reduce((acc, curr) => acc + curr.balance, 0);

			const additionalRows: ReferralCommissionEarned[] = [
				{ info: 'Commission Earned', date: null, balance: this.totalCommission, bankAccountSemiHash: null },
				{ info: 'Owned To You', date: null, balance: this.referralInfo.totalBalanceOwed, bankAccountSemiHash: null },
			];

			const dataArray = [...additionalRows, ...this.referralInfo.referralCommissionsEarned];

			this.referralCommission.data = dataArray;
			this.customDescription =
				`Share this link with anyone who wants to run an event and earn
				${this.referralInfo.commissionPercentage}
				% of Quicket\'s commission for yourself.`;

			this.isLoading = false;
		});
	}

	handleReadMore() {
		window.open(URLCreator.referralArticle());
	}

	handleSaveLink(link: string) {
		this.actions$
			.pipe(
				ofType(UserActionsConstants.UPDATE_REFERRAL_CODE_SUCCESS, UserActionsConstants.UPDATE_REFERRAL_CODE_FAILED),
				takeUntil(this.destroyed$)
			)
			.subscribe(({ type, payload: { updateReferrals } }:
			{ type: string; payload: { updateReferrals: UpdateReferrals } }) => {
				this.isUpdating = false;
				if (type === UserActionsConstants.UPDATE_REFERRAL_CODE_SUCCESS) {
					this.handleNotification(updateReferrals.success, false, updateReferrals.errorMessage);
					this.linkCardComponent.handleAttemptedSave(updateReferrals.success);
				}
			});

		this.store.dispatch(new UpdateReferralCode({ newCode: link }));
		this.isUpdating = true;
	}

	handleChooseBankAccount() {
		this.actions$
			.pipe(
				ofType(UserActionsConstants.UPDATE_REFERRAL_BANK_ACCOUNT_SUCCESS, UserActionsConstants.UPDATE_REFERRAL_BANK_ACCOUNT_FAILED),
				takeUntil(this.destroyed$)
			)
			.subscribe(({ type, payload: { updateReferrals } }:
			{ type: string; payload: { updateReferrals: UpdateReferrals } }) => {
				this.isBanksDataFetching = false;
				if (type === UserActionsConstants.UPDATE_REFERRAL_BANK_ACCOUNT_SUCCESS) {
					this.handleNotification(updateReferrals.success, true, updateReferrals.errorMessage);
				}
			});

		this.isBanksDataFetching = true;
		const dialogRef = this.dialog.open(BankAccountModalComponent, {
			data: {
				isMobile: this.isMobile,
				currencyId: 1,
				descriptions: BANK_ACCOUNT_MODAL['referrals'],
			},
			panelClass: this.isMobile ? 'g-full-page-dialog' : 'g-standard-dialog',
		});

		dialogRef.afterClosed().subscribe((account: AccountModalReturn) => {
			if (account && account.accountId) {
				this.store.dispatch(new UpdateReferralBankAccount({ id: account.accountId }));
			} else {
				this.isBanksDataFetching = false;
			}
		});
	}

	handleNavigationBack() {
		this.store.dispatch(new Go({ path: [InternalURLCreator.earnCommission()] }));
	}

	isFirstPage(): boolean {
		return this.referralCommission.paginator && this.referralCommission.paginator.pageIndex === 0;
	}

	ngOnDestroy() {
		this.destroyed$.next();
	}
}
