import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-no-products-card',
	templateUrl: './no-products-card.component.html',
	styleUrls: ['./no-products-card.component.sass'],
})
export class NoProductsCardComponent {

	@Input() title: string;
	@Input() content: string;
	@Input() buttonText: string;
	@Output() buttonClick: EventEmitter<void> = new EventEmitter<void>();

	handleButton() {
		this.buttonClick.emit();
	}

}
