import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HeaderContent } from '@app/models/shared';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { MY_TICKETS_HEADER } from '@app/utils/consts';
import { take, takeUntil } from 'rxjs/operators';
import { StoreService } from '@app/services/store/store.service';
import * as userSelectors from '@app/store/selectors/user.selector';
import { TransferTicket, TransferTicketReturn } from '@app/models/user.model';
import { GetTransferableTickets, TransferTickets } from '@app/store/actions/user/user.actions';
import { Actions, ofType } from '@ngrx/effects';
import { UserActionsConstants } from '@app/store/actions/user/user.actions.constants';
import { AddNotification } from '@app/store/actions/notification/notification.actions';
import { NotificationType } from '@app/models/notification.model';
import { FooterButtonComponent } from '@app/shared/footer-button/footer-button.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '@app/shared/modals/confirmation-modal/confirmation-modal.component';
import { Go } from '@app/store/actions/router/router.actions';
import { InternalURLCreator } from '@app/services/url/url.dictionary';
import { ErrorDisplayModalComponent } from '@app/shared/error-display-modal/error-display-modal.component';
import { NgForm } from '@angular/forms';
import { TICKET_ID_PARAM_KEY } from '../my-tickets/my-tickets.component';

@Component({
	selector: 'app-transfer-ticket',
	templateUrl: './transfer-ticket.component.html',
	styleUrls: ['./transfer-ticket.component.sass'],
})
export class TransferTicketComponent implements OnInit {
	isMobile = false;
	headerContent: HeaderContent = MY_TICKETS_HEADER['ticketTransfer'];
	purchaseId: number;
	reference: string;
	email: string;
	confirmEmail: string;
	confirmation = false;
	transferableTickets: TransferTicket[];
	isTicketFetchLoading = true;
	isTicketTransferLoading = false;
	statusMessage: string;
	selectedTickets: TransferTicket[] = [];
	onlyOneTransferableAllowed = false;
	@ViewChild('ticketTransferForm') form: NgForm;
	@ViewChild('footerButton') footerButton: FooterButtonComponent;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	get isFooterButtonDisabled(): boolean {
		return this.isTicketFetchLoading || !!this.statusMessage || this.selectedTickets.length === 0 || this.isTicketTransferLoading;
	}

	constructor(
		private breakpointService: BreakpointService,
		private activatedRoute: ActivatedRoute,
		private store: StoreService,
		private actions$: Actions,
		private dialog: MatDialog
	) {}

	ngOnInit(): void {
		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});

		this.activatedRoute.params.pipe(take(1)).subscribe((params) => {
			this.purchaseId = params[TICKET_ID_PARAM_KEY];
			this.initializeGetTransferTickets();
		});

		this.initializeTransferTicketsResponse();
	}

	initializeGetTransferTickets() {
		this.store
			.select(userSelectors.currentTransferableTickets())
			.pipe(takeUntil(this.destroyed$))
			.subscribe((transferableTickets) => {
				if (!transferableTickets.transferableTickets.statusMessage || +transferableTickets.purchaseId !== +this.purchaseId) {
					return;
				}
				if (transferableTickets.transferableTickets.tickets.length > 0) {
					this.transferableTickets = transferableTickets.transferableTickets.tickets;
					this.onlyOneTransferableAllowed = transferableTickets.transferableTickets.onlyOneTransferAllowed;
					this.reference = transferableTickets.transferableTickets.purchaseReference;
				} else {
					this.statusMessage = transferableTickets.transferableTickets.statusMessage;
					this.reference = transferableTickets.transferableTickets.purchaseReference;
				}
			});

		this.actions$
			.pipe(
				ofType(UserActionsConstants.GET_TRANSFERABLE_TICKETS_SUCCESS, UserActionsConstants.GET_TRANSFERABLE_TICKETS_FAILED),
				take(1)
			)
			.subscribe(() => {
				this.isTicketFetchLoading = false;
			});

		this.store.dispatch(new GetTransferableTickets({ purchaseId: this.purchaseId }));
	}

	initializeTransferTicketsResponse() {
		this.actions$
			.pipe(
				ofType(UserActionsConstants.TRANSFER_TICKETS_SUCCESS, UserActionsConstants.TRANSFER_TICKETS_FAILED),
				takeUntil(this.destroyed$)
			)
			.subscribe(
				({
					type,
					payload: { transferableTickets },
				}: {
					type: string;
					payload: { transferableTickets: TransferTicketReturn; purchaseId: number };
				}) => {
					if (type === UserActionsConstants.TRANSFER_TICKETS_SUCCESS) {
						this.handleTransferTicketSuccess(transferableTickets);
						this.resetFormField('confirmEmail', undefined);
						this.resetFormField('email', undefined);
						this.resetFormField('confirm', false);
						this.form.form.markAsPristine();
					}
					this.isTicketTransferLoading = false;
				}
			);
	}

	handleTransferTicketSuccess(transferableTickets: TransferTicketReturn): void {
		if (transferableTickets.statusMessage.includes('Success')) {
			this.handleSuccessNotification(transferableTickets.statusMessage);
			this.selectedTickets = [];
			if (!this.hasValidTickets(transferableTickets.tickets)) {
				this.handleNavigateToTickets();
			}
		} else {
			this.selectedTickets = [];
			this.handleTransferErrorDialog(transferableTickets.statusMessage);
		}
	}

	handleNavigationBack() {
		this.store.dispatch(new Go({ path: [InternalURLCreator.manageBooking(this.purchaseId)] }));
	}

	handleNavigateToTickets() {
		this.store.dispatch(new Go({ path: [InternalURLCreator.myTickets()] }));
	}

	handlePrimaryButton() {
		if (this.isMobile) {
			const confirmationText = this.onlyOneTransferableAllowed
				? 'Tickets for this event can only be transferred once. '
				: '' +
				  `By transferring this ticket you agree you relinquish any financial claim to the ticket,
					including in the case of an event postponement or cancellation.`;
			this.dialog
				.open(ConfirmationModalComponent, {
					data: {
						title: 'Are You Sure?',
						text: confirmationText,
						buttonText: 'TRANSFER',
					},
					panelClass: 'g-standard-dialog',
				})
				.afterClosed()
				.subscribe((result) => {
					if (result) {
						this.handleTransferTickets();
					}
				});
		} else {
			this.handleTransferTickets();
		}
	}

	handleTransferTickets() {
		const transferTickets = {
			purchaseId: this.purchaseId,
			transferableTickets: {
				tickets: this.selectedTickets,
				email: this.email,
			},
		};

		const transferAction = () => {
			this.isTicketTransferLoading = true;
			this.store.dispatch(new TransferTickets(transferTickets));
		};

		if (this.onlyOneTransferableAllowed && !this.isMobile) {
			const dialogRef = this.dialog.open(ConfirmationModalComponent, {
				data: {
					title: 'Are You Sure?',
					text: 'Tickets for this event can only be transferred once.',
					buttonText: 'TRANSFER',
					isMobile: this.isMobile,
				},
				panelClass: 'g-standard-dialog',
			});

			dialogRef.afterClosed().subscribe((result) => {
				if (result) {
					transferAction();
				}
			});
		} else {
			transferAction();
		}
	}

	handleCheckboxChange(ticket: TransferTicket) {
		const index = this.selectedTickets.indexOf(ticket);
		if (index === -1) {
			this.selectedTickets.push(ticket);
		} else {
			this.selectedTickets.splice(index, 1);
		}
	}

	handleSuccessNotification(message: string) {
		this.store.dispatch(
			new AddNotification({
				id: 'transfer-ticket',
				actionType: null,
				action: null,
				type: NotificationType.SUCCESS,
				title: message,
				delay: 4000,
			})
		);
	}

	handleTransferErrorDialog(message: string): void {
		this.dialog.open(ErrorDisplayModalComponent, {
			data: {
				title: 'Transfer Unsuccessful',
				text: message,
			},
			panelClass: 'g-standard-dialog',
		});
	}

	hasValidTickets(tickets: TransferTicket[]): boolean {
		return tickets.some((ticket) => ticket.canTransfer);
	}

	checkEmail() {
		this.form.form.get('confirmEmail').updateValueAndValidity();
		this.form.form.get('email').updateValueAndValidity();
	}

	resetFormField(fieldName: string, value: any, markUntouched: boolean = true): void {
		const control = this.form.form.get(fieldName);
		if (control) {
			control.setValue(value);
			if (markUntouched) {
				control.markAsUntouched();
			}
		}
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
