import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { numberLessThen, numberMoreThen, requiredValidator } from '@app/shared/form-field/form-validators';

@Component({
	selector: 'app-mat-number-control-field',
	templateUrl: './mat-number-control-field.component.html',
	styleUrls: ['./mat-number-control-field.component.sass'],
})
export class MatNumberControlFieldComponent implements OnInit {
	@Input() outerClass = 'l-form-item';
	@Input() labelClass = 'g-form-label l-form-label';
	@Input() label = '';
	@Input() control: UntypedFormControl;
	@Input() allowNegative = true;
	@Input() tooltip = '';
	@Input() customId = '';
	@Input() required = false;
	@Input() min: number | null = null;
	@Input() max: number | null = null;
	@Input() placeholder = '';
	@Input() errorOutline = false;
	@Input() paddingBottom = true;
	@Input() disabled = false;

	ngOnInit(): void {
		if (!this.control) {
			this.control = new UntypedFormControl('');
		}

		this.applyValidators();

		if (this.disabled) {
			this.control.disable();
		}
	}

	applyValidators() {
		const validators = [];

		if (this.required) {
			validators.push(requiredValidator());
		}

		if (!this.allowNegative && this.min === null) {
			this.min = 0;
		}

		if (this.min !== null) {
			validators.push(numberMoreThen(this.min));
		}

		if (this.max !== null) {
			validators.push(numberLessThen(this.max));
		}

		if (validators.length) {
			this.control.addValidators(validators);
			this.control.updateValueAndValidity();
		}
	}

	isControlInvalid() {
		return this.control && this.control.invalid && (this.control.dirty || this.control.touched);
	}

	getError(errors: { [key: string]: string }) {
		return  errors && errors[Object.keys(errors)[0]];
	}
}
