<div class="l-container">
    <div class="l-icon-container">
        <i class="g-orange fa-solid fa-triangle-exclamation"></i>
    </div>
    <div class="l-header">Are you sure you want to submit your event for approval?</div>
    <div class="l-main-paragraph">
        Please note that once tickets start selling, the fees below will apply. 
        Funds collected by Quicket will be paid out to you, less any accumulated fees, within 3 working days of the event ending.
    </div>
    <ul>
        <li>
            {{ data.commissionForHost ? 'You' : 'Ticket buyers' }} will pay <span class="fw-bold">{{ getCommission() }}%</span> ex VAT on each ticket sold.
        </li>
        <li>
            Ticket booking fees are payable by <span class="fw-bold">{{ data.serviceFeeForHost ? 'you' : 'ticket buyers' }}.</span>
        </li>
    </ul>
    <div class="g-link l-terms">
        Please see our <a [href]="terms" target="_blank">Terms and Conditions</a> for more details.
    </div>
    <div class="l-buttons-container" [ngClass]="{'mobile': data.isMobile}">
        <button
			class="g-secondary-button border-0 l-button"
			[ngClass]="{'mobile': data.isMobile}"
			(click)="handleAction(false)"
		>
			CANCEL
		</button>
        <button
			class="g-primary-button l-button" 
			[ngClass]="{'mobile': data.isMobile}"
            (click)="handleAction(true)" 
            id="submit-modal-button"
        >
            SUBMIT
        </button>
    </div>
</div>