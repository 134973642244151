import { Component, EventEmitter, Input } from '@angular/core';
import { SectionRouteCard } from '@app/models/shared';
import { convertToIdFormat, handleOrder } from '@app/utils/common-helpers';
import { ORGANISER_NAV } from '@app/utils/consts';
import { Dictionary } from '@ngrx/entity';
@Component({
	selector: 'app-side-nav',
	templateUrl: './side-nav.component.html',
	styleUrls: ['./side-nav.component.sass'],
})
export class SideNavComponent {
	@Input() currentNavItemTitle: string;
	navItems: Dictionary<SectionRouteCard> = ORGANISER_NAV;
	precomputedImageSources: { [key: string]: string } = {};

	handleDefaultOrder = handleOrder;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	formatId(itemTitle: string, prefix: string ): string {
		return convertToIdFormat(itemTitle, prefix);
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}

}
