import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { HeaderContent } from '@app/models/shared';
import { Country, UserAddress, UserContactInfo } from '@app/models/user.model';
import { StoreService } from '@app/services/store/store.service';
import { MY_ACCOUNT_HEADER } from '@app/utils/consts';
import { emailRegex, phoneRegex } from '@app/utils/regex';
import { userContactInfo as userSelector } from '@app/store/selectors/user.selector';
import { take, takeUntil } from 'rxjs/operators';
import { GetUserInfo, UpdateUserInfo } from '@app/store/actions/user/user.actions';
import { Actions, ofType } from '@ngrx/effects';
import { UserActionsConstants } from '@app/store/actions/user/user.actions.constants';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { Action } from '@ngrx/store';
import { FormCanDeactivate } from '@app/services/guards/user.guard';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '@app/shared/modals/confirmation-modal/confirmation-modal.component';
import { Observable } from 'rxjs';
import { Go } from '@app/store/actions/router/router.actions';
import { InternalURLCreator } from '@app/services/url/url.dictionary';

@Component({
	selector: 'app-contact-info',
	templateUrl: './contact-info.component.html',
	styleUrls: ['./contact-info.component.sass'],
})
export class ContactInfoComponent extends FormCanDeactivate implements OnInit {
	headerContent: HeaderContent = MY_ACCOUNT_HEADER['contactInfo'];
	contactInformation: UserContactInfo;
	emailRegex: RegExp = emailRegex;
	phoneRegex: RegExp = phoneRegex;
	userAddress: UserAddress;
	isLoading = false;
	isMobile = false;
	phoneForm: FormGroup;

	country: Country = {alpha2Code: 'ZA'};

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	@ViewChild('contactInformationForm') contactForm: NgForm;

	constructor(
		private breakpointService: BreakpointService,
		private store: StoreService,
		private actions$: Actions,
		private dialog: MatDialog
	) {
		super();
	}

	ngOnInit(): void {
		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});

		this.store.dispatch(
			new GetUserInfo()
		);

		this.store.select(userSelector()).pipe(takeUntil(this.destroyed$)).subscribe((user) => {
			if (user) {
				this.contactInformation = {...user};
				this.userAddress = {...user.userAddress};

				this.phoneForm = new FormGroup({
					cellphone: new FormControl(this.contactInformation.cellphone),
					telephone: new FormControl(this.contactInformation.telephone),
				});

				this.phoneForm.valueChanges.pipe(take(1)).subscribe(() => {
					this.contactForm.form.markAsDirty();
				});

				if (this.contactInformation?.userAddress?.country) {
					this.country = {alpha2Code: this.contactInformation.userAddress.country};
				} else {
					this.userAddress.country = 'ZA';
				};
			}
		});
	}

	handlePrimaryButton() {
		const userDetails = {...this.contactInformation, userAddress: {...this.userAddress}};

		userDetails.cellphone = this.phoneForm.get('cellphone').value;
		userDetails.telephone = this.phoneForm.get('telephone').value;

		this.isLoading = true;

		this.actions$
			.pipe(
				ofType(UserActionsConstants.UPDATE_USER_INFO_SUCCESS, UserActionsConstants.UPDATE_USER_INFO_FAILED),
				take(1)
			)
			.subscribe((action: Action) => {
				this.isLoading = false;
				if (action.type === UserActionsConstants.UPDATE_USER_INFO_SUCCESS) {
					this.contactForm.form.markAsPristine();
				}
			});

		this.store.dispatch(
			new UpdateUserInfo({userDetails: userDetails})
		);
	}

	handleNavigationBack() {
		this.store.dispatch(new Go({ path: [InternalURLCreator.myAccount()] }));
	}

	isFormDeactivatable(): boolean | Observable<boolean> {
		return !this.contactForm.form || this.contactForm.form.pristine;
	}

	deactivationFallback(): Observable<boolean> {
		this.dialog
			.open(ConfirmationModalComponent, {
				data: {
					title: 'Unsaved Changes',
					text: 'Are you sure you want to leave this page? Any unsaved changes will be discarded.',
					buttonText: 'LEAVE',
					centerText: true,
					isMobile: this.isMobile,
				},
				panelClass: 'g-standard-dialog',
			})
			.afterClosed()
			.subscribe((result) => {
				this.closeDeactivationModal(result);
			});
		return this.modalCallback.asObservable();
	}

	closeDeactivationModal(isConfirmed: boolean): void {
		this.modalCallback.next(isConfirmed);
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}

	handleCountrySelect(country: Country) {
		this.userAddress.country = country.alpha2Code;
		this.contactForm.form.markAsDirty();
	}
}
