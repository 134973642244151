import { UserActionsConstants as constants } from './user.actions.constants';
import { FailedAction, AppAction } from '@app/models/action.model';
import {
	User,
	UserContactListDraftItem,
	ContactsSource,
	BankAccount,
	Bank,
	DraftBankAccount,
	PreviousEvent,
	UserContactInfo,
	Passwords,
	EmailSubscription,
	EmailPreference,
	CurrentBankAccount,
	MyTickets,
	MyRegistrations,
	RefundableTicketReturn,
	RefundableTicket,
	TransferTicketReturn,
	TransferTicketRequest,
	EditTicketDetails,
	RegistrationDetails,
	AccountTicketResales,
	UserSavedEvent,
	CreateTicketResale,
	PromoterNetwork,
	Referrals,
	UpdateReferrals
} from '@app/models/user.model';
import { NormalizedScheme, SchemeID } from '@app/models/dataSchema.model';
import { UserChart } from '@app/models/ticket.model';
import { UserContactInfoForm, UserPreviousContactList } from '@app/models/event.model';

export class UpdateUserAPIToken extends AppAction {
	readonly type = constants.UPDATE_USER_API_TOKEN;
}

export class UpdateUserAPITokenSuccess extends AppAction<string> {
	readonly type = constants.UPDATE_USER_API_TOKEN_SUCCESS;
}

export class UpdateUserAPITokenFailed extends FailedAction {
	readonly type = constants.UPDATE_USER_API_TOKEN_FAILED;
}

export class SetAPIModalFlag extends AppAction<{
	isModalOpen: boolean;
}> {
	readonly type = constants.SET_API_MODAL_FLAG;
}

export class VerifyToken extends AppAction<{ token: string }> {
	readonly type = constants.VERIFY_TOKEN;
}

export class VerifyTokenSuccess extends AppAction<{ user: User }> {
	readonly type = constants.VERIFY_TOKEN_SUCCESS;
}

export class VerifyTokenFailed extends FailedAction {
	readonly type = constants.VERIFY_TOKEN_FAILED;
}

export class LogoutComplete extends AppAction {
	readonly type = constants.LOGOUT_COMPLETE;
}

export class GetUserDesignerKey extends AppAction<{
	eventId: SchemeID;
}> {
	readonly type = constants.GET_USER_DESIGNER_KEY;
}

export class GetUserDesignerKeySuccess extends AppAction<{
	seatsDesignerKey: string;
}> {
	readonly type = constants.GET_USER_DESIGNER_KEY_SUCCESS;
}

export class GetUserDesignerKeyFailed extends FailedAction {
	readonly type = constants.GET_USER_DESIGNER_KEY_FAILED;
}

export class GetUserCharts extends AppAction<{
	eventId: SchemeID;
}> {
	readonly type = constants.GET_USER_CHARTS;
}

export class GetUserChartsSuccess extends AppAction<{
	id: SchemeID;
	charts: UserChart[];
}> {
	readonly type = constants.GET_USER_CHARTS_SUCCESS;
}

export class GetUserChartsFailed extends FailedAction {
	readonly type = constants.GET_USER_CHARTS_FAILED;
}

export class SetUserInfo extends AppAction<UserContactInfoForm> {
	readonly type = constants.SET_USER_INFO;
}

export class SetUserInfoSuccess extends AppAction<UserContactInfoForm> {
	readonly type = constants.SET_USER_INFO_SUCCESS;
}

export class SetUserInfoFailed extends FailedAction {
	readonly type = constants.SET_USER_INFO_FAILED;
}

export class CreateContactList extends AppAction<{
	name: string;
	autoSendInvites?: boolean;
	eventId: SchemeID;
	source: ContactsSource;
	contacts: UserContactListDraftItem[];
}> {
	readonly type = constants.CREATE_CONTACT_LIST;
}

export class CreateContactListSuccess extends AppAction<{
	itemsImported: number;
	contactListId: SchemeID;
	contactListName: string;
}> {
	readonly type = constants.CREATE_CONTACT_LIST_SUCCESS;
}

export class CreateContactListFailed extends FailedAction {
	readonly type = constants.CREATE_CONTACT_LIST_FAILED;
}

export class GetPreviousContactLists extends AppAction <{
	eventId: SchemeID;
}>{
	readonly type = constants.GET_PREVIOUS_CONTACT_LISTS;
}

export class GetPreviousContactListsSuccess extends AppAction<{
	previousContactLists: UserPreviousContactList[];
}> {
	readonly type = constants.GET_PREVIOUS_CONTACT_LISTS_SUCCESS;
}

export class GetPreviousContactListsFailed extends FailedAction {
	readonly type = constants.GET_PREVIOUS_CONTACT_LISTS_FAILED;
}

export class GetPreviousEvents extends AppAction<{
	eventId: SchemeID;
}> {
	readonly type = constants.GET_PREVIOUS_EVENTS;
}

export class GetPreviousEventsSuccess extends AppAction<{
	events: PreviousEvent[];
}> {
	readonly type = constants.GET_PREVIOUS_EVENTS_SUCCESS;
}

export class GetPreviousEventsFailed extends FailedAction {
	readonly type = constants.GET_PREVIOUS_EVENTS_FAILED;
}

export class GetEventsAttendees extends AppAction<{
	eventIds: SchemeID[];
}> {
	readonly type = constants.GET_EVENTS_ATTENDEES;
}

export class GetEventsAttendeesSuccess extends AppAction<{
	contacts: UserContactListDraftItem[];
}> {
	readonly type = constants.GET_EVENTS_ATTENDEES_SUCCESS;
}

export class GetEventsAttendeesFailed extends FailedAction {
	readonly type = constants.GET_EVENTS_ATTENDEES_FAILED;
}

export class SetActiveContactsSource extends AppAction<{
	source: ContactsSource;
}> {
	readonly type = constants.SET_ACTIVE_CONTACTS_SOURCE;
}

export class GetBankAccounts extends AppAction {
	readonly type = constants.GET_BANK_ACCOUNTS;
}

export class GetBankAccountsSuccess extends AppAction<{
	bankAccounts: BankAccount[];
	needsPasswordVerification?: boolean;
	passwordVerificationRedirect?: string;
}> {
	readonly type = constants.GET_BANK_ACCOUNTS_SUCCESS;
}

export class GetBankAccountsFailed extends FailedAction {
	readonly type = constants.GET_BANK_ACCOUNTS_FAILED;
}

export class DeleteBankAccount extends AppAction<{ accountId: number }> {
	readonly type = constants.DELETE_BANK_ACCOUNT;
}

export class DeleteBankAccountSuccess extends AppAction<{accountId: number}> {
	readonly type = constants.DELETE_BANK_ACCOUNT_SUCCESS;
}

export class DeleteBankAccountFailed extends FailedAction {
	readonly type = constants.DELETE_BANK_ACCOUNT_FAILED;
}

export class GetBanks extends AppAction<{ currencyId: SchemeID }> {
	readonly type = constants.GET_BANKS;
}

export class GetBanksSuccess extends AppAction<{ banks: Bank[] }> {
	readonly type = constants.GET_BANKS_SUCCESS;
}

export class GetBanksFailed extends FailedAction {
	readonly type = constants.GET_BANKS_FAILED;
}

export class GetAllBanks extends AppAction {
	readonly type = constants.GET_ALL_BANKS;
}

export class GetAllBanksSuccess extends AppAction<{ banks: Bank[] }> {
	readonly type = constants.GET_ALL_BANKS_SUCCESS;
}

export class GetAllBanksFailed extends FailedAction {
	readonly type = constants.GET_ALL_BANKS_FAILED;
}
export class AddBankAccount extends AppAction<{ draftAccount: DraftBankAccount }> {
	readonly type = constants.ADD_BANK_ACCOUNT;
}

export class AddBankAccountSuccess extends AppAction<{ account: BankAccount }> {
	readonly type = constants.ADD_BANK_ACCOUNT_SUCCESS;
}

export class AddBankAccountFailed extends FailedAction {
	readonly type = constants.ADD_BANK_ACCOUNT_FAILED;
}

export class UpdateBankAccount extends AppAction<{ draftAccount: CurrentBankAccount }> {
	readonly type = constants.UPDATE_BANK_ACCOUNT;
}

export class UpdateBankAccountSuccess extends AppAction<{ updatedAccount: BankAccount }> {
	readonly type = constants.UPDATE_BANK_ACCOUNT_SUCCESS;
}

export class UpdateBankAccountFailed extends FailedAction {
	readonly type = constants.UPDATE_BANK_ACCOUNT_FAILED;
}

export class GetUserInfo extends AppAction {
	readonly type = constants.GET_USER_INFO;
}

export class GetUserInfoSuccess extends AppAction<UserContactInfo> {
	readonly type = constants.GET_USER_INFO_SUCCESS;
}

export class GetUserInfoFailed extends FailedAction {
	readonly type = constants.GET_USER_INFO_FAILED;
}

export class UpdateUserInfo extends AppAction<{ userDetails: UserContactInfo }> {
	readonly type = constants.UPDATE_USER_INFO;
}

export class UpdateUserInfoSuccess extends AppAction<UserContactInfo> {
	readonly type = constants.UPDATE_USER_INFO_SUCCESS;
}

export class UpdateUserInfoFailed extends FailedAction {
	readonly type = constants.UPDATE_USER_INFO_FAILED;
}

export class UpdatePassword extends AppAction<{ passwords: Passwords }> {
	readonly type = constants.UPDATE_PASSWORD;
}

export class UpdatePasswordSuccess extends AppAction<{payload: any}> {
	readonly type = constants.UPDATE_PASSWORD_SUCCESS;
}

export class UpdatePasswordFailed extends FailedAction {
	readonly type = constants.UPDATE_PASSWORD_FAILED;
}

export class GetEmailPreferences extends AppAction<{ e: string }> {
	readonly type = constants.GET_EMAIL_PREFERENCES;
}

export class GetEmailPreferencesSuccess extends AppAction<{payload: EmailSubscription}> {
	readonly type = constants.GET_EMAIL_PREFERENCES_SUCCESS;
}

export class GetEmailPreferencesFailed extends FailedAction {
	readonly type = constants.GET_EMAIL_PREFERENCES_FAILED;
}

export class UpdateEmailPreferences extends AppAction<{ e: string; preferences: EmailPreference }> {
	readonly type = constants.UPDATE_EMAIL_PREFERENCES;
}

export class UpdateEmailPreferencesSuccess extends AppAction<{payload: EmailSubscription}> {
	readonly type = constants.UPDATE_EMAIL_PREFERENCES_SUCCESS;
}

export class UpdateEmailPreferencesFailed extends FailedAction {
	readonly type = constants.UPDATE_EMAIL_PREFERENCES_FAILED;
}

export class DeleteUser extends AppAction<{ password: string }> {
	readonly type = constants.DELETE_USER;
}

export class DeleteUserSuccess extends AppAction<{route: string}> {
	readonly type = constants.DELETE_USER_SUCCESS;
}

export class DeleteUserFailed extends FailedAction {
	readonly type = constants.DELETE_USER_FAILED;
}

export class GetUserMyTickets extends AppAction <{
	cartId: SchemeID;
}> {
	readonly type = constants.GET_USER_MY_TICKETS;
}

export class GetUserMyTicketsSuccess extends AppAction <{
	bookings: NormalizedScheme<MyTickets>;
}> {
	readonly type = constants.GET_USER_MY_TICKETS_SUCCESS;
}

export class GetUserMyTicketFailed extends FailedAction {
	readonly type = constants.GET_USER_MY_TICKETS_FAILED;
}

export class GetUserMyRegistrations extends AppAction {
	readonly type = constants.GET_USER_MY_REGISTRATIONS;
}

export class GetUserMyRegistrationsSuccess extends AppAction <{
	registrations: MyRegistrations[];
}> {
	readonly type = constants.GET_USER_MY_REGISTRATIONS_SUCCESS;
}

export class GetUserMyRegistrationsFailed extends FailedAction {
	readonly type = constants.GET_USER_MY_REGISTRATIONS_FAILED;
}

export class GetRefundableTickets extends AppAction<{
	purchaseId: number;
}> {
	readonly type = constants.GET_REFUNDABLE_TICKETS;
}
export class GetRefundableTicketsSuccess extends AppAction <{
	refundableTickets: RefundableTicketReturn;
	purchaseId: number;
}> {
	readonly type = constants.GET_REFUNDABLE_TICKETS_SUCCESS;
}
export class GetRefundableTicketsFailed extends FailedAction {
	readonly type = constants.GET_REFUNDABLE_TICKETS_FAILED;
}

export class PostRefundableTickets extends AppAction<{
	purchaseId: number;
	tickets: RefundableTicket[];
	bankAccountId: number;
}> {
	readonly type = constants.POST_REFUNDABLE_TICKETS;
}
export class PostRefundableTicketsSuccess extends AppAction <{
	refundableTickets: RefundableTicketReturn;
	purchaseId: number;
}> {
	readonly type = constants.POST_REFUNDABLE_TICKETS_SUCCESS;
}
export class PostRefundableTicketsFailed extends FailedAction {
	readonly type = constants.POST_REFUNDABLE_TICKETS_FAILED;
}


export class GetTransferableTickets extends AppAction<{
	purchaseId: number;
}> {
	readonly type = constants.GET_TRANSFERABLE_TICKETS;
}

export class GetTransferableTicketsSuccess extends AppAction <{
	transferableTickets: TransferTicketReturn;
	purchaseId: number;
}> {
	readonly type = constants.GET_TRANSFERABLE_TICKETS_SUCCESS;
}

export class GetTransferableTicketsFailed extends FailedAction {
	readonly type = constants.GET_TRANSFERABLE_TICKETS_FAILED;
}

export class TransferTickets extends AppAction<{
	purchaseId: number;
	transferableTickets: TransferTicketRequest;
}> {
	readonly type = constants.TRANSFER_TICKETS;
}

export class TransferTicketsSuccess extends AppAction <{
	transferableTickets: TransferTicketReturn;
	purchaseId: number;
}> {
	readonly type = constants.TRANSFER_TICKETS_SUCCESS;
}

export class TransferTicketsFailed extends FailedAction {
	readonly type = constants.TRANSFER_TICKETS_FAILED;
}

export class TransferTicketsBack extends AppAction<{
	purchaseId: number;
}> {
	readonly type = constants.TRANSFER_TICKETS_BACK;
}

export class TransferTicketsBackSuccess extends AppAction <{
}> {
	readonly type = constants.TRANSFER_TICKETS_BACK_SUCCESS;
}

export class TransferTicketsBackFailed extends FailedAction {
	readonly type = constants.TRANSFER_TICKETS_BACK_FAILED;
}

export class GetEditTicketDetails extends AppAction<{
	id: number;
}> {
	readonly type = constants.GET_EDIT_TICKET_DETAILS;
}

export class GetEditTicketDetailsSuccess extends AppAction <{
	editTicketDetails: EditTicketDetails;
	id: number;
}> {
	readonly type = constants.GET_EDIT_TICKET_DETAILS_SUCCESS;
}

export class GetEditTicketDetailsFailed extends FailedAction {
	readonly type = constants.GET_EDIT_TICKET_DETAILS_FAILED;
}

export class UpdateEditTicketDetails extends AppAction<{
	id: number;
	editTicketDetails: EditTicketDetails;
}> {
	readonly type = constants.UPDATE_EDIT_TICKET_DETAILS;
}

export class UpdateEditTicketDetailsSuccess extends AppAction <{
	editTicketDetails: EditTicketDetails;
}> {
	readonly type = constants.UPDATE_EDIT_TICKET_DETAILS_SUCCESS;
}

export class UpdateEditTicketDetailsFailed extends FailedAction {
	readonly type = constants.UPDATE_EDIT_TICKET_DETAILS_FAILED;
}

export class GetRegistrationDetails extends AppAction<{
	id: number;
}>{
	readonly type = constants.GET_REGISTRATION_DETAILS;
}

export class GetRegistrationDetailsSuccess extends AppAction <{
	registrationDetails: RegistrationDetails;
	id: number;
}> {
	readonly type = constants.GET_REGISTRATION_DETAILS_SUCCESS;
}

export class GetRegistrationDetailsFailed extends FailedAction {
	readonly type = constants.GET_REGISTRATION_DETAILS_FAILED;
}

export class UpdateRegistrationDetails extends AppAction<{
	registrationDetails: RegistrationDetails;
	id: number;
}> {
	readonly type = constants.UPDATE_REGISTRATION_DETAILS;
}

export class UpdateRegistrationDetailsSuccess extends AppAction <{
	registrationDetails: RegistrationDetails;
}> {
	readonly type = constants.UPDATE_REGISTRATION_DETAILS_SUCCESS;
}

export class UpdateRegistrationDetailsFailed extends FailedAction {
	readonly type = constants.UPDATE_REGISTRATION_DETAILS_FAILED;
}

export class GetResaleTickets extends AppAction<{
	purchaseId: number;
}>{
	readonly type = constants.GET_RESALE_TICKETS;
}

export class GetResaleTicketsSuccess extends AppAction <{
	resaleTickets: AccountTicketResales;
	purchaseId: number;
}> {
	readonly type = constants.GET_RESALE_TICKETS_SUCCESS;
}

export class GetResaleTicketsFailed extends FailedAction {
	readonly type = constants.GET_RESALE_TICKETS_FAILED;
}

export class PostResaleTickets extends AppAction<{
	createTicketResale: CreateTicketResale;
	purchaseId: number;
}>{
	readonly type = constants.POST_RESALE_TICKETS;
}

export class PostResaleTicketsSuccess extends AppAction <{
	resaleTickets: AccountTicketResales;
	purchaseId: number;
}> {
	readonly type = constants.POST_RESALE_TICKETS_SUCCESS;
}

export class PostResaleTicketsFailed extends FailedAction {
	readonly type = constants.POST_RESALE_TICKETS_FAILED;
}

export class CancelResaleTickets extends AppAction<{
	ticketResaleId: number;
	purchaseId: number;
}>{
	readonly type = constants.CANCEL_RESALE_TICKETS;
}

export class CancelResaleTicketsSuccess extends AppAction <{
	resaleTickets: AccountTicketResales;
	purchaseId: number;
}> {
	readonly type = constants.CANCEL_RESALE_TICKETS_SUCCESS;
}

export class CancelResaleTicketsFailed extends FailedAction {
	readonly type = constants.CANCEL_RESALE_TICKETS_FAILED;
}

export class FileUpload extends AppAction<{
	formData: FormData;
	name: string;
	purchaseId: number;
	encrypt: string;
	productId: number;
	purchaseItemId: number;
	questionId: number;
}>{
	readonly type = constants.FILE_UPLOAD;
}

export class FileUploadSuccess extends AppAction <{
	success: boolean;
	error: string;
	downloadUrl: string;
	purchaseItemId: number;
	questionId: number;
}> {
	readonly type = constants.FILE_UPLOAD_SUCCESS;
}

export class FileUploadFailed extends FailedAction {
	readonly type = constants.FILE_UPLOAD_FAILED;
}

export class GetUserSavedEvents extends AppAction <{
}> {
	readonly type = constants.GET_USER_SAVED_EVENTS;
}

export class GetUserSavedEventsSuccess extends AppAction <{
	userSavedEvents: UserSavedEvent[];
}> {
	readonly type = constants.GET_USER_SAVED_EVENTS_SUCCESS;
}

export class GetUserSavedEventsFailed extends FailedAction {
	readonly type = constants.GET_USER_SAVED_EVENTS_FAILED;
}

export class GetAllPromoterNetworks extends AppAction<{}>{
	readonly type = constants.GET_ALL_PROMOTER_NETWORK;
}

export class GetAllPromoterNetworksSuccess extends AppAction <{
	promoterNetworks: PromoterNetwork[];
}> {
	readonly type = constants.GET_ALL_PROMOTER_NETWORK_SUCCESS;
}

export class GetAllPromoterNetworksFailed extends FailedAction {
	readonly type = constants.GET_ALL_PROMOTER_NETWORK_FAILED;
}

export class GetPromoterNetwork extends AppAction<{
	id: number;
}>{
	readonly type = constants.GET_PROMOTER_NETWORK;
}

export class GetPromoterNetworkSuccess extends AppAction <{
	promoterNetwork: PromoterNetwork;
}> {
	readonly type = constants.GET_PROMOTER_NETWORK_SUCCESS;
}

export class GetPromoterNetworkFailed extends FailedAction {
	readonly type = constants.GET_PROMOTER_NETWORK_FAILED;
}

export class UpdatePromoterNetworkLink extends AppAction<{
	id: number;
	code: string;
}>{
	readonly type = constants.UPDATE_PROMOTER_NETWORK_LINK;
}

export class UpdatePromoterNetworkLinkSuccess extends AppAction <{
	promoterNetwork: PromoterNetwork;
}> {
	readonly type = constants.UPDATE_PROMOTER_NETWORK_LINK_SUCCESS;
}

export class UpdatePromoterNetworkLinkFailed extends FailedAction {
	readonly type = constants.UPDATE_PROMOTER_NETWORK_LINK_FAILED;
}

export class UpdatePromoterBankAccount extends AppAction <{
	id: number;
	accountId: number;
}> {
	readonly type = constants.UPDATE_PROMOTER_BANK_ACCOUNT;
}
export class UpdatePromoterBankAccountSuccess extends AppAction <{
	promoterNetwork: PromoterNetwork;
}> {
	readonly type = constants.UPDATE_PROMOTER_BANK_ACCOUNT_SUCCESS;
}

export class UpdatePromoterBankAccountFailed extends FailedAction {
	readonly type = constants.UPDATE_PROMOTER_BANK_ACCOUNT_FAILED;
}

export class GetReferralDetails extends AppAction <{
}> {
	readonly type = constants.GET_REFERRAL_DETAILS;
}

export class GetReferralDetailsSuccess extends AppAction<{
	referrals: Referrals;
}> {
	readonly type = constants.GET_REFERRAL_DETAILS_SUCCESS;
}

export class GetReferralDetailsFailed extends FailedAction {
	readonly type = constants.GET_REFERRAL_DETAILS_FAILED;
}

export class UpdateReferralCode extends AppAction <{
	newCode: string;
}> {
	readonly type = constants.UPDATE_REFERRAL_CODE;
}

export class UpdateReferralCodeSuccess extends AppAction<{
	updateReferrals: UpdateReferrals;
}> {
	readonly type = constants.UPDATE_REFERRAL_CODE_SUCCESS;
}

export class UpdateReferralCodeFailed extends FailedAction {
	readonly type = constants.UPDATE_REFERRAL_CODE_FAILED;
}

export class UpdateReferralBankAccount extends AppAction <{
	id: number;
}> {
	readonly type = constants.UPDATE_REFERRAL_BANK_ACCOUNT;
}
export class UpdateReferralBankAccountSuccess extends AppAction <{
	updateReferrals: UpdateReferrals;
}> {
	readonly type = constants.UPDATE_REFERRAL_BANK_ACCOUNT_SUCCESS;
}

export class UpdateReferralBankAccountFailed extends FailedAction {
	readonly type = constants.UPDATE_REFERRAL_BANK_ACCOUNT_FAILED;
}

export type Actions =
	VerifyToken
	| VerifyTokenFailed
	| VerifyTokenSuccess
	| UpdateUserAPIToken
	| UpdateUserAPITokenFailed
	| UpdateUserAPITokenSuccess
	| SetAPIModalFlag
	| LogoutComplete
	| GetUserDesignerKey
	| GetUserDesignerKeySuccess
	| GetUserDesignerKeyFailed
	| GetUserCharts
	| GetUserChartsFailed
	| GetUserChartsSuccess
	| SetUserInfo
	| SetUserInfoFailed
	| SetUserInfoSuccess
	| CreateContactList
	| CreateContactListSuccess
	| CreateContactListFailed
	| GetPreviousContactLists
	| GetPreviousContactListsSuccess
	| GetPreviousContactListsFailed
	| GetPreviousEvents
	| GetPreviousEventsSuccess
	| GetPreviousEventsFailed
	| GetEventsAttendees
	| GetEventsAttendeesSuccess
	| GetEventsAttendeesFailed
	| SetActiveContactsSource
	| GetBankAccounts
	| GetBankAccountsSuccess
	| GetBankAccountsFailed
	| GetBanks
	| GetBanksSuccess
	| GetBanksFailed
	| GetAllBanks
	| GetAllBanksSuccess
	| GetAllBanksFailed
	| AddBankAccount
	| AddBankAccountSuccess
	| AddBankAccountFailed
	| UpdateBankAccount
	| UpdateBankAccountSuccess
	| UpdateBankAccountFailed
	| DeleteBankAccount
	| DeleteBankAccountSuccess
	| DeleteBankAccountFailed
	| GetUserInfo
	| GetUserInfoSuccess
	| GetUserInfoFailed
	| UpdateUserInfo
	| UpdateUserInfoSuccess
	| UpdateUserInfoFailed
	| UpdatePassword
	| UpdatePasswordSuccess
	| UpdatePasswordFailed
	| GetEmailPreferences
	| GetEmailPreferencesSuccess
	| GetEmailPreferencesFailed
	| UpdateEmailPreferences
	| UpdateEmailPreferencesSuccess
	| UpdateEmailPreferencesFailed
	| DeleteUser
	| DeleteUserSuccess
	| DeleteUserFailed
	| GetUserMyTickets
	| GetUserMyTicketsSuccess
	| GetUserMyTicketFailed
	| GetUserMyRegistrations
	| GetUserMyRegistrationsSuccess
	| GetUserMyRegistrationsFailed
	| GetRefundableTickets
	| GetRefundableTicketsSuccess
	| GetRefundableTicketsFailed
	| PostRefundableTickets
	| PostRefundableTicketsSuccess
	| PostRefundableTicketsFailed
	| GetTransferableTickets
	| GetTransferableTicketsSuccess
	| GetTransferableTicketsFailed
	| TransferTickets
	| TransferTicketsSuccess
	| TransferTicketsFailed
	| TransferTicketsBack
	| TransferTicketsBackSuccess
	| TransferTicketsBackFailed
	| GetEditTicketDetails
	| GetEditTicketDetailsSuccess
	| GetEditTicketDetailsFailed
	| UpdateEditTicketDetails
	| UpdateEditTicketDetailsSuccess
	| UpdateEditTicketDetailsFailed
	| GetRegistrationDetails
	| GetRegistrationDetailsSuccess
	| GetRegistrationDetailsFailed
	| UpdateRegistrationDetails
	| UpdateRegistrationDetailsSuccess
	| UpdateRegistrationDetailsFailed
	| FileUpload
	| FileUploadSuccess
	| FileUploadFailed
	| GetResaleTickets
	| GetResaleTicketsSuccess
	| GetResaleTicketsFailed
	| PostResaleTickets
	| PostResaleTicketsSuccess
	| PostResaleTicketsFailed
	| CancelResaleTickets
	| CancelResaleTicketsSuccess
	| CancelResaleTicketsFailed
	| GetUserSavedEvents
	| GetUserSavedEventsSuccess
	| GetUserSavedEventsFailed
	| GetAllPromoterNetworks
	| GetAllPromoterNetworksSuccess
	| GetAllPromoterNetworksFailed
	| GetPromoterNetwork
	| GetPromoterNetworkSuccess
	| GetPromoterNetworkFailed
	| UpdatePromoterNetworkLink
	| UpdatePromoterNetworkLinkSuccess
	| UpdatePromoterNetworkLinkFailed
	| UpdatePromoterBankAccount
	| UpdatePromoterBankAccountSuccess
	| UpdatePromoterBankAccountFailed
	| GetReferralDetails
	| GetReferralDetailsSuccess
	| GetReferralDetailsFailed
	| UpdateReferralCode
	| UpdateReferralCodeSuccess
	| UpdateReferralCodeFailed
	| UpdateReferralBankAccount
	| UpdateReferralBankAccountSuccess
	| UpdateReferralBankAccountFailed;
