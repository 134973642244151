import { UserEffects } from './user.effects';
import { EventEffects } from './event.effects';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { RouterEffects } from './router.effects';
import { NotificationEffects } from './notification.effects';
import { OrganiserProfileEffects } from './organiserProfiles.effects';
import { FileEffects } from './file.effects';
import { TicketEffects } from './ticket.effects';
import { VenueEffects } from './venue.effects';
import { EventDashboardEffects } from './eventDashboard.effects';
import { OrganiserEffects } from './organiser.effects';

@NgModule({
	imports: [
		EffectsModule.forRoot([
			UserEffects,
			EventEffects,
			EventDashboardEffects,
			VenueEffects,
			TicketEffects,
			FileEffects,
			OrganiserProfileEffects,
			RouterEffects,
			NotificationEffects,
			OrganiserEffects,
		]),
	],
}) export class EffectModule {}
