<div class="l-container">
    <div class="l-header">
        <h3 class="l-title" [ngClass]="{ 'mobile': data.isMobile }">Incentivise Guests</h3>
        <p class="l-content" [ngClass]="{ 'mobile': data.isMobile }">This message will be shown to ticket buyers on the purchase success page.</p>
    </div>
    <div class="l-form">
        <label class="g-form-label l-form-label"> {{label}} </label>
        <textarea class="l-form-field form-control" [(ngModel)]="incentiveMessage"></textarea>
    </div>
    <div class="l-two-buttons-container" *ngIf='!data.isMobile; else mobileButton'>
        <button class="g-secondary-button" (click)="handleClose()">CANCEL</button>
        <button class="g-primary-button" (click)="handleSave()">SAVE</button>
    </div>
    <ng-template #mobileButton>
        <div class="button-container">
            <button class="g-primary-button" (click)="handleSave()">ADD</button>
        </div>
    </ng-template>
</div>
