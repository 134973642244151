import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-link-bank-banner',
	templateUrl: './link-bank-banner.component.html',
	styleUrls: ['./link-bank-banner.component.sass'],
})
export class LinkBankBannerComponent {
	@Input() isMobile = false;
	@Output() handleClick: EventEmitter<void> = new EventEmitter<void>();

	handleButton() {
		this.handleClick.emit();
	}

}
