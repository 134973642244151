<div class='w-100'>
	<label class="l-label g-form-label">
		{{label}}
		<mat-icon 
			*ngIf="tooltip" 
			class="l-tooltip" 
			[matTooltip]="tooltip"
			matTooltipPosition="above"
			#imageTooltip="matTooltip"
			(click)="imageTooltip.toggle()"
		>
			info
		</mat-icon>
	</label>
	<input class="d-none" type="file" (change)="handleImageUpload($event)" [accept]="allowedFileTypes" #fileInput>
	<div *ngIf="!imagePath; else showImagePreview">
		<div *ngIf='!isUploading else imageUploading'
			[ngClass]="{'l-upload-container': !isMobile, 'l-upload-container-mobile': isMobile}"
			[ngStyle]="{'max-height': maxHeight, 'aspect-ratio': aspectRatio}" (click)="fileInput.click()"
			id="image-upload-container">
			<mat-icon class='l-upload-icon'>landscape</mat-icon>
			<div class='l-upload-text'>
				<ng-container *ngIf="customPreferredResText; else defaultText">
				  {{ uploadText }}
				  For best quality use a {{ preferredRes }} image.
				</ng-container>
				<ng-template #defaultText>
				  {{ uploadText }}
				  Please note your image must be {{ preferredRes }}.
				</ng-template>
			  </div>
		</div>
		<ng-template #imageUploading>
			<div [ngClass]="{'l-upload-container': !isMobile, 'l-upload-container-mobile': isMobile}"
				[ngStyle]="{'max-height': maxHeight, 'aspect-ratio': aspectRatio}">
				<mat-spinner class="l-image-loader" id="image-loader"></mat-spinner>
			</div>
		</ng-template>
	</div>
	<ng-template #showImagePreview>
		<img [src]="imagePath" *ngIf="imagePath" class="l-image-preview" [ngClass]="{'l-round' : isRound}"
			[ngStyle]="{'max-height': maxHeight, 'aspect-ratio': aspectRatio}" id="uploaded-image-preview">
		<div class='l-image-control-container'>
			<div class="l-image-control" (click)='handleImageRemove()' id="delete-image-button">
				<mat-icon [ngClass]="{'l-image-icon' : !isMobile, 'l-image-icon-mobile' : isMobile}">
					delete_outline
				</mat-icon>
				{{ showRemoveText ? 'Remove Image' : ''}}
			</div>
		</div>
	</ng-template>
</div>
