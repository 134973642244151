<div
	class="l-container"
	[ngClass]="{'mobile': data.isMobile}"
>
	<h3 class='mb-4'>Stop Sales</h3>
	<div class="l-toggle-container">
		<label class="g-form-label p-0 l-label">End Ticket Sales</label>
		<mat-slide-toggle
			class="standard-toggle"
			[(ngModel)]="endTicketSales"
			id="end-ticket-sales-toggle"
		>
		</mat-slide-toggle>
	</div>
	<p>
		Use this button to mark all ticket sales as ended. This marks the end ticket sales to the current time. Edit the
		end sales date of each ticket to open ticket sales.
	</p>
	<hr class='g-light-line my-4' />
	<div class="l-toggle-container">
		<label class="g-form-label p-0 l-label">
			Mark Event As Sold Out
		</label>
		<mat-slide-toggle
			class="standard-toggle"
			[(ngModel)]="soldOut"
			id="sold-out-toggle"
		>
		</mat-slide-toggle>
	</div>
	<p class='mb-4'>
		Note that outstanding EFT bookings that still have time remaining to pay will be allowed to complete their
		payment and get their tickets.
	</p>
	<div class="l-buttons-container">
		<button
			class="g-secondary-button l-button"
			(click)="handleCancel()"
			id="stop-sales-close"
		>CANCEL</button>
		<button
			class="g-primary-button l-button"
			[disabled]='!endTicketSales && !soldOut'
			[class.disabled]='!endTicketSales && !soldOut'
			(click)="handleClose()"
			id="submit-stop-sales"
		>SAVE CHANGES</button>
	</div>
</div>
