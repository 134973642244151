import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { AngularMaterialModule } from '@app/shared/angular-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ManageBookingComponent } from './pages/manage-booking/manage-booking.component';
import { RegistrationDetailsComponent } from './pages/registration-details/registration-details.component';
import { EditTicketComponent } from './pages/edit-ticket/edit-ticket.component';
import { TransferTicketComponent } from './pages/transfer-ticket/transfer-ticket.component';
import { TicketResaleComponent } from './pages/ticket-resale/ticket-resale.component';
import { RefundRequestComponent } from './pages/refund-request/refund-request.component';
import { RefundCardComponent } from './components/refund-card/refund-card.component';
import { RefundModalComponent } from './modals/refund-modal/refund-modal.component';
import { EditInvoiceModalComponent } from './modals/edit-invoice-modal/edit-invoice-modal.component';
import { DetailsCardComponent } from './components/details-card/details-card.component';
import { ValueMatchModule } from '@app/directive/value-match.directive';
import { SmallTextFieldComponent } from './components/form-components/small-text-field/small-text-field.component';
import { LargeTextFieldComponent } from './components/form-components/large-text-field/large-text-field.component';
import { DateFieldComponent } from './components/form-components/date-field/date-field.component';
import { AddressFieldComponent } from './components/form-components/address-field/address-field.component';
import { SectionHeadingComponent } from './components/form-components/section-heading/section-heading.component';
import { TermsAndConditionsComponent } from './components/form-components/terms-and-conditions/terms-and-conditions.component';
import { FileUploadComponent } from './components/form-components/file-upload/file-upload.component';
import { CheckBoxFieldComponent } from './components/form-components/check-box-field/check-box-field.component';
import { DropDownFieldComponent } from './components/form-components/drop-down-field/drop-down-field.component';
import { RadioButtonComponent } from './components/form-components/radio-button/radio-button.component';
import { CountrySelectComponent } from './components/form-components/country-select/country-select.component';
import { MultiChoiceBaseControlComponent } from './components/form-components/multi-choice-base-control/multi-choice-base-control.component'; // eslint-disable-line max-len
import { DynamicFormFieldsComponent } from './components/dynamic-form-fields/dynamic-form-fields.component';
import { TicketDetailsModalComponent } from './modals/ticket-details-modal/ticket-details-modal.component';
import { TransferBackModalComponent } from './modals/transfer-back-modal/transfer-back-modal.component';
import { MobileResaleCardComponent } from './components/mobile-resale-card/mobile-resale-card.component';
import { ResaleAvailableComponent } from './components/resale-available/resale-available.component';
import { ResaleListedComponent } from './components/resale-listed/resale-listed.component';
import { MiddleClickNavigateModule } from '@app/directive/middle-click-navigate.directive';
import { MyTicketsComponent } from './pages/my-tickets/my-tickets.component';
import { MyRegistrationsComponent } from './pages/my-registrations/my-registrations.component';
import { MobileResaleInfoModalComponent } from './modals/mobile-resale-info-modal/mobile-resale-info-modal.component';

@NgModule({
	declarations: [
		ManageBookingComponent,
		RegistrationDetailsComponent,
		EditTicketComponent,
		TransferTicketComponent,
		TicketResaleComponent,
		RefundRequestComponent,
		RefundCardComponent,
		RefundModalComponent,
		EditInvoiceModalComponent,
		DetailsCardComponent,
		SmallTextFieldComponent,
		LargeTextFieldComponent,
		DateFieldComponent,
		AddressFieldComponent,
		SectionHeadingComponent,
		TermsAndConditionsComponent,
		FileUploadComponent,
		CheckBoxFieldComponent,
		DropDownFieldComponent,
		RadioButtonComponent,
		CountrySelectComponent,
		MultiChoiceBaseControlComponent,
		DynamicFormFieldsComponent,
  		TicketDetailsModalComponent,
  		TransferBackModalComponent,
    	MobileResaleCardComponent,
		ResaleAvailableComponent,
		ResaleListedComponent,
		MyTicketsComponent,
		MyRegistrationsComponent,
  		MobileResaleInfoModalComponent,
	],
	imports: [
		CommonModule,
		SharedModule,
		AngularMaterialModule,
		ReactiveFormsModule,
		FormsModule,
		ValueMatchModule,
		MiddleClickNavigateModule,
	],
})
export class MyTicketsAndRegistrationsModule { }
