import { Routes } from '@angular/router';
import { LoginComponent } from '@app/core/login/login.component';

export const routes: Routes = [
	{
		path: '',
		redirectTo: '/account',
		pathMatch: 'full',
	},
	{
		path: 'login',
		component: LoginComponent,
	},
];
