import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-read-more-modal',
	templateUrl: './read-more-modal.component.html',
	styleUrls: ['./read-more-modal.component.sass'],
})
export class ReadMoreModalComponent {
	constructor(
		public dialogRef: MatDialogRef<ReadMoreModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data: {
			isMobile: boolean;
			modalTitle: string;
			modalDescription: string;
		}
	) {}

	handleClose() {
		this.dialogRef.close();
	}
}
