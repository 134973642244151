import { Component, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SchemeID } from '@app/models/dataSchema.model';
import { HeaderContent } from '@app/models/shared';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { StoreService } from '@app/services/store/store.service';
import { InternalURLCreator, URLCreator } from '@app/services/url/url.dictionary';
import { Go } from '@app/store/actions/router/router.actions';
import { EVENT_ID_PARAM_KEY, PROMOTER_NETWORK_ID_KEY } from '@app/utils/consts';
import { take, takeUntil } from 'rxjs';

@Component({
	selector: 'app-join-promoter-network',
	templateUrl: './join-promoter-network.component.html',
	styleUrls: ['./join-promoter-network.component.sass'],
})
export class JoinPromoterNetworkComponent {
	url: string;
	promoterNetworkId: SchemeID;
	promoterNetworkEncrypt: string;
	eventId: SchemeID;
	isMobile = false;

	headerContent: HeaderContent = {
		breadCrumbs: [
			{
				routeName: 'My Account',
				routeTo: () => InternalURLCreator.myAccount(),
			},
			{
				routeName: 'Join Promoter Network',
			},
		],
	};

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private activatedRoute: ActivatedRoute,
		private breakpointService: BreakpointService,
		private store: StoreService
	) {}

	ngOnInit(): void {
		this.activatedRoute.parent.queryParams.pipe(takeUntil(this.destroyed$)).subscribe((queryParams) => {
			if (queryParams['e']) {
				this.promoterNetworkEncrypt = queryParams['e'];
			}
		});

		this.activatedRoute.params.pipe(take(1)).subscribe((params) => {
			this.promoterNetworkId = +params[PROMOTER_NETWORK_ID_KEY];
			this.eventId = +params[EVENT_ID_PARAM_KEY];
			this.url = URLCreator.joinPromoterNetworkAspx(this.eventId, this.promoterNetworkId, this.promoterNetworkEncrypt);
		});

		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}

	handleNavigateBack(){
		this.store.dispatch(new Go({ path: [InternalURLCreator.earnCommission()] }));
	}
}
