<div class="price-field-wrap">
		<label class="ui-label" *ngIf="label" [pTooltip]="tooltip" [tooltipPosition]="tooltip ? tooltipPosition : null">
			{{label}}
			<span *ngIf="required" class="ui-required">*</span>
			<i *ngIf="labelIcon" [class]="'fa fa-' + labelIcon + 'circle'"></i>
		</label>
		<form-number-field-core
			[placeholder]="placeholder"
			[required]="required"
			[readonly]="readonly"
			[formControl]="control"
			[prefix]="getCurrencySymbol()"
			[suffix]="suffix"
			[includeThousandsSeparator]="includeThousandsSeparator"
			[thousandsSeparatorSymbol]="thousandsSeparatorSymbol"
			[allowDecimal]="allowDecimal"
			[decimalSymbol]="decimalSymbol"
			[decimalLimit]="decimalLimit"
			[integerLimit]="integerLimit"
			[allowNegative]="allowNegative"
			[allowLeadingZeroes]="allowLeadingZeroes"
			[formatOnBlur]="formatOnBlur"
			[formatOnFocus]="formatOnFocus"
			[allowSpinButtons]="false"
			[cssClassList]="getFormFieldClasses(offset, 'number-field-core-wrap')"
		></form-number-field-core>
		<small class="ui-error-msg" *ngIf="control.invalid && (control.dirty || control.touched)">{{getError(control.errors)}}</small>
	</div>
