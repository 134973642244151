import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';

@Component({
	selector: 'app-date-field',
	templateUrl: './date-field.component.html',
	styleUrls: ['./date-field.component.sass'],
})
export class DateFieldComponent implements OnInit, OnChanges{
	@Input() isMobile: boolean;
	@Input() inputFormControl: FormControl;
	@Input() label: string;
	@Input() validations: string;
	@Input() isRequired = false;

	minAge: number;
	maxAge: number;

	isDisabled = false;

	displayControl = new FormControl();

	ngOnInit(): void {
		this.isDisabled = this.inputFormControl.disabled;
		const momentDateObject = moment(this.inputFormControl.value, 'DD/MM/YYYY');
		const dateObject = momentDateObject.toDate();
		this.displayControl.setValue(dateObject);

		if (this.isDisabled) {
			this.displayControl.disable();
		}

		this.displayControl.valueChanges.subscribe(value => {
			if (value) {
				this.inputFormControl.setValue(moment(value).format('DD/MM/YYYY'));
			} else {
				this.inputFormControl.setValue('');
			}
			this.inputFormControl.markAsTouched();
			this.inputFormControl.markAsDirty();
		});

		if (this.validations) {
			const parts = this.validations.split('#');
			this.minAge = parts[0] ? parseInt(parts[0], 10) : null;
			this.maxAge = parts[1] ? parseInt(parts[1], 10) : null;
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.inputFormControl) {
			const dateChange = changes.inputFormControl.currentValue;

			if (!changes.inputFormControl.isFirstChange() && dateChange.value !== this.displayControl.value) {
				if (dateChange.value) {
					this.displayControl.setValue(moment(dateChange.value, 'DD/MM/YYYY').toDate());
				} else {
					this.displayControl.setValue('');
				}
			}
		}
	}
}
