import { Injectable } from '@angular/core';
import config from '@app/config';

@Injectable()
export class MapsService {
	private geocoder: google.maps.Geocoder;
	private loaded = false;

	defaultMapZoom = 14;

	constructor(
		// private mapsAPILoader: MapsAPILoader
	) {}

	initializeMaps(): Promise<void> {
		return new Promise((resolve, reject) => {
		  if (this.loaded) {
				resolve();
				return;
		  }

		  const script = document.createElement('script');
		  script.src = `https://maps.googleapis.com/maps/api/js?key=${config.googleAPIKey}&libraries=places`;
		  script.onload = () => {
				this.loaded = true;
				resolve();
		  };
		  script.onerror = (error: any) => reject(error);
		  document.head.appendChild(script);
		});
	}

	initGeocoder() {
		if (!this.geocoder){
			this.geocoder = new google.maps.Geocoder();
		}
	}

	getPlaceByCoordinates({ longitude, latitude, locationCb }) {
		if (this.geocoder) {
			this.geocoder.geocode({
				location: {
					lat: latitude,
					lng: longitude,
				},
			}, locationCb);
		}
	}

	getDefaultLocation(cb) {
		if ('geolocation' in navigator) {
			navigator.geolocation.getCurrentPosition(position => {
				cb({
					latitude: position.coords.latitude,
					longitude: position.coords.longitude,
				});
			});
		}
	}

	parsePlace(place: google.maps.places.PlaceResult) {
		const components: { [key: string]: string } = {};
		if (place) {
			place.address_components
				.forEach(el => {
					components[el.types[0]] = el.long_name;
				});
		}

		if (place.formatted_address) {
			components.longAddress = place.formatted_address;
		}

		if (place.name) {
			components.name = place.name;
		}

		return components;
	}

	getAddressLine(components, place: google.maps.places.PlaceResult): { addressLine1: string; addressLine2: string; longAddress: string } {
		const sublocalityLevel1 = components.sublocality_level_1 ? components.sublocality_level_1 : '';
		const sublocalityLevel2 = components.sublocality_level_2 ? `, ${components.sublocality_level_2}` : '';
		let addressLine2 = `${sublocalityLevel1}${sublocalityLevel2}`;

		if (!addressLine2){
			const sublocalities = place.address_components.filter(el => el.types.includes('sublocality'));
			addressLine2 = sublocalities.length ? sublocalities.map(el => el.long_name).join(', ') : '';
		}

		const addressLine = {
			addressLine1: `${components.street_number ? components.street_number + ' ' : ''}${components.route ? components.route : ''}`,
			addressLine2: addressLine2,
			longAddress: components.longAddress,
		};
		return addressLine;
	}
}
