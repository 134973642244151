import { Component, OnInit, Input } from '@angular/core';
import { user as userSelector } from '@app/store/selectors/user.selector';
import { StoreService } from '@app/services/store/store.service';
import { Observable } from 'rxjs';
import { startWith, filter } from 'rxjs/operators';
import { URLCreator } from '@app/services/url/url.dictionary';
import { QUICKET_LOGO } from '@app/utils/consts';
import { convertToIdFormat } from '@app/utils/common-helpers';
import { MatMenuTrigger } from '@angular/material/menu';

interface NavMenuItem {
	title: string;
	href?: string;
	routerLink?: string;
	extraClass?: string;
}

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.sass'],
})
export class HeaderComponent implements OnInit {
	user$: Observable<{ info: { userName: string } }>;
	navMenuItems: NavMenuItem[] = [];
	QUICKET_LOGO = QUICKET_LOGO;
	@Input() withSearch = false;

	enteredButton = false;
	enteredMenu = false;
	isMatMenuOpen = false;

	constructor(private storeService: StoreService) {
		this.navMenuItems = [
			{
				title: 'CREATE EVENT',
				href: URLCreator.createEvent(),
				extraClass: 'l-highlighted l-top-menu-item',
			},
			{
				title: 'MY TICKETS',
				href: URLCreator.manageTicketDashboard(),
			},
			{
				title: 'ORGANISER HUB',
				href: URLCreator.orgDashboard(),
			},
			{
				title: 'MY ACCOUNT',
				href: URLCreator.userProfile(),
			},
			{
				title: 'MY SAVED EVENTS',
				href: URLCreator.savedEvents(),
			},
			{
				title: 'EARN COMMISSION',
				href: URLCreator.earnCommission(),
			},
			{
				title: 'LOG OUT',
				href: URLCreator.logout(),
				extraClass: 'l-dangerous l-bottom-menu-item',
			},
		];
	}

	ngOnInit() {
		this.user$ = this.storeService.select(userSelector()).pipe(
			startWith({
				info: { userName: null },
			}),
			filter((user) => !!user.info)
		);
	}

	formatId(itemTitle: string, prefix: string): string {
		return convertToIdFormat(itemTitle, prefix);
	}

	handleMenuEnter() {
		this.enteredMenu = true;
		this.isMatMenuOpen = true;
	}

	handleMenuLeave(menuLeaveTrigger: MatMenuTrigger) {
		 this.enteredMenu = false;
		 setTimeout(() => {
		 	if (!this.enteredButton && !this.enteredMenu) {
		 		this.isMatMenuOpen = false;
		 		menuLeaveTrigger.closeMenu();
		 	}
		 }, 400);
	}

	handleButtonEnter(buttonEnterTrigger: MatMenuTrigger) {
		this.enteredButton = true;
		setTimeout(() => {
			if (!this.isMatMenuOpen) {
				buttonEnterTrigger.openMenu();
			}
		}, 200);
	}

	handleButtonLeave(buttonLeaveTrigger: MatMenuTrigger) {
		 this.enteredButton = false;
		 setTimeout(() => {
		 	if (!this.enteredButton && !this.enteredMenu) {
		 		buttonLeaveTrigger.closeMenu();
		 	}
		 }, 600);
	}
}
