<div class="l-container">
	<button class="l-button" [matMenuTriggerFor]="menu" id="event-actions-button" (menuClosed)="menuClosed()" (menuOpened)="menuOpened()">
		Event Actions 
		<mat-icon class="l-button-icon" [@rotateIcon]="expanded ? 'rotated' : 'default'">expand_more</mat-icon>
	</button>
	<mat-menu #menu="matMenu">
		<button mat-menu-item id="event-actions-copy-event-button" (click)="handleEventActionCopy()">
			<img src="./assets/icons/copy.svg" alt="Copy Icon" class="l-event-action-icon">
			Copy Event
		</button>
		<div *ngIf="!isDraft">
			<button mat-menu-item id="event-actions-postpone-event-button" (click)="handleEventActionPostpone()">
				<img src="./assets/icons/calendar.svg" alt="Calendar Icon" class="l-event-action-icon">
				Postpone Event
			</button>
			<button mat-menu-item id="event-actions-cancel-event-button" (click)="handleEventActionCancel()">
				<img src="./assets/icons/trash.svg" alt="Trash Icon" class="l-event-action-icon">
				Cancel Event
			</button>
		</div>
		<button mat-menu-item id="event-actions-copy-event-link-button" (click)="handleEventActionCopyLink()">
			<img src="./assets/icons/copy-squares.svg" alt="Copy Squares Icon" class="l-event-action-icon">
			Copy Short Link
		</button>
		<button mat-menu-item id="event-actions-edit-event-link-button" (click)="handleEventActionEditLink()">
			<img src="./assets/icons/edit-black.svg" alt="Edit Icon" class="l-event-action-icon">
			Edit Short Link
		</button>
		<button mat-menu-item id="event-actions-send-invites-button" (click)="handleEventActionSendInvites()">
			<img src="./assets/icons/email-black.svg" alt="Email Icon" class="l-event-action-icon">
			Send Invites
		</button>
		<button mat-menu-item id="event-actions-qr-code-button" (click)="handleEventQRCodeDownload()">
			<img src="./assets/icons/qr-code-black.svg" alt="QR Code Icon" class="l-event-action-icon">
			Event QR Code
		</button>
	</mat-menu>
	<a [href]="eventDetailsUrl">
		<button id="dashboard-edit-details" class="l-button l-button-edit-details">Edit Event Details</button>
	</a>
</div>