import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { AsyncResponse, RequestType } from '@app/models/http.model';
import { TopLinkCampaigns } from '@app/models/event.model';
import {
	ProductFilter,
	EventList,
	MailAccount,
	NameAndID,
	OrganiserDashboardEvents,
	SalesOverview,
	MailChimpConnection,
	DateRange,
	DownloadUserReportsInfo,
	UserReportInfo
} from '@app/models/organiser.model';
import { IOrganiserDomainAPI } from '@app/models/organiser.api.model';
import { stringifyObjectValues } from '@app/utils/common-helpers';

@Injectable()
export class OrganiserDomainAPI implements IOrganiserDomainAPI {
	constructor(
		private http: HttpService
	) { }

	getSalesOverview = (id: number, dateRange: DateRange, currencyId: number): AsyncResponse<SalesOverview> => {
		const stringifiedFilter = stringifyObjectValues(dateRange);
		const queryParams = new URLSearchParams(stringifiedFilter).toString();
		return this.http.makeRequest({
			url: `/api/users/${id}/salesoverview?${queryParams}&currencyId=${currencyId}`,
			type: RequestType.GET,
		});
	};

	getCurrentCampaigns = (id: number): AsyncResponse<TopLinkCampaigns[]> =>
		this.http.makeRequest({
			url: `/api/users/${id}/currentlinkcampaigns`,
			type: RequestType.GET,
		});

	getFilteredProducts = (id: number, productsFilter: ProductFilter): AsyncResponse<EventList> => {
		const stringifiedFilter = stringifyObjectValues(productsFilter);
		const queryParams = new URLSearchParams(stringifiedFilter).toString();
		return this.http.makeRequest({
			url: `/api/users/${id}/products?${queryParams}`,
			type: RequestType.GET,
		});
	};

	getOrganiserNames = (id: number): AsyncResponse<NameAndID[]> =>
		this.http.makeRequest({
			url: `/api/users/${id}/organisernames`,
			type: RequestType.GET,
		});

	getMailAccountsByUserId = (id: number): AsyncResponse<MailAccount[]> =>
		this.http.makeRequest({
			url: `/api/users/${id}/mailaccounts`,
			type: RequestType.GET,
		});

	addMailAccount = (id: number, mailAccount: MailAccount): AsyncResponse<MailAccount[]> =>
		this.http.makeRequest({
			url: `/api/users/${id}/mailaccounts/`,
			type: RequestType.POST,
			payload: mailAccount,
		});

	deleteMailAccount = (id: number, mailAccountId: number): AsyncResponse<MailAccount[]> =>
		this.http.makeRequest({
			url: `/api/users/${id}/mailaccounts/${mailAccountId}/delete`,
			type: RequestType.DELETE,
		});

	getDashboardEvents = (id: number): AsyncResponse<OrganiserDashboardEvents> =>
		this.http.makeRequest({
			url: `/api/users/${id}/dashboardevents`,
			type: RequestType.GET,
		});

	getMailChimpConnection = (id: number, code: string): AsyncResponse<MailChimpConnection> =>
		this.http.makeRequest({
			url: `/api/organisers/${id}/mailchimp?code=${code}`,
			type: RequestType.GET,
		});

	postUnlinkMailChimp = (id: number): AsyncResponse<MailChimpConnection> =>
		this.http.makeRequest({
			url: `/api/organisers/${id}/mailchimp/disconnect`,
			type: RequestType.POST,
		});

	getUserReportsInfo = (id: number): AsyncResponse<UserReportInfo> =>
		this.http.makeRequest({
			url: `/api/users/${id}/reports`,
			type: RequestType.GET,
		});

	downloadUserReports = (id: number, downloadUserReports: DownloadUserReportsInfo): AsyncResponse<string> =>
		this.http.makeRequest({
			url: `/api/users/${id}/reports/download`,
			type: RequestType.POST,
			payload: downloadUserReports,
		});
}
