import { NgModule } from '@angular/core';
import { UserControlPanelModule } from './user-control-panel.module';
import { Title } from '@angular/platform-browser';


@NgModule({
	imports: [
		UserControlPanelModule,
	],
	exports: [
		UserControlPanelModule,
	],
	providers: [
		Title,
	],
})
export class UserFeaturesModule {}
