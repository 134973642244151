import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AccountDetails, AdditionalInfo, Bank, BankAccount, BankBranch } from '@app/models/user.model';
import { StoreService } from '@app/services/store/store.service';
import { AddBankAccount, GetAllBanks, GetBanks } from '@app/store/actions/user/user.actions';
import { UserActionsConstants } from '@app/store/actions/user/user.actions.constants';
import { Actions, ofType } from '@ngrx/effects';
import { take, takeUntil } from 'rxjs/operators';
import * as eventSelectors from '@app/store/selectors/event.selector';
import * as bankAccountsSelectors from '@app/store/selectors/userBankAccounts.selector';
import { Action } from '@ngrx/store';
import { BankAccountDescriptions } from '@app/models/shared';
import { NgForm } from '@angular/forms';

@Component({
	selector: 'app-bank-account-modal',
	templateUrl: './bank-account-modal.component.html',
	styleUrls: ['./bank-account-modal.component.sass'],
})
export class BankAccountModalComponent implements OnInit {
	defaultBranch: BankBranch = {
		id: 0,
		branchCode: '',
		branchName: '',
	};

	bankDetails: AccountDetails = {
		bank: null,
		accountHolder: null,
		accountNumber: null,
		isDefault: false,
		branch: this.defaultBranch,
	};

	branches: BankBranch[];
	filteredBranches: BankBranch[];
	banks: Bank[];
	filteredBanks: Bank[];

	accounts: BankAccount[];
	selectedAccount: BankAccount;

	hiddenNumber: string;

	isBankAccountsFetching = true;
	isBanksLoading = true;

	accountsErrorMessage: string;
	banksErrorMessage: string;

	email: string;
	isPublic = false;
	additionalInfoIsValid = true;
	confirmAccountNumber = null;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private store: StoreService,
		private actions$: Actions,
		public dialogRef: MatDialogRef<BankAccountModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data: {
			isMobile: boolean;
			currencyId: number;
			descriptions: BankAccountDescriptions;
			collectAdditionalInfo: boolean;
			allowPublicResale: boolean;
			allowPrivateResale: boolean;
		}
	) {	}

	ngOnInit() {
		this.actions$.pipe(
			ofType(
				UserActionsConstants.GET_BANKS_SUCCESS,
				UserActionsConstants.GET_BANKS_FAILED,
				UserActionsConstants.GET_ALL_BANKS_SUCCESS,
				UserActionsConstants.GET_ALL_BANKS_FAILED
			), take(1)
		).subscribe((payload: Action) => {
			if (
				payload.type === UserActionsConstants.GET_BANKS_SUCCESS
				|| payload.type === UserActionsConstants.GET_ALL_BANKS_SUCCESS
			) {
				this.store.select(eventSelectors.banks()).pipe(take(1)).subscribe((banks) => {
					this.banks = banks;
					this.filteredBanks = banks;
				});
			} else {
				this.banksErrorMessage = 'Unable to fetch banks, please try again';
			}
			this.isBanksLoading = false;
		});

		this.store.select(bankAccountsSelectors.userBankAccounts()).pipe(takeUntil(this.destroyed$)).subscribe((accounts) => {
			if (accounts) {
				if (this.data.currencyId) {
					this.accounts = accounts.filter((account) => account.currency.id === this.data.currencyId);
				} else {
					this.accounts = accounts;
				}
				if (this.accounts.length === 0) {
					this.accountsErrorMessage = 'You have no saved bank accounts';
				}
			} else {
				this.accountsErrorMessage = 'No bank accounts found';
			}
			this.isBankAccountsFetching = false;
		});

		if (this.data.currencyId) {
			this.store.dispatch(new GetBanks({ currencyId: this.data.currencyId }));
		} else {
			this.store.dispatch(new GetAllBanks());
		}

		this.actions$
			.pipe(
				ofType(UserActionsConstants.ADD_BANK_ACCOUNT_SUCCESS, UserActionsConstants.ADD_BANK_ACCOUNT_FAILED),
				takeUntil(this.destroyed$)
			)
			.subscribe(({ type, payload }:
			{
				type: string; payload: { account: BankAccount };
			}) => {
				if (type === UserActionsConstants.ADD_BANK_ACCOUNT_SUCCESS) {
					this.dialogRef.close({ accountId: +payload.account.id, isPublic: this.isPublic, email: this.email });

				} else {
					this.banksErrorMessage = 'Something went wrong adding your bank account.';
				}
				this.isBanksLoading = false;
			});
	}

	handleBankFilter(input: string) {
		if (!this.banks) {
			return;
		}
		if (input) {
			const search = input?.toLowerCase();
			this.filteredBanks = this.banks.filter(bank => bank.name.toLowerCase().includes(search));
		};
	}

	handleBranchesFilter(input: string) {
		if (!this.branches) {
			return;
		}
		if (input) {
			const search = input?.toLowerCase();
			this.filteredBranches = this.branches.filter(branch => branch.branchName.toLowerCase().includes(search));
		};
	}

	handleBankSelection() {
		this.branches = this.bankDetails.bank.branches;
		this.filteredBranches = this.branches;
	}

	handleCancel() {
		this.dialogRef.close();
	}

	handleSave() {
		this.isBanksLoading = true;

		const {
			bank: { id: bankId, universalCode, name: bankName },
			accountHolder: holder,
			accountNumber,
			isDefault,
		} = this.bankDetails;
		const newBankAccount = {
			bankId,
			branchCode: this.bankDetails.branch.branchCode ? this.bankDetails.branch.branchCode : universalCode,
			holder,
			mobileNumber: null,
			accountNumber,
			isDefault,
			branch: this.bankDetails.branch,
			bankName,
		};
		this.store.dispatch(new AddBankAccount({ draftAccount: newBankAccount }));
	}

	handleAccountSave() {
		this.dialogRef.close({ accountId: +this.selectedAccount.id, isPublic: this.isPublic, email: this.email });
	}

	handleAdditionalInfo(info: AdditionalInfo): void {
		this.email = info.email;
		this.isPublic = info.isPublic;
		this.additionalInfoIsValid = info.isValid;
	}

	validateAccountNumbers(accountDetailsForm: NgForm){
		accountDetailsForm.form.get('accountNumber').updateValueAndValidity();
		accountDetailsForm.form.get('confirmAccountValue').updateValueAndValidity();
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
