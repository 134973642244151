import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EventCurrencyOption } from '@app/models/event.model';
import { takeUntil } from 'rxjs';

@Component({
	selector: 'app-currency-select-modal',
	templateUrl: './currency-select-modal.component.html',
	styleUrls: ['./currency-select-modal.component.sass'],
})
export class CurrencySelectModalComponent implements OnInit {
	currencyCtrl: UntypedFormControl = new UntypedFormControl();
	currencyFilterCtrl: UntypedFormControl = new FormControl<string>('');
	filteredCurrencies: EventCurrencyOption[] = [];
	currency: EventCurrencyOption;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		public dialogRef: MatDialogRef<CurrencySelectModalComponent>,
		@Inject(MAT_DIALOG_DATA)
		public data: {
			currencies: EventCurrencyOption[];
			currencyCode: string;
			title: string;
			text: string;
			currencyId: number;
		}) { }

	ngOnInit(): void {
		this.filteredCurrencies = this.data.currencies;

		if (this.data.currencyId) {
			this.currency = this.data.currencies.find(el => el.value.id === this.data.currencyId);
		} else {
			this.currency = this.data.currencies.find(el => el.value.code === this.data.currencyCode);
		}

		this.currencyCtrl.setValue(this.currency ? this.currency : null);

		this.currencyFilterCtrl.valueChanges
			.pipe(takeUntil(this.destroyed$))
			.subscribe(() => {
				this.filterCurrencies();
			});
	}

	handleClose(currency?: EventCurrencyOption) {
		this.dialogRef.close(currency);
	}

	filterCurrencies() {
		if (!this.data.currencies) {
			return;
		}
		let search = this.currencyFilterCtrl.value;
		if (search) {
			search = search.toLowerCase();
			this.filteredCurrencies = this.data.currencies.filter(currency => currency.title.toLowerCase().includes(search));
		} else {
			this.filteredCurrencies = this.data.currencies;
		};
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}

}
