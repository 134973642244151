import { SchemeID } from '@app/models/dataSchema.model';
import { ProductStatus } from '@app/models/event.model';
import { ProductType } from './product.model';

export interface User {
	id: number;
	email: string;
	userName: string;
	encrypt: string;
	seatsDesignerKey: string;
	isAdmin: boolean;
	contactInfo: UserContactInfo;
	emailEncrypt: string;
	apiToken: string;
}

export interface UserContactInfo {
	firstName: string;
	surname: string;
	phone: string;
	dateOfBirth?: string;
	idPassportNumber?: string;
	gender?: string;
	company?: string;
	email?: string;
	cellphone?: string;
	telephone?: string;
	userAddress?: UserAddress;
}

export interface UserAddress {
	isPoBox?: boolean;
	number?: string;
	street?: string;
	suburb?: string;
	city?: string;
	postalCode?: number;
	country?: string;
}

export interface Country {
	name?: string;
	alpha2Code: string;
	alpha3Code?: string;
	numericCode?: string;
	callingCode?: string;
}

export class UserContactsInviteBatch {
	contactListName = '';
	contacts: UserContactListDraftItem[] = [];
}

export interface UserContactListDraftItem {
	email: string;
	name: string;
	surname: string;
}

export interface Passwords {
	oldPassword: string;
	newPassword: string;
}

export interface EmailPreference {
	email: string;
	subscribedToNewsletter: boolean;
	subscribedToDailySales: boolean;
	subscribedToInvites: boolean;
	subscribedToRecommendations: boolean;
	decryptFailed: boolean;
}

export interface EmailSubscription {
	email: string;
	subscriptions: Subscription[];
	decryptFailed: boolean;
}

export interface Subscription {
	subscriptionStatus: string;
	subscriptionType: string;
}

export enum EmailSubscriptionType {
	subscribedToNewsletter = 'newsletter',
	subscribedToDailySales = 'dailySalesUpdates',
	subscribedToInvites = 'invites',
	subscribedToRecommendations = 'recommendations',
}

export interface PreviousEvent {
	id: SchemeID;
	eventName: string;
	numberOfAttendees: number;
	eventDate: string;
}

export interface UserContactListItem extends UserContactListDraftItem {
	id: SchemeID;
}

export enum ContactsSource {
	PreviousList,
	PreviousEvent,
	Google,
	Outlook,
	Manual,
	CSV,
}

export interface DraftBankAccount {
	bankId: SchemeID | Bank;
	branchCode: string;
	holder: string;
	mobileNumber: string;
	accountNumber: string;
	isDefault: boolean;
	branch: BankBranch | number;
	bankName?: string;
}

export interface BankCurrency {
	id: SchemeID;
	iso: string;
}

export interface BankAccountReturn {
	bankAccounts: BankAccount[];
	needsPasswordVerification: boolean;
	passwordVerificationRedirect: string;
}

export interface BankAccount {
	id?: SchemeID;
	bankId?: SchemeID;
	bankName: string;
	holder: string;
	number: string;
	isDefault: boolean;
	isSelected?: boolean;
	currency: BankCurrency;
	branch: BankBranch | number;
	branchCode: string;
	logoUrl?: string;
	responseMessage?: string;
}

export interface CurrentBankAccount {
	BankAccountId: number;
	holder: string;
	mobileNumber: string;
	accountNumber: string;
	isDefault: boolean;
	branchCode: string;
	branchId: SchemeID;
	branchName: string;
}

export interface Bank {
	id: SchemeID;
	name: string;
	universalCode: string;
	isMobileMoney: boolean;
	currency: BankCurrency;
	branches: BankBranch[];
}

export interface BankBranch {
	id: SchemeID;
	branchName: string;
	branchCode: string;
}

export interface GroupedBank {
	iso: string;
	banks: Bank[];
}

export interface AccountDetails {
	bank: Bank;
	accountHolder: string;
	accountNumber: string;
	isDefault: boolean;
	branch?: BankBranch;
	universalCode?: string;
}

export interface AccountModalReturn {
	accountId: number;
	isPublic?: boolean;
	email?: string;
}

export interface ManageBooking {
	id: SchemeID;
	encrypt: string;
	productId: SchemeID;
	eventName: string;
	eventLink: string;
	datePurchased: Date;
	dateFrom: Date;
	dateTo: Date;
	canRefund: boolean;
	eventImage: string;
	venueName: string;
	googleMapsUrl: string;
	amountPaid: string;
	organiser: ManageBookingOrganiserDetails;
	tickets: ManageBookingTicket[];
	hasSchedules: boolean;
	schedules: ManageBookingSchedules[];
	invoice: ManageBookingInvoice;
	refundProtectType: BookingRefundProtectType;
	canTransfer: boolean;
	canTicketResale: boolean;
	scheduleItemId: SchemeID;
	hasSeatingChart: boolean;
	allowOrphanSeats: boolean;
	showSeatLabels: boolean;
	seatPublicKey: string;
	seatingPricing: ManageBookingSeating[];
	productStatus: ProductStatus;
	enableContactHost: boolean;
	allowTicketEditing: boolean;
	isPaid: boolean;
	reference: string;
	isDonationOnly: boolean;
	canTransferBack: boolean;
	purchaseStep: number;
	cartId: number;
	eventIsCancelled: boolean;
	contactHostUrl: string;
	invoiceUrl: string;
	isLocked: boolean;
}

export interface ManageBookingOrganiserDetails {
	id: SchemeID;
	name: string;
	phone: string;
	twitter: string;
	facebook: string;
}

export interface ManageBookingSchedules {
	id: SchemeID;
	name: string;
	dateFrom: Date;
	dateTo: Date;
	canTransfer: boolean;
	status: ProductStatus;
}

export interface ManageBookingTicket {
	id: SchemeID;
	isStreamingTicket: boolean;
	ticketType: string;
	streamAccessCode: string;
	streamAccessUrl: string;
	isValid: boolean;
	questions: ManageBookingTicketQuestion[];
	isDonation: boolean;
	seat: string;
	price: string;
}

export class ManageBookingInvoice {
	line1: string = null;
	line2: string = null;
	line3: string = null;
	line4: string = null;
	line5: string = null;
}

export interface ManageBookingTicketQuestion {
	question: string;
	answer: string;
}

export interface ManageBookingSeating {
	category: string;
	ticketTypes: ManageBookingSeatingTicket[];
}

export interface ManageBookingSeatingTicket {
	ticketType: string;
	price: string;
	ticketId: SchemeID;
}

export enum BookingRefundProtectType {
	None = 1,
	RefundProtect = 2,
	BookingProtect = 3,
}


export interface Referrals {
	commissionPercentage: string;
	code: string;
	affiliateLinkExcludingCode: string;
	totalBalanceOwed: number;
	referralCommissionsEarned: ReferralCommissionEarned[];
	selectedBankAccountSemiHashed: string;
}

export interface UpdateReferrals {
	code: string;
	bankAccountSemiHashed: string;
	success: boolean;
	errorMessage: string;
}

export interface ReferralCommissionEarned {
	date: Date;
	balance: number;
	bankAccountSemiHash: string;
	info?: string;
}

export class MyTickets {
	id: SchemeID = null;
	productId: SchemeID = null;
	productName: string = null;
	eventLink: string = null;
	reference: string = null;
	venue: string = null;
	dateFrom: Date = null;
	dateTo: Date = null;
	dateAdded: Date = null;
	productType: ProductType = null;
	productStatus: ProductStatus = null;
	bookingAmount: number = null;
	EFTCutoff: Date = null;
	bookingAmountFormatted: string = null;
	eventImage: string = null;
	isProvisional = false;
	paid = false;
	showMap = false;
	gpsCoordinates: string = null;
	googleMapsUrl: string = null;
	encrypt: string = null;
	invoice: ManageBookingInvoice = null;
	printAllCartTicketsUrl: string = null;
	currencySymbol: string = null;
}

export interface MyRegistrations {
	productId: number;
	preRegistrationId: number;
	productName: string;
	dateFrom: string;
	dateTo: string;
	eventImage: string;
	status: number;
}

export interface RefundableTicket {
	ticketHolder: string;
	barcode: number;
	productDealName: string;
	productDealId: number;
	price: number;
	currencySymbol: string;
	purchaseItemId: number;
	canRefund: boolean;
	ticketStatus: string;
}

export interface RefundableTicketReturn {
	errorMessage: string;
	canRefund: string;
	currencyId: number;
	reference: string;
	productImage: string;
	productName: string;
	refundMethod: RefundOptions;
	tickets: RefundableTicket[];
	bank: BankAccount;
}

export interface RefundOptions {
	canRefundViaQuicket: boolean;
	gatewaySupportsRefunding: boolean;
	quicketHasTheMoney: boolean;
	refundRequiresBankAccount: boolean;
}

export interface TransferTicketReturn {
	statusMessage: string;
	tickets: TransferTicket[];
	email: string;
	onlyOneTransferAllowed: boolean;
	purchaseReference: string;
}

export interface TransferTicket {
	barcode: number;
	seat: string;
	purchaseItemId: number;
	canTransfer: boolean;
	transferReason: string;
	ticketName: string;
}

export interface TransferTicketRequest {
	email: string;
	tickets: TransferTicket[];
}

export interface AccountTicketResales {
	purchaseReference: string;
	productName: string;
	refundRequiredBankAccount: boolean;
	allowPrivate: boolean;
	allowPublic: boolean;
	currencyId: number;
	ticketsForResale: AccountTicketResalePurchaseItem[];
	ticketsInResale: AccountTicketResaleItem[];
}

export class AccountTicketResalePurchaseItem {
	purchaseItemId: number;
	ticketTypeName: string;
	price: number;
	ticketHolder: string;
	canResale: boolean;
	reasonCantResale: string;
	isPublic = false;
	email: string = null;
}

export interface AccountTicketResaleItem {
	id: number;
	ticketResaleId: number;
	purchaseItemId: number;
	ticketTypeName: string;
	price: number;
	ticketHolder: string;
	type: number;
	dateCreated: string;
	bankAccountId: number;
	bankAccountHashed: string;
	status: number;
	statusDescription: string;
	email: string;
	needsBankDetails: boolean;
	canCancel: boolean;
	canEditBankDetails: boolean;
}

export interface CreateTicketResale {
	bankAccountId: number;
	tickets: CreateTicketResaleItem[];
}

export interface CreateTicketResaleItem {
	purchaseItemId: number;
	type: number;
	email: string;
}

export interface PromoterNetwork {
	id: number;
	productName: string;
	productStatus: number;
	shortlinkCode: string;
	shortlinkUrl: string;
	commissionPercentage: number;
	totalEarned: number;
	selectedBankAccountSemiHashed: string;
	payments: PromoterNetworkPayout[];
	eventImage: string;
	success: boolean;
	errorMessage: string;
}

export interface PromoterNetworkPayout {
	date?: Date;
	account?: string;
	yourShare?: string;
	sales?: string;
	ticketsSold?: number;
	linkClicks?: number;
}

export class MailTicketsPdf {
	purchaseId: SchemeID;
	purchaseItemIds: number[];
}

export interface DetailParameters {
	canTransfer: boolean;
	canRefund: boolean;
	canTicketResale: boolean;
	allowTicketEditing: boolean;
	canTransferBack: boolean;
	contactHost: boolean;
}


export interface UploadFile {
	purchaseItemId: number;
	questionId: number;
	originalFileName: string;
	fileName: string;
	downloadUrl: string;
}

export interface CheckoutFileUpload {
	success: boolean;
	error: string;
	downloadUrl: string;
	purchaseItemId?: number;
	questionId?: number;
}

export interface Address {
	formattedAddress: string;
	type: string;
	lineOne: string;
	lineTwo: string;
	lineThree: string;
	lineFour: string;
	lineFive: string;
}

export interface EditTicketDetails {
	hasCheckedInTickets: boolean;
	hasUniqueError: boolean;
	isLocked: boolean;
	numEditsLimited: boolean;
	numEditsUsed: number;
	numEditsRemaining: number;
	eventName: string;
	errorMessage: string;
	transferred: boolean;
	productId: number;
	encrypt: string;
	purchaseItems: PurchaseItem[];
	buyerOnlyQuestions: Question[];
}
export interface PurchaseItem {
	id: number;
	productDealId: number;
	name: string;
	price: number;
	seat: string;
	sendSms: boolean;
	sendTicketCopy: boolean;
	allowSendTicketCopy: boolean;
	answers: Answer[];
	questions: Question[];
	hasTicketEmailQuestion: boolean;
	discountAmount?: number;
	isPercentage?: boolean;
	discountCode: string;
	priceBeforeDiscount: number;
	isMerchandise: boolean;
	valid: boolean;
	checkedIn: boolean;
	nonUniqueError: boolean;
	isEditable: boolean;
	reasonNotEditable: string;
}

export interface Question {
	id: number;
	question: string;
	productDealLinks: number[];
	answer: string;
	type: number;
	controlSize: number;
	regularExpression: string;
	validationType?: number;
	buyerOnly: boolean;
	required: boolean;
	sortOrder: number;
	options: string;
	errorMessage: string;
	profileQuestionId?: number;
	awsBucketName: string;
	isUnique: boolean;
	uniqueAnswerError?: boolean;
	purchaseItemId: number;
	addOtherOption?: boolean;
	validatePreregQuestionId?: number;
	includeInWeb?: boolean;
	includeInPOS?: boolean;
	showUpdateRegWarning: boolean;
	isUpdated: boolean;
}

export interface Answer {
	id: number;
	questionId: number;
	question: string;
	answer: string;
	buyerOnly: boolean;
}

export interface RegistrationDetails {
	id: number;
	questions: Question[];
	status: number;
	statusMessage: string;
	eventUrl: string;
	errorMessage: string;
}


export enum QuestionType {
	SmallTextField = 1,
	LargeTextField = 2,
	RadioButtonList = 3,
	CheckBoxList = 4,
	DropDownList = 5,
	Waiver = 6,
	DateField = 7,
	SectionHeading = 8,
	Address = 9,
	Country = 10,
	FileUpload = 11,
	Cellphone = 12,
	TermsAndConditions = 6,
};

export enum ValidationType {
	Number = 1,
	Email = 2,
	Url = 3,
	Regex = 4,
	InDiscountCode = 5,
	FileDocument = 6,
	FileImage = 7,
	FileAnything = 8,
	Cellphone = 9,
};

export interface AdditionalInfo {
	email: string;
	isPublic: boolean;
	isValid: boolean;
}

export interface Locality {
	localityId: number;
	levelOne: string;
	levelTwo: string;
	levelThree: string;
	levelOneEncoded: string;
	levelTwoEncoded: string;
	levelThreeEncoded: string;
};

export interface UserSavedEvent {
	name: string;
	venueName: string;
	dateFrom: string;
	imageUrl: string;
	eventUrl: string;
};

