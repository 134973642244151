import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { StoreService } from '@app/services/store/store.service';
import { InternalURLCreator } from '@app/services/url/url.dictionary';
import { Go } from '@app/store/actions/router/router.actions';
import { GetTicketSettings } from '@app/store/actions/tickets/tickets.actions';
import { EVENT_ID_PARAM_KEY } from '@app/utils/consts';
import { take, takeUntil } from 'rxjs';

@Component({
	selector: 'app-event-settings-ticketing-description',
	templateUrl: './event-settings-ticketing-description.component.html',
	styleUrls: ['./event-settings-ticketing-description.component.sass'],
})
export class EventSettingsTicketingDescriptionComponent implements OnInit {
	readMore = false;
	readMoreText = 'Read More';
	isMobile = false;
	customDescription = `Customise the ticket and confirmation email that ticket buyers receive.
	Please note that the ticket stub information and
    image provided here will apply to all ticket types.`;
	destroyed$: EventEmitter<void> = new EventEmitter<void>();
	eventId: number;

	constructor(
		private breakpointService: BreakpointService,
		private store: StoreService,
		private activatedRoute: ActivatedRoute
	) {}

	ngOnInit() {
		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});

		this.activatedRoute.parent.paramMap.pipe(take(1)).subscribe((params) => {
			this.eventId = +params.get(EVENT_ID_PARAM_KEY);
			this.store.dispatch(new GetTicketSettings({ id: this.eventId }));
		});
	}
	handleReadMore() {
		this.readMore = !this.readMore;
		this.readMoreText = this.readMore ? 'Read Less' : 'Read More';
	}

	goToTicketsPage() {
		this.store.dispatch(new Go({ path: [InternalURLCreator.ticketTypes(this.eventId)] }));
	}

	ngOnDestroyed(): void {
		this.destroyed$.next();
	}
}
