<div class="d-flex l-container">
	<mat-icon
		id="mobile-navigate-back"
		class='ms-2 mt-3 overflow-visible position-absolute'
		[ngClass]="{'l-menu': isParent}"
		(click)='handleNavigationBack()'
	>
		{{isParent ? 'menu' : 'arrow_back'}}
	</mat-icon>
	<h3 class="mb-3 m-auto mt-3">{{pageName}}</h3>
</div>