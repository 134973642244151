<div
	class="l-container"
	[ngClass]="{ 'mobile': isMobile }"
>
	<div class="l-header" [class.l-header-mobile]="isMobile">
		<app-web-content-header
			class="mb-2"
			[headerContent]="headerContent"
			[longWidth]='true'
			[showBreadCrumb]="!isMobile"
			[showTitle]="!isMobile"
		>
		</app-web-content-header>
	</div>
	<app-iframe
		class="g-iframe"
		[url]='url'
	></app-iframe>
</div>