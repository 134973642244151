<div
	class="l-container"
	[ngClass]="{ 'mobile': isMobile }"
>
	<div class="l-title-container">
		<app-web-content-header
			*ngIf="!isMobile"
			class="mb-2"
			[headerContent]="headerContent"
			[longWidth]='true'
		>
		</app-web-content-header>

		<div class="l-actions-button">
			<app-dropdown-button
				[options]="options"
				[isLoading]="isLoading"
				buttonText="ACTIONS"
				(optionSelected)='handleAction($event)'
			></app-dropdown-button>
		</div>
	</div>

	<div
		class="l-content"
		*ngIf="!isLoading; else pageLoading"
	>
		<div class="l-header-container">
			<div
				class="l-header-content"
				[ngClass]="{ 'mobile': isMobile }"
			>
				<p> <strong>{{order.paymentState === paymentState.TRANSFER ? 'Transferred to:' : 'Ordered by'}}</strong>
					{{ order.email }} on {{ formatDate(order.dateAdded) }} </p>
				<p *ngIf="order.amount > 0 && this.isPaid">
					<strong>Payment Method:</strong> {{ order.paymentType }}
				</p>
				<p> <strong>Payment Collection By:</strong> {{ order.paymentCollectionBy }}
					<span *ngIf="order.isVended === true">
						(Vended)
					</span>
				</p>
				<p *ngIf="order.paymentDate"> <strong>Date Paid: </strong>{{ formatDate(order.paymentDate) }} </p>
			</div>
			<div class="l-order-info">
				<h3 class="g-h3 mb-0">{{order.amount | currency: order.currencySymbol}}</h3>
				<div
					class="l-badge"
					[ngClass]="{ 'l-valid': order.paid, 'l-invalid': !order.paid }"
				>
					{{order.paymentState | uppercase}}
				</div>
			</div>
		</div>

	<div
		class="l-ticket-list-container"
		[ngClass]="{ 'mobile': isMobile }"
	>
		<div class="l-table-container">

				<div
					*ngIf='isTableLoading'
					class='l-filtering'
				>
					<mat-spinner></mat-spinner>
				</div>

				<table
					mat-table
					[dataSource]="guests"
					class='l-table web'
					matSort
				>

					<ng-container matColumnDef="ticketId">
						<th
							id="ticket-number-table-header"
							mat-header-cell
							*matHeaderCellDef
							mat-sort-header
						>
							Ticket #
						</th>
						<td
							mat-cell
							*matCellDef="let guest"
						>
							{{ guest.ticketId }}
						</td>
					</ng-container>

					<ng-container matColumnDef="barcode">
						<th
							id="barcode-table-header"
							mat-header-cell
							*matHeaderCellDef
						>
							Barcode
						</th>
						<td
							mat-cell
							*matCellDef="let guest"
						>
							{{ guest.barcode }}
						</td>
					</ng-container>

					<ng-container matColumnDef="ticketHolder">
						<th
							id="ticket-holder-table-header"
							mat-header-cell
							*matHeaderCellDef
						>
							Ticket Holder
						</th>
						<td
							mat-cell
							*matCellDef="let guest"
						>
							{{ guest.ticketInformation.email || '-' }}
						</td>
					</ng-container>

					<ng-container matColumnDef="type">
						<th
							id="ticket-type-table-header"
							mat-header-cell
							*matHeaderCellDef
						>
							Type
						</th>
						<td
							mat-cell
							*matCellDef="let guest"
							class="l-ticket-type-cell"
						>
							{{ guest.ticketType }}
						</td>
					</ng-container>

					<ng-container matColumnDef="amount">
						<th
							id="amount-table-header"
							mat-header-cell
							*matHeaderCellDef
						>
							Amount
						</th>
						<td
							mat-cell
							*matCellDef="let guest"
						>
							{{order.currencySymbol}}{{ guest.ticketInformation.pricePaid }}
						</td>
					</ng-container>

					<ng-container matColumnDef="status">
						<th
							id="status-table-header"
							mat-header-cell
							*matHeaderCellDef
							id="status"
						>
							Status
						</th>
						<td
							mat-cell
							*matCellDef="let guest"
						>
							<div
								class="l-badge"
								[ngClass]="{ 'l-valid': guest.valid, 'l-invalid': !guest.valid }"
							>
								{{ guest.valid ? 'Valid' : 'Invalid' }}
							</div>
						</td>
					</ng-container>
					<ng-container matColumnDef="download">
						<th
							id="download-table-header"
							mat-header-cell
							*matHeaderCellDef
							id="download"
						>
						</th>
						<td
							mat-cell
							*matCellDef="let guest"
						>
							<mat-icon
								*ngIf="guest.valid"
								fontIcon="file_download"
								class="l-download-icon"
								(click)="handleTicketDownload(guest.ticketId)"
							></mat-icon>
						</td>
					</ng-container>

					<tr
						mat-header-row
						*matHeaderRowDef="displayColumns"
					></tr>
					<tr
						mat-row
						*matRowDef="let row; columns: displayColumns;"
					></tr>
					<tr *matNoDataRow>
						<td colSpan="6">
							<div class="l-empty-state">
								<h3 class="mb-3">Nothing to see here...</h3>
								<div class="mb-3">Nothing matches your search or filters</div>
							</div>
						</td>
					</tr>

				</table>

			</div>

			<mat-paginator
				#productsPaginator
				[pageSizeOptions]="pageSizeOptions"
			></mat-paginator>

		</div>
	</div>
	<ng-template #pageLoading>
		<div class="l-page-loader">
			<mat-spinner></mat-spinner>
		</div>
	</ng-template>
</div>