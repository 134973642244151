import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-completion-progress-item',
	templateUrl: './completion-progress-item.component.html',
	styleUrls: ['./completion-progress-item.component.sass'],
})
export class CompletionProgressItemComponent {
	@Input() text = '';
	@Input() buttonText = '';
	@Input() completed = false;
	@Input() link = '';
	@Input() id: string;
	@Input() displaySkip: boolean;

	@Output() actionClick = new EventEmitter();

	icon: string;

	ngOnInit() {
		this.icon = this.completed ? 'check_circle' : 'circle';
	}

	clickAction() {
		this.actionClick.emit(this.id);
	}
}
