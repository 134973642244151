<div class="l-container">
    <app-whatsapp-sharing-btn
        [link]="eventLink"
        [eventName]="eventName"
    >
    </app-whatsapp-sharing-btn>
    <app-mail-sharing-btn
        [link]="eventLink"
        [title]="eventName"
    >
    </app-mail-sharing-btn>
    <app-linkedin-sharing-btn
        [link]="eventLink"
    >
    </app-linkedin-sharing-btn>
    <app-pinterest-sharing-btn
        [link]="eventLink"
    >
    </app-pinterest-sharing-btn>
    <app-twitter-sharing-btn
        [link]="eventLink"
        [title]="eventName"
    >
    </app-twitter-sharing-btn>
    <app-facebook-sharing-btn 
        [link]="eventLink"
    >
    </app-facebook-sharing-btn>
</div>
