import * as ticketActions from '@app/store/actions/tickets/tickets.actions';
import { TicketsActionsConstant } from '@app/store/actions/tickets/tickets.constants';
import { initialState } from './initialState';
import { EventsState } from '@app/models/store.model';

export const currencies = (
	state = initialState.currencies,
	action: ticketActions.Actions
): EventsState['currencies'] => {
	switch (action.type) {
		case TicketsActionsConstant.GET_CURRENCIES_SUCCESS:
			return action.payload.currencies;
		default:
			return state;
	}
};
