<div class="l-nav-menu">
	<div class='l-event-menu'>
		<mat-icon id="mobile-navigate-sub-menu" class='ms-2 g-pointer'>menu</mat-icon>
		<div>{{currentEventNav}}</div>
	</div>
	<div [id]="formatId(item.key, 'mobile-event-nav')" *ngFor='let item of navItems | keyvalue: handleDefaultOrder' (click)="handleNavigation(item.value)"
		class='l-nav-item'>
		<div class="l-icon-container" [class.l-active-route]="currentEventNav === item.key">
			<img class="l-icon"
				[src]="currentEventNav === item.key ? item.value?.iconPath + '-active.svg' : item.value?.iconPath + '.svg'" />
		</div>
		<p class='l-title'>{{item.key}}</p>
	</div>
</div>
