<app-my-account-header
	[isMobile]='isMobile'
	pageName="Promoter Networks"
	(navigateBack)="handleNavigationBack()"
>
</app-my-account-header>
<div class="g-account-container">
	<div
		class="g-account-content"
		[ngClass]="{ 'mobile': isMobile, 'web': !isMobile }"
	>
		<app-web-content-header
			class="w-100"
			[headerContent]="headerContent"
			[longWidth]="true"
			[isMobile]="isMobile"
		></app-web-content-header>

		<div *ngIf="!isNetworksLoading else networksLoading">
			<div
				class="l-card-container my-3"
			>
				<ng-container *ngIf="promoterNetworks.length > 0; else noNetworks">
					<a
						*ngFor="let network of promoterNetworks"
						class="l-card g-flat-link"
						[routerLink]="[handleRouterLink(network.id)]"
					>
						<app-ticket-card
							[productStatus]="network.productStatus"
							[productName]="network.productName"
							[lineOne]="createCommissionText(network)"
							[lineTwo]="createLinkUrl(network)"
							[eventImage]="network.eventImage"
							type="promoter-network"
							[isMobile]="isMobile"
							(linkClick)='handleLinkClick(createLinkUrl(network))'
							class="l-card-content"
						></app-ticket-card>
					</a>
				</ng-container>
			</div>
		</div>
		<ng-template #networksLoading>
			<div class="l-loading">
				<mat-spinner></mat-spinner>
			</div>
		</ng-template>
		<ng-template #noNetworks>
			<div
				[ngClass]="{ 'l-empty-card-container': !isMobile, 'mt-4 d-flex flex-column align-items-center': isMobile }">
				<img
					class="d-flex l-image"
					src="assets/placeholders/promoter-network-empty.png"
				/>
				<h3 class="d-flex justify-content-center">Become A Promoter</h3>
				<p [ngClass]="{ 'l-empty-card-text-mobile': isMobile, 'l-empty-card-text': !isMobile }">
					You can join promoter networks by invitation from an organiser or by browsing public promoter
					networks.
				</p>
			</div>
		</ng-template>
	</div>
</div>