import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { EventSharedModule } from '@app/features/event-shared/event-shared.module';
import { EventCollectionsComponent } from './event-collections.component';
import { EffectsModule } from '@ngrx/effects';
import { CollectionsEffects } from './event-collections.effects';
import { CollectionsService } from './event-collections.service';
import { ImageService } from '@app/services/image/image.service';


@NgModule({
	imports: [
		SharedModule,
		EventSharedModule,
		EffectsModule.forFeature([CollectionsEffects]),
	],
	declarations: [
		EventCollectionsComponent,
	],
	exports: [
		EventCollectionsComponent,
	],
	providers: [
		CollectionsService,
		ImageService,
	],
}) export class EventCollectionsModule {}
