import { EventEmitter, Injectable } from '@angular/core';
import { FormService } from '@app/services/form/form.service';
import { FormValidators, FormSchemeControls } from '@app/models/common.model';
import { requiredValidator } from '@app/shared/form-field/form-validators';
import { FormArray, FormControl, UntypedFormGroup } from '@angular/forms';
import { Subscription, of } from 'rxjs';
import { NormalizedScheme } from '@app/models/dataSchema.model';
import {
	DraftEventCollectInfo,
	DraftEventCollectInfoForm,
	DraftEventCollectionBase,
	EventCollectInfoBase,
	EventCollectInfoTicket,
	EventCollectInfoType
} from '@app/models/event.model';
import { takeUntil, withLatestFrom } from 'rxjs/operators';
import * as deepEqual from 'fast-deep-equal';

@Injectable({
	providedIn: 'root',
})

export class CollectInfoService {
	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private formService: FormService
	) {}

	createCollectInfoForm(initialValues?: Partial<DraftEventCollectInfo>, isRegistration?: boolean) {
		const formValues = new DraftEventCollectInfoForm();
		formValues.isRegistrationQuestion = isRegistration;

		const validatorsConfig:
		FormValidators<Partial<DraftEventCollectInfoForm>> = {
			question: [requiredValidator()],
			type: [requiredValidator()],
		};

		const formConfig: Partial<FormSchemeControls<DraftEventCollectInfoForm>> =
		this.formService.createFormControls({
			formValues,
			initialValues,
			validatorsConfig,
		});

		formConfig.choiceOptions = this.formService.formBuilder.array(formConfig.choiceOptions.value);

		return this.formService.formBuilder.group(formConfig);
	}

	setFormValueChangeHandler = ({
		form,
		profileCollectInfo,
		collectInfoTickets,
		isRegistration,
	}: {
		form: UntypedFormGroup;
		profileCollectInfo: NormalizedScheme<EventCollectInfoBase>;
		collectInfoTickets: EventCollectInfoTicket[];
		isRegistration: boolean;
	}): Subscription => {
		let valueChangeSub: Subscription;

		const ticketIds = isRegistration ? [] : collectInfoTickets.map(ticket => ticket.id);

		valueChangeSub = form// eslint-disable-line prefer-const
			.get('profileQuestionId')
			.valueChanges
			.pipe(
				withLatestFrom(
					of(profileCollectInfo),
					of(ticketIds)
				),
				takeUntil(this.destroyed$)
			)
			.subscribe(([id, innerProfileCollectInfo, tickets]) => {
				let formToSet: Partial<DraftEventCollectInfoForm>;
				if (id) {
					if (innerProfileCollectInfo.byId[id].choiceOptions &&
						innerProfileCollectInfo.byId[id].choiceOptions.length > 0) {
						this.addChoiceOptionsToForm(form, innerProfileCollectInfo.byId[id].choiceOptions);
					}
					formToSet = {
						...innerProfileCollectInfo.byId[id],
						id: null,
						selectedTickets: tickets,
					};
				} else {
					formToSet = {
						...new DraftEventCollectionBase,
						selectedTickets: [],
					};
				}

				form.patchValue(formToSet);
			});

		valueChangeSub.add(
			form
				.get('showOnInvoice')
				.valueChanges
				.pipe(
					takeUntil(this.destroyed$)
				)
				.subscribe(v => {
					if (v) {
						form.get('applyToBuyer').disable();
						form.get('applyToBuyer').setValue(v);
					} else {
						form.get('applyToBuyer').enable();
					}
				})
		);


		type FormKeys = keyof DraftEventCollectInfoForm;
		type FormChangeCond = ((type: EventCollectInfoType) => boolean) | boolean;

		valueChangeSub.add(
			form
				.get('type')
				.valueChanges
				.pipe(
					takeUntil(this.destroyed$)
				)
				.subscribe(type => {
					const keysToReset = new Map<FormKeys, FormChangeCond>([
						['textboxRegEx', true],
						['textboxRegExMessage', true],
						['textboxWidth', true],
						['textboxValidation', true],
						['termsAndCond', true],
						['fileValidation', true],
						['minAge', true],
						['maxAge', true],
						// type specific
						['showOnInvoice', t => t === EventCollectInfoType.SectionHeading || t === EventCollectInfoType.FileUpload],
						['showOnTicket', t => t === EventCollectInfoType.SectionHeading || t === EventCollectInfoType.FileUpload],
						['isUnique', t => t !== EventCollectInfoType.SmallTextField],
						['isOtherOption', t => t !== EventCollectInfoType.CheckBoxList && t !== EventCollectInfoType.RadioButtonList],
						['choiceOptions', t => t !== EventCollectInfoType.CheckBoxList &&
								t !== EventCollectInfoType.DropDownList &&
								t !== EventCollectInfoType.RadioButtonList,
						],
						['showOnCheckin', t => (
							t === EventCollectInfoType.SectionHeading
								|| t === EventCollectInfoType.Waiver
								|| t === EventCollectInfoType.FileUpload
								|| t === EventCollectInfoType.CheckBoxList
								|| t === EventCollectInfoType.DropDownList
								|| t === EventCollectInfoType.RadioButtonList
								|| t === EventCollectInfoType.Country
						)],
						['includeOnTransferredTickets', t => t === EventCollectInfoType.SectionHeading],
					]);

					const defaultFormValues = new DraftEventCollectInfoForm();

					let valuesToSet: Partial<DraftEventCollectInfoForm> = {};

					Array.from(keysToReset).forEach(([k, cond]) => {
						const isAssignable = typeof cond === 'function' ? cond(type) : cond;

						if (isAssignable && !deepEqual(form.get(k).value, defaultFormValues[k])) {
							valuesToSet = {
								...valuesToSet,
								[k]: defaultFormValues[k],
							};
						}
					});

					if (Object.keys(valuesToSet).length) {
						form.patchValue(valuesToSet);
					}
				})
		);

		return valueChangeSub;
	};

	addChoiceOptionsToForm(form: UntypedFormGroup, options: string[]) {
		const choiceOptionsFormArray = form.get('choiceOptions') as FormArray;

		options.forEach(option => {
			const control = new FormControl(option);
			choiceOptionsFormArray.push(control);
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
