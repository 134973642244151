<div [ngClass]="getFormFieldClasses(offset, 'editor-wrap')">
	<label class="ui-label g-form-label" *ngIf="label">
		{{label}}
		<span *ngIf="required" class="ui-required" >*</span>
		<i *ngIf="labelIcon" [class]="'fa fa-' + labelIcon"  [pTooltip]="tooltip" [tooltipPosition]="tooltip ? tooltipPosition : null"></i>
	</label>
	<p-editor
		[formControl]="control"
		[style]="editorStyle"
		[imageUploadHandler]="imageUploadHandler"
		id="text-editor"
	>
		<p-header>
			<span class="ql-formats">
			<select class="ql-size" id="text-size">
				<option value="small" id="small-text-size">Small</option>
				<option selected id="selected-text-size"></option>
				<option value="large" id="large-text-size">Subtitle</option>
				<option value="huge" id="huge-text-size">Title</option>
			</select>
			</span>
			<span class="ql-formats">
				<button class="ql-bold" aria-label="Bold" id="bold-text"></button>
				<button class="ql-italic" aria-label="Italic" id="italic-text"></button>
				<button class="ql-underline" aria-label="Underline" id="underline-text"></button>
				<button class="ql-strike" aria-label="Strike" id="strike-text"></button>
			</span>
			<span class="ql-formats">
				<button class="ql-list" value="ordered" id="ordered-list"></button>
				<button class="ql-list" value="bullet" id="bullet-list"></button>
				<select title="Text Alignment" class="ql-align" id="text-alignment">
					<option selected id="align-left">Left</option>
					<option value="center" label="Center" id="align-center"></option>
					<option value="right" label="Right" id="align-right"></option>
					<option value="justify" label="Justify" id="align-justify"></option>
				</select>
			</span>
			<span class="ql-formats">
				<button aria-label="Link" class="ql-link" id="add-link"></button>
				<button aria-label="Image" class="ql-image" id="add-image"></button>
				<button aria-label="Video" class="ql-video" id="add-video"></button>
			</span>
		</p-header>
	</p-editor>
	<small
		class="g-error-input"
		*ngIf="control.invalid && (control.dirty || control.touched)"
	>
		*{{getError(control.errors)}}
	</small>
</div>
