<div class="l-container">
	<div class="l-form-container" [class.mobile]="isMobile">
		<form
			*ngIf="form"
			[formGroup]="form"
		>
			<div
				[ngClass]="{'g-tabbed-content input-group-mobile-large': isMobile, 'tabbed-content input-group-web-large pt-3 pb-2': !isMobile }">
				<app-mat-text-control-field
					[label]="'Tab Name'"
					[placeholder]="'Tab Name'"
					[control]="fromForm('name')"
					id="tab-name"
					[required]="true"
					labelClass="g-form-label"
				>
				</app-mat-text-control-field>
			</div>
			<div
				[ngClass]="{'g-tabbed-content input-group-mobile-large': isMobile, 'tabbed-content input-group-web-large pt-3 pb-2': !isMobile }">
				<app-mat-select-control-field
					label="Tab Type"
					placeholder="Tab Type"
					[control]="fromForm('type')"
					[options]="tabTypeOptions"
					[required]="true"
					id="tab-type"
				>
				</app-mat-select-control-field>
			</div>
			<div
				[ngClass]="{ 'input-group-mobile l-mobile-display pt-2': isMobile, 'tabbed-content input-group-web flex-grow-1': !isMobile }"
				[class.pb-3]="!getFormValue('type').value"
			>
				<div class="l-visibility">
					<label class="g-form-label">Tab Visibility</label>
					<div class="l-visibility-slider">
						<mat-slide-toggle
							class="standard-toggle"
							[formControl]="getFormValue('isVisible')"
							name="displayReviews"
							id="tab-visibility"
						></mat-slide-toggle>
					</div>
				</div>
			</div>
			<hr *ngIf="getFormValue('type').value" />
			<ng-container [ngSwitch]="getFormValue('type').value">
				<ng-container *ngSwitchCase="eventTabTypeEnum.Content">
					<app-tab-content-content
						[eventId]="eventId"
						[form]="form"
						[editorImageUploadHandler]="editorImageUploadHandler"
					>
					</app-tab-content-content>
				</ng-container>
				<ng-container *ngSwitchCase="eventTabTypeEnum.ImageGallery">
					<app-tab-content-image-gallery
						[eventId]="eventId"
						[form]="form"
						[dataSource]="dataSource"
						[isMobile]="isMobile"
						(deleteTab)="handleDeleteTabType($event)"
						[editorImageUploadHandler]="editorImageUploadHandler"
						[(isImageUploading)]="isImageUploading"
						(isLoadingChange)="handleLoading($event)"
					>
					</app-tab-content-image-gallery>
				</ng-container>
				<ng-container *ngSwitchCase="eventTabTypeEnum.Attributes">
					<div class="l-content">
						<ng-container *ngTemplateOutlet="contentInfoTemplate;
								context: { tabContentType : getTabContentType()}">
						</ng-container>
						<app-tab-content-attribute
							[eventId]="eventId"
							[form]="form"
							[dataSource]="dataSource"
							[isMobile]="isMobile"
							(deleteTab)="handleDeleteTabType($event)"
							[editorImageUploadHandler]="editorImageUploadHandler"
						>
						</app-tab-content-attribute>
					</div>
				</ng-container>
				<ng-container *ngSwitchCase="eventTabTypeEnum.News">
					<div class="l-content">
						<ng-container *ngTemplateOutlet="contentInfoTemplate;
								context: { tabContentType : getTabContentType()}">
						</ng-container>
						<app-tab-content-news
							[eventId]="eventId"
							[form]="form"
							[dataSource]="dataSource"
							[isMobile]="isMobile"
							(deleteTab)="handleDeleteTabType($event)"
							[editorImageUploadHandler]="editorImageUploadHandler"
						>
						</app-tab-content-news>
					</div>
				</ng-container>
				<ng-container *ngSwitchCase="eventTabTypeEnum.Schedule">
					<div class="l-content">
						<ng-container *ngTemplateOutlet="contentInfoTemplate;
								context: { tabContentType : getTabContentType()}">
						</ng-container>
						<app-tab-content-schedules
							[eventId]="eventId"
							[form]="form"
							[dataSource]="dataSource"
							[isMobile]="isMobile"
							(deleteTab)="handleDeleteTabType($event)"
							[editorImageUploadHandler]="editorImageUploadHandler"
							[(isImageUploading)]="isImageUploading"
							(isLoadingChange)="handleLoading($event)"
						>
						</app-tab-content-schedules>
					</div>
				</ng-container>
				<ng-container *ngSwitchCase="eventTabTypeEnum.MixedContent">
					<div class="l-content">
						<ng-container *ngTemplateOutlet="contentInfoTemplate;
								context: { tabContentType : getTabContentType()}">
						</ng-container>
						<app-tab-content-mixed-content
							[eventId]="eventId"
							[form]="form"
							[dataSource]="dataSource"
							[isMobile]="isMobile"
							(deleteTab)="handleDeleteTabType($event)"
							[editorImageUploadHandler]="editorImageUploadHandler"
							[(isImageUploading)]="isImageUploading"
							(isLoadingChange)="handleLoading($event)"
						>
						</app-tab-content-mixed-content>
					</div>
				</ng-container>
			</ng-container>
		</form>
		<ng-template
			#contentInfoTemplate
			let-tabContentType="tabContentType"
		>
			<div class="d-flex flex-col w-100 pt-3">
				<div class="g-info-block l-info-block py-3 pr-1">
					<i class="fa-regular fa-lightbulb g-info-block-icon"></i>
					<p class="g-info-block-msg mb-0">You can add multiple {{tabContentType.toLowerCase()}} items to appear under the
						same tab.</p>
				</div>
			</div>
	
			<div
				class="pb-2 pt-1"
				[ngClass]="{'l-button-container': !isMobile, 'l-mobile-add-button': isMobile}"
			>
			<button
				class='l-add-button g-primary-button'
				(click)="addContent(tabbedContentService.getTabContentControls(form), getFormTabType('type'))"
				[label]="'Add' + ' ' + getFormTabType('type')"
			>{{ 'Add ' +
				(getFormTabType('type') | titlecase) }}</button>
		</div>
		</ng-template>
	</div>
	<div>
		<app-footer-button
			[isMobile]="isMobile"
			[secondaryText]="'CANCEL'"
			[primaryText]="'SAVE CHANGES'"
			[isDisabled]="!isSubmitAllowed(form)"
			($primaryButton)="onTabContentFormClose(form.getRawValue())"
			($secondaryButton)="handleCancel()"
		>
		</app-footer-button>
	</div>
</div>