import { FormCanDeactivate } from '@app/services/guards/event.guard';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { StoreService } from '@app/services/store/store.service';
import { Observable, take, takeUntil } from 'rxjs';
import * as organiserSelectors from '@app/store/selectors/organiserProfile.selector';
import { ActivatedRoute } from '@angular/router';
import { GetUserOrganiserProfiles, UpdateUserOrganiserProfile } from '@app/store/actions/organiserProfiles/organiserProfiles.actions';
import { EventOrganiserProfile } from '@app/models/profile.model';
import { Go } from '@app/store/actions/router/router.actions';
import { InternalURLCreator, URLCreator } from '@app/services/url/url.dictionary';
import { Actions, ofType } from '@ngrx/effects';
import { OrganiserProfileActionConstants } from '@app/store/actions/organiserProfiles/organiserProfiles.constants';
import { OrganiserProfileservice } from '@app/services/organiser-profile/organiser-profile.service';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '@app/shared/modals/confirmation-modal/confirmation-modal.component';

const ORGANISER_ID_PARAM_KEY = 'id';

@Component({
	selector: 'app-organiser-details',
	templateUrl: './organiser-details.component.html',
	styleUrls: ['./organiser-details.component.sass'],
})
export class OrganiserDetailsComponent extends FormCanDeactivate implements OnInit {
	isMobile = false;
	isProfileLoading = true;
	organiserId: number;
	organiserProfile: EventOrganiserProfile;
	hasDispatchedOrganiser = false;
	isUpdatingDetails = false;
	form: UntypedFormGroup;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private breakpointService: BreakpointService,
		private store: StoreService,
		private activatedRoute: ActivatedRoute,
		private actions$: Actions,
		private organiserProfileService: OrganiserProfileservice,
		private dialog: MatDialog
	) {
		super();
	}

	ngOnInit(): void {
		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});

		this.activatedRoute.parent.params.pipe(take(1)).subscribe((params) => {
			if (!params) {
				this.handleNavigateBack();
			}
			this.organiserId = params[ORGANISER_ID_PARAM_KEY];
			this.organiserProfileDetails();
		});
	}

	organiserProfileDetails() {
		this.store.select(organiserSelectors.organiser(this.organiserId)).pipe(
			takeUntil(this.destroyed$)).subscribe((organiser) => {
			if (!organiser && !this.hasDispatchedOrganiser) {
				this.hasDispatchedOrganiser = true;
				this.store.dispatch(new GetUserOrganiserProfiles());
			} else {
				this.organiserProfile = { ...organiser };
				this.form = this.organiserProfileService.createEventProfileForm(this.organiserProfile);
				setTimeout(() => {
					this.isProfileLoading = false;
				}, 250);
			}
		});
	}

	handleNavigateBack() {
		this.store.dispatch(new Go({ path: [InternalURLCreator.orgProfile()] }));
	}

	handleHelpNavigate() {
		this.store.dispatch(new Go({ path: [URLCreator.helpCentre()] }));
	}

	handleOrganiserSave() {
		this.isUpdatingDetails = true;

		this.organiserProfile = this.form.getRawValue();

		this.actions$
			.pipe(
				ofType(
					OrganiserProfileActionConstants.UPDATE_ORGANISER_PROFILE_SUCCESS,
					OrganiserProfileActionConstants.UPDATE_ORGANISER_PROFILE_FAILED
				),
				takeUntil(this.destroyed$)
			)
			.subscribe(() => {
				this.isUpdatingDetails = false;
			});

		this.store.dispatch(
			new UpdateUserOrganiserProfile({
				profile: this.organiserProfile,
				isUpdating: true,
			})
		);
	}

	handleImageChange(){
		this.form.markAsTouched();
	}

	isFormDeactivatable(): boolean | Observable<boolean> {
		return !this.form || this.form.pristine;
	}

	deactivationFallback(): Observable<boolean> {
		this.dialog
			.open(ConfirmationModalComponent, {
				data: {
					title: 'Unsaved Changes',
					text: 'Are you sure you want to leave this page? Any unsaved changes will be discarded.',
					buttonText: 'LEAVE',
					centerText: true,
					isMobile: this.isMobile,
				},
				panelClass: 'g-standard-dialog',
			})
			.afterClosed()
			.subscribe((result) => {
				this.closeDeactivationModal(result);
			});
		return this.modalCallback.asObservable();
	}

	closeDeactivationModal(isConfirmed: boolean): void {
		this.modalCallback.next(isConfirmed);
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
		this.isProfileLoading = true;
	}
}
