import { NgModule } from '@angular/core';
import { UserMyTicketsComponent } from './my-tickets.component';
import { SharedModule } from '@app/shared/shared.module';
import { MyTicketsPanelComponent } from './my-tickets-panel/my-tickets-panel.component';
import { FundraiserInvoiceModalComponent } from './fundraiser-invoice-modal/fundraiser-invoice-modal.component';

@NgModule({
	imports: [
		SharedModule,
	],
	declarations: [
		UserMyTicketsComponent,
		MyTicketsPanelComponent,
		FundraiserInvoiceModalComponent,
	],
	exports: [
		UserMyTicketsComponent,
		MyTicketsPanelComponent,
		FundraiserInvoiceModalComponent,
	],
})

export class UserMyTicketsModule {}
