<div class="l-container">
	<div class="l-content pb-0" [class.pt-2]="isMobile" *ngIf="!isMobile">
		<app-web-content-header class="mb-2" [headerContent]="headerContent" [mediumWidth]='true'>
		</app-web-content-header>
	</div>
	<div *ngIf="!isLoading else definitionsLoading">
		<div class="l-content" [ngClass]="{ 'mobile': isMobile }">
			<p *ngIf="isMobile">Customize your event page by changing the default wording below.</p>
			<form *ngIf="form" [formGroup]="form" class="l-form-container" [ngClass]="{ 'mt-4': isMobile }"
				id="event-details-form">
				<div [ngClass]="{ 'input-group-mobile': isMobile, 'l-input flex-grow-1': !isMobile }">
					<label class="g-form-label">
						Call-to-action button text
						<span class="g-orange"> *</span>
						<mat-icon
							class="l-tooltip-icon"
							[matTooltip]="'The wording on the button that starts the purchase process on your event page.'"
							matTooltipPosition="right"
							#callToActionTooltip="matTooltip"
							(click)="callToActionTooltip.toggle()"
						>info</mat-icon>
					</label>
					<input id='buttonText' type="text" class="form-control" placeholder="Tickets"
						[formControl]="fromForm('buttonText')" />
					<div class="g-error-input"
						*ngIf="fromForm('buttonText').invalid && ((fromForm('buttonText').dirty || fromForm('buttonText').touched))">
						{{getError(fromForm('buttonText').errors)}}
					</div>
				</div>
				<div [ngClass]="{ 'input-group-mobile': isMobile, 'l-input flex-grow-1': !isMobile }">
					<label class="g-form-label pt-3">
						Event
						<span class="g-orange"> *</span>
						<mat-icon
							class="l-tooltip-icon"
							[matTooltip]="'Used in the descriptions of certain ticket purchase steps, e.g. the Schedule step for scheduled events.'"
							matTooltipPosition="right"
							#eventTooltip="matTooltip"
							(click)="eventTooltip.toggle()"
						>info</mat-icon>
					</label>
					<input id='event' type="text" class="form-control" placeholder="Event"
						[formControl]="fromForm('event')" />
					<div class="g-error-input"
						*ngIf="fromForm('event').invalid && ((fromForm('event').dirty || fromForm('event').touched))">
						{{getError(fromForm('event').errors)}}
					</div>
				</div>
				<div [ngClass]="{ 'input-group-mobile': isMobile, 'l-input flex-grow-1': !isMobile }">
					<label class="g-form-label pt-3">
						Events
						<span class="g-orange"> *</span>
						<mat-icon
							class="l-tooltip-icon"
							[matTooltip]="'Used in the descriptions of certain ticket purchase steps.'"
							matTooltipPosition="right"
							#eventsTooltip="matTooltip"
							(click)="eventsTooltip.toggle()"
						>info</mat-icon>
					</label>
					<input id='events' type="text" name="name" class="form-control" placeholder="Events"
						[formControl]="fromForm('events')" />
					<div class="g-error-input"
						*ngIf="fromForm('events').invalid && ((fromForm('events').dirty || fromForm('events').touched))">
						{{getError(fromForm('events').errors)}}
					</div>
				</div>
				<div [ngClass]="{ 'input-group-mobile': isMobile, 'l-input flex-grow-1': !isMobile }">
					<label class="g-form-label pt-3">
						Ticket
						<span class="g-orange"> *</span>
						<mat-icon
							class="l-tooltip-icon"
							[matTooltip]="'Used when referring to a ticket in the descriptions of certain purchase steps e.g. the Tickets and Details steps.'"
							matTooltipPosition="right"
							#ticketTooltip="matTooltip"
							(click)="ticketTooltip.toggle()"
						>info</mat-icon>
					</label>
					<input id='ticket' type="text" class="form-control" placeholder="Ticket"
						[formControl]="fromForm('ticket')" />
					<div class="g-error-input"
						*ngIf="fromForm('ticket').invalid && ((fromForm('ticket').dirty || fromForm('ticket').touched))">
						{{getError(fromForm('ticket').errors)}}
					</div>
				</div>
				<div [ngClass]="{ 'input-group-mobile': isMobile, 'l-input flex-grow-1': !isMobile }">
					<label class="g-form-label pt-3">
						Tickets
						<span class="g-orange"> *</span>
						<mat-icon
							class="l-tooltip-icon"
							[matTooltip]="'Used when referring to tickets in the descriptions of certain purchase steps e.g. the Details step.'"
							matTooltipPosition="right"
							#ticketsTooltip="matTooltip"
							(click)="ticketsTooltip.toggle()"
						>info</mat-icon>
					</label>
					<input id='tickets' type="text" class="form-control" placeholder="Tickets"
						[formControl]="fromForm('tickets')" />
					<div class="g-error-input"
						*ngIf="fromForm('tickets').invalid && ((fromForm('tickets').dirty || fromForm('tickets').touched))">
						{{getError(fromForm('tickets').errors)}}
					</div>
				</div>
				<!-- <div [ngClass]="{ 'input-group-mobile': isMobile, 'l-input flex-grow-1': !isMobile }">
					<label class="g-form-label pt-3">
						You need to register to buy tickets for this event
						<span class="g-orange"> *</span>
						<mat-icon
							class="l-tooltip-icon"
							[matTooltip]="'The wording of the title of the Registration purchase step for events with registrations enabled.'"
							matTooltipPosition="right"
							#registerTooltip="matTooltip"
							(click)="registerTooltip.toggle()"
						>info</mat-icon>
					</label>
					<input id='register' type="text" class="form-control"
						placeholder="You need to register to buy tickets for this event"
						[formControl]="fromForm('register')" />
					<div class="g-error-input"
						*ngIf="fromForm('register').invalid && ((fromForm('register').dirty || fromForm('register').touched))">
						{{getError(fromForm('register').errors)}}
					</div>
				</div>
				<div [ngClass]="{ 'input-group-mobile': isMobile, 'l-input flex-grow-1': !isMobile }">
					<label class="g-form-label pt-3">
						If you have a promotion code enter it here
						<span class="g-orange">*</span>
						<mat-icon
							class="l-tooltip-icon"
							[matTooltip]="'Call-to-action text for entering promotional codes in the purchase process for events that offer promotional codes.'"
							matTooltipPosition="right"
							#enterPromoTooltip="matTooltip"
							(click)="enterPromoTooltip.toggle()"
						>info</mat-icon>
					</label>
					<input id='register' type="text" class="form-control"
						placeholder="If you have a promotion code enter it here"
						[formControl]="fromForm('enterPromoCode')" />
					<div class="g-error-input"
						*ngIf="fromForm('enterPromoCode').invalid && ((fromForm('enterPromoCode').dirty || fromForm('enterPromoCode').touched))">
						{{getError(fromForm('enterPromoCode').errors)}}
					</div>
				</div>
				<div [ngClass]="{ 'input-group-mobile': isMobile, 'l-input flex-grow-1': !isMobile }">
					<label class="g-form-label pt-3">
						Use a promotion code
						<span class="g-orange"> *</span>
						<mat-icon
							class="l-tooltip-icon"
							[matTooltip]="'Call-to-action text for entering promotional codes in the purchase process for events that offer promotional codes.'"
							matTooltipPosition="right"
							#usePromoTooltip="matTooltip"
							(click)="usePromoTooltip.toggle()"
						>info</mat-icon>
					</label>
					<input id='register' type="text" class="form-control"
						placeholder="Use a promotion code"
						[formControl]="fromForm('usePromoCode')" />
					<div class="g-error-input"
						*ngIf="fromForm('usePromoCode').invalid && ((fromForm('usePromoCode').dirty || fromForm('usePromoCode').touched))">
						{{getError(fromForm('usePromoCode').errors)}}
					</div>
				</div>
				<div [ngClass]="{ 'input-group-mobile': isMobile, 'l-input flex-grow-1': !isMobile }">
					<label class="g-form-label pt-3">
						Choose your seats
						<span class="g-orange"> *</span>
						<mat-icon
							class="l-tooltip-icon"
							[matTooltip]="'The wording of the title of the Seats purchase step for seated events.'"
							matTooltipPosition="right"
							#seatTooltip="matTooltip"
							(click)="seatTooltip.toggle()"
						>info</mat-icon>
					</label>
					<input id='register' type="text" class="form-control" placeholder="Choose your seats"
						[formControl]="fromForm('chooseYourSeats')" />
					<div class="g-error-input"
						*ngIf="fromForm('chooseYourSeats').invalid && ((fromForm('chooseYourSeats').dirty || fromForm('chooseYourSeats').touched))">
						{{getError(fromForm('chooseYourSeats').errors)}}
					</div>
				</div>
				<div [ngClass]="{ 'input-group-mobile': isMobile, 'l-input flex-grow-1': !isMobile }">
					<label class="g-form-label pt-3">
						Select your merchandise
						<span class="g-orange"> *</span>
						<mat-icon
							class="l-tooltip-icon"
							[matTooltip]="'The wording of the title of the Merchandise purchase step for events that offer merchandise.'"
							matTooltipPosition="right"
							#merchTooltip="matTooltip"
							(click)="merchTooltip.toggle()"
						>info</mat-icon>
					</label>
					<input id='register' type="text" class="form-control" placeholder="Select your merchandise"
						[formControl]="fromForm('selectYourMerchandise')" />
					<div class="g-error-input"
						*ngIf="fromForm('selectYourMerchandise').invalid && ((fromForm('selectYourMerchandise').dirty || fromForm('selectYourMerchandise').touched))">
						{{getError(fromForm('selectYourMerchandise').errors)}}
					</div>
				</div> -->
				<div [ngClass]="{ 'input-group-mobile': isMobile, 'w-100': !isMobile }">
					<label class="g-form-label pt-3">How to book tickets (desktop) <mat-icon class="l-tooltip-icon"
							[matTooltip]="desktopToolTip" matTooltipPosition="right" 
							#webDefinitionsTooltip="matTooltip" (click)="webDefinitionsTooltip.toggle()">info</mat-icon></label>
					<textarea id="book-tickets-desktop" [formControl]="fromForm('howToBookDesktop')"
						class="form-control l-text-area"></textarea>
				</div>
				<div [ngClass]="{ 'input-group-mobile': isMobile, 'w-100': !isMobile }">
					<label class="g-form-label pt-3">How to book tickets (mobile)<mat-icon class="l-tooltip-icon"
							[matTooltip]="mobileToolTip" matTooltipPosition="right"
							#mobileDefinitionsTooltip="matTooltip" (click)="mobileDefinitionsTooltip.toggle()">info</mat-icon></label>
					<textarea id="book-tickets-mobile" [formControl]="fromForm('howToBookMobile')"
						class="form-control l-text-area"></textarea>
				</div>
			</form>
		</div>
		<div class="l-sticky-bar">
			<app-footer-button [isMobile]="isMobile" [primaryText]="'SAVE'" [isLoading]="isSavingChanges"
				[isDisabled]="!isSubmitAllowed(form)" ($primaryButton)="saveDefinitions(form.getRawValue())">
			</app-footer-button>
		</div>
	</div>
	<ng-template #definitionsLoading>
		<div class="l-loading">
			<mat-spinner></mat-spinner>
		</div>
	</ng-template>
</div>