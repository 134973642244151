import { Component, EventEmitter, OnInit } from '@angular/core';
import { EventOrganiserProfile } from '@app/models/profile.model';
import { SectionRouteCard } from '@app/models/shared';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { StoreService } from '@app/services/store/store.service';
import { InternalURLCreator, URLCreator } from '@app/services/url/url.dictionary';
import { DeleteUserOrganiserProfile, GetUserOrganiserProfiles } from '@app/store/actions/organiserProfiles/organiserProfiles.actions';
import { Go } from '@app/store/actions/router/router.actions';
import { handleOrder } from '@app/utils/common-helpers';
import { Dictionary } from '@ngrx/entity';
import { takeUntil } from 'rxjs';
import * as organiserSelectors from '@app/store/selectors/organiserProfile.selector';
import { Actions, ofType } from '@ngrx/effects';
import { OrganiserProfileActionConstants } from '@app/store/actions/organiserProfiles/organiserProfiles.constants';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '@app/shared/modals/confirmation-modal/confirmation-modal.component';
import { LinkedProductsModalComponent } from '../../modals/linked-products-modal/linked-products-modal.component';

enum IntegrationKey {
	MailAccounts = 'mailAccounts',
	TrackingScripts = 'trackingScripts',
	DirectDistribution = 'directDistribution',
}

@Component({
	selector: 'app-organiser-profile',
	templateUrl: './organiser-profile.component.html',
	styleUrls: ['./organiser-profile.component.sass'],
})
export class OrganiserProfileComponent implements OnInit {
	isMobile = false;
	isProfilesLoading = true;
	handleDefaultOrder = handleOrder;

	profiles: EventOrganiserProfile[] = [];
	displayList: EventOrganiserProfile[] = [];
	profilesToShow = 2;
	moreProfilesToShow = false;

	integrations: Dictionary<SectionRouteCard> = {
		[IntegrationKey.MailAccounts]: {
			title: 'Mail Accounts',
			iconPath: './assets/icons/mandrill-logo.png',
			description: 'Connect your own mail sending account.',
			routeTo: () => InternalURLCreator.orgMailAccounts(),
		},
		[IntegrationKey.TrackingScripts]: {
			title: 'Tracking Scripts',
			iconPath: './assets/icons/browse_activity.png',
			description: 'Connect Google Analytics and more.',
			routeTo: () => InternalURLCreator.orgTrackingScripts(),
		},
		[IntegrationKey.DirectDistribution]: {
			title: 'Direct Distribution',
			iconPath: './assets/icons/ticket.svg',
			description: 'Manage your directly distributed tickets.',
			routeTo: () => InternalURLCreator.orgDirectDistribution(),
		},
	};

	zapier: SectionRouteCard = {
		title: 'Zapier',
		iconPath: './assets/icons/zapier.png',
		description: 'Connect to hundreds of services.',
		routeTo: () => URLCreator.zapier(null),
	};

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private breakpointService: BreakpointService,
		private store: StoreService,
		private actions$: Actions,
		private dialog: MatDialog
	) {}

	ngOnInit(): void {
		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});

		this.actions$
			.pipe(
				ofType(
					OrganiserProfileActionConstants.GET_ORGANISER_PROFILES_SUCCESS,
					OrganiserProfileActionConstants.GET_ORGANISER_PROFILES_FAILED
				),
				takeUntil(this.destroyed$)
			)
			.subscribe(() => {
				this.isProfilesLoading = false;
			});

		this.store
			.select(organiserSelectors.organisers())
			.pipe(takeUntil(this.destroyed$))
			.subscribe((profiles) => {
				this.profiles = profiles;
				if (profiles.length > 0) {
					if (this.profiles.length >= 10) {
						this.displayList = this.profiles.slice(0, this.profilesToShow);
						this.moreProfilesToShow = true;
					} else {
						this.displayList = this.profiles;
						this.moreProfilesToShow = false;
					}
				}
			});
		this.store.dispatch(new GetUserOrganiserProfiles());
	}

	handleDeleteProfile(profile: EventOrganiserProfile) {
		if (profile.linkedProducts.length) {
			const panelClass = this.isMobile ? 'g-full-page-dialog' : 'g-standard-dialog-height-8';
			this.dialog.open(LinkedProductsModalComponent, {
				panelClass: panelClass,
				data: {
					isMobile: this.isMobile,
					linkedProducts: profile.linkedProducts,
				},
			});
		} else {
			const confirmationDialogRef = this.dialog.open(ConfirmationModalComponent, {
				data: {
					title: 'Are You Sure?',
					text: `Delete ${profile.name} organiser profile? This action cannot be undone.`,
					buttonText: 'DELETE',
					isMobile: this.isMobile,
				},
				panelClass: 'g-standard-dialog',
			});

			confirmationDialogRef.afterClosed().subscribe((result) => {
				if (result) {
					this.store.dispatch(new DeleteUserOrganiserProfile({ id: profile.id }));
				}
			});
		}
	}

	handleProfileNavigation(id: number) {
		this.store.dispatch(new Go({ path: [InternalURLCreator.orgProfileDetails(id)] }));
	}

	handleRouterLink(id: number): string {
		return InternalURLCreator.orgProfileDetails(id);
	}

	onLinkClick(event: MouseEvent): void {
		const target = event.target as HTMLElement;
		if (target.closest('.l-delete')) {
			event.preventDefault();
		}
	}

	handleIntegrationsRouterLink(integration: string): string {
		return this.integrations[integration].routeTo();
	}

	handleShowMore() {
		this.profilesToShow += 10;
		this.updateDisplay();
	}

	private updateDisplay() {
		if (this.profilesToShow >= this.profiles.length) {
			this.profilesToShow = this.profiles.length;
			this.displayList = this.profiles;
			this.moreProfilesToShow = false;
		} else {
			this.displayList = this.profiles.slice(0, this.profilesToShow);
		}
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
