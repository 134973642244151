import { Component, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IconType } from '@app/models/event.model';
import { HeaderContent, SectionRouteCard } from '@app/models/shared';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { StoreService } from '@app/services/store/store.service';
import { InternalURLCreator } from '@app/services/url/url.dictionary';
import { Go } from '@app/store/actions/router/router.actions';
import { handleOrder } from '@app/utils/common-helpers';
import { EVENT_ID_PARAM_KEY, EVENT_INTEGRATION_CARDS, IntegrationSection } from '@app/utils/consts';
import { Dictionary } from '@ngrx/entity';
import { takeUntil, take } from 'rxjs';

@Component({
	selector: 'app-integrations',
	templateUrl: './integrations.component.html',
	styleUrls: ['./integrations.component.sass'],
})
export class IntegrationsComponent {
	cardsData: Dictionary<SectionRouteCard> = EVENT_INTEGRATION_CARDS;
	isMobile = false;
	eventId: number;
	handleDefaultOrder = handleOrder;
	iconType = IconType.PNG;

	headerContent: HeaderContent = {
		breadCrumbs: [
			{
				routeName: 'Settings',
				routeTo: () => InternalURLCreator.settings(this.eventId),
			},
			{
				routeName: 'Integrations',
			},
		],
		title: 'Integrations',
		description: `This section allows you to connect your event with various third-party services to 
		enhance functionality and streamline operations.`,
	};

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private breakpointService: BreakpointService,
		private store: StoreService,
		private activatedRoute: ActivatedRoute
	) { }

	ngOnInit(): void {
		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});

		this.activatedRoute.parent.paramMap.pipe(take(1)).subscribe((params) => {
			this.eventId = +params.get(EVENT_ID_PARAM_KEY);
		});
	}

	handleNavigate(card: SectionRouteCard, key: string): void {
		if (key === IntegrationSection.ZAPIER) {
			window.open(card.routeTo(), '_blank');
		} else {
			this.store.dispatch(new Go({ path: [card.routeTo(this.eventId)] }));
		}
	}

	handleMiddleClick(card: SectionRouteCard, key: string): string {
		if (key === IntegrationSection.ZAPIER) {
			return card.routeTo();
		} else {
			return card.externalRouteTo(this.eventId);
		}
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}

}
