import { Component, EventEmitter } from '@angular/core';
import { HeaderContent } from '@app/models/shared';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { StoreService } from '@app/services/store/store.service';
import { InternalURLCreator } from '@app/services/url/url.dictionary';
import { Go } from '@app/store/actions/router/router.actions';
import { GetAllPromoterNetworks } from '@app/store/actions/user/user.actions';
import { UserActionsConstants } from '@app/store/actions/user/user.actions.constants';
import { EARN_COMMISSION_HEADER } from '@app/utils/consts';
import { Actions, ofType } from '@ngrx/effects';
import { take, takeUntil } from 'rxjs';
import * as userSelectors from '@app/store/selectors/user.selector';
import { PromoterNetwork } from '@app/models/user.model';

@Component({
	selector: 'app-promoter-networks',
	templateUrl: './promoter-networks.component.html',
	styleUrls: ['./promoter-networks.component.sass'],
})
export class PromoterNetworksComponent {
	isMobile = false;
	headerContent: HeaderContent = EARN_COMMISSION_HEADER['promoterNetworks'];

	isNetworksLoading = true;

	promoterNetworks: PromoterNetwork[] = [];
	link: string;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private breakpointService: BreakpointService,
		private store: StoreService,
		private actions$: Actions
	) { }

	ngOnInit(): void {
		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});

		this.store.select(userSelectors.promoterNetworks())
			.pipe(takeUntil(this.destroyed$))
			.subscribe((promoterNetworks) => {
				if (!promoterNetworks.length) {
					return;
				}
				this.promoterNetworks = promoterNetworks;
			});

		this.store.dispatch(new GetAllPromoterNetworks());

		this.actions$
			.pipe(
				ofType(UserActionsConstants.GET_ALL_PROMOTER_NETWORK_SUCCESS, UserActionsConstants.GET_ALL_PROMOTER_NETWORK_FAILED),
				take(1)
			)
			.subscribe(() => {
				this.isNetworksLoading = false;
			});
	}

	handleNavigationBack() {
		this.store.dispatch(new Go({ path: [InternalURLCreator.earnCommission()] }));
	}

	handleRouterLink(id: string): string {
		return InternalURLCreator.promoterDetails(id);
	}

	handleLinkClick(url: string) {
		window.open(url);
	}

	createCommissionText(network: PromoterNetwork): string {
		return 'Share this link to earn ' + network.commissionPercentage + '% of ticket sales:';
	}

	createLinkUrl(network: PromoterNetwork): string {
		return network.shortlinkUrl.concat(network.shortlinkCode);
	}

}
