import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { StorageKey } from '@app/models/storage.model';
import { ProductRoute } from '@app/models/url.model';

interface IntroStep {
	icon: string;
	title: string;
	desc: string;
}

@Component({
	selector: 'app-event-user-intro',
	templateUrl: './user-intro.component.html',
	styleUrls: ['./user-intro.component.sass'],
})
export class EventUserIntroComponent implements OnInit {
	constructor() {}

	@Input() isEventAlreadyCreated: boolean;
	@Output() closeUserIntro = new EventEmitter();
	activeIntroStep = 0;
	ProductRoute = ProductRoute;

	introSteps: IntroStep[] = [
		{
			icon: 'users',
			title: 'Step 1: Organiser Profile',
			desc: 'You\'ll create or select an organiser profile to group your events.',
		},
		{
			icon: 'edit',
			title: 'Step 2: Event Details',
			desc: 'Name and describe your event, select dates and a venue, and choose how your event will be listed.',
		},
		{
			icon: 'ticket-alt',
			title: 'Step 3: Ticket Types',
			desc: 'Create the tickets your guests will book, and add a seating chart if you need guests to reserve seats.',
		},
		{
			icon: 'flag-checkered',
			title: 'That\'s it! Go live when you\'re ready.',
			desc: 'You can edit all the details you provided, review event settings and much more from your event dashboard at any time.',
		},
	];

	ngOnInit() {
		localStorage.removeItem(StorageKey.EventCreationUserIntro);
		this.activeIntroStep = 0;
	}

	onNextIntroStep() {
		if (this.activeIntroStep !== 3) {
			this.activeIntroStep++;
		} else {
			localStorage.setItem(StorageKey.EventCreationUserIntro, '1');
			this.closeUserIntro.emit();
		}
	}

	skipButtonLabel(stepIndex: number): string {
		const { introSteps, isEventAlreadyCreated } = this;
		if (stepIndex === introSteps.length - 1) {
			if (isEventAlreadyCreated) {
				return 'Continue editing and don\'t show this again';
			} else if (!isEventAlreadyCreated) {
				return 'Create my event and don\'t show this again';
			}
		} else {
			return 'Skip intro';
		}
	}

	nextButtonLabel(stepIndex: number) {
		const { introSteps, isEventAlreadyCreated } = this;
		if (stepIndex === introSteps.length - 1) {
			if (isEventAlreadyCreated) {
				return 'CONTINUE EDITING';
			} else if (!isEventAlreadyCreated) {
				return 'CREATE MY EVENT';
			}
		} else {
			return 'NEXT';
		}
	}

	getIndicatorClasses(currentInd: number, indexInd: number) {
		return {
			'l-intro-step-indicator': true,
			'l-intro-step-indicator__active': indexInd === currentInd,
		};
	}

	skipIntro() {
		localStorage.setItem(StorageKey.EventCreationUserIntro, '1');
		this.closeUserIntro.emit();
	}

	setIntroStep(i: number) {
		if (i < this.activeIntroStep) {
			this.activeIntroStep = i;
		}
	}
}
