<app-header></app-header>
<div
	class='event-form'
	[loadingSp]="(isCreationFetching$ | async) && !eventCreationForm && isEventLoading"
>
	<div
		*ngIf="eventCreationForm && !isEventLoading"
		class='event-form-content l-container'
		#container
	>
		<mat-stepper
			class="l-stepper"
			[selectedIndex]="activeStepIndex"
			(selectionChange)="setStepIndex($event.selectedIndex)"
			(animationDone)="setStepsAsInteracted()"
			[orientation]="isMobile ? 'vertical' : 'horizontal'"
			linear
			#stepper
		>
			<mat-step [completed]="creationStep > 1" [editable]="(creationStep >= 1) && !ticketsRequestSuccess">
				<ng-template matStepLabel>ORGANISER PROFILE</ng-template>
				<app-edit-event-alert [isMobile]="isMobile"></app-edit-event-alert>
				<app-event-user-contact-info
					*ngIf="isUserInfoFormShown()"
					[form]="userContactInfoForm"
					(submit)="onUserInfoSubmit($event)"
					(skip)="skipUserInfo()"
				>
				</app-event-user-contact-info>
				<app-event-user-intro
					class="event-creation-intro"
					*ngIf="isUserIntro && !isUserInfoFormShown()"
					(closeUserIntro)="isUserIntro = false"
					[isEventAlreadyCreated]="recoveredInitialStep > activeStepIndex"
				>
				</app-event-user-intro>
				<app-event-organiser-profile-form
					class="l-organiser-form"
					*ngIf="!isUserIntro && !isUserInfoFormShown()"
					[form]="eventProfileForm"
					[profile]="selectedOrganiser$ | async"
					[organiserProfileOptions]="organiserProfileOptions"
					[includeHeaderWithName]="true"
					[isEventCreation]="isEventCreation"
					(createProfile)="createProfile()"
				>
				</app-event-organiser-profile-form>
			</mat-step>

			<mat-step [completed]="creationStep > 2" [editable]="(creationStep >= 2) && !ticketsRequestSuccess">
				<ng-template matStepLabel>EVENT DETAILS</ng-template>
				<app-edit-event-alert [isMobile]="isMobile"></app-edit-event-alert>
				<app-event-details-form
					class="l-form"
					[ngClass]="{ 'mobile': isMobile }"
					[form]="eventDetailsForm"
					[isMobile]="isMobile"
					[image]="image"
					[eventId]="eventDetailsForm.get('eventId').value"
					[hasSoldTickets]="false"
					[eventFormats]="(eventTypes$ | async).formats"
					[eventCategories]="(eventTypes$ | async).categories"
					[eventSubcategories]="(eventTypes$ | async).subcategories"
					[eventFeatureOptions]="eventFeatureOptions$ | async"
					[previousVenues]="previousVenues$ | async"
					[dynamicValidatorsKeys]="eventDetailsFormValidators"
					[isEventCreation]="true"
				>
				</app-event-details-form>
			</mat-step>

			<mat-step [completed]="ticketsRequestSuccess" [editable]="creationStep == 3">
				<ng-template matStepLabel>TICKET TYPES</ng-template>
				<app-edit-event-alert [isMobile]="isMobile"></app-edit-event-alert>
				<app-event-ticket-types-form
					class="l-form"
					[ngClass]="{ 'mobile': isMobile }"
					[form]="eventTicketsForm"
					[isMobile]='isMobile'
					[hasSoldTickets]="false"
					[details]="getTicketTypeFormEventDetails()"
					[eventId]="eventDetailsForm.get('eventId').value"
					[currencies]="currencies$ | async"
					[questions]="questions$ | async"
					[seatingCategories]="seatingCategories"
					[designerKey]="userDesignerKey$ | async"
					[userCharts]="userCharts$ | async"
					[showFooterButton]="false"
					[isEventCreation]="true"
					[eventEndDateTime]="eventDetailsForm.get('endDateTime').value"
				>
				</app-event-ticket-types-form>
			</mat-step>
			<mat-step [editable]="false">
				<ng-template matStepLabel>EVENT DASHBOARD</ng-template>
				<div class="l-success-container">
					<ng-lottie
						width='220px'
						height='220px'
						[options]="options"
						class='l-lottie'
						(animationCreated)="animationCreated($event)"
					></ng-lottie>
					<h3 class="l-title">Success! Your event has been created</h3>
					<p class="l-success-description">
						You can edit and add more event details under the <span class='fw-bold g-green'>Manage
						Event</span> & <span class='fw-bold g-green'>Manage Ticket</span> tabs in the
						event dashboard space.
					</p>
					<p class="l-event-activation-description">
						Your event is almost live! Continue to your event dashboard to activate your event.
					</p>
					<div class='l-button-container'>
						<button
							class='g-primary-button'
							(click)='handleEventRoute()'
							id="go-to-event"
						>GO TO EVENT DASHBOARD</button>
					</div>
				</div>
			</mat-step>
		</mat-stepper>
	</div>

	<div
		class="l-submit-container"
		*ngIf="!isUserIntro && eventCreationForm && !isUserInfoFormShown() && activeStepIndex !== 3"
	>
		<app-footer-button
			class="l-footer-button"
			[isMobile]='isMobile'
			[isDisabled]="isSubmitDisabled() || (isCreationFetching$ | async) || (isFetching$ | async)"
			[isLoading]="(isCreationFetching$ | async) || (isFetching$ | async)"
			[primaryText]="getSubmitLabel()"
			[longPrimaryText]="activeStepIndex == eventPartEnum.DETAILS"
			primaryButtonId="event-creation-button"
			($primaryButton)="proceedFormSubmit()"
		>
		</app-footer-button>
	</div>
</div>