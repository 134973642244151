<app-my-account-header
	[isMobile]='isMobile'
	pageName="Edit Ticket"
	(navigateBack)="handleNavigationBack()"
>
</app-my-account-header>
<div class="g-account-container">
	<div
		class="g-account-content h-100"
		[ngClass]="{ 'mobile': isMobile, 'web': !isMobile }"
	>
		<app-web-content-header
			class="w-100"
			[headerContent]="headerContent"
			[isMobile]="isMobile"
			[reference]="'REF'+ticketId"
			[id]="ticketId"
		></app-web-content-header>
		<div
			[class.l-questions-mobile]="isMobile"
			[class.l-questions-web]="!isMobile"
			class="d-flex flex-column align-items-start mb-3"
		>
			<div
				*ngIf="isMobile"
				class="mb-3"
			>Edit Ticket Details for <strong>REF{{ticketId}}</strong></div>
			<ng-container *ngIf="!editTicketLoading; else showLoading">
				<ng-container *ngIf="editTicketFormData?.hasCheckedInTickets">
					<div class="g-orange l-status-message mb-3"> Some Tickets
						have already been checked-in, these
						tickets will not be shown.</div>
				</ng-container>
				<ng-container *ngIf="isPurchaseLocked">
					<div class="g-orange l-status-message mb-3"> This ticket is currently locked. To receive your
						ticket, you must first edit and confirm your details.</div>
				</ng-container>
				<ng-container *ngIf="editTicketFormData?.transferred">
					<div class="g-orange l-status-message mb-3"> These tickets were transferred to you.
						Enter your ticket information to get your tickets</div>
				</ng-container>
				<ng-container *ngIf="editTicketFormData?.numEditsLimited">
					<div class="l-status-message mb-3">
						<ng-container *ngIf='editTicketFormData.numEditsRemaining === 0; else remainingEdits'>
							You have exceeded the <span
								class="strong g-orange">{{editTicketFormData.numEditsUsed}}</span> edits available for
							these tickets. They are no longer
							available to edit.
						</ng-container>
						<ng-template #remainingEdits>
							This event allows you to edit the ticket details on this page <span class="strong g-orange">
								{{editTicketFormData.numEditsUsed + editTicketFormData.numEditsRemaining}}</span> times.
							You have <span class="strong g-orange">{{editTicketFormData.numEditsRemaining}}</span>
							edit(s) remaining. <br />
							<div class="mt-2">Clicking "Save Changes" at the bottom of this page will count as an edit.
							</div>
						</ng-template>
					</div>
				</ng-container>
				<ng-container *ngIf="hasUniqueError">
					<div class="g-orange l-status-message mb-3">{{ editTicketFormData.errorMessage }}</div>
				</ng-container>
				<ng-container *ngIf="!errorMessage; else showErrorMessage">
					<ng-container
						*ngIf="editTicketFormData?.numEditsRemaining > 0 || !editTicketFormData?.numEditsLimited"
					>
						<form
							*ngIf="buyerOnlyQuestions.length"
							[formGroup]="editTicketForms.get('buyerOnly')"
							[ngClass]="{'w-100': isMobile}"
							class="l-buyer-details"
						>
							<h4> Buyer Details </h4>
							<div
								*ngFor="let control of buyerOnlyQuestions; let i = index"
								class="mb-3 w-100"
							>
								<app-dynamic-form-fields
									id="buyer-only-answer-{{i + 1}}"
									[index]="i"
									[control]="control"
									[isMobile]="isMobile"
									[editTicketFormGroup]="editTicketForms.get('buyerOnly')"
									[purchaseId]="ticketId"
									[productId]="editTicketFormData.productId"
									[encrypt]="editTicketFormData.encrypt"
									[isBuyerQuestion]="true"
									(questionUpdated)="handleUpdateQuestion($event)"
								>
								</app-dynamic-form-fields>
							</div>
						</form>
						<form
							[formGroup]="editTicketForms"
							[ngClass]="{'w-100': isMobile}"
							class="l-form"
						>
							<h4> Ticket Details </h4>
							<div
								*ngFor="let ticket of purchaseItems; let i = index"
								class="mb-3"
							>
								<mat-accordion>
									<mat-expansion-panel [expanded]="expandPanels">
										<mat-expansion-panel-header
											[ngClass]="{'l-error-border': editTicketForms.get(ticket.id.toString()).invalid}"
										>
											<span
												id="ticket-{{i+1}}"
												[class.mx-3]="isMobile"
												[class.g-f-14]="isMobile"
												[class.g-subtitle]="!isMobile"
												class="l-expansion-header"
											>
												Ticket #{{i + 1}} - {{ticket.name}}
												<mat-icon
													id='not-editable-tooltip'
													*ngIf='!ticket.isEditable'
													class="g-orange l-icon"
													(click)="tooltip.toggle(); $event.stopImmediatePropagation()"
													#tooltip="matTooltip"
													[matTooltip]="ticket.reasonNotEditable"
													matTooltipPosition="right"
												>info</mat-icon>
											</span>
										</mat-expansion-panel-header>

										<form
											[formGroup]="editTicketForms.get(ticket.id.toString())"
											class="g-form-container gap-3"
										>
											<div
												*ngFor="let control of ticket.questions; let j = index"
												class="mb-3 w-100"
											>
												<app-dynamic-form-fields
													id="ticket-{{i+1}}-answer-{{j + 1}}"
													[index]="i"
													[control]="control"
													[isMobile]="isMobile"
													[editTicketFormGroup]="editTicketForms.get(ticket.id.toString())"
													[purchaseId]="ticketId"
													[productId]="editTicketFormData.productId"
													[encrypt]="editTicketFormData.encrypt"
												>
												</app-dynamic-form-fields>
											</div>
										</form>
										<div
											id='apply-all'
											*ngIf="purchaseItems.length > 1 && ticket.isEditable"
											class="l-apply-all"
											[class.mx-3]="isMobile"
											(click)="handleApplyAll(ticket.id)"
										>
											Apply these details to all tickets
										</div>
										<div class="w-100 d-flex mt-3 mb-4">
											<mat-divider
												class="w-100"
												[class.mx-3]="isMobile"
											></mat-divider>
										</div>
									</mat-expansion-panel>

								</mat-accordion>
							</div>
						</form>
					</ng-container>
				</ng-container>
				<ng-template #showErrorMessage>
					<div
						*ngIf="errorMessage"
						class="g-red l-error-message"
						[class.mobile]="isMobile"
					>
						<mat-icon class="overflow-visible">error</mat-icon>
						<div>{{errorMessage}}</div>
					</div>
				</ng-template>
			</ng-container>
			<ng-template #showLoading>
				<div class="l-loading">
					<mat-spinner></mat-spinner>
				</div>
			</ng-template>
		</div>
	</div>
</div>
<div class="l-sticky-bar">
	<app-footer-button
		#footerButton
		class="w-100 mt-auto"
		[primaryText]="'SAVE CHANGES'"
		[isDisabled]="isFooterButtonDisabled"
		($primaryButton)="handleSubmit()"
		[isLoading]="editTicketLoading"
		($secondaryButton)="handleNavigationBack()"
		[secondaryText]="getSecondaryText()"
		[isMobile]="isMobile"
	>
	</app-footer-button>
</div>
