import { Pipe, PipeTransform } from '@angular/core';
import { TransferTicket } from '@app/models/user.model';

@Pipe({
	name: 'formattedTicketTransfer',
})
export class FormattedTicketTransferPipe implements PipeTransform {

	transform(ticket: TransferTicket): string {
		const ticketName = ticket.ticketName.split(',')[0];
		const seat = ticket.seat;

		return ticketName + seat;
	}

}
