import { NgModule } from '@angular/core';
import { EventInvitationsComponent } from './event-invitations.component';
import { EventSelectContactsFormComponent } from './select-contacts-form/event-select-contacts-form.component';
import { SharedModule } from '@app/shared/shared.module';
import { EventInvitationsService } from './event-invitations.service';

@NgModule({
	imports: [
		SharedModule,
	],
	declarations: [
		EventInvitationsComponent,
		EventSelectContactsFormComponent,
	],
	exports: [
		EventInvitationsComponent,
	],
	providers: [
		EventInvitationsService,
	],
}) export class EventInvitationsModule {}
