import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { BankAccountForm } from '@app/models/order.model';
import { Bank } from '@app/models/user.model';
import { StoreService } from '@app/services/store/store.service';
import { GetBanks } from '@app/store/actions/user/user.actions';
import { banks as banksSelector } from '@app/store/selectors/event.selector';
import { takeUntil } from 'rxjs';

@Component({
	selector: 'app-cancel-tickets-bank-details',
	templateUrl: './cancel-tickets-bank-details.component.html',
	styleUrls: ['./cancel-tickets-bank-details.component.sass'],
})
export class CancelTicketsBankDetailsComponent implements OnInit {
	@Input() bankAccountForm: FormControl<BankAccountForm>;

	bankSelect: FormControl<Bank> = new FormControl<Bank>(null);
	requestBranchCode = false;

	banks: Bank[];
	filteredBanks: Bank[];
	isBanksLoading = true;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(private store: StoreService) {}

	ngOnInit() {
		this.store
			.select(banksSelector())
			.pipe(takeUntil(this.destroyed$))
			.subscribe((banks) => {
				if (banks.length) {
					this.banks = banks;
					this.filteredBanks = banks;
					this.isBanksLoading = false;
				} else {
					this.store.dispatch(new GetBanks({ currencyId: 1 }));
				}
			});

		this.bankSelect.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
			this.bankAccountForm.get('bankName').setValue(value.name);
			this.bankAccountForm.get('branchCode').setValue(value.universalCode);
			this.requestBranchCode = value.universalCode ? false : true;
		});
	}

	handleBanksFilter(input: string) {
		if (!this.banks) {
			return;
		}
		let search = input;
		if (!search) {
			this.filteredBanks = this.banks;
			return;
		} else {
			search = search.toLowerCase();
			this.filteredBanks = this.banks.filter((bank) => bank.name.toLowerCase().includes(search));
		}
	}

	fromForm(controlKey: string, form: FormGroup): AbstractControl {
		if (form) {
			return form.get(controlKey);
		}
		return null;
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
