<div class="l-container" *ngIf="!isProductsLoading; else pageLoading">
	<div class="l-content l-header-content" [ngClass]="{ 'mobile': isMobile }">
        <app-web-content-header *ngIf='!isMobile; else mobileHeaderContent' class="l-header" [headerContent]="headerContent"
        [mediumWidth]="true"></app-web-content-header>
		<ng-template #mobileHeaderContent>
            <div class="l-mobile-description">
                <p *ngIf="!readMore" class="l-header">{{ customDescription }}
                    <span *ngIf="includeReadMore" id="web-header-read-more" (click)="handleReadMore()" class="g-pointer g-highlight g-green">
                        {{readMoreText}}
                    </span>
                </p>
                <p *ngIf="readMore" class="l-header" [ngClass]="{ 'mobile': isMobile }">{{ headerContent.description }}
                    <span *ngIf="includeReadMore" id="web-header-read-more" (click)="handleReadMore()" class="g-pointer g-highlight g-green">
                        {{readMoreText}}
                    </span>
                </p>
            </div>
        </ng-template>
        <div *ngIf="hasPromoters">
            <div *ngIf="!isMobile; else mobileButton" class="l-add-incentive-button-container">
                <button class="g-primary-button l-add-incentive-button" (click)="openIncentiveModal()" >INCENTIVISE GUESTS</button>
            </div>
            <ng-template #mobileButton>
                <div class="l-add-incentive-button-mobile-container">
                    <button class="g-primary-button w-100" (click)="openIncentiveModal()" >INCENTIVISE GUESTS</button>
                </div>
            </ng-template>
        </div>
	</div>
    <div class="l-content">
        <div *ngIf='!hasPromoters; else promotersContent' class="l-promoters-content-empty" [ngClass]="{ 'mobile': isMobile }">
            <img src=".\assets\placeholders\promoter-network-empty.png" class="l-image">
            <h3 class="l-no-promoters-title" [ngClass]="{ 'mobile': isMobile }">No One Has Promoted Your Event Yet</h3>
            <p class="l-no-promoters-description" [ngClass]="{ 'mobile': isMobile }">
                Add an incentive for ticket buyers to promote your event.</p>
            <div class="l-add-incentive-button-no-promoter-data-container" [ngClass]="{ 'l-add-incentive-button-mobile-container': isMobile }">
                <button class="g-primary-button l-add-incentive-button-no-promoter-data" [ngClass]="{ 'mobile': isMobile }"
                (click)="openIncentiveModal()">ADD AN INCENTIVE TO PROMOTE</button>
            </div>
        </div>
        <ng-template #promotersContent>
            <div class="l-info-cards-container" [ngClass]="{ 'mobile': isMobile }">
                <app-info-card
                    class="l-info-card" [isMobile]="isMobile" [title]="'Total Visits'"
                    [data]="promotersPageInfo.totalVisits" [tooltipText]="'Total Visits Info'">
                </app-info-card>
                <app-info-card
                    class="l-info-card" [isMobile]="isMobile" [title]="'Total Tickets Sold'"
                    [data]="promotersPageInfo.totalTicketsSold" [tooltipText]="'Total Tickets Info'">
                </app-info-card>
                <app-info-card
                    class="l-info-card" [isMobile]="isMobile" [title]="'Total Sold Through Quicket'"
                    [data]="promotersPageInfo.totalSalesValue"
                    [tooltipText]="'Total Tickets via Quicket Info'" [currencySymbol]="promotersPageInfo.currencySymbol">
                </app-info-card>
            </div>
            <div class="l-promoters-content-container" [ngClass]="{ 'mobile': isMobile }">
                <app-web-filter [isMobile]="isMobile" [buttonText]="'Download CSV'" [searchPlaceholder]="'Search promoters'"
                (filterOutput)="handleFilter($event)" (buttonClick)="handleDownload()"></app-web-filter>
                <div class="l-table-container">
                    <div *ngIf='isProductsFiltering' class='l-filtering'>
                        <mat-spinner></mat-spinner>
                    </div>
                    <table mat-table [dataSource]="promoters" class='l-table web' matSort>
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Promoter Name </th>
                            <td mat-cell *matCellDef="let promoter">{{ promoter.name }}</td>
                        </ng-container>
                        <ng-container matColumnDef="numberOfClicks">
                            <th mat-header-cell *matHeaderCellDef> Number of Clicks </th>
                            <td mat-cell *matCellDef="let promoter">{{ promoter.numberOfClicks }}</td>
                        </ng-container>
                        <ng-container matColumnDef="numberOfTickets">
                            <th mat-header-cell *matHeaderCellDef> Number of Tickets </th>
                            <td mat-cell *matCellDef="let promoter">{{ promoter.numberOfTickets }}</td>
                        </ng-container>
                        <ng-container matColumnDef="salesValue">
                            <th mat-header-cell *matHeaderCellDef> Number of Sales ({{ promotersPageInfo.currencyISO }}) </th>
                            <td mat-cell *matCellDef="let promoter">{{ promoter.salesValue}}</td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
                        <tr mat-row *matNoDataRow>
                            <td mat-cell *colSpan="4">
                                <div class="l-empty-state">
                                    <h3 class="mb-3">Nothing to see here...</h3>
                                    <div class="mb-3">Nothing matches your search or filters</div>
                                </div>
                            </td>
                        </tr>
                    </table>
                    <mat-paginator #productsPaginator [pageSizeOptions]="pageSizeOptions"></mat-paginator>
                </div>
            </div>
        </ng-template>
    </div>
</div>
<ng-template #pageLoading>
	<div class="l-page-loader">
		<mat-spinner></mat-spinner>
	</div>
</ng-template>
