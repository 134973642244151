<div class="l-container">
	<div class="l-content" [ngClass]="{ 'mobile': isMobile }">
		<div class="l-title" [ngClass]="{ 'mobile': isMobile, 'web': !isMobile }">
			<h3 *ngIf='!isMobile'>Marketing</h3>
			<p>
				Maximise your event's reach with our marketing tools. Engage promoters, expand networks, 
				create promo codes, and track campaigns effortlessly to ensure your event's success.
			</p>
		</div>
		<div class="l-cards-container" [ngClass]="{ 'mobile': isMobile, 'web': !isMobile }">
			<a *ngFor="let card of cardsData | keyvalue: handleDefaultOrder" [routerLink]="[card.value.routeTo(eventId)]"
				[ngClass]="{'l-card': !isMobile }" class="g-flat-link g-pointer" [id]="'nav-card-'+ card.key">
				<ng-container *ngIf="!isMobile; else showMobile">
					<app-section-route-card class="g-pointer l-card-content"
						[card]="card.value"></app-section-route-card>
				</ng-container>
				<ng-template #showMobile>
					<app-mobile-section-route-card [icon]="card.value.iconPath"
						[text]="card.value.title" [asCard]='true'></app-mobile-section-route-card>
				</ng-template>
			</a>
		</div>
	</div>
</div>
