import { Component, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SchemeID } from '@app/models/dataSchema.model';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { Observable, take, takeUntil } from 'rxjs';
import { EVENT_ID_PARAM_KEY } from '@app/utils/consts';
import { HeaderContent } from '@app/models/shared';
import { InternalURLCreator } from '@app/services/url/url.dictionary';
import { EventTab, TabbedContentPageView } from '@app/models/event.model';
import { StoreService } from '@app/services/store/store.service';
import { Go } from '@app/store/actions/router/router.actions';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '@app/shared/modals/confirmation-modal/confirmation-modal.component';
import { FormCanDeactivate } from '@app/services/guards/event.guard';

@Component({
	selector: 'app-tabbed-content',
	templateUrl: './tabbed-content.component.html',
	styleUrls: ['./tabbed-content.component.sass'],
})

export class TabbedContentComponent extends FormCanDeactivate {
	isMobile = false;
	eventId: SchemeID;
	tabPageView = TabbedContentPageView.view;
	tabForEditing: EventTab;
	isEditing = false;
	browserBackClicked = false;

	headerContent: HeaderContent = {
		breadCrumbs: [
			{
				routeName: 'Manage Event',
				routeTo: () => InternalURLCreator.manageEvent(this.eventId),
			},
			{
				routeName: 'Tabbed Content',
				routeTo: () => InternalURLCreator.tabbedContent(this.eventId),
			},
		],
		title: 'Tabbed Content',
		description: `With Tabbed Content you can organise your event description into multiple sections,
		allowing ticket buyers to easily find the information they need. Your main event
		description will still appear in a tab titled ABOUT.`,
	};

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private breakpointService: BreakpointService,
		private activatedRoute: ActivatedRoute,
		private store: StoreService,
		private dialog: MatDialog
	) {
		super();
	}

	ngOnInit(): void {
		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});

		this.activatedRoute.parent.paramMap.pipe(take(1)).subscribe((params) => {
			this.eventId = +params.get(EVENT_ID_PARAM_KEY);
		});

		window.addEventListener('popstate', () => {
			this.browserBackClicked = true;
		}, false);
	}

	handleAddTab() {
		this.tabPageView = TabbedContentPageView.add;
		this.isEditing = false;
		this.headerContent.title = 'Add Tab';
		this.headerContent.breadCrumbs = [
			...this.headerContent.breadCrumbs,
			{
				routeName: 'Add Tab',
			},
		];
	}

	handleTabEdit(eventTab: EventTab) {
		this.tabForEditing = eventTab;
		this.isEditing = true;
		this.tabPageView = TabbedContentPageView.edit;
		this.headerContent.title = 'Edit Tab';
		this.headerContent.breadCrumbs = [
			...this.headerContent.breadCrumbs,
			{
				routeName: 'Edit Tab',
			},
		];
	}

	handleTabComplete() {
		this.tabPageView = TabbedContentPageView.view;
		this.isEditing = false;
		this.headerContent.breadCrumbs = this.headerContent.breadCrumbs.slice(0, -1);
		this.headerContent.title = 'Tabbed Content';
	}

	handleBreadCrumbNavigation(routeTo: CallableFunction) {
		const isNavigatingToTabbedContent = routeTo(this.eventId) === InternalURLCreator.tabbedContent(this.eventId);
		const requiresConfirmation = (isNavigatingToTabbedContent && this.tabPageView !== TabbedContentPageView.view);
		if (requiresConfirmation) {
			this.dialog.open(ConfirmationModalComponent, {
				data: {
					title: 'Unsaved Changes',
					text: 'Are you sure you want to leave this page? Any unsaved changes will be discarded.',
					buttonText: 'LEAVE',
					centerText: true,
					isMobile: this.isMobile,
				},
				panelClass: 'g-standard-dialog',
			}).afterClosed().subscribe((confirmed: boolean) => {
				if (confirmed) {
					if (isNavigatingToTabbedContent && this.tabPageView !== TabbedContentPageView.view) {
						this.handleTabComplete();
					}
				}
			});
		} else {
			this.store.dispatch(new Go({ path: [routeTo(this.eventId)] }));
		}
	}

	isFormDeactivatable(): boolean | Observable<boolean> {
		return this.tabPageView === TabbedContentPageView.view;
	}

	deactivationFallback(): Observable<boolean> {
		this.dialog
			.open(ConfirmationModalComponent, {
				data: {
					title: 'Unsaved Changes',
					text: 'Are you sure you want to leave this page? Any unsaved changes will be discarded.',
					buttonText: 'LEAVE',
					centerText: true,
					isMobile: this.isMobile,
				},
				panelClass: 'g-standard-dialog',
			})
			.afterClosed()
			.subscribe((result) => {
				this.closeDeactivationModal(result);
				if ((this.isMobile || this.browserBackClicked) && result) {
					this.browserBackClicked = false;
					this.handleTabComplete();
					this.store.dispatch(new Go({ path: [InternalURLCreator.tabbedContent(this.eventId)] }));
				}
			});
		return this.modalCallback.asObservable();
	}

	closeDeactivationModal(isConfirmed: boolean): void {
		this.modalCallback.next(isConfirmed);
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
		this.browserBackClicked = false;
	}
}
