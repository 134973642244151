import { Component, Input } from '@angular/core';
import { SocialSharingService } from '@app/services/social-sharing/socail-sharing.service';

@Component({
	selector: 'app-whatsapp-sharing-btn',
	templateUrl: './whatsapp-sharing.component.html',
})

export class WhatsappSharingButtonComponent {
	@Input() eventName: string;
	@Input() link: string;
	constructor(
		private socialSharing: SocialSharingService
	) { }

	getSharingLink() {
		return this.socialSharing.getWhatsappSharingURL(this.link, this.eventName);
	}
}
