import { GoogleMapsModule } from '@angular/google-maps';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormFieldModule } from './form-field/form-field.module';
import { DeleteConfirmationComponent } from './delete-confirmation/delete-confirmation.component';
import { LoadingSpinnerModule } from './loading-spinner/loading-spinner.component';
import { UIKitModule } from '@app/shared/uikit.module';
import { ButtonComponent } from './button/button.component';
import { NotificationComponent } from './notification/notification.component';
import { ErrorModalComponent } from './error-modal/error-modal.component';
import { ClipboardModule } from 'ngx-clipboard';
import { FacebookSharingButtonComponent } from './social-sharing/facebook-sharing/facebook-sharing.component';
import { TwitterSharingButtonComponent } from './social-sharing/twitter-sharing/twitter-sharing.component';
import { MailSharingButtonComponent } from './social-sharing/mail-sharing/mail-sharing.component';
import { PinterestSharingButtonComponent } from './social-sharing/pinterest-sharing/pinterest-sharing.component';
import { LinkedinSharingButtonComponent } from './social-sharing/linkedin-sharing/linkedin-sharing.component';
import { WhatsappSharingButtonComponent } from './social-sharing/whatsapp-sharing/whatsapp-sharing.component';
import { AppRouterModule } from '@app/router/router.module';
import { DialogModule } from './dialog/dialog.component';
import { SectionRouteCardComponent } from './section-route-card/section-route-card.component';
import { MobileSectionRouteCardComponent } from './mobile-section-route-card/mobile-section-route-card.component';
import { AngularMaterialModule } from './angular-material.module';
import { FooterButtonComponent } from './footer-button/footer-button.component';
import { WebContentHeaderComponent } from './web-content-header/web-content-header.component';
import { LinkCardComponent } from './link-card/link-card.component';
import { FormattedDatePipe, FormattedShortDatePipe } from './pipes/FormattedDatePipe';
import { HeaderMobileComponent } from './header-mobile/header-mobile.component';
import { ConfirmationModalComponent } from './modals/confirmation-modal/confirmation-modal.component';
import { MobileSidenavComponent } from './mobile-sidenav/mobile-sidenav.component';
import { FormattedAccountNumberPipePipe } from './pipes/formatted-account-number-pipe.pipe';
import { FormattedTicketTransferPipe } from './pipes/formatted-ticket-transfer.pipe';
import { ErrorDisplayModalComponent } from './error-display-modal/error-display-modal.component';
import { OrganiserMobileHeaderComponent } from './organiser-mobile-header/organiser-mobile-header.component';
import { MobileNavMenuComponent } from './mobile-nav-menu/mobile-nav-menu.component';
import { FormattedTimePipe } from './pipes/formatted-time-pipe';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { MobileFilterBottomsheetComponent } from './mobile-filter-bottomsheet/mobile-filter-bottomsheet.component';
import { TicketCardComponent } from './ticket-card/ticket-card.component';
import { BankAccountModalComponent } from './bank-account-modal/bank-account-modal.component';
import { SkeletonLoaderComponent } from './skeleton-loader/skeleton-loader.component';
import { AdditionalInfoComponent } from './additional-info/additional-info.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { WebFilterComponent } from './web-filter/web-filter.component';
import { TabNavBarComponent } from './tab-nav-bar/tab-nav-bar.component';
import { ScheduleSelectorComponent } from './schedule-selector/schedule-selector.component';
import { EventDetailsFormComponent } from './event-details-form/event-details-form.component';
import { TimezoneSelectorComponent } from './timezone-selector/timezone-selector.component';
import { AddressAutocompleteComponent } from './address-autocomplete/address-autocomplete.component';
import { ImageUploadAndCropperComponent } from './image-upload-and-cropper/image-upload-and-cropper.component';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { DropdownButtonComponent } from './dropdown-button/dropdown-button.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { MatSelectControlFieldComponent } from './mat-form-field/mat-select-control-field/mat-select-control-field.component';
import { MatTextControlFieldComponent } from './mat-form-field/mat-text-control-field/mat-text-control-field.component';
import { MatNumberControlFieldComponent } from './mat-form-field/mat-number-control-field/mat-number-control-field.component';
import { MatTextAreaControlFieldComponent } from './mat-form-field/mat-text-area-control-field/mat-text-area-control-field.component';
import { MatSlideControlFieldComponent } from './mat-form-field/mat-slide-control-field/mat-slide-control-field.component';
import { ScheduleCreatorComponent } from './event-details-form/schedule-creator/schedule-creator.component';
import { InfoCardComponent } from './info-card/info-card.component';
import { IframeComponent } from './iframe/iframe.component';
import { EventTicketTypesFormComponent } from './event-ticket-types-form/event-ticket-types-form.component';
import { SeatingChartModalComponent } from './seating-chart-modal/seating-chart-modal.component';
import { CurrencySelectModalComponent } from './currency-select-modal/currency-select-modal.component';
import { EventTicketTypesTableComponent } from './event-ticket-types-table/event-ticket-types-table.component';
import { NgxMaskModule } from 'ngx-mask';
import { ApplyAllFormComponent } from './apply-all-form/apply-all-form.component';
import { QuantityWarningModalComponent } from './quantity-warning-modal/quantity-warning-modal.component';
import { PreviewComponent } from './preview-modal/preview-modal.component';
// eslint-disable-next-line max-len
import { MultiScheduleSelectModalComponent } from './event-details-form/schedule-creator/multi-schedule-select-modal/multi-schedule-select-modal.component';
import { ChartComponent } from './chart/chart.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { InputModalComponent } from './modals/input-modal/input-modal.component';
import { EventOrganiserProfileFormComponent } from './event-organiser-profile-form/event-organiser-profile-form.component';
import { BankAccountSelectionComponent } from './bank-account-selection/bank-account-selection.component';
import { MiddleClickNavigateModule } from '@app/directive/middle-click-navigate.directive';
import { ValueMatchModule } from '@app/directive/value-match.directive';
import { PhoneInputComponent } from './mat-form-field/phone-input/phone-input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { AddressLinesComponent } from './mat-form-field/address-lines/address-lines.component';
import { MobileNotificationComponent } from './mobile-notification/mobile-notification.component';
import { MyAccountHeaderComponent } from './my-account-header/my-account-header.component';
import { ChartTableModalComponent } from './chart-table-modal/chart-table-modal.component';
import { TruncatePipe } from './pipes/truncate';
import { DynamicCurrencyPipe } from './pipes/dynamic-currency.pipe';

const socialSharingComponents = [
	FacebookSharingButtonComponent,
	TwitterSharingButtonComponent,
	MailSharingButtonComponent,
	PinterestSharingButtonComponent,
	LinkedinSharingButtonComponent,
	WhatsappSharingButtonComponent,
];

@NgModule({
	declarations: [
		HeaderComponent,
		DeleteConfirmationComponent,
		ButtonComponent,
		NotificationComponent,
		ErrorModalComponent,
		SectionRouteCardComponent,
		MobileSectionRouteCardComponent,
		FooterButtonComponent,
		...socialSharingComponents,
		WebContentHeaderComponent,
		LinkCardComponent,
		FormattedDatePipe,
		FormattedShortDatePipe,
		FormattedTimePipe,
		HeaderMobileComponent,
		ConfirmationModalComponent,
		MobileSidenavComponent,
		FormattedAccountNumberPipePipe,
		ErrorDisplayModalComponent,
		SearchBarComponent,
		MobileFilterBottomsheetComponent,
		FormattedTicketTransferPipe,
		TicketCardComponent,
		BankAccountModalComponent,
		OrganiserMobileHeaderComponent,
		MobileNavMenuComponent,
		SkeletonLoaderComponent,
		AdditionalInfoComponent,
		SideNavComponent,
		WebFilterComponent,
		TabNavBarComponent,
		TabNavBarComponent,
		ScheduleSelectorComponent,
		EventDetailsFormComponent,
		TimezoneSelectorComponent,
		AddressAutocompleteComponent,
		ImageUploadAndCropperComponent,
		ImageUploadComponent,
		DropdownButtonComponent,
		MatSelectControlFieldComponent,
		MatTextControlFieldComponent,
		MatNumberControlFieldComponent,
		MatTextAreaControlFieldComponent,
		MatSlideControlFieldComponent,
		BreadcrumbComponent,
		InfoCardComponent,
		IframeComponent,
		PreviewComponent,
		EventTicketTypesFormComponent,
		SeatingChartModalComponent,
		CurrencySelectModalComponent,
		EventTicketTypesTableComponent,
		ApplyAllFormComponent,
		QuantityWarningModalComponent,
		ScheduleCreatorComponent,
		MultiScheduleSelectModalComponent,
		ChartComponent,
		InputModalComponent,
		EventOrganiserProfileFormComponent,
		BankAccountSelectionComponent,
		PhoneInputComponent,
  		AddressLinesComponent,
    	MobileNotificationComponent,
     	MyAccountHeaderComponent,
      	ChartTableModalComponent,
		TruncatePipe,
  		DynamicCurrencyPipe,
	],
	exports: [
		HeaderComponent,
		DeleteConfirmationComponent,
		LoadingSpinnerModule,
		ButtonComponent,
		NotificationComponent,
		CommonModule,
		UIKitModule,
		FormFieldModule,
		ErrorModalComponent,
		ClipboardModule,
		DialogModule,
		SectionRouteCardComponent,
		MobileSectionRouteCardComponent,
		FooterButtonComponent,
		...socialSharingComponents,
		WebContentHeaderComponent,
		LinkCardComponent,
		HeaderMobileComponent,
		FormattedDatePipe,
		FormattedShortDatePipe,
		FormattedTimePipe,
		MobileSidenavComponent,
		FormattedAccountNumberPipePipe,
		FormattedTicketTransferPipe,
		SearchBarComponent,
		MobileFilterBottomsheetComponent,
		TicketCardComponent,
		BankAccountModalComponent,
		ErrorDisplayModalComponent,
		OrganiserMobileHeaderComponent,
		SkeletonLoaderComponent,
		AdditionalInfoComponent,
		SideNavComponent,
		WebFilterComponent,
		TabNavBarComponent,
		ScheduleSelectorComponent,
		EventDetailsFormComponent,
		TimezoneSelectorComponent,
		AddressAutocompleteComponent,
		ImageUploadAndCropperComponent,
		ImageUploadComponent,
		DropdownButtonComponent,
		MatSelectControlFieldComponent,
		MatTextControlFieldComponent,
		MatNumberControlFieldComponent,
		MatTextAreaControlFieldComponent,
		MatSlideControlFieldComponent,
		BreadcrumbComponent,
		InfoCardComponent,
		IframeComponent,
		EventTicketTypesFormComponent,
		EventTicketTypesTableComponent,
		ApplyAllFormComponent,
		QuantityWarningModalComponent,
		ScheduleCreatorComponent,
		MultiScheduleSelectModalComponent,
		ChartComponent,
		InputModalComponent,
		EventOrganiserProfileFormComponent,
		BankAccountSelectionComponent,
		PhoneInputComponent,
		AddressLinesComponent,
		MobileNotificationComponent,
		MyAccountHeaderComponent,
		ChartTableModalComponent,
		TruncatePipe,
		DynamicCurrencyPipe,
	],
	imports: [
		UIKitModule,
		CommonModule,
		BrowserAnimationsModule,
		AppRouterModule,
		FormFieldModule,
		ClipboardModule,
		DialogModule,
		LoadingSpinnerModule,
		AngularMaterialModule,
		GoogleMapsModule,
		NgxMaskModule,
		NgxChartsModule,
		MiddleClickNavigateModule,
		ValueMatchModule,
		ReactiveFormsModule,
		NgxIntlTelInputModule,
	],
})
export class SharedModule { }
