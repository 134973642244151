import { Component, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HeaderContent } from '@app/models/shared';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { StoreService } from '@app/services/store/store.service';
import { InternalURLCreator } from '@app/services/url/url.dictionary';
import { EVENT_ID_PARAM_KEY } from '@app/utils/consts';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntil, take, map, distinctUntilChanged, Observable } from 'rxjs';
import * as eventSelectors from '@app/store/selectors/event.selector';
import { GetEventTickets, MarkAsSoldOut, StopTicketSales } from '@app/store/actions/tickets/tickets.actions';
import { GetUserCharts } from '@app/store/actions/user/user.actions';
import { userCharts } from '@app/store/selectors/userCharts.selector';
import { userDesignerKey } from '@app/store/selectors/user.selector';
import { Currency, SeatingCategory, UserChart } from '@app/models/ticket.model';
import MoneyPresenter from '@app/utils/MoneyPresenter';
import { TicketsActionsConstant } from '@app/store/actions/tickets/tickets.constants';
import { UntypedFormGroup } from '@angular/forms';
import { TicketsService } from '@app/services/tickets/tickets.service';
import { cloneDeep, isEqual } from 'lodash';
import { EventCurrency, EventDetails, EventQuestion } from '@app/models/event.model';
import { EventMetadata } from '@app/models/event-metadata.model';
import { UserActionsConstants } from '@app/store/actions/user/user.actions.constants';
import { MatDialog } from '@angular/material/dialog';
import { StopSalesModalComponent } from '../../modals/stop-sales-modal/stop-sales-modal.component';
import { FormCanDeactivate } from '@app/services/guards/event.guard';
import { ConfirmationModalComponent } from '@app/shared/modals/confirmation-modal/confirmation-modal.component';

@Component({
	selector: 'app-ticket-types',
	templateUrl: './ticket-types.component.html',
	styleUrls: ['./ticket-types.component.sass'],
})
export class TicketTypesComponent extends FormCanDeactivate {
	isMobile: boolean;
	eventId: number;

	isEventTicketsLoading = true;
	isUserChartsLoading = true;

	headerContent: HeaderContent = {
		breadCrumbs: [
			{
				routeName: 'Manage Checkout',
				routeTo: () => InternalURLCreator.tickets(this.eventId),
			},
			{
				routeName: 'Ticket Types',
			},
		],
		description: `Create and customize various ticket categories. Easily manage ticket options, 
		currency, capacity limits, and fees, with expandable details for each ticket type.`,
		title: 'Ticket Types',
	};

	form: UntypedFormGroup;
	seatingCategories: SeatingCategory[];
	questions: EventQuestion[];
	currencies: EventCurrency[];
	userDesignerKey: string;
	details: EventDetails = null;
	userCharts: UserChart[];

	eventMetadata: EventMetadata;
	hasSoldTickets: boolean;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private breakpointService: BreakpointService,
		private store: StoreService,
		private activatedRoute: ActivatedRoute,
		private actions$: Actions,
		private ticketsService: TicketsService,
		private dialog: MatDialog
	) {
		super();
	}

	ngOnInit(): void {
		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});

		this.activatedRoute.parent.paramMap.pipe(take(1)).subscribe((params) => {
			this.eventId = +params.get(EVENT_ID_PARAM_KEY);

			this.store
				.select(eventSelectors.eventMetadata(this.eventId))
				.pipe(take(1))
				.subscribe((metaData) => {
					this.eventMetadata = metaData;
				});

			this.initialiseTicketTypes();
			this.initialiseUserCharts();
			this.initialiseFormSuccessActions();
		});
	}

	initialiseTicketTypes() {
		this.actions$
			.pipe(
				ofType(TicketsActionsConstant.GET_EVENT_TICKETS_SUCCESS, TicketsActionsConstant.GET_EVENT_TICKETS_FAILED),
				takeUntil(this.destroyed$)
			)
			.subscribe(({ type }: { type: string }) => {
				if (type === TicketsActionsConstant.GET_EVENT_TICKETS_SUCCESS) {
					this.initialiseForm();
				}
				this.isEventTicketsLoading = false;
			});

		this.store.dispatch(new GetEventTickets({ id: this.eventId }));
	}

	initialiseUserCharts() {
		this.actions$
			.pipe(
				ofType(UserActionsConstants.GET_USER_CHARTS_SUCCESS, UserActionsConstants.GET_USER_CHARTS_FAILED),
				takeUntil(this.destroyed$)
			)
			.subscribe(() => {
				this.isUserChartsLoading = false;
			});

		this.store.dispatch(new GetUserCharts({ eventId: this.eventId }));

		this.store
			.select(userCharts())
			.pipe(takeUntil(this.destroyed$))
			.subscribe((charts) => {
				if (charts) {
					this.userCharts = charts;
				}
			});
	}

	initialiseForm() {
		this.store
			.select(eventSelectors.eventTickets())
			.pipe(
				takeUntil(this.destroyed$),
				distinctUntilChanged((prev, curr) => isEqual(prev, curr))
			)
			.subscribe((event) => {
				if (event) {
					if (!this.form) {
						this.form = this.ticketsService.createEventTicketsForm(event.ticketsDetails, event.seatingCategories);
						this.initialiseTicketComponents();
					} else {
						this.ticketsService.checkAndUpdateTicketsIds(this.form, event.ticketsDetails.tickets);
						this.ticketsService.checkAndUpdateTicketCategories(event.seatingCategories, this.form);
						this.ticketsService.checkAndUpdateTicketQuestions(this.form, event.ticketsDetails.tickets);
						this.ticketsService.checkAndUpdateTicketSalesEnds(this.form, event.ticketsDetails.tickets);
						this.ticketsService.checkAndUpdateTicketsCapacity(this.form, event.ticketsDetails);
					}

					this.details = event.details;
					this.seatingCategories = cloneDeep(event.seatingCategories);
				}
			});
	}

	initialiseTicketComponents() {
		this.store
			.select(userDesignerKey())
			.pipe(takeUntil(this.destroyed$))
			.subscribe((designerKey) => {
				this.userDesignerKey = designerKey;
			});
		this.store
			.select(eventSelectors.questions())
			.pipe(takeUntil(this.destroyed$))
			.subscribe((questions) => {
				this.questions = questions;
			});
		this.store
			.select(eventSelectors.hasSoldTickets())
			.pipe(takeUntil(this.destroyed$))
			.subscribe((hasSoldTickets) => {
				this.hasSoldTickets = hasSoldTickets;
			});

		this.store
			.select(eventSelectors.currencies())
			.pipe(
				takeUntil(this.destroyed$),
				map((curr: Currency[]) =>
					curr.map(({ iso: code, id, defaultCommission, defaultServiceFee }) => ({
						value: {
							id,
							code,
							defaultCommission,
							defaultServiceFee,
						},
						title: `${MoneyPresenter.getCurrencyName(code)}`,
					}))
				)
			)
			.subscribe((currencies: EventCurrency[]) => {
				this.currencies = currencies;
			});
	}

	initialiseFormSuccessActions() {
		this.actions$.pipe(ofType(TicketsActionsConstant.MARK_TICKETS_AS_SOLD_OUT_SUCCESS), takeUntil(this.destroyed$)).subscribe(() => {
			if (this.form) {
				this.form.get('capacity').setValue(0);
			}
		});
	}

	handleStopSales() {
		this.dialog
			.open(StopSalesModalComponent, {
				data: {
					isMobile: this.isMobile,
				},
				panelClass: this.isMobile ? 'g-full-page-dialog' : 'g-standard-dialog',
			})
			.afterClosed()
			.subscribe((result: { endTicketSales: boolean; soldOut: boolean }) => {
				if (result) {
					if (result.endTicketSales) {
						this.store.dispatch(new StopTicketSales({ eventId: this.eventId }));
					}
					if (result.soldOut) {
						this.store.dispatch(new MarkAsSoldOut({ eventId: this.eventId }));
					}
				}
			});
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}

	isFormDeactivatable(): boolean | Observable<boolean> {
		return !this.form || this.form.pristine;
	}

	deactivationFallback(): Observable<boolean> {
		this.dialog
			.open(ConfirmationModalComponent, {
				data: {
					title: 'Unsaved Changes',
					text: 'Are you sure you want to leave this page? Any unsaved changes will be discarded.',
					buttonText: 'LEAVE',
					centerText: true,
					isMobile: this.isMobile,
				},
				panelClass: 'g-standard-dialog',
			})
			.afterClosed()
			.subscribe((result) => {
				this.closeDeactivationModal(result);
			});
		return this.modalCallback.asObservable();
	}

	closeDeactivationModal(isConfirmed: boolean): void {
		this.modalCallback.next(isConfirmed);
	}
}
