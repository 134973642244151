import { AppAction, FailedAction } from '@app/models/action.model';
import { SchemeID } from '@app/models/dataSchema.model';
import { EventCollections } from '@app/models/event.model';


export enum CollectionsActionConstants {
	GET_EVENT_COLLECTIONS = 'GET_EVENT_COLLECTIONS',
	GET_EVENT_COLLECTIONS_SUCCESS = 'GET_EVENT_COLLECTIONS_SUCCESS',
	GET_EVENT_COLLECTIONS_FAILED = 'GET_EVENT_COLLECTIONS_FAILED',

	UPDATE_EVENT_COLLECTIONS = 'UPDATE_EVENT_COLLECTIONS',
	UPDATE_EVENT_COLLECTIONS_SUCCESS = 'UPDATE_EVENT_COLLECTIONS_SUCCESS',
	UPDATE_EVENT_COLLECTIONS_FAILED = 'UPDATE_EVENT_COLLECTIONS_FAILED',
}

const constants = CollectionsActionConstants;

export class GetEventCollections extends AppAction <{
	id: SchemeID;
}> {
	readonly type = constants.GET_EVENT_COLLECTIONS;
}

export class GetEventCollectionsSuccess extends AppAction<{
	id: SchemeID;
	collections: EventCollections;
}> {
	readonly type = constants.GET_EVENT_COLLECTIONS_SUCCESS;
}

export class GetEventCollectionsFailed extends FailedAction {
	readonly type = constants.GET_EVENT_COLLECTIONS_FAILED;
}

export class UpdateEventCollections extends AppAction <{
	id: SchemeID;
	collections: EventCollections;
}> {
	readonly type = constants.UPDATE_EVENT_COLLECTIONS;
}

export class UpdateEventCollectionsSuccess extends AppAction<{
	id: SchemeID;
	collections: EventCollections;
}> {
	readonly type = constants.UPDATE_EVENT_COLLECTIONS_SUCCESS;
}

export class UpdateEventCollectionsFailed extends FailedAction {
	readonly type = constants.UPDATE_EVENT_COLLECTIONS_FAILED;
}

export type Actions =
GetEventCollections
| GetEventCollectionsSuccess
| GetEventCollectionsFailed
| UpdateEventCollections
| UpdateEventCollectionsSuccess
| UpdateEventCollectionsFailed;
