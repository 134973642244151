import { AppState } from '@app/models/store.model';

export const organisers = () =>
	(state: AppState) => state.organiserProfiles.all.map(id => state.organiserProfiles.byId[id]);

export const organiser = (id: number) =>
	(state: AppState) => state.organiserProfiles && state.organiserProfiles.byId
		? state.organiserProfiles.byId[id]
		: null;

export const isFetching = () =>
	(state: AppState) => state.organiserProfiles.isFetching;

export const isFormModalOpen = () =>
	(state: AppState) => state.organiserProfiles.isFormModalOpen;

export const isDeleteModalFormOpen = () =>
	(state: AppState) => state.organiserProfiles.isLinkedProductModalOpen;
