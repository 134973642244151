import { FileActionsConstants as constants } from './file.actions.constants';
import { AppAction, FailedAction } from '@app/models/action.model';
import { SchemeID } from '@app/models/dataSchema.model';
import { EventImage } from '@app/models/event.model';
import { UserContactListItem } from '@app/models/user.model';

export class RemoveEventImage extends AppAction<{
	eventId: SchemeID;
	image?: EventImage;
}> {
	readonly type = constants.REMOVE_EVENT_IMAGE;
}

export class RemoveEventImageSuccess extends AppAction<{
	eventId: SchemeID;
	image: EventImage;
}> {
	readonly type = constants.REMOVE_EVENT_IMAGE_SUCCESS;
}

export class RemoveEventImageFailed extends FailedAction<RemoveEventImage> {
	readonly type = constants.REMOVE_EVENT_IMAGE_FAILED;
}

export class EventImageUploadSuccess extends AppAction<{
	eventId: SchemeID;
	image: EventImage;
}> {
	readonly type = constants.UPLOAD_EVENT_IMAGE_SUCCESS;
}

export class EventImageUploadFailed extends FailedAction {
	readonly type = constants.UPLOAD_EVENT_IMAGE_FAILED;
}

export class RemoveTicketImage extends AppAction<{
	eventId: SchemeID;
	image?: EventImage;
}> {
	readonly type = constants.REMOVE_TICKET_IMAGE;
}

export class RemoveTicketImageSuccess extends AppAction<{
	eventId: SchemeID;
	image: EventImage;
}> {
	readonly type = constants.REMOVE_TICKET_IMAGE_SUCCESS;
}

export class RemoveTicketImageFailed extends FailedAction {
	readonly type = constants.REMOVE_TICKET_IMAGE_FAILED;
}

export class TicketImageUploadSuccess extends AppAction<{
	eventId: SchemeID;
	image: EventImage;
}> {
	readonly type = constants.UPLOAD_TICKET_IMAGE_SUCCESS;
}

export class TicketImageUploadFailed extends FailedAction {
	readonly type = constants.UPLOAD_TICKET_IMAGE_FAILED;
}

export class UploadContactsCSV extends AppAction<{
	file: File;
}> {
	readonly type = constants.UPLOAD_CONTACTS_CSV;
}

export class UploadContactsCSVSuccess extends AppAction<{
	contacts: UserContactListItem[];
}> {
	readonly type = constants.UPLOAD_CONTACTS_CSV_SUCCESS;
}

export class UploadContactsCSVFailed extends FailedAction {
	readonly type = constants.UPLOAD_CONTACTS_CSV_FAILED;
}

export type Actions =
	RemoveEventImage
	| RemoveEventImageSuccess
	| RemoveEventImageFailed
	| EventImageUploadSuccess
	| EventImageUploadFailed
	| RemoveTicketImage
	| RemoveTicketImageFailed
	| RemoveTicketImageSuccess
	| TicketImageUploadFailed
	| TicketImageUploadSuccess
	| UploadContactsCSV
	| UploadContactsCSVSuccess
	| UploadContactsCSVFailed;
