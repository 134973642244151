<div [ngSwitch]="control.type">
	<app-small-text-field *ngSwitchCase="questionTypes.SmallTextField" [isMobile]="isMobile" [label]="control.question"
		[inputFormControl]="editTicketFormGroup.get(control.id.toString())"></app-small-text-field>

	<app-phone-input
		*ngSwitchCase="questionTypes.Cellphone || 'Cellphone'"
		[control]="editTicketFormGroup.get(control.id.toString())"
		[label]="control.question"
		inputClass="form-control"
		outerClass="l-edit-ticket"
		[isMobile]="isMobile"
		[required]="control.required"
		[syncErrorsWithDisplayControl]="false"
	>
	</app-phone-input>

	<app-large-text-field *ngSwitchCase="questionTypes.LargeTextField" [isMobile]="isMobile" [label]="control.question"
		[inputFormControl]="editTicketFormGroup.get(control.id.toString())"></app-large-text-field>

	<app-country-select *ngSwitchCase="questionTypes.Country" [isMobile]="isMobile" [label]="control.question"
		[inputFormControl]="editTicketFormGroup.get(control.id.toString())"></app-country-select>

	<app-date-field *ngSwitchCase="questionTypes.DateField" [isMobile]="isMobile" [label]="control.question"
		[inputFormControl]="editTicketFormGroup.get(control.id.toString())"
		[validations]="control.regularExpression" [isRequired]="control.required"></app-date-field>

	<app-drop-down-field *ngSwitchCase="questionTypes.DropDownList" [isMobile]="isMobile" [label]="control.question"
		[inputFormControl]="editTicketFormGroup.get(control.id.toString())"
		[control]="control"></app-drop-down-field>

	<app-check-box-field *ngSwitchCase="questionTypes.CheckBoxList" [isMobile]="isMobile" [label]="control.question"
		[inputFormControl]="editTicketFormGroup.get(control.id.toString())"
		[control]="control"></app-check-box-field>

	<app-address-field *ngSwitchCase="questionTypes.Address" [isMobile]="isMobile" [label]="control.question"
		[inputFormControl]="editTicketFormGroup.get(control.id.toString())"
		[control]="control" [index]="index" [isBuyerQuestion]="isBuyerQuestion"></app-address-field>

	<app-radio-button *ngSwitchCase="questionTypes.RadioButtonList" [isMobile]="isMobile" [label]="control.question"
		[inputFormControl]="editTicketFormGroup.get(control.id.toString())"
		[control]="control"></app-radio-button>

	<app-section-heading *ngSwitchCase="questionTypes.SectionHeading" [isMobile]="isMobile"
		[label]="control.question"></app-section-heading>

	<app-terms-and-conditions *ngSwitchCase="questionTypes.Waiver" [isMobile]="isMobile" [label]="control.question"
		[inputFormControl]="editTicketFormGroup.get(control.id.toString())" [control]="control" [isRequired]="control.required"></app-terms-and-conditions>

	<app-file-upload *ngSwitchCase="questionTypes.FileUpload" [isMobile]="isMobile" [label]="control.question"
		[inputFormControl]="editTicketFormGroup.get(control.id.toString())"
		[control]="control" [purchaseId]="purchaseId" [productId]="productId" [encrypt]="encrypt" (questionUpdated)="handleUpdatedQuestion()"></app-file-upload>
</div>
