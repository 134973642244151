import { Component, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SchemeID } from '@app/models/dataSchema.model';
import { HeaderContent } from '@app/models/shared';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { StoreService } from '@app/services/store/store.service';
import { InternalURLCreator } from '@app/services/url/url.dictionary';
import { GetEventRequestFieldServices } from '@app/store/actions/event/event.actions';
import { EventActionsConstants } from '@app/store/actions/event/event.actions.constants';
import { EVENT_ID_PARAM_KEY } from '@app/utils/consts';
import { Actions, ofType } from '@ngrx/effects';
import { take, takeUntil } from 'rxjs';
import * as eventSelectors from '@app/store/selectors/event.selector';

@Component({
	selector: 'app-event-field-services',
	templateUrl: './event-field-services.component.html',
	styleUrls: ['./event-field-services.component.sass'],
})
export class EventFieldServicesComponent {

	eventId: SchemeID;
	destroyed$: EventEmitter<void> = new EventEmitter<void>();
	isMobile = false;
	isPageLoading = true;
	ratesUrl: string;

	headerContent: HeaderContent = {
		breadCrumbs: [
			{
				routeName: 'On The Day',
				routeTo: () => InternalURLCreator.fieldServices(this.eventId),
			},
			{
				routeName: 'Field Services',
			},
		],
		title: 'Field Services',
		description: '',
	};

	constructor (
		private breakpointService: BreakpointService,
		private activatedRoute: ActivatedRoute,
		private store: StoreService,
		private actions$: Actions
	) {
		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});

		this.activatedRoute.parent.paramMap.pipe(take(1)).subscribe((params) => {
			this.eventId = +params.get(EVENT_ID_PARAM_KEY);
			this.store.dispatch(new GetEventRequestFieldServices({id: this.eventId}));
		});

		this.actions$.pipe(
			ofType(
				EventActionsConstants.GET_EVENT_REQUEST_FIELD_SERVICES_SUCCESS,
				EventActionsConstants.GET_EVENT_REQUEST_FIELD_SERVICES_FAILED),
			takeUntil(this.destroyed$)
		)
			.subscribe( () => {
				this.isPageLoading = false;
			});

		this.store.select(eventSelectors.eventRequestFieldServices(this.eventId)).pipe(takeUntil(this.destroyed$)).subscribe((url) => {
			if (url) {
				this.ratesUrl = url;
			}
		});
	}

	handleDownload() {
		window.open(this.ratesUrl, '_blank');
	}
}
