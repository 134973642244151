import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { emailValidator, requiredValidator } from '@app/shared/form-field/form-validators';

@Component({
	selector: 'app-resend-tickets-modal',
	templateUrl: './resend-tickets-modal.component.html',
	styleUrls: ['./resend-tickets-modal.component.sass'],
})
export class ResendTicketsModalComponent {
	emailControl: FormControl;

	constructor(
		public dialogRef: MatDialogRef<ResendTicketsModalComponent>,
		@Inject(MAT_DIALOG_DATA)
		public data: {
			isMobile: boolean;
			email: string;
		}
	) {}

	ngOnInit() {
		this.emailControl = new FormControl(this.data.email, [
			requiredValidator(),
			emailValidator(),
		]);
	}

	handleSendTickets() {
		this.dialogRef.close(this.emailControl.getRawValue());
	}

	handleCancel() {
		this.dialogRef.close(false);
	}
}
