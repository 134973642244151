import { Injectable } from '@angular/core';
import { FormService } from '@app/services/form/form.service';
import { ManageBookingInvoice, MyTickets } from '@app/models/user.model';
import { FormSchemeControls } from '@app/models/common.model';
import { SchemeID } from '@app/models/dataSchema.model';

@Injectable()
export class ManageBookingService {
	constructor(
		private formService: FormService
	) {}

	createEditInvoiceForm = (initialValues?: Partial<ManageBookingInvoice>) => {
		const formValues = new ManageBookingInvoice();

		const formConfig: Partial<FormSchemeControls<ManageBookingInvoice>> = this.formService.createFormControls({
			formValues,
			initialValues,
			validatorsConfig: {},
		});

		return this.formService.formBuilder.group(formConfig);
	};

	createBookingDateChange(scheduleId: SchemeID) {
		const formConfig = {
			scheduleId: scheduleId,
		};

		return this.formService.formBuilder.group(formConfig);
	}

	createFundraiserInvoiceForm = (initialValues?: Partial<MyTickets>) => {
		const formValues = new MyTickets();

		const formConfig: Partial<FormSchemeControls<MyTickets>> = this.formService.createFormControls({
			formValues,
			initialValues,
			validatorsConfig: {},
		});

		(formConfig.invoice) = this.formService.formBuilder.group({
			line1: initialValues.invoice['line1'],
			line2: initialValues.invoice['line2'],
			line3: initialValues.invoice['line3'],
			line4: initialValues.invoice['line4'],
			line5: initialValues.invoice['line5']});


		return this.formService.formBuilder.group(formConfig);
	};
}
