<!-- [files]="files" -->
<p-fileUpload
	[auto]="true"
	[multiple]="multiple"
	[ngClass]="getFormFieldClasses(this.offset)"
	class="file-upload"
	[chooseLabel]="uploadLabel"
	[accept]="accept"
	[customUpload]="true"
	(uploadHandler)="onCustomUpload($event)"
>
	<ng-template pTemplate="content">
		<div *ngIf="!ignoreImageTemplate">
			<div div *ngIf="imageFiles.url">
				<app-file-upload-image
				[url]='imageFiles.url'
				(remove)="onRemoved(file, 0)"
				></app-file-upload-image>
			</div>
		</div>
		<span class="ui-fileupload-msg" *ngIf="dropBoxMessage">{{dropBoxMessage}}</span>
		<div class='g-green fw-bold text-center' *ngIf='customSuccessMessage'>{{ customSuccessMessage }}</div>
	</ng-template>
	<ng-template pTemplate="file" *ngIf="accept=='image/*'"></ng-template>
</p-fileUpload>
