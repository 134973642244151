import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
	selector: 'app-mat-text-area-control-field',
	templateUrl: './mat-text-area-control-field.component.html',
	styleUrls: ['./mat-text-area-control-field.component.sass'],
})
export class MatTextAreaControlFieldComponent implements OnInit {
	@Input() outerClass = 'l-form-item';
	@Input() labelClass = 'g-form-label l-form-label';
	@Input() label = '';
	@Input() placeholder = '';
	@Input() inputClass = '';
	@Input() control: UntypedFormControl;
	@Input() tooltip = '';
	@Input() customId = '';
	@Input() required = false;
	@Input() readonly = false;
	@Input() showError = false;
	@Input() isDisabled = false;

	@Output() textAreaClick = new EventEmitter();

	ngOnInit(): void {
		if (this.isDisabled) {
			this.control.disable();
		}
	}

	handleTextAreaClick(){
		this.textAreaClick.emit();
	}

	isControlInvalid() {
		if (this.control) {
			return this.control.invalid && (this.control.dirty || this.control.touched);
		}
		return false;
	}

	getError(errors: { [key: string]: string }) {
		return  errors && errors[Object.keys(errors)[0]];
	}
}
