import { Component, OnInit, Input } from '@angular/core';
import { FormField } from '../form-field.model';
import MoneyPresenter from '../../../utils/MoneyPresenter';
import { NumberField } from '../number-field/number-field-core/number-field.model';

@Component({
	selector: 'app-price-field',
	templateUrl: './price-field.component.html',
	styleUrls: ['./price-field.component.sass', '../form-field.component.sass'],
})

export class PriceFieldComponent extends FormField implements OnInit, NumberField {
	@Input() currency: string;
	@Input() disabled: boolean;
	@Input() prefix = '';
	@Input() suffix = '';
	@Input() includeThousandsSeparator = true;
	@Input() thousandsSeparatorSymbol = ',';
	@Input() allowDecimal = true;
	@Input() decimalSymbol = '.';
	@Input() decimalLimit = 10;
	@Input() integerLimit = 10;
	@Input() allowNegative = false;
	@Input() allowLeadingZeroes = false;

	getCurrencySymbol() {
		if (this.currency) {
			return MoneyPresenter.getBasicSymbol(this.currency);
		}
		return null;
	}

	ngOnInit() {
		if (this.currency) {
			this.decimalLimit = MoneyPresenter.getDecimalDigits(this.currency);
		}
	}

	formatOnFocus = (value: string) => {
		if (value) {
			const decimals = value.split(this.decimalSymbol)[1];
			const basis = value.split(this.decimalSymbol)[0];
			let parseStop = false;
			const nonZeroDecimals = Number(decimals) && decimals
				.split('')
				.reverse()
				.map(el => {
					if (parseStop) {
						return el;
					}
					if (Number(el) > 0) {
						parseStop = true;
						return el;
					}
					return '';
				})
				.reverse()
				.join('');
			const decimalStr = Number(decimals) ? `${this.decimalSymbol}${nonZeroDecimals}` : '';
			return `${basis}${decimalStr}`;
		}
	};

	formatOnBlur = (value: string): string => {
		if (value) {
			const valueToProceed = value.toString();
			const decimals = valueToProceed.split(this.decimalSymbol)[1];
			const decimalLimit = MoneyPresenter.getDecimalDigits(this.currency);
			let decimalStr = '';

			if (!decimals && valueToProceed.indexOf(this.decimalSymbol) === -1) {
				decimalStr += this.decimalSymbol;
			}

			const decimalDiff = decimalLimit - (decimals ? decimals.length : 0);

			decimalStr += new Array(decimalDiff).fill('0').join('');

			return valueToProceed + decimalStr;
		}

		return '';
	};
}
