import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SchemeID } from '@app/models/dataSchema.model';
import { URLCreator } from '@app/services/url/url.dictionary';

@Component({
	selector: 'app-event-actions-menu-button',
	templateUrl: './event-actions-menu-button.component.html',
	styleUrls: ['./event-actions-menu-button.component.sass'],
	animations: [
		trigger('detailExpand', [
			state('collapsed', style({ height: '*', minHeight: '*', opacity: '0' })),
			state('expanded', style({ height: '*', opacity: '1' })),
			transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
		]),
		trigger('rotateIcon', [
			state('default', style({
				transform: 'rotate(0deg)',
			})),
			state('rotated', style({
				transform: 'rotate(180deg)',
			})),
			transition('default <=> rotated', animate('300ms ease-out')),
		]),
	],
})
export class EventActionsMenuButtonComponent {
	@Output() copyEventButton: EventEmitter<boolean> = new EventEmitter();
	@Output() postponeEventButton: EventEmitter<boolean> = new EventEmitter();
	@Output() cancelEventButton: EventEmitter<boolean> = new EventEmitter();
	@Output() copyEventLinkButton: EventEmitter<boolean> = new EventEmitter();
	@Output() editEventLinkButton: EventEmitter<boolean> = new EventEmitter();
	@Output() sendInvitesButton: EventEmitter<boolean> = new EventEmitter();
	@Output() qrCodeDownloadButton: EventEmitter<boolean> = new EventEmitter();

	@Input() eventId: SchemeID;
	@Input() isDraft: boolean;

	expanded = false;
	editDetails = '';

	get eventDetailsUrl() {
		return URLCreator.eventDetails(this.eventId);
	}

	handleEventActionCopy() {
		this.copyEventButton.emit(true);
	}

	handleEventActionPostpone() {
		this.postponeEventButton.emit(true);
	}

	handleEventActionCancel() {
		this.cancelEventButton.emit(true);
	}

	handleEventActionCopyLink() {
		this.copyEventLinkButton.emit(true);
	}

	handleEventActionEditLink() {
		this.editEventLinkButton.emit(true);
	}

	handleEventActionSendInvites() {
		this.sendInvitesButton.emit(true);
	}

	handleEventQRCodeDownload() {
		this.qrCodeDownloadButton.emit(true);
	}

	menuOpened() {
		this.expanded = true;
	}

	menuClosed() {
		this.expanded = false;
	}
}
