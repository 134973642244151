<div [class]="styleClass" [ngStyle]="style" [ngClass]="'ui-panelmenu ui-widget'">
	<ng-container *ngFor="let item of model;let f=first;let l=last;">
		<div class="ui-panelmenu-panel" [ngClass]="{'ui-helper-hidden': item.visible === false}">
			<div [ngClass]="{'ui-widget ui-panelmenu-header ui-state-default':true,'ui-corner-top':f,'ui-corner-bottom':l&&!item.expanded,
			'ui-state-active':item.expanded,'ui-state-disabled':item.disabled}" [class]="item.styleClass" [ngStyle]="item.style">
				<a *ngIf="!item.routerLink && !item.id" [href]="item.url||'#'" (click)="handleClick($event,item)"
					[attr.target]="item.target" [attr.title]="item.title" class="ui-panelmenu-header-link" [attr.id]="item.id"
					>
					<span
						*ngIf="item.items"
						class="ui-panelmenu-icon fa"
						[ngClass]="{'fa-angle-left':!item.expanded,'fa-angle-down':item.expanded}"
					></span>
					<span class="ui-menuitem-icon" [ngClass]="item.icon" *ngIf="item.icon"></span>
					<span class="ui-menuitem-text">{{item.label}}</span>
				</a>
				<a *ngIf="!item.routerLink && item.id" [href]="item.url||'#'" (click)="handleClick($event,item)"
					[attr.target]="item.target" [attr.title]="item.title" class="ui-panelmenu-header-link" [attr.id]="item.id"
					>
					<span
						*ngIf="item.items"
						class="ui-panelmenu-icon fa"
						[ngClass]="{'fa-angle-left':!item.expanded,'fa-angle-down':item.expanded}"
					></span>
					<span class="ui-menuitem-icon" [ngClass]="item.icon" *ngIf="item.icon"></span>
					<span class="ui-menuitem-text">{{item.label}}</span>
				</a>
				<a
					*ngIf="item.routerLink"
					[routerLink]="item.routerLink"
					[queryParams]="item.queryParams"
					[routerLinkActive]="'ui-state-active'"
					[routerLinkActiveOptions]=" item.routerLinkActiveOptions || {exact:false}"
					(click)="handleClick($event,item)"
					[attr.target]="item.target"
					[attr.title]="item.title"
					class="ui-panelmenu-header-link"
				>
					<span
						*ngIf="item.items"
						class="ui-panelmenu-icon fa"
						[ngClass]="{'fa-angle-left':!item.expanded,'fa-angle-down':item.expanded}"
					></span
					><span class="ui-menuitem-icon" [ngClass]="item.icon" *ngIf="item.icon"></span
					><span class="ui-menuitem-text">{{item.label}}</span>
				</a>
			</div>
			<div
				*ngIf="item.items"
				[class]="item.styleClass"
				[@rootItem]="item.expanded
					? {value: 'visible', params: {transitionParams: transitionOptions}}
					: {value: 'hidden', params: {transitionParams: transitionOptions}}"  (@rootItem.done)="onToggleDone()"
					[ngClass]="{ 'ui-panelmenu-content-wrapper': true, 'ui-panelmenu-content-wrapper-overflown': !item.expanded||animating }">
				<div class="ui-panelmenu-content ui-widget-content">
					<p-panelMenuSub
						[item]="item" [expanded]="true" [transitionOptions]="transitionOptions" class="ui-panelmenu-root-submenu"></p-panelMenuSub>
				</div>
			</div>
		</div>
	</ng-container>
</div>