import { Injectable } from '@angular/core';
import { FormService, FormConfig, ValidatorsConfig } from '@app/services/form/form.service';
import { BankAccountSelectionForm, DraftBankAccountForm } from '@app/models/ticket.model';
import { requiredValidator, conditionalPipe, matchFieldValidator, maxLength } from '@app/shared/form-field/form-validators';

@Injectable()
export class BankSelectionService {
	constructor(
		private formService: FormService
	) {}

	createAccountSelectionForm(initialValues?: Partial<BankAccountSelectionForm>) {
		const formValues = new BankAccountSelectionForm();

		const formConfig: FormConfig<BankAccountSelectionForm> =
			this.formService.createFormControls({
				formValues,
				initialValues,
				validatorsConfig: {},
			});

		return this.formService.formBuilder.group(formConfig);
	}

	createDraftAccountForm(initialValues?: Partial<DraftBankAccountForm>) {
		const formValues = new DraftBankAccountForm();

		const validatorsConfig: ValidatorsConfig<DraftBankAccountForm> = {
			bankId: [requiredValidator()],
			holder: [requiredValidator()],
		};

		const formConfig: FormConfig<DraftBankAccountForm> =
			this.formService.createFormControls({
				formValues,
				initialValues,
				validatorsConfig,
			});

		const dynamicValirators: ValidatorsConfig<DraftBankAccountForm> = {
			mobileNumber: [
				conditionalPipe(
					(
						(fC: FormConfig<DraftBankAccountForm>) => {
							const isMobileNumberValidation = fC.bankId.value && fC.bankId.value.isMobileMoney;
							if (isMobileNumberValidation) {
								fC.reenterMobileNumber.updateValueAndValidity();
							}
							return isMobileNumberValidation;
						}
					).bind(null, formConfig),
					requiredValidator()
				),
			],
			accountNumber: [
				conditionalPipe(
					(
						(fC: FormConfig<DraftBankAccountForm>) => {
							const isAccountNumberValidation = fC.bankId.value && !fC.bankId.value.isMobileMoney;
							if (isAccountNumberValidation) {
								fC.reenterAccountNumber.updateValueAndValidity();
							}
							return isAccountNumberValidation;
						}
					).bind(null, formConfig),
					requiredValidator(),
					maxLength(15, 'Bank account number is too long, please make sure you are not entering a credit card number.')
				),
			],
			branchCode: [
				conditionalPipe(
					(
						(fC: FormConfig<DraftBankAccountForm>) => fC.bankId.value && !fC.bankId.value.isMobileMoney
					).bind(null, formConfig),
					requiredValidator()
				),
			],
			reenterAccountNumber: [
				conditionalPipe(
					(
						(fC: FormConfig<DraftBankAccountForm>) => fC.bankId.value && !fC.bankId.value.isMobileMoney
					).bind(null, formConfig),
					matchFieldValidator(formConfig.accountNumber, 'Should match account number')
				),
			],
			reenterMobileNumber: [
				conditionalPipe(
					(
						(fC: FormConfig<DraftBankAccountForm>) => fC.bankId.value && fC.bankId.value.isMobileMoney
					).bind(null, formConfig),
					matchFieldValidator(formConfig.mobileNumber, 'Should match mobile number')
				),
			],
			branch: [
				conditionalPipe(
					(
						(fC: FormConfig<DraftBankAccountForm>) => fC.bankId.value && fC.bankId.value.branches &&
							fC.bankId.value.branches.length
					).bind(null, formConfig),
					requiredValidator()
				),
			],
		};

		this.formService.setDynamicValidators(formConfig, dynamicValirators);

		return this.formService.formBuilder.group(formConfig);
	}
}
