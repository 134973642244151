import { Component, Input, OnDestroy } from '@angular/core';
import { differenceWith } from 'lodash';
import { RemoveNotification } from '@app/store/actions/notification/notification.actions';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Notification, NotificationType } from '@app/models/notification.model';
import { StoreService } from '@app/services/store/store.service';
import { notifications as notificationsSelector } from '@app/store/selectors/notification.selector';
import { Observable, Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MobileNotificationComponent } from '../mobile-notification/mobile-notification.component';

@Component({
	selector: 'app-notification',
	templateUrl: 'notification.component.html',
	styleUrls: ['./notification.component.sass'],
	animations: [
		trigger('flyInOut', [
			state('in', style({ opacity: 1, transform: 'translateX(0)' })),
			transition('void => *', [
				style({
					opacity: 0,
					transform: 'translateX(-100%)',
				}),
				animate('0.2s ease-in'),
			]),
			transition('* => void', [
				animate(
					'0.2s 0.1s ease-out',
					style({
						opacity: 0,
						transform: 'translateX(100%)',
					})
				),
			]),
		]),
	],
})
export class NotificationComponent implements OnDestroy {
	@Input() isMobile: boolean;
	msg$: Observable<Notification[]>;
	msg: Notification[] = [];
	msgSub: Subscription;
	constructor(private store: StoreService, private _snackBar: MatSnackBar) {
		this.msg$ = this.store.select(notificationsSelector());

		this.msgSub = this.msg$.subscribe((value) => {
			if (value.length > this.msg.length) {
				const diff = [...differenceWith(value, this.msg, (arg1, arg2) => arg1.id === arg2.id)];

				this.msg.push(...diff);
				if (this.isMobile) {
					diff.forEach((notification) => this.showSnackbar(notification));
				}
			} else {
				const diff = [...differenceWith(this.msg, value, (arg1, arg2) => arg1.id === arg2.id)];

				diff.forEach((diffEl) => {
					const index = this.msg.findIndex((el) => el.id === diffEl.id);

					this.msg.splice(index, 1);
				});
			}
		});
	}

	onClose(value: Notification) {
		this.store.dispatch(new RemoveNotification({ id: value.id }));
	}

	onRetry(value: Notification) {
		this.store.dispatch(new RemoveNotification({ id: value.id }));
		this.store.dispatch(value.action);
	}

	getNotificationClass(ntf: Notification) {
		return {
			'notification': true,
			[`notification__${ntf.type}`]: true,
		};
	}

	getNotificationIcon(ntf: Notification) {
		const type = {
			[NotificationType.SUCCESS]: 'check',
			[NotificationType.INFO]: 'info',
			[NotificationType.ERROR]: 'exclamation',
		};
		return {
			'fa': true,
			[`fa-${type[ntf.type]}`]: true,
		};
	}

	showSnackbar(notification: Notification) {
		this._snackBar.openFromComponent(MobileNotificationComponent, {
			duration: notification.delay,
			data: {
				...notification,
			},
			panelClass: `g-${notification.type}-snackbar`,
			horizontalPosition: 'center',
			verticalPosition: 'top',
		}).afterDismissed().subscribe((payload) => {
			if (payload.dismissedByAction) {
				this.onRetry(notification);
			} else {
				this.onClose(notification);
			}
		});
	}

	ngOnDestroy() {
		this.msgSub.unsubscribe();
	}
}
