<div *ngIf='!isOrdersLoading else ordersLoading' class='mt-0'>
	<div *ngIf="isEventOngoing">
		<app-schedule-selector 
			[scheduleItems]='scheduleItems'
			(SelectedScheduleOutput)='handleSelectedScheduleItem($event)' 
			id="schedule-selector"
		></app-schedule-selector>
	</div>
	<div class="d-flex flex-column pb-5">
		<div *ngIf='isOrdersFiltering' class='l-filtering'>
			<mat-spinner></mat-spinner>
		</div>
		<app-web-filter 
			[isMobile]="isMobile" 
			[showRangeDate]="true" [paymentStatuses]="paymentStatuses" 
			[searchPlaceholder]="searchPlaceholder"
			(filterOutput)='handleFilterOutput($event)'
			id="web-filter"
		></app-web-filter>
		<div class="l-table-container" *ngIf="orders.length; else noOrders">
			<table mat-table [dataSource]="orders" class='l-table web' matSort (matSortChange)="handleSorting($event)" id="order-table">
				<ng-container matColumnDef="DateAdded">
					<th mat-header-cell *matHeaderCellDef mat-sort-header id="date-added-header" class="l-min">Date</th>
					<td mat-cell *matCellDef="let order">{{ order.dateAdded | date:'dd MMM yyyy HH:mm':'UTC'}}</td>
				</ng-container>
				<ng-container matColumnDef="CartId">
					<th mat-header-cell *matHeaderCellDef id="payment-reference-header">Payment Reference</th>
					<td mat-cell *matCellDef="let order; let i = index" [id]="'payment-reference-' + (i+1)">{{ order.cartId }}</td>
				</ng-container>
				<ng-container matColumnDef="OrderId">
					<th mat-header-cell *matHeaderCellDef mat-sort-header id="order-id-header">Order Number</th>
					<td mat-cell *matCellDef="let order; let i = index" [id]="'order-id-' + (i+1)">{{ order.orderId }}</td>
				</ng-container>
				<ng-container matColumnDef="Email">
					<th mat-header-cell *matHeaderCellDef mat-sort-header id="order-email-header">Email </th>
					<td mat-cell *matCellDef="let order; let i = index" [id]="'order-email-' + (i+1)">{{ order.email }}</td>
				</ng-container>
				<ng-container matColumnDef="Amount">
					<th mat-header-cell *matHeaderCellDef mat-sort-header id="order-amount">Amount</th>
					<td mat-cell *matCellDef="let order; let i = index" [id]="'order-amount-' + (i+1)">{{ order.amount | currency:order.currency.iso:'symbol-narrow':'1.2-2'}}</td>
				</ng-container>
				<ng-container matColumnDef="Source">
					<th mat-header-cell *matHeaderCellDef id="order-source-header">Source</th>
					<td mat-cell *matCellDef="let order; let i = index" [id]="'order-source-' + (i+1)">{{ order.source }}</td>
				</ng-container>
				<ng-container matColumnDef="PaymentStatus">
					<th mat-header-cell *matHeaderCellDef id="order-status-header">Payment Status</th>
					<td mat-cell *matCellDef="let order; let i = index" [id]="'order-status-' + (i+1)">
						<div class="l-badge" [ngClass]="handleStatusClass(order.paymentStatus)">
							{{ order.paymentStatus }}
						</div>
					</td>
				</ng-container>
				<ng-container matColumnDef="More">
					<th mat-header-cell *matHeaderCellDef id="order-redirect-header"></th>
					<td mat-cell *matCellDef="let order; let i = index" [id]="'order-redirect-' + (i+1)">
						<div *ngIf="!isMobile">
							<mat-icon> chevron_right </mat-icon>
						</div>
					</td>
				</ng-container>
				<tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
				<tr 
					mat-row *matRowDef="let order; columns: displayColumns;" 
					(click)="handleOrderDetailsRedirect(order)" 
					[id]="'order-click-' + order.orderId"
				>
				</tr>
			</table>
		</div>
		<mat-paginator 
			#ordersPaginator [length]="ordersLength"
			[pageSizeOptions]="pageSizeOptions" [pageIndex]="pageIndex" 
			(page)="handlePagination($event)" id="paginator"
		></mat-paginator>
		<ng-template #noOrders>
			<div *ngIf="firstDispatch else noMatch">
				<div class='l-empty-card'>
					<div class="l-empty-state" id="no-orders">
						<h3 class="mb-3">You currently have no orders for your event.</h3>
					</div>
				</div>
			</div>
			<ng-template #noMatch>
				<div class="l-empty-state" id="no-orders-for-filter">
					<h3 class="mb-3">Nothing to see here...</h3>
					<div class="mb-3">Nothing matches your search or filters</div>
				</div>
			</ng-template>
		</ng-template>
	</div>
</div>
<ng-template #ordersLoading>
	<div class="l-loading">
		<mat-spinner></mat-spinner>
	</div>
</ng-template>
