import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EarnCommissionComponent } from './pages/earn-commission/earn-commission.component';
import { SharedModule } from '@app/shared/shared.module';
import { AngularMaterialModule } from '@app/shared/angular-material.module';
import { PromoterNetworksComponent } from './pages/promoter-networks/promoter-networks.component';
import { ReferralsComponent } from './pages/referrals/referrals.component';
import { PromoterNetworkDetailsComponent } from './pages/promoter-network-details/promoter-network-details.component';
import { BankInfoBarComponent } from './components/bank-info-bar/bank-info-bar.component';
import { LinkBankBannerComponent } from './components/link-bank-banner/link-bank-banner.component';

@NgModule({
	declarations: [
		EarnCommissionComponent,
  		PromoterNetworksComponent,
		ReferralsComponent,
		PromoterNetworkDetailsComponent,
		BankInfoBarComponent,
		LinkBankBannerComponent,
	],
	imports: [
		CommonModule,
		SharedModule,
		AngularMaterialModule,
	],
})
export class EarnCommissionModule { }
