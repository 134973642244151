<div class="l-container">
	<div
		*ngIf="!isSecondaryPage; else mobileSubMenu"
		class='l-main-header'
	>
		<a
			href="/"
			class="header-logo-link"
		>
			<img
				class="header-logo"
				[src]="QUICKET_LOGO"
				alt="logo"
			>
		</a>
		<button
			*ngIf="currentNavItem"
			class='g-primary-button l-nav-button'
			(click)="handleNavMenu()"
		>
			<img
				[src]="currentNavItem.iconPath + '-active.svg'"
				class='l-icon'
			>{{currentNavItem.title}}
		</button>
	</div>
	<ng-template #mobileSubMenu>
		<div class="d-flex mt-3 w-100">
			<mat-icon
				id="mobile-navigate-back"
				class='ms-2 g-pointer'
				(click)='handleNavigateBack()'
			>arrow_back</mat-icon>
			<h3 class="mb-3 m-auto">{{currentSubNavItem.title}}</h3>
		</div>
	</ng-template>
</div>