<div class="l-container">
	<div class="l-header">
		<h3 class="l-title" [ngClass]="{ 'mobile': data.isMobile }">{{ data.modalTitle }}</h3>
		<p class="l-content" [ngClass]="{ 'mobile': data.isMobile }">{{ data.modalDescription }}</p>
	</div>
	<form #promoterNetworkForm="ngForm">
		<div class="l-form">
			<div class="l-form-field-container">
				<label class="g-form-label"> Promoter Network Name <span class="g-orange"> * </span> </label>
				<input #networkNameInput="ngModel" id='network-name-input' class="form-control" [(ngModel)]="name" name="networkName" required />
				<div *ngIf="networkNameInput.invalid && (networkNameInput.dirty || networkNameInput.touched)">
					<div class="g-error-input" *ngIf="networkNameInput.errors.required">*Network name is required.</div>
					<div class="g-error-input" *ngIf="networkNameInput.errors.invalidNetworkName">*Network name cannot be longer than 100 characters.</div>
				</div>
			</div>

			<div class="l-form-field-container">
				<label class="g-form-label"> Referral Amount
					<span class="g-green">%</span>
					<span class="g-orange"> * </span>
					<mat-icon
							class="l-tooltip-icon"
							matTooltip="Set a reward for promoters as a percentage of ticket sales driven by the promoter.
								Promoter rewards will be deducted from your proceeds, and will be paid out automatically to promoters after your event."
							matTooltipPosition="right"
							#referralAmntTooltip="matTooltip"
							(click)="referralAmntTooltip.toggle()"
						>info</mat-icon>
				</label>
				<input #referralAmountInput="ngModel" id='referral-amount-input' type="number" min="0" max="100" class="form-control"
					[(ngModel)]="referralAmountPercentage" name="referralAmount" [disabled]="!data.canEditReferralAmount" required />
				<div *ngIf="referralAmountInput.invalid && (referralAmountInput.dirty || referralAmountInput.touched)">
					<div class="g-error-input" *ngIf="referralAmountInput.errors.required">*Referral amount is required.</div>
					<div class="g-error-input" *ngIf="referralAmountInput.errors.min">*Referral amount can't be less than 0.</div>
					<div class="g-error-input" *ngIf="referralAmountInput.errors.max">*Referral amount can't be more than 100.</div>
				</div>
			</div>

			<div class="l-form-field-container">
				<label class="g-form-label"> Additional Notes </label>
				<textarea #additionalNotesInput="ngModel" id='additional-notes-input' class="l-form-field form-control" [(ngModel)]="additionalNotes" name="additionalNotes" maxlength="4000"></textarea>
			</div>
		</div>
		<div class="l-switch-container">
			<mat-slide-toggle class="standard-toggle" id="open-to-public-toggle" [(ngModel)]="openToPublic" name="openToPublic"></mat-slide-toggle>
			<label class="g-form-label l-label"> Open to the public?
				<mat-icon
					class="l-tooltip-icon"
					matTooltip="Public promoter networks allow members of the public to discover your promoter network on Quicket's public promoter networks directory.
						If not public, people will need a link to join your network."
					matTooltipPosition="right"
					#isPublicTooltip="matTooltip"
					(click)="isPublicTooltip.toggle()"
				>info</mat-icon>
			</label>
		</div>
	</form>
	<div class="l-note-container">
		<img src="./assets/icons/info-icon.svg" alt="Info Icon" class="l-info-icon">
		<p class="l-note">Note that the referral amount cannot be changed once tickets have been sold by promoters.</p>
	</div>
	<hr style="width:90%; margin: 0 auto;">
	<div class="l-fine-print-container">
		<p>By creating or editing a promoter network, you agree to the Quicket terms and conditions and understand that
		promoters will be paid the referral amount specified out of your ticket sales proceeds for sales that they
		generate.</p>
	</div>
	<div class="l-two-buttons-container" *ngIf='!data.isMobile; else mobileButton'>
		<button class="g-secondary-button" id="cancel-button" (click)="handleClose()">CANCEL</button>
		<button class="g-primary-button" [class.disabled]='promoterNetworkForm.invalid' id="save-button" (click)="handleSave()" [disabled]="promoterNetworkForm.invalid">SAVE</button>
	</div>
	<ng-template #mobileButton>
		<div class="l-button-container">
			<button class="g-secondary-button" id="save-button-mobile" (click)="handleBack()">BACK</button>
			<button class="g-primary-button" [class.disabled]='promoterNetworkForm.invalid' id="save-button-mobile" (click)="handleSave()" [disabled]="promoterNetworkForm.invalid">SAVE</button>
		</div>
	</ng-template>
</div>
