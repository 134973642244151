import { Component, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserActiveEvents } from '@app/models/event.model';
import { SelectFieldOption } from '@app/shared/form-field/select-field/select-field.model';

@Component({
	selector: 'app-redirect-finished-event-modal',
	templateUrl: './redirect-finished-event-modal.component.html',
	styleUrls: ['./redirect-finished-event-modal.component.sass'],
})
export class RedirectFinishedEventModalComponent {
	form: UntypedFormGroup;
	options: SelectFieldOption[];
	isLoading = true;

	constructor(
		public dialogRef: MatDialogRef<RedirectFinishedEventModalComponent>,

		@Inject(MAT_DIALOG_DATA) public data: {
			events: UserActiveEvents[];
			currentlySelected?: number;
			isMobile: false;
		}
	) { }

	ngOnInit() {
		this.form = new UntypedFormGroup({
			productId: new UntypedFormControl(null, Validators.required),
		});

		this.options = this.data.events.map((event): SelectFieldOption  => ({
			value: Number(event.id),
			label: event.name,
		}));

		this.options.unshift({
			value: 0,
			label: 'Unselect',
		});

		if (this.data.currentlySelected && this.data.currentlySelected !== 0) {
			this.form.get('productId').setValue(this.data.currentlySelected);
		}

		this.isLoading = false;
	}

	handleCancel() {
		this.dialogRef.close(null);
	}

	handleSave() {
		this.dialogRef.close(this.form.get('productId').value);
	}
}
