import { Component, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProfileLinkedProducts } from '@app/models/profile.model';
import { URLList } from '@app/models/url.model';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { URLCreator } from '@app/services/url/url.dictionary';
import { takeUntil } from 'rxjs';

@Component({
	selector: 'app-linked-products',
	templateUrl: './linked-products.component.html',
	styleUrls: ['./linked-products.component.sass'],
})
export class LinkedProductsComponent {

	isMobile = false;
	destroyed$: EventEmitter<void> = new EventEmitter<void>();
	links: Partial<Record<keyof URLList, string>>;

	constructor(
		private breakPointService: BreakpointService,
		public dialogRef: MatDialogRef<LinkedProductsComponent>,
		@Inject(MAT_DIALOG_DATA) public data: {
			isMobile: boolean;
			isLinkedProduct: boolean;
			linkedProducts: ProfileLinkedProducts[];
		}
	) { }

	ngOnInit() {
		this.breakPointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});
	}

	getManageProductLink = (product: ProfileLinkedProducts) => {
		this.links = {
			dashboard: URLCreator.dashboard(product.id, 2),
		};
		return this.links.dashboard;
	};

	handleRouting(product: ProfileLinkedProducts) {
		window.open(this.getManageProductLink(product));
	}

	handleClose() {
		this.dialogRef.close();
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
