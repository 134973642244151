import { formatNumber } from '@angular/common';
import { Component, Input } from '@angular/core';
import { EventDashboard } from '@app/models/event.model';
import { FileSaverService } from '@app/services/file-saver/file-saver.service';

@Component({
	selector: 'app-total-guests',
	templateUrl: './total-guests.component.html',
	styleUrls: ['./total-guests.component.sass'],
})
export class TotalGuestsComponent {
	@Input() totalGuests: number;
	@Input() maximumGuests: number;
	@Input() event: EventDashboard;
	@Input() isSeated: boolean;

	totalGuestsString: string;
	maxGuestsString: string;
	tooltip = '';
	isLoading = false;

	constructor(private fileSaverService: FileSaverService) {}

	ngOnInit() {
		this.tooltip = this.getTooltipInfo();
		this.getGuestString();
	}

	getTooltipInfo(): string {
		const endDate = new Date(this.event.endDate);

		if (this.event.isScheduledEvent) {
			if (endDate < new Date()) {
				// eslint-disable-next-line max-len
				return 'Shows the total guest count that attended the last schedule of the event alongside the schedule\'s maximum capacity, and allows you to export the guest list in a CSV format.';
			} else {
				// eslint-disable-next-line max-len
				return 'Shows the total guest count attending the next schedule of the event alongside the schedule\'s maximum capacity, and allows you to export the guest list in a CSV format.';
			}
		} else if (this.isSeated) {
			if (endDate < new Date()) {
				// eslint-disable-next-line max-len
				return 'Shows the total guest count that attended the event, and allows you to export the guest list in a CSV format.';
			} else {
				// eslint-disable-next-line max-len
				return 'Shows the total guest count attending the event, and allows you to export the guest list in a CSV format.';
			}
		} else {
			if (endDate < new Date()) {
				// eslint-disable-next-line max-len
				return 'Shows the total guest count that attended the event alongside the event\'s maximum capacity, and allows you to export the guest list in a CSV format.';
			} else {
				// eslint-disable-next-line max-len
				return 'Shows the total guest count attending the event alongside the event\'s maximum capacity, and allows you to export the guest list in a CSV format.';
			}
		}
	}

	getGuestString() {
		this.totalGuestsString = `${formatNumber(this.totalGuests, 'en_ZA')}`;
		if (this.isSeated) {
			this.maxGuestsString = '';
		} else {
			this.maxGuestsString = `${formatNumber(this.maximumGuests, 'en_ZA')}`;
		}
	}

	handleGuestListExport() {
		this.isLoading = true;
		this.fileSaverService.save({
			url: `/api/product/${this.event.id}/guestlist/download?scheduleItemId=0&reportType=csv`,
			fileName: `${this.event.name}-CSV-Report.csv`,
			onComplete: this.handleComplete.bind(this),
		});
	}

	handleComplete() {
		this.isLoading = false;
	}
}
