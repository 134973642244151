import { Injectable, NgModule } from '@angular/core';
import { ErrorCodes } from '@app/models/errorCode.model';
import { Subject } from 'rxjs';

@Injectable()
export class ErrorBusService {
	private errorStream = new Subject<any>();
	errorStream$ = this.errorStream.asObservable();

	constructor() {}

	sendError(error: Error) {
		this.errorStream.next(error);
	}

	getServerMessages(errorCodes: ErrorCodes[] = []) {
		return errorCodes.map(el => ErrorCodes[el]);
	}
}

@NgModule({
	providers: [
		ErrorBusService,
	],
}) export class ErrorBusModule {}
