import { NgModule } from '@angular/core';
import {
	EventControlPanelFeatureResolver
} from '@app/services/resolvers/event-dashboard-feature/event-dashboard-feature.resolver';
import { UserControlPanelFeatureResolver } from './user-dashboard-feature/user-dashboard-feature.resolver';
import { EventFeatureResolver } from './event-feature/event-feature.resolver';

@NgModule({
	providers: [
		EventControlPanelFeatureResolver,
		UserControlPanelFeatureResolver,
		EventFeatureResolver,
	],
})
export class ResolverModule { }
