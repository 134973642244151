import { Injectable } from '@angular/core';
import { FormService } from '../form/form.service';
import { DraftEventTicketGroupForm,
		 DraftTicketGroupTicketType,
		 DraftTicketGroupTicketTypeForm,
		 EventImage, EventTicketGroup,
		 EventTicketGroupTicketType } from '@app/models/event.model';
import { FormSchemeControls, FormValidators } from '@app/models/common.model';
import { ValidationMessage, requiredValidator } from '@app/shared/form-field/form-validators';
import { AbstractControl, UntypedFormArray, UntypedFormGroup, ValidatorFn } from '@angular/forms';

@Injectable()
export class TicketGroupsService {

	constructor(
		private formService: FormService
	) { }

	createTicketGroupForm(
		ticketTypes: EventTicketGroupTicketType[],
		initialValues?: Partial<EventTicketGroup>
	) {
		const formValues = new DraftEventTicketGroupForm();

		const validatorsConfig:
		FormValidators<Partial<DraftEventTicketGroupForm>> = {
			name: [requiredValidator()],
		};

		const ticketTypesInitialValues: DraftTicketGroupTicketType[] =
			ticketTypes.map(ticket => ({
				...ticket,
				checked: initialValues && initialValues.linkedTicketTypes.includes(ticket.id),
			}));

		const formConfig: Partial<FormSchemeControls<DraftEventTicketGroupForm>> =
		this.formService.createFormControls({
			formValues,
			initialValues: initialValues
				? {
					...initialValues,
					linkedTicketTypes: ticketTypesInitialValues,
				}
				: null,
			validatorsConfig,
		});

		const ticketTypesControls = ticketTypesInitialValues.map(ticket => this.createTicketGroupTicketTypeForm(ticket));
		formConfig.linkedTicketTypes = this.formService.formBuilder.array(ticketTypesControls, [this.notEmptyLinkedTickets()]); // eslint-disable-line max-len

		return this.formService.formBuilder.group(formConfig);
	}

	createTicketGroupTicketTypeForm(initialValues?: DraftTicketGroupTicketType) {
		const formValues = new DraftTicketGroupTicketTypeForm();

		const formConfig: Partial<FormSchemeControls<DraftTicketGroupTicketType>> =
		this.formService.createFormControls({
			formValues,
			initialValues,
			validatorsConfig: {},
		});

		return this.formService.formBuilder.group(formConfig);
	}

	notEmptyLinkedTickets = (msg?: string): ValidatorFn => (
		(control: AbstractControl): ValidationMessage => {
			if (!control.value || !(control.value as DraftTicketGroupTicketType[]).some(t => t.checked)) {
				return {
					notEmptyLinkedTickets: msg || 'At least one ticket should be selected',
				};
			}
			return null;
		}
	);

	getAvailableTicketTypes(ticketGroups: EventTicketGroup[], ticketTypes: EventTicketGroupTicketType[]) {
		return ticketTypes.filter(({ id }) => !ticketGroups.some(tG => tG.linkedTicketTypes.includes(id)));
	}

	prepareTicketGroupToSubmit = (
		draftTicketGroup: DraftEventTicketGroupForm
	): EventTicketGroup => ({
		...draftTicketGroup,
		linkedTicketTypes: draftTicketGroup.linkedTicketTypes
			.filter(t => t.checked)
			.map(t => t.id),
	});

	getLinkedTicketControls(form: UntypedFormGroup) {
		return form
			? (form.get('linkedTicketTypes') as UntypedFormArray).controls
			: [];
	}

	onImageRemove(form: UntypedFormGroup) {
		form.patchValue({
			imageString: null,
			thumbnail: null,
		});
		form.markAsDirty();
	}

	handleImageStringUpload(imageString: string, form: UntypedFormGroup){
		form.patchValue({
			imageString: imageString,
			thumbnail: null,
		});
		form.markAsDirty();
	}

	handleImageUpload(image: EventImage, form: UntypedFormGroup){
		form.patchValue({
			thumbnail: image.thumbUrl,
		});
		form.markAsDirty();
	}

}
