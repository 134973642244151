import { Routes } from '@angular/router';
import { ACLItem } from '@app/models/url.model';
import { EventControlPanelFeatureResolver } from '@app/services/resolvers/event-dashboard-feature/event-dashboard-feature.resolver';
import { HelpArticle } from '@app/models/help.model';
import { CanDeactivateEventPage } from '@app/services/guards/event.guard';
import { DashboardComponent } from '@app/features/organiser-hub/pages/dashboard/dashboard.component';
import { EventsComponent } from '@app/features/organiser-hub/pages/events/events.component';
import { OrganiserProfileComponent } from '@app/features/organiser-hub/pages/organiser-profile/organiser-profile.component';
import { MailAccountsComponent } from '@app/features/organiser-hub/pages/mail-accounts/mail-accounts.component';
import { OrganiserDetailsComponent } from '@app/features/organiser-hub/pages/organiser-details/organiser-details.component';
import { EventDashboardComponent } from '@app/features/events/dashboard/pages/event-dashboard/event-dashboard.component';
import { ManageEventComponent } from '@app/features/events/manage-event/pages/manage-event/manage-event.component';
import { EventDetailsComponent } from '@app/features/events/manage-event/pages/event-details/event-details.component';
import { ManageTicketsComponent } from '@app/features/events/tickets/pages/manage-tickets/manage-tickets.component';
import { PaymentComponent } from '@app/features/events/payment/pages/payment/payment.component';
import { MarketingComponent } from '@app/features/events/marketing/pages/marketing/marketing.component';
import { ReportsComponent } from '@app/features/events/reports/pages/reports/reports.component';
import { SettingsComponent } from '@app/features/events/settings/pages/settings/settings.component';
import { GuestListComponent } from '@app/features/events/guest-management/pages/guest-list/guest-list.component';
import { IntegrationsComponent } from '@app/features/events/settings/pages/integrations/integrations.component';
import { EventFeatureResolver } from '@app/services/resolvers/event-feature/event-feature.resolver';
import { PayoutsComponent } from '@app/features/events/payment/pages/payouts/payouts.component';
import { OrdersComponent } from '@app/features/events/guest-management/pages/orders/orders.component';
import { OrganiserProfileParentComponent } from '@app/features/organiser-hub/components/organiser-profile-parent/organiser-profile-parent.component'; // eslint-disable-line max-len
import { MailchimpIntegrationComponent } from '@app/features/organiser-hub/pages/mailchimp-integration/mailchimp-integration.component';
import { PromotersComponent } from '@app/features/events/marketing/pages/promoters/promoters.component';
import { RegistrationsComponent } from '@app/features/events/tickets/pages/registrations/registrations.component';
import { SendComplimentaryComponent } from '@app/features/events/guest-management/pages/send-complimentary/send-complimentary.component';
import { SendRSVPsComponent } from '@app/features/events/guest-management/pages/send-rsvps/send-rsvps.component';
import { SendSmsComponent } from '@app/features/events/guest-management/pages/send-sms/send-sms.component';
import { MerchandiseComponent } from '@app/features/events/tickets/pages/merchandise/merchandise.component';
import { PromotionalCodesComponent } from '@app/features/events/marketing/pages/promotional-codes/promotional-codes.component';
import { UserAccessComponent } from '@app/features/events/manage-event/pages/user-access/user-access.component';
import { PrintedTicketsComponent } from '@app/features/events/guest-management/pages/printed-tickets/printed-tickets.component';
import { CheckInReportComponent } from '@app/features/events/reports/pages/check-in-report/check-in-report.component';
import { ReviewsReportComponent } from '@app/features/events/reports/pages/reviews-report/reviews-report.component';
import { SalesReportComponent } from '@app/features/events/reports/pages/sales-report/sales-report.component';
import { VisitorReportComponent } from '@app/features/events/reports/pages/visitor-report/visitor-report.component';
import { MailChimpComponent } from '@app/features/events/settings/pages/mail-chimp/mail-chimp.component';
import { WidgetComponent } from '@app/features/events/settings/pages/widget/widget.component';
import { PromoterNetworksComponent } from '@app/features/events/marketing/pages/promoter-networks/promoter-networks.component';
import { TabbedContentComponent } from '@app/features/events/manage-event/pages/tabbed-content/tabbed-content.component';
import { LinkCampaignsComponent } from '@app/features/events/marketing/pages/link-campaigns/link-campaigns.component';
import { EventScannersComponent } from '@app/features/events/on-the-day/pages/scanners/event-scanners.component';
import { SendInvitesComponent } from '@app/features/events/dashboard/pages/send-invites/send-invites.component';
import { EventDesignComponent } from '@app/features/events/manage-event/pages/event-design/event-design.component';
import { DefinitionsComponent } from '@app/features/events/settings/pages/definitions/definitions.component';
import { EventFieldServicesComponent } from '@app/features/events/on-the-day/pages/event-field-services/event-field-services.component';
import { EventSettingsTicketingComponent } from '@app/features/events/settings/pages/event-settings-ticketing/event-settings-ticketing.component'; // eslint-disable-line max-len
import { TicketTypesComponent } from '@app/features/events/tickets/pages/ticket-types/ticket-types.component';
import { CancelPostponeComponent } from '@app/features/events/manage-event/pages/cancel-postpone/cancel-postpone.component';
import { ExportDataComponent } from '@app/features/events/reports/pages/export-data/export-data.component';
import { CopyEventComponent } from '@app/features/events/manage-event/pages/copy-event/copy-event.component';
import { UserRegistrationDetailsComponent } from '@app/features/events/tickets/pages/user-registration-details/user-registration-details.component';  // eslint-disable-line max-len
import { EventOrganiserComponent } from '@app/features/events/manage-event/pages/event-organiser/event-organiser.component';
import { TicketGroupsComponent } from '@app/features/events/tickets/pages/ticket-groups/ticket-groups.component';
import { EventSettingsComponent } from '@app/features/events/settings/pages/event-settings/event-settings.component';
import { WebhooksComponent } from '@app/features/events/settings/pages/webhooks/webhooks.component';
import { DirectDistributionComponent } from '@app/features/organiser-hub/pages/direct-distribution/direct-distribution.component';
import { TrackingScriptsComponent } from '@app/features/organiser-hub/pages/tracking-scripts/tracking-scripts.component';
import { TransferScheduleComponent } from '@app/features/events/guest-management/pages/transfer-schedule/transfer-schedule.component';
import { GuestManagementComponent } from '@app/features/events/guest-management/pages/guest-management/guest-management.component';
import { CheckoutQuestionsComponent } from '@app/features/events/tickets/pages/checkout-questions/checkout-questions.component';
import { OrderDetailsComponent } from '@app/features/events/guest-management/pages/order-details/order-details.component';
import { PromoterNetworkComponent } from '@app/features/events/marketing/pages/promoter-network/promoter-network.component';
import { EventAtTheDoorComponent } from '@app/features/control-panel/at-the-door/at-the-door.component';

const organiserProfileRoutes: Routes = [
	{
		path: 'details',
		component: OrganiserDetailsComponent,
		data: {
			pageTitle: 'Profile Details | Quicket',
		},
		resolve: [EventFeatureResolver],
		canDeactivate: [CanDeactivateEventPage],
	},
	{
		path: 'mailchimp',
		component: MailchimpIntegrationComponent,
		data: {
			pageTitle: 'Mailchimp | Quicket',
		},
		resolve: [EventFeatureResolver],
	},
];

export const organiserDashboardRoutes: Routes = [
	{
		path: '',
		component: DashboardComponent,
		data: {
			pageTitle: 'Organiser Dashboard | Quicket',
		},
		resolve: [EventFeatureResolver],
	},
	{
		path: 'events',
		component: EventsComponent,
		data: {
			pageTitle: 'Organiser Events | Quicket',
		},
		resolve: [EventFeatureResolver],
	},
	{
		path: 'profiles',
		component: OrganiserProfileComponent,
		data: {
			pageTitle: 'Organiser profiles | Quicket',
		},
		resolve: [EventFeatureResolver],
	},
	{
		path: 'mail-accounts',
		component: MailAccountsComponent,
		data: {
			pageTitle: 'Mail Accounts | Quicket',
		},
		resolve: [EventFeatureResolver],
	},
	{
		path: 'direct-distribution',
		component: DirectDistributionComponent,
		data: {
			pageTitle: 'Direct Distribution | Quicket',
		},
		resolve: [EventFeatureResolver],
	},
	{
		path: 'tracking-scripts',
		component: TrackingScriptsComponent,
		data: {
			pageTitle: 'Tracking Scripts | Quicket',
		},
		resolve: [EventFeatureResolver],
	},
	{
		path: 'profiles/:id',
		component: OrganiserProfileParentComponent,
		children: organiserProfileRoutes,
	},
];

export const eventRoutes: Routes = [
	{
		path: 'dashboard',
		component: EventDashboardComponent,
		data: {
			pageTitle: 'Event Dashboard | Quicket',
			featureKey: ACLItem.dashboard,
			helpArticle: null,
		},
		resolve: [EventFeatureResolver],
	},
	{
		path: 'checkout-questions',
		component: CheckoutQuestionsComponent,
		data: {
			pageTitle: 'Checkout Questions | Quicket',
			featureKey: ACLItem.checkoutQuestions,
			helpArticle: HelpArticle.EditCollectInfo,
		},
		resolve: [EventFeatureResolver],
		canDeactivate: [CanDeactivateEventPage],
	},
	{
		path: 'send-invites',
		component: SendInvitesComponent,
		data: {
			pageTitle: 'Send Invites | Quicket',
			featureKey: ACLItem.contactInvitations,
			helpArticle: null,
		},
		resolve: [EventFeatureResolver],
	},
	{
		path: 'manage-event',
		component: ManageEventComponent,
		data: {
			pageTitle: 'Manage Event | Quicket',
		},
		resolve: [EventFeatureResolver],
	},
	{
		path: 'design',
		component: EventDesignComponent,
		data: {
			pageTitle: 'Event Design | Quicket',
			featureKey: ACLItem.design,
			helpArticle: HelpArticle.EditDesign,
		},
		resolve: [EventFeatureResolver],
		canDeactivate: [CanDeactivateEventPage],
	},
	{
		path: 'manage-checkout',
		component: ManageTicketsComponent,
		data: {
			pageTitle: 'Manage Checkout | Quicket',
		},
		resolve: [EventFeatureResolver],
	},
	{
		path: 'payment-options',
		component: PaymentComponent,
		data: {
			pageTitle: 'Payments | Quicket',
			featureKey: ACLItem.paymentOptions,
			helpArticle: null,
		},
		resolve: [EventFeatureResolver],
	},
	{
		path: 'payouts',
		component: PayoutsComponent,
		data: {
			pageTitle: 'Payouts | Quicket',
			featureKey: ACLItem.payoutDetails,
			helpArticle: null,
		},
		resolve: [EventFeatureResolver],
	},
	{
		path: 'marketing',
		component: MarketingComponent,
		data: {
			pageTitle: 'Marketing | Quicket',
		},
		resolve: [EventFeatureResolver],
	},
	{
		path: 'promoters',
		component: PromotersComponent,
		data: {
			pageTitle: 'Promoters | Quicket',
			featureKey: ACLItem.promoters,
			helpArticle: null,
		},
		resolve: [EventFeatureResolver],
	},
	{
		path: 'promoter-networks',
		component: PromoterNetworksComponent,
		data: {
			pageTitle: 'Promoter Networks | Quicket',
			featureKey: ACLItem.promoterNetworks,
			helpArticle: null,
		},
		resolve: [EventFeatureResolver],
	},
	{
		path: 'promoter-networks/:networkId',
		component: PromoterNetworkComponent,
		data: {
			pageTitle: 'Promoter Network | Quicket',
			featureKey: ACLItem.promoterNetworks,
			helpArticle: null,
		},
		resolve: [EventFeatureResolver],
	},
	{
		path: 'link-campaigns',
		component: LinkCampaignsComponent,
		data: {
			pageTitle: 'Link Campaigns | Quicket',
			featureKey: ACLItem.linkCampaigns,
			helpArticle: HelpArticle.PromoteLinkCampaigns,
		},
		resolve: [EventFeatureResolver],
	},
	{
		path: 'scanners',
		component: EventScannersComponent,
		data: {
			pageTitle: 'Scanners | Quicket',
			featureKey: ACLItem.scanners,
			helpArticle: null,
		},
		resolve: [EventFeatureResolver],
	},
	{
		path: 'request-field-services',
		component: EventFieldServicesComponent,
		data: {
			pageTitle: 'Field Services | Quicket',
		},
		resolve: [EventFeatureResolver],
	},
	{
		path: 'at-the-door',
		component: EventAtTheDoorComponent,
		data: {
			pageTitle: 'At The Door | Quicket',
			featureKey: ACLItem.sellAtTheDoor,
		},
		resolve: [EventFeatureResolver],
	},
	{
		path: 'reports',
		component: ReportsComponent,
		data: {
			pageTitle: 'Reports | Quicket',
		},
		resolve: [EventFeatureResolver],
	},
	{
		path: 'settings',
		component: SettingsComponent,
		data: {
			pageTitle: 'Settings | Quicket',
		},
		resolve: [EventFeatureResolver],
	},
	{
		path: 'ticket-settings',
		component: EventSettingsTicketingComponent,
		data: {
			pageTitle: 'Ticket Settings | Quicket',
			featureKey: ACLItem.ticketSettings,
			helpArticle: HelpArticle.EditTickets,
		},
		resolve: [EventFeatureResolver],
		canDeactivate: [CanDeactivateEventPage],
	},
	{
		path: 'guest-management',
		component: GuestManagementComponent,
		data: {
			pageTitle: 'Guest Management | Quicket',
		},
		resolve: [EventFeatureResolver],
	},
	{
		path: 'guest-list',
		component: GuestListComponent,
		data: {
			pageTitle: 'Guest List | Quicket',
			featureKey: ACLItem.guestsList,
			helpArticle: null,
		},
		resolve: [EventFeatureResolver],
	},
	{
		path: 'integrations',
		component: IntegrationsComponent,
		data: {
			pageTitle: 'Integrations | Quicket',
		},
		resolve: [EventFeatureResolver],
	},
	{
		path: 'orders',
		component: OrdersComponent,
		data: {
			pageTitle: 'Orders | Quicket',
			featureKey: ACLItem.ordersList,
			helpArticle: null,
		},
		resolve: [EventFeatureResolver],
	},
	{
		path: 'send-complimentary',
		component: SendComplimentaryComponent,
		data: {
			pageTitle: 'Send Complimentary | Quicket',
			featureKey: ACLItem.complimentaries,
			helpArticle: null,
		},
		resolve: [EventFeatureResolver],
	},
	{
		path: 'send-RSVP',
		component: SendRSVPsComponent,
		data: {
			pageTitle: 'Send RSVPs | Quicket',
		},
		resolve: [EventFeatureResolver],
	},
	{
		path: 'send-SMS',
		component: SendSmsComponent,
		data: {
			pageTitle: 'Send SMSs | Quicket',
			featureKey: ACLItem.SMSyourGuests,
			helpArticle: null,
		},
		resolve: [EventFeatureResolver],
	},
	{
		path: 'transfer-schedules',
		component: TransferScheduleComponent,
		data: {
			pageTitle: 'Transfer Schedule | Quicket',
			featureKey: ACLItem.transferSchedule,
			helpArticle: null,
		},
		resolve: [EventFeatureResolver],
	},
	{
		path: 'merchandise',
		component: MerchandiseComponent,
		data: {
			pageTitle: 'Merchandise | Quicket',
		},
	},
	{
		path: 'promotional-codes',
		component: PromotionalCodesComponent,
		data: {
			pageTitle: 'Promo Codes | Quicket',
		},
	},
	{
		path: 'user-access',
		component: UserAccessComponent,
		data: {
			pageTitle: 'User Access | Quicket',
		},
	},
	{
		path: 'printed-tickets',
		component: PrintedTicketsComponent,
		data: {
			pageTitle: 'Printed Tickets | Quicket',
		},
	},
	{
		path: 'order-details',
		component: OrderDetailsComponent,
		data: {
			pageTitle: 'Order Details | Quicket',
			featureKey: ACLItem.ordersList,
		},
		resolve: [EventFeatureResolver],
	},
	{
		path: 'visitors-report',
		component: VisitorReportComponent,
		data: {
			pageTitle: 'Visitor Report | Quicket',
		},
	},
	{
		path: 'sales-report',
		component: SalesReportComponent,
		data: {
			pageTitle: 'Sales Report | Quicket',
		},
	},
	{
		path: 'check-in-report',
		component: CheckInReportComponent,
		data: {
			pageTitle: 'Check-in Report | Quicket',
		},
	},
	{
		path: 'reviews-report',
		component: ReviewsReportComponent,
		data: {
			pageTitle: 'Reviews Report | Quicket',
		},
	},
	{
		path: 'export-data',
		component: ExportDataComponent,
		data: {
			pageTitle: 'Export Data | Quicket',
		},
	},
	{
		path: 'mailchimp',
		component: MailChimpComponent,
		data: {
			pageTitle: 'Mailchimp | Quicket',
		},
	},
	{
		path: 'widget',
		component: WidgetComponent,
		data: {
			pageTitle: 'Widget | Quicket',
		},
	},
	{
		path: 'registrations',
		component: RegistrationsComponent,
		data: {
			pageTitle: 'Registrations | Quicket',
		},
		resolve: [EventFeatureResolver],
		canDeactivate: [CanDeactivateEventPage],
	},
	{
		path: 'registrations/:regId/user-registration-details',
		component: UserRegistrationDetailsComponent,
		data: {
			pageTitle: 'Registration Details | Quicket',
		},
		resolve: [EventFeatureResolver],
	},
	{
		path: 'details',
		component: EventDetailsComponent,
		data: {
			pageTitle: 'Event Details | Quicket',
			featureKey: ACLItem.eventDetails,
			helpArticle: HelpArticle.EditInformation,
		},
		resolve: [EventFeatureResolver],
		canDeactivate: [CanDeactivateEventPage],
	},
	{
		path: 'tabbed-content',
		component: TabbedContentComponent,
		data: {
			pageTitle: 'Tabbed Content | Quicket',
			featureKey: ACLItem.tabbedContent,
			helpArticle: HelpArticle.EditTabs,
		},
		resolve: [EventFeatureResolver],
		canDeactivate: [CanDeactivateEventPage],
	},
	{
		path: 'definitions',
		component: DefinitionsComponent,
		data: {
			pageTitle: 'Definitions | Quicket',
			featureKey: ACLItem.eventSettings,
			helpArticle: HelpArticle.EditSettings,
		},
		resolve: [EventFeatureResolver],
		canDeactivate: [CanDeactivateEventPage],
	},
	{
		path: 'tickets',
		component: TicketTypesComponent,
		data: {
			pageTitle: 'Ticket Types | Quicket',
			featureKey: ACLItem.ticketTypes,
			helpArticle: HelpArticle.EditTickets,
		},
		resolve: [EventFeatureResolver],
		canDeactivate: [CanDeactivateEventPage],
	},
	{
		path: 'details-settings',
		component: EventSettingsComponent,
		data: {
			pageTitle: 'Event Settings | Quicket',
			featureKey: ACLItem.eventDetailsSettings,
			helpArticle: HelpArticle.EditInformation,
		},
		resolve: [EventFeatureResolver],
		canDeactivate: [CanDeactivateEventPage],
	},
	{
		path: 'cancel',
		component: CancelPostponeComponent,
		data: {
			pageTitle: 'Cancel Event | Quicket',
		},
		resolve: [EventFeatureResolver],
	},
	{
		path: 'postpone',
		component: CancelPostponeComponent,
		data: {
			pageTitle: 'Postpone Event | Quicket',
		},
		resolve: [EventFeatureResolver],
	},
	{
		path: 'copy',
		component: CopyEventComponent,
		data: {
			pageTitle: 'Copy Event | Quicket',
			featureKey: ACLItem.eventDetails,
			helpArticle: null,
		},
		resolve: [EventControlPanelFeatureResolver],
	},
	{
		path: 'profile',
		component: EventOrganiserComponent,
		data: {
			pageTitle: 'Organiser Profile | Quicket',
			featureKey: ACLItem.eventDetails,
			helpArticle: null,
		},
		resolve: [EventControlPanelFeatureResolver],
		canDeactivate: [CanDeactivateEventPage],
	},
	{
		path: 'ticket-groups',
		component: TicketGroupsComponent,
		data: {
			pageTitle: 'Ticket groups | Quicket',
			featureKey: ACLItem.ticketGroups,
			helpArticle: HelpArticle.TicketGroupAdded,
		},
		resolve: [EventFeatureResolver],
		canDeactivate: [CanDeactivateEventPage],
	},
	{
		path: 'webhooks',
		component: WebhooksComponent,
		data: {
			pageTitle: 'Webhooks | Quicket',
			featureKey: ACLItem.widget,
		},
		resolve: [EventControlPanelFeatureResolver],
		canDeactivate: [CanDeactivateEventPage],
	},
	{
		path: '**',
		redirectTo: 'dashboard',
	},
];
