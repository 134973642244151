import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { EventSharedModule } from '@app/features/event-shared/event-shared.module';
import { EventAtTheDoorComponent } from './at-the-door.component';
import { EffectsModule } from '@ngrx/effects';
import { AtTheDoorEffects } from './at-the-door.effects';

@NgModule ({
	imports: [
		SharedModule,
		EventSharedModule,
		EffectsModule.forFeature([AtTheDoorEffects]),
	],
	declarations: [
		EventAtTheDoorComponent,
	],
	exports: [
		EventAtTheDoorComponent,
	],
})
export class EventAtTheDoorModule {}
