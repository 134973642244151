import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SchemeID } from '@app/models/dataSchema.model';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { SCTypes, StoreService } from '@app/services/store/store.service';
import { EVENT_ID_PARAM_KEY } from '@app/utils/consts';
import { Observable, filter, map, startWith, take, takeUntil } from 'rxjs';
import * as eventSelectors from '@app/store/selectors/event.selector';
import { EventCategory, EventDetails, EventFeatureOptions, EventFormat,
	EventImage, EventPart, Venue } from '@app/models/event.model';
import { FormCanDeactivate } from '@app/services/guards/event.guard';
import { EventDetailsService } from '@app/services/event-details/event-details.service';
import { ScheduleService } from '@app/services/schedule/schedule.service';
import { Actions, ofType } from '@ngrx/effects';
import { EventActionsConstants } from '@app/store/actions/event/event.actions.constants';
import { SelectFieldOption } from '@app/shared/form-field/select-field/select-field.model';
import { GetEventDetails, UpdateEvent } from '@app/store/actions/event/event.actions';
import { InternalURLCreator } from '@app/services/url/url.dictionary';
import { HeaderContent } from '@app/models/shared';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '@app/shared/modals/confirmation-modal/confirmation-modal.component';

@Component({
	selector: 'app-event-details',
	templateUrl: './event-details.component.html',
	styleUrls: ['./event-details.component.sass'],
})
export class EventDetailsComponent extends FormCanDeactivate implements OnInit, OnDestroy{
	form: UntypedFormGroup;
	dynamicValidatorsKeys: (string | { [x: string]: string[] })[];
	image: EventImage;
	isSalesDateWarningVisible: boolean;

	isMobile = false;
	eventId: SchemeID;

	isLoading = true;
	isUpdating = false;

	hasSoldTickets = false;

	eventFormats: SelectFieldOption<SchemeID>[];
	eventCategories: SelectFieldOption<SchemeID>[];
	eventSubcategories: { [key: number]: SelectFieldOption<SchemeID>[] };
	eventFeatureOptions: EventFeatureOptions[];
	previousVenuesOptions: Venue[];

	headerContent: HeaderContent = {
		breadCrumbs: [
			{
				routeName: 'Manage Event',
				routeTo: () => InternalURLCreator.manageEvent(this.eventId),
			},
			{
				routeName: 'Event Details',
			},
		],
		title: 'Event Details',
		description: `Customize and manage essential information about your event, ensuring that all key details are accurate and 
			up-to-date for your attendees.`,
	};

	disconnectStore: SCTypes.UnsubscribeFn;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor
	(
		private breakpointService: BreakpointService,
		private activatedRoute: ActivatedRoute,
		private store: StoreService,
		private eventDetailsService: EventDetailsService,
		private scheduleService: ScheduleService,
		private actions$: Actions,
		private dialog: MatDialog
	){
		super();
		this.disconnectStore = this.store
			.connect(
				new Map([
					[eventSelectors.eventDetails(), this.onEventUpdate],
				])
			);
	}

	ngOnInit() {
		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});
		this.activatedRoute.parent.paramMap.pipe(take(1)).subscribe((params) => {
			this.eventId = +params.get(EVENT_ID_PARAM_KEY);
		});

		this.store.dispatch(new GetEventDetails({ id: this.eventId }));

		this.store.select(eventSelectors.venues()).pipe(takeUntil(this.destroyed$)).subscribe((venues) => {
			if (venues){
				this.previousVenuesOptions = venues;
			}
		});

		this.store.select(eventSelectors.eventFeatureOptions()).pipe(takeUntil(this.destroyed$)).subscribe((featureOptions) => {
			if (featureOptions){
				this.eventFeatureOptions = featureOptions;
			}
		});

		this.store.select(eventSelectors.eventTypes())
			.pipe(
				takeUntil(this.destroyed$),
				startWith({ categories: [], formats: [], subcategories: [] }),
				filter((value: { formats: EventFormat[]; categories: EventCategory[] }) => !!value),
				map(value => ({
					formats: value.formats.map(({ id, name }) => ({
						value: id,
						label: name,
					})),
					categories: value.categories.map(({ id, name }) => ({
						value: id,
						label: name,
					})),
					subcategories: value.categories.reduce((acc, { subcategories, id }) => {
						acc[id] = subcategories && subcategories.length
							? subcategories.map(el => ({
								value: el.id,
								label: el.name,
							}))
							: null;
						return acc;
					}, {}),
				}))
			).subscribe((value: { categories: []; formats: []; subcategories: [] }) => {
				this.eventFormats = value.formats;
				this.eventCategories = value.categories;
				this.eventSubcategories = value.subcategories;
			});

		this.form = null;

		this.actions$
			.pipe(
				ofType(EventActionsConstants.UPDATE_EVENT_SUCCESS),
				takeUntil(this.destroyed$)
			)
			.subscribe(() => {
				if (this.form) {
					this.form.markAsPristine();
				}
				this.isUpdating = false;
			});

		this.actions$
			.pipe(
				ofType(EventActionsConstants.GET_EVENT_DETAILS_SUCCESS, EventActionsConstants.GET_EVENT_DETAILS_FAILED),
				take(1)
			)
			.subscribe(() => {
				this.isLoading = false;
			});
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
		this.disconnectStore();
		this.form = null;
	}

	onEventUpdate = (event: { image: EventImage; details: EventDetails }) => {
		if (event) {
			const { details, details: { venue, schedules, image } } = event;
			if (!this.form) {
				const { form, dynamicValidatorsKeys } = this.eventDetailsService.createEventDetailsForm(details);
				this.form = form;
				this.dynamicValidatorsKeys = dynamicValidatorsKeys;
			} else {
				this.eventDetailsService.checkAndUpdateVenueId(venue, this.form, 'venue.id');
				this.scheduleService.checkAndUpdateScheduleIds(schedules, this.form, 'schedules');
				this.scheduleService.checkAndUpdateScheduleDates(details, this.form);
			}
			this.form.get('image').setValue(image);
			this.image = image;
			if (event.details.eventEndDateChanged === true) {
				this.isSalesDateWarningVisible = true;
			}
			this.hasSoldTickets = event.details.hasSoldTickets;
		}
	};

	isFormDeactivatable(): boolean | Observable<boolean> {
		return !this.form || this.form.pristine;
	}

	deactivationFallback(): Observable<boolean> {
		this.dialog
			.open(ConfirmationModalComponent, {
				data: {
					title: 'Unsaved Changes',
					text: 'Are you sure you want to leave this page? Any unsaved changes will be discarded.',
					buttonText: 'LEAVE',
					centerText: true,
					isMobile: this.isMobile,
				},
				panelClass: 'g-standard-dialog',
			})
			.afterClosed()
			.subscribe((result) => {
				this.closeDeactivationModal(result);
			});
		return this.modalCallback.asObservable();
	}

	closeDeactivationModal(isConfirmed: boolean): void {
		this.modalCallback.next(isConfirmed);
	}

	proceedFormSubmit() {
		this.store.dispatch(new UpdateEvent({
			id: this.eventId,
			event: {
				details: this.form.getRawValue(),
			},
			eventPart: EventPart.DETAILS,
			isUpdating: true,
		}));
		this.isUpdating = true;
	}

	isSubmitAllowed() {
		return this.form && this.form.valid && !this.form.pristine && !this.isUpdating;
	}
}
