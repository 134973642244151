<app-my-account-header
	[isMobile]='isMobile'
	pageName="My Saved Events"
	(navigateBack)="handleNavigationBack()"
>
</app-my-account-header>
<div class="g-account-container">
	<div
		class="g-account-content"
		[ngClass]="{ 'mobile': isMobile, 'web': !isMobile }"
	>
		<app-web-content-header
			class="w-100"
			[headerContent]="headerContent"
			[longWidth]="true"
			[isMobile]="isMobile"
		></app-web-content-header>

		<div
				class="d-flex flex-column"
			>
				<app-search-bar
					[isMobile]="isMobile"
					[showFilter]="false"
					[numberAppliedFilters]="numberAppliedFilters"
					(searchAndFilter)="handleSavedFilterAndSearch($event)"
					(sortOption)="handleSort($event)"
					(openFilterBottomSheet)="handleBottomSheetFilter()"
					(mobileSearchTerm)="handleMobileSearchTerm($event)"
				></app-search-bar>
				<div *ngIf="!isEventsLoading else eventsLoading">
					<div
						[ngClass]="{ 'l-card-container-mobile': isMobile }"
						class='mt-4 l-card-container'
					>
						<ng-container *ngIf="filteredEvents.length > 0; else noEvents">
							<a
								*ngFor="let event of filteredEvents"
								class="l-card g-pointer g-flat-link"
								[href]="event.eventUrl"
								target="_blank"
							>
								<app-event-card
									[data]="event"
									[isMobile]="isMobile"
									class="l-card-content"
								></app-event-card>
							</a>
						</ng-container>
					</div>
				</div>
				<ng-template #eventsLoading>
					<div class="l-loading">
						<mat-spinner></mat-spinner>
					</div>
				</ng-template>
				<ng-template #noEvents>
					<div
						class="l-empty-state"
						*ngIf="currentEventFilter || unfilteredEvents.length > 0; else noUserEvents"
					>
						<h3 class="mb-3">Nothing to see here...</h3>
						<div class="mb-3">Nothing matches your search or filters</div>
					</div>
					<ng-template #noUserEvents>
						<div class="l-empty-state">
							<h3 class="mb-3">No Events</h3>
							<div class="mb-3 text-center px-2">To add events to your saved events list, browse our
								events and click the <span class='fw-bold g-green'>'Heart'</span> button on any event.
								We will remind you to come back to get
								a ticket a week before the event starts</div>
							<button
								class="g-primary-button"
								(click)="handleEventsPageNavigation()"
							>Browse
								Events</button>
						</div>
					</ng-template>
				</ng-template>
			</div>
	</div>
</div>