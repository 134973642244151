import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { MatTableDataSource } from '@angular/material/table';
import {
	Order,
	RefundPayee,
	TicketTypeForm,
	PaymentCollectionBy,
	OrderModalStep,
	RefundBankAccount,
	BankAccountFormControl
} from '@app/models/order.model';
import { OrderService } from '@app/services/orders/orders.service';
import { URLCreator } from '@app/services/url/url.dictionary';
import { takeUntil } from 'rxjs';

@Component({
	selector: 'app-cancel-tickets-table',
	templateUrl: './cancel-tickets-table.component.html',
	styleUrls: ['./cancel-tickets-table.component.sass'],
})
export class CancelTicketsTableComponent implements OnInit, OnChanges {
	@Input() isMobile: boolean;
	@Input() currentStep: OrderModalStep;
	@Input() refundFeePayableBy: RefundPayee;
	@Input() order: Order;

	@Output() tickets = new EventEmitter<{ ticketIds: number[]; totalAmount: number }>();
	@Output() bankDetails = new EventEmitter<{ bankAccount: RefundBankAccount; isValid: boolean }>();
	@Output() refunderSelected = new EventEmitter<RefundPayee>();

	isLoading = true;

	selectDisplayColumns: string[] = ['selectTicket', 'ticketNumber', 'barcode', 'ticketHolder', 'type', 'amount'];
	confirmDisplayColumns: string[] = ['ticketNumber', 'amount', 'refundable', 'refundFee', 'totalRefund', 'paymentMethod'];
	selection = new SelectionModel<FormGroup<TicketTypeForm>>(true, []);

	ticketData = new MatTableDataSource<FormGroup>();
	guestForms: FormGroup<TicketTypeForm>[];
	bankAccountForm: FormGroup<BankAccountFormControl>;

	refundPayeeEnum = RefundPayee;
	paymentCollectionByEnum = PaymentCollectionBy;
	orderModalStep = OrderModalStep;

	termsLink = URLCreator.terms();

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	get shouldDisplayRefundFeeOptions(): boolean {
		return (
			this.currentStep === OrderModalStep.CONFIRMATION &&
			(this.order.paymentCollectionBy !== PaymentCollectionBy.ORGANISER || !this.order.isTransferred)
		);
	}

	get displayColumns(): string[] {
		return this.currentStep === OrderModalStep.CONFIRMATION ? this.confirmDisplayColumns : this.selectDisplayColumns;
	}

	constructor(private orderService: OrderService) {}

	ngOnInit(): void {
		this.guestForms = this.orderService.createGuestForms(this.order);
		this.bankAccountForm = this.orderService.createBankRefundForm();
		this.ticketData.data = this.guestForms;

		this.selection.changed.pipe(takeUntil(this.destroyed$)).subscribe(() => {
			const selectedTicketsAmount = this.selection.selected.reduce((total, guest) => total + guest.get('amount').value, 0);
			this.tickets.emit({
				ticketIds: this.selection.selected.map((guest) => guest.get('ticketId').value),
				totalAmount: selectedTicketsAmount,
			});
		});

		this.bankAccountForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value: RefundBankAccount) => {
			this.bankDetails.emit({ bankAccount: value, isValid: this.bankAccountForm.valid });
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.currentStep && !changes.currentStep.isFirstChange()) {
			this.updateTableDataSource();
		}

		if (changes.currentStep && changes.currentStep.currentValue === OrderModalStep.BANK_DETAILS) {
			this.bankAccountForm.reset();
		}
		this.isLoading = false;
	}

	updateTableDataSource() {
		this.ticketData.data = this.currentStep === OrderModalStep.CONFIRMATION ? this.selection.selected : this.guestForms;
	}

	isAllSelected(): boolean {
		return this.selection.selected.length === this.getSelectableRows().length;
	}

	toggleAllRows(): void {
		if (this.isAllSelected()) {
			this.selection.clear();
		} else {
			this.selection.select(...this.getSelectableRows());
		}
	}

	handleRefundFeePayableBy(selection: MatRadioChange) {
		this.refunderSelected.emit(selection.value);
	}

	getRefund(price: number) {
		if (this.refundFeePayableBy === RefundPayee.BUYER) {
			const cancellationRefundFee = price * this.order.refundFee;
			return price - cancellationRefundFee;
		}
		return price;
	}

	getTotalRefundFee(): number {
		return this.calculateSelectionTotal('amount') * this.order.refundFee;
	}

	getTotalAmount(): number {
		if (this.currentStep === OrderModalStep.CONFIRMATION) {
			return this.calculateSelectionTotal('amount');
		}
		if (this.currentStep === OrderModalStep.DETAILS) {
			return this.calculateGuestTotal('amount');
		}
	}

	getTotalRefund(): number {
		const totalAmount = this.calculateSelectionTotal('refundAmount');
		const totalRefundFee = totalAmount * this.order.refundFee;
		return this.refundFeePayableBy === RefundPayee.BUYER ? totalAmount - totalRefundFee : totalAmount;
	}

	private calculateSelectionTotal(controlKey: string): number {
		return this.selection.selected.reduce((total, guest) => total + guest.get(controlKey).value, 0);
	}

	private calculateGuestTotal(controlKey: string): number {
		return this.guestForms.reduce((total, guest) => total + guest.get(controlKey).value, 0);
	}

	private getSelectableRows(): FormGroup[] {
		return this.ticketData.data.filter((ticket) => this.fromForm('isValid', ticket).value);
	}

	fromForm(controlKey: string, form: FormGroup): AbstractControl {
		if (form) {
			return form.get(controlKey);
		}
		return null;
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}
