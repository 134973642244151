<div [ngClass]="{outerClass, 'l-padding-bottom': paddingBottom}">
	<label
		*ngIf="label"
		[ngClass]="labelClass"
		class="l-label" 
	>
		{{label}} <span
			*ngIf="required"
			class="g-orange"
		> *</span>
		<mat-icon
			*ngIf="tooltip"
			class="l-tooltip"
			[matTooltip]="tooltip"
			matTooltipPosition="right"
			#numberFieldTooltip="matTooltip"
			(click)="numberFieldTooltip.toggle()"
		>
			info
		</mat-icon>
	</label>
	<input
		[formControl]="control"
		type="number"
		[placeholder]="placeholder"
		[min]="min"
		[max]="max"
		[disabled]="disabled"
		[ngClass]="{ 'l-error': (errorOutline && getError(control.errors)) }"
		class="form-control"
		[id]="customId"
	>
	<small
		class="g-error-input position-absolute"
		*ngIf="isControlInvalid() && !errorOutline"
	>
		*{{getError(control.errors)}}
	</small>
</div>