export function handleOrder() {
	return 0;
}

export function getCurrentPage(url: string) {
	const pathWithoutQueryString = url.split('?')[0];
	return pathWithoutQueryString.split('/').pop();
}

export function stringifyObjectValues(value: any): Record<string, string> {
	const result: Record<string, string> = {};
	for (const key in value) {
		if (value.hasOwnProperty(key)) {
			result[key] = value[key] !== null && value[key] !== undefined ? value[key].toString() : null;
		}
	}
	return result;
}

export function getInitials(text: string): string {
	const words = text.split(' ');
	const initials = [];
	for (let i = 0; i < words.length; i++) {
		if (words[i].toLowerCase() !== 'of') {
			initials.push(words[i].charAt(0).toUpperCase());
		}
		if (initials.length === 2) {
			break;
		}
	}
	return initials.join('');
}

export function urlSegmentToHeading(segment: string): string {
	return segment
		.split('-')
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ');
}

export function convertToIdFormat(string: string, prefix?: string): string {
	return prefix ? (prefix + '-' + string).toLowerCase().replace(/\s+/g, '-') : string.toLowerCase().replace(/\s+/g, '-');
}

export function convertToTitleCase(text: string): string {
	return text
		.replace(/([A-Z])/g, ' $1')
		.replace(/^./, (str) => str.toUpperCase())
		.split(' ')
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
		.join(' ');
}

export function trimWithEllipsis(text: string, maxLength: number): string {
	if (text.length > maxLength) {
		return text.slice(0, maxLength - 3) + '...';
	}
	return text;
}
