import { ConfigModel } from '@app/models/common.model';

const config: ConfigModel = {
	facebookAppId: '777774122688024',
	googleClientId: '210357309073-o9qpo5i5fh71n2lhops3fg84jglg5f6f.apps.googleusercontent.com',
	googleAPIKey: 'AIzaSyBbhD9efE44VoD4qTW31S5hvp0f19YKmDU',
	baseURL: 'http://quicket.local',
	baseAPI_URL: 'http://api.local/api',
	splitIOKey: 'p4cpmmkoencacvc0d00e9499uld8426ueran',
	isSplitIOLocal: false,
	algoliaKey: 'ec5eb43c5ca4fd2aa3883ae2c131f731',
	algoliaAppId: 'QAUIPDMSEL',
	sentryDSN: 'https://c68de2ba027e46398d88cdeb418367a6@o1026512.ingest.us.sentry.io/6729277',
	sentryEnv: 'dev',
	APIKey: '3171510c9eb84a7b70700652bcbd5cae',
	GA4MeasurementId: 'G-ZPZYXX3WW8',
};

export default config;
