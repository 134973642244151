<div class="l-container">
	<h3 *ngIf="!isLoading" class="mb-3 text-center">Your Refund Result</h3>
	<h3 *ngIf="isLoading" class="mb-3 text-center">Processing Your Refund</h3>
	<div *ngIf="!isLoading; else showLoading" class="mb-4">
		<pre class="g-subtitle l-text" [ngClass]="{ 'l-error': !isSuccess }">{{errorMessage}}</pre>
	</div>
	<ng-template #showLoading>
		<div class="l-loading">
			<mat-progress-bar mode="indeterminate"></mat-progress-bar>
		</div>
	</ng-template>
	<div class="l-buttons-container">
		<button id="close-refund-modal" class="g-primary-button" (click)="handleClose()" [disabled]="isLoading"
			[ngClass]="{ disabled: isLoading }">
			CLOSE
		</button>
	</div>
</div>
