import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SchemeID } from '@app/models/dataSchema.model';
import { HeaderContent } from '@app/models/shared';
import { BreakpointService } from '@app/services/breakpoint/breakpoint.service';
import { InternalURLCreator, URLCreator } from '@app/services/url/url.dictionary';
import { EVENT_ID_PARAM_KEY } from '@app/utils/consts';
import { take, takeUntil } from 'rxjs';

@Component({
	selector: 'app-cancel-postpone',
	templateUrl: './cancel-postpone.component.html',
	styleUrls: ['./cancel-postpone.component.sass'],
})
export class CancelPostponeComponent implements OnInit {
	url: string;
	eventId: SchemeID;
	type: string;
	isMobile = false;

	headerContent: HeaderContent;

	destroyed$: EventEmitter<void> = new EventEmitter<void>();

	constructor(private activatedRoute: ActivatedRoute, private breakpointService: BreakpointService) { }

	ngOnInit(): void {
		this.activatedRoute.parent.paramMap.pipe(take(1)).subscribe((params) => {
			this.eventId = +params.get(EVENT_ID_PARAM_KEY);
			this.type = this.activatedRoute.routeConfig.path;
			this.url = URLCreator.cancelPostpone(this.eventId, this.type);
			this.headerContent = {
				breadCrumbs: [
					{
						routeName: 'Manage Event',
						routeTo: () => InternalURLCreator.manageEvent(this.eventId),
					},
					{
						routeName: this.type,
					},
				],
				title: `${this.type} Event`,
			};
		});

		this.breakpointService.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
			this.isMobile = isMobile;
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}
}

