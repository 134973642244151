import { EventEntityState } from '@app/models/store.model';
import { Actions, WidgetActionConstants } from './widget.actions';
import { EventWidget } from '@app/models/event.model';

export const entityWidgetReducer = (initialState: EventEntityState['widget']) => (
	state = initialState,
	action: Actions
): EventWidget => {
	switch (action.type) {
		case WidgetActionConstants.GET_EVENT_WIDGET_SUCCESS:
			return action.payload.widget;
		default:
			return state;
	}
};
