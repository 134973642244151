<div
	class="l-container"
	[ngClass]="{ 'mobile': data.isMobile }"
>
	<div
		class="l-content"
		[ngClass]="{ 'mobile': data.isMobile }"
	>
		<i class="l-icon fa fa-exclamation-triangle fa-3x"></i>
		<div class="text-center">
			<div class="mb-1">
				You've just changed the end date{{ data.isScheduledEvent ? '(s)' : '' }} of your event
				{{ data.isScheduledEvent ? ' series' : ' to:' }}
			</div>
			<b *ngIf="!data.isScheduledEvent">{{ data.salesEndDate | date:'EEEE, d LLLL yyyy, HH:mm':'+OOOO' }}</b>
			<p class="mt-1 mb-0">Please note that you may need to change the sales end date of your tickets.</p>
			<p>to do this, follow these quick steps:</p>
		</div>
		<ol class="l-steps">
			<li>Click <b>'Update tickets now'</b> to go to your tickets page</li>
			<li>Expand your ticket(s)</li>
			<li>Update the sales end date</li>
			<li>Click <b>'Save'</b> on the tickets page</li>
		</ol>
		<p class="mb-4 text-center">
			Would you like to update your ticket sales end date now?
		</p>
	</div>
	<div
		class="l-buttons-container"
		[ngClass]="{ 'mobile': data.isMobile }"
	>
		<button
			id="cancel"
			class="g-secondary-button"
			[ngClass]="{ 'w-100': data.isMobile }"
			(click)="handleClose(false)"
		>
			CLOSE AND UPDATE LATER
		</button>
		<button
			id="update"
			class="g-primary-button"
			[ngClass]="{ 'w-100': data.isMobile }"
			(click)="handleClose(true)"
		>
			UPDATE TICKETS NOW
		</button>
	</div>

</div>