<div
	class="l-table-container"
	*ngIf="!isLoading; else showLoading"
>
	<table
		mat-table
		[dataSource]="ticketData"
		class='l-table web'
	>
		<ng-container matColumnDef="ticketNumber">
			<th
				id="ticket-number-table-header"
				class="l-medium-width"
				mat-header-cell
				*matHeaderCellDef
			>
				Ticket # </th>
			<td
				mat-cell
				*matCellDef="let ticket"
				[ngClass]="{ 'l-disabled': !fromForm('isValid', ticket).value }"
			>{{ fromForm('ticketId', ticket).value }}</td>
		</ng-container>
		<ng-container matColumnDef="barcode">
			<th
				id="barcode-table-header"
				class="l-medium-width"
				mat-header-cell
				*matHeaderCellDef
			>
				Barcode </th>
			<td
				mat-cell
				*matCellDef="let ticket"
				[ngClass]="{ 'l-disabled': !fromForm('isValid', ticket).value }"
			>{{ fromForm('barcode', ticket).value }}</td>
		</ng-container>
		<ng-container matColumnDef="ticketHolder">
			<th
				id="ticket-holder-table-header"
				class="l-large-width"
				mat-header-cell
				*matHeaderCellDef
			>
				Ticket Holder </th>
			<td
				mat-cell
				*matCellDef="let ticket"
				[ngClass]="{ 'l-disabled': !fromForm('isValid', ticket).value }"
			>{{ fromForm('ticketHolder', ticket).value }}</td>
		</ng-container>
		<ng-container matColumnDef="typeSelect">
			<th
				id="type-select-table-header"
				class="l-large-width"
				mat-header-cell
				*matHeaderCellDef
			>
				Type </th>
			<td
				mat-cell
				*matCellDef="let ticket"
			>
				<app-mat-select-control-field
					[options]="ticketOptions"
					[control]="fromForm('newTicketTypeId', ticket)"
					[customId]="fromForm('ticketId', ticket).value + '-type-select'"
					[disabled]="!fromForm('isValid', ticket).value"
					outerClass=""
					(selectedOption)="handleTicketTypeChange($event, ticket)"
				></app-mat-select-control-field>
			</td>
		</ng-container>
		<ng-container matColumnDef="oldType">
			<th
				id="old-type-table-header"
				class="l-large-width"
				mat-header-cell
				*matHeaderCellDef
			>
				Old Ticket Type </th>
			<td
				mat-cell
				*matCellDef="let ticket"
			>{{ fromForm('oldTicketType', ticket).value }}</td>
		</ng-container>
		<ng-container matColumnDef="newType">
			<th
				id="new-type-table-header"
				class="l-large-width"
				mat-header-cell
				*matHeaderCellDef
			>
				New Ticket Type </th>
			<td
				mat-cell
				*matCellDef="let ticket"
			>{{ getTicketTypeName(fromForm('newTicketTypeId', ticket).value) }}</td>
		</ng-container>
		<tr
			mat-header-row
			*matHeaderRowDef="displayColumns"
		></tr>
		<tr
			mat-row
			*matRowDef="let row; columns: displayColumns;"
		></tr>
	</table>
</div>
<ng-template #showLoading>
	<div class="l-loading">
		<mat-spinner></mat-spinner>
	</div>
</ng-template>