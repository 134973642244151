import { Component, Input, HostBinding, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

type LoadingSize = 'medium' | 'small' | 'tiny';

@Component({
	selector: '[loadingSp]',
	templateUrl: './loading-spinner.component.html',
	styleUrls: ['./loading-spinner.component.sass'],
})

export class LoadingSpinnerComponent {
	readonly sizes: { [K in LoadingSize]: number } = {
		'medium': 75,
		'small': 40,
		'tiny': 30,
	};

	@Input() loadingSp: boolean;
	@Input() loadingBackground = '#f3f3f4';
	@Input() spinnerBg: 'white' | 'black' = 'black';
	@Input() loadingText = 'Loading';
	@Input() loadingSize: LoadingSize = 'medium';

	@HostBinding('class.overlay-host-item') overlay = true;

	getCubeClass() {
		return {
			'spinner-path': true,
			[this.spinnerBg]: true,
		};
	}
}

@NgModule({
	declarations: [LoadingSpinnerComponent],
	exports: [LoadingSpinnerComponent],
	imports: [CommonModule],
})
export class LoadingSpinnerModule { }
