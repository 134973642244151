<div [ngClass]="{ 'input-group-mobile-large': isMobile, 'input-group-web-large': !isMobile }">
	<label class="g-form-label">
		<span [innerHTML]="label"></span>
		<span *ngIf='isRequired' class="g-orange"> *</span>
	</label>
	<mat-radio-group id="radio-button-group" [formControl]="inputFormControl" class="d-flex flex-column" (change)="onSelectionChange($event)">
		<div *ngFor="let option of options" class="d-flex align-items-center">
			<mat-radio-button [value]="option">
				{{ option }}
			</mat-radio-button>
		</div>
		<div *ngIf="this.control.addOtherOption" class="d-flex align-items-center">
			<mat-radio-button [value]="otherOptionControl.value">
				Other
			</mat-radio-button>
		</div>
	</mat-radio-group>
	<div *ngIf="otherEnabled" class="d-flex gap-2">			
		<div *ngIf="this.control.addOtherOption && !isDisabled" class="w-100">
			<label class="g-form-label">Please specify: </label>
			<div class="d-flex gap-2">
				<input id="custon-radio-input" type="text" class="form-control" [formControl]="otherOptionControl" />
			</div>
			<div *ngIf="inputFormControl.invalid && otherEnabled && otherOptionControl.value === ''">
				<div class="g-error-input">
					Please enter a valid answer.	
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="inputFormControl.invalid">
		<div class="g-error-input">
			<div *ngIf="inputFormControl.hasError('radioButtonRequired')">A selection is required.</div>
			<div *ngIf="inputFormControl.hasError('regexPattern')">{{ inputFormControl.getError('regexPattern') }}</div>
		</div>
	</div>
</div>
