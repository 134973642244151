<div class="l-container" [ngClass]="{ 'mobile': isMobile}">
    <div *ngIf="!isPreviousEventsLoading; else previousEventsLoading">
    <div *ngIf="hasInitialEvents; else noEvents" >
        <mat-expansion-panel [expanded]="!(attendees.data.length > 0)">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Previous Events
                </mat-panel-title>
            </mat-expansion-panel-header>
        <app-web-filter
        [isMobile]="isMobile"
        (filterOutput)="handleEventFilterOutput($event)"
		(buttonClick)="getEventsAttendees()"
        buttonText="GET ATTENDEES"
		[isButtonDisabled]='!selectedEvents.length || isPreviousContactsLoading'
        ></app-web-filter>
        <div *ngIf="events.filteredData.length; else noMatch" class="l-table-container">
                <table mat-table [dataSource]="events" class="l-table web" matSort>
                    <ng-container matColumnDef="checkbox">
                        <th mat-header-cell *matHeaderCellDef>
                            <div [ngClass]="{ 'd-flex justify-content-center flex-wrap': isMobile}">
                                <mat-checkbox id="select-all-checkbox" (change)="handleSelectAllEvents()" [checked]="isAllSelected()"></mat-checkbox>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let event"> <mat-checkbox  id="select-event" (change)="handleCheckboxChange(event)"
                            [checked]="isSelected(event)"></mat-checkbox> </td>
                        </ng-container>
                        <ng-container matColumnDef="eventName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Event Name</th>
                            <td id="event-name" mat-cell *matCellDef="let event">{{ event.eventName }} </td>
                        </ng-container>
                        <ng-container matColumnDef="eventDate">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
                            <td mat-cell id="event-date" *matCellDef="let event">
                                <div class='l-info'>
                                    {{ event.eventDate | formattedShortDatePipe }}
                                </div>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="numberOfContacts">
                    <th mat-header-cell *matHeaderCellDef> Number of Contacts </th>
                    <td mat-cell id="event-attendees" *matCellDef="let event"> {{ event.numberOfAttendees }}
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
                </table>
            </div>
                <div class="l-secondary-button">
                    <button
                        id="get-attendees-button"
                        class="g-secondary-button"
                        (click)="getEventsAttendees()"
                        [ngClass]="{ disabled: (!selectedEvents.length || isPreviousContactsLoading), 'w-100 mx-4': isMobile }"
                        [disabled]="!selectedEvents.length || isPreviousContactsLoading">
                        	<div *ngIf="!isPreviousContactsLoading else showLoading"> GET ATTENDEES </div>
                        	<ng-template #showLoading><mat-spinner class='l-button-spinner'></mat-spinner></ng-template>
                    </button>
        </div>
            </mat-expansion-panel>
    </div>
    </div>
    <div *ngIf="attendees.data.length" class='mt-4'>
    <mat-expansion-panel [expanded]="attendees.data.length">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Attendees
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
            <label class="g-form-label">Contact List Name</label>
            <div class="l-input-container">
                <input type="text" id="contact-list-name" #attendeesBlock class="form-control w-100 input-group" #contactListName="ngModel" [(ngModel)]="userContactsInviteBatch.contactListName" (ngModelChange)="isButtonDisabledCheck()" required>
            </div>
            <div class="g-error-input" *ngIf=" contactListName.touched || contactListName.dirty">
                <div *ngIf="contactListName.errors?.required">Contact list name is required.</div>
            </div>
        </div>
        <app-web-filter
            searchPlaceholder="search contacts"
            (filterOutput)="handleAttendeesFilterOutput($event)"
        ></app-web-filter>
        <div class="l-table-container">
            <div *ngIf="events.filteredData.length; else noMatch">
                <table mat-table [dataSource]="attendees" class="l-table web" #previousEventsAttendees>
                    <ng-container matColumnDef="checkbox">
                        <th mat-header-cell *matHeaderCellDef>
                            <div [ngClass]="{ 'd-flex justify-content-center flex-wrap': isMobile}">
                                <mat-checkbox id="select-all-attendees-checkbox" (change)="handleSelectAllAttendees()" [checked]="isAllAttendeesSelected()"></mat-checkbox>
                            </div>
                        </th>
                        <td id="select-attendee" mat-cell *matCellDef="let attendee"> <mat-checkbox (change)="handleAttendeeCheckboxChange(attendee)"
                                [checked]="isAttendeeSelected(attendee)"></mat-checkbox> </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef>Name</th>
                        <td mat-cell *matCellDef="let attendee">{{ attendee.name }} </td>
                    </ng-container>
                    <ng-container matColumnDef="surname">
                        <th mat-header-cell *matHeaderCellDef>Surname</th>
                        <td mat-cell *matCellDef="let attendee">{{ attendee.surname }} </td>
                    </ng-container>
                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef>Email</th>
                        <td mat-cell *matCellDef="let attendee">{{ attendee.email }} </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="attendeesDisplayColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: attendeesDisplayColumns;"></tr>
                </table>
            </div>
        </div>
    </mat-expansion-panel>
    </div>
</div>
<div class="l-sticky-bar">
	<app-footer-button
		id="send-invites-button"
		primaryText="SEND INVITES"
		[isLoading]="isButtonLoading"
		($primaryButton)="handleSendInvites()"
		[isDisabled]="isButtonDisabled || isButtonLoading"
		[isMobile]="isMobile"
		[fullWidth]="true"
		class="mt-3"
	></app-footer-button>
</div>
<ng-template #previousEventsLoading class="l-loading">
    <div class="l-loading">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>
<ng-template #noMatch>
    <div class="l-empty-state">
        <h3 class="mb-3">Nothing to see here...</h3>
        <div class="mb-3">Nothing matches your search</div>
    </div>
</ng-template>
<ng-template #noEvents>
    <div class='l-empty-card'>
        <div class="l-empty-state">
            <h3 class="mb-3">You currently have no previous events.</h3>
        </div>
    </div>
</ng-template>
