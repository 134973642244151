import { HostListener, Injectable, Directive } from '@angular/core';
import { CanDeactivate, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { Observable, Subject, of, Subscription } from 'rxjs';
import { StoreService } from '@app/services/store/store.service';
import { eventMetadata, activeEventId } from '@app/store/selectors/event.selector';
import { take, switchMap, map } from 'rxjs/operators';
import { GuardPayload } from '@app/models/common.model';
import { GetEventMetadataSuccess, GetEventMetadataFailed, GetEventMetadata } from '@app/store/actions/event/event.actions';
import { ofType, Actions } from '@ngrx/effects';
import { EventActionsConstants } from '@app/store/actions/event/event.actions.constants';
import { EventMetadata } from '@app/models/event-metadata.model';
import { SchemeID } from '@app/models/dataSchema.model';

@Directive()
export abstract class ComponentCanDeactivate {
	intentionSub: Subscription;
	abstract canDeactivate(): Observable<boolean> | boolean;

	@HostListener('window:beforeunload', ['$event'])
	unloadNotification($event: BeforeUnloadEvent) {
		const intention = this.canDeactivate();
		if (intention instanceof Observable) {
			if (this.intentionSub) {
				this.intentionSub.unsubscribe();
			}
			this.intentionSub = intention.subscribe(v => {
				if (!v) {
					$event.returnValue = true;
				}
			});
		} else {
			if (!intention) {
				$event.returnValue = true;
			}
		}
	}
}

@Directive()
export abstract class FormCanDeactivate extends ComponentCanDeactivate {
	modalCallback = new Subject<boolean>();
	isDeactivationModalOpen = false;
	abstract isFormDeactivatable(): Observable<boolean> | boolean;
	abstract deactivationFallback(): Observable<boolean>;
	abstract closeDeactivationModal(isConfirmed: boolean): void;

	canDeactivate() {
		return this.isFormDeactivatable();
	}
}

@Injectable()
export class CanDeactivateEventPage implements CanDeactivate<FormCanDeactivate> {

	canDeactivate(component: FormCanDeactivate) {
		const intention = component.canDeactivate();

		if (intention instanceof Observable) {
			return intention
				.pipe(
					switchMap(v => v ? of(true) : component.deactivationFallback())
				);
		}

		if (!intention) {
			return component.deactivationFallback();
		}

		return true;
	}
}

@Injectable()
export class CanActivateEventChild implements CanActivateChild {
	constructor (
		private storeService: StoreService,
		private action$: Actions
	) { }

	canActivateChild(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
		const { id } = route.parent.params;
		return this.storeService.select(eventMetadata(id))
			.pipe(
				take(1),
				switchMap((metadata: EventMetadata) => {
					const guardPayload: GuardPayload<GetEventMetadataSuccess | GetEventMetadataFailed> = {
						isReady: false,
						action: null,
					};

					this.storeService.dispatch(new GetEventMetadata({ id }));

					return this.action$.pipe(
						ofType(
							EventActionsConstants.GET_EVENT_METADATA_FAILED,
							EventActionsConstants.GET_EVENT_METADATA_SUCCESS
						),
						map((action: GetEventMetadataSuccess | GetEventMetadataFailed) => ({
							...guardPayload,
							action,
						}))
					);
				}),
				map(guardPayload => {
					/* eslint-disable-next-line max-len */
					const isSuccessAction = (guardPayload.action && guardPayload.action.type === EventActionsConstants.GET_EVENT_METADATA_SUCCESS);
					if (guardPayload.isReady || isSuccessAction) {
						return true;
					}

					return false;
				})
			);
	}
}

@Injectable()
export class CanActivateEvent implements CanActivate {
	constructor (
		private storeService: StoreService,
		private action$: Actions
	) { }

	canActivate(
		route: ActivatedRouteSnapshot,
		router: RouterStateSnapshot
	) {
		const { id } = route.params;

		return this.storeService.select(state => ({ metadata: eventMetadata(id)(state), active: activeEventId()(state) }))
			.pipe(
				take(1),
				switchMap(({ metadata, active }: { metadata: EventMetadata; active: SchemeID }) => {
					const guardPayload: GuardPayload<GetEventMetadataSuccess | GetEventMetadataFailed> = {
						isReady: !!metadata,
						action: null,
					};

					if ((metadata && metadata.id === active) || !id) {
						return of(guardPayload);
					}

					this.storeService.dispatch(new GetEventMetadata({ id }));

					return this.action$.pipe(
						ofType(
							EventActionsConstants.GET_EVENT_METADATA_FAILED,
							EventActionsConstants.GET_EVENT_METADATA_SUCCESS
						),
						map(action => ({
							...guardPayload,
							action,
						}))
					);
				}),
				map(guardPayload => {
					/* eslint-disable-next-line max-len */
					const isSuccessAction = (guardPayload.action && guardPayload.action.type === EventActionsConstants.GET_EVENT_METADATA_SUCCESS);
					if (guardPayload.isReady || isSuccessAction) {
						return true;
					}

					return true;
				})
			);
	}
}
