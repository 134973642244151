import { dashboard } from './dashboard.reducer';
import { events } from './events.reducer';
import { initialState } from './initialState';
import { Actions } from '@app/store/actions/organiser/organiser.actions';
import { OrganiserState } from '@app/models/store.model';
import { OrganiserActionsConstants } from '@app/store/actions/organiser/organiser.actions.constants';

const mailAccounts = (
	state = initialState.mailAccounts,
	action: Actions
): OrganiserState['mailAccounts'] => {
	switch (action.type) {
		case OrganiserActionsConstants.GET_MAIL_ACCOUNT_BY_USER_ID_SUCCESS:
		case OrganiserActionsConstants.ADD_MAIL_ACCOUNT_SUCCESS:
		case OrganiserActionsConstants.DELETE_MAIL_ACCOUNT_SUCCESS:
			return action.payload.mailAccounts;
		default:
			return state;
	}
};

const mailChimpConnection = (
	state = initialState.mailChimpConnection,
	action: Actions
): OrganiserState['mailChimpConnection'] => {
	switch (action.type) {
		case OrganiserActionsConstants.GET_MAILCHIMP_CONNECTION_SUCCESS:
		case OrganiserActionsConstants.POST_UNLINK_MAILCHIMP_SUCCESS:
			return {
				connected: action.payload.mailChimpConnection.connected,
				connectLink: action.payload.mailChimpConnection.connectLink,
			};
		default:
			return state;
	}
};

export const organiserHubReducer = {
	events,
	dashboard,
	mailAccounts,
	mailChimpConnection,
};
