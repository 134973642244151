import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { EventDomainAPI } from '@app/api/domains/event';
import { ofTypeExt, handleSuccessRequest, handleFailedRequest } from '@app/utils/Store';
import { GetPreviousVenues, GetPreviousVenuesSuccess, GetPreviousVenuesFailed } from '@app/store/actions/event/event.actions';
import { EventActionsConstants } from '@app/store/actions/event/event.actions.constants';
import { switchMap, catchError } from 'rxjs/operators';

@Injectable()
export class VenueEffects {
	constructor(private action$: Actions, private api?: EventDomainAPI) {}

	getPreviousVenues$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<GetPreviousVenues>(EventActionsConstants.GET_VENUES),
			switchMap(({ action, payload: { eventId } }) =>
				this.api.getPreviousVenues(eventId).pipe(
					handleSuccessRequest(
						({ payload: venues }) =>
							new GetPreviousVenuesSuccess({
								venues,
							})
					),
					catchError(
						handleFailedRequest(
							new GetPreviousVenuesFailed({
								msg: 'can\'t get venues',
								action,
							})
						)
					)
				)
			)
		)
	);
}
