<div [ngClass]="getFormFieldClasses(offset, 'color-picker-wrap')">
	<label class="g-form-label" *ngIf="label">
		{{label}}
		<span
			*ngIf="required"
			class="ui-required"
		>
			*
		</span>
		<i
			*ngIf="labelIcon"
			[class]="'fa fa-' + labelIcon"
			[pTooltip]="tooltip"
			[tooltipPosition]="tooltip ? tooltipPosition : null"
		></i>
	</label>
	<div class="color-input">
		<div (click)="toggle=!toggle" class="color-preview-wrap">
			<div class="color-preview" [style.background]="control.value"></div>
		</div>
		<input
			#input
			type="text"
			[readOnly]="true"
			[spellcheck]="false"
			[placeholder]="placeholder"
			[cpOutputFormat]="'hex'"
			[cpFallbackColor]="'#fff'"
			[cpPosition]="previewOnTop ? 'top' : 'bottom'"
			[(cpToggle)]="toggle"
			pInputText
			[formControl]="control"
			[colorPicker]="control.value"
			(colorPickerChange)="onChange($event)"
			id="color-input"
		>
	</div>
</div>
