import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { UserContactInfoForm } from '@app/models/event.model';

@Component({
	selector: 'app-event-user-contact-info',
	templateUrl: './user-contact-info.component.html',
	styleUrls: ['./user-contact-info.component.sass'],
})
export class EventUserContactInfoComponent {
	@Input() form: UntypedFormGroup;
	@Output() submit = new EventEmitter<UserContactInfoForm>();
	@Output() skip = new EventEmitter();

	onSubmit() {
		this.submit.emit(this.form.getRawValue());
	}

	onSkip() {
		this.skip.emit();
	}

	isSubmitDisabled() {
		if (this.form) {
			return this.form.invalid;
		}
		return false;
	}
}
