<app-my-account-header
	[isMobile]='isMobile'
	pageName="Transfer Ticket"
	(navigateBack)="handleNavigationBack()"
>
</app-my-account-header>
<div class="g-account-container">
	<div
		class="g-account-content h-100"
		[ngClass]="{ 'mobile': isMobile, 'web': !isMobile }"
	>
		<app-web-content-header
			*ngIf="!isMobile"
			class="w-100"
			[headerContent]="headerContent"
			[id]="purchaseId"
			[reference]="reference"
		></app-web-content-header>

		<div [ngClass]="{ 'l-mobile-description': isMobile }">
			<div class="mb-3">Below you can transfer tickets to another person. Please enter in the email address
				of the person receiving the tickets and then select which tickets your want to transfer.</div>
			<div class="mb-4">Please note: This action cannot be reversed. Please make sure you want to transfer the
				tickets
				before
				proceeding and that you have the <strong>correct email address.</strong>
			</div>
			<div
				*ngIf="onlyOneTransferableAllowed"
				class="l-status-message mb-4"
			>
				Once transferred these tickets cannot be transferred further
			</div>
		</div>
		<form #ticketTransferForm="ngForm" class="l-form">
			<div *ngIf="!isTicketFetchLoading; else mainLoader">
				<div *ngIf="!statusMessage; else showStatusMessage">
					<div class="g-double-input-container mb-4">
						<div
							[ngClass]="{ 'input-group-mobile mx-3': isMobile, 'input-group-web l-full-width': !isMobile }">
							<label class="g-form-label">Email<span class="g-orange">*</span></label>
							<input
								id='email-input'
								[(ngModel)]="email"
								type="email"
								name="email"
								class="form-control"
								placeholder="Email"
								id="email"
								required
								#emailValue="ngModel"
								(change)="checkEmail()"
								email
							/>
							<div
								class="g-error-input"
								*ngIf="emailValue.invalid && emailValue.touched"
							>
								<div *ngIf="emailValue.errors?.required">Email is required.</div>
								<div *ngIf="emailValue.errors?.email">Invalid email format.</div>
							</div>
						</div>
						<div
							[ngClass]="{ 'input-group-mobile mx-3': isMobile, 'input-group-web l-full-width': !isMobile }">
							<label class="g-form-label">Confirm Email<span class="g-orange">*</span></label>
							<input
								id='email-confirmation-input'
								[(ngModel)]="confirmEmail"
								type="email"
								name="confirmEmail"
								class="form-control"
								placeholder="Confirm Email"
								required
								#confirmEmailValue="ngModel"
								email
								appValueMatch
								[confirmValue]="emailValue"
								(change)="checkEmail()"
							/>
							<div
								class="g-error-input"
								*ngIf="confirmEmailValue.invalid && confirmEmailValue.touched"
							>
								<div *ngIf="confirmEmailValue.errors?.confirmValue">Emails do not match.</div>
								<div *ngIf="confirmEmailValue.errors?.required">Confirmation is required.</div>
							</div>
						</div>
					</div>
					<div
						class="fw-bold mb-2"
						[ngClass]="{ 'mx-3': isMobile }"
					>Tickets to transfer:</div>
					<div
						class="l-ticket-list-container"
						[ngClass]="{ 'mx-3': isMobile, 'mb-4': !isMobile }"
					>
						<div *ngIf="!isTicketTransferLoading; else transferLoading">
							<div
								*ngFor="let ticket of transferableTickets; let i = index"
								class="l-checkbox-content"
							>
								<mat-checkbox
									id='ticket-select-checkbox-{{i}}'
									[disabled]="!ticket.canTransfer"
									(change)="handleCheckboxChange(ticket)"
								>
									<label
										[ngClass]="{'l-disabled': !ticket.canTransfer}"
										for="ticket-select-checkbox-{{i}}"
									>
										{{ ticket | formattedTicketTransfer}}
									</label>
								</mat-checkbox>
								<mat-icon
									*ngIf="!ticket.canTransfer"
									class="l-disabled l-icon"
									(click)="tooltip.toggle();"
									[matTooltip]="ticket.transferReason"
									matTooltipPosition="right"
									#tooltip="matTooltip"
								>info</mat-icon>
							</div>
						</div>
						<ng-template #transferLoading>
							<div class='l-transfer-loading'>
								<mat-spinner></mat-spinner>
							</div>
						</ng-template>
					</div>
					<div
						*ngIf="!isMobile"
						class="fw-bold mb-2"
						[ngClass]="{ 'mx-3': isMobile }"
					>Are you sure?</div>
					<mat-checkbox
						*ngIf="!isMobile"
						id='confirmation-checkbox'
						[ngClass]="{ 'w-75 ms-3': isMobile }"
						#confirm='ngModel'
						name='confirm'
						[(ngModel)]='confirmation'
						required
					>By transferring this ticket you agree you relinquish any financial claim to the ticket,
						including in the case of an event postponement or cancellation</mat-checkbox>
				</div>
				<ng-template #showStatusMessage>
					<div
						class="g-orange l-status-message pb-1"
						[ngClass]="{ 'l-mobile-status-message': isMobile }"
					>
						{{statusMessage}}
					</div>
				</ng-template>
			</div>
			<ng-template #mainLoader>
				<div class='l-loading'>
					<mat-spinner></mat-spinner>
				</div>
			</ng-template>
		</form>
	</div>
</div>
<app-footer-button
	#footerButton
	class="g-fixed-footer"
	[primaryText]="'TRANSFER'"
	[isDisabled]="ticketTransferForm.invalid || isFooterButtonDisabled"
	($primaryButton)="handlePrimaryButton()"
	($secondaryButton)="handleNavigationBack()"
	[secondaryText]="'BACK'"
	[isLoading]="isTicketTransferLoading"
	[isMobile]="isMobile"
></app-footer-button>