<div *ngIf="!isContactsLoading; else previousEventsLoading" class="l-container" [ngClass]="{ 'mobile': isMobile}">
    <div *ngIf="hasInitialContactsLists; else noContactLists" class='mt-0 pb-5'>
        <app-web-filter
            id="contacts-filter"
            [isMobile]="isMobile"
            (filterOutput)="handleFilterOutput($event)"
        ></app-web-filter>
        <div class="l-table-container" *ngIf="contactList.filteredData.length; else noMatch">
            <table id="contact-list-table" mat-table [dataSource]="contactList" class="l-table web" matSort>
                <ng-container matColumnDef="checkbox">
                    <th mat-header-cell *matHeaderCellDef>
                        <div [ngClass]="{ 'd-flex justify-content-center flex-wrap': isMobile}">
                            <mat-checkbox id="select-all-checkbox" (change)="handleSelectAllContacts()" [checked]="isAllSelected()"></mat-checkbox>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let contact"> <mat-checkbox id="select-contact" (change)="handleCheckboxChange(contact)"
                            [checked]="isSelected(contact)"></mat-checkbox> </td>
                </ng-container>
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>List Name</th>
                    <td id="contact-list-name" mat-cell *matCellDef="let contact">{{ contact.name }} </td>
                </ng-container>
                <ng-container matColumnDef="contactsCount">
                    <th mat-header-cell *matHeaderCellDef> Number of Contacts </th>
                    <td mat-cell *matCellDef="let contact"> {{ contact.contactsCount }}
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
                <tr mat-row  *matRowDef="let row; columns: displayColumns;"></tr>
            </table>
        </div>
    </div>
    <ng-template #noMatch>
        <div class="l-empty-state">
            <h3 class="mb-3">Nothing to see here...</h3>
            <div class="mb-3">Nothing matches your search</div>
        </div>
    </ng-template>
    <ng-template #noContactLists>
        <div class='l-empty-card'>
            <div class="l-empty-state">
                <h3 class="mb-3">You currently have no previous contact lists.</h3>
            </div>
        </div>
        </ng-template>
</div>
<div class="l-sticky-bar">
	<app-footer-button
		id="send-invites-button"
		primaryText="SEND INVITES"
		[isLoading]="isButtonLoading"
		($primaryButton)="handleSendInvites()"
		[isDisabled]="isButtonDisabled || isButtonLoading"
		[isMobile]="isMobile"
		[fullWidth]="true"
		class="mt-5"
	></app-footer-button>
</div>
<ng-template #previousEventsLoading>
    <div class="l-loading">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>