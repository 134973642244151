import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-details-card',
	templateUrl: './details-card.component.html',
	styleUrls: ['./details-card.component.sass'],
})
export class DetailsCardComponent {
	@Input() icon: string;
	@Input() text: string;
	@Input() isDisabled: boolean;
	@Input() tooltip: string;
}
