<div *ngIf="!isLoading && !isProfilesLoading; else pageLoading">
	<div [ngClass]="{ 'l-mobile-container': isMobile, 'l-container': !isMobile }">
		<div>
			<app-web-content-header
				[headerContent]="headerContent"
				[mediumWidth]='true'
				[showBreadCrumb]="!isMobile"
				[showTitle]="!isMobile"
			>
			</app-web-content-header>
		</div>
		<div *ngIf="!isLoading && !isProfilesLoading && form">
			<app-event-organiser-profile-form
				[form]="form"
				[organiserProfileOptions]="organiserProfileOptions"
				[includeHeaderWithName]="true"
				[profile]="selectedProfile"
				[isEventCreation]="false"
				[isMobile]="isMobile"
				(createProfile)="createProfile()"
			></app-event-organiser-profile-form>
		</div>
	</div>
	<div class="l-sticky-bar" [ngClass]="{ 'mobile': isMobile }">
		<app-footer-button
			[isMobile]="isMobile"
			[primaryText]="'SAVE ORGANISER PROFILE'"
			id="save-selected-profile"
			($primaryButton)="proceedFormSubmit()"
			[isLoading]="isUpdating"
			[isDisabled]="form && ((!form.valid || form.pristine) && isButtonDisabled)"
			[fullWidth]="true"
		>
		</app-footer-button>
	</div>
</div>
<ng-template #pageLoading>
	<div class="l-loading" [class.mobile]="isMobile">
		<mat-spinner></mat-spinner>
	</div>
</ng-template>
