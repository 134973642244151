<div [class.l-has-tooltip]="tooltip">
	<label
		*ngIf="label"
		[ngClass]="labelClass"
		class="l-label"
	>
		{{label}}
		<span
			class="g-orange"
			*ngIf="required"
		>*</span>
		<mat-icon
			*ngIf="tooltip"
			class="l-tooltip"
			#controlFieldTooltip="matTooltip"
			[matTooltip]="tooltip"
			matTooltipPosition="right"
			(click)="controlFieldTooltip.toggle()"
		>
			info
		</mat-icon>
	</label>
	<div [formGroup]="addressForm">
		<div [ngClass]="outerClass">
			<input
				formControlName="lineOne"
				name="lineOne"
				type="text"
				placeholder="Line 1"
				class="l-form-field form-control"
				id="address-line-1"
			>
		</div>
		<div [ngClass]="outerClass">
			<input
				formControlName="lineTwo"
				name="lineTwo"
				type="text"
				placeholder="Line 2"
				class="l-form-field form-control"
				id="address-line-2"
			>
		</div>
		<div [ngClass]="outerClass">
			<input
				formControlName="lineThree"
				name="lineThree"
				type="text"
				placeholder="Line 3"
				class="l-form-field form-control"
				id="address-line-3"
			>
		</div>
		<div [ngClass]="outerClass">
			<input
				formControlName="lineFour"
				name="lineFour"
				type="text"
				placeholder="Line 4"
				class="l-form-field form-control"
				id="address-line-4"
			>
		</div>
		<div [ngClass]="outerClass">
			<input
				formControlName="lineFive"
				name="lineFive"
				type="text"
				placeholder="Line 5"
				class="l-form-field form-control"
				[class.l-input-and-icon]="customIcon"
				id="address-line-5"
			>
		</div>
	</div>
</div>