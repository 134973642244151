import { NotificationDesc } from '@app/models/notification.model';
import { NotificationSourceService } from './notification-source.service';
import { Injectable } from '@angular/core';

@Injectable()
export class NotificationService {
	constructor(
		private sources: NotificationSourceService
	) {}

	getNotificationDesc(actionType: string, options?: any): NotificationDesc {
		let actionDesc: NotificationDesc = {
			title: 'Action successful',
			desc: 'Data succefully loaded',
		};

		const preferableActions = this.sources.getDictionary();

		if (preferableActions && preferableActions[actionType]) {
			actionDesc = preferableActions[actionType](options);
		}

		return actionDesc;
	}
}
