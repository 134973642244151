import { AppAction, FailedAction } from '@app/models/action.model';
import { SchemeID } from '@app/models/dataSchema.model';
import { EventWidget } from '@app/models/event.model';

export enum WidgetActionConstants {
	GET_EVENT_WIDGET = 'GET_EVENT_WIDGET',
	GET_EVENT_WIDGET_SUCCESS = 'GET_EVENT_WIDGET_SUCCESS',
	GET_EVENT_WIDGET_FAILED = 'GET_EVENT_WIDGET_FAILED',
}

const constants = WidgetActionConstants;

export class GetEventWidget extends AppAction <{
	id: SchemeID;
}> {
	readonly type = constants.GET_EVENT_WIDGET;
}

export class GetEventWidgetSuccess extends AppAction <{
	id: SchemeID;
	widget: EventWidget;
}> {
	readonly type = constants.GET_EVENT_WIDGET_SUCCESS;
}

export class GetEventWidgetFailed extends FailedAction {
	readonly type = constants.GET_EVENT_WIDGET_FAILED;
}

export type Actions =
GetEventWidget
| GetEventWidgetSuccess
| GetEventWidgetFailed;
