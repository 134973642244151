import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { EventDomainAPI } from '@app/api/domains/event';
import { ofTypeExt, handleSuccessRequest, handleFailedRequest } from '@app/utils/Store';
import {
	GetEventStreaming,
	StreamingActionConstants,
	GetEventStreamingSuccess,
	GetEventStreamingFailed,
	UpdateEventStreamingFailed,
	SetEventStreamingEnabledFailed,
	ReorderStreamsFailed,
	SetOrganiserPreviewCodeFailed,
	DeleteOrganiserPreviewCode,
	DeleteOrganiserPreviewCodeSuccess,
	DeleteOrganiserPreviewFailed,
	RequestVodDownloadLink
} from './event-streaming.actions';
import { catchError, switchMap, mergeMap, concatMap, withLatestFrom, map } from 'rxjs/operators';
import { defaultDashboardRoute } from '@app/services/url/url.service';
import { Go } from '@app/store/actions/router/router.actions';
import { normalize } from 'normalizr';
import { arrayOfCommonScheme } from '@app/schemes/common.scheme';
import {
	UpdateEventStreaming,
	UpdateEventStreamingSuccess,
	SetStreamingFormModalFlag,
	DeleteEventStreamingRoom,
	DeleteEventStreamingRoomSuccess,
	DeleteEventStreamingRoomFailed
} from './event-streaming.actions';
import { throwError, concat, of } from 'rxjs';
import { getResponseErrors } from '@app/utils/Response';
import { SetEventStreamingEnabled, SetEventStreamingEnabledSuccess } from './event-streaming.actions';
import {
	productStreamToEventStream,
	updateProductStreamToEventStream,
	productStreamIdtoId,
	eventStreamToProductStream,
	eventStreamIdtoProductStreamId
} from '@app/utils/mappers/event';
import { Store } from '@ngrx/store';
import { AppState } from '@app/models/store.model';
import {
	ReorderStreams,
	ReorderStreamsSuccess,
	SetOrganiserPreviewCode,
	SetOrganiserPreviewCodeSuccess,
	RequestVodDownloadLinkSuccess,
	RequestVodDownloadLinkFailed
} from './event-streaming.actions';

@Injectable()
export class StreamingEffects {
	constructor(private action$: Actions, private api: EventDomainAPI, private store$: Store<AppState>) {}

	getEventStreaming = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<GetEventStreaming>(StreamingActionConstants.GET_EVENT_STREAMING),
			switchMap(({ action, payload: { eventId } }) =>
				this.api.getEventStreaming(eventId).pipe(
					withLatestFrom(this.store$),
					map(([value, _store]) => {
						if (value.isSuccess) {
							const stream = productStreamToEventStream(value.payload.streams);
							const streamScheme = normalize(stream, arrayOfCommonScheme);
							return new GetEventStreamingSuccess({
								eventId,
								settings: value.payload.settings,
								streams: {
									byId: streamScheme.entities.byId,
									all: streamScheme.result,
								},
								ticketTypes: value.payload.ticketTypes,
								eventHasBegun: value.payload.eventHasBegun,
							});
						} else {
							throw getResponseErrors(value);
						}
					}),
					catchError((error) =>
						of(
							new GetEventStreamingFailed({
								msg: 'can\'t get the event streaming details',
								action,
								critical: true,
								meta: {
									extraAction: new Go({ path: [defaultDashboardRoute] }),
									extraActionTitle: 'Go to dashboard',
									allowClose: false,
								},
							})
						)
					)
				)
			)
		)
	);

	updateEventStreamingRoom$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<UpdateEventStreaming>(StreamingActionConstants.UPDATE_EVENT_STREAMING),
			switchMap(({ action, payload: { eventId, streams: streamToAdd, isUpdating } }) => {
				const streamToSet = eventStreamToProductStream(streamToAdd);

				return this.api.upsertEventStreamingRoom(eventId, streamToSet).pipe(
					withLatestFrom(this.store$),
					mergeMap(([value]) => {
						if (value.isSuccess && value.payload) {
							const stream = updateProductStreamToEventStream(value.payload);

							return concat([
								new UpdateEventStreamingSuccess({
									eventId,
									streams: stream,
									isUpdating,
								}),
								new SetStreamingFormModalFlag({
									eventId,
									isModalOpen: false,
								}),
							]);
						} else {
							return throwError(getResponseErrors(value));
						}
					}),
					catchError(
						handleFailedRequest(
							new UpdateEventStreamingFailed({
								msg: 'can\'t add streaming details',
								action,
							})
						)
					)
				);
			})
		)
	);

	deleteEventStream$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<DeleteEventStreamingRoom>(StreamingActionConstants.DELETE_EVENT_STREAMING_ROOM),
			switchMap(({ action, payload: { eventId, id } }) =>
				this.api.deleteEventStreamingRoom(eventId, id).pipe(
					withLatestFrom(this.store$),
					map(([value]) => {
						if (value.isSuccess) {
							const streamId = productStreamIdtoId(id);
							return new DeleteEventStreamingRoomSuccess({
								eventId,
								id: streamId,
							});
						} else {
							throw getResponseErrors(value);
						}
					}),
					catchError(
						handleFailedRequest(
							new DeleteEventStreamingRoomFailed({
								msg: 'can\'t delete stream',
								action,
							})
						)
					)
				)
			)
		)
	);

	setEventStreamingEnabled = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<SetEventStreamingEnabled>(StreamingActionConstants.SET_EVENT_STREAMING_ENABLED),
			concatMap(({ action, payload: { eventId, id, isEnabled } }) =>
				this.api.setEventStreamingEnabled(eventId, id, isEnabled).pipe(
					handleSuccessRequest(({ payload }) => new SetEventStreamingEnabledSuccess(payload)),
					catchError(
						handleFailedRequest(
							new SetEventStreamingEnabledFailed({
								msg: 'can\'t update this setting',
								action,
							})
						)
					)
				)
			)
		)
	);

	reorderStream$ = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<ReorderStreams>(StreamingActionConstants.REORDER_STREAMS),
			concatMap(({ action, payload: { eventId, sortOrder } }) =>
				this.api.reorderStreams(eventId, sortOrder).pipe(
					handleSuccessRequest(
						({ payload }) =>
							new ReorderStreamsSuccess({
								eventId,
								sortOrder,
							})
					),
					catchError(
						handleFailedRequest(
							new ReorderStreamsFailed({
								msg: 'can\'t reorder the streams',
								action,
							})
						)
					)
				)
			)
		)
	);

	setOrganiserPreviewCode = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<SetOrganiserPreviewCode>(StreamingActionConstants.SET_ORGANISER_PREVIEW_CODE),
			switchMap(({ action, payload: { eventId } }) =>
				this.api.setOrganiserPreviewCode(eventId).pipe(
					withLatestFrom(this.store$),
					map(([value]) => {
						if (value.isSuccess) {
							const stream = productStreamToEventStream(value.payload.streams);
							const streamScheme = normalize(stream, arrayOfCommonScheme);

							return new SetOrganiserPreviewCodeSuccess({
								eventId,
								settings: value.payload.settings,
								streams: {
									byId: streamScheme.entities.byId,
									all: streamScheme.result,
								},
								ticketTypes: value.payload.ticketTypes,
							});
						} else {
							throw getResponseErrors(value);
						}
					}),
					catchError(
						handleFailedRequest(
							new SetOrganiserPreviewCodeFailed({
								msg: 'can\'t get streaming access code',
								action,
							})
						)
					)
				)
			)
		)
	);

	deleteOrganiserPreviewCode = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<DeleteOrganiserPreviewCode>(StreamingActionConstants.DELETE_ORGANISER_PREVIEW_CODE),
			switchMap(({ action, payload: { eventId } }) =>
				this.api.deleteOrganiserPreviewCode(eventId).pipe(
					withLatestFrom(this.store$),
					map(([value]) => {
						if (value.isSuccess) {
							const stream = productStreamToEventStream(value.payload.streams);
							const streamScheme = normalize(stream, arrayOfCommonScheme);

							return new DeleteOrganiserPreviewCodeSuccess({
								eventId,
								settings: value.payload.settings,
								streams: {
									byId: streamScheme.entities.byId,
									all: streamScheme.result,
								},
								ticketTypes: value.payload.ticketTypes,
							});
						} else {
							throw getResponseErrors(value);
						}
					}),
					catchError(
						handleFailedRequest(
							new DeleteOrganiserPreviewFailed({
								msg: 'can\'t delete streaming access code',
								action,
							})
						)
					)
				)
			)
		)
	);

	requestVodDownloadLink = createEffect(() =>
		this.action$.pipe(
			ofTypeExt<RequestVodDownloadLink>(StreamingActionConstants.REQUEST_VOD_DOWNLOAD_LINK),
			switchMap(({ action, payload: { eventId, id: streamId } }) => {
				const id = eventStreamIdtoProductStreamId(streamId);
				return this.api.requestVodDownloadLink(eventId, id).pipe(
					withLatestFrom(this.store$),
					map(([value]) => {
						if (value.isSuccess) {
							return new RequestVodDownloadLinkSuccess({
								eventId,
								id,
							});
						} else {
							throw getResponseErrors(value);
						}
					}),
					catchError(
						handleFailedRequest(
							new RequestVodDownloadLinkFailed({
								msg: 'can\'t send your download link',
								action,
							})
						)
					)
				);
			})
		)
	);
}
