<div
	class="l-container"
	[ngClass]="{'mobile': data.isMobile}"
>
	<h3 class='mb-4 mx-auto'>{{ heading }}</h3>
	<div *ngIf="description.length" class="l-description">{{ description }}</div>
	<div class="l-table-container">
		<app-change-ticket-table
			*ngIf="data.type === orderModalType.ChangeTicketTypes"
			class="l-table"
			[order]="data.order"
			[eventId]="data.eventId"
			[currentStep]="currentStep"
			[isMobile]="data.isMobile"
			(tickets)="handleTicketTypesChange($event)"
		></app-change-ticket-table>
		<app-cancel-tickets-table
			*ngIf="data.type === orderModalType.CancelTickets"
			class="l-table"
			[order]="data.order"
			[currentStep]="currentStep"
			[isMobile]="data.isMobile"
			[refundFeePayableBy]="refundFeePayableBy"
			(tickets)="handleCancelTicketsSelected($event)"
			(bankDetails)="handleBankDetails($event)"
			(refunderSelected)="handleRefunderSelected($event)"
		></app-cancel-tickets-table>
	</div>
	<div
		class="l-buttons-container"
		[ngClass]="{'mobile': data.isMobile}"
	>
		<button
			class="g-secondary-button l-button"
			(click)="handleCancel()"
			id="back-button"
		>{{ previousButtonText }}</button>
		<button
			class="g-primary-button l-button"
			[disabled]='isSubmitDisabled'
			[class.disabled]='isSubmitDisabled'
			(click)="handleNext()"
			id="submit-button"
		>
			<span *ngIf="!isLoading; else buttonLoader">
				{{ nextButtonText }}
			</span>
			<ng-template #buttonLoader>
				<mat-spinner class="g-button-spinner"></mat-spinner>
			</ng-template>
		</button>
	</div>
</div>
<mat-progress-bar
	*ngIf="isLoading"
	mode="indeterminate"
></mat-progress-bar>