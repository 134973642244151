import { NgModule } from '@angular/core';

import { EventProfileComponent } from './event-profile.component';
import { SharedModule } from '@app/shared/shared.module';
import { EventSharedModule } from '@app/features/event-shared/event-shared.module';

@NgModule({
	imports: [
		SharedModule,
		EventSharedModule,
	],
	exports: [
		EventProfileComponent,
	],
	declarations: [
		EventProfileComponent,
	],
	providers: [],
})
export class EventProfileModule { }
