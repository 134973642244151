import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { PasswordMatchModule } from '@app/directive/password-match.directive';
import { AngularMaterialModule } from '@app/shared/angular-material.module';
import { PaymentCardComponent } from './components/payment-card/payment-card.component';
import { AddBankModalComponent } from './modals/add-bank-modal/add-bank-modal.component';
import { DeleteAccountModalComponent } from './modals/delete-account-modal/delete-account-modal.component';
import { ApiKeysComponent } from './pages/api-keys/api-keys.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { ContactInfoComponent } from './pages/contact-info/contact-info.component';
import { EmailPreferenceComponent } from './pages/email-preference/email-preference.component';
import { PayoutsComponent } from './pages/payouts/payouts.component';
import { PersonalInfoComponent } from './pages/personal-info/personal-info.component';
import { ProfileDashboardComponent } from './pages/profile-dashboard/profile-dashboard.component';
import { SharedModule } from '@app/shared/shared.module';
import { ValueMatchModule } from '@app/directive/value-match.directive';
import { JoinPromoterNetworkComponent } from './pages/join-promoter-network/join-promoter-network.component';
import { RequestBankDetailsComponent } from './pages/request-bank-details/request-bank-details.component';
import { VendingComponent } from './pages/vending/vending.component';

@NgModule({
	declarations: [
		ProfileDashboardComponent,
		DeleteAccountModalComponent,
		ApiKeysComponent,
		PersonalInfoComponent,
		ContactInfoComponent,
		EmailPreferenceComponent,
		ChangePasswordComponent,
		PayoutsComponent,
		PaymentCardComponent,
		AddBankModalComponent,
		JoinPromoterNetworkComponent,
		RequestBankDetailsComponent,
		VendingComponent,
	],
	imports: [
		CommonModule,
		SharedModule,
		AngularMaterialModule,
		FormsModule,
		PasswordMatchModule,
		ValueMatchModule,
	],
})
export class MyAccountModule { }
