<app-my-account-header
	[isMobile]='isMobile'
	pageName="Personal Information"
	(navigateBack)="handleNavigationBack()"
>
</app-my-account-header>
<div class="g-account-container">
	<div
		class="g-account-content"
		[ngClass]="{ 'mobile': isMobile, 'web': !isMobile }"
	>
		<app-web-content-header
			*ngIf="!isMobile"
			class="w-100"
			[headerContent]="headerContent"
		></app-web-content-header>

		<form
			#personalInformationForm="ngForm"
			class="g-form-container"
			[ngClass]="{ 'mt-4': isMobile, 'my-5': !isMobile }"
		>
			<div class="g-double-input-container">
				<div [ngClass]="{ 'g-full-width': isMobile, 'g-double-input': !isMobile }">
					<label class="g-form-label">First Name</label>
					<input
						id='first-name-input'
						#firstName="ngModel"
						[(ngModel)]="personalInformation.firstName"
						type="text"
						name="firstName"
						class="form-control"
						placeholder="First Name"
						id="firstName"
					/>
				</div>
				<div [ngClass]="{ 'g-full-width': isMobile, 'g-double-input': !isMobile }">
					<label class="g-form-label">Last Name</label>
					<input
						id='last-name-input'
						#lastName="ngModel"
						[(ngModel)]="personalInformation.surname"
						type="text"
						name="lastName"
						class="form-control"
						placeholder="Last Name"
					/>
				</div>
			</div>
			<div class="g-double-input-container">
				<div [ngClass]="{ 'g-full-width': isMobile, 'g-double-input': !isMobile }">
					<label class="g-form-label">Date of birth</label>
					<input
						id='dob-input'
						#dob="ngModel"
						[(ngModel)]="dateOfBirth"
						[max]="today"
						type="text"
						name="dateOfBirth"
						class="form-control"
						placeholder="dd/mm/yyyy"
						readonly
						[matDatepicker]="picker"
						(click)="picker.open()"
					/>
					<mat-datepicker
						#picker
						startView="multi-year"
					>
						<mat-date-range-picker-actions>
							<button
								id="close-datepicker"
								class="g-secondary-button g-datepicker-button"
								matDatepickerCancel
							>CANCEL</button>
							<button
								id="apply-datepicker"
								class="g-primary-button g-datepicker-button"
								matDatepickerApply
							>APPLY</button>
						</mat-date-range-picker-actions>
					</mat-datepicker>
				</div>
				<div [ngClass]="{ 'g-full-width': isMobile, 'g-double-input': !isMobile }">
					<label class="g-form-label">ID / Passport number</label>
					<input
						id='passport-input'
						#idPassport="ngModel"
						[(ngModel)]="personalInformation.idPassportNumber"
						type="text"
						name="idPassport"
						class="form-control"
						placeholder="ID / Passport"
					/>
				</div>
			</div>
			<div class="g-double-input-container w-100">
				<div [ngClass]="{ 'g-full-width': isMobile, 'g-double-input': !isMobile }">
					<label class="g-form-label">Gender</label>
					<mat-select
						id='gender-select'
						class="form-control w-100"
						#gender="ngModel"
						name="gender"
						placeholder="Please Select"
						[(ngModel)]="personalInformation.gender"
					>
						<mat-option
							*ngFor="let genderOption of genders"
							[value]="genderOption"
						>{{ genderOption }}</mat-option>
					</mat-select>
				</div>
				<div [ngClass]="{ 'g-full-width': isMobile, 'g-double-input': !isMobile }">
					<label class="g-form-label">Company</label>
					<input
						id='company-input'
						#company="ngModel"
						[(ngModel)]="personalInformation.company"
						type="text"
						name="company"
						class="form-control"
						placeholder="Company"
					/>
				</div>
			</div>
		</form>
	</div>
</div>
<app-footer-button
	class="g-fixed-footer"
	[primaryText]="'SAVE CHANGES'"
	[isDisabled]="personalInformationForm.invalid || personalInformationForm.pristine"
	($primaryButton)="handlePrimaryButton()"
	[isLoading]="isLoading"
	[isMobile]="isMobile"
>
</app-footer-button>