import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import * as fileActions from '../actions/file/file.actions';
import { FileActionsConstants } from '@app/store/actions/file/file.actions.constants';
import { switchMap, catchError } from 'rxjs/operators';
import { ofTypeExt, handleSuccessRequest, handleFailedRequest } from '@app/utils/Store';
import { FileDomainAPI } from '@app/api/domains/file';

@Injectable()
export class FileEffects {
	constructor(
		private action$: Actions,
		private api?: FileDomainAPI
	) { }

	removeImage$ = createEffect(() =>
		this.action$
			.pipe(
				ofTypeExt<fileActions.RemoveEventImage>(FileActionsConstants.REMOVE_EVENT_IMAGE),
				switchMap(({ action, payload: { image, image: { id }, eventId } }) =>
					this.api.removeImage(id)
						.pipe(
							handleSuccessRequest(
								() => new fileActions.RemoveEventImageSuccess({
									image,
									eventId,
								})
							),
							catchError(
								handleFailedRequest(
									new fileActions.RemoveEventImageFailed({
										msg: 'can\'t remove image',
										action,
									})
								)
							)
						)
				)
			)
	);

	removeTicketImage$ = createEffect(() =>
		this.action$
			.pipe(
				ofTypeExt<fileActions.RemoveTicketImage>(FileActionsConstants.REMOVE_TICKET_IMAGE),
				switchMap(({ action, payload: { image, image: { id }, eventId } }) =>
					this.api.removeImage(id)
						.pipe(
							handleSuccessRequest(
								() => new fileActions.RemoveTicketImageSuccess({
									image,
									eventId,
								})
							),
							catchError(
								handleFailedRequest(
									new fileActions.RemoveTicketImageFailed({
										msg: 'can\'t remove image',
										action,
										critical: false,
									})
								)
							)
						)
				)
			)
	);

	uploadContactsCSV$ = createEffect(() =>
		this.action$
			.pipe(
				ofTypeExt<fileActions.UploadContactsCSV>(FileActionsConstants.UPLOAD_CONTACTS_CSV),
				switchMap(({ action }) =>
					this.api.uploadContactsCSV(action.payload.file)
						.pipe(
							handleSuccessRequest(
								({ payload: contacts }) => new fileActions.UploadContactsCSVSuccess({
									contacts,
								})
							),
							catchError(
								handleFailedRequest(
									new fileActions.UploadContactsCSVFailed({
										msg: 'can\'t upload CSV file',
										action,
										critical: false,
									})
								)
							)
						)
				)
			)
	);
}
