<div class="l-container">
	<div class="l-content">
		<div class="l-details">
			<div class="l-badge" [ngClass]="{ 'on-sale': ticket.status === 1}">
				{{ticket.statusDescription.toUpperCase()}}
			</div>
			<div class="l-name">
				<h3>{{ title }}</h3>
			</div>
			<div class="l-info">
				<strong>Ticket Type: </strong>{{ ticket.ticketTypeName }}
			</div>
			<div class="l-info">
				<strong>Price: </strong>{{currencySymbol}} {{ticket.price | number :
				'.2-2'}}
			</div>
			<div class="l-info">
				<strong>Type: </strong>{{ticket.type === 1 ? 'Public' : 'Private'}}
			</div>
			<div class="l-info">
				<strong>Date: </strong>{{ticket.dateCreated | formattedDatePipe}}
			</div>
			<div class="l-info">
				<strong>Email: </strong>{{ticket.email ? ticket.email : 'N/A'}}
			</div>
			<div class="l-info">
				<strong>Bank Details: </strong>{{ticket.bankAccountHashed ?
				ticket.bankAccountHashed : 'N/A'}}
			</div>
		</div>
		<mat-icon  class="l-icon" [matMenuTriggerFor]="menu">more_horiz</mat-icon>
		<mat-menu #menu="matMenu">
			<button id="cancel-ticket-resale" mat-menu-item (click)="handleTicketCancel()"> {{cancelButtonText}} </button>
		  </mat-menu>
	</div>
</div>
