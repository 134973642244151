import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PaymentStatus, ProductStatus, ProductStatusMapping } from '@app/models/event.model';
import { NameAndID } from '@app/models/organiser.model';
import { DropdownOption, FilterOptions } from '@app/models/shared';
import { handleOrder } from '@app/utils/common-helpers';
import { Dictionary } from '@ngrx/entity';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
	selector: 'app-web-filter',
	templateUrl: './web-filter.component.html',
	styleUrls: ['./web-filter.component.sass'],
})
export class WebFilterComponent implements OnInit {

	@Input() isMobile = false;

	@Input() showSearch = true;
	@Input() showRangeDate = false;
	@Input() reportButton = false;

	@Input() organiserProfiles: NameAndID[];
	@Input() eventStatuses: ProductStatusMapping;
	@Input() paymentStatuses: PaymentStatus;
	@Input() buttonText: string;
	@Input() buttonId = '';
	@Input() isButtonDisabled = false;
	@Input() disabledTooltipText: string;
	@Input() isSearchVisible = true;

	@Input() dropDownButtonText: string;
	@Input() dropDownOptions: Dictionary<DropdownOption>;

	@Input() searchPlaceholder = 'Search events';

	filterOptions = new FilterOptions;

	handleDefaultOrder = handleOrder;

	eventStatus: string;
	dateFrom: Date = null;
	dateTo: Date = null;

	@Output() buttonClick: EventEmitter<void> = new EventEmitter<void>();
	@Output() filterOutput: EventEmitter<FilterOptions> = new EventEmitter<FilterOptions>;
	@Output() dropDownButtonSelection: EventEmitter<DropdownOption> = new EventEmitter<DropdownOption>();

	private searchTerm$ = new Subject<string>();

	ngOnInit(): void {
		this.searchTerm$.pipe(
			debounceTime(300),
			distinctUntilChanged()
		).subscribe(() => this.handleEmit());
	}

	handleEmit() {
		this.filterOutput.emit(this.filterOptions);
	}

	handleEmitButton() {
		this.buttonClick.emit();
	}

	handleDropdownOption(option: DropdownOption) {
		this.dropDownButtonSelection.emit(option);
	}

	handleSearchChanged() {
		this.searchTerm$.next(this.filterOptions.searchTerm);
	}

	handleEventStatus() {
		this.filterOptions.eventStatus = this.eventStatus ? ProductStatus[this.eventStatus] : null;
		this.handleEmit();
	}

	handleDateChange() {
		if (!this.dateFrom && !this.dateTo) {
			this.filterOptions.dateFrom = null;
			this.filterOptions.dateTo = null;
			this.handleEmit();
		}

		if (this.dateFrom && this.dateTo) {
			this.filterOptions.dateFrom = this.adjustGMTAndReturnISOString(this.dateFrom);
			const date = this.adjustGMTAndReturnISOString(this.dateTo);
			const dateTo = new Date(date);
			dateTo.setUTCHours(23, 59, 59, 59);
			this.filterOptions.dateTo = dateTo.toISOString();
			this.handleEmit();
		}
	}

	adjustGMTAndReturnISOString(date: Date) {
		const timezoneOffset = (date.getTimezoneOffset() / 60) * -1;
		date.setHours(date.getHours() + timezoneOffset);
		return date.toISOString();
	}
}
